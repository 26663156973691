export * from './lead';
export * from './user';

export const Institute = {
  FETCH_INSTITUTE: 'FETCH_INSTITUTE',
  FETCH_INSTITUTE_LOADING: 'FETCH_INSTITUTE_LOADING',
  FETCH_INSTITUTE_ERROR: 'FETCH_INSTITUTE_ERROR',
  FETCH_INSTITUTE_CLEAR: 'FETCH_INSTITUTE_CLEAR',
};

export const Product = {
  FETCH_PRODUCT: 'FETCH_PRODUCT',
  FETCH_PRODUCT_FEES: 'FETCH_PRODUCT_FEES',
  FETCH_PRODUCT_FEES_CLEAR: 'FETCH_PRODUCT_FEES_CLEAR',
  FETCH_PRODUCT_LOADING: 'FETCH_PRODUCT_LOADING',
  FETCH_PRODUCT_ERROR: 'FETCH_PRODUCT_ERROR',
  FETCH_PRODUCT_CLEAR: 'FETCH_PRODUCT_CLEAR',
  REQUEST_PRODUCT_FILTERS: 'REQUEST_PRODUCT_FILTERS',
  SET_PRODUCT_FILTERS: 'SET_PRODUCT_FILTERS',
};
