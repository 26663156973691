import React, { useState, FC } from 'react';
import { Typography, Button, Space } from 'antd';
import { DrawerElem } from '@shared-components/elements';
import { importData } from '@lyra/services.api';
import { DownloadTemplate } from '../../forms/download-template';
import {
  CANCEL_BTN,
  DRAWER_WIDTH,
  IMPORT,
  LEAD_IMPORT_TITLE,
} from '@moxie/constants';
import { errorHandler } from '@moxie/utils';
import { successNotificationHandler } from 'libs/shared/src/shared';

interface IImportLead {
  isOpen: boolean;
  onClose: () => void;
  triggerRefresh: () => void;
}

const ImportLeadDrawer: FC<IImportLead> = ({
  isOpen,
  onClose,
  triggerRefresh,
}: IImportLead) => {
  const { Title } = Typography;
  const [excelContent, setExcelContent] = useState([]);
  const [disable, setDisable] = useState(true);

  const setExcelData = (data: any) => {
    setExcelContent(data.data);
  };

  const setIsImport = (data: boolean) => {
    setDisable(data);
  };

  const handleSubmit = async () => {
    if (excelContent) {
      try {
        const data = await importData(excelContent);
        if (data.data.success) {
          setDisable(true);
          successNotificationHandler(data.data.message);
          onClose();
          triggerRefresh && triggerRefresh();
        }
      } catch (err) {
        errorHandler(err);
      }
    }
  };
  return (
    <DrawerElem
      title={<Title level={4}>{LEAD_IMPORT_TITLE}</Title>}
      width={DRAWER_WIDTH}
      onClose={onClose}
      visible={isOpen}
      footer={
        <div className="padding-bottom-2 text-align-right">
          <Space direction="horizontal">
            <Button onClick={onClose} className="lead-margin-right">
              {CANCEL_BTN}
            </Button>
            <Button
              onClick={handleSubmit}
              type="primary"
              disabled={disable}
              data-testid="import_lead"
            >
              {IMPORT}
            </Button>
          </Space>
        </div>
      }
    >
      <DownloadTemplate setExcelData={setExcelData} isDisabled={setIsImport} />
    </DrawerElem>
  );
};

export default ImportLeadDrawer;
