import { AxiosResponse } from 'axios';
import { put, takeLatest } from 'redux-saga/effects';
import * as department from './department.constant';
import * as departmentAction from './department.action';
import { IAction, IDepartment } from '@shared-components/models';
import {
  getAllDepartments,
  getOneDepartment,
  addDepartment,
  updateDepartment,
  deleteDepartment,
  getBranchDepartments,
} from '@lyra/services.api';
import { errorHandler } from '@moxie/utils';
import { successNotificationHandler } from '@moxie/shared';

export const getDepartment = function* ({ payload }: IAction<any>) {
  try {
    if (payload) {
      const response: AxiosResponse = yield getOneDepartment(payload);
      yield put(departmentAction.getDepartmentSuccess(response.data.data));
    }
  } catch (err) {
    errorHandler(err);
    yield put(departmentAction.getDepartmentFailure(err));
  }
};

export const getDepartments = function* (): any {
  try {
    const res: AxiosResponse = yield getAllDepartments();
    yield put(departmentAction.getDepartmentsSuccess(res.data.data));
  } catch (err) {
    errorHandler(err);
    yield put(departmentAction.getDepartmentsFailure(err));
  }
};

export const addDepartmentData = function* ({
  payload,
  triggerRefresh,
}: IAction<IDepartment>) {
  try {
    if (payload) {
      const response: AxiosResponse = yield addDepartment(payload);
      yield put(departmentAction.addDepartmentSuccess(response.data.data));
      successNotificationHandler(response.data.message);
      triggerRefresh?.();
    }
  } catch (err) {
    errorHandler(err);
    yield put(departmentAction.addDepartmentFailure(err));
  }
};

export const updateDepartmentData = function* ({
  payload,
  triggerRefresh,
}: IAction<IDepartment>) {
  try {
    if (payload) {
      const response: AxiosResponse = yield updateDepartment(payload);
      yield put(departmentAction.updateDepartmentSuccess(response.data.data));
      triggerRefresh?.();
      successNotificationHandler(response.data.message);
    }
  } catch (err) {
    errorHandler(err);
    yield put(departmentAction.updateDepartmentFailure(err));
  }
};

export const deleteDepartmentData = function* ({ payload }: IAction<any>) {
  try {
    if (payload) {
      const response: AxiosResponse = yield deleteDepartment(payload);
      yield put(departmentAction.deleteDepartmentSuccess(response.data.data));
      successNotificationHandler(response.data.message);
    }
  } catch (err) {
    errorHandler(err);
    yield put(departmentAction.deleteDepartmentFailure(err));
  }
};

export const getAllBranchDepartments = function* ({ payload }: any) {
  try {
    const response: AxiosResponse = yield getBranchDepartments(payload);
    yield put(
      departmentAction.getAllBranchDepartmentsSuccess(response.data.data)
    );
  } catch (err) {
    errorHandler(err);
    yield put(departmentAction.getAllBranchDepartmentsFailure(err));
  }
};

export default function* departmentSaga(): unknown {
  yield takeLatest(department.GET_DEPARTMENT_REQUEST, getDepartment);
  yield takeLatest(department.GET_DEPARTMENTS_REQUEST, getDepartments);
  yield takeLatest(department.ADD_DEPARTMENT_REQUEST, addDepartmentData);
  yield takeLatest(department.UPDATE_DEPARTMENT_REQUEST, updateDepartmentData);
  yield takeLatest(department.DELETE_DEPARTMENT_REQUEST, deleteDepartmentData);
  yield takeLatest(
    department.GET_BRANCH_DEPARTMENTS_REQUEST,
    getAllBranchDepartments
  );
}
