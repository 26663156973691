export const dummyCategory = [
  {
    name: 'Personal Information',
    id: '1',
  },
  {
    name: 'Visa information',
    id: '2',
  },
];

export const dummyLabel = {
  name: 'Personal Information',
  fields: [
    {
      id: 1,
      name: 'Sibling',
      type: 'Character',
    },
    {
      id: 2,
      name: 'Gender',
      type: 'Option',
      Option: [{ name: 'Option 1', id: 1 }],
    },
  ],
};
