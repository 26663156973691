import React from 'react';
import { PersonalDetailsTab } from './personal-details-tab';
import { AddressDetailsTab } from './address-details';
import { VisaDetailsTab } from './passport-visa-details';
import { EmergencyContactDetailsTab } from './emergency-contact-details';
import { FinancialDetailsTab } from './financial-details';

const CategoryDetailContent: React.FC<any> = (props) => {
  const { categoryKey, lead } = props;

  const detailContent = () => {
    switch (categoryKey) {
      case 'personal':
        return <PersonalDetailsTab lead={lead} />;
      case 'address':
        return <AddressDetailsTab lead={lead} />;
      case 'passport':
        return <VisaDetailsTab lead={lead} />;
      case 'emergency_contact':
        return (
          <EmergencyContactDetailsTab
            id={lead?.id}
            contactDetails={lead?.emergencyContactDetails}
            archived={lead?.archived}
          />
        );
      case 'financial':
        return (
          <FinancialDetailsTab
            archived={lead?.archived}
            id={lead?.id}
            financialDetails={lead?.financialDetails}
          />
        );
      default:
        return null;
    }
  };

  return detailContent();
};

export { CategoryDetailContent };
