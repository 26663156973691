const isAssignedFollowed = (type: string, data: any, user_id: any) => {
  if (type === 'contacts') {
    const allOptions = data?.followers_id ?? [];
    if (!allOptions.includes(data?.assignee_id)) {
      allOptions.push(data?.assignee_id);
    }
    return allOptions.includes(user_id);
  } else {
    return true;
  }
};
export default isAssignedFollowed;
