import React from 'react';
import { Col, Form, Row } from 'antd';
import {
  COUNTRIES,
  COUNTRY_LABEL,
  COUNTRY_PLACEHOLDER,
  GUTTER,
  LABEL,
  PLACEHOLDER,
  WORKFLOW_INFORMATION,
} from '@moxie/constants';
import { FormItem, Option, SearchableSelect } from '@moxie/shared';
import {
  IGetPopupContainer,
  IWorkflowFormComponent,
  IWorkflowType,
} from '@shared-components/models';

const CreateWorkflowForm = ({
  form,
  onSubmit,
  workflowTypeList,
  handleFieldsChange,
}: IWorkflowFormComponent) => {
  return (
    <div>
      <Form
        layout="vertical"
        form={form}
        onFinish={onSubmit}
        onFieldsChange={handleFieldsChange}
      >
        <div className="form_section_header">{WORKFLOW_INFORMATION}</div>
        <Row gutter={GUTTER}>
          <Col span="24">
            <FormItem
              name="workflowTypeId"
              label={LABEL.WORKFLOW_TYPE}
              rules={[{ required: true }]}
            >
              <SearchableSelect
                optionFilter="key"
                placeholder={PLACEHOLDER.SELECT_WORKFLOW_TYPE}
              >
                {workflowTypeList &&
                  workflowTypeList.map((item: IWorkflowType) => {
                    return (
                      <Option value={item.id || ''} key={item.name}>
                        {item.name}
                      </Option>
                    );
                  })}
              </SearchableSelect>
            </FormItem>
          </Col>
          <Col span="24">
            <div id="country_list" className="relative">
              <FormItem
                name="country"
                label={COUNTRY_LABEL}
                rules={[{ required: true }]}
              >
                <SearchableSelect
                  placeholder={COUNTRY_PLACEHOLDER}
                  getPopupContainer={(): IGetPopupContainer =>
                    document.getElementById('country_list')
                  }
                >
                  {COUNTRIES.map((country) => {
                    return (
                      <Option value={country.name} key={country.code}>
                        {country.name}
                      </Option>
                    );
                  })}
                </SearchableSelect>
              </FormItem>
            </div>
          </Col>
        </Row>
      </Form>
    </div>
  );
};

export { CreateWorkflowForm };
