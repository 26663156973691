import React from 'react';
import {
  CANCEL_BTN,
  CREATE_TASKS,
  EDIT,
  EDIT_TASK,
  SAVE_BTN,
  UPDATE_BTN,
  VIEW_TASK_TITLE,
} from '@moxie/constants';
import { Button, DrawerElem } from '@shared-components/elements';
import { Space } from 'antd';
import { TasksAddEditForm } from '../../forms/tasks';
import { TaskFormDrawerProps } from '@shared-components/models';
import { EditOutlined } from '@ant-design/icons';
import { useAppSelector } from '@lyra/core';
import { setLoading } from 'apps/lyra/src/core/services/services.action';

const TaskFormDrawer: React.FC<TaskFormDrawerProps> = ({
  isOpen,
  onClose,
  handleFormSubmit,
  handleSubmit,
  isEdit,
  isView,
  setDocuments,
  documents,
  setIsEdit,
  setIsView,
  initialData,
  form,
  setDisabled,
  disabled,
}: TaskFormDrawerProps) => {
  const loading = useAppSelector((store) => store.tasks.loading);

  const toggleEdit = () => {
    setIsEdit(true);
    setIsView(false);
  };

  const onFieldsChange = (allFields: string | any[]) => {
    if (allFields.length > 0) {
      setDisabled(false);
    }
  };

  const editIcon = (
    <>
      {VIEW_TASK_TITLE}
      <Button
        type="primary"
        onClick={() => toggleEdit()}
        icon={<EditOutlined />}
        className="edit-action"
      >
        {EDIT}
      </Button>
    </>
  );

  return (
    <DrawerElem
      title={isEdit ? EDIT_TASK : isView ? editIcon : CREATE_TASKS}
      width={720}
      onClose={onClose}
      visible={isOpen}
      maskClosable={false}
      closable={!loading}
      footer={
        <div className="padding-bottom-2 text-align-right">
          {!isView && (
            <>
              <Space direction="horizontal">
                <Button
                  onClick={onClose}
                  className="lead-margin-right"
                  disabled={loading}
                  data-testid="cancel_btn"
                >
                  {CANCEL_BTN}
                </Button>

                <Button
                  onClick={handleFormSubmit}
                  type="primary"
                  disabled={disabled || loading}
                  data-testid="submit_btn"
                >
                  {isEdit ? UPDATE_BTN : SAVE_BTN}
                </Button>
              </Space>
            </>
          )}
        </div>
      }
    >
      <TasksAddEditForm
        initialData={initialData}
        handleSubmit={handleSubmit}
        form={form}
        documents={documents}
        setDocuments={setDocuments}
        isView={isView}
        isEditMode={isEdit}
        setLoading={setLoading}
        setDisabled={setDisabled}
        onFieldsChange={onFieldsChange}
      />
    </DrawerElem>
  );
};

export { TaskFormDrawer };
