import { errorHandler, ObjectToParams } from '@moxie/utils';
import { useEffect, useState } from 'react';
import { UsePaginationParams } from '@shared-components/models';

const usePagination = ({
  page,
  action,
  filter,
  limit,
  sortBy,
  sort,
  refresh = true,
}: UsePaginationParams): Array<any> => {
  const [allId] = useState([]);
  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(true);
  const [totalNoOfData, setTotalData] = useState(0);
  const [totalDataCount, setTotalDataCount] = useState(0);
  const [debounce, setDebounce] = useState(true);

  useEffect(() => {
    if (debounce) setTimeout(() => setDebounce(false), 100);

    if (!debounce) {
      executeAction();
    }
  }, [filter.s, page, filter, limit, sort, sortBy, refresh, debounce]);

  const getUrl = (): string => {
    const object = {
      page: page,
      limit: limit,
      sort: sort,
      sortBy: sortBy,
      ...filter,
    };
    const url = ObjectToParams(object);
    return `?${url}`;
  };

  const executeAction = async () => {
    setData([]);
    setLoading(true);
    try {
      const response = await action(getUrl());
      setData(
        response?.data?.data.map((item: any) => {
          item.key = item.id;
          return item;
        })
      );
      setTotalDataCount(response?.data?.meta?.data_count);
      setTotalData(
        parseInt(response?.data?.meta?.total) *
          parseInt(response?.data?.meta?.limit)
      );
    } catch (err: any) {
      setData([]);
      if (err?.response?.status !== 404) errorHandler(err);
    }
    setLoading(false);
  };
  return [data, loading, totalNoOfData, totalDataCount, allId];
};

export { usePagination };
