import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import { Button, FormItemProps } from 'antd';
import { LeftOutlined } from '@ant-design/icons';
import { TEXT, URL_OFFICE } from '@moxie/constants';
import { OverViewHeader } from '@shared-components/elements';
import { errorHandler } from '@moxie/utils';
import { OfficeProfile } from './office-branch-profile';
import { OfficeDrawer } from './office-form-drawer';
import { useForm } from 'antd/lib/form/Form';
import { IOfficeBranch } from '@shared-components/models';
import { changeNullToUndefined, OfficeBranchTabs } from '@moxie/shared';
import { officeBranchActions, useAppSelector } from '@lyra/core';
import { useDispatch } from 'react-redux';

const OfficeBranchDetailComponent: React.FC = () => {
  const branchData = useAppSelector(
    (state) => state.office_branches.singleData
  );
  const [form] = useForm();
  const dispatch = useDispatch();
  const [open, setOpen] = useState(false);
  const [nameValidationStatus, setNameValidationStatus] = useState<
    FormItemProps['validateStatus']
  >('');
  const handleClose = () => {
    setOpen(false);
  };

  const handleOpen = () => {
    setOpen(true);
  };

  const handleSubmit = async (data: IOfficeBranch) => {
    let postData;
    try {
      postData = {
        ...data,
        id: branchData?.id,
        address: changeNullToUndefined({
          ...data.address,
          id: branchData?.address_id,
        }),
      };
      await dispatch(officeBranchActions.updateRequest(postData));
      await dispatch(officeBranchActions.getOneRequest(postData.id));
    } catch (error) {
      errorHandler(error);
    }
    handleClose();
  };

  const handleFormSubmit = () => {
    form.submit();
  };

  return (
    <div>
      <OverViewHeader
        title={TEXT.OFFICES}
        actions={
          <Link to={URL_OFFICE}>
            <Button icon={<LeftOutlined />}>{TEXT.BRANCH_LIST}</Button>
          </Link>
        }
      />

      {branchData && (
        <OfficeDrawer
          visible={open}
          onClose={handleClose}
          handleFormSubmit={handleFormSubmit}
          isEdit={open}
          form={form}
          nameValidationStatus={nameValidationStatus}
          setNameValidationStatus={setNameValidationStatus}
          handleSubmit={handleSubmit}
          initialData={branchData}
        />
      )}
      {branchData && (
        <OfficeProfile data={branchData} handleOpen={handleOpen} />
      )}
      {branchData && <OfficeBranchTabs />}
    </div>
  );
};

export { OfficeBranchDetailComponent };
