import { API_URL } from '@moxie/constants';
import { Company, IResponse } from '@shared-components/models';
import { axiosInstance as axios } from './axios';

const getAllTenants = (params = ''): Promise<IResponse> => {
  return axios.get(`${API_URL.COMPANY}/${params}`);
};

const getOneTenant = (id?: string): Promise<IResponse> => {
  return axios.get(`${API_URL.COMPANY}/${id}`);
};

const updateTenantDetail = (
  id: string | undefined,
  data: { sharing_ratio: number; expiration_date: Date }
): Promise<IResponse> => {
  return axios.put(`${API_URL.COMPANY}/detail/${id}`, data);
};

const updateCompany = (
  id: string | undefined,
  data: Company
): Promise<IResponse> => {
  return axios.put(`${API_URL.COMPANY}/${id}`, data);
};
const getCompanies = (): Promise<IResponse> => {
  return axios.get(`${API_URL.COMPANY}/all`);
};

const deleteCompany = (id: string): Promise<IResponse> => {
  return axios.delete(`${API_URL.COMPANY}/${id}`);
};

const disableCompany = (id: string | any, body: any): Promise<IResponse> => {
  return axios.put(`${API_URL.COMPANY}/status/${id}`, body);
};
export {
  getAllTenants,
  getOneTenant,
  getCompanies,
  updateTenantDetail,
  updateCompany,
  deleteCompany,
  disableCompany,
};
