import { authActions, useAppSelector } from '@lyra/core';
import {
  AUTH_MESSAGE,
  COOKIE_ACCESS,
  COOKIE_REFRESH,
  EMAIL_LABEL,
  LOGOUT_BTN,
  PASSWORD_LABEL,
  TEXT,
  UPDATE_BTN,
} from '@moxie/constants';
import { Button, Input } from '@shared-components/elements';
import { ILogin } from '@shared-components/models';
import { Form, Modal } from 'antd';
import { useForm } from 'antd/lib/form/Form';
import { RootState } from 'apps/lyra/src/core/store';
import Cookies from 'js-cookie';
import React from 'react';
import { useDispatch } from 'react-redux';
import { useHistory } from 'react-router';
import { successNotificationHandler } from '../../shared';

const SessionRefreshModal: React.FC = () => {
  const history = useHistory();
  const [form] = useForm();
  const isVisible = useAppSelector(
    (store: RootState) => store.auth.sessionRefreshModal
  );
  const dispatch = useDispatch();

  const handleOk = () => {
    form.submit();
  };

  const handleCancel = () => {
    dispatch(authActions.closeSessionModal());
    handleLogout();
  };

  const handleSubmit = async (data: { password: string }) => {
    const email = localStorage.getItem(EMAIL_LABEL);
    if (email) {
      const userData: ILogin = {
        password: data.password,
        email: email,
      };
      dispatch(authActions.login(userData));
    }
  };

  const handleLogout = async () => {
    const token = Cookies.get(COOKIE_ACCESS);
    Cookies.remove(COOKIE_ACCESS);
    Cookies.remove(COOKIE_REFRESH);
    history.push('/');
    dispatch(authActions.logout(token));
    successNotificationHandler(AUTH_MESSAGE.LOGOUT);
  };
  return (
    <Modal
      title={TEXT.SESSION_UPDATE}
      visible={isVisible}
      footer={[
        <Button onClick={handleCancel} key="1">
          {LOGOUT_BTN}
        </Button>,
        <Button onClick={handleOk} type="primary" key="2">
          {UPDATE_BTN}
        </Button>,
      ]}
    >
      <Form onFinish={handleSubmit} form={form}>
        <Form.Item
          label={PASSWORD_LABEL}
          name="password"
          rules={[{ required: true }]}
        >
          <Input type="password" />
        </Form.Item>
      </Form>
    </Modal>
  );
};

export { SessionRefreshModal };
