import { useAppSelector, userActions } from '@admin/core';
import { getAllApplications, getWorkflowStages } from '@admin/services.api';
import {
  ALL_APPLICATIONS,
  LABEL,
  TEXT,
  UNASSIGNED_LABEL,
} from '@moxie/constants';
import {
  Button,
  Option,
  PageHeaderTitle,
  SearchableSelect,
} from '@shared-components/elements';
import {
  IApplicationStage,
  IApplicationTableButtonState,
  IWorkflow,
} from '@shared-components/models';
import { Tabs } from 'antd';
import { useEffect, useReducer, useState } from 'react';
import { useDispatch } from 'react-redux';
import { ApplicationTable } from './application-table';

const ApplicationListComponent = () => {
  const dispatch = useDispatch();
  const { TabPane } = Tabs;
  const [filter, setFilter] = useState<any>({});
  const [stages, setStages] = useState<IApplicationStage[]>([]);
  const loggedInUserId = useAppSelector((state) => state?.auth?.user?.id);
  const workflows = useAppSelector((state) => state?.workflows?.allData);
  const companyId = useAppSelector((state) => state?.auth?.user?.company_id);

  const filteredWorkflows = workflows.filter(
    (workflow: any) => workflow?.company_id === companyId
  );

  const [buttonState, setButtonState] = useReducer(
    (
      state: IApplicationTableButtonState,
      newState: IApplicationTableButtonState
    ) => ({
      ...state,
      ...newState,
    }),
    {
      myApplications: false,
      unassignedApplications: false,
      showAll: true,
      isTabChanged: false,
      loading: false,
      activeKey: 'All',
    }
  );

  const getApplications = async (params: string) => {
    setButtonState({ loading: true });
    const response = await getAllApplications(params);
    setButtonState({ loading: false });
    return response;
  };

  const handleTableButtons = async (type: string, isActive?: boolean) => {
    setButtonState({ isTabChanged: !buttonState.isTabChanged });
    switch (type) {
      case 'unassigned':
        setButtonState({
          unassignedApplications: !buttonState.unassignedApplications,
          myApplications: false,
          showAll: isActive,
        });
        setFilter({ ...filter, adminUserId: isActive ? '' : 'unassigned' });
        return;
      case 'myApplications':
        setButtonState({
          unassignedApplications: false,
          myApplications: !buttonState.myApplications,
          showAll: isActive,
        });
        setFilter({ ...filter, adminUserId: isActive ? '' : loggedInUserId });
        return;
      case 'showAll':
        if (isActive) return;
        setButtonState({
          unassignedApplications: false,
          myApplications: false,
          showAll: !buttonState.showAll,
        });
        setFilter({ ...filter, adminUserId: '' });
        return;
      default:
        return;
    }
  };

  const handleChangeWorkflow = async (key: string | any) => {
    if (key === 'all') {
      setFilter({ ...filter, workflowId: '', stageId: '' });
      setStages([]);
      return;
    }
    setFilter({ ...filter, workflowId: key });
    const response = await getWorkflowStages(key);
    setStages(response?.data?.data);
  };

  const handleTabClick = (key: string) => {
    setButtonState({ activeKey: key });
    if (key === 'All') {
      setFilter({ ...filter, stageId: '' });
    } else {
      setFilter({ ...filter, stageId: key });
    }
  };

  useEffect(() => {
    dispatch(userActions.fetchUsers());
  }, [dispatch]);

  const setFilterData = (key: string, value: string) => {
    setFilter({ [key]: value });
  };

  return (
    <div className="full-height">
      <PageHeaderTitle title={TEXT.APPLICATIONS} />
      <div className="site-page-header-application">
        <SearchableSelect
          className="workflow-select"
          optionFilter="search_prop"
          defaultValue="all"
          allowClear={false}
          onChange={handleChangeWorkflow}
          dropdownMatchSelectWidth={false}
        >
          <Option key="all" value="all" search_prop="all">
            <b>{ALL_APPLICATIONS}</b>
          </Option>
          {filteredWorkflows &&
            filteredWorkflows.map((item: IWorkflow) => {
              return (
                <Option
                  value={item.id || ''}
                  key={item.id}
                  search_prop={`${item?.country} ${item?.service_name} ${item?.wf_name}`}
                >
                  <div className="product-workflow-options">
                    <b>{item?.wf_name}</b> &bull; {item?.country} &emsp;
                    <small>{item?.service_name}</small>
                  </div>
                </Option>
              );
            })}
        </SearchableSelect>
      </div>
      {stages && stages.length > 0 ? (
        <div className="chrome-tab card-container">
          <Tabs
            type="card"
            className="stage-tab-position"
            activeKey={buttonState.activeKey}
            defaultActiveKey="All"
            onTabClick={(key) => {
              handleTabClick(key);
            }}
          >
            <TabPane key="All" tab="All Stages" />
            {stages.map((item: IApplicationStage) => {
              return <TabPane key={item.id} tab={item.stage} />;
            })}
          </Tabs>
        </div>
      ) : null}

      <ApplicationTable
        getData={getApplications}
        tableName={TEXT.APPLICATIONS}
        filter={filter}
        setFilter={setFilterData}
        isTabChanged={buttonState.isTabChanged}
        tableButtons={
          <>
            <Button
              type={buttonState.myApplications ? 'primary' : 'default'}
              onClick={() =>
                handleTableButtons('myApplications', buttonState.myApplications)
              }
              disabled={buttonState.loading}
            >
              {LABEL.ONLY_MY_APPLICATIONS}
            </Button>
            <Button
              type={buttonState.unassignedApplications ? 'primary' : 'default'}
              onClick={() =>
                handleTableButtons(
                  'unassigned',
                  buttonState?.unassignedApplications
                )
              }
              disabled={buttonState.loading}
            >
              {UNASSIGNED_LABEL}
            </Button>
            <Button
              type={buttonState.showAll ? 'primary' : 'default'}
              onClick={() => handleTableButtons('showAll', buttonState.showAll)}
              disabled={buttonState.loading}
            >
              {LABEL.SHOW_ALL}
            </Button>
          </>
        }
      />
    </div>
  );
};

export { ApplicationListComponent };
