import React from 'react';
import { PersonalDetailsTab } from './personal-detail-tab';
import { AddressDetailsTab } from './address-detail';
import { VisaDetailsTab } from './passport-visa-details';
import { EmergencyContactDetailsTab } from './emergancy-contact-detail';
import { FinancialDetailsTab } from './financial-detail';

const CategoryDetailContent: React.FC<any> = (props) => {
  const { categoryKey, lead } = props;
  const detailContent = () => {
    switch (categoryKey) {
      case 'personal':
        return <PersonalDetailsTab lead={lead} />;
      case 'address':
        return <AddressDetailsTab lead={lead} />;
      case 'passport':
        return <VisaDetailsTab lead={lead} />;
      case 'emergency_contact':
        return (
          <EmergencyContactDetailsTab
            contactDetails={lead?.emergencyContactDetails}
          />
        );
      case 'financial':
        return (
          <FinancialDetailsTab financialDetails={lead?.financialDetails} />
        );
      default:
        return null;
    }
  };
  return detailContent();
};

export { CategoryDetailContent };
