import React from 'react';
import AntSelect, { SelectValue, SelectProps } from 'antd/lib/select';
import { ISearchableSelect } from '@shared-components/models';

const { Option } = AntSelect;
const OptionGroup = AntSelect.OptGroup;

const Select: React.FC<SelectProps<SelectValue>> = ({
  children,
  ...props
}: SelectProps<SelectValue>) => {
  return <AntSelect<SelectValue> {...props}>{children}</AntSelect>;
};

const SearchableSelect: React.FC<ISearchableSelect> = ({
  optionFilter,
  children,
  innerRef,
  ...props
}: ISearchableSelect) => {
  return (
    <AntSelect
      showSearch
      allowClear
      ref={innerRef}
      defaultActiveFirstOption={false}
      optionFilterProp={optionFilter}
      {...props}
    >
      {children}
    </AntSelect>
  );
};

export { Select, Option, OptionGroup, SearchableSelect };
