import { axiosInstance as axios } from './axios';

import { IResponse } from '@shared-components/models';
import { API_URL } from '@moxie/constants';

const getServices = (): Promise<IResponse> => {
  return axios.get(API_URL.SERVICE);
};

const getServiceWorkFlow = (id: string): Promise<IResponse> => {
  return axios.get(`${API_URL.WORKFLOW_TYPE}/${id}${API_URL.SERVICE}`);
};

const getWorkflow = (
  service_id: string,
  country: string
): Promise<IResponse> => {
  return axios.get(`workflows/${service_id}/${country}`);
};

const fetchServiceCountry = (id: string): Promise<IResponse> => {
  return axios.get(`${API_URL.SERVICE}/${id}/country`);
};

export { getServices, getServiceWorkFlow, getWorkflow, fetchServiceCountry };
