import { IResponse } from '@shared-components/models';
import axios from './axios';
import { API_URL } from '@moxie/constants';

const getAllApplications = async (params = ''): Promise<IResponse> => {
  return axios.get(`${API_URL.APPLICATIONS}/all/${params}`);
};

const postApplicationComment = async (body: {
  comment: string;
  application_id: string;
  created_by: string;
}): Promise<IResponse> => {
  return axios.post(`${API_URL.APPLICATIONS}/comment`, body);
};

const getOneApplicationComments = async (
  id: string | undefined
): Promise<IResponse> => {
  return axios.get(`${API_URL.APPLICATIONS}/many/comment/${id}`);
};

const getApplicationActivities = async (id: string) => {
  return axios.get(`${API_URL.APPLICATIONS}/${id}/activities`);
};

const bulkDiscontinueApplications = async (ids: string[]) => {
  return axios.put(`${API_URL.APPLICATIONS}/bulk-discontinue`, { ids });
};

export {
  getAllApplications,
  postApplicationComment,
  getOneApplicationComments,
  getApplicationActivities,
  bulkDiscontinueApplications,
};
