import React from 'react';
import { IEditDeleteButtonProps } from '@shared-components/models';
import { Button } from '../button/button';
import { DeleteOutlined, EditOutlined } from '@ant-design/icons';
import { TEXT } from '@moxie/constants';

const EditDeleteButton: React.FC<IEditDeleteButtonProps> = ({
  onEdit,
  onDelete,
  item,
  canDelete, 
  canUpdate
}: IEditDeleteButtonProps) => {
  return (
    <div>
      {canUpdate && <Button
        onClick={onEdit}
        type="link"
        size="large"
        icon={<EditOutlined />}
        className="color-sky-blue"
      />}
      {(item?.fee_name !== TEXT.DEFAULT_FEE && canDelete) && (
        <Button
          onClick={onDelete}
          size="large"
          type="link"
          icon={<DeleteOutlined />}
          danger
        />
      )}
    </div>
  );
};

export { EditDeleteButton };
