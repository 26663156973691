import { getOfficeList } from "@crm/services.api";
import { errorNotificationHandler } from "@moxie/shared";
import { useQuery } from "@tanstack/react-query";

export const useFetchAllBranches = () => {
  const { data, isLoading } = useQuery({
    queryKey: ['all-branches'],
    queryFn: () => getOfficeList({}),
    onError: () => errorNotificationHandler('Error fetching branches'),
  });

  return {
    branches: data?.data?.data ?? [],
    isLoading
  }
}
