import React, { Key, useEffect, useState } from 'react';
import { Col, List, Row, Select, Space } from 'antd';
import {
  DocumentFileIcons,
  DocumentNameEditCard,
  getExtension,
  getFileName,
} from '@moxie/shared';
import { EditOutlined, MinusCircleTwoTone } from '@ant-design/icons';
import { PLACEHOLDER } from '@moxie/constants';
import {
  IChecklistData,
  IDocNameForm,
  IDocumentItem,
  IFileNameComponent,
} from '@shared-components/models';
import { useForm } from 'antd/lib/form/Form';
import { useAppSelector } from '@lyra/core';
import _ from 'lodash';

const FileNameComponent: React.FC<IFileNameComponent> = ({
  item,
  documents,
  onSetDocument,
  onRemove,
  onTypeChange,
  showType = true,
}: IFileNameComponent) => {
  const [isEdit, setIsEdit] = useState(false);
  const [form] = useForm();

  const handleSubmit = async (data: IDocNameForm) => {
    const [filterDocument] = await Promise.all([
      documents.filter((document: IDocumentItem) => {
        const newDocument = document;
        if (item.id) {
          if (document?.id === item?.id) {
            newDocument.fileName = data.fileName;
          }
        } else if (document?.uid === item?.uid) {
          newDocument.fileName = data.fileName;
          newDocument.extension = getExtension(item.name);
        }
        return newDocument;
      }),
    ]);
    setIsEdit(false);
    onSetDocument(filterDocument);
  };
  const allChecklists = useAppSelector(
    (state) => state.checklist?.documentChecklist
  );
  const [allChecklistArray, setAllChecklistArray] = useState<
    IChecklistData[]
  >();

  const handleCancel = () => {
    setIsEdit(false);
  };

  const handleEdit = () => {
    setIsEdit(true);
  };
  const { Option, OptGroup } = Select;
  const groupedData: any = [];

  useEffect(() => {
    const groupData = _.groupBy(allChecklists, 'type');
    groupedData.push(groupData);
    setAllChecklistArray(groupedData[0]);
  }, [allChecklists]);

  return (
    <List.Item key={item?.uid}>
      <List.Item.Meta
        avatar={<DocumentFileIcons mimetype={item?.type || item?.mimetype} />}
        title={
          <Space>
            {isEdit ? (
              <DocumentNameEditCard
                handleSubmit={handleSubmit}
                handleCancel={handleCancel}
                form={form}
                documentData={item}
              />
            ) : (
              <Row>
                <Col>
                  <div className="margin-top-1 document-file-name ">
                    {item?.fileName || getFileName(item?.name) || ''}
                  </div>
                </Col>
                <Col>
                  <EditOutlined
                    className="document-edit-btn margin-top-1 margin-left-1"
                    onClick={handleEdit}
                    title="Edit"
                  />
                </Col>
              </Row>
            )}
          </Space>
        }
      />
      <Space>
        {showType ? (
          <Select
            className="custom__select_width"
            placeholder={PLACEHOLDER.DOCUMENT_TYPE}
            onChange={(value: string) => onTypeChange?.(item, value)}
            value={item.documentType}
          >
            {allChecklistArray &&
              Object.entries(allChecklistArray).map(
                (item: any, index: number) => (
                  <OptGroup label={item[0]} key={index}>
                    {item[1]?.map(
                      (pos: { id: Key; name: string; type: string }) => {
                        return (
                          <Option
                            value={pos?.type + '<' + pos?.name}
                            key={pos?.name}
                            className="document-option-type"
                          >
                            {pos.name}
                          </Option>
                        );
                      }
                    )}
                  </OptGroup>
                )
              )}
          </Select>
        ) : null}
        {!isEdit ? (
          <>
            <MinusCircleTwoTone
              twoToneColor="red"
              className="action__button__icon margin-left-1"
              onClick={() => {
                onRemove(item);
              }}
              title="Remove"
            />
          </>
        ) : null}
      </Space>
    </List.Item>
  );
};

export { FileNameComponent };
