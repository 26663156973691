import React from 'react';
import { TEXT } from '@moxie/constants';
import { Button, Table } from '@moxie/shared';
import { ContactReportTableColumns } from './table-columns';

const ContactReportTable = ({
  getData,
  tableName,
  filter,
  setFilter,
  handleExport,
}: any) => {
  return (
    <div className="bg-white padding-top-2">
      <Table
        action={getData}
        columns={ContactReportTableColumns(setFilter)}
        tableName={tableName}
        filter={filter}
        filterOptions={true}
        scroll={window.screen.height < 800 ? { y: 500 } : { y: undefined }}
        tableButtons={
          <Button type="primary" onClick={() => handleExport()}>
            {TEXT.EXPORT}
          </Button>
        }
      />
    </div>
  );
};

export { ContactReportTable };
