/* eslint-disable react-hooks/rules-of-hooks */
import React from 'react';
import { ApplicationStatus, Table } from '@moxie/shared';
import { APPLICATION_STATUS, COLUMNS, LABEL, TEXT } from '@moxie/constants';
import {
  IActiveStage,
  ILeadApplication,
  IService,
  ITableProps,
  IWorkflow,
} from '@shared-components/models';
import { ColumnsType } from 'antd/lib/table';
import { TableClientApplicationNameCard } from './client-application-name-card';
import { AdminApplicationAssignee } from './admin-application-assignee';
import { Link } from 'react-router-dom';
import { Button } from 'antd';
import { BlockOutlined } from '@ant-design/icons';

const ClientApplicationTable: React.FC<ITableProps> = ({
  getData,
  tableName,
  filter,
  refreshTable,
  setRefreshTable,
  tableButtons,
}: ITableProps) => {
  const columns = (): ColumnsType<any> => {
    return [
      {
        title: TEXT.ID,
        dataIndex: 'id',
        key: 'id',
        width: 100,
        ellipsis: true,
        render(id: string, data: any) {
          return (
            <Link
              to={`/contact/detail/${data?.contact_id}/applications/${id}`}
              className="primary-id-color"
            >
              <Button
                type="primary"
                icon={<BlockOutlined />}
                className="view_application"
              />
              {data?.company?.company_code + '-' + data?.app_identifier}
            </Link>
          );
        },
      },

      {
        title: LABEL.PRODUCT_INSTITUTION_NAME,
        width: 300,
        ellipsis: true,
        sorter: () => 0,
        render(data: ILeadApplication) {
          return <TableClientApplicationNameCard data={data} />;
        },
      },
      {
        title: LABEL.SERVICE,
        width: 200,
        dataIndex: ['workflow', 'workflowType', 'service'],
        sorter: () => 0,
        render(data: IService) {
          return data?.name;
        },
      },
      {
        title: COLUMNS.ADMIN_ASSIGNEE,
        dataIndex: 'admin_clients',
        render(text: any, data: any): React.ReactElement | any {
          return (
            <AdminApplicationAssignee
              data={data}
              refreshTable={refreshTable}
              setRefreshTable={setRefreshTable}
            />
          );
        },
      },
      {
        title: LABEL.WORKFLOW,
        width: 200,
        render({ workflow }: { workflow: IWorkflow }) {
          return (
            <>
              <div>{workflow?.workflowType?.name}</div>
              <span className="table-mail-icon text-icon">
                {workflow?.country}
              </span>
            </>
          );
        },
      },
      {
        title: LABEL.CURRENT_STAGE,
        width: 200,
        render({
          activeStage,
          status,
        }: {
          activeStage: IActiveStage;
          status: string;
        }) {
          return status === APPLICATION_STATUS.COMPLETED
            ? TEXT.COMPLETE
            : activeStage?.stage;
        },
      },
      {
        title: LABEL.STATUS,
        width: 200,
        render({ status }: { status: string }) {
          return <ApplicationStatus status={status} />;
        },
      },
    ];
  };

  return (
    <Table
      tableName={tableName}
      columns={columns()}
      action={getData}
      refresh={refreshTable}
      filter={filter}
      filterOptions={true}
      tableButtons={tableButtons}
    />
  );
};

export default ClientApplicationTable;
