import { AxiosResponse } from 'axios';
import { put, takeLatest } from 'redux-saga/effects';
import * as institutionAction from './institution.action';
import * as institution from './institution.constant';
import { getAllInstitutes, instituteServices } from '@lyra/services.api';
import { errorHandler } from '@moxie/utils';
import { IAction } from '@shared-components/models';
import {
  saveInstitution,
  updateInstitution,
  deleteInstitution,
} from '../../libs/services.api/lib/institution.api';
import { successNotificationHandler } from '@moxie/shared';

export function* getInstitutions(): any {
  try {
    const res: AxiosResponse = yield getAllInstitutes();
    yield put(institutionAction.getInstitutionsSuccess(res.data.data));
  } catch (e) {
    errorHandler(e);
    yield put(institutionAction.getInstitutionsFailure(e));
  }
}

export function* getInstitution({ payload }: IAction<any>) {
  try {
    if (payload) {
      const response: AxiosResponse = yield instituteServices.apiGetInstituteDetail(
        payload
      );
      yield put(institutionAction.getInstitutionSuccess(response.data.data));
    }
  } catch (e) {
    errorHandler(e);
    yield put(institutionAction.getInstitutionFailure(e));
  }
}

export function* addInstitution({ payload, triggerRefresh }: IAction<any>) {
  try {
    if (payload) {
      const response: AxiosResponse = yield saveInstitution(payload);
      yield put(institutionAction.getInstitutionSuccess(response?.data?.data));
      successNotificationHandler(response?.data?.message);
    }
    triggerRefresh && triggerRefresh();
  } catch (e) {
    errorHandler(e);
    yield put(institutionAction.addInstitutionFailure(e));
  }
}

export function* institutionUpdate({
  payload,
  triggerRefresh,
  onClose,
  clearFetchInstitution,
}: IAction<any>) {
  try {
    if (payload) {
      const response: AxiosResponse = yield updateInstitution(payload);
      yield put(
        institutionAction.updateInstitutionSuccess(response?.data?.data)
      );
      successNotificationHandler(response?.data?.message);
      onClose && onClose();
      if (clearFetchInstitution)
        yield put(institutionAction.clearFetchInstitution());
    }
    triggerRefresh && triggerRefresh();
  } catch (e) {
    errorHandler(e);
    yield put(institutionAction.updateInstitutionFailure(e));
  }
}

export function* removeInstitution({ payload, triggerRefresh }: IAction<any>) {
  try {
    if (payload) {
      const response: AxiosResponse = yield deleteInstitution(payload);
      yield put(
        institutionAction.deleteInstitutionSuccess(response?.data?.data)
      );
      successNotificationHandler(response?.data?.message);
    }
    triggerRefresh && triggerRefresh();
  } catch (e) {
    errorHandler(e);
    yield put(institutionAction.deleteInstitutionFailure(e));
  }
}

export default function* institutionSaga(): unknown {
  yield takeLatest(institution.GET_INSTITUTIONS_REQUEST, getInstitutions);
  yield takeLatest(institution.GET_INSTITUTION_REQUEST, getInstitution);
  yield takeLatest(institution.ADD_INSTITUTION_REQUEST, addInstitution);
  yield takeLatest(institution.UPDATE_INSTITUTION_REQUEST, institutionUpdate);
  yield takeLatest(institution.DELETE_INSTITUTION_REQUEST, removeInstitution);
}
