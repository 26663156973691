import { all, AllEffect, fork, ForkEffect } from 'redux-saga/effects';
import themeSagas from '../theme/theme.saga';
import authSaga from '../auth/auth.saga';
import applicationSaga from '../application/application.saga';
import productSagas from '../products/products.saga';
import workflowTypeSaga from '../workflow/workflow-type.saga';
import institutionSaga from '../institutions/institution.saga';
import workflowSaga from '../workflow/workflow.saga';
import userSaga from '../users/user.saga';
import officeBranchSaga from '../office-branch/office-branch.saga';
import rolePermissionSaga from '../role_permissions/role-permission.saga';
// import leadSagas from 'apps/lyra/src/core/lead/lead.saga';
import settingOtherDetailsSaga from 'apps/lyra/src/core/setting-other-details/setting-other-details.saga';
import professionalExperienceSagas from 'apps/lyra/src/core/professional-experience/professional-experience.saga';
import otherTestScoreSaga from 'apps/lyra/src/core/other-test-score/other-test-score.saga';
import languageTestScoreSaga from 'apps/lyra/src/core/language-test-score/language-test-score.saga';
import educationBackgroundSagas from 'apps/lyra/src/core/education-background/education-background.saga';
import clientSaga from '../client/client.saga';
import ClientActivitiesSagas from '../client-activities/client-activities.saga';
import NotificationSagas from '../notification/notifications.saga';
import TenantSagas from '../tenant/tenant.saga';
export default function* rootSaga(): Generator<
  AllEffect<ForkEffect<unknown>>,
  void,
  unknown
> {
  yield all(
    [
      authSaga,
      themeSagas,
      applicationSaga,
      productSagas,
      workflowTypeSaga,
      institutionSaga,
      workflowSaga,
      userSaga,
      officeBranchSaga,
      rolePermissionSaga,
      settingOtherDetailsSaga,
      // leadSagas,
      professionalExperienceSagas,
      otherTestScoreSaga,
      languageTestScoreSaga,
      educationBackgroundSagas,
      clientSaga,
      NotificationSagas,
      ClientActivitiesSagas,
      TenantSagas,
    ].map(fork)
  );
}
