import {
  IAction,
  IInterestedService,
  ILead,
  IUser,
} from '@shared-components/models';
import { Socket } from 'socket.io-client';
import {
  DELETE_INTERESTED_SERVICE,
  DELETE_INTERESTED_SERVICE_REQUEST,
  FETCH_INTERESTED_SERVICES,
  FETCH_INTERESTED_SERVICES_REQUEST,
  IDLE_INTERESTED_SERVICE,
  LOADING_INTERESTED_SERVICE,
  POST_INTERESTED_SERVICE,
  POST_INTERESTED_SERVICE_REQUEST,
  POST_PRODUCT_AS_INTERESTED_SERVICE_REQUEST,
  PRODUCT_SUCCEEDED_INTERESTED_SERVICE,
  SET_ERROR_INTERESTED_SERVICES,
  SUCCEEDED_INTERESTED_SERVICE,
  UPDATE_INTERESTED_SERVICE,
  UPDATE_INTERESTED_SERVICE_REQUEST,
} from './interested-services.constant';

export const fetchInterestedServices = (
  payload: IInterestedService[]
): IAction<IInterestedService[]> => ({
  type: FETCH_INTERESTED_SERVICES,
  payload,
});

export const fetchInterestedServiceRequest = (
  payload: string
): IAction<string> => ({
  type: FETCH_INTERESTED_SERVICES_REQUEST,
  payload,
});

export const addInterestedServiceRequest = (
  payload: IInterestedService,
  user: IUser | null,
  socket: Socket | null,
  lead: ILead
): IAction<IInterestedService> => ({
  type: POST_INTERESTED_SERVICE_REQUEST,
  payload,
  user,
  socket,
  lead,
});

export const addProductAsInterestedServiceRequest = (payload: {
  productId: string;
  workflowId: string | undefined;
  institutionBranchId: string | undefined;
  leadData: any;
  socket: Socket | null;
  user: any;
}): {
  payload: {
    productId: string;
    workflowId: string | undefined;
    institutionBranchId: string | undefined;
    leadData: any;
    socket: Socket | null;
    user: any;
  };
  type: string;
} => ({
  type: POST_PRODUCT_AS_INTERESTED_SERVICE_REQUEST,
  payload,
});

export const updateInterestedServiceRequest = (
  payload: IInterestedService,
  user: IUser | null,
  socket: Socket | null,
  lead: ILead
): IAction<IInterestedService> => ({
  type: UPDATE_INTERESTED_SERVICE_REQUEST,
  payload,
  user,
  socket,
  lead,
});

export const deleteInterestedServiceRequest = (
  payload: string,
  user: IUser | null,
  socket: Socket | null,
  lead: ILead
): IAction<string> => ({
  type: DELETE_INTERESTED_SERVICE_REQUEST,
  payload,
  user,
  socket,
  lead,
});

export const deleteInterestedService = () => ({
  type: DELETE_INTERESTED_SERVICE,
});

export const addInterestedService = () => ({
  type: POST_INTERESTED_SERVICE,
});

export const updateInterestedService = () => ({
  type: UPDATE_INTERESTED_SERVICE,
});

export const setLoadingInterestedServices = () => ({
  type: LOADING_INTERESTED_SERVICE,
});

export const setSuccessInterestedService = () => ({
  type: SUCCEEDED_INTERESTED_SERVICE,
});

export const setProductSuccessInterestedService = () => ({
  type: PRODUCT_SUCCEEDED_INTERESTED_SERVICE,
});

export const setIdleInterestedServices = () => ({
  type: IDLE_INTERESTED_SERVICE,
});

export const setError = () => ({
  type: SET_ERROR_INTERESTED_SERVICES,
});
