const alias = 'CLIENT';

export const GET_CLIENTS_REQUEST = `GET_${alias}S_REQUEST`;
export const GET_CLIENTS_SUCCESS = `GET_${alias}S_SUCCESS`;
export const GET_CLIENTS_FAILURE = `GET_${alias}S_FAILURE`;

export const GET_CLIENT_REQUEST = `GET_${alias}_REQUEST`;
export const GET_CLIENT_SUCCESS = `GET_${alias}_SUCCESS`;
export const GET_CLIENT_FAILURE = `GET_${alias}_FAILURE`;
export const CLEAR_CLIENT = `CLEAR_${alias}`;

export const SET_REDIRECT = `SET_REDIRECT`;
export const SET_ERROR = `SET_ERROR`;
export const DOWNLOADING = `DOWNLOADING`;
