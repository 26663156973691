import { Collapse, Rate, Row } from 'antd';
import moment from 'moment';
import React from 'react';


interface Props {
  name: string;
  createdAt: string;
  data: {
    currentRating: number;
    previousRating: number;
  };
}

const { Panel } = Collapse;

export const EnquiryRatingActivity: React.FC<Props> = ({
  name,
  createdAt,
  data,
}) => {
  const { currentRating, previousRating } = data;

  return (
    <>
      <Collapse
        defaultActiveKey={[]}
        expandIconPosition={'left'}
        accordion={true}
      >
        <Panel
          header={(
            <div className="enquiry-info-interested-services" style={{ position: "absolute", top: "7px", left: "3%" }} >
              <div className="enquiry-author"> <span style={{ fontWeight: 'bold', fontSize: '14px' }}>Rating</span>&nbsp;changed</div>
              <div className="enquiry-date" style={{ position: "absolute", right: "3%", top: "50%", transform: "translate(-15px,-50%)" }}>
                <div>{moment(createdAt).format('dddd, MMMM D, YYYY')}</div>
                <div>by {name}</div>
              </div>
            </div>
          )}
          key="1">
          <Row gutter={4} align="middle">
            <Rate
              disabled
              defaultValue={previousRating}
            />
            &nbsp; to &nbsp;
            <Rate
              disabled
              defaultValue={currentRating}
            />
          </Row>
        </Panel >
      </Collapse >
    </>
  );
};
