import { IAction, ICrmEducation, ICrmProfessionalExperience, IOfficeBranchCRM } from '@shared-components/models';
import * as actionTypes from './contact.constant';
import { IUserData } from 'libs/shared/src/crm-modules/user/user.model';
import { IContactCrm } from '@model/crm/contact.model';

// TODO:: need to add more types
interface ContactState {
  singleData: IContactCrm,
  allData: IContactCrm[],
  loading: boolean,
  error: null,
  assigneeData?: IUserData | undefined,
  followersData: IUserData[],
  followersId: string[],
  contactDetail: IContactCrm,
  educationBackground: ICrmEducation[],
  eduBackgroundLoading: boolean,
  profExpLoading: boolean,
  profExpData: ICrmProfessionalExperience[],
  activities: any[],
  branch: IOfficeBranchCRM;
  secondaryBranches: IOfficeBranchCRM[];
}
const initialContactState: ContactState = {
  singleData: {
    id: '',
    firstName: '',
    lastName: '',
    email: '',
  },
  allData: [],
  loading: false,
  error: null,
  assigneeData: undefined,
  followersData: [],
  followersId: [],
  contactDetail: {
    id: '',
    firstName: '',
    lastName: '',
    email: '',
  },
  educationBackground: [],
  eduBackgroundLoading: false,
  profExpLoading: false,
  profExpData: [],
  activities: [],
  branch: {
    id: '',
    name: '',
    email: '',
  },
  secondaryBranches: [],
};

export const CONTACT_FEATURE_KEY = 'contact';

export const contactReducer = (
  state = initialContactState,
  action: IAction<any>
): ContactState => {
  switch (action.type) {
    case actionTypes.GET_CONTACT_DETAILS_REQUEST:
      return {
        ...state,
        loading: true,
        error: null,
      };
    case actionTypes.GET_CONTACT_DETAILS_SUCCESS:
      return {
        ...state,
        loading: false,
        error: null,
        singleData: action.payload,
        assigneeData: action.payload.assignee,
        followersData: action.payload.followers,
        followersId: action.payload.followers.map((follower: IUserData) => follower.id),
        branch: action.payload.branch,
        secondaryBranches: action.payload.secondaryBranches,
      };
    case actionTypes.GET_CONTACT_DETAILS_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.payload,
      };
    case actionTypes.UPDATE_CONTACT_REQUEST:
      return {
        ...state,
        loading: true,
        error: null,
      };
    case actionTypes.UPDATE_CONTACT_SUCCESS:
      return {
        ...state,
        loading: false,
        error: null,
      };
    case actionTypes.UPDATE_CONTACT_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.payload,
      };
    case actionTypes.UPDATE_CONTACT_ASSIGNEE_REQUEST:
      return {
        ...state,
        loading: true,
        error: null,
      };
    case actionTypes.UPDATE_CONTACT_ASSIGNEE_SUCCESS:
      return {
        ...state,
        loading: false,
        error: null,
        assigneeData: action.payload.crm_assignee,
      };
    case actionTypes.UPDATE_CONTACT_ASSIGNEE_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.payload,
      };
    case actionTypes.UPDATE_CONTACT_PERSONAL_INFO_REQUEST:
      return {
        ...state,
        loading: true,
        error: null,
      };
    case actionTypes.UPDATE_CONTACT_PERSONAL_INFO_SUCCESS:
      return {
        ...state,
        loading: false,
        error: null,
        contactDetail: action.payload,
      };
    case actionTypes.UPDATE_CONTACT_PERSONAL_INFO_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.payload,
      };
    case actionTypes.UPDATE_CONTACT_ADDRESS_INFO_REQUEST:
      return {
        ...state,
        loading: true,
        error: null,
      };
    case actionTypes.UPDATE_CONTACT_ADDRESS_INFO_SUCCESS:
      return {
        ...state,
        loading: false,
        error: null,
      };
    case actionTypes.UPDATE_CONTACT_ADDRESS_INFO_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.payload,
      };
    case actionTypes.UPDATE_CONTACT_PASSPORT_VISA_INFO_REQUEST:
      return {
        ...state,
        loading: true,
        error: null,
      };
    case actionTypes.UPDATE_CONTACT_PASSPORT_VISA_INFO_SUCCESS:
      return {
        ...state,
        loading: false,
        error: null,
      };
    case actionTypes.UPDATE_CONTACT_PASSPORT_VISA_INFO_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.payload,
      };

    case actionTypes.UPDATE_CONTACT_EMERGENCY_DETAILS_REQUEST:
      return {
        ...state,
        loading: true,
        error: null,
      };
    case actionTypes.UPDATE_CONTACT_EMERGENCY_DETAILS_SUCCESS:
      return {
        ...state,
        loading: false,
        error: null,
      };
    case actionTypes.UPDATE_CONTACT_EMERGENCY_DETAILS_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.payload,
      };
    case actionTypes.UPDATE_CONTACT_FOLLOWERS_REQUEST:
      return {
        ...state,
        loading: true,
        error: null,
      };
    case actionTypes.UPDATE_CONTACT_FOLLOWERS_SUCCESS:
      return {
        ...state,
        loading: false,
        error: null,
      };
    case actionTypes.UPDATE_CONTACT_FOLLOWERS_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.payload,
      };
    case actionTypes.CLEAR_CONTACT_FETCH:
      return {
        ...state,
        loading: false,
        error: null,
        singleData: {
          id: '',
          firstName: '',
          lastName: '',
          email: '',
        },
        allData: [],
        assigneeData: undefined,
        followersData: [],
        followersId: [],
      };
    case actionTypes.ADD_CONTACT_EDUCATION_BACKGROUND_REQUEST:
      return {
        ...state,
        eduBackgroundLoading: true,
        error: null,
      };
    case actionTypes.ADD_CONTACT_EDUCATION_BACKGROUND_SUCCESS:
      return {
        ...state,
        eduBackgroundLoading: false,
        error: null,
      };
    case actionTypes.ADD_CONTACT_EDUCATION_BACKGROUND_FAILURE:
      return {
        ...state,
        eduBackgroundLoading: false,
        error: action.payload,
      };
    case actionTypes.GET_CONTACT_EDUCATION_BACKGROUND_REQUEST:
      return {
        ...state,
        eduBackgroundLoading: true,
        error: null,
      };
    case actionTypes.GET_CONTACT_EDUCATION_BACKGROUND_SUCCESS:
      return {
        ...state,
        eduBackgroundLoading: false,
        error: null,
        educationBackground: action.payload,
      };
    case actionTypes.GET_CONTACT_EDUCATION_BACKGROUND_FAILURE:
      return {
        ...state,
        eduBackgroundLoading: false,
        error: action.payload,
      };
    case actionTypes.UPDATE_CONTACT_EDUCATION_BACKGROUND_REQUEST:
      return {
        ...state,
        eduBackgroundLoading: true,
        error: null,
      };
    case actionTypes.UPDATE_CONTACT_EDUCATION_BACKGROUND_SUCCESS:
      return {
        ...state,
        eduBackgroundLoading: false,
        error: null,
      };
    case actionTypes.UPDATE_CONTACT_EDUCATION_BACKGROUND_FAILURE:
      return {
        ...state,
        eduBackgroundLoading: false,
        error: action.payload,
      };
    case actionTypes.DELETE_CONTACT_EDUCATION_BACKGROUND_REQUEST:
      return {
        ...state,
        eduBackgroundLoading: true,
        error: null,
      };
    case actionTypes.DELETE_CONTACT_EDUCATION_BACKGROUND_SUCCESS:
      return {
        ...state,
        eduBackgroundLoading: false,
        error: null,
      };
    case actionTypes.DELETE_CONTACT_EDUCATION_BACKGROUND_FAILURE:
      return {
        ...state,
        eduBackgroundLoading: false,
        error: action.payload,
      };
    case actionTypes.CLEAR_CONTACT_EDUCATION_BACKGROUND_FETCH:
      return {
        ...state,
        eduBackgroundLoading: false,
        error: null,
        educationBackground: [],
      };
    case actionTypes.ADD_CONTACT_PROFESSIONAL_EXPERIENCE_REQUEST:
      return {
        ...state,
        profExpLoading: true,
        error: null,
      };
    case actionTypes.ADD_CONTACT_PROFESSIONAL_EXPERIENCE_SUCCESS:
      return {
        ...state,
        profExpLoading: false,
        error: null,
      };
    case actionTypes.ADD_CONTACT_PROFESSIONAL_EXPERIENCE_FAILURE:
      return {
        ...state,
        profExpLoading: false,
        error: action.payload,
      };
    case actionTypes.GET_CONTACT_PROFESSIONAL_EXPERIENCE_REQUEST:
      return {
        ...state,
        profExpLoading: true,
        error: null,
      };
    case actionTypes.GET_CONTACT_PROFESSIONAL_EXPERIENCE_SUCCESS:
      return {
        ...state,
        profExpData: action.payload,
        profExpLoading: false,
        error: null,
      };
    case actionTypes.GET_CONTACT_PROFESSIONAL_EXPERIENCE_FAILURE:
      return {
        ...state,
        profExpLoading: false,
        error: action.payload,
      };
    case actionTypes.UPDATE_CONTACT_PROFESSIONAL_EXPERIENCE_REQUEST:
      return {
        ...state,
        profExpLoading: true,
        error: null,
      };
    case actionTypes.UPDATE_CONTACT_PROFESSIONAL_EXPERIENCE_SUCCESS:
      return {
        ...state,
        profExpLoading: false,
        error: null,
      };
    case actionTypes.UPDATE_CONTACT_PROFESSIONAL_EXPERIENCE_FAILURE:
      return {
        ...state,
        profExpLoading: false,
        error: action.payload,
      };
    case actionTypes.DELETE_CONTACT_PROFESSIONAL_EXPERIENCE_REQUEST:
      return {
        ...state,
        profExpLoading: true,
        error: null,
      };
    case actionTypes.DELETE_CONTACT_PROFESSIONAL_EXPERIENCE_SUCCESS:
      return {
        ...state,
        profExpLoading: false,
        error: null,
      };
    case actionTypes.DELETE_CONTACT_PROFESSIONAL_EXPERIENCE_FAILURE:
      return {
        ...state,
        profExpLoading: false,
        error: action.payload,
      };
    case actionTypes.CLEAR_CONTACT_PROFESSIONAL_EXPERIENCE_FETCH:
      return {
        ...state,
        profExpLoading: false,
        profExpData: [],
        error: null,
      };
    case actionTypes.ARCHIVE_CLIENT_REQUEST:
      return {
        ...state,
        loading: true,
        error: null,
      };
    case actionTypes.ARCHIVE_CLIENT_SUCCESS:
      return {
        ...state,
        loading: false,
        error: null,
      };
    case actionTypes.ARCHIVE_CLIENT_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.payload,
      };
    case actionTypes.UNARCHIVE_CLIENT_REQUEST:
      return {
        ...state,
        loading: true,
        error: null,
      };
    case actionTypes.UNARCHIVE_CLIENT_SUCCESS:
      return {
        ...state,
        loading: false,
        error: null,
      };
    case actionTypes.UNARCHIVE_CLIENT_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.payload,
      };
    case actionTypes.UPDATE_CLIENT_REQUEST:
      return {
        ...state,
        loading: true,
        error: null,
      };
    case actionTypes.UPDATE_CLIENT_SUCCESS:
      return {
        ...state,
        loading: false,
        error: null,
      };
    case actionTypes.UPDATE_CLIENT_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.payload,
      };
    case actionTypes.DELETE_CLIENT_SUCCESS:
      return {
        ...state,
        loading: false,
        error: null,
      };
    case actionTypes.DELETE_CLIENT_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.payload,
      };

    default:
      return state;
  }
};
