import { PlusOutlined } from '@ant-design/icons';
import {
  TITLE,
  FIRST_NAME_LABEL,
  FIRST_NAME_PLACEHOLDER,
  LAST_NAME_LABEL,
  LAST_NAME_PLACEHOLDER,
  LABEL,
  DATE_OF_BIRTH_PLACEHOLDER,
  regex,
  AUTH_MESSAGE,
} from '@moxie/constants';
import { FormItem, Input } from '@shared-components/elements';
import { ILeadPersonalDetailsProps } from '@shared-components/models';
import { Row, Col, DatePicker, Divider } from 'antd';
import Title from 'antd/lib/typography/Title';
import Dragger from 'antd/lib/upload/Dragger';
import React from 'react';
import { disableFutureDate } from '@moxie/shared';
const LeadFormPersonalDetails: React.FC<ILeadPersonalDetailsProps> = ({
  personalDetailsData,
}: ILeadPersonalDetailsProps) => {
  return (
    <div className="created-leadForm-section ">
      <Title level={4}>{TITLE.PERSONAL_DETAILS}</Title>
      <Row gutter={24}>
        {personalDetailsData?.profile_image && (
          <Col md={4} xs={20}>
            <Dragger accept=".jpg, .jpeg, .png">
              <p className="ant-upload-drag-icon">
                <PlusOutlined />
              </p>
              <p className="ant-upload-hint">{TITLE.UPLOAD_LOGO}</p>
            </Dragger>
          </Col>
        )}
        <Col
          span={personalDetailsData?.profile_image ? 20 : 24}
          className="align-vertically-centered"
        >
          <Row gutter={24}>
            {personalDetailsData?.first_name && (
              <Col md={12} xs={24}>
                <FormItem
                  name="first_name"
                  label={FIRST_NAME_LABEL}
                  rules={[
                    { required: true },
                    {
                      pattern: new RegExp(regex.ONLY_STRING),
                      message: AUTH_MESSAGE.INVALID_FIRST_NAME,
                      type: 'string',
                    },
                    {
                      max: 50,
                    },
                  ]}
                >
                  <Input placeholder={FIRST_NAME_PLACEHOLDER} />
                </FormItem>
              </Col>
            )}
            {personalDetailsData?.last_name && (
              <Col md={12} xs={24}>
                <FormItem
                  name="last_name"
                  label={LAST_NAME_LABEL}
                  rules={[
                    { required: true },
                    {
                      pattern: new RegExp(regex.ONLY_STRING),
                      type: 'string',
                      message: AUTH_MESSAGE.INVALID_LAST_NAME,
                    },
                    { max: 50 },
                  ]}
                >
                  <Input placeholder={LAST_NAME_PLACEHOLDER} />
                </FormItem>
              </Col>
            )}
            {personalDetailsData?.date_of_birth && (
              <Col md={12} xs={24}>
                <FormItem name="date_of_birth" label={LABEL.DATE_OF_BIRTH}>
                  <DatePicker
                    className="full-width"
                    disabledDate={disableFutureDate}
                    placeholder={DATE_OF_BIRTH_PLACEHOLDER}
                  />
                </FormItem>
              </Col>
            )}
          </Row>
        </Col>
      </Row>
      <Divider plain />
    </div>
  );
};
export { LeadFormPersonalDetails };
