import {
  IAuth,
  IAuthResponse,
  IForgetPassword,
  IForgetPasswordResponse,
  IVerifyAccount,
} from '@shared-components/models';
import axios from './axios';
import { API_URL } from '@moxie/constants';

const verifyAdminAccount = (body: IVerifyAccount): Promise<IAuthResponse> => {
  return axios.post(API_URL.ADMIN_EMAIL_VERIFICATION, body);
};

const resendVerifyAdminAccountLink = (
  body: IForgetPassword
): Promise<IAuthResponse> => {
  return axios.post(API_URL.RESEND_ADMIN_EMAIL_VERIFICATION, body);
};

const auth = (body: IAuth): Promise<IAuthResponse> => {
  return axios.post(API_URL.ADMIN_AUTH, body);
};

const forgetPassword = (
  body: IForgetPassword
): Promise<IForgetPasswordResponse> => {
  return axios.post(API_URL.ADMIN_FORGET_PASSWORD, body);
};

export {
  verifyAdminAccount,
  resendVerifyAdminAccountLink,
  forgetPassword,
  auth,
};
