import {
  CANCEL_BTN,
  DRAWER_WIDTH,
  SAVE_BTN,
  TEXT,
  UPDATE_BTN,
} from '@moxie/constants';
import { DrawerElem } from '@shared-components/elements';
import { ITenantDrawer } from '@shared-components/models';
import { Button, Space, Spin, Typography } from 'antd';
import React from 'react';
import TenantForm from './tenant-form';

const { Title } = Typography;
const TenantDrawer: React.FC<ITenantDrawer> = ({
  isOpen,
  showDrawer,
  isEdit,
  form,
  handleSubmit,
  dialCode,
  setDialCode,
  setCompanyDomainValidationStatus,
  companyCodeValidationStatus,
  companyDomainValidationStatus,
  setCompanyCodeValidationStatus,
  emailValidationStatus,
  setEmailValidationStatus,
  handleFieldsChange,
  disableDrawerButton,
  setDisableDrawerButton,
  submitted,
  setSubmitted,
}: ITenantDrawer) => {
  const handleSubmitButtonClick = async () => {
    setSubmitted && setSubmitted(true);
    if (!form.getFieldValue('company_code')) {
      setCompanyCodeValidationStatus('error');
      Promise.resolve(true);
    }
    if (!form.getFieldValue('company_domain')) {
      setCompanyDomainValidationStatus('error');
      Promise.resolve(true);
    }
    if (!form.getFieldValue('email')) {
      setEmailValidationStatus('error');
      Promise.resolve(true);
    }
    setDisableDrawerButton(true);
    try {
      const isValid = await form.validateFields();
      isValid && form.submit();
    } catch {
      setSubmitted && setSubmitted(false);
    }
  };

  return (
    <DrawerElem
      title={
        isEdit ? (
          <Title level={4}>{TEXT.EDIT_TENANT}</Title>
        ) : (
          <Title level={4}>{TEXT.ADD_TENANT}</Title>
        )
      }
      width={DRAWER_WIDTH}
      onClose={showDrawer}
      visible={isOpen}
      footer={
        <div className="padding-bottom-2 text-align-right">
          <Space direction="horizontal">
            <Button className="lead-margin-right" onClick={showDrawer}>
              {CANCEL_BTN}
            </Button>
            <Button
              type="primary"
              onClick={handleSubmitButtonClick}
              disabled={disableDrawerButton}
            >
              {isEdit ? UPDATE_BTN : SAVE_BTN}
            </Button>
          </Space>
        </div>
      }
    >
      <Spin spinning={submitted}>
        <TenantForm
          form={form}
          isEdit={isEdit}
          handleSubmit={handleSubmit}
          dialCode={dialCode}
          setDialCode={setDialCode}
          setCompanyDomainValidationStatus={setCompanyDomainValidationStatus}
          companyCodeValidationStatus={companyCodeValidationStatus}
          companyDomainValidationStatus={companyDomainValidationStatus}
          setCompanyCodeValidationStatus={setCompanyCodeValidationStatus}
          emailValidationStatus={emailValidationStatus}
          setEmailValidationStatus={setEmailValidationStatus}
          handleFieldsChange={handleFieldsChange}
          setDisableDrawerButton={setDisableDrawerButton}
        />
      </Spin>
    </DrawerElem>
  );
};

export default TenantDrawer;
