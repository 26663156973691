import * as actionTypes from './notifications.constant';
import { IAction } from '@shared-components/models';

export const NOTIFICATIONS_FEATURE_KEY = 'notifications';
export interface NotificationState {
  notifications: any[];
  loading: boolean;
  error: null;
}

export const initialNotificationState: NotificationState = {
  notifications: [],
  loading: false,
  error: null,
};

export const NotificationsReducer = (
  state = initialNotificationState,
  action: IAction<any>
) => {
  switch (action.type) {
    case actionTypes.GET_NOTIFICATIONS_REQUEST:
      return {
        ...state,
        loading: true,
      };
    case actionTypes.GET_NOTIFICATIONS_SUCCESS:
      return {
        ...state,
        loading: false,
        error: null,
        notifications: [...action.payload],
      };
    case actionTypes.GET_NOTIFICATIONS_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.payload,
      };
    case actionTypes.GET_NOTIFICATIONS_CLEAR:
      return {
        ...state,
        loading: false,
        error: null,
        notifications: [],
      };
    case actionTypes.ADD_NOTIFICATION_SUCCESS:
      return {
        ...state,
        loading: false,
        error: null,
        notifications: [...action.payload, ...state.notifications],
      };
    case actionTypes.UPDATE_NOTIFICATION_SUCCESS:
      return {
        ...state,
        loading: false,
        error: null,
        notifications: state.notifications.map((item) =>
          item.id === action.payload.data.id ? action.payload.data : item
        ),
      };

    case actionTypes.UPDATE_NOTIFICATION_REQUEST:
      return {
        ...state,
        loading: true,
        error: null,
      };
    case actionTypes.NOTIFICATION_MARK_READ_ALL_SUCCESS:
      return {
        ...state,
        loading: false,
        error: null,
        notifications: action.payload.data,
      };
    default:
      return state;
  }
};
