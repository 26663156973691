export const lead = {
  POST_LEAD_SAGA: 'POST_LEAD_SAGA',
  EDIT_LEAD_SAGA: 'EDIT_LEAD_SAGA',
  DELETE_SINGLE_LEAD_SAGA: 'DELETE_SINGLE_LEAD_SAGA',
  DELETE_MULTIPLE_LEAD_SAGA: 'DELETE_MULTIPLE_LEAD_SAGA',
  ARCHIVE_MULTIPLE_LEAD_SAGA: 'ARCHIVE_MULTIPLE_LEAD_SAGA',
  RESTORE_MULTIPLE_LEAD_SAGA: 'RESTORE_MULTIPLE_LEAD_SAGA',
  ARCHIVE_SUCCESSFULL_MUTIPLE_LEAD_SAGA:
    'SUCCESSFULL_ARCHIVE_MULTIPLE_LEAD_SAGA',
  RESTORE_SUCCESSFULL_MUTIPLE_LEAD_SAGA:
    'SUCCESSFULL_RESTORE_MULTIPLE_LEAD_SAGA',
  SUCCESSFUL_POST_LEAD: 'SUCCESSFUL_POST_LEAD',
  SUCCESSFULL_DELETE_LEAD: 'SUCCESSFULL_DELETE_LEAD',
  SUCCESSFULL_EDIT_LEAD: 'SUCCESSFUL_POST_LEAD',
  ERROR_LEAD: 'ERROR_LEAD',
  TOGGLE_ERROR_STATUS: 'TOGGLE_ERROR_STATUS',
  RESET_ERROR_STATUS: 'RESET_ERROR_STATUS',
  FETCH_LEAD: 'FETCH_LEAD',
};
