import { put, takeLatest } from '@redux-saga/core/effects';
import * as interestedServices from './interested-services.constant';
import * as interestedServiceActions from './interested-services.action';
import { AxiosResponse } from 'axios';
import { errorHandler } from '@moxie/utils';
import {
  deleteInterestedServices,
  getInterestedServices,
  postInterestedServices,
  postProductAsInterestedService,
  putInterestedServices,
} from '@lyra/services.api';
import { IAction, IInterestedService } from '@shared-components/models';
import { successNotificationHandler } from '@moxie/shared';
import { ADD_SUCCESS, DELETE_SUCCESS, UPDATE_SUCCESS } from '@moxie/constants';
import { Socket } from 'socket.io-client';

export function* fetchInterestedService({ payload }: IAction<string>) {
  yield put(interestedServiceActions.setLoadingInterestedServices());
  try {
    const res: AxiosResponse<any> = yield getInterestedServices(payload || '');
    yield put(interestedServiceActions.fetchInterestedServices(res.data.data));
    yield put(interestedServiceActions.setIdleInterestedServices());
  } catch (e) {
    errorHandler(e);
  }
}

export function* updateInterestedService({
  payload,
  user,
  socket,
  lead,
}: IAction<IInterestedService>) {
  if (payload) {
    yield put(interestedServiceActions.setLoadingInterestedServices());
    try {
      const res: AxiosResponse = yield putInterestedServices(payload);
      successNotificationHandler(UPDATE_SUCCESS);
      yield put(interestedServiceActions.setSuccessInterestedService());
      socket?.emit('updated-interested-services-to-server', {
        user_id: user?.id,
        interested_service_id: res?.data?.data?.id,
        parent_id: lead?.id,
        data: res?.data,
        company_id: user?.company?.id,
      });
      socket?.emit('create-interested-service-notification-to-server', {
        user_id: user?.id,
        receiver_id: lead?.assignee_user_id,
        receivers_many: lead?.followers_id,
        type: 'interested-service-updated',
        parent_id: lead?.id,
      });
    } catch (e) {
      errorHandler(e);
      yield put(interestedServiceActions.setIdleInterestedServices());
    }
  }
}

export function* addInterestedService({
  payload,
  user,
  socket,
  lead,
}: IAction<IInterestedService>) {
  if (payload) {
    yield put(interestedServiceActions.setLoadingInterestedServices());
    try {
      const res: AxiosResponse = yield postInterestedServices(payload);

      successNotificationHandler(ADD_SUCCESS);
      yield put(interestedServiceActions.setSuccessInterestedService());
      socket?.emit('added-interested-services-to-server', {
        user_id: user?.id,
        interested_service_id: res?.data?.data?.id,
        parent_id: lead?.id,
        data: res?.data,
        company_id: user?.company?.id,
      });
      socket?.emit('create-interested-service-notification-to-server', {
        user_id: user?.id,
        receiver_id: lead?.assignee_user_id,
        receivers_many: lead?.followers_id?.map((id: string) => id),
        type: 'interested-service-created',
        parent_id: lead?.id,
      });
    } catch (e) {
      errorHandler(e);
      yield put(interestedServiceActions.setIdleInterestedServices());
    }
  }
}

export function* addProductAsInterestedService({
  payload,
}: IAction<{
  productId: string;
  workflowId: string;
  institutionBranchId: string;
  leadData: any;
  socket?: Socket;
  user: any;
}>) {
  if (payload) {
    yield put(interestedServiceActions.setLoadingInterestedServices());
    try {
      const res: AxiosResponse = yield postProductAsInterestedService(
        payload.leadData.id,
        payload.productId,
        payload.workflowId,
        payload.institutionBranchId
      );
      successNotificationHandler(ADD_SUCCESS);
      yield put(interestedServiceActions.setProductSuccessInterestedService());
      payload?.socket?.emit('added-interested-services-to-server', {
        user_id: payload.user?.id,
        interested_service_id: res?.data?.data?.id,
        parent_id: payload.leadData.id,
        data: res?.data,
        company_id: payload.user.company?.id,
      });
      payload?.socket?.emit(
        'create-interested-service-notification-to-server',
        {
          user_id: payload.user.id,
          receiver_id: payload.leadData.assignee_user_id,
          receivers_many: payload.leadData?.followers_id?.map(
            (id: string) => id
          ),
          type: 'interested-service-created',
          parent_id: payload.leadData?.id,
        }
      );
    } catch (e) {
      errorHandler(e);
      yield put(interestedServiceActions.setError());
    }
  }
}
export function* deleteInterestedService({
  payload,
  user,
  socket,
  lead,
}: IAction<string>) {
  if (payload) {
    yield put(interestedServiceActions.setLoadingInterestedServices());

    try {
      const res: AxiosResponse = yield deleteInterestedServices(payload);
      successNotificationHandler(DELETE_SUCCESS);
      yield put(interestedServiceActions.setSuccessInterestedService());
      socket?.emit('deleted-interested-service-to-server', {
        interested_service_id: payload,
        user_id: user?.id,
        data: res.data,
        parent_id: lead?.id,
        company_id: user?.company?.id,
      });
      socket?.emit('create-interested-service-notification-to-server', {
        user_id: user?.id,
        receiver_id: lead?.assignee_user_id,
        receivers_many: lead?.followers_id?.map((id: string) => id),
        type: 'interested-service-deleted',
        parent_id: lead?.id,
      });
    } catch (e) {
      errorHandler(e);
      yield put(interestedServiceActions.setIdleInterestedServices());
    }
  }
}

export default function* rootSaga(): unknown {
  yield takeLatest(
    interestedServices.FETCH_INTERESTED_SERVICES_REQUEST,
    fetchInterestedService
  );
  yield takeLatest(
    interestedServices.POST_INTERESTED_SERVICE_REQUEST,
    addInterestedService
  );
  yield takeLatest(
    interestedServices.DELETE_INTERESTED_SERVICE_REQUEST,
    deleteInterestedService
  );
  yield takeLatest(
    interestedServices.UPDATE_INTERESTED_SERVICE_REQUEST,
    updateInterestedService
  );
  yield takeLatest(
    interestedServices.POST_PRODUCT_AS_INTERESTED_SERVICE_REQUEST,
    addProductAsInterestedService
  );
}
