import React, { useEffect, useState } from 'react';
import { Card, Col, DatePicker, Form, Row, Space } from 'antd';
import moment from 'moment';
import {
  Button,
  disableFutureDate,
  formatDate,
  Option,
  SearchableSelect,
} from '@moxie/shared';
import { useForm } from 'antd/lib/form/Form';
import { IGetPopupContainer, ILead } from '@shared-components/models';
import { useDispatch } from 'react-redux';
import { leadActions, useAppSelector } from '@lyra/core';
import {
  CANCEL_BTN,
  CATEGORY_ITEMS,
  COUNTRIES,
  EDIT_BTN,
  GENDER_ITEMS,
  GUTTER,
  LABEL,
  NOT_AVAILABLE_MESSAGE,
  SAVE_BTN,
} from '@moxie/constants';

const PersonalDetailsTab: React.FC<any> = (props: any) => {
  const { lead } = props;
  const [form] = useForm();
  const [isEdit, setIsEdit] = useState<boolean>(false);
  const dispatch = useDispatch();
  const { user, socket } = useAppSelector((state) => ({
    user: state.auth.user,
    socket: state.socket.wss,
  }));

  const handleFinish = (data: ILead) => {
    form.resetFields();
    dispatch(
      leadActions.editLeadAction(
        { ...data, id: lead.id },
        socket,
        user,
        'personal information',
        'other-details'
      )
    );
    setIsEdit(false);
  };

  useEffect(() => {
    form.setFieldsValue({
      date_of_birth: lead?.date_of_birth ? moment(lead?.date_of_birth) : null,
      country_of_birth: lead?.country_of_birth,
      nationality: lead?.nationality,
      gender: lead?.gender,
    });
  }, [isEdit, lead]);

  return (
    <Card
      title={CATEGORY_ITEMS[0].name}
      extra={[
        isEdit ? (
          <>
            {!lead?.archived && (
              <Space>
                <Button
                  key="save"
                  onClick={() => {
                    form.submit();
                  }}
                  type="primary"
                >
                  {SAVE_BTN}
                </Button>
                <Button
                  key="cancel"
                  onClick={() => {
                    form.resetFields();
                    setIsEdit(false);
                  }}
                >
                  {CANCEL_BTN}
                </Button>
              </Space>
            )}
          </>
        ) : (
          <>
            {!lead?.archived && (
              <Button key="edit" type="primary" onClick={() => setIsEdit(true)}>
                {EDIT_BTN}
              </Button>
            )}
          </>
        ),
      ]}
    >
      <Form
        layout="horizontal"
        form={form}
        onFinish={handleFinish}
        scrollToFirstError={true}
        labelCol={{ span: 10, xl: 9, lg: 6, md: 6 }}
        wrapperCol={{ span: 14, offset: 1 }}
      >
        <Row gutter={GUTTER}>
          <Col span={24} xl={12} xxl={8}>
            <div id="gender" className="relative">
              <Form.Item
                name="gender"
                className="custom_label_style"
                label={LABEL.GENDER}
              >
                {isEdit ? (
                  <SearchableSelect
                    className="note-title-input"
                    allowClear={false}
                    disabled={!isEdit}
                    getPopupContainer={(): IGetPopupContainer =>
                      document.getElementById('gender')
                    }
                  >
                    {GENDER_ITEMS.map((item: string) => {
                      return (
                        <Option key={item} value={item}>
                          {item}
                        </Option>
                      );
                    })}
                  </SearchableSelect>
                ) : lead?.gender ? (
                  lead?.gender
                ) : (
                  NOT_AVAILABLE_MESSAGE
                )}
              </Form.Item>
            </div>
          </Col>
          <Col span={24} md={24} lg={24} xl={12} xxl={8}>
            <div id="date_of_birth" className="relative">
              <Form.Item
                name="date_of_birth"
                className="custom_label_style"
                label={LABEL.DATE_OF_BIRTH}
              >
                {isEdit ? (
                  <DatePicker
                    disabled={!isEdit}
                    disabledDate={disableFutureDate}
                    className="full-width note-title-input"
                  />
                ) : lead?.date_of_birth ? (
                  formatDate(lead?.date_of_birth)
                ) : (
                  NOT_AVAILABLE_MESSAGE
                )}
              </Form.Item>
            </div>
          </Col>
          <Col span={24} md={24} lg={24} xl={12} xxl={8}>
            <div id="country_list" className="relative">
              <Form.Item
                name="country_of_birth"
                className="custom_label_style"
                label={LABEL.COUNTRY_OF_BIRTH}
              >
                {isEdit ? (
                  <SearchableSelect
                    className="note-title-input"
                    disabled={!isEdit}
                    allowClear={false}
                    optionFilter="search_prop"
                    getPopupContainer={(): IGetPopupContainer =>
                      document.getElementById('country_list')
                    }
                  >
                    {COUNTRIES.map((item) => {
                      return (
                        <Option
                          value={item.name}
                          key={item.code}
                          search_prop={`${item.name} ${item.code}`}
                        >
                          {item.name}
                        </Option>
                      );
                    })}
                  </SearchableSelect>
                ) : lead?.country_of_birth ? (
                  lead?.country_of_birth
                ) : (
                  NOT_AVAILABLE_MESSAGE
                )}
              </Form.Item>
            </div>
          </Col>
          <Col span={24} md={24} lg={24} xl={12} xxl={8}>
            <div id="nationality" className="relative">
              <Form.Item
                name="nationality"
                className="custom_label_style"
                label={LABEL.NATIONALITY}
              >
                {isEdit ? (
                  <SearchableSelect
                    className="note-title-input"
                    disabled={!isEdit}
                    allowClear={false}
                    optionFilter="search_prop"
                    getPopupContainer={(): IGetPopupContainer =>
                      document.getElementById('nationality')
                    }
                  >
                    {COUNTRIES.map((item) => {
                      return (
                        <Option
                          value={item.nationality}
                          key={item.code}
                          search_prop={`${item.nationality} ${item.code} ${item.nationality}`}
                        >
                          {item.nationality}
                        </Option>
                      );
                    })}
                  </SearchableSelect>
                ) : lead?.nationality ? (
                  lead?.nationality
                ) : (
                  NOT_AVAILABLE_MESSAGE
                )}
              </Form.Item>
            </div>
          </Col>
        </Row>
      </Form>
    </Card>
  );
};

export { PersonalDetailsTab };
