import React from 'react';
import { PriceCard } from '@shared-components/elements';
import { Row, Col } from 'antd';
import { PRICE_PLANS } from './libs/constants';
import { IPricePlan } from '@shared-components/models';

const PricePlan: React.FC<IPricePlan> = ({
  onChangePlan,
  selected,
}: IPricePlan) => {
  const handleClick = (id: number) => {
    onChangePlan?.(id);
  };

  return (
    <Row gutter={[16, 0]}>
      {PRICE_PLANS.map((item, index) => {
        return (
          <Col span={6} key={index}>
            <PriceCard {...item} onClick={handleClick} selected={selected} />
          </Col>
        );
      })}
    </Row>
  );
};

export default PricePlan;
