/* eslint-disable no-unused-vars */
import { IAction } from '@shared-components/models';
import * as actionTypes from './role-permission.constant';

export const ROLE_PERMISSION_FEATURE = 'role_permissions';

export type loadingStatusEnum =
  | 'GET_ROLES'
  | 'GET_ROLE'
  | 'GET_PERMISSIONS'
  | 'DELETE_ROLE'
  | 'UPDATE_ROLE'
  | 'ADD_ROLE'
  | 'IDLE';
export interface Role_PermissionsState {
  roles: any[];
  permissions: any[];
  selectedRole: any;
  loading?: boolean;
  loading_status: loadingStatusEnum;
  error?: boolean;
}

export const initialRolePermissionState: Role_PermissionsState = {
  roles: [],
  permissions: [],
  selectedRole: null,
  loading: false,
  loading_status: 'IDLE',
  error: false,
};

export const rolePermissionReducer = (
  state = initialRolePermissionState,
  action: IAction<any>
) => {
  switch (action.type) {
    case actionTypes.ADD_ROLE_REQUEST:
      return {
        ...state,
        loading: true,
        loading_status: 'ADD_ROLE',
      };
    case actionTypes.ADD_ROLE_SUCCESS:
      return {
        ...state,
        loading: false,
        loading_status: 'IDLE',
        error: null,
        roles: [...action.payload, ...state.roles],
      };
    case actionTypes.ADD_ROLE_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.payload,
        loading_status: 'IDLE',
      };

    case actionTypes.DELETE_ROLE_REQUEST:
      return {
        ...state,
        loading: true,
        loading_status: 'DELETE_ROLE',
      };
    case actionTypes.DELETE_ROLE_SUCCESS:
      return {
        ...state,
        loading: false,
        loading_status: 'IDLE',
        error: null,
        roles: [
          ...state.roles.filter(
            (item) => item.id !== parseInt(action.payload.id)
          ),
        ],
      };
    case actionTypes.DELETE_ROLE_FAILURE:
      return {
        ...state,
        loading: false,
        loading_status: 'IDLE',
        error: action.payload,
      };

    case actionTypes.UPDATE_ROLE_REQUEST:
      return {
        ...state,
        loading: true,
        loading_status: 'UPDATE_ROLE',
      };
    case actionTypes.UPDATE_ROLE_SUCCESS:
      return {
        ...state,
        loading: false,
        loading_status: 'IDLE',
        error: null,
        roles: [
          state.roles.map((item) => {
            if (item.id !== parseInt(action.payload)) {
              return action.payload;
            }
          }),
        ],
      };
    case actionTypes.UPDATE_ROLE_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.payload,
      };

    case actionTypes.GET_ROLES_REQUEST:
      return {
        ...state,
        loading: true,
        loading_status: 'GET_ROLES',
      };
    case actionTypes.GET_ROLES_SUCCESS:
      return {
        ...state,
        loading: false,
        loading_status: 'IDLE',
        error: null,
        roles: [...action.payload],
      };
    case actionTypes.GET_ROLES_FAILURE:
      return {
        ...state,
        loading: false,
        loading_status: 'IDLE',
        error: action.payload,
      };

    case actionTypes.GET_ROLE_REQUEST:
      return {
        ...state,
        loading: true,
        loading_status: 'GET_ROLE',
      };
    case actionTypes.GET_ROLE_SUCCESS:
      return {
        ...state,
        loading: false,
        loading_status: 'IDLE',
        error: null,
        selectedRole: action.payload,
      };
    case actionTypes.GET_ROLE_FAILURE:
      return {
        ...state,
        loading: false,
        loading_status: 'IDLE',
        error: action.payload,
      };

    case actionTypes.GET_ROLES_CLEAR:
      return {
        ...state,
        loading: false,
        loading_status: 'IDLE',
        roles: [],
      };
    case actionTypes.GET_PERMISSIONS_SUCCESS:
      return {
        ...state,
        loading: false,
        loading_status: 'GET_PERMISSIONS',
        error: null,
        permissions: [...action.payload],
      };
    case actionTypes.GET_PERMISSIONS_FAILURE:
      return {
        ...state,
        loading: false,
        loading_status: 'IDLE',
        error: action.payload,
      };
    case actionTypes.GET_PERMISSIONS_CLEAR:
      return {
        ...state,
        loading: false,
        loading_status: 'IDLE',
        permissions: [],
        selectedRole: null,
      };
    default:
      return state;
  }
};
