import {
  IAction,
  IOtherDetailValue,
  IOtherDetailValuePayload,
} from '@shared-components/models';
import * as actionTypes from './other-detail-values.constant';

export const addLeadOtherDetailValueRequest = (
  payload: IOtherDetailValue
): IAction<IOtherDetailValue> => ({
  type: actionTypes.ADD_LEAD_OTHER_DETAIL_VALUE_REQUEST,
  payload,
});
export const addLeadOtherDetailValueSuccess = (
  payload: IOtherDetailValue
): IAction<IOtherDetailValue> => ({
  type: actionTypes.ADD_LEAD_OTHER_DETAIL_VALUE_SUCCESS,
  payload,
});
export const addLeadOtherDetailValueFailure = (
  payload: IOtherDetailValuePayload
): IAction<IOtherDetailValue> => ({
  type: actionTypes.ADD_LEAD_OTHER_DETAIL_VALUE_FAILURE,
  payload,
});

export const getLeadOtherDetailValuesRequest = (
  id: string | undefined,
  field_id: string
): IAction<any> => ({
  type: actionTypes.GET_LEAD_OTHER_DETAIL_VALUES_REQUEST,
  payload: { id, field_id },
});

export const getLeadOtherDetailValuesSuccess = (
  payload: IOtherDetailValue[]
): IAction<IOtherDetailValue[]> => ({
  type: actionTypes.GET_LEAD_OTHER_DETAIL_VALUES_SUCCESS,
  payload,
});

export const getLeadOtherDetailValuesFailure = (
  payload: IOtherDetailValuePayload
): IAction => ({
  type: actionTypes.GET_LEAD_OTHER_DETAIL_VALUES_FAILURE,
  payload,
});

export const getLeadOtherDetailValuesClear = () => {
  return { type: actionTypes.GET_LEAD_OTHER_DETAIL_VALUES_CLEAR };
};
