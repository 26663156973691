import React from 'react';
import { Menu, Space } from 'antd';
import { EditOutlined } from '@ant-design/icons';
import { UserNameCard } from '@moxie/shared';
import { IUserTableNameCardProps } from '@shared-components/models';
import { EDIT_BTN, URL_USER_DETAIL_LINK } from '@moxie/constants';

const userMenu = ({ showDrawer }: { showDrawer: () => void }) => (
  <Menu>
    <Menu.Item key={1} onClick={showDrawer}>
      <Space direction="horizontal">
        <EditOutlined />
        {EDIT_BTN}
      </Space>
    </Menu.Item>
  </Menu>
);

const TableUserNameCard: React.FC<IUserTableNameCardProps> = ({
  index,
  firstName,
  lastName,
  email,
  id,
  showDrawer,
  actionBar,
}: IUserTableNameCardProps) => {
  const handleDrawer = () => {
    showDrawer(id);
  };
  const menuActions = {
    showDrawer: handleDrawer,
  };
  return (
    <UserNameCard
      index={index}
      id={id}
      firstName={firstName}
      lastName={lastName}
      email={email}
      link={`${URL_USER_DETAIL_LINK}${id}`}
      actionBar={actionBar}
      menu={userMenu(menuActions)}
      showEmailAsLink={true}
      showMailIcon={true}
    />
  );
};

export { TableUserNameCard };
