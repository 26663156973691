import React, { useState } from 'react';
import { Card, Col, Divider, Menu, Row } from 'antd';
import { CATEGORY_ITEMS, GUTTER, LABEL } from '@moxie/constants';
import { CategoryDetailContent } from './category-detail-content';

const GeneralDetails: React.FC<any> = ({ lead }) => {
  const [categoryKey, setCategoryKey] = useState<string>(CATEGORY_ITEMS[0].key);

  const handleClick = async ({ key }: { key: string }) => {
    setCategoryKey(key);
  };
  return (
    <div className="overflow-scroll">
      <Divider plain={true} className="search-margin-top" />
      <Row gutter={GUTTER}>
        <Col
          span={24}
          sm={10}
          md={10}
          lg={8}
          xl={7}
          xxl={6}
          className="padding-left-2"
        >
          <Card title={LABEL.CATEGORIES}>
            <Menu
              mode="inline"
              className="menu_ul"
              defaultSelectedKeys={[CATEGORY_ITEMS[0].key]}
            >
              {CATEGORY_ITEMS.map((item) => {
                return (
                  <Menu.Item
                    className="lead-other-details__menu"
                    key={item.key}
                    onClick={handleClick}
                  >
                    {item.name}
                  </Menu.Item>
                );
              })}
            </Menu>
          </Card>
        </Col>
        <Col span={24} sm={14} md={14} lg={16} xl={17} xxl={18}>
          <CategoryDetailContent categoryKey={categoryKey} lead={lead} />
        </Col>
      </Row>
    </div>
  );
};

export { GeneralDetails };
