export const LOADING_INTERESTED_SERVICE = 'LOADING_INTERESTED_SERVICE';
export const FETCH_INTERESTED_SERVICES_REQUEST =
  'FETCH_INTERESTED_SERVICES_REQUEST';
export const FETCH_INTERESTED_SERVICES = 'FETCH_INTERESTED_SERVICE';
export const DELETE_INTERESTED_SERVICE_REQUEST =
  'DELETE_INTERESTED_SERVICE_REQUEST';
export const DELETE_INTERESTED_SERVICE = 'DELETE_INTERESTED_SERVICE';
export const POST_INTERESTED_SERVICE_REQUEST =
  'POST_INTERESTED_SERVICE_REQUEST';
export const POST_PRODUCT_AS_INTERESTED_SERVICE_REQUEST =
  'POST_PRODUCT_AS_INTERESTED_SERVICE_REQUEST';
export const POST_INTERESTED_SERVICE = 'POST_INTERESTED_SERVICE';
export const UPDATE_INTERESTED_SERVICE_REQUEST =
  'UPDATE_INTERESTED_SERVICE_REQUEST';
export const UPDATE_INTERESTED_SERVICE = 'UPDATE_INTERESTED_SERVICE';
export const SUCCEEDED_INTERESTED_SERVICE = 'SUCCEEDED_INTERESTED_SERVICE';
export const PRODUCT_SUCCEEDED_INTERESTED_SERVICE =
  'PRODUCT_SUCCEEDED_INTERESTED_SERVICE';
export const IDLE_INTERESTED_SERVICE = 'IDLE_INTERESTED_SERVICE';
export const SET_ERROR_INTERESTED_SERVICES = 'SET_ERROR_INTERESTED_SERVICES';
export const RESET_ERROR = 'RESET_ERROR';
