import React, { useState } from 'react';
import { Dropdown, Menu } from 'antd';
import { DeleteOutlined, EditOutlined, MoreOutlined } from '@ant-design/icons';
import { Button, popConfirm } from '@shared-components/elements';
import { useDispatch } from 'react-redux';
import {
  BUTTON,
  CONFIRM_MESSAGE,
  CONFIRM_TITLE,
  DELETE_BTN,
  TEXT,
} from '@moxie/constants';
import { LeadAppointmentDrawer } from '../appointment-drawer';
import { IAppointmentData } from '@shared-components/models';
import * as appointmentActions from 'apps/lyra/src/core/lead-appointments/lead-appointments.action';
import { useAppSelector, userAppointmentActions } from '@lyra/core';
import Can, { PermissionAction, PermissionObjects } from '@lyra/privilege';
import { useForm } from 'antd/lib/form/Form';
const AppointmentNameCard: React.FC<IAppointmentData> = ({
  title,
  id,
  triggerRefresh,
  archived,
}: IAppointmentData) => {
  const dispatch = useDispatch();
  const [form] = useForm();

  const [isOpen, setIsOpen] = useState(false);
  const { appointment } = useAppSelector((store) => ({
    appointment: store.lead_appointment.appointment,
  }));
  const socket: any = useAppSelector((state) => state.socket.wss);
  const user: any = useAppSelector((state) => state.auth.user);
  const currentlead: any = useAppSelector((state) => state.lead.lead);
  const handleClose = () => {
    triggerRefresh && triggerRefresh();
    form.resetFields();
    setIsOpen(false);
  };

  const removeAppointment = async () => {
    id && dispatch(appointmentActions.getLeadAppointmentRequest(id));
    popConfirm(
      CONFIRM_TITLE.DELETE_APPOINTMENT,
      `${CONFIRM_MESSAGE.DELETE_APPOINTMENT}`,
      async () => {
        if (id) {
          socket.emit('create-appointment-notification-to-server', {
            user_id: user.id,
            receiver_id: currentlead?.assignee_user_id,
            receivers_many: appointment?.user_list?.map((item: any) => item),
            type: 'appointment-deleted',
          });
          title === TEXT.USER_APPOINTMENT
            ? dispatch(userAppointmentActions.deleteUserAppointmentRequest(id))
            : dispatch(
                appointmentActions.deleteLeadAppointmentRequest(
                  id,
                  user,
                  socket,
                  currentlead
                )
              );
        }
      }
    );
  };

  const appointmentDrawer = async () => {
    setIsOpen(true);
    if (id) {
      dispatch(appointmentActions.getLeadAppointmentRequest(id));
    }
  };

  const menu = (
    <Menu>
      {/* {Can(PermissionAction.Update, PermissionObjects.LeadAppointment) && ( */}
        <Menu.Item key="2" icon={<EditOutlined />} onClick={appointmentDrawer}>
          {BUTTON.EDIT}
        </Menu.Item>
      {/* )} */}
      {/* {Can(PermissionAction.Delete, PermissionObjects.LeadAppointment) && ( */}
        <Menu.Item
          key="3"
          icon={<DeleteOutlined />}
          onClick={removeAppointment}
        >
          {DELETE_BTN}
        </Menu.Item>
      {/* )} */}
    </Menu>
  );

  return (
    <>
      {(Can(PermissionAction.Delete, PermissionObjects.LeadAppointment) ||
        Can(PermissionAction.Update, PermissionObjects.LeadAppointment)) &&
        !archived && (
          <Dropdown overlay={menu}>
            <Button
              type="text"
              className="table-cell-action-btn"
              icon={<MoreOutlined />}
            />
          </Dropdown>
        )}
      <LeadAppointmentDrawer
        title={title}
        appointmentData={appointment}
        isOpen={isOpen}
        onClose={handleClose}
        isEdit={true}
        form={form}
      />
    </>
  );
};

export { AppointmentNameCard };
