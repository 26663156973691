import { getEnglishTestScore } from '@crm/services.api';
import { errorNotificationHandler } from '@moxie/shared';
import { useQuery } from '@tanstack/react-query';

export const useFetchEnglishTestScore = (contactId: string) => {
  const { data, isLoading } = useQuery({
    queryKey: ['englishTestScore', contactId],
    queryFn: () => getEnglishTestScore(contactId),
    enabled: true,
    onError: () => {
      errorNotificationHandler('Error fetching english test score.');
    },
  });

  return { englishTestScore: data?.data, isLoading };
};
