
import React from 'react';
import useProductTypes from '../hooks/useProductTypes';
import Tree, { DataNode, TreeProps } from 'antd/lib/tree';
import Spin from 'antd/es/spin';
import { regex } from '@moxie/constants';

interface Props {
  // eslint-disable-next-line no-unused-vars
  onChange: (vals: string[]) => void,
  selectedProductSubTypes: string[];
  service: string | undefined;
}
const uuidRegex = regex.UUID;

const ProductTypeFilter: React.FC<Props> = ({ onChange, selectedProductSubTypes, service }) => {
  const { productTypes, isLoading } = useProductTypes(service);

  const treePayload: DataNode[] = productTypes.map((productType): DataNode => ({
    title: productType.name,
    key: productType.id,
    children: productType.subTypeLevel.map(subType => ({
      key: subType.name,
      title: subType.name
    }))
  }))

  const onCheck: TreeProps['onCheck'] = (values) => {
    if (Array.isArray(values)) {
      const subTypes = values.filter(val => !(val.toString().match(uuidRegex))) as string[];
      onChange(subTypes)
    }
  }

  if (isLoading) {
    return <Spin />
  }

  return (
    <Tree
      checkable
      multiple
      defaultExpandAll
      selectable={false}
      checkedKeys={selectedProductSubTypes}
      treeData={treePayload}
      onCheck={onCheck}
    />
  )
}
export default ProductTypeFilter
