import React, { useState } from 'react';
import { Typography } from 'antd';
import { Button } from '@moxie/shared';
import { IProductDetailUtil } from '@shared-components/models';

const ProductDetailUtil: React.FC<IProductDetailUtil> = ({
  index,
  remark,
  count = 70,
}: IProductDetailUtil) => {
  const [showMore, setShowMore] = useState(false);
  const handleParagraph = () => {
    setShowMore(!showMore);
  };
  return (
    <div className="text-justify">
      {showMore ? (
        <Typography.Text key={index}>{remark && remark}</Typography.Text>
      ) : (
        <Typography.Text key={index}>
          {remark && remark?.substring(0, count)}
        </Typography.Text>
      )}
      {remark && remark?.trim().length >= count && (
        <Button type="link" size="small" onClick={handleParagraph} key={index}>
          {showMore ? (
            <Typography.Text italic>Show Less</Typography.Text>
          ) : (
            <Typography.Text italic>Show More...</Typography.Text>
          )}
        </Button>
      )}
    </div>
  );
};

export { ProductDetailUtil };
