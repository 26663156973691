import { productsActions, useAppSelector } from '@admin/core';
import { productServices } from '@admin/services.api';
import {
  CONFIRM_MESSAGE,
  CONFIRM_TITLE,
  PAGE_HEADER_MENU_PRODUCTS,
  PRODUCT_TITLE,
} from '@moxie/constants';
import { PageHeaderTitle, popConfirm } from '@shared-components/elements';
import { useForm } from 'antd/lib/form/Form';
import React, { useState } from 'react';
import { useDispatch } from 'react-redux';
import { ProductFormDrawer } from './product-form-drawer';
import { ProductTable } from './product-table';
import { PageHeaderMenu } from '../components/page-header-menu';

const ProductListComponent: React.FC = () => {
  const [isOpen, setIsOpen] = useState(false);
  const [form] = useForm();
  const [refreshTable, setRefreshTable] = useState(true);
  const [isEdit, setIsEdit] = useState(false);
  const [disabled, setDisabled] = useState(true);
  const dispatch = useDispatch();
  const singleData = useAppSelector((state) => state.product.product);
  const triggerRefresh = () => setRefreshTable((prev) => !prev);
  const showDrawer = async (id?: string) => {
    if (id) {
      dispatch(productsActions.getProductRequest(id));
      setIsEdit(true);
    } else {
      setIsEdit(false);
    }
    setIsOpen(true);
  };
  const onClose = () => {
    setIsOpen(false);
    form.resetFields();
    setIsEdit(false);
    dispatch(productsActions.getProductClear());
    setDisabled(true);
  };

  const handleSubmit = async (data: any) => {
    if (data.subject_area_id === undefined) data.subject_area_id = null;
    let postData;
    if (singleData?.id && isEdit) {
      postData = { ...data, id: singleData?.id };
      dispatch(productsActions.updateProductRequest(postData, triggerRefresh));
    } else {
      postData = data;
      dispatch(productsActions.addProductRequest(postData, triggerRefresh));
    }

    onClose();
  };

  const getProducts = async (params: string) => {
    return productServices.apiGetProducts(params);
  };

  const deleteData = async (id: string) => {
    popConfirm(
      CONFIRM_TITLE.DELETE_PRODUCT,
      CONFIRM_MESSAGE.DELETE_PRODUCT,
      () => {
        dispatch(productsActions.deleteProductRequest(id));
        triggerRefresh();
      }
    );
  };

  return (
    <>
      <div className="full-height">
        <PageHeaderTitle title={PRODUCT_TITLE} />
        <PageHeaderMenu menu={PAGE_HEADER_MENU_PRODUCTS} />
        <ProductFormDrawer
          isOpen={isOpen}
          form={form}
          handleSubmit={handleSubmit}
          initialData={singleData}
          onClose={onClose}
          isEdit={isEdit}
          disabled={disabled}
          setDisabled={setDisabled}
          formData={FormData}
        />
        <ProductTable
          tableName={PRODUCT_TITLE}
          refreshTable={refreshTable}
          getData={getProducts}
          deleteData={deleteData}
          showDrawer={showDrawer}
        />
      </div>
    </>
  );
};
export { ProductListComponent };
