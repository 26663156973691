import React from 'react';
import { ISubjectDisciplineAreas } from '@shared-components/models';
import { Tag, Tooltip } from 'antd';

const SubjectAreaTagGroup = ({
  subjectAreas,
}: {
  subjectAreas: ISubjectDisciplineAreas[];
}) => {
  const tooltipData = (
    <div>
      {(subjectAreas || [])
        .slice(2, subjectAreas.length)
        .map((subjectArea: ISubjectDisciplineAreas) => {
          return (
            <Tag
              className="margin-bottom-1 initial_capital"
              key={subjectArea.id}
              color="default"
            >
              {subjectArea.name}
            </Tag>
          );
        })}
    </div>
  );
  return (
    <>
      {subjectAreas.slice(0, 2).map((subjectArea: ISubjectDisciplineAreas) => {
        return (
          <Tag
            key={subjectArea.id}
            color="default"
            className="initial_capital margin-bottom-1"
          >
            {subjectArea.name}
          </Tag>
        );
      })}

      {subjectAreas.length > 2 && (
        <Tooltip placement="topLeft" color="white" title={tooltipData}>
          <Tag color="default">+{subjectAreas.length - 2}</Tag>
        </Tooltip>
      )}
    </>
  );
};

export { SubjectAreaTagGroup };
