import React from 'react';
import { Collapse } from 'antd';
import { useFetchBrancheById } from '@crm/libs/hooks/branches/useFetchBrancheById';
import moment from 'moment';

interface Props {
  previousBranchId: string,
  currentBranchId: string,
  name: string,
  createdAt: string
}

const BranchPanel: React.FC<Props> = ({
  previousBranchId,
  currentBranchId,
  name,
  createdAt }) => {
  const { Panel } = Collapse;
  const { branch: previousBranch } = useFetchBrancheById(previousBranchId ? previousBranchId : '');
  const { branch: currentBranch } = useFetchBrancheById(currentBranchId ? currentBranchId : '');

  return (
    <Panel
      style={{
        paddingLeft: 20, padding: 15, background: 'none'
      }}
      key={1}
      header={(
        <div className="enquiry-info">
          <p className="enquiry-author">
            <span style={{ fontWeight: 'bold', fontSize: '14px' }}>Branch </span>changed from <span style={{ fontWeight: 'bold', fontSize: '14px' }}>{previousBranch?.name || 'Unassigned'} </span>to <span style={{ fontWeight: 'bold' }}>{currentBranch?.name || 'Unassigned'} </span>
          </p>
          <div className="enquiry-date">
            <div>{moment(createdAt).format('dddd, MMMM D, YYYY')}</div>
            <div>by {name}</div>
          </div>
        </div>
      )}>
    </Panel >
  );
};

export default BranchPanel;
