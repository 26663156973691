import React, { useEffect, useState } from 'react';
import Select from 'antd/es/select';
import { Empty, type SelectProps } from 'antd';
import { PaginationMeta, CrmResponse } from '@model/api-response.model';
import { useDebounced } from '@crm/libs/hooks';
import Spin from 'antd/es/spin';
import { errorNotificationHandler } from '../../functions';
import { filterValidRefs } from '@crm/libs/helpers';
import { TEXT } from '@moxie/constants';

interface Props<T> extends SelectProps<any> {
  // eslint-disable-next-line no-unused-vars
  fetchQuery?: (params: Record<string, unknown>) => Promise<CrmResponse<T>>;
  valueKey?: keyof T;
  labelKey?: keyof T;
  filterValues?: string[];
  notApplicable?: { label: string; value: string };
  emptyOption?: React.ReactElement;
  filter?: Record<string, unknown>;
  // eslint-disable-next-line no-unused-vars
  children?: (option: T) => JSX.Element;
  hideDefaultOptionView?: boolean;
}

function SearchBox<T>({
  fetchQuery,
  labelKey,
  valueKey,
  children,
  filter,
  hideDefaultOptionView = false,
  emptyOption,
  filterValues,
  ...props
}: React.PropsWithChildren<Props<T>>) {
  const [options, setOptions] = useState<T[]>([]);
  const [isLoading, setLoading] = useState(true);
  const [search, setSearch] = useState<string>('');
  const [page, setPage] = useState(1);
  const [initialLoading, setInitialLoading] = useState(true);
  const [paginationMeta, setPaginationMeta] = useState<PaginationMeta>({
    currentPage: 1,
    itemsPerPage: 10,
    sortBy: [],
    totalItems: 0,
    totalPages: 1,
  });

  const fetchOptions = async (search: string, value?: unknown, page = 1) => {
    if (fetchQuery) {
      setLoading(true);
      try {
        let params: Record<string, unknown> = {
          ...filter,
          search,
          limit: 10,
          page,
        };

        if ((value as string | string[])?.length) {

          params = {
            ...params,
            [`filter.${valueKey as string}`]: `${Array.isArray(value) ? '$in:$not:' + value.filter(val => filterValidRefs(val)).join(',') : '$in:$not:' + value}`,
          };

          if (Array.isArray(value)) {
            const clause = value.filter(val => filterValidRefs(val)).length;
            if (!clause) {
              params.search = clause ? params.search : '';
              delete params['filter.id']
            }
          }
          else {
            const clause = filterValidRefs(value as string);
            if (!clause) {
              params.search = clause ? params.search : '';
              delete params['filter.id']
            }
          }

        }
        const {
          data: { data, meta },
        } = await fetchQuery(params);
        if (page !== 1) {
          setOptions((prev) => [...prev, ...data]);
        } else {
          setOptions(data);
        }
        setPaginationMeta(meta);
        setPage(meta.currentPage);
      } catch (error) {
        if (error instanceof Error) {
          errorNotificationHandler(error.message)
        } else if (typeof error === 'string') {
          errorNotificationHandler(error)
        }
      } finally {
        setLoading(false)
      }
    }
  };

  const optionSelectMap = React.useMemo(() => {
    if (hideDefaultOptionView) {
      return undefined;
    }
    if (labelKey && valueKey) {
      return options.map((d) => ({
        label: d[labelKey] as unknown,
        value: d[valueKey] as unknown,
      })).filter(val => !filterValues?.includes(val.value as string)) as { label: string; value: string }[];
    }
    return [];
  }, [options, labelKey, valueKey, filterValues]);

  const onSearch = useDebounced((value: string) => {
    setSearch(search);
    fetchOptions(value);
  });

  const paginateOptions: React.UIEventHandler<HTMLDivElement> = (event) => {
    const { scrollHeight, scrollTop, clientHeight } = event.currentTarget;
    const isScrollingNearBottom = Math.floor(scrollHeight - scrollTop) <= clientHeight;
    if (isScrollingNearBottom && paginationMeta.totalPages > page) {
      fetchOptions(search, props.value, page + 1);
    }
  };

  useEffect(() => {
    fetchOptions(search, props.value).then(() => setInitialLoading(false));
  }, [props.value]);


  if (initialLoading) {
    return <Spin spinning />;
  }

  return (
    <Select
      showSearch
      defaultOpen
      defaultActiveFirstOption={false}
      showArrow={false}
      filterOption={false}
      onSearch={onSearch}
      options={optionSelectMap}
      onPopupScroll={paginateOptions}
      loading={isLoading}
      disabled={initialLoading}
      notFoundContent={(<Empty
        image={Empty.PRESENTED_IMAGE_SIMPLE}
        description={TEXT.NO_DATA}
      />
      )}
      {...props}
    >
      {emptyOption}
      {
        children &&
        options.map((option, index) => (
          <React.Fragment key={index}>{children(option)}</React.Fragment>
        ))
      }
    </Select >
  );
}

export default SearchBox;
