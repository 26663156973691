import React, { useState } from 'react';
import {
  Card,
  Avatar,
  Badge,
  Col,
  Row,
  Space,
  Tooltip,
  Typography,
} from 'antd';
import {
  AM_PERMISSIONS,
  COUNTRY_LABEL,
  LABEL,
  TEXT,
  URL_USER_DETAIL_LINK,
} from '@moxie/constants';
import { IOfficeBranch } from '@shared-components/models';
import { EditOutlined, CopyOutlined } from '@ant-design/icons';
import { Link } from 'react-router-dom';
import { Button } from '@moxie/shared';
import { useAppSelector } from '@lyra/core';
import permit from '@lyra/permit';

const OfficeProfile: React.FC<any> = ({
  data,
  handleOpen,
}: {
  data: IOfficeBranch;
  handleOpen: () => void;
}) => {
  const [isCopied, setIsCopied] = useState(false);
  const rolePerms: string[] = useAppSelector(
    (state: any) => state?.auth?.user?.am_role?.permissions
  );

  const copyToClipboard = (text: string) => {
    navigator.clipboard.writeText(text.trim().toLowerCase());
    setIsCopied(true);
  };
  return (
    <section className="profile">
      <Card>
        <Row justify="space-between" className="custom_horizontal_divider">
          <Col span={8} className="custom__profile__info__container">
            <Row gutter={24} justify="space-between">
              <Col>
                <div className="profile__info">
                  <div className="profile__info__item profile__info__user">
                    <Avatar size="large">
                      {data.name?.substring(0, 1).toUpperCase()}
                    </Avatar>
                    <Space>
                      <h3 className="profile__info__user__detail margin-top-1 initial_capital">
                        {data.name}
                      </h3>
                      <Badge
                        count={data.status?.toUpperCase()}
                        className={
                          data.status === 'active' ? 'badge__color-green' : ''
                        }
                      />
                    </Space>
                  </div>
                </div>
              </Col>
              <Col className="margin-top-1">
                {
                  permit([AM_PERMISSIONS.BRANCHES.MANAGE], rolePerms) && (<Tooltip title="Edit" className="profile__info__edit">
                    <Button
                      icon={<EditOutlined />}
                      onClick={handleOpen}
                      type="link"
                    />
                  </Tooltip>)
                }
              </Col>
            </Row>

            <Row>
              <h4 className="profile__title">{COUNTRY_LABEL}:</h4>
              <span className="text-link-color-office">
                {data.address?.country ? data.address?.country : '-'}
              </span>
            </Row>

            <Row>
              <h4 className="profile__title">{LABEL.STATE}:</h4>
              <span className="text-link-color-office initial_capital">
                {data.address?.state ? data.address?.state : '-'}
              </span>
            </Row>

            <Row>
              <h4 className="profile__title">{LABEL.CITY}:</h4>
              <span className="text-link-color-office initial_capital">
                {data.address?.city ? data.address?.city : '-'}
              </span>
            </Row>

            <Row>
              <h4 className="profile__title">{TEXT.POSTAL_CODE}:</h4>
              <span className="text-link-color-office initial_capital">
                {data.address?.postal_code ? data.address?.postal_code : '-'}
              </span>
            </Row>
            <Row>
              <h4 className="profile__title">{TEXT.ADDRESS}:</h4>
              {data.address?.address_line_one &&
                data.address?.address_line_two ? (
                <div>
                  <span className="text-link-color-office initial_capital">
                    {data?.address?.address_line_one &&
                      data?.address?.address_line_one}
                  </span>{' '}
                  <span className="text-link-color-office initial_capital">
                    {data?.address?.address_line_two &&
                      data?.address?.address_line_two}
                  </span>
                </div>
              ) : (
                '-'
              )}
            </Row>
          </Col>

          <Col span={8} className="custom__data__container">
            {data.email && (
              <Row className="email_div">
                <Col>
                  <Row>
                    <Col>
                      <span className="profile__title">{TEXT.EMAIL}: </span>
                    </Col>
                    <Col className="initial_small">
                      <Tooltip title={data.email}>
                        <a
                          className="text-link-color-office email-ellipsis "
                          href={`mailto:${data.email}`}
                        >
                          {data.email ? data.email.toLowerCase() : '-'}
                        </a>
                      </Tooltip>
                    </Col>
                  </Row>
                </Col>
                <Col className="hide">
                  <Tooltip
                    placement="right"
                    color={isCopied ? '#8EAF74' : '#9B9D9A '}
                    title={
                      isCopied ? `${TEXT.COPIED_SUCCESS}` : `${TEXT.COPY_EMAIL}`
                    }
                  >
                    <CopyOutlined
                      className="copy_email_icon"
                      onClick={() => copyToClipboard(`${data.email}`)}
                    />
                  </Tooltip>
                </Col>
              </Row>
            )}
            <Row>
              <h4 className="profile__title">{LABEL.MOBILE}:</h4>
              <span className="text-link-color-office">
                {data.mobile ? data.mobile : '-'}
              </span>
            </Row>
            <Row>
              <h4 className="profile__title">{LABEL.PHONE_NUMBER}:</h4>
              <span className="text-link-color-office">
                {data.phone ? data.phone : '-'}
              </span>
            </Row>
            <Row>
              <h4 className="profile__title">{LABEL.CONTACT_PERSON}:</h4>
              <Link to={`${URL_USER_DETAIL_LINK}${data?.contact_person_id}`}>
                <Typography.Text className="text-link-color-office">
                  {data.contact_person_id
                    ? ` ${data?.contactPerson?.first_name} ${data?.contactPerson?.last_name}`
                    : '-'}
                </Typography.Text>
              </Link>
            </Row>
          </Col>

          <Col lg={8} className="custom__data__container">
            <div className="profile__info__item">
              <Row justify="start">
                <h4 className="profile__title align-items-center margin-0">
                  {TEXT.OVERVIEW}:
                </h4>
              </Row>
              <div className="profile__overview">
                <div className="profile__overview__item">
                  <h4>{TEXT.TOTAL_USERS}</h4>
                  <span>{data.userCount}</span>
                </div>
                <div className="profile__overview__item">
                  <h4>{TEXT.TOTAL_DEPARTMENTS}</h4>
                  <span>{data.departmentCount}</span>
                </div>
              </div>
            </div>
          </Col>
        </Row>
      </Card>
    </section>
  );
};

export { OfficeProfile };
