import React from 'react';
import { Checkbox, Col, Form, Row } from 'antd';
import { ILeadsFormComponent } from '@shared-components/models';
import { GUTTER, LABEL, TITLE } from '@moxie/constants';

const OtherDetails: React.FC<ILeadsFormComponent> = ({
  setLeadForm,
  leadForm,
  setDisabled,
}: ILeadsFormComponent) => {
  const handleChange = (e: any) => {
    setDisabled(false);
    setLeadForm({ ...leadForm, [e.target.name]: e.target.checked });
  };

  const onCheckAllChange = (e: any) => {
    setDisabled(false);
    if (!e.target.checked) {
      setLeadForm({
        ...leadForm,
        upload_documents: false,
        comments: false,
      });
      return;
    }
    setLeadForm({
      ...leadForm,
      upload_documents: true,
      comments: true,
    });
  };

  return (
    <>
      <Row justify="space-between" className="custom__title__text">
        <Col>{TITLE.OTHER_DETAILS}</Col>
        <Col>
          <Checkbox
            checked={leadForm.upload_documents && leadForm.comments}
            onChange={onCheckAllChange}
          />
        </Col>
      </Row>
      <Form.Item className="custom__formItem">
        <Row gutter={GUTTER}>
          <Col span={5} className="lead-form-box">
            <Checkbox
              name="upload_documents"
              checked={leadForm.upload_documents}
              onChange={handleChange}
            >
              {LABEL.UPLOAD_DOCUMENTS}
            </Checkbox>
          </Col>
          <Col span={5} className="lead-form-box">
            <Checkbox
              name="comments"
              checked={leadForm.comments}
              onChange={handleChange}
            >
              {LABEL.COMMENTS}
            </Checkbox>
          </Col>
        </Row>
      </Form.Item>
    </>
  );
};

export default OtherDetails;
