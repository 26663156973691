const alias = 'WORKFLOW';
const workflowStage = 'WORKFLOW_STAGE';
const wfStageChecklist = 'WF_STAGE_CHECKLIST';
const checklist = 'CHECKLIST';
const workflowsByInstitutionId = 'WORKFLOWS_BY_INSTITUTION_ID';

export const FETCH_WORKFLOWS_REQUEST = `FETCH_${alias}S_REQUEST`;
export const FETCH_WORKFLOWS_SUCCESS = `FETCH_${alias}S_SUCCESS`;
export const FETCH_WORKFLOWS_FAILURE = `FETCH_${alias}S_FAILURE`;

export const FETCH_WORKFLOW_REQUEST = `FETCH_${alias}_REQUEST`;
export const FETCH_WORKFLOW_SUCCESS = `FETCH_${alias}_SUCCESS`;
export const FETCH_WORKFLOW_FAILURE = `FETCH_${alias}_FAILURE`;

export const FETCH_WORKFLOWS_BY_INSTITUTION_ID_REQUEST = `FETCH_${workflowsByInstitutionId}_REQUEST`;
export const FETCH_WORKFLOWS_BY_INSTITUTION_ID_SUCCESS = `FETCH_${workflowsByInstitutionId}_SUCCESS`;
export const FETCH_WORKFLOWS_BY_INSTITUTION_ID_FAILURE = `FETCH_${workflowsByInstitutionId}_FAILURE`;

export const ADD_WORKFLOW_REQUEST = `ADD_${alias}_REQUEST`;
export const ADD_WORKFLOW_SUCCESS = `ADD_${alias}_SUCCESS`;
export const ADD_WORKFLOW_FAILURE = `ADD_${alias}_FAILURE`;

export const DELETE_WORKFLOW_REQUEST = `DELETE_${alias}_REQUEST`;
export const DELETE_WORKFLOW_SUCCESS = `DELETE_${alias}_SUCCESS`;
export const DELETE_WORKFLOW_FAILURE = `DELETE_${alias}_FAILURE`;

export const CLEAR_WORKFLOW_FETCH = `CLEAR_${alias}_FETCH`;

export const ADD_WORKFLOW_STAGE_REQUEST = `ADD_${workflowStage}_REQUEST`;
export const ADD_WORKFLOW_STAGE_SUCCESS = `ADD_${workflowStage}_SUCCESS`;
export const ADD_WORKFLOW_STAGE_FAILURE = `ADD_${workflowStage}_FAILURE`;

export const FETCH_WORKFLOW_STAGES_REQUEST = `FETCH_${workflowStage}S_REQUEST`;
export const FETCH_WORKFLOW_STAGES_SUCCESS = `FETCH_${workflowStage}S_SUCCESS`;
export const FETCH_WORKFLOW_STAGES_FAILURE = `FETCH_${workflowStage}S_FAILURE`;

export const FETCH_WORKFLOW_STAGE_CHECKLISTS_REQUEST = `FETCH_${wfStageChecklist}S_REQUEST`;
export const FETCH_WORKFLOW_STAGE_CHECKLISTS_SUCCESS = `FETCH_${wfStageChecklist}S_SUCCESS`;
export const FETCH_WORKFLOW_STAGE_CHECKLISTS_FAILURE = `FETCH_${wfStageChecklist}S_FAILURE`;
export const CLEAR_WORKFLOW_STAGE_CHECKLISTS = `CLEAR_${wfStageChecklist}S`;

export const FETCH_CHECKLISTS_REQUEST = `FETCH_${checklist}S_REQUEST`;
export const FETCH_CHECKLISTS_SUCCESS = `FETCH_${checklist}S_SUCCESS`;
export const FETCH_CHECKLISTS_FAILURE = `FETCH_${checklist}S_FAILURE`;

export const DELETE_WF_STAGE_CHECKLIST_REQUEST = `DELETE_${wfStageChecklist}_REQUEST`;
export const DELETE_WF_STAGE_CHECKLIST_SUCCESS = `DELETE_${wfStageChecklist}_SUCCESS`;
export const DELETE_WF_STAGE_CHECKLIST_FAILURE = `DELETE_${wfStageChecklist}_FAILURE`;
