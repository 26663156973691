import { errorHandler } from '@moxie/utils';
import { AxiosResponse } from 'axios';
import { put, takeLatest, call } from 'redux-saga/effects';
import { subjectDisciplineServices } from '../../libs/services.api/lib/subject-discipline.api';
import * as actionTypes from './subject-disciplines.constant';
import * as subjectDisciplineActions from './subject-disciplines.action';

function* fetchSubjectDiscipline(): any {
  try {
    const res: AxiosResponse = yield call(
      subjectDisciplineServices.apiGetSubjectDiscipline
    );
    if (res?.data?.data)
      yield put(
        subjectDisciplineActions.getSubjectDisciplinesSuccess(res?.data?.data)
      );
  } catch (err) {
    errorHandler(err);
    yield put(subjectDisciplineActions.getSubjectDisciplinesFailure(err));
  }
}

export default function* subjectDisciplineSaga(): any {
  yield takeLatest(
    actionTypes.GET_SUBJECT_DISCIPLINES_REQUEST,
    fetchSubjectDiscipline
  );
}
