import axios, { AxiosRequestConfig } from 'axios';
import cookies from 'js-cookie';
import {
  AUTH_MESSAGE,
  ADMIN_COOKIE_ACCESS,
} from '@moxie/constants';
import { errorNotificationHandler } from '@moxie/shared';
const axiosConfig: AxiosRequestConfig = {
  baseURL: process.env.NX_BASE_URL,
};
const axiosInstance = axios.create(axiosConfig);

axiosInstance.interceptors.request.use((config: AxiosRequestConfig) => {
  const accessToken = cookies.get(ADMIN_COOKIE_ACCESS);
  if (accessToken && accessToken.length > 10) {
    config.headers = {
      authorization: `Bearer ${accessToken}`,
    };
  }
  return config;
});

axiosInstance.interceptors.response.use(
  (response) => {
    return response;
  },
  (err) => {
    if (err?.message === 'Network Error') {
      errorNotificationHandler(AUTH_MESSAGE.SERVICE_DOWN);
    }
    throw err;
  }
);


export { axiosInstance };
export default axiosInstance;
