import React, { Suspense } from 'react';
import { Route, RouteProps } from 'react-router-dom';
import { Spin } from 'antd';

const PublicRoute = ({
  component: Component,
  ...rest
}: RouteProps | any): any => {
  return (
    <Route
      {...rest}
      render={(props) => (
        <Suspense
          fallback={
            <div className="page-load">
              <Spin />
            </div>
          }
        >
          <div className="body-container">
            <Component {...props} />
          </div>
        </Suspense>
      )
      }
    />
  );
};
export default PublicRoute;
