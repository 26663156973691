import React from 'react';
import { Typography, List, Col } from 'antd';
import { IOtherDetails } from '@shared-components/models';
import OtherDetailValueForm from './other-detail-value-form';

const OtherDetailCollapse: React.FC<IOtherDetails | any> = ({
  category,
  labels,
  categoryKey,
  labelValue,
  lead,
}: IOtherDetails | any) => {
  return (
    <>
      {categoryKey === category?.id && (
        <List
          key={category.id}
          className="lead-other-details-content  border_top_classname"
          size="small"
          itemLayout="horizontal"
          dataSource={labels}
          renderItem={(label: any, index) => (
            <List.Item
              className={
                index % 2 === 0
                  ? 'even_classname lead-other-details__padding-left'
                  : '' || index === 0
                    ? 'border_top_classname lead-other-details__padding-left'
                    : ' lead-other-details__padding-left'
              }
              key={index}
            >
              <Col xl={{ span: 12 }} md={{ span: 10 }}>
                <Typography.Paragraph
                  strong
                  className="lead-other-details__paragraph initial_capital"
                >
                  {label?.label}:{' '}
                </Typography.Paragraph>
              </Col>
              <Col xl={{ span: 12 }} md={{ span: 14 }}>
                {labelValue && (
                  <OtherDetailValueForm
                    lead={lead}
                    categoryId={categoryKey}
                    labelId={label?.id}
                    labelValue={labelValue}
                    label={label}
                    index={index}
                  />
                )}
              </Col>
            </List.Item>
          )}
        />
      )}
    </>
  );
};

export default OtherDetailCollapse;
