import { Button } from 'antd';
import { ContainerOutlined } from '@ant-design/icons';
import type { ButtonProps } from 'antd';
import React from 'react';

export const ViewDetailsButton: React.FC<ButtonProps> = ({
  onClick,
  ...props
}) => {
  return (
    <Button onClick={onClick} {...props}>
      <ContainerOutlined />
      View Details
    </Button>
  );
};
