import React, { useEffect, useState } from 'react';
import { useHistory, useLocation, useParams } from 'react-router-dom';
import {
  ADD_OFFICE_VISIT,
  LABEL,
  PAGE_HEADER_MENU_OFFICE_VISIT,
  TEXT,
  URL_OFFICE_VISIT_WAITING,
} from '@moxie/constants';
import { CrmTable, PageHeaderTitle } from '@shared-components/elements';
import { OfficeVisitDrawer } from './office-visit-drawer';
import { Button, Space, Spin } from 'antd';
import { useForm } from 'antd/lib/form/Form';
import { useDispatch } from 'react-redux';
import { officeVisitActions, useAppSelector } from '@crm/core';
import { PlusOutlined, ShareAltOutlined } from '@ant-design/icons';
import {
  attendingColumns,
  waitingColumns,
  completedColumns,
  archivedColumns,
  pendingColumns,
} from './office-visit-table-column';
import { getBranchesByUserContactPermission, officeVisitList } from '@crm/services.api';
import { OfficeVisitDetailsDrawer } from './office-visit-details-drawer';
import { PageHeaderMenu, SearchBox } from '../../shared';
import { useAssignedBranch } from '@crm/libs/hooks/contacts';
import { capitalizeFirstLetter } from 'libs/shared/src/functions';
import OfficeVisitUrlShareModal from './office-visit-url-share-modal';
import { OfficeVisitApproveModal } from './office-visit-approve-modal';
import { ViewEnquiryDetailsDrawer } from '@crm/src/pages/enquiries/components/view-enquiry/view-enquiry-details-drawer';

const getColumns = (name: string) => {
  switch (name) {
    case "pending":
      return pendingColumns;

    case "waiting":
      return waitingColumns;

    case "attending":
      return attendingColumns;

    case "completed":
      return completedColumns;

    case "archived":
      return archivedColumns;

    default:
      return;
  }
}

const OfficeVisitTableComponent: React.FC = () => {
  const { name } = useParams<{ name: string }>();
  const officeVisitStatus = name !== 'archived' ? capitalizeFirstLetter(name) : 'Unattended';
  const [form] = useForm();
  const [visible, setVisible] = useState(false);
  const [disabled, setDisabled] = useState(true);
  const [showShareModal, setShowShareModal] = useState(false);
  const [filter, setFilter] = useState<any>({});
  const [showAll, setShowAll] = useState<boolean>(true);
  const [assignedToMe, setAssignedToMe] = useState<boolean>(false);
  const [selectedBranch, setSelectedBranch] = useState<string>();

  const history = useHistory();

  const {
    initialData,
    drawerIsOpen,
    detailDrawerIsOpen,
    refreshTable,
    loggedInUserId,
    loggedInUserBranchId,
    companyId,
    isEnquiryDrawerOpen,
    isApproveModalOpen,
    enquiryId,
  } = useAppSelector((state) => ({
    initialData: state.office_visits.singleData,
    drawerIsOpen: state.office_visits.drawerIsOpen,
    detailDrawerIsOpen: state.office_visits.detailDrawerIsOpen,
    refreshTable: state.office_visits.refresh,
    loggedInUserId: state.auth?.user?.id,
    loggedInUserBranchId: state.auth?.user?.branchId as string,
    companyId: state.auth?.user?.companyId,
    isEnquiryDrawerOpen: state.office_visits?.isEnquiryDrawerOpen,
    isApproveModalOpen: state.office_visits?.isApproveModalOpen,
    enquiryId: state.office_visits?.enquiryId,
  }));

  const { branches: branchList } = useAssignedBranch();

  // const socket = useAppSelector((state) => state.socket.wss);
  // const user = useAppSelector((state) => state.auth.user);

  const dispatch = useDispatch();
  const triggerRefresh = () => {
    dispatch(officeVisitActions.officeVisitTableRefresh());
  };

  const showDrawer = () => {
    setVisible(true);
    dispatch(officeVisitActions.clearOfficeVisitSingleData());
    form.resetFields();
  };

  const handleClose = () => {
    form.resetFields();
    setVisible(false);
    setDisabled(true);
    dispatch(officeVisitActions.clearOfficeVisitSingleData());
    dispatch(officeVisitActions.closeOfficeVisitDrawer());
  };
  const handleEnquiryDrawerClose = () => {
    dispatch(officeVisitActions.closeOfficeEnquiryDrawer());
  }
  const handleDetailsDrawerClose = () => {
    // dispatch(themeActions.toggleNavbar(false));
    dispatch(officeVisitActions.closeOfficeVisitDetailsDrawer());
  };

  const getData = (params: Record<string, unknown>) => {
    return officeVisitList(params);
  };

  const setDrawerOpen = (): boolean => {
    return visible || drawerIsOpen!;
  };

  const submitForm = (data: any) => {
    if (initialData?.id) {
      dispatch(
        officeVisitActions.updateOfficeVisitRequest(
          initialData.id,
          data,
          triggerRefresh
        )
      );
    } else {
      const newData = { ...data, companyId, branchId: selectedBranch, status: "Waiting" };
      dispatch(
        officeVisitActions.addOfficeVisitRequest(newData, () => {
          dispatch(officeVisitActions.officeVisitTableRefresh());
          history.push(URL_OFFICE_VISIT_WAITING);
          // ToDo: Integrate later
          // socket?.emit('server::office-visit-updated', {
          //   assignee: data.assignee_id,
          //   branch: branchList?.find((branch: Branch) => branch.id === data.branch_id)
          //     ?.name as string,
          //   contact_id: data.contact_id,
          //   crm_user_id: user?.id as string,
          //   type: 'created',
          // });
        })
      );
    }
    handleClose();
  };

  const handleTableButtons = (type: string, isActive: boolean) => {
    switch (type) {
      case 'assignedToMe':
        setAssignedToMe(!assignedToMe);
        setShowAll(isActive);
        setFilter({ ...filter, assigneeId: isActive ? undefined : loggedInUserId });
        return;
      case 'showAll':
        setShowAll(!showAll);
        setAssignedToMe(isActive);
        setFilter({ ...filter, assigneeId: undefined });
        return;
      default:
        return;
    }
  };

  const handleBranchChange = (branchId: string) => {
    setSelectedBranch(branchId);
    setFilter({ ...filter, branchId });
  };

  const setDetailDrawerOpen = () => {
    dispatch(officeVisitActions.openOfficeVisitDetailsDrawer());
  }

  const location = useLocation();

  const modifiedfilter = React.useMemo(() => {
    return {
      ...filter,
      branchId: selectedBranch,
    }
  }, [selectedBranch, filter, officeVisitStatus])

  useEffect(() => {
    if (branchList.length > 0 && branchList.find(b => b.id === loggedInUserBranchId)) {
      const params = new URLSearchParams(location.search.replace('?', ''));
      const preSelectedBranch = params.get('filter.branchId');
      if (preSelectedBranch) {
        setSelectedBranch(preSelectedBranch);
      } else {
        setSelectedBranch(loggedInUserBranchId);
      }
    }
  }, [branchList, loggedInUserBranchId]);

  useEffect(() => {
    if (filter.status !== officeVisitStatus) setFilter({ ...filter });
  }, [officeVisitStatus]);

  if (!selectedBranch) {
    return (
      <div className="page-load">
        <Spin />
      </div>
    )
  }

  return (
    <div className="full-height">
      <PageHeaderTitle title={TEXT.OFFICE_VISIT} />
      <PageHeaderMenu
        menu={PAGE_HEADER_MENU_OFFICE_VISIT}
        defaultActiveKey={name}
      >
        <div className="add-office-visit-btn-container">
          <Space direction='horizontal'>
            <Button type="ghost" onClick={() => setShowShareModal(true)}><ShareAltOutlined />Share</Button>
            <Button type="primary" onClick={showDrawer}>
              <PlusOutlined data-testid="crm-addofficevisitbutton" />{' '}
              {ADD_OFFICE_VISIT}
            </Button>
          </Space>
        </div>
      </PageHeaderMenu>
      <OfficeVisitDrawer
        visible={setDrawerOpen()}
        onClose={handleClose}
        disabled={disabled}
        setDisabled={setDisabled}
        form={form}
        initialData={initialData}
        submitForm={submitForm}
      />
      <OfficeVisitDetailsDrawer
        visible={detailDrawerIsOpen!}
        onClose={handleDetailsDrawerClose}
        setDrawerOpen={setDetailDrawerOpen}
      />
      <div className="bg-white padding-top-2">
        <CrmTable
          tableName={TEXT.OFFICE_VISIT}
          key={name}
          action={getData as any}
          filter={modifiedfilter}
          refresh={refreshTable}
          type={officeVisitStatus}
          columns={getColumns(name)}
          className="lead-table"
          filterContent={
            <SearchBox
              fetchQuery={getBranchesByUserContactPermission}
              valueKey='id'
              labelKey='name'
              value={selectedBranch}
              className="branch-list"
              defaultOpen={false}
              showArrow
              placeholder="Select branch"
              onChange={handleBranchChange}
            />
          }
          tableButtons={
            <>
              <Button
                onClick={() => handleTableButtons('assignedToMe', assignedToMe)}
                type={assignedToMe ? 'primary' : 'default'}
              >
                {LABEL.ASSIGNED_TO_ME}
              </Button>
              <Button
                onClick={() => handleTableButtons('showAll', showAll)}
                type={showAll ? 'primary' : 'default'}
              >
                {LABEL.SHOW_ALL}
              </Button>
            </>
          }
        />
      </div>
      <OfficeVisitUrlShareModal key={selectedBranch} showShareModal={showShareModal} onCancel={() => setShowShareModal(false)} selectedBranch={selectedBranch} />
      {enquiryId ?
        <ViewEnquiryDetailsDrawer key={enquiryId} visible={isEnquiryDrawerOpen} handleClose={handleEnquiryDrawerClose} enquiryId={enquiryId} />
        : null}
      <OfficeVisitApproveModal visible={isApproveModalOpen} dispatch={dispatch} triggerRefresh={triggerRefresh} />
    </div>
  );
};

export { OfficeVisitTableComponent };

