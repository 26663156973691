import { PlusOutlined } from "@ant-design/icons";
import { contactActions, officeVisitActions, useAppSelector } from "@crm/core";
import { useUpdateEnquiryData } from "@crm/libs/hooks/enquiry-form/useUpdateEnquiryData";
import { getVisitPurposeListBasedOnService, queryAssignees } from "@crm/services.api";
import { RootState } from "@crm/src/core/store";
import { useFetchContactEnquiry } from "@crm/src/libs/hooks/enquiries/useFetchContactEnquiry";
import { useOfficeVisitUpdate } from "@crm/src/libs/hooks/office-visits/useOfficeVisitUpdate";
import { useFetchDeals } from "@crm/src/pages/deals/hooks/useFetchDeals";
import UserSearchBox from '@crm/src/shared/user-select-box/user-search-box';
import { DealsInterface } from "@model/crm/deal.model";
import { ContactEnquiry, IOfficeVisitCrm, IVisitPurposeCrm, VisitStatusTypeCrm } from "@model/index";
import { LABEL, TEXT } from "@moxie/constants";
import { useQueryClient } from "@tanstack/react-query";
import { Card, Col, Empty, Form, Row, Select, Spin, Tag, Typography } from "antd";
import Modal from "antd/es/modal";
import Space from "antd/es/space";
import { useForm } from "antd/lib/form/Form";
import { capitalizeFirstLetter, successNotificationHandler } from "libs/shared/src/functions";
import React, { Dispatch, useState } from "react";
import { crmErrorHandler, SearchBox, UnassignedCard, UserCard } from "../../shared";
import { ActiveDealsCard } from "./active-deals-card";
import { ActiveEnquiriesCard } from "./active-enquiries-card";
import { ContactPreviousVisitsCard } from "./contact-previous-visits";
import { ContactProfile } from "./contact-profile";

interface ModalProps {
  visible: boolean;
  dispatch: Dispatch<any>;
  triggerRefresh: () => void;
}

interface IFormData extends IOfficeVisitCrm {
  contactAssigneeId: string;
}

const OfficeVisitApproveModal = ({ visible, dispatch, triggerRefresh }: ModalProps) => {
  const [form] = useForm();
  const [visitPurposes, setVisitPurposes] = useState<IVisitPurposeCrm[]>([]);
  const [visitPurposeLoading, setVisitPurposeLoading] = useState(false);
  const [disabled, setDisabled] = useState(false);
  const [edit, setEdit] = useState({
    contactAssignee: false,
    officeVisitAssignee: false,
  })

  const { officeVisitData, servicesList, socket, loggedUser, loading } = useAppSelector((store: RootState) => {
    return {
      officeVisitData: store.office_visits.singleData,
      servicesList: store.services.allData,
      socket: store.socket.wss,
      loggedUser: store.auth.user,
      loading: store.office_visits.loading
    }
  })

  const queryClient = useQueryClient();
  const updateOfficeVisit = useOfficeVisitUpdate(officeVisitData?.id);
  const { mutate: updateEnquiry } = useUpdateEnquiryData();

  let contactPhone;

  const { contact } = officeVisitData;
  const contactAssignee = contact?.assignee;

  if (contact?.countryCode && contact?.email) {
    contactPhone = contact?.countryCode + " " + contact?.phone
  }

  const { contactEnquiry } = useFetchContactEnquiry(contact?.id, {
    'filter.status': '$in:new,contacted,evaluating,engage immediately,future lead,assessing'
  });

  const { deals } = useFetchDeals({ "filter.contactId": contact?.id, 'filter.status': '$in:discovery, in-progress' }, !!contact?.id)

  const handleClose = () => {
    setTimeout(() => {
      setEdit((prev) => ({
        ...prev,
        officeVisitAssignee: false,
        contactAssignee: false,
      }));
      dispatch(officeVisitActions.closeApproveModal());
      form.resetFields();
    }, 10);
  };

  const getVisitPurposes = async (serviceId: string) => {
    try {
      setVisitPurposeLoading(true);
      if (serviceId) {
        const res = await getVisitPurposeListBasedOnService(serviceId);
        setVisitPurposes(res?.data ?? []);
      }
    } catch (e) {
      crmErrorHandler(e);
      setVisitPurposes([]);
    } finally {
      setVisitPurposeLoading(false);
      form.setFieldsValue({ visitPurposeId: '' });
    }
  };

  const cancelContactAssigneeUpdate = () => {
    setEdit((prev) => {
      return {
        ...prev,
        contactAssignee: false,
      }
    })
  };

  const handleOfficeVisitAssigneeCancel = () => {
    const changedAssignee = form.getFieldValue('assigneeId');
    if (!changedAssignee) {
      setEdit(prev => { return { ...prev, officeVisitAssignee: false } })
    }
  }

  const updateContactAssignee = (value: string) => {
    const assigneeId = value === '' ? null : value;
    const assigneeData = {
      id: contact?.id,
      assigneeId,
    };

    dispatch(
      contactActions.updateContactAssigneeRequest(assigneeData, () => {
        socket?.emit('server::assignee-updated', {
          userId: loggedUser?.id as string,
          contactId: contact?.id as string,
          receiverId: assigneeId,
          companyId: loggedUser?.companyId as string,
          removedAssignee: null,
          activitiesType: 'contact',
          activitiesAction: 'assigned',
          activitiesTypeId: contact?.id,
        });
        dispatch(officeVisitActions.getOfficeVisitRequest(officeVisitData?.id));
      })
    );
    cancelContactAssigneeUpdate();
  };

  const handleCreateOfficeVisitClick = async () => {
    try {
      await form.validateFields();
      form.submit()
    } catch (error) {
      setDisabled(true);
    }
  }

  const handleFieldsChange = (allFields: string | any[]) => {
    if (allFields.length > 0) {
      for (const field of allFields) {
        field.errors.length < 1 ? setDisabled(false) : setDisabled(true);
      }
    }
  };

  const updateEnquiryAssignee = (assigneeId: string | null) => {
    if (assigneeId) {
      updateEnquiry({
        id: officeVisitData?.enquiry?.id,
        assignee: assigneeId
      }, {
        onSuccess: () => {
          successNotificationHandler("Enquiry assignee updated.");
          queryClient.invalidateQueries({
            queryKey: ['contact-enquiry', officeVisitData?.contactId]
          });
        },
      })
    }
  }

  const handleSubmit = (data: Partial<IFormData>) => {
    try {
      // eslint-disable-next-line no-unused-vars
      const { contactAssigneeId, ...restData } = data;
      const submissionData = {
        ...restData,
        status: 'Waiting' as VisitStatusTypeCrm
      }
      updateOfficeVisit.mutate(submissionData, {
        onSuccess() {
          updateEnquiryAssignee(submissionData?.assigneeId as string)
          handleClose();
          triggerRefresh();
        },
      })
    } catch (error) {
      crmErrorHandler(error);
    }
  }

  return (
    <Modal
      visible={visible}
      width={1000}
      bodyStyle={{ overflowY: "auto", maxHeight: 'calc(100vh - 250px)' }}
      title={
        <Space>
          <Typography.Text>{TEXT.OFFICE_VISIT}</Typography.Text>
          <Tag color="purple">{officeVisitData?.enquiry?.type} Contact</Tag>
        </Space>}
      onCancel={handleClose}
      okText={
        <Space>
          <PlusOutlined />
          Create Office Visit
        </Space>
      }
      okButtonProps={{ disabled: disabled }}
      onOk={handleCreateOfficeVisitClick}
    >
      <Row>
        <Col span={10}>
          <ContactProfile
            firstName={contact?.firstName}
            lastName={contact?.lastName}
            email={contact?.email}
            phone={contactPhone}
            status={contact?.status}
            branchName={contact?.branch?.name}
            archivedContact={contact?.archived}
            id={`${contact?.internalId}`}
          />
          <Row className="margin-top-2 w-100">
            <Form form={form} layout="vertical" className="w-100" onFinish={handleSubmit} onFieldsChange={handleFieldsChange}>
              {officeVisitData?.enquiry?.type !== "Existing" ?
                <Form.Item initialValue={contactAssignee?.id ?? ''} name="contactAssigneeId" label="Contact Assignee">
                  <div className="w-100">
                    {!loading ? !edit.contactAssignee ?
                      <div onClick={() => setEdit(prev => { return { ...prev, contactAssignee: true } })}>
                        {!contactAssignee ?
                          <UnassignedCard /> :
                          <UserCard firstName={contactAssignee?.firstName} lastName={contactAssignee?.lastName} email={contactAssignee?.email} />}
                      </div>
                      :
                      <div className="w-100">
                        <UserSearchBox
                          filter={{ contactId: contact?.id }}
                          currentValue={contactAssignee?.id}
                          value={contactAssignee?.id}
                          emptyOption={
                            <Select.Option value="">
                              <UnassignedCard />
                            </Select.Option>
                          }
                          onChange={updateContactAssignee}
                          onDropdownVisibleChange={cancelContactAssigneeUpdate}
                          defaultOpen={true}
                          autoFocus={true}
                        />
                      </div>
                      : <Spin />
                    }
                  </div>
                </Form.Item>
                : null}
              <Form.Item
                name="serviceId"
                label={LABEL.SERVICE}
                rules={[
                  {
                    required: true,
                    message: "Please select service"
                  },
                ]}
              >
                <Select
                  showSearch
                  allowClear
                  placeholder={LABEL.SERVICE}
                  optionFilterProp="search_prop"
                  onChange={getVisitPurposes}
                  disabled={!officeVisitData?.id}
                  className="clear-disable"
                >
                  {servicesList.length > 0 &&
                    servicesList?.map((item: any) => {
                      return (
                        <Select.Option
                          value={item?.id}
                          key={item?.id}
                          search_prop={item?.name}
                        >
                          {item?.name}
                        </Select.Option>
                      );
                    })}
                </Select>
              </Form.Item>
              <Form.Item
                name={"visitPurposeId"}
                label={LABEL.VISIT_PURPOSE}
                rules={[{ required: true }]}
                data-testid="crm-officevisit-visitpurpose"
              >
                <Select
                  showSearch
                  allowClear
                  placeholder={LABEL.VISIT_PURPOSE}
                  disabled={!form.getFieldValue('serviceId') || !officeVisitData?.id}
                  optionFilterProp="search_prop"
                  className={officeVisitData?.id ? 'clear-disable' : ''}
                >
                  {visitPurposeLoading ? (
                    <Select.Option value="loading" disabled>
                      <Spin size="small" className="full-width" />
                    </Select.Option>
                  ) : (
                    visitPurposes.map((item) => {
                      return (
                        <Select.Option
                          value={item?.id}
                          key={item?.id}
                          search_prop={item?.visitPurpose}
                        >
                          {item?.visitPurpose}
                        </Select.Option>
                      );
                    })
                  )}
                </Select>
              </Form.Item>
              <Form.Item name="assigneeId" label="Office Visit Assignee" >
                {!edit.officeVisitAssignee ?
                  <div onClick={() => setEdit(prev => ({ ...prev, officeVisitAssignee: true }))}>
                    {officeVisitData?.assignee ?
                      <UserCard
                        firstName={officeVisitData?.assignee?.firstName}
                        lastName={officeVisitData?.assignee?.lastName}
                        email={officeVisitData?.assignee?.email}
                      /> :
                      <UnassignedCard />
                    }
                  </div>
                  :
                  <SearchBox
                    showArrow
                    hideDefaultOptionView
                    fetchQuery={queryAssignees}
                    filter={{
                      branches: [loggedUser?.branchId]
                    }}
                    optionLabelProp='label'
                    allowClear
                    autoFocus={true}
                    defaultOpen={true}
                    onBlur={handleOfficeVisitAssigneeCancel}
                  >
                    {
                      (option) => {
                        const { id, firstName, lastName, email } = option;
                        return (
                          <Select.Option
                            value={id}
                            label={`${capitalizeFirstLetter(firstName)} ${capitalizeFirstLetter(lastName)}`}
                          >
                            <UserCard
                              firstName={firstName}
                              lastName={lastName}
                              email={email}
                              index={id}
                            />
                          </Select.Option>
                        )
                      }
                    }
                  </SearchBox>
                }
              </Form.Item>
            </Form>
          </Row>
        </Col>
        <Col span={13} offset={1}>
          <>
            <Row className="w-100">
              <Typography.Text strong>Previous Visits</Typography.Text>
            </Row>
            {officeVisitData?.enquiry?.type !== "New" ?
              <ContactPreviousVisitsCard /> :
              <Empty
                description="No previous visit found"
                image={Empty.PRESENTED_IMAGE_SIMPLE}
              />
            }
          </>
          <>
            <Row className="w-100 margin-top-2 margin-bottom-1">
              <Typography.Text strong>Active Enquiries</Typography.Text>
            </Row>
            {contactEnquiry?.length ?
              contactEnquiry.map((enquiry: ContactEnquiry) =>
                <ActiveEnquiriesCard key={enquiry?.id} enquiry={enquiry} />
              )
              :
              <Card>
                <Empty image={Empty.PRESENTED_IMAGE_SIMPLE} description="No active enquiries" />
              </Card>
            }
          </>
          <>
            <Row className="w-100 margin-top-2 margin-bottom-1">
              <Typography.Text strong>Active Deals</Typography.Text>
            </Row>

            {deals?.length ?
              deals.map((deal: DealsInterface) =>
                <ActiveDealsCard key={deal?.id} deal={deal} />
              )
              : <Card>
                <Empty image={Empty.PRESENTED_IMAGE_SIMPLE} description="No active deals" />
              </Card>}
          </>
        </Col>
      </Row >
    </Modal >
  )
}
export { OfficeVisitApproveModal };
