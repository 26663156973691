const alias = 'TASK';

export const GET_TASKS_REQUEST = `GET_${alias}S_REQUEST`;
export const GET_TASKS_SUCCESS = `GET_${alias}S_SUCCESS`;
export const GET_TASKS_FAILURE = `GET_${alias}S_FAILURE`;
export const GET_TASKS_CLEAR = `GET_${alias}S_CLEAR`;

export const DELETE_TASK_REQUEST = `DELETE_${alias}_REQUEST`;
export const DELETE_TASK_SUCCESS = `DELETE_${alias}_SUCCESS`;
export const DELETE_TASK_FAILURE = `DELETE_${alias}_FAILURE`;

export const ADD_TASK_REQUEST = `ADD_${alias}_REQUEST`;
export const ADD_TASK_SUCCESS = `ADD_${alias}_SUCCESS`;
export const ADD_TASK_FAILURE = `ADD_${alias}_FAILURE`;

export const UPDATE_TASK_REQUEST = ` UPDATE_${alias}_REQUEST`;
export const UPDATE_TASK_SUCCESS = ` UPDATE_${alias}_SUCCESS`;
export const UPDATE_TASK_FAILURE = ` UPDATE_${alias}_FAILURE`;

export const UPDATE_TASK_STATUS_REQUEST = ` UPDATE_${alias}_STATUS_REQUEST`;
export const UPDATE_TASK_STATUS_SUCCESS = ` UPDATE_${alias}_STATUS_SUCCESS`;
export const UPDATE_TASK_STATUS_FAILURE = ` UPDATE_${alias}_STATUS_FAILURE`;

export const UPDATE_TASK_ASSIGNEE_REQUEST = ` UPDATE_${alias}_ASSIGNEE_REQUEST`;
export const UPDATE_TASK_ASSIGNEE_SUCCESS = ` UPDATE_${alias}_ASSIGNEE_SUCCESS`;
export const UPDATE_TASK_ASSIGNEE_FAILURE = ` UPDATE_${alias}_ASSIGNEE_FAILURE`;

export const DOWNLOADING = 'DOWNLOADING';
