import React from 'react';
import { Row, Col, Tooltip, Avatar, Space } from 'antd';
import Form from 'antd/lib/form';
import { UserOutlined } from '@ant-design/icons';
import { UserSelectBox } from '@shared-components/elements';
import { ASSIGN_TO_BTN } from '@moxie/constants';
import { ITableAssigneeEdit } from '@shared-components/models';

export const TableAssignee: React.FC<ITableAssigneeEdit> = ({
  handleSubmit,
  handleCancel,
  assigneeId,
  archived,
  users,
}: ITableAssigneeEdit) => {
  const handleClick = (value: any) => {
    handleSubmit && handleSubmit(value);
  };
  return (
    <Row className="lead-table__name user-name-card" style={{ width: '8rem' }}>
      <Col className="align-items-center user-name-card__avatar">
        <Tooltip title="unassigned">
          <Avatar icon={<UserOutlined />} alt="unassigned" />
        </Tooltip>
      </Col>
      <Col>
        <Form layout="inline">
          <Space direction="horizontal">
            <Form.Item name="assignee_user_id" className="inline_input_width">
              <UserSelectBox
                defaultOpen={true}
                placeholder={ASSIGN_TO_BTN}
                showArrow={false}
                bordered={false}
                onChange={handleClick}
                onBlur={handleCancel}
                autoFocus={true}
                selected_id={assigneeId}
                showUnassigned={true}
                archived={archived ? archived : undefined}
                users={users}
              />
            </Form.Item>
          </Space>
        </Form>
      </Col>
    </Row>
  );
};
