import axios from './axios';
import { API_URL } from '@moxie/constants';
import { IResponse, IWorkflowType } from '@shared-components/models';
import crmAxios from './crm-api-axios';

const addCRMWorkflowType = async (data: IWorkflowType): Promise<IResponse> => {
  return crmAxios.post(`${API_URL.CRM_API_WORKFLOW_TYPE}`, data);
};

const updateCRMWorkflowType = async (
  data: IWorkflowType
): Promise<IResponse> => {
  return axios.put(`${API_URL.CRM_WORKFLOW_TYPE}/${data.id}`, data);
};

const deleteCRMWorkflowType = async (id: string): Promise<IResponse> => {
  return axios.delete(`${API_URL.CRM_WORKFLOW_TYPE}/${id}`);
};

const getOneCRMWorkflowType = async (id: string): Promise<IResponse> => {
  return axios.get(`${API_URL.CRM_WORKFLOW_TYPE}/${id}`);
};

const getCRMWorkflowTypeList = async (params = ''): Promise<IResponse> => {
  return axios.get(`${API_URL.CRM_WORKFLOW_TYPE}/${params}`);
};
const getCRMWorkflowTypeByServiceId = async (
  id: string,
  params?: Record<string, unknown>
)=> {
  const data = await crmAxios.get(`${API_URL.CRM_API_WORKFLOW_TYPE}/`, {
    params: {
      ...params,
      'filter.serviceId': id,
    }
  });
  return data?.data;
};

export {
  addCRMWorkflowType,
  updateCRMWorkflowType,
  deleteCRMWorkflowType,
  getOneCRMWorkflowType,
  getCRMWorkflowTypeList,
  getCRMWorkflowTypeByServiceId,
};
