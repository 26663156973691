import React, { useEffect, useState } from 'react';
import { Col, Dropdown, Input, Menu, Row, Spin, Typography } from 'antd';
import { Button, MenuItem, Option, Select } from '@shared-components/elements';
import { CheckOutlined, CloseOutlined, MoreOutlined } from '@ant-design/icons';
import {
  BUTTON,
  LABEL,
  PLACEHOLDER,
  STAGE_NAME_REQUIRED,
  STAGE_NAME_TOO_LONG,
  TEXT,
  WORKFLOW_STAGE_ACTORS,
  WORKFLOW_STAGE_TABS,
} from '@moxie/constants';
import Form from 'antd/lib/form';
import { useForm } from 'antd/lib/form/Form';
import { useDispatch } from 'react-redux';
import { workflowActions } from '@admin/core';
import {
  addWorkflowStage,
  deleteWorkflowStage,
  updateWorkflowStage,
} from '@admin/services.api';
import { errorHandler } from '@moxie/utils';
import { WorkflowStageChecklists } from './workflow-stage-checklists';
import { WorkflowForm } from '@admin/shared';
import {
  successNotificationHandler,
  warnNotificationHandler,
} from '@moxie/shared';
import { IResponse, IWorkflowStagesProps } from '@shared-components/models';

const WorkflowStages = ({
  loading,
  stagesLoading,
  stageList,
  workflowId,
}: IWorkflowStagesProps) => {
  const [form] = useForm();
  const dispatch = useDispatch();
  const [submitted, setSubmitted] = useState(false);
  const [stages, setStages] = useState<any>(stageList);
  const [selectedStage, setSelectedStage] = useState<string>('');
  const [stageToEdit, setStageToEdit] = useState('');
  const [addFormIsVisible, setAddFormVisible] = useState(false);
  const [selectedTab, setSelectedTab] = useState(WORKFLOW_STAGE_TABS.CHECKLIST);

  const onFormSubmit = async (data: any) => {
    try {
      setSubmitted(true);
      await addWorkflowStage({
        ...data,
        workflow_id: workflowId,
      });
      dispatch(workflowActions.getWorkflowStagesRequest(workflowId));
      setAddFormVisible(false);
      setStageToEdit('');
      form.resetFields();
    } catch (err) {
      errorHandler(err);
    } finally {
      setSubmitted(false);
    }
  };

  const handleStageSelect = (id: string) => {
    dispatch(workflowActions.getWfStageChecklists(id));
    setSelectedStage(id);
  };
  const handleEditBtnClick = (id: string) => {
    setSelectedStage(id);
    setStageToEdit(id);
    setAddFormVisible(false);
  };

  const handleDeleteBtnClick = async (id: string) => {
    try {
      const response: IResponse = await deleteWorkflowStage(id);
      id === stageList[0]?.id
        ? setSelectedStage(stageList[1]?.id)
        : setSelectedStage(stageList[0]?.id);
      successNotificationHandler(response?.message);
      dispatch(workflowActions.getWorkflowStagesRequest(workflowId));
    } catch (e: any) {
      warnNotificationHandler(`Cannot delete. ${e?.response?.data?.message}`);
    }
  };

  const handleCancelFormButton = () => {
    setStageToEdit('');
    form.resetFields();
  };

  const handleAddStageBtn = () => {
    setStageToEdit('');
    setAddFormVisible(true);
  };

  const handleCloseStageForm = () => {
    setAddFormVisible(false);
    form.resetFields();
  };

  const handleEditSubmit = async (data: any) => {
    try {
      setSubmitted(true);
      const response = await updateWorkflowStage({
        id: selectedStage,
        ...data,
      });
      successNotificationHandler(response?.data?.message);
      setStageToEdit('');
      dispatch(workflowActions.getWorkflowStagesRequest(workflowId));
    } catch (err) {
      errorHandler(err);
    } finally {
      setSubmitted(false);
    }
  };

  useEffect(() => {
    dispatch(workflowActions.getAllChecklistsRequest());
  }, []);

  useEffect(() => {
    if (!selectedStage) {
      setSelectedStage(stageList[0]?.id);
    }
    setStages(stageList);
  }, [stageList]);

  useEffect(() => {
    dispatch(workflowActions.getWfStageChecklists(selectedStage));

    return () => {
      dispatch(workflowActions.clearWfStageChecklist());
    };
  }, [selectedStage]);

  return (
    <Row
      className="padding-top-2"
      justify={selectedStage ? 'space-around' : 'start'}
      gutter={selectedStage ? 20 : 0}
    >
      <Col span={9} className="other-details__category">
        <Row justify="space-between" className="margin-bottom-1">
          <Typography.Title level={5}>{LABEL.STAGES}</Typography.Title>
        </Row>
        <Spin spinning={loading ? false : submitted || stagesLoading}>
          <Row>
            {stages &&
              stages.map((stage: any) => {
                return (
                  <Col
                    span={24}
                    className={`other-details__category__list margin-top-1 ${
                      selectedStage?.toString() === stage?.id?.toString() &&
                      'other-details__category__list__selected'
                    }`}
                    key={stage.stage}
                  >
                    {stageToEdit === stage.id ? (
                      <Form
                        initialValues={stage}
                        onFinish={(data) => handleEditSubmit(data)}
                        className="margin-top-2 padding-top-1"
                      >
                        <Row>
                          <Form.Item
                            name="stage"
                            rules={[
                              { required: true, message: STAGE_NAME_REQUIRED },
                              { max: 30, message: STAGE_NAME_TOO_LONG },
                              {
                                type: 'string',
                                whitespace: true,
                                message: STAGE_NAME_REQUIRED,
                              },
                            ]}
                            className="margin-right-1"
                          >
                            <Input className="general-setting-category-input" />
                          </Form.Item>
                          <Form.Item name="actor">
                            <Select
                              className="document_type_select_width"
                              placeholder={PLACEHOLDER.ACTOR}
                            >
                              {WORKFLOW_STAGE_ACTORS.map((item) => (
                                <Option value={item.value}>{item.name}</Option>
                              ))}
                            </Select>
                          </Form.Item>
                          <Button
                            icon={<CheckOutlined />}
                            type="link"
                            size="small"
                            htmlType="submit"
                            className="padding-left-2 stage-form-icon-position"
                          />

                          <Button
                            icon={<CloseOutlined />}
                            type="link"
                            danger
                            onClick={handleCancelFormButton}
                            size="small"
                            className="stage-form-icon-position"
                          />
                        </Row>
                      </Form>
                    ) : (
                      <>
                        <Col onClick={() => handleStageSelect(stage.id)}>
                          <Row
                            justify="space-between"
                            className="cursor-pointer"
                          >
                            <Typography.Text strong className="initial_capital">
                              {stage?.stage}
                            </Typography.Text>
                            <Typography.Text strong>
                              {stage?.actor}
                            </Typography.Text>
                          </Row>
                        </Col>
                        <Dropdown
                          overlay={MenuItem(
                            () => handleEditBtnClick(stage?.id),
                            () => handleDeleteBtnClick(stage?.id)
                          )}
                        >
                          <Button type="link" icon={<MoreOutlined />} />
                        </Dropdown>
                      </>
                    )}
                  </Col>
                );
              })}
            <Col
              span={24}
              className="other-details__category__list margin-top-1"
            >
              <>
                <Col>
                  <Row justify="space-between" className="cursor-pointer">
                    <Typography.Text className="complete-stage-text" strong>
                      {TEXT.COMPLETE}
                    </Typography.Text>
                    <Typography.Text className="complete-stage-text" strong>
                      {TEXT.ADMISSION}
                    </Typography.Text>
                  </Row>
                </Col>
                <Dropdown overlay={<></>}>
                  <Button type="link" icon={<MoreOutlined />} />
                </Dropdown>
              </>
            </Col>
            <Col span={24}>
              <Form form={form} onFinish={(data) => onFormSubmit(data)}>
                {addFormIsVisible && (
                  <Row className="margin-top-2">
                    <Form.Item
                      name="stage"
                      className="margin-right-1"
                      rules={[
                        { required: true, message: STAGE_NAME_REQUIRED },
                        { max: 30, message: STAGE_NAME_TOO_LONG },
                        {
                          type: 'string',
                          whitespace: true,
                          message: STAGE_NAME_REQUIRED,
                        },
                      ]}
                    >
                      <Input
                        placeholder={PLACEHOLDER.STAGE_NAME}
                        className="general-setting-category-input"
                      />
                    </Form.Item>
                    <Form.Item name="actor">
                      <Select
                        className="document_type_select_width"
                        placeholder={PLACEHOLDER.ACTOR}
                      >
                        {WORKFLOW_STAGE_ACTORS.map((item) => (
                          <Option value={item.value}>{item.name}</Option>
                        ))}
                      </Select>
                    </Form.Item>
                    <Button
                      icon={<CheckOutlined />}
                      type="link"
                      htmlType="submit"
                      size="small"
                      className="padding-left-1"
                    />
                    <Button
                      icon={<CloseOutlined />}
                      onClick={handleCloseStageForm}
                      type="link"
                      danger
                      size="small"
                    />
                  </Row>
                )}
              </Form>
            </Col>
            <Col span={24} className="padding-top-1">
              <Button
                type="primary"
                className="full-width"
                size="middle"
                onClick={handleAddStageBtn}
              >
                {BUTTON.ADD_STAGES}
              </Button>
            </Col>
          </Row>
        </Spin>
      </Col>
      {selectedStage && (
        <Col span={14} className="other-details__labels">
          <Row className="page-header margin-top-n2">
            <Col className="page-header__menu">
              <Menu selectedKeys={[selectedTab]} mode="horizontal">
                <Menu.Item key={WORKFLOW_STAGE_TABS.CHECKLIST}>
                  <Typography.Text
                    strong
                    onClick={() =>
                      setSelectedTab(WORKFLOW_STAGE_TABS.CHECKLIST)
                    }
                  >
                    {LABEL.CHECKLIST}
                  </Typography.Text>
                </Menu.Item>
                <Menu.Item key={WORKFLOW_STAGE_TABS.FORM}>
                  <Typography.Text
                    strong
                    onClick={() => setSelectedTab(WORKFLOW_STAGE_TABS.FORM)}
                  >
                    {LABEL.FORM}
                  </Typography.Text>
                </Menu.Item>
              </Menu>
            </Col>
          </Row>
          {selectedTab === WORKFLOW_STAGE_TABS.CHECKLIST ? (
            <WorkflowStageChecklists stageId={selectedStage} />
          ) : null}
          {selectedTab === WORKFLOW_STAGE_TABS.FORM && (
            <WorkflowForm stageId={selectedStage} />
          )}
        </Col>
      )}
    </Row>
  );
};

export { WorkflowStages };
