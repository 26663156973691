import { CommentOutlined } from '@ant-design/icons';
import { applicationActions } from '@lyra/core';
import { GUTTER, TEXT } from '@moxie/constants';
import { Button } from '@moxie/shared';
import { errorHandler } from '@moxie/utils';
import { ILeadApplication } from '@shared-components/models';
import { Card, Col, Row, Space, Tooltip } from 'antd';
import { useForm } from 'antd/lib/form/Form';
import React, { useState } from 'react';
import { useDispatch } from 'react-redux';
import { ApplicationActivityLogsDrawer } from './application-activity-log-drawer';

const ApplicationDetailProfile: React.FC<any> = (
  applicationDetail: ILeadApplication
) => {
  const {
    institution,
    workflow,
    branch,
    product,
    app_identifier,
    company,
    user,
    type,
  } = applicationDetail;

  const [initialData, setInitialData] = useState<any>();
  const [isEdit, setIsEdit] = useState<boolean>(false);
  const [visible, setVisible] = useState(false);
  const [isSubmitted, setIsSubmitted] = useState(false);
  const [commentFormButton, setCommentFormButton] = useState(true);
  const [form] = useForm();
  const dispatch = useDispatch();

  const showDrawer = () => {
    setInitialData({});
    setVisible(true);
    if (applicationDetail?.id)
      dispatch(applicationActions.getCommentRequest(applicationDetail.id));
  };

  const onClose = () => {
    setVisible(false);
    setIsEdit(false);
    form.resetFields();
    setCommentFormButton(true);
  };

  const handleFormSubmit = () => {
    form.submit();
  };
  const handleSubmit = (data: any) => {
    try {
      data.application_id = applicationDetail?.id;
      if (type === 'Admission') {
        data.created_by_admin = user?.id;
      } else {
        data.created_by = user?.id;
      }
      data.workflow_application_stage_id = applicationDetail?.active_stage_id;
      dispatch(applicationActions.addCommentRequest(data));
      setIsSubmitted(true);
    } catch (err) {
      errorHandler(err);
    }
    form.resetFields();
    setCommentFormButton(true);
  };

  return (
    <section className="profile">
      <ApplicationActivityLogsDrawer
        isVisible={visible}
        onClose={onClose}
        handleFormSubmit={handleFormSubmit}
        form={form}
        isEdit={isEdit}
        isSubmitted={isSubmitted}
        handleSubmit={handleSubmit}
        initialData={initialData}
        disabled={commentFormButton}
        setDisabled={setCommentFormButton}
        type={type}
      />

      <Row
        gutter={[GUTTER, GUTTER]}
        className="custom-white-background custom_vertical_divider_left"
      >
        <Col span={20}>
          <Card bordered={false} className="margin-top-n1 padding-top-1">
            <Row gutter={[GUTTER, 16]}>
              <Col xl={8} lg={12} md={10}>
                <div className="profile__info__item">
                  <Space>
                    <h4 className="profile__title margin-0">
                      {TEXT.APPLICATION_ID}:
                    </h4>
                    <Tooltip title={app_identifier}>
                      <span className="profile__link">
                        {company?.company_code + '-' + app_identifier}
                      </span>
                    </Tooltip>
                  </Space>
                </div>
              </Col>
              <Col xl={8} lg={12} md={10}>
                <div className="profile__info__item">
                  <Space>
                    <h4 className="profile__title margin-0">
                      {TEXT.INSTITUTION}:
                    </h4>
                    <Tooltip title={institution?.name}>
                      <span className="profile__link">{institution?.name}</span>
                    </Tooltip>
                  </Space>
                </div>
              </Col>
              <Col xl={8} lg={12} md={10}>
                <div className="profile__info__item">
                  <Space>
                    <h4 className="profile__title margin-0">{TEXT.SERVICE}:</h4>
                    <span className="profile__link initial_capital">
                      {workflow?.workflowType?.service?.name}
                    </span>
                  </Space>
                </div>
              </Col>
              <Col xl={8} lg={12} md={10}>
                <div className="profile__info__item">
                  <Space>
                    <h4 className="profile__title margin-0">{TEXT.COURSE}:</h4>
                    <Tooltip title={product?.name}>
                      <span className="profile__link">{product?.name}</span>
                    </Tooltip>
                  </Space>
                </div>
              </Col>
              <Col xl={8} lg={12} md={10}>
                <div className="profile__info__item">
                  <Space>
                    <h4 className="profile__title margin-0">Branch:</h4>
                    <Tooltip title={branch?.name}>
                      <span className="profile__link">{branch?.name}</span>
                    </Tooltip>
                  </Space>
                </div>
              </Col>
              <Col xl={8} lg={12} md={10}>
                <div className="profile__info__item">
                  <Space>
                    <h4 className="profile__title margin-0">
                      {TEXT.WORKFLOW}:
                    </h4>
                    <Tooltip
                      title={`${workflow?.workflowType?.name} (${workflow?.country})`}
                    >
                      <span className="profile__link">{`${workflow?.workflowType?.name} (${workflow?.country})`}</span>
                    </Tooltip>
                  </Space>
                </div>
              </Col>
            </Row>
          </Card>
        </Col>
        <Col span={4} className="custom_vertical_divider_right">
          <Button
            className="custom_comments_button"
            icon={<CommentOutlined />}
            onClick={showDrawer}
          >
            Activity Log
          </Button>
        </Col>
      </Row>
    </section>
  );
};

export { ApplicationDetailProfile };
