import React from "react";
import Button, { ButtonProps } from "antd/es/button";
import { CustomArchiveIcon } from "../custom-archive-icon/custom-archive-icon";
import Space from "antd/es/space";

const ArchiveButton: React.FC<ButtonProps> = ({ onClick, ...props }) => {
  return (
    <Button className="archive-btn" onClick={onClick} {...props}>
      <Space direction="horizontal">
        <CustomArchiveIcon width="20" height="20" outlineColor="#f5222d" />
        Archive
      </Space>
    </Button>
  )
}

export { ArchiveButton };
