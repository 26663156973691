/* eslint-disable no-unused-vars */
import React, { useState } from 'react';
import { Col, Form, FormItemProps, Row, Select } from 'antd';
import {
  validateCompanyCode,
  validateCompanyDomain,
} from '@luzon/services.api';
import { FormItem, Input, PhoneInput } from '@shared-components/elements';
import Title from 'antd/lib/typography/Title';
import {
  FORM_SIZE,
  ROLES,
  GUTTER,
  COMPANY_NAME_PLACEHOLDER,
  INSTITUTION_SUB_DOMAIN_PLACEHOLDER,
  INSTITUTION_PHONE_NUMBER_PLACEHOLDER,
  INSTITUTION_POSITION_PLACEHOLDER,
  COMPANY_NAME_LABEL,
  YOUR_ROLE_LABEL,
  INSTITUTION_PHONE_NUMBER_LABEL,
  DOMAIN_INVALID,
  DOMAIN_TAKEN,
  PHONE_NUMBER_INVALID,
  COMPANY_CODE,
  COMPANY_CODE_TAKEN,
  CODE_INVALID,
} from '@moxie/constants';

import { errorHandler } from '@moxie/utils';
import { IForm, IInstitution } from '@shared-components/models';
import { FieldData } from 'rc-field-form/es/interface.js';

const { Option } = Select;

interface IInstitutionForm<T> extends IForm<T> {
  dialCode: string | undefined;
  setBackButtonDisabled: any;
}

const InstitutionDetailsForm: React.FC<IInstitutionForm<IInstitution>> = ({
  onSubmit,
  dialCode,
  form,
  isValid,
  setBackButtonDisabled,
}: IInstitutionForm<IInstitution>) => {
  const [
    companyDomainValidationStatus,
    setCompanyDomainValidationStatus,
  ] = useState<FormItemProps['validateStatus']>('');
  const [
    companyCodeValidationStatus,
    setCompanyCodeValidationStatus,
  ] = useState<FormItemProps['validateStatus']>('');
  const [dialCodeState, setDialCode] = useState(dialCode);
  const handleSubmit = (data: IInstitution) => {
    data.country_code = dialCodeState;
    onSubmit && onSubmit(data);
  };

  async function handleSubDomainChange(
    rule: any,
    value: any
  ): Promise<boolean | any> {
    value = value?.toLowerCase();
    if (!value) {
      setCompanyDomainValidationStatus('error');
      return Promise.resolve(true);
    }
    const regex = new RegExp(/^[a-z]+$/i);
    if (!regex.test(value)) {
      setCompanyDomainValidationStatus('error');
      throw new Error(DOMAIN_INVALID);
    } else {
      setCompanyDomainValidationStatus('validating');
      const result = await validateDomain(value);
      if (result) {
        setCompanyDomainValidationStatus('success');
        return Promise.resolve(true);
      } else {
        setCompanyDomainValidationStatus('error');
        throw new Error(DOMAIN_TAKEN);
      }
    }
  }

  const handleCodeChange = async (
    rule: any,
    value: any
  ): Promise<boolean | any> => {
    value = value?.toLowerCase();
    if (!value) {
      setCompanyCodeValidationStatus('error');
      return Promise.resolve(true);
    }
    const regex = new RegExp(/^[a-z]+$/i);
    if (!regex.test(value)) {
      setCompanyCodeValidationStatus('error');
      throw new Error(CODE_INVALID);
    } else {
      setCompanyCodeValidationStatus('validating');
      const result = await validateCode(value);
      if (result) {
        setCompanyCodeValidationStatus('success');
        return Promise.resolve(true);
      } else {
        setCompanyCodeValidationStatus('error');
        throw new Error(COMPANY_CODE_TAKEN);
      }
    }
  };

  const handleChange = (_data: FieldData[], data: FieldData[]) => {
    const backButtonError: boolean[] = [];
    form.getFieldsError().map((item) => {
      if (item.errors.length > 0) {
        backButtonError.push(true);
      } else {
        backButtonError.push(false);
      }

      if (backButtonError?.includes(true)) {
        setBackButtonDisabled(true);
      } else {
        setBackButtonDisabled(false);
      }
    });

    let isFieldsTouched = false;
    isFieldsTouched = form.isFieldsTouched(true);
    let error = false;
    let valueIsEmpty = false;

    data.forEach((item: any) => {
      if (!item?.value || item?.value?.length === 0) {
        valueIsEmpty = true;
      }
    });
    isFieldsTouched = isFieldsTouched || !valueIsEmpty;
    form.getFieldsError().forEach((item) => {
      if (item.errors.length > 0) {
        error = true;
      }
    });
    isValid && isValid(!error && isFieldsTouched);
  };

  const validateDomain = (value: string) => {
    return validateCompanyDomain(value)
      .then((res: any) => {
        return !!res?.data?.success;
      })
      .catch((err) => {
        setCompanyDomainValidationStatus('error');
      });
  };

  const validateCode = (value: string) => {
    return validateCompanyCode({ code: value })
      .then((res: any) => {
        return !!res?.data?.success;
      })
      .catch((err) => {
        setCompanyCodeValidationStatus('error');
      });
  };
  return (
    <>
      <Title level={4}>Company Details</Title>
      <Form
        name="institution-details-form"
        data-testid="institution-details-form"
        layout="vertical"
        size={FORM_SIZE}
        form={form}
        onFinish={handleSubmit}
        onFieldsChange={handleChange}
      >
        <div>
          <Row gutter={GUTTER}>
            <Col span="8">
              <FormItem
                name="company_name"
                label={COMPANY_NAME_LABEL}
                rules={[{ required: true, max: 100, type: 'string' }]}
              >
                <Input placeholder={COMPANY_NAME_PLACEHOLDER} />
              </FormItem>
            </Col>
            <Col span="8">
              <FormItem
                validateFirst={'parallel'}
                name="company_code"
                validateStatus={companyCodeValidationStatus}
                label={COMPANY_CODE}
                rules={[
                  { required: true, max: 5 },
                  { validator: handleCodeChange },
                ]}
              >
                <Input maxLength={5} placeholder={COMPANY_CODE} />
              </FormItem>
            </Col>
            <Col span="8">
              <FormItem
                name="company_domain"
                hasFeedback
                extra={`${
                  form.getFieldValue('company_domain')
                    ? form.getFieldValue('company_domain')
                    : 'domainName'
                }.${process.env.NX_APP_DOMAIN}`}
                validateStatus={companyDomainValidationStatus}
                label="Domain name"
                rules={[
                  { required: true, max: 20 },
                  { validator: handleSubDomainChange },
                ]}
              >
                <Input
                  maxLength={20}
                  placeholder={INSTITUTION_SUB_DOMAIN_PLACEHOLDER}
                />
              </FormItem>
            </Col>
          </Row>
          <FormItem
            name="position"
            label={YOUR_ROLE_LABEL}
            rules={[{ required: true, type: 'string' }]}
          >
            <Select placeholder={INSTITUTION_POSITION_PLACEHOLDER}>
              {ROLES.map((item) => {
                return (
                  <Option value={item.value} key={item.value}>
                    {item.name}
                  </Option>
                );
              })}
            </Select>
          </FormItem>
          <FormItem
            name="phone"
            label={INSTITUTION_PHONE_NUMBER_LABEL}
            rules={[
              {
                required: true,
                pattern: new RegExp(/^[0-9]*$/),
                message: PHONE_NUMBER_INVALID,
              },
            ]}
          >
            <PhoneInput
              placeholder={INSTITUTION_PHONE_NUMBER_PLACEHOLDER}
              type="string"
              maxLength={15}
              onChangeDialCode={setDialCode}
              dialCode={dialCodeState || '+977'}
            />
          </FormItem>
          <FormItem
            name="branch_name"
            label="Branch Name"
            rules={[{ required: true, max: 100, type: 'string' }]}
          >
            <Input placeholder={'Main Branch'} type="string" />
          </FormItem>
        </div>
      </Form>
    </>
  );
};

export { InstitutionDetailsForm };
