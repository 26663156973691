import { API_URL } from "@moxie/constants"
import { EventInput } from "@fullcalendar/core"
import { CalendarEvent } from "@model/calendar"
import crmAxios from "./crm-api-axios"

export const fetchGoogleEvents = async () => {
  const { data: { data } } = await crmAxios.get<{ data: EventInput[] }>(API_URL.CALENDAR)
  return data
}

export const deleteEvent = async (event: string) => crmAxios.delete(`${API_URL.CALENDAR}/${event}`)

export const addNewEvent = (event: CalendarEvent) => crmAxios.post(API_URL.CALENDAR, event)

export const fetchAuthorizeUrl = async (): Promise<string> => {
  const { data: { data } } = await crmAxios.get<{ data: string }>(`${API_URL.OAUTH_USER}/authorize-url`, { withCredentials: true })
  return data
}

export const updateCalendarEvent = (eventId: string, event: CalendarEvent) => crmAxios.patch(`${API_URL.CALENDAR}/${eventId}`, event)
