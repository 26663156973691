import React, { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import Form from 'antd/lib/form';
import { Col, Row, Select, Spin, Typography } from 'antd';
import { useForm } from 'antd/lib/form/Form';
import { CloseOutlined } from '@ant-design/icons';
import { useAppSelector, workflowActions } from '@admin/core';
import { addWfStageChecklist } from '@admin/services.api';
import { SearchableSelect, Option } from '@moxie/shared';
import { IChecklistData, IStageChecklist } from '@shared-components/models';
import { Button } from '@shared-components/elements';
import {
  ADD_BTN,
  CHECKLISTS_REQUIRED,
  GUTTER,
  PLACEHOLDER,
} from '@moxie/constants';
import { Key } from 'rc-select/lib/interface/generator';
import _ from 'lodash';

const WorkflowStageChecklists = ({ stageId }: { stageId: string }) => {
  const { Option, OptGroup } = Select;
  const [form] = useForm();
  const dispatch = useDispatch();
  const allChecklists = useAppSelector(
    (state) => state.workflows.allChecklists
  );
  const { checklists, loading } = useAppSelector((state) => ({
    checklists: state.workflows.checklists,
    loading: state.workflows.checklists_loading,
  }));
  const [allChecklistArray, setAllChecklistArray] = useState<IChecklistData[]>(
    []
  );
  const [submitted, setSubmitted] = useState(false);
  const groupedData: any = [];

  const handleDeleteChecklist = (id: string) => {
    dispatch(workflowActions.deleteStageChecklistRequest(id));
  };

  const handlePostChecklist = async (data: Array<number>) => {
    setSubmitted(true);
    await addWfStageChecklist({ stageId, data });
    dispatch(workflowActions.getWfStageChecklists(stageId));
    form.resetFields();
    setSubmitted(false);
  };

  useEffect(() => {
    form.resetFields();
  }, [stageId]);

  useEffect(() => {
    const formatData = allChecklists.filter((item: IChecklistData) => {
      return !checklists.find((obj: IStageChecklist) => {
        return item.id === obj.checklist.id;
      });
    });
    const groupData = _.groupBy(formatData, 'type');
    groupedData.push(groupData);
    setAllChecklistArray(groupedData[0]);

    return () => {
      form.resetFields();
    };
  }, [checklists, dispatch]);

  return (
    <div className="margin-top-2">
      <Spin spinning={loading || submitted}>
        <Form layout="vertical" form={form} onFinish={handlePostChecklist}>
          <Row gutter={GUTTER}>
            <Col span={20}>
              <Form.Item
                name="checklists"
                rules={[{ required: true, message: CHECKLISTS_REQUIRED }]}
              >
                <SearchableSelect
                  placeholder={PLACEHOLDER.MULTIPLE_CHECKLISTS}
                  mode="multiple"
                >
                  {Object.entries(allChecklistArray).map(
                    (item: any, index: number) => (
                      <OptGroup label={item[0]} key={index}>
                        {item[1]?.map((pos: { id: Key; name: string }) => {
                          return (
                            <Option
                              value={pos?.id}
                              key={pos?.name}
                              className="document-option-type"
                            >
                              {pos.name}
                            </Option>
                          );
                        })}
                      </OptGroup>
                    )
                  )}
                </SearchableSelect>
              </Form.Item>
            </Col>
            <Col span={4}>
              <Form.Item>
                <Button htmlType="submit" type="primary">
                  {ADD_BTN}
                </Button>
              </Form.Item>
            </Col>
          </Row>
        </Form>
        <Row gutter={[16, 16]} className="padding-x1 margin-top-2">
          {checklists &&
            checklists.map((stageChecklist: IStageChecklist) => {
              return (
                <Col span={7} className="checklist-items">
                  <>
                    <Col>
                      <Typography.Text strong>
                        {stageChecklist.checklist.name}
                      </Typography.Text>
                    </Col>
                    <Col
                      onClick={() => handleDeleteChecklist(stageChecklist.id)}
                    >
                      <CloseOutlined />
                    </Col>
                  </>
                </Col>
              );
            })}
        </Row>
      </Spin>
    </div>
  );
};

export { WorkflowStageChecklists };
