import React, { useEffect, useState, useCallback } from 'react';
import {
  InstitutionDetailsForm,
  PersonalDetailsForm,
  WorkOrderScopeDetailsForm,
} from '@shared-components/forms';
import { Row, Col, Steps, Space, Typography } from 'antd';
import { RegistrationLeftPane } from '../registration-left-pane';
import { REGISTRATION_LEFT_PANE_TEXT, GRID } from './libs/constants';
import { Button } from '@shared-components/elements';
import PricePlan from '../price-plan';
import { useForm } from 'antd/lib/form/Form';
import {
  IInstitution,
  IPersonalInformation,
  IRegistration,
  IRegistrationModule,
  IWorkOrderScope,
} from '@shared-components/models';
import { COUNTRIES } from '@moxie/constants';

const { Step } = Steps;
const { Paragraph } = Typography;
interface IFormData {
  personalInformation?: IPersonalInformation;
  institution?: IInstitution;
  pricePlan?: number;
  workOrderScope?: IWorkOrderScope;
}

const Registration: React.FC<IRegistrationModule> = ({
  onSubmit,
}: IRegistrationModule) => {
  const [current, setCurrent] = useState<number>(0);
  const [formData, setFormData] = useState<IFormData>();
  const [personalDetailsForm] = useForm();
  const [personalDetailIsValid, setPersonalDetailIsValid] = useState(false);
  const [institutionDetailIsValid, setinstitutionDetailIsValid] = useState(
    false
  );
  const [workScopeDetailIsValid, setworkScopeDetailIsValid] = useState(false);
  const [institutionDetailsForm] = useForm();
  const [workOrderScopeDetailForm] = useForm();
  const [pricePlanSelected, setPricePlanSelected] = useState(false);
  const [loading, setLoading] = useState(false);
  const [disabled, setDisabled] = useState(true);
  const [backButtonDisabled, setBackButtonDisabled] = useState(false);

  const handlePersonalInformationSave = (data: IPersonalInformation) => {
    setFormData({ ...formData, personalInformation: data });
    changeCurrent();
  };
  const handleInstitutionSave = (data: IInstitution) => {
    setFormData({ ...formData, institution: data });
    changeCurrent();
  };
  const handleWorkOrderScopeSave = (data: IWorkOrderScope) => {
    setFormData({ ...formData, workOrderScope: data });
    if (current === 3) {
      handleSubmit({ ...formData, workOrderScope: data });
    }
    changeCurrent();
  };
  const handlePricePlanChange = (id: number) => {
    setPricePlanSelected(true);
    setFormData({ ...formData, pricePlan: id });
  };
  const handlePersonalValidation = (data: boolean) => {
    if (current === 0) {
      setPersonalDetailIsValid(data);
    }
  };
  const handleInstitutionValidation = (data: boolean) => {
    if (current === 1) {
      setinstitutionDetailIsValid(data);
    }
  };
  const handleWorkOrderScopeValidation = (data: boolean) => {
    if (current === 3) {
      setworkScopeDetailIsValid(data);
    }
  };

  const STEPS = [
    {
      title: 'Introduction',
      content: (
        <PersonalDetailsForm
          form={personalDetailsForm}
          onSubmit={handlePersonalInformationSave}
          isValid={handlePersonalValidation}
        />
      ),
    },
    {
      title: 'Company',
      content: (
        <InstitutionDetailsForm
          setBackButtonDisabled={setBackButtonDisabled}
          dialCode={
            COUNTRIES.find(
              (item) => formData?.personalInformation?.country === item.name
            )?.dial_code
          }
          form={institutionDetailsForm}
          onSubmit={handleInstitutionSave}
          isValid={handleInstitutionValidation}
        />
      ),
    },
    {
      title: 'Pricing plan',
      content: (
        <PricePlan
          onChangePlan={handlePricePlanChange}
          selected={formData?.pricePlan || -1}
        />
      ),
    },
    {
      title: 'Almost Done!',
      content: (
        <WorkOrderScopeDetailsForm
          setDisabled={setBackButtonDisabled}
          form={workOrderScopeDetailForm}
          onSubmit={handleWorkOrderScopeSave}
          isValid={handleWorkOrderScopeValidation}
        />
      ),
    },
  ];

  const handleNextButtonClick = () => {
    if (current === 0) {
      personalDetailsForm.submit();
    } else if (current === 1) {
      institutionDetailsForm.submit();
    } else if (current === 2) {
      changeCurrent();
    } else if (current === 3) {
      workOrderScopeDetailForm.submit();
    }
  };

  const changeCurrent = () => {
    if (current < 3) setCurrent(current + 1);
  };

  const handlePrevButtonClick = () => {
    if (current >= 0) setCurrent(current - 1);
    setDisabled(false);
  };

  const handleSubmit = (formData: IFormData) => {
    const data: IRegistration = {
      ...formData?.institution,
      ...formData?.personalInformation,
      ...formData?.workOrderScope,
      price_plan: formData?.pricePlan,
      company_size: formData?.workOrderScope?.number_of_employees,
    };
    if (data) onSubmit(data, setLoading);
  };

  const buttonIsDisabled = useCallback(
    (current: number) => {
      if (current === 0) {
        setDisabled(!personalDetailIsValid);
      }
      if (current === 1) {
        setDisabled(!institutionDetailIsValid);
      }
      if (current === 2) {
        setDisabled(!pricePlanSelected);
      }
      if (current === 3) {
        setDisabled(!workScopeDetailIsValid);
      }
    },
    [
      current,
      personalDetailIsValid,
      institutionDetailIsValid,
      pricePlanSelected,
      workScopeDetailIsValid,
    ]
  );

  useEffect(() => {
    setDisabled(true);
    buttonIsDisabled(current);
  }, [current, buttonIsDisabled]);

  return (
    <Row className="full-height" justify="space-around" align="middle">
      <Col {...GRID}>
        <div className="registration-card">
          <Row>
            <Col span={current === 2 ? 0 : 8}>
              <RegistrationLeftPane {...REGISTRATION_LEFT_PANE_TEXT[current]} />
            </Col>
            <Col
              span={current === 2 ? 24 : 16}
              className="padding-top-4 padding-left-4 padding-right-4 padding-bottom-2"
            >
              <Row gutter={[0, 24]}>
                <Col span={24}>
                  <Steps className="steps" current={current} size="small">
                    {STEPS.map((item, index) => {
                      return <Step key={index} title={item.title} />;
                    })}
                  </Steps>
                </Col>
                <Col span={24}>
                  <div className="steps-content">
                    {React.cloneElement(STEPS[current].content)}
                  </div>
                </Col>
                <Col span={24}>
                  <Space>
                    {current > 0 && (
                      <Button
                        onClick={handlePrevButtonClick}
                        disabled={backButtonDisabled}
                      >
                        Back
                      </Button>
                    )}
                    <Button
                      className="btn-long"
                      data-testid="submit-button"
                      type="primary"
                      loading={loading}
                      disabled={disabled}
                      htmlType="button"
                      onClick={handleNextButtonClick}
                    >
                      {current !== 3 ? 'Next' : 'Finish'}
                    </Button>
                  </Space>
                </Col>
                <Paragraph type="secondary">
                  <small>
                    In order to process your registration, we ask you to provide
                    the following information. Please node that all fields
                    marked with an asterisk (*) are required
                  </small>
                </Paragraph>
              </Row>
            </Col>
          </Row>
        </div>
      </Col>
    </Row>
  );
};

export { Registration };
