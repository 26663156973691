import React from 'react';

const ShortApplyIMSLogoLight = () => (
  <svg
    id="Layer_1"
    data-name="Layer 1"
    width={120}
    height={100}
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 1086.89 304.5"
  >
    <path
      className="apply-ims-light-fill"
      d="M174.79,294.06c-15.17,7.41-31,15-46.64,22.12a27.84,27.84,0,0,1-23.73-.29l-27-13.13a19.46,19.46,0,0,0-16.46-.24L58,303.83c1,.89,23.57,21.41,28.28,25.7a2.28,2.28,0,0,1,.75,1.85,69,69,0,0,0,0,8.73A3.27,3.27,0,0,0,91.14,343C146,327.47,295.44,282.5,312.46,268.3c6.84-5.71,6.22-10.7,5.8-12.27-1.35-5.09-13-6.1-15.23-6.56s-8.82-6.17-33.57-.14c-25,6.09-58.79,26.87-60.61,27.92Z"
      transform="translate(-58 -146.19)"
    />
    <path
      className="apply-ims-light-fill"
      d="M106.39,346.33l54.05-17.23-17.6,68.4a10.3,10.3,0,0,1-10,7.74H97.79a10.3,10.3,0,0,1-9.54-14.17Z"
      transform="translate(-58 -146.19)"
    />
    <path
      className="apply-ims-light-fill"
      d="M294.27,191.32,285.69,194l-28.48,8.92L229,211.75c-3.81,1.19-7.63,2.38-11.42,3.59l17.15,42.41-36.93,16.93-9.55-32.52h0c-10.85,29.44-32.46,51.88-61,64.87l-6.41,2.91,38.6-95.12q-29.22-12.7-58.4-25.43a1.53,1.53,0,0,0-2.18.57,46.1,46.1,0,0,0-8.11,15.2,65.51,65.51,0,0,0-2.92,16.41,7.24,7.24,0,0,0-.23,3.89c2.51,2.5,2.76,4.16,1,7.36-.4.67.17.69.42.89a8.71,8.71,0,0,1,3.49,6.84q-.51,9.3-1,18.59l-.15,2.16a1.74,1.74,0,0,1-.22.05l-15.42-.89c-.59,0-1.19-.08-1.76-.1.35-6.89.7-13.81,1.07-20.72a8.93,8.93,0,0,1,4.51-6.57c.49-.28.32-.5.17-.87-1.48-3.27-.37-6.1,3-7.61.64-1.29.29-2.7.47-4s.27-2.93.44-4.36A66.63,66.63,0,0,1,85.67,205c4.07-13.71,12.15-24.07,25.34-30.12a79.08,79.08,0,0,1,9.15-3.42,43.39,43.39,0,0,0-6.23.84,41.14,41.14,0,0,0-22.48,12,1.47,1.47,0,0,1-1.66.34c-4.19-1.86-8.4-3.67-12.59-5.5-.35-.15-.85-.18-1-.67l.84-.27q20.9-6,41.77-12,22.31-6.41,44.57-12.84,12.23-3.49,24.46-7.07a2.39,2.39,0,0,1,1.81.23l16.66,7.16q40.38,17.25,80.76,34.53C289.36,189.17,291.64,190.18,294.27,191.32Z"
      transform="translate(-58 -146.19)"
    />
    <path
      className="apply-ims-light-fill"
      d="M279.08,405.52H247.41a10.3,10.3,0,0,1-9.8-7.15L210.11,313l41.49-13.23,37,91.59A10.3,10.3,0,0,1,279.08,405.52Z"
      transform="translate(-58 -146.19)"
    />
  </svg>
);

export { ShortApplyIMSLogoLight };
