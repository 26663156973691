import { Tag } from 'antd';
import { PresetColorType, PresetStatusColorType } from 'antd/lib/_util/colors';
import React from 'react';
import { CurrentService } from '@model/crm/deal.model';
import { classNames } from '@moxie/constants';

interface Props
  extends React.DetailedHTMLProps<
    React.HTMLAttributes<HTMLDivElement>,
    HTMLDivElement
  > {
  currentService: CurrentService;
  className?: string;
  clickable?: boolean;
}

export const ServiceList: React.FC<Props> = ({
  currentService,
  className = '',
  clickable,
  ...props
}) => {
  return (
    <div
      className={`service-list-container ${className}`}
      role={clickable ? 'button' : ''}
      {...props}
    >
      {Object.entries(currentService)?.map(([keylabel, value], index) => {
        if (Array.isArray(value)) {
          return value.map((item) => (
            <Tag
              key={item}
              className={classNames(
                'service-list',
                String(item).toLowerCase() === 'not sure'
                  ? 'not-sure-tag'
                  : undefined
              )}
              color={getTagColor(keylabel)}
            >
              {item}
            </Tag>
          ));
        } else {
          return value && keylabel !== 'id' ? (
            <Tag
              className={classNames(
                'service-list',
                value.toLowerCase() === 'not sure' ? 'not-sure-tag' : undefined
              )}
              key={index}
              color={getTagColor(keylabel)}
            >
              {value}
            </Tag>
          ) : null;
        }
      })}
    </div>
  );
};

function getTagColor(level: string): PresetColorType | PresetStatusColorType {
  switch (level) {
    case 'parent':
      return 'processing';
    case 'countries':
      return 'purple';
    case 'interests':
      return 'error';
    default:
      return 'default';
  }
}
