import { all, put } from 'redux-saga/effects';
import { themeActions } from './theme.reducer';

function* sagaToggleNavbar(payload: boolean): any {
  yield put(themeActions.toggleNavbar(payload));
}

export default function* themeSaga(): any {
  yield all([sagaToggleNavbar]);
}
