import React, { useEffect, useState } from 'react';
import {
  Col,
  Divider,
  // Dropdown,
  Form,
  Menu,
  Row,
  Spin,
  Typography,
} from 'antd';
import {
  BUTTON,
  // CONFIRM_TITLE,
  LABEL,
  PLACEHOLDER,
  STAGE_MAX_CHARACTER_LIMIT,
  STAGE_NAME_REQUIRED,
  TEXT,
  WORKFLOW_STAGE_TABS,
} from '@moxie/constants';
import { useForm } from 'antd/lib/form/Form';

import {
  Input,
  Button,
  // MenuItem,
  // successNotificationHandler,
  // warnNotificationHandler,
  // popConfirm,
} from '@moxie/shared';
import {
  CheckCircleOutlined,
  CheckOutlined,
  CloseOutlined,
  // MoreOutlined,
} from '@ant-design/icons';
import { IWorkflowStagesProps } from '@shared-components/models';
import { useDispatch } from 'react-redux';
import { workflowActions } from '@crm/core';
// import {
//   deleteWorkflowStage,
//   updateWorkflowStage,
// } from 'apps/crm/src/libs/services.api/lib/workflow.api';
import { WorkflowStageChecklists } from './workflow-stage-checklist';
import { crmErrorHandler } from '../../shared';

const WorkflowStages = ({
  stagesLoading,
  stageList,
  workflowId,
}: IWorkflowStagesProps) => {
  const [form] = useForm();
  const dispatch = useDispatch();

  const [addFormVisible, setAddFormVisible] = useState(false);
  const [submitted, setSubmitted] = useState(false);
  const [selectedStage, setSelectedStage] = useState('');
  // const [stageToEdit, setStageToEdit] = useState<string>('');
  const [selectedTab, setSelectedTab] = useState(WORKFLOW_STAGE_TABS.CHECKLIST);

  useEffect(() => {
    if (stageList.length > 0) {
      setSelectedStage(stageList[0]?.id);
    }
  }, [stageList]);

  useEffect(() => {
    dispatch(workflowActions.getAllChecklistsRequest());
  }, [dispatch]);

  useEffect(() => {
    dispatch(workflowActions.getWfStageChecklists(selectedStage));

    return () => {
      dispatch(workflowActions.clearWfStageChecklist());
    };
  }, [selectedStage, dispatch]);

  const handleAddStageBtn = () => {
    // setStageToEdit('');
    setAddFormVisible(true);
  };
  const handleCloseStageFormBtn = () => {
    setAddFormVisible(false);
    form.resetFields();
  };

  const handleSubmit = (data: any) => {
    try {
      setSubmitted(true);
      dispatch(
        workflowActions.addWorkflowStageRequest({
          ...data,
          workflowId,
          actor: 'Admission',
        })
      );
      dispatch(workflowActions.getWorkflowStagesRequest(workflowId));
      setAddFormVisible(false);
      // setStageToEdit('');
      form.resetFields();
      setSelectedStage(stageList[0]?.id);
    } catch (err) {
      crmErrorHandler(err);
    } finally {
      setSubmitted(false);
    }
  };

  // Below code are commented considering for now not to allow editing stage 

  // const handleEditBtnClick = (id: string) => {
  //   setSelectedStage(id);
  //   setStageToEdit(id);
  //   setAddFormVisible(false);
  // };

  // const handleDeleteBtnClick = (id: string, stage: string) => {
  //   try {
  //     popConfirm(
  //       CONFIRM_TITLE.DELETE_WORKFLOW_STAGE,
  //       `Do you want to delete ${stage} ?`,
  //       async () => {
  //         const response: IResponse = await deleteWorkflowStage(id);
  //         id === stageList[0]?.id
  //           ? setSelectedStage(stageList[1]?.id)
  //           : setSelectedStage(stageList[0]?.id);
  //         successNotificationHandler(response?.message);
  //         dispatch(workflowActions.getWorkflowStagesRequest(workflowId));
  //       }
  //     );
  //   } catch (e: any) {
  //     warnNotificationHandler(`Cannot delete. ${e?.response?.data?.message}`);
  //   }
  // };

  // const handleEditSubmit = async (data: any) => {
  //   try {
  //     setSubmitted(true);
  //     const response = await updateWorkflowStage({
  //       id: selectedStage,
  //       ...data,
  //       workflowId,
  //       actor: 'Admission',
  //     });
  //     successNotificationHandler(response?.data?.message);
  //     setStageToEdit('');
  //     dispatch(workflowActions.getWorkflowStagesRequest(workflowId));
  //   } catch (err) {
  //     errorHandler(err);
  //   } finally {
  //     setSubmitted(false);
  //   }
  // };
  // const handleCancelFormButton = () => {
  //   setStageToEdit('');
  //   form.resetFields();
  // };

  const handleStageSelect = (id: string) => {
    dispatch(workflowActions.getWfStageChecklists(id));
    setSelectedStage(id);
  };

  return (
    <Row
      className="padding-top-2"
      justify={selectedStage && stageList.length > 0 ? 'space-around' : 'start'}
      gutter={selectedStage && stageList.length > 0 ? 20 : 0}
    >
      <Col span={8} className="other-details__category">
        <Row className="margin-bottom-1">
          <Typography.Title level={5}>{LABEL.STAGES}</Typography.Title>
        </Row>
        <Spin spinning={submitted || stagesLoading}>
          {stageList &&
            stageList.map((stage: any) => {
              return (
                <Row
                  key={stage?.stage}
                  justify="space-between"
                  align="middle"
                  className={`other-details__category__list margin-top-1 cursor-pointer  ${selectedStage?.toString() === stage?.id?.toString() &&
                    'other-details__category__list__selected'
                    }`}
                >
                  {/* {stageToEdit === stage.id ? (
                    <Row
                      justify="space-between"
                      align="middle"
                      className="full-width"
                    >
                      <Form
                        initialValues={stage}
                        className="full-width margin-top-2"
                        onFinish={(data) => handleEditSubmit(data)}
                      >
                        <Row>
                          <Col span={20}>
                            <Form.Item
                              name="stage"
                              className="full-width"
                              rules={[
                                {
                                  required: true,
                                  message: STAGE_NAME_REQUIRED,
                                },
                                { max: 30, message: STAGE_MAX_CHARACTER_LIMIT },
                                {
                                  type: 'string',
                                  whitespace: true,
                                  message: STAGE_NAME_REQUIRED,
                                },
                              ]}
                            >
                              <Input
                                placeholder={PLACEHOLDER.STAGE_NAME}
                                className="border-radius-1 general-setting-category-input"
                              />
                            </Form.Item>
                          </Col>
                          <Col span={3}>
                            <Row
                              justify="space-around"
                              className="stage-form-icon-position"
                            >
                              <Col span={2}>
                                <Button
                                  icon={<CheckOutlined />}
                                  type="link"
                                  htmlType="submit"
                                  size="small"
                                />
                              </Col>
                              <Col span={2}>
                                <Button
                                  icon={<CloseOutlined />}
                                  onClick={handleCancelFormButton}
                                  type="link"
                                  danger
                                  size="small"
                                />
                              </Col>
                            </Row>
                          </Col>
                        </Row>
                      </Form>
                    </Row>
                  ) : ( */}
                  <>
                    <Col
                      span={22}
                      onClick={() => handleStageSelect(stage.id)}
                    >
                      <Row justify="space-between">
                        <Typography.Text strong className="initial_capital">
                          {stage?.stage}
                        </Typography.Text>
                      </Row>
                    </Col>
                    {/* <Col span={2}>
                        <Dropdown
                          overlay={MenuItem(
                            () => handleEditBtnClick(stage?.id),
                            () => handleDeleteBtnClick(stage?.id, stage?.stage)
                          )}
                        >
                          <Button type="link" icon={<MoreOutlined />} />
                        </Dropdown>
                      </Col> */}
                  </>
                  {/* )} */}
                </Row>
              );
            })}
        </Spin>
        <Row
          justify="space-between"
          className="other-details__category__list margin-top-1 cursor-pointer"
        >
          <Col span={22}>
            <Row justify="space-between">
              <Typography.Text className="complete-stage-text" strong>
                {TEXT.COMPLETE}
              </Typography.Text>
            </Row>
          </Col>
          <Col span={2}>
            <Button
              type="link"
              icon={<CheckCircleOutlined className="text-grey" />}
            />
          </Col>
        </Row>
        {addFormVisible ? (
          <Row align="middle">
            <Divider />
            <Form
              form={form}
              className="full-width"
              onFinish={(data) => handleSubmit(data)}
            >
              <Row>
                <Col span={20}>
                  <Form.Item
                    name="stage"
                    className="full-width"
                    rules={[
                      { required: true, message: STAGE_NAME_REQUIRED },
                      { max: 30, message: STAGE_MAX_CHARACTER_LIMIT },
                      {
                        type: 'string',
                        whitespace: true,
                        message: STAGE_NAME_REQUIRED,
                      },
                    ]}
                  >
                    <Input
                      placeholder={PLACEHOLDER.STAGE_NAME}
                      className="border-radius-1 general-setting-category-input"
                    />
                  </Form.Item>
                </Col>
                <Col span={3}>
                  <Row
                    justify="space-around"
                    className="stage-form-icon-position"
                  >
                    <Col span={2}>
                      <Button
                        icon={<CheckOutlined />}
                        type="link"
                        htmlType="submit"
                        size="small"
                      />
                    </Col>
                    <Col span={2}>
                      <Button
                        icon={<CloseOutlined />}
                        onClick={handleCloseStageFormBtn}
                        type="link"
                        danger
                        size="small"
                      />
                    </Col>
                  </Row>
                </Col>
              </Row>
            </Form>
          </Row>
        ) : null}
        <Row className="padding-top-1">
          <Col span={24}>
            <Button
              type="primary"
              className="full-width"
              size="middle"
              onClick={handleAddStageBtn}
            >
              {BUTTON.ADD_STAGES}
            </Button>
          </Col>
        </Row>
      </Col>

      {selectedStage && stageList.length > 0 ? (
        <Col span={15} className="other-details__labels">
          <Row className="page-header margin-top-n2">
            <Col className="page-header__menu">
              <Menu selectedKeys={[selectedTab]} mode="horizontal">
                <Menu.Item key={WORKFLOW_STAGE_TABS.CHECKLIST}>
                  <Typography.Text
                    strong
                    onClick={() =>
                      setSelectedTab(WORKFLOW_STAGE_TABS.CHECKLIST)
                    }
                  >
                    {LABEL.CHECKLIST}
                  </Typography.Text>
                </Menu.Item>
              </Menu>
            </Col>
          </Row>

          {selectedTab === WORKFLOW_STAGE_TABS.CHECKLIST ? (
            <WorkflowStageChecklists stageId={selectedStage} />
          ) : null}
        </Col>
      ) : null}
    </Row>
  );
};
export { WorkflowStages };
