import React from 'react';
import { IServiceImportDrawer } from '@shared-components/models';
import { DrawerElem } from '@shared-components/elements';
import { CANCEL_BTN, DRAWER_WIDTH, IMPORT, LABEL } from '@moxie/constants';
import { Button, Space } from 'antd';
import { ImportServiceTable } from './import-service-table';

const ImportServiceDrawer: React.FC<IServiceImportDrawer> = ({
  onChangeSelected,
  visible,
  onClose,
  onSubmit,
  disabled,
}: IServiceImportDrawer) => {
  return (
    <DrawerElem
    data-testid="importdrawer"
      visible={visible}
      onClose={onClose}
      title={LABEL.IMPORT_SERVICE}
      width={DRAWER_WIDTH}
      footer={
        <div className="padding-bottom-2 text-align-right">
          <Space direction="horizontal">
            <Button
              onClick={onClose}
              className="lead-margin-right"
              htmlType="reset"
            >
              {CANCEL_BTN}
            </Button>
            <Button
              data-testid="crmimportbutton"
              type="primary"
              htmlType="submit"
              disabled={disabled}
              onClick={onSubmit}
            >
              {IMPORT}
            </Button>
          </Space>
        </div>
      }
    >
      <ImportServiceTable onChangeSelected={onChangeSelected} />
    </DrawerElem>
  );
};

export { ImportServiceDrawer };
