import axios from 'axios';
import crmAxios from './crm-api-axios';
import { API_URL } from '@moxie/constants';
import { IAuthCompany, IAuthResponse } from '@shared-components/models';

const updateCompany = (body: IAuthCompany): Promise<IAuthResponse> => {
  return axios.put(API_URL.COMPANY_CRM, body);
};

const validateDomain = async ()=> {
  const data = await crmAxios.get<{data: {data: boolean}}>(`${API_URL.CRM_API_COMPANY}/verify-domain`)
  return data;
}
export { updateCompany , validateDomain};
