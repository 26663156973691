import React from 'react';
import Modal from 'antd/es/modal';
import DocViewer, {
  PDFRenderer,
  JPGRenderer,
  PNGRenderer,
  MSDocRenderer,
} from '@cyntler/react-doc-viewer';

interface Props {
  doc: {
    url: string;
    extension: string;
  }
  onCancel: () => void;
  onOk: () => void;
}
const DocumentPreview: React.FC<Props> = ({ doc, onCancel, onOk }) => {
  return (
  <Modal
    width="100%"
    visible={!!doc.url?.length}
    onCancel={onCancel}
    onOk={onOk}
    cancelButtonProps={{ style: { display: 'none' } }}
  >
    <DocViewer
      className="doc-preview"
      key={doc.url}
      config={{
        header: {
          disableHeader: true,
          retainURLParams: true,
        },
      }}
      documents={[
        {
          uri: doc?.url,
          fileType: doc?.extension.toLowerCase(),
        },
      ]}
      pluginRenderers={[
        PDFRenderer,
        PNGRenderer,
        JPGRenderer,
        MSDocRenderer,
      ]}
    />
  </Modal>
)
}

export default DocumentPreview
