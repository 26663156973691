/* eslint-disable no-unused-vars */
/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from 'react';
import { Empty, Tabs, Typography } from 'antd';
import { useHistory, useParams } from 'react-router-dom';
import SwiperCore, {
  Navigation,
  Pagination,
  Scrollbar,
  A11y,
  Mousewheel,
} from 'swiper';
import { InterestedService, LeadDocumentDrawer } from '@moxie/shared';
import {
  PROFILE_HEADERS,
  SLUG_EDUCATION_BACKGROUND,
  // SLUG_PROFILE_APPOINTMENTS,
  URL_DETAIL_LINK,
  SLUG_PROFESSIONAL_EXPERIENCE,
  SLUG_NOTES,
  SLUG_DOCUMENTS,
  SLUG_APPLICATIONS,
  SLUG_ACTIVITIES,
  SLUG_INTERESTED_SERVICES,
  SLUG_OTHER_DETAILS,
  TAB_POSITION,
  SLUG_PROFILE_SECTION,
} from '@moxie/constants';
import { ILeadProfileSection } from '@shared-components/models';
import { getAllDocuments } from '@lyra/services.api';
import { LeadNoteContent } from '../../lead-notes/libs/lead-note-content';
import { EducationBackground } from './education-background';
import LeadActivities from './lead-activities';
// import LeadAppointment from './lead-appointment';
import { LeadDocumentTable } from './lead-document-table';
import { ProfessionalExperience } from './professional-experience';
import { LeadApplication } from '../application';
import LeadOtherDetails from './lead-other-details';
import { useDispatch } from 'react-redux';
import {
  educationBackgroundActions,
  languageTestScoreActions,
  otherTestScoreActions,
} from '@lyra/core';
import { ProfileSection } from '../profile-section/profile-section';
import Can, { PermissionAction, PermissionObjects } from '@lyra/privilege';

SwiperCore.use([Navigation, Pagination, Scrollbar, A11y, Mousewheel]);

const LeadDetailContent: React.FC<ILeadProfileSection> = (
  props: ILeadProfileSection
) => {
  const { TabPane } = Tabs;
  const lead = props?.lead;
  const id = lead?.id;
  const history = useHistory();
  const params = useParams<{ slug: string; id: string }>();
  const dispatch = useDispatch();
  const [visible, setVisible] = useState(false);
  const [refreshTable, setRefreshTable] = useState(true);
  const [activeKey, setActiveKey] = useState(params.slug);
  const [newHeaders, setNewHeaders] = useState<any>([]);

  const onCloseImport = () => {
    setVisible(false);
  };

  const handleClick = () => {
    setVisible(true);
  };

  const getDocuments = async (str: string) => {
    return await getAllDocuments(params.id, str);
  };

  const triggerRefresh = () => {
    setRefreshTable((prev) => !prev);
  };

  const handleChange = (key: string) => {
    const selected = PROFILE_HEADERS.find((item) => item.slug === key);
    if (selected?.url) {
      history.push(`${URL_DETAIL_LINK}${id}/${selected?.url}`);
    }
  };

  const tabContent = (key: string) => {
    switch (key) {
      case SLUG_PROFILE_SECTION:
        return (
          <div className="full-height-tab-content interested-service-content overflow-scroll">
            <ProfileSection lead={lead} />
          </div>
        );
      case SLUG_ACTIVITIES:
        return (
          <div className=" overflow-scroll">
            <LeadActivities />
          </div>
        );

      case SLUG_DOCUMENTS:
        return (
          <div className="full-height-tab-content overflow-scroll">
            <LeadDocumentTable
              lead={lead}
              documentData={getDocuments}
              refreshTable={refreshTable}
              handleClick={handleClick}
              triggerRefresh={triggerRefresh}
            />
          </div>
        );
      case lead?.assignee_id && SLUG_APPLICATIONS:
        return (
          <div className="full-height-tab-content overflow-scroll">
            <LeadApplication />
          </div>
        );

      case lead?.assignee_id && SLUG_INTERESTED_SERVICES:
        return (
          <div className="full-height-tab-content interested-service-content overflow-scroll">
            <InterestedService lead={lead} />
          </div>
        );
      case SLUG_NOTES:
        return (
          <div className="full-height-tab-content overflow-scroll">
            <LeadNoteContent lead={lead} />
          </div>
        );
      case SLUG_EDUCATION_BACKGROUND:
        return (
          <div className="full-height-tab-content overflow-scroll">
            <EducationBackground id={id} lead={lead} />
          </div>
        );

      case SLUG_PROFESSIONAL_EXPERIENCE:
        return (
          <div className="full-height-tab-content overflow-scroll">
            <ProfessionalExperience id={id} lead={lead} />
          </div>
        );

      // case SLUG_PROFILE_APPOINTMENTS:
      //   return (
      //     <div className="full-height-tab-content overflow-scroll">
      //       <LeadAppointment archived={lead?.archived} />
      //     </div>
      //   );

      case SLUG_OTHER_DETAILS:
        return (
          <div className="full-height-tab-content overflow-scroll">
            <LeadOtherDetails lead={lead} />
          </div>
        );
      default:
        return (
          <div className="full-height-tab-content overflow-scroll">
            <div className="empty-content">
              <Empty image={Empty.PRESENTED_IMAGE_SIMPLE} />
            </div>
          </div>
        );
    }
  };

  const setDefaultActiveKey = () => {
    const selected = PROFILE_HEADERS.find((item) => item.slug === params.slug);
    selected?.slug && setActiveKey(selected?.slug);
  };

  const filterHeaders = () => {
    const profileHeaders: any = [];
    PROFILE_HEADERS.map((profile_header) => {
      if (
        lead?.assignee_id === null &&
        profile_header.name !== 'Interested Services' &&
        profile_header.name !== 'Applications'
      ) {
        profileHeaders.push(profile_header);
      }
    });

    setNewHeaders(profileHeaders);
  };

  useEffect(() => {
    filterHeaders();
    if (
      (params.slug === 'applications' ||
        params.slug === 'interested-services') &&
      lead?.assignee_id === null
    ) {
      history.push(`${URL_DETAIL_LINK}${id}/activities`);
    }
  }, [lead]);

  useEffect(() => {
    if (id) {
      const params = `?parent_id=${id}`;
      dispatch(languageTestScoreActions.getLanguageTestScoresRequest(params));
      dispatch(otherTestScoreActions.getOtherTestScoresRequest(params));
      dispatch(educationBackgroundActions.getEducationBackgroundsRequest(id));
    }
    return () => {
      dispatch(educationBackgroundActions.getEducationBackgroundsClear());
      dispatch(languageTestScoreActions.getLanguageTestScoresClear());
    };
  }, [id, dispatch]);

  useEffect(() => {
    triggerRefresh();
    setDefaultActiveKey();
  }, [params.slug]);

  return (
    <section className="lead_content">
      <div className="pro">
        <Tabs
          activeKey={activeKey}
          tabPosition={TAB_POSITION}
          onTabClick={(key) => handleChange(key)}
        >
          {[
            ...Array.from(
              lead && lead?.assignee_id === null ? newHeaders : PROFILE_HEADERS
            ),
          ].map((item: any) => {
            return (
              <>
                {item.slug === 'notes' ? (
                  <>
                    {/* {Can(
                      PermissionAction.Read,
                      PermissionObjects.LeadNotes
                    ) && ( */}
                    <TabPane
                      className="custom_tab"
                      tab={
                        <Typography.Text strong>{item.name}</Typography.Text>
                      }
                      key={item.slug}
                    >
                      {tabContent(item.slug)}
                    </TabPane>
                    {/* )} */}
                  </>
                ) : // item.slug === 'appointments' ? (
                //   <>
                //     {Can(
                //       PermissionAction.Read,
                //       PermissionObjects.LeadAppointment
                //     ) && (
                //       <TabPane
                //         className="custom_tab"
                //         tab={
                //           <Typography.Text strong>{item.name}</Typography.Text>
                //         }
                //         key={item.slug}
                //       >
                //         {tabContent(item.slug)}
                //       </TabPane>
                //     )}
                //   </>
                // ) :
                item.slug === 'documents' ? (
                  <>
                    {/* {Can(
                      PermissionAction.Read,
                      PermissionObjects.LeadDocumentEntity
                    ) && ( */}
                    <TabPane
                      className="custom_tab"
                      tab={
                        <Typography.Text strong>{item.name}</Typography.Text>
                      }
                      key={item.slug}
                    >
                      {tabContent(item.slug)}
                    </TabPane>
                    {/* )} */}
                  </>
                ) : (
                  <TabPane
                    className="custom_tab"
                    tab={<Typography.Text strong>{item.name}</Typography.Text>}
                    key={item.slug}
                  >
                    {tabContent(item.slug)}
                  </TabPane>
                )}
              </>
            );
          })}
        </Tabs>
        <LeadDocumentDrawer
          isOpen={visible}
          onClose={onCloseImport}
          triggerRefresh={triggerRefresh}
        />
      </div>
    </section>
  );
};

export { LeadDetailContent };
