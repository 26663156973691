import React, { ReactNode, useState } from 'react';
import { Typography } from 'antd';
import { LABEL, TEXT } from '@moxie/constants';
import { Table } from '@shared-components/elements';
import {
  IClient,
  ICompany,
  ILead,
  ITableProps,
} from '@shared-components/models';
import { TableUserNameCard } from './client-table-name-card';
import { Link } from 'react-router-dom';
import { formatDateWithTimezone } from '@moxie/shared';
import { useAppSelector } from '@admin/core';

const { Text } = Typography;

export const columns = (userTimeZone: string) => {
  return [
    {
      title: TEXT.INTERNAL_ID,
      dataIndex: 'internal_id',
      render: (value: string, data: ILead): ReactNode => {
        return (
          <Link to={`/contact/detail/${data?.id}/activities`}>
            <Text className="text-primary-color initial_capital">
              {data?.company?.company_code + '-' + value}
            </Text>
          </Link>
        );
      },
    },
    {
      title: LABEL.NAME,
      sorter: () => 0,
      width: 300,
      render(info: IClient, index: number) {
        return (
          <TableUserNameCard
            id={info.id}
            firstName={info.first_name}
            email={info.email}
            lastName={info.last_name}
            index={index.toString()}
          />
        );
      },
    },
    {
      title: LABEL.COMPANY_NAME,
      dataIndex: 'company',
      key: 'company',
      width: 300,
      render(company: ICompany) {
        return (
          <Text className="initial_capital"> {company?.company_name}</Text>
        );
      },
    },

    {
      title: LABEL.CURRENT_CITY,
      dataIndex: 'city',
      key: 'city',
      width: 200,
      render(city: string) {
        return <Text className="initial_capital">{city ? city : '-'}</Text>;
      },
    },
    {
      title: LABEL.PHONE_NUMBER,
      dataIndex: 'phone',
      key: 'phone',
      width: 200,
      render(phone: string) {
        return <Text>{phone ? phone : '-'}</Text>;
      },
    },
    {
      title: LABEL.ADDED_DATE,
      dataIndex: 'created_at',
      key: 'created_at',
      width: 200,
      render(created_at: string) {
        return (
          <Text>
            {userTimeZone && formatDateWithTimezone(created_at, userTimeZone)}
          </Text>
        );
      },
    },
  ];
};
const ClientListTable: React.FC<ITableProps> = ({
  getData,
  refreshTable,
  tableName,
}: ITableProps) => {
  const userTimeZone =
    useAppSelector((store) => store?.auth?.user?.time_zone) || '';
  const [filter] = useState({});

  return (
    <div className="bg-white padding-top-2">
      <Table
        columns={columns(userTimeZone)}
        tableName={tableName}
        filterOptions={true}
        refresh={refreshTable}
        action={getData}
        filter={filter}
      />
    </div>
  );
};
export { ClientListTable };
