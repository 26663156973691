import React from 'react';

const ApplyIMSLogoDark = () => (
  <svg
    id="Layer_1"
    data-name="Layer 1"
    width={120}
    height={50}
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 1086.89 304.5"
  >
    <path
      className="apply-ims-dark-fill"
      d="M343.57,391.88v56.49H310.93V311.56c0-9.69,0-19.27-.22-28.88h31.8a136.58,136.58,0,0,1,1.06,14.93c5.09-8.8,15.67-17.7,34.48-17.7,26.63,0,47.24,22.5,47.24,59.67,0,42.27-23,65.69-51.74,65.69C356.82,405.27,348.09,398.89,343.57,391.88Zm48.4-51c0-22-8.8-34.36-24.43-34.36-18.65,0-25.26,12.09-25.26,36.24,0,22.86,6.11,36.16,23.86,36.16C383.52,378.9,392,365.26,392,340.86Z"
      transform="translate(-58 -146.19)"
    />
    <path
      className="apply-ims-dark-fill"
      d="M473.73,391.88v56.49H441.09V311.56c0-9.69,0-19.27-.23-28.88h31.8a136.4,136.4,0,0,1,1.07,14.93c5.08-8.8,15.67-17.7,34.47-17.7,26.64,0,47.24,22.5,47.24,59.67,0,42.27-23,65.69-51.74,65.69C487,405.27,478.24,398.89,473.73,391.88Zm48.39-51c0-22-8.8-34.36-24.42-34.36-18.65,0-25.27,12.09-25.27,36.24,0,22.86,6.12,36.16,23.86,36.16C513.67,378.9,522.12,365.26,522.12,340.86Z"
      transform="translate(-58 -146.19)"
    />
    <path
      className="apply-ims-dark-fill"
      d="M571.24,402.49v-169h32.84v169Z"
      transform="translate(-58 -146.19)"
    />
    <path
      className="apply-ims-dark-fill"
      d="M649.47,282.68C661.72,324,672.4,360.91,674.28,372h.23c2.57-12.09,9.51-38.94,23.67-89.31h32.69L691,405.94c-11.33,34.46-20.79,44.75-49.28,44.75a153.07,153.07,0,0,1-15.38-.9V423.14a84.54,84.54,0,0,0,8.49.49c12.06,0,18.14-4.23,22.58-17.47L614.27,282.68Z"
      transform="translate(-58 -146.19)"
    />
    <path
      className="apply-ims-dark-fill"
      d="M781.43,232.94V402.49H745.14V232.94Z"
      transform="translate(-58 -146.19)"
    />
    <path
      className="apply-ims-dark-fill"
      d="M955,338.89c0-28.93.46-61.36,1.19-80.52H955c-7.89,34.59-24.67,91.07-40.77,144.12H883.31c-12.24-46.38-29.59-110.72-37-144.59h-1.13c1.44,19.89,2.14,54.78,2.14,83.86v60.73H814.28V232.94h54c13.09,44.3,27.76,99.87,32.8,125.15h.27c4.11-22.29,22.55-81.7,36.55-125.15H990V402.49H955Z"
      transform="translate(-58 -146.19)"
    />
    <path
      className="apply-ims-dark-fill"
      d="M1044.83,352.79c3.83,17,15.92,24.49,35.35,24.49s27.66-7.73,27.66-20.14c0-13.8-8.1-19.79-36.8-26.53-45.68-10.83-57-27.62-57-50.51,0-29.55,21.89-49.62,61.62-49.62,44.57,0,62.33,24.2,64.73,49h-36.57c-1.75-10.43-7.46-21.76-29.1-21.76-14.73,0-23.62,6.14-23.62,18.18,0,11.79,7.13,16.57,34.25,22.93,48.82,11.61,59.52,30,59.52,53.86,0,31-23.19,52.2-67.56,52.2-42.61,0-65-21.19-69.07-52.1Z"
      transform="translate(-58 -146.19)"
    />
    <path
      className="apply-ims-dark-fill"
      d="M174.79,294.06c-15.17,7.41-31,15-46.64,22.12a27.84,27.84,0,0,1-23.73-.29l-27-13.13a19.46,19.46,0,0,0-16.46-.24L58,303.83c1,.89,23.57,21.41,28.28,25.7a2.28,2.28,0,0,1,.75,1.85,69,69,0,0,0,0,8.73A3.27,3.27,0,0,0,91.14,343C146,327.47,295.44,282.5,312.46,268.3c6.84-5.71,6.22-10.7,5.8-12.27-1.35-5.09-13-6.1-15.23-6.56s-8.82-6.17-33.57-.14c-25,6.09-58.79,26.87-60.61,27.92Z"
      transform="translate(-58 -146.19)"
    />
    <path
      className="apply-ims-dark-fill"
      d="M106.39,346.33l54.05-17.23-17.6,68.4a10.3,10.3,0,0,1-10,7.74H97.79a10.3,10.3,0,0,1-9.54-14.17Z"
      transform="translate(-58 -146.19)"
    />
    <path
      className="apply-ims-dark-fill"
      d="M294.27,191.32,285.69,194l-28.48,8.92L229,211.75c-3.81,1.19-7.63,2.38-11.42,3.59l17.15,42.41-36.93,16.93-9.55-32.52h0c-10.85,29.44-32.46,51.88-61,64.87l-6.41,2.91,38.6-95.12q-29.22-12.7-58.4-25.43a1.53,1.53,0,0,0-2.18.57,46.1,46.1,0,0,0-8.11,15.2,65.51,65.51,0,0,0-2.92,16.41,7.24,7.24,0,0,0-.23,3.89c2.51,2.5,2.76,4.16,1,7.36-.4.67.17.69.42.89a8.71,8.71,0,0,1,3.49,6.84q-.51,9.3-1,18.59l-.15,2.16a1.74,1.74,0,0,1-.22.05l-15.42-.89c-.59,0-1.19-.08-1.76-.1.35-6.89.7-13.81,1.07-20.72a8.93,8.93,0,0,1,4.51-6.57c.49-.28.32-.5.17-.87-1.48-3.27-.37-6.1,3-7.61.64-1.29.29-2.7.47-4s.27-2.93.44-4.36A66.63,66.63,0,0,1,85.67,205c4.07-13.71,12.15-24.07,25.34-30.12a79.08,79.08,0,0,1,9.15-3.42,43.39,43.39,0,0,0-6.23.84,41.14,41.14,0,0,0-22.48,12,1.47,1.47,0,0,1-1.66.34c-4.19-1.86-8.4-3.67-12.59-5.5-.35-.15-.85-.18-1-.67l.84-.27q20.9-6,41.77-12,22.31-6.41,44.57-12.84,12.23-3.49,24.46-7.07a2.39,2.39,0,0,1,1.81.23l16.66,7.16q40.38,17.25,80.76,34.53C289.36,189.17,291.64,190.18,294.27,191.32Z"
      transform="translate(-58 -146.19)"
    />
    <path
      className="apply-ims-dark-fill"
      d="M279.08,405.52H247.41a10.3,10.3,0,0,1-9.8-7.15L210.11,313l41.49-13.23,37,91.59A10.3,10.3,0,0,1,279.08,405.52Z"
      transform="translate(-58 -146.19)"
    />
  </svg>
);

export { ApplyIMSLogoDark };
