const alias = 'PRODUCT_TYPE_BY_SERVICE_ID';
const subTypes = 'PRODUCT_SUB_TYPES';

export const FETCH_PRODUCT_TYPE_BY_SERVICE_ID_REQUEST = `FETCH_${alias}_REQUEST`;
export const FETCH_PRODUCT_TYPE_BY_SERVICE_ID_SUCCESS = `FETCH_${alias}_SUCCESS`;
export const FETCH_PRODUCT_TYPE_BY_SERVICE_ID_FAILURE = `FETCH_${alias}_FAILURE`;

export const FETCH_PRODUCT_SUB_TYPES_REQUEST = `FETCH_${subTypes}_REQUEST`;
export const FETCH_PRODUCT_SUB_TYPES_SUCCESS = `FETCH_${subTypes}_SUCCESS`;
export const FETCH_PRODUCT_SUB_TYPES_FAILURE = `FETCH_${subTypes}_FAILURE`;

export const CLEAR_PRODUCT_TYPE_FETCH = `FETCH_${alias}_FETCH`;
export const CLEAR_PRODUCT_SUB_TYPE_FETCH = `FETCH_${subTypes}_FETCH`;
