import { TEXT } from '@moxie/constants';
import { ColumnsType } from 'antd/lib/table';
import { Col, Typography } from 'antd';
import React from 'react';
import { Table } from '@shared-components/elements';
import { getAdminServiceList } from '@crm/services.api';
import { IServiceImportTable } from '@shared-components/models';

const { Text } = Typography;

const ImportServiceTable: React.FC<IServiceImportTable> = ({
  onChangeSelected,
}: IServiceImportTable) => {
  const getAdminServices = async () => {
    const response = await getAdminServiceList();
    return response;
  };
  const ServiceTableColumns: ColumnsType<any> = [
    {
      title: TEXT.NAME,
      key: 'name',
      width: '30%',
      render(data) {
        return (
          <Col span={24}>
            <Text
              className="text-primary-color import-table-title-ellipsis"
              ellipsis={true}
            >
              {data?.name}
            </Text>
          </Col>
        );
      },
    },
    {
      title: TEXT.DESCRIPTION,
      key: 'description',
      width: '70%',
      render(data) {
        return (
          <Col span={24}>
            <div
              dangerouslySetInnerHTML={{
                __html: `${
                  data?.description.length > 50
                    ? data?.description.substring(0, 50) + '...'
                    : data?.description
                }`,
              }}
            ></div>
          </Col>
        );
      },
    },
  ];

  return (
    <div>
      <Table
        className="all__border"
        columns={ServiceTableColumns}
        action={getAdminServices}
        hasPagination={false}
        onChangeSelected={onChangeSelected}
      />
    </div>
  );
};
export { ImportServiceTable };
