import {
  COUNTRIES,
  GUTTER,
  LABEL,
  PASSPORT_EXPIRY_DATE_PLACEHOLDER,
  regex,
  TITLE,
  VALIDATION_MESSAGE,
  VISA_EXPIRY_DATE_PLACEHOLDER,
} from '@moxie/constants';
import { FormItem } from '@shared-components/elements';
import { ILeadVisaInformationProps } from '@shared-components/models';
import { Col, DatePicker, Divider, Input, Row, Select } from 'antd';
import Title from 'antd/lib/typography/Title';
import React from 'react';
import { disablePastDate } from '@moxie/shared';
const { Option } = Select;

const LeadFormCurrentVisaDetails: React.FC<ILeadVisaInformationProps> = ({
  visaInformationData,
}: ILeadVisaInformationProps) => {
  const handleOptionFilter = (input: string, option: any): boolean =>
    option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0;

  return (
    <div className="created-leadForm-section ">
      <Title level={4}>{TITLE.VISA_INFO_DETAILS}</Title>
      <Row gutter={GUTTER}>
        {visaInformationData?.visa_type && (
          <Col md={8} xs={24}>
            <FormItem
              name="visa_type"
              label={LABEL.VISA_TYPE}
              rules={[{ type: 'string', max: 50 }]}
            >
              <Input />
            </FormItem>
          </Col>
        )}
        {visaInformationData?.visa_expiry_date && (
          <Col md={8} xs={24}>
            <FormItem name="visa_expiry_date" label={LABEL.VISA_EXPIRY_DATE}>
              <DatePicker
                className="full-width"
                disabledDate={disablePastDate}
                placeholder={VISA_EXPIRY_DATE_PLACEHOLDER}
              />
            </FormItem>
          </Col>
        )}
        {visaInformationData?.passport_expiry_date && (
          <Col md={8} xs={24}>
            <FormItem
              name="passport_expiry_date"
              label={LABEL.PASSPORT_EXPIRY_DATE}
            >
              <DatePicker
                className="full-width"
                disabledDate={disablePastDate}
                placeholder={PASSPORT_EXPIRY_DATE_PLACEHOLDER}
              />
            </FormItem>
          </Col>
        )}
        {visaInformationData?.country_of_passport && (
          <Col md={8} xs={24}>
            <FormItem
              name="country_of_passport"
              label={LABEL.COUNTRY_OF_PASSPORT}
              rules={[{ type: 'string', max: 50 }]}
            >
              <Select showSearch filterOption={handleOptionFilter}>
                <Option value="null" label="None">
                  None
                </Option>
                {COUNTRIES.map((country: any) => {
                  return (
                    <Option value={country.name} key={country.code}>
                      {country.name}
                    </Option>
                  );
                })}
              </Select>
            </FormItem>
          </Col>
        )}
        {visaInformationData?.passport_no && (
          <Col md={8} xs={24}>
            <FormItem
              name="passport_no"
              label={LABEL.PASSPORT_NO}
              rules={[
                { type: 'string', max: 50 },
                {
                  pattern: new RegExp(regex.ALPHA_NUMERIC),
                  message: VALIDATION_MESSAGE.INVALID_PASSPORT_NUMBER,
                },
              ]}
            >
              <Input />
            </FormItem>
          </Col>
        )}
      </Row>
      <Divider plain />
    </div>
  );
};
export { LeadFormCurrentVisaDetails };
