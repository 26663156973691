import React from 'react';
import moment from 'moment';
import { Collapse } from 'antd';
import { useFetchUsersById } from '@crm/libs/hooks/users/useFetchUsersById';

interface Props {
  assigneeId: string,
  name: string,
  createdAt: string
}

const EnquiryAssigneePanel: React.FC<Props> = ({
  assigneeId,
  name,
  createdAt }) => {
  const { Panel } = Collapse;

  const { users } = useFetchUsersById(assigneeId);

  const capitalizeFirstLetter = (string: string) => string.charAt(0).toUpperCase() + string.slice(1);

  return (
    <Panel
      className="enquiry-assignee-panel"
      key={assigneeId}
      header={(
        <div className="enquiry-info">
          <p className="enquiry-author">
            <span style={{ fontWeight: 'bold', fontSize: '14px' }}>{capitalizeFirstLetter(users?.firstName || '')} {capitalizeFirstLetter(users?.lastName || '')}</span> added as  <span style={{ fontWeight: 'bold', fontSize: '14px' }}>Assignee</span>
          </p>
          <div className="enquiry-date">
            <div>{moment(createdAt).format('dddd, MMMM D, YYYY')}</div>
            <div>by {name}</div>
          </div>
        </div>
      )}
    />
  );
};

export default EnquiryAssigneePanel;
