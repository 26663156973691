/* eslint-disable react/display-name */
/* eslint-disable no-unused-vars */
import React, { ReactNode, useEffect, useRef, useState } from 'react';
import moment, { Moment } from 'moment';
import { RangeValue } from 'rc-picker/lib/interface';
import { Link } from 'react-router-dom';
import { Checkbox, DatePicker, Form, Input, Row, Typography } from 'antd';
import {
  IActiveStage,
  IApplicationReportColumnFilterData,
  IApplicationReportColumns,
  ICompany,
  IInstitutionDetail,
  ILead,
  ILeadApplication,
  IProduct,
  IUser,
  IWorkflow,
} from '@shared-components/models';
import {
  APPLICATION_FILTER_INITIAL_DATA,
  COLUMNS,
  LABEL,
  SEARCH,
  TEXT,
  URL_DETAIL_LINK,
} from '@moxie/constants';
import {
  ApplicationReportAssignee,
  ApplicationStatus,
  Button,
  formatDateWithTimezone,
  TextInstitutionLinkField,
  TextProductLinkField,
} from '@moxie/shared';
import { ColumnType } from 'antd/lib/table';
import { SearchOutlined, FilterFilled } from '@ant-design/icons';
import { FilterConfirmProps } from 'antd/lib/table/interface';
import { getAdminApplicationReportFilterData } from '@admin/services.api';
import { useAppSelector } from '@admin/core';

const { Text } = Typography;
const { RangePicker } = DatePicker;
const CheckboxGroup = Checkbox.Group;

const TableColumns = (setFilter: (key: string, value: string) => void) => {
  const [
    filterDataList,
    setFilterDataList,
  ] = useState<IApplicationReportColumnFilterData>(
    APPLICATION_FILTER_INITIAL_DATA
  );

  const userTimeZone =
    useAppSelector((store) => store?.auth?.user?.time_zone) || '';

  useEffect(() => {
    (async () => {
      const response = await getAdminApplicationReportFilterData();
      setFilterDataList(response?.data);
    })();
  }, []);

  const searchInput = useRef(null);
  type DataIndex = keyof IApplicationReportColumns;

  const handleReset = (clearFilters: () => void, dataIndex: string) => {
    clearFilters();
    setFilter(dataIndex, '');
  };

  const handleSearch = (
    selectedKeys: string[],
    confirm: (param?: FilterConfirmProps) => void,
    dataIndex: DataIndex,
    multipleData?: boolean
  ) => {
    confirm();
    if (multipleData) {
      setFilter(dataIndex, selectedKeys.join());
      return;
    }
    setFilter(dataIndex, selectedKeys[0]);
  };

  const getColumnSearchProps = (
    dataIndex: DataIndex,
    name?: string
  ): ColumnType<IApplicationReportColumns> => ({
    filterDropdown: ({
      setSelectedKeys,
      selectedKeys,
      confirm,
      clearFilters,
    }: any) => {
      return (
        <div className="table-column-search-dropdown">
          <Input
            className="table-column-search-input"
            ref={searchInput}
            placeholder={`Search ${name}`}
            value={selectedKeys[0]}
            onChange={(e) =>
              setSelectedKeys(e.target.value ? [e.target.value] : [])
            }
            onPressEnter={() =>
              handleSearch(selectedKeys as string[], confirm, dataIndex)
            }
          />
          <Row justify="space-between">
            <Button
              className="margin-left-2"
              onClick={() =>
                clearFilters && handleReset(clearFilters, dataIndex)
              }
              size="small"
            >
              {LABEL.RESET}
            </Button>
            <Button
              type="primary"
              onClick={() => {
                handleSearch(selectedKeys as string[], confirm, dataIndex);
              }}
              size="small"
            >
              {SEARCH}
            </Button>
          </Row>
        </div>
      );
    },
    filterIcon: (filtered: boolean) => {
      return (
        <SearchOutlined className={filtered ? 'table-column-search-btn' : ''} />
      );
    },
  });

  const getCheckboxFilterProps = (
    dataIndex: DataIndex
  ): ColumnType<IApplicationReportColumns> => ({
    filterDropdown: ({
      setSelectedKeys,
      selectedKeys,
      confirm,
      clearFilters,
    }: any) => {
      const handleStatusChange = (list: any) => {
        setSelectedKeys(list);
      };

      return (
        <div>
          <Form className="border-bottom padding-1">
            <Form.Item className="margin-bottom-0">
              <CheckboxGroup
                className="custom_role_vertical--checkbox"
                options={
                  dataIndex === 'status'
                    ? filterDataList.applicationStatus
                    : dataIndex === 'country'
                    ? filterDataList.country
                    : dataIndex === 'country_of_passport'
                    ? filterDataList.countryOfPassport
                    : dataIndex === 'company'
                    ? filterDataList.companies
                    : dataIndex === 'service'
                    ? filterDataList.services
                    : dataIndex === 'workflow'
                    ? filterDataList.workflows
                    : dataIndex === 'institution'
                    ? filterDataList.institutions
                    : dataIndex === 'product'
                    ? filterDataList.products
                    : dataIndex === 'source'
                    ? filterDataList.source
                    : dataIndex === 'current_stage'
                    ? filterDataList.currentStages
                    : dataIndex === 'assignee'
                    ? filterDataList.assignee
                    : filterDataList.addedBy
                }
                onChange={handleStatusChange}
                value={selectedKeys}
              />
            </Form.Item>
          </Form>

          <Row className="padding-1" justify="space-between">
            <Button
              onClick={() => {
                clearFilters && handleReset(clearFilters, dataIndex);
                setSelectedKeys([]);
              }}
              size="small"
            >
              {LABEL.RESET}
            </Button>
            <Button
              type="primary"
              onClick={() => {
                handleSearch(
                  selectedKeys as string[],
                  confirm,
                  dataIndex,
                  true
                );
              }}
              size="small"
            >
              {TEXT.STATUS_DONE}
            </Button>
          </Row>
        </div>
      );
    },
    filterIcon: (filtered: boolean) => {
      return (
        <FilterFilled className={filtered ? 'table-column-search-btn' : ''} />
      );
    },
  });

  const getDateFilterProps = (
    dataIndex: DataIndex
  ): ColumnType<IApplicationReportColumns> => {
    return {
      filterDropdown: ({ setSelectedKeys, selectedKeys, confirm }: any) => {
        const handleRangeChange = (
          data: RangeValue<Moment>,
          values: string[]
        ) => {
          let dateRange: React.Key[] = [];
          if (data) {
            dateRange = [
              values[0] && moment(values[0]).format('YYYY-MM-DD'),
              values[1] && moment(values[1]).format('YYYY-MM-DD'),
            ];
          }

          setSelectedKeys(dateRange);
        };
        return (
          <div className="padding-1">
            <Form>
              <Form.Item>
                <RangePicker
                  format="MMM DD, YYYY"
                  onChange={handleRangeChange}
                />
              </Form.Item>
              <Row justify="end" className="margin-top-n2">
                <Button
                  type="primary"
                  onClick={() =>
                    handleSearch(
                      selectedKeys as string[],
                      confirm,
                      dataIndex,
                      true
                    )
                  }
                  size="small"
                >
                  {TEXT.STATUS_DONE}
                </Button>
              </Row>
            </Form>
          </div>
        );
      },
      filterIcon: (filtered: boolean) => {
        return (
          <FilterFilled className={filtered ? 'table-column-search-btn' : ''} />
        );
      },
    };
  };

  return [
    {
      title: TEXT.APPLICATION_ID,
      dataIndex: 'id',
      width: 200,
      ellipsis: true,
      render(id: string, data: ILeadApplication): ReactNode {
        return (
          <Link
            to={`/contact/detail/${data?.client?.id}/applications/${id}`}
            className="primary-id-color"
          >
            {`${data?.company?.company_code} - ${data?.app_identifier}`}
          </Link>
        );
      },
    },

    {
      title: LABEL.CLIENT_ID,
      width: 200,
      ellipsis: true,
      dataIndex: 'internal_id',
      sorter: () => 0,
      ...getColumnSearchProps('internal_id', 'Internal Id'),
      render: (internal_id: string, data: ILeadApplication): ReactNode => {
        return (
          <div className="lead-table__city">
            <Link
              to={`${URL_DETAIL_LINK}${data?.client?.id}/profile-section`}
              className="text-primary-color"
            >
              {`${data?.company?.company_code} - ${data?.client?.internal_id}`}
            </Link>
          </div>
        );
      },
    },

    {
      title: COLUMNS.CLIENT_NAME,
      dataIndex: 'client',
      width: 300,
      render(row: ILead): React.ReactElement {
        return (
          <Link
            to={`/contact/detail/${row?.id}/deals`}
            className="text-primary-color initial_capital"
          >
            {`${row?.first_name} ${row?.last_name}`}
          </Link>
        );
      },
    },

    {
      title: LABEL.COMPANY_NAME,
      dataIndex: 'company',
      width: 400,
      // ...getCheckboxFilterProps('company'),
      sorter: () => 0,
      render: (data: ICompany): ReactNode => {
        return (
          <div className="lead-table__phone">
            <Text className="initial_capital">{data?.company_name}</Text>
          </div>
        );
      },
    },

    {
      title: LABEL.ASSIGNEE,
      width: 200,
      dataIndex: 'admin_clients',
      ...getCheckboxFilterProps('assignee'),
      render(data: any): React.ReactElement | any {
        return <ApplicationReportAssignee data={data} />;
      },
    },

    {
      title: TEXT.PHONE,
      width: 200,
      dataIndex: 'client',
      ellipsis: true,
      ...getColumnSearchProps('phone', 'Phone'),
      render: (data: ILead): ReactNode => {
        return (
          <div className="lead-table__phone">
            {data?.country_code &&
              data?.phone &&
              `${data?.country_code} ${data?.phone}`}
          </div>
        );
      },
    },

    {
      title: LABEL.EMAIL,
      width: 200,
      dataIndex: 'email',
      ellipsis: true,
      ...getColumnSearchProps('email', 'Email'),
      sorter: () => 0,
      render: (_text: string, data: ILeadApplication): ReactNode => {
        return (
          <div className="lead-table__email">
            <a
              className="text-primary-color initial_small"
              href={`mailto:${data?.client?.email}`}
            >
              {data?.client?.email}
            </a>
          </div>
        );
      },
    },

    {
      title: LABEL.DATE_OF_BIRTH,
      width: 300,
      dataIndex: 'date_of_birth',
      sorter: () => 0,
      render: (value: string, row: ILeadApplication): ReactNode => {
        return (
          <div className="lead-table__visa-expiry-date">
            {row?.client?.date_of_birth
              ? moment(row?.client?.date_of_birth).format('MMM DD, YYYY')
              : '-'}
          </div>
        );
      },
    },

    {
      title: LABEL.COUNTRY_OF_RESIDENCE,
      width: 200,
      ellipsis: true,
      dataIndex: 'country',
      sorter: () => 0,
      ...getCheckboxFilterProps('country'),
      render: (text: string, data: ILeadApplication): ReactNode => {
        return (
          <div className="lead-table__city">{data?.client?.country || '-'}</div>
        );
      },
    },

    {
      title: LABEL.COUNTRY_OF_PASSPORT,
      width: 200,
      ellipsis: true,
      dataIndex: 'country_of_passport',
      ...getCheckboxFilterProps('country_of_passport'),
      sorter: () => 0,
      render: (text: string, data: ILeadApplication): ReactNode => {
        return (
          <div className="lead-table__city">
            {data?.client?.country_of_passport || '-'}
          </div>
        );
      },
    },

    {
      title: LABEL.VISA_EXPIRY_DATE,
      width: 200,
      dataIndex: 'visa_expiry_date',
      ...getDateFilterProps('visa_expiry_date'),
      ellipsis: true,
      sorter: () => 0,
      render: (value: string, data: ILeadApplication): ReactNode => {
        return (
          <div className="lead-table__visa-expiry-date">
            {data?.client?.visa_expiry_date
              ? moment(data?.client?.visa_expiry_date).format('MMM DD, YYYY')
              : '-'}
          </div>
        );
      },
    },

    {
      title: LABEL.ADDED_DATE,
      width: 200,
      dataIndex: 'created_at',
      ...getDateFilterProps('created_at'),
      sorter: () => 0,
      render: (value: string): ReactNode => {
        return (
          <div className="lead-table__visa-expiry-date">
            {formatDateWithTimezone(value, userTimeZone)}
          </div>
        );
      },
    },

    {
      title: LABEL.SERVICE,
      ellipsis: true,
      dataIndex: 'service',
      ...getCheckboxFilterProps('service'),
      sorter: () => 0,
      render(_text: string, data: ILeadApplication) {
        return (
          <div className="lead-table__city">
            {data?.institution?.service?.name}
          </div>
        );
      },
    },

    {
      title: LABEL.WORKFLOW,
      ellipsis: true,
      dataIndex: 'workflow',
      ...getCheckboxFilterProps('workflow'),
      render(workflow: IWorkflow) {
        return `${workflow?.workflowType?.name} (${workflow?.country})`;
      },
    },

    {
      title: LABEL.APPLIED_INSTITUTION,
      width: 250,
      dataIndex: 'institution',
      ellipsis: true,
      sorter: () => 0,
      ...getCheckboxFilterProps('institution'),
      render(institution: IInstitutionDetail) {
        return (
          <TextInstitutionLinkField
            text={institution?.name || ''}
            institutionId={institution?.id}
          />
        );
      },
    },

    {
      title: LABEL.APPLIED_PRODUCT,
      dataIndex: 'product',
      sorter: () => 0,
      ...getCheckboxFilterProps('product'),
      render(data: IProduct) {
        return <TextProductLinkField text={data.name || ''} row={data} />;
      },
    },

    {
      title: LABEL.STATUS,
      width: 200,
      ellipsis: true,
      dataIndex: 'status',
      ...getCheckboxFilterProps('status'),
      render(status: string) {
        return <ApplicationStatus status={status} />;
      },
    },

    {
      title: LABEL.START_DATE,
      dataIndex: 'start_date',
      sorter: () => 0,
      render: (data: string): ReactNode => {
        return (
          <div className="lead-table__created-at">
            {data ? moment(data).format('MMM DD, YYYY') : '-'}
          </div>
        );
      },
    },

    {
      title: LABEL.END_DATE,
      dataIndex: 'end_date',
      sorter: () => 0,
      render: (data: string): ReactNode => {
        return (
          <div className="lead-table__created-at">
            {data ? moment(data).format('MMM DD, YYYY') : '-'}
          </div>
        );
      },
    },

    {
      title: TEXT.CREATED_BY,
      width: 300,
      ellipsis: true,
      dataIndex: 'created_by',
      // ...getCheckboxFilterProps('added_by'),
      render: (data: IUser): ReactNode => {
        return data ? (
          <div className="lead-table__phone">
            {`${data?.first_name} ${data?.last_name}`}
          </div>
        ) : (
          '-'
        );
      },
    },

    {
      title: LABEL.CURRENT_STAGE,
      width: 300,
      ellipsis: true,
      dataIndex: 'activeStage',
      ...getCheckboxFilterProps('current_stage'),
      render(activeStage: IActiveStage) {
        return activeStage?.stage;
      },
    },

    {
      title: LABEL.LAST_UPDATED,
      dataIndex: 'updated_at',
      sorter: () => 0,
      render: (data: string): ReactNode => {
        return (
          <div className="lead-table__created-at">
            {formatDateWithTimezone(data, userTimeZone)}
          </div>
        );
      },
    },
  ];
};

export { TableColumns };
