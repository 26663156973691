import { fetchEnquiryActivities } from '@crm/services.api'
import { PaginationMeta } from '@model/api-response.model'
import { EnquiryActivity } from '@model/contact-form'
import { useInfiniteQuery } from '@tanstack/react-query'
import dot from 'dot-object'

const useEnquiriesActivities = (enquiryId: string) => {
  const query = useInfiniteQuery<{ data: EnquiryActivity[], meta: PaginationMeta }>({
    queryKey: ['contact-enquiry-activity', enquiryId],
    queryFn: async (args) => {
      const filter = {
        enquiryId: enquiryId ?? undefined,
      }

      const { data } = await fetchEnquiryActivities({
        limit: 10,
        page: args.pageParam || 1,
        ...dot.dot({ filter })
      })
      return data;
    },
    getNextPageParam: (lastPage) => lastPage.meta.currentPage === lastPage.meta.totalPages ? false : lastPage.meta.currentPage + 1,
  })

  return {
    activities: query.data?.pages.map(p => p.data).flat(),
    hasMore: Boolean(query.hasNextPage),
    next: query.fetchNextPage,
    currentPage: query.data?.pages.at(-1)?.meta.currentPage,
    isLoading: query.isLoading,
    refetch: query.refetch
  }
}

export default useEnquiriesActivities
