/* eslint-disable no-unused-vars */
import React from 'react';
import {
  IDepartmentTitleMenu,
  ITableTitleCard,
} from '@shared-components/models';
import { Col, Dropdown, Menu, Row, Space, Typography } from 'antd';
import { Link } from 'react-router-dom';
import {
  DELETE_BTN,
  EDIT_BTN,
  URL_DEPARTMENT,
  VIEW_BTN,
} from '@moxie/constants';
import { Button } from '@moxie/shared';
import {
  DeleteOutlined,
  EditOutlined,
  EyeOutlined,
  MoreOutlined,
} from '@ant-design/icons';
import Can, { PermissionAction, PermissionObjects } from '@lyra/privilege';

const departmentTitleMenu = ({
  data,
  deleteData,
  showDrawer,
}: IDepartmentTitleMenu) => {
  return (
    <Menu>
      {/* {Can(PermissionAction.Read, PermissionObjects.Department) && ( */}
      <Menu.Item key={1}>
        <Space direction="horizontal">
          <Link to={`${URL_DEPARTMENT}/detail/${data?.id}`}>
            <EyeOutlined /> {VIEW_BTN}
          </Link>
        </Space>
      </Menu.Item>
      {/* )}
      {Can(PermissionAction.Update, PermissionObjects.Department) && ( */}
      <Menu.Item key={2} onClick={showDrawer}>
        <Space direction="horizontal">
          <EditOutlined />
          {EDIT_BTN}
        </Space>
      </Menu.Item>
      {/* )} */}
      {/* {Can(PermissionAction.Update, PermissionObjects.Department) && ( */}
      <Menu.Item key={3} danger onClick={deleteData}>
        <Space direction="horizontal">
          <DeleteOutlined />
          {DELETE_BTN}
        </Space>
      </Menu.Item>
      {/* )} */}
    </Menu>
  );
};

export const TableDepartmentNameCard: React.FC<ITableTitleCard> = ({
  data,
  deleteData,
  showDrawer,
  actionBar,
}: ITableTitleCard) => {
  const handleDelete = () => {
    deleteData(data.id);
  };

  const handleDrawer = () => {
    showDrawer(data.id);
  };
  const menuActions = {
    data: data,
    deleteData: handleDelete,
    showDrawer: handleDrawer,
  };
  return (
    <Row>
      <Col span={18} className="align-items-center">
        <Link to={`${URL_DEPARTMENT}/detail/${data.id}`}>
          <Typography.Text className="text-primary-color initial_capital">
            {data.name}
          </Typography.Text>
        </Link>
      </Col>
      {actionBar && (
        <Col span={3}>
          <Dropdown overlay={departmentTitleMenu(menuActions)}>
            <Button
              type="text"
              className="table-cell-action-btn"
              icon={<MoreOutlined />}
            />
          </Dropdown>
        </Col>
      )}
    </Row>
  );
};
