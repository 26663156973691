import React, { useEffect } from 'react';
import { DepartmentDetailComponent } from '@moxie/shared';
import { useParams } from 'react-router-dom';
import { errorHandler } from '@moxie/utils';
import { useDispatch } from 'react-redux';
import { departmentActions } from '@lyra/core';

const DepartmentDetail: React.FC = () => {
  const { id }: { id: string } = useParams();
  const dispatch = useDispatch();

  useEffect(() => {
    if (id) {
      try {
        dispatch(departmentActions.getDepartmentRequest(id));
      } catch (e) {
        errorHandler(e);
      }
    }
    return () => {
      dispatch(departmentActions.clearDepartmentFetch());
    };
  }, [id, dispatch]);
  return <DepartmentDetailComponent />;
};

export default DepartmentDetail;
