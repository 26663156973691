import React from 'react';
import { Checkbox, Col, Form, Row } from 'antd';
import { ILeadsFormComponent } from '@shared-components/models';
import { TITLE, LABEL, GUTTER } from '@moxie/constants';
import { globalHandleCheck } from '@moxie/shared';

const PersonalDetails: React.FC<ILeadsFormComponent> = ({
  setLeadForm,
  leadForm,
  setDisabled,
}: ILeadsFormComponent) => {
  const onCheckAllChange = (e: any) => {
    setDisabled(false);
    if (!e.target.checked) {
      setLeadForm({
        ...leadForm,
        personal_details_section: {
          ...leadForm.personal_details_section,
          profile_image: false,
          date_of_birth: false,
        },
      });
      return;
    }
    setLeadForm({
      ...leadForm,
      personal_details_section: {
        ...leadForm.personal_details_section,
        profile_image: true,
        date_of_birth: true,
      },
    });
  };

  const handleChange = (e: any) => {
    globalHandleCheck(
      e,
      leadForm,
      setLeadForm,
      'personal_details_section',
      setDisabled
    );
  };

  return (
    <div>
      <Row justify="space-between" className="custom__title__text">
        <Col>{TITLE.PERSONAL_DETAILS}</Col>
        <Col>
          <Checkbox
            checked={
              leadForm?.personal_details_section?.profile_image &&
              leadForm?.personal_details_section?.date_of_birth
            }
            onChange={onCheckAllChange}
          />
        </Col>
      </Row>
      <Form.Item className="custom__formItem">
        <Row gutter={GUTTER}>
          <Col span={5} className="lead-form-box">
            <Checkbox
              name="profile_image"
              checked={leadForm?.personal_details_section?.profile_image}
              onChange={handleChange}
            >
              {LABEL.UPLOAD_PROFILE_PICTURE}
            </Checkbox>
          </Col>
          <Col span={5} className="lead-form-box">
            <Checkbox
              name="first_name"
              disabled
              checked={leadForm?.personal_details_section?.first_name || true}
              onChange={handleChange}
            >
              {LABEL.FIRST_NAME}
            </Checkbox>
          </Col>
          <Col span={5} className="lead-form-box">
            <Checkbox
              name="last_name"
              disabled
              checked={leadForm?.personal_details_section?.last_name || true}
              onChange={handleChange}
            >
              {LABEL.LAST_NAME}
            </Checkbox>
          </Col>
          <Col span={5} className="lead-form-box">
            <Checkbox
              name="date_of_birth"
              checked={leadForm?.personal_details_section?.date_of_birth}
              onChange={handleChange}
            >
              {LABEL.DATE_OF_BIRTH}
            </Checkbox>
          </Col>
        </Row>
      </Form.Item>
    </div>
  );
};

export default PersonalDetails;
