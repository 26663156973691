import React, { useEffect, useState } from 'react';
import {
  PAGE_HEADER_MENU_ADMIN_INSTITUTION_DETAIL,
  PAGE_HEADER_MENU_INSTITUTION_DETAIL,
  TAB_POSITION,
  URL_INSTITUTION_DETAIL_LINK,
} from '@moxie/constants';
import { IProfileSection } from '@shared-components/models';

import { Tabs, Typography } from 'antd';
import { useHistory, useParams } from 'react-router-dom';
import InstitutionDetailBranches from './institution-detail-branches';
import InstitutionDetailProducts from './institution-detail-products';

const InstitutionDetailContent: React.FC<IProfileSection> = (
  props: IProfileSection
) => {
  const { data } = props;
  const { TabPane } = Tabs;
  const history = useHistory();
  const params = useParams<{ slug: string; id: string }>();
  const [activeKey, setActiveKey] = useState(params.slug);

  const setDefaultActiveKey = () => {
    const selected = PAGE_HEADER_MENU_INSTITUTION_DETAIL.find(
      (item) => item.id === params.slug
    );
    selected?.id && setActiveKey(selected?.id);
  };

  const handleChange = (key: string) => {
    const selected = PAGE_HEADER_MENU_INSTITUTION_DETAIL.find(
      (item) => item.id === key
    );
    if (selected?.url) {
      history.push(
        `${URL_INSTITUTION_DETAIL_LINK}${params?.id}/${selected?.url}`
      );
    }
  };
  useEffect(() => {
    setDefaultActiveKey();
  }, [params.slug]);

  const pageHeaderMenu = (slug: string) => {
    switch (slug) {
      case 'branches':
        return (
          data?.id && <InstitutionDetailBranches branchData={data?.branches} />
        );

      case 'products':
        return data?.id && <InstitutionDetailProducts id={data.id} />;

      default:
        return;
    }
  };
  return (
    <>
      <section className="lead_content">
        <div className="pro">
          <Tabs
            activeKey={activeKey}
            tabPosition={TAB_POSITION}
            onTabClick={(key) => handleChange(key)}
          >
            {[...Array.from(PAGE_HEADER_MENU_ADMIN_INSTITUTION_DETAIL)].map(
              (item) => {
                return (
                  <TabPane
                    className="custom_tab"
                    tab={<Typography.Text strong>{item.name}</Typography.Text>}
                    key={item.id}
                  >
                    {pageHeaderMenu(item.id)}
                  </TabPane>
                );
              }
            )}
          </Tabs>
        </div>
      </section>
    </>
  );
};
export { InstitutionDetailContent };
