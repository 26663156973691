import React, { useEffect, useState } from 'react';
import {
  IDepartment,
  IForm,
  IGetPopupContainer,
} from '@shared-components/models';
import {
  AUTH_MESSAGE,
  DEFAULT_COUNTRY_CODE,
  DEPARTMENT_INFORMATION,
  DESCRIPTION_LABEL,
  DESCRIPTION_PLACEHOLDER,
  EMAIL_PLACEHOLDER,
  GUTTER,
  LABEL,
  PHONE_NUMBER_INVALID,
  PHONE_PLACEHOLDER,
  PLACEHOLDER,
  regex,
} from '@moxie/constants';
import { Col, Row } from 'antd';
import Form from 'antd/lib/form';
import { Input, PhoneInput, TextArea, UserSelectBox } from '@moxie/shared';

const DepartmentAddForm: React.FC<IForm<IDepartment>> = ({
  form,
  initialData,
  onSubmit,
  setDisabled,
}: IForm<IDepartment>) => {
  const [countryCode, setCountryCode] = useState(DEFAULT_COUNTRY_CODE);

  const handleCountryCodeChange = (code: string) => {
    setCountryCode(code);
    setDisabled(false);
  };
  const onFieldsChange = (allFields: string | any[]) => {
    if (allFields.length > 0) {
      setDisabled(false);
    }
  };
  useEffect(() => {
    if (initialData) {
      form.setFieldsValue(initialData);
    } else {
      form.setFieldsValue({});
    }
  }, [form, initialData]);

  const handleFinish = async (data: IDepartment) => {
    data.email = data?.email?.replace(/ /g, '');
    data.phone_number = data?.phone_number?.replace(/ /g, '');
    onSubmit && onSubmit(data);
    setDisabled(true);
    form.resetFields();
  };
  return (
    <div>
      <Form
        layout="vertical"
        form={form}
        onFinish={handleFinish}
        onFieldsChange={onFieldsChange}
      >
        <div className="form_section_header">{DEPARTMENT_INFORMATION}</div>
        <Row gutter={GUTTER}>
          <Col span={24}>
            <Form.Item
              name="name"
              label={LABEL.DEPARTMENT_NAME}
              rules={[{ required: true, max: 50 }]}
            >
              <Input placeholder={PLACEHOLDER.DEPARTMENT} />
            </Form.Item>
          </Col>
        </Row>
        <Row>
          <Col span={24}>
            <Form.Item
              name="description"
              label={DESCRIPTION_LABEL}
              rules={[{ required: false }]}
            >
              <TextArea rows={5} placeholder={DESCRIPTION_PLACEHOLDER} />
            </Form.Item>
          </Col>
        </Row>
        <Row gutter={GUTTER}>
          <Col span={12}>
            <Form.Item
              name="email"
              label={LABEL.EMAIL}
              rules={[
                {
                  required: true,
                  pattern: new RegExp(regex.EMAIL),
                  message: AUTH_MESSAGE.INVALID_EMAIL,
                },
              ]}
            >
              <Input placeholder={EMAIL_PLACEHOLDER} />
            </Form.Item>
          </Col>
          <Col span={12}>
            <Form.Item
              name="phone_number"
              label={LABEL.PHONE_NUMBER}
              rules={[
                {
                  pattern: new RegExp(regex.ONLY_NUMBER),
                  message: PHONE_NUMBER_INVALID,
                },
              ]}
            >
              <PhoneInput
                type="string"
                placeholder={PHONE_PLACEHOLDER}
                dialCode={countryCode}
                onChangeDialCode={handleCountryCodeChange}
              />
            </Form.Item>
          </Col>
        </Row>
        <Col span={12}>
          <div id="contact_person_list">
            <Form.Item
              name="contact_person_id"
              label={LABEL.CONTACT_PERSON}
              rules={[{ required: false }]}
            >
              <UserSelectBox
                placeholder={LABEL.CONTACT_PERSON}
                getPopupContainer={(): IGetPopupContainer =>
                  document.getElementById('contact_person_list')
                }
              />
            </Form.Item>
          </div>
        </Col>
      </Form>
    </div>
  );
};

export { DepartmentAddForm };
