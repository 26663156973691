import { AxiosResponse } from 'axios';
import * as actionTypes from './office-branch.constant';
import * as action from './office-branch.action';
import { IAction, IOfficeBranch } from '@shared-components/models';
import { errorHandler } from '@moxie/utils';
import { put, takeLatest } from 'redux-saga/effects';
import {
  getOneOfficeBranch,
  getOfficeBranches,
  postOfficeBranch,
  updateOfficeBranch,
  deleteOfficeBranch,
} from '@lyra/services.api';
import { successNotificationHandler } from '@moxie/shared';

export const getOfficeBranch = function* ({ payload }: IAction<any>) {
  try {
    if (payload) {
      const response: AxiosResponse = yield getOneOfficeBranch(payload);
      yield put(action.getOneSuccess(response.data.data));
    }
  } catch (err) {
    errorHandler(err);
    yield put(action.getOneFailure(err));
  }
};

export const getAllOfficeBranches = function* (): any {
  try {
    const response: AxiosResponse = yield getOfficeBranches('');
    yield put(action.getAllSuccess(response.data.data));
  } catch (err) {
    errorHandler(err);
    yield put(action.getAllFailure(err));
  }
};

export const addOfficeBranch = function* ({ payload }: IAction<IOfficeBranch>) {
  try {
    if (payload) {
      const response: AxiosResponse = yield postOfficeBranch(payload);
      yield put(action.addSuccess(response.data.data));
      successNotificationHandler(response.data.message);
    }
  } catch (err) {
    errorHandler(err);
    yield put(action.addFailure(err));
  }
};

export const editOfficeBranch = function* ({
  payload,
}: IAction<IOfficeBranch>) {
  try {
    if (payload) {
      const response: AxiosResponse = yield updateOfficeBranch(payload);
      yield put(action.updateSuccess(response.data.data));
      successNotificationHandler(response.data.message);
    }
  } catch (err) {
    errorHandler(err);
    yield put(action.updateFailure(err));
  }
};

export const deleteOfficeBranchData = function* ({ payload }: IAction<any>) {
  try {
    if (payload) {
      const response: AxiosResponse = yield deleteOfficeBranch(payload);
      yield put(action.deleteOfficeBranchSuccess(response.data.data));
      successNotificationHandler(response.data.message);
    }
  } catch (err) {
    errorHandler(err);
    yield put(action.deleteOfficeBranchFailure(err));
  }
};

const officeBranchSaga = function* (): unknown {
  yield takeLatest(actionTypes.GET_BRANCH_REQUEST, getOfficeBranch);
  yield takeLatest(actionTypes.GET_BRANCHES_REQUEST, getAllOfficeBranches);
  yield takeLatest(actionTypes.ADD_BRANCH_REQUEST, addOfficeBranch);
  yield takeLatest(actionTypes.UPDATE_BRANCH_REQUEST, editOfficeBranch);
  yield takeLatest(actionTypes.DELETE_BRANCH_REQUEST, deleteOfficeBranchData);
};

export default officeBranchSaga;
