import React from 'react';
import { Col, Menu, Row, Skeleton, Spin, Typography } from 'antd';
import { TEXT } from '@moxie/constants';
import { PageHeaderTitle } from '@shared-components/elements';
import { useAppSelector } from '@admin/core';
import { WorkflowStages } from './workflow-stages';

const WorkflowDetailComponent = () => {
  const { workflowDetail, stages, loading, stagesLoading } = useAppSelector(
    (state) => ({
      workflowDetail: state.workflows.singleData,
      stages: state.workflows.stages,
      loading: state.workflows.loading,
      stagesLoading: state.workflows.stages_loading,
    })
  );

  return (
    <div className="full-height">
      <PageHeaderTitle title={TEXT.WORKFLOW_DETAIL} />
      <Spin spinning={loading}>
        <Row className="page-header">
          <Col className="page-header__menu">
            <Menu selectedKeys={['1']} mode="horizontal">
              <Menu.Item key="1">
                {loading ? (
                  <Skeleton paragraph={{ rows: 0 }} active />
                ) : (
                  <Typography.Text strong>
                    {`${workflowDetail?.workflowType?.name} (${workflowDetail?.country})`}
                  </Typography.Text>
                )}
              </Menu.Item>
            </Menu>
          </Col>
        </Row>
        <WorkflowStages
          loading={loading}
          stagesLoading={stagesLoading}
          stageList={!loading && stages}
          workflowId={workflowDetail?.id}
        />
      </Spin>
    </div>
  );
};

export { WorkflowDetailComponent };
