import {
  call,
  CallEffect,
  put,
  PutEffect,
  takeLatest,
} from 'redux-saga/effects';
import { authActions } from './auth.reducer';
import { errorHandler, handleAdminTokens } from '@moxie/utils';
import {
  API_URL,
  ADMIN_EMAIL_LABEL,
  JWT_TOKEN_HEADER,
  REMEMBER_ME_COOKIE_EXPIRY_DATE,
} from '@moxie/constants';
import { IAction, ILogin } from '@shared-components/models';
import { message } from 'antd';
import { login, axiosInstance } from '@admin/services.api';
const baseUrl = process.env.NX_BASE_URL;
const logout_endpoint = `${baseUrl}${API_URL.ADMIN_LOGOUT}`;
const get_current_user_endpoint = `${baseUrl}${API_URL.GET_CURRENT_ADMIN_USER}`;

function* getCurrentUser(): Generator<
  | CallEffect<unknown>
  | PutEffect<{
      payload: any;
      type: string;
    }>
> {
  try {
    const res: any = yield call(axiosInstance.request, {
      url: `${get_current_user_endpoint}`,
      method: 'post',
    });
    if (res?.data?.data) yield put(authActions.authUser(res?.data?.data));
  } catch (err) {
    errorHandler(err);
  }
}

function* logout(
  data: any
): Generator<
  | CallEffect<unknown>
  | PutEffect<{
      payload: any;
      type: string;
    }>
> {
  try {
    yield call(axiosInstance.request, {
      url: `${logout_endpoint}`,
      method: 'post',
      headers: { Authorization: `${JWT_TOKEN_HEADER} ${data.payload}` },
    });
    localStorage.removeItem(ADMIN_EMAIL_LABEL);
  } catch (err) {
    errorHandler(err, false);
  }
}

function* loginAccount(
  data: IAction<ILogin>
): Generator<CallEffect<unknown> | PutEffect<IAction<ILogin>>> {
  try {
    yield put(authActions.loading(true));
    let response: any;
    if (data.payload) {
      response = yield call(login, data.payload);
    }

    if (response.data.message) {
      message.warning(response.data.message);
    } else {
      if (response.data.access_token && response.data.refresh_token) {
        if (data.payload?.remember_me)
          handleAdminTokens(response, REMEMBER_ME_COOKIE_EXPIRY_DATE);
        handleAdminTokens(response);
        yield put(authActions.setUser(response.data.user));
        yield put(authActions.closeSessionModal());
      }
    }
    yield put(authActions.loading(false));
  } catch (err) {
    errorHandler(err);
    yield put(authActions.loading(false));
  }
}

export default function* authSaga(): any {
  yield takeLatest(authActions.logout.type, logout);
  yield takeLatest(authActions.login.type, loginAccount);
  yield takeLatest(authActions.getAuthUser.type, getCurrentUser);
}
