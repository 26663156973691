/* eslint-disable no-unused-vars */
import React, { useEffect, useState } from 'react';
import { useForm } from 'antd/lib/form/Form';
import { Col, Row, Tabs } from 'antd';
import { axiosInstance, getAllApplications } from '@lyra/services.api';
import { getWorkflowStages } from '@admin/services.api';
import { ApplicationTable } from './application-table';
import {
  Button,
  Option,
  PageHeaderTitle,
  SearchableSelect,
} from '@shared-components/elements';
import {
  ALL_APPLICATIONS,
  LABEL,
  TEXT,
  UNASSIGNED_LABEL,
} from '@moxie/constants';
import { useDispatch } from 'react-redux';
import { useAppSelector, userActions, workflowActions } from '@lyra/core';
import {
  IApplicationStage,
  ILeadApplicationForm,
  IWorkflow,
} from '@shared-components/models';
import Can, { PermissionAction, PermissionObjects } from '@lyra/privilege';
import { PlusOutlined } from '@ant-design/icons';
import { CreateApplicationDrawer } from './create-application-drawer';
import { applicationActions } from '@lyra/core';

const ApplicationListComponent = () => {
  const { TabPane } = Tabs;
  const [form] = useForm();
  const dispatch = useDispatch();
  const [refreshTable, setRefreshTable] = useState(true);
  const [visible, setVisible] = useState<boolean>(false);
  const [workflows, setWorkflows] = useState([]);
  const [filter, setFilter] = useState<any>({});
  const [stages, setStages] = useState<IApplicationStage[]>([]);
  const [activeKey, setActiveKey] = useState<string>('All');
  const [myApplications, setMyApplications] = useState<boolean>(false);
  const [unassignedApplications, setUnassignedApplications] = useState<boolean>(
    false
  );
  const [showAll, setShowAll] = useState<boolean>(true);
  const [loading, setLoading] = useState<boolean>(false);
  const [isTabChanged, setIsTabChanged] = useState<boolean>(false);
  const loggedInUserId = useAppSelector((state) => state?.auth?.user?.id);
  const [initialData, setInitialData] = useState<any>();
  const [isEdit, setIsEdit] = useState<boolean>(false);
  const [isSubmitted, setIsSubmitted] = useState<boolean>(false);
  const triggerRefresh = () => setRefreshTable((prev) => !prev);
  const [disabled, setDisabled] = useState(true);

  const currentUser = useAppSelector((store) => store.auth.user);
  const socket = useAppSelector((store) => store.socket.wss);

  const getApplications = async (params: string) => {
    setLoading(true);
    const response = await getAllApplications(params);
    setLoading(false);
    return response;
  };

  const handleTableButtons = async (type: string, isActive: boolean) => {
    setIsTabChanged(!isTabChanged);
    switch (type) {
      case 'unassigned':
        setUnassignedApplications(!unassignedApplications);
        setMyApplications(false);
        setShowAll(isActive);
        setFilter({ ...filter, userId: isActive ? '' : 'unassigned' });
        return;
      case 'myApplications':
        setMyApplications(!myApplications);
        setUnassignedApplications(false);
        setShowAll(isActive);
        setFilter({ ...filter, userId: isActive ? '' : loggedInUserId });
        return;
      case 'showAll':
        if (isActive) return;
        setShowAll(!showAll);
        setUnassignedApplications(false);
        setMyApplications(false);
        setFilter({ ...filter, userId: '' });
        return;
      default:
        return;
    }
  };

  const handleChangeWorkflow = async (key: string | any) => {
    if (key === 'all') {
      setFilter({ ...filter, workflowId: '', stageId: '' });
      setStages([]);
      return;
    }
    setFilter({ ...filter, workflowId: key });
    const response = await getWorkflowStages(key);
    setStages(response?.data?.data);
  };

  const handleTabClick = (key: string) => {
    setActiveKey(key);
    if (key === 'All') {
      setFilter({ ...filter, stageId: '' });
    } else {
      setFilter({ ...filter, stageId: key });
    }
  };

  const showDrawer = async () => {
    setInitialData({});
    setVisible(true);
  };

  const onClose = () => {
    setVisible(false);
    setIsEdit(false);
    setDisabled(true);
    triggerRefresh();
    form.resetFields();
  };
  const handleFormSubmit = () => {
    form.submit();
  };
  const handleSubmit = async (data: ILeadApplicationForm) => {
    const { contact_id } = data;
    setIsSubmitted(true);
    setDisabled(true);
    dispatch(
      applicationActions.addRequest(
        { ...data },
        currentUser,
        socket,
        { id: contact_id },
        onClose,
        setIsSubmitted
      )
    );
  };

  useEffect(() => {
    dispatch(userActions.fetchUsers());
    axiosInstance.get('workflows/am/all').then((res) => {
      setWorkflows(res?.data?.data);
    });
  }, []);

  return (
    <div className="full-height">
      <PageHeaderTitle title={TEXT.APPLICATIONS} />
      <div className="site-page-header-application padding-bottom-1">
        <Row justify="space-between" className="padding-1">
          <Col>
            <SearchableSelect
              className="workflow-select"
              optionFilter="search_prop"
              defaultValue="all"
              allowClear={false}
              onChange={handleChangeWorkflow}
              dropdownMatchSelectWidth={false}
            >
              <Option key="all" value="all" search_prop="all">
                <b>{ALL_APPLICATIONS}</b>
              </Option>
              {workflows &&
                workflows.map((item: IWorkflow) => {
                  return (
                    <Option
                      value={item.id || ''}
                      key={item.id}
                      search_prop={`${item?.country} ${item?.service_name} ${item?.wf_name}`}
                    >
                      <div className="product-workflow-options">
                        <b>{item?.wf_name}</b> &bull; {item?.country} &emsp;
                        <small>{item?.service_name}</small>
                      </div>
                    </Option>
                  );
                })}
            </SearchableSelect>
          </Col>
          <Col>
            {/* {Can(
              PermissionAction.Create,
              PermissionObjects.ClientApplication
            ) && ( */}
            <Button type="primary" typeof="button" onClick={() => showDrawer()}>
              <PlusOutlined />
              {LABEL.CREATE_NEW_APPLICATION}
            </Button>
            {/* )} */}
          </Col>
        </Row>
      </div>
      {stages && stages.length > 0 ? (
        <div className="chrome-tab card-container">
          <Tabs
            type="card"
            className="stage-tab-position"
            activeKey={activeKey}
            defaultActiveKey="All"
            onTabClick={(key) => {
              handleTabClick(key);
            }}
          >
            <TabPane key="All" tab="All Stages" />
            {stages.map((item: IApplicationStage) => {
              return <TabPane key={item.id} tab={item.stage} />;
            })}
          </Tabs>
        </div>
      ) : null}

      <CreateApplicationDrawer
        form={form}
        isOpen={visible}
        onClose={onClose}
        handleFormSubmit={handleFormSubmit}
        isSubmitted={isSubmitted}
        handleSubmit={handleSubmit}
        initialData={initialData}
        isEdit={isEdit}
        disabled={disabled}
        setDisabled={setDisabled}
      />

      <ApplicationTable
        getData={getApplications}
        tableName={TEXT.APPLICATIONS}
        filter={filter}
        refreshTable={refreshTable}
        setRefreshTable={setRefreshTable}
        isTabChanged={isTabChanged}
        tableButtons={
          <>
            <Button
              type={myApplications ? 'primary' : 'default'}
              onClick={() =>
                handleTableButtons('myApplications', myApplications)
              }
              disabled={loading}
            >
              {LABEL.ONLY_MY_APPLICATIONS}
            </Button>
            <Button
              type={unassignedApplications ? 'primary' : 'default'}
              onClick={() =>
                handleTableButtons('unassigned', unassignedApplications)
              }
              disabled={loading}
            >
              {UNASSIGNED_LABEL}
            </Button>
            <Button
              type={showAll ? 'primary' : 'default'}
              onClick={() => handleTableButtons('showAll', showAll)}
              disabled={loading}
            >
              {LABEL.SHOW_ALL}
            </Button>
          </>
        }
      />
    </div>
  );
};

export { ApplicationListComponent };
