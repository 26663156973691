import axios from './axios';
import { IResponse } from '@shared-components/models';
import { API_URL } from '@moxie/constants';

const getServices = (): Promise<IResponse> => {
  return axios.get(API_URL.SERVICE);
};

const getServiceWorkFlow = (id: string): Promise<IResponse> => {
  return axios.get(`${id}${API_URL.SERVICE}`);
};

const fetchServiceCountry = (id: string): Promise<IResponse> => {
  return axios.get(`${API_URL.SERVICE}/${id}/country/list`);
};

const fetchServiceCountryWorkflow = (
  id: string,
  country: string
): Promise<IResponse> => {
  return axios.get(`${API_URL.SERVICE}/${id}/${country}`);
};

export {
  getServices,
  getServiceWorkFlow,
  fetchServiceCountry,
  fetchServiceCountryWorkflow,
};
