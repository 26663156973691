import * as actionTypes from './other-detail-values.constant';

export const LEAD_OTHER_DETAIL_VALUE_FEATURE_KEY = 'lead_other_detail_value';

export interface IOtherDetailValueState {
  valueData: any;
  error: boolean | null;
  loading: boolean;
}

export const initialOtherDetailValueData: IOtherDetailValueState = {
  valueData: [],
  error: null,
  loading: false,
};

export const leadOtherDetailValueReducer = (
  state = initialOtherDetailValueData,
  action: any
) => {
  switch (action.type) {
    case actionTypes.GET_LEAD_OTHER_DETAIL_VALUES_REQUEST:
      return {
        ...state,
        loading: true,
      };

    case actionTypes.GET_LEAD_OTHER_DETAIL_VALUES_SUCCESS:
      return {
        ...state,
        loading: false,
        valueData: action.payload,
      };

    case actionTypes.GET_LEAD_OTHER_DETAIL_VALUES_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.payload,
      };
    case actionTypes.GET_LEAD_OTHER_DETAIL_VALUES_CLEAR:
      return {
        ...state,
        valueData: [],
        loading: false,
        error: null,
      };

    case actionTypes.GET_LEAD_OTHER_DETAIL_VALUE_REQUEST:
      return {
        ...state,
        loading: true,
      };

    case actionTypes.GET_LEAD_OTHER_DETAIL_VALUE_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.payload,
      };

    case actionTypes.ADD_LEAD_OTHER_DETAIL_VALUE_REQUEST:
      return {
        ...state,
        loading: true,
      };
    case actionTypes.ADD_LEAD_OTHER_DETAIL_VALUE_SUCCESS:
      return {
        ...state,
        loading: false,
        valueData: action.payload,
      };
    case actionTypes.ADD_LEAD_OTHER_DETAIL_VALUE_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.payload,
      };
    case actionTypes.UPDATE_LEAD_OTHER_DETAIL_VALUE_REQUEST:
      return {
        ...state,
        loading: true,
      };
    case actionTypes.UPDATE_LEAD_OTHER_DETAIL_VALUE_SUCCESS:
      return {
        ...state,
        loading: false,
        valueData: state.valueData.map((item: { id: string }) => {
          return item.id === action.payload.id ? action.payload : item;
        }),
      };
    case actionTypes.UPDATE_LEAD_OTHER_DETAIL_VALUE_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.payload,
      };

    case actionTypes.DELETE_LEAD_OTHER_DETAIL_VALUE_REQUEST:
      return {
        ...state,
        loading: true,
      };
    case actionTypes.DELETE_LEAD_OTHER_DETAIL_VALUE_SUCCESS:
      return {
        ...state,
        loading: false,
        valueData: state.valueData.filter(
          (item: { id: string }) => item.id !== action.payload
        ),
      };
    case actionTypes.DELETE_LEAD_OTHER_DETAIL_VALUE_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.payload,
      };

    default:
      return state;
  }
};

export default leadOtherDetailValueReducer;
