import React, { useState } from 'react';
import { ILeadProfileSection } from '@shared-components/models';
import { LinkOutlined, BarsOutlined } from '@ant-design/icons';
import { Avatar, Col, Row, Tag, Tooltip, Button, Skeleton } from 'antd';
import { TEXT } from '@moxie/constants';

const ContactProfileSection: React.FC<ILeadProfileSection> = (
  props: ILeadProfileSection
) => {
  const { lead, handleView, loading } = props;

  const [isCopied, setIsCopied] = useState(false);

  const copyToClipboard = (text: string) => {
    navigator.clipboard.writeText(text);
    setIsCopied(true);
  };

  return (
    <section className="profile">
      <div className="bg-white padding-left-3 padding-y1">
        <Row gutter={24} wrap={false}>
          {loading ? (
            <Skeleton avatar paragraph={{ rows: 3 }} active />
          ) : (
            <Col lg={8} md={8} className="border-right">
              <Row justify="space-between">
                <Col>
                  <div className="padding-top-1">
                    <div className="profile__info__item profile__info__user">
                      <Avatar size="large" className="initial_capital">
                        {lead?.first_name?.substring(0, 1) +
                          lead?.last_name?.substring(0, 1).toUpperCase()}
                      </Avatar>
                      <div className="profile__info__user__detail initial_capital">
                        <h3>
                          {lead?.first_name} {lead?.last_name}
                        </h3>
                        <Tag className="tag-primary">{lead?.status}</Tag>
                        {lead?.archived && (
                          <Tag color="#87d068">{TEXT.ARCHIVED}</Tag>
                        )}
                      </div>
                    </div>
                  </div>
                  <div className="padding-top-1">
                    <Tooltip title={lead?.internal_id}>
                      <h1 className="profile__title">
                        {TEXT.INTERNAL_ID}:{' '}
                        <span className="text-link-color">
                          {lead?.company?.company_code +
                            '-' +
                            lead?.internal_id}
                        </span>
                      </h1>
                    </Tooltip>
                  </div>
                </Col>
                <Col>
                  <div>
                    <h4 className="profile__title margin-top-1">
                      {TEXT.CREATED_BY}&nbsp;
                      <span className="text-link-color ">
                        {lead?.user?.first_name}{' '}
                        {lead?.user ? lead.user?.last_name : '-'}
                      </span>
                    </h4>
                  </div>
                </Col>
                <Col>
                  <div className="profile__info__edit margin-top-1">
                    <Tooltip placement="right" title={TEXT.VIEW_LEAD_DETAIL}>
                      <Button
                        icon={<BarsOutlined />}
                        onClick={handleView}
                        type="link"
                      />
                    </Tooltip>
                  </div>
                </Col>
              </Row>
            </Col>
          )}
          {loading ? (
            <Skeleton paragraph={{ rows: 2 }} title={false} active />
          ) : (
            <Col lg={8} md={8} className="border-right ">
              <Row>
                <Col>
                  {lead?.email && (
                    <Row className="email_div ">
                      <Col>
                        <h4 className="profile__title ">
                          {TEXT.EMAIL}:{' '}
                          <a
                            className="text-link-color initial_small"
                            href={`mailto:${lead?.email}`}
                          >
                            {lead?.email}
                          </a>
                        </h4>
                      </Col>
                      <Col className="hide">
                        <Tooltip
                          className=" margin-left-1 "
                          placement="right"
                          color={isCopied ? '#8EAF74' : '#9B9D9A '}
                          title={
                            isCopied
                              ? `${TEXT.COPIED_SUCCESS}`
                              : `${TEXT.COPY_EMAIL}`
                          }
                        >
                          <LinkOutlined
                            className="copy_email_icon"
                            onClick={() => copyToClipboard(`${lead?.email}`)}
                          />
                        </Tooltip>
                      </Col>
                    </Row>
                  )}
                  {lead?.phone && (
                    <div>
                      <h4 className="profile__title margin-right-1">
                        {TEXT.PHONE}:{' '}
                        <Tooltip title={lead?.phone}>
                          <span className="text-link-color ">
                            {lead?.phone}
                          </span>
                        </Tooltip>
                      </h4>
                    </div>
                  )}
                </Col>
              </Row>
            </Col>
          )}
          {loading ? (
            <Skeleton paragraph={{ rows: 1 }} active />
          ) : (
            <Col lg={8} md={9}>
              <div>
                <h4 className="profile__title">
                  {TEXT.BRANCH}:{' '}
                  <span className="text-link-color ">
                    {lead?.branch ? lead.branch.name : '-'}
                  </span>
                </h4>
              </div>
              <div>
                <h4 className="profile__title">
                  {TEXT.TENANT}:{' '}
                  <span className="text-link-color ">
                    {lead?.company ? lead.company?.company_name : '-'}
                  </span>
                </h4>
              </div>
            </Col>
          )}
        </Row>
      </div>
    </section>
  );
};

export { ContactProfileSection };
