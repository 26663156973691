import { AxiosResponse } from 'axios';
import { IAction, IWorkflow } from '@shared-components/models';
import { errorHandler } from '@moxie/utils';
import { put, takeLatest } from 'redux-saga/effects';
import * as workflow from './workflow.constant';
import * as workflowAction from './workflow.action';
import { successNotificationHandler } from '@moxie/shared';
import {
  deleteWorkflow,
  deleteWorkflowStageChecklist,
  getChecklists,
  getOneWorkflow,
  getWfChecklists,
  getWorkflowStages,
  postWorkflow,
  updateWfForm as updateWfFormApi,
  fetchWfStageForm as fetchWfStageFormApi,
  getAllWorkflows,
} from '@admin/services.api';

export const getAllWorkflowData = function* () {
  try {
    const response: AxiosResponse = yield getAllWorkflows();
    yield put(workflowAction.getWorkflowsSuccess(response.data.data));
  } catch (err) {
    yield put(workflowAction.getWorkflowFailure(err));
  }
};

export const deleteWorkflowData = function* ({
  payload,
  triggerRefresh,
}: IAction<any>) {
  try {
    if (payload) {
      const response: AxiosResponse = yield deleteWorkflow(payload);
      successNotificationHandler(response.data?.message);
      triggerRefresh && triggerRefresh();
    }
  } catch (err) {
    errorHandler(err);
    yield put(workflowAction.deleteWorkflowFailure(err));
  }
};

export const addWorkflowData = function* ({
  payload,
  triggerRefresh,
}: IAction<IWorkflow>) {
  try {
    if (payload) {
      const response: AxiosResponse = yield postWorkflow(payload);
      yield put(workflowAction.addWorkflowSuccess(response?.data?.data));
      yield successNotificationHandler(response?.data?.message);
      triggerRefresh && triggerRefresh();
    }
  } catch (err) {
    errorHandler(err);
    yield put(workflowAction.addWorkflowFailure(err));
  }
};

export const getOneWorkflowData = function* ({ payload }: IAction<any>) {
  try {
    if (payload) {
      const response: AxiosResponse = yield getOneWorkflow(payload);
      yield put(workflowAction.getWorkflowSuccess(response?.data?.data));
    }
  } catch (err) {
    errorHandler(err);
    yield put(workflowAction.getWorkflowFailure(err));
  }
};

export const getAllWorkflowStages = function* ({ payload }: IAction<any>) {
  try {
    if (payload) {
      const response: AxiosResponse = yield getWorkflowStages(payload);
      yield put(workflowAction.getWorkflowStagesSuccess(response?.data?.data));
    }
  } catch (err) {
    errorHandler(err);
    yield put(workflowAction.getWorkflowStagesFailure(err));
  }
};

export const getAllWfStageChecklists = function* ({ payload }: IAction<any>) {
  try {
    if (payload) {
      const response: AxiosResponse = yield getWfChecklists(payload);
      yield put(
        workflowAction.getWfStageChecklistsSuccess(response?.data?.data)
      );
    }
  } catch (err) {
    errorHandler(err);
    yield put(workflowAction.getWfStageChecklistsFailure(err));
  }
};

export const getAllChecklistData = function* (): any {
  try {
    const response: AxiosResponse = yield getChecklists();
    yield put(workflowAction.getAllChecklistsSuccess(response.data.data));
  } catch (err) {
    yield put(workflowAction.getAllChecklistsFailure(err));
  }
};

export const deleteStageChecklist = function* ({ payload }: IAction<any>) {
  try {
    if (payload) {
      const response: AxiosResponse = yield deleteWorkflowStageChecklist(
        payload
      );
      yield put(workflowAction.deleteStageChecklistSuccess(response.data.data));
      successNotificationHandler(response.data.message);
    }
  } catch (err) {
    errorHandler(err);
    yield put(workflowAction.deleteStageChecklistFailure(err));
  }
};

export const updateWfForm = function* ({ payload }: IAction<any>) {
  try {
    if (payload) {
      const response: AxiosResponse = yield updateWfFormApi(payload);
      yield put(workflowAction.updateWfFormSuccess(response.data.data));
      successNotificationHandler(response.data.message);
    }
  } catch (err) {
    errorHandler(err);
    yield put(workflowAction.updateWfFormFailure(err));
  }
};

export const getWfStageForm = function* ({ payload }: IAction<string>) {
  try {
    if (payload) {
      const response: AxiosResponse = yield fetchWfStageFormApi(payload);
      yield put(workflowAction.getWfStageFormSuccess(response.data.data));
    }
  } catch (err) {
    errorHandler(err);
    yield put(workflowAction.getWfStageFormFailure(err));
  }
};

export default function* workflowSaga(): unknown {
  yield takeLatest(workflow.FETCH_WORKFLOWS_REQUEST, getAllWorkflowData);
  yield takeLatest(workflow.DELETE_WORKFLOW_REQUEST, deleteWorkflowData);
  yield takeLatest(workflow.ADD_WORKFLOW_REQUEST, addWorkflowData);
  yield takeLatest(workflow.FETCH_WORKFLOW_REQUEST, getOneWorkflowData);
  yield takeLatest(workflow.UPDATE_WF_FORM_REQUEST, updateWfForm);
  yield takeLatest(workflow.FETCH_WF_FORM_REQUEST, getWfStageForm);
  yield takeLatest(
    workflow.FETCH_WORKFLOW_STAGES_REQUEST,
    getAllWorkflowStages
  );
  yield takeLatest(
    workflow.FETCH_WORKFLOW_STAGE_CHECKLISTS_REQUEST,
    getAllWfStageChecklists
  );
  yield takeLatest(workflow.FETCH_CHECKLISTS_REQUEST, getAllChecklistData);
  yield takeLatest(
    workflow.DELETE_WF_STAGE_CHECKLIST_REQUEST,
    deleteStageChecklist
  );
}
