import axios from './axios';
import { API_URL } from '@moxie/constants';
import { errorHandler } from '@moxie/utils';

const getAdminAllDocuments = (id: string, str: string): Promise<any> => {
  const params = { id };
  return axios.get(`${API_URL.ADMIN_CONTACT_DOCUMENT}${str}`, { params });
};

const getAdminOneDocument = async (id: string): Promise<any> => {
  try {
    const response = await axios.get(
      `${API_URL.DOCUMENTS}/admin/download-file/${id}`
    );
    return response?.data;
  } catch (err) {
    errorHandler(err);
  }
};

export { getAdminAllDocuments, getAdminOneDocument };
