import React, { ReactElement } from 'react';
import { Card, Typography } from 'antd';
import { EditDeleteButton } from '@shared-components/elements';
import { capitalizeFirstLetter } from 'libs/shared/src/functions';

interface IProfessionalExperienceProps {
    title: string;
    companyName?: string;
    employmentType: string;
    children: React.ReactNode | ReactElement | React.ReactChildren;
}
const ProfessionalExperienceCard: React.FC<IProfessionalExperienceProps> = ({
    title,
    companyName,
    employmentType,
    children,
}: IProfessionalExperienceProps) => {

    return (
        <Card
            className="education-card"
            title={
                <div className="professional-experience">
                    <Typography.Title className="text-primary-color" level={4}>
                        {title}
                    </Typography.Title>
                    <Typography.Text>{companyName}</Typography.Text>
                    <Typography.Text type="secondary">
                        -
                        {capitalizeFirstLetter(
                            employmentType === 'full_time'
                                ? 'Full Time'
                                : employmentType === 'part_time'
                                    ? 'part Time'
                                    : employmentType === 'self_employed'
                                        ? 'Self Employed'
                                        : employmentType
                        )}
                    </Typography.Text>
                </div>
            }
        >
            {children}
        </Card >
    );
};
export { ProfessionalExperienceCard };
