import * as actionTypes from './workflow.constant';
import { IAction, IPayload, IWorkflow } from '@shared-components/models';

export const addWorkflowRequest = (payload: IWorkflow): IAction<IWorkflow> => {
  return {
    type: actionTypes.ADD_WORKFLOW_REQUEST,
    payload,
  };
};

export const addWorkflowSuccess = (payload: IWorkflow): IAction<IWorkflow> => ({
  type: actionTypes.ADD_WORKFLOW_SUCCESS,
  payload,
});

export const addWorkflowFailure = (payload: IPayload<IWorkflow>): IAction => ({
  type: actionTypes.ADD_WORKFLOW_FAILURE,
  payload,
});

export const updateWorkflowRequest = (payload: any): IAction<IWorkflow> => ({
  type: actionTypes.UPDATE_WORKFLOW_REQUEST,
  payload,
});

export const updateWorkflowSuccess = (
  payload: IWorkflow
): IAction<IWorkflow> => ({
  type: actionTypes.UPDATE_WORKFLOW_SUCCESS,
  payload,
});

export const updateWorkflowFailure = (
  payload: IPayload<IWorkflow>
): IAction => ({
  type: actionTypes.UPDATE_WORKFLOW_FAILURE,
  payload,
});

export const getWorkflowsRequest = (): IAction => ({
  type: actionTypes.FETCH_WORKFLOWS_REQUEST,
});

export const getWorkflowsSuccess = (
  payload: IWorkflow[]
): IAction<IWorkflow[]> => ({
  type: actionTypes.FETCH_WORKFLOWS_SUCCESS,
  payload,
});

export const getWorkflowsFailure = (payload: IPayload<IWorkflow>): IAction => ({
  type: actionTypes.FETCH_WORKFLOWS_FAILURE,
  payload,
});

export const getWorkflowRequest = (id: string): IAction<string> => ({
  type: actionTypes.FETCH_WORKFLOW_REQUEST,
  payload: id,
});

export const getWorkflowSuccess = (payload: IWorkflow): IAction<IWorkflow> => ({
  type: actionTypes.FETCH_WORKFLOW_SUCCESS,
  payload,
});

export const getWorkflowFailure = (payload: IPayload<IWorkflow>): IAction => ({
  type: actionTypes.FETCH_WORKFLOW_FAILURE,
  payload,
});

export const deleteWorkflowRequest = (
  id: string,
  triggerRefresh: () => void
): IAction<string> => ({
  type: actionTypes.DELETE_WORKFLOW_REQUEST,
  payload: id,
  triggerRefresh: triggerRefresh,
});

export const deleteWorkflowSuccess = (payload: any): IAction => ({
  type: actionTypes.DELETE_WORKFLOW_SUCCESS,
  payload,
});

export const deleteWorkflowFailure = (
  payload: IPayload<IWorkflow>
): IAction => ({
  type: actionTypes.DELETE_WORKFLOW_FAILURE,
  payload,
});

export const clearWorkflowFetch = (): IAction => ({
  type: actionTypes.CLEAR_WORKFLOW_FETCH,
});

export const getWorkflowStagesRequest = (id: string): IAction<string> => ({
  type: actionTypes.FETCH_WORKFLOW_STAGES_REQUEST,
  payload: id,
});

export const getWorkflowStagesSuccess = (payload: any): IAction<any> => ({
  type: actionTypes.FETCH_WORKFLOW_STAGES_SUCCESS,
  payload,
});

export const getWorkflowStagesFailure = (payload: IPayload<any>): IAction => ({
  type: actionTypes.FETCH_WORKFLOW_STAGES_FAILURE,
  payload,
});

export const getWfStageChecklists = (id: string): IAction<string> => ({
  type: actionTypes.FETCH_WORKFLOW_STAGE_CHECKLISTS_REQUEST,
  payload: id,
});

export const getWfStageChecklistsSuccess = (payload: any): IAction<any> => ({
  type: actionTypes.FETCH_WORKFLOW_STAGE_CHECKLISTS_SUCCESS,
  payload,
});

export const getWfStageChecklistsFailure = (
  payload: IPayload<any>
): IAction => ({
  type: actionTypes.FETCH_WORKFLOW_STAGE_CHECKLISTS_FAILURE,
  payload,
});

export const clearWfStageChecklist = () => ({
  type: actionTypes.CLEAR_WORKFLOW_STAGE_CHECKLISTS,
});

export const getAllChecklistsRequest = () => ({
  type: actionTypes.FETCH_CHECKLISTS_REQUEST,
});

export const getAllChecklistsSuccess = (
  payload: IPayload<any>
): IAction<any> => ({
  type: actionTypes.FETCH_CHECKLISTS_SUCCESS,
  payload,
});

export const getAllChecklistsFailure = (payload: IPayload<any>): IAction => ({
  type: actionTypes.FETCH_CHECKLISTS_FAILURE,
  payload,
});

export const deleteStageChecklistRequest = (id: string): IAction<string> => ({
  type: actionTypes.DELETE_WF_STAGE_CHECKLIST_REQUEST,
  payload: id,
});

export const deleteStageChecklistSuccess = (payload: any): IAction => ({
  type: actionTypes.DELETE_WF_STAGE_CHECKLIST_SUCCESS,
  payload,
});

export const deleteStageChecklistFailure = (
  payload: IPayload<any>
): IAction => ({
  type: actionTypes.DELETE_WF_STAGE_CHECKLIST_FAILURE,
  payload,
});

// ---- Workflow form action start ----
export const updateWfFormRequest = (payload: any): IAction<any> => ({
  type: actionTypes.UPDATE_WF_FORM_REQUEST,
  payload,
});

export const updateWfFormSuccess = (payload: any): IAction<any> => ({
  type: actionTypes.UPDATE_WF_FORM_SUCCESS,
  payload,
});

export const updateWfFormFailure = (payload: any): IAction<any> => ({
  type: actionTypes.UPDATE_WF_FORM_FAILURE,
  payload,
});

export const getWfStageFormRequest = (stageId: string): IAction<string> => ({
  type: actionTypes.FETCH_WF_FORM_REQUEST,
  payload: stageId,
});

export const getWfStageFormSuccess = (
  payload: IWorkflow[]
): IAction<IWorkflow[]> => ({
  type: actionTypes.FETCH_WF_FORM_SUCCESS,
  payload,
});

export const getWfStageFormFailure = (
  payload: IPayload<IWorkflow>
): IAction => ({
  type: actionTypes.FETCH_WF_FORM_FAILURE,
  payload,
});

export const clearWfStageForm = () => ({
  type: actionTypes.CLEAR_WF_FORM,
});
