import React, { useState } from 'react';
import { Route, Redirect, RouteProps } from 'react-router-dom';
import Cookies from 'js-cookie';
import { ADMIN_COOKIE_ACCESS } from '@moxie/constants';
import { SessionRefreshModal } from '@shared-components/modules';
import { AdminDashboardLayout } from '@admin/shared';
import { Spin } from 'antd';

const AdminProtectedRoute = ({
  component: Component,
  ...rest
}: RouteProps | any): any => {
  const [access] = useState(Cookies.get(`${ADMIN_COOKIE_ACCESS}`));
  return (
    <Route
      {...rest}
      render={(props) =>
        !access ? (
          <Redirect to="/" />
        ) : (
          <AdminDashboardLayout>
            <React.Suspense
              fallback={
                <div className="page-load">
                  <Spin />
                </div>
              }
            >
              <div className="body-container">
                <SessionRefreshModal />
                <Component {...props} />
              </div>
            </React.Suspense>
          </AdminDashboardLayout>
        )
      }
    />
  );
};

export { AdminProtectedRoute };
