import React from 'react';
import { VerifyEmail as VerifyEmailModule } from '@moxie/shared';
import { resendEmailToken, verifyEmailToken } from '@lyra/services.api';
import {
  IEmailVerification,
  IResendEmailVerification,
} from '@shared-components/models';

const VerifyEmail: React.FC = () => {
  const handleVerification = (token: IEmailVerification) => {
    return verifyEmailToken(token);
  };

  const handleResendEmailVerification = (data: IResendEmailVerification) => {
    return resendEmailToken(data);
  };
  return (
    <div className="login-layout">
      <VerifyEmailModule
        onEmailVerification={handleVerification}
        onResendEmailVerification={handleResendEmailVerification}
      />
    </div>
  );
};

export default VerifyEmail;
