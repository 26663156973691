import { put, takeLatest } from 'redux-saga/effects';
import { IAction } from '@shared-components/models';
import { errorHandler } from '@moxie/utils';
import {
  deleteLanguageTestScore as removeLanguageTestScore,
  getAllLanguageTestScore as fetchLanguageTestScore,
  createLanguageTestScore,
  updateLanguageTestScore,
} from '@lyra/services.api';
import { AxiosResponse } from 'axios';
import { ADD_SUCCESS, DELETE_SUCCESS, UPDATE_SUCCESS } from '@moxie/constants';
import { successNotificationHandler } from '@moxie/shared';
import * as actionTypes from './language-test-score.constant';
import * as actionLanguageTestScore from './language-test-score.action';

function* getLanguageTestScoresSaga({ payload }: IAction<string>) {
  try {
    const response: AxiosResponse = yield fetchLanguageTestScore(payload);
    yield put(
      actionLanguageTestScore.getLanguageTestScoresSuccess(
        response?.data?.data?.tests || []
      )
    );
  } catch (err) {
    errorHandler(err);
    yield put(actionLanguageTestScore.getLanguageTestScoresFailure(err));
  }
}

function* addLanguageTestScoreSaga({
  payload,
  user,
  socket,
  lead,
  onClose,
}: any) {
  try {
    if (payload) {
      const response: AxiosResponse = yield createLanguageTestScore(payload);
      yield put(
        actionLanguageTestScore.addLanguageTestScoreSuccess(
          response.data.data.tests
        )
      );
      socket.emit('qualification-notification', {
        user_id: user.id,
        parent_id: lead?.id,
        receiver_id: lead?.assignee_id,
        receivers_many: lead?.followers_id,
        profile_tab: 'education-background',
        type: 'english-test',
        update_type: 'added',
      });
      socket.emit('added-language-test-score-to-server', {
        user_id: user.id,
        language_test_id: response?.data?.data.id,
        data: response?.data,
        parent_id: lead?.id,
        company_id: user?.company?.id,
      });
      onClose();
      successNotificationHandler(ADD_SUCCESS);
    }
  } catch (err) {
    errorHandler(err);
    yield put(actionLanguageTestScore.addLanguageTestScoreFailure(err));
  }
}

function* updateLanguageTestScoreSaga({
  payload,
  user,
  socket,
  lead,
  onClose,
}: any) {
  try {
    if (!payload) return;
    const response: AxiosResponse = yield updateLanguageTestScore(payload);
    yield put(
      actionLanguageTestScore.updateLanguageTestScoreSuccess(response.data.data)
    );
    socket.emit('qualification-notification', {
      user_id: user.id,
      parent_id: lead?.id,
      receiver_id: lead?.assignee_id,
      receivers_many: lead?.followers_id,
      profile_tab: 'education-background',
      type: 'english-test',
      update_type: 'updated',
    });
    socket.emit('updated-language-test-to-server', {
      user_id: user.id,
      language_test_id: response?.data?.data.id,
      data: response?.data,
      parent_id: lead?.id,
      company_id: user?.company?.id,
    });
    onClose();
    successNotificationHandler(UPDATE_SUCCESS);
  } catch (err) {
    errorHandler(err);
    yield put(actionLanguageTestScore.updateLanguageTestScoreFailure(err));
  }
}

function* deleteLanguageTestScoreSaga({ payload, user, socket, lead }: any) {
  try {
    if (!payload) return;
    if (!payload.id) return;
    const response: AxiosResponse = yield removeLanguageTestScore(payload.id);
    yield put(
      actionLanguageTestScore.deleteLanguageTestScoreSuccess(payload.id)
    );
    socket.emit('qualification-notification', {
      user_id: user.id,
      parent_id: lead?.id,
      receiver_id: lead?.assignee_id,
      receivers_many: lead?.followers_id,
      profile_tab: 'education-background',
      type: 'english-test',
      update_type: 'deleted',
    });
    socket.emit('deleted-language-test-to-server', {
      user_id: user.id,
      language_test_id: payload.id,
      data: response.data,
      parent_id: lead.id,
      company_id: user?.company?.id,
    });
    successNotificationHandler(DELETE_SUCCESS);
  } catch (err) {
    errorHandler(err);
    yield put(actionLanguageTestScore.deleteLanguageTestScoreFailure(err));
  }
}

export default function* rootSagas(): unknown {
  yield takeLatest(
    actionTypes.GET_LANGUAGE_TEST_SCORES_REQUEST,
    getLanguageTestScoresSaga
  );
  yield takeLatest(
    actionTypes.ADD_LANGUAGE_TEST_SCORE_REQUEST,
    addLanguageTestScoreSaga
  );
  yield takeLatest(
    actionTypes.UPDATE_LANGUAGE_TEST_SCORE_REQUEST,
    updateLanguageTestScoreSaga
  );
  yield takeLatest(
    actionTypes.DELETE_LANGUAGE_TEST_SCORE_REQUEST,
    deleteLanguageTestScoreSaga
  );
}
