import React, { useEffect, useState } from 'react';
import { Tabs } from 'antd';
import {
  EducationBackground,
  ProfessionalExperience,
} from '@shared-components/modules';
import { IProfileSectionProps } from '@shared-components/models';
import { SLUG_PROFILE_SECTION, TITLE } from '@moxie/constants';
import {
  IdcardOutlined,
  BookOutlined,
  FileDoneOutlined,
} from '@ant-design/icons';
import { GeneralDetails } from './general-details';
import { useParams } from 'react-router-dom';

const { TabPane } = Tabs;
const ProfileSection: React.FC<IProfileSectionProps> = ({
  lead,
}: IProfileSectionProps) => {
  const { slug, slug2 }: { slug: string; slug2?: string } = useParams();
  const [refresh, setRefresh] = useState(false);
  const id = lead?.id;
  const [selectedTab, setSelectedTab] = useState<string>('other-details');

  useEffect(() => {
    if (slug === SLUG_PROFILE_SECTION && slug2) setSelectedTab(slug2);
  }, []);

  return (
    <>
      <div className="chrome-tab card-container">
        <Tabs
          activeKey={selectedTab}
          type="card"
          onChange={(tabName: string) => {
            setSelectedTab(tabName);
            setRefresh(!refresh);
          }}
        >
          <TabPane
            tab={
              <span>
                <IdcardOutlined />
                {TITLE.GENERAL_DETAILS}
              </span>
            }
            key="other-details"
          >
            <GeneralDetails />
          </TabPane>
          <TabPane
            tab={
              <span>
                <BookOutlined />
                {TITLE.QUALIFICATIONS}
              </span>
            }
            key="education-background"
          >
            <EducationBackground id={id} lead={lead} />
          </TabPane>

          <TabPane
            tab={
              <span>
                <FileDoneOutlined />
                {TITLE.PROFESSIONAL_EXPERIENCE}
              </span>
            }
            key="professional-experiences"
          >
            <ProfessionalExperience id={id} lead={lead} />
          </TabPane>
        </Tabs>
      </div>
    </>
  );
};

export { ProfileSection };
