import React, { useEffect, useState } from 'react';
import { Card, Col, Row, Checkbox, Spin } from 'antd';
import { LABEL, SAVE_BTN } from '@moxie/constants';
import { useWorkflowForm } from './use-workflow-form';
import { IOtherDetailsLabel } from '@shared-components/models';
import { Button } from '@shared-components/elements';
import { useAppSelector, workflowActions } from '@admin/core';
import { useDispatch } from 'react-redux';

const WorkflowForm = ({ stageId }: { stageId: string }) => {
  const dispatch = useDispatch();
  const {
    categories,
    categoryOptions,
    categoryLoading,
    selectedCategory,
    selectedLabel,
    handleSubmit,
    onChangeSelected,
    onChangeSelectedLabel,
  } = useWorkflowForm(stageId);

  const [submitted, setSubmitted] = useState(false);
  const loading = useAppSelector((state) => state.workflows.form_loading);

  const parseSelectedLabel = (selectedLabel: any) => {
    let id: string[] = [];
    for (let key in selectedLabel) {
      id = id.concat(selectedLabel[key]);
    }
    return id;
  };

  const handleSubmitBtnClick = () => {
    setSubmitted(true);
    handleSubmit();
    setSubmitted(false);
  };

  useEffect(() => {
    return () => {
      dispatch(workflowActions.clearWfStageForm());
    };
  }, []);

  return (
    <Spin spinning={loading || submitted}>
      <Row className="padding-2 " wrap={false}>
        <Col flex="0 0 15rem">
          <Card
            title={LABEL.CATEGORIES}
            loading={categoryLoading}
            className="workflow-form-card"
          >
            {(categories || []).map((item, index) => {
              return (
                <div
                  key={index}
                  onClick={() => onChangeSelected(item.id)}
                  className={`workflow-form-card__list ${
                    selectedCategory === item.id &&
                    'workflow-form-card__list-selected'
                  }`}
                >
                  {item.name}
                </div>
              );
            })}
          </Card>
        </Col>
        <Col flex="1 1 auto">
          <Checkbox.Group
            className="full-width"
            value={parseSelectedLabel(selectedLabel)}
            onChange={(e) => onChangeSelectedLabel(e)}
          >
            <Row wrap={true} className="padding-1">
              {(categoryOptions || []).map(
                (item: IOtherDetailsLabel, index: number) => {
                  return (
                    <Col key={item.id} className="padding-bottom-2" span={8}>
                      <Checkbox value={item.id}>{item.label}</Checkbox>
                    </Col>
                  );
                }
              )}
            </Row>
          </Checkbox.Group>
        </Col>
      </Row>
      <Row>
        <Col>
          <Button
            onClick={handleSubmitBtnClick}
            className="save-btn"
            type="primary"
          >
            {SAVE_BTN}
          </Button>
        </Col>
      </Row>
    </Spin>
  );
};

export { WorkflowForm };
