import React, { useEffect, useState } from 'react';
import {
  PAGE_HEADER_MENU_OFFICE_DETAIL,
  SLUG_DEPARTMENTS,
  SLUG_USERS,
  TAB_POSITION,
  URL_OFFICE,
} from '@moxie/constants';
import { useHistory, useParams } from 'react-router-dom';
import { Tabs, Typography } from 'antd';
import { UserTable, DepartmentTable } from '@moxie/shared';
import { getBranchDepartments, getBranchUsers } from '@lyra/services.api';

const { TabPane } = Tabs;
const OfficeBranchTabs: React.FC = () => {
  const { id, slug } = useParams<{ id: string; slug: string }>();
  const [activeKey, setActiveKey] = useState(slug);
  const history = useHistory();

  const setDefaultActiveKey = () => {
    const selected = PAGE_HEADER_MENU_OFFICE_DETAIL.find(
      (item) => item.id === slug
    );
    selected?.id && setActiveKey(selected?.id);
  };

  useEffect(() => {
    setDefaultActiveKey();
  }, [slug, activeKey]);

  const handleChange = (key: string) => {
    const selected = PAGE_HEADER_MENU_OFFICE_DETAIL.find(
      (item) => item.id === key
    );
    if (selected?.url) {
      history.push(`${URL_OFFICE}/detail/${id}/${selected?.url}`);
    }
  };

  const getDepartmentData = async (str: string) => {
    return await getBranchDepartments(id, str);
  };

  const getUserData = async (str: string) => {
    return await getBranchUsers(id, str);
  };

  const tabContent = (key: string) => {
    switch (key) {
      case `${SLUG_DEPARTMENTS}`:
        return (
          <DepartmentTable
            action={getDepartmentData}
            tableType="branch-department"
          />
        );
      case `${SLUG_USERS}`:
        return <UserTable getData={getUserData} hideBranch={true} />;
      default:
        return;
    }
  };
  return (
    <section className="lead_content">
      <div className="pro">
        <Tabs
          activeKey={activeKey}
          tabPosition={TAB_POSITION}
          onTabClick={(key) => handleChange(key)}
        >
          {PAGE_HEADER_MENU_OFFICE_DETAIL.map((item) => {
            return (
              <TabPane
                key={item.id}
                tab={<Typography.Text strong>{item.name}</Typography.Text>}
              >
                {tabContent(item.id)}
              </TabPane>
            );
          })}
        </Tabs>
      </div>
    </section>
  );
};

export { OfficeBranchTabs };
