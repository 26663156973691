import React, { useState } from 'react';
import { Space } from 'antd';
import {
  CANCEL_BTN,
  CREATE_PRODUCT,
  EDIT_PRODUCT,
  SAVE_BTN,
  UPDATE_BTN,
} from '@moxie/constants';
import { Button, DrawerElem } from '@shared-components/elements';
import { ProductFormDrawerProps } from '@shared-components/models';
import { ProductForm } from '../../forms/product';

const ProductFormDrawer: React.FC<ProductFormDrawerProps> = ({
  isOpen,
  onClose,
  form,
  isEdit,
  initialData,
  setDisabled,
  disabled,
  handleSubmit,
}: ProductFormDrawerProps) => {
  const handleSubmitButtonClick = () => {
    setDisabled(true);
    form.submit();
  };

  return (
    <DrawerElem
      visible={isOpen}
      onClose={onClose}
      width={720}
      title={isEdit ? EDIT_PRODUCT : CREATE_PRODUCT}
      footer={
        <div className="padding-bottom-2 text-align-right">
          <Space direction="horizontal">
            <Button onClick={onClose} className="lead-margin-right">
              {CANCEL_BTN}
            </Button>

            <Button
              onClick={handleSubmitButtonClick}
              type="primary"
              disabled={disabled}
            >
              {isEdit ? UPDATE_BTN : SAVE_BTN}
            </Button>
          </Space>
        </div>
      }
    >
      <ProductForm
        isEdit={isEdit}
        form={form}
        onSubmit={handleSubmit}
        initialData={initialData}
        setDisabled={setDisabled}
      />
    </DrawerElem>
  );
};
export { ProductFormDrawer };
