import { createContext, useState } from "react";

interface Props {
  selectedBranch: string | undefined;
  setSelectedBranch: (val: string) => void
}
export const OfficeVisitContext = createContext<Props>({
  selectedBranch: '',
  setSelectedBranch: (val) => { return }
})


const OfficeVisitProvider: React.FC = ({ children }) => {
  const [selectedBranch, setSelectedBranch] = useState<string>();

  return (
    <OfficeVisitContext.Provider value={{
      selectedBranch,
      setSelectedBranch
    }}>
      {children}
    </OfficeVisitContext.Provider>
  )
}

export default OfficeVisitProvider;
