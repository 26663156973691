import React, { useState } from 'react';
import { PageHeaderTitle } from '@shared-components/elements';
import { PAGE_HEADER_MENU_USER, USER_TITLE } from '@moxie/constants';
import { useForm } from 'antd/lib/form/Form';
import { UserList } from '@admin/services.api';
import { errorHandler } from '@moxie/utils';
import { UserTable } from './user-table';
import { UserDrawer } from './user-drawer';
import { useDispatch } from 'react-redux';
import { userActions } from '@admin/core';
import { useAppSelector } from '@admin/core';
import { PageHeaderMenu } from '../components/page-header-menu';

const User: React.FC = () => {
  const [personalDetailsForm] = useForm();
  const [visible, setVisible] = useState(false);
  const [editBool, setIsEdit] = useState(false);
  const [filter] = useState({});
  const [disabled, setDisabled] = useState(true);
  const [refreshTable, setRefreshTable] = useState(true);
  const dispatch = useDispatch();

  const triggerRefresh = () => setRefreshTable((prev) => !prev);

  const { singleData, loading } = useAppSelector((state) => ({
    singleData: state.users.singleData,
    loading: state.users.loading,
  }));

  const getUserList = async (str: string) => {
    return await UserList(str);
  };

  const showDrawer = async (id?: string) => {
    if (id) {
      try {
        dispatch(userActions.fetchUser(id));
        setIsEdit(true);
        setVisible(true);
      } catch (error) {
        errorHandler(error);
      }
    } else {
      dispatch(userActions.clearFetchUser());
      setVisible(true);
    }
  };

  const onClose = () => {
    personalDetailsForm.resetFields();
    setVisible(false);
    setIsEdit(false);
    setDisabled(true);
    dispatch(userActions.clearFetchUser());
  };

  const saveData = async () => {
    personalDetailsForm.submit();
  };
  return (
    <div className="full-height">
      <PageHeaderTitle title={USER_TITLE} />
      <PageHeaderMenu menu={PAGE_HEADER_MENU_USER}>
        <UserDrawer
          formData={singleData}
          edit={editBool}
          form={personalDetailsForm}
          visible={visible}
          onClose={onClose}
          saveData={saveData}
          triggerRefresh={triggerRefresh}
          disabled={disabled}
          setDisabled={setDisabled}
          loading={loading}
        />
      </PageHeaderMenu>
      <UserTable
        filterOptions={true}
        actionBar={true}
        showDrawer={showDrawer}
        getData={getUserList}
        filter={filter}
        refresh={refreshTable}
      />
    </div>
  );
};

export { User };
