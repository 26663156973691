import * as actionTypes from './office-branch.constant';
import {
  IAction,
  IInitialState,
  IOfficeBranch,
} from '@shared-components/models';

export const initialState: IInitialState<IOfficeBranch> = {
  allData: [],
  singleData: {},
  error: null,
  loading: false,
};

export const OFFICE_BRANCH_FEATURE_KEY = 'office_branches';

const officeBranchReducer = (state = initialState, action: IAction<any>) => {
  switch (action.type) {
    case actionTypes.GET_BRANCHES_REQUEST:
      return {
        ...state,
        loading: true,
        error: null,
      };
    case actionTypes.GET_BRANCHES_SUCCESS:
      return {
        ...state,
        loading: false,
        error: null,
        allData: action.payload,
      };
    case actionTypes.GET_BRANCHES_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.payload,
      };
    case actionTypes.GET_BRANCH_REQUEST:
      return {
        ...state,
        loading: true,
        error: null,
      };
    case actionTypes.GET_BRANCH_SUCCESS:
      return {
        ...state,
        loading: false,
        error: null,
        singleData: action.payload,
      };
    case actionTypes.GET_BRANCH_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.payload,
      };
    case actionTypes.CLEAR_BRANCH_FETCH:
      return {
        ...state,
        loading: false,
        error: null,
        singleData: null,
      };
    case actionTypes.UPDATE_BRANCH_REQUEST:
      return {
        ...state,
        loading: true,
        error: null,
      };
    case actionTypes.UPDATE_BRANCH_SUCCESS:
      return {
        ...state,
        loading: false,
        error: null,
      };
    case actionTypes.UPDATE_BRANCH_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.payload,
      };
    case actionTypes.ADD_BRANCH_REQUEST:
      return {
        ...state,
        loading: true,
        error: null,
      };
    case actionTypes.ADD_BRANCH_SUCCESS:
      return {
        ...state,
        loading: false,
        error: null,
        allData: [action.payload, ...state.allData],
      };
    case actionTypes.ADD_BRANCH_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.payload,
      };
    case actionTypes.DELETE_BRANCH_REQUEST:
      return {
        ...state,
        loading: true,
        error: null,
      };
    case actionTypes.DELETE_BRANCH_SUCCESS:
      return {
        ...state,
        loading: false,
        error: null,
      };
    case actionTypes.DELETE_BRANCH_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.payload,
      };
    default:
      return state;
  }
};

export { officeBranchReducer };
