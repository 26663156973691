const alias = 'PROFESSIONAL_EXPERIENCE';

export const GET_PROFESSIONAL_EXPERIECES_REQUEST = `GET_${alias}S_REQUEST`;
export const GET_PROFESSIONAL_EXPERIECES_SUCCESS = `GET_${alias}S_SUCCESS`;
export const GET_PROFESSIONAL_EXPERIECES_FAILURE = `GET_${alias}_FAILURE`;
export const GET_PROFESSIONAL_EXPERIECES_CLEAR = `GET_${alias}S_CLEAR`;

export const DELETE_PROFESSIONAL_EXPERIECES_REQUEST = `DELETE_${alias}_REQUEST`;
export const DELETE_PROFESSIONAL_EXPERIECES_SUCCESS = `DELETE_${alias}_SUCCESS`;
export const DELETE_PROFESSIONAL_EXPERIECES_FAILURE = `DELETE_${alias}_FAILURE`;

export const ADD_PROFESSIONAL_EXPERIECES_REQUEST = `ADD_${alias}_REQUEST`;
export const ADD_PROFESSIONAL_EXPERIECES_SUCCESS = `ADD_${alias}_SUCCESS`;
export const ADD_PROFESSIONAL_EXPERIECES_FAILURE = `ADD_${alias}_FAILURE`;

export const UPDATE_PROFESSIONAL_EXPERIECES_REQUEST = ` UPDATE_${alias}_REQUEST`;
export const UPDATE_PROFESSIONAL_EXPERIECES_SUCCESS = ` UPDATE_${alias}_SUCCESS`;
export const UPDATE_PROFESSIONAL_EXPERIECES_FAILURE = ` UPDATE_${alias}_FAILURE`;
