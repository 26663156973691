import React, { useEffect, useState } from 'react';
import moment from 'moment';
import { Card, Col, DatePicker, Form, Row, Space } from 'antd';
import {
  Button,
  disablePastDate,
  formatDate,
  Input,
  Option,
  SearchableSelect,
} from '@moxie/shared';
import { useForm } from 'antd/lib/form/Form';
import { IGetPopupContainer, ILead } from '@shared-components/models';
import { useDispatch } from 'react-redux';
import { leadActions, useAppSelector } from '@lyra/core';
import {
  CANCEL_BTN,
  CATEGORY_ITEMS,
  COUNTRIES,
  EDIT_BTN,
  GUTTER,
  LABEL,
  NOT_AVAILABLE_MESSAGE,
  regex,
  SAVE_BTN,
  VALIDATION_MESSAGE,
} from '@moxie/constants';

const VisaDetailsTab: React.FC<any> = (props: any) => {
  const [form] = useForm();
  const { lead } = props;
  const dispatch = useDispatch();
  const [isEdit, setIsEdit] = useState<boolean>(false);
  const { user, socket } = useAppSelector((state) => ({
    user: state.auth.user,
    socket: state.socket.wss,
  }));

  const handleFinish = (data: ILead) => {
    form.resetFields();
    dispatch(
      leadActions.editLeadAction(
        { ...data, id: lead.id },
        socket,
        user,
        'passport and visa information',
        'other-details'
      )
    );
    setIsEdit(false);
  };

  useEffect(() => {
    form.setFieldsValue({
      passport_no: lead?.passport_no,
      visa_type: lead?.visa_type,
      visa_expiry_date: lead?.visa_expiry_date
        ? moment(lead?.visa_expiry_date)
        : null,
      country_of_passport: lead?.country_of_passport,
      passport_expiry_date: lead?.passport_expiry_date
        ? moment(lead?.passport_expiry_date)
        : null,
    });
  }, [isEdit, lead]);

  return (
    <Card
      title={CATEGORY_ITEMS[2].name}
      extra={[
        isEdit ? (
          <>
            {!lead?.archived && (
              <Space>
                <Button key="save" type="primary" onClick={() => form.submit()}>
                  {SAVE_BTN}
                </Button>
                <Button
                  key="cancel"
                  onClick={() => {
                    form.resetFields();
                    setIsEdit(false);
                  }}
                >
                  {CANCEL_BTN}
                </Button>
              </Space>
            )}
          </>
        ) : (
          <>
            {!lead?.archived && (
              <Button key="edit" type="primary" onClick={() => setIsEdit(true)}>
                {EDIT_BTN}
              </Button>
            )}
          </>
        ),
      ]}
    >
      <Form
        layout="horizontal"
        form={form}
        onFinish={handleFinish}
        scrollToFirstError={true}
        labelCol={{ xl: 12, lg: 8, md: 10, span: 10 }}
        wrapperCol={{ span: 14, offset: 1 }}
      >
        <Row gutter={GUTTER}>
          <Col span={24} xl={12} xxl={8}>
            <Form.Item
              name="passport_no"
              className="custom_label_style"
              label={LABEL.PASSPORT_NO}
              rules={[
                { required: true },
                {
                  pattern: new RegExp(regex.ALPHA_NUMERIC),
                  message: VALIDATION_MESSAGE.INVALID_PASSPORT_NUMBER,
                },
              ]}
            >
              {isEdit ? (
                <Input className="note-title-input" disabled={!isEdit} />
              ) : lead?.passport_no ? (
                lead?.passport_no.toUpperCase()
              ) : (
                NOT_AVAILABLE_MESSAGE
              )}
            </Form.Item>
          </Col>
          <Col span={24} xl={12} xxl={10}>
            <div id="country_list" className="relative">
              <Form.Item
                name="country_of_passport"
                className="custom_label_style"
                label={LABEL.COUNTRY_OF_PASSPORT}
                rules={[{ required: true }]}
              >
                {isEdit ? (
                  <SearchableSelect
                    className="note-title-input"
                    disabled={!isEdit}
                    allowClear={false}
                    optionFilter="search_prop"
                    getPopupContainer={(): IGetPopupContainer =>
                      document.getElementById('country_list')
                    }
                  >
                    {COUNTRIES.map((item) => {
                      return (
                        <Option
                          value={item.name}
                          key={item.code}
                          search_prop={`${item.name} ${item.code}`}
                        >
                          {item.name}
                        </Option>
                      );
                    })}
                  </SearchableSelect>
                ) : lead?.country_of_passport ? (
                  lead?.country_of_passport
                ) : (
                  NOT_AVAILABLE_MESSAGE
                )}
              </Form.Item>
            </div>
          </Col>
          <Col span={24} xl={12} xxl={6}>
            <Form.Item
              name="visa_type"
              className="custom_label_style"
              label={LABEL.VISA_TYPE}
              rules={[
                {
                  pattern: new RegExp(regex.ONLY_STRING),
                  message: VALIDATION_MESSAGE.INVALID_VISA_TYPE,
                },
              ]}
            >
              {isEdit ? (
                <Input className="note-title-input" disabled={!isEdit} />
              ) : lead?.visa_type ? (
                <div className="initial_capital">{lead?.visa_type}</div>
              ) : (
                NOT_AVAILABLE_MESSAGE
              )}
            </Form.Item>
          </Col>
          <Col span={24} xl={12} xxl={8}>
            <Form.Item
              name="passport_expiry_date"
              className="custom_label_style"
              label={LABEL.PASSPORT_EXPIRY_DATE}
              rules={[{ required: true }]}
            >
              {isEdit ? (
                <DatePicker
                  className="full-width note-title-input"
                  disabled={!isEdit}
                  disabledDate={disablePastDate}
                />
              ) : lead?.passport_expiry_date ? (
                formatDate(lead?.passport_expiry_date)
              ) : (
                NOT_AVAILABLE_MESSAGE
              )}
            </Form.Item>
          </Col>

          <Col span={24} xl={12} xxl={10}>
            <Form.Item
              name="visa_expiry_date"
              className="custom_label_style"
              label={LABEL.VISA_EXPIRY_DATE}
              rules={[{ required: true }]}
            >
              {isEdit ? (
                <DatePicker
                  className="full-width note-title-input"
                  disabled={!isEdit}
                  disabledDate={disablePastDate}
                />
              ) : lead?.visa_expiry_date ? (
                formatDate(lead?.visa_expiry_date)
              ) : (
                NOT_AVAILABLE_MESSAGE
              )}
            </Form.Item>
          </Col>
        </Row>
      </Form>
    </Card>
  );
};

export { VisaDetailsTab };
