import { Col, Row } from 'antd';
import { Typography } from 'antd';
import React from 'react';
import { IRegistrationLeftPage } from '..';

const { Paragraph } = Typography;

const BottomText: React.FC<IRegistrationLeftPage> = ({
  icon,
  title,
  body,
}: IRegistrationLeftPage) => {
  return (
    <div className="bottom-text">
      <Row gutter={[12, 16]} justify="center" className="head">
        <Col style={{ alignSelf: 'center' }}>
          {icon && React.cloneElement(icon, { className: 'registration-icon' })}
        </Col>
        <Col style={{ alignSelf: 'center', textAlign: 'center' }}>
          {title && <span>{title}</span>}
        </Col>
      </Row>
      <Row>
        <Col className="body">
          <Paragraph className="text-white text-align-center">{body}</Paragraph>
        </Col>
      </Row>
    </div>
  );
};

BottomText.defaultProps = {
  title: '',
  body: '',
};

export default BottomText;
