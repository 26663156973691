import React from 'react';
import { Spin } from 'antd';
import { ISearchableSelect } from '@shared-components/models';
import { Option, SearchableSelect } from '@moxie/shared';
import { UNASSIGNED_LABEL } from '@moxie/constants';
import { IUserData } from '../../crm-modules/user/user.model';
import { UnassignedCard } from './unassigned-card';
import { UserNameCard } from './user-name-card';

interface ISelectUserData extends Omit<IUserData, 'branch'>{
  disabled?: boolean;
}
interface UserSelectBoxProps extends Omit<ISearchableSelect, 'users'> {
  users?: ISelectUserData[];
}

const UserSelectBox= ({
  showUnassigned,
  ...props
}:  UserSelectBoxProps) => {
    const {users} = props;
    return (
    <SearchableSelect
      {...props}
      optionLabelProp="label"
      optionFilter="search_prop"
    >
      {!users ? (
        <Option value="">
          <Spin spinning />
        </Option>
      ) : (
        <>
          {!showUnassigned && (
            <Option value="" label={UNASSIGNED_LABEL}>
              <UnassignedCard />
            </Option>
          )}
          {users.length > 0 && users.map((user: ISelectUserData, index: number) => {
            return (
              <Option
                disabled={user.disabled || user.id === props.selected_id}
                value={user.id || ''}
                key={user.id || index}
                label={`${user.firstName} ${user.lastName}`}
                search_prop={`${user.firstName} ${user.lastName} ${user.email}`}
                className="padding-left-1"
              >
                <UserNameCard
                  firstName={user.firstName || ''}
                  lastName={user.lastName}
                  email={user.email || ''}
                  index={user.id || ''}
                />
              </Option>
            );
          })}
        </>
      )}
    </SearchableSelect>
  );
};

export { UserSelectBox };
