import axios from './axios';
import {
  IAuthResponse,
  IAuthCompany,
  IResponse,
} from '@shared-components/models';
import { API_URL } from '@moxie/constants';

const updateCompany = (body: IAuthCompany): Promise<IAuthResponse> => {
  return axios.put(API_URL.COMPANY, body);
};

const getAllCompanies = async (): Promise<IResponse> => {
  return axios.get(`${API_URL.COMPANY}`);
};

export { updateCompany, getAllCompanies };
