import React, { createContext, useState } from 'react';

interface IProductFormStateContext {
  selectedInstitution: string | undefined;
  setSelectedInstitution: (value: any) => void;
}

export const ProductFormStateContext = createContext<IProductFormStateContext>({
  selectedInstitution: undefined,
  setSelectedInstitution: (value: string | undefined) => {
    return;
  },
});

const ProductFormStateProvider: React.FC = ({ children }) => {
  const [selectedInstitution, setSelectedInstitution] = useState<any>();
  return (
    <ProductFormStateContext.Provider
      value={{ selectedInstitution, setSelectedInstitution }}
    >
      {children}
    </ProductFormStateContext.Provider>
  );
};

export default ProductFormStateProvider;
