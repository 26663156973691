import { ControlOutlined, SearchOutlined } from '@ant-design/icons';
import { getEligibleProducts } from '@lyra/services.api';
import { TITLE } from '@moxie/constants';
import { IInterestedServiceProps } from '@shared-components/models';
import {
  InterestedServicePreference,
  ProductSearchTable,
} from '@shared-components/modules';
import { Tabs } from 'antd';
import { useForm } from 'antd/lib/form/Form';
import React, { useState } from 'react';
import { useHistory } from 'react-router';
import { useParams } from 'react-router-dom';
import {
  ProductComparisonList,
  ProductComparisonListProvider,
} from '../../product-comparison-list';
const { TabPane } = Tabs;
const InterestedService: React.FC<IInterestedServiceProps> = ({
  lead,
}: IInterestedServiceProps) => {
  const params: { id: string; slug: string } = useParams();
  const [refresh, setRefresh] = useState(false);
  const [selectedTab, setSelectedTab] = useState<string>('service');
  const [form] = useForm();
  const history = useHistory();
  const getData = async (url: string) => {
    const data = {
      contact_id: params.id,
    };
    return getEligibleProducts(data, url);
  };

  const handleSearchButton = (queryParams: string) => {
    history.push(
      `/contact/detail/${params.id}/interested-services${queryParams}`
    );
    setSelectedTab('products');
  };

  return (
    <ProductComparisonListProvider>
      <>
        <ProductComparisonList />
        <div className="chrome-tab card-container ">
          <Tabs
            activeKey={selectedTab}
            type="card"
            onChange={(tabName: string) => {
              if (tabName === 'products')
                history.push(
                  `/contact/detail/${params.id}/interested-services`
                );
              setSelectedTab(tabName);
              setRefresh(!refresh);
            }}
          >
            <TabPane
              tab={
                <span>
                  <ControlOutlined />
                  {TITLE.SERVICE_PREFERENCE}
                </span>
              }
              forceRender={true}
              key="service"
            >
              <InterestedServicePreference
                form={form}
                lead={lead}
                handleSearch={handleSearchButton}
              />
            </TabPane>
            <TabPane
              tab={
                <span>
                  <SearchOutlined />
                  {TITLE.PRODUCT_SEARCH}
                </span>
              }
              key="products"
            >
              <ProductSearchTable
                form={form}
                getData={getData}
                lead={lead}
                refresh={refresh}
              />
            </TabPane>
          </Tabs>
        </div>
      </>
    </ProductComparisonListProvider>
  );
};

export { InterestedService };
