const alias = 'OFFICE_VISIT';

export const ADD_OFFICE_VISIT_REQUEST = `ADD_${alias}_REQUEST`;
export const ADD_OFFICE_VISIT_SUCCESS = `ADD_${alias}_SUCCESS`;
export const ADD_OFFICE_VISIT_FAILURE = `ADD_${alias}_FAILURE`;
export const GET_OFFICE_VISIT_REQUEST = `GET_${alias}_REQUEST`;
export const GET_OFFICE_VISIT_SUCCESS = `GET_${alias}_SUCCESS`;
export const GET_OFFICE_VISIT_FAILURE = `GET_${alias}_FAILURE`;
export const UPDATE_OFFICE_VISIT_REQUEST = `UPDATE_${alias}_REQUEST`;
export const UPDATE_OFFICE_VISIT_SUCCESS = `UPDATE_${alias}_SUCCESS`;
export const UPDATE_OFFICE_VISIT_FAILURE = `UPDATE_${alias}_FAILURE`;
export const CLEAR_OFFICE_VISIT_DATA = `CLEAR_${alias}_DATA`;
export const OPEN_OFFICE_VISIT_DRAWER = `OPEN_${alias}_DRAWER`;
export const CLOSE_OFFICE_VISIT_DRAWER = `CLOSE_${alias}_DRAWER`;
export const OPEN_OFFICE_VISIT_DETAILS_DRAWER = `OPEN_${alias}_DETAILS_DRAWER`;
export const CLOSE_OFFICE_VISIT_DETAILS_DRAWER = `CLOSE_${alias}_DETAILS_DRAWER`;
export const OPEN_OFFICE_VISIT_ENQUIRY_DRAWER = `OPEN_${alias}_ENQUIRY_DRAWER`;
export const CLOSE_OFFICE_VISIT_ENQUIRY_DRAWER = `CLOSE_${alias}_ENQUIRY_DRAWER`;
export const OPEN_APPROVE_MODAL = `OPEN_${alias}_APPROVE_MODAL`;
export const CLOSE_APPROVE_MODAL = `CLOSE_${alias}_APPROVE_MODAL`;
export const OFFICE_VISIT_TABLE_REFRESH = `${alias}_TABLE_REFRESH`;
export const GET_PREV_OFFICE_VISIT_REQUEST = `GET_PREV_${alias}_REQUEST`;
export const GET_PREV_OFFICE_VISIT_SUCCESS = `GET_PREV_${alias}_SUCCESS`;
export const GET_PREV_OFFICE_VISIT_FAILURE = `GET_PREV_${alias}_FAILURE`;
