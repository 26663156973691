import { userAdd, userUpdate } from "@crm/services.api"
import { useMutation } from "@tanstack/react-query"
import { IUserData } from "libs/shared/src/crm-modules/user/user.model"

export const useUserMutation = () => {
  return useMutation({
    mutationKey: ['add-user'],
    mutationFn: (data: Omit<IUserData, 'id'>) => userAdd(data),
  })
}

export const useUserUpdate = () => {
  return useMutation({
    mutationKey: ['update-user'],
    mutationFn: (data: Partial<IUserData>) => userUpdate(data)
  })
}

