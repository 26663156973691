import { CrmPaginatedResponse } from '@model/api-response.model';
import { IOfficeBranchCRM } from '@model/branch';
import { API_URL } from '@moxie/constants';
import { IResponse } from '@shared-components/models';
import crmAxios from './crm-api-axios';

const getOfficeList = async (params: Record<string, unknown>): Promise<CrmPaginatedResponse<IOfficeBranchCRM>> => {
  const responseData = await crmAxios.get(`${API_URL.CRM_API_OFFICE}`, {
    params
  });
  return responseData?.data;
};

const postOfficeBranch = async (body: IOfficeBranchCRM): Promise<IResponse> => {
  return await crmAxios.post(API_URL.CRM_API_OFFICE, body);
};

const getOneOfficeBranch = async (id: string): Promise<IOfficeBranchCRM> => {
  const { data } = await crmAxios.get(`${API_URL.CRM_API_OFFICE}/${id}`);
  return data?.data;
};

const updateOfficeBranch = async (body: Partial<IOfficeBranchCRM>): Promise<IResponse> => {
  const { id } = body;
  return crmAxios.put(`${API_URL.CRM_API_OFFICE}/${id}`, body);
};
const deleteOfficeBranch = async (id: string): Promise<IResponse> => {
  const data = await crmAxios.delete(`${API_URL.CRM_API_OFFICE}/${id}`);
  return data;
};
const getBranchesByUserContactPermission = async (params?: Record<string, unknown>): Promise<CrmPaginatedResponse<IOfficeBranchCRM>> => {
  const { data } = await crmAxios.get(`${API_URL.CRM_API_OFFICE}/query`, {
    params: {
      type: 'contactPermission',
      ...params
    }
  });
  return data;
}

const getBranchesList = async (params: Record<string, unknown>) => {
  const { data } = await crmAxios.get<CrmPaginatedResponse<IOfficeBranchCRM>>(`${API_URL.CRM_API_OFFICE}/query`, { params });
  return data;
}

const getAvailableStatesByCountry = async (country: string) => {
  const { data } = await crmAxios.get(`${API_URL.CRM_API_OFFICE}/country`, {
    params: {
      country
    }
  })
  return data;
}
export {
  getOfficeList,
  postOfficeBranch,
  getOneOfficeBranch,
  updateOfficeBranch,
  deleteOfficeBranch,
  getBranchesByUserContactPermission,
  getBranchesList,
  getAvailableStatesByCountry
};
