import axios from './axios';
import { AuthUser, IResponse, IUser } from '@shared-components/models';
import { API_URL } from '@moxie/constants';
import crmAxios from './crm-api-axios';
import { CrmResponse } from '@model/api-response.model';
import { IUserData, ICrmPermissionData } from 'libs/shared/src/crm-modules/user/user.model';
import { Assignee } from 'libs/shared/src/crm-modules/user/user.model';

const userAdd = async (payload: any): Promise<IResponse> => {
  return crmAxios.post(`${API_URL.CRM_API_USER}/add`, payload);
};

const userUpdate = async (data: Partial<IUserData>): Promise<IUser> => {
  const id = data.id;
  delete data.id;
  return crmAxios.put(`${API_URL.CRM_API_USER}/${id}`, data);
};

const checkUserEmail = (email: string): Promise<any> => {
  return axios.get(`${API_URL.CRM_API_USER}/check-email/${email}`);
};

const paginateUsers = async (params?: Record<string, unknown>) => {
  const { data } = await crmAxios.get<CrmResponse<AuthUser>>(API_URL.CRM_API_USER, { params });
  return data;
};

const getUserPermissionsList = async () => {
  const { data: { data } } = await crmAxios.get<{ data: ICrmPermissionData[] }>(`${API_URL.CRM_USER_PERMISSION}/list`);
  return data;
}

const getUserPermission = async (userId: string) => {
  const resData = await crmAxios.get<{ data: ICrmPermissionData[] }>(`${API_URL.CRM_USER_PERMISSION}/${userId}`);
  return resData?.data;
}

const getActiveUsersList = async (params?: Record<string, unknown>, status = true) => {
  const data = await crmAxios.get<CrmResponse<IUserData>>(`${API_URL.CRM_API_USER}/?active=${status}`, {
    params
  });
  return data?.data;
};

const UserData = async (id: string): Promise<any> => {
  const data = await crmAxios.get(`${API_URL.CRM_API_USER}/${id}`);
  return data;
};

const deactivateUser = async (userId: string): Promise<IResponse> => {
  return crmAxios.put(`${API_URL.CRM_API_USER}/${userId}`, { deactivated: true });
};

const activateUser = async (userId: string): Promise<IResponse> => {
  return crmAxios.put(`${API_URL.CRM_API_USER}/${userId}`, { deactivated: false });
};

const fetchPrimaryBranchUsers = async (branchId: string): Promise<CrmResponse<IUserData>> => {
  return (await crmAxios.get(`${API_URL.CRM_API_USER}`, {
    params: {
      'filter.branchId': branchId
    }
  }))?.data;
}

const fetchUsersByBranchId = async (branchId: string) => {
  const { data } = await axios.get(`${API_URL.CRM_USER}/${branchId}/users`);
  return data;
}

const queryAssignees = async (params: Record<string, unknown>) => {
  const { data } = await crmAxios.get<CrmResponse<Assignee>>(`${API_URL.CRM_API_USER}/assignees`, { params })
  return data;
}

export {
  userAdd,
  userUpdate,
  paginateUsers,
  UserData,
  checkUserEmail,
  deactivateUser,
  activateUser,
  getActiveUsersList,
  fetchPrimaryBranchUsers,
  getUserPermission,
  fetchUsersByBranchId,
  queryAssignees,
  getUserPermissionsList,
};
