/* eslint-disable no-unused-vars */
import React, { useEffect, useState } from 'react';
import { Col, Row, Space } from 'antd';
import {
  Button,
  DrawerElem,
  PageHeaderTitle,
  PageHeaderMenu,
} from '@shared-components/elements';
import {
  deleteLeadsForm,
  deleteMultiple,
  getLeadsForms,
  getOneLeadsForm,
  postLeadFormFile,
  postLeadsForm,
  updateLeadsForm,
} from '@lyra/services.api';
import { LeadsFormComponent } from '@shared-components/modules';
import {
  LEAD_FORM_ADD_TITLE,
  TITLE,
  PAGE_HEADER_MENU_LEADS_FORM,
  ACTIONS,
  LEAD_FORM_INITIAL_DATA,
  LEAD_FORM_EDIT_TITLE,
  DRAWER_WIDTH,
} from '@moxie/constants';
import { SAVE_BTN, CANCEL_BTN, UPDATE_BTN } from '@moxie/constants';
import { errorHandler } from '@moxie/utils';
import { useParams } from 'react-router-dom';
import { LeadsFormTable } from './leads-form-table';
import {
  performMultipleAction,
  successNotificationHandler,
} from '@moxie/shared';
import { ILeadsForm } from '@shared-components/models';
import { useForm } from 'antd/lib/form/Form';
import Can, { PermissionAction, PermissionObjects } from '@lyra/privilege';
import { LeadFormPageHeader } from './lead-form-page-header';

const LeadsFormList: React.FC = () => {
  const [visible, setVisible] = useState(false);
  const [isEdit, setIsEdit] = useState(false);
  const [leadFormData, setLeadFormData] = useState(LEAD_FORM_INITIAL_DATA);
  const { name } = useParams<{ name: string }>();
  const [filter, setFilter] = useState({ selectBy: name || '' });
  const [refreshTable, setRefreshTable] = useState(true);
  const [disabled, setDisabled] = useState<boolean>(true);
  const [form] = useForm();

  const triggerRefresh = () => setRefreshTable((prev) => !prev);

  const showDrawer = async (id?: string) => {
    if (id) {
      try {
        const response = await getOneLeadsForm(id ? id : '');
        setLeadFormData(response.data.data);
        setIsEdit(true);
      } catch (err) {
        errorHandler(err);
      }
    } else {
      setLeadFormData(LEAD_FORM_INITIAL_DATA);
    }
    setVisible(true);
  };

  const onClose = () => {
    setVisible(false);
    setIsEdit(false);
    setDisabled(true);
    form.resetFields();
  };

  const handleFormSubmit = async (formData: ILeadsForm) => {
    try {
      const data = leadFormData;
      delete data.cover_image_path_preview;
      delete data.logo_path_preview;
      const response = !isEdit
        ? await postLeadsForm({ ...leadFormData, ...formData })
        : await updateLeadsForm(leadFormData);
      successNotificationHandler(response.data.message);
      onClose();
      triggerRefresh();
    } catch (err: any) {
      if (err.response.data.message) {
        form.setFields([
          {
            name: 'form_name',
            errors: [err.response.data.message],
          },
        ]);
      } else {
        errorHandler(err);
      }
    }
  };

  const handleEdit = async (data: ILeadsForm) => {
    try {
      const response = await updateLeadsForm(data);
      successNotificationHandler(response.data.message);
    } catch (err) {
      errorHandler(err);
    }
    triggerRefresh();
  };

  const handleChange = (e: any) => {
    setDisabled(false);
    setLeadFormData({ ...leadFormData, [e.target.name]: e.target.value });
  };

  const handleFile = async (info: any) => {
    setDisabled(false);
    const formData = new FormData();
    formData.append(info.filename, info.file);
    try {
      const response = await postLeadFormFile(formData);
      const preview_name = response.data.data.fieldname + '_preview';
      setLeadFormData({
        ...leadFormData,
        [response.data.data.fieldname]: response.data.data.path,
        [preview_name]: response.data.data.signedURL,
      });
      info.onSuccess('done');
    } catch (err) {
      info.onError('error');
      errorHandler(err);
    }
  };

  const onDeleteLeadForm = async (id: string) => {
    try {
      const response = await deleteLeadsForm(id);
      successNotificationHandler(response.data.message);
    } catch (err) {
      errorHandler(err);
    }
    triggerRefresh();
  };

  const getData = async (str: string) => {
    return getLeadsForms(str);
  };

  const handleMultiple = async (action: string, data: string[]) => {
    switch (action) {
      case ACTIONS.DELETE:
        await performMultipleAction({ deleteFunction: deleteMultiple, data });
        break;
      default:
        break;
    }
    triggerRefresh();
  };

  const handleSubmit = () => {
    form.submit();
  };

  useEffect(() => {
    if (filter.selectBy !== name) setFilter({ selectBy: name });
  }, [visible, name]);

  return (
    <div className="full-height">
      <PageHeaderTitle title={TITLE.LEAD_FORM} />
      <LeadFormPageHeader menu={PAGE_HEADER_MENU_LEADS_FORM}>
        {visible && (
          <DrawerElem
            title={isEdit ? LEAD_FORM_EDIT_TITLE : LEAD_FORM_ADD_TITLE}
            width={DRAWER_WIDTH}
            visible={visible}
            onClose={onClose}
            footer={
              <div className="padding-bottom-2 text-align-right">
                <Space>
                  <Button onClick={onClose} className="lead-margin-right">
                    {CANCEL_BTN}
                  </Button>
                  <Button
                    type="primary"
                    onClick={handleSubmit}
                    disabled={disabled}
                  >
                    {isEdit ? UPDATE_BTN : SAVE_BTN}
                  </Button>
                </Space>
              </div>
            }
          >
            <LeadsFormComponent
              onChange={handleChange}
              handleFile={handleFile}
              isEdit={isEdit}
              setLeadForm={setLeadFormData}
              leadFormData={leadFormData && leadFormData}
              handleFormSubmit={handleFormSubmit}
              form={form}
              setDisabled={setDisabled}
            />
          </DrawerElem>
        )}
      </LeadFormPageHeader>

      <Row className="bg-white">
        <Col span={24} className="padding-top-1">
          {/* {Can(PermissionAction.Read, PermissionObjects.LeadsForm) && ( */}
          <LeadsFormTable
            showDrawer={showDrawer}
            filter={filter}
            getData={getData}
            deleteData={onDeleteLeadForm}
            handleEdit={handleEdit}
            handleMultiple={handleMultiple}
            refreshTable={refreshTable}
          />
          {/* )} */}
        </Col>
      </Row>
    </div>
  );
};

export { LeadsFormList };
