const alias = 'TENANT';

export const GET_TENANTS_REQUEST = `GET_${alias}S_REQUEST`;
export const GET_TENANTS_SUCCESS = `GET_${alias}S_SUCCESS`;
export const GET_TENANTS_FAILURE = `GET_${alias}_FAILURE`;

export const GET_TENANT_REQUEST = `GET_${alias}_REQUEST`;
export const GET_TENANT_SUCCESS = `GET_${alias}_SUCCESS`;
export const GET_TENANT_FAILURE = `GET_${alias}_FAILURE`;
export const CLEAR_TENANT_FETCH = `CLEAR_${alias}_FETCH`;

export const ADD_TENANT_REQUEST = `ADD_${alias}_REQUEST`;
export const ADD_TENANT_SUCCESS = `ADD_${alias}_SUCCESS`;
export const ADD_TENANT_FAILURE = `ADD_${alias}_FAILURE`;

export const UPDATE_TENANT_REQUEST = `UPDATE_${alias}_REQUEST`;
export const UPDATE_TENANT_SUCCESS = `UPDATE_${alias}_SUCCESS`;
export const UPDATE_TENANT_FAILURE = `UPDATE_${alias}_FAILURE`;

export const UPDATE_SHARING_RATIO_REQUEST = `UPDATE_SHARING_RATIO_REQUEST`;
export const UPDATE_SHARING_RATIO_SUCCESS = `UPDATE_SHARING_RATIO_SUCCESS`;
export const UPDATE_SHARING_RATIO_FAILURE = `UPDATE_SHARING_RATIO_FAILURE`;

export const DELETE_TENANT_REQUEST = `DELETE_${alias}_REQUEST`;
export const DELETE_TENANT_SUCCESS = `DELETE_${alias}_SUCCESS`;
export const DELETE_TENANT_FAILURE = `DELETE_${alias}_FAILURE`;

export const DISABLE_TENANT_REQUEST = `DISABLE_${alias}_REQUEST`;
export const DISABLE_TENANT_SUCCESS = `DISABLE_${alias}_SUCCESS`;
export const DISABLE_TENANT_FAILURE = `DISABLE_${alias}_FAILURE`;
