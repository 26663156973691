import { IAction } from '@shared-components/models';
import * as actionTypes from './role-permission.constant';

export const ROLE_PERMISSION_FEATURE = 'role_permissions';

export type loadingStatusEnum =
  | 'GET_ROLES'
  | 'GET_ROLE'
  | 'GET_PERMISSIONS'
  | 'DELETE_ROLE'
  | 'UPDATE_ROLE'
  | 'ADD_ROLE'
  | 'IDLE';
export interface RolePermissionsState {
  roles: any[];
  permissions: any[];
  selectedRole: any;
  loading?: boolean;
  loading_status: loadingStatusEnum;
  error?: boolean;
}

export const initialRolePermissionState: RolePermissionsState = {
  roles: [],
  permissions: [],
  selectedRole: null,
  loading: false,
  loading_status: 'IDLE',
  error: false,
};

export const rolePermissionReducer = (
  state = initialRolePermissionState,
  action: IAction<any>
): RolePermissionsState => {
  switch (action.type) {
    case actionTypes.GET_ROLES_REQUEST:
      return {
        ...state,
        loading: true,
        loading_status: 'GET_ROLES',
      };
    case actionTypes.GET_ROLES_SUCCESS:
      return {
        ...state,
        loading: false,
        loading_status: 'IDLE',
        error: false,
        roles: [...action.payload],
      };
    case actionTypes.GET_ROLES_FAILURE:
      return {
        ...state,
        loading: false,
        loading_status: 'IDLE',
        error: action.payload,
      };

    case actionTypes.GET_ROLES_CLEAR:
      return {
        ...state,
        loading: false,
        loading_status: 'IDLE',
        roles: [],
      };

    default:
      return state;
  }
};
