import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { otherDetailActions, SETTING_OTHER_DETAILS } from '@lyra/core';
import { IOtherDetailsOptions } from '@shared-components/models';
import { useForm } from 'antd/lib/form/Form';

const useLabelOptionDrawer = (selectedLabel: string) => {
  const [form] = useForm();
  const dispatch = useDispatch();
  const labelList = useSelector(
    (store: any) => store[SETTING_OTHER_DETAILS].labels
  );

  useEffect(() => {
    if (selectedLabel.length > 1 && labelList.length > 0) {
      form.setFieldsValue({
        options: changeOptionToFormData(
          labelList.find(
            (item: IOtherDetailsOptions) => item.id === selectedLabel
          )?.options ?? []
        ),
      });
    }
    if (selectedLabel === '') form.resetFields();
  }, [selectedLabel, labelList]);

  const changeOptionToFormData = (initialData: IOtherDetailsOptions[]): any[] =>
    initialData.length > 0
      ? initialData.map((item) => ({ name: item.name, id: item.id }))
      : [{ name: '' }, { name: '' }];

  const handleClose = () => {
    form.resetFields();
  };

  const handleUpdate = (value: IOtherDetailsOptions[]) => {
    dispatch(otherDetailActions.setTempOption(value));
  };

  return {
    form,
    handleUpdate,
    handleClose,
  };
};

export { useLabelOptionDrawer };
