import React, { useState } from 'react';
import { DURATION, TEXT, URL_INSTITUTION_DETAIL_LINK } from '@moxie/constants';
import {
  Button,
  InstitutionBranchTagGroup,
  WorkflowTagGroup,
} from '@shared-components/elements';

import { IProduct, IProfileSection } from '@shared-components/models';
import { Avatar, Card, Col, Row, Skeleton, Tooltip, Typography } from 'antd';
import { LinkOutlined, EditOutlined } from '@ant-design/icons';
import { useForm } from 'antd/lib/form/Form';
import { ProductFormDrawer } from './product-form-drawer';
import { productsActions, useAppSelector } from '@admin/core';
import { useDispatch } from 'react-redux';
import { Link } from 'react-router-dom';
const { Text } = Typography;
const ProductProfile: React.FC<IProfileSection> = ({
  loading,
  data,
}: IProfileSection) => {
  const {
    name,
    program_link,
    product_duration_year: year,
    product_duration_month: month,
    product_duration_week: week,
    institution,
    institution_workflows,
    revenue_type,
    fees,
    officialId,
    identification_system_code,
    id,
  } = data;

  const [isCopied, setIsCopied] = useState(false);
  const [isEdit, setIsEdit] = useState(false);
  const [form] = useForm();
  const [refreshTable, setRefreshTable] = useState(true);
  const [reload] = useState(true);

  const dispatch = useDispatch();
  const [disabled, setDisabled] = useState(true);
  const triggerRefresh = () => setRefreshTable((prev) => !prev);

  const [isOpen, setIsOpen] = useState(false);
  const handleOpen = () => {
    setIsEdit(true);
    setIsOpen(true);
  };
  const onClose = () => {
    setIsOpen(false);
    form.resetFields();
    setIsEdit(false);
    setDisabled(true);
  };

  const copyToClipboard = (text: string) => {
    navigator.clipboard.writeText(text);
    setIsCopied(true);
  };

  const handleSubmit = async (data: any) => {
    if (data.subject_area_id === undefined) data.subject_area_id = null;
    let postData;
    postData = { ...data, id: id };
    dispatch(productsActions.updateProductRequest(postData, triggerRefresh));
    onClose();
  };

  return (
    <section className="profile">
      <Card>
        <ProductFormDrawer
          isOpen={isOpen}
          form={form}
          handleSubmit={handleSubmit}
          initialData={data}
          onClose={onClose}
          isEdit={isEdit}
          disabled={disabled}
          setDisabled={setDisabled}
          formData={FormData}
        />
        <Row gutter={24}>
          <Col lg={8} className="custom_divider">
            {loading ? (
              <Skeleton avatar paragraph={{ rows: 3 }} active />
            ) : (
              <div className="profile__info">
                <div className="profile__info__item profile__info__user">
                  <Avatar className="profile__info__user__avatar" size="large">
                    {name?.substring(0, 1)}
                  </Avatar>
                  <div className="profile__info__user__detail">
                    <h3 className="initial_capital">{name}</h3>
                    <Link
                      to={`${URL_INSTITUTION_DETAIL_LINK}${institution?.id}/branches`}
                    >
                      <Text className="text-primary-color">
                        {institution?.name}
                      </Text>
                    </Link>
                    {institution?.branches?.length > 0 && (
                      <Row className="padding-top-1">
                        <Col>
                          <h3 className="profile__title">{TEXT.BRANCHES}:</h3>
                        </Col>
                        <Col className="padding-left-1">
                          <InstitutionBranchTagGroup
                            data={institution?.branches}
                          />
                        </Col>
                      </Row>
                    )}
                  </div>
                  <div className="profile__info__edit">
                    <Button
                      icon={<EditOutlined />}
                      onClick={handleOpen}
                      type="link"
                    />
                  </div>
                </div>
                <div className="padding-top-2">
                  {officialId && (
                    <h3 className="profile__title">
                      CRICOS Course Code: &nbsp;{' '}
                      <span className="text-link-color">
                        {identification_system_code
                          ? identification_system_code?.toUpperCase()
                          : '-'}
                      </span>
                    </h3>
                  )}
                </div>
              </div>
            )}
          </Col>
          <Col lg={8} className="custom_divider">
            {loading ? (
              <Skeleton paragraph={{ rows: 2 }} title={false} active />
            ) : (
              <>
                <Row>
                  <Col>
                    <h3 className="profile__title">{TEXT.FEES}:</h3>
                  </Col>
                  <Col className="padding-left-1">
                    {fees && fees?.length > 0 ? (
                      <h3 className="profile__title">
                        {institution?.currency} &nbsp;
                        {data?.fees?.map((item: any) => {
                          const total_fees = item.pfees?.map(
                            (item: any) => item?.total_fee
                          );
                          const sum = total_fees.reduce(
                            (a: number, b: number) => a + b,
                            0
                          );
                          return sum;
                        })}
                      </h3>
                    ) : (
                      '-'
                    )}
                  </Col>
                </Row>
                <Row>
                  <Col>
                    <h3 className="profile__title">{TEXT.DURATION}:</h3>
                  </Col>
                  <Col className="padding-left-1">
                    {year || month || week ? (
                      <h3 className="profile__title">
                        {year
                          ? `${year} ${
                              year > 1 ? DURATION.YEARS : DURATION.YEAR
                            }`
                          : ''}
                        {month
                          ? `${month} ${
                              month > 1 ? DURATION.MONTHS : DURATION.MONTH
                            }`
                          : ''}
                        {week
                          ? `${week} ${
                              week > 1 ? DURATION.WEEKS : DURATION.WEEK
                            }`
                          : ''}
                      </h3>
                    ) : (
                      '-'
                    )}
                  </Col>
                </Row>
              </>
            )}
          </Col>
          <Col lg={8}>
            {loading ? (
              <Skeleton paragraph={{ rows: 1 }} active />
            ) : (
              <>
                <Row>
                  <Col>
                    <h3 className="profile__title">{TEXT.WORKFLOW}:</h3>
                  </Col>
                  <Col className="padding-left-1">
                    {institution_workflows ? (
                      <WorkflowTagGroup workflows={institution_workflows} />
                    ) : (
                      '-'
                    )}
                  </Col>
                </Row>
                {revenue_type && (
                  <Row>
                    <Col>
                      <h3 className="profile__title">{TEXT.REVENUE_TYPE}:</h3>
                    </Col>
                    <Col className="padding-left-1">
                      <h3 className="profile__title">
                        {revenue_type ? revenue_type : '-'}
                      </h3>
                    </Col>
                  </Row>
                )}
                <Row>
                  <Col>
                    <h3 className="profile__title">
                      {TEXT.PROGRAM_LINK}: &nbsp;
                    </h3>
                  </Col>
                  {program_link ? (
                    <>
                      <Col>
                        <a
                          className="text-primary-color "
                          target="_blank"
                          rel="noopener noreferrer"
                          href={program_link}
                        >
                          {TEXT.WEBSITE}
                        </a>
                      </Col>
                      <Col className="padding-left-1">
                        <Tooltip
                          placement="right"
                          color={isCopied ? '#8EAF74' : '#9B9D9A '}
                          title={
                            isCopied
                              ? `${TEXT.COPIED_SUCCESS}`
                              : `${TEXT.COPY_LINK}`
                          }
                        >
                          <LinkOutlined
                            onClick={() => copyToClipboard(`${program_link}`)}
                          />
                        </Tooltip>
                      </Col>
                    </>
                  ) : (
                    '-'
                  )}
                </Row>
              </>
            )}
          </Col>
        </Row>
      </Card>
    </section>
  );
};
export { ProductProfile };
