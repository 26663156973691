/* eslint-disable no-unused-vars */
import React, { useState } from 'react';
import {
  ADD_NOTE,
  CONFIRM_MESSAGE,
  CONFIRM_TITLE,
  NOTE_INITIAL_DATA,
} from '@moxie/constants';
import { Button, popConfirm } from '@shared-components/elements';
import { LeadsNoteTable } from './lead-note-table';
import { PlusOutlined } from '@ant-design/icons';
import { NoteAddEdit } from '../note-add-edit';
import {
  deleteMultipleNotes,
  deleteOneNote,
  getAllContactNotes,
  getOneNote,
} from '@lyra/services.api';
import { errorHandler } from '@moxie/utils';
import { useParams } from 'react-router-dom';
import { useAppSelector } from '@lyra/core';
import { successNotificationHandler } from 'libs/shared/src/shared';
import Can, { PermissionAction, PermissionObjects } from '@lyra/privilege';

const LeadNoteContent: React.FC<any> = (props: any) => {
  const [isNoteOpen, setNoteOpen] = useState(false);
  const [noteFormData, setNoteFormData] = useState(NOTE_INITIAL_DATA);
  const [isEditMode, setIsEditMode] = useState(false);
  const [isViewMode, setIsViewMode] = useState(false);
  const [refreshTable, setRefreshTable] = useState(true);
  const [disabled, setDisabled] = useState(true);
  const [isAddMode, setIsAddMode] = useState(false);
  const [isSubmitted, setIsSubmitted] = useState(false);
  const socket: any = useAppSelector((state) => state.socket.wss);
  const user: any = useAppSelector((state) => state.auth.user);
  const currentlead: any = useAppSelector((state) => state.lead.lead);
  const { id } = useParams<{ id: string }>();
  const { lead } = props;

  const getNotes = async (params: string) => {
    const response = await getAllContactNotes(params, id);
    return await response?.data;
  };

  const onNoteClose = () => {
    setNoteFormData(NOTE_INITIAL_DATA);
    setNoteOpen(false);
    setIsEditMode(false);
    setIsViewMode(false);
    setDisabled(true);
    setIsSubmitted(false);
  };

  const showDrawer = async (id?: string, isView?: boolean) => {
    if (isView && id) {
      setIsViewMode(true);
      setIsEditMode(false);
      getNotesDetails(id);
      setDisabled(true);
    } else if (id) {
      getNotesDetails(id);
      setIsViewMode(false);
      setIsEditMode(true);
      setDisabled(true);
    } else {
      setIsAddMode(true);
      setNoteFormData(NOTE_INITIAL_DATA);
      setDisabled(true);
    }
    setNoteOpen(true);
  };

  const getNotesDetails = async (id: string) => {
    try {
      const response = await getOneNote(id ? id : '');
      setNoteFormData(response?.data?.data);
    } catch (err) {
      errorHandler(err);
    }
  };

  const onDeleteNote = async (note_id: string) => {
    try {
      const response = await deleteOneNote(note_id);
      onNoteClose();

      const dataLeadActivities = {
        user_id: user.id,
        note_id: note_id,
        data: response?.data,
        parent_id: id,
        company_id: user?.company?.id,
      };
      socket.emit('deleted-note-to-server', dataLeadActivities);
      socket.emit('create-note-notification-to-server', {
        user_id: user.id,
        receiver_id: currentlead?.assignee_user_id,
        receivers_many: currentlead?.followers.map((item: any) => item.id),
        type: 'note-deleted',
        parent_id: id,
      });
      successNotificationHandler(response?.data?.message);
    } catch (err) {
      errorHandler(err);
    }
    triggerRefresh();
  };

  const onDeleteMultipleNote = async (ids: string[]) => {
    try {
      const response = await deleteMultipleNotes(ids);
      onNoteClose();
      successNotificationHandler(response?.data?.message);
    } catch (err) {
      errorHandler(err);
    }
    triggerRefresh();
  };

  const handleInputChange = (e: any) => {
    setNoteFormData({ ...noteFormData, [e.target.name]: e.target.value });
    setDisabled(false);
  };

  const confirmDelete = (id: string) => {
    popConfirm(
      CONFIRM_TITLE.DELETE_NOTE,
      `${CONFIRM_MESSAGE.DELETE_NOTE}`,
      () => onDeleteNote(id)
    );
  };

  const confirmMultipleDelte = (ids: string[]) => {
    popConfirm(
      CONFIRM_TITLE.DELETE_NOTE,
      `${CONFIRM_MESSAGE.DELETE_MULTIPLE_NOTES}`,
      () => onDeleteMultipleNote(ids)
    );
  };

  const triggerRefresh = () => setRefreshTable((prev) => !prev);

  return (
    <>
      <div className="slider_content padding-top-1 padding-x1">
        {
          // Can(PermissionAction.Create, PermissionObjects.LeadNotes) &&
          !lead?.archived && (
            <Button
              type="primary"
              onClick={() => showDrawer()}
              icon={<PlusOutlined />}
            >
              {ADD_NOTE}
            </Button>
          )
        }
      </div>
      {/* {Can(PermissionAction.Read, PermissionObjects.LeadNotes) && ( */}
      <LeadsNoteTable
        showDrawer={showDrawer}
        getData={getNotes}
        deleteNote={confirmDelete}
        deleteMultiple={confirmMultipleDelte}
        refreshTable={refreshTable}
        archived={lead?.archived}
      />
      {/* )} */}
      <NoteAddEdit
        isOpen={isNoteOpen}
        noteFormData={noteFormData}
        setNoteForm={setNoteFormData}
        isEdit={isEditMode}
        setIsEdit={setIsEditMode}
        isView={isViewMode}
        setIsView={setIsViewMode}
        disabled={disabled}
        setDisabled={setDisabled}
        isAdd={isAddMode}
        lead={lead}
        leadId={id}
        setIsSubmitted={setIsSubmitted}
        isSubmitted={isSubmitted}
        archived={lead?.archived}
        onChange={handleInputChange}
        triggerRefresh={triggerRefresh}
        onClose={onNoteClose}
      />
    </>
  );
};
export { LeadNoteContent };
