/* eslint-disable react-hooks/rules-of-hooks */
import React, { ReactNode } from 'react';
import { capitalizeFirstLetter, DocumentNameCard, Table } from '@moxie/shared';
import { ColumnsType } from 'antd/lib/table';
import { PLACEHOLDER, TEXT } from '@moxie/constants';
import { useQuery } from '@moxie/utils';
import { IExistingDocumentProps } from '@shared-components/models';
import { Typography } from 'antd';

const ExistingDocumentsList = ({
  documentsList,
  count,
  customRowSelection,
}: IExistingDocumentProps) => {
  const columns: ColumnsType<any> = [
    {
      title: TEXT.SN,
      key: 'id',
      render: (data: any, row: any, index: number): ReactNode => {
        const pageNumber = useQuery().get('pageNo') || 1;
        const limit = useQuery().get('limit') || 10;
        return (pageNumber - 1) * limit + index + 1;
      },
    },
    {
      title: TEXT.FILE_NAME,
      render(data: any): ReactNode {
        return <DocumentNameCard data={data} showMenu={false} />;
      },
    },
    {
      title: PLACEHOLDER.DOCUMENT_TYPE,
      render(data: any): ReactNode {
        return <span>{capitalizeFirstLetter(data.document_type || '')}</span>;
      },
    },
    {
      title: TEXT.FILE_SIZE,
      render(data: any): ReactNode {
        return <span>{`${Math.round(data?.size / 1024)} KB`}</span>;
      },
    },
  ];

  return (
    <div>
      <Table
        className="all__border"
        tableName={TEXT.DOCUMENTS}
        columns={columns}
        action={documentsList}
        hasPagination={false}
        customRowSelection={customRowSelection}
      />
      {count !== 0 ? (
        <Typography.Title level={5} className="margin-top-3">
          {TEXT.SELECTED_DOCUMENTS}
        </Typography.Title>
      ) : null}
    </div>
  );
};

export { ExistingDocumentsList };
