const downloadFile = (url: string) => {
  const link = document.createElement('a');
  link.href = url;
  link.setAttribute('download', 'download');
  document.body.appendChild(link);
  link.click();
  link.parentNode?.removeChild(link);
}

export default downloadFile
