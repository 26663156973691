import axios from './axios';
import { IEducation, IResponse } from '@shared-components/models';
import { API_URL } from '@moxie/constants';

const createEducationBackground = async (
  body: IEducation
): Promise<IResponse> => {
  return axios.post(API_URL.EDUCATION_BACKGROUND, body);
};

const updateEducationBackground = async (
  body: IEducation
): Promise<IResponse> => {
  return axios.put(`${API_URL.EDUCATION_BACKGROUND}/${body.id}`, body);
};

const getAllEducationBackground = async (params = ''): Promise<IResponse> => {
  return axios.get(`${API_URL.EDUCATION_BACKGROUND}/contact/${params}`);
};

const deleteEducationBackground = async (id: string): Promise<IResponse> => {
  return axios.delete(`${API_URL.EDUCATION_BACKGROUND}/${id}`);
};

const getEducationLevels = async (): Promise<IResponse> => {
  return axios.get(`${API_URL.EDUCATION_BACKGROUND}/levels`);
};

export {
  createEducationBackground,
  updateEducationBackground,
  getAllEducationBackground,
  deleteEducationBackground,
  getEducationLevels,
};
