import { IResponse } from '@shared-components/models';
import axios from './axios';
import { API_URL } from '@moxie/constants';
const getClientApplications = async (
  contactId: string,
  params = ''
): Promise<IResponse> => {
  return axios.get(
    `${API_URL.APPLICATIONS}/contacts/admin/${contactId}/${params}`
  );
};
const uploadAndSaveChecklistDocuments = async (
  body: FormData
): Promise<IResponse> => {
  return axios.post(`${API_URL.ADMIN_CHECKLIST_DOCUMENTS}`, body);
};
export { getClientApplications, uploadAndSaveChecklistDocuments };
