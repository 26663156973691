import React, { useState, useEffect } from 'react';
import {
  ADD_SERVICE_BTN,
  CONFIRM_MESSAGE,
  DELETE_BTN,
  STATUS,
} from '@moxie/constants';
import { Button, popConfirm } from '@shared-components/elements';
import { PlusOutlined } from '@ant-design/icons';
import { InterestedServiceAddEdit } from '@shared-components/modules';
import { useDispatch, useSelector } from 'react-redux';
import { interestedServiceActions, serviceActions } from '@lyra/core';
import { useSubjectDiscipline } from '@lyra/hooks';
import {
  IInterestedService,
  IInterestedServicesSectionProps,
} from '@shared-components/models';
import { InterestedServiceTable } from './interested-service-table';
import { useAppSelector } from '@lyra/core';

const InterestedServicePreference: React.FC<
  IInterestedServicesSectionProps | any
> = ({ lead, form, handleSearch }: IInterestedServicesSectionProps | any) => {
  const [isOpen, setOpen] = useState(false);
  const [isEdit, setIsEdit] = useState(false);
  const [isSubmitted, setIsSubmitted] = useState(false);
  const [refresh, triggerRefresh] = useState(false);
  const [disabled, setDisabled] = useState(true);
  const status = useSelector((store: any) => store.interested_service.status);
  const currentUser = useAppSelector((store) => store.auth.user);
  const socket = useAppSelector((state) => state.socket.wss);
  const [
    selectedInterestedService,
    setSelectedInterestedService,
  ] = useState<IInterestedService>();
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(serviceActions.fetchServiceRequest());
    dispatch(serviceActions.fetchEducationLevelRequest());
  }, []);

  useEffect(() => {
    if (status === STATUS.SUCCEEDED) {
      handleSuccess();
    }
  }, [status]);

  const handleSuccess = () => {
    setOpen(false);
    triggerRefresh(!refresh);
    setIsEdit(false);
  };

  const handleClose = () => {
    setOpen(false);
    setDisabled(true);
    setIsSubmitted(false);
    setIsEdit(false);
  };

  const handleAdd = () => {
    setSelectedInterestedService(undefined);
    setOpen(true);
  };

  useSubjectDiscipline();

  const handleEdit = (data: IInterestedService) => {
    setSelectedInterestedService(data);
    setOpen(true);
    setIsEdit(true);
  };

  const handleDelete = (data: IInterestedService) => {
    popConfirm(DELETE_BTN, CONFIRM_MESSAGE.DELETE_INTERESTED_SERVICE, () => {
      dispatch(
        interestedServiceActions.deleteInterestedServiceRequest(
          data?.id || '',
          currentUser,
          socket,
          lead
        )
      );
    });
  };

  return (
    <div>
      <div className="slider_content">
        {!lead?.archived && (
          <Button
            type="primary"
            onClick={handleAdd}
            icon={<PlusOutlined />}
            className="margin-right-1"
          >
            {ADD_SERVICE_BTN}
          </Button>
        )}
      </div>
      <div className="align-contents-center">
        {lead?.id && (
          <InterestedServiceTable
            leadId={lead?.id || ''}
            refresh={refresh}
            onEdit={handleEdit}
            onDelete={handleDelete}
            onSearch={handleSearch}
            archived={lead?.archived}
          />
        )}
      </div>
      <InterestedServiceAddEdit
        isOpen={isOpen}
        initialData={selectedInterestedService}
        onClose={handleClose}
        id={lead?.id}
        form={form}
        isEdit={isEdit}
        disabled={disabled}
        setDisabled={setDisabled}
        isSubmitted={isSubmitted}
        setSubmitted={setIsSubmitted}
      />
    </div>
  );
};

export { InterestedServicePreference };
