import LeadsForm from './leads-form';
import PersonalDetails from './personal-details';
import ContactDetails from './contact-details';
import AddressDetails from './address-details';
import VisaInformation from './visa-information';
import EducationBackground from './education-background';
import EnglishTestScore from './english-test-score';
import OtherTestScore from './other-test-score';
import OtherDetails from './other-details';
import Compliance from './compliance';

export {
  LeadsForm,
  PersonalDetails,
  ContactDetails,
  AddressDetails,
  VisaInformation,
  EducationBackground,
  EnglishTestScore,
  OtherTestScore,
  OtherDetails,
  Compliance,
};
