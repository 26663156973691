import { all, AllEffect, fork, ForkEffect } from 'redux-saga/effects';
import themeSagas from '../theme/theme.saga';
import leadSagas from '../lead/lead.saga';
import rolePermissionSagas from '../role_permissions/role-permission.saga';
import educationBackgroundSagas from '../education-background/education-background.saga';
import authSaga from '../auth/auth.saga';
import userSaga from '../users/user.saga';
import adminUserSaga from '../admin_users/admin-user.saga';
import languageTestScoreSaga from '../language-test-score/language-test-score.saga';
import productSagas from '../products/products.saga';
import socketSagas from '../socket/socket.sagas';
import leadActivitiesSagas from '../lead-activities/lead-activities.saga';
import otherTestScoreSaga from '../other-test-score/other-test-score.saga';
import subjectDisciplineSaga from '../subject-disciplines/subject-disciplines.saga';
import professionalExperienceSagas from '../professional-experience/professional-experience.saga';
import leadNoteSagas from '../leads-notes/lead-notes.saga';
import serviceSaga from '../services/service.saga';
import institutionSaga from '../institutions/institution.saga';
import interestedServiceSaga from '../interested-services/interested-service.saga';
import departmentSaga from '../department/department.saga';
import appointmentSaga from '../lead-appointments/lead-appointments.saga';
import officeBranchSaga from '../office-branch/office-branch.saga';
import userAppointmentSaga from '../user-appointments/user-appointment.saga';
import applicationSaga from '../application/application.saga';
import settingOtherDetailsSaga from '../setting-other-details/setting-other-details.saga';
import taskSaga from '../tasks/tasks.saga';
import notificationSaga from '../notifications/notifications.saga';
import otherDetailValueSaga from '../lead-other-detail-values/other-detail-values.saga';
import productComparison from '../product-comparison/product-comparison.saga';
import workflowSaga from 'apps/admin/src/core/workflow/workflow.saga';

export default function* rootSaga(): Generator<
  AllEffect<ForkEffect<unknown>>,
  void,
  unknown
> {
  yield all(
    [
      authSaga,
      languageTestScoreSaga,
      rolePermissionSagas,
      educationBackgroundSagas,
      professionalExperienceSagas,
      themeSagas,
      leadSagas,
      institutionSaga,
      userSaga,
      adminUserSaga,
      productSagas,
      socketSagas,
      otherTestScoreSaga,
      leadActivitiesSagas,
      subjectDisciplineSaga,
      leadNoteSagas,
      serviceSaga,
      interestedServiceSaga,
      departmentSaga,
      appointmentSaga,
      officeBranchSaga,
      userAppointmentSaga,
      applicationSaga,
      settingOtherDetailsSaga,
      taskSaga,
      notificationSaga,
      otherDetailValueSaga,
      productComparison,
      workflowSaga,
    ].map(fork)
  );
}
