import {
  IAction,
  IAppointment,
  IAppointmentPayload,
  ILead,
  IUser,
} from '@shared-components/models';
import * as actionTypes from './lead-appointments.constant';

export const addLeadAppointmentRequest = (
  data: IAppointmentPayload,
  user: IUser,
  socket: any,
  lead: ILead,
  onClose: () => void,
  triggerRefresh: any
): any => ({
  type: actionTypes.ADD_LEAD_APPOINTMENT_REQUEST,
  data,
  user,
  socket,
  lead,
  onClose,
  triggerRefresh,
});
export const addLeadAppointmentSuccess = (payload: any): IAction<any> => ({
  type: actionTypes.ADD_LEAD_APPOINTMENT_SUCCESS,
  payload,
});
export const addLeadAppointmentFailure = (payload: any): IAction<any> => ({
  type: actionTypes.ADD_LEAD_APPOINTMENT_FAILURE,
  payload,
});

export const getLeadAppointmentsRequest = (id: string): IAction<string> => ({
  type: actionTypes.GET_LEAD_APPOINTMENTS_REQUEST,
  payload: id,
});

export const getLeadAppointmentsSuccess = (
  payload: IAppointment[]
): IAction<IAppointment[]> => ({
  type: actionTypes.GET_LEAD_APPOINTMENTS_SUCCESS,
  payload,
});

export const getLeadAppointmentsFailure = (
  payload: IAppointmentPayload
): IAction => ({
  type: actionTypes.GET_LEAD_APPOINTMENTS_FAILURE,
  payload,
});

export const getLeadAppointmentsClear = () => {
  return { type: actionTypes.GET_LEAD_APPOINTMENTS_CLEAR };
};

export const getLeadAppointmentRequest = (id: string): IAction<string> => ({
  type: actionTypes.GET_LEAD_APPOINTMENT_REQUEST,
  payload: id,
});

export const getLeadAppointmentSuccess = (
  payload: IAppointment[]
): IAction<IAppointment[]> => ({
  type: actionTypes.GET_LEAD_APPOINTMENT_SUCCESS,
  payload,
});

export const getLeadAppointmentFailure = (
  payload: IAppointmentPayload
): IAction => ({
  type: actionTypes.GET_LEAD_APPOINTMENT_FAILURE,
  payload,
});

export const updateLeadAppointmentRequest = (
  data: IAppointmentPayload,
  user: IUser,
  socket: any,
  lead: ILead,
  onClose: () => void,
  triggerRefresh: any,
  scheduleChange: boolean
): any => ({
  type: actionTypes.UPDATE_LEAD_APPOINTMENT_REQUEST,
  data,
  user,
  socket,
  lead,
  onClose,
  triggerRefresh,
  scheduleChange,
});

export const updateLeadAppointmentSuccess = (
  payload: IAppointment
): IAction<IAppointment> => ({
  type: actionTypes.UPDATE_LEAD_APPOINTMENT_SUCCESS,
  payload,
});

export const updateLeadAppointmentFailure = (
  payload: IAppointmentPayload
): IAction<IAppointmentPayload> => ({
  type: actionTypes.UPDATE_LEAD_APPOINTMENT_FAILURE,
  payload,
});

export const deleteLeadAppointmentRequest = (
  payload: IAppointmentPayload,
  user: IUser,
  socket: any,
  lead: ILead
): any => ({
  type: actionTypes.DELETE_LEAD_APPOINTMENT_REQUEST,
  payload,
  user,
  socket,
  lead,
});

export const deleteLeadAppointmentSuccess = (
  payload: IAppointmentPayload
): IAction<IAppointmentPayload> => ({
  type: actionTypes.DELETE_LEAD_APPOINTMENT_SUCCESS,
  payload,
});

export const deleteLeadAppointmentFailure = (
  payload: IAppointmentPayload
): IAction<IAppointmentPayload> => ({
  type: actionTypes.DELETE_LEAD_APPOINTMENT_FAILURE,
  payload,
});
