import React, { useState } from 'react';
import {
  Row,
  Col,
  Menu,
  Avatar,
  Typography,
  Space,
  Tooltip,
  Dropdown,
  Button,
} from 'antd';
import {
  EditOutlined,
  MailOutlined,
  LinkOutlined,
  MoreOutlined,
} from '@ant-design/icons';
import {
  ITableNameCardProps,
  IUserTableNameCardProps,
} from '@shared-components/models';
import { EDIT_BTN, TEXT, URL_USER_DETAIL_LINK } from '@moxie/constants';
import { normalizePhrase } from '@moxie/shared';
import { Link } from 'react-router-dom';
const { Text } = Typography;

export const UserNameCard: React.FC<ITableNameCardProps> = ({
  firstName,
  lastName,
  email,
  index,
  actionBar,
  link,
  menu,
  showEmailAsLink,
  showMailIcon,
  size,
  handleEdit,
}: ITableNameCardProps) => {
  const [isCopied, setIsCopied] = useState(false);
  const copyToClipboard = (text: string) => {
    navigator.clipboard.writeText(text);
    setIsCopied(true);
  };
  return (
    <Row className="lead-table__name user-name-card">
      <Col className="align-items-center user-name-card__avatar initial_capital">
        {link ? (
          <Link to={link}>
            <Avatar size={size}>
              {firstName?.substring(0, 1).toUpperCase()}
              {lastName?.substring(0, 1).toUpperCase()}
            </Avatar>
          </Link>
        ) : (
          <div onClick={handleEdit && handleEdit}>
            <Avatar size={size}>
              {firstName?.substring(0, 1).toUpperCase()}
              {lastName?.substring(0, 1).toUpperCase()}
            </Avatar>
          </div>
        )}
      </Col>
      <Col className="user-name-card__name">
        <div key={index}>
          <Col span={24} className="initial_capital">
            {link ? (
              <Link to={link}>
                <Text className="text-primary-color">
                  {normalizePhrase(`${firstName} ${lastName}`)}
                </Text>
              </Link>
            ) : (
              <Col>
                <Text onClick={handleEdit && handleEdit}>
                  {normalizePhrase(`${firstName} ${lastName}`)}
                </Text>
              </Col>
            )}
          </Col>
          <Col>
            <Row className="email_div">
              <Col>
                {showEmailAsLink === true ? (
                  <>
                    {showMailIcon && (
                      <a href={`mailto:${email}`}>
                        <MailOutlined className="table-mail-icon" />
                      </a>
                    )}
                    <small>
                      <a
                        className="text-primary-color"
                        href={`mailto:${email}`}
                      >
                        {email?.toLowerCase()}
                      </a>
                    </small>
                  </>
                ) : (
                  <small className="text-primary-color">
                    {email?.toLowerCase()}
                  </small>
                )}
              </Col>
              {showEmailAsLink && (
                <Col className="hide">
                  <Tooltip
                    className=" margin-left-1 "
                    placement="right"
                    color={isCopied ? '#8EAF74' : '#9B9D9A '}
                    title={
                      isCopied ? `${TEXT.COPIED_SUCCESS}` : `${TEXT.COPY_EMAIL}`
                    }
                  >
                    <LinkOutlined
                      className="copy_email_icon"
                      onClick={() => copyToClipboard(`${email}`)}
                    />
                  </Tooltip>
                </Col>
              )}
            </Row>
          </Col>
        </div>
      </Col>
      {actionBar && menu !== null && (
        <Col className="user-name-card__action">
          {menu && (
            <Dropdown overlay={menu}>
              <Button
                type="text"
                className="table-cell-action-btn"
                icon={<MoreOutlined />}
              />
            </Dropdown>
          )}
        </Col>
      )}
    </Row>
  );
};

const userMenu = ({ showDrawer }: { showDrawer: () => void }) => (
  <Menu>
    <Menu.Item key={1} onClick={showDrawer}>
      <Space direction="horizontal">
        <EditOutlined />
        {EDIT_BTN}
      </Space>
    </Menu.Item>
  </Menu>
);

const TableUserNameCard: React.FC<IUserTableNameCardProps> = ({
  index,
  firstName,
  lastName,
  email,
  id,
  showDrawer,
  actionBar,
}: IUserTableNameCardProps) => {
  const handleDrawer = () => {
    showDrawer(id);
  };
  const menuActions = {
    showDrawer: handleDrawer,
  };
  return (
    <UserNameCard
      index={index}
      id={id}
      firstName={firstName}
      lastName={lastName}
      email={email}
      link={`${URL_USER_DETAIL_LINK}${id}`}
      actionBar={actionBar}
      menu={userMenu(menuActions)}
      showEmailAsLink={true}
      showMailIcon={true}
    />
  );
};

export { TableUserNameCard };
