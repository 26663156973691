import * as actionTypes from './application.constant';
import {
  IAction,
  IInitialState,
  ILeadApplication,
} from '@shared-components/models';

const initialState: IInitialState<ILeadApplication | any> = {
  allData: [],
  comments: [],
  singleData: {},
  error: null,
  loading: false,
};

export const APPLICATION_FEATURE_KEY = 'application';

const applicationReducer = (
  state = initialState,
  action: IAction<any>
): IInitialState<ILeadApplication | any> => {
  switch (action.type) {
    case actionTypes.GET_APPLICATIONS_REQUEST:
      return {
        ...state,
        loading: true,
        error: null,
      };
    case actionTypes.GET_APPLICATIONS_SUCCESS:
      return {
        ...state,
        loading: false,
        error: null,
        allData: action.payload,
      };
    case actionTypes.GET_APPLICATIONS_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.payload,
      };
    case actionTypes.GET_APPLICATION_REQUEST:
      return {
        ...state,
        loading: true,
        error: null,
      };
    case actionTypes.GET_APPLICATION_SUCCESS:
      return {
        ...state,
        loading: false,
        error: null,
        singleData: action.payload,
      };
    case actionTypes.GET_APPLICATION_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.payload,
        singleData: null,
      };
    case actionTypes.GET_APPLICATION_COMMENT_REQUEST:
      return {
        ...state,
        loading: true,
        error: null,
      };
    case actionTypes.GET_APPLICATION_COMMENT_SUCCESS:
      return {
        ...state,
        loading: false,
        error: null,
        comments: action.payload?.comments,
      };
    case actionTypes.GET_APPLICATION_COMMENT_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.payload,
        comments: [],
      };
    case actionTypes.CLEAR_APPLICATION_FETCH:
      return {
        ...state,
        loading: false,
        error: null,
        singleData: {},
      };
    case actionTypes.UPDATE_APPLICATION_REQUEST:
      return {
        ...state,
        loading: true,
        error: null,
      };
    case actionTypes.UPDATE_APPLICATION_SUCCESS:
      return {
        ...state,
        loading: false,
        error: null,
        singleData: action.payload,
      };
    case actionTypes.UPDATE_APPLICATION_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.payload,
      };
    case actionTypes.ADD_APPLICATION_REQUEST:
      return {
        ...state,
        loading: true,
        error: null,
      };
    case actionTypes.ADD_APPLICATION_SUCCESS:
      return {
        ...state,
        loading: false,
        error: null,
        allData: [action.payload, ...state.allData],
      };
    case actionTypes.ADD_APPLICATION_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.payload,
      };
    case actionTypes.ADD_APPLICATION_COMMENT_REQUEST:
      return {
        ...state,
        loading: true,
        error: null,
      };
    case actionTypes.ADD_APPLICATION_COMMENT_SUCCESS:
      return {
        ...state,
        loading: false,
        error: null,
        comments: action.payload?.comments,
      };
    case actionTypes.ADD_APPLICATION_COMMENT_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.payload,
      };
    case actionTypes.DELETE_APPLICATION_REQUEST:
      return {
        ...state,
        loading: true,
        error: null,
      };
    case actionTypes.DELETE_APPLICATION_SUCCESS:
      return {
        ...state,
        loading: false,
        error: null,
      };
    case actionTypes.DELETE_APPLICATION_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.payload,
      };
    default:
      return state;
  }
};

export { applicationReducer };
