import React from 'react';
import {
  UsergroupAddOutlined,
  UserOutlined,
  PoweroffOutlined,
  BankOutlined,
} from '@ant-design/icons';

const priceClassDescription = (value: Array<string>) => (
  <ul>
    {(value || []).map((text, index) => (
      <li key={index}>{text}</li>
    ))}
  </ul>
);

const FEATURE_LIST_1 = [
  'Limited to 100 staff',
  'Limited to 50 partners',
  'Automation of task',
];
const FEATURE_LIST_2 = [
  'Unlimited staff',
  'Unlimited partners',
  'Automation of task',
];
const FEATURE_LIST_3 = [
  'Unlimited staff',
  'Unlimited partners',
  'Automation of task',
];
const FEATURE_LIST_4 = [
  'Unlimited staff',
  'Unlimited partners',
  'Automation of task',
  'Feature customization',
];

const PRICE_PLANS = [
  {
    id: 1,
    icon: <UserOutlined />,
    title: 'Starter',
    children: priceClassDescription(FEATURE_LIST_1),
    price: 'FREE',
  },
  {
    id: 2,
    icon: <UsergroupAddOutlined />,
    title: 'Growth',
    children: priceClassDescription(FEATURE_LIST_2),
    price: '$99/AUD',
  },
  {
    id: 3,
    icon: <PoweroffOutlined />,
    title: 'Power',
    children: priceClassDescription(FEATURE_LIST_3),
    price: '$249/AUD',
  },
  {
    id: 4,
    icon: <BankOutlined />,
    title: 'Enterprise',
    children: priceClassDescription(FEATURE_LIST_4),
    price: '$249/AUD',
  },
];

export { PRICE_PLANS };
