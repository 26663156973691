import React, { useState } from 'react';
import { DELETE_BTN, NOTES_TITLE, TEXT } from '@moxie/constants';
import { Button, Table } from '@moxie/shared';
import { DeleteOutlined } from '@ant-design/icons';
import { ColumnsType } from 'antd/lib/table';
import NoteNameCard from './note-name-card';
import NoteAddedByName from './note-added-by-name';
import { Col, Row, Typography } from 'antd';
import { LeadNoteTableProps } from '@shared-components/models';

const LeadsNoteTable: React.FC<LeadNoteTableProps> = ({
  showDrawer,
  getData,
  deleteNote,
  refreshTable,
  deleteMultiple,
  archived,
}: LeadNoteTableProps) => {
  const [ids, setIds] = useState<Array<string>>([]);
  const [filter] = useState({});
  const handleViewDrawer = (data: any) => {
    showDrawer(data?.id, true);
  };
  const onMultipleDelete = () => {
    deleteMultiple(ids);
  };
  const noteColumns: ColumnsType<any> = [
    {
      title: TEXT.TITLE,
      render(data) {
        return (
          <Row justify="space-between">
            <Col span={18}>
              <Row>
                <Typography.Text
                  className="text-primary-color cursor-pointer note-title-position"
                  onClick={() => handleViewDrawer(data)}
                >
                  {data?.title.length > 50 ? data?.title.substring(0, 50)+"..." : data?.title}
                </Typography.Text>
              </Row>
            </Col>
            <Col span={3}>
              <NoteNameCard
                data={data}
                showDrawer={showDrawer}
                deleteNote={deleteNote}
                archived={archived}
              />
            </Col>
          </Row>
        );
      },
    },
    {
      title: TEXT.DESCRIPTION,
      render(data) {
        return (
          <Col span={24}>
            <div
            className="ellipsis-text"
              dangerouslySetInnerHTML={{
                __html: `${data?.description.length > 50 ? data?.description.substring(0, 50)+"..." : data?.description}`,
              }}
            ></div>
          </Col>
        );
      },
    },
    {
      title: TEXT.ADDED_BY,
      render(data, index: number) {
        return (
          <NoteAddedByName
            id={data.id}
            firstName={data?.created_by?.first_name}
            email={data?.created_by?.email}
            lastName={data?.created_by?.last_name}
            index={index.toString()}
          />
        );
      },
    },

    {
      title: TEXT.LAST_MODIFIED,
      render(data) {
        return <>{new Date(data?.updated_at).toLocaleDateString('en-us', { year:"numeric", month:"short", day:"numeric"})}</>;
      },
    },
  ];

  const handleMultipleSelection = (data: Array<string>) => {
    setIds(data);
  };

  return (
    <Table
      tableName={NOTES_TITLE}
      action={getData}
      columns={noteColumns}
      onChangeSelected={handleMultipleSelection}
      refresh={refreshTable}
      filter={filter}
      hasPagination={true}
    >
      <Button
        type="primary"
        title={DELETE_BTN}
        danger
        onClick={onMultipleDelete}
        icon={<DeleteOutlined />}
      >
        {DELETE_BTN}
      </Button>
    </Table>
  );
};

export { LeadsNoteTable };
