import React, { useState } from 'react';
import {
  ADD_BTN,
  CANCEL_BTN,
  DRAWER_WIDTH,
  TITLE,
  UPDATE_BTN,
} from '@moxie/constants';
import { Button, DrawerElem } from '@shared-components/elements';
import { IProduct, IProductFee } from '@shared-components/models';
import { FormInstance, FormItemProps, Space } from 'antd';
import { ProductFeeForm } from '../../forms';

export interface IProductFeeDrawerProps {
  onClose: () => void;
  visible: boolean;
  fees: any;
  currency?: string;
  isEdit: boolean;
  feeValidationStatus?: FormItemProps['validateStatus'];
  setFeeValidationStatus?: any;
  form: FormInstance;
  params: { slug: string; id: string };
  handleSubmit: (data: IProduct) => void;
  initialData: IProductFee;
  disabled?: boolean;
  setDisabled?: (data: boolean) => void;
}
const ProductFeeDrawer: React.FC<IProductFeeDrawerProps> = ({
  onClose,
  visible,
  currency,
  feeValidationStatus,
  setFeeValidationStatus,
  form,
  initialData,
  isEdit,
  params,
  handleSubmit,
  fees,
  disabled,
  setDisabled,
}: IProductFeeDrawerProps) => {
  const [loading] = useState(false);

  const handleSubmitButtonClick = () => {
    form.submit();
  };

  return (
    <>
      <DrawerElem
        title={
          !isEdit ? TITLE.ADD_PRODUCT_FEE_TITLE : TITLE.EDIT_PRODUCT_FEE_TITLE
        }
        width={DRAWER_WIDTH}
        onClose={onClose}
        visible={visible}
        footer={
          <div className="padding-bottom-2 text-align-right">
            <Space direction="horizontal">
              <Button onClick={onClose} className="lead-margin-right">
                {CANCEL_BTN}
              </Button>
              <Button
                onClick={handleSubmitButtonClick}
                type="primary"
                loading={loading}
                disabled={disabled}
              >
                {!isEdit ? ADD_BTN : UPDATE_BTN}
              </Button>
            </Space>
          </div>
        }
      >
        <ProductFeeForm
          setDisabled={setDisabled}
          form={form}
          handleSubmit={handleSubmit}
          product_id={params.id}
          currency={currency}
          initialData={initialData}
          feeValidationStatus={feeValidationStatus}
          setFeeValidationStatus={setFeeValidationStatus}
        />
      </DrawerElem>
    </>
  );
};
export { ProductFeeDrawer };
