import React, { useEffect } from 'react';
import { Comment, Avatar, Tooltip, Pagination, Timeline, Empty } from 'antd';
import { useAppSelector } from '@lyra/core';
import { useParams } from 'react-router';
import { useDispatch } from 'react-redux';
import { leadActivitiesActions } from '@lyra/core';
import {
  capitalizeAllLetter,
  formatDateWithTimezone,
} from 'libs/shared/src/functions';

interface ILeadActivities {
  limit?: number;
  showTimeline?: boolean;
  showDate?: boolean;
}
interface ILeadActivitiesItem {
  item?: any;
  key?: any;
  showDate?: boolean;
}

const LeadActivities: React.FC<ILeadActivities> = (props: ILeadActivities) => {
  const { limit, showTimeline = true, showDate = true } = props;

  const dispatch = useDispatch();
  const leadActivities = useAppSelector(
    (state) => state.leadactivities.leadactivities
  );

  const socket: any = useAppSelector((state) => state.socket.wss);

  const { id } = useParams<{ id: string }>();

  useEffect(() => {
    const socketleadData = { parent_id: id };
    socket?.emit('get-initial-activities-to-server', socketleadData);
    return () => {
      dispatch(leadActivitiesActions.cleargetLeadActivitiesRequest());
    };
  }, [socket, id, dispatch]);

  if (leadActivities && leadActivities?.length <= 0) {
    return (
      <div className="full-height-activities-content ">
        <Empty image={Empty.PRESENTED_IMAGE_SIMPLE} />
      </div>
    );
  }
  return (
    <section className="lead-activities full-height-tab-content overflow-scroll">
      <div
        className={`lead-activities__content  ${
          !showTimeline ? ' hide--timeline ' : ''
        }`}
      >
        {showTimeline ? (
          <Timeline>
            {leadActivities &&
              leadActivities.slice(0, limit).map((item) => {
                return (
                  item.user && (
                    <Timeline.Item key={item.id}>
                      <LeadActivitiesItem item={item} showDate={showDate} />
                    </Timeline.Item>
                  )
                );
              })}
          </Timeline>
        ) : (
          <>
            {leadActivities &&
              leadActivities.slice(0, limit).map((item) => {
                return (
                  item.user && <LeadActivitiesItem item={item} key={item.id} />
                );
              })}
          </>
        )}
      </div>
      <div className="lead-activities__pagination">
        {leadActivities && leadActivities?.length > 10 && (
          <Pagination defaultCurrent={6} total={leadActivities.length} />
        )}
      </div>
    </section>
  );
};

const LeadActivitiesItem: React.FC<ILeadActivitiesItem> = (
  props: ILeadActivitiesItem
) => {
  const { item, showDate } = props;
  const {
    user,
    activities_action,
    activities_type,
    data,
    created_at,
    update_type,
  } = item;
  const userTimeZone =
    useAppSelector((store) => store?.auth?.user?.time_zone) || '';

  const getInformation = (): string | null => {
    switch (activities_type) {
      case 'note':
        if (activities_action === 'created') {
          return 'Created a Note';
        } else if (activities_action === 'updated') {
          return 'Updated a Note';
        } else if (activities_action === 'deleted') {
          return 'Deleted a Note';
        }
        return 'Created a Note';
      case 'document':
        if (activities_action === 'uploaded') {
          return 'Uploaded a Document';
        } else if (activities_action === 'updated') {
          return 'Updated a Document';
        } else if (activities_action === 'deleted') {
          return 'Deleted a Document';
        }
        return 'Uploaded a Document';

      // case 'appointment':
      //   if (activities_action === 'created') {
      //     return 'Scheduled an appointment';
      //   } else if (activities_action === 'updated') {
      //     return 'Updated an appointment';
      //   } else if (activities_action === 'deleted') {
      //     return 'Deleted an appointment';
      //   } else {
      //     return 'Scheduled an appointment';
      //   }
      case 'interested-service':
        if (activities_action === 'updated') {
          return 'Updated an Interested Service';
        } else if (activities_action === 'deleted') {
          return 'Deleted an Interested Service';
        }
        return 'Created an Interested Service';

      case 'application':
        if (activities_action === 'created') {
          return 'Created an Application';
        } else if (activities_action === 'deleted') {
          return 'Deleted an Application';
        } else if (
          activities_action === 'updated' &&
          update_type === 'assignee'
        ) {
          return 'Updated Assignee for an Application';
        } else if (
          activities_action === 'updated' &&
          update_type === 'intake'
        ) {
          return 'Updated Intake for an Application';
        } else if (
          activities_action === 'updated' &&
          update_type === 'enrollment-period'
        ) {
          return 'Updated Enrollment Period for an Application';
        } else if (
          activities_action === 'updated' &&
          update_type === 'discontinued'
        ) {
          return 'Discontinued an Application';
        } else if (
          activities_action === 'updated' &&
          update_type === 'revert'
        ) {
          return 'Reverted an Application';
        } else if (
          activities_action === 'updated' &&
          update_type === 'previous-stage'
        ) {
          return 'Updated an Application to Previous Stage';
        } else if (
          activities_action === 'updated' &&
          update_type === 'next-stage'
        ) {
          return 'Updated an Application to Next Stage';
        } else if (activities_action === 'updated') {
          return 'Updated  an Application';
        }

        return 'Created an Application';

      case 'language-test':
        if (activities_action === 'created') {
          return 'Created an English Language Test';
        } else if (activities_action === 'updated') {
          return 'Updated an English Language Test';
        } else if (activities_action === 'deleted') {
          return 'Deleted an english Language Test';
        } else {
          return 'Scheduled an appointment';
        }
      case 'education':
        if (activities_action === 'uploaded') {
          return 'Uploaded an Education Background';
        } else if (activities_action === 'updated') {
          return 'Updated an Education Background';
        } else if (activities_action === 'deleted') {
          return 'Deleted an Education Background';
        }
        return 'Uploaded a Education Background';
      default:
        return null;
    }
  };
  const getData = (): any => {
    const parseData = JSON.parse(data);
    switch (activities_type) {
      case 'note':
        return (
          <>
            <h4 className="margin-0">{parseData?.data?.title}</h4>
            <p>{parseData?.data?.description.replace(/<[^>]+>/g, '')}</p>
          </>
        );
      case 'language-test': {
        return (
          <>
            {parseData?.data ? (
              <>
                <h4 className="margin-bottom-1">
                  {parseData?.data?.tests
                    ? capitalizeAllLetter(parseData?.data?.tests?.name)
                    : capitalizeAllLetter(parseData?.data?.name)}
                </h4>
              </>
            ) : null}
          </>
        );
      }
      case 'application': {
        return <></>;
      }
      // case 'appointment': {
      //   return (
      //     <>
      //       {parseData?.data ? (
      //         <>
      //           <h4 className="margin-bottom-1">
      //             {capitalizeFirstLetter(parseData?.data?.title)}
      //           </h4>
      //           <IconTextComponent
      //             icon={<CalendarOutlined />}
      //             text={moment(parseData?.data?.date).format('MMM DD YYYY')}
      //           />
      //           <IconTextComponent
      //             icon={<ClockCircleOutlined />}
      //             text={moment(parseData?.data?.time).format('hh:mm A')}
      //           />
      //         </>
      //       ) : null}
      //     </>
      //   );
      // }

      default:
        return null;
    }
  };

  return (
    <Comment
      className="custom-timeline-comment "
      author={
        <h4 className="margin-0">
          {user?.first_name} {user?.last_name} {getInformation()}
        </h4>
      }
      avatar={
        <Avatar alt={user?.first_name + user?.last_name}>
          {user?.first_name?.substring(0, 1)}
          {user?.last_name?.substring(0, 1)}
        </Avatar>
      }
      content={getData()}
      datetime={
        showDate && (
          <Tooltip title={formatDateWithTimezone(created_at, userTimeZone)}>
            <span>{formatDateWithTimezone(created_at, userTimeZone)}</span>
          </Tooltip>
        )
      }
    />
  );
};

export default LeadActivities;
export { LeadActivitiesItem };
