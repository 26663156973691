import { IAction, ILead, IUser } from '@shared-components/models';
import { Socket } from 'socket.io-client';
import { lead } from '../constants';
import * as actionTypes from './lead.constant';

export const addLeadAction = (
  payload: ILead,
  socket?: Socket | null,
  user?: IUser | null
): IAction<ILead> => ({
  type: lead.POST_LEAD_SAGA,
  payload,
  socket,
  user,
});

export const editLeadAction = (
  payload: ILead,
  socket?: Socket | null,
  user?: IUser | null,
  update_type?: string,
  tab?: string
): IAction<ILead> => ({
  type: lead.EDIT_LEAD_SAGA,
  payload,
  socket,
  user,
  update_type,
  tab,
});

export const deleteSingleLeadAction = (payload: string): IAction<string> => ({
  type: lead.DELETE_SINGLE_LEAD_SAGA,
  payload,
});

export const deleteMultipleLeadAction = (
  payload: string[]
): IAction<string[]> => ({
  type: lead.DELETE_MULTIPLE_LEAD_SAGA,
  payload,
});

export const archiveMultipleLeadAction = (
  payload: string[]
): IAction<string[]> => ({
  type: lead.ARCHIVE_MULTIPLE_LEAD_SAGA,
  payload,
});

export const restoreMultipleLeadAction = (
  payload: string[]
): IAction<string[]> => ({
  type: lead.RESTORE_MULTIPLE_LEAD_SAGA,
  payload,
});

export const setErrorAction = (payload: boolean): IAction<boolean> => ({
  type: lead.TOGGLE_ERROR_STATUS,
  payload,
});

export const resetErrorAction = (): IAction<null> => ({
  type: lead.RESET_ERROR_STATUS,
  payload: null,
});

export const fetchLead = (payload: string): IAction<string> => ({
  type: lead.FETCH_LEAD,
  payload,
});

export const loading = (payload: boolean): IAction<boolean> => ({
  type: actionTypes.LOADING,
  payload,
});

export const downloading = (payload: boolean): IAction<boolean> => ({
  type: actionTypes.DOWNLOADING,
  payload,
});

export const setError = (payload: boolean | any): IAction<boolean | any> => {
  return {
    type: actionTypes.SET_ERROR,
    payload,
  };
};

export const getLead = (payload: string): IAction<string> => ({
  type: actionTypes.GET_LEAD,
  payload,
});

export const clearLeads = (): IAction<string> => ({
  type: actionTypes.CLEAR_LEAD,
});

export const redirectLead = (): IAction<boolean> => ({
  type: actionTypes.SET_REDIRECT,
});

export const redirectToAllLead = (payload: boolean): IAction<boolean> => ({
  type: actionTypes.SET_REDIRECT_TO_ALL_LEADS,
  payload,
});
