import React from 'react';
import { ITableTitleCard } from '@shared-components/models';
import {
  Button,
  Col,
  Dropdown,
  Menu,
  Row,
  Space,
  Tag,
  Tooltip,
  Typography,
} from 'antd';
import { MoreOutlined, EditOutlined, DeleteOutlined } from '@ant-design/icons';
import { EDIT_BTN, DELETE_BTN, GUTTER, URL_WORKFLOWS } from '@moxie/constants';
import { Link } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import { serviceActions } from '@crm/core';
import { MenuDataItem } from '@ant-design/pro-layout';

const { Text } = Typography;

const ServiceNameMenu = ({ menuItems }: { menuItems: MenuDataItem[] }) => {
  return (
    <Menu>
      {
        menuItems.map((item, index) => (
          <React.Fragment key={index}>
            {item}
          </React.Fragment>
        ))
      }
    </Menu>
  );
};

const ServiceNameCard: React.FC<ITableTitleCard> = ({
  data,
  showDrawer,
  deleteData,
  canAccess
}: ITableTitleCard) => {
  const handleDrawer = () => {
    showDrawer(data.id);
  };
  const handleDelete = () => {
    deleteData(data.id);
  };

  const dispatch = useDispatch();
  const handleServiceClick = (id: string) => {
    dispatch(serviceActions.getOneRequest(id));
  };

  const menuItems = [
    (
    canAccess('service','update') &&<Menu.Item key={1} onClick={handleDrawer}>
      <Space direction="horizontal">
        <EditOutlined />
        {EDIT_BTN}
      </Space>
    </Menu.Item>
    ),
    (
      canAccess('service','delete') &&<Menu.Item key={2} danger onClick={handleDelete}>
      <Space direction="horizontal">
        <DeleteOutlined />
        {DELETE_BTN}
      </Space>
    </Menu.Item>
    )
  ].filter(Boolean)

  return data.sync_id === null ? (
    <Row gutter={GUTTER}>
      <Col span={22} className="align-items-center initial_capital">
        <Link
          data-testid="servicelink"
          to={canAccess('service', 'all') ? `/service/${data?.id}${URL_WORKFLOWS}` : '#'}
        >
          {data?.name.length > 35 ? (
            <Tooltip title={data?.name}>
              <Text
                className="text-primary-color table-title-ellipsis"
                ellipsis={true}
                onClick={() => handleServiceClick(data.id)}
              >
                {data?.name}
              </Text>
            </Tooltip>
          ) : (
            <Text
              className="text-primary-color"
              onClick={() => handleServiceClick(data.id)}
            >
              {data?.name}
            </Text>
          )}
        </Link>
      </Col>
      {menuItems.length > 0?  <Col span={2} className="align-items-center">
        <Dropdown overlay={<ServiceNameMenu menuItems={menuItems} />}>
          <Button
            type="text"
            className="table-cell-action-button"
            icon={<MoreOutlined />}
          />
        </Dropdown>
      </Col>: null}
    </Row>
  ) : (
    <Row gutter={GUTTER}>
      <Col span={16} className="align-items-center">
        <Link
          data-testid="servicelink"
          to={canAccess('service','all') ? `/service/${data?.id}${URL_WORKFLOWS}`: '#'}
        >
          {data?.name.length > 35 ? (
            <Tooltip title={data?.name}>
              <Text
                className="text-primary-color table-title-ellipsis"
                ellipsis={true}
                onClick={() => handleServiceClick(data.id)}
              >
                {data?.name}
              </Text>
            </Tooltip>
          ) : (
            <Text
              className="text-primary-color"
              onClick={() => handleServiceClick(data.id)}
            >
              {data?.name}
            </Text>
          )}
        </Link>
      </Col>

      <Col span={4}>
        <Tag className="tag-processing">System Default</Tag>
      </Col>
    </Row>
  );
};
export { ServiceNameCard };
