import { errorHandler } from '@moxie/utils';
import {
  getOtherDetailsLabelValue,
  postOtherDetailsLabelValue,
} from '@lyra/services.api';
import { successNotificationHandler } from '@moxie/shared';
import { put, takeEvery, takeLatest } from '@redux-saga/core/effects';
import { IAction } from '@shared-components/models';
import { AxiosResponse } from 'axios';
import * as actionLeadOtherDetailValue from './other-detail-values.action';
import * as actionTypes from './other-detail-values.constant';

export function* getLeadOtherDetailValues({ payload }: IAction<any>) {
  try {
    const response: AxiosResponse = yield getOtherDetailsLabelValue(
      payload?.id,
      payload?.field_id
    );

    yield put(
      actionLeadOtherDetailValue.getLeadOtherDetailValuesSuccess(
        response?.data?.data
      )
    );
  } catch (err) {
    errorHandler(err);
    yield put(actionLeadOtherDetailValue.getLeadOtherDetailValuesFailure(err));
  }
}

export function* addLeadOtherDetailValues({ payload }: IAction<any>) {
  try {
    if (payload) {
      const response: AxiosResponse = yield postOtherDetailsLabelValue(payload);
      yield put(
        actionLeadOtherDetailValue.addLeadOtherDetailValueSuccess(
          response.data.data
        )
      );
      successNotificationHandler(response.data.message);
    }
  } catch (err) {
    errorHandler(err);
    yield put(actionLeadOtherDetailValue.addLeadOtherDetailValueFailure(err));
  }
}

export default function* rootSagas(): unknown {
  yield takeEvery(
    actionTypes.GET_LEAD_OTHER_DETAIL_VALUES_REQUEST,
    getLeadOtherDetailValues
  );
  yield takeLatest(
    actionTypes.ADD_LEAD_OTHER_DETAIL_VALUE_REQUEST,
    addLeadOtherDetailValues
  );
}
