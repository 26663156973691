import { AxiosResponse } from 'axios';
import { put, takeLatest } from 'redux-saga/effects';
import * as clientAction from './client.action';
import { errorHandler } from '@moxie/utils';
import { getAllClients, getClientDetail } from '@admin/services.api';
import * as client from './client.constant';

export function* getClients(): any {
  try {
    const res: AxiosResponse = yield getAllClients();
    yield put(clientAction.getClientsSuccess(res.data.data));
  } catch (e) {
    errorHandler(e);
    yield put(clientAction.getClientsFailure(e));
  }
}

export function* getClient(data: any) {
  try {
    const res: AxiosResponse<any> = yield getClientDetail(data.payload);
    if (res?.data?.success) {
      yield put(clientAction.getClientSuccess(res?.data?.data));
    } else {
      yield put(clientAction.redirectClient());
    }
  } catch (e) {
    errorHandler(e);
    yield put(clientAction.setError(true));
    yield put(clientAction.redirectClient());
  }
}

export default function* clientSaga(): unknown {
  yield takeLatest(client.GET_CLIENTS_REQUEST, getClients);
  yield takeLatest(client.GET_CLIENT_REQUEST, getClient);
}
