import React from 'react';
import { Checkbox, Col, Form, Row } from 'antd';
import { ILeadsFormComponent } from '@shared-components/models';
import { GUTTER, LABEL, TITLE } from '@moxie/constants';

const EducationBackground: React.FC<ILeadsFormComponent> = ({
  setLeadForm,
  leadForm,
  setDisabled,
}: ILeadsFormComponent) => {
  const onCheckAllChange = (e: any) => {
    setDisabled(false);
    if (!e.target.checked) {
      setLeadForm({
        ...leadForm,
        education_background_section: {
          ...leadForm.education_background_section,
          degree_title: false,
          degree_level: false,
          institution: false,
          subject_area: false,
          subject: false,
          course_start: false,
          course_end: false,
          academic_score: false,
        },
      });
      return;
    }
    setLeadForm({
      ...leadForm,
      education_background_section: {
        ...leadForm.education_background_section,
        degree_title: true,
        degree_level: true,
        institution: true,
        subject_area: true,
        subject: true,
        course_start: true,
        course_end: true,
        academic_score: true,
      },
    });
  };

  return (
    <>
      <Row justify="space-between" className="custom__title__text">
        <Col>{TITLE.EDUCATION_BACKGROUND}</Col>
        <Col>
          <Checkbox
            checked={
              leadForm?.education_background_section?.degree_title &&
              leadForm?.education_background_section?.degree_level &&
              leadForm?.education_background_section?.institution &&
              leadForm?.education_background_section?.subject_area &&
              leadForm?.education_background_section?.subject &&
              leadForm?.education_background_section?.course_start &&
              leadForm?.education_background_section?.course_end &&
              leadForm?.education_background_section?.academic_score
            }
            onChange={onCheckAllChange}
          />
        </Col>
      </Row>
      <Form.Item className="custom__formItem">
        <Row gutter={GUTTER}>
          <Col md={5} className="lead-form-box">
            <Checkbox
              name="degree_title"
              disabled
              checked={leadForm?.education_background_section?.degree_title}
            >
              {LABEL.DEGREE_TITLE}
            </Checkbox>
          </Col>
          <Col md={5} className="lead-form-box">
            <Checkbox
              name="degree_level"
              disabled
              checked={leadForm?.education_background_section?.degree_level}
            >
              {LABEL.DEGREE_LEVEL}
            </Checkbox>
          </Col>
          <Col md={5} className="lead-form-box">
            <Checkbox
              name="institution"
              disabled
              checked={leadForm?.education_background_section?.institution}
            >
              {LABEL.INSTITUTION}
            </Checkbox>
          </Col>
          <Col md={5} className="lead-form-box">
            <Checkbox
              name="subject_area"
              disabled
              checked={leadForm?.education_background_section?.subject_area}
            >
              {LABEL.SUBJECT_AREA}
            </Checkbox>
          </Col>
          <Col md={5} className="lead-form-box">
            <Checkbox
              name="subject"
              disabled
              checked={leadForm?.education_background_section?.subject}
            >
              {LABEL.SUBJECT}
            </Checkbox>
          </Col>
          <Col md={5} className="lead-form-box">
            <Checkbox
              name="course_start"
              disabled
              checked={leadForm?.education_background_section?.course_start}
            >
              {LABEL.COURSE_START}
            </Checkbox>
          </Col>
          <Col md={5} className="lead-form-box">
            <Checkbox
              name="course_end"
              disabled
              checked={leadForm?.education_background_section?.course_end}
            >
              {LABEL.COURSE_END}
            </Checkbox>
          </Col>
          <Col md={5} className="lead-form-box">
            <Checkbox
              name="academic_score"
              disabled
              checked={leadForm?.education_background_section?.academic_score}
            >
              {LABEL.ACADEMIC_SCORE}
            </Checkbox>
          </Col>
        </Row>
      </Form.Item>
    </>
  );
};

export default EducationBackground;
