import axios from './axios';
import { API_ENDPOINT_URL, API_URL } from '@moxie/constants';
import { IResponse, IWorkflow, IWorkflowType } from '@shared-components/models';

const getAllWorkflowTypes = async (params = ''): Promise<IResponse> => {
  return axios.get(`${API_URL.WORKFLOW_TYPE}/${params}`);
};

const getWorkflowTypes = async (): Promise<IResponse> => {
  return axios.get(`${API_URL.WORKFLOW_TYPE}/all`);
};

const getOneWorkflowType = async (id: string): Promise<IResponse> => {
  return axios.get(`${API_URL.WORKFLOW_TYPE}/${id}`);
};

const postWorkflowType = async (data: IWorkflowType): Promise<IResponse> => {
  return axios.post(`${API_URL.WORKFLOW_TYPE}`, data);
};

const updateWorkflowType = async (data: IWorkflowType): Promise<IResponse> => {
  return axios.put(`${API_URL.WORKFLOW_TYPE}/${data.id}`, data);
};

const deleteWorkflowType = async (id: string): Promise<IResponse> => {
  return axios.delete(`${API_URL.WORKFLOW_TYPE}/${id}`);
};

const getWorkflows = async (params = ''): Promise<IResponse> => {
  return axios.get(`${API_URL.WORKFLOWS}/${params}`);
};

const getAllWorkflows = async (): Promise<IResponse> => {
  return axios.get(`${API_URL.WORKFLOWS}/all`);
};

const deleteWorkflow = async (id: string): Promise<IResponse> => {
  return axios.delete(`${API_URL.WORKFLOWS}/${id}`);
};

const postWorkflow = async (data: IWorkflow): Promise<IResponse> => {
  return axios.post(`${API_URL.WORKFLOWS}`, data);
};

const getOneWorkflow = async (id: string): Promise<IResponse> => {
  return axios.get(`${API_URL.WORKFLOWS}/${id}`);
};

const getWorkflowStages = async (workflowId: string): Promise<IResponse> => {
  return axios.get(
    `${API_ENDPOINT_URL.WORKFLOW_APPLICATION_STAGES}/${workflowId}`
  );
};

const addWorkflowStage = async (body: any): Promise<IResponse> => {
  return axios.post(`${API_ENDPOINT_URL.WORKFLOW_APPLICATION_STAGES}`, body);
};

const getWfChecklists = async (wfStageId: string): Promise<IResponse> => {
  return axios.get(
    `${API_ENDPOINT_URL.WORKFLOW_APPLICATION_STAGES}/checklist/${wfStageId}`
  );
};

const addWfStageChecklist = async (body: {
  stageId: string;
  data: Array<number>;
}): Promise<IResponse> => {
  return axios.post(
    `${API_ENDPOINT_URL.WORKFLOW_APPLICATION_STAGES}/checklist`,
    body
  );
};

const deleteWorkflowStageChecklist = async (id: string): Promise<IResponse> => {
  return axios.delete(
    `${API_ENDPOINT_URL.WORKFLOW_APPLICATION_STAGES}/checklist/${id}`
  );
};

const deleteWorkflowStage = async (id: string): Promise<IResponse> => {
  const response = await axios.delete(
    `${API_ENDPOINT_URL.WORKFLOW_APPLICATION_STAGES}/${id}`
  );
  return response?.data;
};

const updateWorkflowStage = async (body: any): Promise<IResponse> => {
  return axios.put(`${API_ENDPOINT_URL.WORKFLOW_APPLICATION_STAGES}`, body);
};

const updateWfForm = async (body: any): Promise<IResponse> => {
  return axios.post(`${API_ENDPOINT_URL.WORKFLOW_FORM}`, body);
};

const fetchWfStageForm = async (id: string): Promise<IResponse> => {
  return axios.get(`${API_ENDPOINT_URL.WORKFLOW_FORM}/${id}`);
};

export {
  updateWfForm,
  fetchWfStageForm,
  getAllWorkflowTypes,
  getWorkflowTypes,
  getOneWorkflowType,
  postWorkflowType,
  updateWorkflowType,
  deleteWorkflowType,
  getAllWorkflows,
  getWorkflows,
  deleteWorkflow,
  postWorkflow,
  getOneWorkflow,
  getWorkflowStages,
  getWfChecklists,
  addWorkflowStage,
  deleteWorkflowStage,
  updateWorkflowStage,
  addWfStageChecklist,
  deleteWorkflowStageChecklist,
};
