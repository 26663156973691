import React, { useEffect, useState } from 'react';
import { IProduct, IProductFee } from '@shared-components/models';
import {
  Checkbox,
  Col,
  Divider,
  Form,
  FormInstance,
  FormItemProps,
  Input,
  InputNumber,
  Row,
  Select,
  Space,
} from 'antd';
import {
  ADD_FEES,
  COUNTRIES,
  COUNTRY_PLACEHOLDER,
  DELETE_BTN,
  FEE_NAME_REQUIRED,
  FEE_TYPE_LIST,
  GUTTER,
  INSTALLMENT_TYPE,
  LABEL,
  PLACEHOLDER,
  PRODUCT_FEE_RESPONSE_MESSAGE,
  TEXT,
} from '@moxie/constants';
import { Button } from '@shared-components/elements';
import { PlusOutlined, DeleteOutlined } from '@ant-design/icons';
import { productServices } from '@admin/services.api';
import { preventMinus } from '@moxie/shared';

export interface IProductFeeFormProps {
  product_id: string;
  form: FormInstance;
  feeValidationStatus?: FormItemProps['validateStatus'];
  setFeeValidationStatus?: any;
  currency?: string;
  initialData: IProductFee;
  handleSubmit: (data: IProduct) => void;
  setDisabled?: (data: boolean) => void;
}
const { Option } = Select;

interface IProductFeeItem {
  name: any;
  key: any;
  fieldKey: any;
  getInstallmentAmountByType?: any;
  getInstallmentsByType?: any;
  getClaimableType?: any;
  remove: any;
  setNetTotalFee: any;
  netTotalFee: number;
  currency?: string;
  form: FormInstance;
  initialData: any;
}
let totalFeeList: number[] = [];
export const ProductFeeItem: React.FC<IProductFeeItem> = ({
  name,
  fieldKey,
  key,
  remove,
  netTotalFee,
  currency,
  setNetTotalFee,
  initialData,
  form,
}: IProductFeeItem) => {
  //{* this will be implemented in future}
  // const [installment_type, setInstallmentType] = useState<string>('');
  // const handleSelectInstallmentType = (value: string) => {
  //   setInstallmentType(value);
  // };

  // const getInstallmentAmountByType = () => {
  //   switch (installment_type) {
  //     case 'full_fee':
  //       return 'Installment Amount';
  //     case 'per_year':
  //       return 'Per Year Amount';
  //     case 'per_month':
  //       return 'Per Month Amount';
  //     case 'per_term':
  //       return 'Per Term Amount';
  //     case 'per_week':
  //       return 'Per Week Amount';
  //     default:
  //       return 'Installment Amount';
  //   }
  // };

  // const getInstallmentsByType = () => {
  //   switch (installment_type) {
  //     case 'full_fee':
  //       return 'Installments';
  //     case 'per_year':
  //       return 'No. of Year';
  //     case 'per_month':
  //       return 'No. of Month';
  //     case 'per_term':
  //       return 'No. of Term';
  //     case 'per_week':
  //       return 'No. of Week';
  //     default:
  //       return 'Installments';
  //   }
  // };

  // const getClaimableType = () => {
  //   switch (installment_type) {
  //     case 'full_fee':
  //       return 'Claimable Terms';
  //     case 'per_year':
  //       return 'Claimable Year';
  //     case 'per_month':
  //       return 'Claimable Month';
  //     case 'per_term':
  //       return 'Claimable Term';
  //     case 'per_week':
  //       return 'Claimable Week';
  //     default:
  //       return 'Claimable Terms';
  //   }
  // };
  let totalFee = 0;
  const [total, setTotal] = useState<number>();
  const [commissionChecked, setCommissionChecked] = useState<boolean>(false);
  const handleChange = () => {
    totalFee =
      form.getFieldValue('pfees')[fieldKey]?.installments *
      form.getFieldValue('pfees')[fieldKey]?.installment_amount;

    form.getFieldValue('pfees')[fieldKey].total_fee = totalFee;
    if (totalFee !== 0) {
      totalFeeList[fieldKey] = totalFee;

      netTotalFee = totalFeeList.map((datum) => datum).reduce((a, b) => a + b);
    } else {
      totalFeeList[fieldKey] = totalFee;

      netTotalFee = totalFeeList.map((datum) => datum).reduce((a, b) => a + b);
    }
    setTotal(totalFee);

    setNetTotalFee(netTotalFee);
  };
  const handleCommission = (e: any) => {
    setCommissionChecked(e.target.checked);
    form.getFieldValue('pfees')[fieldKey].commission = Number(0);
    form.getFieldValue('pfees')[fieldKey].quotation = false;
    form.getFieldValue('pfees')[fieldKey].terms = 1;
  };
  const handleRemove = (fieldKey: number) => {
    if (totalFeeList.length > 1) {
      totalFeeList.splice(fieldKey, 1);
      netTotalFee = totalFeeList.map((datum) => datum).reduce((a, b) => a + b);
      setNetTotalFee(netTotalFee);
    }
    remove(fieldKey);
  };

  useEffect(() => {
    if (Object.keys(initialData).length > 0) {
      initialData?.pfees?.map((item: any, index: number) => {
        if (fieldKey === index) {
          setCommissionChecked(item?.commission_checked);
          setTotal(item?.total_fee);
        }
      });
    } else {
      form.getFieldValue('pfees')[name].total_fee = Number(
        totalFee?.toFixed(3)
      );
    }
  }, [initialData]);

  return (
    <div key={key}>
      <Row justify="space-between" gutter={GUTTER}>
        <Col span={8}>
          <Form.Item
            name={[name, 'fee_type']}
            fieldKey={[fieldKey, 'fee_type']}
            label={LABEL.FEE_TYPE}
            rules={[{ required: true }]}
            initialValue={FEE_TYPE_LIST[0].value}
          >
            <Select
              placeholder={PLACEHOLDER.SELECT_FEE_TYPE}
              optionLabelProp="label"
            >
              {FEE_TYPE_LIST.map((item, index) => (
                <Option key={index} label={item?.name} value={item?.value}>
                  {item?.name}
                </Option>
              ))}
            </Select>
          </Form.Item>
        </Col>
        <Col span={16}></Col>
      </Row>
      <Row justify="space-between" gutter={GUTTER}>
        <Col span={8}>
          <Form.Item
            name={[name, 'installment_type']}
            fieldKey={[fieldKey, 'installment_type']}
            label={LABEL.INSTALLMENT_TYPE}
            rules={[{ required: true }]}
            initialValue={INSTALLMENT_TYPE[0].value}
          >
            <Select placeholder={PLACEHOLDER.SELECT_INSTALLMENT_TYPE}>
              {INSTALLMENT_TYPE.map((data: any) => (
                <Option value={data.value}>{data.name}</Option>
              ))}
            </Select>
          </Form.Item>
        </Col>
        <Col span={8}>
          <Form.Item
            name={[name, 'installment_amount']}
            fieldKey={[fieldKey, 'installment_amount']}
            label="Installment Amount"
            initialValue={0}
            rules={[{ required: true }]}
          >
            <InputNumber
              onChange={handleChange}
              type="number"
              min={0}
              onKeyPress={preventMinus}
            />
          </Form.Item>
        </Col>
        <Col span={8}>
          <Form.Item
            name={[name, 'installments']}
            fieldKey={[fieldKey, 'installments']}
            label="Installments"
            initialValue={1}
            rules={[{ required: true }]}
          >
            <InputNumber
              max={100}
              onChange={handleChange}
              type="number"
              onKeyPress={preventMinus}
            />
          </Form.Item>
        </Col>
      </Row>
      <Row justify="space-between" gutter={GUTTER}>
        {commissionChecked && (
          <>
            <Col span={8}>
              <Form.Item
                name={[name, 'terms']}
                fieldKey={[fieldKey, 'terms']}
                label="Claimable Terms"
              >
                <InputNumber min={1} type="number" onKeyPress={preventMinus} />
              </Form.Item>
            </Col>
            <Col span={8}>
              <Form.Item
                name={[name, 'commission']}
                fieldKey={[fieldKey, 'commission']}
                label={LABEL.FEE_COMMISSION}
              >
                <InputNumber type="number" />
              </Form.Item>
            </Col>
            <Col span={8} className="padding-top-3">
              <Form.Item name={[name, 'quotation']} valuePropName="checked">
                <Checkbox>{LABEL.SUB_AGENT_SHARING}</Checkbox>
              </Form.Item>
            </Col>
          </>
        )}
      </Row>
      <Row justify="space-between" gutter={GUTTER}>
        <Col span={8}>
          <Form.Item
            name={[name, 'commission_checked']}
            valuePropName="checked"
          >
            <Checkbox onChange={handleCommission}>
              {LABEL.IS_COMMISSIONABLE}
            </Checkbox>
          </Form.Item>
        </Col>
      </Row>
      <Row justify="space-between" gutter={GUTTER}>
        <Col span={12}>
          <p>
            {LABEL.FEE_TOTAL} : {currency} {total ? total : 0.0}
          </p>
        </Col>
        {name !== 0 && (
          <Col span={12}>
            <Space
              className="custom-delete-fee"
              size={8}
              onClick={() => handleRemove(name)}
            >
              <DeleteOutlined />
              <span>{DELETE_BTN}</span>
            </Space>
          </Col>
        )}
      </Row>
      <Divider className="margin-top-1" />
    </div>
  );
};

const ProductFeeForm: React.FC<IProductFeeFormProps> = ({
  form,
  currency,
  product_id,
  initialData,
  handleSubmit,
  setDisabled,
}: IProductFeeFormProps) => {
  const [netTotalFee, setNetTotalFee] = useState(0.0);
  let list = FEE_TYPE_LIST;
  const setFeeTypeList = (value: string) => {
    list = list.filter((item) => item.value !== value);
  };
  const [feeValidationStatus, setFeeValidationStatus] = useState<
    FormItemProps['validateStatus']
  >('');

  const onFieldsChange = (allFields: any) => {
    if (allFields?.length > 0) {
      setDisabled && setDisabled(false);
    }
  };

  let net = 0;
  useEffect(() => {
    if (initialData) {
      form.setFieldsValue({
        country: TEXT.ALL_COUNTRIES,
      });
      list = list?.filter(
        ({ value: id1 }) =>
          !initialData?.pfees?.some(({ fee_type: id2 }) => id2 === id1)
      );
      const netFee = initialData?.pfees
        ?.map((item) => {
          return item?.total_fee;
        })
        .filter((item) => item !== null);
      setNetTotalFee(netFee?.reduce((a: any, b: any) => a + b));
      form.setFieldsValue({ ...initialData });
    } else {
      form.setFieldsValue({});
    }
  }, [initialData]);

  const handleCountryFilter = (input: string, option: any): boolean => {
    return option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0;
  };

  const handleFeeNameChange = async (rule: any, value: any): Promise<any> => {
    value = value?.trim();
    value = value?.toLowerCase().replace(/  + /g, ' ');

    if (!value) {
      setFeeValidationStatus('error');
      return Promise.resolve(true);
    }

    if (value?.length > 50) {
      setFeeValidationStatus('error');
      return Promise.resolve(true);
    }
    if (value?.length === 0) {
      setFeeValidationStatus('error');
      return Promise.resolve(true);
    } else {
      setFeeValidationStatus('validating');
      if (value !== '' || null || undefined) {
        const result = await productServices.apiValidateProductFeeName(
          value,
          product_id,
          initialData?.id
        );
        if (result.data.success) {
          setFeeValidationStatus('success');
          return Promise.resolve(true);
        } else {
          setFeeValidationStatus('error');
          return Promise.reject(
            new Error(PRODUCT_FEE_RESPONSE_MESSAGE.FEE_NAME_ALREADY_EXISTS)
          );
        }
      }
    }
  };

  return (
    <Form
      className="product-fee-form"
      form={form}
      initialValues={{ pfees: [{}] }}
      onFinish={handleSubmit}
      layout="vertical"
      onFieldsChange={onFieldsChange}
    >
      <Row gutter={14}>
        <Col span={12}>
          <Form.Item
            label={LABEL.FEE_OPTION_NAME}
            rules={[
              { max: 50 },
              { required: true },
              { whitespace: true, type: 'string', message: FEE_NAME_REQUIRED },
              { validator: handleFeeNameChange },
            ]}
            name="fee_name"
          >
            <Input disabled={initialData?.fee_name === TEXT.DEFAULT_FEE} />
          </Form.Item>
        </Col>
        <Col span={12}>
          <Form.Item
            name="country"
            label={LABEL.COUNTRY_OF_RESIDENCE}
            rules={[{ required: true }]}
          >
            <Select
              placeholder={COUNTRY_PLACEHOLDER}
              showSearch
              filterOption={handleCountryFilter}
              disabled
              defaultValue={TEXT.ALL_COUNTRIES}
            >
              {COUNTRIES.map((country) => (
                <Option value={country.code} key={country.code}>
                  {country.name}
                </Option>
              ))}
            </Select>
          </Form.Item>
        </Col>
      </Row>

      <Form.List name="pfees">
        {(fields, { add, remove }) => {
          return (
            <>
              {fields.map(
                ({
                  key,
                  name,
                  fieldKey,
                }: {
                  key: any;
                  name: any;
                  fieldKey: any;
                }) => {
                  return (
                    <ProductFeeItem
                      remove={remove}
                      key={key}
                      form={form}
                      initialData={initialData}
                      name={name}
                      setNetTotalFee={setNetTotalFee}
                      currency={currency}
                      netTotalFee={net}
                      fieldKey={fieldKey}
                    />
                  );
                }
              )}
              <Space size={14} />
              <Form.Item>
                <Button
                  type="dashed"
                  onClick={() => add()}
                  block
                  icon={<PlusOutlined />}
                >
                  {ADD_FEES}
                </Button>
              </Form.Item>
              <Space size={8} />
              <Divider />
              <div className="margin-top-1">
                <b>
                  {LABEL.FEE_NET_TOTAL}: {currency}{' '}
                  {netTotalFee && netTotalFee.toFixed(3)}
                </b>
              </div>
            </>
          );
        }}
      </Form.List>
    </Form>
  );
};
export { ProductFeeForm };
