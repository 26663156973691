import React, { useEffect, useState } from 'react';
import { Col, Row, Select, Spin, Skeleton } from 'antd';
import {
  ICountry,
  IEducationLevel,
  IForm,
  IGetPopupContainer,
  IInterestedService,
  IOfficeBranch,
  IService,
  ISubject,
  ISubjectDiscipline,
  ISubjectDisciplinePayload,
  ITestTypeForForm,
  IWorkflowType,
} from '@shared-components/models';
import { changeNullToUndefined, formExtraNotes } from '@moxie/shared';
import Form from 'antd/lib/form';
import { GUTTER, LABEL, VALIDATION_MESSAGE } from '@moxie/constants';
import TextArea from 'rc-textarea';
import { useDispatch, useSelector } from 'react-redux';
import { serviceActions } from '@lyra/core';
import { SearchableSelect } from '@shared-components/elements';
import {
  fetchServiceCountryRequest,
  fetchServiceInstitutionRequest,
} from 'apps/lyra/src/core/services/services.action';
import { productServices } from '@lyra/services.api';

const { Option } = Select;
const InterestedServiceForm: React.FC<IForm<IInterestedService>> = ({
  form,
  onSubmit,
  initialData,
  setDisabled,
}: IForm<IInterestedService>) => {
  const {
    services,
    workflow,
    countries,
    subjectDisciplines,
    institutions,
  }: {
    services: IService[];
    workflow: IWorkflowType[];
    countries: ICountry[];
    subjectDisciplines: ISubjectDisciplinePayload[];
    institutions: IOfficeBranch[];
  } = useSelector((store: any) => ({
    services: store.services.service,
    workflow: store.services.workflow,
    countries: store.services.countries,
    subjectDisciplines: store.subject_discipline.disciplines,
    institutions: store.services.institutions,
  }));
  const [subjectAreas, setSubjectArea] = useState<ISubject[]>([]);
  const [productDataLoading, setProductDataLoading] = useState<boolean>(false);
  const [countryLoading, setCountryLoading] = useState<boolean>(true);
  const [workflowLoading, setWorkflowLoading] = useState<boolean>(true);
  const [educationDataLoading, setEducationDataLoading] = useState<boolean>(
    false
  );
  const [selectedDiscipline, setSelectedDiscipline] = useState<number>();
  const [productTypeData, setProductTypeData] = useState<any>([]);
  const [subTypeLevelData, setsubTypeLevelData] = useState([]);

  const dispatch = useDispatch();

  const getProductTypes = async () => {
    setProductDataLoading(true);
    const res = await productServices.apiGetProductTypes();
    setProductTypeData(res?.data?.data);
    setProductDataLoading(false);
  };

  const handleProductType = async (value: any) => {
    setEducationDataLoading(true);
    form.resetFields(['education_level']);
    const res = await productServices.apiGetProductTypes();
    setsubTypeLevelData(
      res?.data?.data
        ?.map((item: any) => {
          if (item.id === value) {
            return item.sub_type_level;
          }
        })
        .filter((item: any) => item !== (null || undefined))[0]
    );
    setEducationDataLoading(false);
  };

  useEffect(() => {
    if (!initialData?.discipline) setSubjectArea([]);
    if (initialData && Object.keys(initialData).length > 0) {
      if (initialData?.fees) {
        initialData.fees =
          typeof initialData.fees === 'string'
            ? initialData.fees.split('-')
            : initialData.fees;
      }
      if (initialData?.subject_area) {
        initialData.subject_area = Array.isArray(initialData.subject_area)
          ? initialData.subject_area
          : initialData.subject_area
              .split(',')
              .map((value: string) => parseInt(value));
      }

      if (initialData?.workflow_id) {
        setWorkflowLoading(false);
        form.setFieldsValue({
          workflow_type_id: initialData?.workflow?.workflowType?.id,
        });
        initialData.workflow_type_id = initialData?.workflow?.workflowType?.id;

        initialData?.workflow?.workflowType?.id &&
          dispatch(fetchServiceCountryRequest(initialData?.service_id));
      }
      if (initialData?.country && initialData?.service_id) {
        setCountryLoading(false);
        dispatch(
          serviceActions.fetchServiceWorkflowRequest({
            serviceId: initialData?.service_id,
            country: initialData?.country,
          })
        );
        setWorkflowLoading(false);
        dispatch(
          fetchServiceInstitutionRequest({
            serviceId: initialData.service_id,
            country: initialData.country,
          })
        );
      }
      if (initialData?.discipline) {
        setSelectedDiscipline(parseInt(initialData?.discipline.toString()));
        initialData.discipline = parseInt(initialData.discipline.toString());
      }
      setsubTypeLevelData(initialData?.productType?.sub_type_level);
      form.setFieldsValue(changeNullToUndefined(initialData));
    } else form.resetFields();
    getProductTypes();
  }, [form, initialData]);

  useEffect(() => {
    if (subjectDisciplines.some((item) => item.id === selectedDiscipline)) {
      setSubjectArea(
        subjectDisciplines.find((item) => item.id === selectedDiscipline)
          ?.subject_areas || []
      );
    }
  }, [subjectDisciplines, selectedDiscipline]);

  const handleSubmit = async (data: ITestTypeForForm) => {
    if (initialData?.id) data = { ...data, id: initialData.id };
    if (data?.discipline) {
      if (!data?.subject_area || data?.subject_area?.length <= 0) {
        data.subject_area = subjectAreas?.map((item) => item.id);
      }
    }

    onSubmit && onSubmit(data);
  };

  const onFieldsChange = (allFields: string | any[]) => {
    if (allFields.length > 0) {
      setDisabled(false);
    }
  };

  const handleOnChange = (
    changedValues: IInterestedService,
    // eslint-disable-next-line no-unused-vars
    _allValues: IInterestedService
  ) => {
    if (Object.keys(changedValues).includes('service_id')) {
      if (form.getFieldValue('service_id')) {
        dispatch(serviceActions.resetEducationLevel());
        setCountryLoading(true);
        form.setFieldsValue({
          product_type_id: undefined,
          education_level: undefined,
        });
        dispatch(
          serviceActions.fetchServiceCountryRequest(
            changedValues?.service_id || ''
          )
        );
        setCountryLoading(false);
      } else {
        dispatch(serviceActions.resetServices());
      }
      form.setFieldsValue({
        workflow_type_id: undefined,
        country: undefined,
        institution_id: undefined,
      });
      setWorkflowLoading(true);
    }
    if (Object.keys(changedValues).includes('country')) {
      if (form.getFieldValue('country')) {
        dispatch(serviceActions.resetEducationLevel());
        dispatch(
          serviceActions.fetchServiceWorkflowRequest({
            serviceId: form.getFieldValue('service_id'),
            country: changedValues?.country || '',
          })
        );
        setWorkflowLoading(false);
        dispatch(
          serviceActions.fetchServiceInstitutionRequest({
            serviceId: form.getFieldValue('service_id'),
            country: changedValues?.country || '',
          })
        );
      } else {
        dispatch(serviceActions.resetServiceWorkflowCountries());
      }
      form.setFieldsValue({
        workflow_type_id: undefined,
        institution_id: undefined,
      });
    }

    if (Object.keys(changedValues).includes('discipline')) {
      setSelectedDiscipline(changedValues?.discipline);
      form.setFieldsValue({
        subject_area: undefined,
      });
    }
  };

  return productDataLoading ? (
    <Skeleton paragraph={{ rows: 10 }} active />
  ) : (
    <Form
      layout="vertical"
      onFinish={handleSubmit}
      form={form}
      onValuesChange={handleOnChange}
      scrollToFirstError={true}
      onFieldsChange={onFieldsChange}
    >
      <Row gutter={GUTTER}>
        {/*Service Column*/}
        <Col span={24}>
          <div id="lead_select_service" className="relative">
            <Form.Item
              name="service_id"
              label={LABEL.SELECT_SERVICE}
              rules={[
                {
                  required: true,
                  message: VALIDATION_MESSAGE.SELECT_SERVICE,
                },
              ]}
            >
              <SearchableSelect
                className="full-width"
                getPopupContainer={(): IGetPopupContainer =>
                  document.getElementById('lead_select_service')
                }
                optionFilter="search_prop"
              >
                {services?.map((item) => {
                  return (
                    <Option
                      value={item?.id || ''}
                      key={item?.id}
                      search_prop={item?.name}
                    >
                      {item.name}
                    </Option>
                  );
                })}
              </SearchableSelect>
            </Form.Item>
          </div>
        </Col>

        {/*Workflow & Country Column*/}
        {form.getFieldValue('service_id') && (
          <>
            <Col span={12}>
              <div id="lead_service_country" className="relative">
                <Form.Item
                  name="country"
                  label={LABEL.COUNTRY}
                  rules={[
                    {
                      required: true,
                      message: VALIDATION_MESSAGE.SELECT_COUNTRY,
                    },
                  ]}
                >
                  <SearchableSelect
                    className="full-width"
                    getPopupContainer={(): IGetPopupContainer =>
                      document.getElementById('lead_service_country')
                    }
                    optionFilter="search_prop"
                  >
                    {!countryLoading && countries?.length > 0 ? (
                      countries.map((item) => {
                        return (
                          <Option
                            value={item?.name || ''}
                            key={item?.name}
                            search_prop={item?.name}
                          >
                            {item.name}
                          </Option>
                        );
                      })
                    ) : (
                      <Option value="loading" disabled>
                        <Spin size="small" className="full-width" />
                      </Option>
                    )}
                  </SearchableSelect>
                </Form.Item>
              </div>
            </Col>
            <Col span={12}>
              <div id="lead_select_workflow_type" className="relative">
                <Form.Item
                  name="workflow_type_id"
                  label={LABEL.SELECT_WORKFLOW}
                  rules={[
                    {
                      required: true,
                      message: VALIDATION_MESSAGE.SELECT_WORKFLOW_TYPE,
                    },
                  ]}
                >
                  <SearchableSelect
                    className="full-width"
                    getPopupContainer={(): IGetPopupContainer =>
                      document.getElementById('lead_select_workflow_type')
                    }
                    optionFilter="search_prop"
                  >
                    {!workflowLoading && workflow?.length > 0 ? (
                      workflow.map((item: any) => {
                        return (
                          <Option
                            value={item.id}
                            key={item?.id}
                            search_prop={item?.name}
                          >
                            {item.name}
                          </Option>
                        );
                      })
                    ) : (
                      <Option value="loading" disabled>
                        <Spin size="small" className="full-width" />
                      </Option>
                    )}
                  </SearchableSelect>
                </Form.Item>
              </div>
            </Col>
          </>
        )}

        {/* Product type for fetching education levels */}
        {form.getFieldValue('service_id') && (
          <>
            <Col span={12}>
              <div id="product_type" className="relative">
                <Form.Item
                  label={LABEL.SELECT_PRODUCT_TYPE}
                  name="product_type_id"
                >
                  <Select
                    className="note-title-input"
                    onChange={handleProductType}
                    getPopupContainer={(): IGetPopupContainer =>
                      document.getElementById('product_type')
                    }
                    showSearch
                  >
                    {!productDataLoading && productTypeData.length > 0 ? (
                      productTypeData?.map((data: any) => {
                        return (
                          <Option value={data?.id} key={data?.id}>
                            {data?.name}
                          </Option>
                        );
                      })
                    ) : (
                      <option value="loading" disabled>
                        <Spin size="small" className="full-width" />
                      </option>
                    )}
                  </Select>
                </Form.Item>
              </div>
            </Col>

            {/*Education Level Column*/}
            <Col span={12}>
              <div id="lead_educational_level" className="relative">
                <Form.Item
                  name="education_level"
                  label={LABEL.SELECT_PRODUCT_SUB_TYPE}
                >
                  <SearchableSelect
                    optionFilter="search_prop"
                    getPopupContainer={(): IGetPopupContainer =>
                      document.getElementById('lead_educational_level')
                    }
                  >
                    {!educationDataLoading && subTypeLevelData?.length > 0 ? (
                      subTypeLevelData?.map((item: IEducationLevel) => (
                        <Option
                          value={item?.id}
                          key={item?.id}
                          search_prop={item.name}
                        >
                          {item.name}
                        </Option>
                      ))
                    ) : (
                      <option value="loading" disabled>
                        <Spin size="small" className="full-width" />
                      </option>
                    )}
                  </SearchableSelect>
                </Form.Item>
              </div>
            </Col>
          </>
        )}

        {/*Discipline Column*/}
        <Col span={24}>
          <div id="discipline" className="relative">
            <Form.Item name="discipline" label={LABEL.SELECT_DISCIPLINE}>
              <SearchableSelect
                optionFilter="search_prop"
                getPopupContainer={(): IGetPopupContainer =>
                  document.getElementById('discipline')
                }
              >
                {subjectDisciplines.map((item: ISubjectDiscipline) => (
                  <Option
                    value={item.id}
                    key={item.id}
                    search_prop={item.discipline}
                  >
                    {item.discipline}
                  </Option>
                ))}
              </SearchableSelect>
            </Form.Item>
          </div>
        </Col>

        {/*Subject Area Column*/}
        {form.getFieldValue('discipline') && (
          <Col span={24}>
            <div id="lead_subject_area" className="relative">
              <Form.Item
                name="subject_area"
                label={LABEL.SELECT_SUBJECT_AREA}
                extra={
                  !form.getFieldValue('discipline') &&
                  formExtraNotes('Discipline')
                }
              >
                <SearchableSelect
                  placeholder="All Subject Areas for the Discipline are selected by default"
                  optionFilter="search_prop"
                  mode="multiple"
                  getPopupContainer={(): IGetPopupContainer =>
                    document.getElementById('lead_subject_area')
                  }
                >
                  {subjectAreas.map((item: ISubject) => (
                    <Option
                      value={item?.id || ''}
                      key={item.id}
                      search_prop={item.name}
                    >
                      {item.name}
                    </Option>
                  ))}
                </SearchableSelect>
              </Form.Item>
            </div>
          </Col>
        )}

        {/*Institution Column*/}
        {form.getFieldValue('workflow_type_id') && (
          <Col span={24}>
            <div id="lead_select_institute" className="relative">
              <Form.Item name="institution_id" label={LABEL.INSTITUTION}>
                <SearchableSelect
                  optionFilter="search_prop"
                  getPopupContainer={(): IGetPopupContainer =>
                    document.getElementById('lead_select_institute')
                  }
                >
                  {institutions.map((item: IOfficeBranch) => (
                    <Option
                      value={item?.id || ''}
                      key={item.id}
                      search_prop={item.name}
                    >
                      {item.name}
                    </Option>
                  ))}
                </SearchableSelect>
              </Form.Item>
            </div>
          </Col>
        )}

        {/*TODO: This section will be refactored later. */}
        {/*<Col span={24}>*/}
        {/*  <Form.Item name="duration" label={LABEL.DURATION}>*/}
        {/*    <div id="lead_duration_level" className="relative">*/}
        {/*      <Select*/}
        {/*        className="full-width"*/}
        {/*        getPopupContainer={(): IGetPopupContainer =>*/}
        {/*          document.getElementById('lead_duration_level')*/}
        {/*        }*/}
        {/*      >*/}
        {/*        {INTERESTED_SERVICE_DURATION.map((item) => {*/}
        {/*          return (*/}
        {/*            <Option value={item?.value || ''} key={item?.value}>*/}
        {/*              {item.name}*/}
        {/*            </Option>*/}
        {/*          );*/}
        {/*        })}*/}
        {/*      </Select>*/}
        {/*    </div>*/}
        {/*  </Form.Item>*/}
        {/*</Col>*/}
        {/*<Col span={12}>*/}
        {/*  <Form.Item name="fee_type" label={LABEL.FEE_TYPE}>*/}
        {/*    <Select className="full-width">*/}
        {/*      {FEE_TYPE.map((name) => {*/}
        {/*        return (*/}
        {/*          <Option value={name} key={name}>*/}
        {/*            {name}*/}
        {/*          </Option>*/}
        {/*        );*/}
        {/*      })}*/}
        {/*    </Select>*/}
        {/*  </Form.Item>*/}
        {/*</Col>*/}
        {/*<Col span={12}>*/}
        {/*  <Form.Item name="fees" label={LABEL.FEE_RANGE}>*/}
        {/*    <Slider range max={100000} min={0} />*/}
        {/*  </Form.Item>*/}
        {/*</Col>*/}
        <Col span={24}>
          <Form.Item name="comment" label={LABEL.COMMENTS}>
            <TextArea
              className="full-width padding-1 text-justify"
              autoSize={{ minRows: 4, maxRows: 8 }}
            />
          </Form.Item>
        </Col>
      </Row>
    </Form>
  );
};

export { InterestedServiceForm };
