import React from 'react';
import {
  institutionActions,
  institutionBranchesActions,
  productActions,
  productTypeActions,
  useAppSelector,
  workflowActions,
} from '@crm/core';
import { GUTTER, PRODUCT_TITLE, TEXT } from '@moxie/constants';
import { PageHeaderTitle } from '@shared-components/elements';
import { Card, Col, Empty, Pagination, Row, Spin } from 'antd';
import { useForm } from 'antd/lib/form/Form';
import { useContext, useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import ProductsCard from './products-card';
import ProductFilter from './product-filter';
import { ProductsFormDrawer } from './products-form-drawer';
import { useUpdateProduct } from '../hooks/useUpdateProduct';
import { useDeleteProduct } from '../hooks/useDeleteProduct';
import { ProductFilterContext } from '../provider/product-filter-provider';
import { ProductFormStateContext } from '../provider/product-form-state-provider';
import { useGetProductById } from '../hooks/useGetProductById';
import ProductToolbar from './product-toolbar';
import { ICrmProduct } from '../product.model';

const ProductsListComponent: React.FC = () => {
  const [productId, setProductId] = useState<string | undefined>();
  const [isOpen, setIsOpen] = useState(false);
  const [form] = useForm();
  const [isEdit, setIsEdit] = useState(false);
  const [disabled, setDisabled] = useState(true);
  const [initialData, setInitialData] = useState<ICrmProduct>();

  const {
    filter,
    selectedService,
    selectedCountry,
    search,
    currentPage,
    setCurrentPage,
    institutions,
  } = useContext(ProductFilterContext);

  const { setSelectedInstitution } = useContext(ProductFormStateContext);

  const dispatch = useDispatch();
  const productsList = useAppSelector((state) => state.products.products);
  const meta = useAppSelector((state) => state.products.meta);
  const productsListLoading = useAppSelector((state) => state.products.loading);
  const servicesLoading = useAppSelector((state) => state.services.loading);
  const { data } = useGetProductById(productId);

  const { updateProduct } = useUpdateProduct(
    productId,
    currentPage,
    search,
    selectedService,
    selectedCountry
  );

  const { deleteProduct } = useDeleteProduct(
    currentPage,
    search,
    selectedService,
    selectedCountry
  );

  const showDrawer = async (id?: string) => {
    if (id) {
      setIsEdit(true);
    } else {
      dispatch(institutionActions.clearFetchInstitution());
      dispatch(institutionBranchesActions.clearBranchFetch());
      dispatch(workflowActions.clearCRMWorkflowFetch());
      dispatch(productTypeActions.clearProductTypeFetch());
      dispatch(productTypeActions.clearProductSubTypeFetch());
      form.resetFields();
      setIsEdit(false);
    }
    setIsOpen(true);
  };

  const onClose = () => {
    if (isEdit && !data) {
      setIsOpen(false);
      setDisabled(true);
      form.resetFields();
    } else {
      dispatch(institutionActions.clearFetchInstitution());
      dispatch(institutionBranchesActions.clearBranchFetch());
      dispatch(workflowActions.clearCRMWorkflowFetch());
      dispatch(productTypeActions.clearProductTypeFetch());
      dispatch(productTypeActions.clearProductSubTypeFetch());
      form.resetFields();
      setIsEdit(false);
      setIsOpen(false);
      setDisabled(true);
    }
  };

  const triggerRefresh = () => {
    dispatch(
      productActions.getProductsRequest({
        page: currentPage,
        search,
        service: selectedService,
        country: selectedCountry,
        limit: 10,
      })
    );
  };

  const handleSubmit = async (data: any) => {
    data.productDurationYear = data?.productDurationYear ? Number(data?.productDurationYear) : undefined;
    data.productDurationMonth = data?.productDurationMonth ? Number(data?.productDurationMonth) : undefined;
    data.productDurationWeek = data?.productDurationWeek ? Number(data?.productDurationWeek) : undefined;
    if (isEdit) {
      updateProduct({ ...data, id: productId });
    } else {
      dispatch(productActions.addProductRequest(data, triggerRefresh));
    }
    triggerRefresh();
    onClose();
  };
  useEffect(() => {
    if (data && isEdit) {
      setInitialData(data);
      form.setFieldsValue({
        id: productId,
        name: data.name,
        service: data.institution.serviceId,
        institutionId: data.institution.id,
        branches: data.branches.map(
          (item: any) => item.id
        ),
        workflows: data?.institutionWorkflows?.map(
          (item: any) => item.id
        ),
        identificationSystemCode: data.identificationSystemCode,
        productTypeId: data.productTypeId,
        productSubTypeId: data.productSubTypeId,
        revenueType: data.revenueType,
        subjectDisciplinesId: data.subjectDisciplinesId,
        subjectAreaId: data?.subjectAreaId,
        productDurationYear: data.productDurationYear,
        productDurationMonth: data.productDurationMonth,
        productDurationWeek: data.productDurationWeek,
        intakes: data?.intakes,
        programLink: data.programLink,
        description: data.description,
      });
      setSelectedInstitution(data?.institution?.id);
    } else {
      setSelectedInstitution(undefined);
    }
  }, [data, isEdit]);

  useEffect(() => {
    if (selectedService) {
      const filterQuery = {};
      if (filter.productSubTypes && filter.productSubTypes.length > 0) {
        Object.assign(filterQuery, {
          'filter.productSubType': '$in:' + filter.productSubTypes.join(','),
        })
      }
      if (filter.subjectArea && filter.subjectArea.length > 0) {
        Object.assign(filterQuery, {
          'filter.subjectArea': filter.subjectArea
        })
      }

      if (institutions.length) {
        Object.assign(filterQuery, {
          'filter.institutionId': '$in:' + institutions.join(',')
        })
      }
      dispatch(
        productActions.getProductsRequest({
          page: currentPage,
          search: search,
          service: selectedService,
          country: selectedCountry,
          limit: 10,
          ...filterQuery
        })
      );
    }
  }, [currentPage, search, selectedService, selectedCountry, filter, dispatch, institutions]);

  return (
    <Spin spinning={productsListLoading}>
      <div className="full-height">
        <PageHeaderTitle title={PRODUCT_TITLE} />
        <ProductsFormDrawer
          productId={productId}
          isOpen={isOpen}
          form={form}
          isLoading={servicesLoading}
          handleSubmit={handleSubmit}
          initialData={initialData}
          onClose={onClose}
          isEdit={isEdit}
          disabled={disabled}
          setDisabled={setDisabled}
        />

        <Row gutter={GUTTER}>
          <Col span={6}>
            <ProductFilter />
          </Col>

          <Col span={18} className=" bg-white padding-top-2 border-1-px">
            <ProductToolbar showDrawer={showDrawer} />
            {!productsListLoading && productsList?.length ? (
              <Card bordered={true} className="margin-top-2 product-card">
                {productsList?.map((product, index: number) => (
                  <ProductsCard
                    key={index}
                    product={product}
                    setProductId={setProductId}
                    setIsOpen={setIsOpen}
                    setIsEdit={setIsEdit}
                    deleteProduct={deleteProduct}
                  />
                ))}
              </Card>
            ) : (
              <Card className="margin-top-2">
                <Empty
                  image={Empty.PRESENTED_IMAGE_SIMPLE}
                  description={TEXT.NO_PRODUCTS_FOUND}
                />
              </Card>
            )}

            <Row justify="end" className="margin-top-2 margin-bottom-2">
              {productsList?.length > 0 && !productsListLoading ?
                <Pagination
                  onChange={(value) => setCurrentPage(value)}
                  current={meta?.currentPage}
                  total={meta?.totalItems}
                  showSizeChanger={false}
                  defaultPageSize={meta?.itemsPerPage}
                  pageSize={meta?.itemsPerPage}
                  hideOnSinglePage
                />
                : null}
            </Row>
          </Col>
        </Row>
      </div>
    </Spin>
  );
};
export { ProductsListComponent };
