/* eslint-disable no-unused-vars */
import { ADMIN_EMAIL_LABEL } from '@moxie/constants';
import { createAction, createSlice } from '@reduxjs/toolkit';
import { ILogin, IUser } from '@shared-components/models';
export const AUTH_FEATURE_KEY = 'auth';

export interface AuthState {
  isAuthenticated: boolean;
  loading: boolean;
  user: Required<IUser> | null;
  sessionRefreshModal: boolean;
  error: null;
}

export const initialAuthState: AuthState = {
  isAuthenticated: false,
  loading: false,
  sessionRefreshModal: false,
  user: null,
  error: null,
};

export const authSlice = createSlice({
  name: AUTH_FEATURE_KEY,
  initialState: initialAuthState,
  reducers: {
    loading: (state, action) => {
      state.loading = action.payload;
    },
    error: (state) => {
      state.isAuthenticated = false;
      state.loading = false;
    },
    setUser: (state, action) => {
      state.isAuthenticated = true;
      localStorage.setItem(ADMIN_EMAIL_LABEL, action.payload.email);
      state.user = action.payload;
      state.loading = false;
    },
    getAuthUser: (state) => {
      state.loading = false;
    },
    authUser: (state, action) => {
      state.isAuthenticated = true;
      state.user = action.payload;
      state.loading = false;
    },
    isAuthenticated: (state) => {
      state.isAuthenticated = true;
    },
    openSessionModal: (state) => {
      state.sessionRefreshModal = true;
    },
    closeSessionModal: (state) => {
      if (state.sessionRefreshModal) window.location.reload();
      state.sessionRefreshModal = false;
    },
    logout: (state, _action) => {
      state.isAuthenticated = false;
      state.user = null;
      state.loading = false;
      state.error = null;
    },
  },
});

const login = createAction<ILogin>(`${AUTH_FEATURE_KEY}/login`);

export default authSlice.reducer;

export const authActions = { ...authSlice.actions, login };
