import { GUTTER } from '@moxie/constants';
import { Divider, Row, Image } from 'antd';
import React from 'react';
const LeadFormFooter: React.FC = () => {
  return (
    <div className="created-leadForm-section ">
      <Divider plain>
        <Row gutter={GUTTER}>
          <Image
            height={40}
            preview={false}
            src="https://heubert.com/wp-content/uploads/2021/02/heubert_logo@2x.png"
          />
        </Row>
      </Divider>
    </div>
  );
};
export { LeadFormFooter };
