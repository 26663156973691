import React from 'react';
import {
  InfoCircleOutlined,
  BankOutlined,
  CheckCircleOutlined,
} from '@ant-design/icons';

export const GRID = {
  xs: 23,
  lg: 15,
  xll: 10,
};

export const REGISTRATION_LEFT_PANE_TEXT = [
  {
    title: 'Personal details',
    body:
      "This takes just a few minutes. When you are done, we'll send you and email with your login details. Awesome",
    icon: <InfoCircleOutlined />,
  },
  {
    title: 'Institution information',
    body:
      'These information will assist us to setup preferences for your application',
    icon: <BankOutlined />,
  },
  {
    title: '',
    body: '',
  },
  {
    title: 'Almost done!',
    icon: <CheckCircleOutlined />,
  },
];
