//all commented code are for future implementation.
import {
  ApartmentOutlined,
  AuditOutlined,
  ClockCircleOutlined,
  FilterOutlined,
  RedoOutlined,
} from '@ant-design/icons';
import { useAppSelector } from '@lyra/core';
import { getAllProducts } from '@lyra/services.api';
import {
  APPLY_FILTERS_BTN,
  COLUMNS,
  DRAWER_WIDTH,
  LABEL,
  PRODUCT_TABLE,
  PRODUCT_TITLE,
  TEXT,
  URL_INSTITUTION_DETAIL_LINK,
  URL_PRODUCT_DETAIL_LINK,
} from '@moxie/constants';
import { InstitutionBranchTagGroup, Table } from '@shared-components/elements';
import {
  IDataType,
  // ISubjectAreasOptions,
  IFilterItem,
  IInstituteInterface,
  IProduct,
  IProductFilterDrawer,
  IProductType,
} from '@shared-components/models';
import {
  Badge,
  Button,
  Checkbox,
  Collapse,
  Drawer,
  InputNumber,
  Slider,
  Space,
  Tree,
  Typography,
} from 'antd';
import { CheckboxValueType } from 'antd/lib/checkbox/Group';
import { DataNode } from 'antd/lib/tree';
import React, { useEffect, useState } from 'react';
import { Link, useHistory, useLocation } from 'react-router-dom';
import { ProductTableExpandable } from './product-table-expandable';
const { Text } = Typography;

const { Panel } = Collapse;
const CheckboxGroup = Checkbox.Group;

export const TextProductLinkField = ({
  text,
  row,
}: {
  text: string | number;
  row: any;
}) => {
  return (
    <Link to={`${URL_PRODUCT_DETAIL_LINK}${row.id}/documents`}>
      <Text
        className="text-primary-color ellipsis-text"
        ellipsis={String(text).length >= 45 ? { tooltip: text } : false}
      >
        {text}
      </Text>
    </Link>
  );
};

export const TextInstitutionLinkField = ({
  text,
  institutionId,
}: {
  text: string | number;
  institutionId: string | number;
}) => {
  return (
    <div>
      <Link to={`${URL_INSTITUTION_DETAIL_LINK}${institutionId}/branches`}>
        <Text
          className="text-primary-color ellipsis-text margin-bottom-half"
          ellipsis={String(text).length >= 45 ? { tooltip: text } : false}
        >
          {text}
        </Text>
      </Link>
    </div>
  );
};

const ProductTable: React.FC = () => {
  const [filter, setFilter] = useState<any>({});
  const [showFilter, setShowFilter] = useState(false);
  const [filterCount, setFilterCount] = useState(0);

  const columns: IDataType[] = [
    {
      title: LABEL.NAME,
      dataIndex: 'name',
      key: 'name',
      sorter: () => 0,
      innerWidth: 450,
      render(data: string, row: IProduct) {
        return <TextProductLinkField text={data} row={row} />;
      },
    },
    {
      title: LABEL.INSTITUTION_BRANCHES,
      dataIndex: 'institution',
      key: 'institution',
      innerWidth: 450,
      render(institution: IInstituteInterface, data: IProduct) {
        const productBranches: any[] = [];
        data?.institutionBranches?.forEach((item: any) => {
          item?.branch && productBranches.push(item.branch);
        });
        return (
          <>
            <TextInstitutionLinkField
              text={institution?.name}
              institutionId={institution?.id}
            />
            <InstitutionBranchTagGroup
              data={productBranches}
              numberOfBranch={3}
            />
          </>
        );
      },
    },

    {
      title: COLUMNS.PRODUCT_TYPE,
      dataIndex: 'productType',
      key: 'productType',
      render(productType: IProductType, data: IProduct) {
        return (
          <>
            <div>
              <Text>{data?.productSubType?.name}</Text>
            </div>
            <Text>
              <small className="table-mail-icon text-icon">
                {productType?.name}
              </small>
            </Text>
          </>
        );
      },
    },
    {
      title: TEXT.REVENUE_TYPE,
      dataIndex: 'revenue_type',
      key: 'revenue_type',
    },
    {
      title: LABEL.ENROLLED,
      dataIndex: 'enrolled',
      key: 'enrolled',
      render(data: string) {
        return data ? data : '-';
      },
    },
    {
      title: LABEL.IN_PROGRESS,
      dataIndex: 'inprogress',
      key: 'inprogress',
      render(data: string) {
        return data ? data : '-';
      },
    },
  ];

  const getData = async (params: string) => {
    return getAllProducts(params);
  };

  const handleToggleFilter = () => {
    setShowFilter(!showFilter);
  };

  useEffect(() => {
    const filtersCount = Object.keys(filter).filter(function (k: any) {
      return filter[k] !== null;
    });
    setFilterCount(filtersCount.length);
  }, [filter]);

  return (
    <>
      <ProductFilterDrawer
        setFilter={setFilter}
        filter={filter}
        setShowFilter={setShowFilter}
        handleToggleFilter={handleToggleFilter}
        showFilter={showFilter}
      />
      <div className="bg-white padding-top-2">
        <Table
          tableName={PRODUCT_TITLE}
          className="interested-service-table"
          columns={columns}
          action={getData}
          filter={filter}
          filterOptions={true}
          expandable={{
            expandedRowRender(data: IProduct) {
              return <ProductTableExpandable data={data} />;
            },
          }}
          filterContent={
            <Button className="filter-btn" onClick={handleToggleFilter}>
              <FilterOutlined />
              <span>{TEXT.FILTERS}</span>
              {filterCount > 0 && (
                <Badge
                  count={filterCount}
                  size="small"
                  className="margin-left-1"
                />
              )}
            </Button>
          }
          rowSelect={false}
        />
      </div>
    </>
  );
};

export const ProductFilterDrawer = (props: IProductFilterDrawer) => {
  const {
    setFilter,
    setShowFilter,
    filter,
    handleToggleFilter,
    showFilter,
  } = props;
  const search = useLocation().search;
  const history = useHistory();
  const productFilters = useAppSelector(
    (state: any) => state.product.productFilters
  );
  const [education_level, setEducationLevelFilters] = useState<any>();
  // const [durations, setDurationsFilters] = useState<any>();
  const [disciplines, setDisciplines] = useState<any>();
  // const [workflows, setWorkflows] = useState<[]>();
  // const [services, setServices] = useState<IService[]>();

  const [educationLevel, setEducationLevel] = useState<
    (string | CheckboxValueType)[]
  >([]);
  // const [checkedDisciplines, setCheckedDisciplines] = useState<
  //   (string | CheckboxValueType)[]
  // >([]);
  // const [checkedDuration, setCheckedDuration] = useState<
  //   (string | CheckboxValueType)[]
  // >([]);
  const [checkedWorkflow, setCheckedWorkflow] = useState<
    (string | CheckboxValueType)[]
  >([]);
  const [checkedService, setCheckedService] = useState<
    (string | CheckboxValueType)[]
  >([]);
  // const [
  //   checkedDisciplineAreasParent,
  //   setCheckedDisciplineAreasParent,
  // ] = useState<(string | CheckboxValueType)[]>([]);

  // const [viewAllDisciplines, setViewAllDisciplines] = useState(false);
  // const [slicedDiscipline, setSlicedDiscipline] = useState(3);
  const [ieltsInput, setIeltsInput] = useState<number>(0);
  const [pteInput, setPteInput] = useState<number>(0);
  const [toeflInput, setToeflInput] = useState<number>(0);
  const [satOneInput, setSatOneInput] = useState<number>(0);
  const [satTwoInput, setSatTwoInput] = useState<number>(0);
  const [greInput, setGreInput] = useState<number>(0);
  const [expandedKeys, setExpandedKeys] = useState<React.Key[]>([]);
  const [checkedKeys, setCheckedKeys] = useState<React.Key[]>([]);
  const [autoExpandParent, setAutoExpandParent] = useState<boolean>(true);
  // const [searchDiscipline, setSearchDiscipline] = useState('');
  const handleEducationLevelChange = (
    checkedList: (string | CheckboxValueType)[]
  ) => {
    setEducationLevel(checkedList);
  };

  // const handleCheckedDisciplineChange = (e: any) => {
  //   if (e.target.checked === true) {
  //     setCheckedDiscipline([e.target.value, ...checkedDiscipline]);
  //   } else {
  //     const removeFilter = checkedDiscipline.filter(
  //       (item) => item !== e.target.value
  //     );
  //     setCheckedDiscipline(removeFilter);

  //     const subjectAreaIds: any =
  //       disciplines &&
  //       disciplines
  //         .filter(
  //           (item: IFilterItem) => item.value.toString() === e.target.value
  //         )[0]
  //         .subject_areas.map((item: any) => item.value.toString());
  //     const removeFilterAreas = checkedDisciplineAreasParent.filter(
  //       (item: string | any) => subjectAreaIds.indexOf(item) === -1
  //     );
  //     setCheckedDisciplineAreasParent(removeFilterAreas);
  //   }
  // };

  // const handleCheckedDurationChange = (
  //   checkedList: (string | CheckboxValueType)[]
  // ) => {
  //   setCheckedDuration(checkedList);
  // };

  // const handleCheckedWorkflowChange = (
  //   checkedList: (string | CheckboxValueType)[]
  // ) => {
  //   setCheckedWorkflow(checkedList);
  // };

  // const handleCheckedServiceChange = (
  //   checkedList: (string | CheckboxValueType)[]
  // ) => {
  //   setCheckedService(checkedList);
  // };

  // const onSearch = (e: React.ChangeEvent<HTMLInputElement>) => {
  //   setSearchDiscipline(e.target.value);
  // };

  const checkExist = (query: any, tag: string, first = true) => {
    return query.length > 0 ? `${!first ? '&' : ''}${tag}=${query}` : '';
  };

  const handleQuery = async () => {
    let formattedCheckedKeys: any = [];
    const checkLevelExist =
      (educationLevel.length === 0 &&
        checkedKeys.length === 0 &&
        checkedWorkflow.length === 0 &&
        checkedService.length === 0) ||
      false;
    if (checkedKeys.length === 1) {
      formattedCheckedKeys = await Promise.all(
        checkedKeys?.map((item: any) => {
          return String(item).includes('-');
        })
      );
    }

    const queryString = `?${checkExist(checkedService, 'service')}${checkExist(
      formattedCheckedKeys.includes(false) ? '' : [...checkedKeys],
      'disciplineAreas',
      checkLevelExist
    )}${checkExist(checkedWorkflow, 'workflow', checkLevelExist)}${checkExist(
      educationLevel,
      'level',
      checkLevelExist
    )}${ieltsInput ? `&ielts=${ieltsInput}` : ''}${pteInput ? `&pte=${pteInput}` : ''
      }${toeflInput ? `&toefl=${toeflInput}` : ''}${satOneInput ? `&sat1=${satOneInput}` : ''
      }${satTwoInput ? `&sat2=${satTwoInput}` : ''}${greInput ? `&gre=${greInput}` : ''
      }`;

    history.push(queryString);
    setShowFilter(false);
  };

  const handleResetFilters = () => {
    history.replace({
      search: '',
    });
    setShowFilter(false);
    setCheckedKeys([]);
  };

  // const handleViewAllDiscipline = () => {
  //   setViewAllDisciplines(!viewAllDisciplines);
  // };

  const onIeltsChange = (value: number) => {
    if (isNaN(value)) {
      return;
    }
    setIeltsInput(value);
  };

  const onPteChange = (value: number) => {
    if (isNaN(value)) {
      return;
    }
    setPteInput(value);
  };

  const onToeflChange = (value: number) => {
    if (isNaN(value)) {
      return;
    }
    setToeflInput(value);
  };

  // const onSat1Change = (value: number) => {
  //   if (isNaN(value)) {
  //     return;
  //   }
  //   setSatOneInput(value);
  // };

  // const onSat2Change = (value: number) => {
  //   if (isNaN(value)) {
  //     return;
  //   }
  //   setSatTwoInput(value);
  // };

  // const onGreChange = (value: number) => {
  //   if (isNaN(value)) {
  //     return;
  //   }
  //   setGreInput(value);
  // };

  const checkFilterState = (filter: any) => {
    return (
      filter &&
      Object.keys(filter).length === 0 &&
      filter.constructor === Object
    );
  };

  useEffect(() => {
    setEducationLevelFilters(productFilters.education_level);
    setDisciplines(productFilters.disciplines);
    // setDurationsFilters(productFilters.durations);
    // setServices(productFilters.services);
    // setWorkflows(productFilters.workflows);
  }, [productFilters]);

  // useEffect(() => {
  //   if (viewAllDisciplines) {
  //     setSlicedDiscipline(-1);
  //   } else {
  //     setSlicedDiscipline(3);
  //   }
  // }, [setSlicedDiscipline, viewAllDisciplines]);

  // useEffect(() => {
  //   const filteredDisciplines: any =
  //     disciplines &&
  //     disciplines?.filter(
  //       (item: IFilterItem) =>
  //         item.label.toLowerCase().indexOf(searchDiscipline.toLowerCase()) >= 0
  //     );
  //   if (
  //     filteredDisciplines &&
  //     filteredDisciplines?.length > 0 &&
  //     searchDiscipline !== ''
  //   ) {
  //     setDisciplines(filteredDisciplines);
  //   } else {
  //     setDisciplines(productFilters.disciplines);
  //   }
  // }, [searchDiscipline, setDisciplines]);

  useEffect(() => {
    const searchQuery = new URLSearchParams(search);

    if (searchQuery.get('level')) {
      let defaultValue: string[] | undefined = searchQuery
        .get('level')
        ?.split(',');
      if (defaultValue) {
        defaultValue = defaultValue.map((item) => item.trim());
        setEducationLevel(defaultValue);
      }
    }

    if (searchQuery.get('workflow')) {
      let defaultValue: string[] | undefined = searchQuery
        .get('workflow')
        ?.split(',');
      if (defaultValue) {
        defaultValue = defaultValue.map((item) => item.trim());
        setCheckedWorkflow(defaultValue);
      }
    }
    if (searchQuery.get('service')) {
      let defaultValue: string[] | undefined = searchQuery
        .get('service')
        ?.split(',');
      defaultValue = defaultValue?.map((item) => item.trim()) || [];
      setCheckedService(defaultValue);
    }
    // if (searchQuery.get('duration')) {
    //   let defaultValue: string[] | undefined = searchQuery
    //     .get('duration')
    //     ?.split(',');
    //   if (defaultValue) {
    //     defaultValue = defaultValue.map((item) => item.trim());
    //     setCheckedDuration(defaultValue);
    //   }
    // }
    if (searchQuery.get('disciplineAreas')) {
      let defaultValue: string[] | undefined = searchQuery
        .get('disciplineAreas')
        ?.split(',');
      if (defaultValue) {
        defaultValue = defaultValue.map((item) => item.trim());
        setCheckedKeys(defaultValue);
      }
    }

    // if (searchQuery.get('discipline')) {
    //   let defaultValue: string[] | undefined = searchQuery
    //     .get('discipline')
    //     ?.split(',');
    //   if (defaultValue) {
    //     defaultValue = defaultValue.map((item) => item.trim());
    //     setDiscipline(defaultValue);
    //   }
    // }
    // if (searchQuery.get('area')) {
    //   let defaultValue: string[] | undefined = searchQuery
    //     .get('area')
    //     ?.split(',');
    //   if (defaultValue) {
    //     defaultValue = defaultValue.map((item) => item.trim());
    //     setCheckedDisciplineAreasParent(defaultValue);
    //   }
    // }
    if (searchQuery.get('ielts')) {
      const defaultValue: string | undefined | null = searchQuery.get('ielts');
      if (defaultValue) {
        setIeltsInput(parseInt(defaultValue));
      }
    }
    if (searchQuery.get('pte')) {
      const defaultValue: string | undefined | null = searchQuery.get('pte');
      if (defaultValue) {
        setPteInput(parseInt(defaultValue));
      }
    }
    if (searchQuery.get('toefl')) {
      const defaultValue: string | undefined | null = searchQuery.get('toefl');
      if (defaultValue) {
        setToeflInput(parseInt(defaultValue));
      }
    }
    if (searchQuery.get('sat1')) {
      const defaultValue: string | undefined | null = searchQuery.get('sat1');
      if (defaultValue) {
        setSatOneInput(parseInt(defaultValue));
      }
    }
    if (searchQuery.get('sat2')) {
      const defaultValue: string | undefined | null = searchQuery.get('sat2');
      if (defaultValue) {
        setSatTwoInput(parseInt(defaultValue));
      }
    }
    if (searchQuery.get('gre')) {
      const defaultValue: string | undefined | null = searchQuery.get('gre');
      if (defaultValue) {
        setGreInput(parseInt(defaultValue));
      }
    }
    setFilter({
      ...filter,
      // area: searchQuery.get('area'),
      // discipline: searchQuery.get('discipline'),
      disciplineAreas: searchQuery.get('disciplineAreas'),
      workflow: searchQuery.get('workflow'),
      service: searchQuery.get('service'),
      country: searchQuery.get('country'),
      level: searchQuery.get('level'),
      ielts: searchQuery.get('ielts'),
      toefl: searchQuery.get('toefl'),
      pte: searchQuery.get('pte'),
      sat1: searchQuery.get('sat1'),
      sat2: searchQuery.get('sat2'),
      gre: searchQuery.get('gre'),
    });
  }, [search]);

  useEffect(() => {
    const searchQuery = new URLSearchParams(search);

    if (
      checkFilterState(filter) === false &&
      filter.level !== null &&
      filter.level !== undefined
    ) {
      let defaultValue: string[] = searchQuery.get('level')?.split(',') || [];
      defaultValue = defaultValue.map((item) => item.trim());
      setEducationLevel(defaultValue);
    }
    // if (
    //   checkFilterState(filter) === false &&
    //   filter.area !== null &&
    //   filter.area !== undefined
    // ) {
    //   let defaultValue: string[] = searchQuery.get('area')?.split(',') || [];
    //   defaultValue = defaultValue.map((item) => item.trim());
    //   setCheckedDisciplineAreasParent(defaultValue);
    // }
    // if (
    //   checkFilterState(filter) === false &&
    //   filter.discipline !== null &&
    //   filter.discipline !== undefined
    // ) {
    //   let defaultValue: string[] =
    //     searchQuery.get('discipline')?.split(',') || [];
    //   defaultValue = defaultValue.map((item) => item.trim());
    //   setCheckedDiscipline(defaultValue);
    // }

    if (
      checkFilterState(filter) === false &&
      filter.workflow !== null &&
      filter.workflow !== undefined
    ) {
      let defaultValue: string[] =
        searchQuery.get('workflow')?.split(',') || [];
      defaultValue = defaultValue.map((item) => item.trim());
      setCheckedWorkflow(defaultValue);
    }

    if (
      checkFilterState(filter) === false &&
      filter.service !== null &&
      filter.service !== undefined
    ) {
      let defaultValue: string[] = searchQuery.get('service')?.split(',') || [];
      defaultValue = defaultValue.map((item) => item.trim());
      setCheckedService(defaultValue);
    }

    // if (
    //   checkFilterState(filter) === false &&
    //   filter.duration !== null &&
    //   filter.duration !== undefined
    // ) {
    //   let defaultValue: string[] =
    //     searchQuery.get('duration')?.split(',') || [];
    //   defaultValue = defaultValue.map((item) => item.trim());
    //   setCheckedDuration(defaultValue);
    // }

    if (
      checkFilterState(filter) === false &&
      filter.level !== null &&
      filter.level !== undefined
    ) {
      let defaultValue: string[] = searchQuery.get('level')?.split(',') || [];
      defaultValue = defaultValue.map((item) => item.trim());
      setEducationLevel(defaultValue);
    }
    if (
      checkFilterState(filter) === false &&
      filter.ielts !== null &&
      filter.ielts !== undefined
    ) {
      const defaultValue: string | undefined | null =
        searchQuery.get('ielts') || '0';
      if (defaultValue) {
        setIeltsInput(parseInt(defaultValue));
      }
    }
    if (
      checkFilterState(filter) === false &&
      filter.pte !== null &&
      filter.pte !== undefined
    ) {
      const defaultValue: string | undefined | null =
        searchQuery.get('pte') || '0';
      if (defaultValue) {
        setPteInput(parseInt(defaultValue));
      }
    }
    if (
      checkFilterState(filter) === false &&
      filter.toefl !== null &&
      filter.toefl !== undefined
    ) {
      const defaultValue: string | undefined | null =
        searchQuery.get('toefl') || '0';
      if (defaultValue) {
        setToeflInput(parseInt(defaultValue));
      }
    }

    if (
      checkFilterState(filter) === false &&
      filter.sat1 !== null &&
      filter.sat1 !== undefined
    ) {
      const defaultValue: string | undefined | null =
        searchQuery.get('sat1') || '0';
      if (defaultValue) {
        setSatOneInput(parseInt(defaultValue));
      }
    }

    if (
      checkFilterState(filter) === false &&
      filter.sat2 !== null &&
      filter.sat2 !== undefined
    ) {
      const defaultValue: string | undefined | null =
        searchQuery.get('sat2') || '0';
      if (defaultValue) {
        setSatTwoInput(parseInt(defaultValue));
      }
    }

    if (
      checkFilterState(filter) === false &&
      filter.gre !== null &&
      filter.gre !== undefined
    ) {
      const defaultValue: string | undefined | null =
        searchQuery.get('gre') || '0';
      if (defaultValue) {
        setGreInput(parseInt(defaultValue));
      }
    }
  }, [
    search,
    showFilter,
    filter,
    // setCheckedDisciplineAreasParent,
    // setCheckedDiscipline,
    setEducationLevel,
  ]);

  const onExpand = (expandedKeysValue: React.Key[]) => {
    setExpandedKeys(expandedKeysValue);
    setAutoExpandParent(false);
  };

  const onCheck = async (checkedKeysValue: any) => {
    setCheckedKeys(checkedKeysValue);
  };

  const treeData: DataNode[] = disciplines?.map((discipline: any) => {
    return {
      title: discipline.label,
      key: discipline.value,
      children: discipline.subject_areas?.map((area: any) => {
        return {
          title: area.label,
          key: discipline.value + '-' + area.value,
        };
      }),
    };
  });

  return (
    <Drawer
      className="filter"
      title={
        <>
          <Space size={8} className="filter__title">
            <FilterOutlined />
            <span>{TEXT.FILTERS}</span>
          </Space>
        </>
      }
      bodyStyle={{ padding: '0px' }}
      placement="right"
      onClose={handleToggleFilter}
      visible={showFilter}
      width={DRAWER_WIDTH}
      footer={
        <>
          <div className="text-align-right">
            <Button
              onClick={handleQuery}
              type="primary"
              block
              size="large"
              className="lead-margin-right"
            >
              {APPLY_FILTERS_BTN}
            </Button>
          </div>
          <Button
            onClick={handleResetFilters}
            icon={<RedoOutlined />}
            type="link"
          >
            {TEXT.RESET_FILTERS}
          </Button>
        </>
      }
    >
      {showFilter && (
        <Collapse
          className="filter__collapse"
          expandIconPosition="right"
          defaultActiveKey={[
            // 'service',
            // 'workflow',
            'level',
            // 'discipline',
            'requirement',
            'disciplines',
          ]}
          ghost
        >
          <Panel
            className="filter__panel "
            header={
              <div className="space-1 filter__header">
                <Space size={8}>
                  <AuditOutlined />
                  <span>{TEXT.DISCIPLINE}</span>
                </Space>
              </div>
            }
            key="disciplines"
          >
            <Tree
              height={300}
              selectable={false}
              checkable
              onExpand={onExpand}
              expandedKeys={expandedKeys}
              autoExpandParent={autoExpandParent}
              onCheck={onCheck}
              checkedKeys={checkedKeys}
              treeData={treeData}
            />
          </Panel>
          {/*Commented for now but will be implemented in future*/}
          {/*Services panel starts*/}
          {/* <Panel
            className="filter__panel"
            header={
              <div className="space-1 filter__header">
                <Space size={8}>
                  <ApartmentOutlined />
                  <span>{TEXT.SERVICES}</span>
                </Space>
              </div>
            }
            key="service"
          >
            <CheckboxGroup
              value={checkedService}
              onChange={handleCheckedServiceChange}
            >
              {services &&
                services.map((item: IService) => (
                  <div className="filter__options" key={item.id}>
                    <Checkbox value={item.id}>
                      <div className="filter__options_checkbox">
                        <span>{item.name}</span>
                      </div>
                    </Checkbox>
                  </div>
                ))}
            </CheckboxGroup>
          </Panel>

          {/*Workflow panel starts*/}
          {/* <Panel
            className="filter__panel"
            header={
              <div className="space-1 filter__header">
                <Space size={8}>
                  <ApartmentOutlined />
                  <span>{TEXT.WORKFLOW}</span>
                </Space>
              </div>
            }
            key="workflow"
          >
            <CheckboxGroup
              value={checkedWorkflow}
              onChange={handleCheckedWorkflowChange}
            >
              {workflows &&
                workflows.map((item: any) => (
                  <div className="filter__options" key={item.id}>
                    <Checkbox value={item.id}>
                      <div className="filter__options_checkbox">
                        <span>{`${item.workflow_type} (${item.country})`}</span>
                      </div>
                    </Checkbox>
                  </div>
                ))}
            </CheckboxGroup>
          </Panel> */}

          {/*Education Level panel starts*/}
          <Panel
            className="filter__panel"
            header={
              <div className="space-1 filter__header">
                <Space size={8}>
                  <ApartmentOutlined />
                  <span>{PRODUCT_TABLE.DEGREE_LEVEL}</span>
                </Space>
              </div>
            }
            key="level"
          >
            <CheckboxGroup
              value={educationLevel}
              onChange={handleEducationLevelChange}
            >
              {education_level &&
                education_level.map((item: IFilterItem) => (
                  <div className="filter__options" key={item.value}>
                    <Checkbox value={item.value.toString()}>
                      <div className="filter__options_checkbox">
                        <span>{item.label}</span>
                        {item.count && (
                          <div className="filter__options_count">
                            {item.count}
                          </div>
                        )}
                      </div>
                    </Checkbox>
                  </div>
                ))}
            </CheckboxGroup>
          </Panel>

          {/*Discipline panel starts*/}
          {/* <Panel
            className="filter__panel"
            header={
              <div className="space-1 filter__header">
                <Space size={8}>
                  <AuditOutlined />
                  <span>{TEXT.DISCIPLINE}</span>
                </Space>
              </div>
            }
            key="discipline"
          >
            <Search
              className="margin-bottom-1"
              placeholder="Search Disciplines"
              allowClear
              value={searchDiscipline}
              onChange={onSearch}
            />
            {disciplines &&
              disciplines
                .slice(0, slicedDiscipline)
                .map((item: IFilterItem) => (
                  <div className="filter__options" key={item.value}>
                    <Checkbox
                      value={item.value.toString()}
                      onChange={handleCheckedDisciplineChange}
                      checked={checkedDiscipline.includes(
                        item.value.toString()
                      )}
                    >
                      <div className="filter__options_checkbox">
                        <span>{item.label}</span>
                        {item.count && (
                          <div className="filter__options_count">
                            {item.count}
                          </div>
                        )}
                      </div>
                    </Checkbox>
                    <SubjectAreasOptions
                      checkedDiscipline={checkedDiscipline}
                      checkedDisciplineAreasParent={
                        checkedDisciplineAreasParent
                      }
                      setCheckedDisciplineAreasParent={
                        setCheckedDisciplineAreasParent
                      }
                      item={item}
                      setFilter={setFilter}
                    />
                  </div>
                ))}
            <span
              className="filter__view_more"
              onClick={handleViewAllDiscipline}
            >
              {!viewAllDisciplines ? 'View All...' : 'Show Less...'}
            </span>
          </Panel> */}

          {/*Duration panel starts*/}
          {/*TODO: Refactor later*/}
          {/*<Panel*/}
          {/*  className="filter__panel"*/}
          {/*  header={*/}
          {/*    <div className="space-1 filter__header">*/}
          {/*      <Space size={8}>*/}
          {/*        <ClockCircleOutlined />*/}
          {/*        <span>{TEXT.DURATION}</span>*/}
          {/*      </Space>*/}
          {/*    </div>*/}
          {/*  }*/}
          {/*  key="duration"*/}
          {/*>*/}
          {/*  <CheckboxGroup*/}
          {/*    value={checkedDuration}*/}
          {/*    onChange={handleCheckedDurationChange}*/}
          {/*  >*/}
          {/*    {durations &&*/}
          {/*      durations.map((item: IFilterItem) => (*/}
          {/*        <div className="filter__options" key={item.value}>*/}
          {/*          <Checkbox value={item.value.toString()}>*/}
          {/*            <div className="filter__options_checkbox">*/}
          {/*              <span>{item.label}</span>*/}
          {/*              {item.count && (*/}
          {/*                <div className="filter__options_count">*/}
          {/*                  {item.count}*/}
          {/*                </div>*/}
          {/*              )}*/}
          {/*            </div>*/}
          {/*          </Checkbox>*/}
          {/*        </div>*/}
          {/*      ))}*/}
          {/*  </CheckboxGroup>*/}
          {/*</Panel>*/}

          {/*Product Requirement panel starts*/}
          <Panel
            className="filter__panel"
            header={
              <div className="space-1 filter__header">
                <Space size={8}>
                  <ClockCircleOutlined />
                  <span>{TEXT.OVERALL_ENGLISH_TEST_SCORE}</span>
                </Space>
              </div>
            }
            key="requirement"
          >
            <div className="prd">
              <div className="prd-left">
                <div className="prd-item">
                  <span>{TEXT.IELTS}</span>
                  <div className="prd-item__slider">
                    <Slider
                      min={0}
                      max={9}
                      step={0.5}
                      onChange={onIeltsChange}
                      value={ieltsInput || 0}
                    />
                  </div>
                  <InputNumber
                    min={0}
                    max={9}
                    value={ieltsInput}
                    onChange={onIeltsChange}
                  />
                </div>
                <div className="prd-item">
                  <span>{TEXT.PTE}</span>
                  <div className="prd-item__slider">
                    <Slider
                      min={0}
                      max={90}
                      step={1}
                      onChange={onPteChange}
                      value={pteInput || 0}
                    />
                  </div>
                  <InputNumber
                    min={0}
                    max={90}
                    value={pteInput}
                    onChange={onPteChange}
                  />
                </div>
                <div className="prd-item">
                  <span>{TEXT.TOEFL}</span>
                  <div className="prd-item__slider">
                    <Slider
                      min={0}
                      max={120}
                      step={1}
                      onChange={onToeflChange}
                      value={toeflInput || 0}
                    />
                  </div>
                  <InputNumber
                    min={0}
                    max={120}
                    value={toeflInput}
                    onChange={onIeltsChange}
                  />
                </div>
              </div>
              {/* <div className="prd-right">
                <div className="prd-item">
                  <span>{TEXT.SAT_I}</span>
                  <div className="prd-item__slider">
                    <Slider
                      min={0}
                      max={1600}
                      step={1}
                      onChange={onSat1Change}
                      value={satOneInput || 0}
                    />
                  </div>
                  <InputNumber
                    min={0}
                    max={1600}
                    value={satOneInput}
                    onChange={onSat1Change}
                  />
                </div>
                <div className="prd-item">
                  <span>{TEXT.SAT_II}</span>
                  <div className="prd-item__slider">
                    <Slider
                      min={0}
                      max={1600}
                      step={1}
                      onChange={onSat2Change}
                      value={satTwoInput || 0}
                    />
                  </div>
                  <InputNumber
                    min={0}
                    max={1600}
                    value={satTwoInput}
                    onChange={onSat2Change}
                  />
                </div>
                <div className="prd-item">
                  <span>{TEXT.GRE}</span>
                  <div className="prd-item__slider">
                    <Slider
                      min={0}
                      max={346}
                      step={1}
                      onChange={onGreChange}
                      value={greInput || 0}
                    />
                  </div>
                  <InputNumber
                    min={0}
                    max={346}
                    value={greInput}
                    onChange={onGreChange}
                  />
                </div>
              </div> */}
            </div>
          </Panel>
        </Collapse>
      )}
    </Drawer>
  );
};

// const SubjectAreasOptions = (props: ISubjectAreasOptions) => {
//   const {
//     item,
//     checkedDiscipline,
//     checkedDisciplineAreasParent,
//     setCheckedDisciplineAreasParent,
//   } = props;

//   const handleCheckedDisciplineAreasChange = (
//     e: React.ChangeEvent<HTMLInputElement> | any
//   ) => {
//     if (e.target.checked === true) {
//       setCheckedDisciplineAreasParent([
//         e.target.value,
//         ...checkedDisciplineAreasParent,
//       ]);
//     } else {
//       const removeFilter = checkedDisciplineAreasParent?.filter(
//         (item: string) => item !== e.target.value
//       );
//       setCheckedDisciplineAreasParent(removeFilter);
//     }
//   };

//   return (
//     <div
//       className={`filter__areas ${
//         item.value === 1 || checkedDiscipline.includes(item.value?.toString())
//           ? 'checked'
//           : ''
//       }`}
//     >
//       {item?.subject_areas &&
//         (item.value === 1 ||
//           checkedDiscipline.includes(item.value.toString())) &&
//         item?.subject_areas.map((item: any) => (
//           <div className="filter__options_nested" key={item.value}>
//             <Checkbox
//               value={item.value.toString()}
//               onChange={handleCheckedDisciplineAreasChange}
//               checked={checkedDisciplineAreasParent.includes(
//                 item.value.toString()
//               )}
//             >
//               <div className="filter__options_checkbox">
//                 <span>{item.label}</span>
//                 {item.count && (
//                   <div className="filter__options_count">{item.count}</div>
//                 )}
//               </div>
//             </Checkbox>
//           </div>
//         ))}
//     </div>
//   );
// };

export { ProductTable };
