import { Col, Row, Space, Typography } from 'antd';
import React from 'react';
import { ApplyIMSLogoDark } from '@shared-components/elements';

interface IRegistrationCard {
  title: string;
  children: React.ReactNode;
  portal?: string;
}

const { Title, Text } = Typography;

const RegistrationCard: React.FC<IRegistrationCard> = ({
  children,
  title,
  portal,
}: IRegistrationCard) => {
  return (
    <Row align="middle" justify="center" className="full-height">
      <div className="registration-card-element">
        <Space direction="vertical" size="large">
          <Row justify="center">
            <Col>
              <div className="card-logo-title-container">
                <ApplyIMSLogoDark />
                <Text className="card-logo-title">{portal}</Text>
              </div>
            </Col>
          </Row>
          <Row justify="center">
            <Col>
              <Title level={4}>{title}</Title>
            </Col>
          </Row>
          <Row justify="center">
            <Col className="text-align-center">{children}</Col>
          </Row>
        </Space>
      </div>
    </Row>
  );
};

export { RegistrationCard };
