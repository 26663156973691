const alias = 'DOCUMENT';
const document_type = 'DOCUMENT_TYPE';

export const GET_DOCUMENT_REQUEST = `GET_${alias}_REQUEST`;
export const GET_DOCUMENT_SUCCESS = `GET_${alias}_SUCCESS`;
export const GET_DOCUMENT_FAILURE = `GET_${alias}_FAILURE`;

export const GET_DOCUMENT_TYPE_REQUEST = `GET_${document_type}_REQUEST`;
export const GET_DOCUMENT_TYPE_SUCCESS = `GET_${document_type}_SUCCESS`;
export const GET_DOCUMENT_TYPE_FAILURE = `GET_${document_type}_FAILURE`;

export const CLEAR_DOCUMENT_FETCH = `CLEAR_${alias}_FETCH`;
