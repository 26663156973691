const tagsToRemove = ['<p>', '</p>', '<br>'];

export const removeDefTags = (data?: string) => {
  if (data) {
    let val = data;
    tagsToRemove.forEach(tag => {
      val = val.replace(tag, '');
    })
    return val.trim();
  }
  return '';
}
