import { leadActions } from "@crm/core";
import { getMediaUrl } from "@crm/services.api";
import { Media } from "@model/document";
import { useState } from "react";
import { useDispatch } from "react-redux";

const useDocumentPreview = () => {
  const dispatch = useDispatch();
  const [isDocsLoading, setDocsLoading] = useState(false);
  const [docPreview, setDocPreview] = useState({
    url: '',
    extension: ''
  })

  const fetchUrlAndPreviewDocs = async (doc: Media) => {
    setDocsLoading(true);
    dispatch(leadActions.downloading(true));
    const url = await getMediaUrl(doc.id);
    if (url) {
      setDocPreview({
        url: url,
        extension: doc.extension.replace('.', ''),
      });
      setDocsLoading(false);
    }
    dispatch(leadActions.downloading(false));

  };

  const clearDocsPreview = () => setDocPreview({ extension: '', url: '' })

  return {
    docPreview,
    fetchUrlAndPreviewDocs,
    isDocsLoading,
    setDocsLoading,
    clearDocsPreview
  }
}

export default useDocumentPreview;
