import * as action from './office-visit.action';
import * as actionTypes from './office-visit.constant';
import { CrmResponse, IAction, IOfficeVisitCrm } from '@shared-components/models';
import {
  fetchPrevVisitByContact,
  officeVisitAdd,
  officeVisitFetch,
  officeVisitUpdate,
} from '../../libs/services.api';
import { AxiosResponse } from 'axios';
import { put, takeEvery, takeLatest } from 'redux-saga/effects';
import { successNotificationHandler } from '@moxie/shared';
import { CONTACT_VISIT_RESPONSE_MESSAGE } from '@moxie/constants';
import { crmErrorHandler } from 'libs/shared/src/crm-modules/shared';

const addOfficeVisit = function* ({
  payload,
  triggerRefresh,
}: IAction<IOfficeVisitCrm>) {
  try {
    if (payload) {
      yield officeVisitAdd(payload);
      yield put(action.addOfficeVisitSuccess());
      successNotificationHandler(CONTACT_VISIT_RESPONSE_MESSAGE.CREATED);
      triggerRefresh && triggerRefresh();
    }
  } catch (err) {
    crmErrorHandler(err);
    yield put(action.addOfficeVisitFailure(err));
  }
};

const getOfficeVisit = function* ({ id }: IAction<IOfficeVisitCrm>) {
  try {
    if (id) {
      const res: AxiosResponse = yield officeVisitFetch(id);
      yield put(action.getOfficeVisitSuccess(res?.data));
    }
  } catch (err) {
    crmErrorHandler(err);
    yield put(action.getOfficeVisitFailure(err));
  }
};

const getPrevOfficeVisitByContact = function* ({ payload }: { payload: { contactId: string; limit?: number; officeVisitId?: string; }; type: string; }) {
  try {
    if (payload?.contactId) {
      const res: CrmResponse<IOfficeVisitCrm> = yield fetchPrevVisitByContact(payload?.contactId, { limit: payload?.limit, 'filter.id': `$not:$eq:${payload?.officeVisitId}` });
      yield put(action.getPrevOfficeVisitSuccess(res?.data?.data));
    }
  } catch (err) {
    crmErrorHandler(err);
    yield put(action.getPrevOfficeVisitFailure(err));
  }
};

const updateOfficeVisit = function* ({
  id,
  payload,
  triggerRefresh,
}: IAction<IOfficeVisitCrm>) {
  try {
    if (id && payload) {
      yield officeVisitUpdate(id, payload);
      yield put(action.updateOfficeVisitSuccess());
      successNotificationHandler(CONTACT_VISIT_RESPONSE_MESSAGE.UPDATED);
      yield put(action.getOfficeVisitRequest(id));
      yield put(action.officeVisitTableRefresh())
      triggerRefresh && triggerRefresh();
    }
  } catch (err) {
    crmErrorHandler(err);
    yield put(action.updateOfficeVisitFailure(err));
  }
};

export default function* rootSaga(): unknown {
  yield takeLatest(actionTypes.ADD_OFFICE_VISIT_REQUEST, addOfficeVisit);
  yield takeEvery(actionTypes.GET_OFFICE_VISIT_REQUEST, getOfficeVisit);
  yield takeLatest(actionTypes.UPDATE_OFFICE_VISIT_REQUEST, updateOfficeVisit);
  yield takeLatest(actionTypes.GET_PREV_OFFICE_VISIT_REQUEST, getPrevOfficeVisitByContact);
}
