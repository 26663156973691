import { useAppSelector } from '@admin/core';
import { COUNTRIES } from '@moxie/constants';
import { ITenantForm } from '@shared-components/models';
import { Form, Skeleton } from 'antd';
import React, { useEffect } from 'react';
import { InstitutionDetailsForm } from './company-detail-form';
import { PersonalDetailsForm } from './personal-detail-form';
import { WorkOrderScopeDetailsForm } from './work-scope-detail-form';

const TenantForm: React.FC<ITenantForm> = ({
  form,
  isEdit,
  handleSubmit,
  dialCode,
  setDialCode,
  setCompanyDomainValidationStatus,
  companyCodeValidationStatus,
  companyDomainValidationStatus,
  setCompanyCodeValidationStatus,
  emailValidationStatus,
  setEmailValidationStatus,
  handleFieldsChange,
  setDisableDrawerButton,
}: ITenantForm) => {
  const { initialData, dataLoading } = useAppSelector((store) => ({
    initialData: store.tenant.singleData,
    dataLoading: store.tenant.loading,
  }));
  const handleChange = (values: any) => {
    if (values.country) {
      setDialCode(
        COUNTRIES.find((item) => values.country === item.code)?.dial_code
      );
    }
  };

  useEffect(() => {
    if (isEdit) {
      form.setFieldsValue({
        ...initialData,
        phone: initialData?.company_phone,
        company_domain: initialData?.company_domain?.split('.')[0],
        feature: initialData?.feature,
      });
      setDialCode(initialData?.country_code);
    }
  }, [isEdit, initialData]);
  return (
    <Form
      layout="vertical"
      form={form}
      onFinish={handleSubmit}
      onValuesChange={handleChange}
      onFieldsChange={handleFieldsChange}
    >
      {dataLoading ? (
        <Skeleton active />
      ) : (
        <>
          {!isEdit && (
            <PersonalDetailsForm
              form={form}
              emailValidationStatus={emailValidationStatus}
              setEmailValidationStatus={setEmailValidationStatus}
            />
          )}
          <InstitutionDetailsForm
            isEdit={isEdit}
            form={form}
            companyId={initialData?.id}
            dialCode={dialCode}
            setDialCode={setDialCode}
            setCompanyDomainValidationStatus={setCompanyDomainValidationStatus}
            companyCodeValidationStatus={companyCodeValidationStatus}
            companyDomainValidationStatus={companyDomainValidationStatus}
            setCompanyCodeValidationStatus={setCompanyCodeValidationStatus}
            setDisabled={setDisableDrawerButton}
          />
          <WorkOrderScopeDetailsForm form={form} isEdit={isEdit} />
        </>
      )}
    </Form>
  );
};

export default TenantForm;
