import LinkOutlined from '@ant-design/icons/lib/icons/LinkOutlined';
import { productServices } from '@lyra/services.api';
import { DURATION, LABEL, TEXT } from '@moxie/constants';
import { Col, Row, Skeleton, Tooltip, Typography } from 'antd';
import React, { useEffect, useState } from 'react';
const { Text } = Typography;

export const ProductTableExpandable = (data: any) => {
  const { id } = data?.data;
  const [productDetail, setProductDetail] = useState<any>();
  const [loading, setLoading] = useState<boolean>(true);

  const getProductDetail = async () => {
    const response = await productServices.apiGetProductDetail(id);
    response && setProductDetail(response?.data?.data);
    response && setLoading(false);
  };

  const [isCopied, setIsCopied] = useState(false);
  const [totalFee, setTotalFee] = useState(0.0);
  const copyToClipboard = (text: string) => {
    navigator.clipboard.writeText(text);
    setIsCopied(true);
  };

  useEffect(() => {
    getProductDetail();
  }, [data]);

  useEffect(() => {
    const element = document.querySelector(
      `[data-row-key="${id}"] .ant-table-row-expand-icon-cell`
    );
    const btn = element?.querySelector('button');

    const addClassToElement = (): void =>
      element?.classList.add('interested-service-border');
    const removeClassFromElement = (): void =>
      element?.classList.remove('interested-service-border');
    const handleBtnClick = () => {
      if (btn?.classList.contains('ant-table-row-expand-icon-collapsed'))
        addClassToElement();
      else removeClassFromElement();
    };

    if (element) addClassToElement();
    btn?.addEventListener('click', handleBtnClick);
    return () => {
      btn?.removeEventListener('click', handleBtnClick);
    };
  }, []);

  useEffect(() => {
    const netFee: any = [];
    productDetail &&
      productDetail?.fees?.map(
        (fee: {
          fee_name: any;
          pfees: {
            total_fee: number;
          }[];
        }) => {
          if (fee?.fee_name === 'Default Fee') {
            fee?.pfees?.map((pfee) => {
              netFee.push(pfee.total_fee);
            });
          }
        }
      );

    setTotalFee(
      netFee?.length > 0 ? netFee?.reduce((a: any, b: any) => a + b) : 0.0
    );
  }, [productDetail]);

  return loading ? (
    <Row className="interested-service-expanded interested-service-border">
      <Skeleton
        className="padding-top-1"
        paragraph={{ rows: 2 }}
        title={false}
        active
      />
    </Row>
  ) : (
    <Row className="interested-service-expanded interested-service-border">
      <Col span={8}>
        <Row gutter={[0, 8]}>
          <Col span={24}>
            <Text type="secondary" strong>
              {TEXT.DISCIPLINE}:&nbsp;
            </Text>
            {productDetail?.subject_disciplines?.discipline ?
              productDetail?.subject_disciplines?.discipline
              :
              '-'}
          </Col>
          <Col span={24}>
            <Text type="secondary" strong>
              {LABEL.SUBJECT_AREA}:&nbsp;
            </Text>
            {productDetail?.subject_area?.name ?
              productDetail?.subject_area?.name :
              '-'
            }
          </Col>
        </Row>
      </Col>
      <Col span={6}>
        <Row gutter={[0, 8]}>
          <Col span={24}>
            <Text type="secondary" strong>
              {TEXT.DURATION}:&nbsp;
            </Text>
            {productDetail?.product_duration_year || productDetail?.product_duration_month || productDetail?.product_duration_week ? (
              <>
                {productDetail?.product_duration_year
                  ? `${productDetail?.product_duration_year} ${productDetail?.product_duration_year > 1
                    ? DURATION.YEARS
                    : DURATION.YEAR
                  }`
                  : ''}
                {productDetail?.product_duration_month
                  ? `${productDetail?.product_duration_month} ${productDetail?.product_duration_month > 1
                    ? DURATION.MONTHS
                    : DURATION.MONTH
                  }`
                  : ''}
                {productDetail?.product_duration_week
                  ? `${productDetail?.product_duration_week} ${productDetail?.product_duration_week > 1
                    ? DURATION.WEEKS
                    : DURATION.WEEK
                  }`
                  : ''}
              </>
            ) : '-'}
          </Col>
          <Col span={24}>
            <Text type="secondary" strong>
              {TEXT.DEFAULT_FEE}:&nbsp;
            </Text>
            {productDetail?.fees.length > 0 ? (
              <>
                {productDetail?.institution?.currency}&nbsp;
                {totalFee}
              </>
            ) : (
              ' -'
            )}
          </Col>
        </Row>
      </Col>
      <Col span={6}>
        <Row gutter={[0, 8]}>
          <Col span={24}>
            <Text type="secondary" strong>
              {TEXT.REVENUE_TYPE}:&nbsp;
            </Text>
            {productDetail?.revenue_type}
          </Col>
        </Row>
      </Col>
      <Col span={4}>
        <Text type="secondary" strong>
          {TEXT.PROGRAM_LINK}:&nbsp;
        </Text>
        {productDetail?.program_link ? (
          <><a
            className="text-primary-color "
            target="_blank"
            rel="noopener noreferrer"
            href={productDetail?.program_link}
          >{TEXT.WEBSITE}&nbsp;
          </a>
            <Tooltip
              placement="right"
              color={isCopied ? '#8EAF74' : '#9B9D9A '}
              title={isCopied ? `${TEXT.COPIED_SUCCESS}` : `${TEXT.COPY_LINK}`}
            >
              <LinkOutlined onClick={() => copyToClipboard(`${productDetail?.program_link}`)} />
            </Tooltip>
          </>
        ) : '-'}
      </Col>
      </Row>
  );
};
