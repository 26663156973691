import { JSONContent } from '@tiptap/react';

function removeMentions(content: JSONContent[] | undefined) {
  const mentions: JSONContent[] = [];

  function traverse(item: JSONContent) {
    if (item.type !== 'mention') {
      mentions.push(item);
    }

    if (Array.isArray(item.content)) {
      item.content.forEach(traverse);
    }
  }

  if (Array.isArray(content)) {
    content.forEach(traverse);
  } else if (content) {
    traverse(content);
  }

  return mentions;
}

export default removeMentions;
