import { API_URL } from '@moxie/constants';
import { IAppointment } from '@shared-components/models';
import axios from './axios';

const saveAppointment = async (body: any): Promise<any> => {
  return await axios.post(API_URL.LEAD_APPOINTMENT_SAVE, body);
};

const getAppointments = (contact_id: string): Promise<any> => {
  return axios.get(`${API_URL.LEAD_GET_APPOINTMENTS}${contact_id}`);
};

const getMyAppointments = (user_id: string | undefined): Promise<any> => {
  return axios.get(`${API_URL.LEAD_GET_MY_APPOINTMENTS}/${user_id}`);
};

const updateAppointment = (body: IAppointment): Promise<any> => {
  return axios.put(`${API_URL.LEAD_UPDATE_APPOINTMENTS}`, body);
};

const updateAppointmentStatus = (body: {
  status: any;
  appointment_id: any;
}): Promise<any> => {
  return axios.put(`${API_URL.LEAD_APPOINTMENTS_STATUS}`, body);
};

const deleteAppointment = async (id: string) => {
  return axios.delete(`${API_URL.LEAD_DELETE_APPOINTMENTS}${id}`);
};

const getAppointment = (id: string) => {
  return axios.get(`${API_URL.LEAD_DELETE_APPOINTMENTS}${id}`);
};
export {
  saveAppointment,
  getAppointments,
  deleteAppointment,
  getAppointment,
  updateAppointment,
  updateAppointmentStatus,
  getMyAppointments,
};
