import React from 'react';
import { TEXT } from '@moxie/constants';
import { IProfileSection } from '@shared-components/models';
import {
  Avatar,
  Card,
  Col,
  DatePicker,
  FormItemProps,
  Row,
  Skeleton,
  Space,
  Tag,
  Tooltip,
} from 'antd';
import { EditOutlined, BankOutlined } from '@ant-design/icons';
import { useState } from 'react';
import { Button, InstitutionBranchTagGroup } from '@shared-components/elements';
import { useForm } from 'antd/lib/form/Form';
import InstitutionDrawer from './institution-drawer';
import { useDispatch } from 'react-redux';
import { institutionActions } from '@admin/core';

const InstitutionDetailProfile: React.FC<IProfileSection> = (
  props: IProfileSection
) => {
  const dispatch = useDispatch();
  const { data, loading } = props;
  const [form] = useForm();
  const [nameValidationStatus, setNameValidationStatus] = useState<
    FormItemProps['validateStatus']
  >('');
  const [disabled, setDisabled] = useState(true);
  const [isOpen, setIsOpen] = useState(false);
  const [isEdit, setIsEdit] = useState(false);
  const [submitted, setSubmitted] = useState<boolean>(false);
  const handleOpen = () => {
    setIsEdit(true);
    setIsOpen(true);
  };

  const onClose = () => {
    setIsOpen(false);
    form.resetFields();
    setIsEdit(false);
    setDisabled(true);
    setSubmitted(false);
  };

  const handleSubmit = (data: any) => {
    let postData;
    if (data?.id && isEdit) {
      postData = { ...data, id: data?.id };

      dispatch(institutionActions.updateInstitutionRequest(postData));
    }
    onClose();
  };
  return (
    <>
      <section className="profile">
        <Card>
          <InstitutionDrawer
            handleClose={onClose}
            isOpen={isOpen}
            triggerRefresh={() => {}}
            form={form}
            handleSubmit={handleSubmit}
            disabled={disabled}
            setDisabled={setDisabled}
            isEdit={isEdit}
            setNameValidationStatus={setNameValidationStatus}
            nameValidationStatus={nameValidationStatus}
            submitted={submitted}
            setSubmitted={setSubmitted}
          />
          <Row gutter={24}>
            <Col lg={8} className="custom_profile">
              {loading ? (
                <Skeleton avatar paragraph={{ rows: 4 }} active />
              ) : (
                <div className="profile__info">
                  <div className="profile__info__item profile__info__institute padding-top-2">
                    <Avatar size="large" icon={<BankOutlined />}>
                      {data?.name?.substring(0, 2)}
                    </Avatar>
                    <div className="profile__info__user__detail ">
                      <h3 className="initial_capital">{data?.name}</h3>
                    </div>
                    <div className="profile__info__edit">
                      <Button
                        icon={<EditOutlined />}
                        onClick={handleOpen}
                        type="link"
                      />
                    </div>
                  </div>
                  <div className="profile__info__item">
                    <Space size={8} className="align-items-center">
                      <h4 className="institute-profile">{TEXT.SERVICE}:</h4>
                      <span className="profile__link initial_capital">
                        {data?.service?.name}
                      </span>
                    </Space>
                  </div>
                  <div className="profile__info__item">
                    <Space size={8} className="align-items-center">
                      <h4 className="institute-profile">{TEXT.BRANCH}:</h4>
                      <span className="profile__link">
                        <InstitutionBranchTagGroup
                          data={data?.branches}
                          numberOfBranch={3}
                        />
                      </span>
                    </Space>
                  </div>
                  <div className="profile__info__item">
                    <Space size={8} className="align-items-center">
                      <h4 className="institute-profile">{TEXT.WORKFLOW}:</h4>
                      <span className="profile__link">
                        {data?.workflows?.map((item: any) => (
                          <Tag key={item?.id}>{item?.workflowType?.name}</Tag>
                        ))}
                      </span>
                    </Space>
                  </div>
                  <div className="profile__info__item">
                    {data?.officialId && (
                      <h4 className="institute-profile">
                        {TEXT.CRICOS_PROVIDER_CODE}:
                        <span className="text-link-color padding-left-1">
                          {data?.identification_system_code
                            ? data.identification_system_code.toUpperCase()
                            : '-'}
                        </span>
                      </h4>
                    )}
                  </div>
                </div>
              )}
            </Col>
            <Col lg={8} className="custom_divider">
              {loading ? (
                <Skeleton paragraph={{ rows: 2 }} title={false} active />
              ) : (
                <>
                  <div>
                    <Space size={8} className=" align-items-center">
                      <h4 className="institute-profile">{TEXT.EMAIL}:</h4>
                      <Tooltip className="initial_small" title={data?.email}>
                        <span className="profile__link initial_small">
                          {data?.email}
                        </span>
                      </Tooltip>
                    </Space>
                  </div>
                  {data?.website && (
                    <div className="profile__info__item">
                      <Space size={8} className=" align-items-center">
                        <h4 className="institute-profile">{TEXT.WEBSITE}:</h4>
                        <span>
                          <a
                            href={`https://${data?.website}`}
                            target="_blank"
                            rel="noopener noreferrer"
                          >
                            {data?.website}
                          </a>
                        </span>
                      </Space>
                    </div>
                  )}
                  <div className="profile__info__item">
                    <Space size={8} className="align-items-center">
                      <h4 className="institute-profile">{TEXT.CURRENCY}:</h4>
                      <span>{data?.currency}</span>
                    </Space>
                  </div>
                </>
              )}
            </Col>
            <Col lg={8}>
              <div className="profile__info__item">
                <div className="profile__header">
                  <h4 className="profile__title align-items-center margin-0">
                    {TEXT.OVERVIEW}:
                  </h4>
                  <DatePicker picker="year" />
                </div>
                <div className="profile__overview">
                  <div className="profile__overview__item">
                    <h4>Completed</h4>
                    <span>0</span>
                  </div>
                  <div className="profile__overview__item">
                    <h4>Completed</h4>
                    <span>0</span>
                  </div>
                  <div className="profile__overview__item">
                    <h4>Completed</h4>
                    <span>0</span>
                  </div>
                  <div className="profile__overview__item">
                    <h4>Completed</h4>
                    <span>0</span>
                  </div>
                </div>
              </div>
            </Col>
          </Row>
        </Card>
      </section>
    </>
  );
};
export { InstitutionDetailProfile };
