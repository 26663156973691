import React, { useState } from 'react';
import { Row, Col, Typography, Tooltip } from 'antd';
import { MailOutlined, LinkOutlined } from '@ant-design/icons';
import {
  IClientTableNameCardProps,
  ITableNameCardProps,
} from '@shared-components/models';
import { TEXT, URL_CLIENT } from '@moxie/constants';
import { Link } from 'react-router-dom';
const { Text } = Typography;

export const UserNameCard: React.FC<ITableNameCardProps> = ({
  id,
  firstName,
  lastName,
  email,
  index,
  link,
  showEmailAsLink,
  showMailIcon,
}: ITableNameCardProps) => {
  const [isCopied, setIsCopied] = useState(false);
  const copyToClipboard = (text: string) => {
    navigator.clipboard.writeText(text);
    setIsCopied(true);
  };
  return (
    <Row className="lead-table__name user-name-card">
      <Col className="user-name-card__name">
        <div key={index}>
          <Col span={24} className="initial_capital">
            {link ? (
              <Link to={`/contact/detail/${id}/activities`}>
                <Text className="text-primary-color text-ellipsis">{`${firstName} ${lastName}`}</Text>
              </Link>
            ) : (
              <Col>
                <Text>{`${firstName} ${lastName}`}</Text>
              </Col>
            )}
          </Col>
          <Row className="email_div">
            <Col>
              {showEmailAsLink === true ? (
                <>
                  {showMailIcon && (
                    <a href={`mailto:${email}`}>
                      <MailOutlined className="table-mail-icon" />
                    </a>
                  )}
                  <small>
                    <a className="text-primary-color" href={`mailto:${email}`}>
                      {email?.toLowerCase()}
                    </a>
                  </small>
                </>
              ) : (
                <small className="text-primary-color">
                  {email?.toLowerCase()}
                </small>
              )}
            </Col>
            {showEmailAsLink && (
              <Col className="hide">
                <Tooltip
                  className=" margin-left-1 "
                  placement="right"
                  color={isCopied ? '#8EAF74' : '#9B9D9A '}
                  title={
                    isCopied ? `${TEXT.COPIED_SUCCESS}` : `${TEXT.COPY_EMAIL}`
                  }
                >
                  <LinkOutlined
                    className="copy_email_icon"
                    onClick={() => copyToClipboard(`${email}`)}
                  />
                </Tooltip>
              </Col>
            )}
          </Row>
        </div>
      </Col>
    </Row>
  );
};
const TableUserNameCard: React.FC<IClientTableNameCardProps> = ({
  index,
  firstName,
  lastName,
  email,
  id,
}: IClientTableNameCardProps) => {
  return (
    <UserNameCard
      index={index}
      id={id}
      firstName={firstName}
      lastName={lastName}
      email={email}
      link={`/contact/detail/${id}/activities`}
      showEmailAsLink={true}
      showMailIcon={true}
    />
  );
};

export { TableUserNameCard };
