import axios from './axios';
import { IResponse, Role } from '@shared-components/models';
import { API_URL, CRM_URL } from '@moxie/constants';
import crmAxios from './crm-api-axios';

const getTenantRoles = async (): Promise<IResponse> => {
  return axios.get(`${API_URL.CRM_ROLE}`);
};

const getAllRoles = async () => crmAxios.get<{ data: Role[] }>(CRM_URL.ROLE);

export { getTenantRoles, getAllRoles };
