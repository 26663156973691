import React, { useEffect, useState } from 'react';
import { Col, Row, Menu, Typography } from 'antd';
import { useHistory } from 'react-router-dom';
import { IPageHeaderMenuProps } from '@shared-components/models';

const { Text } = Typography;

const PageHeaderMenu: React.FC<IPageHeaderMenuProps> = ({
  menu,
  children,
  defaultActiveKey,
}: IPageHeaderMenuProps) => {
  const history = useHistory();
  const [current, setCurrent] = useState(defaultActiveKey || menu[0].id);

  useEffect(() => {
    if (defaultActiveKey) {
      if (defaultActiveKey !== current) setCurrent(defaultActiveKey);
    }
  }, [defaultActiveKey, current]);

  const handleChange = (e: any) => {
    const selected = menu.find((item) => item.id === e.key);
    setCurrent(e?.key);
    if (selected?.url) history.push(`/${selected?.url}`);
  };

  return (
    <Row className="page-header">
      <Col className="page-header__menu">
        <Menu onClick={handleChange} selectedKeys={[current]} mode="horizontal">
          {(menu || []).map((menuItem) => {
            switch (menuItem.id) {
              case 'institutions':
                return (
                  <Menu.Item key={menuItem.id}>
                    <Text strong>{menuItem.name}</Text>
                  </Menu.Item>
                );

              default:
                return;
            }
          })}
        </Menu>
      </Col>
      <Col className="page-header__children">{children && children}</Col>
    </Row>
  );
};

export { PageHeaderMenu };
