import { put, takeLatest } from 'redux-saga/effects';
import { AxiosResponse } from 'axios';
import { IAction, IUser } from '@shared-components/models';
import { errorHandler } from '@moxie/utils';
import {
  UserList,
  UserData,
  UserAdd,
  updateUserData,
} from '@admin/services.api';
import * as actionTypes from './admin-user.constant';
import * as action from './admin-user.action';
import { successNotificationHandler } from '@moxie/shared';

const getAdminUsers = function* () {
  try {
    const res: AxiosResponse<any> = yield UserList('');
    yield put(action.fetchAdminUsersSuccess(res?.data?.data));
  } catch (e) {
    errorHandler(e);
    yield put(action.fetchAdminUsersFailure(e));
  }
};

const getAdminUser = function* ({ payload }: IAction<string>) {
  if (payload) {
    try {
      const res: AxiosResponse = yield UserData(payload);
      yield put(action.fetchAdminUserSuccess(res.data.data));
    } catch (e) {
      errorHandler(e);
      yield put(action.fetchAdminUserFailure(e));
    }
  }
};

const addAdminUser = function* ({ payload }: IAction<IUser>) {
  try {
    if (payload) {
      const response: AxiosResponse = yield UserAdd(payload);
      yield put(action.addAdminUserSuccess(response.data.data));
      if (response) {
        successNotificationHandler(response.data.message);
      }
    }
  } catch (err) {
    errorHandler(err);
    yield put(action.addAdminUserFailure(err));
  }
};

const editAdminUser = function* ({ payload }: IAction<IUser>) {
  try {
    const response: AxiosResponse = yield updateUserData(
      payload?.id || '',
      payload || {}
    );
    yield put(action.updateAdminUserSuccess(response.data.data));
    if (response) {
      successNotificationHandler(response.data.message);
    }
  } catch (err) {
    errorHandler(err);
    yield put(action.updateAdminUserFailure(err));
  }
};

export default function* rootSage(): unknown {
  yield takeLatest(actionTypes.GET_USERS_REQUEST, getAdminUsers);
  yield takeLatest(actionTypes.GET_USER_REQUEST, getAdminUser);
  yield takeLatest(actionTypes.ADD_USER_REQUEST, addAdminUser);
  yield takeLatest(actionTypes.UPDATE_USER_REQUEST, editAdminUser);
}
