const ObjectToParams = (object: any): string => {
  let params = '';
  Object.keys(object).forEach((key) => {
    if (object[key]) {
      const value = object[key].toString();
      if (value.length > 0) {
        if (params.length === 0) params = params + `${key}=${object[key]}`;
        else params = params + `&${key}=${object[key]}`;
      }
    }
  });
  return params.replace(/\s+/g, ' ').trim();
};

export { ObjectToParams };
