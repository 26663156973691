const alias1 = 'LEAD_ACTIVITIES';
const alias2 = 'LEAD_ACTIVITY';

export const GET_CLIENT_ACTIVITIES_REQUEST = `GET_${alias1}_REQUEST`;
export const GET_CLIENT_ACTIVITIES_SUCCESS = `GET_${alias1}_SUCCESS`;
export const GET_CLIENT_ACTIVITIES_FAILURE = `GET_${alias1}_FAILURE`;
export const GET_CLIENT_ACTIVITIES_CLEAR = `GET_${alias1}_CLEAR`;

export const GET_CLIENT_ACTIVITY_REQUEST = `GET_${alias2}_REQUEST`;
export const GET_CLIENT_ACTIVITY_SUCCESS = `GET_${alias2}_SUCCESS`;
export const GET_CLIENT_ACTIVITY_FAILURE = `GET_${alias2}_FAILURE`;

export const ADD_CLIENT_ACTIVITY_REQUEST = `ADD_${alias2}_REQUEST`;
export const ADD_CLIENT_ACTIVITY_SUCCESS = `ADD_${alias2}_SUCCESS`;
export const ADD_CLIENT_ACTIVITY_FAILURE = `ADD_${alias2}_FAILURE`;

export const UPDATE_CLIENT_ACTIVITY_REQUEST = `UPDATE_${alias2}_REQUEST`;
export const UPDATE_CLIENT_ACTIVITY_SUCCESS = `UPDATE_${alias2}_SUCCESS`;
export const UPDATE_CLIENT_ACTIVITY_FAILURE = `UPDATE_${alias2}_FAILURE`;

export const DELETE_CLIENT_ACTIVITY_REQUEST = `DELETE_${alias2}_REQUEST`;
export const DELETE_CLIENT_ACTIVITY_SUCCESS = `DELETE_${alias2}_SUCCESS`;
export const DELETE_CLIENT_ACTIVITY_FAILURE = `DELETE_${alias2}_FAILURE`;
