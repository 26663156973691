import { IAction } from '@shared-components/models';
import { Product } from '../constants';

const localproductActions: any = {};

localproductActions.fetchProduct = (payload: string): IAction<string> => ({
  type: Product.FETCH_PRODUCT,
  payload,
});

localproductActions.clearfetchProduct = (): IAction<string> => ({
  type: Product.FETCH_PRODUCT_CLEAR,
});

localproductActions.loadingfetchProduct = (): IAction<string> => ({
  type: Product.FETCH_PRODUCT_LOADING,
});

localproductActions.errorfetchProduct = (): IAction<string> => ({
  type: Product.FETCH_PRODUCT_ERROR,
});

localproductActions.setProductFiltersActions = (): IAction<any> => ({
  type: Product.REQUEST_PRODUCT_FILTERS,
});

export { localproductActions };
