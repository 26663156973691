import {
  ASSIGNEE_LABEL,
  LABEL,
  SLUG_ARCHIVED,
  SLUG_RAW_LEADS,
  TEXT,
} from '@moxie/constants';
import { columns } from '../../lead/libs/lead-table-column';

const getColumns = (value: string, name: string) => {
  const returnColumns: any = [];
  columns.forEach((item) => {
    if (name !== SLUG_ARCHIVED) {
      if (name === SLUG_RAW_LEADS) {
        if (item.title !== LABEL.ARCHIVED_BY) {
          returnColumns.push(item);
        }
      } else if (item.title !== LABEL.ARCHIVED_BY) {
        returnColumns.push(item);
      }
    } else {
      if (name === SLUG_ARCHIVED && item.title !== ASSIGNEE_LABEL) {
        returnColumns.push(item);
      }
    }
  });
  if (value !== TEXT.CONTACTS) {
    returnColumns.splice(2, 1);
  }
  return returnColumns;
};

export { getColumns };
