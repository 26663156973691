/* eslint-disable no-unused-vars */
import React from 'react';
import {
  DashboardLayout,
  RoleLists,
  SettingsLayout,
} from '@shared-components/modules';
import { Route } from 'react-router-dom';
import PrefrencesSettings from './prefrences-settings';
import Can, { PermissionAction, PermissionObjects } from '@lyra/privilege';

const GeneralSettings: React.FC = () => {
  return (
    <DashboardLayout className="content_margin--fluid">
      <SettingsLayout>
        <Route
          exact
          path="/dashboard/settings/"
          component={PrefrencesSettings}
        />
        {/* {Can(PermissionAction.Read, PermissionObjects.Role) && ( */}
        <Route exact path="/dashboard/settings/roles" component={RoleLists} />
        {/* )} */}
        <Route
          exact
          path="/dashboard/settings/preferences"
          component={PrefrencesSettings}
        />
      </SettingsLayout>
    </DashboardLayout>
  );
};

export default GeneralSettings;
