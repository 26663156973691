import { Col, Row } from 'antd/es/grid';
import Select from 'antd/es/select';
import React, { useContext } from 'react';
import { ProductFilterContext } from '../provider/product-filter-provider';
import { useAppSelector } from '@crm/core';
import Spin from 'antd/es/spin';
import Search from 'antd/es/input/Search';
import { TEXT } from '@moxie/constants';
import { useCanAccess } from '@crm/libs/privilege/useCanAccess';
import Button from 'antd/es/button';
import PlusOutlined from '@ant-design/icons/PlusOutlined';
import { SearchBox } from 'libs/shared/src/crm-modules/shared';
import { getInstitutions } from '@crm/services.api';

interface Props {
  showDrawer: (id?: string) => void;
}
const ProductToolbar: React.FC<Props> = ({ showDrawer }) => {

  const services = useAppSelector((state) => state.services.allData);
  const servicesLoading = useAppSelector((state) => state.services.loading);
  const canAccess = useCanAccess();
  const {
    handleServiceChange,
    selectedService,
    onCountryChange,
    selectedCountry,
    countriesLoading,
    countries,
    onSearch,
    searchInput,
    onSearchChange,
    institutions,
    onInstitutionChange,
  } = useContext(ProductFilterContext);

  return (
    <Row justify="space-between">
      <Col span={20}>
        <Row style={{ 'columnGap': '5px' }}>
          <Col xs={4}>
            <Select
              onChange={handleServiceChange}
              className="margin-right-1 initial_capital full-width"
              showSearch
              optionFilterProp="search_prop"
              placeholder="Select service"
              value={servicesLoading ? 'Loading..' : selectedService}
            >
              {servicesLoading ? (
                <Select.Option disabled value={''}>
                  <Spin spinning className="full-width" size="small" />
                </Select.Option>
              ) : (
                services?.map((service: any, i: number) => (
                  <Select.Option
                    className="initial_capital"
                    value={service.id}
                    key={i}
                  >
                    {service.name}
                  </Select.Option>
                ))
              )}
            </Select>
          </Col>
          <Col xs={4}>
            <Select
              className="full-width"
              onChange={(value) => onCountryChange(value)}
              showSearch
              optionFilterProp="search_prop"
              placeholder="Select country"
              value={selectedCountry}
              loading={countriesLoading}
            >
              {countriesLoading ? (
                <Select.Option disabled value={''}>
                  <Spin spinning className="full-width" size="small" />
                </Select.Option>
              ) : (
                countries?.map((country: any, i: number) => (
                  <Select.Option value={country} key={i}>
                    {country}
                  </Select.Option>
                ))
              )}
            </Select>
          </Col>
          <Col xs={6}>
            <SearchBox
              className="full-width"
              value={institutions}
              defaultOpen={false}
              mode="multiple"
              allowClear
              onClear={() => onInstitutionChange([])}
              fetchQuery={(params) => getInstitutions({ ...params, type: 'search', sortBy: 'name:ASC' })}
              filter={{
                'filter.serviceId': selectedService
              }}
              labelKey="name"
              valueKey="id"
              placeholder="Search institutions"
              maxTagCount={1}
              maxTagTextLength={15}
              onChange={onInstitutionChange}
            />
          </Col>
          <Col xs={9}>
            <Search
              data-testid="productsearch"
              placeholder="Search Products"
              className="product_search full-width"
              onSearch={onSearch}
              value={searchInput}
              onChange={(e) => onSearchChange(e.target.value)}
              allowClear
            />
          </Col>
        </Row>
      </Col>
      {canAccess('product', 'create') && <Button
        type="primary"
        data-testid="crm-addproductbutton"
        onClick={() => showDrawer()}
      >
        <PlusOutlined /> {TEXT.ADD_PRODUCT}
      </Button>}
    </Row>
  )
}

export default ProductToolbar;
