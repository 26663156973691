import { Checkbox, Form, Input, Col, Typography, Row } from 'antd';
import React from 'react';
import { FormItem } from '../../../elements/form';
import {
  EMAIL_LABEL,
  FORM_SIZE,
  PASSWORD_LABEL,
  REMEMBER_ME_LABEL,
  PASSWORD_REQUIRED,
  FORGET_PASSWORD_LABEL,
  regex,
  AUTH_MESSAGE,
} from '@moxie/constants';
import { UserOutlined, LockOutlined } from '@ant-design/icons';
import { Link } from 'react-router-dom';
import { Button } from '@shared-components/elements';
import { URL_FORGET_PASSWORD } from '@moxie/constants';
import { ILogin, ILoginForm } from '@shared-components/models';

const { Paragraph } = Typography;
const LoginForm: React.FC<ILoginForm<ILogin>> = ({
                                               onSubmit,
                                             }: ILoginForm<ILogin>) => {
  const handleSubmit = (data: ILogin) => {
    data.email = data?.email?.replace(/ /g, '');
    onSubmit(data);
  };
  return (
    <Form
      layout="vertical"
      size={FORM_SIZE}
      name="login-form"
      data-testid="login-form"
      className="auth-layout__form"
      onFinish={handleSubmit}
    >
      <div className="header">
        <FormItem
          name="email"
          className="lyra-label"
          label={EMAIL_LABEL}
          rules={[
            {
              required: true,
              pattern: new RegExp(regex.EMAIL),
              message: AUTH_MESSAGE.INVALID_EMAIL,
            },
          ]}
        >
          <Input
            autoFocus
            prefix={<UserOutlined className="site-form-item-icon" />}
          ></Input>
        </FormItem>
        <FormItem
          name="password"
          label={PASSWORD_LABEL}
          className="lyra-label"
          rules={[{ required: true, message: PASSWORD_REQUIRED }]}
        >
          <Input.Password
            prefix={<LockOutlined></LockOutlined>}
          ></Input.Password>
        </FormItem>

        <FormItem name="remember_me">
          <Checkbox checked>{REMEMBER_ME_LABEL}</Checkbox>
        </FormItem>
      </div>
      <Row className="footer">
        <Col span={24}>
          <Button type="primary" block htmlType="submit">
            Log In
          </Button>
        </Col>
        <Col span={24} className="padding-top-2 text-align-center">
          <Link to={URL_FORGET_PASSWORD}>
            <Paragraph type="secondary">{FORGET_PASSWORD_LABEL}</Paragraph>
          </Link>
        </Col>
      </Row>
    </Form>
  );
};

export { LoginForm };
