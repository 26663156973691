import React from 'react';
import { List, Typography } from 'antd';
import { TEXT } from '@moxie/constants';
import { IAppointmentNotesList } from '@shared-components/models';
import { AppointmentDetailUtil } from './appointment-detail-utils';

const AppointmentNoteList: React.FC<IAppointmentNotesList> = ({
  list,
}: IAppointmentNotesList) => {
  return (
    <>
      {list && (
        <>
          <Typography.Title level={5}>{TEXT.PREVIOUS_NOTES}</Typography.Title>
          <List
            itemLayout="horizontal"
            dataSource={list}
            renderItem={(item, index) => {
              return (
                <List.Item>
                  <List.Item.Meta
                    title={item?.title}
                    description={
                      <AppointmentDetailUtil
                        index={index}
                        count={260}
                        description={item.description}
                      />
                    }
                  />
                </List.Item>
              );
            }}
          />
        </>
      )}
    </>
  );
};

export default AppointmentNoteList;
