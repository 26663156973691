import {
  CONFIRM_MESSAGE,
  DELETE_BTN,
  PAGE_HEADER_MENU_SERVICES,
  TEXT,
} from '@moxie/constants';
import { PageHeaderTitle, popConfirm } from '@shared-components/elements';
import React, { useState } from 'react';
import { ServiceDrawer } from './service-drawer';
import { useForm } from 'antd/lib/form/Form';
import { ServiceTableList } from './service-table-list';
import { getOneService, getServiceList } from '@crm/services.api';
import { useDispatch } from 'react-redux';
import { serviceActions } from '@crm/core';
import { ImportServiceDrawer } from './import-service-drawer';
import { PageHeaderMenu } from '../../shared';
import { crmErrorHandler } from '../../shared';

const ServiceTable: React.FC = () => {
  const [form] = useForm();
  const dispatch = useDispatch();
  const [serviceFormVisible, setServiceFormVisible] = useState(false);
  const [importServiceVisible, setImportServiceVisible] = useState(false);
  const [initialData, setInitialData] = useState({});
  const [isEdit, setIsEdit] = useState(false);
  const [disabled, setDisabled] = useState(true);
  const [importDisabled, setImportDisabled] = useState(true);
  const [refreshTable, setRefreshTable] = useState(true);

  const getData = async (params: Record<string, unknown>) => {
    return getServiceList(params);
  };

  const handleClose = () => {
    setServiceFormVisible(false);
    setIsEdit(false);
    setDisabled(true);
    form.resetFields();
  };

  const triggerRefresh = () => {
    setRefreshTable((prev) => !prev);
  };

  const handleFormDrawerVisible = async (id?: any) => {
    if (typeof id === 'string') {
      try {
        setIsEdit(true);
        const response = await getOneService(id);
        setInitialData(response.data.data);
      } catch (err) {
        crmErrorHandler(err);
      }
    } else {
      setInitialData({});
      setIsEdit(false);
    }
    setServiceFormVisible(true);
    setDisabled(true);
  };
  const handleDelete = async (id: string) => {
    popConfirm(DELETE_BTN, `${CONFIRM_MESSAGE.DELETE_SERVICE}`, async () => {
      try {
        dispatch(serviceActions.deleteServiceRequest(id, triggerRefresh));
      } catch (err) {
        crmErrorHandler(err);
      }
    });
  };

  const handleImportClose = async () => {
    setImportServiceVisible(false);
    setSelectedService([]);
  };
  const handleImportVisible = () => {
    setImportServiceVisible(true);
    setDisabled(true);
  };
  const [selectedService, setSelectedService] = useState([]);
  const onChangeSelected = async (data: any) => {
    setSelectedService(data);
    data.length > 0 ? setImportDisabled(false) : setImportDisabled(true);
  };
  const handleImportSubmit = () => {
    try {
      dispatch(
        serviceActions.syncServiceRequest(selectedService, triggerRefresh)
      );
      setImportServiceVisible(false);
      setImportDisabled(true);
    } catch (err) {
      crmErrorHandler(err);
    }
  };

  return (
    <div className="full-height">
      <PageHeaderTitle title={TEXT.SERVICES} />
      <PageHeaderMenu menu={PAGE_HEADER_MENU_SERVICES} />

      <ServiceDrawer
        form={form}
        visible={serviceFormVisible}
        setVisible={setServiceFormVisible}
        onClose={handleClose}
        disabled={disabled}
        setDisabled={setDisabled}
        setRefreshTable={setRefreshTable}
        initialData={initialData}
        isEdit={isEdit}
        handleClose={handleClose}
      />

      <ImportServiceDrawer
        visible={importServiceVisible}
        onClose={handleImportClose}
        setVisible={setImportServiceVisible}
        onSubmit={handleImportSubmit}
        onChangeSelected={onChangeSelected}
        disabled={importDisabled}
      />

      <ServiceTableList
        refreshTable={refreshTable}
        getData={getData}
        handleFormVisible={handleFormDrawerVisible}
        handleImportVisible={handleImportVisible}
        deleteData={handleDelete}
      />
    </div>
  );
};

export { ServiceTable };
