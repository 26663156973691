import {
  IAction,
  ILead,
  ILeadApplication,
  ILeadApplicationForm,
  IPayload,
  IUser,
} from '@shared-components/models';
import { Socket } from 'socket.io-client';
import * as actionTypes from './application.constant';

export const addRequest = (
  payload: ILeadApplicationForm,
  triggerRefresh: any
): IAction<ILeadApplicationForm> => ({
  type: actionTypes.ADD_APPLICATION_REQUEST,
  payload,
  triggerRefresh,
});

export const addSuccess = (
  payload: ILeadApplication
): IAction<ILeadApplication> => ({
  type: actionTypes.ADD_APPLICATION_SUCCESS,
  payload,
});

export const addFailure = (payload: IPayload<ILeadApplication>): IAction => ({
  type: actionTypes.ADD_APPLICATION_FAILURE,
  payload,
});

export const addCommentRequest = (payload: { id: string }): IAction<any> => ({
  type: actionTypes.ADD_APPLICATION_COMMENT_REQUEST,
  payload,
});

export const addCommentSuccess = (
  payload: ILeadApplication
): IAction<ILeadApplication> => ({
  type: actionTypes.ADD_APPLICATION_COMMENT_SUCCESS,
  payload,
});

export const addCommentFailure = (
  payload: IPayload<ILeadApplication>
): IAction => ({
  type: actionTypes.ADD_APPLICATION_COMMENT_FAILURE,
  payload,
});

export const getCommentRequest = (id: string): IAction<any> => ({
  type: actionTypes.GET_APPLICATION_COMMENT_REQUEST,
  payload: id,
});

export const getCommentSuccess = (payload: any[]): IAction<any[]> => ({
  type: actionTypes.GET_APPLICATION_COMMENT_SUCCESS,
  payload,
});

export const getCommentFailure = (payload: IPayload<any>): IAction => ({
  type: actionTypes.GET_APPLICATION_COMMENT_FAILURE,
  payload,
});

export const updateRequest = (
  payload: ILeadApplication | any,
  user?: IUser | null,
  socket?: Socket | null,
  lead?: ILead,
  update_type?: string,
  admin_assignees?: string[]
): IAction<ILeadApplication> => ({
  type: actionTypes.UPDATE_APPLICATION_REQUEST,
  payload,
  user,
  socket,
  lead,
  update_type,
  admin_assignees,
});

export const updateSuccess = (
  payload: ILeadApplication
): IAction<ILeadApplication> => ({
  type: actionTypes.UPDATE_APPLICATION_SUCCESS,
  payload,
});

export const updateFailure = (
  payload: IPayload<ILeadApplication>
): IAction => ({
  type: actionTypes.UPDATE_APPLICATION_FAILURE,
  payload,
});

export const getAllRequest = (): IAction<ILeadApplication> => ({
  type: actionTypes.GET_APPLICATIONS_REQUEST,
});

export const getAllSuccess = (
  payload: ILeadApplication[]
): IAction<ILeadApplication[]> => ({
  type: actionTypes.GET_APPLICATIONS_SUCCESS,
  payload,
});

export const getAllFailure = (
  payload: IPayload<ILeadApplication>
): IAction => ({
  type: actionTypes.GET_APPLICATIONS_FAILURE,
  payload,
});

export const getOneRequest = (id: string): IAction<string> => ({
  type: actionTypes.GET_APPLICATION_REQUEST,
  payload: id,
});

export const getOneSuccess = (
  payload: ILeadApplication
): IAction<ILeadApplication> => ({
  type: actionTypes.GET_APPLICATION_SUCCESS,
  payload,
});

export const getOneFailure = (
  payload: IPayload<ILeadApplication>
): IAction => ({
  type: actionTypes.GET_APPLICATION_FAILURE,
  payload,
});

export const clearApplication = (): IAction<string> => ({
  type: actionTypes.CLEAR_APPLICATION_FETCH,
});

export const deleteApplicationRequest = (id: string): IAction<string> => ({
  type: actionTypes.DELETE_APPLICATION_REQUEST,
  payload: id,
});

export const deleteApplicationSuccess = (payload: any): IAction => ({
  type: actionTypes.DELETE_APPLICATION_SUCCESS,
  payload,
});

export const deleteApplicationFailure = (
  payload: IPayload<ILeadApplication>
): IAction => ({
  type: actionTypes.DELETE_APPLICATION_FAILURE,
  payload,
});
