import { IAction, IProductComparison } from '@shared-components/models';
import { AxiosResponse } from 'axios';
import { productServices } from '@lyra/services.api';
import { put, takeLatest } from '@redux-saga/core/effects';
import { errorHandler } from '@moxie/utils';
import * as actionProductComparison from '../product-comparison/product-comparison.action';
import * as actionTypes from '../product-comparison/product-comparison.constant';

function* addProductComparison({ payload }: IAction<IProductComparison>) {
  try {
    if (payload) {
      const response: AxiosResponse = yield productServices.apiAddProductComparison(
        payload
      );
      yield put(
        actionProductComparison.addProductComparisonSuccess(response.data.data)
      );
    }
  } catch (err) {
    errorHandler(err);
    yield put(actionProductComparison.addProductComparisonFailure(err));
  }
}

export default function* rootSage(): unknown {
  yield takeLatest(
    actionTypes.ADD_PRODUCT_COMPARISON_REQUEST,
    addProductComparison
  );
}
