import { NoteSummarizeResponse } from "@model/crm/note.model";
import crmAxios from "./crm-api-axios";


export const summarizeNote = async (system: string, prompt: string) => {
  const { data } = await crmAxios.post<NoteSummarizeResponse>('/v1/text-enhancer/action-plan', {
    system,
    prompt,
  });

  return data.data.choices[0].message.content;
}
