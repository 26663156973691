const alias = 'LANGUAGE_TEST_SCORE';

export const GET_LANGUAGE_TEST_SCORES_REQUEST = `GET_${alias}S_REQUEST`;
export const GET_LANGUAGE_TEST_SCORES_SUCCESS = `GET_${alias}S_SUCCESS`;
export const GET_LANGUAGE_TEST_SCORES_FAILURE = `GET_${alias}_FAILURE`;
export const GET_LANGUAGE_TEST_SCORES_CLEAR = `GET_${alias}S_CLEAR`;

export const GET_LANGUAGE_TEST_SCORE_REQUEST = `GET_${alias}_REQUEST`;
export const GET_LANGUAGE_TEST_SCORE_SUCCESS = `GET_${alias}_SUCCESS`;
export const GET_LANGUAGE_TEST_SCORE_FAILURE = `GET_${alias}_FAILURE`;

export const ADD_LANGUAGE_TEST_SCORE_REQUEST = `ADD_${alias}_REQUEST`;
export const ADD_LANGUAGE_TEST_SCORE_SUCCESS = `ADD_${alias}_SUCCESS`;
export const ADD_LANGUAGE_TEST_SCORE_FAILURE = `ADD_${alias}_FAILURE`;

export const UPDATE_LANGUAGE_TEST_SCORE_REQUEST = `UPDATE_${alias}_REQUEST`;
export const UPDATE_LANGUAGE_TEST_SCORE_SUCCESS = `UPDATE_${alias}_SUCCESS`;
export const UPDATE_LANGUAGE_TEST_SCORE_FAILURE = `UPDATE_${alias}_FAILURE`;

export const DELETE_LANGUAGE_TEST_SCORE_REQUEST = `DELETE_${alias}_REQUEST`;
export const DELETE_LANGUAGE_TEST_SCORE_SUCCESS = `DELETE_${alias}_SUCCESS`;
export const DELETE_LANGUAGE_TEST_SCORE_FAILURE = `DELETE_${alias}_FAILURE`;
