import { useAppSelector } from '@admin/core';
import {
  applicationActions,
  interestedServiceActions,
  leadActions,
} from '@lyra/core';
import { productServices } from '@lyra/services.api';
import {
  ADD_BTN,
  CANCEL_BTN,
  DRAWER_WIDTH,
  GUTTER,
  LABEL,
  PLACEHOLDER,
  PRODUCT_ACTIONS,
  START_BTN,
  STATUS,
  TEXT,
} from '@moxie/constants';
import { errorHandler } from '@moxie/utils';
import {
  DrawerElem,
  Option,
  SearchableSelect,
} from '@shared-components/elements';
import {
  IInstitutionBranch,
  ISelectProductWorkflowDrawer,
  IWorkflow,
} from '@shared-components/models';
import { Button, Col, Form, Row, Select, Skeleton, Space, Spin } from 'antd';
import { useForm } from 'antd/lib/form/Form';
import { AxiosResponse } from 'axios';
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';

const SelectProductWorkflowDrawer: React.FC<ISelectProductWorkflowDrawer> = ({
  isOpen,
  onClose,
  productId,
  actionType,
}: ISelectProductWorkflowDrawer) => {
  const [form] = useForm();
  const { id }: { id: string } = useParams();
  const dispatch = useDispatch();
  const [loading, setLoading] = useState(true);
  const [submitted, setSubmitted] = useState(false);
  const [institutionBranchList, setInstitutionBranchList] = useState<
    IInstitutionBranch[]
  >();
  const [children, setChildren] = useState<JSX.Element[]>([]);

  const status: string = useSelector((store: any) => {
    return store.interested_service.status;
  });
  const applicationStatus: string = useSelector((store: any) => {
    return store.application.status;
  });
  const socket = useAppSelector((state) => state.socket.wss);
  const leadData = useAppSelector((store) => store.lead.lead);
  const user = useAppSelector((store) => store.auth.user);

  useEffect(() => {
    if (status === STATUS.FAILED || applicationStatus === STATUS.FAILED)
      handleSubmitFail();
    else if (
      status === STATUS.PRODUCT_SUCCEEDED ||
      applicationStatus === STATUS.SUCCEEDED
    )
      handleSuccess();
  }, [status, applicationStatus]);

  const handleSubmitFail = () => {
    if (actionType === PRODUCT_ACTIONS.START_APPLICATION) {
      dispatch(applicationActions.setIdleApplication());
    } else {
      dispatch(interestedServiceActions.setIdleInterestedServices());
    }
    setSubmitted(false);
  };

  const handleSuccess = () => {
    if (actionType === PRODUCT_ACTIONS.START_APPLICATION) {
      dispatch(applicationActions.setIdleApplication());
    } else {
      dispatch(interestedServiceActions.setIdleInterestedServices());
    }
    onClose();
    setSubmitted(false);
    dispatch(leadActions.fetchLead(id));
    // if (leadData?.status === CONTACT_STATUS.LEAD) {
    //   const name = leadData?.first_name + ' ' + leadData?.last_name;
    //   successNotificationHandler(
    //     name + LEAD_CONVERSION_MESSAGE.LEAD_TO_PROSPECT
    //   );
    // }
  };

  useEffect(() => {
    if (productId && productId.length > 1) {
      handleFetchWorkflows(productId);
    }
  }, [productId]);

  const handleFetchWorkflows = async (productId: string) => {
    productServices
      .apiGetProductWorkflows(productId)
      .then((res: AxiosResponse<any>) => {
        if (res?.data?.data) {
          const options = res.data.data.map((item: IWorkflow) => {
            return (
              <Select.Option value={item.id || ''} key={item.id}>
                <div className="product-workflow-options">
                  <b>{item.workflowType?.name}</b> &bull; {item.country}
                  <small>{item.workflowType?.service?.name}</small>
                </div>
              </Select.Option>
            );
          });
          setChildren([...options]);
        }
        setLoading(false);
      })
      .catch((err) => {
        errorHandler(err);
        setLoading(false);
      });
    const response = await productServices.apiGetProductBranches(productId);
    setInstitutionBranchList(response?.data?.data);
  };

  const onFinish = (e: {
    workflow_id: string;
    institution_branch_id: string;
  }) => {
    setSubmitted(true);
    if (actionType === PRODUCT_ACTIONS.START_APPLICATION) {
      const saveData = {
        contact_id: id,
        product_id: productId,
        institution_branch_id: e?.institution_branch_id || '',
        workflow_id: e?.workflow_id || '',
      };
      dispatch(applicationActions.addRequest(saveData, user, socket, leadData));
    } else {
      dispatch(
        interestedServiceActions.addProductAsInterestedServiceRequest({
          productId,
          workflowId: e?.workflow_id,
          institutionBranchId: e?.institution_branch_id,
          leadData,
          socket,
          user,
        })
      );
      dispatch(interestedServiceActions.fetchInterestedServiceRequest(id));
    }
    onClose();
    form.resetFields();
  };

  const handleDoneBtn = () => {
    form.submit();
  };

  const handleCancelBtn = () => {
    onClose();
    form.resetFields();
  };

  useEffect(() => {
    if (leadData === null || JSON.stringify(leadData) === '{}')
      dispatch(leadActions.fetchLead(id));
  }, []);

  return (
    <DrawerElem
      title={
        actionType === PRODUCT_ACTIONS.START_APPLICATION
          ? TEXT.START_APPLICATION
          : TEXT.ADD_INTERESTED_SERVICE
      }
      width={DRAWER_WIDTH}
      visible={isOpen}
      onClose={handleCancelBtn}
      footer={
        <div className="padding-bottom-2 text-align-right">
          <Space direction="horizontal">
            <Button htmlType="button" onClick={handleCancelBtn}>
              {CANCEL_BTN}
            </Button>
            <Button
              htmlType="button"
              onClick={handleDoneBtn}
              type="primary"
              disabled={submitted}
            >
              {actionType === PRODUCT_ACTIONS.START_APPLICATION
                ? START_BTN
                : ADD_BTN}
            </Button>
          </Space>
        </div>
      }
    >
      {loading ? (
        <Skeleton paragraph={{ rows: 1 }} active />
      ) : (
        <Spin spinning={submitted}>
          <Form layout="vertical" form={form} onFinish={onFinish}>
            <Row gutter={GUTTER}>
              <Col span={14}>
                <Form.Item
                  label={LABEL.WORKFLOW}
                  name="workflow_id"
                  rules={[{ required: true }]}
                >
                  <Select placeholder={PLACEHOLDER.SELECT_WORKFLOW} showSearch>
                    {children}
                  </Select>
                </Form.Item>
              </Col>
              <Col span={10}>
                <Form.Item
                  name="institution_branch_id"
                  label={LABEL.BRANCH}
                  rules={[{ required: true }]}
                >
                  <SearchableSelect
                    optionFilter="search_prop"
                    placeholder={PLACEHOLDER.SELECT_BRANCH}
                    showSearch
                  >
                    {institutionBranchList &&
                      institutionBranchList.map((item: IInstitutionBranch) => {
                        return (
                          <Option
                            value={item?.id}
                            key={item?.id}
                            search_prop={item?.name}
                          >
                            {item?.name}
                          </Option>
                        );
                      })}
                  </SearchableSelect>
                </Form.Item>
              </Col>
            </Row>
          </Form>
        </Spin>
      )}
    </DrawerElem>
  );
};

export { SelectProductWorkflowDrawer };
