/* eslint-disable react-hooks/rules-of-hooks */

import React, { useEffect, useState } from 'react';
import { Spin } from 'antd';
import { ICompany, ISearchableSelect, IUser } from '@shared-components/models';
import { Option, UnassignedCard, UserNameCard } from '@moxie/shared';
import { RootState } from 'apps/lyra/src/core/store';
import { getAllCompanies } from '@lyra/services.api';
import { UNASSIGNED_LABEL } from '@moxie/constants';
import { useAppSelector } from '@lyra/core';
import { SearchableSelect } from '../form';

const UserSelectBox: React.FC<ISearchableSelect> = ({
  showUnassigned,
  ...props
}: ISearchableSelect) => {
  const userList = props.users
    ? props.users
    : // eslint-disable-next-line react-hooks/rules-of-hooks
      useAppSelector((store: RootState) => store.users.allData);

  return (
    <SearchableSelect
      {...props}
      optionLabelProp="label"
      optionFilter="search_prop"
    >
      {!userList ? (
        <Option value="null">
          <Spin spinning />
        </Option>
      ) : (
        <>
          {!showUnassigned && (
            <Option
              value="null"
              label={UNASSIGNED_LABEL}
              disabled={!props.selected_id}
            >
              <UnassignedCard />
            </Option>
          )}
          {userList?.map((user: IUser, index: number) => {
            return (
              <Option
                disabled={user.disabled || user.id === props.selected_id}
                value={user.id || ''}
                key={user.id || index}
                label={`${user.first_name} ${user.last_name}`}
                search_prop={`${user.first_name} ${user.last_name} ${user.email}`}
                className="padding-left-1"
              >
                <UserNameCard
                  firstName={user.first_name || ''}
                  lastName={user.last_name}
                  email={user.email || ''}
                  index={user.id || ''}
                />
              </Option>
            );
          })}
        </>
      )}
    </SearchableSelect>
  );
};

const CompanySelectBox: React.FC<ISearchableSelect> = ({
  ...props
}: ISearchableSelect) => {
  const [companyList, setCompanyList] = useState<ICompany[]>();

  useEffect(() => {
    getAllCompanies().then((response) => {
      setCompanyList(response.data.data);
    });
    return;
  }, []);

  return (
    <SearchableSelect
      {...props}
      optionLabelProp="label"
      optionFilter="search_prop"
    >
      {companyList &&
        companyList.map((item: ICompany) => {
          return (
            <Option
              key={item?.id}
              label={item?.company_name}
              value={item.id}
              search_prop={item.company_name}
            >
              {item?.company_name}
            </Option>
          );
        })}
    </SearchableSelect>
  );
};
export { UserSelectBox, CompanySelectBox };
