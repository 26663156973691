import {
  IAction,
  IInstitutionDetail,
  IInstitutionPayload,
} from '@shared-components/models';
import {
  GET_COUNTRY_BY_SERVICE_FAILURE,
  GET_COUNTRY_BY_SERVICE_REQUEST,
  GET_COUNTRY_BY_SERVICE_RESET,
  GET_COUNTRY_BY_SERVICE_SUCCESS,
} from './country-service.constant';

export const getCountryByServiceRequest = (id: string, triggerRefresh?:(countries:string[] ) => void): IAction<string> => ({
  type: GET_COUNTRY_BY_SERVICE_REQUEST,
  payload: id,
  triggerRefresh
});

export const getCountryByServiceSuccess = (
  payload: IInstitutionDetail
): IAction<IInstitutionDetail> => ({
  type: GET_COUNTRY_BY_SERVICE_SUCCESS,
  payload,
});

export const getCountryByServiceFailure = (
  payload: IInstitutionPayload
): IAction => ({
  type: GET_COUNTRY_BY_SERVICE_FAILURE,
  payload,
});

export const resetState = () => ({
  type:GET_COUNTRY_BY_SERVICE_RESET
})
