import {
  CANCEL_BTN,
  CONFIRM_NEW_PASSWORD,
  CONFIRM_PASSWORD_DIDNT_MATCH,
  GUTTER,
  NEW_PASSWORD,
  CURRENT_PASSWORD,
  PAGE_HEADER_MENU_CHANGE_PASSWORD,
  SAVE_BTN,
  TEXT,
  CURRENT_PASSWORD_REQUIRED,
  NEW_PASSWORD_REQUIRED,
  CONFIRM_NEW_PASSWORD_REQUIRED,
  CRM_COOKIE_ACCESS,
  CRM_COOKIE_REFRESH,
} from '@moxie/constants';
import { useForm } from 'antd/lib/form/Form';
import { PageHeaderMenu, PageHeaderTitle } from '@shared-components/elements';
import { Button, Card, Col, Form, Input, Row, Spin } from 'antd';
import React, { useState } from 'react';
import { useHistory, useParams } from 'react-router-dom';
import { authActions, useAppSelector } from '@crm/core';
import { useDispatch } from 'react-redux';
import { useProfile } from '../dashboard-profile/dashboard-profile';
import { changeCRMAccountPasswordAPI } from '@crm/services.api';
import Cookies from 'js-cookie';
import { crmErrorHandler } from 'libs/shared/src/crm-modules/shared';

export interface IChangePassword {
  oldPassword: string;
  newPassword: string;
  confirmPassword?: string;
}

const ChangePasswordSettings: React.FC = () => {
  const { name } = useParams<{ name: string }>();
  const dispatch = useDispatch();
  const [form] = useForm();
  const [loading, setLoading] = useState(false);
  const [disabled, setDisabled] = useState(true);

  const history = useHistory();
  const statUser = useAppSelector((state) => state.auth.user);
  useProfile();

  const onSubmit = () => {
    form.submit();
  };

  const handleLogout = () => {
    Cookies.remove(CRM_COOKIE_ACCESS);
    Cookies.remove(CRM_COOKIE_REFRESH);
    dispatch(authActions.logout());
    history.push('/');
  };

  const handleFieldsChange = (allFields: string | any[]) => {
    for (const fields of allFields) {
      fields.errors.length <= 0 ? setDisabled(false) : setDisabled(true);
    }
  };

  const handleSubmit = async (data: IChangePassword) => {
    setDisabled(true);
    setLoading(true);

    delete data.confirmPassword;
    try {
      if (statUser?.id) {
        await changeCRMAccountPasswordAPI({
          oldPassword: data.oldPassword!,
          newPassword: data.newPassword!,
        });

        handleLogout();
      }
    } catch (err) {
      setLoading(false);
      setDisabled(false);
      crmErrorHandler(err);
    }
  };

  return (
    <>
      <PageHeaderTitle title={TEXT.CHANGE_PASSWORD} />
      <PageHeaderMenu
        menu={PAGE_HEADER_MENU_CHANGE_PASSWORD}
        defaultActiveKey={name}
      />
      <Spin spinning={loading}>
        <Card className="margin-top-2">
          <Row gutter={GUTTER}>
            <Col span={15}>
              <Form
                name="basic"
                form={form}
                layout="vertical"
                onFinish={handleSubmit}
                labelCol={{ span: 15 }}
                wrapperCol={{ span: 15 }}
                onFieldsChange={handleFieldsChange}
              >
                <Form.Item
                  label={CURRENT_PASSWORD}
                  name="oldPassword"
                  rules={[
                    {
                      required: true,
                      message: CURRENT_PASSWORD_REQUIRED,
                    },
                  ]}
                >
                  <Input.Password type="password" />
                </Form.Item>
                <Form.Item
                  label={NEW_PASSWORD}
                  name="newPassword"
                  required={true}
                  wrapperCol={{ span: 15 }}
                  rules={[
                    {
                      required: true,
                      message: NEW_PASSWORD_REQUIRED,
                    },
                  ]}
                >
                  <Input.Password type="password" />
                </Form.Item>
                <Form.Item
                  label={CONFIRM_NEW_PASSWORD}
                  name="confirmPassword"
                  required={true}
                  wrapperCol={{ span: 15 }}
                  dependencies={['newPassword']}
                  hasFeedback
                  rules={[
                    {
                      required: true,
                      message: CONFIRM_NEW_PASSWORD_REQUIRED,
                    },
                    ({ getFieldValue }) => ({
                      validator(_, value) {
                        if (!value || getFieldValue('newPassword') === value) {
                          return Promise.resolve();
                        }
                        return Promise.reject(
                          new Error(CONFIRM_PASSWORD_DIDNT_MATCH)
                        );
                      },
                    }),
                  ]}
                >
                  <Input.Password type="password" />
                </Form.Item>
                <Col span={15}>
                  <Row gutter={GUTTER} justify="end" wrap={true}>
                    <Col>
                      <Button htmlType="reset">{CANCEL_BTN}</Button>
                    </Col>
                    <Col>
                      <Button
                        onClick={onSubmit}
                        type="primary"
                        disabled={disabled}
                        htmlType="submit"
                      >
                        {SAVE_BTN}
                      </Button>
                    </Col>
                  </Row>
                </Col>
              </Form>
            </Col>
          </Row>
        </Card>
      </Spin>
    </>
  );
};

export default ChangePasswordSettings;
