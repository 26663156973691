import React from 'react';
import { LeadsForm } from '@shared-components/forms';
import { ILeadsFormAdd } from '@shared-components/models';

const LeadsFormComponent: React.FC<ILeadsFormAdd> = ({
  leadFormData,
  setLeadForm,
  isEdit,
  onChange,
  handleFile,
  form,
  handleFormSubmit,
  setDisabled,
}: ILeadsFormAdd) => {
  return (
    <LeadsForm
      form={form}
      handleFormSubmit={handleFormSubmit}
      leadForm={leadFormData}
      onChange={onChange}
      setLeadForm={setLeadForm}
      isEdit={isEdit}
      handleFile={handleFile}
      setDisabled={setDisabled}
    />
  );
};

export { LeadsFormComponent };
