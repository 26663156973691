import React, { useState } from 'react';
import { PageHeaderTitle } from '@shared-components/elements';
import {
  AM_PERMISSIONS,
  AM_ROLES,
  PAGE_HEADER_MENU_USER,
  USER_TITLE,
} from '@moxie/constants';
import { useForm } from 'antd/lib/form/Form';
import { UserData, UserList } from '@lyra/services.api';
import { errorHandler } from '@moxie/utils';
import { UserTable } from './user-table';
import { UserDrawer } from './user-drawer';
import { useAppSelector } from '@lyra/core';
import permit from '@lyra/permit';
import { capitalizeFirstLetter } from 'libs/shared/src/functions';
import { PageHeaderMenu } from '../components/page-header-menu';

const User: React.FC = () => {
  const [personalDetailsForm] = useForm();
  const [visible, setVisible] = useState(false);
  const [formData, setFormData] = useState({});
  const [editBool, setIsEdit] = useState(false);
  const [disabled, setDisabled] = useState(true);
  const [refreshTable, setRefreshTable] = useState(true);
  const [filter] = useState({});

  const triggerRefresh = () => setRefreshTable((prev) => !prev);

  const getUserList = async (str: string) => {
    return await UserList(str);
  };

  const rolePerms: string[] = useAppSelector(
    (state: any) => state?.auth?.user?.am_role?.permissions
  );

  const showDrawer = async (id?: string) => {
    if (id) {
      try {
        const response = await UserData(id ? id : '');
        const { data } = response.data;

        const formData = {
          id: data.id,
          first_name: data.first_name,
          last_name: data.last_name,
          email: data.email,
          phone: data.phone,
          address1: data.address1,
          address2: data.address2,
          country: data.country,
          time_zone: data.time_zone,
          role:
            data.am_role.name === AM_ROLES.SUPER_USER
              ? 'Super Admin'
              : capitalizeFirstLetter(
                  data.am_role.name.toLowerCase().split('_').join(' ')
                ),
          role_id: data.am_role.id,
          city_or_state: data.city_or_state,
          alternative_phone: data.alternative_phone,
          department: data.department,
          branch: data.branch.id,
        };

        setFormData(formData);
        setIsEdit(true);
        setVisible(true);
      } catch (error) {
        errorHandler(error);
      }
    } else {
      setFormData({});
      setVisible(true);
    }
  };

  const onClose = () => {
    setFormData({});
    personalDetailsForm.resetFields();
    setVisible(false);
    setIsEdit(false);
    setDisabled(true);
  };

  const saveData = async () => {
    personalDetailsForm.submit();
  };

  return (
    <div className="full-height">
      <PageHeaderTitle title={USER_TITLE} />
      <PageHeaderMenu menu={PAGE_HEADER_MENU_USER}>
        <UserDrawer
          formData={formData}
          edit={editBool}
          form={personalDetailsForm}
          visible={visible}
          onClose={onClose}
          saveData={saveData}
          triggerRefresh={triggerRefresh}
          setDisabled={setDisabled}
          disabled={disabled}
        />
      </PageHeaderMenu>
      <UserTable
        filterOptions={true}
        actionBar={permit([AM_PERMISSIONS.USERS.MANAGE], rolePerms)}
        showDrawer={showDrawer}
        getData={getUserList}
        filter={filter}
        refresh={refreshTable}
        hideBranch={false}
      />
    </div>
  );
};

export { User };
