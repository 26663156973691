import { IAction, IInstitutionDetail } from '@shared-components/models';
import * as actionTypes from './institution.constant';

export interface IInstitutionState {
  institutions: IInstitutionDetail[];
  institution: IInstitutionDetail;
  error: boolean | null;
  loading: boolean;
}
const initialInstitutionState: IInstitutionState = {
  institutions: [],
  institution: {},
  error: null,
  loading: false,
};
export const INSTITUTION_FEATURE_KEY = 'institutions';

export const institutionReducer = (
  state = initialInstitutionState,
  action: IAction<any>
): any => {
  switch (action.type) {
    case actionTypes.GET_INSTITUTIONS_REQUEST:
      return { ...state, loading: true };
    case actionTypes.GET_INSTITUTIONS_SUCCESS:
      return {
        ...state,
        loading: false,
        error: null,
        institutions: [...action.payload],
      };
    case actionTypes.GET_INSTITUTIONS_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.payload,
      };
    case actionTypes.GET_INSTITUTION_REQUEST:
      return {
        ...state,
        loading: true,
        error: null,
      };
    case actionTypes.GET_INSTITUTION_SUCCESS:
      return {
        ...state,
        loading: false,
        error: null,
        institution: action.payload,
      };
    case actionTypes.CLEAR_INSTITUTION_FETCH:
      return {
        ...state,
        institution: null,
      };
    case actionTypes.GET_INSTITUTION_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.payload,
      };
    case actionTypes.ADD_INSTITUTION_REQUEST:
      return {
        ...state,
        loading: true,
        error: null,
      };
    case actionTypes.ADD_INSTITUTION_SUCCESS:
      return {
        ...state,
        loading: false,
        error: null,
        institutions: [...action.payload, ...state.institutions],
      };
    case actionTypes.ADD_INSTITUTION_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.payload,
      };

    case actionTypes.UPDATE_INSTITUTION_REQUEST:
      return {
        ...state,
        loading: true,
        error: null,
      };
    case actionTypes.UPDATE_INSTITUTION_SUCCESS:
      return {
        ...state,
        loading: false,
        error: null,
        institution: action.payload,
      };
    case actionTypes.UPDATE_INSTITUTION_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.payload,
      };
    case actionTypes.DELETE_INSTITUTION_REQUEST:
      return {
        ...state,
        loading: true,
        error: null,
        institutions: state.institutions.filter(
          (item) => item.id !== action.payload
        ),
      };
    case actionTypes.DELETE_INSTITUTION_SUCCESS:
      return {
        ...state,
        loading: false,
        error: null,
      };
    case actionTypes.DELETE_INSTITUTION_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.payload,
      };
    default:
      return state;
  }
};
