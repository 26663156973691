import React, { useEffect, useState } from 'react';
import { useAppSelector, userAppointmentActions } from '@lyra/core';
import { useDispatch } from 'react-redux';
import UserAppointmentContent from '../user-appointment';

const UserAppointment: React.FC = () => {
  const dispatch = useDispatch();
  const { user } = useAppSelector((store) => ({
    user: store.auth.user,
  }));

  const [refreshContent, setRefreshContent] = useState(false);

  const { userAppointment, today, upcoming } = useAppSelector((store) => ({
    userAppointment: store.user_appointment.appointmentData,
    today: store.user_appointment.todayData,
    upcoming: store.user_appointment.upcomingData,
  }));
  const triggerRefresh = () => {
    setRefreshContent((prev) => !prev);
  };
  const getAppointments = async () => {
    if (user) {
      dispatch(userAppointmentActions.getUserAppointmentRequest(user.id));
    }
  };

  useEffect(() => {
    getAppointments();
  }, [user, refreshContent]);

  return (
    <div>
      <UserAppointmentContent
        currentUser={user}
        appointmentData={userAppointment}
        today={today}
        upcoming={upcoming}
        triggerRefresh={triggerRefresh}
      />
    </div>
  );
};

export { UserAppointment };
