import React from 'react';
import { Space, Spin } from 'antd';
import { Button, DrawerElem } from '@moxie/shared';
import { ILeadApplicationDrawer } from '@shared-components/models';
import { LeadApplicationForm } from '@shared-components/forms';
import {
  CANCEL_BTN,
  SAVE_BTN,
  UPDATE_BTN,
  DRAWER_WIDTH,
  TITLE,
} from '@moxie/constants';

const LeadApplicationDrawer = ({
  isOpen,
  onClose,
  handleFormSubmit,
  isSubmitted,
  isEdit,
  form,
  handleSubmit,
  initialData,
}: ILeadApplicationDrawer) => {
  return (
    <DrawerElem
      title={TITLE.CREATE_APPLICATION}
      width={DRAWER_WIDTH}
      onClose={onClose}
      visible={isOpen}
      footer={
        <div className="padding-bottom-2 text-align-right">
          <Space direction="horizontal">
            <Button onClick={onClose} className="lead-margin-right">
              {CANCEL_BTN}
            </Button>
            <Button
              type="primary"
              onClick={handleFormSubmit}
              disabled={isSubmitted}
            >
              {isEdit ? UPDATE_BTN : SAVE_BTN}
            </Button>
          </Space>
        </div>
      }
    >
      <Spin spinning={isSubmitted}>
        <LeadApplicationForm
          form={form}
          onSubmit={handleSubmit}
          initialData={initialData}
        />
      </Spin>
    </DrawerElem>
  );
};

export { LeadApplicationDrawer };
