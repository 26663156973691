import React from 'react';

interface ILayout {
  children: React.ReactNode;
}

const Layout: React.FC<ILayout> = ({ children }: ILayout) => {
  return <div className="registration-layout">{children}</div>;
};

export { Layout };
