import { COUNTRIES, GENDER_ITEMS, PHONE_LABEL } from "@moxie/constants";
import Card from 'antd/es/card';
import Form, { RuleObject } from 'antd/es/form';
import { Col } from 'antd/es/grid';
import Select from "antd/es/select";
import Typography from 'antd/es/typography';
import DatePicker from 'antd/lib/date-picker';
import { Row } from 'antd/lib/grid';
import { disableFutureDate } from "libs/shared/src/functions";
import { PhoneInput } from "libs/shared/src/shared";
import React from 'react';

interface PersonalInfoCardProps {
  editMode: boolean;
  // eslint-disable-next-line no-unused-vars
  validatePhoneNumber: (_: RuleObject, value: string) => Promise<void>;
  countryCode: string;
  setCountryCode: React.Dispatch<string | any>;
  // eslint-disable-next-line no-unused-vars
  onPhoneNumberChange: (val: string) => void;
  phone: string;
}

const preferredContact = ['phone', 'email'];

const EnquiryViewPersonalInfo = ({ editMode, validatePhoneNumber, setCountryCode, onPhoneNumberChange, countryCode, phone }: PersonalInfoCardProps) => {
  return (
    <Card title={<Typography.Text strong>Personal Information</Typography.Text>} headStyle={{ background: "none" }}>
      <Row>
        <Col span={24}>
          <Form.Item
            name={['personalInformation', 'phone']}
            label={PHONE_LABEL}
            className="country_dial_code basic-detail-phone "
            validateTrigger={"onChange"}
            rules={[
              {
                validator: validatePhoneNumber,
              },
            ]}
          >
            <PhoneInput
              dialCode={countryCode}
              className="basic-detail-phone custom-phone-placeholder"
              onChangeDialCode={(val) => setCountryCode(val)}
              onChange={(e) => onPhoneNumberChange(e.target.value)}
              value={phone}
              placeholder={!editMode ? "N/A" : "Enter Phone Number"}
              readOnly={!editMode}
              disabled={!editMode}
            />
          </Form.Item>
        </Col>
      </Row>

      <Row>
        <Col span={11}>
          <Form.Item name={['personalInformation', 'gender']} label="Gender">
            <Select allowClear={false} disabled={!editMode} placeholder={!editMode ? "N/A" : ""}>
              {GENDER_ITEMS.map((item: string) => {
                return (
                  <Select.Option key={item} value={item}>
                    {item}
                  </Select.Option>
                );
              })}
            </Select>
          </Form.Item>
        </Col>
        <Col span={11} offset={2}>
          <Form.Item name={['personalInformation', 'dateOfBirth']} label="Date Of Birth">
            <DatePicker
              disabledDate={disableFutureDate}
              className="full-width note-title-input"
              format="YYYY-MM-DD"
              disabled={!editMode}
              placeholder={!editMode ? "N/A" : "Select date"}
            />
          </Form.Item>
        </Col>
      </Row>

      <Row>
        <Col span={11}>
          <Form.Item name={['personalInformation', 'nationality']} label="Nationality">
            <Select showSearch allowClear={false} disabled={!editMode} optionFilterProp="searchprop" placeholder={!editMode ? "N/A" : ""}>
              {COUNTRIES.map((item) => {
                return (
                  <Select.Option key={item.code} value={item.name} searchprop={item.name}>
                    {item.name}
                  </Select.Option>
                );
              })}
            </Select>
          </Form.Item>
        </Col>
        <Col span={11} offset={2}>
          <Form.Item name={['personalInformation', 'preferredContact']} label="Preferred Contact">
            <Select allowClear={false} disabled={!editMode} placeholder={!editMode ? "N/A" : ""}>
              {preferredContact.map((item) => {
                return (
                  <Select.Option key={item} value={item}>
                    {item}
                  </Select.Option>
                );
              })}
            </Select>
          </Form.Item>
        </Col>
      </Row>

    </Card>
  )
}

export { EnquiryViewPersonalInfo };
