import { IAction, IInitialState, IUser } from '@shared-components/models';
import * as actionTypes from './user.constant';
export const USER_FEATURE_KEY = 'users';

export const initialUserState: IInitialState<IUser> = {
  allData: [],
  singleData: {},
  followerUsers: [],
  error: null,
  loading: false,
};

const userReducer = (state = initialUserState, action: IAction<any>) => {
  switch (action.type) {
    case actionTypes.GET_USERS_REQUEST:
      return {
        ...state,
        loading: true,
        error: null,
      };
    case actionTypes.GET_USERS_SUCCESS:
      return {
        ...state,
        loading: false,
        error: null,
        allData: action.payload,
      };
    case actionTypes.GET_USERS_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.payload,
      };
    case actionTypes.GET_USER_REQUEST:
      return {
        ...state,
        loading: true,
        error: null,
      };
    case actionTypes.GET_USER_SUCCESS:
      return {
        ...state,
        loading: false,
        error: null,
        singleData: action.payload,
      };
    case actionTypes.ADD_USER_REQUEST:
      return {
        ...state,
        loading: true,
      };
    case actionTypes.ADD_USER_SUCCESS:
      return {
        ...state,
        loading: false,
      };
    case actionTypes.ADD_USER_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.payload,
      };
    case actionTypes.GET_USER_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.payload,
      };
    case actionTypes.CLEAR_USER_FETCH:
      return {
        ...state,
        error: null,
        singleData: null,
      };
    case actionTypes.UPDATE_USER_REQUEST:
      return {
        ...state,
        loading: true,
        error: null,
      };
    case actionTypes.UPDATE_USER_SUCCESS:
      return {
        ...state,
        loading: false,
        error: null,
      };
    case actionTypes.UPDATE_USER_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.payload,
      };
    case actionTypes.DELETE_USER_REQUEST:
      return {
        ...state,
        loading: true,
        error: null,
      };
    case actionTypes.DELETE_USER_SUCCESS:
      return {
        ...state,
        loading: false,
        error: null,
      };
    case actionTypes.DELETE_USER_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.payload,
      };
    case actionTypes.GET_FOLLOWER_USERS_REQUEST:
      return {
        ...state,
        loading: true,
        error: null,
      };
    case actionTypes.GET_FOLLOWER_USERS_SUCCESS:
      return {
        ...state,
        loading: false,
        error: null,
        followerUsers: action.payload,
      };
    case actionTypes.GET_FOLLOWER_USERS_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.payload,
      };
    default:
      return state;
  }
};

export { userReducer };
