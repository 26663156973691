import { IAction, IInitialState } from '@shared-components/models';
import * as actionType from './document.constant';

export const DOCUMENT_FEATURE_KEY = 'document';

export const initialDocumentState: IInitialState<any> = {
  allData: [],
  singleData: {},
  documentType: [],
  error: null,
  loading: false,
};

const documentReducer = (
  state = initialDocumentState,
  action: IAction<any>
) => {
  switch (action.type) {
    case actionType.GET_DOCUMENT_REQUEST:
      return {
        ...state,
        loading: true,
        error: null,
      };
    case actionType.GET_DOCUMENT_SUCCESS:
      return {
        ...state,
        loading: false,
        allData: action.payload,
      };
    case actionType.GET_DOCUMENT_FAILURE:
      return {
        ...state,
        loading: true,
        error: null,
      };
    case actionType.GET_DOCUMENT_TYPE_REQUEST:
      return {
        ...state,
        loading: true,
        error: null,
      };
    case actionType.GET_DOCUMENT_TYPE_SUCCESS:
      return {
        ...state,
        loading: false,
        documentType: action.payload,
      };
    case actionType.GET_DOCUMENT_TYPE_FAILURE:
      return {
        ...state,
        loading: true,
        error: null,
      };
    case actionType.CLEAR_DOCUMENT_FETCH:
      return {
        ...state,
        allData: [],
        singleData: {},
      };
    default:
      return state;
  }
};

export { documentReducer };
