import React from 'react';
// import { RoleLists } from '@shared-components/modules';
import { OrganizationSettingsLayout } from '@crm/shared';
import { Route } from 'react-router-dom';
import PrefrencesSettings from './prefrences-settings';

const GeneralSettings: React.FC = () => {
  return (
    <OrganizationSettingsLayout>
      <Route exact path="/dashboard/settings/" component={PrefrencesSettings} />
      {/* <Route exact path="/dashboard/settings/roles" component={RoleLists} /> */}
    </OrganizationSettingsLayout>
  );
};

export default GeneralSettings;
