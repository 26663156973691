const AM_ROLES = {
  SUPER_USER: 'SUPER_USER',
  MANAGER: 'MANAGER',
  COUNSELLOR: 'COUNSELLOR',
};

const AM_PERMISSIONS = {
  ROLES: {
    MANAGE: 'MANAGE_ROLES',
  },
  USERS: {
    MANAGE: 'MANAGE_USERS',
    READ: 'READ_USERS',
  },
  CONTACTS: {
    MANAGE: 'MANAGE_CONTACTS', //includes Delete
    ARCHIVE: 'ARCHIVE_CONTACTS',
    READ: 'READ_CONTACTS',
    READ_ARCHIVED: 'READ_ARCHIVED_CONTACTS', // only contact overview if not assigned or followed
    READ_ASSIGNED_FOLLOWED: 'READ_CONTACTS_ASSIGNED_FOLLOWED',
    UPDATE_ASSIGNED_FOLLOWED: 'UPDATE_CONTACTS_ASSIGNED_FOLLOWED',
    ARCHIVE_ASSIGNED_FOLLOWED: 'ARCHIVE_CONTACTS_ASSIGNED_FOLLOWED',
  },
  CONTACTS_FORM: {
    MANAGE: 'MANAGE_CONTACTS_FORM',
  },
  APPLICATIONS: {
    MANAGE: 'MANAGE_APPLICATIONS',
    READ: 'READ_APPLICATIONS',
    UPDATE: 'UPDATE_APPLICATIONS',
    DISCONTINUE: 'DISCONTINUE_APPLICATIONS',
    READ_ASSIGNED_FOLLOWED: 'READ_APPLICATIONS_ASSIGNED',
    UPDATE_ASSIGNED_FOLLOWED: 'UPDATE_CONTACTS_ASSIGNED',
    ARCHIVE_ASSIGNED_FOLLOWED: 'ARCHIVE_CONTACTS_ASSIGNED',
  },
  CONTACT_APPOINTMENTS: {
    MANAGE: 'MANAGE_CONTACT_APPOINTMENTS',
  },
  CONTACT_DOCUMENTS: {
    MANAGE: 'MANAGE_CONTACT_DOCUMENTS',
  },
  DEPARTMENTS: {
    MANAGE: 'MANAGE_DEPARTMENTS',
  },
  BRANCHES: {
    MANAGE: 'MANAGE_BRANCHES',
    READ_ALL: 'READ_ALL_BRANCHES',
  },
};

const {
  ROLES,
  USERS,
  CONTACTS,
  CONTACTS_FORM,
  APPLICATIONS,
  CONTACT_APPOINTMENTS,
  CONTACT_DOCUMENTS,
  DEPARTMENTS,
  BRANCHES,
} = AM_PERMISSIONS;

const AM_PERMISSIONS_SEED = [
  { name: ROLES.MANAGE, description: 'Can manage roles' },
  { name: USERS.READ, description: 'Can read users' },
  { name: USERS.MANAGE, description: 'Can manage users' },
  {
    name: CONTACTS.MANAGE,
    description: 'Can manage contacts',
  },
  {
    name: CONTACTS.ARCHIVE,
    description: 'Can archive contacts',
  },
  {
    name: CONTACTS.READ,
    description: 'Can read contacts',
  },
  {
    name: CONTACTS.READ_ARCHIVED,
    description: 'Can read archived contacts',
  },
  {
    name: CONTACTS.READ_ASSIGNED_FOLLOWED,
    description: 'Can read, if user is assigned or a follower',
  },
  {
    name: CONTACTS.UPDATE_ASSIGNED_FOLLOWED,
    description: 'Can update , if user is assigned or a follower',
  },
  {
    name: CONTACTS.ARCHIVE_ASSIGNED_FOLLOWED,
    description:
      'Can arcgive if user is assigned or a follower',
  },
  {
    name: CONTACTS_FORM.MANAGE,
    description: 'Can manage contacts_form',
  },
  {
    name: APPLICATIONS.READ,
    description: 'Can read applications',
  },
  {
    name: APPLICATIONS.UPDATE,
    description: 'Can update applications',
  },
  {
    name: APPLICATIONS.MANAGE,
    description: 'Can manage applications',
  },
  {
    name: APPLICATIONS.DISCONTINUE,
    description: 'Can discontinue applications',
  },
  {
    name: CONTACT_APPOINTMENTS.MANAGE,
    description: 'Can manage contact_appointments',
  },
  {
    name: CONTACT_DOCUMENTS.MANAGE,
    description: 'Can manage contact_documents',
  },
  {
    name: DEPARTMENTS.MANAGE,
    description: 'Can manage departments',
  },
  {
    name: BRANCHES.MANAGE,
    description: 'Can manage branches',
  },
  {
    name: BRANCHES.READ_ALL,
    description: 'User can read all branches',
  },
];

const AM_ROLES_SEED = [
  { name: AM_ROLES.SUPER_USER, description: 'Can manage everything.' },
  { name: AM_ROLES.MANAGER, description: 'Can perform managerial actions.' },
  { name: AM_ROLES.COUNSELLOR, description: 'Can perform counsellor actions.' },
];

const AM_ROLES_PERMISSIONS_SEED = {
  [AM_ROLES.SUPER_USER]: [
    { name: ROLES.MANAGE, description: 'Can manage roles' },
    { name: USERS.MANAGE, description: 'Can manage users' },
    {
      name: CONTACTS.MANAGE,
      description: 'Can manage contacts',
    },
    {
      name: CONTACTS_FORM.MANAGE,
      description: 'Can manage contacts_form',
    },
    {
      name: APPLICATIONS.MANAGE,
      description: 'Can manage applications',
    },
    {
      name: APPLICATIONS.DISCONTINUE,
      description: "Can discontinue applications",
    },
    {
      name: CONTACT_APPOINTMENTS.MANAGE,
      description: 'Can manage contact_appointments',
    },
    {
      name: CONTACT_DOCUMENTS.MANAGE,
      description: 'Can manage contact_documents',
    },
    {
      name: DEPARTMENTS.MANAGE,
      description: 'Can manage departments',
    },
    {
      name: BRANCHES.MANAGE,
      description: 'Can manage branches',
    },
    {
      name: BRANCHES.READ_ALL,
      description: 'User can read all branches',
    },
  ],
  [AM_ROLES.MANAGER]: [
    { name: USERS.READ, description: 'Can read users' },
    {
      name: CONTACTS.MANAGE,
      description: 'Can manage contacts',
    },
    {
      name: CONTACTS.ARCHIVE,
      description: 'Can archive contacts',
    },
    {
      name: APPLICATIONS.MANAGE,
      description: 'Can manage applications',
    },
    {
      name: APPLICATIONS.DISCONTINUE,
      description: 'Can discontinue applications',
    },
    {
      name: CONTACT_DOCUMENTS.MANAGE,
      description: 'Can manage contact_documents',
    },
    {
      name: BRANCHES.READ_ALL,
      description: 'User can read all branches',
    },
  ],
  [AM_ROLES.COUNSELLOR]: [
    { name: USERS.READ, description: 'Can read users' },
    { name: CONTACTS.READ, description: 'Can read contacts' },
    { name: CONTACTS.READ_ARCHIVED, description: 'Can read archived contacts' },
    {
      name: CONTACTS.READ_ASSIGNED_FOLLOWED,
      description: 'Can read, if user is assigned or a follower',
    },
    {
      name: CONTACTS.UPDATE_ASSIGNED_FOLLOWED,
      description: 'Can update , if user is assigned or a follower',
    },
    {
      name: CONTACTS.ARCHIVE_ASSIGNED_FOLLOWED,
      description: 'Can create if user is assigned or a follower',
    },
    {
      name: APPLICATIONS.READ,
      description: 'Can read applications',
    },
    {
      name: APPLICATIONS.UPDATE,
      description: 'Can update applications',
    },
    {
      name: APPLICATIONS.DISCONTINUE,
      description: 'Can discontinue applications',
    },
    {
      name: BRANCHES.READ_ALL,
      description: 'User can read all branches',
    },
  ],
};

export {
  AM_ROLES,
  AM_PERMISSIONS,
  AM_ROLES_SEED,
  AM_ROLES_PERMISSIONS_SEED,
  AM_PERMISSIONS_SEED,
};
