import React, { useState } from 'react';
import {
  ACTIONS,
  DELETE_BTN,
  INACTIVE_LEAD_FORM,
  LABEL,
  TITLE,
} from '@moxie/constants';
import { ILeadsForm, ITableProps } from '@shared-components/models';
import { Button, generateLeadsFormUrl, Table } from '@moxie/shared';
import { Badge, Col, Row } from 'antd';
import { TableFormNameCard } from './table-form-name-card';
import { DeleteOutlined, PlusOutlined, StarFilled } from '@ant-design/icons';
import Can, { PermissionAction, PermissionObjects } from '@lyra/privilege';

const LeadsFormTable: React.FC<ITableProps> = ({
  showDrawer,
  filter,
  getData,
  deleteData,
  handleEdit,
  handleMultiple,
  refreshTable,
}: ITableProps) => {
  const [ids, setIds] = useState<Array<string>>([]);
  const columns = [
    {
      title: LABEL.BADGE,
      key: 'is_favourite',
      width: 100,
      render(data: ILeadsForm) {
        return data.is_favourite ? (
          <StarFilled className="badge__position color-primary" />
        ) : null;
      },
    },
    {
      title: LABEL.FORM_NAME,
      key: 'form_name',
      width: 250,
      render(data: ILeadsForm) {
        return (
          <Row>
            <Col span={18} className="align-items-center">
              {data.form_name}
            </Col>
            <Col span={3}>
              <TableFormNameCard
                data={data}
                showDrawer={showDrawer}
                deleteData={deleteData}
                editData={handleEdit}
              />
            </Col>
          </Row>
        );
      },
    },
    {
      title: LABEL.URL,
      key: 'id',
      width: 400,
      render(data: ILeadsForm) {
        const url = generateLeadsFormUrl(data.id);
        if (data.status === 'active') {
          return (
            <a
              href={url}
              target="_blank"
              title={url}
              rel="noopener noreferrer"
              className="text__overflow"
            >
              {url}
            </a>
          );
        }
        return INACTIVE_LEAD_FORM;
      },
    },
    {
      title: LABEL.RELATED_OFFICE,
      key: 'relatedOffice',
      width: 300,
      render(data: ILeadsForm) {
        return data?.form_properties?.related_office
          ? data.form_properties.related_office
          : LABEL.HEUBERT;
      },
    },
    {
      title: LABEL.BRANCH,
      key: 'branch',
      width: 300,
      render(data: any) {
        return data?.branch ? data?.branch?.name : '-';
      },
    },
    {
      title: LABEL.SOURCE,
      key: 'source',
      width: 200,
      render(data: ILeadsForm) {
        return data?.form_properties?.source
          ? data.form_properties.source
          : LABEL.WALK_IN;
      },
    },
    {
      title: LABEL.STATUS,
      key: 'status',
      width: 200,
      render(text: ILeadsForm, record: ILeadsForm) {
        return (
          <Badge
            count={record.status?.toUpperCase()}
            className={record.status === 'active' ? 'badge__color-green' : ''}
          />
        );
      },
    },
  ];

  const handleMultipleSelection = (data: Array<string>) => {
    setIds(data);
  };
  const handleMultipleDelete = () => {
    handleMultiple && handleMultiple(ACTIONS.DELETE, ids);
  };

  return (
    <Table
      tableName={TITLE.LEAD_FORM}
      filter={filter}
      action={getData}
      columns={columns}
      onChangeSelected={handleMultipleSelection}
      handleMultipleDelete={handleMultipleDelete}
      refresh={refreshTable}
      filterOptions={true}
      tableButtons={
        Can(PermissionAction.Create, PermissionObjects.LeadsForm) && (
          <Button
            type="primary"
            typeof="button"
            onClick={() => showDrawer && showDrawer()}
          >
            <PlusOutlined /> {LABEL.CREATE_NEW_LEAD_FORM}
          </Button>
        )
      }
    >
      {/* {Can(PermissionAction.Delete, PermissionObjects.LeadsForm) && ( */}
      <Button
        type="primary"
        title={DELETE_BTN}
        danger
        onClick={handleMultipleDelete}
        icon={<DeleteOutlined />}
      >
        {DELETE_BTN}
      </Button>
      {/* )} */}
    </Table>
  );
};

export { LeadsFormTable };
