/* eslint-disable no-unused-vars */
/* eslint-disable react/prop-types */
/* eslint-disable react-hooks/rules-of-hooks */
import React, { ReactNode, useEffect, useState } from 'react';
import { Button, Table } from '@moxie/shared';
import { PlusOutlined } from '@ant-design/icons';
import { ADD_DOCUMENT, PLACEHOLDER, TEXT } from '@moxie/constants';
import { ColumnsType } from 'antd/lib/table';
import {
  ILeadDocumentData,
  ILeadDocumentTable,
} from '@shared-components/models';
import { useQuery } from '@moxie/utils';
import { DocumentNameCard, DocumentAddCard } from '@moxie/shared';
import { DocumentTypeNameCard } from '@moxie/shared';
import Can, { PermissionAction, PermissionObjects } from '@lyra/privilege';
import moment from 'moment';
import { useDispatch } from 'react-redux';
import { documentChecklistAction } from '@lyra/core';

const LeadDocumentTable: React.FC<ILeadDocumentTable> = ({
  handleClick,
  refreshTable,
  documentData,
  triggerRefresh,
  lead,
}: ILeadDocumentTable) => {
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(documentChecklistAction.getDocumentChecklistRequest());
  }, []);
  const [filter] = useState({});
  const columns: ColumnsType<ILeadDocumentData> = [
    {
      title: TEXT.ID,
      key: 'id',
      width: 100,
      render: (
        data: ILeadDocumentData,
        row: ILeadDocumentData,
        index: number
      ): ReactNode => {
        const pageNumber = useQuery().get('pageNo') || 1;
        const limit = useQuery().get('limit') || 10;
        return (pageNumber - 1) * limit + index + 1;
      },
    },
    {
      title: TEXT.FILE_NAME,
      width: 300,
      ellipsis: true,
      render(data: ILeadDocumentData): ReactNode {
        return (
          <DocumentNameCard
            triggerRefresh={triggerRefresh}
            data={data?.document}
            archived={lead?.archived}
            leadData={lead}
          />
        );
      },
    },
    {
      title: TEXT.ADDED_BY,
      width: 300,
      sorter: () => 0,
      dataIndex: 'added_by',
      render(
        value: string,
        record: ILeadDocumentData,
        index: number
      ): ReactNode {
        return (
          <DocumentAddCard
            data={record?.document?.addedBy || ''}
            index={index.toString()}
          />
        );
      },
    },
    {
      title: PLACEHOLDER.DOCUMENT_TYPE,
      width: 300,
      dataIndex: 'type',
      sorter: () => 0,
      render(_text: string, data: ILeadDocumentData): ReactNode {
        return (
          <DocumentTypeNameCard
            data={data.document}
            triggerRefresh={triggerRefresh}
            archived={lead?.archived}
            leadData={lead}
          />
        );
      },
    },
    {
      title: TEXT.FILE_SIZE,
      width: 250,
      dataIndex: 'size',
      render(_text: string, data: ILeadDocumentData): ReactNode {
        return <span>{Math.round(data?.document?.size / 1024)} &nbsp;KB</span>;
      },
    },
    {
      title: TEXT.ADDED_ON,
      width: 300,
      render: (data: ILeadDocumentData): ReactNode => {
        return moment(data?.document?.created_at).format('MMM DD, YYYY');
      },
    },
  ];

  return (
    <div>
      <div className="slider_content">
        {
          // Can(PermissionAction.Create, PermissionObjects.LeadDocumentEntity) &&
          !lead?.archived && (
            <Button
              type="primary"
              onClick={() => handleClick && handleClick(TEXT.DEVICE)}
              icon={<PlusOutlined />}
              className="margin-top-1 margin-right-1"
            >
              {ADD_DOCUMENT}
            </Button>
          )
        }
      </div>
      {/* {Can(PermissionAction.Read, PermissionObjects.LeadDocumentEntity) && ( */}
      <Table
        columns={columns}
        action={documentData}
        tableName={TEXT.DOCUMENTS}
        filter={filter}
        className="lead-table"
        refresh={refreshTable}
      />
      {/* )} */}
    </div>
  );
};

export { LeadDocumentTable };
