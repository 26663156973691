import React, { FC, useEffect, useState } from 'react';
import { List, Spin, Upload } from 'antd';
import { InboxOutlined } from '@ant-design/icons';
import {
  AUDIO_TYPE,
  DOCUMENT_TYPE,
  FILE_SIZE_EXCEED,
  IMAGE_TYPE,
  MAXIMUM_NUMBER_OF_FILES,
  MAXIMUM_SIZE_OF_FILES,
  NO_FILES_SELECTED,
  PRESENTATION_TYPE,
  SPREADSHEET_TYPE,
  TEXT,
  TITLE,
  VIDEO_TYPE,
} from '@moxie/constants';
import { IDocumentDragger, IDocumentItem } from '@shared-components/models';
import { errorNotificationHandler, FileNameComponent } from '@moxie/shared';

const DocumentDragger: FC<IDocumentDragger> = ({
  onSetDocument,
  setLoading,
  loading,
  documents,
}: IDocumentDragger) => {
  const { Dragger } = Upload;
  const [documentsSize, setDocumentsSize] = useState<number>(0);

  const handleRemove = async (file: IDocumentItem | undefined | any) => {
    const filterDocument = await documents.filter(
      (item: IDocumentItem) => item?.uid !== file?.uid
    );
    onSetDocument(filterDocument);
  };

  const handleTypeChange = async (
    file: IDocumentItem | undefined,
    value: string
  ) => {
    const [filterDocument] = await Promise.all([
      documents.filter((item: IDocumentItem) => {
        const newItem = item;
        if (item?.uid === file?.uid) {
          newItem.documentType = value;
        }
        return newItem;
      }),
    ]);
    onSetDocument(filterDocument);
  };

  const handleUpload = async (fileInfo: any) => {
    if (fileInfo.file.size / 1024 / 1024 < 10) {
      setLoading?.(true);
      onSetDocument((files: []) => [...files, fileInfo.file]);
      setLoading?.(false);
    } else {
      errorNotificationHandler(FILE_SIZE_EXCEED);
    }
  };

  const SETTING = {
    name: 'files',
    multiple: true,
    accept: `${PRESENTATION_TYPE}, ${DOCUMENT_TYPE}, ${IMAGE_TYPE}, ${VIDEO_TYPE}, ${SPREADSHEET_TYPE}, ${AUDIO_TYPE}`,
    showUploadList: false,
    customRequest: handleUpload,
  };

  useEffect(() => {
    setDocumentsSize(() => {
      return documents?.reduce((acc: number, curr: any) => {
        return acc + curr.size;
      }, 0);
    });
  }, [documents]);

  return (
    <div>
      <Dragger className="dragger_height" {...SETTING} listType="picture">
        <p className="ant-upload-drag-icon">
          <InboxOutlined />
        </p>
        <p className="ant-upload-text">{TITLE.DRAG_TO_UPLOAD}</p>
        <div>
          <div>
            <p className="font-size-20-px">
              {TEXT.SELECTED_DOCUMENTS}&nbsp;
              <span
                style={{
                  color: documents?.length > 20 ? 'red' : 'black',
                }}
              >
                ({documents?.length})
              </span>{' '}
              <span
                style={{
                  color: documentsSize / 1024 / 1024 > 50 ? 'red' : 'black',
                }}
              >{` (${Math.round(documentsSize / 1024 / 1024)} MB)`}</span>
            </p>
            <p>{MAXIMUM_NUMBER_OF_FILES}: 20</p>
            <p>{MAXIMUM_SIZE_OF_FILES}: 50 MB</p>
            <p className="dragger_font">
              {documents?.length === 0 ? NO_FILES_SELECTED : null}
            </p>
          </div>
        </div>
      </Dragger>
      <div className="margin-top-1">
        <Spin
          spinning={loading}
          tip={TEXT.UPLOAD_DOCUMENTS}
          className="margin-top-3"
        >
          {documents?.length !== 0 ? (
            <List
              bordered={true}
              dataSource={documents}
              renderItem={(item: IDocumentItem) => {
                return (
                  <FileNameComponent
                    item={item}
                    documents={documents}
                    onSetDocument={onSetDocument}
                    onRemove={handleRemove}
                    onTypeChange={handleTypeChange}
                  />
                );
              }}
            />
          ) : null}
        </Spin>
      </div>
    </div>
  );
};

export default DocumentDragger;
