/* eslint-disable no-unused-vars */
import { FormOutlined, ImportOutlined, PlusOutlined } from '@ant-design/icons';
import { leadActions, useAppSelector } from '@lyra/core';
import permit from '@lyra/permit';
import {
  getAllLead,
  getAllContacts,
  getArchived,
  getAssigned,
} from '@lyra/services.api';
import {
  ADD_CONTACTS,
  AM_PERMISSIONS,
  IMPORT,
  LEAD_INITIAL_DATA,
  LEAD_TITLE,
  MANAGE_FORM,
  PAGE_HEADER_MENU_CONTACT,
  SLUG_ALL_CONTACTS,
  SLUG_MY_CONTACTS,
  TEXT,
  URL_ACTIVE_LEAD_FORM,
} from '@moxie/constants';
import { Button, PageHeaderTitle, Table } from '@shared-components/elements';
import { LeadAddEdit } from '@shared-components/modules';
import React, { useContext, useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { Link, useHistory, useParams } from 'react-router-dom';
import { LeadContext } from '../../lead';
import ImportLeadDrawer from '../../lead/import-drawer';
import { getColumns } from './lead-columns';
import { PageHeaderMenu } from '../components/page-header-menu';
const { CONTACTS: CNT, CONTACTS_FORM } = AM_PERMISSIONS;

const Contact: React.FC = () => {
  const [
    _initialData,
    _setInitialData,
    _isOpen,
    _setOpen,
    _disable,
    _setDisable,
    refreshTable,
    triggerRefresh,
  ] = useContext(LeadContext);
  const history = useHistory();
  const { name } = useParams<{ name: string }>();
  const rolePerms: string[] = useAppSelector(
    (state: any) => state?.auth?.user?.am_role?.permissions
  );
  const [visible, setVisible] = useState(false);
  const [isSubmitted, setIsSubmitted] = useState<boolean>(false);
  const [selectedId, setSelectedId] = useState<string[]>([]);
  const [filter, setFilter] = useState({ selectBy: name });
  const dispatch = useDispatch();
  const { assignee } = useAppSelector((store) => ({
    assignee: store.users.allData,
  }));

  const tableLoading = useAppSelector((store) => store.lead.loading);

  const onClose = () => {
    _setOpen(false);
    _setDisable(true);
    setIsSubmitted(false);
  };

  if (useAppSelector((item) => item.lead.redirect)) {
    dispatch(leadActions.clearLeads());
  }

  const handleAfterLeadUpdate = () => {
    dispatch(leadActions.clearLeads());
    history.push(`/contacts/${SLUG_MY_CONTACTS}`);
  };

  const handleAddLead = () => {
    _setInitialData(LEAD_INITIAL_DATA);
    _setOpen(true);
  };

  const handleSelectedId = (ids: string[]) => {
    setSelectedId(ids);
  };
  const onCloseImport = () => {
    setVisible(false);
  };
  const handleClick = () => {
    setVisible(true);
  };

  useEffect(() => {
    if (filter.selectBy !== name) setFilter({ selectBy: name });
  }, [name]);

  useEffect(() => {
    triggerRefresh();
  }, [assignee, tableLoading]);

  useEffect(() => {
    if (!rolePerms) return;
    const allPermitted = permit([CNT.MANAGE], rolePerms);
    if (!allPermitted) history.push(`/contacts/${SLUG_MY_CONTACTS}`);
  }, [rolePerms]);

  const getData = async (str: string) => {
    const params = new URLSearchParams(str);
    const selectBy = params.get('selectBy');
    params.delete('selectBy');
    const p = params.toString();
    if (selectBy === 'all-contacts') {
      return await getAllContacts(p);
    } else if (selectBy === 'my-contacts') {
      return await getAssigned(p);
    } else if (selectBy === 'archived') {
      return await getArchived(p);
    }
  };

  return (
    <div className="full-height">
      <PageHeaderTitle title={TEXT.CONTACTS} />
      <PageHeaderMenu menu={PAGE_HEADER_MENU_CONTACT} defaultActiveKey={name} />
      <LeadAddEdit
        setDisabled={_setDisable}
        submitted={isSubmitted}
        setSubmitted={setIsSubmitted}
        disabled={_disable}
        isOpen={_isOpen}
        onAfterSuccess={handleAfterLeadUpdate}
        onClose={onClose}
        initialData={_initialData}
        triggerRefresh={triggerRefresh}
      />

      <ImportLeadDrawer
        isOpen={visible}
        onClose={onCloseImport}
        triggerRefresh={triggerRefresh}
      />
      <div className="bg-white padding-top-2">
        <Table
          tableLoading={tableLoading}
          filterOptions={true}
          tableName={LEAD_TITLE}
          refresh={refreshTable}
          columns={getColumns(TEXT.CONTACTS, name)}
          onChangeSelected={handleSelectedId}
          action={getData}
          filter={filter}
          size={'small'}
          className="lead-table"
          tableButtons={
            <>
              {/* {permit([CONTACTS_FORM.MANAGE], rolePerms) && (
                <Link to={URL_ACTIVE_LEAD_FORM}>
                  <Button type="ghost" icon={<FormOutlined />}>
                    {MANAGE_FORM}
                  </Button>
                </Link>
              )} */}
              {permit([CNT.MANAGE], rolePerms) && (
                <>
                  <Button type="primary" onClick={handleAddLead}>
                    <PlusOutlined />
                    {ADD_CONTACTS}
                  </Button>
                  {/* <Button
                    type="primary"
                    icon={<ImportOutlined />}
                    onClick={handleClick}
                    typeof="button"
                  >
                    {IMPORT}
                  </Button> */}
                </>
              )}
            </>
          }
        />
      </div>
    </div>
  );
};

export { Contact };
