const alias = 'USER';

export const GET_USERS_REQUEST = `GET_${alias}S_REQUEST`;
export const GET_USERS_SUCCESS = `GET_${alias}S_SUCCESS`;
export const GET_USERS_FAILURE = `GET_${alias}_FAILURE`;

export const ADD_USER_REQUEST = `ADD_${alias}_REQUEST`;
export const ADD_USER_SUCCESS = `ADD_${alias}_SUCCESS`;
export const ADD_USER_FAILURE = `ADD_${alias}_FAILURE`;

export const GET_USER_REQUEST = `GET_${alias}_REQUEST`;
export const GET_USER_SUCCESS = `GET_${alias}_SUCCESS`;
export const GET_USER_FAILURE = `GET_${alias}_FAILURE`;

export const UPDATE_USER_REQUEST = `UPDATE_${alias}REQUEST`;
export const UPDATE_USER_SUCCESS = `UPDATE_${alias}SUCCESS`;
export const UPDATE_USER_FAILURE = `UPDATE_${alias}FAILURE`;

export const DEACTIVATE_USER_REQUEST = `DEACTIVATE_${alias}REQUEST`;
export const DEACTIVATE_USER_SUCCESS = `DEACTIVATE_${alias}SUCCESS`;
export const DEACTIVATE_USER_FAILURE = `DEACTIVATE_${alias}FAILURE`;


export const ACTIVATE_USER_REQUEST = `ACTIVATE_${alias}REQUEST`;
export const ACTIVATE_USER_SUCCESS = `ACTIVATE_${alias}SUCCESS`;
export const ACTIVATE_USER_FAILURE = `ACTIVATE_${alias}FAILURE`;

export const GET_FOLLOWER_USERS_REQUEST = `GET_FOLLOWER_${alias}S_REQUEST`;
export const GET_FOLLOWER_USERS_SUCCESS = `GET_FOLLOWER_${alias}S_SUCCESS`;
export const GET_FOLLOWER_USERS_FAILURE = `GET_FOLLOWER_${alias}_FAILURE`;

export const CLEAR_USER_FETCH = `CLEAR_${alias}_FETCH`;
