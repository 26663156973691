import React, { FC, useEffect, useState } from 'react';
import { Space, Button } from 'antd';
import { DrawerElem } from '@shared-components/elements';
import DocumentDragger from '../document-drawer';
import { CANCEL_BTN, DRAWER_WIDTH, SAVE_BTN, TEXT } from '@moxie/constants';
import { saveContactDocuments } from '@lyra/services.api';
import { errorHandler } from '@moxie/utils';
import { ILeadDocumentProps } from '@shared-components/models';
import { useAppSelector } from '@lyra/core';
import {
  successNotificationHandler,
  errorNotificationHandler,
} from '@moxie/shared';
import { useParams } from 'react-router-dom';

const LeadDocumentDrawer: FC<ILeadDocumentProps> = ({
  isOpen,
  onClose,
  triggerRefresh,
}: ILeadDocumentProps) => {
  const params = useParams<{ id: string }>();
  const [documents, setDocuments] = useState<any>([]);
  const [disabled, setIsDisabled] = useState(true);
  const [loading, setIsLoading] = useState(false);
  const socket: any = useAppSelector((state) => state.socket.wss);
  const user: any = useAppSelector((state) => state.auth.user);
  const lead: any = useAppSelector((state) => state.lead.lead);

  const handleSubmit = async () => {
    if (documents.length > 20) {
      return errorNotificationHandler(TEXT.MAX_TASK_DOCUMENTS_COUNT);
    }
    setIsLoading(true);
    setIsDisabled(true);
    const formData = new FormData();
    await documents.forEach((file: any) => {
      formData.append('files', file);
      formData.append('documentType', file.documentType);
      formData.append('fileName', file.fileName + file.extension || file.name);
    });
    formData.append('contactId', params.id);

    try {
      const response: any = await saveContactDocuments(formData);
      const documentNotificationData = {
        user_id: user.id,
        parent_id: lead.id,
        receiver_id: lead.assignee_id,
        receivers_many: lead?.followers.map((item: any) => item.id),
        type: 'document-created',
      };
      socket.emit('document-notification', documentNotificationData);
      const dataLeadActivities = {
        user_id: user.id,
        document_id: response.data.data[0].id,
        parent_id: response.data.data[0].contact_id,
        company_id: user?.company?.id,
      };
      socket.emit('added-new-document-to-server', dataLeadActivities);
      successNotificationHandler(response!.data!.message);
      setIsLoading(false);
      handleClose();
      triggerRefresh();
    } catch (err) {
      errorHandler(err);
      setIsDisabled(false);
    }
  };

  const handleClose = () => {
    setIsDisabled(true);
    setDocuments([]);
    onClose();
  };

  useEffect(() => {
    if (documents.length > 0) {
      setIsDisabled(false);
    } else {
      setIsDisabled(true);
    }
  }, [documents]);

  return (
    <DrawerElem
      title={TEXT.UPLOAD_FROM_DEVICE}
      width={DRAWER_WIDTH}
      onClose={handleClose}
      visible={isOpen}
      closable={!loading}
      footer={
        <div className="padding-bottom-2 text-align-right">
          <Space direction="horizontal">
            <Button
              onClick={handleClose}
              className="lead-margin-right"
              disabled={loading}
            >
              {CANCEL_BTN}
            </Button>
            <Button type="primary" onClick={handleSubmit} disabled={disabled}>
              {SAVE_BTN}
            </Button>
          </Space>
        </div>
      }
    >
      <DocumentDragger
        setLoading={setIsLoading}
        loading={loading}
        onSetDocument={setDocuments}
        documents={documents}
      />
    </DrawerElem>
  );
};

export { LeadDocumentDrawer };
