import React, { useEffect, useState } from 'react';
import { Card, Col, Form, Row, Space } from 'antd';
import {
  CANCEL_BTN,
  CATEGORY_ITEMS,
  COUNTRIES,
  DELETE_BTN,
  EDIT_BTN,
  GUTTER,
  LABEL,
  NOT_AVAILABLE_MESSAGE,
  regex,
  SAVE_BTN,
  TEXT,
  VALIDATION_MESSAGE,
} from '@moxie/constants';
import { useForm } from 'antd/lib/form/Form';
import { Button, Input, Option, SearchableSelect } from '@moxie/shared';
import { DeleteOutlined, PlusOutlined } from '@ant-design/icons';
import { useDispatch } from 'react-redux';
import { leadActions, useAppSelector } from '@lyra/core';
import { IGetPopupContainer } from '@shared-components/models';

const EmergencyContactDetailsTab: React.FC<any> = (props: any) => {
  const { id, contactDetails, archived } = props;
  const [form] = useForm();
  const dispatch = useDispatch();
  const [isEdit, setIsEdit] = useState<boolean>(false);
  const [disabled, setDisabled] = useState<boolean>(true);
  const [isCanceled, setIsCanceled] = useState<boolean>(false);
  const { user, socket } = useAppSelector((state) => ({
    user: state.auth.user,
    socket: state.socket.wss,
  }));

  const onFieldsChange = (allFields: string | any[]) => {
    if (allFields.length > 0) {
      setDisabled(false);
    }
  };

  const handleSubmit = (data: any) => {
    dispatch(
      leadActions.editLeadAction(
        { ...data, id: id },
        socket,
        user,
        'emergancy contact details',
        'other-details'
      )
    );
    setIsEdit(false);
  };

  useEffect(() => {
    if (contactDetails.length > 0) {
      form.setFieldsValue({ emergencyContactDetails: contactDetails });
    } else {
      form.resetFields();
    }
  }, [contactDetails, form, isCanceled]);

  useEffect(() => {
    if (contactDetails.length < 1) {
      setIsEdit(true);
    }
  }, [contactDetails]);

  return (
    <Card
      title={CATEGORY_ITEMS[3].name}
      extra={[
        <>
          {isEdit ? (
            <>
              {!archived && (
                <Space>
                  <Button
                    disabled={disabled}
                    type="primary"
                    onClick={() => form.submit()}
                  >
                    {SAVE_BTN}
                  </Button>
                  <Button
                    onClick={() => {
                      setIsEdit(false);
                      setIsCanceled(!isCanceled);
                    }}
                  >
                    {CANCEL_BTN}
                  </Button>
                </Space>
              )}
            </>
          ) : (
            <>
              {!archived && (
                <Button type="primary" onClick={() => setIsEdit(true)}>
                  {EDIT_BTN}
                </Button>
              )}
            </>
          )}
        </>,
      ]}
    >
      <Form
        onFinish={handleSubmit}
        initialValues={{
          emergencyContactDetails: [{}],
        }}
        form={form}
        scrollToFirstError={true}
        onFieldsChange={onFieldsChange}
        labelCol={{ lg: 7, md: 8, xl: 10, span: 10 }}
        wrapperCol={{ span: 14, offset: 1 }}
      >
        <Form.List name="emergencyContactDetails">
          {(fields, { add, remove }) => {
            return (
              <>
                {fields.map(({ key, name }) => {
                  return (
                    <Card key={key} className="margin-bottom-1">
                      <Row gutter={GUTTER}>
                        <Col span={24} md={24} lg={24} xl={12} xxl={8}>
                          <Form.Item
                            name={[name, 'contact_name']}
                            className="custom_label_style"
                            label={LABEL.NAME}
                            rules={[{ required: true }]}
                          >
                            {contactDetails.length === 0 || isEdit ? (
                              <Input
                                className="note-title-input"
                                disabled={!isEdit}
                              />
                            ) : contactDetails[name]?.contact_name ? (
                              <div className="initial_capital">
                                {contactDetails[name]?.contact_name}
                              </div>
                            ) : (
                              NOT_AVAILABLE_MESSAGE
                            )}
                          </Form.Item>
                        </Col>
                        <Col span={24} md={24} lg={24} xl={12} xxl={8}>
                          <Form.Item
                            name={[name, 'relationship']}
                            className="custom_label_style"
                            label={LABEL.RELATIONSHIP}
                            rules={[
                              { required: true },
                              {
                                pattern: new RegExp(regex.ONLY_STRING),
                                message: VALIDATION_MESSAGE.INVALID_TEXT_INPUT,
                              },
                            ]}
                          >
                            {contactDetails.length === 0 || isEdit ? (
                              <Input
                                className="note-title-input"
                                disabled={!isEdit}
                              />
                            ) : contactDetails[name]?.relationship ? (
                              <div className="initial_capital">
                                {contactDetails[name]?.relationship}
                              </div>
                            ) : (
                              NOT_AVAILABLE_MESSAGE
                            )}
                          </Form.Item>
                        </Col>
                        <Col span={24} md={24} lg={24} xl={12} xxl={8}>
                          <Form.Item
                            name={[name, 'email']}
                            className="custom_label_style"
                            label={LABEL.EMAIL}
                            rules={[
                              {
                                pattern: new RegExp(regex.EMAIL),
                                message: VALIDATION_MESSAGE.INVALID_EMAIL,
                              },
                            ]}
                          >
                            {contactDetails.length === 0 || isEdit ? (
                              <Input
                                className="note-title-input"
                                disabled={!isEdit}
                              />
                            ) : contactDetails[name]?.email ? (
                              contactDetails[name]?.email.toLowerCase()
                            ) : (
                              NOT_AVAILABLE_MESSAGE
                            )}
                          </Form.Item>
                        </Col>
                        <Col span={24} md={24} lg={24} xl={12} xxl={8}>
                          <Form.Item
                            name={[name, 'phone']}
                            className="custom_label_style"
                            label={LABEL.PHONE_NUMBER}
                            rules={[
                              { required: true },
                              {
                                type: 'string',
                                min: 10,
                                pattern: new RegExp(regex.ONLY_NUMBER),
                                message:
                                  VALIDATION_MESSAGE.INVALID_PHONE_NUMBER,
                              },
                            ]}
                          >
                            {contactDetails.length === 0 || isEdit ? (
                              <Input
                                className="note-title-input"
                                disabled={!isEdit}
                              />
                            ) : contactDetails[name]?.phone ? (
                              contactDetails[name]?.phone
                            ) : (
                              NOT_AVAILABLE_MESSAGE
                            )}
                          </Form.Item>
                        </Col>
                        <Col span={24} md={24} lg={24} xl={12} xxl={8}>
                          <div id="country_list" className="relative">
                            <Form.Item
                              name={[name, 'address', 'country']}
                              className="custom_label_style"
                              label={LABEL.COUNTRY}
                              rules={[{ required: true }]}
                            >
                              {contactDetails.length === 0 || isEdit ? (
                                <SearchableSelect
                                  className="note-title-input"
                                  disabled={!isEdit}
                                  optionFilter="search_prop"
                                  getPopupContainer={(): IGetPopupContainer =>
                                    document.getElementById('country_list')
                                  }
                                >
                                  {COUNTRIES.map((item) => {
                                    return (
                                      <Option
                                        value={item.name}
                                        key={item.code}
                                        search_prop={`${item.name} ${item.code}`}
                                      >
                                        {item.name}
                                      </Option>
                                    );
                                  })}
                                </SearchableSelect>
                              ) : contactDetails[name]?.address?.country ? (
                                contactDetails[name]?.address?.country
                              ) : (
                                NOT_AVAILABLE_MESSAGE
                              )}
                            </Form.Item>
                          </div>
                        </Col>
                        <Col span={24} md={24} lg={24} xl={12} xxl={8}>
                          <Form.Item
                            name={[name, 'address', 'state']}
                            className="custom_label_style"
                            label={LABEL.STATE}
                            rules={[
                              {
                                pattern: new RegExp(regex.ONLY_STRING),
                                message: VALIDATION_MESSAGE.INVALID_TEXT_INPUT,
                              },
                            ]}
                          >
                            {contactDetails.length === 0 || isEdit ? (
                              <Input
                                className="note-title-input"
                                disabled={!isEdit}
                              />
                            ) : contactDetails[name]?.address?.state ? (
                              <div className="initial_capital">
                                {contactDetails[name]?.address?.state}
                              </div>
                            ) : (
                              NOT_AVAILABLE_MESSAGE
                            )}
                          </Form.Item>
                        </Col>
                        <Col span={24} md={24} lg={24} xl={12} xxl={8}>
                          <Form.Item
                            name={[name, 'address', 'city']}
                            className="custom_label_style"
                            label={LABEL.CITY}
                            rules={[
                              {
                                pattern: new RegExp(regex.ONLY_STRING),
                                message: VALIDATION_MESSAGE.INVALID_TEXT_INPUT,
                              },
                            ]}
                          >
                            {contactDetails.length === 0 || isEdit ? (
                              <Input
                                className="note-title-input"
                                disabled={!isEdit}
                              />
                            ) : contactDetails[name]?.address?.city ? (
                              <div className="initial_capital">
                                {contactDetails[name]?.address?.city}
                              </div>
                            ) : (
                              NOT_AVAILABLE_MESSAGE
                            )}
                          </Form.Item>
                        </Col>
                      </Row>
                      {fields.length > 1 ? (
                        <Row justify="end">
                          <Col>
                            <Button
                              type="primary"
                              danger
                              icon={<DeleteOutlined />}
                              onClick={() => {
                                setIsEdit(true);
                                remove(name);
                              }}
                            >
                              {DELETE_BTN}
                            </Button>
                          </Col>
                        </Row>
                      ) : null}
                    </Card>
                  );
                })}
                <Row justify="end">
                  <Form.Item>
                    <Button
                      type="link"
                      onClick={() => {
                        add();
                        setIsEdit(true);
                      }}
                      icon={<PlusOutlined />}
                    >
                      {TEXT.ADD_ANOTHER_CONTACT}
                    </Button>
                  </Form.Item>
                </Row>
              </>
            );
          }}
        </Form.List>
      </Form>
    </Card>
  );
};

export { EmergencyContactDetailsTab };
