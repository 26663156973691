import { IAppointment } from '@shared-components/models';
import moment from 'moment';
import * as actionTypes from './user-appointment.constant';

export const USER_APPOINTMENT_FEATURE_KEY = 'user_appointment';

export interface IAppointmentState {
  userAppointment: IAppointment;
  appointmentData: IAppointment[];
  upcomingData: IAppointment[];
  todayData: IAppointment[];
  error: boolean | null;
  loading: boolean;
}

export const initialAppointmentData: IAppointmentState = {
  userAppointment: {},
  appointmentData: [],
  upcomingData: [],
  todayData: [],
  error: null,
  loading: false,
};

export const userAppointmentReducer = (
  state = initialAppointmentData,
  action: any
) => {
  switch (action.type) {
    case actionTypes.GET_USER_APPOINTMENTS_REQUEST:
      return {
        ...state,
        loading: true,
      };

    case actionTypes.GET_USER_APPOINTMENTS_SUCCESS:
      return {
        ...state,
        loading: false,
        appointmentData: [...action.payload],
        todayData: action.payload.filter(
          (item: { date: moment.MomentInput }) => {
            return (
              moment(item.date).utc().isSame(moment(), 'day') && action.payload
            );
          }
        ),
        upcomingData: action.payload.filter(
          (item: { date: moment.MomentInput }) =>
            !moment(item.date).utc().isSame(moment(), 'day') &&
            !moment(item.date).utc().isBefore() &&
            action.payload
        ),
      };

    case actionTypes.GET_USER_APPOINTMENTS_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.payload,
      };
    case actionTypes.GET_USER_APPOINTMENTS_CLEAR:
      return {
        ...state,
        appointmentData: [],
        loading: false,
        error: null,
      };

    case actionTypes.GET_USER_APPOINTMENT_REQUEST:
      return {
        ...state,
        loading: true,
      };

    case actionTypes.GET_USER_APPOINTMENT_SUCCESS:
      return {
        ...state,
        loading: false,
        userAppointment: action.payload,
      };
    case actionTypes.GET_USER_APPOINTMENT_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.payload,
      };
    case actionTypes.ADD_USER_APPOINTMENT_REQUEST:
      return {
        ...state,
        loading: true,
      };
    case actionTypes.ADD_USER_APPOINTMENT_SUCCESS:
      return {
        ...state,
        loading: false,
        appointmentData: [action.payload, ...state.appointmentData],
      };
    case actionTypes.ADD_USER_APPOINTMENT_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.payload,
      };
    case actionTypes.UPDATE_USER_APPOINTMENT_REQUEST:
      return {
        ...state,
        loading: true,
      };
    case actionTypes.UPDATE_USER_APPOINTMENT_SUCCESS:
      return {
        ...state,
        loading: false,
        appointmentData: state.appointmentData.map((item) => {
          return item.id === action.payload.id ? action.payload : item;
        }),
        upcomingData: state.upcomingData.map((item) => {
          if (
            item.id === action.payload.id &&
            !moment(item.date).utc().isSame(moment(), 'day') &&
            !moment(item.date).utc().isBefore()
          ) {
            return action.payload;
          } else {
            return item;
          }
        }),
        todayData: state.todayData.map((item) => {
          if (
            item.id === action.payload.id &&
            moment(item.date).utc().isSame(moment(), 'day')
          ) {
            return action.payload;
          } else {
            return item;
          }
        }),
      };
    case actionTypes.UPDATE_USER_APPOINTMENT_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.payload,
      };

    case actionTypes.DELETE_USER_APPOINTMENT_REQUEST:
      return {
        ...state,
        loading: true,
      };
    case actionTypes.DELETE_USER_APPOINTMENT_SUCCESS:
      return {
        ...state,
        loading: false,
        appointmentData: state.appointmentData.filter(
          (item) => item.id !== action.payload
        ),
        todayData: state.todayData.filter((item) => item.id !== action.payload),
        upcomingData: state.upcomingData.filter(
          (item) => item.id !== action.payload
        ),
      };
    case actionTypes.DELETE_USER_APPOINTMENT_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.payload,
      };

    default:
      return state;
  }
};

export default userAppointmentReducer;
