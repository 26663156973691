import React, { useEffect } from 'react';
import moment from 'moment';
import { Card, Col, DatePicker, Form, Row } from 'antd';
import { useForm } from 'antd/lib/form/Form';
import {
  CATEGORY_ITEMS,
  GUTTER,
  LABEL,
  NOT_AVAILABLE_MESSAGE,
} from '@moxie/constants';
import { formatDate, SearchableSelect } from '@moxie/shared';
import { IGetPopupContainer } from '@shared-components/models';

const PersonalDetailsTab: React.FC<any> = (props: any) => {
  const { lead } = props;
  const [form] = useForm();

  useEffect(() => {
    form.setFieldsValue({
      date_of_birth: lead?.date_of_birth ? moment(lead?.date_of_birth) : null,
      country_of_birth: lead?.country_of_birth,
      nationality: lead?.nationality,
      gender: lead?.gender,
    });
  }, [lead]);

  return (
    <Card title={CATEGORY_ITEMS[0].name}>
      <Form
        layout="horizontal"
        form={form}
        labelCol={{ span: 10, xl: 9, lg: 6, md: 6 }}
        wrapperCol={{ span: 14, offset: 1 }}
      >
        <Row gutter={GUTTER}>
          <Col span={24} xl={12} xxl={8}>
            <Form.Item
              className="custom_label_style"
              name="gender"
              label={LABEL.GENDER}
            >
              {lead?.gender ? lead?.gender : NOT_AVAILABLE_MESSAGE}
            </Form.Item>
          </Col>
          <Col span={24} xl={12} xxl={8}>
            <Form.Item
              className="custom_label_style"
              name="date_of_birth"
              label={LABEL.DATE_OF_BIRTH}
            >
              {lead?.date_of_birth
                ? formatDate(lead?.date_of_birth)
                : NOT_AVAILABLE_MESSAGE}
            </Form.Item>
          </Col>
          <Col span={24} xl={12} xxl={8}>
            <Form.Item
              className="custom_label_style"
              name="country_of_birth"
              label={LABEL.COUNTRY_OF_BIRTH}
              rules={[{ required: true }]}
            >
              {lead?.country_of_birth
                ? lead?.country_of_birth
                : NOT_AVAILABLE_MESSAGE}
            </Form.Item>
          </Col>
          <Col span={24} xl={12} xxl={8}>
            <Form.Item
              className="custom_label_style"
              name="nationality"
              label={LABEL.NATIONALITY}
            >
              {lead?.nationality ? lead?.nationality : NOT_AVAILABLE_MESSAGE}
            </Form.Item>
          </Col>
        </Row>
      </Form>
    </Card>
  );
};

export { PersonalDetailsTab };
