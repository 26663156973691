/* eslint-disable react/display-name */
import { store } from '@lyra/core';
import { ASSIGNEE_LABEL, LABEL, TEXT, URL_DETAIL_LINK } from '@moxie/constants';
import { formatDateWithTimezone } from '@moxie/shared';
import { LeadTableNameCard, UserNameCard } from '@shared-components/elements';
import { ILead, IOfficeBranch, IUser } from '@shared-components/models';
import { Col, Row, Typography } from 'antd';
import React, { ReactElement, ReactNode } from 'react';
import { Link } from 'react-router-dom';
import { LeadTableAssigneeCard } from './lead-table-assignee-card';

const { Text } = Typography;

const columns = [
  {
    title: TEXT.INTERNAL_ID,
    dataIndex: 'internal_id',
    render: (value: string, data: ILead): ReactNode => {
      return (
        <Link to={`${URL_DETAIL_LINK}${data?.id}/profile-section`}>
          <Text className="text-primary-color initial_capital">
            {data?.company?.company_code + '-' + value}
          </Text>
        </Link>
      );
    },
  },
  {
    title: LABEL.NAME,
    dataIndex: 'first_name',
    sorter: () => 0,
    render: (_text: string, row: ILead, index: number): ReactElement => (
      <LeadTableNameCard
        id={row.id}
        firstName={row.first_name || ''}
        email={row.email || ''}
        lastName={row.last_name}
        data={row}
        index={index.toString()}
      />
    ),
  },
  {
    title: LABEL.PHONE_NUMBER,
    dataIndex: 'phone',
    render: (_text: string, row: ILead): ReactNode => {
      return (
        <Row className="lead-table__phone">
          <Col>
            {row?.country_code}&nbsp;
            {row?.phone}
          </Col>
        </Row>
      );
    },
  },
  {
    title: LABEL.BRANCH,
    dataIndex: 'branch',
    render: (branch: IOfficeBranch): ReactNode => {
      return (
        <div className="lead-table__city">
          <span>{branch ? branch?.name : '-'}</span>
        </div>
      );
    },
  },
  {
    title: ASSIGNEE_LABEL,
    dataIndex: 'assignee_user_id',
    width: 300,
    render: (text: string, data: ILead): ReactElement => {
      return <LeadTableAssigneeCard data={data} />;
    },
  },
  {
    title: LABEL.LAST_UPDATED,
    dataIndex: 'updated_at',
    sorter: () => 0,
    render: (text: string, { user_timezone }: { user_timezone: string }) => {
      return (
        <div className="lead-table__created-at">
          <span>
            {user_timezone && formatDateWithTimezone(text, user_timezone)}
          </span>
        </div>
      );
    },
  },
  {
    title: LABEL.ARCHIVED_BY,
    dataIndex: 'archived_by',
    render: (text: string): ReactElement => {
      const user: IUser | undefined = store
        .getState()
        .users.allData?.find((item: IUser) => item.id === text);
      return (
        <div className="lead-table__archived-by">
          {user && (
            <UserNameCard
              index={user.id || ''}
              actionBar={false}
              firstName={user.first_name || ''}
              lastName={user.last_name}
              email={user.email || ''}
              id={user.id || ''}
            />
          )}
        </div>
      );
    },
  },
];

export { columns };
