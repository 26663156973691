/* eslint-disable no-unused-vars */
import React, { useEffect, useState } from 'react';
import { Button, Col, Drawer, Row, Space } from 'antd';
import { PlusOutlined } from '@ant-design/icons';
import { useDispatch } from 'react-redux';
import { rolePermissionActions } from '@admin/core';
import { RoleForm } from '@admin/shared';
import { RoleTable } from './role-table/RoleTable';
import { PageHeaderMenu, PageHeaderTitle } from '@shared-components/elements';
import {
  ADD_BTN,
  ADD_NEW_ROLE,
  ADD_NEW_ROLE_BTN,
  CANCEL_BTN,
  DRAWER_WIDTH,
  EDIT_ROLE,
  LIST_OF_ROLES,
  PAGE_HEADER_MENU_ROLE,
  SAVE_BTN,
} from '@moxie/constants';
import { Form } from 'antd';
import { IRolePermission } from '@shared-components/models';

const RoleLists: React.FC = () => {
  const [showDrawerVisible, setShowDrawerVisible] = useState(false);
  const [refreshTable, setRefreshTable] = useState(false);
  const [isEdit, setIsEdit] = useState(false);
  const [isEditId, setIsEditId] = useState<string | number | null | undefined>(
    null
  );
  const [form] = Form.useForm();
  const dispatch = useDispatch();

  const triggerRefresh = () => setRefreshTable((prev) => !prev);

  const handleAddUpdateRole = (value: {
    name: string;
    description: string;
    id: string | number | null | undefined;
    permissions?: (string | number)[];
  }) => {
    const { name, description, id, ...permissions } = value;
    if (!name) return;
    const permValuesArr: any = Object.values(permissions).filter(
      (item) => item !== undefined
    );
    const permissionsArr = [].concat(...permValuesArr);
    value.permissions = permissionsArr;
    const data: IRolePermission = {
      name: value.name,
      description: value.description,
      permissions: value.permissions,
    };
    if (isEdit) {
      data.id = isEditId;
      dispatch(
        rolePermissionActions.updateRoleRequest({ data, triggerRefresh })
      );
    } else {
      dispatch(rolePermissionActions.addRoleRequest({ data, triggerRefresh }));
    }
    onClose();
  };

  const handleDelete = async (id: string) => {
    dispatch(rolePermissionActions.deleteRoleRequest({ id, triggerRefresh }));
  };

  const showDrawer = () => {
    form.resetFields();
    setIsEdit(false);
    setShowDrawerVisible(true);
  };

  const onClose = () => {
    setShowDrawerVisible(false);
    setRefreshTable(false);
    setIsEdit(false);
    setIsEditId(null);
    form.resetFields();
    dispatch(rolePermissionActions.clearRolesSuccess());
  };

  const submitAddRole = async () => {
    form.submit();
  };
  const handleEditDrawer = async (id?: string) => {
    setShowDrawerVisible(true);
    setIsEdit(true);
    setIsEditId(id);
    dispatch(rolePermissionActions.getRoleRequest(id));
  };

  useEffect(() => {
    dispatch(rolePermissionActions.getPermissionsRequest());
    return () => {
      dispatch(rolePermissionActions.clearRolesSuccess());
      form.resetFields();
    };
  }, [dispatch, form]);

  return (
    <>
      <Drawer
        title={isEdit ? EDIT_ROLE : ADD_NEW_ROLE}
        width={DRAWER_WIDTH}
        onClose={onClose}
        visible={showDrawerVisible}
        footer={
          <div className="text-align-right">
            <Space size={8}>
              <Button onClick={onClose}>{CANCEL_BTN}</Button>
              <Button onClick={submitAddRole} type="primary">
                {isEdit ? SAVE_BTN : ADD_BTN}
              </Button>
            </Space>
          </div>
        }
      >
        {showDrawerVisible && (
          <RoleForm
            form={form}
            isEdit={isEdit}
            isEditId={isEditId}
            handleSubmit={handleAddUpdateRole}
            hideAction
          />
        )}
      </Drawer>
      <PageHeaderTitle title={LIST_OF_ROLES} />
      <PageHeaderMenu menu={PAGE_HEADER_MENU_ROLE}>
        <Button key="add" type="primary" onClick={showDrawer}>
          <PlusOutlined /> {ADD_NEW_ROLE_BTN}
        </Button>
      </PageHeaderMenu>
      <Row>
        <Col span={24} className="padding-top-2">
          <RoleTable
            showDrawer={handleEditDrawer}
            setRefreshTable={setRefreshTable}
            refreshTable={refreshTable}
            handleDelete={handleDelete}
          />
        </Col>
      </Row>
    </>
  );
};

export default RoleLists;
