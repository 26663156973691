import { ExclamationCircleOutlined } from '@ant-design/icons';
import { Modal } from 'antd';
import React from 'react';

const { confirm } = Modal;

function popConfirm(
  title: string,
  body: string,
  onOk: () => void,
  del = false,
  onCancel?: () => void
): void {
  confirm({
    title: title,
    content: body,
    icon: <ExclamationCircleOutlined className={`${del && 'color-danger'}`} />,
    onOk() {
      onOk();
    },
    onCancel() {
      onCancel?.();
    },
  });
}

function popConfirmCompany(
  title: string,
  body: string,
  onOk: () => void,
  del = false
): void {
  confirm({
    title: title,
    content: body,
    icon: <ExclamationCircleOutlined className={`${del && 'color-danger'}`} />,
    onOk() {
      onOk();
    },
  });
}

export { popConfirm, popConfirmCompany };
