import React, { useEffect } from 'react';
import { Bar } from '@ant-design/plots';

interface IBarChartComponent {
  stages: any;
  applications: any;
}

interface IBarClientComponent {
  clients: any;
  contacts: any;
}
const BarChartComponent: React.FC<IBarChartComponent> = ({
  stages,
  applications,
}: IBarChartComponent) => {
  const data =
    stages?.length > 0 &&
    stages?.map((stage: { stage: any; stageApplication: string | any[] }) => {
      return {
        stage: stage?.stage,
        application: stage?.stageApplication?.length,
      };
    });

  const barConfig: any = {
    data,
    xField: 'application',
    yField: 'stage',
    barWidthRatio: 0.8,
    xAxis: {
      max: Math.max(50, applications?.length),
      tickCount: 0,
    },
    label: {
      autoHide: true,
    },
    meta: {
      stage: {
        alias: 'stage',
      },
      application: {
        alias: 'Number of Applications',
      },
    },
  };

  useEffect(() => {
    return;
  }, [stages]);
  return <div>{data && <Bar {...barConfig} />}</div>;
};

const BarClientComponent: React.FC<IBarClientComponent> = ({
  clients,
  contacts,
}: IBarClientComponent) => {
  const data =
    clients?.length > 0 &&
    clients?.map(
      (client: { first_name: any; last_name: any; count: any; id: string }) => {
        return {
          user_name: `${client?.first_name} ${client?.last_name}`,
          count: Number(client?.count),
          id: client?.id,
        };
      }
    );

  const clientDataConfig: any = {
    data: data,
    xField: 'count',
    yField: 'user_name',
    barWidthRatio: 0.8,
    minBarWidth: 20,
    maxBarWidth: 20,
    height: 50 * data.length,
    limitInPlot: true,
    barStyle: {
      lineWidth: 5,
    },
    xAxis: {
      max: Math.max(10, contacts?.length),
      tickCount: 0,
    },
    label: {
      autoHide: true,
    },
    meta: {
      count: {
        alias: 'Number of clients',
      },
      user_name: {
        alias: 'User',
      },
    },
  };

  useEffect(() => {
    return;
  }, [clients]);
  return <div>{data && <Bar {...clientDataConfig} />}</div>;
};

export { BarChartComponent, BarClientComponent };
