import {
  call,
  CallEffect,
  put,
  PutEffect,
  takeLatest,
} from 'redux-saga/effects';
import { authActions } from './auth.reducer';
import { IAction, ILogin } from '@shared-components/models';
import Cookies from 'js-cookie';
import {
  CRM_COOKIE_ACCESS,
  CRM_COOKIE_REFRESH,
  CRM_EMAIL_LABEL,
} from '@moxie/constants';
import { findLoggedInUser, login, logoutUser } from '@crm/services.api';
import { crmErrorHandler } from 'libs/shared/src/crm-modules/shared';

function* getCurrentUser(): Generator<
  | CallEffect<unknown>
  | PutEffect<{
    payload: any;
    type: string;
  }>
> {
  try {
    const res: any = yield call<typeof findLoggedInUser>(findLoggedInUser);
    localStorage.setItem('branchId', res?.data?.data?.branch_id);
    yield put(authActions.authUser(res?.data?.data));
    yield put(authActions.updateAuthPermission(res.data.data.branchPermission))
  } catch (err) {
    crmErrorHandler(err);
  }
}

function* logout(): Generator<
  | CallEffect<unknown>
  | PutEffect<{
    payload: any;
    type: string;
  }>
> {
  try {
    // TODO: Logic for logout.
    yield call(logoutUser);
    localStorage.removeItem(CRM_EMAIL_LABEL);
    localStorage.removeItem('branchId');
    sessionStorage.removeItem('accessToken');
  } catch (err) {
    crmErrorHandler(err, false);
  }
}

function* loginAccount(
  data: IAction<ILogin>
): Generator<CallEffect<unknown> | PutEffect<IAction<ILogin>>> {
  try {
    yield put(authActions.loading(true));
    let response: any;
    if (data.payload) {
      response = yield call(login, data.payload);
    }

    Cookies.set(CRM_COOKIE_ACCESS, response.data.data.access_token);
    Cookies.set(CRM_COOKIE_REFRESH, response.data.data.refresh_token);
    yield put(authActions.setUser(response.data.data.user));
    yield put(authActions.closeSessionModal());
    yield put(authActions.loading(false));
    yield put(authActions.getAuthUser())
  } catch (err: any) {
    crmErrorHandler(err);
    yield put(authActions.loading(false));
  }
}

function* fetchCurrentUserPermission(): Generator<
  | CallEffect<unknown>
  | PutEffect<{
    payload: any;
    type: string;
  }>
> {
  try {
    const res: any = yield call<typeof findLoggedInUser>(findLoggedInUser);
    yield put(authActions.updateAuthPermission(res.data.data.branchPermission))
  } catch (err) {
    crmErrorHandler(err);
  }
}

export default function* authSaga(): any {
  yield takeLatest(authActions.logout.type, logout);
  yield takeLatest(authActions.login.type, loginAccount);
  yield takeLatest(authActions.getAuthUser.type, getCurrentUser);
  yield takeLatest(authActions.fetchAuthPermission.type, fetchCurrentUserPermission);

}
