import {
  ApartmentOutlined,
  DownOutlined,
  LogoutOutlined,
  MenuFoldOutlined,
  MenuUnfoldOutlined,
  SettingOutlined,
  UserOutlined,
  BellOutlined,
} from '@ant-design/icons';
import { authActions, useAppSelector } from '@crm/core';
import {
  AUTH_MESSAGE,
  CRM_COOKIE_ACCESS,
  CRM_COOKIE_REFRESH,
  DASHBOARD_ROUTES,
  SEARCH_CONTACT,
} from '@moxie/constants';
import { IDashboardHeader } from '@shared-components/models';
import { Avatar, Menu } from 'antd';
import Cookies from 'js-cookie';
import { capitalizeFirstLetter, successNotificationHandler } from 'libs/shared/src/shared';
import React from 'react';
import { useDispatch } from 'react-redux';
import { NavLink, useHistory, useLocation } from 'react-router-dom';
import { RootState } from '../../core/store';
import Badge from 'antd/es/badge';
import NotificationMenu from './notification-menu';
import { useFetchUnReadNotificationCount } from '@crm/libs/hooks/users';
import { getcontactList, logoutUser } from '@crm/services.api';
import { SearchBar } from './dashboard-settings/search-bar';

const { SubMenu } = Menu;

const DashboardHeader = (props: IDashboardHeader): React.ReactNode | any => {

  const { setCollapsed, collapsed } = props;
  const dispatch = useDispatch();
  const history = useHistory();
  const location = useLocation();
  const currentUser = useAppSelector((store: RootState) => store.auth.user);
  const { count } = useFetchUnReadNotificationCount();

  const handleLogout = async () => {
    await logoutUser();
    Cookies.remove(CRM_COOKIE_ACCESS);
    Cookies.remove(CRM_COOKIE_REFRESH);
    dispatch(authActions.logout());
    history.push('/');
    successNotificationHandler(AUTH_MESSAGE.LOGOUT);
  };

  const fetchContacts = async (search: string, page = 1) => getcontactList({ type: 'globalSearch', search, sortBy: 'firstName:ASC', page });

  const GlobalSearchBar = () => {
    return (
      <div className="padding-left-2">
        <SearchBar
          fetchOptions={fetchContacts}
          placeholder={SEARCH_CONTACT}
          className="global-search-bar"
        />
      </div>
    );
  };

  return (
    <nav className="dashboard-header__nav">
      <div
        onClick={() => setCollapsed(!collapsed)}
        className="dashboard-header__nav_trigger"
      >
        {collapsed ? <MenuUnfoldOutlined /> : <MenuFoldOutlined />}
      </div>
      <div className="dashboard-header__nav_menu">
        <GlobalSearchBar />
        <Menu
          mode="horizontal"
          triggerSubMenuAction="click"
          selectedKeys={[location.pathname]}
        >
          <SubMenu
            popupOffset={[-20, 0]}
            key="notificationMenu"
            title={
              <Badge size="small" count={count}>
                <BellOutlined />
              </Badge>
            }
          >
            <NotificationMenu />
          </SubMenu>
          <SubMenu
            popupOffset={[8, 0]}
            key="profileMenu"
            className="lyra-dashboard__layout_header_dropmenu"
            title={
              <div className="drop-menu">
                <div className="drop-menu__info">
                  <Avatar size="large" className="drop-menu__avatar">
                    {currentUser?.firstName?.substring(0, 1)?.toUpperCase()}
                    {currentUser?.lastName?.substring(0, 1)?.toUpperCase()}
                  </Avatar>
                  <div className="drop-menu__text">
                    <h4>
                      {capitalizeFirstLetter(currentUser?.firstName as string)} {capitalizeFirstLetter(currentUser?.lastName as string)}
                    </h4>
                    <p>{currentUser?.email}</p>
                  </div>
                </div>
                <DownOutlined />
              </div>
            }
          >
            <Menu.Divider />
            <Menu.Item
              key={DASHBOARD_ROUTES.profile.path}
              icon={<UserOutlined />}
            >
              <NavLink exact to={DASHBOARD_ROUTES.profile.path}>
                {DASHBOARD_ROUTES.profile.name}
              </NavLink>
            </Menu.Item>
            <Menu.Item
              key={DASHBOARD_ROUTES.accountSettings.path}
              icon={<SettingOutlined />}
            >
              <NavLink exact to={DASHBOARD_ROUTES.accountSettings.path}>
                {DASHBOARD_ROUTES.accountSettings.name}
              </NavLink>
            </Menu.Item>
            <Menu.Item
              key={DASHBOARD_ROUTES.settings.path}
              icon={<ApartmentOutlined />}
            >
              <NavLink exact to={DASHBOARD_ROUTES.settings.path}>
                {DASHBOARD_ROUTES.settings.name}
              </NavLink>
            </Menu.Item>
            <Menu.Divider />
            <Menu.Item
              key="2"
              icon={<LogoutOutlined />}
              onClick={handleLogout}
              danger
            >
              {DASHBOARD_ROUTES.logout.name}
            </Menu.Item>
          </SubMenu>
        </Menu>
      </div>
    </nav>
  );
};

export default DashboardHeader;
