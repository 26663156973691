import axios from './axios';
import { AxiosResponse } from 'axios';
import { IRole } from '@shared-components/models';
import { API_URL } from '@moxie/constants';

const baseUrl = process.env.NX_BASE_URL;
const endpoint_roles = `${baseUrl}${API_URL.ROLE}`;
const endpoint_permissions = `${baseUrl}${API_URL.PERMISSIONS}`;

export const RolesApi = {
  getRolesApi: async (): Promise<AxiosResponse> => {
    return axios.get(`${baseUrl}/am/roles`);
  },
  setRolesApi: async (body: {
    payload: string | number;
  }): Promise<AxiosResponse> => {
    return axios.get(`${endpoint_roles}/${body.payload}`);
  },
  addRoleApi: async (data: IRole): Promise<AxiosResponse> => {
    return axios.post(`${endpoint_roles}`, data);
  },
  updateRoleApi: async (data: IRole, id: number): Promise<AxiosResponse> => {
    return axios.put(`${endpoint_roles}/${id}`, data);
  },
  deleteRoleApi: async (data: string | number): Promise<AxiosResponse> => {
    return axios.delete(`${endpoint_roles}/${data}`);
  },
  getPermissionsApi: async (): Promise<AxiosResponse> => {
    return axios.get(`${endpoint_permissions}`);
  },
};
