import React from 'react';
import {
  CANCEL_BTN,
  CREATE_WORKFLOW_TITLE,
  DRAWER_WIDTH,
  EDIT_WORKFLOW_TITLE,
  SAVE_BTN,
  UPDATE_BTN,
} from '@moxie/constants';
import { Space } from 'antd';
import { Button, DrawerElem } from '@moxie/shared';
import { IWorkflowTypeDrawerComponent } from '@shared-components/models';
import { WorkflowForm } from '../../forms/workflow/workflow-form';
import { useAppSelector } from '@admin/core';

const WorkflowDrawer: React.FC<IWorkflowTypeDrawerComponent> = ({
  visible,
  onClose,
  disabled,
  setDisabled,
  form,
  handleFormSubmit,
  handleSubmit,
  isEdit,
  submitted,
}: IWorkflowTypeDrawerComponent) => {
  const workflowTypeList = useAppSelector(
    (state) => state.workflow_types.allData
  );
  return (
    <DrawerElem
      title={isEdit ? EDIT_WORKFLOW_TITLE : CREATE_WORKFLOW_TITLE}
      width={DRAWER_WIDTH}
      visible={visible}
      onClose={onClose}
      footer={
        <div className="padding-bottom-2 text-align-right">
          <Space direction="horizontal">
            <Button onClick={onClose} className="lead-margin-right">
              {CANCEL_BTN}
            </Button>
            <Button
              type="primary"
              onClick={handleFormSubmit}
              disabled={disabled}
            >
              {isEdit ? UPDATE_BTN : SAVE_BTN}
            </Button>
          </Space>
        </div>
      }
    >
      <WorkflowForm
        form={form}
        onSubmit={handleSubmit}
        workflowTypeList={workflowTypeList}
        setDisabled={setDisabled}
        submitted={submitted}
      />
    </DrawerElem>
  );
};

export { WorkflowDrawer };
