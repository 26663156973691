import React from 'react';
import { Avatar, Button, Col, Row, Tag, Tooltip } from 'antd';
import {
  EditOutlined,
  BarsOutlined,
  AppstoreAddOutlined,
  RollbackOutlined,
} from '@ant-design/icons';
import { AM_PERMISSIONS, TEXT } from '@moxie/constants';
import { ILeadProfilePersonal } from '@shared-components/models';
import { useAppSelector } from '@admin/core';
import permit from '@lyra/permit';
import isAssignedFollowed from "@lyra/assigned-followed";

const { CONTACTS } = AM_PERMISSIONS;

const LeadProfilePersonal: React.FC<ILeadProfilePersonal> = ({
  first_name,
  last_name,
  lead,
  internal_id,
  branch,
  user,
  handleView,
  handleOpen,
  handleArchive,
}: ILeadProfilePersonal) => {
  const loggedInUser = useAppSelector((state) => state?.auth?.user);
  const rolePerms: string[] = useAppSelector(
    (state: any) => state?.auth?.user?.am_role?.permissions
  );

  return (
    <Row justify="space-between">
      <Col>
        <div className="padding-top-1">
          <div className="profile__info__item profile__info__user">
            <Avatar size="large" className="initial_capital">
              {first_name?.substring(0, 1) +
                last_name?.substring(0, 1).toUpperCase()}
            </Avatar>
            <div className="profile__info__user__detail initial_capital">
              <h3>
                {first_name} {last_name}
              </h3>
              {lead?.archived && <Tag color="#ef9447">{TEXT.ARCHIVED}</Tag>}
            </div>
          </div>
        </div>
        <div className="padding-top-1">
          <h1 className="profile__title">
            {TEXT.INTERNAL_ID}:{' '}
            <span className="text-link-color">
              {lead?.company?.company_code + '-' + internal_id}
            </span>
          </h1>
        </div>
      </Col>
      <Col md={{ span: 24, order: 3 }}>
        <div>
          <h4 className="profile__title">
            {TEXT.BRANCH}:{' '}
            <span className="text-link-color ">
              {branch ? branch?.name : '-'}
            </span>
          </h4>
        </div>
        <div>
          <h4 className="profile__title">
            {TEXT.CREATED_BY}&nbsp;
            <span className="text-link-color ">
              {user?.first_name} {user ? user?.last_name : '-'}
            </span>
          </h4>
        </div>
      </Col>
      <Col>
        <div className="profile__info__edit">
          <Tooltip placement="right" title={TEXT.VIEW_LEAD_DETAIL}>
            <Button icon={<BarsOutlined />} onClick={handleView} type="link" />
          </Tooltip>
        </div>
        {
          (
            isAssignedFollowed("contacts", lead, loggedInUser?.id) || permit([CONTACTS.MANAGE], rolePerms)
          )
          &&
          !lead?.archived && (
            <>
              <div className="profile__info__edit">
                <Tooltip placement="right" title={TEXT.EDIT_LEAD_DETAIL}>
                  <Button
                    icon={<EditOutlined />}
                    onClick={handleOpen}
                    type="link"
                  />
                </Tooltip>
              </div>
            </>
          )}
        {
          (
            isAssignedFollowed("contacts", lead, loggedInUser?.id) || permit([CONTACTS.MANAGE], rolePerms)
          )
          &&
          (
            <div className="profile__info__edit">
              <Tooltip
                placement="right"
                title={lead?.archived ? TEXT.RESTORE_LEAD : TEXT.ARCHIVE_LEAD}
              >
                <Button
                  icon={
                    lead?.archived ? (
                      <RollbackOutlined />
                    ) : (
                      <AppstoreAddOutlined />
                    )
                  }
                  onClick={handleArchive}
                  type="link"
                />
              </Tooltip>
            </div>
          )}
      </Col>
    </Row>
  );
};

export { LeadProfilePersonal };
