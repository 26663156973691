import { ILead } from '@shared-components/models';
import React, { useState } from 'react';

const LeadContext = React.createContext<any | ILead>(undefined);

const LeadContextProvider: React.FC<{ children: React.ReactNode }> = ({
  children,
}: {
  children: React.ReactNode;
}) => {
  const [initialData, setInitialData] = useState<ILead>();
  const [isOpen, setOpen] = useState(false);
  const [disable, setDisable] = useState(true);
  const [refreshTable, setRefreshTable] = useState(true);

  const triggerRefresh = () => setRefreshTable((prev) => !prev);

  return (
    <LeadContext.Provider
      value={[
        initialData,
        setInitialData,
        isOpen,
        setOpen,
        disable,
        setDisable,
        refreshTable,
        triggerRefresh,
      ]}
    >
      {children}
    </LeadContext.Provider>
  );
};

export { LeadContextProvider, LeadContext };
