import React, { useState } from 'react';
import { LABEL, PRODUCT_TABLE, TEXT } from '@moxie/constants';
import { Table } from '@shared-components/elements';
import { IInstitutionProducts } from '@shared-components/models';
import { Tag } from 'antd';
import { ColumnsType } from 'antd/lib/table';
import { instituteServices } from '@lyra/services.api';

const InstitutionDetailProducts: React.FC<any> = ({ id }: { id: any }) => {
  const [filter] = useState({});

  const getProductData = async (params: string) => {
    return instituteServices.apiGetInstitutionProducts(id, params);
  };

  const columns: ColumnsType<IInstitutionProducts> = [
    {
      title: LABEL.NAME,
      width: 550,
      dataIndex: 'name',
      sorter: () => 0,
    },
    {
      title: TEXT.BRANCH,
      dataIndex: 'institutionBranches',
      width: 550,
      render(data: any) {
        return data?.map((item: any) => (
          <Tag key={item.id}>{item?.branch?.name}</Tag>
        ));
      },
    },
    {
      title: PRODUCT_TABLE.DEGREE_LEVEL,
      dataIndex: 'level',
    },
  ];

  return (
    <div className="margin-top-1">
      <Table columns={columns} action={getProductData} filter={filter} />
    </div>
  );
};
export default InstitutionDetailProducts;
