import React from 'react';
import { PAGE_HEADER_MENU_CLIENTS, TEXT } from '@moxie/constants';
import { PageHeaderTitle } from '@shared-components/elements';
import { ClientListTable } from './client-table';
import { getAllClients } from '@admin/services.api';
import { PageHeaderMenu } from './components/page-header-menu';

const ClientListComponent: React.FC = () => {
  const getClients = async (params: string | undefined) => {
    return getAllClients(params);
  };
  return (
    <div className="full-height">
      <PageHeaderTitle title={TEXT.CLIENTS} />
      <PageHeaderMenu menu={PAGE_HEADER_MENU_CLIENTS} />
      <ClientListTable getData={getClients} tableName={TEXT.CLIENTS} />
    </div>
  );
};

export { ClientListComponent };
