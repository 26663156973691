import React, { useState, useEffect } from 'react';
import { Col, Row, FormItemProps, Skeleton } from 'antd';
import { IForm, IGetPopupContainer, ILead } from '@shared-components/models';
import Form from 'antd/lib/form';
import {
  ASSIGNEE_PLACEHOLDER,
  ASSIGNEE_LABEL,
  DEFAULT_COUNTRY_CODE,
  EMAIL_LABEL,
  EMAIL_PLACEHOLDER,
  FIRST_NAME_LABEL,
  FIRST_NAME_PLACEHOLDER,
  LAST_NAME_LABEL,
  LAST_NAME_PLACEHOLDER,
  PHONE_LABEL,
  PHONE_PLACEHOLDER,
  regex,
  PHONE_NUMBER_INVALID,
  GUTTER,
  LEAD_INITIAL_DATA,
  AUTH_MESSAGE,
} from '@moxie/constants';
import { Input, PhoneInput } from 'libs/shared/src/shared';
import moment, { Moment } from 'moment';
import { checkLeadEmail } from '@lyra/services.api';
import { useAppSelector, userActions } from '@lyra/core';
import { useDispatch } from 'react-redux';
import { UserSelectBox } from '@shared-components/elements';
import { RootState } from 'apps/lyra/src/core/store';

const LeadForm: React.FC<IForm<ILead>> = ({
  form,
  onSubmit,
  isView,
  initialData,
  setDisabled,
}: IForm<ILead>) => {
  const dispatch = useDispatch();
  const [country_code, setCountryCode] = useState<string | any>(
    DEFAULT_COUNTRY_CODE
  );
  const [emailValidationStatus, setEmailValidationStatus] = useState<
    FormItemProps['validateStatus']
  >('');
  const { loading } = useAppSelector((store) => ({
    loading: store.users.loading,
  }));

  const currentUser: any = useAppSelector(
    (store: RootState) => store.auth.user
  );

  const onFieldsChange = (allFields: string | any[]) => {
    if (allFields.length === 1) {
      setDisabled(false);
    }
  };

  useEffect(() => {
    if (initialData?.id) {
      dispatch(userActions.fetchFollowerUsers(initialData?.id));
    }
  }, [initialData?.id]);

  useEffect(() => {
    if (initialData) {
      initialData.date_of_birth = changeDateToMoment(
        initialData?.date_of_birth
      );
      initialData.date_of_birth = changeDateToMoment(initialData.date_of_birth);
      initialData.visa_expiry_date = changeDateToMoment(
        initialData.visa_expiry_date
      );
      initialData.passport_expiry_date = changeDateToMoment(
        initialData.passport_expiry_date
      );
      setCountryCode(initialData?.country_code || DEFAULT_COUNTRY_CODE);
      form.setFieldsValue(initialData);
    } else {
      form.setFieldsValue(LEAD_INITIAL_DATA);
    }
    form.setFieldsValue({
      assignee_user_id:
        initialData?.assignee_user_id !== undefined
          ? initialData?.assignee_user_id
          : currentUser?.id,
    });
  }, [form, initialData, isView]);

  const changeDateToMoment = (date?: string | Moment): Moment | undefined => {
    if (date) return moment(date);
    return;
  };

  const handleSubmit = async (data: ILead) => {
    data.country_code = country_code;
    data.email = data?.email?.replace(/ /g, '');
    data.phone = data?.phone?.replace(/ /g, '');
    onSubmit && onSubmit(data);
  };

  const handleCountryCodeChange = (input: string) => {
    setCountryCode(input);
    setDisabled(false);
  };

  const handleEmailChange = async (rule: any, value: any): Promise<any> => {
    if (initialData?.email === value) return true;
    value = value?.toLowerCase();
    if (value.length === 0) {
      setEmailValidationStatus('error');
      return Promise.resolve(true);
    } else {
      const trimValue = value.replace(/ /g, '');
      setEmailValidationStatus('validating');

      if (String(trimValue).match(new RegExp(regex.EMAIL))) {
        const result = await validateEmail({ email: trimValue });

        if (result) {
          setEmailValidationStatus('success');
          return Promise.resolve(true);
        } else {
          setEmailValidationStatus('error');
          throw new Error(AUTH_MESSAGE.REGISTER_EMAIL_TAKEN);
        }
      } else {
        setEmailValidationStatus('error');
      }
    }
  };

  const validateEmail = async (value: { email: string }) => {
    try {
      const res = await checkLeadEmail(value?.email);
      return !!res?.data?.success;
    } catch (err) {
      return false;
    }
  };

  return (
    <>
      {loading && initialData?.id ? (
        <Skeleton active />
      ) : (
        <Form
          layout="vertical"
          onFinish={handleSubmit}
          form={form}
          scrollToFirstError={true}
          onFieldsChange={onFieldsChange}
        >
          <Row gutter={GUTTER}>
            <Col span={12}>
              <Form.Item
                name="first_name"
                label={FIRST_NAME_LABEL}
                validateFirst={true}
                rules={[
                  { required: true },
                  {
                    pattern: new RegExp(regex.ONLY_STRING),
                    message: AUTH_MESSAGE.INVALID_FIRST_NAME,
                  },
                  { max: 50 },
                ]}
              >
                <Input
                  data-testid="first_name"
                  className="note-title-input"
                  placeholder={FIRST_NAME_PLACEHOLDER}
                  disabled={isView}
                />
              </Form.Item>
            </Col>
            <Col span={12}>
              <Form.Item
                name="last_name"
                label={LAST_NAME_LABEL}
                validateFirst={true}
                rules={[
                  { required: true },
                  {
                    pattern: new RegExp(regex.ONLY_STRING),
                    message: AUTH_MESSAGE.INVALID_LAST_NAME,
                  },
                  { max: 50 },
                ]}
              >
                <Input
                  data-testid="last_name"
                  className="note-title-input"
                  placeholder={LAST_NAME_PLACEHOLDER}
                  disabled={isView}
                />
              </Form.Item>
            </Col>
          </Row>
          <Row gutter={GUTTER}>
            <Col span={12}>
              <Form.Item
                name="email"
                label={EMAIL_LABEL}
                validateStatus={emailValidationStatus}
                rules={[
                  {
                    required: true,
                    pattern: new RegExp(regex.EMAIL),
                    message: AUTH_MESSAGE.INVALID_EMAIL,
                  },
                  {
                    validator: handleEmailChange,
                  },
                ]}
              >
                <Input
                  data-testid="email"
                  className="note-title-input"
                  placeholder={EMAIL_PLACEHOLDER}
                  disabled={isView}
                />
              </Form.Item>
            </Col>
            <Col span={12}>
              <Form.Item
                name="phone"
                label={PHONE_LABEL}
                className="country_dial_code"
                rules={[
                  {
                    required: true,
                    pattern: new RegExp(regex.ONLY_NUMBER),
                    message: PHONE_NUMBER_INVALID,
                  },
                ]}
              >
                <PhoneInput
                  data-testid="phone"
                  className="note-title-input"
                  type="string"
                  placeholder={PHONE_PLACEHOLDER}
                  disabled={isView}
                  dialCode={country_code}
                  onChangeDialCode={handleCountryCodeChange}
                />
              </Form.Item>
            </Col>
          </Row>
          <Row gutter={GUTTER}>
            <Col span={24}>
              <div id="lead_assignee_id" className="relative">
                <Form.Item
                  name="assignee_user_id"
                  data-testid="assignee_user_id"
                  label={ASSIGNEE_LABEL}
                  required={true}
                >
                  <UserSelectBox
                    className="note-title-input"
                    getPopupContainer={(): IGetPopupContainer =>
                      document.getElementById('lead_assignee_id')
                    }
                    placeholder={!isView ? ASSIGNEE_PLACEHOLDER : '-'}
                    disabled={isView}
                    showUnassigned={true}
                    allowClear={false}
                  />
                </Form.Item>
              </div>
            </Col>
          </Row>
        </Form>
      )}
    </>
  );
};

export { LeadForm };
