import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import {
  Badge,
  Button,
  Col,
  Dropdown,
  Menu,
  Row,
  Space,
  Tooltip,
  Typography,
} from 'antd';
import {
  EditOutlined,
  DeleteOutlined,
  MoreOutlined,
  FilterOutlined,
  PlusOutlined,
} from '@ant-design/icons';
import {
  ADD_PRODUCT,
  COLUMNS,
  DELETE_BTN,
  EDIT_BTN,
  LABEL,
  TEXT,
  URL_INSTITUTION_DETAIL_LINK,
  URL_PRODUCT_DETAIL_LINK,
} from '@moxie/constants';
import { InstitutionBranchTagGroup, Table } from '@shared-components/elements';
import {
  IInstituteInterface,
  IProduct,
  IProductType,
  ITableProps,
} from '@shared-components/models';
import { ProductFilterDrawer } from '@moxie/shared';
import { ProductTableExpandable } from '@admin/shared';
import { useAppSelector } from '@lyra/core';

const { Text } = Typography;

const productNameMenu = ({ data, onDelete, onEdit }: any) => {
  return (
    <Menu>
      <Menu.Item key={2} onClick={() => onEdit(data.id)}>
        <Space direction="horizontal">
          <EditOutlined />
          {EDIT_BTN}
        </Space>
      </Menu.Item>

      <Menu.Item key={3} danger onClick={() => onDelete(data.id)}>
        <Space direction="horizontal">
          <DeleteOutlined />
          {DELETE_BTN}
        </Space>
      </Menu.Item>
    </Menu>
  );
};
export const TextProductNameCard = ({
  menuActions,
  text,
  row,
}: {
  text: string | number;
  row: any;
  menuActions: any;
}) => {
  return (
    <Row justify="space-between">
      <Col span={18}>
        <Link to={`${URL_PRODUCT_DETAIL_LINK}${row.id}/documents`}>
          <Text
            className="text-primary-color product-name-table ellipsis-text"
            ellipsis={String(text).length >= 45 ? { tooltip: text } : false}
          >
            {text}
          </Text>
        </Link>
      </Col>
      <Col span={3}>
        <Dropdown overlay={productNameMenu({ ...menuActions, data: row })}>
          <Button
            type="text"
            className="table-cell-action-btn"
            icon={<MoreOutlined />}
          />
        </Dropdown>
      </Col>
    </Row>
  );
};

export const TextInstitutionLinkField = ({
  text,
  institutionId,
}: {
  text: string | number;
  institutionId: string | number;
}) => {
  return (
    <div>
      <Link to={`${URL_INSTITUTION_DETAIL_LINK}${institutionId}/branches`}>
        <Text
          className="text-primary-color ellipsis-text margin-bottom-half"
          ellipsis={String(text).length >= 45 ? { tooltip: text } : false}
        >
          {text}
        </Text>
      </Link>
    </div>
  );
};

export const columns = (menuActions: any) => {
  return [
    {
      title: LABEL.NAME,
      dataIndex: 'name',
      sorter: () => 0,
      key: 'name',
      width: 450,
      render(data: string, row: IProduct) {
        return (
          <TextProductNameCard
            menuActions={menuActions}
            text={data && data}
            row={row}
          />
        );
      },
    },

    {
      title: LABEL.INSTITUTION_BRANCHES,
      dataIndex: 'institution',
      key: 'institution',
      innerWidth: 450,
      render(institution: IInstituteInterface, data: IProduct) {
        const productBranches: any[] = [];
        data?.institutionBranches?.forEach((item: any) => {
          item?.branch && productBranches.push(item.branch);
        });
        return (
          <>
            <div>
              <Link
                to={`${URL_INSTITUTION_DETAIL_LINK}${institution?.id}/branches`}
              >
                <Text
                  className="text-primary-color ellipsis-text margin-bottom-half"
                  ellipsis={
                    String(institution?.name).length >= 45
                      ? { tooltip: institution?.name }
                      : false
                  }
                >
                  {institution && institution?.name}
                </Text>
              </Link>
            </div>
            <InstitutionBranchTagGroup
              data={productBranches}
              numberOfBranch={3}
            />
          </>
        );
      },
    },

    {
      title: COLUMNS.PRODUCT_TYPE,
      dataIndex: 'productType',
      key: 'productType',
      render(productType: IProductType, data: IProduct) {
        return (
          <>
            <div>
              <Text>{data?.productSubType?.name}</Text>
            </div>
            <Text>
              <small className="table-mail-icon text-icon">
                {productType?.name}
              </small>
            </Text>
          </>
        );
      },
    },
    {
      title: TEXT.REVENUE_TYPE,
      dataIndex: 'revenue_type',
      key: 'revenue_type',
    },
    {
      title: LABEL.ENROLLED,
      dataIndex: 'enrolled',
      key: 'enrolled',
      render(data: string) {
        return data ? data : '-';
      },
    },
    {
      title: LABEL.IN_PROGRESS,
      dataIndex: 'inprogress',
      key: 'inprogress',
      render(data: string) {
        return data ? data : '-';
      },
    },
  ];
};
const ProductTable: React.FC<ITableProps> = ({
  getData,
  refreshTable,
  tableName,
  deleteData,
  showDrawer,
}: ITableProps) => {
  const [filter, setFilter] = useState<any>({});
  const [showFilter, setShowFilter] = useState(false);
  const [filterCount, setFilterCount] = useState(0);
  const handleToggleFilter = () => {
    setShowFilter(!showFilter);
  };
  const menuActions = {
    onEdit: showDrawer,
    onDelete: deleteData,
  };

  const tableLoading = useAppSelector((state) => state.product.loading);

  useEffect(() => {
    const filtersCount = Object.keys(filter).filter(function (k: any) {
      return filter[k] !== null;
    });
    setFilterCount(filtersCount.length);
  }, [filter]);

  return (
    <>
      <ProductFilterDrawer
        setFilter={setFilter}
        filter={filter}
        setShowFilter={setShowFilter}
        handleToggleFilter={handleToggleFilter}
        showFilter={showFilter}
      />
      <div className="bg-white padding-top-2">
        <Table
          columns={columns(menuActions)}
          tableName={tableName}
          filterOptions={true}
          filter={filter}
          refresh={refreshTable}
          className="interested-service-table"
          action={getData}
          rowSelect={false}
          tableLoading={tableLoading}
          tableButtons={
            <Button type="primary" onClick={() => showDrawer && showDrawer()}>
              <PlusOutlined />
              {ADD_PRODUCT}
            </Button>
          }
          expandable={{
            expandedRowRender(data: IProduct) {
              return <ProductTableExpandable data={data} />;
            },
          }}
          filterContent={
            <Button className="filter-btn" onClick={handleToggleFilter}>
              <FilterOutlined />
              <span>{TEXT.FILTERS}</span>
              {filterCount > 0 && (
                <Badge
                  count={filterCount}
                  size="small"
                  className="margin-left-1"
                />
              )}
            </Button>
          }
        />
      </div>
    </>
  );
};
export { ProductTable };
