import React, { useState } from 'react';
import { Divider, Typography, Row } from 'antd';
import { TEXT } from '@moxie/constants';
import { IUserAppointment } from '@shared-components/models';
import AppointmentCard from '../../leads-appointment/libs/appointment-card';

const UserAppointmentContent: React.FC<IUserAppointment> = ({
  currentUser,
  today,
  upcoming,
  triggerRefresh,
}: IUserAppointment) => {
  const [changeStatus, setChangeStatus] = useState<boolean>(false);

  return (
    <>
      {today && today.length > 0 ? (
        <>
          <Divider orientation="left">
            <Typography.Text type="secondary">
              {TEXT.TODAY} ({today.length})
            </Typography.Text>
          </Divider>
          <Row>
            <AppointmentCard
              currentUser={currentUser}
              setChangeStatus={setChangeStatus}
              changeStatus={changeStatus}
              triggerRefresh={triggerRefresh}
              data={today}
              title={TEXT.USER_APPOINTMENT}
            />
          </Row>
        </>
      ) : (
        <></>
      )}
      {upcoming && upcoming.length > 0 && (
        <>
          <Divider orientation="left">
            <Typography.Text type="secondary">
              {TEXT.UPCOMING} ({upcoming.length})
            </Typography.Text>
          </Divider>
          <Row>
            <AppointmentCard
              currentUser={currentUser}
              setChangeStatus={setChangeStatus}
              changeStatus={changeStatus}
              data={upcoming}
              triggerRefresh={triggerRefresh}
              title={TEXT.USER_APPOINTMENT}
            />
          </Row>
        </>
      )}
    </>
  );
};

export default UserAppointmentContent;
