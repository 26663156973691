import React from 'react';
import BoldOutlined from '@ant-design/icons/BoldOutlined';
import ItalicOutlined from '@ant-design/icons/ItalicOutlined';
import UnderlineOutlined from '@ant-design/icons/UnderlineOutlined';
import UnorderedListOutlined from '@ant-design/icons/UnorderedListOutlined';
import { Editor } from '@tiptap/react';
import { classNames } from '@moxie/constants';
import UndoOutlined from '@ant-design/icons/UndoOutlined';
import RedoOutlined from '@ant-design/icons/RedoOutlined';

interface Props {
  editor: Editor
}

const ActionMenu: React.FC<Props> = ({ editor }) => {

  return (
    <div className="tiptap-action-menu">
      <BoldOutlined
        className={classNames(editor.isActive('bold') ? 'active' : null)}
        onClick={() => editor.chain().focus().toggleBold().run()}
      />
      <ItalicOutlined
        className={classNames(editor.isActive('italic') ? 'active' : null)}
        onClick={() => editor.chain().focus().toggleItalic().run()}
      />
      <UnderlineOutlined
        className={classNames(editor.isActive('underline') ? 'active' : null)}
        onClick={() => editor.chain().focus().toggleUnderline().run()}
      />
      <UnorderedListOutlined
        className={classNames(editor.isActive('bulletList') ? 'active' : null)}
        onClick={() => editor.chain().focus().toggleBulletList().run()}
      />
      <UndoOutlined
        onClick={() => editor.chain().focus().undo().run()}
        disabled={!editor.can().undo()}
      />
      <RedoOutlined
        onClick={() => editor.chain().focus().redo().run()}
        disabled={!editor.can().redo()}
      />

    </div>
  )
}
export default ActionMenu;
