import React, { ReactNode, useState } from 'react';
import { PLACEHOLDER, TEXT } from '@moxie/constants';
import { useQuery } from '@moxie/utils';
import {
  ILeadDocumentData,
  ILeadDocumentTable,
} from '@shared-components/models';
import { Table } from '@moxie/shared';
import { DocumentNameCard } from '@shared-components/modules';
import { ColumnsType } from 'antd/lib/table';
import moment from 'moment';
import { Typography } from 'antd';

const ContactDocumentTable: React.FC<ILeadDocumentTable> = ({
  refreshTable,
  documentData,
  triggerRefresh,
}) => {
  const [filter] = useState({});

  const columns: ColumnsType<ILeadDocumentData> = [
    {
      title: TEXT.ID,
      key: 'id',
      width: 100,
      render: (
        data: ILeadDocumentData,
        row: ILeadDocumentData,
        index: number
      ): ReactNode => {
        const pageNumber = useQuery().get('pageNo') || 1;
        const limit = useQuery().get('limit') || 10;
        return (pageNumber - 1) * limit + index + 1;
      },
    },
    {
      title: TEXT.FILE_NAME,
      width: 300,
      ellipsis: true,
      render(data: ILeadDocumentData): ReactNode {
        return (
          <DocumentNameCard
            triggerRefresh={triggerRefresh}
            data={data?.document}
            isAdmin={true}
          />
        );
      },
    },
    {
      title: TEXT.ADDED_BY,
      width: 300,
      sorter: () => 0,
      render(record: ILeadDocumentData): ReactNode {
        return record?.document?.addedBy ? (
          <div>
            <Typography.Text>
              {record?.document?.addedBy?.first_name}{' '}
              {` ${record?.document?.addedBy?.last_name}`}
            </Typography.Text>
            <div>
              <Typography.Text>
                {record?.document?.addedBy?.email}
              </Typography.Text>
            </div>
          </div>
        ) : (
          '-'
        );
      },
    },
    {
      title: PLACEHOLDER.DOCUMENT_TYPE,
      width: 300,
      dataIndex: 'type',
      sorter: () => 0,
      render(_text: string, data: ILeadDocumentData): ReactNode {
        return (
          <Typography.Text>{data.document.document_type ?? ''}</Typography.Text>
        );
      },
    },
    {
      title: TEXT.FILE_SIZE,
      width: 250,
      dataIndex: 'size',
      render(_text: string, data: ILeadDocumentData): ReactNode {
        return <span>{Math.round(data?.document?.size / 1024)} &nbsp;KB</span>;
      },
    },
    {
      title: TEXT.ADDED_ON,
      width: 300,
      render: (data: ILeadDocumentData): ReactNode => {
        return moment(data?.document?.created_at).format('MMM DD, YYYY');
      },
    },
  ];

  return (
    <div>
      <Table
        columns={columns}
        action={documentData}
        tableName={TEXT.DOCUMENTS}
        filter={filter}
        className="lead-table"
        refresh={refreshTable}
      />
    </div>
  );
};

export default ContactDocumentTable;
