import { postNotes, getAllNotes } from '@lyra/services.api';
import { takeLatest, put } from '@redux-saga/core/effects';
import * as actionTypes from './lead-notes.constant';
import * as actionNotes from './lead-notes.action';
import { errorHandler } from '@moxie/utils';
import { AxiosResponse } from 'axios';
import { INote, IAction } from '@shared-components/models';

function* getLeadNotes({ payload }: IAction<string>) {
  try {
    if (payload) {
      const response: AxiosResponse = yield getAllNotes(payload);
      yield put(actionNotes.getNotesSuccess(response.data.data.data));
    }
  } catch (err) {
    errorHandler(err);
    yield put(actionNotes.getNotesFailure(err));
  }
}

function* addLeadNote({ payload }: IAction<INote>) {
  try {
    if (payload) {
      if (!payload.parent_id) return;
      const response: AxiosResponse = yield postNotes(
        payload,
        payload.parent_id
      );
      yield put(actionNotes.addNoteSuccess(response.data));
    }
  } catch (err) {
    errorHandler(err);
    yield put(actionNotes.addNoteFailure(err));
  }
}

export default function* rootSage(): unknown {
  yield takeLatest(actionTypes.GET_NOTES_REQUEST, getLeadNotes);
  yield takeLatest(actionTypes.ADD_NOTE_REQUEST, addLeadNote);
}
