import * as actionTypes from './client-activities.constant';
import { IAction } from '@shared-components/models';

export const CLIENT_ACTIVITIES_FEATURE_KEY = 'clientActivities';
export interface ClientActivitiesState {
  clientActivities: any[];
  loading: boolean;
  error: null;
}

export const initialClientActivitiesState: ClientActivitiesState = {
  clientActivities: [],
  loading: false,
  error: null,
};

export const ClientActivitiesReducer = (
  state = initialClientActivitiesState,
  action: IAction<any>
) => {
  switch (action.type) {
    case actionTypes.GET_CLIENT_ACTIVITIES_REQUEST:
      return {
        ...state,
        loading: true,
      };
    case actionTypes.GET_CLIENT_ACTIVITIES_SUCCESS:
      return {
        ...state,
        loading: false,
        error: null,
        clientActivities: [...action.payload],
      };
    case actionTypes.GET_CLIENT_ACTIVITIES_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.payload,
      };
    case actionTypes.GET_CLIENT_ACTIVITIES_CLEAR:
      return {
        ...state,
        loading: false,
        error: null,
        clientActivities: [],
      };
    case actionTypes.ADD_CLIENT_ACTIVITY_SUCCESS:
      return {
        ...state,
        loading: false,
        error: null,
        clientActivities: [...action.payload, ...state.clientActivities],
      };
    default:
      return state;
  }
};
