import React from 'react';
import moment from 'moment-timezone';
import { Typography } from 'antd';
import { IDatePeriodProps } from '@shared-components/models';

const DatePeriod: React.FC<IDatePeriodProps> = ({
  startDate,
  endDate,
}: IDatePeriodProps) => {
  const diffInYears = endDate
    ? moment(endDate).diff(startDate, 'years')
    : moment().diff(startDate, 'years');
  const diffInMonths = endDate
    ? moment(endDate).diff(startDate, 'months') % 12
    : moment().diff(startDate, 'months') % 12;

  const totaYear =
    diffInYears === 1
      ? `${diffInYears} year`
      : diffInYears > 1
      ? `${diffInYears} years`
      : '';

  const totalMonth =
    diffInMonths === 1
      ? `${diffInMonths} month`
      : diffInMonths > 1
      ? `${diffInMonths} months`
      : '';

  return (
    <Typography.Text type="secondary" strong>
      {totaYear} {totalMonth}
      {!totaYear && !totalMonth && '-'}
    </Typography.Text>
  );
};
export { DatePeriod };
