import React, { useEffect, useState } from 'react';
import { Card, Col, Form, Row, Space } from 'antd';
import { Button, Input, Option, SearchableSelect } from '@moxie/shared';
import { useForm } from 'antd/lib/form/Form';
import { IGetPopupContainer, ILead } from '@shared-components/models';
import { useDispatch } from 'react-redux';
import { leadActions, useAppSelector } from '@lyra/core';
import {
  ADDRESS1_LABEL,
  ADDRESS2_LABEL,
  CANCEL_BTN,
  CATEGORY_ITEMS,
  COUNTRIES,
  EDIT_BTN,
  GUTTER,
  LABEL,
  NOT_AVAILABLE_MESSAGE,
  regex,
  SAVE_BTN,
  VALIDATION_MESSAGE,
} from '@moxie/constants';

const AddressDetailsTab: React.FC<any> = (props: any) => {
  const { lead } = props;
  const [form] = useForm();
  const dispatch = useDispatch();
  const [isEdit, setIsEdit] = useState<boolean>(false);
  const { user, socket } = useAppSelector((state) => ({
    user: state.auth.user,
    socket: state.socket.wss,
  }));

  const handleFinish = (data: ILead) => {
    form.resetFields();
    dispatch(
      leadActions.editLeadAction(
        { ...data, id: lead.id },
        socket,
        user,
        'address information',
        'other-details'
      )
    );
    setIsEdit(false);
  };

  useEffect(() => {
    form.setFieldsValue({
      country: lead?.country,
      city: lead?.city,
      state: lead?.state,
      zip_code: lead?.zip_code,
      postal_code: lead?.postal_code,
      street: lead?.street,
      address_line_one: lead?.address_line_one,
      address_line_two: lead?.address_line_two,
    });
  }, [isEdit, lead]);

  return (
    <Card
      title={CATEGORY_ITEMS[1].name}
      extra={[
        isEdit ? (
          <>
            {!lead?.archived && (
              <Space>
                <Button key="save" type="primary" onClick={() => form.submit()}>
                  {SAVE_BTN}
                </Button>
                <Button
                  key="cancel"
                  onClick={() => {
                    form.resetFields();
                    setIsEdit(false);
                  }}
                >
                  {CANCEL_BTN}
                </Button>
              </Space>
            )}
          </>
        ) : (
          <>
            {!lead?.archived && (
              <Button key="edit" type="primary" onClick={() => setIsEdit(true)}>
                {EDIT_BTN}
              </Button>
            )}
          </>
        ),
      ]}
    >
      <Form
        layout="horizontal"
        form={form}
        onFinish={handleFinish}
        scrollToFirstError={true}
        labelCol={{ lg: 6, md: 6, span: 10 }}
        wrapperCol={{ span: 14, offset: 1 }}
      >
        <Row gutter={GUTTER}>
          <Col span={24} xl={12} xxl={8}>
            <Form.Item
              name="address_line_one"
              className="custom_label_style"
              label={ADDRESS1_LABEL}
            >
              {isEdit ? (
                <Input className="note-title-input" disabled={!isEdit} />
              ) : lead?.address_line_one ? (
                <div className="initial_capital">{lead?.address_line_one}</div>
              ) : (
                NOT_AVAILABLE_MESSAGE
              )}
            </Form.Item>
          </Col>
          <Col span={24} xl={12} xxl={8}>
            <Form.Item
              name="address_line_two"
              className="custom_label_style"
              label={ADDRESS2_LABEL}
            >
              {isEdit ? (
                <Input className="note-title-input" disabled={!isEdit} />
              ) : lead?.address_line_two ? (
                <div className="initial_capital">{lead?.address_line_two}</div>
              ) : (
                NOT_AVAILABLE_MESSAGE
              )}
            </Form.Item>
          </Col>
          <Col span={24} xl={12} xxl={8}>
            <Form.Item
              name="city"
              className="custom_label_style"
              label={LABEL.CITY}
              rules={[
                { required: true },
                {
                  pattern: new RegExp(regex.ONLY_STRING),
                  message: VALIDATION_MESSAGE.INVALID_TEXT_INPUT,
                },
              ]}
            >
              {isEdit ? (
                <Input className="note-title-input" disabled={!isEdit} />
              ) : lead?.city ? (
                <div className="initial_capital">{lead?.city}</div>
              ) : (
                NOT_AVAILABLE_MESSAGE
              )}
            </Form.Item>
          </Col>
          <Col span={24} xl={12} xxl={8}>
            <Form.Item
              name="state"
              className="custom_label_style"
              label={LABEL.STATE}
              rules={[
                {
                  pattern: new RegExp(regex.ONLY_STRING),
                  message: VALIDATION_MESSAGE.INVALID_TEXT_INPUT,
                },
              ]}
            >
              {isEdit ? (
                <Input className="note-title-input" disabled={!isEdit} />
              ) : lead?.state ? (
                <div className="initial_capital">{lead?.state}</div>
              ) : (
                NOT_AVAILABLE_MESSAGE
              )}
            </Form.Item>
          </Col>
          <Col span={24} xl={12} xxl={8}>
            <div id="country_list" className="relative">
              <Form.Item
                name="country"
                className="custom_label_style"
                label={LABEL.COUNTRY}
                rules={[{ required: true }]}
              >
                {isEdit ? (
                  <SearchableSelect
                    className="note-title-input"
                    disabled={!isEdit}
                    allowClear={false}
                    optionFilter="search_prop"
                    getPopupContainer={(): IGetPopupContainer =>
                      document.getElementById('country_list')
                    }
                  >
                    {COUNTRIES.map((item) => {
                      return (
                        <Option
                          value={item.name}
                          key={item.code}
                          search_prop={`${item.name} ${item.code}`}
                        >
                          {item.name}
                        </Option>
                      );
                    })}
                  </SearchableSelect>
                ) : lead?.country ? (
                  lead?.country
                ) : (
                  NOT_AVAILABLE_MESSAGE
                )}
              </Form.Item>
            </div>
          </Col>
          <Col span={24} xl={12} xxl={8}>
            <Form.Item
              name="zip_code"
              className="custom_label_style"
              label={LABEL.ZIP_CODE}
              rules={[
                {
                  pattern: new RegExp(regex.ALPHA_NUMERIC),
                  message: VALIDATION_MESSAGE.INVALID_ZIP_CODE,
                },
              ]}
            >
              {isEdit ? (
                <Input className="note-title-input" disabled={!isEdit} />
              ) : lead?.zip_code ? (
                <div className="initial_capital">{lead?.zip_code}</div>
              ) : (
                NOT_AVAILABLE_MESSAGE
              )}
            </Form.Item>
          </Col>
        </Row>
      </Form>
    </Card>
  );
};

export { AddressDetailsTab };
