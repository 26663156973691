import React, { useState } from 'react';
import {
  CANCEL_BTN,
  DRAWER_WIDTH,
  SAVE_BTN,
  TEXT,
  UPDATE_BTN,
} from '@moxie/constants';
import { DrawerElem } from '@shared-components/elements';
import { Button, Space, Typography } from 'antd';
import TenantManageForm from './tenant-manage-form';
import { ITenantManageDrawer } from '@shared-components/models';

const { Title } = Typography;
const TenantManageDrawer: React.FC<ITenantManageDrawer> = ({
  isOpen,
  showDrawer,
  isEdit,
  form,
  handleSubmit,
  disableManageDrawerButton,
  setDisableManageDrawerButton,
}: ITenantManageDrawer) => {
  const handleSubmitButtonClick = () => {
    form.submit();
  };

  return (
    <DrawerElem
      title={<Title level={4}>{TEXT.MANAGE_TENANT}</Title>}
      width={DRAWER_WIDTH}
      onClose={showDrawer}
      visible={isOpen}
      footer={
        <div className="padding-bottom-2 text-align-right">
          <Space direction="horizontal">
            <Button className="lead-margin-right" onClick={showDrawer}>
              {CANCEL_BTN}
            </Button>
            <Button
              type="primary"
              onClick={handleSubmitButtonClick}
              disabled={disableManageDrawerButton}
            >
              {isEdit ? UPDATE_BTN : SAVE_BTN}
            </Button>
          </Space>
        </div>
      }
    >
      <TenantManageForm
        form={form}
        handleSubmit={handleSubmit}
        setDisabled={setDisableManageDrawerButton}
      />
    </DrawerElem>
  );
};

export default TenantManageDrawer;
