import { errorHandler } from '@moxie/utils';
import {
  deleteAppointment,
  getAppointment,
  getAppointments,
  saveAppointment,
  updateAppointment,
} from '@lyra/services.api';
import { successNotificationHandler } from '@moxie/shared';
import { put, takeLatest } from '@redux-saga/core/effects';
import { IAction } from '@shared-components/models';
import { AxiosResponse } from 'axios';
import * as actionLeadAppointment from './lead-appointments.action';
import * as actionTypes from './lead-appointments.constant';

export function* getLeadAppointments({ payload }: IAction<string>) {
  try {
    if (payload) {
      const response: AxiosResponse = yield getAppointments(payload);
      yield put(
        actionLeadAppointment.getLeadAppointmentsSuccess(response?.data?.data)
      );
    }
  } catch (err) {
    errorHandler(err);
    yield put(actionLeadAppointment.getLeadAppointmentsFailure(err));
  }
}

export function* getLeadAppointment({ payload }: IAction<string>) {
  try {
    if (payload) {
      const response: AxiosResponse = yield getAppointment(payload);
      yield put(
        actionLeadAppointment.getLeadAppointmentSuccess(response?.data?.data)
      );
    }
  } catch (err) {
    errorHandler(err);
    yield put(actionLeadAppointment.getLeadAppointmentFailure(err));
  }
}

export function* addLeadAppointments({
  data,
  user,
  socket,
  lead,
  onClose,
  triggerRefresh,
}: any) {
  try {
    if (data) {
      const response: AxiosResponse = yield saveAppointment(data);
      yield put(actionLeadAppointment.addLeadAppointmentSuccess(response));

      socket.emit('added-appointment-to-server', {
        user_id: user.id,
        appointment_id: response?.data?.data?.id,
        data: response?.data,
        parent_id: lead.id,
        company_id: user?.company?.id,
      });

      socket.emit('create-appointment-notification-to-server', {
        user_id: user.id,
        receiver_id: lead.assignee_user_id,
        receivers_many: data?.users_id?.map((id: string) => id),
        type: 'appointment-created',
      });
      successNotificationHandler(response.data.message);
      onClose();
      triggerRefresh && triggerRefresh();
    }
  } catch (err) {
    errorHandler(err);
    yield put(actionLeadAppointment.addLeadAppointmentFailure(err));
  }
}

export function* updateLeadAppointments({
  data,
  user,
  socket,
  lead,
  onClose,
  triggerRefresh,
  scheduleChange,
  socketType,
}: any) {
  try {
    if (!data) return;
    const response: AxiosResponse = yield updateAppointment(data);
    socket.emit('updated-appointment-to-server', {
      user_id: user.id,
      appointment_id: response?.data?.data?.id,
      data: response?.data,
      parent_id: lead.id,
      company_id: user?.company?.id,
    });
    if (socketType === 'appointment-created') {
      socket.emit('create-appointment-notification-to-server', {
        user_id: user.id,
        receiver_id: lead.assignee_user_id,
        receivers_many: data?.users_id?.map((id: string) => id),
        type: 'appointment-created',
      });
    } else {
      socket.emit('create-appointment-notification-to-server', {
        user_id: user.id,
        receiver_id: lead.assignee_user_id,
        receivers_many: data?.users_id?.map((id: string) => id),
        type:
          scheduleChange === true
            ? 'appointment-rescheduled'
            : 'appointment-updated',
      });
    }

    yield put(
      actionLeadAppointment.updateLeadAppointmentSuccess(response.data.data)
    );
    successNotificationHandler(response.data.message);
    onClose();
    triggerRefresh && triggerRefresh();
  } catch (err) {
    errorHandler(err);
    yield put(actionLeadAppointment.updateLeadAppointmentFailure(err));
  }
}

export function* deleteLeadAppointments({ payload, user, socket, lead }: any) {
  try {
    if (!payload) return;
    const response: AxiosResponse = yield deleteAppointment(payload);
    yield put(actionLeadAppointment.deleteLeadAppointmentSuccess(payload));

    const deleteActivities = {
      data: response?.data,
      appointment_id: payload,
      parent_id: lead.id,
      user_id: user.id,
      company_id: user?.company?.id,
    };
    socket.emit('deleted-appointment-to-server', deleteActivities);
    successNotificationHandler(response.data.message);
  } catch (err) {
    errorHandler(err);
    yield put(actionLeadAppointment.deleteLeadAppointmentFailure(err));
  }
}

export default function* rootSagas(): unknown {
  yield takeLatest(
    actionTypes.GET_LEAD_APPOINTMENTS_REQUEST,
    getLeadAppointments
  );
  yield takeLatest(
    actionTypes.GET_LEAD_APPOINTMENT_REQUEST,
    getLeadAppointment
  );
  yield takeLatest(
    actionTypes.ADD_LEAD_APPOINTMENT_REQUEST,
    addLeadAppointments
  );
  yield takeLatest(
    actionTypes.UPDATE_LEAD_APPOINTMENT_REQUEST,
    updateLeadAppointments
  );
  yield takeLatest(
    actionTypes.DELETE_LEAD_APPOINTMENT_REQUEST,
    deleteLeadAppointments
  );
}
