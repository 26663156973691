import React, { FC, useState, useEffect } from 'react';
import { Row, Col, Button, Upload, Typography, Image } from 'antd';
import { UploadOutlined } from '@ant-design/icons';
import { downloadTemplate } from '@lyra/services.api';
import {
  DOWNLOAD_BTN,
  DOWNLOAD_TEMPLATE,
  ENQ_INFO,
  UPLOAD_BTN,
  UPLOAD_FILE,
  PREFERRED,
  SIZE,
  LIMIT,
  TYPE,
  XLSX,
} from '@moxie/constants';
import { ILeadInterface } from '@shared-components/models';

const ImportLead: FC<ILeadInterface> = ({
  index,
  handleSubmit,
}: ILeadInterface) => {
  const { Text } = Typography;
  const [signedUrl, setSignedUrl] = useState('');

  const SETTING = {
    name: 'file',
    accept: '.xlsx, .xls',
    maxCount: 1,
    multiple: false,
    customRequest: handleSubmit,
  };
  const handleDownload = async () => {
    const data = await downloadTemplate();
    setSignedUrl(data?.data);
  };

  const handleIndex = (index: any) => {
    switch (index) {
      case '1':
        return DOWNLOAD_TEMPLATE;
      case '2':
        return ENQ_INFO;
      case '3':
        return UPLOAD_FILE;
      default:
        return;
    }
  };

  useEffect(() => {
    handleDownload();
  }, [signedUrl]);

  return (
    <>
      <Row className="import-lead-margin">
        <Col span="3">
          <div>
            <Button shape="circle">{index}</Button>
          </div>
        </Col>
        <Col span="6">
          <Image src="./assets/icons.png" preview={false} />
        </Col>
        <Col span="15">
          <div className="lead-padding-left">
            <Text strong>{handleIndex(index)}</Text>
            {index === '1' && (
              <Button
                typeof="button"
                type="default"
                onClick={handleDownload}
                className="import-lead-button"
              >
                <Text strong>
                  <a href={signedUrl && signedUrl}>{DOWNLOAD_BTN}</a>
                </Text>
              </Button>
            )}
            {index === '3' && (
              <>
                <Upload {...SETTING}>
                  <Button
                    typeof="button"
                    type="default"
                    icon={<UploadOutlined />}
                    className="import-lead-button"
                  >
                    <Text strong>{UPLOAD_BTN}</Text>
                  </Button>
                </Upload>
                <p className="import-lead-button">
                  <Col>
                    <Text type="secondary" strong>
                      {PREFERRED}
                    </Text>
                  </Col>
                  <Col>
                    <Text type="secondary" strong>
                      {TYPE}
                    </Text>
                    &nbsp;
                    <Text type="secondary">{XLSX}</Text>
                  </Col>
                  <Col>
                    <Text type="secondary" strong>
                      {SIZE}
                    </Text>
                    &nbsp;
                    <Text type="secondary">{LIMIT}</Text>
                  </Col>
                </p>
              </>
            )}
          </div>
        </Col>
      </Row>
    </>
  );
};

export { ImportLead };
