import {
  COUNTRIES,
  COUNTRIES_OPERATED_LABEL,
  FEATURES,
  GUTTER,
  NO_OF_DESTINATION_COUNTRIES_LABEL,
  NO_OF_EMPLOYEES_LABEL,
  NO_OF_ESTIMATES_LABEL,
  NUMBER_OF_DESTINATION_COUNTRIES,
  NUMBER_OF_EMPLOYEES,
  NUMBER_OF_ESTIMATES,
  SELECT_FEATURE_LABEL,
  WOS_COUNTRIES_OPERATED_PLACEHOLDER,
  WOS_FEATURE_PLACEHOLDER,
  WOS_NO_OF_DESTINATION_COUNTRIES_PLACEHOLDER,
  WOS_NO_OF_EMPLOYEES_PLACEHOLDER,
  WOS_NO_OF_ESTIMATED_APPLICATIONS_PLACEHOLDER,
} from '@moxie/constants';
import { FormItem, Option, Select } from '@shared-components/elements';
import {
  IForm,
  IGetPopupContainer,
  IWorkOrderScope,
} from '@shared-components/models';
import { Col, Row } from 'antd';
import React from 'react';

const WorkOrderScopeDetailsForm: React.FC<IForm<IWorkOrderScope>> = ({
  form,
  isEdit
}: IForm<IWorkOrderScope>) => {
  const handleCountryFilter = (input: string, option: any): boolean =>
    option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0;

  return (
    <>
      <div id="feature_list">
        <FormItem
          name="feature"
          label={SELECT_FEATURE_LABEL}
          rules={[{ required: true, type: 'string' }]}
        >
          <Select
            placeholder={WOS_FEATURE_PLACEHOLDER}
            getPopupContainer={(): IGetPopupContainer =>
              document.getElementById('feature_list')
            }
            disabled={isEdit}
          >
            {FEATURES.map((item) => (
              <Option key={item.value} value={item.value}>
                {item.name}
              </Option>
            ))}
          </Select>
        </FormItem>
      </div>
      <Row gutter={GUTTER}>
        <Col span="12">
          <div id="no_of_employees_list">
            <FormItem
              name="number_of_employees"
              label={NO_OF_EMPLOYEES_LABEL}
              rules={[{ required: true }]}
            >
              <Select
                placeholder={WOS_NO_OF_EMPLOYEES_PLACEHOLDER}
                getPopupContainer={(): IGetPopupContainer =>
                  document.getElementById('no_of_employees_list')
                }
              >
                {NUMBER_OF_EMPLOYEES.map((item) => (
                  <Option key={item.key} value={item.name}>
                    {item.name}
                  </Option>
                ))}
              </Select>
            </FormItem>
          </div>
        </Col>
        <Col span="12">
          <div id="no_of_distination_list">
            <FormItem
              name="number_of_destination_countries"
              label={NO_OF_DESTINATION_COUNTRIES_LABEL}
              rules={[{ required: true }]}
            >
              <Select
                placeholder={WOS_NO_OF_DESTINATION_COUNTRIES_PLACEHOLDER}
                getPopupContainer={(): IGetPopupContainer =>
                  document.getElementById('no_of_distination_list')
                }
              >
                {NUMBER_OF_DESTINATION_COUNTRIES.map((item) => (
                  <Option key={item.value} value={item.name}>
                    {item.name}
                  </Option>
                ))}
              </Select>
            </FormItem>
          </div>
        </Col>
      </Row>
      <div id="countries_operated_list">
        <FormItem
          name="countries_operated"
          label={COUNTRIES_OPERATED_LABEL}
          rules={[{ required: true }]}
        >
          <Select
            placeholder={WOS_COUNTRIES_OPERATED_PLACEHOLDER}
            mode="multiple"
            showSearch
            filterOption={handleCountryFilter}
            getPopupContainer={(): IGetPopupContainer =>
              document.getElementById('countries_operated_list')
            }
          >
            {COUNTRIES.map((country) => (
              <Option value={country.code} key={country.code}>
                {country.name}
              </Option>
            ))}
          </Select>
        </FormItem>
      </div>
      <div id="no_of_estimates_list">
        <FormItem
          name="number_of_estimates"
          label={NO_OF_ESTIMATES_LABEL}
          rules={[{ required: true }]}
        >
          <Select
            placeholder={WOS_NO_OF_ESTIMATED_APPLICATIONS_PLACEHOLDER}
            getPopupContainer={(): IGetPopupContainer =>
              document.getElementById('no_of_estimates_list')
            }
          >
            {NUMBER_OF_ESTIMATES.map((item) => (
              <Option key={item.key} value={item.name}>
                {item.name}
              </Option>
            ))}
          </Select>
        </FormItem>
      </div>
    </>
  );
};

export { WorkOrderScopeDetailsForm };
