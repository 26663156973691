import { Collapse } from 'antd';
import moment from 'moment';
import React from 'react';
import EnquiryAssigneePanel from './EnquiryAddigneePanel';

interface Props {
  name: string;
  createdAt: string;
  data: {
    assignees: {
      id: string;
    };
    previousAssignees: {
      id: string;
    };
  };
}
const { Panel } = Collapse;


export const EnquiryAssigneeActivity: React.FC<Props> = ({
  name,
  createdAt,
  data,
}) => {
  const { assignees } = data;

  if (!assignees.id) {
    return (<Panel
      className="enquiry-assignee-panel"
      key={'null'}
      header={(
        <div className="enquiry-info">
          <p className="enquiry-author">
            <span style={{ fontWeight: 'bold', fontSize: '14px' }}>{name}</span> removed as <span style={{ fontWeight: 'bold', fontSize: '14px' }}>Assignee</span></p>
          <div className="enquiry-date">
            <div>{moment(createdAt).format('dddd, MMMM D, YYYY')}</div>
            <div>by {name}</div>
          </div>
        </div >
      )}
    />
    )
  }

  return (
    <>
      <EnquiryAssigneePanel
        key={assignees.id}
        assigneeId={assignees.id}
        name={name}
        createdAt={createdAt}
      /></>
  );
};
