import axios from './axios';
import { API_URL } from '@moxie/constants';
import { IResponse, IWorkflow } from '@shared-components/models';
import crmAxios from './crm-api-axios';
import { ApplicationWorkflowStageChecklist } from '@model/workflow';

const getAllWorkflows = async (params=''): Promise<IResponse> => {
  const data = await crmAxios.get(`${API_URL.CRM_API_WORKFLOW}/`, {params});
  return data?.data;
};

const getCRMWorkflows = async (params = ''): Promise<IResponse> => {
  return axios.get(`${API_URL.CRM_WORKFLOW}/${params}`);
};

const getOneCRMWorkflow = async (id: string): Promise<IResponse> => {
  const data = await crmAxios.get(`${API_URL.CRM_API_WORKFLOW}/${id}`);
  return data;
};

const addCRMWorkflow = async (data: IWorkflow): Promise<IResponse> => {
  return crmAxios.post(`${API_URL.CRM_API_WORKFLOW}`, data);
};

const deleteCRMWorkflow = async (id: string): Promise<IResponse> => {
  return axios.delete(`${API_URL.CRM_WORKFLOW}/${id}`);
};

const getWorkflowStages = async (workflowId: string): Promise<IResponse> => {
  return crmAxios.get(
    `${API_URL.CRM_API_WORKFLOW_APPLICATION_STAGES}`, {
      params: {
        workflowId
      }
    }
  );
};

const addWorkflowStage = async (body: any): Promise<IResponse> => {
  return crmAxios.post(
    `${API_URL.CRM_API_WORKFLOW_APPLICATION_STAGES}`,
    body
  );
};

const deleteWorkflowStage = async (id: string): Promise<IResponse> => {
  const response = await crmAxios.delete(
    `${API_URL.CRM_API_WORKFLOW_APPLICATION_STAGES}/${id}`
  );
  return response?.data;
};

const updateWorkflowStage = async (body: any): Promise<IResponse> => {
  return crmAxios.put(
    `${API_URL.CRM_API_WORKFLOW_APPLICATION_STAGES}/${body.id}`,
    body
  );
};

const getApplicationWorkflowStages = async (workflowId: string) => {
  return crmAxios.get(
    `${API_URL.CRM_API_WORKFLOW_APPLICATION_STAGES}`, {
      params: {
        workflowId
      }
    }
  );
};

const getWfChecklists = async (wfStageId: string) => {
  return crmAxios.get<{data:ApplicationWorkflowStageChecklist[] }>(
    `${API_URL.CRM_API_STAGE_CHECKLISTS}`, {
      params: {
        stageId: wfStageId
      }
    }
  );
};

const addWfStageChecklist = async (body: {
  stageId: string;
  checklists: Array<number>;
}): Promise<IResponse> => {
  return crmAxios.post(
    `${API_URL.CRM_API_STAGE_CHECKLISTS}`,
    body
  );
};

const deleteWorkflowStageChecklist = async (id: string): Promise<IResponse> => {
  return crmAxios.delete(
    `${API_URL.CRM_API_STAGE_CHECKLISTS}/${id}`
  );
};

const getCRMWorkflowsByServiceId = async (
  params?: Record<string, unknown>
): Promise<any> => {
  const data = await crmAxios.get(`${API_URL.CRM_API_WORKFLOW}`, {
    params
  });
  return data?.data;
};

export {
  getAllWorkflows,
  getCRMWorkflows,
  getOneCRMWorkflow,
  addCRMWorkflow,
  deleteCRMWorkflow,
  addWorkflowStage,
  getWorkflowStages,
  deleteWorkflowStage,
  updateWorkflowStage,
  getWfChecklists,
  addWfStageChecklist,
  deleteWorkflowStageChecklist,
  getCRMWorkflowsByServiceId,
  getApplicationWorkflowStages
};
