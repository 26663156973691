import {
  IAction,
  ISubjectDiscipline,
  ISubjectDisciplinePayload,
} from '@shared-components/models';
import * as actionTypes from './client-activities.constant';

export const getClientActivitiesRequest = (payload: any): IAction<any> => {
  return {
    type: actionTypes.GET_CLIENT_ACTIVITIES_REQUEST,
    payload,
  };
};
export const getClientActivitiesSuccess = (
  payload: ISubjectDiscipline[]
): IAction<ISubjectDiscipline[]> => ({
  type: actionTypes.GET_CLIENT_ACTIVITIES_SUCCESS,
  payload,
});
export const getClientActivitiesFailure = (
  payload: ISubjectDisciplinePayload
): IAction<ISubjectDisciplinePayload> => ({
  type: actionTypes.GET_CLIENT_ACTIVITIES_FAILURE,
  payload,
});
export const clearGetClientActivitiesRequest = () => ({
  type: actionTypes.GET_CLIENT_ACTIVITIES_CLEAR,
});

export const addClientActivitiesSuccess = (
  payload: ISubjectDiscipline[]
): IAction<ISubjectDiscipline[]> => ({
  type: actionTypes.ADD_CLIENT_ACTIVITY_SUCCESS,
  payload,
});
