import {
  IAction,
  ISubjectDiscipline,
  ISubjectDisciplinePayload,
} from '@shared-components/models';
import * as actionTypes from './lead-activities.constant';

export const getLeadActivitiesRequest = (payload: any): IAction<any> => {
  return {
    type: actionTypes.GET_LEAD_ACTIVITIES_REQUEST,
    payload,
  };
};
export const getLeadActivitiesSuccess = (
  payload: ISubjectDiscipline[]
): IAction<ISubjectDiscipline[]> => ({
  type: actionTypes.GET_LEAD_ACTIVITIES_SUCCESS,
  payload,
});
export const getLeadActivitiesFailure = (
  payload: ISubjectDisciplinePayload
): IAction<ISubjectDisciplinePayload> => ({
  type: actionTypes.GET_LEAD_ACTIVITIES_FAILURE,
  payload,
});
export const cleargetLeadActivitiesRequest = () => ({
  type: actionTypes.GET_LEAD_ACTIVITIES_CLEAR,
});

export const addLeadActivitiesSuccess = (
  payload: ISubjectDiscipline[]
): IAction<ISubjectDiscipline[]> => ({
  type: actionTypes.ADD_LEAD_ACTIVITIE_SUCCESS,
  payload,
});
