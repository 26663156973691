import { API_URL } from '@moxie/constants';
import {
  IProductComparison,
  IProductResponse,
  IResponse
} from '@shared-components/models';
import { axiosInstance as axios } from './axios';

const productServices = {
  apiGetProducts: async (params = ''): Promise<IProductResponse> => {
    return axios.get(`${API_URL.PRODUCTS}/${params}`);
  },
  apiGetProductTypes: async (params?: Record<string, unknown>): Promise<IProductResponse> => {
    return axios.get(`${API_URL.PRODUCT_TYPES}`, {
      params
    });
  },
  apiGetProductDetail: async (id: string): Promise<IProductResponse> => {
    return axios.get(`${API_URL.PRODUCTS}/${id}`);
  },

  apiAddProduct: async (data: any): Promise<IProductResponse> => {
    return axios.post(`${API_URL.PRODUCTS}`, data);
  },

  apiUpdateProduct: async (data: any): Promise<IProductResponse> => {
    return axios.put(`${API_URL.PRODUCTS}/${data.id}`, data);
  },

  apiDeleteProduct: async (id: string): Promise<IProductResponse> => {
    return axios.delete(`${API_URL.PRODUCTS}/${id}`);
  },

  apiAddProductFee: async (data: any): Promise<IProductResponse> => {
    return axios.post(`${API_URL.PRODUCTS_FEES}`, data);
  },
  apiValidateProductFeeName: async (
    fee_name: string,
    product_id: string,
    id?: string
  ): Promise<IResponse> => {
    const params = {
      product_id: product_id,
      fee_name: fee_name,
      id: id,
    };
    return axios.get(`${API_URL.PRODUCTS_FEES}/check/name`, { params });
  },

  apiUpdateProductFee: async (data: any): Promise<IProductResponse> => {
    return axios.put(`${API_URL.PRODUCTS_FEES}/${data.id}`, data);
  },

  apiGetProductFees: async (id: string): Promise<IProductResponse> => {
    return axios.get(`${API_URL.PRODUCTS_FEES}/product/${id}`);
  },
  apiGetProductFee: async (id: string): Promise<IProductResponse> => {
    return axios.get(`${API_URL.PRODUCTS_FEES}/${id}`);
  },

  apiDeleteProductFee: async (id: string): Promise<IProductResponse> => {
    return axios.delete(`${API_URL.PRODUCTS_FEES}/${id}`);
  },

  apiAddProductComparison: async (
    data: IProductComparison
  ): Promise<IProductResponse> => {
    return axios.post(`${API_URL.PRODUCTS}/compare`, data);
  },

  apiGetProductFiltersData: async (): Promise<IResponse> => {
    return axios.get(`/products-filter`);
  },
};

export { productServices };
