import { AxiosResponse } from 'axios';
import * as actionTypes from './application.constant';
import * as action from './application.action';
import { put, takeLatest } from 'redux-saga/effects';
import {
  addLeadApplication,
  deleteApplication,
  getApplication,
  getOneApplicationComments,
  postApplicationComment,
  updateLeadApplication,
} from '@lyra/services.api';
import { IAction, ILeadApplication } from '@shared-components/models';
import { successNotificationHandler } from '@moxie/shared';
import { errorHandler } from '@moxie/utils';
import { lead as leadAction } from '../constants';
import { getLead } from '../lead/lead.saga';

export const getLeadApplication = function* ({ payload }: IAction<any>) {
  try {
    if (payload) {
      const response: AxiosResponse = yield getApplication(payload);
      yield put(action.getOneSuccess(response?.data?.data || []));
    }
  } catch (err) {
    errorHandler(err);
    yield put(action.getOneFailure(err));
  }
};

export const getApplicationComments = function* ({ payload }: IAction<any>) {
  try {
    if (payload) {
      const response: AxiosResponse = yield getOneApplicationComments(payload);
      yield put(action.getCommentSuccess(response?.data?.data || []));
    }
  } catch (err) {
    errorHandler(err);
    yield put(action.getCommentFailure(err));
  }
};

export const addApplicationComments = function* ({ payload }: IAction<any>) {
  try {
    if (payload) {
      const response: AxiosResponse = yield postApplicationComment(payload);
      yield put(action.addCommentSuccess(response?.data?.data || []));
    }
  } catch (err) {
    errorHandler(err);
    yield put(action.addCommentFailure(err));
  }
};

export const createLeadApplication = function* ({
  payload,
  user,
  socket,
  lead,
  onClose,
  setIsSubmitted,
}: IAction<ILeadApplication>) {
  try {
    if (payload) {
      const response: AxiosResponse = yield addLeadApplication(payload);
      yield getLead({
        payload: response?.data?.data?.contact_id,
        type: leadAction.FETCH_LEAD,
      });
      yield put(action.addSuccess(response.data.data));
      successNotificationHandler(response.data.message);
      socket?.emit('create-application-activities-to-server', {
        user_id: user?.id,
        action_type: 'created',
        application_id: response?.data?.data?.id,
        parent_id: lead?.id,
        data: response?.data,
        company_id: user?.company?.id,
      });

      socket?.emit('create-application-notification-to-server', {
        user_id: user?.id,
        receiver_id: lead?.assignee_user_id,
        receivers_many: lead?.followers_id?.map((id: string) => id),
        type: 'application-created',
        parent_id: lead?.id,
        application_identifier: response?.data?.data?.app_identifier,
        application_id: response?.data?.data?.id,
      });

      // if (
      //   lead?.status === CONTACT_STATUS.LEAD ||
      //   lead?.status === CONTACT_STATUS.PROSPECT
      // ) {
      //   const name = lead?.first_name + ' ' + lead?.last_name;
      //   successNotificationHandler(
      //     name + LEAD_CONVERSION_MESSAGE.LEAD_PROSPECT_TO_CLIENT
      //   );
      // }
      if (response) {
        onClose?.();
      }
    }
  } catch (err: any) {
    errorHandler(err);
    yield put(action.addFailure(err));
  }
  setIsSubmitted?.(false);
};

export const editLeadApplication = function* ({
  payload,
  user,
  socket,
  lead,
  update_type,
  newStage,
}: IAction<ILeadApplication | any>) {
  try {
    if (payload) {
      const { handleRefreshTable, ...restPayload } = payload;
      const response: AxiosResponse = yield updateLeadApplication(restPayload);
      yield put(action.updateSuccess(response.data.data));
      let agentClients = response?.data?.data?.agent_clients?.map(
        (item: any) => item.id
      );
      if (newStage?.stage === 'Complete') {
        lead?.assignee_user_id && agentClients.push(lead.assignee_user_id);
        if (lead?.followers_id && lead.followers_id.length > 0) {
          lead.followers_id.map((item: any) => agentClients.push(item));
        }
        agentClients = [...new Set(agentClients)];
      }

      socket?.emit('create-application-activities-to-server', {
        user_id: user?.id,
        action_type: 'updated',
        application_id: response?.data?.data?.id,
        parent_id: lead?.id,
        data: response?.data,
        update_type: update_type,
        company_id: user?.company?.id,
      });

      socket?.emit('create-application-notification-to-server', {
        user_id: user?.id,
        receivers_many: agentClients,
        type: 'application-updated',
        parent_id: lead?.id,
        update_type: update_type,
        application_identifier: response?.data?.data?.app_identifier,
        application_id: response?.data?.data?.id,
        newStage: newStage && newStage,
      });
      handleRefreshTable?.();
      successNotificationHandler(response.data.message);
    }
  } catch (err) {
    errorHandler(err);
    yield put(action.updateFailure(err));
  }
};

export const deleteLeadApplication = function* ({
  payload,
  user,
  socket,
  lead,
}: IAction<any>) {
  try {
    if (payload) {
      const response: AxiosResponse = yield deleteApplication(payload);
      yield put(action.deleteApplicationSuccess(response.data.data));
      successNotificationHandler(response.data.message);
      socket?.emit('create-application-activities-to-server', {
        user_id: user?.id,
        action_type: 'deleted',
        application_id: response?.data?.data?.id,
        parent_id: lead?.id,
        data: response?.data,
        company_id: user?.company?.id,
      });
      socket?.emit('create-application-notification-to-server', {
        user_id: user?.id,
        receiver_id: lead?.assignee_user_id,
        receivers_many: lead?.followers_id?.map((id: string) => id),
        type: 'application-deleted',
        parent_id: lead?.id,
        application_identifier: response?.data?.data?.app_identifier,
        application_id: response?.data?.data?.id,
      });
    }
  } catch (err) {
    errorHandler(err);
    yield put(action.deleteApplicationFailure(err));
  }
};

const applicationSaga = function* (): unknown {
  yield takeLatest(actionTypes.GET_APPLICATION_REQUEST, getLeadApplication);
  yield takeLatest(actionTypes.ADD_APPLICATION_REQUEST, createLeadApplication);
  yield takeLatest(actionTypes.UPDATE_APPLICATION_REQUEST, editLeadApplication);
  yield takeLatest(
    actionTypes.DELETE_APPLICATION_REQUEST,
    deleteLeadApplication
  );
  yield takeLatest(
    actionTypes.ADD_APPLICATION_COMMENT_REQUEST,
    addApplicationComments
  );
  yield takeLatest(
    actionTypes.GET_APPLICATION_COMMENT_REQUEST,
    getApplicationComments
  );
};

export default applicationSaga;
