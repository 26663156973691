import React, { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { clientActivitiesActions, notificationActions } from '@admin/core';
import { useAppSelector } from '../useStore';

const SocketClient: React.FC = () => {
  const dispatch = useDispatch();
  const socket: any = useAppSelector((state) => state.socket.wss);

  useEffect(() => {
    socket?.on('all-lead-activities-to-client', (clients: any[]) => {
      dispatch(clientActivitiesActions.getClientActivitiesRequest(clients));
    });
  }, [socket, dispatch]);

  useEffect(() => {
    socket?.on('new-add-lead-activities-to-client', (clientActivities: any) => {
      dispatch(
        clientActivitiesActions.addClientActivitiesSuccess(clientActivities)
      );
    });
  }, [socket, dispatch]);

  useEffect(() => {
    socket?.on('all-notifications-to-client', (notifications: any) => {
      dispatch(notificationActions.getNotificationSuccess(notifications));
    });
  }, [socket, dispatch]);

  return null;
};

export { SocketClient };
