/* eslint-disable no-unused-vars */
import React, { useEffect, useState } from 'react';
import { Col, Row, Spin } from 'antd';
import Form from 'antd/lib/form';
import {
  IForm,
  IInstituteInterface,
  ILeadApplicationForm,
} from '@shared-components/models';
import {
  getInstitutionProducts,
  getInstitutionByServiceCountry,
  getServices,
  productServices,
  getWorkflowByServiceCountry,
} from '@lyra/services.api';
import {
  COUNTRY_PLACEHOLDER,
  GUTTER,
  INTAKE_MONTHS,
  LABEL,
  PLACEHOLDER,
} from '@moxie/constants';
import { FormItem, Option, SearchableSelect } from '@moxie/shared';
import { fetchServiceCountry } from 'apps/lyra/src/libs/services.api/lib/service.api';

const LeadApplicationForm = ({ form, initialData, onSubmit }: IForm<any>) => {
  const [serviceList, setServiceList] = useState([] as any[]);
  const [workflowList, setWorkflowList] = useState([] as any[]);
  const [institutionList, setInstitutionList] = useState([] as any[]);
  const [productList, setProductList] = useState([] as any[]);
  const [branchList, setBranchList] = useState([] as any[]);
  const [countryList, setCountryList] = useState([] as any[]);
  const [selectedService, setSelectedService] = useState('');
  const [selectedBranch, setSelectedBranch] = useState<string | any>();
  const [intakeYears, setIntakeYears] = useState<number[]>();
  const [intakeMonths, setIntakeMonths] = useState<[]>();
  const [loading, setLoading] = useState({
    service: true,
    country: false,
    workflowType: false,
    institution: false,
    product: false,
  });

  useEffect(() => {
    if (initialData) {
      form.setFieldsValue(initialData);
    } else {
      form.setFieldsValue({});
    }
  }, [form, initialData, productList]);

  useEffect(() => {
    getData().then(() => {
      return;
    });
  }, [form]);

  const getData = async () => {
    const response = await getServices();
    setServiceList(response.data.data);
    setLoading({ ...loading, service: false });
  };

  const getIntakeYears = () => {
    const intake = [];
    for (let i = 0; i < 3; i++) {
      intake.push(new Date().getFullYear() + i);
    }
    setIntakeYears(intake);
  };

  const handleFinish = async (data: ILeadApplicationForm) => {
    onSubmit && onSubmit(data);
  };

  const handleServiceChange = async (serviceId: string | any) => {
    setLoading({ ...loading, country: true });
    if (serviceId) {
      setSelectedService(serviceId);

      const countryResponse = await fetchServiceCountry(serviceId);
      setCountryList(countryResponse?.data?.data);
      setLoading({ ...loading, country: false });
    }
    setInstitutionList([]);
    setProductList([]);
    form.setFieldsValue({
      institution_id: null,
      institution_branch_id: null,
      workflow_type_id: null,
      country: null,
      product_id: null,
      intake_year: null,
      product_intake_id: null,
    });
  };

  const handleWorkflowTypeChange = async () => {
    setProductList([]);
    form.setFieldsValue({
      institution_id: null,
      institution_branch_id: null,
      product_id: null,
      intake_year: null,
      product_intake_id: null,
    });
  };

  const handleCountryChange = async (name: any) => {
    if (name !== undefined) {
      setLoading({ ...loading, workflowType: true, institution: true });
      const workflowResponse = await getWorkflowByServiceCountry(
        selectedService && selectedService,
        name
      );
      setWorkflowList(workflowResponse?.data?.data);
      const response = await getInstitutionByServiceCountry(
        selectedService,
        name
      );
      setInstitutionList(response.data.data);
      setLoading({ ...loading, institution: false, workflowType: false });
    } else {
      form.setFieldsValue({
        workflow_type_id: null,
        institution_id: null,
        institution_branch_id: null,
        product_id: null,
        intake_year: null,
        product_intake_id: null,
      });
      setProductList([]);
    }
  };

  const handleInstitutionChange = async (institutionId: string | any) => {
    if (institutionId) {
      const institution =
        institutionList &&
        institutionList.find(
          (item: IInstituteInterface) => item?.id === institutionId
        );
      setBranchList(institution.branches);
    }
    setProductList([]);
    form.setFieldsValue({
      institution_branch_id: null,
      product_id: null,
      intake_year: null,
      product_intake_id: null,
    });
  };

  const handleBranchChange = async (id: string | any) => {
    setLoading({ ...loading, product: true });
    setProductList([]);
    if (id) {
      setSelectedBranch(id);
      const response = await getInstitutionProducts(id);
      setProductList(response?.data?.data);
      setLoading({ ...loading, product: false });
    }
    form.setFieldsValue({
      product_id: null,
      intake_year: null,
      product_intake_id: null,
    });
  };

  const handleProductSelect = (productId: any) => {
    getIntakeYears();
    if (productId) {
      productServices
        .apiGetProductIntakes(productId, selectedBranch || '')
        .then((res) => {
          setIntakeMonths(res.data.data);
        });
    }

    form.setFieldsValue({
      intake_year: null,
      product_intake_id: null,
    });
  };

  return (
    <Form layout="vertical" form={form} onFinish={handleFinish}>
      <Row gutter={GUTTER}>
        <Col span={form.getFieldValue('service_id') ? 12 : 24}>
          <FormItem
            name="service_id"
            label={LABEL.SERVICE}
            rules={[{ required: true }]}
          >
            <SearchableSelect
              optionFilter="key"
              placeholder={PLACEHOLDER.SELECT_SERVICE}
              onChange={handleServiceChange}
            >
              {loading.service ? (
                <Option value="null" disabled>
                  <Spin spinning size="small" className="full-width" />
                </Option>
              ) : (
                serviceList &&
                serviceList.map((item) => {
                  return (
                    <Option value={item.id} key={item.name}>
                      {item.name}
                    </Option>
                  );
                })
              )}
            </SearchableSelect>
          </FormItem>
        </Col>
        {form.getFieldValue('service_id') && (
          <Col span={12}>
            <Form.Item
              name="country"
              label={LABEL.COUNTRY}
              rules={[{ required: true }]}
            >
              <SearchableSelect
                placeholder={COUNTRY_PLACEHOLDER}
                optionFilter="search_prop"
                onChange={handleCountryChange}
              >
                {loading.country ? (
                  <Option value="null" disabled>
                    <Spin spinning size="small" className="full-width" />
                  </Option>
                ) : (
                  countryList &&
                  countryList?.map((item) => {
                    return (
                      <Option
                        value={item.name}
                        key={item.name}
                        search_prop={`${item.name}`}
                      >
                        {item.name}
                      </Option>
                    );
                  })
                )}
              </SearchableSelect>
            </Form.Item>
          </Col>
        )}

        {form.getFieldValue('service_id') && (
          <Col span="12">
            <FormItem
              name="workflow_type_id"
              label={LABEL.WORKFLOW_TYPE}
              rules={[{ required: true }]}
            >
              <SearchableSelect
                optionFilter="search_prop"
                placeholder={PLACEHOLDER.SELECT_WORKFLOW_TYPE}
                onChange={handleWorkflowTypeChange}
              >
                {loading.workflowType ? (
                  <Option value="null" disabled>
                    <Spin spinning size="small" className="full-width" />
                  </Option>
                ) : (
                  workflowList &&
                  workflowList.map((item) => {
                    return (
                      <Option
                        value={item.id}
                        key={item.id}
                        search_prop={item.name}
                      >
                        {item.name}
                      </Option>
                    );
                  })
                )}
              </SearchableSelect>
            </FormItem>
          </Col>
        )}

        {form.getFieldValue('service_id') && (
          <Col span="12">
            <FormItem
              name="institution_id"
              label={LABEL.INSTITUTION}
              rules={[{ required: true }]}
            >
              <SearchableSelect
                optionFilter="key"
                placeholder={PLACEHOLDER.SELECT_INSTITUTION}
                onChange={handleInstitutionChange}
              >
                {loading.institution ? (
                  <Option value="null" disabled>
                    <Spin spinning size="small" className="full-width" />
                  </Option>
                ) : (
                  institutionList &&
                  institutionList.map((item) => {
                    return (
                      <Option value={item.id} key={item.id}>
                        {item.name}
                      </Option>
                    );
                  })
                )}
              </SearchableSelect>
            </FormItem>
          </Col>
        )}

        {form.getFieldValue('service_id') && (
          <Col span="12">
            <FormItem
              name="institution_branch_id"
              label={LABEL.BRANCH}
              rules={[{ required: true }]}
            >
              <SearchableSelect
                optionFilter="search_prop"
                placeholder={PLACEHOLDER.SELECT_BRANCH}
                onChange={handleBranchChange}
              >
                {branchList &&
                  branchList.map((item) => {
                    return (
                      <Option
                        value={item.id}
                        key={item.id}
                        search_prop={item.name}
                      >
                        {item.name}
                      </Option>
                    );
                  })}
              </SearchableSelect>
            </FormItem>
          </Col>
        )}

        {form.getFieldValue('service_id') && (
          <Col span="12">
            <FormItem
              name="product_id"
              label={LABEL.PRODUCT}
              rules={[{ required: true }]}
            >
              <SearchableSelect
                optionFilter="search_prop"
                placeholder={PLACEHOLDER.SELECT_PRODUCT}
                onChange={handleProductSelect}
              >
                {loading.product ? (
                  <Option value="null" disabled>
                    <Spin spinning size="small" className="full-width" />
                  </Option>
                ) : (
                  productList &&
                  productList.map((item) => {
                    return (
                      <Option
                        value={item.id}
                        key={item.id}
                        search_prop={item.name}
                      >
                        {item.name}
                      </Option>
                    );
                  })
                )}
              </SearchableSelect>
            </FormItem>
          </Col>
        )}

        {form.getFieldValue('service_id') && (
          <>
            <Col span="12">
              <FormItem
                name="intake_year"
                label={LABEL.INTAKE_YEAR}
                rules={[{ required: true }]}
              >
                <SearchableSelect
                  optionFilter="key"
                  placeholder={PLACEHOLDER.INTAKE_YEAR}
                >
                  {intakeYears &&
                    intakeYears.map((item: number) => {
                      return (
                        <Option value={item} key={item}>
                          {item}
                        </Option>
                      );
                    })}
                </SearchableSelect>
              </FormItem>
            </Col>

            <Col span="12">
              <FormItem
                name="intake"
                label={LABEL.INTAKE_MONTH}
                rules={[{ required: true }]}
              >
                <SearchableSelect
                  optionFilter="key"
                  placeholder={PLACEHOLDER.INTAKE_MONTH}
                  className="initial_capital"
                >
                  {INTAKE_MONTHS.map((item: any) => {
                    return (
                      <Option
                        value={item.intake}
                        key={item.intake}
                        className="initial_capital"
                      >
                        {item.intake}
                      </Option>
                    );
                  })}
                </SearchableSelect>
              </FormItem>
            </Col>
          </>
        )}
      </Row>
    </Form>
  );
};

export { LeadApplicationForm };
