import React, { useState, useEffect } from 'react';
import { Col, Typography, Form, Row, Space } from 'antd';
import { Button, DrawerElem, Input } from '@shared-components/elements';
import { MinusCircleOutlined } from '@ant-design/icons';
import {
  BUTTON,
  CANCEL_BTN,
  DONE_BTN,
  DRAWER_WIDTH,
  LABEL_OPTION_SHOULD_NOT_BE_SIMILAR,
  MINIMUM_SIZE_OPTION_REQUIRED,
  OPTION_REQUIRED,
  TITLE,
} from '@moxie/constants';
import { LabelOptionDrawerProps } from '@shared-components/models';
import { PlusOutlined } from '@ant-design/icons';
import { useLabelOptionDrawer } from './use-label-option-drawer.hook';

const { Text } = Typography;

const LabelOptionDrawer: React.FC<LabelOptionDrawerProps> = ({
  selectedLabel,
  isOpen,
  onClose,
}: LabelOptionDrawerProps) => {
  const { form, handleClose, handleUpdate } = useLabelOptionDrawer(
    selectedLabel || ''
  );
  const [error, setError] = useState<boolean>();

  useEffect(() => {
    if (error) setTimeout(() => setError(false), 3000);
  }, [error]);

  const handleDoneBtn = () => {
    form.submit();
  };

  const handleSubmit = (value: any) => {
    handleUpdate(value.options);
    onClose();
  };

  const handleCloseBtn = () => {
    handleClose();
    onClose();
  };

  const optionListValidator = {
    validator(_: any, value: any) {
      if (form.getFieldsValue()?.options) {
        if (
          form
            .getFieldsValue()
            ?.options.filter(
              (item: any) =>
                item?.name === value &&
                item?.name.length > 0 &&
                value?.length > 0
            ).length > 1
        )
          return Promise.reject(LABEL_OPTION_SHOULD_NOT_BE_SIMILAR);
        return Promise.resolve();
      }
      return Promise.resolve();
    },
  };
  return (
    <DrawerElem
      title={
        selectedLabel && selectedLabel?.length > 2
          ? TITLE.UPDATE_OPTION
          : TITLE.ADD_OPTION
      }
      onClose={handleCloseBtn}
      visible={isOpen}
      width={DRAWER_WIDTH}
      footer={
        <div className="padding-bottom-2 text-align-right">
          <Space direction="horizontal">
            <Button htmlType="button" onClick={handleCloseBtn}>
              {CANCEL_BTN}
            </Button>
            <Button htmlType="button" onClick={handleDoneBtn} type="primary">
              {DONE_BTN}
            </Button>
          </Space>
        </div>
      }
    >
      <Row className={`${error && 'label-option__error'} label-option`}>
        <Col span={24}>
          <Form
            form={form}
            onFinish={handleSubmit}
            initialValues={{ options: [{ name: '' }, { name: '' }] }}
          >
            <Form.List name="options">
              {(fields, { add, remove }) => (
                <>
                  {fields.map(({ key, name, fieldKey, ...restField }) => (
                    <Row className="option-form-item" key={key}>
                      <Form.Item
                        {...restField}
                        name={[name, 'name']}
                        fieldKey={[fieldKey, 'name']}
                        style={{ maxWidth: '93%' }}
                        rules={[
                          {
                            required: true,
                            message: OPTION_REQUIRED,
                            whitespace: true,
                            type: 'string',
                          },
                          optionListValidator,
                        ]}
                      >
                        <Input />
                      </Form.Item>
                      {![0, 1].includes(name) && (
                        <MinusCircleOutlined
                          onClick={() => remove(name)}
                          className="color-danger"
                        />
                      )}
                    </Row>
                  ))}
                  <Row justify="center">
                    <Col span={8}>
                      <Button
                        type="primary"
                        onClick={() => add()}
                        block
                        icon={<PlusOutlined />}
                      >
                        {BUTTON.ADD_OPTION}
                      </Button>
                    </Col>
                  </Row>
                </>
              )}
            </Form.List>
          </Form>
        </Col>
      </Row>
      {error && (
        <Text type="danger">
          <small>{MINIMUM_SIZE_OPTION_REQUIRED}</small>
        </Text>
      )}
    </DrawerElem>
  );
};

export { LabelOptionDrawer };
