import React from 'react';
import { GUTTER, LABEL, LEAD_OTHER_TEST_SCORES, TITLE } from '@moxie/constants';
import { FormItem } from '@shared-components/elements';
import { ILeadOtherTestScoreProps } from '@shared-components/models';
import { Col, Divider, InputNumber, Row } from 'antd';
import Title from 'antd/lib/typography/Title';

const LeadFormOtherTestScore: React.FC<ILeadOtherTestScoreProps> = ({
  otherTestScoreData,
}: ILeadOtherTestScoreProps) => {
  return (
    <div className="created-leadForm-section ">
      <Title level={4}>{TITLE.OTHER_TEST_SCORES}</Title>
      <Row gutter={GUTTER}>
        {otherTestScoreData?.sat_1 && (
          <Col span="8">
            <FormItem
              name="sat_1"
              label={LABEL.SAT_I}
              rules={[
                {
                  min: 400,
                  type: 'number',
                  max: 1600,
                  message: LEAD_OTHER_TEST_SCORES.VALUE_LIMIT_ERROR,
                },
              ]}
            >
              <InputNumber className="full-width" />
            </FormItem>
          </Col>
        )}
        {otherTestScoreData?.sat_2 && (
          <Col span="8">
            <FormItem
              name="sat_2"
              label={LABEL.SAT_II}
              rules={[
                {
                  min: 400,
                  type: 'number',
                  max: 1600,
                  message: LEAD_OTHER_TEST_SCORES.VALUE_LIMIT_ERROR,
                },
              ]}
            >
              <InputNumber className="full-width" />
            </FormItem>
          </Col>
        )}
        {otherTestScoreData?.gre && (
          <Col span="8">
            <FormItem
              name="gre"
              label={LABEL.GRE}
              rules={[
                {
                  type: 'number',
                  min: 260,
                  max: 340,
                  message: LEAD_OTHER_TEST_SCORES.VALUE_LIMIT_ERROR_GRE,
                },
              ]}
            >
              <InputNumber className="full-width" />
            </FormItem>
          </Col>
        )}
        {otherTestScoreData?.gmat && (
          <Col span="8">
            <FormItem
              name="gmat"
              label={LABEL.GMAT}
              rules={[
                {
                  type: 'number',
                  min: 200,
                  max: 800,
                  message: LEAD_OTHER_TEST_SCORES.VALUE_LIMIT_ERROR_GMAT,
                },
              ]}
            >
              <InputNumber className="full-width" />
            </FormItem>
          </Col>
        )}
      </Row>
      <Divider plain />
    </div>
  );
};
export { LeadFormOtherTestScore };
