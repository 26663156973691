import { getAllUsersClient } from '@admin/services.api';

import {
  MailFilled,
  UserSwitchOutlined,
  DeleteOutlined,
} from '@ant-design/icons';
import {
  ARCHIVE,
  CONFIRM_MESSAGE,
  CONFIRM_TITLE,
  CONTACT,
  DELETE_BTN,
  LEAD_TITLE,
  SLUG_MY_CLIENTS,
} from '@moxie/constants';
import { Button, popConfirm, Table } from '@shared-components/elements';
import React, { useState } from 'react';
import { columns } from './user-my-clients-column';
import { useDispatch } from 'react-redux';
import { leadActions } from '@lyra/core';

const UserMyClientTable: React.FC = () => {
  const [selectedId, setSelectedId] = useState<string[]>([]);
  const [filter] = useState({ selectBy: SLUG_MY_CLIENTS });
  const [refresh, setRefresh] = useState(false);
  const dispatch = useDispatch();

  const triggerRefresh = () => {
    setRefresh((refresh) => !refresh);
  };

  const handleSelectedId = (ids: string[]) => {
    setSelectedId(ids);
  };

  const getData = async (str: string) => {
    return getAllUsersClient(str);
  };

  const handleMultipleArchive = () => {
    dispatch(leadActions.archiveMultipleLeadAction(selectedId));
    triggerRefresh();
  };

  const handleMultipleDelete = () => {
    dispatch(leadActions.deleteMultipleLeadAction(selectedId));
    triggerRefresh();
  };

  return (
    <Table
      tableName={LEAD_TITLE}
      refresh={refresh}
      columns={columns}
      size={'small'}
      onChangeSelected={handleSelectedId}
      action={getData}
      filter={filter}
      className="lead-table"
    >
      <Button type="primary" icon={<MailFilled />}>
        {CONTACT}
      </Button>
      <Button
        type="primary"
        icon={<UserSwitchOutlined />}
        onClick={() =>
          popConfirm(
            CONFIRM_TITLE.ARCHIVE_MULTIPLE_LEADS,
            `${CONFIRM_MESSAGE.ARCHIVE_MULTIPLE_LEADS} ${selectedId.length} leads?`,
            handleMultipleArchive
          )
        }
      >
        {ARCHIVE}
      </Button>
      <Button
        className="margin-left-1"
        type="primary"
        title={DELETE_BTN}
        onClick={() =>
          popConfirm(
            CONFIRM_TITLE.DELETE_MULTIPLE_LEADS,
            `${CONFIRM_MESSAGE.DELETE_MULTIPLE_LEADS} ${selectedId.length} leads`,
            handleMultipleDelete
          )
        }
        danger
        icon={<DeleteOutlined />}
      >
        {DELETE_BTN}
      </Button>
    </Table>
  );
};

export { UserMyClientTable };
