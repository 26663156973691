import axios from './axios';
import {
  IAuth,
  IAuthResponse,
  IChangePassword,
  IForgetPassword,
  IVerifyAccount,
} from '@shared-components/models';
import { AM_API_URL, API_URL } from '@moxie/constants';

const auth = (body: IAuth): Promise<IAuthResponse> => {
  return axios.post(AM_API_URL.RESETPASSWORD, body);
};

const getAllRolesPermissions = (params = ''): Promise<IAuthResponse> => {
  return axios.get(`${API_URL.ROLE}/${params}`);
};

const verifyAccount = (body: IVerifyAccount): Promise<IAuthResponse> => {
  return axios.post(`am${API_URL.EMAIL_VERIFICATION}`, body);
};

const resendVerifyAccountLink = (
  body: IForgetPassword
): Promise<IAuthResponse> => {
  return axios.post(`am/auth/resend-verification`, body);
};

const changePasswordApi = (body: IChangePassword): Promise<any> => {
  return axios.post(AM_API_URL.CHANGE_PASSWORD, body);
};

export {
  auth,
  verifyAccount,
  // verifyCrmAccount,
  resendVerifyAccountLink,
  getAllRolesPermissions,
  changePasswordApi,
};
