/* eslint-disable react-hooks/rules-of-hooks */
import React, { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { errorNotificationHandler, UserSelectBox } from '@moxie/shared';
import { applicationActions, useAppSelector } from '@lyra/core';
import { Avatar, Button, Form, Space, Tooltip } from 'antd';
import { CheckOutlined, CloseOutlined, UserOutlined } from '@ant-design/icons';
import { UserNameCard } from '@shared-components/elements';
import { ASSIGN_TO_BTN, TEXT } from '@moxie/constants';
import { useForm } from 'antd/lib/form/Form';

const ApplicationAssignee: React.FC<any> = (props: any) => {
  const [form] = useForm();
  const {
    data,
    setRefreshTable,
    refreshTable,
    isTabChanged,
    archivedWarnNotification,
  } = props;
  const contactArchived = data?.client?.archived;
  const dispatch = useDispatch();
  const [selectValues, setSelectedValues] = useState([]);
  const [selectArr, setSelectedArr] = useState([]);
  const [showAssignee, setShowAssignee] = useState<boolean>(false);
  const user = useAppSelector((state) => state.auth.user);
  const socket = useAppSelector((state) => state.socket.wss);

  const toggleShowAssignee = () => {
    if (contactArchived) {
      if (archivedWarnNotification) {
        archivedWarnNotification();
      }
      return;
    }
    setShowAssignee(!showAssignee);
  };

  const handleRefreshTable: any = () => {
    setRefreshTable(!refreshTable);
    toggleShowAssignee();
  };

  const handleSubmit = (values: any) => {
    if (values) {
      const assignedData = {
        agent_clients: values.agent_clients,
        contact_id: data?.client?.id,
      };
      const update_type = 'assignee';
      data?.id &&
        dispatch(
          applicationActions.updateRequest(
            {
              ...assignedData,
              id: data?.id,
              handleRefreshTable,
            },
            user,
            socket,
            data.client,
            update_type
          )
        );
    } else {
      errorNotificationHandler(TEXT.SELECT_ASSIGNEE, 2);
    }
  };

  useEffect(() => {
    const clients = data?.agent_clients.map((item: any) => item.id);
    setSelectedValues(clients);
    setSelectedArr(data?.agent_clients);
    form.setFieldsValue({ agent_clients: clients });
  }, [data?.agent_clients]);

  useEffect(() => {
    setShowAssignee(false);
  }, [isTabChanged]);

  return (
    <div
      className={
        contactArchived
          ? 'lead-table__archived-by cursor-disabled'
          : 'lead-table__archived-by'
      }
    >
      {!showAssignee ? (
        <div
          data-testid="applicationassigneeavatar"
          onClick={toggleShowAssignee}
        >
          <Tooltip title="Assignee">
            {selectValues?.length > 0 ? (
              <Avatar.Group maxCount={2} size="default">
                {selectArr?.map((item: any) => {
                  return (
                    <Tooltip
                      key={item.id}
                      color="white"
                      title={
                        <UserNameCard
                          firstName={item.first_name || ''}
                          lastName={item.last_name}
                          email={item.email || ''}
                          index={item.id || ''}
                        />
                      }
                      placement="top"
                    >
                      <Avatar>
                        {`${item.first_name
                          ?.substring(0, 1)
                          .toUpperCase()}${item.last_name
                          ?.substring(0, 1)
                          .toUpperCase()}`}
                      </Avatar>
                    </Tooltip>
                  );
                })}
              </Avatar.Group>
            ) : (
              <Avatar icon={<UserOutlined />} alt="assignee" />
            )}
          </Tooltip>
        </div>
      ) : (
        <div className="lead-table__name user-name-card">
          <div className="lead-assignee-selector">
            <Form form={form} onFinish={handleSubmit}>
              <Form.Item
                name="agent_clients"
                className="lead-assignee-selector__item"
              >
                <UserSelectBox
                  data-testid="applicationassignee"
                  showUnassigned={true}
                  defaultOpen={true}
                  placeholder={ASSIGN_TO_BTN}
                  mode="multiple"
                  maxTagCount={1}
                  showArrow={false}
                  bordered={false}
                  autoFocus={true}
                />
              </Form.Item>
              <Space size={8}>
                <Button
                  type="link"
                  htmlType="submit"
                  size="small"
                  className="padding-left-2 padding-right-1"
                >
                  <CheckOutlined data-testid="applicationassigneecheck" />
                </Button>
                <Button
                  onClick={toggleShowAssignee}
                  type="link"
                  danger
                  size="small"
                >
                  <CloseOutlined data-testid="applicationassigneecross" />
                </Button>
              </Space>
            </Form>
          </div>
        </div>
      )}
    </div>
  );
};

export { ApplicationAssignee };
