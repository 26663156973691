import React from 'react';
import {
  DashboardLayout,
  AccountSettingsLayout,
} from '@shared-components/modules';
import { Route } from 'react-router-dom';
import ChangePasswordSettings from './change-password';

const UserAccountSettings: React.FC = () => {
  return (
    <DashboardLayout className="content_margin--fluid">
      <AccountSettingsLayout>
        <Route
          exact
          path="/dashboard/account-settings"
          component={ChangePasswordSettings}
        />
      </AccountSettingsLayout>
    </DashboardLayout>
  );
};

export default UserAccountSettings;
