import { tenantActions, useAppSelector } from '@admin/core';
import { getAllTenants, updateTenantDetail } from '@admin/services.api';
import {
  CONFIRM_MESSAGE,
  CONFIRM_TITLE,
  PAGE_HEADER_MENU_TENANTS,
  TENANTS_TITLE,
} from '@moxie/constants';
import { successNotificationHandler } from '@moxie/shared';
import { errorHandler } from '@moxie/utils';
import {
  PageHeaderTitle,
  popConfirm,
  popConfirmCompany,
} from '@shared-components/elements';
import { useForm } from 'antd/lib/form/Form';
import React, { useState } from 'react';
import { useDispatch } from 'react-redux';
import RejectModal from './reject-modal';
import TenantDrawer from './tenant-drawer';
import TenantManageDrawer from './tenant-manage-drawer';
import { TenantTable } from './tenant-table';
import { FormItemProps } from 'antd';
import { PageHeaderMenu } from '../components/page-header-menu';

const TenantListComponent: React.FC = () => {
  const getTenants = async (params: string | undefined) => {
    return getAllTenants(params);
  };
  const [refresh, setRefresh] = useState(false);
  const triggerRefresh = () => setRefresh((prev) => !prev);
  const [visible, setVisible] = useState(false);
  const [isEdit, setIsEdit] = useState(false);
  const [manageTenant, setManageTenant] = useState(false);
  const [editManageTenant, setEditManageTenant] = useState(false);
  const [form] = useForm();
  const [tenantDetails] = useForm();
  const [companyId, setCompanyId] = useState<string>();
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [statusName, setStatusName] = useState<string>();
  const dispatch = useDispatch();
  const loading = useAppSelector((store) => store.tenant.loading);
  const [dialCode, setDialCode] = useState<string>();
  const [
    companyDomainValidationStatus,
    setCompanyDomainValidationStatus,
  ] = useState<FormItemProps['validateStatus']>('');
  const [
    companyCodeValidationStatus,
    setCompanyCodeValidationStatus,
  ] = useState<FormItemProps['validateStatus']>('');
  const [submitted, setSubmitted] = useState<boolean>(false);
  const [emailValidationStatus, setEmailValidationStatus] = useState<
    FormItemProps['validateStatus']
  >('');
  const [disableDrawerButton, setDisableDrawerButton] = useState(true);
  const [disableManageDrawerButton, setDisableManageDrawerButton] = useState(
    true
  );

  const showDrawer = async (id?: string) => {
    if (id) {
      setCompanyId(id);
      dispatch(tenantActions.fetchTenant(id));
      setIsEdit(true);
    } else {
      setIsEdit(false);
    }
    setVisible(!visible);
  };

  const showTenantManageDrawer = async (id?: string) => {
    if (id) {
      setCompanyId(id);
      dispatch(tenantActions.fetchTenant(id));
      setEditManageTenant(true);
    } else {
      setEditManageTenant(false);
    }
    setManageTenant(!visible);
  };

  const onTenantManageDrawerClose = () => {
    setManageTenant(!manageTenant);
    dispatch(tenantActions.clearFetchTenant());
    form.resetFields();
    setDisableManageDrawerButton(true);
  };

  const onClose = () => {
    setVisible(!visible);
    dispatch(tenantActions.clearFetchTenant());
    setEmailValidationStatus('');
    setCompanyCodeValidationStatus('');
    setCompanyDomainValidationStatus('');
    setDisableDrawerButton(true);
    form.resetFields();
    setSubmitted(false);
  };

  const handleFieldsChange = (fields: any) => {
    if (fields.length === 1) setDisableDrawerButton(false);
  };

  const handleSubmit = async (data: any) => {
    onClose();
    if (isEdit) {
      const companyData = data;
      companyData.company_phone = data?.phone;
      companyData.country_code = dialCode;
      delete companyData.phone;

      dispatch(
        tenantActions.updateTenantRequest(
          companyId,
          companyData,
          onClose,
          triggerRefresh
        )
      );
    } else {
      data.price_plan = 'enterprise';
      data.company_size = data?.number_of_employees;
      data.country_code = dialCode;
      dispatch(tenantActions.addTenantRequest(data, onClose, triggerRefresh));
    }
  };

  const handleUpdateDetails = async (data: any) => {
    const response = await updateTenantDetail(companyId, data);
    triggerRefresh();
    successNotificationHandler(response?.data.message);
    onTenantManageDrawerClose();
  };

  const deleteData = async (id: string) => {
    popConfirm(
      CONFIRM_TITLE.DELETE_COMPANY,
      CONFIRM_MESSAGE.DELETE_COMPANY,
      async () => {
        dispatch(tenantActions.deleteTenantRequest(id, triggerRefresh));
      }
    );
  };

  const handleStatusRejection = async (comment: string) => {
    dispatch(
      tenantActions.disableTenantRequest(
        companyId,
        {
          status: statusName,
          comment: comment,
        },
        triggerRefresh
      )
    );
    setIsModalVisible(false);
  };

  const changeCompanyStatus = async (id: string, name: string) => {
    try {
      if (name === 'rejected') {
        setIsModalVisible(true);
        setCompanyId(id);
        setStatusName(name);
      } else if (name === 'disabled') {
        popConfirmCompany(
          CONFIRM_TITLE.DISABLE_COMPANY,
          CONFIRM_MESSAGE.DISABLE_COMPANY,
          async () => {
            dispatch(
              tenantActions.disableTenantRequest(
                id,
                {
                  status: name,
                },
                triggerRefresh
              )
            );
            setIsModalVisible(false);
          }
        );
      } else {
        dispatch(
          tenantActions.disableTenantRequest(
            id,
            {
              status: name,
            },
            triggerRefresh
          )
        );
        setIsModalVisible(false);
      }
    } catch (err) {
      errorHandler(err);
    }
  };

  return (
    <div className="full-height">
      <PageHeaderTitle title={TENANTS_TITLE} />
      <PageHeaderMenu menu={PAGE_HEADER_MENU_TENANTS} />
      <TenantTable
        getData={getTenants}
        tableName={TENANTS_TITLE}
        triggerRefresh={triggerRefresh}
        refreshTable={refresh}
        showDrawer={showDrawer}
        deleteData={deleteData}
        manageTenantDetail={showTenantManageDrawer}
        changeCompanyStatus={changeCompanyStatus}
        loading={loading}
      />
      <TenantDrawer
        isOpen={visible}
        showDrawer={onClose}
        isEdit={isEdit}
        form={form}
        handleSubmit={handleSubmit}
        dialCode={dialCode}
        setDialCode={setDialCode}
        setCompanyDomainValidationStatus={setCompanyDomainValidationStatus}
        companyCodeValidationStatus={companyCodeValidationStatus}
        companyDomainValidationStatus={companyDomainValidationStatus}
        setCompanyCodeValidationStatus={setCompanyCodeValidationStatus}
        emailValidationStatus={emailValidationStatus}
        setEmailValidationStatus={setEmailValidationStatus}
        handleFieldsChange={handleFieldsChange}
        disableDrawerButton={disableDrawerButton}
        setDisableDrawerButton={setDisableDrawerButton}
        submitted={submitted}
        setSubmitted={setSubmitted}
      />
      <TenantManageDrawer
        isOpen={manageTenant}
        showDrawer={onTenantManageDrawerClose}
        isEdit={editManageTenant}
        form={tenantDetails}
        handleSubmit={handleUpdateDetails}
        disableManageDrawerButton={disableManageDrawerButton}
        setDisableManageDrawerButton={setDisableManageDrawerButton}
      />
      <RejectModal
        setIsModalVisible={setIsModalVisible}
        isModalVisible={isModalVisible}
        handleStatusRejection={handleStatusRejection}
      />
    </div>
  );
};

export { TenantListComponent };
