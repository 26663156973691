import React from 'react';

const applicationActions = {
  CLICK_STAGE: 'CLICK_STAGE',
  SET_CURRENT_STAGE: 'SET_CURRENT_STAGE',
  SET_SELECTED_CHECKLIST: 'SET_SELECTED_CHECKLIST',
  CHECK_LIST_DETAIL_CLICK: 'CHECK_LIST_DETAIL_CLICK',
  CLEAR_DETAIL_AND_CHECKLIST: 'CLEAR_DETAIL_AND_CHECKLIST',
  PREVIOUS_STAGE_AND_CLEAR_DETAIL_AND_CHECKLIST:
    'PREVIOUS_STAGE_AND_CLEAR_DETAIL_AND_CHECKLIST',
  NEXT_STAGE_AND_CLEAR_DETAIL_AND_CHECKLIST:
    'NEXT_STAGE_AND_CLEAR_DETAIL_AND_CHECKLIST',
  GO_TO_CURRENT_STAGE: 'GO_TO_CURRENT_STAGE',
  START_DOCUMENTS_DOWNLOADING: 'START_DOCUMENTS_DOWNLOADING',
  FINISH_DOCUMENTS_DOWNLOADING: 'FINISH_DOCUMENTS_DOWNLOADING',
  COMPLETE_STAGE: 'COMPLETE_STAGE',
};

const applicationReducer = (state: any, action: any) => {
  switch (action.type) {
    case applicationActions.CLICK_STAGE:
      return {
        ...state,
        selectedStage: action.selectedStage,
        showDetail: false,
        selectedChecklist: '',
      };
    case applicationActions.SET_CURRENT_STAGE:
      return {
        ...state,
        currentStage: action.currentStage,
      };
    case applicationActions.SET_SELECTED_CHECKLIST:
      return {
        ...state,
        selectedChecklistData: action.checklistData,
      };
    case applicationActions.CHECK_LIST_DETAIL_CLICK:
      return {
        ...state,
        showDetail: true,
        selectedChecklist: action.selectedChecklist,
      };
    case applicationActions.CLEAR_DETAIL_AND_CHECKLIST:
      return {
        ...state,
        showDetail: false,
        selectedChecklist: '',
      };
    case applicationActions.PREVIOUS_STAGE_AND_CLEAR_DETAIL_AND_CHECKLIST:
      return {
        ...state,
        showDetail: false,
        selectedChecklist: '',
        selectedStage: {
          stage: action.previousStage.stage,
          wfAppStageId: action.previousStage.wfAppStageId,
          level: action.previousStage.level,
          actor: action.previousStage.actor,
        },
      };
    case applicationActions.NEXT_STAGE_AND_CLEAR_DETAIL_AND_CHECKLIST:
      return {
        ...state,
        showDetail: false,
        selectedChecklist: '',
        selectedStage: {
          stage: action.nextStage.stage,
          wfAppStageId: action.nextStage.wfAppStageId,
          level: action.nextStage.level,
          actor: action.nextStage.actor,
        },
      };
    case applicationActions.COMPLETE_STAGE:
      return {
        ...state,
        showDetail: false,
        selectedChecklist: '',
        selectedStage: null,
      };
    case applicationActions.GO_TO_CURRENT_STAGE:
      return {
        ...state,
        selectedStage: action.currentStage,
        showDetail: false,
      };
    case applicationActions.START_DOCUMENTS_DOWNLOADING:
      return {
        ...state,
        isDocumentsDownloading: true,
      };
    case applicationActions.FINISH_DOCUMENTS_DOWNLOADING:
      return {
        ...state,
        isDocumentsDownloading: false,
      };
    default:
      return state;
  }
};

const initialState = {
  selectedStage: null,
  currentStage: null,
  showDetail: false,
  selectedChecklist: '',
  selectedChecklistData: [],
  disableView: false,
  isDocumentsDownloading: false,
};

const ApplicationContext = React.createContext<any>(null);

const ApplicationProvider = ({ children }: any) => {
  const [state, dispatch] = React.useReducer(applicationReducer, initialState);

  const value = {
    applicationState: state,
    clickStage: (selectedStage: any) => {
      dispatch({ type: applicationActions.CLICK_STAGE, selectedStage });
    },
    setCurrentStage: (currentStage: any) => {
      dispatch({ type: applicationActions.SET_CURRENT_STAGE, currentStage });
    },
    setSelectedChecklist: (checklistData: any) => {
      dispatch({
        type: applicationActions.SET_SELECTED_CHECKLIST,
        checklistData,
      });
    },
    checkListDetailClick: (selectedChecklist: any) => {
      dispatch({
        type: applicationActions.CHECK_LIST_DETAIL_CLICK,
        selectedChecklist,
      });
    },
    clearDetailAndChecklist: () => {
      dispatch({ type: applicationActions.CLEAR_DETAIL_AND_CHECKLIST });
    },
    handlePreviousStage: (previousStage: any) => {
      dispatch({
        type: applicationActions.PREVIOUS_STAGE_AND_CLEAR_DETAIL_AND_CHECKLIST,
        previousStage,
      });
    },
    handleNextStage: (nextStage: any) => {
      dispatch({
        type: applicationActions.NEXT_STAGE_AND_CLEAR_DETAIL_AND_CHECKLIST,
        nextStage,
      });
    },
    handleCompleteStage: (data: any) => {
      dispatch({
        type: applicationActions.COMPLETE_STAGE,
        data,
      });
    },
    goToCurrentStage: (currentStage: any) => {
      dispatch({ type: applicationActions.GO_TO_CURRENT_STAGE, currentStage });
    },
    startDocumentsDownloading: () => {
      dispatch({ type: applicationActions.START_DOCUMENTS_DOWNLOADING });
    },
    finishDocumentsDownloading: () => {
      dispatch({ type: applicationActions.FINISH_DOCUMENTS_DOWNLOADING });
    },
  };

  return (
    <ApplicationContext.Provider value={value}>
      {children}
    </ApplicationContext.Provider>
  );
};

export { ApplicationContext, ApplicationProvider, applicationActions };
