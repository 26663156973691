import React from 'react';
import { Link } from 'react-router-dom';
import { Col, Row, Typography } from 'antd';

import { ITableTitleCard } from '@shared-components/models';
import { URL_PRODUCT_DETAIL_LINK } from '@moxie/constants';

export const TableClientApplicationNameCard: React.FC<ITableTitleCard> = ({
  data,
}: ITableTitleCard) => {
  return (
    <Row>
      <Col span={18} className="align-items-center">
        <div>
          <Col>
            <Link
              to={`${URL_PRODUCT_DETAIL_LINK}${data?.product?.id}/applications`}
            >
              <Typography.Text className="text-primary-color">
                {data?.product?.name}
              </Typography.Text>
            </Link>
          </Col>
          <Col>
            <Typography.Text>
              <small>
                <span>{data?.institution?.name}</span>
                {data?.branch && <span>{` (${data?.branch?.name})`}</span>}
              </small>
            </Typography.Text>
          </Col>
        </div>
      </Col>
    </Row>
  );
};
