import React from 'react';
interface IIconTextProps {
  icon?: any;
  text?: string;
}
const IconTextComponent: React.FC<IIconTextProps> = ({
  icon,
  text,
}: IIconTextProps) => {
  return (
    <div className="text-grey text-icon">
      {icon && icon}
      <span className="margin-left-1">{text && text}</span>
    </div>
  );
};
export default IconTextComponent;
