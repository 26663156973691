import {
  IAction,
  IEducation,
  IEducationPayload,
} from '@shared-components/models';
import * as actionTypes from './education-background.constant';

export const addEducationBackgroundRequest = (
  payload: IEducation
): IAction<IEducation> => ({
  type: actionTypes.ADD_EDUCATION_BACKGROUND_REQUEST,
  payload,
});
export const addEducationBackgroundSuccess = (
  payload: IEducation
): IAction<IEducation> => ({
  type: actionTypes.ADD_EDUCATION_BACKGROUND_SUCCESS,
  payload,
});
export const addEducationBackgroundFailure = (payload: any): IAction<any> => ({
  type: actionTypes.ADD_EDUCATION_BACKGROUND_FAILURE,
  payload,
});

export const getEducationBackgroundsRequest = (
  id: string
): IAction<string> => ({
  type: actionTypes.GET_EDUCATION_BACKGROUNDS_REQUEST,
  payload: id,
});
export const getEducationBackgroundsSuccess = (
  payload: IEducation[]
): IAction<IEducation[]> => ({
  type: actionTypes.GET_EDUCATION_BACKGROUNDS_SUCCESS,
  payload,
});
export const getEducationBackgroundsFailure = (
  payload: IEducationPayload
): IAction => ({
  type: actionTypes.GET_EDUCATION_BACKGROUND_FAILURE,
  payload,
});
export const getEducationBackgroundsClear = () => {
  return { type: actionTypes.GET_EDUCATION_BACKGROUNDS_CLEAR };
};

export const updateEducationBackgroundRequest = (
  payload: IEducationPayload
): IAction => ({
  type: actionTypes.UPDATE_EDUCATION_BACKGROUND_REQUEST,
  payload,
});
export const updateEducationBackgroundSuccess = (
  payload: IEducation
): IAction<IEducation> => ({
  type: actionTypes.UPDATE_EDUCATION_BACKGROUND_SUCCESS,
  payload,
});
export const updateEducationBackgroundFailure = (
  payload: IEducationPayload
): IAction<IEducationPayload> => ({
  type: actionTypes.UPDATE_EDUCATION_BACKGROUND_FAILURE,
  payload,
});

export const deleteEducationBackgroundRequest = (
  payload: IEducationPayload
): IAction => ({
  type: actionTypes.DELETE_EDUCATION_BACKGROUND_REQUEST,
  payload,
});
export const deleteEducationBackgroundSuccess = (
  payload: IEducationPayload
): IAction<IEducationPayload> => ({
  type: actionTypes.DELETE_EDUCATION_BACKGROUND_SUCCESS,
  payload,
});
export const deleteEducationBackgroundFailure = (
  payload: IEducationPayload
): IAction<IEducationPayload> => ({
  type: actionTypes.DELETE_EDUCATION_BACKGROUND_FAILURE,
  payload,
});
