import axios from './axios';
import { ILoginResponse } from '@shared-components/models';
import { API_URL } from '@moxie/constants';

const markNotificationRead = (id: string): Promise<ILoginResponse> => {
  return axios.put(
    `${process.env.NX_BASE_URL}${API_URL.NOTIFICATION}/markread/${id}`
  );
};

const markNotificationReadAll = (userId: string): Promise<ILoginResponse> => {
  return axios.put(
    `${process.env.NX_BASE_URL}${API_URL.NOTIFICATION}/admin/markread/all`,
    { userId }
  );
};

export { markNotificationRead, markNotificationReadAll };
