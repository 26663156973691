import React from 'react';
import { List, Space } from 'antd';
import { useDispatch } from 'react-redux';
import { tasksActions } from '@lyra/core';
import { DocumentFileIcons, popConfirm } from '@moxie/shared';
import { MinusCircleTwoTone, DownloadOutlined } from '@ant-design/icons';
import { ITaskFileNameComponent } from '@shared-components/models';
import { deleteTaskDocuments, getOneDocument } from '@lyra/services.api';
import { errorHandler } from '@moxie/utils';
import { CONFIRM_MESSAGE, DELETE_BTN, DOWNLOAD } from '@moxie/constants';

const TaskFileNameComponent: React.FC<ITaskFileNameComponent> = ({
  item,
  onRemove,
  isEditMode,
  isView,
}: ITaskFileNameComponent) => {
  const dispatch = useDispatch();

  const handleRemove = async (item: any) => {
    if (!isEditMode) {
      return;
    }

    popConfirm(DELETE_BTN, CONFIRM_MESSAGE.DELETE_DOCUMENT, async () => {
      try {
        await deleteTaskDocuments(item);
        onRemove(item);
      } catch (err) {
        errorHandler(err);
      }
    });
  };

  const downloadDocument = async () => {
    dispatch(tasksActions.downloading(true));
    const response = await getOneDocument(item?.document?.id);
    window.open(response.downloadURL);
    dispatch(tasksActions.downloading(false));
  };

  return (
    <List.Item>
      <List.Item.Meta
        avatar={<DocumentFileIcons mimetype={item?.document?.mimetype} />}
        title={
          <Space className="ellipsis-text margin-top-1">
            <div>{item?.document?.name}</div>
          </Space>
        }
      />
      <Space>
        <DownloadOutlined
          className="action__button__icon margin-left-1"
          onClick={downloadDocument}
          title={DOWNLOAD}
        />

        {!isView && (
          <MinusCircleTwoTone
            twoToneColor="red"
            className="action__button__icon margin-left-1"
            onClick={() => handleRemove(item)}
            title={DELETE_BTN}
          />
        )}
      </Space>
    </List.Item>
  );
};

export { TaskFileNameComponent };
