const alias = 'ROLE';

export const GET_ROLES_REQUEST = `GET_${alias}S_REQUEST`;
export const GET_ROLES_SUCCESS = `GET_${alias}S_SUCCESS`;
export const GET_ROLES_FAILURE = `GET_${alias}_FAILURE`;
export const GET_ROLES_CLEAR = `GET_${alias}S_CLEAR`;

export const GET_ROLE_REQUEST = `GET_${alias}_REQUEST`;
export const GET_ROLE_SUCCESS = `GET_${alias}_SUCCESS`;
export const GET_ROLE_FAILURE = `GET_${alias}_FAILURE`;

export const ADD_ROLE_REQUEST = `ADD_${alias}_REQUEST`;
export const ADD_ROLE_SUCCESS = `ADD_${alias}_SUCCESS`;
export const ADD_ROLE_FAILURE = `ADD_${alias}_FAILURE`;

export const UPDATE_ROLE_REQUEST = `UPDATE_${alias}_REQUEST`;
export const UPDATE_ROLE_SUCCESS = `UPDATE_${alias}_SUCCESS`;
export const UPDATE_ROLE_FAILURE = `UPDATE_${alias}_FAILURE`;

export const DELETE_ROLE_REQUEST = `DELETE_${alias}_REQUEST`;
export const DELETE_ROLE_SUCCESS = `DELETE_${alias}_SUCCESS`;
export const DELETE_ROLE_FAILURE = `DELETE_${alias}_FAILURE`;

const alias2 = 'PERMISSION';

export const GET_PERMISSIONS_REQUEST = `GET_${alias2}S_REQUEST`;
export const GET_PERMISSIONS_SUCCESS = `GET_${alias2}S_SUCCESS`;
export const GET_PERMISSIONS_FAILURE = `GET_${alias2}_FAILURE`;
export const GET_PERMISSIONS_CLEAR = `GET_${alias2}S_CLEAR`;

export const GET_PERMISSION_REQUEST = `GET_${alias2}_REQUEST`;
export const GET_PERMISSION_SUCCESS = `GET_${alias2}_SUCCESS`;
export const GET_PERMISSION_FAILURE = `GET_${alias2}_FAILURE`;

export const ADD_PERMISSION_REQUEST = `ADD_${alias2}_REQUEST`;
export const ADD_PERMISSION_SUCCESS = `ADD_${alias2}_SUCCESS`;
export const ADD_PERMISSION_FAILURE = `ADD_${alias2}_FAILURE`;

export const UPDATE_PERMISSION_REQUEST = `UPDATE_${alias2}_REQUEST`;
export const UPDATE_PERMISSION_SUCCESS = `UPDATE_${alias2}_SUCCESS`;
export const UPDATE_PERMISSION_FAILURE = `UPDATE_${alias2}_FAILURE`;

export const DELETE_PERMISSION_REQUEST = `DELETE_${alias2}_REQUEST`;
export const DELETE_PERMISSION_SUCCESS = `DELETE_${alias2}_SUCCESS`;
export const DELETE_PERMISSION_FAILURE = `DELETE_${alias2}_FAILURE`;
