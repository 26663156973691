import React from 'react';
import {
  BlockOutlined,
  BarChartOutlined,
  BankOutlined,
  ReadOutlined,
  TeamOutlined,
  SettingOutlined,
  UserOutlined,
  PartitionOutlined,
  AuditOutlined,
} from '@ant-design/icons';
import {
  APPLICATION_SETTING,
  APPLICATION_SETTINGS,
  APPLICATION_TITLE,
  INSTITUTE_TITLE,
  PRODUCT_TITLE,
  REPORT_TITLE,
  TENANTS,
  TENANTS_TITLE,
  TEXT,
  URL_APPLICATION,
  URL_APPLICATION_REPORT,
  URL_CLIENT,
  URL_CONTACT_REPORT,
  URL_DASHBOARD,
  URL_INSTITUTION,
  URL_LEAD_REPORT,
  URL_PRODUCTS,
  URL_USER,
  URL_WORKFLOWS,
  USER_TITLE,
  WORKFLOW_TITLE,
} from '@moxie/constants';
import { ClientOutlinedIcon } from '../../elements/icons/libs/client';

export const routes = {
  route: {
    path: URL_DASHBOARD,
    layout: false,
    routes: [
      {
        path: URL_DASHBOARD,
        exact: true,
        name: TEXT.DASHBOARD,
        icon: <BarChartOutlined />,
      },
      {
        path: URL_APPLICATION,
        exact: true,
        name: TEXT.APPLICATIONS,
        icon: <BlockOutlined />,
      },
      {
        path: URL_CLIENT,
        exact: true,
        name: TEXT.CLIENTS,
        icon: <ClientOutlinedIcon />,
      },
      {
        path: TENANTS,
        exact: true,
        name: TENANTS_TITLE,
        icon: <TeamOutlined />,
      },
      {
        path: URL_INSTITUTION,
        exact: true,
        name: INSTITUTE_TITLE,
        icon: <BankOutlined />,
      },
      {
        path: URL_PRODUCTS,
        exact: true,
        name: PRODUCT_TITLE,
        icon: <ReadOutlined />,
      },
      {
        path: URL_USER,
        exact: true,
        name: USER_TITLE,
        icon: <UserOutlined />,
      },
      {
        path: URL_WORKFLOWS,
        exact: true,
        name: WORKFLOW_TITLE,
        icon: <PartitionOutlined />,
      },
      {
        path: APPLICATION_SETTING,
        exact: true,
        name: APPLICATION_SETTINGS,
        icon: <SettingOutlined />,
      },

      {
        name: REPORT_TITLE,
        icon: <AuditOutlined />,
        routes: [
          {
            path: URL_CONTACT_REPORT,
            exact: true,
            name: TEXT.CLIENTS,
          },
          {
            path: URL_APPLICATION_REPORT,
            exact: true,
            name: APPLICATION_TITLE,
          },
        ],
      },
    ],
  },
};
