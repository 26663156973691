// import { CalendarOutlined, ClockCircleOutlined } from '@ant-design/icons';
import { Pie } from '@ant-design/plots';
import { useAppSelector, workflowActions } from '@lyra/core';
import { GUTTER, TEXT } from '@moxie/constants';
// import { StatusComponent } from 'libs/shared/src/modules/tasks/libs/tasks-table';
import { SearchableSelect } from '@shared-components/elements';
import { IWorkflow } from '@shared-components/models';
import { Card, Col, Empty, Row, Select, Spin } from 'antd';
// import IconTextComponent from 'libs/shared/src/elements/text-icon';
// import moment from 'moment-timezone';
import React, { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { RootState } from '../../core/store';
import {
  BarChartComponent,
  BarClientComponent,
} from './dashboard-charts/barchart-component';
// import IconTextComponent from 'libs/shared/src/elements/text-icon';
// import { ClockCircleOutlined, CalendarOutlined } from '@ant-design/icons';
interface IDashboardCard {
  currentUser: any;
  contacts: any;
  applications: any;
  clients: any;
}
const { Option } = Select;
const DashboardCharts: React.FC<IDashboardCard> = ({
  // currentUser,
  contacts,
  applications,
  clients,
}: IDashboardCard) => {
  const dispatch = useDispatch();
  const workflows = useAppSelector((state) => state?.workflows?.allData);
  const { loading, workflowStage } = useAppSelector((store: RootState) => ({
    // today: store.user_appointment.todayData,
    loading: store.workflows.loading,
    // remainingTasks: store.tasks.remainingTasks,
    workflowStage: store.workflows.stages,
  }));
  // const renderStatistic = (
  //   containerWidth: number,
  //   text: string,
  //   style: React.CSSProperties
  // ) => {
  //   const { width: textWidth, height: textHeight } = measureTextWidth(
  //     text,
  //     style
  //   );
  //   const baseValue = containerWidth / 2;
  //   let scale = 1;
  //   if (containerWidth < textWidth) {
  //     scale = Math.min(
  //       Math.sqrt(
  //         Math.abs(
  //           Math.pow(baseValue, 2) /
  //             (Math.pow(textWidth / 2, 2) + Math.pow(textHeight, 2))
  //         )
  //       ),
  //       1
  //     );
  //   }
  //   const textStyleStr = `width:${containerWidth}px;`;
  //   return `<div style="${textStyleStr};font-size:${
  //     style?.fontSize
  //   }px;line-height:${scale < 1 ? 1 : 'inherit'};">${text}</div>`;
  // };
  const data = [
    {
      type: TEXT.LEADS,
      value: contacts?.filter((contact: any) => contact?.status === 'Lead')
        ?.length,
    },
    {
      type: TEXT.PROSPECTS,
      value: contacts?.filter((contact: any) => contact?.status === 'Prospect')
        ?.length,
    },
    {
      type: TEXT.CLIENTS,
      value: contacts?.filter((contact: any) => contact?.status === 'Client')
        ?.length,
    },
  ];

  const applicationData = [
    {
      type: 'In Progress',
      value: applications?.filter(
        (application: any) => application?.status === 'In Progress'
      )?.length,
    },
    {
      type: 'Completed',
      value: applications?.filter(
        (application: any) => application?.status === 'Completed'
      )?.length,
    },
    {
      type: 'Discontinued',
      value: applications?.filter(
        (application: any) => application?.status === 'Discontinued'
      )?.length,
    },
  ];

  const pieConfig = {
    appendPadding: 10,
    data: applicationData,
    angleField: 'value',
    colorField: 'type',
    radius: 0.9,
    height: 250,
    width: 250,
    label: {
      type: 'inner',
      offset: '-50%',
      content: () => '',
      style: {
        textAlign: 'center',
        fontSize: 14,
      },
    },
    interactions: [
      {
        type: 'element-selected',
      },
      {
        type: 'element-active',
      },
    ],
    statistic: {
      title: false,
      content: {
        style: {
          whiteSpace: 'pre-wrap',
          overflow: 'hidden',
          textOverflow: 'ellipsis',
        },
        content: `Total: ${data?.length}`,
      },
    },
  };
  // const config = {
  //   appendPadding: 10,
  //   data,
  //   angleField: 'value',
  //   height: 250,
  //   width: 250,
  //   colorField: 'type',
  //   radius: 1,
  //   innerRadius: 0.6,
  //   label: {
  //     type: 'inner',
  //     offset: '-50%',
  //     content: () => '',
  //     style: {
  //       textAlign: 'center',
  //       fontSize: 14,
  //     },
  //   },
  //   interactions: [
  //     {
  //       type: 'element-selected',
  //     },
  //     {
  //       type: 'element-active',
  //     },
  //     {
  //       type: 'pie-statistic-active',
  //     },
  //   ],
  //   statistic: {
  //     title: false,
  //     content: {
  //       style: {
  //         whiteSpace: 'pre-wrap',
  //         overflow: 'hidden',
  //         textOverflow: 'ellipsis',
  //       },
  //       customHtml: (container: any, view: any, datum: any, data: any) => {
  //         const { width } = container.getBoundingClientRect();
  //         const text = datum
  //           ? `Total: ${datum.value}`
  //           : `Total: ${data.reduce((r: any, d: any) => r + d.value, 0)}`;
  //         return renderStatistic(width, text, {
  //           fontSize: 20,
  //         });
  //       },
  //     },
  //   },
  // };
  // const statusList = (value: string) => {
  //   switch (value) {
  //     case 'to-do':
  //       return (
  //         <Text className="text-warning">
  //           <Badge className="text-warning" status="warning" />
  //           <strong>{TEXT.TODO}</strong>
  //         </Text>
  //       );
  //     case 'in-progress':
  //       return (
  //         <Text className="text-processing">
  //           <Badge className="text-processing" status="processing" />
  //           <strong>{TEXT.IN_PROGRESS}</strong>
  //         </Text>
  //       );
  //     case 'done':
  //       return (
  //         <Text className="text-success">
  //           <Badge className="text-success" status="success" />
  //           <strong>{TEXT.STATUS_DONE}</strong>
  //         </Text>
  //       );
  //     default:
  //       return (
  //         <Text className="text-warning">
  //           <Badge className="text-warning" status="warning" />
  //           <b> {TEXT.TODO}</b>
  //         </Text>
  //       );
  //   }
  // };

  const handleChangeWorkflow = async (key: string | any) => {
    dispatch(workflowActions.getWorkflowStagesRequest(key));
  };

  const fetchStagesOnLoad = async () => {
    if (workflows?.length > 0) {
      dispatch(
        workflowActions.getWorkflowStagesRequest(workflows && workflows[0]?.id)
      );
    }
  };

  useEffect(() => {
    fetchStagesOnLoad();
  }, [workflows]);

  return (
    <>
      {loading ? (
        <div className="dashboard-spinner">
          <Spin size="large" />
        </div>
      ) : (
        <>
          <Row gutter={GUTTER} className="margin-top-3">
            {/* <Col xs={24} md={12} xl={8}>
              <Card
                hoverable
                title="Today's Appointment"
                className="dashboard-card-custom"
              >
                {today && today.length <= 0 ? (
                  <div className="card-body">
                    <Empty
                      image={Empty.PRESENTED_IMAGE_SIMPLE}
                      description={<span>{LABEL.NO_APPOINTMENTS}</span>}
                    />
                  </div>
                ) : (
                  <div className=" card-body">
                    {today &&
                      today?.map((appointment: any, index: number) => {
                        const appointmentDay = moment(appointment?.date)
                          .tz(appointment?.time_zone)
                          .format('DD');
                        const appointmentDays = moment(appointment?.date)
                          .tz(appointment?.time_zone)
                          .format('MMMM, dddd');
                        const appointmentYear = moment(appointment?.date)
                          .tz(appointment?.time_zone)
                          .format('YYYY');
                        const appointmentTime = moment(
                          appointment && appointment?.time.split('.')[0]
                        )
                          .tz(currentUser?.time_zone)
                          .format('hh:mm A');
                        return (
                          <div key={index}>
                            <Row
                              gutter={GUTTER}
                              key={appointment?.id}
                              className="dashboard-card"
                            >
                              <Col xs={24} md={12} xl={8}>
                                <div>
                                  <Typography.Title level={4}>
                                    {appointmentDay}
                                  </Typography.Title>
                                </div>
                                <div>
                                  <Typography.Title level={5}>
                                    {appointmentDays}
                                  </Typography.Title>
                                </div>
                              </Col>
                              <Col xs={24} md={12} xl={2}>
                                <Divider
                                  type="vertical"
                                  className="dashboard-card__vertical-divider"
                                />
                              </Col>
                              <Col
                                xs={24}
                                md={12}
                                xl={14}
                                className="padding-top-1"
                              >
                                <div>
                                  <Typography.Text
                                    className="text-primary-color dashboard-text"
                                    strong
                                  >
                                    {appointment?.title}
                                  </Typography.Text>
                                </div>
                                <div>
                                  <IconTextComponent
                                    icon={<ClockCircleOutlined />}
                                    text={appointmentTime}
                                  ></IconTextComponent>
                                  <IconTextComponent
                                    icon={<CalendarOutlined />}
                                    text={appointmentYear}
                                  ></IconTextComponent>
                                </div>
                              </Col>
                            </Row>
                            {today?.length > 1 &&
                              today[today?.length - 1]?.id !==
                                appointment?.id && (
                                <Divider className="dashboard-card__horizontal-divider" />
                              )}
                          </div>
                        );
                      })}
                  </div>
                )}
              </Card>
            </Col>
            {/* <Col xs={24} md={12} xl={8} >
              <Card
                hoverable
                title="My Remaining Tasks"
                className="dashboard-card-custom"
              >
                {remainingTasks && remainingTasks.length <= 0 ? (
                  <div className="card-body">
                    <Empty image={Empty.PRESENTED_IMAGE_SIMPLE} />
                  </div>
                ) : (
                  <div
                    className={
                      remainingTasks?.length >= 4
                        ? 'dashboard-card__overflow card-body'
                        : 'dashboard-card__overflow__card-height card-body'
                    }
                  >
                    <List
                      className={
                        remainingTasks?.length >= 4
                          ? 'dashboard-card__overflow card-body'
                          : 'dashboard-card__overflow__card-height card-body'
                      }
                      dataSource={remainingTasks}
                      renderItem={(task: ITasks) => (
                        <List.Item
                          actions={[
                            <StatusComponent
                              key={task?.id}
                              data={task}
                              statusList={statusList}
                            />,
                          ]}
                        >
                          <List.Item.Meta
                            title={task?.title}
                            className="initial_capital"
                          />
                        </List.Item>
                      )}
                    />
                  </div>
                )}
              </Card>
            </Col> */}
            {/* <Col xs={24} md={12} xl={8}>
              <Card
                hoverable
                title="Contacts"
                className="dashboard-card-custom"
              >
                <div className="dashboard-card__overflow__card-height card-body ">
                  <Pie {...config} />
                </div>
              </Card>
            </Col> */}
            <Col xs={24} md={12} xl={8}>
              <Card
                hoverable
                title="Application by Stages"
                className="dashboard-card-custom"
              >
                <div className="dashboard-card__overflow__card-height card-body">
                  <Pie {...pieConfig} />
                </div>
              </Card>
            </Col>
            <Col xs={24} md={12} xl={8}>
              <Card
                hoverable
                title="Applications By Workflow Stages"
                className="dashboard-card-custom"
              >
                <Row>
                  <Col span={24}>
                    <div>
                      <SearchableSelect
                        className="workflow-select dashboard-workflow"
                        placeholder="Select an workflow"
                        optionFilter="search_prop"
                        onChange={handleChangeWorkflow}
                        allowClear={false}
                        defaultValue={workflows[0]?.id}
                        dropdownMatchSelectWidth={false}
                      >
                        {workflows &&
                          workflows.map((item: IWorkflow) => {
                            return (
                              <Option
                                value={item.id || ''}
                                key={item.id}
                                search_prop={`${item?.country} ${item?.service_name} ${item?.wf_name}`}
                              >
                                <div className="product-workflow-options">
                                  <b>{item?.wf_name}</b> &bull; {item?.country}{' '}
                                  &emsp;
                                  <small>{item?.service_name}</small>
                                </div>
                              </Option>
                            );
                          })}
                      </SearchableSelect>
                    </div>
                  </Col>
                  <Col span={24} className="margin-top-2">
                    <div className="padding-top-2 application-card-body ">
                      {workflowStage && workflowStage?.length > 0 ? (
                        <BarChartComponent
                          stages={workflowStage}
                          applications={applications}
                        />
                      ) : (
                        <div className="card-body">
                          <Empty image={Empty.PRESENTED_IMAGE_SIMPLE} />
                        </div>
                      )}
                    </div>
                  </Col>
                </Row>
              </Card>
            </Col>
            <Col xs={24} md={12} xl={8}>
              <Card
                hoverable
                title="Contacts By Users"
                className="dashboard-card-custom"
              >
                {clients?.length > 0 ? (
                  <div className="card-body">
                    <BarClientComponent clients={clients} contacts={contacts} />
                  </div>
                ) : (
                  <div className="card-body">
                    <Empty image={Empty.PRESENTED_IMAGE_SIMPLE} />
                  </div>
                )}
              </Card>
            </Col>
          </Row>
          {/* <Row gutter={GUTTER} className="margin-top-1"> */}
          {/* </Row> */}
        </>
      )}
    </>
  );
};
export default DashboardCharts;
