import { axiosInstance as axios } from './axios';

import { IResponse } from '@shared-components/models';
import { API_URL } from '@moxie/constants';

const getAllClients = (params = ''): Promise<IResponse> => {
  return axios.get(`${API_URL.CLIENT}/${params}`);
};
const getClientDetail = (clientId: string): Promise<IResponse> => {
  return axios.get(`admin${API_URL.LEAD}/${clientId}`);
};
const getAllUsersClient = (params = ''): Promise<IResponse> => {
  return axios.get(`admin${API_URL.LEAD}/${params}`);
};

export { getAllClients, getClientDetail, getAllUsersClient };
