/* eslint-disable react-hooks/rules-of-hooks */
import React, { ReactNode } from 'react';
import { Link } from 'react-router-dom';
import moment from 'moment';
import { Col, Dropdown, Menu, Row, Space, Typography } from 'antd';
import {
  DeleteOutlined,
  EditOutlined,
  LeftOutlined,
  MoreOutlined,
  PlusOutlined,
} from '@ant-design/icons';
import {
  IService,
  ITableProps,
  IWorkflowType,
} from '@shared-components/models';
import {
  ADD_WORKFLOW_TYPE,
  DELETE_BTN,
  EDIT_BTN,
  LABEL,
  TEXT,
  URL_WORKFLOWS,
} from '@moxie/constants';
import { Button, Table } from '@moxie/shared';
import { useQuery } from '@moxie/utils';
import { useAppSelector } from '@admin/core';

const WorkflowTypeTitleMenu = ({ data, deleteData, showDrawer }: any) => {
  return (
    <Menu>
      <Menu.Item key={1} onClick={() => showDrawer(data.id)}>
        <Space direction="horizontal">
          <EditOutlined />
          {EDIT_BTN}
        </Space>
      </Menu.Item>
      <Menu.Item key={2} danger onClick={deleteData}>
        <Space direction="horizontal">
          <DeleteOutlined />
          {DELETE_BTN}
        </Space>
      </Menu.Item>
    </Menu>
  );
};

export const columns = (props: ITableProps) => {
  const { deleteData, showDrawer } = props;
  return [
    {
      title: TEXT.SN,
      key: 'id',
      dataIndex: 'id',
      width: 50,
      render: (
        _data: string,
        _row: IWorkflowType,
        index: number
      ): ReactNode => {
        const pageNumber = useQuery().get('pageNo') || 1;
        const limit = useQuery().get('limit') || 10;
        return (pageNumber - 1) * limit + index + 1;
      },
    },
    {
      title: LABEL.NAME,
      width: 200,
      sorter: () => 0,
      render(data: IWorkflowType) {
        const handleDelete = () => {
          if (data.id != null) {
            deleteData?.(data.id);
          }
        };

        const menuActions = {
          data: data,
          deleteData: handleDelete,
          showDrawer: showDrawer,
        };
        return (
          <Row>
            <Col span={18} className="align-items-center">
              <Typography.Text className="text-primary-color">
                {data.name}
              </Typography.Text>
            </Col>
            <Col span={3}>
              <Dropdown overlay={WorkflowTypeTitleMenu(menuActions)}>
                <Button
                  type="text"
                  className="table-cell-action-btn"
                  icon={<MoreOutlined />}
                />
              </Dropdown>
            </Col>
          </Row>
        );
      },
    },

    {
      title: LABEL.SERVICE,
      width: 300,
      dataIndex: 'service',
      render(service: IService) {
        return service?.name;
      },
    },
    {
      title: TEXT.ADDED_ON,
      width: 200,
      dataIndex: 'created_at',
      render(date: string) {
        return moment(date).format('MMM DD YYYY');
      },
    },
  ];
};

const WorkflowTypeTable = ({
  getData,
  tableName,
  refreshTable,
  deleteData,
  showDrawer,
  filter,
}: ITableProps) => {
  const tableLoading = useAppSelector((state) => state.workflow_types.loading);
  const columnActions = {
    deleteData: deleteData,
    showDrawer: showDrawer,
  };
  return (
    <div className="bg-white padding-top-2">
      <Table
        filter={filter}
        action={getData}
        tableName={tableName}
        columns={columns(columnActions)}
        refresh={refreshTable}
        filterOptions={true}
        tableLoading={tableLoading}
        tableButtons={
          <Space direction="horizontal">
            <Link to={URL_WORKFLOWS}>
              <Button icon={<LeftOutlined />}>{TEXT.WORKFLOW_LIST}</Button>
            </Link>
            <Button
              type="primary"
              typeof="button"
              onClick={() => showDrawer?.()}
            >
              <PlusOutlined />
              {ADD_WORKFLOW_TYPE}
            </Button>
          </Space>
        }
      />
    </div>
  );
};

export { WorkflowTypeTable };
