import React from 'react';
import { IOverViewHeader } from '@shared-components/models';
import { Card, Skeleton } from 'antd';
const OverViewHeader: React.FC<IOverViewHeader> = (props: IOverViewHeader) => {
  const { title, actions, loading } = props;
  return (
    <header className="pageheader">
      <Card>
        <div className="pageheader__content">
          {loading ? (
            <Skeleton.Button active />
          ) : (
            <div className="pageheader__title">
              <h2>{title}</h2>
            </div>
          )}
          <div className="pageheader__actions">{actions && actions}</div>
        </div>
      </Card>
    </header>
  );
};

export default OverViewHeader;
