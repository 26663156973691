import axios from './axios';
import { IResponse } from '@shared-components/models';

const getContactReport = async (params = ''): Promise<IResponse> => {
  return axios.get(`/contact/report/${params}`);
};

const getApplicationReport = async (params = ''): Promise<IResponse> => {
  return axios.get(`/applications/report/${params}`);
};

const getContactsReportFilterData = async (): Promise<IResponse> => {
  return axios.get(`/contact/report-filter-data`);
};

const getApplicationReportFilterData = async (): Promise<IResponse> => {
  const response = await axios.get(`/applications/report-filter-data`);
  return response?.data;
};

export {
  getContactReport,
  getApplicationReport,
  getContactsReportFilterData,
  getApplicationReportFilterData,
};
