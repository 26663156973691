import React from "react";
import { UNASSIGNED_LABEL } from "@moxie/constants";
import { Avatar, Col, Space, Typography } from "antd";
import { UserOutlined } from '@ant-design/icons';

const UnassignedCard: React.FC = () => {
    return (
      <Space className="lead-table__name user-name-card">
        <Col className="align-items-center  user-name-card__avatar">
          <Avatar icon={<UserOutlined />} alt="unassigned" />
        </Col>
        <Col className="user-name-card__name ">
          <Typography.Text type="secondary">{UNASSIGNED_LABEL}</Typography.Text>
        </Col>
      </Space>
    );
};

export { UnassignedCard };