const alias = 'WORKFLOW_TYPE';

export const GET_WORKFLOW_TYPES_REQUEST = `GET_${alias}S_REQUEST`;
export const GET_WORKFLOW_TYPES_SUCCESS = `GET_${alias}S_SUCCESS`;
export const GET_WORKFLOW_TYPES_FAILURE = `GET_${alias}S_FAILURE`;

export const GET_WORKFLOW_TYPE_REQUEST = `GET_${alias}_REQUEST`;
export const GET_WORKFLOW_TYPE_SUCCESS = `GET_${alias}_SUCCESS`;
export const GET_WORKFLOW_TYPE_FAILURE = `GET_${alias}_FAILURE`;

export const GET_WORKFLOW_TYPES_BY_SERVICE_ID_REQUEST = `GET_${alias}S_BY_SERVICE_ID_REQUEST`;
export const GET_WORKFLOW_TYPES_BY_SERVICE_ID_SUCCESS = `GET_${alias}S_BY_SERVICE_ID_SUCCESS`;
export const GET_WORKFLOW_TYPES_BY_SERVICE_ID_FAILURE = `GET_${alias}S_BY_SERVICE_ID_FAILURE`;

export const ADD_WORKFLOW_TYPE_REQUEST = `ADD_${alias}_REQUEST`;
export const ADD_WORKFLOW_TYPE_SUCCESS = `ADD_${alias}_SUCCESS`;
export const ADD_WORKFLOW_TYPE_FAILURE = `ADD_${alias}_FAILURE`;

export const UPDATE_WORKFLOW_TYPE_REQUEST = `UPDATE_${alias}_REQUEST`;
export const UPDATE_WORKFLOW_TYPE_SUCCESS = `UPDATE_${alias}_SUCCESS`;
export const UPDATE_WORKFLOW_TYPE_FAILURE = `UPDATE_${alias}_FAILURE`;

export const DELETE_WORKFLOW_TYPE_REQUEST = `DELETE_${alias}_REQUEST`;
export const DELETE_WORKFLOW_TYPE_SUCCESS = `DELETE_${alias}_SUCCESS`;
export const DELETE_WORKFLOW_TYPE_FAILURE = `DELETE_${alias}_FAILURE`;

export const CLEAR_WORKFLOW_TYPE_FETCH = `CLEAR_${alias}_FETCH`;
