/* eslint-disable react/display-name */
import React, { ReactNode } from 'react';
import { Button, Table } from '@moxie/shared';
import {
  IOfficeBranch,
  IUserForm,
  IUserTable,
} from '@shared-components/models';
import { TableUserNameCard } from './table-form-name-card';
import {
  AM_PERMISSIONS,
  LABEL,
  PHONE_LABEL,
  TEXT,
  USER_ADD_TITLE,
  USER_TITLE,
} from '@moxie/constants';
import { PlusOutlined } from '@ant-design/icons';
import { useAppSelector } from '@lyra/core';
import permit from '@lyra/permit';

const UserTable: React.FC<IUserTable> = ({
  showDrawer,
  filter,
  getData,
  refresh,
  filterOptions,
  actionBar,
  hideBranch,
}: IUserTable) => {
  const columns = [
    {
      title: LABEL.NAME,
      sorter: () => 0,
      render: (_text: string, row: IUserForm, index: number): ReactNode => (
        <TableUserNameCard
          id={row.id}
          firstName={row.first_name}
          email={row.email}
          lastName={row.last_name}
          index={index.toString()}
          showDrawer={showDrawer}
          actionBar={actionBar}
        />
      ),
    },

    {
      title: TEXT.BRANCH,
      dataIndex: 'branch',
      hidden: hideBranch,
      render: (data: IOfficeBranch): ReactNode => {
        return (
          <div className="lead-table__added-from initial_capital">{`${data?.name}`}</div>
        );
      },
    },

    {
      title: TEXT.ADDRESS,
      render: (_text: string, row: IUserForm): ReactNode => (
        <div className="lead-table__added-from initial_capital">{`${
          row.address1 ? row.address1 : ''
        } ${row.city_or_state}, ${row.country}`}</div>
      ),
    },
    {
      title: PHONE_LABEL,
      render: (_text: string, row: IUserForm): ReactNode => (
        <div className="lead-table__added-from">{`${row.phone}`}</div>
      ),
    },

    {
      title: LABEL.EMAIL,
      sorter: () => 0,
      dataIndex: 'email',
      render: (email: string): ReactNode => (
        <div className="lead-table__added-from">{`${email.toLowerCase()}`}</div>
      ),
    },
  ].filter((item) => !item.hidden);

  const rolePerms: string[] = useAppSelector(
    (state: any) => state?.auth?.user?.am_role?.permissions
  );

  return (
    <div className="bg-white padding-top-2">
      <Table
        tableName={USER_TITLE}
        filterOptions={filterOptions}
        filter={filter}
        action={getData}
        columns={columns}
        size={'small'}
        refresh={refresh}
        tableButtons={
          permit([AM_PERMISSIONS.USERS.MANAGE], rolePerms) && (
            <Button type="primary" onClick={() => showDrawer && showDrawer()}>
              <PlusOutlined />
              {USER_ADD_TITLE}
            </Button>
          )
        }
      />
    </div>
  );
};

export { UserTable };
