import React, { useState } from 'react';
import { getContactReport } from '@admin/services.api';
import { PageHeaderTitle } from '@shared-components/elements';
import { ContactReportTable } from './contact-report-table';
import { LABEL, TEXT } from '@moxie/constants';
import { Excel } from 'antd-table-saveas-excel';
import { exportReportColumns } from './export-report-columns';
import { useAppSelector } from '@admin/core';

const AdminContactReportListComponent: React.FC = () => {
  const [filter, setFilter] = useState({});
  const [reportParams, setReportParams] = useState<string>('');
  const userTimeZone =
    useAppSelector((store) => store?.auth?.user?.time_zone) || '';

  const getData = async (params: string) => {
    setReportParams(params);
    return getContactReport(params);
  };

  const handleExport = async () => {
    const response = await getContactReport(
      `${reportParams}&exportReport=true`
    );

    const excel = new Excel();
    excel
      .addSheet('data')
      .addColumns(exportReportColumns(userTimeZone))
      .addDataSource(response?.data?.data)
      .saveAs('contact_report.xlsx');
  };

  const setFilterData = (key: string, value: string) => {
    setFilter({ [key]: value });
  };

  return (
    <div className="full-height">
      <PageHeaderTitle title={TEXT.CLIENT_REPORT} />
      <ContactReportTable
        getData={getData}
        tableName={LABEL.CONTACT}
        filter={filter}
        setFilter={setFilterData}
        handleExport={handleExport}
      />
    </div>
  );
};

export { AdminContactReportListComponent };
