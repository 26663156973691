import React from 'react';
import { Checkbox, Form } from 'antd';
import { ILeadsFormComponent } from '@shared-components/models';
import { TextArea } from '@shared-components/elements';
import { LABEL, PLACEHOLDER } from '@moxie/constants';

const Compliance: React.FC<ILeadsFormComponent> = ({
  setLeadForm,
  leadForm,
  setDisabled,
}: ILeadsFormComponent) => {
  const handleChange = (e: any) => {
    setDisabled(false);
    if (e.target.type === 'checkbox') {
      return setLeadForm({
        ...leadForm,
        compliance_section: {
          ...leadForm.compliance_section,
          [e.target.name]: e.target.checked,
        },
      });
    }
    setLeadForm({
      ...leadForm,
      compliance_section: {
        ...leadForm.compliance_section,
        [e.target.name]: e.target.value,
      },
    });
  };

  return (
    <>
      <Form.Item>
        <Checkbox
          name="show_privacy_information"
          checked={leadForm?.compliance_section?.show_privacy_information}
          onChange={handleChange}
        >
          {LABEL.SHOW_INFO_IN_LEAD_FORM}
        </Checkbox>
      </Form.Item>
      <Form.Item>
        <div className="margin-bottom-1">{LABEL.PRIVACY_INFO}</div>
        <TextArea
          rows={5}
          name="privacy_information"
          placeholder={PLACEHOLDER.PRIVACY_INFO}
          value={leadForm?.compliance_section?.privacy_information}
          onChange={handleChange}
        />
      </Form.Item>
      <Form.Item>
        <div className="margin-bottom-1">{LABEL.CONSENT}</div>
        <TextArea
          rows={4}
          placeholder={PLACEHOLDER.CONSENT_INFO}
          name="consent_information"
          value={leadForm?.compliance_section?.consent_information}
          onChange={handleChange}
        />
      </Form.Item>
    </>
  );
};

export default Compliance;
