/* eslint-disable no-unused-vars */
import React, { Key, useEffect, useState } from 'react';
import { Col, Form, Row, Select, Space } from 'antd';
import { CheckOutlined, CloseOutlined, EditOutlined } from '@ant-design/icons';
import {
  IChecklistData,
  IDocNameCardComponent,
  IDocument,
  IDocumentTypeCard,
} from '@shared-components/models';
import { useForm } from 'antd/lib/form/Form';
import { Button, capitalizeFirstLetter, SearchableSelect } from '@moxie/shared';
import { PLACEHOLDER, TITLE } from '@moxie/constants';
import { updateDocument } from '@lyra/services.api';
import Can, { PermissionAction, PermissionObjects } from '@lyra/privilege';
import { useAppSelector } from '@lyra/core';
import { useDispatch } from 'react-redux';
import _ from 'lodash';

const DocumentTypeEditCard = ({
  form,
  handleSubmit,
  handleCancel,
}: IDocumentTypeCard) => {
  const { Option, OptGroup } = Select;
  const dispatch = useDispatch();
  const allChecklists = useAppSelector(
    (state) => state.checklist.documentChecklist
  );
  const [allChecklistArray, setAllChecklistArray] = useState<
    IChecklistData[]
  >();
  const groupedData: any = [];

  useEffect(() => {
    const groupData = _.groupBy(allChecklists, 'type');
    groupedData.push(groupData);
    setAllChecklistArray(groupedData[0]);

    return () => {
      form.resetFields();
    };
  }, [dispatch]);

  return (
    <Form layout="inline" form={form} onFinish={handleSubmit}>
      <Space>
        <Form.Item name="document_type">
          <SearchableSelect
            className="custom__select_width margin-right-n2"
            placeholder={PLACEHOLDER.DOCUMENT_TYPE}
          >
            {allChecklistArray &&
              Object.entries(allChecklistArray).map(
                (item: any, index: number) => (
                  <OptGroup label={item[0]} key={index}>
                    {item[1]?.map(
                      (pos: { id: Key; name: string; type: string }) => {
                        return (
                          <Option
                            value={pos?.type + '<' + pos?.name}
                            key={pos?.name}
                            className="document-option-type"
                          >
                            {pos.name}
                          </Option>
                        );
                      }
                    )}
                  </OptGroup>
                )
              )}
          </SearchableSelect>
        </Form.Item>
        <Button
          size="small"
          type="text"
          icon={<CheckOutlined />}
          htmlType="submit"
          className="margin-right-n2"
        />
        <Button
          size="small"
          type="text"
          icon={<CloseOutlined />}
          onClick={handleCancel}
          className="margin-left-n2"
        />
      </Space>
    </Form>
  );
};

const DocumentTypeNameCard = ({
  data,
  triggerRefresh,
  archived,
  leadData,
}: IDocNameCardComponent<IDocument>) => {
  const [isEdit, setIsEdit] = useState(false);
  const [form] = useForm();
  const { socket, userId } = useAppSelector((state) => ({
    socket: state.socket.wss,
    userId: state.auth.user?.id,
  }));

  useEffect(() => {
    form.setFieldsValue(data);
  }, [isEdit]);

  const handleCancel = () => {
    setIsEdit(false);
  };

  const handleSubmit = async (formData: any) => {
    await updateDocument(data.id, {
      document_type: formData.document_type,
    });
    socket?.emit('document-notification', {
      user_id: userId,
      parent_id: leadData.id,
      receiver_id: leadData.assignee_id,
      receivers_many: leadData?.followers.map((item: any) => item.id),
      type: 'document-updated',
    });
    setIsEdit(false);
    triggerRefresh?.();
  };

  const handleEdit = () => {
    setIsEdit(true);
  };

  return (
    <Row justify="space-between" wrap={false}>
      <Col>
        {isEdit ? (
          <DocumentTypeEditCard
            form={form}
            handleCancel={handleCancel}
            handleSubmit={handleSubmit}
          />
        ) : (
          capitalizeFirstLetter(data.document_type || '')
        )}
      </Col>
      <Col>
        {/* {Can(PermissionAction.Update, PermissionObjects.LeadDocumentEntity) && ( */}
        <>
          {!isEdit && !archived ? (
            <EditOutlined
              className="action__button__icon margin-left-1"
              onClick={handleEdit}
              title={TITLE.EDIT}
            />
          ) : null}
        </>
        {/* )} */}
      </Col>
    </Row>
  );
};

export { DocumentTypeNameCard };
