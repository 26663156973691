import React, { useContext, useEffect, useState } from 'react';
import {
  IApplicationDateRange,
  IApplicationStageTimeline,
} from '@shared-components/models';
import {
  Card,
  Typography,
  Col,
  DatePicker,
  Form,
  Row,
  Space,
  Steps,
  Tooltip,
} from 'antd';
import { CheckOutlined, CloseOutlined, EditOutlined } from '@ant-design/icons';
import {
  APPLICATION_STATUS,
  INTAKE_MONTHS,
  PLACEHOLDER,
  TEXT,
} from '@moxie/constants';
import {
  FormItem,
  SearchableSelect,
  Option,
  capitalizeFirstLetter,
  Button,
} from '@moxie/shared';
import { applicationActions, useAppSelector } from '@lyra/core';
import { useDispatch } from 'react-redux';
import { useForm } from 'antd/lib/form/Form';
import { RangeValue } from 'rc-picker/lib/interface';
import moment, { Moment } from 'moment';
import { ApplicationContext } from '../applicationContext';

const { Text } = Typography;

const ApplicationStageTimeline: React.FC<IApplicationStageTimeline> = ({
  applicationDetail,
  stages,
  currentStage,
}: IApplicationStageTimeline) => {
  const { id, start_date, end_date, intake_year, intake } = applicationDetail;

  const { applicationState, clickStage } = useContext(ApplicationContext);
  const { selectedStage } = applicationState;

  const { Step } = Steps;
  const { RangePicker } = DatePicker;
  const dispatch = useDispatch();
  const [form] = useForm();

  const ellipsis = useState(true);

  const [isEdit, setIsEdit] = useState(false);
  const [intakeYears, setIntakeYears] = useState<number[]>();
  const user = useAppSelector((state) => state.auth.user);
  const socket = useAppSelector((state) => state.socket.wss);
  const lead = useAppSelector((state) => state.lead.lead);
  const getIntakeYears = () => {
    const intake = [];
    for (let i = 0; i < 3; i++) {
      intake.push(new Date().getFullYear() + i);
    }
    setIntakeYears(intake);
  };

  const handleFinish = (data: any) => {
    const update_type = 'intake';
    dispatch(
      applicationActions.updateRequest(
        { ...data, id },
        user,
        socket,
        lead,
        update_type
      )
    );
    form.resetFields();
    setIsEdit(!isEdit);
  };

  const handleEdit = () => {
    setIsEdit(!isEdit);
  };

  const handleCancel = () => {
    setIsEdit(!isEdit);
    form.resetFields();
  };

  const handleRangeChange = (data: RangeValue<Moment>, values: string[]) => {
    const update_type = 'enrollment-period';
    const postData: IApplicationDateRange = {
      id,
      start_date: values[0] && moment(values[0]).format('YYYY-MM-DD'),
      end_date: values[1] && moment(values[1]).format('YYYY-MM-DD'),
    };
    if (postData.start_date === '' || postData.end_date === '') {
      postData.start_date = null;
      postData.end_date = null;
    }
    dispatch(
      applicationActions.updateRequest(
        postData,
        user,
        socket,
        lead,
        update_type
      )
    );
  };

  const getClassName = (stage: any) => {
    let className = 'cursor-pointer';

    if (selectedStage && stage.level === selectedStage.level) {
      className += ' selected-stage';
    }

    if (applicationDetail.status !== APPLICATION_STATUS.COMPLETED) {
      if (currentStage.level === stage.level) {
        if (className.includes('selected-stage')) {
          className = className.replace('selected-stage', 'current-stage');
        } else {
          className += ' current-stage';
        }
      }
    }
    return className;
  };

  useEffect(() => {
    getIntakeYears();
  }, []);

  return (
    <>
      <div className="margin-left-2 padding-bottom-1">
        <div>
          <h4 className="profile__title margin-0">{TEXT.APPLIED_INTAKE}:</h4>
          {isEdit ? (
            <Form layout="inline" form={form} onFinish={handleFinish}>
              <Row>
                <Col xxl={10} xl={16} span={24}>
                  <FormItem name="intake">
                    <SearchableSelect
                      size="small"
                      defaultValue={intake}
                      className="initial_capital intake-month-select"
                      optionFilter="key"
                      allowClear={false}
                      placeholder={PLACEHOLDER.INTAKE_MONTH}
                    >
                      {INTAKE_MONTHS.map((item: any) => {
                        return (
                          <Option
                            value={item.intake}
                            key={item.intake}
                            className="initial_capital"
                          >
                            {item.intake}
                          </Option>
                        );
                      })}
                    </SearchableSelect>
                  </FormItem>
                </Col>
                <Col xxl={9} xl={24} span={24}>
                  <FormItem name="intake_year">
                    <SearchableSelect
                      size="small"
                      defaultValue={intake_year}
                      optionFilter="key"
                      className="intake-year-select"
                      allowClear={false}
                      placeholder={PLACEHOLDER.INTAKE_YEAR}
                    >
                      {intakeYears &&
                        intakeYears.map((item: number) => {
                          return (
                            <Option value={item} key={item}>
                              {item}
                            </Option>
                          );
                        })}
                    </SearchableSelect>
                  </FormItem>
                </Col>

                <Col xxl={5} xl={8}>
                  <Space>
                    <Form.Item>
                      <CheckOutlined onClick={() => form.submit()} />
                    </Form.Item>
                    <Form.Item>
                      <CloseOutlined onClick={handleCancel} />
                    </Form.Item>
                  </Space>
                </Col>
              </Row>
            </Form>
          ) : (
            <Space>
              <div>
                {intake ? (
                  <div className="profile-button">
                    <Button className="intake-button " disabled size="small">
                      <h4 className="application-intake-title ">{`${capitalizeFirstLetter(
                        intake
                      )} ${intake_year}`}</h4>
                    </Button>
                  </div>
                ) : null}
              </div>
              <div
                className="cursor-pointer padding-top-1"
                onClick={handleEdit}
              >
                <EditOutlined />
              </div>
            </Space>
          )}
        </div>
        <div
          className={!isEdit ? 'application-intake' : 'application-intake-edit'}
        >
          <h4 className="profile__title">{TEXT.ENROLLMENT_PERIOD}:</h4>
          {start_date !== null || end_date !== null ? (
            <RangePicker
              className="enrollment-period-range-picker"
              format="MMM DD, YYYY"
              defaultValue={[
                moment(start_date, 'YYYY-MM-DD'),
                moment(end_date, 'YYYY-MM-DD'),
              ]}
              onChange={handleRangeChange}
            />
          ) : (
            <RangePicker
              className="margin-right-1"
              onChange={handleRangeChange}
            />
          )}
        </div>
      </div>
      <Card bordered={false} className="stage-timeline">
        {stages ? (
          <Steps
            current={
              applicationDetail?.status === APPLICATION_STATUS.COMPLETED
                ? stages.length
                : currentStage.level
            }
            percent={100}
            direction="vertical"
            size="small"
          >
            <>
              {stages &&
                stages.map((stage) => {
                  return (
                    <Step
                      key={stage.stage}
                      title={
                        <Tooltip
                          title={ellipsis ? `${stage?.stage}` : '-'}
                          placement="right"
                        >
                          <Text
                            className="ellipsis-text stage-text"
                            ellipsis={true}
                          >
                            <span className="stage-timeline-item">
                              {stage?.stage}
                            </span>
                          </Text>
                        </Tooltip>
                      }
                      className={getClassName(stage)}
                      onClick={() => {
                        if (
                          stage.level !== undefined &&
                          currentStage.level !== undefined
                        ) {
                          clickStage(stage);
                        }
                      }}
                    />
                  );
                })}
              <Step
                key="complete"
                title={TEXT.COMPLETE}
                className={
                  applicationDetail.status === APPLICATION_STATUS.COMPLETED
                    ? 'cursor-pointer current-stage'
                    : ''
                }
                onClick={() => {
                  if (applicationDetail.status === APPLICATION_STATUS.COMPLETED)
                    clickStage();
                }}
              />
            </>
          </Steps>
        ) : null}
      </Card>
    </>
  );
};

export { ApplicationStageTimeline };
