import React, { useContext, useEffect, useState } from 'react';
import { CLEAR_BTN, COMPARE_BTN, TEXT } from '@moxie/constants';
import { ExpandAltOutlined, MinusOutlined } from '@ant-design/icons';
import { ProductComparisonListItem } from './product-comparison-list-item';
import { ProductComparisonListContext } from './product-comparison-list-provider';
import { Button } from '@shared-components/elements';
import { Empty, Row } from 'antd';
import { useHistory, useParams } from 'react-router-dom';
import { IProductComparisonModal } from '@shared-components/models';

const ProductComparisonList = () => {
  const [expand, setExpand] = useState(false);
  const history = useHistory();
  const { id }: { id: string } = useParams();
  const { compareList, handleUpdateList } = useContext(
    ProductComparisonListContext
  );
  useEffect(() => {
    handleClear();
  }, [id]);

  const handleDelete = (id: string) => {
    let data = JSON.parse(localStorage.getItem('compare') || '');
    data = data.filter((item: IProductComparisonModal) => item.id !== id);
    localStorage.setItem('compare', JSON.stringify(data));
    handleUpdateList(data);
  };

  const handleClear = () => {
    localStorage.setItem('compare', JSON.stringify([]));
    handleUpdateList([]);
  };

  const handleCompare = () => {
    history.push(
      `/lead/product-compare/${compareList.reduce(
        (prevValue, currentVal, index) =>
          prevValue + `${index === 0 ? '' : ','}${currentVal.id}`,
        `${id}?pid=`
      )}`
    );
  };
  return (
    <div className="product-comparison-list">
      <div
        className="product-comparison-list__head"
        onClick={() => setExpand(!expand)}
      >
        <span className="title">
          {`${
            compareList.length > 0
              ? `${compareList.length} products are`
              : 'No product is'
          }${TEXT.COMPARISON_LIST}`}
        </span>
        <Button
          data-testid="expand-btn"
          icon={expand ? <MinusOutlined /> : <ExpandAltOutlined />}
          type="link"
          size="small"
          className="expand-close-btn"
        />
      </div>
      {expand && (
        <div className="product-comparison-list__body">
          {compareList.length !== 0 ? (
            compareList.map((item) => (
              <ProductComparisonListItem
                key={item.id}
                id={item?.id || ''}
                name={item?.name}
                onDelete={handleDelete}
                location={item?.institution?.name}
              />
            ))
          ) : (
            <Empty
              image={Empty.PRESENTED_IMAGE_SIMPLE}
              description={
                <span className="warning-msg">{TEXT.NO_PRODUCTS_ADDED}</span>
              }
            />
          )}
          {compareList.length < 2 && (
            <div className="warning-msg">
              <small>{TEXT.MINIMUM_PRODUCT_FOR_COMPARISON}</small>
            </div>
          )}
          <Row justify="end">
            {compareList.length !== 0 && (
              <Button
                data-testid="clear_btn"
                className="margin-right-1"
                onClick={handleClear}
              >
                {CLEAR_BTN}
              </Button>
            )}
            {compareList.length >= 2 && (
              <Button
                data-testid="compare_btn"
                type="primary"
                onClick={handleCompare}
              >
                {COMPARE_BTN}
              </Button>
            )}
          </Row>
        </div>
      )}
    </div>
  );
};

export { ProductComparisonList };
export * from './product-comparison-list-provider';
