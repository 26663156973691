import React from 'react';
import {
  GUTTER,
  LABEL,
  PLACEHOLDER,
  TITLE,
  NO_FILES_SELECTED,
  FILE_SIZE_EXCEED,
} from '@moxie/constants';
import { Col, Divider, List, Row, Upload } from 'antd';
import TextArea from 'antd/lib/input/TextArea';
import Title from 'antd/lib/typography/Title';
import { InboxOutlined } from '@ant-design/icons';
import {
  IDocumentItem,
  ILeadOtherDetailsProps,
} from '@shared-components/models';
import { FormItem } from '@shared-components/elements';
import { errorNotificationHandler, FileNameComponent } from '@moxie/shared';

const LeadFormOtherDetails: React.FC<ILeadOtherDetailsProps> = ({
  otherDetailsData,
  documents,
  setDocuments,
}: ILeadOtherDetailsProps) => {
  const { Dragger } = Upload;

  const handleRemove = async (file: IDocumentItem | undefined) => {
    const filterDocument = documents?.filter(({ uid }: IDocumentItem) => {
      return uid !== file?.uid;
    });
    setDocuments(filterDocument);
  };

  const handleDocument = async (files: any) => {
    if (files.file.size / 1024 / 1024 < 10) {
      setDocuments((data: []) => [...data, files.file]);
    } else {
      errorNotificationHandler(FILE_SIZE_EXCEED);
    }
  };

  const SETTING = {
    name: 'files',
    multiple: true,
    accept: '.jpg, .jpeg, .png',
    showUploadList: false,
    customRequest: handleDocument,
  };

  return (
    <div className="created-leadForm-section ">
      <Title level={4}>{TITLE.OTHER_DETAILS}</Title>
      <Row gutter={GUTTER}>
        {otherDetailsData?.upload_documents && (
          <Col md={24} sm={24} xs={24}>
            <FormItem label={LABEL.UPLOAD_DOCUMENTS}>
              <div>
                <Dragger
                  className="dragger_height"
                  {...SETTING}
                  listType="picture"
                >
                  <p className="ant-upload-drag-icon">
                    <InboxOutlined />
                  </p>
                  <p className="ant-upload-text">{TITLE.DRAG_TO_UPLOAD}</p>
                  <div>
                    <p className="font-size-20-px">
                      Selected Documents&nbsp;({documents?.length})
                    </p>
                    <p className="dragger_font">
                      {documents?.length === 0 ? NO_FILES_SELECTED : null}
                    </p>
                  </div>
                </Dragger>

                <div className="padding-top-1">
                  {documents.length !== 0 ? (
                    <>
                      <List
                        bordered={true}
                        dataSource={documents}
                        renderItem={(item: IDocumentItem) => {
                          return (
                            <FileNameComponent
                              item={item}
                              documents={documents}
                              onSetDocument={setDocuments}
                              onRemove={handleRemove}
                              showType={false}
                            />
                          );
                        }}
                      />
                    </>
                  ) : null}
                </div>
              </div>
            </FormItem>
          </Col>
        )}
      </Row>
      <Row>
        {otherDetailsData?.comments && (
          <Col md={24} sm={24} xs={24}>
            <FormItem name="comments" label={LABEL.COMMENTS}>
              <TextArea
                placeholder={PLACEHOLDER.COMMENTS}
                autoSize={{ minRows: 3, maxRows: 3 }}
              />
            </FormItem>
          </Col>
        )}
      </Row>
      <Divider plain />
    </div>
  );
};
export { LeadFormOtherDetails };
