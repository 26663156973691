import { IAction, IContactCrm } from '@shared-components/models';
import * as actionTypes from './lead.constant';

export interface ILeadState {
  leads: IContactCrm[];
  lead: IContactCrm | Record<string, unknown>;
  error: boolean | null;
  loading: boolean;
  redirect: boolean;
  downloading?: boolean;
}

const initialLeadState: ILeadState = {
  leads: [],
  lead: {},
  error: null,
  loading: false,
  redirect: false,
  downloading: false,
};

export const LEADS_FEATURE_KEY = 'leads';

export const leadReducer = (
  state = initialLeadState,
  action: IAction<any>
): any => {
  switch (action.type) {
    case actionTypes.ADD_LEAD_REQUEST:
      return {
        ...state,
        loading: true,
        error: null,
      };
    case actionTypes.ADD_LEAD_SUCCESS:
      return {
        ...state,
        loading: false,
        error: null,
        lead: action.payload,
      };
    case actionTypes.ADD_LEAD_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.payload,
      };

    case actionTypes.DELETE_LEAD_REQUEST:
      return {
        ...state,
        loading: true,
        error: null,
      };
    case actionTypes.DELETE_LEAD_SUCCESS:
      return {
        ...state,
        loading: false,
        error: null,
      };
    case actionTypes.DELETE_LEAD_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.payload,
      };
    case actionTypes.UPDATE_LEAD_REQUEST:
      return {
        ...state,
        loading: true,
        error: null,
      };
    case actionTypes.UPDATE_LEAD_SUCCESS:
      return {
        ...state,
        loading: false,
        error: null,
      };
    case actionTypes.UPDATE_LEAD_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.payload,
      };
    case actionTypes.CLEAR_LEAD:
      return {
        ...state,
        loading: false,
        error: null,
        lead: null,
      };
    case actionTypes.ARCHIVE_LEAD_REQUEST:
      return {
        ...state,
        loading: true,
        error: null,
      };
    case actionTypes.ARCHIVE_LEAD_SUCCESS:
      return {
        ...state,
        loading: false,
        error: null,
      };
    case actionTypes.ARCHIVE_LEAD_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.payload,
      };
      case actionTypes.UNARCHIVE_LEAD_REQUEST:
        return {
          ...state,
          loading: true,
          error: null,
        };
      case actionTypes.UNARCHIVE_LEAD_SUCCESS:
        return {
          ...state,
          loading: false,
          error: null,
        };
      case actionTypes.UNARCHIVE_LEAD_FAILURE:
        return {
          ...state,
          loading: false,
          error: action.payload,
        };
    case actionTypes.DOWNLOADING:
      return {
        ...state,
        downloading: action.payload,
        redirect: false,
      }
    default:
      return state;
  }
};
