import React, { useState } from 'react';
import { TEXT } from '@moxie/constants';
import { Col, Row, Tooltip, Typography } from 'antd';
import { LinkOutlined } from '@ant-design/icons';
import { ILeadProfileContact } from '@shared-components/models';

const LeadProfileContact: React.FC<ILeadProfileContact> = ({
  email,
  phone,
}: ILeadProfileContact) => {
  const [isCopied, setIsCopied] = useState(false);

  const copyToClipboard = (text: string) => {
    navigator.clipboard.writeText(text);
    setIsCopied(true);
  };

  const { Paragraph } = Typography;
  const [ellipsis] = useState(true);

  return (
    <Row className="email_div">
      <Col span={24}>
        {email && (
          <Row className="email_div" style={{ padding: 0 }}>
            <Col>
              <h4 className="profile__title">{TEXT.EMAIL}:</h4>
            </Col>
            <Col span={20} lg={18}>
              <h4 className="profile__title initial_small">
                <a href={`mailto:${email}`}>
                  <Paragraph
                    className="text-link-color margin-left-1"
                    ellipsis={ellipsis ? { tooltip: `${email}` } : undefined}
                  >
                    {email.toLowerCase()}
                  </Paragraph>
                </a>
              </h4>
            </Col>
            <Col className="hide">
              <Tooltip
                className=" margin-left-1 "
                placement="right"
                color={isCopied ? '#8EAF74' : '#9B9D9A '}
                title={
                  isCopied ? `${TEXT.COPIED_SUCCESS}` : `${TEXT.COPY_EMAIL}`
                }
              >
                <LinkOutlined
                  className="copy_email_icon"
                  onClick={() => copyToClipboard(`${email}`)}
                />
              </Tooltip>
            </Col>
          </Row>
        )}
        {phone && (
          <div className="profile-title-phone-container">
            <h4 className="profile__title">
              {TEXT.PHONE}:{' '}
              <Tooltip title={phone}>
                <span className="text-link-color ">{phone}</span>
              </Tooltip>
            </h4>
          </div>
        )}
      </Col>
    </Row>
  );
};

export { LeadProfileContact };
