import { URL_AUTH_CHECK_ACCOUNT, URL_REGISTER } from '@moxie/constants';
import { IRegistration, IResponse } from '@shared-components/models';
import axios from './axios';

const postRegistration = (body: IRegistration): Promise<IResponse> => {
  return axios.post(URL_REGISTER, body);
};

const postAmRegistration = (body: IRegistration): Promise<IResponse> => {
  return axios.post(`am${URL_REGISTER}`, body);
};

const checkAccount = (body: string): Promise<any> => {
  return axios.get(`${URL_AUTH_CHECK_ACCOUNT}/${body}`);
};

export { postRegistration, checkAccount, postAmRegistration };
