import { API_URL } from '@moxie/constants';
import crmAxios from './crm-api-axios';
import { IContactCrm } from '@model/crm/contact.model';

const postLead = async (data: IContactCrm): Promise<IContactCrm> => {
  return crmAxios.post(API_URL.CRM_API_CONTACT, data);
};

const updateLead = async (data: Partial<IContactCrm>): Promise<IContactCrm> => {
  const id = data.id;
  delete data.id;
  return crmAxios.put(`${API_URL.CRM_API_CONTACT}/${id}`, data);
};

const deleteLead = async (id: string, data: { isDeleted: boolean; deletedBy: string; contactId: string }): Promise<any> => {
  return crmAxios.put(`${API_URL.CRM_API_CONTACT}/${id}`, data);
};

const archiveLead = async (
  id: string | undefined,
  data: any
): Promise<IContactCrm> => {
  return crmAxios.put(`${API_URL.CRM_API_CONTACT}/${id}`, data);
};

export { postLead, deleteLead, updateLead, archiveLead };
