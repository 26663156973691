import { IChecklistData, IAction, IPayload } from '@shared-components/models';
import * as documentTypes from './lead-document.constant';

export const getDocumentChecklistRequest = (): IAction => ({
  type: documentTypes.FETCH_DOCUMENT_CHECKLIST_REQUEST,
});

export const getDocumentChecklistSuccess = (
  payload: IChecklistData[]
): IAction<IChecklistData[]> => ({
  type: documentTypes.FETCH_DOCUMENT_CHECKLIST_SUCCESS,
  payload,
});

export const getDocumentChecklistFailure = (
  payload: IPayload<IChecklistData>
): IAction => ({
  type: documentTypes.FETCH_DOCUMENT_CHECKLIST_FAILURE,
  payload,
});
