import React, { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { TEXT } from '@moxie/constants';
import { IUser } from '@shared-components/models';
import { AssigneeCard, errorNotificationHandler } from '@moxie/shared';
import { leadActions, store, useAppSelector } from '@lyra/core';
import Can, { PermissionAction, PermissionObjects } from '@lyra/privilege';

const LeadTableAssigneeCard = ({ data }: any) => {
  const dispatch = useDispatch();
  const [assignee, setAssignee] = useState(true);
  const socket = useAppSelector((state) => state.socket.wss);
  const authUser: IUser | null = store.getState().auth.user;
  const [hasRole, setHasRole] = useState<boolean>(true);

  useEffect(() => {
    if (!Can(PermissionAction.Update, PermissionObjects.ContactsEntity)) {
      setHasRole(false);
    }
  }, []);

  const handleSubmit = (values: string) => {
    if (values) {
      const assignedData = {
        id: data.id,
        first_name: data.first_name,
        last_name: data.last_name,
        phone: data.phone,
        country_code: data.country_code,
        email: data.email,
        assignee_user_id: values,
        avatar: data.avatar,
      };
      data.id &&
        dispatch(leadActions.editLeadAction(assignedData, socket, authUser));
      setAssignee(true);
    } else {
      errorNotificationHandler(TEXT.SELECT_ASSIGNEE, 2);
    }
  };

  return (
    <AssigneeCard
      data={data}
      handleSubmit={handleSubmit}
      assignee={assignee}
      setAssignee={setAssignee}
      hasRole={hasRole}
    />
  );
};

export { LeadTableAssigneeCard };
