import { AssigneeCard, errorNotificationHandler } from '@moxie/shared';
import { TEXT } from '@moxie/constants';
import React, { useState } from 'react';
import { useDispatch } from 'react-redux';
import { tasksActions } from '@lyra/core';

const TaskAssigneeCard = ({ data, triggerRefresh }: any) => {
  const dispatch = useDispatch();
  const [assignee, setAssignee] = useState(true);

  const handleSubmit = (values: any) => {
    if (values) {
      data.id &&
        dispatch(
          tasksActions.updateTaskAssigneeRequest(
            {
              id: data.id,
              assignee_id: values,
            },
            triggerRefresh
          )
        );

      setAssignee(true);
      triggerRefresh();
    } else {
      errorNotificationHandler(TEXT.SELECT_ASSIGNEE, 2);
    }
  };

  return (
    <AssigneeCard
      data={data}
      handleSubmit={handleSubmit}
      assignee={assignee}
      setAssignee={setAssignee}
    />
  );
};

export { TaskAssigneeCard };
