import React from 'react';
import { Button, Space } from 'antd';
import {
  ADD_OFFICE_VISIT,
  CANCEL_BTN,
  DRAWER_WIDTH,
  SAVE_BTN,
  UPDATE_BTN,
  USER_ADD_PLACEMENT,
} from '@moxie/constants';
import { DrawerElem } from '@shared-components/elements';
import { IOfficeVisitDrawerComponent } from '@model/crm/office-visit';

import { OfficeVisitForm } from '../../forms/office-visit';

const OfficeVisitDrawer: React.FC<IOfficeVisitDrawerComponent> = ({
  visible,
  onClose,
  disabled,
  setDisabled,
  form,
  initialData,
  submitForm,
}: IOfficeVisitDrawerComponent) => {
  const handleFieldsChange = (allFields: any[]) => {
    for (const fields of allFields) {
      fields.errors.length <= 0 ? setDisabled(false) : setDisabled(true);
    }
  };

  const handleSubmit = () => {
    form.submit();
  };

  return (
    <DrawerElem
      visible={visible}
      onClose={onClose}
      title={ADD_OFFICE_VISIT}
      width={DRAWER_WIDTH}
      placement={USER_ADD_PLACEMENT}
      data-testid="crm-addofficevisitdrawer"
      footer={
        <div className="padding-bottom-2 text-align-right">
          <Space direction="horizontal">
            <Button onClick={onClose} className="lead-margin-right">
              {CANCEL_BTN}
            </Button>
            <Button
              type="primary"
              onClick={handleSubmit}
              disabled={disabled}
              htmlType="submit"
            >
              {!initialData?.id ? `${SAVE_BTN}` : `${UPDATE_BTN}`}
            </Button>
          </Space>
        </div>
      }
    >
      <OfficeVisitForm
        form={form}
        handleFieldsChange={handleFieldsChange}
        initialData={initialData}
        submitForm={submitForm}
      />
    </DrawerElem>
  );
};

export { OfficeVisitDrawer };
