import axios from './axios';
import { IResponse } from '@shared-components/models';
import { API_ENDPOINT_URL, API_URL } from '@moxie/constants';
const updateDocument = async (
  id: string,
  body: { name?: string; document_type?: string }
): Promise<IResponse> => {
  return axios.put(`${API_URL.DOCUMENTS}/${id}`, body);
};

const hardDeleteDocument = async (id: string): Promise<IResponse> => {
  return axios.delete(`${API_URL.HARD_DELETE_DOCUMENT}/${id}`);
};
const getLeadDocuments = async (
  contactId: string,
  docType: string
): Promise<IResponse> => {
  return axios.get(`${API_URL.DOCUMENTS}/all/${contactId}/${docType}`);
};

const getDownloadDocumentsByCategory = async (
  payload: any
): Promise<IResponse> => {
  const response = await axios.get(
    `${API_ENDPOINT_URL.WORKFLOW_APPLICATION_STAGES}/document/${payload.checklistId}/${payload.wfAppStageId}/${payload.applicationId}/download`
  );
  return response.data;
};

const getDownloadAllByApplicationStageId = async (
  payload: any
): Promise<IResponse> => {
  const response = await axios.get(
    `${API_ENDPOINT_URL.WORKFLOW_APPLICATION_STAGES}/document/${payload.wfAppStageId}/${payload.applicationId}/download`
  );
  return response.data;
};

export {
  updateDocument,
  getLeadDocuments,
  hardDeleteDocument,
  getDownloadDocumentsByCategory,
  getDownloadAllByApplicationStageId,
};
