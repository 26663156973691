/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from 'react';
import { Table as AntTable, Input, Row, Space, Col, Pagination } from 'antd';
import { useHistory } from 'react-router-dom';
import { useLocation } from 'react-router';
import { ObjectToParams } from '@moxie/utils';
import { env, SEARCH } from '@moxie/constants';
import { ITable } from '@shared-components/models';
import { TableRowSelection } from 'antd/lib/table/interface';
import { ToolBar } from './libs/toolbar';
import { usePagination } from './libs/use-pagination';
const Search = Input.Search;
const PAGINATION_CONFIG = {
  showSizeChanger: false,
  pageSizeOptions: ['10', '25', '50', '100'],
};

const Table: React.FC<ITable> = ({
  columns,
  action,
  tableName,
  filter,
  children,
  className,
  onChangeSelected,
  rowSelect,
  refresh,
  filterOptions,
  expandable,
  filterContent,
  tableButtons,
  hasPagination = true,
  tableLoading,
  size,
  scroll,
  customRowSelection,
  additionalFilters,
  ...props
}: ITable) => {
  const history = useHistory();
  const location = useLocation();
  const query = new URLSearchParams(location.search) as any;

  const [tableFilter, setFilter] = useState({});
  const [currentSelected, setCurrentSelected] = useState<Array<string>>([]);
  const [currentSelectedByPage, setCurrentSelectedByPage] = useState<any>({});
  const [pageNo, setPageNo] = useState(query.get('pageNo') || '1');
  const [searchValue, setSearch] = useState(query.get('s') || '');
  const [sort, setSort] = useState(query.get('sort') || '');
  const [sortBy, setSortBy] = useState(query.get('sortBy') || '');
  const [limit, setPageLimit] = useState(
    query.get('limit') || env.DATA_PER_PAGE
  );

  const [data, loading, totalNoOfData, totalDataCount, allId] = usePagination({
    page: pageNo,
    action: action,
    filter: tableFilter,
    limit: limit,
    sortBy: sortBy,
    sort: sort,
    refresh: refresh,
  });

  const rowSelection: TableRowSelection<any> = {
    type: 'checkbox',
    selectedRowKeys: currentSelected,
    onChange: (selectedRowKeys: React.Key[]) => {
      setCurrentSelectedByPage(() => {
        return {
          ...currentSelectedByPage,
          [pageNo]: selectedRowKeys,
        };
      });
    },
    getCheckboxProps: (record: any) => ({
      disabled: record.name === 'Disabled User',
      name: record.name,
    }),
  };

  const getAllCurrentSelected = () => {
    let allSelectedData: Array<string> = [];
    if (currentSelectedByPage)
      (Object.keys(currentSelectedByPage) || []).forEach((key: string) => {
        allSelectedData = allSelectedData.concat(currentSelectedByPage[key]);
      });
    return allSelectedData;
  };

  const handleChange = (page: number, pageSize: number | any) => {
    const params = {
      ...tableFilter,
      pageNo: page || 1,
      limit: pageSize || env.DATA_PER_PAGE,
      sortBy: sortBy,
      sort: sort,
    };
    history.push(`?${ObjectToParams(params)}`);
  };

  const handleTableChange = (_pagination: any, _filters: any, sorter: any) => {
    const params = {
      pageNo: Number(pageNo),
      limit: Number(limit),
      sortBy: sorter.field,
      sort: sorter?.order,
      ...tableFilter,
    };
    history.push(`?${ObjectToParams(params)}`);
  };

  const handleSelectAll = () => {
    setCurrentSelected(allId);
  };

  const handleUnselect = () => {
    setCurrentSelected([]);
  };

  const handleSearch = (value: string) => {
    if (value) {
      const filter = { ...tableFilter, s: value, pageNo: 1 };
      history.push(`?${ObjectToParams(filter)}`);
    }
  };

  const handleSearchPropagation = (event: any) => {
    if (event.target.value === '') {
      history.push(`?${ObjectToParams({ ...filter, pageNo: 1 })}`);
    }
    setSearch(event.target.value);
  };

  useEffect(() => {
    setCurrentSelected(getAllCurrentSelected());
  }, [currentSelectedByPage]);

  useEffect(() => {
    if (query.get('pageNo') && query.get('pageNo') !== pageNo) {
      setPageNo(query.get('pageNo'));
      setFilter({ ...filter, pageNo: query.get('pageNo') });
    }
    if (query.get('limit') && query.get('limit') !== limit) {
      setPageLimit(query.get('limit'));
      setFilter({ ...filter });
    }
    if (query.get('sort') && query.get('sort') !== sort) {
      setSort(query.get('sort'));
    }
    if (query.get('sortBy') && query.get('sortBy') !== sortBy) {
      setSortBy(query.get('sortBy'));
    }
    if (!query.get('sort')) {
      setSort('');
    }
    if (!query.get('sortBy')) {
      setSortBy('');
    }
    if (query.get('s')) {
      const filter: any = tableFilter;
      filter.s = query.get('s');
      setFilter({ ...filter });
    }
    if (!query.get('s')) {
      const filter: any = tableFilter;
      delete filter.s;
      setSearch('');
      setFilter({ ...filter });
    }
    if (!query.get('pageNo')) {
      setPageNo('1');
    }
  }, [
    query.get('pageNo'),
    query.get('limit'),
    query.get('sort'),
    query.get('sortBy'),
    query.get('s'),
    query.get('branch')
  ]);

  useEffect(() => {
    if (onChangeSelected) onChangeSelected(currentSelected);
  }, [currentSelected]);

  useEffect(() => {
    if (filter) setFilter({ ...tableFilter, ...filter });
  }, [filter]);

  useEffect(() => {
    if (data?.length < 0) {
      handleUnselect();
      history.push(`?${ObjectToParams({ ...filter, pageNo: 1 })}`);
    }
  }, [data]);

  useEffect(() => {
    setCurrentSelected([]);
  }, [refresh]);

  return (
    <div className="padding-x1">
      {filterOptions && (
        <Row justify="space-between" className="padding-bottom-1">
          <Col>
            <Space>
              <Search
                value={searchValue}
                placeholder={SEARCH}
                onSearch={handleSearch}
                className="table_search"
                allowClear
                onChange={handleSearchPropagation}
              />
              <Space direction="horizontal">{filterContent}</Space>
              {additionalFilters && <Space>{additionalFilters(filter)}</Space>}
            </Space>
          </Col>
          <Col>
            <Space>{tableButtons && tableButtons}</Space>
          </Col>
        </Row>
      )}

      <div className="table">
        {currentSelected.length > 0 && data.length > 0 && (
          <ToolBar
            tableName={tableName}
            currentSelected={currentSelected?.length || 0}
            totalDataCount={totalDataCount || 0}
            onSelectAll={handleSelectAll}
            onUnselect={handleUnselect}
            {...props}
          >
            {children}
          </ToolBar>
        )}
        <AntTable
          dataSource={data || []}
          columns={columns}
          loading={loading || tableLoading}
          size={size ? size : 'small'}
          expandable={expandable}
          rowSelection={
            rowSelect
              ? customRowSelection
                ? customRowSelection
                : rowSelection
              : undefined
          }
          className={className}
          pagination={false}
          onChange={handleTableChange}
          scroll={{ x: true, y: data?.length > 0 ? scroll?.y : undefined }}
        />
        {hasPagination && (
          <Row justify="end">
            <Pagination
              className="margin-top-2 margin-bottom-2"
              defaultPageSize={parseInt(limit)}
              {...PAGINATION_CONFIG}
              onChange={handleChange}
              total={totalNoOfData}
              hideOnSinglePage={totalNoOfData <= 10}
              current={parseInt(pageNo)}
            />
          </Row>
        )}
      </div>
    </div>
  );
};

Table.defaultProps = {
  rowSelect: true,
};
export { Table };
export * from './libs/table-elements';
