import { CrmPaginatedResponse } from "@model/api-response.model";
import crmAxios from "./crm-api-axios"
import { Task, UpdateTaskPayload } from "@model/crm/note.model";

export const getPaginatedTasks = async (params: Record<string, unknown>) => {
  const { data } = await crmAxios.get<CrmPaginatedResponse<Task>>(`/v1/tasks`, { params })
  return data;
}

export const updateTask = (taskId: string, payload: UpdateTaskPayload) => {
  return crmAxios.patch(`/v1/tasks/${taskId}`, payload)
}
