import {
  ArrowRightOutlined,
  CloseCircleOutlined,
  FilePdfOutlined,
  HeartOutlined,
  LeftCircleFilled,
  LinkOutlined,
  RightCircleFilled,
} from '@ant-design/icons';
import {
  ADD_TO_INTERESTED_SERVICE,
  PRODUCT_ACTIONS,
  START_APPLICATION,
  TEXT,
  URL_PRODUCT_DETAIL_LINK,
} from '@moxie/constants';
import { SelectProductWorkflowDrawer } from '@moxie/shared';
import { IProductComparisonModal } from '@shared-components/models';
import { Affix, Button, Col, Row, Space, Spin, Typography } from 'antd';
import { useForm } from 'antd/lib/form/Form';
import jsPDF from 'jspdf';
import autoTable from 'jspdf-autotable';
import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import { useProductCompare } from './use-product-compare';

const { Title, Text } = Typography;
const TOP = 50;

const ProductCompareTable: React.FC = () => {
  const {
    loading,
    disabled,
    productList,
    productListSource,
    tenantDetails,
    handleLeftClick,
    handleRightClick,
    handleDeleteProduct,
  } = useProductCompare();
  const [drawerIsOpen, setDrawer] = useState(false);
  const [selectedProduct, setSelectedProduct] = useState<string>('');
  const [productOne, setProductOne] = useState<IProductComparisonModal>();
  const [productTwo, setProductTwo] = useState<IProductComparisonModal>();
  const [productThree, setProductThree] = useState<IProductComparisonModal>();
  const [actionType, setActionType] = useState<string>('');
  const [colSpan, setColSpan] = useState(9);
  const [form] = useForm();

  const numberWithCommas = (x: number) => {
    return x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',');
  };

  const titleCase = (str: string) => {
    const splitStr = str.toLowerCase().split(' ');
    for (let i = 0; i < splitStr.length; i++) {
      splitStr[i] =
        splitStr[i].charAt(0).toUpperCase() + splitStr[i].substring(1);
    }

    return splitStr.join(' ');
  };

  const formatInstallmentType = (installmentType: string | undefined) => {
    const formattedString = installmentType?.split('_').join(' ');

    if (formattedString === undefined || formattedString === null) {
      return 'Not available';
    }

    return titleCase(formattedString);
  };

  const getProductDuration = (product: any) => {
    let duration = '';
    if (product.product_duration_year) {
      duration += `${product.product_duration_year} Years `;
    }
    if (product.product_duration_month) {
      duration += ` ${product.product_duration_month} Months`;
    }
    if (product.product_duration_week) {
      duration += ` ${product.product_duration_week} Weeks `;
    }
    return duration;
  };

  const getDefaultFees = (course: any) => {
    const defaultFee = course.fees.find(
      (item: any) => item.fee_name === 'Default Fee'
    );
    if (defaultFee === undefined) {
      return 'N/A';
    } else {
      return defaultFee.pfees
        .map((item: any) => {
          return `${formatFeeType(item.fee_type)}: ${
            course.institution.currency
          } ${numberWithCommas(item.total_fee)}\n\n`;
        })
        .toString()
        .split(',')
        .join('');
    }
  };

  const generateComparisionPdf = () => {
    let format = 'a3';
    let margin = 0;
    let contactXCordinate = 15;
    let doc;
    if (productListSource.length > 3) {
      format = 'a2';
    }

    if (productListSource.length === 2) {
      contactXCordinate = 8;
      margin = 7;
      format = 'a4';
    }

    if (productListSource.length === 4) {
      doc = new jsPDF('p', 'mm', [360, 400]);
    } else {
      doc = new jsPDF({ format });
    }

    //TODO: refactor in future when the logo becomes not null
    // doc.addImage(logo, 'png', 10, 10, 30, 30);

    doc.setFontSize(12);
    doc.text(tenantDetails.company_name!, contactXCordinate, 15);
    doc.setFontSize(10);
    doc.setTextColor(104, 104, 104);
    doc.text(`Tel: ${tenantDetails.company_phone}`, contactXCordinate, 22);
    doc.text(`Email: ${tenantDetails.company_email}`, contactXCordinate, 27);
    doc.text(`Web: ${tenantDetails.company_domain}`, contactXCordinate, 32);

    const courseDetailsHead = [['Compare Shortlist']];
    const courseDetailsBody = [
      ['Institution'],
      ['Level of Qualification'],
      ['Location'],
      ['Duration'],
      // ['Delivery'],
    ];

    productListSource.forEach((course: any) => {
      courseDetailsHead[0].push(course.name!);
      courseDetailsBody[0].push(course.institution.name);
      courseDetailsBody[1].push(
        course.degree ? course.degree.name : 'Not available'
      );
      courseDetailsBody[2].push(course.institution.country);
      courseDetailsBody[3].push(`${getProductDuration(course)}`);
      // courseDetailsBody[4].push('On-Campus');
    });

    autoTable(doc, {
      head: courseDetailsHead,
      body: courseDetailsBody,
      headStyles: {
        fillColor: '#4a2362',
        cellPadding: 5,
        cellWidth: 65,
      },
      bodyStyles: {
        minCellHeight: 10,
        cellPadding: 5,
      },
      startY: 45,
      margin,
      columnStyles: {
        0: { fontStyle: 'bold' },
      },
    });

    const admissionCriteriaHead = [['Admission Criteria']];
    const admissionCriteriaBody = [
      ['Entry Requirement'],
      ['Language Requirement'],
      ['Other Language Requirement'],
    ];

    productListSource.forEach((course: any) => {
      const productEducationRequirement: any[] = [];
      const productLanguageRequirement: any[] = [];
      const productOtherLanguageRequirement: any[] = [];

      //it might just be gpa or percentage sometimes
      course.product_educations.forEach((education: any) => {
        const hasBothGpaAndPercentage =
          education.percentage && education.gpa ? true : false;

        let educationGpaPercentage;
        if (hasBothGpaAndPercentage) {
          educationGpaPercentage = `${education.degreeLevel.name} (${education.percentage}%) or (${education.gpa} gpa)\n\n`;
        } else if (education.percentage) {
          educationGpaPercentage = `${education.degreeLevel.name} (${education.percentage}%)\n\n`;
        } else if (!education.percentage && !education.gpa) {
          educationGpaPercentage = '';
        } else {
          educationGpaPercentage = `${education.degreeLevel.name} (${education.gpa} gpa)\n\n`;
        }
        productEducationRequirement.push(educationGpaPercentage);
      });

      course.product_lang_test_scores.forEach((language: any) => {
        productLanguageRequirement.push(
          `${language.test_name?.toUpperCase()} (${language.overall_score})\n\n`
        );
      });

      course.product_lang_other_test_scores.forEach((language: any) => {
        productOtherLanguageRequirement.push(
          `${language.other_test_name?.toUpperCase().split('_').join(' ')} (${
            language.overall_score
          })\n\n`
        );
      });

      admissionCriteriaHead[0].push('');
      admissionCriteriaBody[0].push(
        productEducationRequirement.toString().split(',').join('')
      );
      admissionCriteriaBody[1].push(
        productLanguageRequirement.toString().split(',').join('')
      );
      admissionCriteriaBody[2].push(
        productOtherLanguageRequirement.toString().split(',').join('')
      );
    });

    autoTable(doc, {
      head: admissionCriteriaHead,
      body: admissionCriteriaBody,
      headStyles: {
        fillColor: '#4a2362',
        cellPadding: 5,
        cellWidth: 65,
      },
      bodyStyles: {
        minCellHeight: 10,
        cellPadding: 5,
      },
      margin,
      columnStyles: {
        0: { fontStyle: 'bold' },
      },
    });

    const pricingHead = [['Pricing']];
    const pricingBody = [
      ['Installment Type'],
      ['Installment Amount'],
      ['Total Fee'],
    ];

    productListSource.forEach((course: any) => {
      pricingHead[0].push('');

      pricingBody[0].push(
        formatInstallmentType(course?.fees[0]?.pfees[0]?.installment_type)
      );
      pricingBody[1].push(
        `${course.institution.currency} ${numberWithCommas(
          course?.fees[0]?.pfees[0]?.installment_amount
        )}`
      );
      pricingBody[2].push(`${numberWithCommas(getDefaultFees(course))}`);
    });

    autoTable(doc, {
      head: pricingHead,
      body: pricingBody,
      headStyles: {
        fillColor: '#4a2362',
        cellPadding: 5,
        cellWidth: 65,
      },
      bodyStyles: {
        minCellHeight: 10,
        cellPadding: 5,
      },
      margin,
      showHead: 'firstPage',
      columnStyles: {
        0: { fontStyle: 'bold' },
      },
    });

    //open pdf in new window
    const blob = doc.output('blob');
    const data = URL.createObjectURL(blob);
    window.open(data);
  };

  useEffect(() => {
    setProductOne(productList[0]);
    setProductTwo(productList[1]);
    setProductThree(productList[2]);
    if (productList.length === 3) setColSpan(6);
    else setColSpan(9);
  }, [productList]);

  const handleDelete = (id: string): void => {
    handleDeleteProduct(id);
  };

  const handleAddProductToInterestedService = (productId: string) => {
    setSelectedProduct(productId);
    setDrawer(true);
    setActionType(PRODUCT_ACTIONS.ADD_TO_INTERESTED);
  };

  const handleStartButton = (productId: string) => {
    setSelectedProduct(productId);
    setDrawer(true);
    setActionType(PRODUCT_ACTIONS.START_APPLICATION);
  };

  const formatFeeType = (feeType: string) => {
    const formattedString = feeType.split('_').join(' ');
    return titleCase(formattedString);
  };

  const showProductFees = (product: any) => {
    const defaultFee = product.fees.find(
      (item: any) => item.fee_name === 'Default Fee'
    );
    if (defaultFee === undefined) {
      return 'N/A';
    } else {
      return defaultFee.pfees.map((item: any, i: number) => {
        return (
          <Text key={`${i}`}>{` ${formatFeeType(item.fee_type)}: ${
            product.institution.currency
          } ${numberWithCommas(item.total_fee)}`}</Text>
        );
      });
    }
  };

  return (
    <div className="product-comparison-section">
      <SelectProductWorkflowDrawer
        form={form}
        actionType={actionType}
        onClose={() => setDrawer(false)}
        isOpen={drawerIsOpen}
        productId={selectedProduct}
      />
      <div className="btn-left">
        <Button
          icon={<LeftCircleFilled />}
          type="link"
          size="large"
          onClick={handleLeftClick}
          disabled={disabled.left}
        />
      </div>
      <Spin spinning={loading}>
        <div className="product-c">
          <Row className="full-width">
            <Col span={24} className="padding-0">
              <Affix offsetTop={TOP}>
                <Row>
                  <Col
                    span={6}
                    className="bg-light-gray text-center text-align-center"
                  >
                    <Title level={4} className="primary-color">
                      {TEXT.COMPARE_SHORTLIST}
                    </Title>
                  </Col>
                  <Col span={colSpan} className="highlighted-dark">
                    <Col
                      span={24}
                      className="margin-0 padding-0 padding-bottom-half"
                    >
                      <strong>{productOne?.name}</strong>
                    </Col>
                    <small>{productOne?.institution?.name}</small>
                  </Col>
                  <Col span={colSpan} className="highlighted">
                    <Col
                      span={24}
                      className="margin-0 padding-0 padding-bottom-1"
                    >
                      <strong>{productTwo?.name}</strong>
                    </Col>
                    <small>{productTwo?.institution?.name}</small>
                  </Col>
                  {productThree && (
                    <Col span={colSpan} className="highlighted-dark">
                      <Col
                        span={24}
                        className="margin-0 padding-0 padding-bottom-half"
                      >
                        <strong>{productThree?.name}</strong>
                      </Col>
                      <small>{productThree?.institution?.name}</small>
                    </Col>
                  )}
                </Row>
              </Affix>
            </Col>
            <Col span={6}>{TEXT.LEVEL_OF_QUALIFICATION}</Col>
            <Col span={colSpan} className="bg-light-gray">
              {(productOne && productOne.degree && productOne.degree.name) ||
                'N/A'}
            </Col>
            <Col span={colSpan}>
              {(productTwo && productTwo.degree && productTwo.degree.name) ||
                'N/A'}
            </Col>

            {productThree && (
              <Col span={colSpan} className="bg-light-gray">
                {(productThree.degree && productThree.degree.name) || 'N/A'}
              </Col>
            )}

            <Col span={6}>{TEXT.INSTITUTION}</Col>
            <Col span={colSpan} className="bg-light-gray">
              {productOne?.institution?.name}
            </Col>
            <Col span={colSpan}>{productTwo?.institution?.name}</Col>
            {productThree && (
              <Col className="bg-light-gray" span={colSpan}>
                {productThree?.institution?.name}
              </Col>
            )}
            <Col span={6}>{TEXT.LOCATION}</Col>
            <Col span={colSpan} className="bg-light-gray">
              {productOne?.institution?.country}
            </Col>
            <Col span={colSpan}>{productTwo?.institution?.country}</Col>
            {productThree && (
              <Col span={colSpan} className="bg-light-gray">
                {productThree?.institution?.country}
              </Col>
            )}
            <Col span={6}>{TEXT.DURATION}</Col>
            <Col span={colSpan} className="bg-light-gray">
              {productOne && getProductDuration(productOne)}
            </Col>
            <Col span={colSpan}>
              {productTwo && getProductDuration(productTwo)}
            </Col>
            {productThree && (
              <Col className="bg-light-gray" span={colSpan}>
                {productThree && getProductDuration(productThree)}
              </Col>
            )}
            <Col span={6}>{TEXT.FEES}</Col>
            <Col span={colSpan} className="bg-light-gray">
              <Space direction="vertical">
                {productOne && productOne.fees && showProductFees(productOne)}
              </Space>
            </Col>
            <Col span={colSpan}>
              <Space direction="vertical">
                {productTwo && productTwo.fees && showProductFees(productTwo)}
              </Space>
            </Col>
            {productThree && (
              <Col className="bg-light-gray" span={colSpan}>
                <Space direction="vertical">
                  {productThree &&
                    productThree.fees &&
                    showProductFees(productThree)}
                </Space>
              </Col>
            )}
          </Row>

          <Affix offsetBottom={0}>
            <Row align="bottom" className="full-width">
              <Col span={24} className="bg-background-color">
                <Text strong>{TEXT.ACTION}</Text>
              </Col>
              <Col span={6}>
                <Text>{TEXT.GENERATE_PDF_DESCRIPTION}</Text>
                <Button
                  block={true}
                  onClick={generateComparisionPdf}
                  type="primary"
                  size="large"
                  className="generate-pdf-btn"
                >
                  {TEXT.GENERATE_PDF}
                  <FilePdfOutlined />
                </Button>
              </Col>
              <Col span={colSpan} className="bg-light-gray">
                <Row className="bg-light-gray activity">
                  <Col span={24} className="padding-0 full-width">
                    <Link
                      to={`${URL_PRODUCT_DETAIL_LINK}${
                        productOne?.id || ''
                      }/applications`}
                      target="_blank"
                    >
                      <Row
                        justify="space-between"
                        className="bg-light-gray padding-right-1 padding-left-1 padding-0"
                      >
                        <Text strong>{TEXT.VIEW_COURSE_DETAILS}</Text>
                        <ArrowRightOutlined />
                      </Row>
                    </Link>
                  </Col>
                  <Col span={24} className="padding-0">
                    <Button
                      className="full-width ant-link-btn"
                      type="link"
                      onClick={() => handleDelete(productOne?.id || '')}
                    >
                      <Text strong>{TEXT.REMOVE_FROM_COMPARISON}</Text>
                      <CloseCircleOutlined />
                    </Button>
                  </Col>
                  <Col span={24} className="padding-0">
                    <Button
                      data-testid="addToInterestedServices_btn1"
                      className="full-width interested-service-btn"
                      type="primary"
                      onClick={() =>
                        handleAddProductToInterestedService(
                          productOne?.id || ''
                        )
                      }
                    >
                      {ADD_TO_INTERESTED_SERVICE}
                      <HeartOutlined />
                    </Button>
                  </Col>
                  <Col span={24} className="padding-0 padding-top-1">
                    <Button
                      data-testid="startApplication_btn1"
                      className="full-width"
                      type="primary"
                      onClick={() => handleStartButton(productOne?.id || '')}
                    >
                      {START_APPLICATION}
                      <LinkOutlined />
                    </Button>
                  </Col>
                </Row>
              </Col>
              <Col span={colSpan}>
                <Row className="activity">
                  <Col span={24} className="padding-0 activity">
                    <Link
                      to={`${URL_PRODUCT_DETAIL_LINK}${
                        productTwo?.id || ''
                      }/applications`}
                      target="_blank"
                    >
                      <Row
                        justify="space-between"
                        className="padding-right-1 padding-left-1 padding-0 link"
                      >
                        <Text strong>{TEXT.VIEW_COURSE_DETAILS}</Text>
                        <ArrowRightOutlined />
                      </Row>
                    </Link>
                  </Col>
                  <Col span={24} className="padding-0">
                    <Button
                      className="full-width ant-link-btn"
                      type="link"
                      onClick={() => handleDelete(productTwo?.id || '')}
                    >
                      <Text strong>{TEXT.REMOVE_FROM_COMPARISON}</Text>
                      <CloseCircleOutlined />
                    </Button>
                  </Col>
                  <Col span={24} className="padding-0">
                    <Button
                      data-testid="addToInterestedServices_btn2"
                      className="full-width interested-service-btn"
                      type="primary"
                      onClick={() =>
                        handleAddProductToInterestedService(
                          productTwo?.id || ''
                        )
                      }
                    >
                      {ADD_TO_INTERESTED_SERVICE}
                      <HeartOutlined />
                    </Button>
                  </Col>
                  <Col span={24} className="padding-0 padding-top-1">
                    <Button
                      data-testid="startApplication_btn2"
                      className="full-width"
                      type="primary"
                      onClick={() => handleStartButton(productTwo?.id || '')}
                    >
                      {START_APPLICATION}
                      <LinkOutlined />
                    </Button>
                  </Col>
                </Row>
              </Col>
              {productThree && (
                <Col span={colSpan} className="bg-light-gray">
                  <Row className="bg-light-gray activity">
                    <Col span={24} className="padding-0 activity">
                      <Link
                        to={`${URL_PRODUCT_DETAIL_LINK}${
                          productTwo?.id || ''
                        }/applications`}
                        target="_blank"
                      >
                        <Row
                          justify="space-between"
                          className="bg-light-gray padding-right-1 padding-left-1 padding-0 link"
                        >
                          <Text strong>{TEXT.VIEW_COURSE_DETAILS}</Text>
                          <ArrowRightOutlined />
                        </Row>
                      </Link>
                    </Col>
                    <Col span={24} className="padding-0">
                      <Button
                        className="full-width ant-link-btn"
                        type="link"
                        onClick={() => handleDelete(productThree?.id || '')}
                      >
                        <Text strong>{TEXT.REMOVE_FROM_COMPARISON}</Text>
                        <CloseCircleOutlined />
                      </Button>
                    </Col>
                    <Col span={24} className="padding-0">
                      <Button
                        data-testid="addToInterestedServices_btn3"
                        className="full-width interested-service-btn"
                        type="primary"
                        onClick={() =>
                          handleAddProductToInterestedService(
                            productThree?.id || ''
                          )
                        }
                      >
                        {ADD_TO_INTERESTED_SERVICE}
                        <HeartOutlined />
                      </Button>
                    </Col>
                    <Col span={24} className="padding-0 padding-top-1">
                      <Button
                        data-testid="startApplication_btn3"
                        className="full-width"
                        type="primary"
                        onClick={() =>
                          handleStartButton(productThree?.id || '')
                        }
                      >
                        {START_APPLICATION}
                        <LinkOutlined />
                      </Button>
                    </Col>
                  </Row>
                </Col>
              )}
            </Row>
          </Affix>
        </div>
      </Spin>
      <div className="btn-right">
        <Button
          icon={<RightCircleFilled />}
          type="link"
          size="large"
          onClick={handleRightClick}
          disabled={disabled.right}
        />
      </div>
    </div>
  );
};

export { ProductCompareTable };
