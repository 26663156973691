import { ReactRenderer } from '@tiptap/react'
import tippy from 'tippy.js'

import MentionList from './mention-list';
import { MentionOptions } from '@tiptap/extension-mention';
import { SuggestionProps } from '@tiptap/suggestion';
import { RefAttributes } from 'react';
import { Assignee } from '@model/application';
import { queryAssignees } from '@crm/services.api';

const suggestion: MentionOptions<Assignee>['suggestion'] = {
  async items({ query }) {
    const { data } = await queryAssignees({
      search: query,
      limit: 10,

    })

    return data.data;
  },


  render: () => {
    let component: ReactRenderer<unknown, SuggestionProps<any, any> & RefAttributes<unknown>>;
    let popup: {
      setProps(arg0: { getReferenceClientRect: () => DOMRect | null; }): unknown;
      hide(): unknown; destroy: () => void;
    }[]

    return {
      onStart(props) {
        component = new ReactRenderer(MentionList, {
          props,
          editor: props.editor,
        })

        if (!props.clientRect) {
          return
        }

        // @ts-ignore
        popup = tippy('body', {
          getReferenceClientRect: props.clientRect,
          appendTo: () => document.body,
          content: component.element,
          showOnCreate: true,
          interactive: true,
          trigger: 'manual',
          placement: 'bottom-start',
        })
      },

      onUpdate(props) {
        component.updateProps(props)
        if (!props.clientRect) {
          return
        }

        popup[0].setProps({
          getReferenceClientRect: props.clientRect,
        })
      },

      onKeyDown(props) {
        if (props.event.key === 'Escape') {
          popup[0].hide()

          return true
        }

        // @ts-ignore
        return component.ref?.onKeyDown(props)
      },

      onExit() {
        popup[0].destroy()
        component.destroy()
      },
    }
  },
}

export default suggestion;
