import { IAction, ILead } from '@shared-components/models';
import * as actionTypes from './lead.constant';

export const LEAD_FEATURE_KEY = 'lead';

export interface leadState {
  lead?: ILead | any;
  data?: ILead | undefined;
  loading: boolean;
  downloading?: boolean;
  error: boolean | null;
  redirect: boolean;
  redirectToAll: boolean;
}

export const initialLeadstate: leadState = {
  lead: {},
  data: undefined,
  loading: false,
  downloading: false,
  error: null,
  redirect: false,
  redirectToAll: false,
};

const leadReducer = (state = initialLeadstate, action: IAction<any>) => {
  switch (action.type) {
    case actionTypes.GET_LEAD:
      return {
        ...state,
        loading: false,
        redirect: false,
        lead: action.payload,
      };

    case actionTypes.SET_ERROR:
      return {
        ...state,
        loading: false,
        error: action.payload,
      };

    case actionTypes.SET_REDIRECT:
      return {
        ...state,
        loading: false,
        redirect: true,
      };

    case actionTypes.LOADING:
      return {
        ...state,
        loading: action.payload,
        redirect: false,
      };

    case actionTypes.DOWNLOADING:
      return {
        ...state,
        downloading: action.payload,
        redirect: false,
      };

    case actionTypes.CLEAR_LEAD:
      return {
        ...state,
        loading: false,
        downloading: false,
        redirect: false,
        error: null,
        lead: null,
      };
    case actionTypes.SET_REDIRECT_TO_ALL_LEADS:
      return {
        ...state,
        redirectToAll: action.payload,
      };
    default:
      return state;
  }
};

export default leadReducer;
