import { CRM_URL } from "@moxie/constants"
import crmAxios from "./crm-api-axios"
import { PermissionFormFields } from "@model/permission.model"
import { ICrmPermissionData } from "libs/shared/src/crm-modules/user/user.model"


export const fetchReportPermission = async (userId: string) => {
  const { data } = await crmAxios.get<{ data: ICrmPermissionData[] }>(`${CRM_URL.PERMISSIONS}/${userId}`)
  return data.data;
}

export const updateUserBranchPermissions = (userId: string, permissions: PermissionFormFields) => {
  return crmAxios.patch(`${CRM_URL.PERMISSIONS}/${userId}/branch-permission`, permissions);
}

export const updateReportPermission = (userId: string,
  crmPermissionData: { permissionIds: { id: string | number }[] }) => {
  return crmAxios.patch(
    `${CRM_URL.PERMISSIONS}/${userId}`,
    crmPermissionData
  )
}
