import { axiosInstance as axios } from '@lyra/services.api';
import { API_URL } from '@moxie/constants';
import { IResponse } from '@shared-components/models';

const baseUrl = process.env.NX_BASE_URL;
export const endpoint_institutions = `${baseUrl}${API_URL.INSTITUTIONS}`;

const instituteServices = {
  apiGetInstitute: async (): Promise<IResponse> => {
    return axios.get(`${endpoint_institutions}`);
  },
  apiGetInstituteDetail: async (id: number): Promise<IResponse> => {
    return axios.get(`${endpoint_institutions}/${id}`);
  },
  apiGetInstitutionBranches: async (
    id: number,
    params = ''
  ): Promise<IResponse> => {
    return axios.get(`${endpoint_institutions}/branches/${id}/${params}`);
  },
  apiGetInstitutionProducts: async (
    id: number,
    params = ''
  ): Promise<IResponse> => {
    return axios.get(`${endpoint_institutions}/${id}/products/${params}`);
  },
  apiGetInstitutionAgentApplications: async (
    id: number,
    params = ''
  ): Promise<IResponse> => {
    return axios.get(
      `${API_URL.APPLICATIONS}/institution/application-list/agent/${id}${params}`
    );
  },
};

export { instituteServices };
