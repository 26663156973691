import { RegistrationCard } from '@shared-components/elements';
import { Row, Col, Typography } from 'antd';
import { CheckCircleOutlined } from '@ant-design/icons';
import React from 'react';

const REGISTRATION_SUCCESS_MESSAGE =
  "You'll receive an email confirmation soon. If not, please check your spam folder and then, if it's not there, please contact at";

const { Paragraph } = Typography;
const RegistrationSuccessCard: React.FC = () => {
  return (
    <RegistrationCard title={'Thanks for signing up.'}>
      <Row justify="center" gutter={[24, 32]} className="registration-success">
        <Col>
          <CheckCircleOutlined data-testid="registration-success-logo" />
        </Col>
        <Col span={24}>
          <Paragraph>
            <strong>
              {REGISTRATION_SUCCESS_MESSAGE}
              &nbsp;
              <a
                href="mailto: support@applyims.com"
                target="_blank"
                rel="noreferrer"
              >
                support@applyims.com
              </a>
            </strong>
          </Paragraph>
        </Col>
      </Row>
    </RegistrationCard>
  );
};

export { RegistrationSuccessCard };
