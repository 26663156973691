import { socketSagaActions } from '../sagas/sagaAction';

export const setSocketRequest = (data: any) => {
  return {
    type: socketSagaActions.SET_SOCKET_CONNECTION,
    payload: data.payload,
  };
};
export const removeSocketRequest = () => {
  return {
    type: socketSagaActions.REMOVE_SOCKET_CONNECTION,
  };
};
