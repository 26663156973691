const alias = 'EDUCATION_BACKGROUND';

export const GET_EDUCATION_BACKGROUNDS_REQUEST = `GET_${alias}S_REQUEST`;
export const GET_EDUCATION_BACKGROUNDS_SUCCESS = `GET_${alias}S_SUCCESS`;
export const GET_EDUCATION_BACKGROUNDS_FAILURE = `GET_${alias}_FAILURE`;
export const GET_EDUCATION_BACKGROUNDS_CLEAR = `GET_${alias}S_CLEAR`;

export const GET_EDUCATION_BACKGROUND_REQUEST = `GET_${alias}_REQUEST`;
export const GET_EDUCATION_BACKGROUND_SUCCESS = `GET_${alias}_SUCCESS`;
export const GET_EDUCATION_BACKGROUND_FAILURE = `GET_${alias}_FAILURE`;

export const ADD_EDUCATION_BACKGROUND_REQUEST = `ADD_${alias}_REQUEST`;
export const ADD_EDUCATION_BACKGROUND_SUCCESS = `ADD_${alias}_SUCCESS`;
export const ADD_EDUCATION_BACKGROUND_FAILURE = `ADD_${alias}_FAILURE`;

export const UPDATE_EDUCATION_BACKGROUND_REQUEST = `UPDATE_${alias}_REQUEST`;
export const UPDATE_EDUCATION_BACKGROUND_SUCCESS = `UPDATE_${alias}_SUCCESS`;
export const UPDATE_EDUCATION_BACKGROUND_FAILURE = `UPDATE_${alias}_FAILURE`;

export const DELETE_EDUCATION_BACKGROUND_REQUEST = `DELETE_${alias}_REQUEST`;
export const DELETE_EDUCATION_BACKGROUND_SUCCESS = `DELETE_${alias}_SUCCESS`;
export const DELETE_EDUCATION_BACKGROUND_FAILURE = `DELETE_${alias}_FAILURE`;
