import React, { useEffect, useState } from 'react';
import {
  CONFIRM_MESSAGE,
  DELETE_BTN,
  PAGE_HEADER_MENU_WORKFLOWS,
  TEXT,
  URL_WORKFLOWS,
} from '@moxie/constants';
import { PageHeaderTitle, popConfirm } from '@shared-components/elements';
import { useHistory } from 'react-router-dom';
import { WorkflowTable } from './workflow-table';
import { getWorkflows, postWorkflow } from '@admin/services.api';
import { useDispatch } from 'react-redux';
import { workflowActions } from '@admin/core';
import { WorkflowDrawer } from './workflow-form-drawer';
import { useForm } from 'antd/lib/form/Form';
import { IWorkflow } from '@shared-components/models';
import { errorHandler } from '@moxie/utils';
import { PageHeaderMenu } from '../components/page-header-menu';

const WorkflowList = () => {
  const [form] = useForm();
  const dispatch = useDispatch();
  const history = useHistory();
  const [refreshTable, setRefreshTable] = useState(true);
  const [visible, setVisible] = useState(false);
  const [isEdit, setIsEdit] = useState(false);
  const [disabled, setDisabled] = useState(true);
  const [submitted, setSubmitted] = useState(false);
  const [filter] = useState({});
  const triggerRefresh = () => setRefreshTable((prev) => !prev);

  const getData = async (params: string) => {
    return getWorkflows(params);
  };

  const onClose = () => {
    form.resetFields();
    setVisible(false);
    setIsEdit(false);
    setDisabled(true);
    setSubmitted(false);
  };

  const showDrawer = async (id?: string) => {
    if (id) {
      history.push(`${URL_WORKFLOWS}/view/${id}`);
    }
    setVisible(true);
  };

  const handleFormSubmit = async () => {
    form.submit();
  };

  const handleSubmit = async (data: IWorkflow) => {
    setDisabled(true);
    setSubmitted(true);
    if (data) {
      try {
        const response = await postWorkflow(data);
        history.push(`${URL_WORKFLOWS}/view/${response?.data?.data?.id}`);
      } catch (err) {
        errorHandler(err);
      }
    }
    onClose();
  };

  const deleteData = (id: string) => {
    popConfirm(DELETE_BTN, CONFIRM_MESSAGE.DELETE_WORKFLOW, () => {
      dispatch(workflowActions.deleteWorkflowRequest(id, triggerRefresh));
    });
  };

  useEffect(() => {
    dispatch(workflowActions.clearWorkflowFetch());
  }, []);

  return (
    <div className="full-height">
      <PageHeaderTitle title={TEXT.WORKFLOW} />
      <PageHeaderMenu menu={PAGE_HEADER_MENU_WORKFLOWS} />
      {visible && (
        <WorkflowDrawer
          visible={visible}
          onClose={onClose}
          isEdit={isEdit}
          form={form}
          handleFormSubmit={handleFormSubmit}
          handleSubmit={handleSubmit}
          disabled={disabled}
          setDisabled={setDisabled}
          submitted={submitted}
        />
      )}
      <WorkflowTable
        getData={getData}
        tableName={TEXT.WORKFLOW}
        refreshTable={refreshTable}
        deleteData={deleteData}
        showDrawer={showDrawer}
        filter={filter}
      />
    </div>
  );
};

export { WorkflowList };
