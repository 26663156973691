/* eslint-disable no-unused-vars */
import {
  CheckCircleFilled,
  FilterOutlined,
  PlusOutlined,
} from '@ant-design/icons';
import {
  COLUMNS,
  PRODUCT_ACTIONS,
  PRODUCT_TITLE,
  TEXT,
  URL_PRODUCT_DETAIL_LINK,
} from '@moxie/constants';
import {
  successNotificationHandler,
  warnNotificationHandler,
  TextInstitutionLinkField,
} from '@moxie/shared';
import {
  CompareFilledIcon,
  CompareOutlinedIcon,
  InterestedServiceOutlinedIcon,
  Table,
  InstitutionBranchTagGroup,
} from '@shared-components/elements';
import {
  IDataType,
  IInstituteInterface,
  IProduct,
  IProductComparisonModal,
  IProductSearchTable,
  IProductSearchTableAction,
  IProductType,
} from '@shared-components/models';
import {
  InterestedServiceAddEdit,
  ProductFilterDrawer,
  ProductTableExpandable,
} from '@shared-components/modules';
import { Badge, Button, Tooltip, Typography } from 'antd';
import React, { useContext, useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { Link, useHistory, useLocation } from 'react-router-dom';
import { ProductComparisonListContext } from '../../product-comparison-list';
import { SelectProductWorkflowDrawer } from '../../select-product-workflow-drawer';
const { Text } = Typography;

//Commented because need to refactor later
// const renderQualificationTags = (
//   otherTest: boolean,
//   languageTest: boolean,
//   educationBackground: boolean
// ): React.ReactElement => (
//   <span>
//     {otherTest || languageTest || educationBackground ? (
//       <Text>
//         <span className="profile__title margin-bottom-1">
//           {TEXT.ELIGIBILITY_FILTER_TOOLTIP}
//         </span>
//       </Text>
//     ) : (
//       <Tag>{TEXT.NO_QUALIFICATION}</Tag>
//     )}
//     <Row>
//       <Col className="margin-bottom-1 initial_capital">
//         {otherTest && <Tag>{TEXT.OTHER_TEST}</Tag>}
//       </Col>
//       <Col className="margin-bottom-1 initial_capital">
//         {languageTest && <Tag>{TEXT.LANGUAGE_TEST}</Tag>}
//       </Col>
//       <Col className="margin-bottom-1 initial_capital">
//         {educationBackground && <Tag>{TEXT.EDUCATION_BACKGROUND}</Tag>}
//       </Col>
//     </Row>
//   </span>
// );

const ProductSearchTable: React.FC<IProductSearchTable> = (
  props: IProductSearchTable
) => {
  const { getData, lead, form, refresh } = props;
  const search = useLocation().search;
  const history = useHistory();
  const [showFilter, setShowFilter] = useState<boolean>(false);
  // eslint-disable-next-line no-unused-vars
  const [filterCount, setFilterCount] = useState(0);

  const {
    otherTestScore,
    haveLanguageTestScore,
    haveEducationBackground,
  } = useSelector((store: any) => ({
    otherTestScore: store.other_test_score.otherTestScoreList,
    haveLanguageTestScore: Boolean(
      store.language_test_score.languageTestScoreList.length > 0
    ),
    haveEducationBackground: Boolean(
      store.education_background.educationBackgrounds.length > 0
    ),
  }));
  const { sat_1, sat_2, gre, gmat } = otherTestScore;
  const haveOtherTestScore = sat_1 > 0 || sat_2 > 0 || gre > 0 || gmat > 0;
  // const [filter, setFilter] = useState<any>({
  //   byQualification:
  //     haveOtherTestScore || haveLanguageTestScore || haveEducationBackground
  //       ? true
  //       : null,
  // });
  const [filter, setFilter] = useState<any>({});

  const TextProductLinkField = (text: string | number, row: any) => {
    return (
      <Link to={`${URL_PRODUCT_DETAIL_LINK}${row.id}/applications`}>
        <Text
          className="text-primary-color ellipsis-text product-name-table"
          ellipsis={String(text).length >= 45 ? { tooltip: text } : false}
        >
          {text}
        </Text>
      </Link>
    );
  };

  const columns: (IDataType | any)[] = [
    {
      title: COLUMNS.NAME,
      sorter: (a: any, b: any) => a.name.localeCompare(b.name),
      sortDirections: ['descend', 'ascend'],
      dataIndex: 'name',
      key: 'name',
      render: TextProductLinkField,
    },
    {
      title: COLUMNS.INSTITUTION_BRANCHES,
      dataIndex: 'institution',
      render(institute: IInstituteInterface, data: IProduct) {
        const productBranches: any[] = [];
        data?.institutionBranches?.forEach((item: any) => {
          item?.branch && productBranches.push(item.branch);
        });
        return (
          <>
            <TextInstitutionLinkField
              text={institute?.name}
              institutionId={institute?.id}
            />
            <InstitutionBranchTagGroup
              data={productBranches}
              numberOfBranch={3}
            />
          </>
        );
      },
      key: 'institution',
    },
    {
      title: COLUMNS.PRODUCT_TYPE,
      dataIndex: 'productType',
      key: 'productType',
      render(product: IProductType, data: IProduct) {
        return (
          <>
            <div>
              <Text className="text-primary-color">{data?.degree?.name}</Text>
            </div>
            <Text className="">
              <small>{product?.name}</small>
            </Text>
          </>
        );
      },
    },
    {
      title: COLUMNS.ACTIONS,
      render(data: IProductType) {
        return <ProductSearchTableAction data={data} lead={lead} form={form} />;
      },
    },
  ];

  const handleToggleFilter = () => {
    setShowFilter(!showFilter);
  };

  // const handleToggleFilterQualification = (e: boolean) => {
  //   if (e) {
  //     setFilter({ ...filter, byQualification: true });
  //   } else {
  //     setFilter({ ...filter, byQualification: null });
  //   }
  // };

  // useEffect(() => {
  //   if (
  //     haveOtherTestScore ||
  //     haveLanguageTestScore ||
  //     haveEducationBackground
  //   ) {
  //     setFilter({ ...filter, byQualification: true });
  //   } else {
  //     setFilter({ ...filter, byQualification: null });
  //   }
  // }, [haveLanguageTestScore, haveEducationBackground, haveOtherTestScore]);

  useEffect(() => {
    const filtersCount = Object.keys(filter).filter(function (k: any) {
      return filter[k] !== null && k !== 'pageNo';
    });
    setFilterCount(filtersCount.length);
  }, [filter]);
  return (
    <>
      <div className="padding-top-2">
        <Table
          tableName={PRODUCT_TITLE}
          className="interested-service-table"
          columns={columns}
          refresh={refresh}
          action={getData}
          filter={filter}
          filterOptions={true}
          rowSelect={false}
          expandable={{
            expandedRowRender(data: IProduct) {
              return <ProductTableExpandable data={data} />;
            },
          }}
          filterContent={
            <Button onClick={handleToggleFilter}>
              <FilterOutlined />
              {TEXT.FILTERS}
              {filterCount > 0 && (
                <Badge
                  count={<CheckCircleFilled className="text-primary" />}
                  size="small"
                  className="margin-left-1"
                />
              )}
            </Button>
          }
          /*TODO: refact for future */
          /* tableButtons={
            <Row justify-content="space-between">
              <Col>
                <span className="margin-right-1 profile__title">
                  {TEXT.ELIGIBLE_PRODUCTS_ONLY}
                </span>
                <Tooltip
                  color="white"
                  overlayInnerStyle={{ borderRadius: '12px' }}
                  placement="topLeft"
                  title={renderQualificationTags(
                    haveOtherTestScore,
                    haveLanguageTestScore,
                    haveEducationBackground
                  )}
                >
                  <Switch
                    size="small"
                    onChange={handleToggleFilterQualification}
                    disabled={
                      !(
                        haveOtherTestScore ||
                        haveLanguageTestScore ||
                        haveEducationBackground
                      )
                    }
                    defaultChecked
                  />
                </Tooltip>
              </Col>
            </Row>
          }
          filterContent={
            <Button onClick={handleToggleFilter}>
              <FilterOutlined />
              {TEXT.FILTERS}
              {filterCount > 0 && (
                <Badge
                  count={<CheckCircleFilled className="text-primary" />}
                  size="small"
                  className="margin-left-1"
                />
              )}
            </Button>
          }*/
        />
      </div>

      <ProductFilterDrawer
        setFilter={setFilter}
        filter={filter}
        setShowFilter={setShowFilter}
        handleToggleFilter={handleToggleFilter}
        showFilter={showFilter}
      />
    </>
  );
};

const ProductSearchTableAction = (props: IProductSearchTableAction) => {
  const { data, lead, form } = props;
  const [isOpen, setOpen] = useState(false);
  const [isSubmitted, setIsSubmitted] = useState(false);
  const [productId, setProductId] = useState('');
  const [addToCompare, setAddCompare] = useState(false);
  const [actionType, setActionType] = useState<string>('');
  const [productWorkflowDrawerIsOpen, setProductWorkflowDrawer] = useState(
    false
  );
  const { compareList, handleUpdateList } = useContext(
    ProductComparisonListContext
  );

  const handleClose = () => {
    setOpen(false);
    setIsSubmitted(false);
  };

  const handleAddToCompare = (data: IProductComparisonModal) => {
    const product_id = data.id;
    const getCompareJson: any = localStorage.getItem('compare');
    let getCompares = JSON.parse(getCompareJson);
    if (!getCompares) getCompares = [];
    const checkExist = getCompares?.some(
      (item: IProductComparisonModal) => item.id === product_id
    );

    if (checkExist) {
      const filteredProduct = getCompares.filter(
        (item: IProductComparisonModal) => item.id !== product_id
      );
      localStorage.setItem('compare', JSON.stringify(filteredProduct));
      handleUpdateList(filteredProduct);
      warnNotificationHandler(
        `${data?.name} ${TEXT.REMOVED_FROM_COMPARISON_LIST}`
      );
    } else {
      if (compareList.length === 5) {
        warnNotificationHandler(`${TEXT.MAXIMUM_PRODUCT_FOR_COMPARISION}`);
        return;
      }
      localStorage.setItem('compare', JSON.stringify([...getCompares, data]));
      handleUpdateList([...getCompares, data]);
      successNotificationHandler(`${data?.name} ${TEXT.COMPARISON_LIST}`);
    }

    setAddCompare(!addToCompare);
  };

  const handleAddToInterestedService = (data: IProduct | any) => {
    setProductWorkflowDrawer(true);
    setProductId(data.id);
    setActionType(PRODUCT_ACTIONS.ADD_TO_INTERESTED);
  };

  const handleStartButton = (data: IProduct | any) => {
    setProductWorkflowDrawer(true);
    setProductId(data.id);
    setActionType(PRODUCT_ACTIONS.START_APPLICATION);
  };

  useEffect(() => {
    const product_id = data.id;
    const checkExist = compareList
      ?.map((item: any) => item.id === product_id)
      .includes(true);
    if (checkExist) {
      setAddCompare(true);
    } else setAddCompare(false);
  }, [compareList]);

  return (
    <>
      {productWorkflowDrawerIsOpen && (
        <SelectProductWorkflowDrawer
          form={form}
          actionType={actionType}
          isOpen={productWorkflowDrawerIsOpen}
          onClose={() => setProductWorkflowDrawer(false)}
          productId={productId}
        />
      )}
      <InterestedServiceAddEdit
        isOpen={isOpen}
        initialData={null}
        onClose={handleClose}
        id={lead?.id}
        form={form}
        isSubmitted={isSubmitted}
        setSubmitted={setIsSubmitted}
      />
      <div className="product-search-table">
        <Tooltip
          title={
            !addToCompare ? TEXT.ADD_COMPARE_LIST : TEXT.REMOVE_FROM_COMPARISON
          }
        >
          <Button
            className={lead?.archived ? 'cursor-disabled' : ''}
            onClick={
              !lead?.archived ? () => handleAddToCompare(data) : undefined
            }
            type="link"
          >
            {!addToCompare ? <CompareOutlinedIcon /> : <CompareFilledIcon />}
          </Button>
        </Tooltip>
        <Tooltip title={TEXT.ADD_INTERESTED_SERVICE}>
          <Button
            onClick={
              !lead?.archived
                ? () => handleAddToInterestedService(data)
                : undefined
            }
            className={
              lead?.archived
                ? 'cursor-disabled margin-right-3'
                : 'margin-right-3'
            }
            type="link"
          >
            <InterestedServiceOutlinedIcon />
          </Button>
        </Tooltip>
        <Button
          type="primary"
          icon={<PlusOutlined />}
          className={lead?.archived ? 'cursor-disabled' : ''}
          onClick={!lead?.archived ? () => handleStartButton(data) : undefined}
        >
          {TEXT.START_APPLICATION}
        </Button>
      </div>
    </>
  );
};

export { ProductSearchTable };
