import React, { useEffect, useState } from 'react';
import {
  CONFIRM_MESSAGE,
  DELETE_BTN,
  PAGE_HEADER_MENU_WORKFLOWS,
  URL_CRM_WORKFLOW_VIEW,
  WORKFLOW_TITLE,
} from '@moxie/constants';
import { PageHeaderTitle, popConfirm } from '@shared-components/elements';
import { WorkflowTableList } from './workflow-table-list';
import { useForm } from 'antd/lib/form/Form';
import { CreateWorkflowDrawer } from './create-workflow-drawer';
import { useDispatch } from 'react-redux';
import {
  serviceActions,
  workflowActions,
  workflowTypeActions,
} from '@crm/core';
import { IWorkflow } from '@shared-components/models';
import { addCRMWorkflow } from 'apps/crm/src/libs/services.api/lib/workflow.api';
import { useHistory, useParams } from 'react-router-dom';
import { PageHeaderMenu } from '../../shared';
import { crmErrorHandler } from '../../shared';

const WorkflowTable: React.FC = () => {
  const [form] = useForm();
  const [refreshTable, setRefreshTable] = useState(true);
  const [visible, setVisible] = useState(false);
  const [disabled, setDisabled] = useState(true);
  const [isEdit] = useState(false);

  const { serviceId }: { serviceId: string } = useParams();
  const dispatch = useDispatch();
  const history = useHistory();
  useEffect(() => {
    dispatch(serviceActions.getOneRequest(serviceId));
  }, []);

  useEffect(() => {
    dispatch(
      workflowTypeActions.getWorkflowTypesBYServiceIdRequest(serviceId!)
    );
  }, []);

  const triggerRefresh = () => setRefreshTable((prev) => !prev);

  const showDrawer = (id?: string) => {
    if (id) {
      history.push(`${URL_CRM_WORKFLOW_VIEW}/${id}`);
    }
    setVisible(true);
  };

  const handleClose = () => {
    setVisible(false);
    form.resetFields();
  };

  const handleFormSubmit = () => {
    form.submit();
    setDisabled(true);
  };

  const handleSubmit = async (data: IWorkflow) => {
    if (data) {
      try {
        const response = await addCRMWorkflow(data);
        history.push(`${URL_CRM_WORKFLOW_VIEW}/${response?.data?.data?.id}`);
      } catch (err) {
        crmErrorHandler(err);
      }
    }
    handleClose();
  };
  const handleFieldsChange = (allFields: string | any[]) => {
    for (const fields of allFields) {
      fields.errors.length <= 0 ? setDisabled(false) : setDisabled(true);
    }
  };

  const handleDelete = (id: string) => {
    popConfirm(DELETE_BTN, CONFIRM_MESSAGE.DELETE_WORKFLOW, () => {
      dispatch(workflowActions.deleteCRMWorkflowRequest(id, triggerRefresh));
    });
  };

  useEffect(() => {
    dispatch(workflowActions.clearCRMWorkflowFetch());
  }, []);

  return (
    <div className="full-height">
      <PageHeaderTitle title={WORKFLOW_TITLE} />
      <PageHeaderMenu menu={PAGE_HEADER_MENU_WORKFLOWS} />

      <CreateWorkflowDrawer
        form={form}
        isEdit={isEdit}
        visible={visible}
        disabled={disabled}
        setDisabled={setDisabled}
        onClose={handleClose}
        handleFormSubmit={handleFormSubmit}
        handleSubmit={handleSubmit}
        handleFieldsChange={handleFieldsChange}
      />

      <WorkflowTableList
        refreshTable={refreshTable}
        showDrawer={showDrawer}
        deleteData={handleDelete}
      />
    </div>
  );
};
export { WorkflowTable };
