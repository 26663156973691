import React, { useState, useEffect } from 'react';
import { Col, Collapse, Divider, Form, Row, Skeleton, Tooltip } from 'antd';
import {
  FormItem,
  Input,
  Option,
  PhoneInput,
  SearchableSelect,
} from 'libs/shared/src/shared';
import {
  ADDRESS1_LABEL,
  ADDRESS1_PLACEHOLDER,
  ADDRESS2_LABEL,
  ADDRESS2_PLACEHOLDER,
  ADDRESS_INFORMATION,
  ALTERNATIVE_PHONE_LABEL,
  ALTERNATIVE_PHONE_PLACEHOLDER,
  AUTH_MESSAGE,
  CHANGE_PASSWORD,
  CHANGE_TIMEZONE,
  CITY_OR_STATE_LABEL,
  CITY_OR_STATE_PLACEHOLDER,
  CONFIRM_PASSWORD,
  CONFIRM_PASSWORD_DIDNT_MATCH,
  CONTACT_INFORMATION,
  COUNTRIES,
  COUNTRY_LABEL,
  COUNTRY_PLACEHOLDER,
  EMAIL_LABEL,
  EMAIL_PLACEHOLDER,
  FIRST_NAME_LABEL,
  FIRST_NAME_PLACEHOLDER,
  FORM_SIZE,
  GUTTER,
  LAST_NAME_LABEL,
  LAST_NAME_PLACEHOLDER,
  NEW_PASSWORD,
  OLD_PASSWORD,
  PHONE_LABEL,
  PHONE_NUMBER_INVALID,
  PHONE_PLACEHOLDER,
  regex,
  TEXT,
  TIMEZONE,
  USER_PERSONAL_DETAIL,
} from '@moxie/constants';
import { errorHandler } from '@moxie/utils';
import {
  CaretRightOutlined,
  LoadingOutlined,
  InfoCircleOutlined,
  CheckCircleOutlined,
} from '@ant-design/icons';
import { useDispatch } from 'react-redux';
import moment from 'moment-timezone';
import { UserEmailCheck } from '@admin/services.api';
import { useAppSelector, userActions } from '@admin/core';
import {
  IGetPopupContainer,
  IUserFormAdd,
  IUserPersonalInformation,
} from '@shared-components/models';
import Select, { SelectValue } from 'antd/lib/select';
import { RootState } from 'apps/admin/src/core/store';

const { Panel } = Collapse;

const UserPersonalInfoForm: React.FC<IUserFormAdd> = ({
  onClose,
  form,
  isProfile,
  setDisabled,
  isEdit,
  formData,
  triggerRefresh,
  loading,
}: IUserFormAdd) => {
  const [countryCode, setCountryCode] = useState('');
  const [passwordCollapsePanel, setPasswordCollapsePanel] = useState(false);
  const [userEmail, setUserEmail] = useState('');
  const [emailFound, setEmailFound] = useState(false);

  const dispatch = useDispatch();
  const currentUser: any = useAppSelector(
    (store: RootState) => store.auth.user
  );

  const handleCountryChange = (input: string) => {
    COUNTRIES.find((item) => {
      if (item.name === input) {
        setCountryCode(item.dial_code);
      }
    });
  };

  const handleSubmit = async (data: IUserPersonalInformation) => {
    data.email = data?.email?.replace(/ /g, '');
    try {
      if (formData?.id) {
        data.role = formData.role_id;
        delete data.confirm_password;
        if (!passwordCollapsePanel) {
          delete data.new_password;
          delete data.old_password;
        }
        dispatch(
          userActions.updateUserRequest(
            { id: formData.id, ...data },
            triggerRefresh
          )
        );
      } else {
        dispatch(userActions.addUserRequest(data, triggerRefresh));
      }
      onClose();
    } catch (err) {
      errorHandler(err);
    } finally {
      form.resetFields();
    }
  };

  const handleOptionFilter = (input: string, option: any): boolean =>
    option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0;

  const handlePasswordCollapseChange = (data: string[] | string) => {
    if (Array.isArray(data)) {
      if (data.includes(CHANGE_PASSWORD)) {
        setPasswordCollapsePanel(true);
      } else setPasswordCollapsePanel(false);
    }
  };

  useEffect(() => {
    if (formData) {
      form.setFieldsValue(formData);
      handleCountryChange(formData.country);
    }
  }, [formData]);

  const onFieldsChange = (allFields: string | any[]) => {
    if (allFields.length > 0) {
      setDisabled(false);
    }
  };

  useEffect(() => {
    if (isEdit && formData) {
      form.setFieldsValue({
        time_zone: formData.time_zone,
      });
    } else {
      form.setFieldsValue({
        time_zone: currentUser?.time_zone,
      });
    }
  }, [formData, form, isEdit]);

  const checkEmailExists = async () => {
    try {
      const emailErr = form.getFieldError('email');
      const emailData = form.getFieldValue('email');
      if (
        (emailErr.length === 0 ||
          emailErr.includes(AUTH_MESSAGE.REGISTER_EMAIL_TAKEN)) &&
        emailData
      ) {
        const d = await UserEmailCheck(emailData);
        console;
        if (d.data?.success === true) {
          setEmailFound(true);
          form.setFields([
            {
              name: 'email',
              errors: [AUTH_MESSAGE.REGISTER_EMAIL_TAKEN],
            },
          ]);
          setDisabled(true);
        } else if (d.data?.success === false) {
          setEmailFound(false);
          form.setFields([
            {
              name: 'email',
              errors: [],
            },
          ]);
          setDisabled(false);
        }
      }
    } catch (err) {
      errorHandler(err);
    }
  };

  useEffect(() => {
    let userCheckTimeout: any;
    if (!isEdit) {
      userCheckTimeout = setTimeout(checkEmailExists, 300);
    }
    return () => clearTimeout(userCheckTimeout);
  }, [userEmail]);

  moment.tz.setDefault(currentUser?.time_zone);

  return loading ? (
    <Skeleton active />
  ) : (
    <Form
      data-testid="personal-details-form"
      layout="vertical"
      form={form}
      size={FORM_SIZE}
      onFinish={handleSubmit}
      onFieldsChange={onFieldsChange}
    >
      <div>
        <div className="form_section_header">{USER_PERSONAL_DETAIL}</div>
        <Row gutter={GUTTER}>
          <Col span="12">
            <FormItem
              name="first_name"
              label={FIRST_NAME_LABEL}
              validateFirst={true}
              rules={[
                { required: true },
                {
                  pattern: new RegExp(regex.ONLY_STRING),
                  message: AUTH_MESSAGE.INVALID_FIRST_NAME,
                  type: 'string',
                },
                {
                  max: 50,
                },
              ]}
            >
              <Input placeholder={FIRST_NAME_PLACEHOLDER} />
            </FormItem>
          </Col>
          <Col span="12">
            <FormItem
              name="last_name"
              label={LAST_NAME_LABEL}
              validateFirst={true}
              rules={[
                { required: true },
                {
                  pattern: new RegExp(regex.ONLY_STRING),
                  type: 'string',
                  message: AUTH_MESSAGE.INVALID_LAST_NAME,
                },
                { max: 50 },
              ]}
            >
              <Input placeholder={LAST_NAME_PLACEHOLDER} />
            </FormItem>
          </Col>
          <Divider />
        </Row>
      </div>

      <Collapse
        defaultActiveKey={ADDRESS_INFORMATION}
        bordered={false}
        ghost={true}
        expandIcon={({ isActive }) => (
          <CaretRightOutlined rotate={isActive ? 90 : 0} />
        )}
      >
        <Panel
          header={<span>{ADDRESS_INFORMATION}</span>}
          key={ADDRESS_INFORMATION}
        >
          <Row gutter={GUTTER}>
            <Col span="12">
              <FormItem
                name="address1"
                label={ADDRESS1_LABEL}
                rules={[{ required: true, type: 'string', max: 50 }]}
              >
                <Input placeholder={ADDRESS1_PLACEHOLDER} />
              </FormItem>
            </Col>
            <Col span="12">
              <FormItem
                name="address2"
                label={ADDRESS2_LABEL}
                rules={[{ type: 'string', max: 100 }]}
              >
                <Input placeholder={ADDRESS2_PLACEHOLDER} />
              </FormItem>
            </Col>
          </Row>
          <Row gutter={GUTTER}>
            <Col span="12">
              <div id="user_country_list" className="relative">
                <FormItem
                  name="country"
                  label={COUNTRY_LABEL}
                  rules={[{ required: true, type: 'string', max: 50 }]}
                >
                  <SearchableSelect
                    onChange={(value: SelectValue) => {
                      if (value) handleCountryChange(value.toString());
                    }}
                    placeholder={COUNTRY_PLACEHOLDER}
                    filterOption={handleOptionFilter}
                    getPopupContainer={(): IGetPopupContainer =>
                      document.getElementById('user_country_list')
                    }
                  >
                    {COUNTRIES.map((country) => {
                      return (
                        <Option value={country.name} key={country.code}>
                          {country.name}
                        </Option>
                      );
                    })}
                  </SearchableSelect>
                </FormItem>
              </div>
            </Col>
            <Col span="12">
              <FormItem
                name="city_or_state"
                label={CITY_OR_STATE_LABEL}
                rules={[{ required: true, type: 'string', max: 100 }]}
              >
                <Input placeholder={CITY_OR_STATE_PLACEHOLDER} />
              </FormItem>
            </Col>
          </Row>
        </Panel>
      </Collapse>
      <Divider />

      <Collapse
        defaultActiveKey={CONTACT_INFORMATION}
        bordered={false}
        ghost={true}
        expandIcon={({ isActive }) => (
          <CaretRightOutlined rotate={isActive ? 90 : 0} />
        )}
      >
        <Panel
          header={<span>{CONTACT_INFORMATION}</span>}
          key={CONTACT_INFORMATION}
        >
          <FormItem
            name="email"
            label={EMAIL_LABEL}
            normalize={(value) =>
              value.replace(
                /[A-Z]/,
                Function.prototype.call.bind(String.prototype.toLowerCase)
              )
            }
            validateFirst={true}
            rules={[
              {
                required: true,
                pattern: new RegExp(regex.EMAIL),
                type: 'email',
              },
              () => ({
                validator() {
                  if (emailFound) {
                    return Promise.reject(
                      new Error(AUTH_MESSAGE.REGISTER_EMAIL_TAKEN)
                    );
                  } else {
                    return Promise.resolve();
                  }
                },
              }),
            ]}
          >
            <Input
              onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                setUserEmail(e.target.value);
              }}
              placeholder={EMAIL_PLACEHOLDER}
              disabled={isEdit}
              autoComplete="off"
            />
          </FormItem>
          <Row gutter={GUTTER}>
            <Col span="12">
              <Form.Item
                validateFirst={true}
                name="phone"
                label={PHONE_LABEL}
                rules={[
                  { required: true },
                  {
                    pattern: new RegExp(regex.ONLY_NUMBER),
                    message: PHONE_NUMBER_INVALID,
                  },
                  { min: 6, max: 15 },
                ]}
              >
                <PhoneInput
                  type="string"
                  placeholder={PHONE_PLACEHOLDER}
                  dialCode={countryCode}
                  onChangeDialCode={handleCountryChange}
                />
              </Form.Item>
            </Col>
            <Col span="12">
              <FormItem
                name="alternative_phone"
                validateFirst={true}
                label={ALTERNATIVE_PHONE_LABEL}
                rules={[
                  {
                    pattern: new RegExp(regex.ONLY_NUMBER),
                    message: PHONE_NUMBER_INVALID,
                  },
                  { min: 6, max: 15 },
                ]}
              >
                <PhoneInput
                  type="string"
                  placeholder={ALTERNATIVE_PHONE_PLACEHOLDER}
                  dialCode={countryCode}
                  onChangeDialCode={handleCountryChange}
                />
              </FormItem>
            </Col>
          </Row>
        </Panel>
      </Collapse>
      <Divider />

      {isProfile && (
        <>
          <Collapse
            onChange={handlePasswordCollapseChange}
            bordered={false}
            ghost={true}
            expandIcon={({ isActive }) => (
              <CaretRightOutlined rotate={isActive ? 90 : 0} />
            )}
          >
            <Panel
              header={<span>{CHANGE_PASSWORD}</span>}
              key={CHANGE_PASSWORD}
            >
              <Row gutter={GUTTER}>
                <Col span="24">
                  <FormItem
                    name="old_password"
                    label={OLD_PASSWORD}
                    rules={[{ required: passwordCollapsePanel }]}
                  >
                    <Input />
                  </FormItem>
                </Col>
                <Col span="12">
                  <FormItem
                    name="new_password"
                    label={NEW_PASSWORD}
                    rules={[
                      {
                        required: passwordCollapsePanel,
                      },
                      { type: 'string', max: 100 },
                    ]}
                  >
                    <Input type="password" />
                  </FormItem>
                </Col>
                <Col span="12">
                  <FormItem
                    name="confirm_password"
                    label={CONFIRM_PASSWORD}
                    dependencies={['new_password']}
                    hasFeedback
                    rules={[
                      {
                        required: passwordCollapsePanel,
                      },
                      ({ getFieldValue }) => ({
                        validator(_, value) {
                          if (
                            !value ||
                            getFieldValue('new_password') === value
                          ) {
                            return Promise.resolve();
                          }
                          return Promise.reject(
                            new Error(CONFIRM_PASSWORD_DIDNT_MATCH)
                          );
                        },
                      }),
                    ]}
                  >
                    <Input type="password" />
                  </FormItem>
                </Col>
              </Row>
            </Panel>
          </Collapse>
          <Divider />
        </>
      )}

      <Collapse
        defaultActiveKey={CHANGE_TIMEZONE}
        bordered={false}
        ghost={true}
        expandIcon={({ isActive }) => (
          <CaretRightOutlined rotate={isActive ? 90 : 0} />
        )}
      >
        <Panel header={<span>{CHANGE_TIMEZONE}</span>} key={CHANGE_TIMEZONE}>
          <div id="user_timezone" className="relative">
            <Row gutter={GUTTER}>
              <Col span="24">
                <Form.Item
                  label="Timezone"
                  name="time_zone"
                  rules={[
                    { required: true, message: `${TEXT.TIMEZONE_SELECT}` },
                  ]}
                >
                  <Select
                    getPopupContainer={(): IGetPopupContainer =>
                      document.getElementById('user_timezone')
                    }
                  >
                    {TIMEZONE.map((data, idx) => {
                      return (
                        <Option value={data.utc[0]} key={idx}>
                          {data.text}
                        </Option>
                      );
                    })}
                  </Select>
                </Form.Item>
              </Col>
            </Row>
          </div>
        </Panel>
      </Collapse>
    </Form>
  );
};

UserPersonalInfoForm.defaultProps = {
  isProfile: false,
};
export { UserPersonalInfoForm };
