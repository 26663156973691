import { put, takeLatest } from 'redux-saga/effects';
import {
  IAction,
  IOtherTest,
  IOtherTestScore,
} from '@shared-components/models';
import { errorHandler } from '@moxie/utils';
import {
  getAllOtherTestScore as fetchOtherTestScore,
  updateOtherTestScore,
  createOtherTestScore,
} from '@lyra/services.api';
import { AxiosResponse } from 'axios';
import { successNotificationHandler } from '@moxie/shared';
import * as actionOtherTestScore from './other-test-score.action';
import * as actionTypes from './other-test-score.constant';
import { LEAD_OTHER_TEST_SCORES } from '@moxie/constants';
function* addOtherTestScore({ payload, lead, socket, user }: IAction<any>) {
  try {
    if (payload) {
      const res: AxiosResponse<any> = yield createOtherTestScore(payload);
      successNotificationHandler(res.data.message);
      socket?.emit('qualification-notification', {
        user_id: user?.id,
        parent_id: lead?.id,
        receiver_id: lead?.assignee_user_id,
        receivers_many: lead?.followers_id,
        profile_tab: 'education-background',
        type: 'other-test',
        update_type: 'added',
      });
      yield put(actionOtherTestScore.addOtherTestScoreSuccess(res.data.data));
    }
  } catch (e) {
    errorHandler(e);
    yield put(actionOtherTestScore.addOtherTestScoreFailure(e));
  }
}

function* editOtherTestScore({
  payload,
  lead,
  socket,
  user,
}: IAction<IOtherTest>) {
  try {
    if (payload) {
      const res: AxiosResponse<any> = yield updateOtherTestScore(payload);
      yield put(
        actionOtherTestScore.updateOtherTestScoreSuccess(res.data.data)
      );
      successNotificationHandler(res.data.message);
      socket?.emit('qualification-notification', {
        user_id: user?.id,
        parent_id: lead?.id,
        receiver_id: lead?.assignee_user_id,
        receivers_many: lead?.followers_id,
        profile_tab: 'education-background',
        type: 'other-test',
        update_type: 'updated',
      });
    }
  } catch (e) {
    errorHandler(e);
    yield put(actionOtherTestScore.updateOtherTestScoreFailure(e));
  }
}

function* deleteOtherTestScore({
  payload,
  lead,
  socket,
  user,
}: IAction<IOtherTestScore>) {
  if (payload) {
    try {
      const res: AxiosResponse<any> = yield updateOtherTestScore(payload);
      successNotificationHandler(LEAD_OTHER_TEST_SCORES.DELETE_SUCCESS);
      socket?.emit('qualification-notification', {
        user_id: user?.id,
        parent_id: lead?.id,
        receiver_id: lead?.assignee_user_id,
        receivers_many: lead?.followers_id,
        profile_tab: 'education-background',
        type: 'other-test',
        update_type: 'deleted',
      });
      yield put(
        actionOtherTestScore.deleteOtherTestScoreSuccess(res.data.data)
      );
    } catch (e) {
      errorHandler(e);
      yield put(actionOtherTestScore.deleteOtherTestScoreFailure(e));
    }
  }
}

function* getAllOtherTestScore({ payload }: IAction<string>) {
  try {
    const res: AxiosResponse<any> = yield fetchOtherTestScore(payload);
    yield put(actionOtherTestScore.getOtherTestScoresSuccess(res.data.data));
  } catch (e) {
    errorHandler(e);
    yield put(actionOtherTestScore.getOtherTestScoresFailure(e));
  }
}

export default function* rootSagas(): unknown {
  yield takeLatest(
    actionTypes.UPDATE_OTHER_TEST_SCORE_REQUEST,
    editOtherTestScore
  );
  yield takeLatest(
    actionTypes.GET_OTHER_TEST_SCORES_REQUEST,
    getAllOtherTestScore
  );
  yield takeLatest(
    actionTypes.ADD_OTHER_TEST_SCORES_REQUEST,
    addOtherTestScore
  );
  yield takeLatest(
    actionTypes.DELETE_OTHER_TEST_SCORE_REQUEST,
    deleteOtherTestScore
  );
}
