import React, { useState } from 'react';
import { useHistory } from 'react-router-dom';
import { Card, Typography } from 'antd';
import { ApplyIMSLogoDark, successNotificationHandler } from '@moxie/shared';
import { useQuery } from '@moxie/utils';
import { Auth as AuthForm } from '@shared-components/forms';
import { IAuth, IAuthLogin } from '@shared-components/models';

import { AUTH_TITLE, MSG_PASSWORD_UPDATED } from '@moxie/constants';
import { CRMResetPassword } from '@crm/services.api';
import { crmErrorHandler } from '../../crm-modules/shared';

const { Title, Text } = Typography;

const CrmAuth: React.FC<IAuthLogin> = () => {
  const [loading, setLoading] = useState(false);
  const query = useQuery();
  const history = useHistory();
  const [token] = useState(query.get('token'));

  const handleSubmit = async (data: IAuth) => {
    setLoading(true);
    data.token = token;
    try {
      await CRMResetPassword(data)
      successNotificationHandler(MSG_PASSWORD_UPDATED);
      history.push('/');
    } catch (err) {
      crmErrorHandler(err);
    } finally {
      setLoading(false);
    }
  };

  return (
    <section className={`auth-layout auth-layout--login`}>
      <div className="auth-layout__card">
        <Card className="padding-3">
          <div className="padding-top-2 padding-bottom-2 text-align-center">
            <div className="card-logo-title-container">
              <ApplyIMSLogoDark />
              <Text className="card-logo-title">{"CRM"}</Text>
            </div>
            <Title level={3}>{AUTH_TITLE}</Title>
          </div>
          <AuthForm onSubmit={handleSubmit} loading={loading} />
        </Card>
      </div>
    </section>
  );
};
export { CrmAuth };
