import axios from 'axios';
import { ILogin, ILoginResponse } from '@shared-components/models';
import { API_URL } from '@moxie/constants';

const login = (body: ILogin): Promise<ILoginResponse> => {
  return axios.post(`${process.env.NX_BASE_URL}/am${API_URL.LOGIN}`,body, {
    headers: {
      tenant: process.env.NX_DEV_TENANT_URL
    }
  });
};

export { login };
