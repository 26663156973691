/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from 'react';
import { Tabs, Typography } from 'antd';
import { useParams } from 'react-router-dom';
import SwiperCore, {
  Navigation,
  Pagination,
  Scrollbar,
  A11y,
  Mousewheel,
} from 'swiper';
import {
  MY_PROFILE_HEADERS,
  SLUG_CLIENTS,
  SLUG_KPI,
  TAB_POSITION,
} from '@moxie/constants';
import { ILeadProfileSection } from '@shared-components/models';
import { UserMyClientTable } from './user-my-clients-table';

SwiperCore.use([Navigation, Pagination, Scrollbar, A11y, Mousewheel]);

const UserTabSection: React.FC<ILeadProfileSection> = () => {
  const { TabPane } = Tabs;
  const { slug } = useParams<{ slug: string }>();

  const [activeKey, setActiveKey] = useState(slug);

  const tabContent = (key: string) => {
    switch (key) {
      case SLUG_CLIENTS:
        return (
          <div className="padding-1">
            <UserMyClientTable />
          </div>
        );
      case SLUG_KPI:
        return <div>...</div>;
      default:
        return;
    }
  };

  const setDefaultActiveKey = () => {
    const selected = MY_PROFILE_HEADERS.find((item) => item.slug === slug);
    selected?.slug && setActiveKey(selected?.slug);
  };

  useEffect(() => {
    setDefaultActiveKey();
  }, [slug]);

  return (
    <section className="lead_content">
      <div className="pro">
        <Tabs activeKey={activeKey} tabPosition={TAB_POSITION}>
          {[...Array.from(MY_PROFILE_HEADERS)].map((item) => {
            return (
              <TabPane
                tab={<Typography.Text strong>{item.name}</Typography.Text>}
                key={item.slug}
              >
                {tabContent(item.slug)}
              </TabPane>
            );
          })}
        </Tabs>
      </div>
    </section>
  );
};

export { UserTabSection };
