import React from 'react';
import { Avatar, Col, Typography, Row } from 'antd';
import { BankOutlined } from '@ant-design/icons';
import { TEXT } from '@moxie/constants';
const { Text } = Typography;

interface IInstituteCardProps {
  companyName: string;
  branchName: string;
}
const InstituteCard: React.FC<IInstituteCardProps> = ({
  companyName,
  branchName,
}: IInstituteCardProps) => {
  return (
    <Row className="lead-table__name user-name-card">
      <Col className="align-items-center user-name-card__avatar">
        <Avatar icon={<BankOutlined />} />
      </Col>
      <Col className="user-name-card__name">
        <div className="user-name-card__name_span">
          <Col>
            <Text title={companyName}>{companyName}</Text>
          </Col>
          <Col>
            <Text>
              <small>
                {branchName && branchName?.length > 0
                  ? branchName
                  : TEXT.NOT_AVAILABLE}
              </small>
            </Text>
          </Col>
        </div>
      </Col>
    </Row>
  );
};

export { InstituteCard };
