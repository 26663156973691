import React from 'react';
import { Col, Row } from 'antd';
import { UnassignedCard, UserNameCard } from '@shared-components/elements';
import { GUTTER, TEXT } from '@moxie/constants';
import { TableAssignee } from './lead-column-edit';
import { LeadFollowersSection } from './lead-followers-section';
import { ILeadProfileAssignedTo } from '@shared-components/models';


const LeadProfileAssignee: React.FC<ILeadProfileAssignedTo> = ({
  editFollowersMode,
  editMode,
  assignee,
  enableEdit,
  handleSubmit,
  handleCancel,
  followers,
  followers_id,
  toggleEditFollowersMode,
  handleFollowersSubmit,
  users,
  archived,
}: ILeadProfileAssignedTo) => {

  return (
    <Row gutter={GUTTER} justify="space-between">
      <Col span={editFollowersMode ? 10 : 12}>
        <div className="profile__info__item profile__added">
          <div className="profile__header">
            <h4 className="profile__title padding-left-1">
              {TEXT.ASSIGNED_TO}:
            </h4>
          </div>
          <div className="profile__user">
            {!editMode &&
              (assignee ? (
                <div className="profile__user_detail">
                  <div
                    className={archived ? 'cursor-disabled' : 'cursor-pointer'}
                    onClick={!archived ? enableEdit : undefined}
                  >
                    <UserNameCard
                      firstName={assignee?.first_name || ''}
                      lastName={assignee?.last_name || ''}
                      email={assignee?.email || ''}
                    />
                  </div>
                </div>
              ) : (
                <div
                  className={archived ? 'cursor-disabled' : 'cursor-pointer'}
                  onClick={!archived ? enableEdit : undefined}
                >
                  <UnassignedCard />
                </div>
              ))}
          </div>

          {
            editMode && (
              <TableAssignee
                archived={archived}
                handleSubmit={handleSubmit}
                handleCancel={handleCancel}
                assigneeId={assignee?.id}
              />
            )}
        </div>
      </Col>

      <Col
        sm={{ span: 22 }}
        md={{ span: 22 }}
        xl={{ span: 12 }}
        className="profile__followersSection"
      >
        <LeadFollowersSection
          followers={followers}
          followersId={followers_id}
          editFollowersMode={editFollowersMode}
          setEditFollowersMode={toggleEditFollowersMode}
          onSubmit={handleFollowersSubmit}
          users={users}
          archived={archived}
        />
      </Col>
    </Row>
  );
};

export { LeadProfileAssignee };
