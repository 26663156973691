import { capitalizeFirstLetter } from '@moxie/shared';
import { Collapse } from 'antd';
import moment from 'moment';
import React from 'react';
import { EnquiryAssigneeActivity } from './EnquiryAssigneeActivity';
import { EnquiryRatingActivity } from './EnquiryRatingActivity';
import { EnquiryInterestedServicesActivity } from './EnquiryInterestedServicesActivity';
import { EnquiryPersonalInformationActivity } from './EnquiryPersonalInformationActivity';
import { EnquiryEditActivity } from './EnquiryEditActivity';
import { EnquiryStatusActivity } from './EnquiryStageActivity';

const { Panel } = Collapse;

interface Props {
  activitiesAction: string;
  assignedUser: any;
  createdAt: string;
  data: any;
}

export const EnquiryActivityPanels: React.FC<Props> = ({
  assignedUser,
  createdAt,
  data,
}) => {
  const name = assignedUser
    ? `${capitalizeFirstLetter(assignedUser?.firstName)} ${capitalizeFirstLetter(assignedUser?.lastName)}`
    : 'undefined';

  return (
    <>
      {data ? (
        <>
          {data.assignees && (
            <EnquiryAssigneeActivity name={name} createdAt={createdAt} data={data} />
          )}
          {data.currentStatus && (
            <EnquiryStatusActivity name={name} createdAt={createdAt} data={data} />
          )}
          {data.currentRating !== null && data.currentRating !== undefined && (
            <EnquiryRatingActivity name={name} createdAt={createdAt} data={data} />
          )}
          {data.interestedServices && (
            <EnquiryInterestedServicesActivity name={name} createdAt={createdAt} data={data} />
          )}
          {data.currentPersonalInformation && (
            <EnquiryPersonalInformationActivity name={name} createdAt={createdAt} data={data} />
          )}
          {data.currentData && (
            <EnquiryEditActivity name={name} createdAt={createdAt} data={data} />
          )}
        </>
      ) : (
        <Panel
          style={{ paddingLeft: 20, padding: 15, background: 'none' }}
          key={1}
          header={
            <div className="enquiry-info">
              <p className="enquiry-author">Some data has been changed.</p>
              <span className="enquiry-date">
                <div>{moment(createdAt).format('dddd, MMMM D, YYYY')}</div>
                <div>by {name}</div>
              </span>
            </div>
          }
        />
      )}
    </>
  );
};
