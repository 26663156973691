import { AxiosResponse } from 'axios';
import { errorHandler } from '@moxie/utils';
import { put, takeLatest } from 'redux-saga/effects';
import * as countryService from './country-service.constant';
import * as countryServiceActions from './country-service.action';
import { fetchServiceCountry } from '../../libs/services.api/lib';

export function* countryByService({ payload, triggerRefresh }: any) {
  try {
    if (payload) {
      const response: AxiosResponse = yield fetchServiceCountry(payload);
      if(triggerRefresh) {
        triggerRefresh(response?.data);
      }
      yield put(
        countryServiceActions.getCountryByServiceSuccess(response?.data)
      );
    }
  } catch (e) {
    errorHandler(e);
    yield put(countryServiceActions.getCountryByServiceFailure(e));
  }
}

export default function* countryByServiceSaga(): unknown {
  yield takeLatest(
    countryService.GET_COUNTRY_BY_SERVICE_REQUEST,
    countryByService
  );
}
