export const socketSagaActions = {
  SET_SOCKET_CONNECTION: 'SET_SOCKET_CONNECTION',
  REMOVE_SOCKET_CONNECTION: 'REMOVE_SOCKET_CONNECTION',
};

export const leadActivitiesSagaActions = {
  FETCH_LEAD_ACTIVITIES: 'FETCH_LEAD_ACTIVITIES',
  SET_LEAD_ACTIVITIES: 'SET_LEAD_ACTIVITIES',
  CLEAR_LEAD_ACTIVITIES: 'CLEAR_LEAD_ACTIVITIES',
};

export const productFeesSagaActions = {
  FETCH_PRODUCT_FEES_ACTIVITIES: 'FETCH_PRODUCT_FEES_ACTIVITIES',
  SET_PRODUCT_FEES_ACTIVITIES: 'SET_PRODUCT_FEES_ACTIVITIES',
  CLEAR_PRODUCT_FEES_ACTIVITIES: 'CLEAR_PRODUCT_FEES_ACTIVITIES',
};

export const subjectDisciplineSagaActions = {
  FETCH_SUBJECT_DISCIPLINE: 'FETCH_SUBJECT_DISCIPLINE',
  SET_SUBJECT_DISCIPLINE: 'SET_SUBJECT_DISCIPLINE',
  CLEAR_SUBJECT_DISCIPLINE: 'CLEAR_SUBJECT_DISCIPLINES',
};
