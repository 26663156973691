import React, { useEffect, useState } from 'react';
import { Route, Redirect, RouteProps } from 'react-router-dom';
import Cookies from 'js-cookie';
import { CRM_COOKIE_ACCESS } from '@moxie/constants';
import { Spin } from 'antd';
import { CrmDashboardLayout } from '@crm/dashboard';
import { useDispatch } from 'react-redux';
import { authActions, useAppSelector } from '@crm/core';
import * as Sentry from '@sentry/react';
import { CustomErrorBoundary } from "@crm/src/shared/error-boundary";
import { LoaderComponent } from '@crm/src/shared/loader';

const SentryRoute = Sentry.withSentryRouting(Route);
const CrmProtectedRoute = ({
  component: Component,
  ...rest
}: RouteProps | any): any => {
  const [access] = useState(Cookies.get(`${CRM_COOKIE_ACCESS}`));
  const dispatch = useDispatch()
  const { loading, user } = useAppSelector(store => store.auth);

  useEffect(() => {
    if (!user) {
      dispatch(authActions.getAuthUser())
    }
  }, [dispatch, user]);

  return (
    <SentryRoute
      {...rest}
      render={(props) => {
        if (loading) {
          return (<LoaderComponent />)
        }
        if (!access) {
          return <Redirect to="/" />
        }

        return (
          <CrmDashboardLayout>
            <CustomErrorBoundary>
              <React.Suspense
                fallback={
                  <>
                    <LoaderComponent />
                  </>
                }
              >
                <div className="body-container">
                  <Component {...props} />
                </div>
              </React.Suspense>

            </CustomErrorBoundary>
          </CrmDashboardLayout>
        )
      }

      }
    />
  );
};

export { CrmProtectedRoute };
