export const FETCH_SERVICE_REQUEST = 'FETCH_SERVICE_REQUEST';
export const FETCH_SERVICE = 'FETCH_SERVICE';
export const FETCH_SERVICE_WORKFLOW_REQUEST = 'FETCH_SERVICE_WORKFLOW_REQUEST';
export const FETCH_SERVICE_WORKFLOW = 'FETCH_SERVICE_WORKFLOW';
export const FETCH_SERVICE_COUNTRIES_REQUEST =
  'FETCH_SERVICE_COUNTRIES_REQUEST';
export const FETCH_SERVICE_INSTITUTION = 'FETCH_SERVICE_INSTITUTION';
export const FETCH_SERVICE_INSTITUTION_REQUEST =
  'FETCH_SERVICE_INSTITUTION_REQUEST';
export const FETCH_EDUCATION_LEVEL = 'FETCH_EDUCATION_LEVEL';
export const RESET_EDUCATION_LEVEL = 'RESET_EDUCATION_LEVEL';
export const FETCH_EDUCATION_LEVEL_REQUEST = 'FETCH_EDUCATION_LEVEL_REQUEST';
export const FETCH_SERVICE_COUNTRIES = 'FETCH_SERVICE_COUNTRIES_WORKFLOW';
export const LOADING_SERVICE = 'LOADING_SERVICE';
export const IDLE_SERVICE = 'IDLE SERVICE';
export const SUCCEEDED_SERVICE = 'SUCCEEDED_SERVICE';
export const FAILED_SERVICE = 'FAILED_SERVICE';
export const SET_ERROR = 'SET_ERROR';
export const RESET_SERVICE = 'RESET_SERVICE_REQUEST';
export const RESET_WORKFLOW = 'RESET_SERVICE_WORKFLOW_REQUEST';
export const RESET_COUNTRY = 'RESET_SERVICE_WORKFLOW_COUNTRY_REQUEST';
