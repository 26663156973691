import { put, takeLatest } from 'redux-saga/effects';
import { IAction, ITasks, ITasksPayload } from '@shared-components/models';
import { errorHandler } from '@moxie/utils';
import {
  updateTasks,
  getAllTasks as fetchTasksList,
  deleteTasks as removeTasks,
  updateTaskStatus,
  updateTaskAssignee,
} from '../../libs/services.api/lib/tasks.api';
import { AxiosResponse } from 'axios';
import { TASKS_RESPONSE_MESSAGE, STATUS } from '@moxie/constants';

import * as actionTasks from './tasks.action';
import * as actionTypes from './tasks.constant';
import { successNotificationHandler } from '@moxie/shared';
import { addTasks } from '../../libs/services.api/lib/tasks.api';
import { sendReminder } from '../../libs/services.api/lib/email.api';
import { UserData } from '@lyra/services.api';

function* createTasks({ payload, triggerRefresh }: IAction<any>) {
  try {
    if (payload) {
      const res: AxiosResponse<any> = yield addTasks(payload);
      yield put(actionTasks.addTasksSuccess(res.data.data));
      successNotificationHandler(TASKS_RESPONSE_MESSAGE.ADD_TASK);
      triggerRefresh && triggerRefresh();
      if (
        res?.data?.data?.assignee_id &&
        res?.data?.data?.category === 'reminder'
      ) {
        const assigneeRes: AxiosResponse<any> = yield UserData(
          res?.data?.data?.assignee_id
        );
        yield sendReminder(assigneeRes?.data?.data, res.data?.data);
      }
    }
  } catch (err) {
    errorHandler(err);
    yield put(actionTasks.addTasksFailure(err));
  }
}
function* updateStatus({ payload }: IAction<any>) {
  try {
    if (payload) {
      const res: AxiosResponse<any> = yield updateTaskStatus(
        payload.id,
        payload.status
      );
      yield put(actionTasks.updateTaskStatusSuccess(res.data.data));
      successNotificationHandler(res.data.message);
    }
  } catch (err) {
    errorHandler(err);
    yield put(actionTasks.updateTaskStatusFailure(err));
  }
}

function* updateAssignee({ payload }: IAction<any>) {
  try {
    if (payload) {
      const res: AxiosResponse<any> = yield updateTaskAssignee(payload);
      yield put(actionTasks.updateTaskAssigneeSuccess(res.data.data));
      successNotificationHandler(TASKS_RESPONSE_MESSAGE.UPDATE_TASK);
    }
  } catch (err) {
    errorHandler(err);
    yield put(actionTasks.updateTaskAssigneeFailure(err));
  }
}

function* editTasks({ payload, triggerRefresh }: IAction<ITasks>) {
  try {
    if (payload) {
      const response: AxiosResponse<any> = yield updateTasks(payload);
      yield put(actionTasks.editTasksSuccess(response.data.data));
      successNotificationHandler(TASKS_RESPONSE_MESSAGE.UPDATE_TASK);
      triggerRefresh && triggerRefresh();
    }
  } catch (err) {
    errorHandler(err);
    yield put(actionTasks.editTasksFailure(err));
  }
}

function* deleteTasks({ payload, triggerRefresh }: IAction<ITasksPayload>) {
  try {
    if (!payload) return;

    yield removeTasks(payload);
    yield put(actionTasks.deleteTasksSuccess(payload));
    successNotificationHandler(TASKS_RESPONSE_MESSAGE.DELETE_TASK);
    triggerRefresh && triggerRefresh();
  } catch (err) {
    errorHandler(err);
    yield put(actionTasks.deleteTasksFailure(err));
  }
}

function* getAllTasks({ payload }: IAction<string>) {
  try {
    const res: AxiosResponse<any> = yield fetchTasksList(payload);
    yield put(actionTasks.getTasksSuccess(res.data.data));
  } catch (e) {
    yield put(actionTasks.getTasksFailure(STATUS.FAILED));
    errorHandler(e);
  }
}

export default function* rootSagas(): unknown {
  yield takeLatest(actionTypes.UPDATE_TASK_REQUEST, editTasks);
  yield takeLatest(actionTypes.UPDATE_TASK_STATUS_REQUEST, updateStatus);
  yield takeLatest(actionTypes.UPDATE_TASK_ASSIGNEE_REQUEST, updateAssignee);
  yield takeLatest(actionTypes.GET_TASKS_REQUEST, getAllTasks);
  yield takeLatest(actionTypes.ADD_TASK_REQUEST, createTasks);
  yield takeLatest(actionTypes.DELETE_TASK_REQUEST, deleteTasks);
}
