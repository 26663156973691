import React from 'react';
import { Image } from 'antd';
import Title from 'antd/lib/typography/Title';
import { PROJECT_PORTAL } from '@moxie/constants';

interface ILogo {
  mode?: 'dark' | 'light';
  portal?: string;
}

export const LOGO_LYRA_LIGHT = './assets/logo-light.png';
export const LOGO_LYRA_DARK = './assets/logo-dark.png';

//TODO: Change logo path of admin
export const LOGO_ADMIN_LIGHT = './assets/logo-light.png';
export const LOGO_ADMIN_DARK = './assets/logo-dark.png';

//TODO: Change logo path of luzon
export const LOGO_LUZON_LIGHT = './assets/logo-light.png';
export const LOGO_LUZON_DARK = './assets/logo-dark.png';

export const LogoIconLight = () => {
  return <img src={LOGO_LYRA_LIGHT} alt={PROJECT_PORTAL.AGENT_TITLE} />;
};

export const AdminLogoIconLight = () => {
  return <img src={LOGO_ADMIN_LIGHT} alt={PROJECT_PORTAL.AGENT} />;
};

const Logo: React.FC<ILogo> = ({ mode, portal }: ILogo) => {
  let src;
  if (mode === 'light') {
    src =
      portal === PROJECT_PORTAL.REGISTRATION
        ? LOGO_LUZON_LIGHT
        : portal === PROJECT_PORTAL.ADMIN
        ? LOGO_ADMIN_LIGHT
        : LOGO_LYRA_LIGHT;
  } else {
    src =
      portal === PROJECT_PORTAL.REGISTRATION
        ? LOGO_LUZON_DARK
        : portal === PROJECT_PORTAL.ADMIN
        ? LOGO_ADMIN_DARK
        : LOGO_LYRA_DARK;
  }

  return (
    <div className="logo-div">
      <Image width={60} height="auto" src={src} preview={false} />
      <Title
        level={2}
        style={{
          display: 'inline',
          color: `${mode === 'light' ? 'white' : 'black'}`,
        }}
      >
        {portal}
      </Title>
    </div>
  );
};

Logo.defaultProps = {
  mode: 'light',
};

export { Logo };
