/* eslint-disable no-unused-vars */

import React, { useState, useEffect } from 'react';
import { Button, popConfirm } from '@shared-components/elements';
import { Col, Empty, Row, Spin, Typography } from 'antd';
import { PlusOutlined } from '@ant-design/icons';
import {
  IProfessionalExperience,
  IProfessionalExperienceProps,
} from '@shared-components/models';
import { ADD_BTN, GUTTER, DELETE_BTN, CONFIRM_MESSAGE } from '@moxie/constants';
import { ProfessionalExperienceAddEdit } from '../../professional-experience-add-edit';
import { ProfessionalExperienceCard } from 'libs/shared/src/elements/professional-experience-card';
import { capitalizeFirstLetter } from 'libs/shared/src/functions';
import { useDispatch } from 'react-redux';
import moment from 'moment-timezone';
import { DatePeriod } from 'libs/shared/src/elements/date-period/date-period';
import { professionalExperienceActions, useAppSelector } from '@lyra/core';
import Can, { LeadsEntity, PermissionAction } from '@lyra/privilege';

const ProfessionalExperience: React.FC<IProfessionalExperienceProps> = ({
  id,
  lead,
}: IProfessionalExperienceProps) => {
  const dispatch = useDispatch();

  const { professionalExperienceList, loading, socket, user } = useAppSelector(
    (store) => ({
      professionalExperienceList:
        store.professional_experience.professionalExperienceList,
      loading: store.professional_experience.loading,
      socket: store.socket.wss,
      user: store.auth.user,
    })
  );
  useEffect(() => {
    if (id) {
      const params = `?parent_id=${id}`;
      dispatch(
        professionalExperienceActions.getProfessionalExperiencesRequest(params)
      );
    }
    return () => {
      dispatch(professionalExperienceActions.getProfessionalExperiencesClear());
    };
  }, [id]);

  const [isDrawerOpen, setDrawer] = useState(false);
  const [disabled, setDisabled] = useState(true);
  const [
    professionalExperienceData,
    setProfessionalExperience,
  ] = useState<IProfessionalExperience>();

  const handleDrawerClose = () => {
    setDrawer(false);
    setDisabled(true);
  };

  const handleDrawerOpen = () => {
    setDrawer(true);
  };
  const handleEdit = (data: IProfessionalExperience) => {
    setProfessionalExperience(data);
    handleDrawerOpen();
  };

  const handleDelete = (data: IProfessionalExperience) => {
    popConfirm(
      DELETE_BTN,
      `${CONFIRM_MESSAGE.DELETE_PROFESSIONAL_EXPERIENCE}`,
      () => {
        dispatch(
          professionalExperienceActions.deleteProfessionalExperienceRequest(
            data.id,
            lead,
            socket,
            user
          )
        );
      }
    );
  };

  const handleProfessionalExperienceAdd = (event?: any) => {
    setProfessionalExperience(undefined);
    handleDrawerOpen();
    event?.stopPropagation();
  };

  return (
    <Spin spinning={loading}>
      <div className="padding-right-2 padding-left-2">
        <div className="slider_content ">
          {/* {
            // Can(
            //   PermissionAction.Create,
            //   new LeadsEntity({
            //     assignee_id: lead?.assignee_id,
            //     followers: lead?.followers?.map((item: any) => item.id),
            //   })
          // ) && */}
          {!lead?.archived && (
            <Button
              type="primary"
              icon={<PlusOutlined />}
              onClick={handleProfessionalExperienceAdd}
            >
              {ADD_BTN}
            </Button>
          )}

        </div>

        <Row gutter={GUTTER} className="height-full">
          {professionalExperienceList.length === 0 && (
            <div className="align-contents-center">
              <Empty image={Empty.PRESENTED_IMAGE_SIMPLE} />
            </div>
          )}
          {professionalExperienceList &&
            professionalExperienceList.map((item) => {
              return (
                <Col
                  key={item.id}
                  md={{ span: 12 }}
                  lg={{ span: 8 }}
                  className="padding-bottom-2"
                >
                  <ProfessionalExperienceCard
                    lead={lead}
                    onDelete={() => handleDelete(item)}
                    onEdit={() => handleEdit(item)}
                    companyName={capitalizeFirstLetter(
                      item.organization_name || ''
                    )}
                    employmentType={item.job_type || ''}
                    title={capitalizeFirstLetter(item.job_title || '')}
                  >
                    <Row>
                      <Col span={24}>
                        <Row
                          justify="space-between"
                          className="professional-experience"
                        >
                          <Col className="professional-experience__date">
                            <Typography.Text strong>
                              {moment(item?.start_date).format('MMMM  YYYY')} to{' '}
                              {item.end_date
                                ? moment(item?.end_date).format('MMMM  YYYY')
                                : 'Present'}
                            </Typography.Text>
                            <Typography.Text
                              type="secondary"
                              className=""
                              strong
                            >
                              {
                                <DatePeriod
                                  startDate={item?.start_date}
                                  endDate={item?.end_date}
                                />
                              }
                            </Typography.Text>
                          </Col>
                          <Col>
                            <Typography.Text
                              className="professional-experience__location"
                              strong
                            >
                              {item.city} {item.city && item.country && ', '}
                              {item.country}
                              {!item.city && !item.country && '-'}
                            </Typography.Text>
                          </Col>
                        </Row>
                      </Col>
                    </Row>
                  </ProfessionalExperienceCard>
                </Col>
              );
            })}
        </Row>

        <ProfessionalExperienceAddEdit
          id={id}
          initialData={professionalExperienceData}
          isOpen={isDrawerOpen}
          onClose={handleDrawerClose}
          disabled={disabled}
          setDisabled={setDisabled}
        />
      </div>
    </Spin>
  );
};

export { ProfessionalExperience };
