import React from 'react';
import { Drawer, DrawerProps } from 'antd';

const DrawerElem: React.FC<DrawerProps> = ({
  title,
  width,
  visible,
  onClose,
  ...props
}: DrawerProps) => {
  return (
    <Drawer
      destroyOnClose={true}
      maskClosable={false}
      className="ant-drawer"
      title={title}
      width={width}
      onClose={onClose}
      visible={visible}
      {...props}
    />
  );
};

export { DrawerElem };
