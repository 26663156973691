import { postAmRegistration, postRegistration } from '@luzon/services.api';
import { successNotificationHandler } from '@moxie/shared';
import { errorHandler } from '@moxie/utils';
import { IAction } from '@shared-components/models';
import { AxiosResponse } from 'axios';
import { put, takeLatest } from 'redux-saga/effects';
import {
  deleteCompany,
  disableCompany,
  getAllTenants,
  getOneTenant,
  updateCompany,
} from '../../libs/services.api/lib/tenant.api';
import * as action from './tenant.action';
import * as actionTypes from './tenant.constant';

const getTenants = function* () {
  try {
    const res: AxiosResponse<any> = yield getAllTenants('');
    yield put(action.fetchTenantsSuccess(res?.data?.data));
  } catch (e) {
    errorHandler(e);
    yield put(action.fetchTenantsFailure(e));
  }
};

const getTenant = function* ({ payload }: IAction<string>) {
  if (payload) {
    try {
      const res: AxiosResponse = yield getOneTenant(payload);
      yield put(action.fetchTenantSuccess(res.data.data));
    } catch (e) {
      errorHandler(e);
      yield put(action.fetchTenantFailure(e));
    }
  }
};

const addTenant = function* ({
  payload,
  onClose,
  triggerRefresh,
}: IAction<any>) {
  try {
    let response: AxiosResponse;
    if (payload) {
      if (payload.feature === 'marketing') {
        response = yield postAmRegistration(payload);
        yield put(action.addTenantSuccess(response?.data?.data));
      } else {
        response = yield postRegistration(payload);
        yield put(action.addTenantSuccess(response.data.data));
      }

      if (response) {
        onClose && onClose();
        successNotificationHandler(response.data.message);
        triggerRefresh && triggerRefresh();
      }
    }
  } catch (err) {
    errorHandler(err);
    yield put(action.addTenantFailure(err));
  }
};

const editTenant = function* ({
  id,
  payload,
  onClose,
  triggerRefresh,
}: IAction<any>) {
  try {
    const response: AxiosResponse = yield updateCompany(id || '', payload);
    yield put(action.updateTenantSuccess(response.data.data));
    if (response) {
      onClose && onClose();
      successNotificationHandler(response.data.message);
      triggerRefresh && triggerRefresh();
    }
  } catch (err) {
    errorHandler(err);
    yield put(action.updateTenantFailure(err));
  }
};

const disableTenant = function* ({
  id,
  payload,
  triggerRefresh,
}: IAction<any>) {
  try {
    const response: AxiosResponse = yield disableCompany(id || '', payload);
    yield put(action.disableTenantSuccess(response?.data?.data));
    if (response) {
      successNotificationHandler(response.data.message);
      triggerRefresh && triggerRefresh();
    }
  } catch (err) {
    errorHandler(err);
    yield put(action.updateTenantFailure(err));
  }
};

const deleteTenant = function* ({ payload, triggerRefresh }: IAction<any>) {
  try {
    const response: AxiosResponse = yield deleteCompany(payload);
    yield put(action.deleteTenantSuccess(response?.data?.data));
    if (response) {
      successNotificationHandler(response.data.message);
      triggerRefresh && triggerRefresh();
    }
  } catch (err) {
    errorHandler(err);
    yield put(action.deleteTenantFailure(err));
  }
};

export default function* rootSage(): unknown {
  yield takeLatest(actionTypes.GET_TENANTS_REQUEST, getTenants);
  yield takeLatest(actionTypes.GET_TENANT_REQUEST, getTenant);
  yield takeLatest(actionTypes.ADD_TENANT_REQUEST, addTenant);
  yield takeLatest(actionTypes.UPDATE_TENANT_REQUEST, editTenant);
  yield takeLatest(actionTypes.DISABLE_TENANT_REQUEST, disableTenant);
  yield takeLatest(actionTypes.DELETE_TENANT_REQUEST, deleteTenant);
}
