import React from 'react';
import Icon from '@ant-design/icons';

const InterestedServiceOutlined = () => (
  <svg
    width="25"
    height="32"
    viewBox="0 0 48 47"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M41.3457 19.7804V19.7804H41.3559C42.1569 19.7804 42.8072 19.1302 42.8072 18.3291V18.1095C42.8072 17.3958 42.2412 16.6472 41.3559 16.6472H38.2497V13.0943C38.2497 12.6663 38.0458 12.3434 37.8422 12.1506C37.6633 11.9812 37.4773 11.9001 37.3803 11.8633C37.1841 11.7889 36.999 11.7676 36.914 11.7592C36.7391 11.742 36.5461 11.7445 36.5065 11.745C36.503 11.7451 36.5007 11.7451 36.4997 11.7451C36.006 11.7451 35.6332 11.9915 35.4158 12.2624C35.3192 12.3827 35.2591 12.4986 35.2221 12.5846C35.203 12.6289 35.1883 12.6695 35.1769 12.7046C35.1712 12.7222 35.1662 12.7389 35.1618 12.7543L35.1558 12.7767L35.153 12.7873L35.1517 12.7924L35.1511 12.795L35.1508 12.7962C35.1506 12.7968 35.1505 12.7975 36.3647 13.0943L35.1505 12.7975L35.1147 12.9437V13.0943V16.6472H32.0943C31.209 16.6472 30.643 17.3958 30.643 18.1095V18.3291C30.643 19.1302 31.2932 19.7804 32.0943 19.7804V19.7805L32.1047 19.7804L35.1147 19.7555V22.3559C35.1147 22.9606 35.4852 23.3393 35.7044 23.5019C35.9374 23.6748 36.2564 23.8072 36.6168 23.8072H36.8383C37.6095 23.8072 38.2933 23.1864 38.2933 22.3559H38.2934L38.2933 22.3417L38.264 19.7554L41.3457 19.7804ZM26.7002 17.7251C26.7002 12.1891 31.1891 7.70016 36.7251 7.70016C42.2611 7.70016 46.75 12.1891 46.75 17.7251C46.75 23.2611 42.2611 27.75 36.7251 27.75C31.1891 27.75 26.7002 23.2611 26.7002 17.7251Z"
      stroke="#4A2362"
      strokeWidth="2.5"
    />
    <mask id="path-2-inside-1_2890_285079" fill="white">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M1.82791e-05 0H35.5558V5.00014C28.9575 5.52443 23.7664 11.0451 23.7664 17.7782C23.7664 24.5113 28.9575 30.032 35.5558 30.5562V33.8714H35.5559V46.6901L17.7783 33.8715L17.6843 33.8715L0 46.7839L1.82791e-05 33.8715V0Z"
      />
    </mask>
    <path
      d="M35.5558 0H38.0558V-2.5H35.5558V0ZM1.82791e-05 0V-2.5H-2.49998V0H1.82791e-05ZM35.5558 5.00014L35.7538 7.49228L38.0558 7.30937V5.00014H35.5558ZM35.5558 30.5562H38.0558V28.247L35.7538 28.0641L35.5558 30.5562ZM35.5558 33.8714H33.0558V36.3714H35.5558V33.8714ZM35.5559 33.8714H38.0559V31.3714H35.5559V33.8714ZM35.5559 46.6901L34.0938 48.718L38.0559 51.5749V46.6901H35.5559ZM17.7783 33.8715L19.2405 31.8437L18.5856 31.3715L17.7782 31.3715L17.7783 33.8715ZM17.6843 33.8715L17.6842 31.3715L16.8687 31.3716L16.2101 31.8525L17.6843 33.8715ZM0 46.7839L-2.5 46.7839L-2.50001 51.7048L1.47424 48.803L0 46.7839ZM1.82791e-05 33.8715L2.50002 33.8715V33.8715H1.82791e-05ZM35.5558 -2.5H1.82791e-05V2.5H35.5558V-2.5ZM38.0558 5.00014V0H33.0558V5.00014H38.0558ZM26.2664 17.7782C26.2664 12.3594 30.4449 7.91412 35.7538 7.49228L35.3578 2.50799C27.4701 3.13474 21.2664 9.73075 21.2664 17.7782H26.2664ZM35.7538 28.0641C30.4449 27.6423 26.2664 23.197 26.2664 17.7782H21.2664C21.2664 25.8256 27.4701 32.4216 35.3578 33.0484L35.7538 28.0641ZM38.0558 33.8714V30.5562H33.0558V33.8714H38.0558ZM35.5558 36.3714H35.5559V31.3714H35.5558V36.3714ZM33.0559 33.8714V46.6901H38.0559V33.8714H33.0559ZM37.0181 44.6623L19.2405 31.8437L16.3161 35.8994L34.0938 48.718L37.0181 44.6623ZM17.6844 36.3715L17.7784 36.3715L17.7782 31.3715L17.6842 31.3715L17.6844 36.3715ZM1.47424 48.803L19.1586 35.8906L16.2101 31.8525L-1.47424 44.7648L1.47424 48.803ZM-2.49998 33.8715L-2.5 46.7839L2.5 46.7839L2.50002 33.8715L-2.49998 33.8715ZM-2.49998 0V33.8715H2.50002V0H-2.49998Z"
      fill="#4A2362"
      mask="url(#path-2-inside-1_2890_285079)"
    />
  </svg>
);

const InterestedServiceOutlinedIcon = (props: any) => (
  <Icon component={InterestedServiceOutlined} {...props} />
);

export { InterestedServiceOutlinedIcon };
