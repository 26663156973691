import React from 'react';
import { Checkbox, Col, Form, Row } from 'antd';
import { ILeadsFormComponent } from '@shared-components/models';
import { GUTTER, LABEL, TITLE } from '@moxie/constants';
import { globalHandleCheck } from '@moxie/shared';

const VisaInformation: React.FC<ILeadsFormComponent> = ({
  setLeadForm,
  leadForm,
  setDisabled,
}: ILeadsFormComponent) => {
  const handleChange = (e: any) => {
    globalHandleCheck(
      e,
      leadForm,
      setLeadForm,
      'visa_information_section',
      setDisabled
    );
  };

  const onCheckAllChange = (e: any) => {
    setDisabled(false);
    if (!e.target.checked) {
      setLeadForm({
        ...leadForm,
        visa_information_section: {
          ...leadForm.visa_information_section,
          visa_type: false,
          visa_expiry_date: false,
          country_of_passport: false,
          passport_expiry_date: false,
          passport_no: false,
        },
      });
      return;
    }
    setLeadForm({
      ...leadForm,
      visa_information_section: {
        ...leadForm.visa_information_section,
        visa_type: true,
        visa_expiry_date: true,
        country_of_passport: true,
        passport_expiry_date: true,
        passport_no: true,
      },
    });
  };

  return (
    <>
      <Row justify="space-between" className="custom__title__text">
        <Col>{TITLE.VISA_INFO_DETAILS}</Col>
        <Col>
          <Checkbox
            checked={
              leadForm?.visa_information_section?.visa_type &&
              leadForm?.visa_information_section?.visa_expiry_date &&
              leadForm?.visa_information_section?.country_of_passport &&
              leadForm?.visa_information_section?.passport_expiry_date &&
              leadForm?.visa_information_section?.passport_no
            }
            onChange={onCheckAllChange}
          />
        </Col>
      </Row>
      <Form.Item className="custom__formItem">
        <Row gutter={GUTTER}>
          <Col span={5} className="lead-form-box">
            <Checkbox
              name="visa_type"
              checked={leadForm?.visa_information_section?.visa_type}
              onChange={handleChange}
            >
              {LABEL.VISA_TYPE}
            </Checkbox>
          </Col>
          <Col span={5} className="lead-form-box">
            <Checkbox
              name="visa_expiry_date"
              checked={leadForm?.visa_information_section?.visa_expiry_date}
              onChange={handleChange}
            >
              {LABEL.VISA_EXPIRY_DATE}
            </Checkbox>
          </Col>
          <Col span={5} className="lead-form-box">
            <Checkbox
              name="country_of_passport"
              checked={leadForm?.visa_information_section?.country_of_passport}
              onChange={handleChange}
            >
              {LABEL.COUNTRY_OF_PASSPORT}
            </Checkbox>
          </Col>
          <Col span={5} className="lead-form-box">
            <Checkbox
              name="passport_expiry_date"
              checked={leadForm?.visa_information_section?.passport_expiry_date}
              onChange={handleChange}
            >
              {LABEL.PASSPORT_EXPIRY_DATE}
            </Checkbox>
          </Col>
          <Col span={5} className="lead-form-box">
            <Checkbox
              name="passport_no"
              checked={leadForm?.visa_information_section?.passport_no}
              onChange={handleChange}
            >
              {LABEL.PASSPORT_NO}
            </Checkbox>
          </Col>
        </Row>
      </Form.Item>
    </>
  );
};

export default VisaInformation;
