import { put, takeLatest } from '@redux-saga/core/effects';
import { errorHandler } from '@moxie/utils';
import {
  deleteAppointment,
  getAppointment,
  getMyAppointments,
  updateAppointment,
} from '@lyra/services.api';
import { AxiosResponse } from 'axios';
import { IAction, IAppointment } from '@shared-components/models';
import * as actionUserAppointment from './user-appointment.action';
import * as actionTypes from './user-appointment.constant';
import { successNotificationHandler } from '@moxie/shared';

export function* getUserAppointments({ payload }: IAction<any>) {
  try {
    if (payload) {
      const response: AxiosResponse = yield getMyAppointments(payload);
      yield put(
        actionUserAppointment.getUserAppointmentsSuccess(response?.data?.data)
      );
    }
  } catch (err) {
    errorHandler(err);
    yield put(actionUserAppointment.getUserAppointmentsFailure(err));
  }
}

export function* getUserAppointment({ payload }: IAction<string>) {
  try {
    if (payload) {
      const response: AxiosResponse = yield getAppointment(payload);
      yield put(
        actionUserAppointment.getUserAppointmentSuccess(response?.data?.data)
      );
    }
  } catch (err) {
    errorHandler(err);
    yield put(actionUserAppointment.getUserAppointmentFailure(err));
  }
}

export function* updateUserAppointments({
  payload,
  onClose,
  triggerRefresh,
}: IAction<IAppointment>) {
  try {
    if (!payload) return;
    const response: AxiosResponse = yield updateAppointment(payload);
    yield put(
      actionUserAppointment.updateUserAppointmentSuccess(response.data.data)
    );
    onClose && onClose();
    triggerRefresh && triggerRefresh();
    successNotificationHandler(response.data.message);
  } catch (err) {
    errorHandler(err);
    yield put(actionUserAppointment.updateUserAppointmentFailure(err));
  }
}

export function* deleteUserAppointments({ payload }: IAction<string>) {
  try {
    if (!payload) return;
    const response: AxiosResponse = yield deleteAppointment(payload);
    yield put(actionUserAppointment.deleteUserAppointmentSuccess(payload));
    successNotificationHandler(response.data.message);
  } catch (err) {
    errorHandler(err);
    yield put(actionUserAppointment.deleteUserAppointmentFailure(err));
  }
}

export default function* rootSagas(): unknown {
  yield takeLatest(
    actionTypes.GET_USER_APPOINTMENTS_REQUEST,
    getUserAppointment
  );
  yield takeLatest(
    actionTypes.GET_USER_APPOINTMENT_REQUEST,
    getUserAppointments
  );
  yield takeLatest(
    actionTypes.UPDATE_USER_APPOINTMENT_REQUEST,
    updateUserAppointments
  );
  yield takeLatest(
    actionTypes.DELETE_USER_APPOINTMENT_REQUEST,
    deleteUserAppointments
  );
}
