import React from 'react';
import Icon from '@ant-design/icons';

const ProspectOutlined = () => (
  <svg xmlns="http://www.w3.org/2000/svg" width="14.2" height="17.796" viewBox="0 0 14.2 17.796">
    <g id="Group_76" data-name="Group 76" transform="translate(-7220 -8171.771)">
      <g id="Group_75" data-name="Group 75" transform="translate(-14 -16)">
        <g id="Layer_1" transform="translate(7234 8187.771)">
          <g id="Group_74" data-name="Group 74">
            <path id="Path_4" data-name="Path 4" d="M15.8,93.063a5.518,5.518,0,0,1-3.261,3.85A4.938,4.938,0,0,1,7.87,96.4,13.2,13.2,0,0,1,6.754,95.5c-.015.014-.058.05-.1.09L2.982,99.261a.681.681,0,0,0-.246.461.587.587,0,0,0,.981.479c.112-.1.216-.21.322-.317q.91-.909,1.819-1.818c.193-.193.352-.222.523-.105a.342.342,0,0,1,.1.473,1.025,1.025,0,0,1-.145.171c-.618.619-1.241,1.233-1.853,1.858a2.018,2.018,0,0,1-.912.6H3.052c-.047-.02-.093-.042-.141-.059a1.264,1.264,0,0,1-.839-.856,1.316,1.316,0,0,1,.4-1.4Q4.336,96.887,6.2,95.026a1.173,1.173,0,0,1,.171-.12,5.707,5.707,0,0,1-.683-1.563c-.011-.04-.1-.083-.155-.083-.475-.006-.95-.009-1.425,0a1.585,1.585,0,0,0-1.083.395,1.692,1.692,0,0,0-.512,1.47c.442,0,.864,0,1.286,0a1,1,0,0,1,.257.03.324.324,0,0,1,.252.315.328.328,0,0,1-.229.35.7.7,0,0,1-.238.035c-.527,0-1.054,0-1.581,0a.465.465,0,0,1-.459-.248V94.462a6.691,6.691,0,0,1,.375-.9,2.028,2.028,0,0,1,1.237-.919c.038-.011.075-.028.139-.053a1.755,1.755,0,0,1-.227-1.153,1.4,1.4,0,0,1,.864-1.263,1.411,1.411,0,0,1,1.478.158c.046.033.09.069.133.1a5.057,5.057,0,0,1,4.909-3.46A4.83,4.83,0,0,1,15.8,92.1ZM10.693,87.68a4.43,4.43,0,1,0,4.438,4.43A4.409,4.409,0,0,0,10.693,87.68Zm-6.66,3.954c0,.052,0,.1,0,.156a.771.771,0,0,0,.722.751.75.75,0,0,0,.774-.649,1.563,1.563,0,0,0,.009-.448.744.744,0,0,0-.91-.667A.773.773,0,0,0,4.033,91.634Z" transform="translate(-1.801 -83.27)" fill="#fff" fillRule="evenodd"/>
            <path id="Path_5" data-name="Path 5" d="M198.876,1.8a.469.469,0,0,1,.227.452c-.008.335,0,.671,0,1.007,0,.274-.13.422-.352.424s-.363-.159-.364-.432c0-.313.009-.625,0-.938A.547.547,0,0,1,198.6,1.8Z" transform="translate(-189.839 -1.8)" fill="#fff" fillRule="evenodd"/>
            <path id="Path_6" data-name="Path 6" d="M137.06,31.915a1.6,1.6,0,0,1-.3-.166c-.2-.183-.39-.377-.577-.573a.356.356,0,0,1-.026-.524.351.351,0,0,1,.525.01c.2.189.4.387.587.588a.318.318,0,0,1,.077.409A1.525,1.525,0,0,1,137.06,31.915Z" transform="translate(-130.222 -29.29)" fill="#fff" fillRule="evenodd"/>
            <path id="Path_7" data-name="Path 7" d="M248.026,30.8a1.745,1.745,0,0,1-.174.3c-.18.195-.369.382-.561.565a.36.36,0,1,1-.516-.5c.189-.2.387-.4.589-.586a.315.315,0,0,1,.41-.069A1.535,1.535,0,0,1,248.026,30.8Z" transform="translate(-236.017 -29.209)" fill="#fff" fillRule="evenodd"/>
            <path id="Path_8" data-name="Path 8" d="M199.238,55.616a.345.345,0,0,1-.342.358.354.354,0,1,1-.009-.707A.344.344,0,0,1,199.238,55.616Z" transform="translate(-189.983 -52.944)" fill="#fff" fillRule="evenodd"/>
            <path id="Path_9" data-name="Path 9" d="M116.319,317.706a.345.345,0,0,1-.355-.346.359.359,0,0,1,.357-.366.377.377,0,0,1,.353.361A.359.359,0,0,1,116.319,317.706Z" transform="translate(-111.004 -303.301)" fill="#fff" fillRule="evenodd"/>
            <path id="Path_10" data-name="Path 10" d="M123.811,126.037a3.768,3.768,0,1,1,3.761-3.767A3.769,3.769,0,0,1,123.811,126.037Zm2.394-1.916a3.033,3.033,0,1,0-4.8-.027,7.413,7.413,0,0,1,.588-.605,7.536,7.536,0,0,1,.713-.473,1.686,1.686,0,0,1-.324-1.257,1.454,1.454,0,0,1,.928-1.271,1.436,1.436,0,0,1,1.568.384,1.75,1.75,0,0,1,.247,1.812,2.305,2.305,0,0,1-.2.363A2.975,2.975,0,0,1,126.205,124.121Zm-.522.53a2.093,2.093,0,0,0-2.065-1.091,2.041,2.041,0,0,0-1.677,1.1A3.015,3.015,0,0,0,125.683,124.651Zm-1.15-2.671a.76.76,0,0,0-.495-.825.7.7,0,0,0-.9.451c-.13.477-.052,1,.44,1.171a.623.623,0,0,0,.168.034C124.231,122.841,124.533,122.522,124.534,121.98Z" transform="translate(-114.913 -113.432)" fill="#fff" fillRule="evenodd"/>
          </g>
        </g>
        <g id="Ellipse_4" data-name="Ellipse 4" transform="translate(7238 8191.419)" fill="none" stroke="#fff" strokeWidth="0.5">
          <circle cx="5.1" cy="5.1" r="5.1" stroke="none"/>
          <circle cx="5.1" cy="5.1" r="4.85" fill="none"/>
        </g>
      </g>
    </g>
  </svg>


)

const ProspectOutlinedIcon: React.FC = (props: any) => (
  <Icon component={ProspectOutlined} {...props} className="compared-filled" />
);

export { ProspectOutlinedIcon };
