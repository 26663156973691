import * as cookies from 'js-cookie';
import { ILoginResponse } from '@shared-components/models';
import { COOKIE_EXPIRY } from '@moxie/constants';
import Cookies from 'js-cookie';

export const handleTokens = (
  response: ILoginResponse,
  expiryDate = COOKIE_EXPIRY
): void => {
  if (response?.data?.access_token) {
    cookies.set('access', response.data.access_token, {
      expires: expiryDate,
    });
    cookies.set('refresh', response.data.refresh_token, {
      expires: expiryDate,
    });
  }
};

export const handleAdminTokens = (
  response: ILoginResponse,
  expiryDate = COOKIE_EXPIRY
): void => {
  if (response?.data?.access_token) {
    cookies.set('adminAccess', response.data.access_token, {
      expires: expiryDate,
    });
    cookies.set('adminRefresh', response.data.refresh_token, {
      expires: expiryDate,
    });
  }
};

export const unauthorizedAccess = (
  accessCookies: string,
  refresCookies: string
): void => {
  Cookies.remove(accessCookies);
  Cookies.remove(refresCookies);
  window.location.reload();
};
