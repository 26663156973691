import { useAppSelector } from "@crm/core";
import Typography from "antd/es/typography";

const WelcomeUser = () => {
  const user = useAppSelector(store => store.auth.user);
  return (
    <div className="dashboard-widget">
      <Typography.Title level={3}>
        Hello, {user?.firstName}
      </Typography.Title>
      <Typography.Paragraph>
        Let’s organize your work and improve your productivity with ApplyIMS
      </Typography.Paragraph>
    </div>
  )
}

export default WelcomeUser;
