import React from 'react';
import { Badge } from 'antd';
import { APPLICATION_STATUS } from '@moxie/constants';

const ApplicationStatus = ({ status }: { status: string }) => {
  return (
    <div>
      {status === APPLICATION_STATUS.IN_PROGRESS ? (
        <>
          <Badge status="processing" />
          <span className="text-blue">{status}</span>
        </>
      ) : status === APPLICATION_STATUS.COMPLETED ? (
        <>
          <Badge status="success" />
          <span className="text-green">{status}</span>
        </>
      ) : (
        <>
          <Badge status="error" />
          <span className="text-red">{status}</span>
        </>
      )}
    </div>
  );
};

export { ApplicationStatus };
