import React from 'react';
import { Button, Table } from '@moxie/shared';
import { ApplicationReportTableColumns } from './table-columns';
import { TEXT } from '@moxie/constants';

const ApplicationReportTable = ({
  getData,
  tableName,
  filter,
  setFilter,
  handleExport,
}: any) => {
  return (
    <div className="bg-white padding-top-2">
      <Table
        action={getData}
        columns={ApplicationReportTableColumns(setFilter)}
        tableName={tableName}
        filter={filter}
        size={'small'}
        filterOptions={true}
        scroll={window.screen.height < 800 ? { y: 500 } : { y: undefined }}
        tableButtons={
          <Button type="primary" onClick={() => handleExport()}>
            {TEXT.EXPORT}
          </Button>
        }
      />
    </div>
  );
};

export { ApplicationReportTable };
