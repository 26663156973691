import { INote, IResponse } from '@shared-components/models';
import { axiosInstance as axios } from '@lyra/services.api';
import { errorHandler } from '@moxie/utils';
import { API_URL } from '@moxie/constants';

const postNotes = async (
  data: INote,
  contactId: string,
  appointmentId?: string
): Promise<INote> => {
  const body = { ...data, parent_id: contactId, appointment_id: appointmentId };
  return axios.post(API_URL.URL_ADD_NOTE, body);
};
const getAllNotes = async (
  parent_id: string,
  appointment_id?: string
): Promise<any> => {
  const params = { parent_id, appointment_id };
  try {
    const data = await axios.get(API_URL.URL_ADD_NOTE, { params });
    return data;
  } catch (err) {
    errorHandler(err);
  }
};
const getAllContactNotes = async (
  params: string,
  parent_id: string,
): Promise<any> => {

  try {
    const data = await axios.get(`${API_URL.URL_ADD_NOTE}/${params}&parent_id=${parent_id}`);
    return data;
  } catch (err) {
    errorHandler(err);
  }
};

const getOneNote = async (id: string): Promise<any> => {
  try {
    const data = await axios.get(`${API_URL.URL_ADD_NOTE}/${id}`);
    return data;
  } catch (err) {
    errorHandler(err);
  }
};
const deleteOneNote = async (id: string): Promise<any> => {
  try {
    const data = await axios.delete(`${API_URL.URL_ADD_NOTE}/${id}`);
    return data;
  } catch (err) {
    errorHandler(err);
  }
};
const updateNote = async (
  data: INote,
  contactId: string,
  id: string
): Promise<INote> => {
  const body = { ...data, parent_id: contactId };
  return axios.put(`${API_URL.URL_ADD_NOTE}/${id}`, body);
};
const deleteMultipleNotes = async (ids: string[]): Promise<IResponse> => {
  const data = { ids };
  return axios.delete(`${API_URL.URL_ADD_NOTE}/delete/all`, { data });
};
export {
  postNotes,
  getAllNotes,
  getAllContactNotes,
  getOneNote,
  deleteOneNote,
  updateNote,
  deleteMultipleNotes,
};
