import React, { useContext, useState } from 'react';
import { Col, Divider, Row } from 'antd';
import { Button, popConfirm } from '@moxie/shared';
import {
  CloseOutlined,
  LeftCircleFilled,
  RightCircleFilled,
  CheckCircleFilled,
  FolderOutlined,
  SolutionOutlined,
  DownloadOutlined,
  SelectOutlined,
} from '@ant-design/icons';
import {
  APPLICATION_ACTORS,
  APPLICATION_STATUS,
  CONFIRM_MESSAGE,
  DOWNLOAD_ALL,
  GUTTER,
  LABEL,
  PAGE_HEADER_MENU_APPLICATION_DETAIL,
  PREPARING_FILES,
  TEXT,
  TITLE,
} from '@moxie/constants';
import { IApplicationDetailChecklist } from '@shared-components/models';
import {
  getChecklistDocuments,
  getDownloadAllByApplicationStageId,
  getDownloadDocumentsByCategory,
} from '@lyra/services.api';
import { ChecklistDocumentDrawer } from './checklist-document-drawer';
import { applicationActions, leadActions } from '@lyra/core';
import { clientActions } from '@admin/core';
import { useDispatch } from 'react-redux';
import { useParams } from 'react-router-dom';
import { ApplicationContext } from '../applicationContext';
import { ApplicationCompletePage } from './application-complete-page';
import { SelectedStagePage } from './selected-stage-page';

const ApplicationDetailChecklist = ({
  previousStageId,
  nextStageId,
  status,
  handleStatus,
  handleStage,
  stageChecklists,
  disableView,
  isActor,
  userType,
  currentStageId,
  leadId,
  currentStage,
  stages,
  selectedChecklistData
}: IApplicationDetailChecklist) => {  
  const dispatch = useDispatch();
  const params: { applicationId: string; slug2: string } = useParams();
  const applicationId = params?.applicationId
    ? params.applicationId
    : params.slug2;
  const [visible, setVisible] = useState(false);
  const [refreshTable, setRefreshTable] = useState(true);
  const [checklistId, setChecklistId] = useState<number>();
  const [wfAppStageId, setWfAppStageId] = useState<string>('');
  const [checklistName, setChecklistName] = useState<string>('');
  const [uploadType, setUploadType] = useState<string>('');
  const [selectedActor, setSelectedActor] = useState<boolean>(false);
  const [selectedTab, setSelectedTab] = useState<string>(
    PAGE_HEADER_MENU_APPLICATION_DETAIL[0].name
  );

  const {
    applicationState,
    checkListDetailClick,
    handleNextStage,
    handleCompleteStage,
    handlePreviousStage,
    goToCurrentStage,
    startDocumentsDownloading,
    finishDocumentsDownloading,
  } = useContext(ApplicationContext);
  const {
    selectedStage,
    showDetail,
    selectedChecklist,
    isDocumentsDownloading,
  } = applicationState;

  const triggerRefresh = () => {
    setRefreshTable((prev) => !prev);
    if (applicationId) {
      dispatch(applicationActions.getOneRequest(applicationId));
    }
  };

  const handleAddDocument = (data: string) => {
    setUploadType(data);
    setVisible(true);
  };

  const handleDetailButton = async (item: any) => {
    if (item?.actor === userType || item?.actor === APPLICATION_ACTORS.BOTH) {
      setSelectedActor(true);
    }
    checkListDetailClick(item.id);
    setChecklistId(item.checklist_id);
    setChecklistName(item?.checklist?.name);
    setWfAppStageId(item.workflow_application_stage_id);

    triggerRefresh();
  };
  const getDocuments = async () => {
    const response = await getChecklistDocuments(
      Number(checklistId),
      wfAppStageId,
      applicationId
    );
    return response.data;
  };

  const handleDocumentsFromCheckListDownload = async () => {
    if (userType === 'admin') {
      dispatch(clientActions.downloading(true));
    } else {
      dispatch(leadActions.downloading(true));
    }
    if (isDocumentsDownloading) {
      return;
    }
    startDocumentsDownloading();
    const response: any = await getDownloadDocumentsByCategory({
      checklistId,
      wfAppStageId,
      applicationId,
    });
    const linkElement = document.createElement('a');
    linkElement.href = response.url;
    linkElement.click();
    finishDocumentsDownloading();
    dispatch(leadActions.downloading(false));
  };

  const handleDocumentsFromApplicationStageDownload = async () => {
    if (userType === 'admin') {
      dispatch(clientActions.downloading(true));
    } else {
      dispatch(leadActions.downloading(true));
    }
    if (isDocumentsDownloading) {
      return;
    }
    startDocumentsDownloading();
    const response: any = await getDownloadAllByApplicationStageId({
      applicationId,
      wfAppStageId: currentStageId,
    });
    const linkElement = document.createElement('a');
    linkElement.href = response.url;
    linkElement.click();
    finishDocumentsDownloading();
    dispatch(leadActions.downloading(false));
    dispatch(clientActions.downloading(false));
  };

  // const checkIfStageCheckListContainsDocument = () => {
  //   let hasDocs = false;
  //   stageChecklists.forEach((item: any) => {
  //     if (item.totalDocs > 0) {
  //       hasDocs = true;
  //     }
  //   });
  //   return hasDocs;
  // };

  // const showDownloadAllBtn = () => {
  //   if (stageChecklists && stageChecklists.length === 0) {
  //     return null;
  //   }

  //   const containsDocument =
  //     stageChecklists && checkIfStageCheckListContainsDocument();
  //   if (!containsDocument) {
  //     return null;
  //   }

  //   return (
  //     <Button
  //       loading={isDocumentsDownloading}
  //       type="primary"
  //       icon={<DownloadOutlined />}
  //       data-testid="downloadAll_btn"
  //       className="margin-right-2 downloadAllBtn"
  //       onClick={handleDocumentsFromApplicationStageDownload}
  //     >
  //       {isDocumentsDownloading === false ? DOWNLOAD_ALL : PREPARING_FILES}
  //     </Button>
  //   );
  // };

   const checkIfStageCheckListContainsDocument = () => {
    let hasDocs = false;
    stageChecklists.forEach((item: any) => {
      if (item.totalDocs > 0) {
        hasDocs = true;
      }
    });
    return hasDocs;
  };

  const showDownloadAllBtn = () => {
    if (stageChecklists && stageChecklists.length === 0) {
      return null;
    }

    const containsDocument =
      stageChecklists && checkIfStageCheckListContainsDocument();
    if (!containsDocument) {
      return null;
    }

    return (
      <Button
        loading={isDocumentsDownloading}
        type="primary"
        icon={<DownloadOutlined />}
        data-testid="downloadAll_btn"
        className="margin-right-2 downloadAllBtn"
        onClick={handleDocumentsFromApplicationStageDownload}
      >
        {isDocumentsDownloading === false ? DOWNLOAD_ALL : PREPARING_FILES}
      </Button>
    );
  };

  const handleClose = () => {
    setVisible(false);
  };

  return (
    <section className="profile ">
      <>
        <Row
          gutter={GUTTER}
          justify="space-between"
          className="padding-left-2 application-tab"
        >
          <Col className="application-tab-secondary">
            {PAGE_HEADER_MENU_APPLICATION_DETAIL.map((item): any => {
              if (item.id === 'forms' && selectedStage?.name === 'Application')
                return null;
              return (
                <div
                  className={
                    item.name === selectedTab ? 'tab-selected-btn' : ''
                  }
                  key={item.id}
                >
                  <Button
                    type="link"
                    size="large"
                    icon={
                      item.name === 'Documents' ? (
                        <FolderOutlined />
                      ) : (
                        <SolutionOutlined />
                      )
                    }
                    className="padding-right-1 tab-btn"
                    onClick={() => setSelectedTab(item.name)}
                  >
                    {item.name}
                  </Button>
                </div>
              );
            })}
          </Col>
          {status !== APPLICATION_STATUS.COMPLETED ? (
            <>
              <Col className="padding-top-1">
                {!disableView || currentStage.level === selectedStage?.level ? (
                  <>
                    {status !== APPLICATION_STATUS.DISCONTINUED ? (
                      <Row align="middle">
                        <Button
                          icon={<CloseOutlined />}
                          className="margin-right-2"
                          data-testid="discontinue_btn"
                          danger
                          onClick={() =>
                            handleStatus(APPLICATION_STATUS.DISCONTINUED)
                          }
                        >
                          {LABEL.DISCONTINUE}
                        </Button>
                        <div className="prev-next-btn margin-right-2">
                          <Button
                            type="link"
                            disabled={!previousStageId}
                            data-testid="previousStage_btn"
                            size="large"
                            icon={<LeftCircleFilled />}
                            onClick={() => {
                              popConfirm(
                                TITLE.WARNING,
                                CONFIRM_MESSAGE.GO_TO_PREVIOUS_STAGE,
                                async () => {
                                  const previousStageData = stages.find(
                                    (stage: any) =>
                                      stage.wfAppStageId === previousStageId
                                  );

                                  const {
                                    stage,
                                    level,
                                    wfAppStageId,
                                    actor,
                                  } = previousStageData;
                                  handleStage(LABEL.PREVIOUS);
                                  handlePreviousStage({
                                    stage,
                                    level,
                                    wfAppStageId,
                                    actor,
                                  });
                                }
                              );
                            }}
                          >
                            {LABEL.PREVIOUS_STAGE}
                          </Button>
                          {nextStageId ? (
                            <Button
                              type="link"
                              size="large"
                              className="padding-right-1"
                              data-testid="nextStage_btn"
                              disabled={!nextStageId || !isActor}
                              onClick={() => {
                                const nextStageData = stages.find(
                                  (stage: any) =>
                                    stage.wfAppStageId === nextStageId
                                );

                                const {
                                  stage,
                                  level,
                                  wfAppStageId,
                                  actor,
                                } = nextStageData;
                                handleStage(LABEL.NEXT);
                                handleNextStage({
                                  stage,
                                  level,
                                  wfAppStageId,
                                  actor,
                                });
                              }}
                            >
                              {LABEL.NEXT_STAGE}
                              <RightCircleFilled />
                            </Button>
                          ) : (
                            <Button
                              className="margin-right-2 stage-timeline-complete-button"
                              type="default"
                              icon={<CheckCircleFilled />}
                              hidden={!isActor}
                              data-testid="complete_btn"
                              onClick={() => {
                                handleStage(LABEL.COMPLETE);
                                handleCompleteStage({});
                              }}
                            >
                              {LABEL.COMPLETE_APPLICATION}
                            </Button>
                          )}
                        </div>
                      </Row>
                    ) : (
                      <Button
                        className="margin-left-2"
                        type="primary"
                        data-testid="revert_btn"
                        onClick={() =>
                          handleStatus(APPLICATION_STATUS.IN_PROGRESS)
                        }
                      >
                        {LABEL.REVERT}
                      </Button>
                    )}
                  </>
                ) : null}
              </Col>
              <Button
                size="large"
                type="default"
                className="padding-right-1 margin-right-2 margin-top-1"
                data-testid="goto_currentStage_btn"
                hidden={
                  !selectedStage || currentStage?.level === selectedStage?.level
                }
                onClick={() => {
                  if (status === APPLICATION_STATUS.COMPLETED) {
                    handleCompleteStage({});
                  } else {
                    goToCurrentStage({
                      ...currentStage,
                      wfAppStageId: currentStageId,
                    });
                  }
                }}
              >
                <SelectOutlined />
                {LABEL.GO_TO_CURRENT_STAGE}
              </Button>
            </>
          ) : (
            <>
              <Col className="padding-top-1">
                <Row align="middle">
                  <div className="prev-next-btn margin-right-2">
                    <Button
                      type="link"
                      data-testid="previousStage_btn"
                      size="large"
                      hidden={selectedStage}
                      icon={<LeftCircleFilled />}
                      onClick={() => {
                        popConfirm(
                          TITLE.WARNING,
                          CONFIRM_MESSAGE.GO_TO_PREVIOUS_STAGE,
                          async () => {
                            handleStage(LABEL.PREVIOUS);
                            handlePreviousStage({
                              ...currentStage,
                              wfAppStageId: currentStageId,
                            });
                          }
                        );
                      }}
                    >
                      {LABEL.PREVIOUS_STAGE}
                    </Button>
                  </div>
                </Row>
              </Col>
              <Button
                size="large"
                type="default"
                className="padding-right-1 margin-right-2 margin-top-1"
                data-testid="goto_currentStage_btn"
                hidden={!selectedStage}
                onClick={() => {
                  if (status === APPLICATION_STATUS.COMPLETED) {
                    handleCompleteStage({});
                  } else {
                    goToCurrentStage({
                      ...currentStage,
                      wfAppStageId: currentStageId,
                    });
                  }
                }}
              >
                <SelectOutlined />
                {LABEL.GO_TO_CURRENT_STAGE}
              </Button>
            </>
          )}
        </Row>
        <Divider className="margin-0" />
      </>
      {(PAGE_HEADER_MENU_APPLICATION_DETAIL[0].name === selectedTab &&
        selectedStage &&
        selectedStage.stage !== TEXT.COMPLETE) ||
        (currentStage && status !== APPLICATION_STATUS.COMPLETED) ? (
        <SelectedStagePage
          selectedChecklistData={selectedChecklistData}
          showDetail={showDetail}
          showDownloadAllBtn={showDownloadAllBtn}
          stageChecklists={stageChecklists}
          handleDetailButton={handleDetailButton}
          handleAddDocument={handleAddDocument}
          selectedChecklist={selectedChecklist}
          handleDocumentsFromCheckListDownload={
            handleDocumentsFromCheckListDownload
          }
          refreshTable={refreshTable}
          triggerRefresh={triggerRefresh}
          getDocuments={getDocuments}
          selectedActor={selectedActor}
          currentStageId={currentStageId}
        />
      ) : (
        <ApplicationCompletePage />
      )}
      {visible && (
        <ChecklistDocumentDrawer
          triggerRefresh={triggerRefresh}
          isOpen={visible}
          onClose={handleClose}
          checklistId={checklistId}
          wfAppStageId={wfAppStageId}
          checklistName={checklistName}
          uploadType={uploadType}
          leadId={leadId}
          userType={userType}
        />
      )}
    </section>
  );
};

export { ApplicationDetailChecklist };
