const alias = 'LEADS';

export const ADD_LEAD_REQUEST = `ADD_${alias}_REQUEST`;
export const ADD_LEAD_SUCCESS = `ADD_${alias}_SUCCESS`;
export const ADD_LEAD_FAILURE = `ADD_${alias}_FAILURE`;

export const DELETE_LEAD_REQUEST = `DELETE_${alias}_REQUEST`;
export const DELETE_LEAD_SUCCESS = `DELETE_${alias}_SUCCESS`;
export const DELETE_LEAD_FAILURE = `DELETE_${alias}_FAILURE`;

export const UPDATE_LEAD_REQUEST = `UPDATE_${alias}_REQUEST`;
export const UPDATE_LEAD_SUCCESS = `UPDATE_${alias}_SUCCESS`;
export const UPDATE_LEAD_FAILURE = `UPDATE_${alias}_FAILURE`;

export const ARCHIVE_LEAD_REQUEST = `ARCHIVE_${alias}_REQUEST`;
export const ARCHIVE_LEAD_SUCCESS = `ARCHIVE_${alias}_SUCCESS`;
export const ARCHIVE_LEAD_FAILURE = `ARCHIVE_${alias}_FAILURE`;

export const UNARCHIVE_LEAD_REQUEST = `UNARCHIVE_${alias}_REQUEST`;
export const UNARCHIVE_LEAD_SUCCESS = `UNARCHIVE_${alias}_SUCCESS`;
export const UNARCHIVE_LEAD_FAILURE = `UNARCHIVE_${alias}_FAILURE`;

export const CLEAR_LEAD = `CLEAR_${alias}`;
export const DOWNLOADING = `DOWNLOADING`
