import React, { useEffect, useState } from 'react';
import { Col, Row, Spin } from 'antd';
import Form from 'antd/lib/form';
import {
  IForm,
  IInstituteInterface,
  ILeadApplicationForm,
} from '@shared-components/models';
import {
  getInstitutionProducts,
  getInstitutionByServiceCountry,
  getServices,
  productServices,
  getWorkflowByServiceCountry,
  getAllLead,
} from '@lyra/services.api';
import {
  COUNTRY_PLACEHOLDER,
  GUTTER,
  INTAKE_MONTHS,
  LABEL,
  PLACEHOLDER,
  SLUG_ASSIGNED_CONTACTS,
} from '@moxie/constants';
import { FormItem, Option, SearchableSelect } from '@moxie/shared';
import { fetchServiceCountry } from 'apps/lyra/src/libs/services.api/lib/service.api';
import { ContactProfileCard } from '../../contacts/libs/contact-profile';
import debounce from 'lodash.debounce';

const ContactApplicationForm = ({
  form,
  initialData,
  onSubmit,
  setDisabled,
}: IForm<any>) => {
  const [contactList, setContactList] = useState([] as any);
  const [serviceList, setServiceList] = useState([] as any);
  const [countryList, setCountryList] = useState([] as any);
  const [workflowTypeList, setWorkflowTypeList] = useState([] as any[]);
  const [institutionList, setInstitutionList] = useState([] as any[]);
  const [branchList, setBranchList] = useState([] as any[]);
  const [productList, setProductList] = useState([] as any[]);
  const [intakeYear, setIntakeYear] = useState([] as any[]);
  const [intakeMonth, setIntakeMonth] = useState([] as any[]);

  const [loading, setLoading] = useState({
    contact: false,
    service: false,
    country: false,
    workflowType: false,
    institution: false,
    product: false,
  });
  const [selectedService, setSelectedService] = useState('');
  const [selectedBranch, setSelectedBranch] = useState('');
  const [searchKeywords, setSearchKeywords] = useState('');
  const [page, setPage] = useState(1);
  const [totalDataCount, setTotalDataCount] = useState(0);

  useEffect(() => {
    getContacts(page).then(() => {
      return;
    });
  }, [form, searchKeywords]);

  useEffect(() => {
    if (initialData) {
      form.setFieldsValue(initialData);
    } else {
      form.setFieldsValue({});
    }
  }, [form, initialData, productList]);

  useEffect(() => {
    getServiceData().then(() => {
      return;
    });
  }, [form]);

  const handleFinish = async (data: ILeadApplicationForm) => {
    onSubmit && onSubmit(data);
  };

  const resPerPage = 10;
  const skipData = resPerPage * (page - 1);

  const getContacts = debounce(
    async (page: number) => {
      setLoading({ ...loading, contact: true });
      if (searchKeywords.length > 0) {
        setContactList(() => {
          return [];
        });
        const response = await getAllLead(
          `?selectBy=${SLUG_ASSIGNED_CONTACTS}&&s=${searchKeywords}`
        );
        setContactList(response.data.data);
      } else {
        const response = await getAllLead(
          `?selectBy=${SLUG_ASSIGNED_CONTACTS}&&page=${page}&&limit=${resPerPage}&&skip=${skipData}`
        );
        setTotalDataCount(response.data.meta.data_count);
        const mergedContactData = [...contactList, ...response.data.data];
        setContactList(mergedContactData);
      }

      setLoading({ ...loading, contact: false });
    },
    100,
    { leading: true }
  );

  const getServiceData = async () => {
    setLoading({ ...loading, service: true });
    const serviceResponse = await getServices();
    await setServiceList(serviceResponse?.data?.data);
    setLoading({ ...loading, service: false });
  };

  const handleSearch = (value: string) => {
    if (value.length === 0) {
      setSearchKeywords('');
      setContactList([]);
      setPage(1);
    } else {
      setSearchKeywords(value);
    }
  };
  const handleContactChange = (contactId: string | any) => {
    if (contactId) {
      setDisabled(false);
    }
  };

  const handleServiceChange = async (serviceId: string | any) => {
    setLoading({ ...loading, country: true });
    if (serviceId) {
      setSelectedService(serviceId);

      const countryResponse = await fetchServiceCountry(serviceId);
      setCountryList(countryResponse?.data?.data);
      setLoading({ ...loading, country: false });
      setDisabled(false);
    }
    setInstitutionList([]);
    setProductList([]);
    form.setFieldsValue({
      institution_id: null,
      institution_branch_id: null,
      product_id: null,
      intake_year: null,
      intake: null,
      product_intake_id: null,
    });
  };

  const handleCountryChange = async (name: any) => {
    if (name !== undefined) {
      setLoading({ ...loading, workflowType: true, institution: true });
      const workflowTypeResponse = await getWorkflowByServiceCountry(
        selectedService && selectedService,
        name
      );
      setWorkflowTypeList(workflowTypeResponse?.data?.data);
      const institutionResponse = await getInstitutionByServiceCountry(
        selectedService,
        name
      );
      setInstitutionList(institutionResponse?.data?.data);
      setLoading({ ...loading, institution: false, workflowType: false });
      setDisabled(false);
    } else {
      form.setFieldsValue({
        workflow_type_id: null,
        institution_id: null,
        institution_branch_id: null,
        product_id: null,
        intake_year: null,
        intake: null,
        product_intake_id: null,
      });
      setProductList([]);
    }
  };

  const handleWorkflowTypeChange = async () => {
    setProductList([]);
    form.setFieldsValue({
      institution_id: null,
      institution_branch_id: null,
      product_id: null,
      intake_year: null,
      intake: null,
      product_intake_id: null,
    });
    setDisabled(false);
  };

  const handleInstitutionChange = async (institutionId: string | any) => {
    if (institutionId) {
      const institution =
        institutionList &&
        institutionList.find(
          (item: IInstituteInterface) => item?.id === institutionId
        );
      setBranchList(institution.branches);
      setDisabled(false);
    }
    setProductList([]);
    form.setFieldsValue({
      institution_branch_id: null,
      product_id: null,
      intake_year: null,
      intake: null,
      product_intake_id: null,
    });
  };

  const handleBranchChange = async (id: string | any) => {
    setLoading({ ...loading, product: true });
    setProductList([]);
    if (id) {
      setSelectedBranch(id);
      const response = await getInstitutionProducts(id);
      setProductList(response?.data?.data);
      setLoading({ ...loading, product: false });
      setDisabled(false);
    }
    form.setFieldsValue({
      product_id: null,
      intake_year: null,
      intake: null,
      product_intake_id: null,
    });
  };
  const getIntakeYears = () => {
    const intake = [];
    for (let i = 0; i < 3; i++) {
      intake.push(new Date().getFullYear() + i);
    }
    setIntakeYear(intake);
  };
  const handleProductSelect = async (productId: string | any) => {
    getIntakeYears();
    if (productId) {
      productServices
        .apiGetProductIntakes(productId, selectedBranch || '')
        .then((response) => {
          setIntakeMonth(response?.data?.data);
        });
      setDisabled(false);
    }
    form.setFieldsValue({
      intake_year: null,
      intake: null,
      product_intake_id: null,
    });
  };

  const handleScroll = debounce(
    (event: any) => {
      event.stopPropagation();
      const maxPage = Math.ceil(totalDataCount / resPerPage);
      const { scrollHeight, scrollTop, clientHeight } = event.target;

      if (scrollTop + clientHeight >= scrollHeight) {
        if (page < maxPage) {
          setPage((prevPage) => {
            getContacts(prevPage + 1);
            return prevPage + 1;
          });
        }
      }
    },
    200,
    { leading: true }
  );

  return (
    <Form layout="vertical" form={form} onFinish={handleFinish}>
      <Row gutter={GUTTER}>
        <Col span={24}>
          <FormItem
            name="contact_id"
            label={LABEL.CONTACT}
            rules={[{ required: true }]}
          >
            <SearchableSelect
              optionFilter="search_prop"
              placeholder={PLACEHOLDER.SELECT_CONTACT}
              onChange={handleContactChange}
              optionLabelProp="label"
              onSearch={handleSearch}
              onPopupScroll={handleScroll}
              searchValue={searchKeywords}
              listHeight={500}
            >
              {loading.contact ? (
                <Option value="null" disabled>
                  <Spin spinning size="small" className="full-width" />
                </Option>
              ) : (
                contactList &&
                contactList.length > 0 &&
                contactList.map((item: any) => {
                  const fullName = `${item?.first_name} ${item?.last_name}`;
                  return (
                    <Option
                      className="contact-selected-background"
                      value={item?.id}
                      key={item.id}
                      search_prop={searchKeywords}
                      label={
                        <>
                          <label className="text-primary-color initial_capital">
                            {fullName}
                          </label>
                          <label className="profile__title margin-left-1 ">
                            |
                          </label>
                          <label className="contact-profile-text margin-left-1">
                            {item?.company.company_code}-{item?.internal_id}
                          </label>
                        </>
                      }
                    >
                      <ContactProfileCard
                        firstName={item?.first_name}
                        lastName={item?.last_name}
                        email={item?.email}
                        phone={item?.phone}
                        status={item?.status}
                        branchName={item?.branch?.name}
                        archivedContact={item?.archived}
                        id={`${item?.company.company_code}-${item?.internal_id}`}
                      />
                    </Option>
                  );
                })
              )}
            </SearchableSelect>
          </FormItem>
        </Col>
        <Col span={selectedService ? 12 : 24}>
          <FormItem
            name="service_id"
            label={LABEL.SERVICE}
            rules={[{ required: true }]}
          >
            <SearchableSelect
              optionFilter="key"
              placeholder={PLACEHOLDER.SELECT_SERVICE}
              onChange={handleServiceChange}
            >
              {loading.service ? (
                <Option value="null" disabled>
                  <Spin spinning size="small" className="full-width" />
                </Option>
              ) : (
                serviceList &&
                serviceList.map((item: any) => {
                  return (
                    <Option value={item.id} key={item.name}>
                      {item?.name}
                    </Option>
                  );
                })
              )}
            </SearchableSelect>
          </FormItem>
        </Col>

        {selectedService && (
          <Col span={12}>
            <FormItem
              name="country"
              label={LABEL.COUNTRY}
              rules={[{ required: true }]}
            >
              <SearchableSelect
                placeholder={COUNTRY_PLACEHOLDER}
                optionFilter="search_prop"
                onChange={handleCountryChange}
              >
                {loading.country ? (
                  <Option value="null" disabled>
                    <Spin spinning size="small" className="full-width" />
                  </Option>
                ) : (
                  countryList &&
                  countryList?.map((item: any) => {
                    return (
                      <Option
                        value={item?.name}
                        key={item?.name}
                        search_prop={`${item?.name}`}
                      >
                        {item?.name}
                      </Option>
                    );
                  })
                )}
              </SearchableSelect>
            </FormItem>
          </Col>
        )}
        {selectedService && (
          <Col span="12">
            <FormItem
              name="workflow_type_id"
              label={LABEL.WORKFLOW_TYPE}
              rules={[{ required: true }]}
            >
              <SearchableSelect
                optionFilter="search_prop"
                placeholder={PLACEHOLDER.SELECT_WORKFLOW_TYPE}
                onChange={handleWorkflowTypeChange}
              >
                {loading.workflowType ? (
                  <Option value="null" disabled>
                    <Spin spinning size="small" className="full-width" />
                  </Option>
                ) : (
                  workflowTypeList &&
                  workflowTypeList.map((item) => {
                    return (
                      <Option
                        value={item?.id}
                        key={item?.id}
                        search_prop={item?.name}
                      >
                        {item?.name}
                      </Option>
                    );
                  })
                )}
              </SearchableSelect>
            </FormItem>
          </Col>
        )}

        {selectedService && (
          <Col span={12}>
            <FormItem
              name="institution_id"
              label={LABEL.INSTITUTION}
              rules={[{ required: true }]}
            >
              <SearchableSelect
                optionFilter="key"
                placeholder={PLACEHOLDER.SELECT_INSTITUTION}
                onChange={handleInstitutionChange}
              >
                {loading.institution ? (
                  <Option value="null" disabled>
                    <Spin spinning size="small" className="full-width" />
                  </Option>
                ) : (
                  institutionList.map((item) => {
                    return (
                      <Option value={item?.id} key={item?.id}>
                        {item?.name}
                      </Option>
                    );
                  })
                )}
              </SearchableSelect>
            </FormItem>
          </Col>
        )}

        {selectedService && (
          <Col span={12}>
            <FormItem
              name="institution_branch_id"
              label={LABEL.BRANCH}
              rules={[{ required: true }]}
            >
              <SearchableSelect
                optionFilter="search_prop"
                placeholder={PLACEHOLDER.SELECT_BRANCH}
                onChange={handleBranchChange}
              >
                {branchList &&
                  branchList.map((item) => {
                    return (
                      <Option
                        value={item?.id}
                        key={item?.id}
                        search_prop={item?.name}
                      >
                        {item?.name}
                      </Option>
                    );
                  })}
              </SearchableSelect>
            </FormItem>
          </Col>
        )}

        {selectedService && (
          <Col span={12}>
            <FormItem
              name="product_id"
              label={LABEL.PRODUCT}
              rules={[{ required: true }]}
            >
              <SearchableSelect
                optionFilter="search_prop"
                placeholder={PLACEHOLDER.SELECT_PRODUCT}
                onChange={handleProductSelect}
              >
                {loading.product ? (
                  <Option value="null" disabled>
                    <Spin spinning size="small" className="full-width" />
                  </Option>
                ) : (
                  productList &&
                  productList.map((item) => {
                    return (
                      <Option
                        value={item?.id}
                        key={item?.id}
                        search_prop={item?.name}
                      >
                        {item?.name}
                      </Option>
                    );
                  })
                )}
              </SearchableSelect>
            </FormItem>
          </Col>
        )}

        {selectedService && (
          <>
            <Col span={12}>
              <FormItem
                name="intake_year"
                label={LABEL.INTAKE_YEAR}
                rules={[{ required: true }]}
              >
                <SearchableSelect
                  optionFilter="key"
                  placeholder={PLACEHOLDER.INTAKE_YEAR}
                >
                  {intakeYear &&
                    intakeYear.map((item: number) => {
                      return (
                        <Option key={item} value={item}>
                          {item}
                        </Option>
                      );
                    })}
                </SearchableSelect>
              </FormItem>
            </Col>
            <Col span={12}>
              <FormItem
                name="intake"
                label={LABEL.INTAKE_MONTH}
                rules={[{ required: true }]}
              >
                <SearchableSelect
                  optionFilter="key"
                  placeholder={PLACEHOLDER.INTAKE_MONTH}
                  className="initial_capital"
                >
                  {intakeMonth &&
                    INTAKE_MONTHS.map((item: any) => {
                      return (
                        <Option
                          value={item?.intake}
                          key={item?.intake}
                          className="initial_capital"
                        >
                          {item?.intake}
                        </Option>
                      );
                    })}
                </SearchableSelect>
              </FormItem>
            </Col>
          </>
        )}
      </Row>
    </Form>
  );
};
export { ContactApplicationForm };
