import React from "react";
import { Avatar, Card, Col, Empty, Row, Tag, Typography } from "antd";
import { UserOutlined } from "@ant-design/icons";
import moment from "moment";
import { useAppSelector } from "@crm/core";

const ContactPreviousVisitsCard = () => {

  const { prevVisits, loading } = useAppSelector(state => {
    return {
      prevVisits: state.office_visits.prevVisits,
      loading: state.office_visits.loading,
    }
  })

  return (
    <Row className="padding-top-1">
      <Card className="w-100 office-visit__prev-visit__card" loading={loading}>
        {prevVisits?.length ?
          <>
            <Row className="margin-bottom-1">
              <Col span={12}>
                <Typography.Text strong className="prev-visit-card__title">Assignee</Typography.Text>
              </Col>
              <Col span={6}>
                <Typography.Text strong className="prev-visit-card__title">Visit Purpose</Typography.Text>
              </Col>
              <Col span={4}>
                <Typography.Text strong className="prev-visit-card__title">Date</Typography.Text>
              </Col>
            </Row>
            {prevVisits.map((item: any) => {
              const fullName =
                item?.assignee?.firstName +
                ' ' +
                item?.assignee?.lastName;
              return (
                <Row className="margin-bottom-2" key={item?.id}>
                  <Col className="display-flex" span={12}>
                    {item?.assignee ? (
                      <Row className="profile__info__item profile__info__user w-100">
                        <Col span={4}>
                          <Avatar
                            size="default"
                            className="initial_capital"
                          >
                            {item?.assignee?.firstName?.substring(0, 1)}
                            {item?.assignee?.lastName?.substring(0, 1)}
                          </Avatar>
                        </Col>
                        <Col span={18} offset={1}>
                          <Row className="profile__info__user__detail initial_capital">
                            <Typography.Text
                              strong
                              className="office-visit__prev-visit__user-name"
                              ellipsis={
                                fullName.length > 30
                                  ? { tooltip: fullName }
                                  : false
                              }
                            >
                              {fullName}
                            </Typography.Text>
                          </Row>
                          <Row>
                            <Tag>{item?.assignee?.branch?.name}</Tag>
                          </Row>
                        </Col>
                      </Row>
                    ) : (
                      <div className="profile__info__item profile__info__user">
                        <Avatar
                          size="default"
                          icon={<UserOutlined />}
                          className="initial_capital margin-right-1"
                          alt="assignee"
                        />
                        <div className="profile__info__user__detail initial_capital">
                          <Typography.Title
                            level={3}
                            className="name-fixed-width"
                          >
                            Unassigned
                          </Typography.Title>
                        </div>
                      </div>
                    )}
                  </Col>
                  <Col span={6}>
                    <Typography.Text className="text-primary">
                      {item?.service?.name ?? 'N/A'}
                    </Typography.Text>
                    <br />
                    <Typography.Text>
                      {item?.visitPurpose?.visitPurpose ?? 'N/A'}
                    </Typography.Text>
                  </Col>
                  <Col span={4}>
                    <Typography.Text type="secondary">
                      {moment(item?.created_at).format('DD/MM/yyyy')}
                    </Typography.Text>
                  </Col>
                </Row>
              );
            })}
          </>
          :
          <div className="prev-visit-loader align-items-center">
            <Empty
              description="No previous visit found"
              image={Empty.PRESENTED_IMAGE_SIMPLE}
            />
          </div>
        }
      </Card>
    </Row>
  )
}

export { ContactPreviousVisitsCard };
