import React from "react";
import { DRAWER_WIDTH } from "@moxie/constants";
import { Drawer, Tabs, Typography } from "antd";
import CloseOutlined from "@ant-design/icons/CloseOutlined";
import { EnquiryViewForm } from "./enquiry-view-form";
import { useFetchEnquiryDetails } from "@crm/libs/hooks/enquiries/useFetchEnquiryDetails";
import Spin from "antd/es/spin";
import useContactEnquiriesActivities from "@crm/src/pages/contact-details/sections/details/components/hooks/use-contact-enquiries-activities";
import { EnquiryActivityView } from "./enquiry-activity-view";

interface IDrawerProps {
  visible: boolean;
  handleClose: () => void;
  enquiryId: string;
  triggerRefresh?: () => void;
  canUpdate?: boolean;
}

const ViewEnquiryDetailsDrawer = ({ visible, handleClose, enquiryId, triggerRefresh, canUpdate = true }: IDrawerProps) => {
  const { enquiryData, isLoading, refetch } = useFetchEnquiryDetails(enquiryId);
  const { activities, hasMore, next, isLoading: isContactEnquiryLoading } = useContactEnquiriesActivities(enquiryId);

  if (!activities || !enquiryData) {
    return null;
  }

  return (
    <Drawer
      width={DRAWER_WIDTH}
      title={
        <Typography.Title className="text-white" level={4}>Enquiry Form Details</Typography.Title>
      }
      visible={visible}
      closeIcon={<CloseOutlined style={{ color: '#fff' }} />}
      headerStyle={{ backgroundColor: '#4A2362', color: '#fff' }}
      onClose={handleClose}
      className="view-enquiry-drawer"
    >
      <Tabs>
        <Tabs.TabPane tab="Submission" key="submission">
          {
            enquiryData ? (
              <EnquiryViewForm
                enquiryData={enquiryData}
                refetch={refetch}
                triggerRefresh={triggerRefresh}
                isLoading={isLoading}
                canUpdate={canUpdate}
              />
            ) : <Spin />
          }
        </Tabs.TabPane>
        <Tabs.TabPane tab="Activities" key="activities">
          {
            activities ? (
              <EnquiryActivityView
                enquiryData={enquiryData}
                activities={activities}
                isLoading={isContactEnquiryLoading}
                hasMore={hasMore}
                next={next} />) : <Spin />
          }
        </Tabs.TabPane>
      </Tabs>
    </Drawer>
  )
}
export { ViewEnquiryDetailsDrawer };
