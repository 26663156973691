import { leadActions, store, useAppSelector } from '@lyra/core';
import Can, { PermissionAction, PermissionObjects } from '@lyra/privilege';
import { ARCHIVE, CONFIRM_MESSAGE, RESTORE, TEXT } from '@moxie/constants';
import { errorNotificationHandler } from '@moxie/shared';
import { popConfirm } from '@shared-components/elements';
import { ILeadProfileSection, IUser } from '@shared-components/models';
import { Col, Row, Skeleton } from 'antd';
import React, { useState } from 'react';
import { useDispatch } from 'react-redux';
import { LeadProfileAssignee } from './lead-profile-assignee';
import { LeadProfileContact } from './lead-profile-contact';
import { LeadProfilePersonal } from './lead-profile-personal';
import { bulkDiscontinueApplications, getActiveContactApplications } from '@lyra/services.api';

const LeadProfileSection: React.FC<ILeadProfileSection> = (
  props: ILeadProfileSection
) => {
  const { lead, loading, handleOpen, handleView } = props;
  const id = lead?.id;
  const leadFollowersId = lead?.followers?.map((d:any) => (d.id));
  const dispatch = useDispatch();
  const [editMode, setEditMode] = useState<boolean>(false);
  const [editFollowersMode, setEditFollowersMode] = useState<boolean>(false);
  const users = useAppSelector((store) => store.users.allData);
  const socket = useAppSelector((state) => state.socket.wss);
  const authUser: IUser | null = store.getState().auth.user;

  const onArchive = async () => {
    if (lead?.archived) {
      dispatch(leadActions.editLeadAction({ id, archived: false }));
    } else {
      const { data } = await getActiveContactApplications(id);
      if (data?.data.length > 0) {
        finalArchiveConfirmation(id, data?.data);
      }else{
        dispatch(leadActions.editLeadAction({ id, archived: true }));
      }
      setEditFollowersMode(false);
    }
  };

  const finalArchiveConfirmation = (userId: string, applications: any) => {
    popConfirm(
      ARCHIVE,
      CONFIRM_MESSAGE.CONTACT_ARCHIVE_FINAL,
      () => { archive(userId, applications) }
    )
  }

  const archive = async (id: string, applications: any) => {
    const applicationIds = applications.map((d: any) => d.client_applications_id)
    await bulkDiscontinueApplications(applicationIds);
    dispatch(
      leadActions.editLeadAction({
        id,
        archived: true,
        archived_by: authUser?.id,
      })
    )
  }


  const handleArchive = () => {
    popConfirm(
      !lead?.archived ? ARCHIVE : RESTORE,
      !lead?.archived
        ? `${CONFIRM_MESSAGE.ARCHIVE_LEAD} ${lead?.first_name} ${lead?.last_name}?`
        : `${CONFIRM_MESSAGE.RESTORE_LEAD} ${lead?.first_name} ${lead?.last_name}?`,
      onArchive
    );
  };

  const enableEdit = () => {
    if (Can(PermissionAction.Update, PermissionObjects.LeadsEntity)) {
      setEditMode(true);
    }
  };

  const toggleEditFollowersMode = (data: boolean) => {
    setEditFollowersMode(data);
  };

  const handleCancel = () => {
    setEditMode(false);
  };

  const handleSubmit = (values: string) => {
    if (values) {
      const assignedData = {
        id: lead.id,
        first_name: lead?.first_name,
        last_name: lead?.last_name,
        phone: lead?.phone,
        email: lead?.email,
        assignee_user_id: values,
      };
      lead?.id &&
        dispatch(leadActions.editLeadAction(assignedData, socket, authUser));
      setEditMode(false);
    } else {
      errorNotificationHandler(TEXT.SELECT_ASSIGNEE, 2);
    }
  };

  const handleFollowersSubmit = (data: any) => {
    if (data) {
      const assignedData = {
        id: lead.id,
        first_name: lead?.first_name,
        last_name: lead?.last_name,
        phone: lead?.phone,
        email: lead?.email,
        followers_id: data.followers_id,
        previous_followers: lead?.followers_id,
        assignee_id: lead?.assignee_id,
      };
      lead?.id &&
        dispatch(leadActions.editLeadAction(assignedData, socket, authUser));
      setEditFollowersMode(false);
    } else {
      errorNotificationHandler(TEXT.SELECT_ASSIGNEE, 2);
    }
  };

  return (
    <section className="profile">
      <div className="bg-white padding-left-3 padding-y1">
        <Row gutter={24} wrap={false}>
          <Col lg={7} md={8} className="border-right">
            {loading ? (
              <Skeleton avatar paragraph={{ rows: 3 }} active />
            ) : (
              <LeadProfilePersonal
                first_name={lead?.first_name}
                last_name={lead?.last_name}
                lead={lead}
                internal_id={lead?.internal_id}
                branch={lead?.branch}
                user={lead?.user}
                handleView={handleView}
                handleOpen={handleOpen}
                handleArchive={handleArchive}
              />
            )}
          </Col>
          <Col lg={7} md={8} className="border-right">
            {loading ? (
              <Skeleton paragraph={{ rows: 2 }} title={false} active />
            ) : (
              <LeadProfileContact email={lead?.email} phone={lead?.phone} />
            )}
          </Col>
          <Col lg={10} md={8}>
            {loading ? (
              <Skeleton paragraph={{ rows: 1 }} active />
            ) : (
              <LeadProfileAssignee
                editFollowersMode={editFollowersMode}
                editMode={editMode}
                assignee={lead?.assignee}
                enableEdit={enableEdit}
                handleSubmit={handleSubmit}
                handleCancel={handleCancel}
                followers={lead?.followers}
                followers_id={leadFollowersId}
                toggleEditFollowersMode={toggleEditFollowersMode}
                handleFollowersSubmit={handleFollowersSubmit}
                users={users}
                archived={lead?.archived}
              />
            )}
          </Col>
        </Row>
      </div>
    </section>
  );
};

export { LeadProfileSection };
