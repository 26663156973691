import React, { useState } from 'react';
import {
  BUTTON,
  CONFIRM_MESSAGE,
  CONFIRM_TITLE,
  DELETE_BTN,
} from '@moxie/constants';
import { Button, popConfirm } from '@shared-components/elements';
import { DeleteOutlined, EditOutlined, MoreOutlined } from '@ant-design/icons';
import { Dropdown, Menu } from 'antd';
import { AcademicRequirementDrawer } from './academic-requirement-drawer';
import { useDispatch } from 'react-redux';
import { productsActions, useAppSelector } from '@admin/core';
import { IProductEducationNameCard } from '@shared-components/models';

const ProductEducationNameCard: React.FC<IProductEducationNameCard> = ({
  id,
  form,
  productId,
}: IProductEducationNameCard) => {
  const [isOpen, setIsOpen] = useState(false);
  const dispatch = useDispatch();
  const productEducation = useAppSelector(
    (store) => store.product.product_education
  );
  const handleDrawer = () => {
    setIsOpen(!isOpen);
    dispatch(productsActions.getProductEducationRequest(id));
  };

  const handleRemove = () => {
    popConfirm(
      CONFIRM_TITLE.DELETE_ACADEMIC_REQUIREMENT,
      `${CONFIRM_MESSAGE.DELETE_ACADEMIC_REQUIREMENT}`,
      async () => {
        id && dispatch(productsActions.deleteProductEducationRequest(id));
      }
    );
  };

  const menu = (
    <Menu>
      <Menu.Item key="1" icon={<EditOutlined />} onClick={handleDrawer}>
        {BUTTON.EDIT}
      </Menu.Item>
      <Menu.Item key="2" icon={<DeleteOutlined />} onClick={handleRemove}>
        {DELETE_BTN}
      </Menu.Item>
    </Menu>
  );

  const handleDrawerClose = () => {
    form.resetFields();
    dispatch(productsActions.getProductEducationsClear());
    setIsOpen(false);
  };
  return (
    <>
      <Dropdown overlay={menu}>
        <Button
          type="text"
          className="table-cell-action-btn"
          icon={<MoreOutlined />}
        />
      </Dropdown>
      <AcademicRequirementDrawer
        productId={productId}
        isOpen={isOpen}
        onClose={handleDrawerClose}
        form={form}
        isEdit={true}
        initialData={productEducation}
      />
    </>
  );
};

export default ProductEducationNameCard;
