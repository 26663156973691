import { getUserPermissionsList } from '@crm/services.api';
import { errorNotificationHandler } from '@moxie/shared';
import { useQuery } from '@tanstack/react-query';

interface GroupBy<T> {
  [key: string | number]: T;
}
const groupBy = <T>(data: T[], fn: (val: T) => number | string): GroupBy<T> => {
  return data.reduce((acc, cur) => {
    acc[fn(cur)] = cur;
    return acc;
  }, {} as GroupBy<T>)
}

export const useFetchPermissionsList = () => {
  const { data, isLoading } = useQuery({
    queryKey: ['fetch-user-permissions-list'],
    queryFn: () => getUserPermissionsList(),
    onError: () => {
      errorNotificationHandler('Error fetching user permissions list.');
    },
  });

  const permissionMap = groupBy(data ?? [], (permission) => permission.name)
  return {
    permissionsList: data ?? [],
    permissionMap,
    permissionsListLoading: isLoading,
  }
}
