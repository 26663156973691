import React, { FC } from 'react';
import { Row, Col, Card, Divider, List, Typography } from 'antd';
import { CaretLeftOutlined } from '@ant-design/icons';
import { ImportLead } from './import-lead';
import { uploadFile } from '@lyra/services.api';
import { OPTIONAL_DATA, MANDATORY_DATA, TITLE_NO } from '@moxie/constants';
import { LEAD_DATA_TITLE, OPTIONAL, REQUIRED } from '@moxie/constants';
import { IExcelData } from '@shared-components/models';
import { errorNotificationHandler } from 'libs/shared/src/functions';

const DownloadTemplate: FC<IExcelData> = ({
  setExcelData,
  isDisabled,
}: IExcelData) => {
  const { Text } = Typography;

  const handleSubmit = async (file?: any) => {
    const formData = new FormData();
    formData.append('file', file.file);
    try {
      const data = await uploadFile(formData);
      setExcelData(data.data);
      file.onSuccess('done');
      isDisabled(false);
    } catch (err: any) {
      isDisabled(true);
      file.onError('error');
      errorNotificationHandler(err?.response?.data?.message);
    }
  };

  return (
    <>
      <Row gutter={16}>
        <Col span="12">
          {TITLE_NO.map((item, idx) => {
            return (
              <ImportLead index={item} key={idx} handleSubmit={handleSubmit} />
            );
          })}
        </Col>
        <Col span="12">
          <div className="import-lead-div">
            <CaretLeftOutlined className="import-lead-caret" />
            <Card className="import-lead-card">
              <div className="import-lead-div-div">
                <Text strong>
                  <p>{LEAD_DATA_TITLE}</p>
                </Text>

                <span className="import-lead-highlight-required-outline">
                  <Divider orientation="left" className="import-lead-divider">
                    <Text
                      type="secondary"
                      italic
                      className="import-lead-divider-text"
                    >
                      <strong className="import-lead-highlight-required">
                        {REQUIRED}
                      </strong>
                    </Text>
                  </Divider>
                </span>
                <List
                  size="small"
                  split={false}
                  dataSource={MANDATORY_DATA}
                  renderItem={(item) => (
                    <List.Item className="import-lead-list">
                      <Text strong>
                        {item.id}.&nbsp;{item.name}
                      </Text>
                    </List.Item>
                  )}
                />
                <Divider orientation="left" className="import-lead-divider">
                  <Text
                    type="secondary"
                    italic
                    className="import-lead-divider-text"
                  >
                    {OPTIONAL}
                  </Text>
                </Divider>
                <List
                  size="small"
                  split={false}
                  dataSource={OPTIONAL_DATA}
                  renderItem={(item) => (
                    <List.Item className="import-lead-list">
                      <Text strong>
                        {item.id}.&nbsp;{item.name}
                      </Text>
                    </List.Item>
                  )}
                />
              </div>
            </Card>
          </div>
        </Col>
      </Row>
    </>
  );
};

export { DownloadTemplate };
