import {
  COURSE_END_PLACEHOLDER,
  COURSE_START_PLACEHOLDER,
  DEGREE_LEVEL_LIST,
  DEGREE_lEVEL_PLACEHOLDER,
  GUTTER,
  INSTITUTION_PLACEHOLDER,
  LABEL,
  SUBJECT_AREA_EXTRA,
  SUBJECT_AREA_PLACEHOLDER,
  SUBJECT_PLACEHOLDER,
  TITLE,
} from '@moxie/constants';
import {
  Button,
  Col,
  DatePicker,
  Divider,
  Input,
  InputNumber,
  Radio,
  Row,
  Select,
} from 'antd';
import Title from 'antd/lib/typography/Title';
import { MinusOutlined, PlusOutlined } from '@ant-design/icons';
import React, { useEffect, useState } from 'react';
import FormList from 'antd/lib/form/FormList';
import {
  ILeadEducationBackgroundProps,
  ISubject,
} from '@shared-components/models';
import { FormItem, SearchableSelect } from '@shared-components/elements';
import { getEducationLevels } from '@lyra/services.api';
import { useSubjectDiscipline } from '@lyra/hooks';
import { useAppSelector } from '@lyra/core';
import Checkbox, { CheckboxChangeEvent } from 'antd/lib/checkbox';

const handleDegreeChange = () => {
  return;
};
const { Option } = Select;
const LeadFormEducationBackground: React.FC<ILeadEducationBackgroundProps> = ({
  EducationBackgroundData,
}: ILeadEducationBackgroundProps) => {
  const disciplines = useAppSelector(
    (state) => state.subject_discipline.disciplines
  );
  const [degreeLevels, setDegreeLevels] = useState([]);
  const [subjectOptions, setSubjectOptions] = useState<ISubject[]>([]);
  const [subjectAreaOptions, setSubjectAreaOptions] = useState<ISubject[]>([]);
  const [isGpa, setGpa] = useState(false);

  const handleChangePercentageCheckbox = (e: CheckboxChangeEvent) => {
    setGpa(!e.target.checked);
  };

  const handleChangeGPACheckbox = (e: CheckboxChangeEvent) => {
    setGpa(e.target.checked);
  };

  useSubjectDiscipline();

  useEffect(() => {
    if (disciplines.length > 0) {
      const mapData: ISubject[] = disciplines.map((item) => {
        return {
          name: item.discipline,
          value: item.id,
        };
      });
      setSubjectOptions(mapData);
    }
  }, [disciplines, setSubjectOptions]);

  useEffect(() => {
    setSubjectAreaOptions([]);
    getEducationLevels().then((res) => {
      setDegreeLevels(res.data.data);
    });
  }, [setSubjectAreaOptions]);

  const handleFormChange = (data: any) => {
    const formValue = { discipline: data };
    const haveKey = Object.keys(formValue).some(
      (key: string) => key === 'discipline'
    );
    if (haveKey) {
      if (formValue.discipline) {
        setSubjectAreaOptions(
          disciplines
            .find((item) => item.id === formValue.discipline)
            ?.subject_areas.map((item: any) => {
              return {
                name: item.name,
                value: item.id,
              };
            }) || []
        );
      } else {
        setSubjectAreaOptions([]);
      }
    }
  };

  return (
    <div className="created-leadForm-section ">
      <Title level={4}>{TITLE.EDUCATION_BACKGROUND}</Title>
      <Row gutter={GUTTER}>
        {EducationBackgroundData?.degree_title && (
          <Col md={8} xs={24}>
            <FormItem
              name="degree_title"
              label={LABEL.DEGREE_TITLE}
              rules={[{ required: true, max: 50, type: 'string' }]}
            >
              <Input />
            </FormItem>
          </Col>
        )}
        {EducationBackgroundData?.degree_level && (
          <Col md={8} xs={24}>
            <FormItem
              name="degree_level_id"
              label={LABEL.DEGREE_LEVEL}
              rules={[{ required: true }]}
            >
              <SearchableSelect optionFilter="search_prop">
                {degreeLevels &&
                  degreeLevels.map((item: any) => (
                    <Option
                      value={item.id}
                      key={item.name}
                      search_prop={item.name}
                    >
                      {item.name}
                    </Option>
                  ))}
              </SearchableSelect>
            </FormItem>
          </Col>
        )}
        {EducationBackgroundData?.institution && (
          <Col md={8} xs={24}>
            <FormItem
              name="institution"
              label={LABEL.INSTITUTION}
              rules={[{ required: true, type: 'string' }]}
            >
              <Input placeholder={INSTITUTION_PLACEHOLDER} />
            </FormItem>
          </Col>
        )}
        {EducationBackgroundData?.course_start && (
          <Col md={8} xs={24}>
            <FormItem name="course_start" label={LABEL.COURSE_START}>
              <DatePicker
                className="full-width"
                placeholder={COURSE_START_PLACEHOLDER}
              />
            </FormItem>
          </Col>
        )}
        {EducationBackgroundData?.course_end && (
          <Col md={8} xs={24}>
            <FormItem name="course_end" label={LABEL.COURSE_END}>
              <DatePicker
                className="full-width"
                placeholder={COURSE_END_PLACEHOLDER}
              />
            </FormItem>
          </Col>
        )}
        {EducationBackgroundData?.subject_area && (
          <Col md={8} xs={24}>
            <FormItem name="discipline" label={LABEL.SUBJECT}>
              <SearchableSelect
                onChange={handleFormChange}
                placeholder={SUBJECT_PLACEHOLDER}
                optionFilterProp="children"
                filterOption={(input, option: any) =>
                  option.props.children
                    .toLowerCase()
                    .indexOf(input.toLowerCase()) >= 0
                }
              >
                {subjectOptions.map((item) => (
                  <Option value={item.value} key={item.value}>
                    {item.name}
                  </Option>
                ))}
              </SearchableSelect>
            </FormItem>
          </Col>
        )}
        {EducationBackgroundData?.subject && (
          <Col md={8} xs={24}>
            <FormItem
              name="subject_area"
              label={LABEL.SUBJECT_AREA}
              extra={SUBJECT_AREA_EXTRA}
            >
              <SearchableSelect
                placeholder={SUBJECT_AREA_PLACEHOLDER}
                optionFilterProp="children"
                filterOption={(input, option: any) =>
                  option.props.children
                    .toLowerCase()
                    .indexOf(input.toLowerCase()) >= 0
                }
              >
                {subjectAreaOptions &&
                  subjectAreaOptions.map((item) => {
                    return (
                      <Option value={item.value} key={item.value}>
                        {item.name}
                      </Option>
                    );
                  })}
              </SearchableSelect>
            </FormItem>
          </Col>
        )}
        {EducationBackgroundData?.academic_score && (
          <Col md={8} xs={24}>
            {isGpa ? (
              <Row gutter={GUTTER}>
                <Col span={12}>
                  <FormItem
                    name="secured_gpa"
                    rules={[{ min: 0, max: 10, type: 'number' }]}
                    label={LABEL.GPA}
                  >
                    <InputNumber className="full-width" type="number" />
                  </FormItem>
                </Col>
                <Col span={12}>
                  <FormItem
                    name="total_gpa"
                    rules={[{ min: 0, max: 10, type: 'number' }]}
                    label={LABEL.TOTAL_GPA}
                  >
                    <InputNumber className="full-width" type="number" />
                  </FormItem>
                </Col>
              </Row>
            ) : (
              <FormItem
                name="percentage"
                label={LABEL.PERCENTAGE}
                rules={[{ min: 0, max: 100, type: 'number' }]}
              >
                <InputNumber type="number" className="full-width" />
              </FormItem>
            )}
            <Col span={24}>
              <Checkbox
                onChange={handleChangePercentageCheckbox}
                checked={!isGpa}
              >
                {LABEL.PERCENTAGE}
              </Checkbox>
              <Checkbox onChange={handleChangeGPACheckbox} checked={isGpa}>
                {LABEL.GPA}
              </Checkbox>
            </Col>
          </Col>
        )}
      </Row>
      <FormList name="users">
        {(fields, { add, remove }) => (
          <>
            {fields.map(({ name }) => (
              <>
                <Divider plain />
                <Row gutter={GUTTER}>
                  {EducationBackgroundData?.degree_title && (
                    <Col md={8} xs={24}>
                      <FormItem
                        name="degree_title"
                        label={LABEL.DEGREE_TITLE}
                        rules={[{ required: true, max: 50, type: 'string' }]}
                      >
                        <Input />
                      </FormItem>
                    </Col>
                  )}
                  {EducationBackgroundData?.degree_level && (
                    <Col md={8} xs={24}>
                      <FormItem
                        name="degree_level"
                        label={LABEL.DEGREE_LEVEL}
                        rules={[{ required: true, type: 'string' }]}
                      >
                        <Select
                          onChange={handleDegreeChange}
                          placeholder={DEGREE_lEVEL_PLACEHOLDER}
                        >
                          {DEGREE_LEVEL_LIST.map((degree: any) => {
                            return (
                              <Option value={degree.name} key={degree.value}>
                                {degree.name}
                              </Option>
                            );
                          })}
                        </Select>
                      </FormItem>
                    </Col>
                  )}
                  {EducationBackgroundData?.institution && (
                    <Col md={8} xs={24}>
                      <FormItem
                        name="institution"
                        label={LABEL.INSTITUTION}
                        rules={[{ required: true, type: 'string' }]}
                      >
                        <Input placeholder={INSTITUTION_PLACEHOLDER} />
                      </FormItem>
                    </Col>
                  )}
                  {EducationBackgroundData?.course_start && (
                    <Col md={8} xs={24}>
                      <FormItem name="course_start" label={LABEL.COURSE_START}>
                        <DatePicker
                          className="full-width"
                          placeholder={COURSE_START_PLACEHOLDER}
                        />
                      </FormItem>
                    </Col>
                  )}
                  {EducationBackgroundData?.course_end && (
                    <Col md={8} xs={24}>
                      <FormItem name="course_end" label={LABEL.COURSE_END}>
                        <DatePicker
                          className="full-width"
                          placeholder={COURSE_END_PLACEHOLDER}
                        />
                      </FormItem>
                    </Col>
                  )}
                  {EducationBackgroundData?.subject_area && (
                    <Col md={8} xs={24}>
                      <FormItem
                        name="subject_area"
                        label={LABEL.SUBJECT_AREA}
                        rules={[{ type: 'string' }]}
                      >
                        <Select
                          onChange={handleDegreeChange}
                          placeholder={DEGREE_lEVEL_PLACEHOLDER}
                        >
                          {DEGREE_LEVEL_LIST.map((degree: any) => {
                            return (
                              <Option value={degree.name} key={degree.value}>
                                {degree.name}
                              </Option>
                            );
                          })}
                        </Select>
                      </FormItem>
                    </Col>
                  )}
                  {EducationBackgroundData?.subject && (
                    <Col md={8} xs={24}>
                      <FormItem
                        name="subject"
                        label={LABEL.SUBJECT}
                        rules={[{ type: 'string' }]}
                      >
                        <Select
                          onChange={handleDegreeChange}
                          placeholder={DEGREE_lEVEL_PLACEHOLDER}
                        >
                          {DEGREE_LEVEL_LIST.map((degree: any) => {
                            return (
                              <Option value={degree.name} key={degree.value}>
                                {degree.name}
                              </Option>
                            );
                          })}
                        </Select>
                      </FormItem>
                    </Col>
                  )}
                  {EducationBackgroundData?.academic_score && (
                    <Col md={8} xs={24}>
                      <FormItem
                        name="academic_score"
                        label={LABEL.ACADEMIC_SCORE}
                      >
                        <Row gutter={GUTTER}>
                          <Col md={14}>
                            <Radio.Group>
                              <Radio value={'gpa'}>{LABEL.GPA}</Radio>
                              <Radio value={'percentage'}>
                                {LABEL.PERCENTAGE}
                              </Radio>
                            </Radio.Group>
                          </Col>
                          <Col md={10}>
                            <Input />
                          </Col>
                        </Row>
                      </FormItem>
                    </Col>
                  )}
                </Row>
                <Row gutter={GUTTER}>
                  <Col md={20} xs={16}>
                    <Divider dashed />
                  </Col>
                  <Col md={4} xs={8}>
                    <Button
                      type="link"
                      danger
                      onClick={() => remove(name)}
                      block
                      icon={<MinusOutlined />}
                    >
                      Remove
                    </Button>
                  </Col>
                </Row>
              </>
            ))}
            <FormItem>
              <Row gutter={GUTTER}>
                <Col md={20} xs={16}>
                  <Divider dashed />
                </Col>
                <Col md={4} xs={8}>
                  <Button
                    type="link"
                    onClick={() => add()}
                    block
                    icon={<PlusOutlined />}
                  >
                    Add
                  </Button>
                </Col>
              </Row>
            </FormItem>
          </>
        )}
      </FormList>
      <Divider plain />
    </div>
  );
};
export { LeadFormEducationBackground };
