import { Card, Col, Form, Row, Space } from 'antd';
import {
  CANCEL_BTN,
  CATEGORY_ITEMS,
  COUNTRY_CURRENCY,
  DELETE_BTN,
  EDIT_BTN,
  GUTTER,
  LABEL,
  NOT_AVAILABLE_MESSAGE,
  regex,
  SAVE_BTN,
  TEXT,
  VALIDATION_MESSAGE,
} from '@moxie/constants';
import { useForm } from 'antd/lib/form/Form';
import { Button, Input, Option, SearchableSelect } from '@moxie/shared';
import { DeleteOutlined, PlusOutlined } from '@ant-design/icons';
import React, { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { leadActions, useAppSelector } from '@lyra/core';
import { IGetPopupContainer } from '@shared-components/models';

const FinancialDetailsTab: React.FC<any> = (props: any) => {
  const { id, financialDetails, archived } = props;
  const dispatch = useDispatch();
  const [form] = useForm();
  const [disabled, setDisabled] = useState<boolean>(true);
  const [isEdit, setIsEdit] = useState<boolean>(false);
  const [isCanceled, setIsCanceled] = useState<boolean>(false);
  const { user, socket } = useAppSelector((state) => ({
    user: state.auth.user,
    socket: state.socket.wss,
  }));

  const onFieldsChange = (allFields: string | any[]) => {
    if (allFields.length > 0) {
      setDisabled(false);
    }
  };

  const handleSubmit = (data: any) => {
    dispatch(
      leadActions.editLeadAction(
        { ...data, id: id },
        socket,
        user,
        'financial details',
        'other-details'
      )
    );
    setIsEdit(false);
  };

  useEffect(() => {
    if (financialDetails.length > 0) {
      form.setFieldsValue({ financialDetails: financialDetails });
    } else {
      form.resetFields();
    }
  }, [financialDetails, form, isCanceled]);

  useEffect(() => {
    if (financialDetails.length < 1) {
      setIsEdit(true);
    }
  }, [financialDetails]);

  return (
    <Card
      title={CATEGORY_ITEMS[4].name}
      extra={[
        <>
          {isEdit ? (
            <>
              {!archived && (
                <Space>
                  <Button
                    key="save"
                    disabled={disabled}
                    type="primary"
                    onClick={() => form.submit()}
                  >
                    {SAVE_BTN}
                  </Button>
                  <Button
                    key="cancel"
                    onClick={() => {
                      setIsEdit(false);
                      setIsCanceled(!isCanceled);
                    }}
                  >
                    {CANCEL_BTN}
                  </Button>
                </Space>
              )}
            </>
          ) : (
            <>
              {!archived && (
                <Button
                  key="edit"
                  type="primary"
                  onClick={() => setIsEdit(true)}
                >
                  {EDIT_BTN}
                </Button>
              )}
            </>
          )}
        </>,
      ]}
    >
      <Form
        onFinish={handleSubmit}
        initialValues={{
          financialDetails: [{}],
        }}
        form={form}
        scrollToFirstError={true}
        onFieldsChange={onFieldsChange}
        labelCol={{ lg: 7, xl: 10, span: 8 }}
        wrapperCol={{ span: 14, offset: 1 }}
      >
        <Form.List name="financialDetails">
          {(fields, { add, remove }) => {
            return (
              <>
                {fields.map(({ key, name }) => {
                  return (
                    <Card key={key} className="margin-bottom-1">
                      <Row gutter={GUTTER}>
                        <Col span={24} xl={12} xxl={8}>
                          <Form.Item
                            name={[name, 'source_name']}
                            className="custom_label_style"
                            label={LABEL.SOURCE_NAME}
                            rules={[{ required: true }]}
                          >
                            {financialDetails.length === 0 || isEdit ? (
                              <Input
                                className="note-title-input"
                                disabled={!isEdit}
                              />
                            ) : financialDetails[name]?.source_name ? (
                              <div className="initial_capital">
                                {financialDetails[name]?.source_name}
                              </div>
                            ) : (
                              NOT_AVAILABLE_MESSAGE
                            )}
                          </Form.Item>
                        </Col>
                        <Col span={24} xl={12} xxl={8}>
                          <Form.Item
                            name={[name, 'relationship']}
                            className="custom_label_style"
                            label={LABEL.RELATIONSHIP}
                            rules={[
                              { required: true },
                              {
                                pattern: new RegExp(regex.ONLY_STRING),
                                message: VALIDATION_MESSAGE.INVALID_TEXT_INPUT,
                              },
                            ]}
                          >
                            {financialDetails.length === 0 || isEdit ? (
                              <Input
                                className="note-title-input"
                                disabled={!isEdit}
                              />
                            ) : financialDetails[name]?.relationship ? (
                              <div className="initial_capital">
                                {financialDetails[name]?.relationship}
                              </div>
                            ) : (
                              NOT_AVAILABLE_MESSAGE
                            )}
                          </Form.Item>
                        </Col>
                        <Col span={24} xl={12} xxl={8}>
                          <Form.Item
                            name={[name, 'bank_name']}
                            className="custom_label_style"
                            label={LABEL.BANK_NAME}
                            rules={[
                              { required: true },
                              {
                                pattern: new RegExp(regex.ONLY_STRING),
                                message: VALIDATION_MESSAGE.INVALID_TEXT_INPUT,
                              },
                            ]}
                          >
                            {financialDetails.length === 0 || isEdit ? (
                              <Input
                                className="note-title-input"
                                disabled={!isEdit}
                              />
                            ) : financialDetails[name]?.bank_name ? (
                              <div className="initial_capital">
                                {financialDetails[name]?.bank_name}
                              </div>
                            ) : (
                              NOT_AVAILABLE_MESSAGE
                            )}
                          </Form.Item>
                        </Col>
                        <Col span={24} xl={12} xxl={8}>
                          <Form.Item
                            name={[name, 'account_type']}
                            className="custom_label_style"
                            label={LABEL.ACCOUNT_TYPE}
                            rules={[
                              { required: true },
                              {
                                pattern: new RegExp(regex.ONLY_STRING),
                                message: VALIDATION_MESSAGE.INVALID_TEXT_INPUT,
                              },
                            ]}
                          >
                            {financialDetails.length === 0 || isEdit ? (
                              <Input
                                className="note-title-input"
                                disabled={!isEdit}
                              />
                            ) : financialDetails[name]?.account_type ? (
                              <div className="initial_capital">
                                {financialDetails[name]?.account_type}
                              </div>
                            ) : (
                              NOT_AVAILABLE_MESSAGE
                            )}
                          </Form.Item>
                        </Col>
                        <Col span={24} xl={12} xxl={8}>
                          <div id="currency" className="relative">
                            <Form.Item
                              name={[name, 'currency']}
                              className="custom_label_style"
                              label={LABEL.CURRENCY}
                              rules={[{ required: true }]}
                            >
                              {financialDetails.length === 0 || isEdit ? (
                                <SearchableSelect
                                  className="note-title-input"
                                  allowClear={false}
                                  disabled={!isEdit}
                                  getPopupContainer={(): IGetPopupContainer =>
                                    document.getElementById('currency')
                                  }
                                >
                                  {COUNTRY_CURRENCY.map((item) => (
                                    <Option key={item.id} value={item.name}>
                                      {item.name}
                                    </Option>
                                  ))}
                                </SearchableSelect>
                              ) : financialDetails[name]?.currency ? (
                                financialDetails[name]?.currency
                              ) : (
                                NOT_AVAILABLE_MESSAGE
                              )}
                            </Form.Item>
                          </div>
                        </Col>
                        <Col span={24} xl={12} xxl={8}>
                          <Form.Item
                            name={[name, 'bank_balance']}
                            className="custom_label_style"
                            label={LABEL.BANK_BALANCE}
                            rules={[
                              { required: true },
                              {
                                pattern: new RegExp(regex.ONLY_NUMBER),
                                message:
                                  VALIDATION_MESSAGE.INVALID_NUMBER_INPUT,
                              },
                            ]}
                          >
                            {financialDetails.length === 0 || isEdit ? (
                              <Input
                                className="note-title-input"
                                disabled={!isEdit}
                              />
                            ) : financialDetails[name]?.bank_balance ? (
                              financialDetails[name]?.bank_balance
                            ) : (
                              NOT_AVAILABLE_MESSAGE
                            )}
                          </Form.Item>
                        </Col>
                      </Row>
                      {fields.length > 1 ? (
                        <Row justify="end">
                          <Col>
                            <Button
                              type="primary"
                              danger
                              icon={<DeleteOutlined />}
                              onClick={() => {
                                setIsEdit(true);
                                remove(name);
                              }}
                            >
                              {DELETE_BTN}
                            </Button>
                          </Col>
                        </Row>
                      ) : null}
                    </Card>
                  );
                })}
                <Row justify="end">
                  <Form.Item>
                    <Button
                      type="link"
                      onClick={() => {
                        add();
                        setIsEdit(true);
                      }}
                      icon={<PlusOutlined />}
                    >
                      {TEXT.ADD_ANOTHER_DETAIL}
                    </Button>
                  </Form.Item>
                </Row>
              </>
            );
          }}
        </Form.List>
      </Form>
    </Card>
  );
};

export { FinancialDetailsTab };
