import React, { ReactNode, useState } from 'react';
import {
  ASSIGNEE_LABEL,
  LABEL,
  TASKS_TITLE,
  TEXT,
  TASK_STATUS_LIST,
  ADD_BTN,
} from '@moxie/constants';
import { Button, capitalizeFirstLetter } from '@moxie/shared';
import { Table } from '@shared-components/elements';
import { ITable, ITasks } from '@shared-components/models';
import { Badge, Select, Typography } from 'antd';
import { ColumnsType } from 'antd/lib/table';
import moment, { Moment } from 'moment';
import { TaskNameCard } from './task-name-card';
import { useDispatch } from 'react-redux';
import { tasksActions } from '@lyra/core';
import { PlusOutlined } from '@ant-design/icons';
import { TaskAssigneeCard } from './task-assignee-card';

const { Option } = Select;
const { Text } = Typography;
const TasksTable: React.FC<ITable> = ({
  filterOptions,
  action,
  actionBar,
  deleteData,
  showDrawer,
  refresh,
  triggerRefresh,
  filter,
}: ITable) => {
  const [taskId, setTaskId] = useState('');

  const statusList = (value: string) => {
    switch (value) {
      case 'to-do':
        return (
          <Text className="text-warning">
            <Badge className="text-warning" status="warning" />
            <strong>{TEXT.TODO}</strong>
          </Text>
        );
      case 'in-progress':
        return (
          <Text className="text-processing">
            <Badge className="text-processing" status="processing" />
            <strong>{TEXT.IN_PROGRESS}</strong>
          </Text>
        );
      case 'done':
        return (
          <Text className="text-success">
            <Badge className="text-success" status="success" />

            <strong>{TEXT.STATUS_DONE}</strong>
          </Text>
        );
      default:
        return (
          <Text className="text-warning" onClick={() => setTaskId(taskId)}>
            <Badge className="text-warning" status="warning" />
            <b> {TEXT.TODO}</b>
          </Text>
        );
    }
  };

  const tasksColumn: ColumnsType<any> = [
    {
      title: TEXT.TASK_NUMBER,
      width: 120,
      dataIndex: 'task_count',
    },
    {
      title: TEXT.TITLE,
      sorter: () => 0,
      width: 300,
      render(_text: string, row: ITasks): ReactNode {
        return (
          <TaskNameCard
            data={row}
            showDrawer={showDrawer}
            deleteData={deleteData}
            actionBar={actionBar}
          />
        );
      },
    },
    {
      title: TEXT.DESCRIPTION,
      dataIndex: 'description',
      render(description: string): ReactNode {
        return (
          <div className="task-description-column">
            <Typography.Paragraph ellipsis={true}>
              {description}
            </Typography.Paragraph>
          </div>
        );
      },
    },
    {
      title: ASSIGNEE_LABEL,
      width: 300,
      render(data: ITasks): ReactNode {
        return <TaskAssigneeCard data={data} triggerRefresh={triggerRefresh} />;
      },
    },
    {
      title: TEXT.PRIORITY,
      width: 100,
      dataIndex: 'priority',
      render(priority: string): ReactNode {
        return <div>{priority && capitalizeFirstLetter(priority)}</div>;
      },
    },
    {
      title: LABEL.DUE_DATE,
      width: 200,
      dataIndex: 'due_date',
      render(due_date: Moment): ReactNode {
        return (
          <div>{due_date ? moment(due_date).format('YYYY-MM-DD') : '-'}</div>
        );
      },
    },
    {
      title: LABEL.STATUS,
      width: 200,
      render(data: ITasks): ReactNode {
        return (
          <StatusComponent
            triggerRefresh={triggerRefresh}
            taskId={taskId}
            data={data}
            statusList={statusList}
            setTaskId={setTaskId}
          />
        );
      },
    },
  ];

  return (
    <div className="bg-white padding-top-2">
      <Table
        action={action}
        filterOptions={filterOptions}
        tableName={TASKS_TITLE}
        columns={tasksColumn}
        refresh={refresh}
        filter={filter}
        tableButtons={
          <Button
            data-testid="add_btn"
            type="primary"
            onClick={() => showDrawer && showDrawer()}
          >
            <PlusOutlined /> {ADD_BTN}
          </Button>
        }
      />
    </div>
  );
};

const StatusComponent: React.FC<any> = (props: any) => {
  const dispatch = useDispatch();
  const { data, statusList, triggerRefresh } = props;

  const handleChange = (value: any) => {
    const statusData = { id: data.id, status: value };
    dispatch(tasksActions.updateTaskStatusRequest(statusData));
    triggerRefresh && triggerRefresh();
  };
  return (
    <div>
      <Select
        clearIcon={true}
        bordered={false}
        defaultValue={data.status}
        className="task-status-list"
        autoFocus={true}
        showArrow={true}
        onChange={handleChange}
      >
        {TASK_STATUS_LIST.map((list: any) => (
          <Option
            className="task-status-hover"
            key={data.id}
            value={list.value}
          >
            {statusList(list.value)}
          </Option>
        ))}
      </Select>
    </div>
  );
};
export { TasksTable, StatusComponent };
