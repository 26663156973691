import { InfoCircleOutlined } from '@ant-design/icons';
import {
  validateCompanyCode,
  validateCompanyDomain,
} from '@luzon/services.api';
import {
  CODE_INVALID,
  COMPANY_CODE,
  COMPANY_CODE_TAKEN,
  DOMAIN_INVALID,
  DOMAIN_TAKEN,
  GUTTER,
  COMPANY_NAME_LABEL,
  COMPANY_NAME_PLACEHOLDER,
  INSTITUTION_PHONE_NUMBER_LABEL,
  INSTITUTION_PHONE_NUMBER_PLACEHOLDER,
  INSTITUTION_POSITION_PLACEHOLDER,
  INSTITUTION_SUB_DOMAIN_PLACEHOLDER,
  PHONE_NUMBER_INVALID,
  ROLES,
  YOUR_ROLE_LABEL,
} from '@moxie/constants';
import { errorHandler } from '@moxie/utils';
import { FormItem, Input, PhoneInput } from '@shared-components/elements';
import {
  IForm,
  IGetPopupContainer,
  IInstitution,
} from '@shared-components/models';
import { Col, Row, Select, Tooltip } from 'antd';
import React, { useEffect, useState } from 'react';

const { Option } = Select;

interface IInstitutionForm<T> extends IForm<T> {
  dialCode: string | undefined;
  setDialCode: any;
  setCompanyDomainValidationStatus: any;
  companyCodeValidationStatus: any;
  companyDomainValidationStatus: any;
  setCompanyCodeValidationStatus: any;
  setDisabled: any;
  companyId?: string;
}

const InstitutionDetailsForm: React.FC<IInstitutionForm<IInstitution>> = ({
  dialCode,
  isEdit,
  form,
  setDialCode,
  setCompanyDomainValidationStatus,
  companyCodeValidationStatus,
  companyDomainValidationStatus,
  setCompanyCodeValidationStatus,
  companyId,
  setDisabled,
}: IInstitutionForm<IInstitution>) => {
  const [domain, setDomain] = useState('');

  const handleChange = (e: any) => {
    setDomain(e.target.value);
  };

  const handleCountryCodeChange = (input: string) => {
    setDialCode(input);
    setDisabled(false);
  };

  async function handleSubDomainChange(
    rule: any,
    value: any
  ): Promise<boolean | any> {
    value = value?.toLowerCase();
    if (!value) {
      setCompanyDomainValidationStatus('error');
      return Promise.resolve(true);
    }
    const regex = new RegExp(/^[a-z]+$/i);
    if (!regex.test(value)) {
      setCompanyDomainValidationStatus('error');
      throw new Error(DOMAIN_INVALID);
    } else {
      setCompanyDomainValidationStatus('validating');
      const result = await validateDomain(value);
      if (result) {
        setCompanyDomainValidationStatus('success');
        return Promise.resolve(true);
      } else {
        setCompanyDomainValidationStatus('error');
        throw new Error(DOMAIN_TAKEN);
      }
    }
  }

  const handleCodeChange = async (
    rule: any,
    value: any
  ): Promise<boolean | any> => {
    value = value?.toLowerCase();
    if (!value) {
      setCompanyCodeValidationStatus('error');
      return Promise.resolve(true);
    }
    const regex = new RegExp(/^[a-z]+$/i);
    if (!regex.test(value)) {
      setCompanyCodeValidationStatus('error');
      throw new Error(CODE_INVALID);
    } else {
      setCompanyCodeValidationStatus('validating');
      const result = await validateCode(value);
      if (result) {
        setCompanyCodeValidationStatus('success');
        return Promise.resolve(true);
      } else {
        setCompanyCodeValidationStatus('error');
        throw new Error(COMPANY_CODE_TAKEN);
      }
    }
  };

  const validateDomain = async (value: string) => {
    return validateCompanyDomain(value)
      .then((res: any) => {
        return !!res?.data?.success;
      })
      .catch((err) => {
        setCompanyDomainValidationStatus('error');
      });
  };

  const ruleDomain = () => {
    if (isEdit) {
      return [{ required: true, max: 20 }];
    } else {
      return [
        { required: true, max: 20 },
        { validator: handleSubDomainChange },
      ];
    }
  };

  const validateCode = async (value: string) => {
    return validateCompanyCode({ code: value, id: companyId })
      .then((res: any) => {
        return !!res?.data?.success;
      })
      .catch((err) => {
        setCompanyCodeValidationStatus('error');
      });
  };

  useEffect(() => {
    return;
  }, [form, isEdit]);
  return (
    <>
      <div>
        <Row gutter={GUTTER}>
          <Col span="8">
            <FormItem
              name="company_name"
              label={COMPANY_NAME_LABEL}
              rules={[{ required: true, max: 100, type: 'string' }]}
            >
              <Input placeholder={COMPANY_NAME_PLACEHOLDER} />
            </FormItem>
          </Col>
          <Col span="8">
            <FormItem
              name="company_code"
              validateStatus={companyCodeValidationStatus}
              label={COMPANY_CODE}
              rules={[
                { required: true, max: 5 },
                { validator: handleCodeChange },
              ]}
            >
              <Input
                maxLength={5}
                placeholder={COMPANY_CODE}
                suffix={
                  <Tooltip title="Company code distinguishes contact and application ID's for each tenant">
                    <InfoCircleOutlined />
                  </Tooltip>
                }
              />
            </FormItem>
          </Col>
          <Col span="8">
            <FormItem
              shouldUpdate
              name="company_domain"
              extra={`${
                form.getFieldValue('company_domain')
                  ? form.getFieldValue('company_domain')
                  : 'domainName'
              }.${process.env.NX_APP_DOMAIN}`}
              validateStatus={isEdit ? '' : companyDomainValidationStatus}
              label="Domain name"
              rules={ruleDomain()}
            >
              <Input
                onChange={handleChange}
                disabled={isEdit}
                maxLength={20}
                placeholder={INSTITUTION_SUB_DOMAIN_PLACEHOLDER}
              />
            </FormItem>
          </Col>
        </Row>
        <div id="position_list">
          <FormItem
            name="position"
            label={YOUR_ROLE_LABEL}
            rules={[{ required: true, type: 'string' }]}
          >
            <Select
              placeholder={INSTITUTION_POSITION_PLACEHOLDER}
              getPopupContainer={(): IGetPopupContainer =>
                document.getElementById('position_list')
              }
            >
              {ROLES.map((item) => {
                return (
                  <Option value={item.value} key={item.value}>
                    {item.name}
                  </Option>
                );
              })}
            </Select>
          </FormItem>
        </div>
        <FormItem
          name="phone"
          label={INSTITUTION_PHONE_NUMBER_LABEL}
          rules={[
            {
              required: true,
              pattern: new RegExp(/^[0-9]+$/),
              message: PHONE_NUMBER_INVALID,
            },
          ]}
        >
          <PhoneInput
            placeholder={INSTITUTION_PHONE_NUMBER_PLACEHOLDER}
            type="string"
            onChangeDialCode={handleCountryCodeChange}
            dialCode={dialCode || '+977'}
          />
        </FormItem>
        {!isEdit && (
          <FormItem
            name="branch_name"
            label="Branch Name"
            rules={[{ required: true, max: 100, type: 'string' }]}
          >
            <Input placeholder={'Main Branch'} type="string" />
          </FormItem>
        )}
      </div>
    </>
  );
};

export { InstitutionDetailsForm };
