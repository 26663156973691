import NoteForm from '@crm/src/shared/note/note-form';
import { Button, Form } from 'antd';
import React, { useContext } from 'react';
import SaveOutlined from '@ant-design/icons/SaveOutlined';
import { FormInstance } from 'antd/es/form/Form';
import { Task, TaskFormFields } from '@model/crm/note.model';
import { NoteFormContext } from '@crm/src/shared/note/note-form-provider';
import { Editor } from '@tiptap/react';

interface Props {
  onCancel?: () => void;
  // eslint-disable-next-line no-unused-vars
  onSubmit?: (val: TaskFormFields) => void;
  editor: Editor | null;
  form: FormInstance
  task?: Task;
}
const SessionNoteForm: React.FC<Props> = ({ onCancel, onSubmit, editor, form, task }) => {

  const { noteMode } = useContext(NoteFormContext);

  return (
    <Form form={form} onFinish={onSubmit}>
      <NoteForm
        editor={editor}
        form={form}
        task={task}
        actions={
          <>
            <Button type="primary" htmlType="submit" icon={<SaveOutlined />} disabled={noteMode !== undefined} loading={noteMode === 'save'}>
              Save
            </Button>

            <Button htmlType="button" onClick={onCancel} disabled={noteMode !== undefined}>
              Cancel
            </Button>
          </>
        }

      />
    </Form>
  )
}

export default SessionNoteForm;
