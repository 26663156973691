const alias = 'WORKFLOW_TYPE';

export const FETCH_WORKFLOW_TYPES_REQUEST = `FETCH_${alias}S_REQUEST`;
export const FETCH_WORKFLOW_TYPES_SUCCESS = `FETCH_${alias}S_SUCCESS`;
export const FETCH_WORKFLOW_TYPES_FAILURE = `FETCH_${alias}S_FAILURE`;

export const FETCH_WORKFLOW_TYPE_REQUEST = `FETCH_${alias}_REQUEST`;
export const FETCH_WORKFLOW_TYPE_SUCCESS = `FETCH_${alias}_SUCCESS`;
export const FETCH_WORKFLOW_TYPE_FAILURE = `FETCH_${alias}_FAILURE`;

export const ADD_WORKFLOW_TYPE_REQUEST = `ADD_${alias}_REQUEST`;
export const ADD_WORKFLOW_TYPE_SUCCESS = `ADD_${alias}_SUCCESS`;
export const ADD_WORKFLOW_TYPE_FAILURE = `ADD_${alias}_FAILURE`;

export const UPDATE_WORKFLOW_TYPE_REQUEST = `UPDATE_${alias}_REQUEST`;
export const UPDATE_WORKFLOW_TYPE_SUCCESS = `UPDATE_${alias}_SUCCESS`;
export const UPDATE_WORKFLOW_TYPE_FAILURE = `UPDATE_${alias}_FAILURE`;

export const DELETE_WORKFLOW_TYPE_REQUEST = `DELETE_${alias}_REQUEST`;
export const DELETE_WORKFLOW_TYPE_SUCCESS = `DELETE_${alias}_SUCCESS`;
export const DELETE_WORKFLOW_TYPE_FAILURE = `DELETE_${alias}_FAILURE`;

export const CLEAR_WORKFLOW_TYPE_FETCH = `CLEAR_${alias}_FETCH`;
