import { IAppointment, IResponse } from '@shared-components/models';
import moment from 'moment-timezone';
import * as actionTypes from './lead-appointments.constant';

export const LEAD_APPOINTMENT_FEATURE_KEY = 'lead_appointment';

export interface IAppointmentState {
  appointment: IAppointment;
  addedAppointmentData: IResponse;
  appointmentData: IAppointment[];
  upcomingData: IAppointment[];
  pastData: IAppointment[];
  error: boolean | null;
  loading: boolean;
}

export const initialAppointmentData: IAppointmentState = {
  appointment: {},
  appointmentData: [],
  upcomingData: [],
  pastData: [],
  addedAppointmentData: {},
  error: null,
  loading: false,
};

export const leadAppointmentReducer = (
  state = initialAppointmentData,
  action: any
) => {
  switch (action.type) {
    case actionTypes.GET_LEAD_APPOINTMENTS_REQUEST:
      return {
        ...state,
        loading: true,
      };

    case actionTypes.GET_LEAD_APPOINTMENTS_SUCCESS:
      return {
        ...state,
        loading: false,
        appointmentData: [...action.payload],
        upcomingData: action.payload.filter(
          (item: { date: moment.MomentInput }) =>
            !moment(item.date).utc().isBefore() && item
        ),
        pastData: action.payload.filter(
          (item: { date: moment.MomentInput }) =>
            moment(item.date).utc().isBefore() && item
        ),
      };

    case actionTypes.GET_LEAD_APPOINTMENTS_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.payload,
      };
    case actionTypes.GET_LEAD_APPOINTMENTS_CLEAR:
      return {
        ...state,
        appointmentData: [],
        addedAppointmentData: {},
        loading: false,
        error: null,
      };

    case actionTypes.GET_LEAD_APPOINTMENT_REQUEST:
      return {
        ...state,
        loading: true,
      };

    case actionTypes.GET_LEAD_APPOINTMENT_SUCCESS:
      return {
        ...state,
        loading: false,
        appointment: action.payload,
      };

    case actionTypes.GET_LEAD_APPOINTMENT_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.payload,
      };

    case actionTypes.ADD_LEAD_APPOINTMENT_REQUEST:
      return {
        ...state,
        loading: true,
      };
    case actionTypes.ADD_LEAD_APPOINTMENT_SUCCESS:
      return {
        ...state,
        loading: false,
        addedAppointmentData: action.payload.data,
        appointmentData: [action.payload.data.data, ...state.appointmentData],
      };
    case actionTypes.ADD_LEAD_APPOINTMENT_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.payload,
      };
    case actionTypes.UPDATE_LEAD_APPOINTMENT_REQUEST:
      return {
        ...state,
        loading: true,
      };
    case actionTypes.UPDATE_LEAD_APPOINTMENT_SUCCESS:
      return {
        ...state,
        loading: false,
        appointmentData: state.appointmentData?.map((item) => {
          return item.id === action.payload.id ? action.payload : item;
        }),
        upcomingData: state.upcomingData?.map((item) => {
          if (
            item.id === action.payload.id &&
            !moment(item.date).utc().isBefore()
          ) {
            return action.payload;
          } else {
            return item;
          }
        }),
        pastData: state.pastData?.map((item) => {
          if (
            item.id === action.payload.id &&
            moment(item.date).utc().isBefore()
          ) {
            return action.payload;
          } else {
            return item;
          }
        }),
      };
    case actionTypes.UPDATE_LEAD_APPOINTMENT_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.payload,
      };

    case actionTypes.DELETE_LEAD_APPOINTMENT_REQUEST:
      return {
        ...state,
        loading: true,
      };
    case actionTypes.DELETE_LEAD_APPOINTMENT_SUCCESS:
      return {
        ...state,
        loading: false,
        appointmentData: state.appointmentData.filter(
          (item) => item.id !== action.payload
        ),
        pastData: state.pastData.filter((item) => item.id !== action.payload),
        upcomingData: state.upcomingData.filter(
          (item) => item.id !== action.payload
        ),
      };
    case actionTypes.DELETE_LEAD_APPOINTMENT_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.payload,
      };

    default:
      return state;
  }
};

export default leadAppointmentReducer;
