import { useLocation } from 'react-router';

const useQuery = (): any => {
  return new URLSearchParams(useLocation().search);
};

const getThreeArrayValue = (value: any, val: number): any => {
  if (value.length <= 3) return value;
  if (val + 3 <= value.length) return value.slice(val, val + 3);
  else return value.slice(value.length - 3, value.length);
};

export { useQuery, getThreeArrayValue };
