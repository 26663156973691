import * as actionTypes from './workflow.constant';
import { IAction, IWorkflow, IInitialState } from '@shared-components/models';

const initialState: IInitialState<IWorkflow> = {
  allData: [],
  singleData: {},
  error: null,
  loading: false,
  stages: [],
  checklists: [],
  formList: null,
  allChecklists: [],
  checklists_loading: false,
  form_loading: false,
  stages_loading: false,
};

export const WORKFLOW_FEATURE_KEY = 'workflows';

const workflowReducer = (state = initialState, action: IAction<any>) => {
  switch (action.type) {
    case actionTypes.FETCH_WORKFLOWS_REQUEST:
      return {
        ...state,
        loading: true,
        error: null,
      };
    case actionTypes.FETCH_WORKFLOWS_SUCCESS:
      return {
        ...state,
        loading: false,
        error: null,
        allData: action.payload,
      };
    case actionTypes.FETCH_WORKFLOWS_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.payload,
      };
    case actionTypes.FETCH_WORKFLOW_REQUEST:
      return {
        ...state,
        loading: true,
        error: null,
      };
    case actionTypes.FETCH_WORKFLOW_SUCCESS:
      return {
        ...state,
        loading: false,
        error: null,
        singleData: action.payload,
      };
    case actionTypes.FETCH_WORKFLOW_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.payload,
      };
    case actionTypes.FETCH_WORKFLOWS_BY_INSTITUTION_ID_REQUEST:
      return {
        ...state,
        loading: true,
        error: null,
      };
    case actionTypes.FETCH_WORKFLOWS_BY_INSTITUTION_ID_SUCCESS:
      return {
        ...state,
        loading: false,
        error: null,
        allData: action.payload,
      };
    case actionTypes.FETCH_WORKFLOWS_BY_INSTITUTION_ID_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.payload,
      };
    case actionTypes.ADD_WORKFLOW_REQUEST:
      return {
        ...state,
        loading: true,
        error: null,
      };
    case actionTypes.ADD_WORKFLOW_SUCCESS:
      return {
        ...state,
        loading: false,
        error: null,
        singleData: action.payload,
        allData: [action.payload, ...state.allData],
      };
    case actionTypes.ADD_WORKFLOW_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.payload,
      };
    case actionTypes.DELETE_WORKFLOW_REQUEST:
      return {
        ...state,
        loading: true,
        error: null,
      };
    case actionTypes.DELETE_WORKFLOW_SUCCESS:
      return {
        ...state,
        loading: false,
        error: null,
      };
    case actionTypes.DELETE_WORKFLOW_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.payload,
      };
    case actionTypes.CLEAR_WORKFLOW_FETCH:
      return {
        ...state,
        loading: false,
        error: null,
        allData: null,
      };
    case actionTypes.ADD_WORKFLOW_STAGE_REQUEST:
      return {
        ...state,
        stages_loading: true,
        error: null,
      };
    case actionTypes.ADD_WORKFLOW_STAGE_SUCCESS:
      return {
        ...state,
        stages_loading: false,
        error: null,
        stages: [...state.stages, action.payload],
      };
    case actionTypes.ADD_WORKFLOW_STAGE_FAILURE:
      return {
        ...state,
        stages_loading: false,
        error: action.payload,
      };
    case actionTypes.FETCH_WORKFLOW_STAGES_REQUEST:
      return {
        ...state,
        stages_loading: true,
        error: null,
      };
    case actionTypes.FETCH_WORKFLOW_STAGES_SUCCESS:
      return {
        ...state,
        stages_loading: false,
        error: null,
        stages: action.payload,
      };
    case actionTypes.FETCH_WORKFLOW_STAGES_FAILURE:
      return {
        ...state,
        stages_loading: false,
        error: action.payload,
      };
    case actionTypes.FETCH_WORKFLOW_STAGE_CHECKLISTS_REQUEST:
      return {
        ...state,
        checklists_loading: true,
        error: null,
      };
    case actionTypes.FETCH_WORKFLOW_STAGE_CHECKLISTS_SUCCESS:
      return {
        ...state,
        checklists_loading: false,
        error: null,
        checklists: action.payload,
      };
    case actionTypes.FETCH_WORKFLOW_STAGE_CHECKLISTS_FAILURE:
      return {
        ...state,
        checklists_loading: false,
        error: action.payload,
      };
    case actionTypes.CLEAR_WORKFLOW_STAGE_CHECKLISTS:
      return {
        ...state,
        checklists: [],
      };
    case actionTypes.FETCH_CHECKLISTS_REQUEST:
      return {
        ...state,
        loading: true,
        error: null,
      };
    case actionTypes.FETCH_CHECKLISTS_SUCCESS:
      return {
        ...state,
        loading: false,
        error: null,
        allChecklists: action.payload,
      };
    case actionTypes.FETCH_CHECKLISTS_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.payload,
      };
    case actionTypes.DELETE_WF_STAGE_CHECKLIST_REQUEST:
      return {
        ...state,
        checklists_loading: true,
        error: null,
      };
    case actionTypes.DELETE_WF_STAGE_CHECKLIST_SUCCESS:
      return {
        ...state,
        checklists_loading: false,
        error: null,
        checklists: action.payload,
      };
    case actionTypes.DELETE_WF_STAGE_CHECKLIST_FAILURE:
      return {
        ...state,
        checklists_loading: false,
        error: action.payload,
      };
    default:
      return state;
  }
};

export { workflowReducer };
