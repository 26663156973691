import { AxiosResponse } from 'axios';
import * as actionTypes from './application.constant';
import * as action from './application.action';
import { put, takeLatest } from 'redux-saga/effects';
import {
  addLeadApplication,
  deleteApplication,
  updateLeadApplication,
} from '@lyra/services.api';

import { IAction, ILeadApplication } from '@shared-components/models';
import { successNotificationHandler } from '@moxie/shared';
import { errorHandler } from '@moxie/utils';
import {
  getApplication,
  getApplicationComments,
  postApplicationComment,
} from '@admin/services.api';

export const getClientApplication = function* ({ payload }: IAction<any>) {
  try {
    if (payload) {
      const response: AxiosResponse = yield getApplication(payload);
      yield put(action.getOneSuccess(response?.data?.data || []));
    }
  } catch (err) {
    errorHandler(err);
    yield put(action.getOneFailure(err));
  }
};

export const getApplicationComment = function* ({ payload }: IAction<any>) {
  try {
    if (payload) {
      const response: AxiosResponse = yield getApplicationComments(payload);
      yield put(action.getCommentSuccess(response?.data?.data || []));
    }
  } catch (err) {
    errorHandler(err);
    yield put(action.getCommentFailure(err));
  }
};

export const addApplicationComments = function* ({ payload }: IAction<any>) {
  try {
    if (payload) {
      const response: AxiosResponse = yield postApplicationComment(payload);
      yield put(action.addCommentSuccess(response?.data?.data || []));
    }
  } catch (err) {
    errorHandler(err);
    yield put(action.addCommentFailure(err));
  }
};

export const createLeadApplication = function* ({
  payload,
  triggerRefresh,
}: IAction<ILeadApplication>) {
  try {
    if (payload) {
      const response: AxiosResponse = yield addLeadApplication(payload);
      yield put(action.addSuccess(response.data.data));
      successNotificationHandler(response.data.message);
      triggerRefresh && triggerRefresh();
    }
  } catch (err: any) {
    errorHandler(err);
    yield put(action.addFailure(err));
  }
};

export const editLeadApplication = function* ({
  payload,
  user,
  socket,
  lead,
  update_type,
  admin_assignees,
}: IAction<ILeadApplication | any>) {
  try {
    if (payload) {
      const { handleRefreshTable, ...restPayload } = payload;
      const response: AxiosResponse = yield updateLeadApplication(restPayload);
      yield put(action.updateSuccess(response.data.data));
      handleRefreshTable?.();
      socket?.emit('create-admin-application-activities-to-server', {
        user_id: user?.id,
        action_type: 'updated',
        application_id: response?.data?.data?.id,
        parent_id: lead?.id,
        data: response?.data,
        update_type: update_type,
        company_id: user?.company?.id,
      });

      socket?.emit('create-admin-application-notification-to-server', {
        admin_user_id: user?.id,
        receivers_many: admin_assignees,
        type: 'application-updated',
        parent_id: lead?.id,
        application_id: response?.data?.data?.app_identifier,
        update_type: update_type,
      });
      successNotificationHandler(response.data.message);
    }
  } catch (err) {
    errorHandler(err);
    yield put(action.updateFailure(err));
  }
};

export const deleteLeadApplication = function* ({ payload }: IAction<any>) {
  try {
    if (payload) {
      const response: AxiosResponse = yield deleteApplication(payload);
      yield put(action.deleteApplicationSuccess(response.data.data));
      successNotificationHandler(response.data.message);
    }
  } catch (err) {
    errorHandler(err);
    yield put(action.deleteApplicationFailure(err));
  }
};

const applicationSaga = function* (): unknown {
  yield takeLatest(actionTypes.GET_APPLICATION_REQUEST, getClientApplication);
  yield takeLatest(actionTypes.ADD_APPLICATION_REQUEST, createLeadApplication);
  yield takeLatest(actionTypes.UPDATE_APPLICATION_REQUEST, editLeadApplication);
  yield takeLatest(
    actionTypes.DELETE_APPLICATION_REQUEST,
    deleteLeadApplication
  );
  yield takeLatest(
    actionTypes.ADD_APPLICATION_COMMENT_REQUEST,
    addApplicationComments
  );
  yield takeLatest(
    actionTypes.GET_APPLICATION_COMMENT_REQUEST,
    getApplicationComment
  );
};

export default applicationSaga;
