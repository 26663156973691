import React, { useState } from 'react';
import { Button, Form, Input, Modal, Typography } from 'antd';
import { useForm } from 'antd/lib/form/Form';
import { warnNotificationHandler } from 'libs/shared/src/functions';

interface IRejectModal {
  setIsModalVisible: (data: boolean) => void;
  isModalVisible: boolean;
  handleStatusRejection: any;
}

const { TextArea } = Input;

const RejectModal: React.FC<IRejectModal> = ({
  setIsModalVisible,
  isModalVisible,
  handleStatusRejection,
}: IRejectModal) => {
  const [comment, setComment] = useState<string>();
  const [form] = useForm();

  const handleOk = () => {
    if (comment && comment?.length > 0) {
      handleStatusRejection(comment);
      setIsModalVisible(false);
      setComment('');
      form.resetFields();
    } else {
      warnNotificationHandler('Comment is required');
    }
  };
  const handleChange = (e: {
    target: { value: React.SetStateAction<string | undefined> };
  }) => {
    setComment(e.target.value);
  };

  const handleCancel = () => {
    setComment('');
    form.resetFields();
    setIsModalVisible(false);
  };

  return (
    <Modal
      title="Reject Company"
      visible={isModalVisible}
      onOk={handleOk}
      onCancel={handleCancel}
    >
      <Form layout="vertical" form={form}>
        <Form.Item
          label="Why are you rejecting this company?"
          rules={[{ required: true }]}
          name="comment"
        >
          <TextArea
            rows={4}
            placeholder="State comment for rejection of company"
            onChange={handleChange}
            value={comment}
          />
        </Form.Item>
      </Form>
    </Modal>
  );
};

export default RejectModal;
