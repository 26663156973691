import React, { useState } from 'react';
import moment from 'moment-timezone';
import { Card, Typography, Col, Row, Tag, Select, Tooltip, Badge } from 'antd';
import { PlusOutlined, DownOutlined } from '@ant-design/icons';
import {
  APPOINTMENT_STATUS_LIST,
  CONTACT,
  NOTES_TITLE,
  NOTE_INITIAL_DATA,
  TEXT,
} from '@moxie/constants';
import {
  Button,
  UserAvatarGroup,
  UserNameCard,
} from '@shared-components/elements';
import { AppointmentNameCard } from './appointment-name-card';
import { IAppointmentData } from '@shared-components/models';
import { NoteAddEdit } from '../../lead-notes/note-add-edit';
import { updateAppointmentStatus } from '@lyra/services.api';
import {
  capitalizeFirstLetter,
  successNotificationHandler,
} from '@moxie/shared';
import { useParams } from 'react-router-dom';
import { errorHandler } from '@moxie/utils';
import { useAppSelector } from '@lyra/core';
const AppointmentCard: React.FC<IAppointmentData> = ({
  currentUser,
  title,
  name,
  data,
  setChangeStatus,
  changeStatus,
  triggerRefresh,
  archived,
}: IAppointmentData) => {
  const { Option } = Select;
  const { Text } = Typography;
  const [isOpen, setIsOpen] = useState<boolean>(false);
  const [disabled, setDisabled] = useState<boolean>(true);
  const { id } = useParams<{ id: string }>();
  const socket: any = useAppSelector((state) => state.socket.wss);
  const user: any = useAppSelector((state) => state.auth.user);
  const currentLead: any = useAppSelector((state) => state.lead.lead);
  const [noteFormData, setNoteFormData] = useState(NOTE_INITIAL_DATA);
  const [isSubmitted, setIsSubmitted] = useState(false);
  const [appId, setAppId] = useState<string>();
  const [appointmentId, setAppointmentId] = useState<string>();
  const handleStatusChange = (id: string) => {
    setChangeStatus(true);
    setAppId(id);
  };

  const statusList = (value: string) => {
    switch (value) {
      case 'attended':
        return (
          <Text key={value} className="text-success">
            <strong>{TEXT.ATTENDED}</strong>
          </Text>
        );
      case 'no-show':
        return (
          <Text key={value} className="text-warning">
            <strong>{TEXT.NO_SHOW}</strong>
          </Text>
        );
      case 'cancelled':
        return (
          <Text key={value} className="text-error">
            <strong>{TEXT.CANCELLED}</strong>
          </Text>
        );
      case 'postponed':
        return (
          <Text key={value} className="text-processing">
            <strong>{TEXT.POSTPONED}</strong>
          </Text>
        );
      default:
        return (
          <Text
            key={5}
            onClick={() => handleStatusChange('5')}
            className="text-secondary  appointment-card__button-margin cursor-pointer"
          >
            <b> {TEXT.SCHEDULED}</b>
          </Text>
        );
    }
  };
  const setStatus = (value: string, id: string) => {
    switch (value) {
      case 'attended':
        return (
          <div
            className="appointment-status"
            onClick={!archived ? () => handleStatusChange(id) : undefined}
          >
            <Badge status="success" className="text-success " />
            <strong key={id} className="text-success ">
              {TEXT.ATTENDED}
            </strong>
            <DownOutlined className="margin-left-1 text-secondary" />
          </div>
        );
      case 'no-show':
        return (
          <div
            className="appointment-status"
            onClick={!archived ? () => handleStatusChange(id) : undefined}
          >
            <Badge status="warning" color="orange" className="text-warning " />
            <strong key={id} className="text-warning ">
              {TEXT.NO_SHOW}
            </strong>
            <DownOutlined className="text-secondary margin-left-1 " />
          </div>
        );
      case 'cancelled':
        return (
          <div
            className="appointment-status"
            onClick={!archived ? () => handleStatusChange(id) : undefined}
          >
            <Badge status="error" color="red" className="text-error " />
            <strong key={id} className="text-error ">
              {TEXT.CANCELLED}
            </strong>
            <DownOutlined className="text-secondary margin-left-1 " />
          </div>
        );
      case 'postponed':
        return (
          <div
            className="appointment-status"
            onClick={!archived ? () => handleStatusChange(id) : undefined}
          >
            <Badge status="processing" className="  text-processing " />
            <strong key={id} className="  text-processing ">
              {TEXT.POSTPONED}
            </strong>
            <DownOutlined className="text-secondary margin-left-1 " />
          </div>
        );
      default:
        return (
          <div
            className="appointment-status"
            onClick={!archived ? () => handleStatusChange(id) : undefined}
          >
            <Badge
              status="default"
              color="grey"
              className="  text-secondary "
            />
            <strong key={id} className="text-secondary ">
              {TEXT.SCHEDULED}
            </strong>
            <DownOutlined className="text-secondary margin-left-1 " />
          </div>
        );
    }
  };

  const handleOpenNotes = (item: any) => {
    const date = moment().format('Do MMMM YYYY');
    setNoteFormData({
      ...NOTE_INITIAL_DATA,
      title: `${date}: ${item.title} Notes`,
    });
    setAppointmentId(item.id);
    setIsOpen(true);
  };

  const handleCloseNotes = () => {
    setIsOpen(false);
    setIsSubmitted(false);
  };

  const handleSelectChange = async (value: any, fieldNames: any) => {
    const body = {
      status: value,
      appointment_id: fieldNames.key,
    };
    try {
      const response = await updateAppointmentStatus(body);
      if (response?.data?.success) {
        successNotificationHandler(response?.data?.message);
        triggerRefresh && triggerRefresh();
        setChangeStatus && setChangeStatus(false);

        const singleData = await data?.filter(
          (item: any) => item?.id === fieldNames?.key
        );

        socket.emit('create-appointment-notification-to-server', {
          user_id: user.id,
          receiver_id: currentLead.assignee_user_id,
          receivers_many: singleData[0]?.user_list?.map(
            (item: any) => item?.id
          ),
          type: 'appointment-status-updated',
        });
      }
    } catch (err) {
      errorHandler(err);
    }
  };

  const handleChange = (e: any) => {
    setNoteFormData({ ...noteFormData, [e.target.name]: e.target.value });
  };

  const onBlur = () => {
    setChangeStatus && setChangeStatus(false);
  };

  return (
    <>
      {data &&
        data?.map((item: any, index: any) => {
          const appointmentDay = moment(item?.date)
            .tz(item?.time_zone)
            .format('DD');
          const appointmentDays = moment(item?.date)
            .tz(item?.time_zone)
            .format('dddd');
          const appointmentYear = moment(item?.date)
            .tz(item?.time_zone)
            .format('MMMM, YYYY');
          const appointmentTime = moment(item && item?.time.split('.')[0])
            .tz(currentUser?.time_zone)
            .format('hh:mm a');

          return (
            <Col key={index} className="appointment-space">
              <Card className="appointment-card">
                <Row justify="space-between">
                  <Col span="6">
                    <Typography.Title level={1}>
                      {appointmentDay}
                    </Typography.Title>
                  </Col>
                  <Col span="10" className="appointment-card__line-height">
                    <Typography.Text strong>{appointmentDays}</Typography.Text>
                    <Typography.Text
                      type="secondary"
                      className="appointment-card__font-size"
                      strong
                    >
                      {appointmentYear}
                    </Typography.Text>
                  </Col>
                  <Col span="4">
                    {moment(item.date).utc().isSame(moment(), 'day') && (
                      <Tag
                        className="margin-top-2 margin-left-2"
                        color="purple"
                      >
                        <strong>{name}</strong>
                      </Tag>
                    )}
                  </Col>
                  <Col span="4" className="appointment-card__name-card">
                    <AppointmentNameCard
                      title={title}
                      id={item.id}
                      setChangeStatus={setChangeStatus}
                      changeStatus={changeStatus}
                      triggerRefresh={triggerRefresh}
                      archived={archived}
                    />
                  </Col>
                </Row>
                <Row>
                  <Col span="16">
                    <Col className="ellipsis-text">
                      <Typography.Text strong>
                        <Tooltip title={capitalizeFirstLetter(item.title)}>
                          {capitalizeFirstLetter(item.title)}
                        </Tooltip>
                      </Typography.Text>
                    </Col>
                    <Col>
                      <Typography.Text
                        type="secondary"
                        className="appointment-card__font-size"
                        strong
                      >
                        {TEXT.STARTS_AT} {appointmentTime}
                      </Typography.Text>
                    </Col>
                  </Col>
                  <Col span="8">
                    <Button
                      type="primary"
                      size={'small'}
                      icon={<PlusOutlined />}
                      onClick={() => handleOpenNotes(item)}
                      className="appointment-card__button-margin margin-top-1"
                    >
                      {NOTES_TITLE}
                    </Button>
                  </Col>
                </Row>
                <Row justify="space-between">
                  <Col span="24">
                    <Col className="margin-top-2">
                      <Typography.Text type="secondary" strong>
                        {CONTACT}:
                      </Typography.Text>
                    </Col>
                    {item.contact && (
                      <UserNameCard
                        firstName={item.contact.first_name}
                        lastName={item.contact.last_name}
                        email={item.contact.email}
                        size="large"
                        link={`/contact/detail/${item.contact.id}/profile-section`}
                      />
                    )}
                  </Col>
                  <Col span="14">
                    <Col className="margin-top-1">
                      <Typography.Text type="secondary" strong>
                        {TEXT.SHOW_INVITEES}
                      </Typography.Text>
                    </Col>

                    <Col>
                      {item?.user_list?.length > 0 ? (
                        <UserAvatarGroup userList={item?.user_list} />
                      ) : (
                        <Typography.Title level={4}>
                          {TEXT.NO_INVITEES}
                        </Typography.Title>
                      )}
                    </Col>
                  </Col>
                  <Col span="10" className="margin-top-2">
                    <Typography.Text type="secondary" strong>
                      {TEXT.APPOINTMENT_STATUS}
                    </Typography.Text>
                    <br />
                    {changeStatus === false || appId !== item.id ? (
                      setStatus(item.status || '', item.id)
                    ) : (
                      <>
                        {appId &&
                          appId ===
                            item.id(
                              <Select
                                clearIcon={true}
                                bordered={false}
                                value={capitalizeFirstLetter(item.status)}
                                onChange={handleSelectChange}
                                defaultOpen={true}
                                onBlur={onBlur}
                                className="appointment-status-list"
                                autoFocus={true}
                                showArrow={true}
                              >
                                {APPOINTMENT_STATUS_LIST.map((list) => (
                                  <Option key={item.id} value={list.value}>
                                    {statusList(list.value)}
                                  </Option>
                                ))}
                              </Select>
                            )}
                      </>
                    )}
                  </Col>
                </Row>
              </Card>
            </Col>
          );
        })}
      <NoteAddEdit
        appointmentId={appointmentId && appointmentId}
        onChange={handleChange}
        noteFormData={noteFormData}
        setNoteForm={setNoteFormData}
        setDisabled={setDisabled}
        disabled={disabled}
        name="appointment"
        isOpen={isOpen}
        onClose={handleCloseNotes}
        leadId={id}
        archived={archived}
        isSubmitted={isSubmitted}
        setIsSubmitted={setIsSubmitted}
      />
    </>
  );
};

export default AppointmentCard;
