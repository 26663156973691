import { IAction, IInitialState } from '@shared-components/models';
import * as actionTypes from './tenant.constant';
export const TENANT_FEATURE_KEY = 'tenant';

export const initialTenantState: IInitialState<any> = {
  allData: [],
  singleData: {},
  error: null,
  loading: false,
};

const tenantReducer = (state = initialTenantState, action: IAction<any>) => {
  switch (action.type) {
    case actionTypes.GET_TENANTS_REQUEST:
      return {
        ...state,
        loading: true,
        error: null,
      };
    case actionTypes.GET_TENANTS_SUCCESS:
      return {
        ...state,
        loading: false,
        error: null,
        allData: action.payload,
      };
    case actionTypes.GET_TENANTS_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.payload,
      };
    case actionTypes.GET_TENANT_REQUEST:
      return {
        ...state,
        loading: true,
        error: null,
      };
    case actionTypes.GET_TENANT_SUCCESS:
      return {
        ...state,
        loading: false,
        error: null,
        singleData: action.payload,
      };
    case actionTypes.GET_TENANT_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.payload,
      };
    case actionTypes.CLEAR_TENANT_FETCH:
      return {
        ...state,
        loading: false,
        error: null,
        singleData: null,
      };
    case actionTypes.ADD_TENANT_REQUEST:
      return {
        ...state,
        loading: true,
        error: null,
      };
    case actionTypes.ADD_TENANT_SUCCESS:
      return {
        ...state,
        loading: false,
        error: null,
      };
    case actionTypes.ADD_TENANT_FAILURE:
      return {
        ...state,
        loading: false,
        error: null,
      };
    case actionTypes.UPDATE_TENANT_REQUEST:
      return {
        ...state,
        loading: true,
        error: null,
      };
    case actionTypes.UPDATE_TENANT_SUCCESS:
      return {
        ...state,
        loading: false,
        error: null,
      };
    case actionTypes.UPDATE_TENANT_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.payload,
      };
    case actionTypes.DELETE_TENANT_REQUEST:
      return {
        ...state,
        loading: true,
        error: null,
      };
    case actionTypes.DELETE_TENANT_SUCCESS:
      return {
        ...state,
        loading: false,
        error: null,
      };
    case actionTypes.DELETE_TENANT_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.payload,
      };
    case actionTypes.DISABLE_TENANT_REQUEST:
      return {
        ...state,
        loading: true,
        error: null,
      };
    case actionTypes.DISABLE_TENANT_SUCCESS:
      return {
        ...state,
        loading: false,
        singleData: action.payload,
      };
    case actionTypes.DISABLE_TENANT_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.payload,
      };
    case actionTypes.UPDATE_SHARING_RATIO_REQUEST:
      return {
        ...state,
        loading: true,
        error: null,
      };
    case actionTypes.UPDATE_SHARING_RATIO_SUCCESS:
      return {
        ...state,
        loading: false,
        singleData: action.payload,
      };
    case actionTypes.UPDATE_SHARING_RATIO_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.payload,
      };
    default:
      return state;
  }
};

export { tenantReducer };
