import React, { useState } from 'react';
import {
  capitalizeFirstLetter,
  changeNullToUndefined,
  PageHeaderTitle,
  popConfirm,
  successNotificationHandler,
} from '@moxie/shared';
import {
  CONFIRM_MESSAGE,
  CONFIRM_TITLE,
  DELETE_BTN,
  OFFICE_TITLE,
  PAGE_HEADER_MENU_OFFICE_BRANCH,
} from '@moxie/constants';
import { FormItemProps } from 'antd';
import {
  getMainBranch,
  getOfficeBranches,
  getOneOfficeBranch,
  updateMainBranch,
} from '@lyra/services.api';
import OfficeBranchTable from './office-branch-table';
import { errorHandler } from '@moxie/utils';
import { useForm } from 'antd/lib/form/Form';
import { IOfficeBranch } from '@shared-components/models';
import { OfficeDrawer } from './office-form-drawer';
import { useDispatch } from 'react-redux';
import { officeBranchActions } from '@lyra/core';
import { PageHeaderMenu } from '../components/page-header-menu';

const OfficeBranch: React.FC = () => {
  const [refreshTable, setRefreshTable] = useState(true);
  const [visible, setVisible] = useState(false);
  const [isEdit, setIsEdit] = useState(false);
  const [initialData, setInitialData] = useState<IOfficeBranch>();
  const [filter] = useState({});
  const [form] = useForm();
  const dispatch = useDispatch();
  const triggerRefresh = () => setRefreshTable((prev) => !prev);
  const [nameValidationStatus, setNameValidationStatus] = useState<
    FormItemProps['validateStatus']
  >('');

  const getData = async (params: string) => {
    return getOfficeBranches(params);
  };

  const handleSubmit = async (data: IOfficeBranch) => {
    if (data.contact_person_id === undefined) data.contact_person_id = null;
    let postData;

    if (initialData?.id) {
      postData = {
        ...data,
        id: initialData.id,
        address: changeNullToUndefined({
          ...data.address,
          id: initialData?.address_id,
        }),
      };
      dispatch(officeBranchActions.updateRequest(postData));
    } else {
      postData = data;
      dispatch(officeBranchActions.addRequest(postData, triggerRefresh));
      triggerRefresh();
    }
    onClose();
    triggerRefresh();
  };

  const handleFormSubmit = () => {
    form.submit();
  };

  const handleDelete = async (id: string) => {
    popConfirm(DELETE_BTN, `${CONFIRM_MESSAGE.DELETE_BRANCH}`, async () => {
      try {
        dispatch(
          officeBranchActions.deleteOfficeBranchRequest(id, triggerRefresh)
        );
      } catch (err) {
        errorHandler(err);
      }
      triggerRefresh();
    });
  };

  const onClose = () => {
    form.resetFields();
    setVisible(false);
    setIsEdit(false);
    setNameValidationStatus('success');
    return Promise.resolve(true);
  };

  const showDrawer = async (id?: string) => {
    if (id) {
      try {
        const response = await getOneOfficeBranch(id ? id : '');
        setInitialData(response.data.data);
        setIsEdit(true);
      } catch (err) {
        errorHandler(err);
      }
    } else {
      setInitialData({});
    }
    setVisible(true);
  };

  const handleMainBranch = async (id: string) => {
    const response = await getMainBranch(id);
    if (response?.data?.success) {
      popConfirm(
        CONFIRM_TITLE.MAKE_MAIN_BRANCH,
        `${capitalizeFirstLetter(response?.data?.message)}?`,
        async () => {
          try {
            const updateResponse = await updateMainBranch(id);
            successNotificationHandler(updateResponse?.data?.message);
          } catch (err) {
            errorHandler(err);
          }
          triggerRefresh();
        }
      );
    }
  };

  return (
    <div className="full-height">
      <PageHeaderTitle title={OFFICE_TITLE} />
      <PageHeaderMenu menu={PAGE_HEADER_MENU_OFFICE_BRANCH}>
        {visible && (
          <OfficeDrawer
            visible={visible}
            onClose={onClose}
            handleFormSubmit={handleFormSubmit}
            isEdit={isEdit}
            nameValidationStatus={nameValidationStatus}
            setNameValidationStatus={setNameValidationStatus}
            form={form}
            handleSubmit={handleSubmit}
            initialData={initialData}
          />
        )}
      </PageHeaderMenu>

      <OfficeBranchTable
        showDrawer={showDrawer}
        getData={getData}
        tableName={OFFICE_TITLE}
        deleteData={handleDelete}
        setMainBranch={handleMainBranch}
        refreshTable={refreshTable}
        filter={filter}
      />
    </div>
  );
};

export { OfficeBranch };
