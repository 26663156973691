import React, { FC, useState } from 'react';
import { Space, Button } from 'antd';
import { DrawerElem } from '@shared-components/elements';
import { useParams } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import {
  CANCEL_BTN,
  SAVE_BTN,
  TEXT,
  UPDATE_BTN,
  DRAWER_WIDTH,
} from '@moxie/constants';
import { ILeadAppointment } from '@shared-components/models';
import AppointmentForm from '../libs/appointment-add-edit';
import {
  appointmentActions,
  useAppSelector,
  userAppointmentActions,
} from '@lyra/core';
import moment from 'moment';

const LeadAppointmentDrawer: FC<ILeadAppointment> = ({
  title,
  isEdit,
  isOpen,
  onClose,
  appointmentData,
  triggerRefresh,
  form,
}: ILeadAppointment) => {
  const dispatch = useDispatch();
  const params = useParams<{ id: string }>();
  const socket: any = useAppSelector((state) => state.socket.wss);
  const user: any = useAppSelector((state) => state.auth.user);
  const [scheduleChange, setScheduleChange] = useState(false);
  const [disabled, setDisabled] = useState(true);

  const currentlead: any = useAppSelector((state) => state.lead.lead);
  const handleFormSubmit = () => {
    form.submit();
  };

  const onFieldsChange = (changedFields: any) => {
    if (
      changedFields.date &&
      moment(changedFields.date?._i).format('DD MM YYYY') !==
        moment(changedFields.date?._d).format('DD MM YYYY')
    ) {
      setScheduleChange(true);
    } else {
      setScheduleChange(false);
    }
  };

  const handleSubmit = async (data: any) => {
    if (appointmentData.id && isEdit) {
      const formatData = {
        id: appointmentData?.id,
        time_zone: data.time_zone,
        date: data.date._d,
        time: data.time._d,
        title: data?.title,
        description: data?.description,
        users_id: data?.user_list,
      };

      title === 'user_appointment'
        ? dispatch(
            userAppointmentActions.updateUserAppointmentRequest(
              formatData,
              onClose,
              triggerRefresh
            )
          )
        : dispatch(
            appointmentActions.updateLeadAppointmentRequest(
              formatData,
              user,
              socket,
              currentlead,
              onClose,
              triggerRefresh,
              scheduleChange
            )
          );
    } else {
      const formatData = {
        parent_id: params?.id,
        time_zone: data.time_zone,
        date: data.date._d,
        time: data.time._d,
        title: data?.title,
        description: data?.description,
        users_id: data?.user_list,
      };
      dispatch(
        appointmentActions.addLeadAppointmentRequest(
          formatData,
          user,
          socket,
          currentlead,
          onClose,
          triggerRefresh
        )
      );
    }
    setDisabled(true);
  };

  return (
    <DrawerElem
      title={isEdit ? TEXT.EDIT_APPOINTMENT : TEXT.ADD_APPOINTMENT}
      width={DRAWER_WIDTH}
      onClose={onClose}
      visible={isOpen}
      footer={
        <div className="padding-bottom-2 text-align-right">
          <Space direction="horizontal">
            <Button onClick={onClose} className="lead-margin-right">
              {CANCEL_BTN}
            </Button>
            <Button
              type="primary"
              onClick={handleFormSubmit}
              disabled={disabled}
            >
              {isEdit ? UPDATE_BTN : SAVE_BTN}
            </Button>
          </Space>
        </div>
      }
    >
      <AppointmentForm
        onFieldsChange={onFieldsChange}
        isEdit={isEdit}
        onSubmit={handleSubmit}
        form={form}
        setDisabled={setDisabled}
        initialData={appointmentData && appointmentData}
      />
    </DrawerElem>
  );
};

export { LeadAppointmentDrawer };
