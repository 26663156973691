/* eslint-disable no-unused-vars */
import React from 'react';
import { Menu } from 'antd';
import { Button } from '@shared-components/elements';
import { LABEL } from '@moxie/constants';

const languageTestScoreMenuAdmin = (
  cb: (value: string) => void,
  languageTestScore: any[]
): React.ReactElement => (
  <Menu>
    {!languageTestScore?.some(
      (item) => item.test_name?.toUpperCase() === LABEL.IELTS
    ) && (
      <Menu.Item key="1">
        <Button
          type="link"
          onClick={(event: any) => {
            event?.stopPropagation();
            cb(LABEL.IELTS);
          }}
        >
          {LABEL.IELTS}
        </Button>
      </Menu.Item>
    )}
    {!languageTestScore?.some(
      (item) => item.test_name?.toUpperCase() === LABEL.TOEFL
    ) && (
      <Menu.Item key="2">
        <Button
          type="link"
          onClick={(event: any) => {
            event?.stopPropagation();
            cb(LABEL.TOEFL);
          }}
        >
          {LABEL.TOEFL}
        </Button>
      </Menu.Item>
    )}
    {!languageTestScore?.some(
      (item) => item.test_name?.toUpperCase() === LABEL.PTE
    ) && (
      <Menu.Item key="3">
        <Button
          type="link"
          onClick={(event: any) => {
            event?.stopPropagation();
            cb(LABEL.PTE);
          }}
        >
          {LABEL.PTE}
        </Button>
      </Menu.Item>
    )}
  </Menu>
);

export { languageTestScoreMenuAdmin };
