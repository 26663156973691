import {
  IAction,
  IAppointment,
  IAppointmentPayload,
} from '@shared-components/models';
import * as actionTypes from './user-appointment.constant';

export const getUserAppointmentsRequest = (
  id: string | undefined
): IAction<string> => ({
  type: actionTypes.GET_USER_APPOINTMENTS_REQUEST,
  payload: id,
});

export const getUserAppointmentsSuccess = (
  payload: IAppointment[]
): IAction<IAppointment[]> => ({
  type: actionTypes.GET_USER_APPOINTMENTS_SUCCESS,
  payload,
});

export const getUserAppointmentsFailure = (
  payload: IAppointmentPayload
): IAction => ({
  type: actionTypes.GET_USER_APPOINTMENTS_FAILURE,
  payload,
});

export const getUserAppointmentsClear = () => {
  return { type: actionTypes.GET_USER_APPOINTMENTS_CLEAR };
};

export const getUserAppointmentRequest = (
  id: string | undefined
): IAction<string> => ({
  type: actionTypes.GET_USER_APPOINTMENT_REQUEST,
  payload: id,
});

export const getUserAppointmentSuccess = (
  payload: IAppointment[]
): IAction<IAppointment[]> => ({
  type: actionTypes.GET_USER_APPOINTMENT_SUCCESS,
  payload,
});

export const getUserAppointmentFailure = (
  payload: IAppointmentPayload
): IAction => ({
  type: actionTypes.GET_USER_APPOINTMENT_FAILURE,
  payload,
});

export const updateUserAppointmentRequest = (
  payload: IAppointmentPayload,
  onClose?: () => void,
  triggerRefresh?: () => void
): IAction => ({
  type: actionTypes.UPDATE_USER_APPOINTMENT_REQUEST,
  payload,
  onClose,
  triggerRefresh,
});

export const updateUserAppointmentSuccess = (
  payload: IAppointment
): IAction<IAppointment> => ({
  type: actionTypes.UPDATE_USER_APPOINTMENT_SUCCESS,
  payload,
});

export const updateUserAppointmentFailure = (
  payload: IAppointmentPayload
): IAction<IAppointmentPayload> => ({
  type: actionTypes.UPDATE_USER_APPOINTMENT_FAILURE,
  payload,
});

export const deleteUserAppointmentRequest = (
  payload: IAppointmentPayload
): IAction => ({
  type: actionTypes.DELETE_USER_APPOINTMENT_REQUEST,
  payload,
});

export const deleteUserAppointmentSuccess = (
  payload: IAppointmentPayload
): IAction<IAppointmentPayload> => ({
  type: actionTypes.DELETE_USER_APPOINTMENT_SUCCESS,
  payload,
});

export const deleteUserAppointmentFailure = (
  payload: IAppointmentPayload
): IAction<IAppointmentPayload> => ({
  type: actionTypes.DELETE_USER_APPOINTMENT_FAILURE,
  payload,
});
