import { AxiosResponse } from 'axios';
import { IAction, IWorkflowType } from '@shared-components/models';
import { errorHandler } from '@moxie/utils';
import { put, takeLatest } from 'redux-saga/effects';
import * as workflowType from './workflow-type.constant';
import * as workflowTypeAction from './workflow-type.action';
import { successNotificationHandler } from '@moxie/shared';
import {
  deleteWorkflowType,
  getOneWorkflowType,
  getWorkflowTypes,
  postWorkflowType,
  updateWorkflowType,
} from '@admin/services.api';

export const getALlWorkflowTypes = function* () {
  try {
    const response: AxiosResponse = yield getWorkflowTypes();
    yield put(workflowTypeAction.getWorkflowTypesSuccess(response.data.data));
  } catch (err) {
    errorHandler(err);
    yield put(workflowTypeAction.getWorkflowTypesFailure(err));
  }
};

export const addWorkflowTypeData = function* ({
  payload,
  triggerRefresh,
}: IAction<IWorkflowType>) {
  try {
    if (payload) {
      const response: AxiosResponse = yield postWorkflowType(payload);
      yield put(
        workflowTypeAction.addWorkflowTypeSuccess(response?.data?.data)
      );
      yield successNotificationHandler(response?.data?.message);
      triggerRefresh && triggerRefresh();
    }
  } catch (err) {
    errorHandler(err);
    yield put(workflowTypeAction.addWorkflowTypeFailure(err));
  }
};

export const updateWorkflowTypeData = function* ({
  payload,
  triggerRefresh,
}: IAction<IWorkflowType>) {
  try {
    if (payload) {
      const response: AxiosResponse = yield updateWorkflowType(payload);
      yield put(
        workflowTypeAction.updateWorkflowTypeSuccess(response?.data?.data)
      );
      yield successNotificationHandler(response?.data?.message);
      triggerRefresh && triggerRefresh();
    }
  } catch (err) {
    errorHandler(err);
    yield put(workflowTypeAction.updateWorkflowTypeFailure(err));
  }
};

export const deleteWorkflowTypeData = function* ({
  payload,
  triggerRefresh,
}: IAction<any>) {
  try {
    if (payload) {
      const response: AxiosResponse = yield deleteWorkflowType(payload);
      yield successNotificationHandler(response.data?.message);
      yield put(workflowTypeAction.deleteWorkflowTypeSuccess());
      triggerRefresh && triggerRefresh();
    }
  } catch (err) {
    errorHandler(err);
    yield put(workflowTypeAction.deleteWorkflowTypeFailure(err));
  }
};

export const getOneWorkflowData = function* ({ payload }: IAction<any>) {
  try {
    if (payload) {
      const response: AxiosResponse = yield getOneWorkflowType(payload);
      yield put(
        workflowTypeAction.getWorkflowTypeSuccess(response?.data?.data)
      );
    }
  } catch (err) {
    errorHandler(err);
    yield put(workflowTypeAction.getWorkflowTypeFailure(err));
  }
};

export default function* workflowTypeSaga(): unknown {
  yield takeLatest(workflowType.ADD_WORKFLOW_TYPE_REQUEST, addWorkflowTypeData);
  yield takeLatest(
    workflowType.UPDATE_WORKFLOW_TYPE_REQUEST,
    updateWorkflowTypeData
  );
  yield takeLatest(
    workflowType.DELETE_WORKFLOW_TYPE_REQUEST,
    deleteWorkflowTypeData
  );
  yield takeLatest(
    workflowType.FETCH_WORKFLOW_TYPE_REQUEST,
    getOneWorkflowData
  );
  yield takeLatest(
    workflowType.FETCH_WORKFLOW_TYPES_REQUEST,
    getALlWorkflowTypes
  );
}
