import * as actionTypes from './products.constant';
import {
  IAction,
  IProduct,
  IPayload,
  IProductFee,
} from '@shared-components/models';

export const addProductRequest = (
  payload: IProduct,
  triggerRefresh: any
): IAction<IProduct> => ({
  type: actionTypes.ADD_PRODUCT_REQUEST,
  payload,
  triggerRefresh,
});

export const addProductSuccess = (payload: IProduct): IAction<IProduct> => ({
  type: actionTypes.ADD_PRODUCT_SUCCESS,
  payload,
});

export const addProductFailure = (payload: IPayload<IProduct>): IAction => ({
  type: actionTypes.ADD_PRODUCT_FAILURE,
  payload,
});

export const updateProductRequest = (
  payload: IProduct,
  triggerRefresh: () => void
): IAction<IProduct> => ({
  type: actionTypes.UPDATE_PRODUCT_REQUEST,
  payload,
  triggerRefresh: triggerRefresh,
});

export const updateProductSuccess = (payload: IProduct): IAction<IProduct> => ({
  type: actionTypes.UPDATE_PRODUCT_SUCCESS,
  payload,
});

export const updateProductFailure = (payload: IPayload<IProduct>): IAction => ({
  type: actionTypes.UPDATE_PRODUCT_FAILURE,
  payload,
});

export const getProductsRequest = (payload?: string): IAction<string> => ({
  type: actionTypes.FETCH_PRODUCTS_REQUEST,
  payload,
});

export const getProductsSuccess = (
  payload: IProduct[]
): IAction<IProduct[]> => ({
  type: actionTypes.FETCH_PRODUCTS_SUCCESS,
  payload,
});

export const getProductsFailure = (payload: IPayload<IProduct>): IAction => ({
  type: actionTypes.FETCH_PRODUCTS_FAILURE,
  payload,
});
export const getProductsClear = () => {
  return { type: actionTypes.FETCH_PRODUCTS_CLEAR };
};
export const getProductRequest = (id: string): IAction<string> => ({
  type: actionTypes.FETCH_PRODUCT_REQUEST,
  payload: id,
});

export const getProductSuccess = (payload: IProduct): IAction<IProduct> => ({
  type: actionTypes.FETCH_PRODUCT_SUCCESS,
  payload,
});

export const getProductFailure = (payload: IPayload<IProduct>): IAction => ({
  type: actionTypes.FETCH_PRODUCT_FAILURE,
  payload,
});
export const getProductClear = (): IAction => ({
  type: actionTypes.FETCH_PRODUCT_CLEAR,
});

export const deleteProductRequest = (id: string): IAction<string> => ({
  type: actionTypes.DELETE_PRODUCT_REQUEST,
  payload: id,
});

export const deleteProductSuccess = (payload: any): IAction => ({
  type: actionTypes.DELETE_PRODUCT_SUCCESS,
  payload,
});

export const deleteProductFailure = (payload: IPayload<IProduct>): IAction => ({
  type: actionTypes.DELETE_PRODUCT_FAILURE,
  payload,
});

export const addProductFeeRequest = (
  payload: IProduct,
  id: string
): IAction<IProduct> => ({
  type: actionTypes.ADD_PRODUCT_FEE_REQUEST,
  payload,
  id,
});

export const addProductFeeSuccess = (payload: IProduct): IAction<IProduct> => ({
  type: actionTypes.ADD_PRODUCT_FEE_SUCCESS,
  payload,
});

export const addProductFeeFailure = (payload: IPayload<IProduct>): IAction => ({
  type: actionTypes.ADD_PRODUCT_FEE_FAILURE,
  payload,
});

export const updateProductFeeRequest = (
  payload: IProduct
): IAction<IProduct> => ({
  type: actionTypes.UPDATE_PRODUCT_FEE_REQUEST,
  payload,
});

export const updateProductFeeSuccess = (
  payload: IProduct
): IAction<IProduct> => ({
  type: actionTypes.UPDATE_PRODUCT_FEE_SUCCESS,
  payload,
});

export const updateProductFeeFailure = (
  payload: IPayload<IProduct>
): IAction => ({
  type: actionTypes.UPDATE_PRODUCT_FEE_FAILURE,
  payload,
});

export const getProductFeeRequest = (
  payload: IPayload<IProductFee>
): IAction<IProductFee> => ({
  type: actionTypes.GET_PRODUCT_FEE_REQUEST,
  payload,
});

export const getProductFeeSuccess = (payload: IProduct): IAction<IProduct> => ({
  type: actionTypes.GET_PRODUCT_FEE_SUCCESS,
  payload,
});

export const getProductFeeFailure = (payload: IPayload<IProduct>): IAction => ({
  type: actionTypes.GET_PRODUCT_FEE_FAILURE,
  payload,
});
export const getProductFeesRequest = (
  payload: IPayload<string>
): IAction<IProduct> => ({
  type: actionTypes.GET_PRODUCT_FEES_REQUEST,
  payload,
});

export const getProductFeesClear = (
  payload: IPayload<string>
): IAction<IProduct> => ({
  type: actionTypes.CLEAR_PRODUCT_FEE_FETCH,
  payload,
});
export const getProductFeesSuccess = (
  payload: IProduct
): IAction<IProduct> => ({
  type: actionTypes.GET_PRODUCT_FEES_SUCCESS,
  payload,
});

export const getProductFeesFailure = (
  payload: IPayload<IProduct>
): IAction => ({
  type: actionTypes.GET_PRODUCT_FEES_FAILURE,
  payload,
});

export const deleteProductFeeRequest = (id: string): IAction<string> => ({
  type: actionTypes.DELETE_PRODUCT_FEE_REQUEST,
  payload: id,
});

export const deleteProductFeeSuccess = (payload: any): IAction => ({
  type: actionTypes.DELETE_PRODUCT_FEE_SUCCESS,
  payload,
});

export const deleteProductFeeFailure = (
  payload: IPayload<IProduct>
): IAction => ({
  type: actionTypes.DELETE_PRODUCT_FEE_FAILURE,
  payload,
});

export const addProductEducationRequest = (payload: any): IAction<any> => ({
  type: actionTypes.ADD_PRODUCT_EDUCATION_REQUEST,
  payload,
});

export const addProductEducationSuccess = (payload: any): IAction<any> => ({
  type: actionTypes.ADD_PRODUCT_EDUCATION_SUCCESS,
  payload,
});

export const addProductEducationFailure = (
  payload: IPayload<any>
): IAction => ({
  type: actionTypes.ADD_PRODUCT_EDUCATION_FAILURE,
  payload,
});

export const updateProductEducationRequest = (
  id: string,
  payload: any
): IAction<any> => ({
  type: actionTypes.UPDATE_PRODUCT_EDUCATION_REQUEST,
  payload,
  id,
});

export const updateProductEducationSuccess = (payload: any): IAction<any> => ({
  type: actionTypes.UPDATE_PRODUCT_EDUCATION_SUCCESS,
  payload,
});

export const updateProductEducationFailure = (
  payload: IPayload<any>
): IAction => ({
  type: actionTypes.UPDATE_PRODUCT_EDUCATION_FAILURE,
  payload,
});

export const getProductEducationRequest = (
  payload: IPayload<any>
): IAction<any> => ({
  type: actionTypes.GET_PRODUCT_EDUCATION_REQUEST,
  payload,
});

export const getProductEducationSuccess = (payload: any): IAction<any> => ({
  type: actionTypes.GET_PRODUCT_EDUCATION_SUCCESS,
  payload,
});

export const getProductEducationFailure = (
  payload: IPayload<any>
): IAction => ({
  type: actionTypes.GET_PRODUCT_EDUCATION_FAILURE,
  payload,
});
export const getProductEducationsRequest = (payload: string): any => ({
  type: actionTypes.GET_PRODUCT_EDUCATIONS_REQUEST,
  payload,
});

export const getProductEducationsClear = (): IAction<any> => ({
  type: actionTypes.CLEAR_PRODUCT_EDUCATION_FETCH,
});
export const getProductEducationsSuccess = (payload: any): IAction<any> => ({
  type: actionTypes.GET_PRODUCT_EDUCATIONS_SUCCESS,
  payload,
});

export const getProductEducationsFailure = (
  payload: IPayload<any>
): IAction => ({
  type: actionTypes.GET_PRODUCT_EDUCATIONS_FAILURE,
  payload,
});

export const deleteProductEducationRequest = (id: string): IAction<string> => ({
  type: actionTypes.DELETE_PRODUCT_EDUCATION_REQUEST,
  payload: id,
});

export const deleteProductEducationSuccess = (payload: any): IAction => ({
  type: actionTypes.DELETE_PRODUCT_EDUCATION_SUCCESS,
  payload,
});

export const deleteProductEducationFailure = (
  payload: IPayload<any>
): IAction => ({
  type: actionTypes.DELETE_PRODUCT_EDUCATION_FAILURE,
  payload,
});

export const addProductLanguageRequest = (payload: any): IAction<any> => ({
  type: actionTypes.ADD_PRODUCT_LANGUAGE_REQUEST,
  payload,
});

export const addProductLanguageSuccess = (payload: any): IAction<any> => ({
  type: actionTypes.ADD_PRODUCT_LANGUAGE_SUCCESS,
  payload,
});

export const addProductLanguageFailure = (payload: IPayload<any>): IAction => ({
  type: actionTypes.ADD_PRODUCT_LANGUAGE_FAILURE,
  payload,
});

export const updateProductLanguageRequest = (
  id: string,
  payload: any
): IAction<any> => ({
  type: actionTypes.UPDATE_PRODUCT_LANGUAGE_REQUEST,
  payload,
  id,
});

export const updateProductLanguageSuccess = (payload: any): IAction<any> => ({
  type: actionTypes.UPDATE_PRODUCT_LANGUAGE_SUCCESS,
  payload,
});

export const updateProductLanguageFailure = (
  payload: IPayload<any>
): IAction => ({
  type: actionTypes.UPDATE_PRODUCT_LANGUAGE_FAILURE,
  payload,
});

export const getProductLanguageRequest = (
  payload: IPayload<any>
): IAction<any> => ({
  type: actionTypes.GET_PRODUCT_LANGUAGE_REQUEST,
  payload,
});

export const getProductLanguageSuccess = (payload: any): IAction<any> => ({
  type: actionTypes.GET_PRODUCT_LANGUAGE_SUCCESS,
  payload,
});

export const getProductLanguageFailure = (payload: IPayload<any>): IAction => ({
  type: actionTypes.GET_PRODUCT_LANGUAGE_FAILURE,
  payload,
});
export const getProductLanguagesRequest = (
  payload: IPayload<string>
): IAction<any> => ({
  type: actionTypes.GET_PRODUCT_LANGUAGES_REQUEST,
  payload,
});

export const getProductLanguagesClear = (): IAction<any> => ({
  type: actionTypes.CLEAR_PRODUCT_LANGUAGE_FETCH,
});
export const getProductLanguagesSuccess = (payload: any): IAction<any> => ({
  type: actionTypes.GET_PRODUCT_LANGUAGES_SUCCESS,
  payload,
});

export const getProductLanguagesFailure = (
  payload: IPayload<any>
): IAction => ({
  type: actionTypes.GET_PRODUCT_LANGUAGES_FAILURE,
  payload,
});

export const deleteProductLanguageRequest = (id: string): IAction<string> => ({
  type: actionTypes.DELETE_PRODUCT_LANGUAGE_REQUEST,
  payload: id,
});

export const deleteProductLanguageSuccess = (payload: any): IAction => ({
  type: actionTypes.DELETE_PRODUCT_LANGUAGE_SUCCESS,
  payload,
});

export const deleteProductLanguageFailure = (
  payload: IPayload<any>
): IAction => ({
  type: actionTypes.DELETE_PRODUCT_LANGUAGE_FAILURE,
  payload,
});

export const productFiltersRequestAction = (): IAction<any> => ({
  type: actionTypes.REQUEST_PRODUCT_FILTERS_REQUEST,
});

export const productFiltersRequestSuccess = (payload: any): IAction<any> => ({
  type: actionTypes.REQUEST_PRODUCT_FILTERS_SUCCESS,
  payload,
});
