import React, { useState } from 'react';
import { OverViewHeader } from '@shared-components/elements';
import {
  DEPARTMENT_DETAIL_TITLE,
  TEXT,
  URL_DEPARTMENT,
} from '@moxie/constants';
import { Link } from 'react-router-dom';
import { Button, Spin } from 'antd';
import { LeftOutlined } from '@ant-design/icons';
import { errorHandler } from '@moxie/utils';
import { IDepartment } from '@shared-components/models';
import { DepartmentProfile } from './department-profile';
import { useForm } from 'antd/lib/form/Form';
import { DepartmentDrawer } from './department-form-drawer';
import { departmentActions, useAppSelector } from '@lyra/core';
import { useDispatch } from 'react-redux';

const DepartmentDetailComponent: React.FC = () => {
  const departmentData = useAppSelector(
    (state) => state.departments.singleData
  );
  const loading = useAppSelector((state) => state.departments.loading);
  const [form] = useForm();
  const dispatch = useDispatch();
  const [disabled, setDisabled] = useState(true);
  const [open, setOpen] = useState(false);

  const handleOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const handleFormSubmit = () => {
    form.submit();
  };

  const handleSubmit = async (data: IDepartment) => {
    let postData;
    try {
      postData = { ...data, id: departmentData?.id };
      await dispatch(departmentActions.updateDepartmentRequest(postData));
      await dispatch(departmentActions.getDepartmentRequest(postData.id));
    } catch (error) {
      errorHandler(error);
    }
    handleClose();
  };

  return (
    <Spin spinning={loading} tip={TEXT.LOADING}>
      <OverViewHeader
        title={DEPARTMENT_DETAIL_TITLE}
        actions={
          <Link to={URL_DEPARTMENT}>
            <Button icon={<LeftOutlined />}>{TEXT.DEPARTMENT_LIST}</Button>
          </Link>
        }
      />

      <DepartmentDrawer
        visible={open}
        onClose={handleClose}
        handleFormSubmit={handleFormSubmit}
        isEdit={open}
        form={form}
        disabled={disabled}
        setDisabled={setDisabled}
        handleSubmit={handleSubmit}
        initialData={departmentData}
      />

      {departmentData && (
        <DepartmentProfile data={departmentData} handleOpen={handleOpen} />
      )}
    </Spin>
  );
};

export { DepartmentDetailComponent };
