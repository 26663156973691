import { ContactFilter, ContactListRouteParam } from "@model/contact";
import { ObjectToParams } from "@moxie/utils";
import { SelectValue } from "antd/lib/select";
import { useEffect, useState } from "react";
import { useHistory, useParams } from "react-router-dom"

export const useContactBranchFilter = () => {
  const { name } = useParams<ContactListRouteParam>();
  const [filter, setFilter] = useState<ContactFilter>({ selectBy: name, branch: undefined });
  const history = useHistory();

  useEffect(() => {
    if (filter.selectBy !== name) setFilter({ ...filter, selectBy: name });
  }, [name]);

  const handleBranchChange = (branch: SelectValue) => {
    setFilter({ ...filter, branch: branch?.toString() ?? undefined })

    history.push(`?${ObjectToParams({
      ...filter,
      page: 1,
      ...(branch && { branch })
    })}`)


  }

  return { handleBranchChange, filter };
}
