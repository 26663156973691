import React from 'react';
import { Space, Spin } from 'antd';
import { Button, DrawerElem } from '@shared-components/elements';
import {
  CANCEL_BTN,
  DRAWER_WIDTH,
  SAVE_BTN,
  TITLE,
  UPDATE_BTN,
} from '@moxie/constants';
import { ContactApplicationForm } from '@shared-components/forms';
import { ILeadApplicationDrawer } from '@shared-components/models';

const CreateApplicationDrawer = ({
  isOpen,
  onClose,
  form,
  handleFormSubmit,
  isSubmitted,
  handleSubmit,
  initialData,
  isEdit,
  disabled,
  setDisabled,
}: ILeadApplicationDrawer) => (
  <DrawerElem
    title={TITLE.CREATE_APPLICATION}
    width={DRAWER_WIDTH}
    visible={isOpen}
    onClose={onClose}
    footer={
      <div className="padding-bottom-2 text-align-right">
        <Space direction="horizontal">
          <Button onClick={onClose} className="lead-margin-right">
            {CANCEL_BTN}
          </Button>
          <Button
            type="primary"
            onClick={handleFormSubmit}
            disabled={disabled || isSubmitted}
          >
            {isEdit ? UPDATE_BTN : SAVE_BTN}
          </Button>
        </Space>
      </div>
    }
  >
    <Spin spinning={isSubmitted}>
      <ContactApplicationForm
        form={form}
        initialData={initialData}
        onSubmit={handleSubmit}
        setDisabled={setDisabled}
      />
    </Spin>
  </DrawerElem>
);

export { CreateApplicationDrawer };
