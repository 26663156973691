/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from 'react';
import { Button, Space, Spin } from 'antd';
import { useForm } from 'antd/lib/form/Form';
import { DrawerElem } from '@shared-components/elements';
import { INote, INoteAddEditProps } from '@shared-components/models';
import {
  CANCEL_BTN,
  CREATE_NOTE_TITLE,
  EDIT,
  EDIT_NOTE_TITLE,
  LEAD_INITIAL_DATA,
  NOTE_INITIAL_DATA,
  SAVE_BTN,
  UPDATE_BTN,
  VIEW_NOTE_TITLE,
} from '@moxie/constants';
import { errorHandler } from '@moxie/utils';
import { NoteForm } from '../../forms/note';
import EditOutlined from '@ant-design/icons/lib/icons/EditOutlined';
import { useAppSelector } from '@lyra/core';
import { successNotificationHandler } from '@moxie/shared';
import AppointmentNoteList from '../../leads-appointment/libs/appintment-notes-list';
import { postNotes, updateNote, getAllNotes } from '@lyra/services.api';

const NoteAddEdit: React.FC<INoteAddEditProps> = ({
  name,
  isOpen,
  onClose,
  triggerRefresh,
  leadId,
  setNoteForm,
  isEdit,
  noteFormData,
  onChange,
  isView,
  isAdd,
  setIsEdit,
  setIsView,
  appointmentId,
  archived,
  disabled,
  setDisabled,
  isSubmitted,
  setIsSubmitted,
}: INoteAddEditProps) => {
  const [form] = useForm();
  const [list, setList] = useState<{ title: string; description: string }[]>([
    NOTE_INITIAL_DATA,
  ]);

  const socket: any = useAppSelector((state) => state.socket.wss);
  const user: any = useAppSelector((state) => state.auth.user);
  const currentlead: any = useAppSelector((state) => state.lead.lead);

  useEffect(() => {
    getAllAppointmentNotes();
  }, [isOpen]);

  const handleSubmitButtonClick = async () => {
    form.submit();
  };

  const handleSubmit = async (data: INote) => {
    setIsSubmitted(true);
    if (isEdit) {
      handleEdit(data);
    } else {
      try {
        const response: any = await postNotes(data, leadId, appointmentId);
        const dataLeadActivities = {
          user_id: user.id,
          note_id: response?.data?.data.id,
          data: response?.data,
          parent_id: response?.data?.data.parent_id,
          company_id: user?.company?.id,
        };
        socket.emit('added-new-note-to-server', dataLeadActivities);

        socket.emit('create-note-notification-to-server', {
          user_id: user.id,
          receiver_id: currentlead?.assignee_user_id,
          receivers_many: currentlead?.followers.map((item: any) => item.id),
          type: 'note-created',
          parent_id: response?.data?.data.parent_id,
        });
        successNotificationHandler(response?.data?.message);
        if (response?.data?.success) {
          setNoteForm(LEAD_INITIAL_DATA);
          onClose();
          triggerRefresh && triggerRefresh();
        }
        getAllAppointmentNotes();
        setIsSubmitted(false);
      } catch (err) {
        errorHandler(err);
      }
    }
  };

  const handleEdit = async (data: INote) => {
    const { title, description } = data;
    const leadData = { title, description };
    if (noteFormData?.id) {
      try {
        const response: any = await updateNote(
          leadData,
          leadId,
          noteFormData.id
        );
        const dataLeadActivities = {
          user_id: user.id,
          note_id: response?.data?.id,
          data: response?.data,
          parent_id: response?.data?.data.parent_id,
          company_id: user?.company?.id,
        };
        socket.emit('updated-note-to-server', dataLeadActivities);
        socket.emit('create-note-notification-to-server', {
          user_id: user.id,
          receiver_id: currentlead?.assignee_user_id,
          receivers_many: currentlead?.followers.map((item: any) => item.id),
          type: 'note-updated',
          parent_id: response?.data?.data.parent_id,
        });
        successNotificationHandler(response?.data?.message);
        if (response?.data?.success) {
          setIsSubmitted(false);
          setNoteForm(LEAD_INITIAL_DATA);
          onClose();
          triggerRefresh && triggerRefresh();
        }
      } catch (err) {
        errorHandler(err);
      }
    }
  };

  const toggleEdit = () => {
    setIsEdit(true);
    setIsView(false);
  };

  const getAllAppointmentNotes = async () => {
    setIsSubmitted(true);
    const response = await getAllNotes(leadId, appointmentId);
    setList(response?.data?.data?.data);
    setIsSubmitted(false);
  };

  const editIcon = (
    <>
      {VIEW_NOTE_TITLE}
      {!archived && (
        <Button
          type="primary"
          onClick={() => toggleEdit()}
          icon={<EditOutlined />}
          className="edit-action"
        >
          {EDIT}
        </Button>
      )}
    </>
  );

  return (
    <DrawerElem
      title={
        isEdit
          ? EDIT_NOTE_TITLE
          : isView
          ? editIcon
          : isAdd
          ? CREATE_NOTE_TITLE
          : name === 'appointment'
          ? 'Add Appointment Note'
          : ''
      }
      width={720}
      onClose={onClose}
      visible={isOpen}
      footer={
        !isView && (
          <div className="padding-bottom-2 text-align-right">
            <Space direction="horizontal">
              <Button
                onClick={onClose}
                className="lead-margin-right"
                htmlType="reset"
              >
                {CANCEL_BTN}
              </Button>
              <Button
                onClick={handleSubmitButtonClick}
                type="primary"
                disabled={archived || disabled}
              >
                {isEdit ? UPDATE_BTN : SAVE_BTN}
              </Button>
            </Space>
          </div>
        )
      }
    >
      <Spin spinning={isSubmitted}>
        <NoteForm
          noteForm={noteFormData}
          setNoteForm={setNoteForm}
          form={form}
          onSubmit={handleSubmit}
          isView={isView}
          setDisabled={setDisabled}
          onChange={onChange}
        />
      </Spin>
      {name === 'appointment' && (
        <AppointmentNoteList
          leadId={leadId}
          appointmentId={appointmentId}
          list={list && list}
        />
      )}
    </DrawerElem>
  );
};

export { NoteAddEdit };
