/* eslint-disable no-unused-vars */
/*   eslint-disable react-hooks/exhaustive-deps */
import { EditOutlined } from '@ant-design/icons';
import { leadActions, useAppSelector } from '@lyra/core';
import permit from '@lyra/permit';
import Can, { PermissionAction } from '@lyra/privilege';
import {
  CANCEL_BTN,
  CLOSE_BTN,
  CREATE_LEAD_TITLE,
  EDIT,
  EDIT_LEAD_TITLE,
  SAVE_BTN,
  UPDATE_BTN,
} from '@moxie/constants';
import { DrawerElem } from '@shared-components/elements';
import { LeadForm } from '@shared-components/forms';
import { IAddEditProps, ILead } from '@shared-components/models';
import { Button, Space, Spin } from 'antd';
import { useForm } from 'antd/lib/form/Form';
import { RootState } from 'apps/lyra/src/core/store';
import { ContactsEntity } from 'apps/lyra/src/libs/privilege/can';
import React, { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { AM_PERMISSIONS } from '@moxie/constants';
import isAssignedFollowed from '@lyra/assigned-followed';
const { CONTACTS } = AM_PERMISSIONS;

const LeadAddEdit: React.FC<IAddEditProps<any>> = ({
  isOpen,
  onClose,
  submitted,
  setSubmitted,
  initialData,
  isView,
  disabled,
  setDisabled,
  handleView,
  onAfterSuccess,
}: IAddEditProps<any>) => {
  const rolePerms: string[] = useAppSelector(
    (state: any) => state?.auth?.user?.am_role?.permissions
  );
  const [form] = useForm();
  const { leadError } = useAppSelector((store: RootState) => {
    return { leadError: store.lead.error, loading: store.lead.loading };
  });
  const dispatch = useDispatch();
  const socket = useAppSelector((state) => state.socket.wss);
  const user = useAppSelector((store) => store.auth.user);

  useEffect(() => {
    if (leadError === true) {
      handleSubmitFailure();
    } else if (leadError === false) {
      handleSubmitSuccess();
    }
  }, [leadError, form]);

  const handleSubmitSuccess = () => {
    dispatch(leadActions.resetErrorAction());
    onClose();
    setTimeout(() => form.resetFields(), 300);
    onAfterSuccess && onAfterSuccess();
  };

  const handleSubmitFailure = () => {
    setSubmitted && setSubmitted(false);
    dispatch(leadActions.resetErrorAction());
  };

  const handleSubmitButtonClick = async () => {
    form.submit();
  };

  const handleSubmit = async (data: ILead) => {
    if (initialData?.id) {
      if (data?.assignee_user_id === undefined) {
        dispatch(
          leadActions.editLeadAction({
            ...data,
            id: initialData.id,
            assignee_user_id: 'null',
          })
        );
      } else {
        dispatch(leadActions.editLeadAction({ ...data, id: initialData.id }));
      }
    } else {
      dispatch(leadActions.addLeadAction(data, socket, user));
    }
  };

  return (
    <DrawerElem
      title={
        <div>
          {initialData?.id
            ? isView
              ? // `View ${initialData?.status}`
              `View Contact`
              : EDIT_LEAD_TITLE
            : CREATE_LEAD_TITLE}
          {/* {VIEW_CONTACT_TITLE} */}
          {isView && handleView && (
            <>
              {
                (
                  isAssignedFollowed("contacts", initialData, user?.id) || permit([CONTACTS.MANAGE], rolePerms)
                )
                &&
                !initialData?.archived && (
                  <Button
                    type="primary"
                    onClick={() => handleView(false)}
                    icon={<EditOutlined />}
                    className="edit-action"
                  >
                    {EDIT}
                  </Button>
                )
              }
            </>
          )}
        </div>
      }
      width={720}
      onClose={onClose}
      visible={isOpen}
      footer={
        <div className="padding-bottom-2 text-align-right">
          <Space direction="horizontal">
            <Button onClick={onClose} className="lead-margin-right">
              {isView ? CLOSE_BTN : CANCEL_BTN}
            </Button>
            {!isView && (
              <Button
                onClick={handleSubmitButtonClick}
                type="primary"
                disabled={disabled}
              >
                {initialData?.id ? UPDATE_BTN : SAVE_BTN}
              </Button>
            )}
          </Space>
        </div>
      }
    >
      <Spin spinning={submitted}>
        <LeadForm
          form={form}
          isView={isView}
          onSubmit={handleSubmit}
          initialData={initialData}
          setDisabled={setDisabled}
        />
      </Spin>
    </DrawerElem>
  );
};

LeadAddEdit.defaultProps = {
  isView: false,
};
export { LeadAddEdit };
