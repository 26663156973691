import React, { FC } from 'react';
import { Row, Col, Typography, Avatar } from 'antd';
import { INoteAddUser } from '@shared-components/models';

const NoteAddedByName: FC<INoteAddUser> = ({
  firstName,
  lastName,
  email,
  index,
}: INoteAddUser) => {
  const { Text } = Typography;

  return (
    <Row className="lead-table__name user-name-card">
      <Col className="align-items-center user-name-card__avatar">
        <Avatar size="large">
          {firstName?.substring(0, 1) + lastName?.substring(0, 1)}
        </Avatar>
      </Col>
      <Col className="user-name-card__name">
          <div key={index}>
            <Col span={24}>
              <Text className="text-primary-color">{`${firstName} ${lastName}`}</Text>
            </Col>
            <Col>
              <Text className="text-primary-color">
                <small>{email}</small>
              </Text>
            </Col>
          </div>
      </Col>
    </Row>
  );
};

export default NoteAddedByName;
