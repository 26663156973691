import { API_URL } from '@moxie/constants';
import { IInstitutionDetail, IResponse } from '@shared-components/models';
import { axiosInstance as axios } from './axios';

const getInstitutions = async (params = '') => {
  return axios.get(`${API_URL.INSTITUTIONS}/${params}`);
};

const getInstitutionBranchesApi = async (branchId: string, page = 1) => {
  return axios.get(
    `${API_URL.INSTITUTIONS}/branches/${branchId}?limit=10&page=${page}`
  );
};

const getInstitutionProductsApi = async (insitututionId: string, page = 1) => {
  return axios.get(
    `${API_URL.INSTITUTIONS}/${insitututionId}/products?limit=10&page=${page}`
  );
};

const getInstitutionByService = async (service_id?: string) => {
  const params = {
    service_id,
  };

  return axios.get(`${API_URL.INSTITUTIONS}/service`, { params });
};
const getInstitutionDetail = async (id: string) => {
  return axios.get(`${API_URL.INSTITUTIONS}/${id}`);
};

const getInstituteTypes = async () => {
  return axios.get(`${API_URL.INSTITUTIONS}/types/all`);
};

const saveInstitution = async (body: IInstitutionDetail) => {
  return axios.post(`${API_URL.INSTITUTIONS}`, body);
};

const updateInstitution = async (body: IInstitutionDetail) => {
  return axios.put(`${API_URL.INSTITUTIONS}/${body?.id}`, body);
};

const uploadLogo = async (body: any) => {
  return axios.post(`${API_URL.INSTITUTIONS}/upload`, body);
};
const getWorkflowsByInstitution = async (
  institution_id: string
): Promise<IResponse> => {
  return axios.get(`${API_URL.INSTITUTIONS}/workflows/${institution_id}`);
};
const deleteInstitution = async (id: number) => {
  return axios.delete(`${API_URL.INSTITUTIONS}/${id}`);
};

const checkInstitutionName = (name: string, id?: number): Promise<any> => {
  const params = {
    name: name,
    id: id,
  };
  return axios.get(`${API_URL.INSTITUTIONS}/check/name`, { params });
};

export {
  getInstitutions,
  getInstitutionDetail,
  saveInstitution,
  getInstituteTypes,
  updateInstitution,
  deleteInstitution,
  uploadLogo,
  checkInstitutionName,
  getWorkflowsByInstitution,
  getInstitutionByService,
  getInstitutionBranchesApi,
  getInstitutionProductsApi,
};
