const alias = 'SERVICE';

export const GET_SERVICES_REQUEST = `GET_${alias}S_REQUEST`;
export const GET_SERVICES_SUCCESS = `GET_${alias}S_SUCCESS`;
export const GET_SERVICES_FAILURE = `GET_${alias}S_FAILURE`;

export const GET_PRODUCT_SERVICES_REQUEST = `GET_PRODUCT_${alias}S__REQUEST`;
export const GET_PRODUCT_SERVICES_SUCCESS = `GET_PRODUCT_${alias}S__SUCCESS`;
export const GET_PRODUCT_SERVICES_FAILURE = `GET_PRODUCT_${alias}S__FAILURE`;

export const GET_SERVICE_REQUEST = `GET_${alias}_REQUEST`;
export const GET_SERVICE_SUCCESS = `GET_${alias}_SUCCESS`;
export const GET_SERVICE_FAILURE = `GET_${alias}_FAILURE`;

export const GET_ADMIN_SERVICES_REQUEST = `GET_ADMIN_${alias}_REQUEST`;
export const GET_ADMIN_SERVICES_SUCCESS = `GET_ADMIN_${alias}_SUCCESS`;
export const GET_ADMIN_SERVICES_FAILURE = `GET_ADMIN_${alias}_FAILURE`;

export const ADD_SERVICE_REQUEST = `ADD_${alias}_REQUEST`;
export const ADD_SERVICE_SUCCESS = `ADD_${alias}_SUCCESS`;
export const ADD_SERVICE_FAILURE = `ADD_${alias}_FAILURE`;

export const UPDATE_SERVICE_REQUEST = `UPDATE_${alias}_REQUEST`;
export const UPDATE_SERVICE_SUCCESS = `UPDATE_${alias}_SUCCESS`;
export const UPDATE_SERVICE_FAILURE = `UPDATE_${alias}_FAILURE`;

export const DELETE_SERVICE_REQUEST = `DELETE_${alias}_REQUEST`;
export const DELETE_SERVICE_SUCCESS = `DELETE_${alias}_SUCCESS`;
export const DELETE_SERVICE_FAILURE = `DELETE_${alias}_FAILURE`;

export const SYNC_SERVICE_CREATE_REQUEST = `ADD_SYNC_${alias}_CREATE_REQUEST`;
export const SYNC_SERVICE_CREATE_SUCCESS = `ADD_SYNC_${alias}_CREATE_SUCCESS`;
export const SYNC_SERVICE_CREATE_FAILURE = `ADD_SYNC_${alias}_CREATE_FAILURE`;

export const CLEAR_SERVICE_FETCH = `CLEAR_${alias}_FETCH`;
