/* eslint-disable react/display-name */
//commented code are for future use
import {
  DeleteOutlined,
  EditOutlined,
  MoreOutlined,
  PlusOutlined,
  SearchOutlined,
} from '@ant-design/icons';
import { applicationActions, useAppSelector } from '@lyra/core';
import { getInterestedServices } from '@lyra/services.api';
import {
  CONFIRM_MESSAGE,
  CONFIRM_TITLE,
  DELETE_BTN,
  EDIT_BTN,
  LABEL,
  SEARCH_PRODUCTS_BTN,
  START_APPLICATION,
  TEXT,
  URL_PRODUCT_DETAIL_LINK,
} from '@moxie/constants';
import { ProductDetailUtil, SubjectAreaTagGroup, Table } from '@moxie/shared';
import { Button, popConfirm } from '@shared-components/elements';
import {
  IInstitutionDetail,
  IInterestedService,
  IInterestedServiceTableExpandableProps,
  IInterestedServiceTableProps,
  ILeadApplication,
  IProduct,
  ISubjectDiscipline,
  ISubjectDisciplineAreas,
  ISubjectDisciplinePayload,
  IWorkflow,
} from '@shared-components/models';
import { Col, Dropdown, Menu, Row, Tooltip, Typography } from 'antd';
import { ColumnsType } from 'antd/lib/table';
import React, { ReactNode, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Link } from 'react-router-dom';
import * as _ from 'lodash';

const { Text } = Typography;

const InterestedServiceTableExpandable: React.FC<IInterestedServiceTableExpandableProps> = ({
  data,
}: IInterestedServiceTableExpandableProps) => {
  const subjectDiscipline: ISubjectDisciplinePayload[] = useSelector(
    (store: any) => store.subject_discipline.disciplines
  );
  const [subjectAreas, setSubjectAreas] = useState<ISubjectDisciplineAreas[]>(
    []
  );

  useEffect(() => {
    const element = document.querySelector(
      `[data-row-key="${data.id}"] .ant-table-row-expand-icon-cell`
    );
    const btn = element?.querySelector('button');

    const addClassToElement = (): void =>
      element?.classList.add('interested-service-border');
    const removeClassFromElement = (): void =>
      element?.classList.remove('interested-service-border');
    const handleBtnClick = () => {
      if (btn?.classList.contains('ant-table-row-expand-icon-collapsed'))
        addClassToElement();
      else removeClassFromElement();
    };

    if (element) addClassToElement();
    btn?.addEventListener('click', handleBtnClick);
    return () => {
      btn?.removeEventListener('click', handleBtnClick);
    };
  }, []);

  useEffect(() => {
    const discipline: ISubjectDiscipline = subjectDiscipline.find(
      (item: ISubjectDiscipline) =>
        item.id === parseInt(data?.discipline?.toString() || '')
    );
    const subjectArea: ISubjectDisciplineAreas[] = [];
    if (data?.subject_area) {
      let tempSubjectArea = data?.subject_area;
      if (typeof tempSubjectArea === 'string') {
        tempSubjectArea = tempSubjectArea.split(',');
      }
      tempSubjectArea.map((item: string) => {
        const temp = discipline?.subject_areas?.find(
          (data: ISubjectDisciplineAreas) => data.id === parseInt(item)
        );
        if (temp) subjectArea.push(temp);
      });
      setSubjectAreas(subjectArea);
    }
  }, [data.discipline]);

  return (
    <Row
      gutter={[0, 8]}
      className="interested-service-expanded interested-service-border"
    >
      <Col span={24}>
        <Row justify="space-between">
          <Col span={12}>
            <Text type="secondary" strong>
              {TEXT.DISCIPLINE}:
            </Text>
            <div>
              {subjectDiscipline.find(
                (item: ISubjectDiscipline) =>
                  parseInt(data?.discipline?.toString() || '') === item.id
              )?.discipline || ''}
            </div>
          </Col>
          <Col span={12}>
            <Text type="secondary" strong>
              {LABEL.SUBJECT_AREA}:
            </Text>
            <div>
              <SubjectAreaTagGroup subjectAreas={subjectAreas} />
            </div>
          </Col>

          {/*TODO: Refactor later, as there is no data to display.*/}
          {/*<Col span={6}>*/}
          {/*  <Text type="secondary" strong>*/}
          {/*    {TEXT.DURATION}:*/}
          {/*  </Text>*/}
          {/*  <div>*/}
          {/*    {*/}
          {/*      INTERESTED_SERVICE_DURATION.find(*/}
          {/*        (item) => item.value === data?.duration*/}
          {/*      )?.name*/}
          {/*    }*/}
          {/*  </div>*/}
          {/*</Col>*/}
          {/*<Col span={6}>*/}
          {/*  <Text type="secondary" strong>*/}
          {/*    {LABEL.FEE_RANGE}:*/}
          {/*  </Text>*/}
          {/*  <div>{data?.fees}</div>*/}
          {/*</Col>*/}
        </Row>
      </Col>
      <Col span={24}>
        <Text type="secondary" strong>
          {LABEL.COMMENTS}:
        </Text>

        <ProductDetailUtil
          index={Number(1)}
          remark={data?.comment || ''}
          count={200}
        />
      </Col>
    </Row>
  );
};

const InterestedServiceTable: React.FC<IInterestedServiceTableProps> = ({
  leadId,
  onEdit,
  refresh,
  onDelete,
  onSearch,
  archived,
}: IInterestedServiceTableProps) => {
  const dispatch = useDispatch();
  const [filter] = useState({});
  const { user, socket, lead } = useAppSelector((state) => ({
    lead: state.lead.lead,
    socket: state.socket.wss,
    user: state.auth.user,
  }));
  const menu = (handleEdit: () => void, handleDelete: () => void): any => {
    return (
      <Menu>
        <Menu.Item key={1} icon={<EditOutlined />} onClick={handleEdit}>
          {EDIT_BTN}
        </Menu.Item>
        <Menu.Item
          key={3}
          icon={<DeleteOutlined />}
          danger
          onClick={handleDelete}
        >
          {DELETE_BTN}
        </Menu.Item>
      </Menu>
    );
  };

  const handleStartApplication = (data: ILeadApplication) => {
    if(archived) return;
    popConfirm(
      CONFIRM_TITLE.START_APPLICATION,
      CONFIRM_MESSAGE.START_APPLICATION,
      () => {
        const saveData = {
          contact_id: data.contact_id,
          service_id: data.service_id,
          product_id: data.product_id,
          institution_id: data.institution_id,
          institution_branch_id: data.institution_branch_id,
          workflow_type_id: data.workflow?.workflow_type_id,
          country: data.country,
          intake_year: data?.intake_year,
          intake_month: data?.intake_month,
          start_date: data?.start_date,
          end_date: data?.end_date,
        };
        dispatch(applicationActions.addRequest(saveData, user, socket, lead));
      }
    );
  };

  const checkExist = (query: string, tag: string, first = false) => {
    return query ? `${!first ? '&' : ''}${tag}=${query}` : '';
  };

  const handleSearchButton = async (data: any) => {
    const {
      // workflow_id,
      // service_id,
      education_level,
      duration,
      discipline,
      subject_area,
    } = data;

    const disciplineAreas: string[] = [];
    // const searchQuery = `?${checkExist(
    //   service_id,
    //   'service',
    //   true
    // )}${checkExist(discipline, 'discipline')}${checkExist(
    //   workflow_id,
    //   'workflow'
    // )}
    if (discipline) {
      disciplineAreas.push(discipline);
      if (subject_area) {
        subject_area
          ?.toString()
          ?.split(',')
          ?.map((item: string) => {
            disciplineAreas.push(discipline + '-' + item);
          });
      }
    }
    const searchQuery = `?${checkExist(education_level, 'level')}${checkExist(
      disciplineAreas?.toString(),
      'disciplineAreas'
    )}${checkExist(duration, 'duration')}`;
    onSearch?.(searchQuery);
  };

  const columns: ColumnsType<IInterestedService> = [
    {
      title: LABEL.SERVICE,
      render: (data: IInterestedService): ReactNode => {
        const { service } = data;
        return (
          <div className="interested-service__service">
            <div className="service_name initial_capital">{service?.name}</div>
            {!archived && (
              <Dropdown
                overlay={() =>
                  menu(
                    () => onEdit(data),
                    () => onDelete(data)
                  )
                }
                className="align-right"
              >
                <Button
                  type="text"
                  className="table-cell-action-btn"
                  icon={<MoreOutlined />}
                />
              </Dropdown>
            )}
          </div>
        );
      },
    },
    {
      title: LABEL.PRODUCT,
      render: ({ product }: { product: IProduct }): ReactNode => {
        return (
          <>
            {product && product?.name ? (
              <Link to={`${URL_PRODUCT_DETAIL_LINK}${product.id}/applications`}>
                <Tooltip overlay={product.name}>
                  <Text className="text-primary-color product-name-table ellipsis-text">
                    {product.name}
                  </Text>
                </Tooltip>
              </Link>
            ) : (
              TEXT.NOT_AVAILABLE
            )}
          </>
        );
      },
    },
    {
      title: LABEL.WORKFLOW,
      render: ({ workflow }: { workflow: IWorkflow }): ReactNode => {
        return (
          <div className="initial_capital">{workflow?.workflowType?.name}</div>
        );
      },
    },
    {
      title: LABEL.COUNTRY,
      render: ({ country }: { country: string }): ReactNode => {
        return <div>{country}</div>;
      },
    },
    {
      title: LABEL.INSTITUTION,
      render: ({
        institution,
      }: {
        institution: IInstitutionDetail;
      }): ReactNode => {
        return (
          <div className="initial_capital">
            {institution?.name ? institution?.name : '-'}
          </div>
        );
      },
    },
    {
      title: TEXT.ACTION,
      render: (data): ReactNode => {
        return (
          <div>
            {data?.product ? (
              <Button
                data-testid="startApplication_btn"
                icon={<PlusOutlined />}
                type="primary"
                onClick={() => {
                  handleStartApplication(data);
                }}
                className={archived?'cursor-disabled':''}
              >
                {START_APPLICATION}
              </Button>
            ) : (
              <Button
                icon={<SearchOutlined />}
                onClick={() => handleSearchButton(data)}
              >
                {SEARCH_PRODUCTS_BTN}
              </Button>
            )}
          </div>
        );
      },
    },
  ];

  const getAllInterestedServices = async (leadId: string, params: any) => {
    const response = await getInterestedServices(leadId, params);
    const formattedResponse = _.orderBy(
      response?.data?.data,
      ['created_at'],
      ['desc']
    );
    response.data.data = formattedResponse;
    return response;
  };

  return (
    <div>
      <Table
        columns={columns}
        refresh={refresh}
        filter={filter}
        expandable={{
          expandedRowRender: (data: IInterestedService): JSX.Element => (
            <InterestedServiceTableExpandable data={data} />
          ),
        }}
        size={'middle'}
        rowSelect={false}
        action={(params) => getAllInterestedServices(leadId, params)}
        tableName={TEXT.INTERESTED_SERVICES}
        className="interested-service-table"
      />
    </div>
  );
};

export { InterestedServiceTable };
