import { put, takeLatest } from 'redux-saga/effects';
import {
  IAction,
  IProfessionalExperience,
  IProfessionalExperiencePayload,
} from '@shared-components/models';
import { errorHandler } from '@moxie/utils';
import {
  addProfessionalExperience,
  updateProfessionalExperience,
  getAllProfessionalExperience as fetchProfessionalExperienceList,
  deleteProfessionalExperience as removeProfessionalExperience,
} from '@lyra/services.api';
import { AxiosResponse } from 'axios';
import {
  LEAD_PROFESSIONAL_EXPERIENCE_RESPONSE_MESSAGE,
  STATUS,
} from '@moxie/constants';

import * as actionProfessionalExperience from './professional-experience.action';
import * as actionTypes from './professional-experience.constant';
import { successNotificationHandler } from '@moxie/shared';

function* createProfessionalExperience({
  payload,
  lead,
  socket,
  user,
}: IAction<IProfessionalExperiencePayload>) {
  try {
    if (payload) {
      const res: AxiosResponse<any> = yield addProfessionalExperience(payload);
      yield put(
        actionProfessionalExperience.addProfessionalExperienceSuccess(
          res.data.data
        )
      );
      socket?.emit('experience-notification', {
        user_id: user?.id,
        parent_id: lead?.id,
        receiver_id: lead?.assignee_user_id,
        receivers_many: lead?.followers_id,
        type: 'experience-added',
        tab: 'professional-experiences',
      });
      successNotificationHandler(
        LEAD_PROFESSIONAL_EXPERIENCE_RESPONSE_MESSAGE.ADD_LEAD_PROFESSIONAL_EXPERIENCE
      );
    }
  } catch (err) {
    errorHandler(err);
    yield put(
      actionProfessionalExperience.addProfessionalExperienceFailure(err)
    );
  }
}

function* editProfessionalExperience({
  payload,
  lead,
  socket,
  user,
}: IAction<IProfessionalExperience>) {
  try {
    if (payload) {
      const response: AxiosResponse<any> = yield updateProfessionalExperience(
        payload
      );
      socket?.emit('experience-notification', {
        user_id: user?.id,
        parent_id: lead?.id,
        receiver_id: lead?.assignee_user_id,
        receivers_many: lead?.followers_id,
        type: 'experience-updated',
        tab: 'professional-experiences',
      });
      yield put(
        actionProfessionalExperience.editProfessionalExperienceSuccess(
          response.data.data
        )
      );
      successNotificationHandler(
        LEAD_PROFESSIONAL_EXPERIENCE_RESPONSE_MESSAGE.UPDATE_LEAD_PROFESSIONAL_EXPERIENCE
      );
    }
  } catch (err) {
    errorHandler(err);
    yield put(
      actionProfessionalExperience.editProfessionalExperienceFailure(err)
    );
  }
}

function* deleteProfessionalExperience({
  payload,
  lead,
  socket,
  user,
}: IAction<IProfessionalExperiencePayload>) {
  try {
    if (!payload) return;
    yield removeProfessionalExperience(payload);
    socket?.emit('experience-notification', {
      user_id: user?.id,
      parent_id: lead?.id,
      receiver_id: lead?.assignee_user_id,
      receivers_many: lead?.followers_id,
      type: 'experience-deleted',
      tab: 'professional-experiences',
    });
    yield put(
      actionProfessionalExperience.deleteProfessionalExperienceSuccess(payload)
    );
    successNotificationHandler(
      LEAD_PROFESSIONAL_EXPERIENCE_RESPONSE_MESSAGE.LEAD_PROFESSIONAL_EXPERIENCE_DELETED
    );
  } catch (err) {
    errorHandler(err);
    yield put(
      actionProfessionalExperience.deleteProfessionalExperienceFailure(err)
    );
  }
}

function* getAllProfessionalExperience({ payload }: IAction<string>) {
  try {
    const res: AxiosResponse<any> = yield fetchProfessionalExperienceList(
      payload
    );
    yield put(
      actionProfessionalExperience.getProfessionalExperiencesSuccess(
        res.data.data
      )
    );
  } catch (e) {
    errorHandler(e);
    yield put(
      actionProfessionalExperience.getProfessionalExperiencesFailure(
        STATUS.FAILED
      )
    );
  }
}

export default function* rootSagas(): unknown {
  yield takeLatest(
    actionTypes.UPDATE_PROFESSIONAL_EXPERIECES_REQUEST,
    editProfessionalExperience
  );
  yield takeLatest(
    actionTypes.GET_PROFESSIONAL_EXPERIECES_REQUEST,
    getAllProfessionalExperience
  );
  yield takeLatest(
    actionTypes.ADD_PROFESSIONAL_EXPERIECES_REQUEST,
    createProfessionalExperience
  );
  yield takeLatest(
    actionTypes.DELETE_PROFESSIONAL_EXPERIECES_REQUEST,
    deleteProfessionalExperience
  );
}
