import { IInitialState, INote } from '@shared-components/models';
import * as actionTypes from './lead-notes.constant';

export type IAction = {
  type: string;
  payload: any;
};

export const initState: IInitialState<INote> = {
  allData: [],
  singleData: {},
  loading: false,
  error: null,
};

export const LEAD_NOTES_FEATURE_KEY = 'lead_notes';

const leadNoteReducer = (state = initState, action: IAction) => {
  switch (action.type) {
    case actionTypes.GET_NOTES_REQUEST:
      return {
        ...state,
        loading: true,
        error: null,
      };
    case actionTypes.GET_NOTES_SUCCESS:
      return {
        ...state,
        loading: false,
        error: null,
        allData: [...action.payload],
      };
    case actionTypes.GET_NOTES_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.payload,
      };

    case actionTypes.ADD_NOTE_REQUEST:
      return {
        ...state,
        loading: true,
        error: null,
      };
    case actionTypes.ADD_NOTE_SUCCESS:
      return {
        ...state,
        loading: false,
        error: null,
        allData: [action.payload, ...state.allData],
      };
    case actionTypes.DELETE_NOTE_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.payload,
      };
    default:
      return state;
  }
};

export { leadNoteReducer };
