import {
  IAction,
  IInitialProductsState,
  IInitialProductState,
  IProduct,
} from '@shared-components/models';
import * as actionTypes from './products.constant';

const initialProductsState: IInitialProductsState<IProduct> = {
  products: [],
  product: {},
  fees: [],
  fee: {},
  product_educations: [],
  product_education: {},
  product_languages: [],
  product_language: {},
  loading: false,
  error: false,
  errorMessage: '',
  productFilters: {},
  education_loading: false,
  language_loading: false,
  fee_loading: false,
};
export const PRODUCTS_FEATURE_KEY = 'product';

const productReducer = (
  state = initialProductsState,
  action: IAction<any>
): IInitialProductState => {
  switch (action.type) {
    case actionTypes.REQUEST_PRODUCT_FILTERS_REQUEST:
      return {
        ...state,
        loading: true,
        error: null,
      };
    case actionTypes.REQUEST_PRODUCT_FILTERS_SUCCESS:
      return {
        ...state,
        loading: false,
        error: null,
        productFilters: action.payload,
      };
    case actionTypes.FETCH_PRODUCTS_REQUEST:
      return {
        ...state,
        loading: true,
        error: null,
      };
    case actionTypes.FETCH_PRODUCTS_SUCCESS:
      return {
        ...state,
        loading: false,
        error: null,
        products: action.payload,
      };
    case actionTypes.FETCH_PRODUCTS_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.payload,
      };
    case actionTypes.FETCH_PRODUCTS_CLEAR:
      return {
        ...state,
        products: [],
        error: null,
      };
    case actionTypes.FETCH_PRODUCT_REQUEST:
      return {
        ...state,
        product: {},
        loading: true,
        error: null,
      };
    case actionTypes.FETCH_PRODUCT_SUCCESS:
      return {
        ...state,
        loading: false,
        error: null,
        product: action.payload,
      };
    case actionTypes.FETCH_PRODUCT_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.payload,
      };
    case actionTypes.ADD_PRODUCT_REQUEST:
      return {
        ...state,
        loading: true,
        error: null,
      };
    case actionTypes.ADD_PRODUCT_SUCCESS:
      return {
        ...state,
        loading: false,
        error: null,
      };
    case actionTypes.ADD_PRODUCT_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.payload,
      };
    case actionTypes.FETCH_PRODUCT_CLEAR:
      return {
        ...state,
        product: {},
      };

    case actionTypes.UPDATE_PRODUCT_REQUEST:
      return {
        ...state,
        loading: true,
        error: null,
      };
    case actionTypes.UPDATE_PRODUCT_SUCCESS:
      return {
        ...state,
        loading: false,
        product: action.payload,
        error: null,
      };
    case actionTypes.UPDATE_PRODUCT_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.payload,
      };
    case actionTypes.DELETE_PRODUCT_REQUEST:
      return {
        ...state,
        loading: true,
        error: null,
        products: state.products.filter((item) => item.id !== action.payload),
      };
    case actionTypes.DELETE_PRODUCT_SUCCESS:
      return {
        ...state,
        loading: false,
        error: null,
      };
    case actionTypes.DELETE_PRODUCT_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.payload,
      };
    case actionTypes.GET_PRODUCT_FEES_REQUEST:
      return {
        ...state,
        fee_loading: true,
        error: null,
      };
    case actionTypes.GET_PRODUCT_FEES_SUCCESS:
      return {
        ...state,
        fee_loading: false,
        error: null,
        fees: action.payload,
      };
    case actionTypes.GET_PRODUCT_FEES_FAILURE:
      return {
        ...state,
        fee_loading: false,
        error: action.payload,
      };

    case actionTypes.CLEAR_PRODUCT_FEE_FETCH:
      return {
        ...state,
        error: null,
        fee: {},
      };
    case actionTypes.GET_PRODUCT_FEE_REQUEST:
      return {
        ...state,
        fee_loading: true,
        error: null,
      };
    case actionTypes.GET_PRODUCT_FEE_SUCCESS:
      return {
        ...state,
        fee_loading: false,
        error: null,
        fee: action.payload,
      };
    case actionTypes.GET_PRODUCT_FEE_FAILURE:
      return {
        ...state,
        fee_loading: false,
        error: action.payload,
      };

    case actionTypes.UPDATE_PRODUCT_FEE_REQUEST:
      return {
        ...state,
        fee_loading: true,
        error: null,
      };
    case actionTypes.UPDATE_PRODUCT_FEE_SUCCESS:
      return {
        ...state,
        fee_loading: false,
        fees: state.fees.map((item) =>
          item.id === action.payload.id ? action.payload : item
        ),
        error: null,
      };
    case actionTypes.UPDATE_PRODUCT_FEE_FAILURE:
      return {
        ...state,
        fee_loading: false,
        error: action.payload,
      };
    case actionTypes.DELETE_PRODUCT_FEE_REQUEST:
      return {
        ...state,
        fee_loading: true,
        error: null,
        fees: state.fees.filter((item) => item.id !== action.payload),
      };
    case actionTypes.DELETE_PRODUCT_FEE_SUCCESS:
      return {
        ...state,
        fee_loading: false,
        error: null,
      };
    case actionTypes.DELETE_PRODUCT_FEE_FAILURE:
      return {
        ...state,
        fee_loading: false,
        error: action.payload,
      };

    case actionTypes.ADD_PRODUCT_EDUCATION_REQUEST:
      return {
        ...state,
        loading: true,
        error: null,
      };
    case actionTypes.ADD_PRODUCT_EDUCATION_SUCCESS:
      return {
        ...state,
        loading: false,
        error: null,
        product_educations: action.payload,
      };
    case actionTypes.ADD_PRODUCT_EDUCATION_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.payload,
      };
    case actionTypes.GET_PRODUCT_EDUCATIONS_REQUEST:
      return {
        ...state,
        education_loading: true,
        error: null,
      };
    case actionTypes.GET_PRODUCT_EDUCATIONS_SUCCESS:
      return {
        ...state,
        education_loading: false,
        error: null,
        product_educations: action.payload,
      };
    case actionTypes.GET_PRODUCT_EDUCATIONS_FAILURE:
      return {
        ...state,
        education_loading: false,
        error: action.payload,
      };

    case actionTypes.CLEAR_PRODUCT_EDUCATION_FETCH:
      return {
        ...state,
        error: null,
        product_education: {},
      };
    case actionTypes.GET_PRODUCT_EDUCATION_REQUEST:
      return {
        ...state,
        education_loading: true,
        error: null,
      };
    case actionTypes.GET_PRODUCT_EDUCATION_SUCCESS:
      return {
        ...state,
        education_loading: false,
        error: null,
        product_education: action.payload,
      };
    case actionTypes.GET_PRODUCT_EDUCATION_FAILURE:
      return {
        ...state,
        education_loading: false,
        error: action.payload,
      };

    case actionTypes.UPDATE_PRODUCT_EDUCATION_REQUEST:
      return {
        ...state,
        education_loading: true,
        error: null,
      };
    case actionTypes.UPDATE_PRODUCT_EDUCATION_SUCCESS:
      return {
        ...state,
        education_loading: false,
        product_educations: state.product_educations.map((item) =>
          item.id === action.payload.id ? action.payload : item
        ),
        error: null,
      };
    case actionTypes.UPDATE_PRODUCT_EDUCATION_FAILURE:
      return {
        ...state,
        education_loading: false,
        error: action.payload,
      };
    case actionTypes.DELETE_PRODUCT_EDUCATION_REQUEST:
      return {
        ...state,
        education_loading: true,
        error: null,
        product_educations: state.product_educations.filter(
          (item) => item.id !== action.payload
        ),
      };
    case actionTypes.DELETE_PRODUCT_EDUCATION_SUCCESS:
      return {
        ...state,
        education_loading: false,
        error: null,
      };
    case actionTypes.DELETE_PRODUCT_EDUCATION_FAILURE:
      return {
        ...state,
        education_loading: false,
        error: action.payload,
      };

    case actionTypes.ADD_PRODUCT_LANGUAGE_REQUEST:
      return {
        ...state,
        language_loading: true,
        error: null,
      };
    case actionTypes.ADD_PRODUCT_LANGUAGE_SUCCESS:
      return {
        ...state,
        language_loading: false,
        error: null,
        product_languages: action.payload,
      };
    case actionTypes.ADD_PRODUCT_LANGUAGE_FAILURE:
      return {
        ...state,
        language_loading: false,
        error: action.payload,
      };
    case actionTypes.GET_PRODUCT_LANGUAGES_REQUEST:
      return {
        ...state,
        language_loading: true,
        error: null,
      };
    case actionTypes.GET_PRODUCT_LANGUAGES_SUCCESS:
      return {
        ...state,
        language_loading: false,
        error: null,
        product_languages: action.payload,
      };
    case actionTypes.GET_PRODUCT_LANGUAGES_FAILURE:
      return {
        ...state,
        language_loading: false,
        error: action.payload,
      };

    case actionTypes.CLEAR_PRODUCT_LANGUAGE_FETCH:
      return {
        ...state,
        error: null,
        product_language: {},
      };
    case actionTypes.GET_PRODUCT_LANGUAGE_REQUEST:
      return {
        ...state,
        language_loading: true,
        error: null,
      };
    case actionTypes.GET_PRODUCT_LANGUAGE_SUCCESS:
      return {
        ...state,
        language_loading: false,
        error: null,
        product_language: action.payload,
      };
    case actionTypes.GET_PRODUCT_LANGUAGE_FAILURE:
      return {
        ...state,
        language_loading: false,
        error: action.payload,
      };

    case actionTypes.UPDATE_PRODUCT_LANGUAGE_REQUEST:
      return {
        ...state,
        language_loading: true,
        error: null,
      };
    case actionTypes.UPDATE_PRODUCT_LANGUAGE_SUCCESS:
      return {
        ...state,
        language_loading: false,
        product_languages: state.product_languages.map((item) =>
          item.id === action.payload.id ? action.payload : item
        ),
        error: null,
      };
    case actionTypes.UPDATE_PRODUCT_LANGUAGE_FAILURE:
      return {
        ...state,
        language_loading: false,
        error: action.payload,
      };
    case actionTypes.DELETE_PRODUCT_LANGUAGE_REQUEST:
      return {
        ...state,
        language_loading: true,
        error: null,
        product_languages: state.product_languages.filter(
          (item) => item.id !== action.payload
        ),
      };
    case actionTypes.DELETE_PRODUCT_LANGUAGE_SUCCESS:
      return {
        ...state,
        language_loading: false,
        error: null,
      };
    case actionTypes.DELETE_PRODUCT_LANGUAGE_FAILURE:
      return {
        ...state,
        language_loading: false,
        error: action.payload,
      };
    default:
      return state;
  }
};

export { productReducer };
