import React, { useState } from 'react';
import { Typography, Row, Collapse, Empty } from 'antd';
import AppointmentCard from './libs/appointment-card';
import { TEXT } from '@moxie/constants';
import { IAppointmentsData } from '@shared-components/models';

const AppointmentContent: React.FC<IAppointmentsData> = ({
  upcoming,
  past,
  appointmentData,
  triggerRefresh,
  currentUser,
  archived,
}: IAppointmentsData) => {
  const { Text } = Typography;
  const [changeStatus, setChangeStatus] = useState<boolean>(false);

  return (
    <div className="overflow-scroll">
      {appointmentData?.length === 0 && (
        <div className="align-contents-center">
          <Empty image={Empty.PRESENTED_IMAGE_SIMPLE} />
        </div>
      )}
      {past && past.length > 0 ? (
        <>
          <Collapse ghost>
            <Collapse.Panel
              header={
                <Text className="education-background-title" strong>
                  {TEXT.PAST} ({past.length})
                </Text>
              }
              key="1"
            >
              <Row>
                <AppointmentCard
                  currentUser={currentUser}
                  data={past}
                  triggerRefresh={triggerRefresh}
                  setChangeStatus={setChangeStatus}
                  changeStatus={changeStatus}
                  archived={archived}
                />
              </Row>
            </Collapse.Panel>
          </Collapse>
        </>
      ) : (
        <></>
      )}

      {upcoming && upcoming.length > 0 && (
        <>
          <Collapse defaultActiveKey={['2']} ghost>
            <Collapse.Panel
              header={
                <Text className="education-background-title" strong>
                  {TEXT.UPCOMING} ({upcoming.length})
                </Text>
              }
              key="2"
            >
              <Row>
                <AppointmentCard
                  currentUser={currentUser}
                  setChangeStatus={setChangeStatus}
                  changeStatus={changeStatus}
                  data={upcoming}
                  name="Today"
                  triggerRefresh={triggerRefresh}
                  archived={archived}
                />
              </Row>
            </Collapse.Panel>
          </Collapse>
        </>
      )}
    </div>
  );
};

export default AppointmentContent;
