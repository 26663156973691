const alias = 'SUBJECT_DISCIPLINE';

export const GET_SUBJECT_DISCIPLINES_REQUEST = `GET_${alias}S_REQUEST`;
export const GET_SUBJECT_DISCIPLINES_SUCCESS = `GET_${alias}S_SUCCESS`;
export const GET_SUBJECT_DISCIPLINES_FAILURE = `GET_${alias}_FAILURE`;
export const GET_SUBJECT_DISCIPLINES_CLEAR = `GET_${alias}S_CLEAR`;

export const GET_SUBJECT_DISCIPLINE_REQUEST = `GET_${alias}_REQUEST`;
export const GET_SUBJECT_DISCIPLINE_SUCCESS = `GET_${alias}_SUCCESS`;
export const GET_SUBJECT_DISCIPLINE_FAILURE = `GET_${alias}_FAILURE`;

export const ADD_SUBJECT_DISCIPLINE_REQUEST = `ADD_${alias}_REQUEST`;
export const ADD_SUBJECT_DISCIPLINE_SUCCESS = `ADD_${alias}_SUCCESS`;
export const ADD_SUBJECT_DISCIPLINE_FAILURE = `ADD_${alias}_FAILURE`;

export const UPDATE_SUBJECT_DISCIPLINE_REQUEST = `UPDATE_${alias}_REQUEST`;
export const UPDATE_SUBJECT_DISCIPLINE_SUCCESS = `UPDATE_${alias}_SUCCESS`;
export const UPDATE_SUBJECT_DISCIPLINE_FAILURE = `UPDATE_${alias}_FAILURE`;

export const DELETE_SUBJECT_DISCIPLINE_REQUEST = `DELETE_${alias}_REQUEST`;
export const DELETE_SUBJECT_DISCIPLINE_SUCCESS = `DELETE_${alias}_SUCCESS`;
export const DELETE_SUBJECT_DISCIPLINE_FAILURE = `DELETE_${alias}_FAILURE`;
