import {
  AuditOutlined,
  BankOutlined,
  BarChartOutlined,
  // CalendarOutlined,
  BlockOutlined,
  ReadOutlined,
  TeamOutlined,
  UserOutlined,
} from '@ant-design/icons';
import ProLayout from '@ant-design/pro-layout';
import { authActions, themeActions } from '@lyra/core';
import {
  APPLICATION_TITLE,
  COOKIE_ACCESS,
  DEPARTMENT_TITLE,
  // LEAD_TITLE,
  INSTITUTE_TITLE,
  OFFICE_TITLE,
  ORGANIZATION_TITLE,
  PRODUCT_TITLE,
  PROJECT_PORTAL,
  REPORT_TITLE,
  // URL_ALL_LEAD,
  TEXT,
  // URL_ALL_CLIENTS,
  // URL_ALL_PROSPECTS,
  URL_APPLICATION,
  URL_APPLICATION_REPORT,
  URL_DASHBOARD,
  URL_DEPARTMENT,
  URL_INSTITUTE,
  URL_LEAD_REPORT,
  URL_MY_CONTACTS,
  URL_OFFICE,
  URL_PRODUCTS,
  URL_USER,
  USER_TITLE,
} from '@moxie/constants';
import { ApplyIMSLogoLight } from '@shared-components/elements';
import { IDashboardLayoutProps } from '@shared-components/models';
import Cookies from 'js-cookie';
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { NavLink } from 'react-router-dom';
import DashboardHeader from '../dashboard/libs/DashboardHeader';
// import { ProspectOutlinedIcon } from '../../elements/icons/libs/prospect';
// import { ClientOutlinedIcon } from '../../elements/icons/libs/client';
// import { LeadOutlinedIcon } from '../../elements/icons/libs/lead';
// eslint-disable-next-line @typescript-eslint/ban-ts-comment
//@ts-ignore
import TawkMessengerReact from '@tawk.to/tawk-messenger-react';
import { ShortApplyIMSLogoLight } from '../../elements/icons/libs/short-apply-ims-logo-light';

declare const window: any;

export const routes: any = {
  route: {
    path: URL_DASHBOARD,
    layout: false,
    routes: [
      {
        path: URL_DASHBOARD,
        exact: true,
        name: TEXT.DASHBOARD,
        icon: <BarChartOutlined />,
      },
      {
        path: URL_MY_CONTACTS,
        name: TEXT.CONTACTS,
        exact: true,
        icon: <TeamOutlined />,
      },
      {
        path: URL_APPLICATION,
        exact: true,
        name: APPLICATION_TITLE,
        icon: <BlockOutlined />,
      },
      {
        path: URL_INSTITUTE,
        exact: true,
        name: INSTITUTE_TITLE,
        icon: <BankOutlined />,
      },
      {
        path: URL_PRODUCTS,
        exact: true,
        name: PRODUCT_TITLE,
        icon: <ReadOutlined />,
      },
      {
        name: ORGANIZATION_TITLE,
        icon: <UserOutlined />,
        routes: [
          {
            path: URL_OFFICE,
            exact: true,
            name: OFFICE_TITLE,
          },
          {
            path: URL_DEPARTMENT,
            exact: true,
            name: DEPARTMENT_TITLE,
          },
          {
            path: URL_USER,
            exact: true,
            name: USER_TITLE,
          },
        ],
      },
      // {
      //   path: URL_APPOINTMENT,
      //   exact: true,
      //   name: APPOINTMENT_TITLE,
      //   icon: <CalendarOutlined />,
      // },
      // {
      //   path: URL_ALL_TASKS,
      //   exact: true,
      //   name: TASKS_TITLE,
      //   icon: <SnippetsOutlined />,
      // },
      {
        name: REPORT_TITLE,
        icon: <AuditOutlined />,
        routes: [
          {
            path: URL_LEAD_REPORT,
            exact: true,
            name: TEXT.CONTACTS,
          },
          {
            path: URL_APPLICATION_REPORT,
            exact: true,
            name: APPLICATION_TITLE,
          },
        ],
      },
    ],
  },
};

const DashboardLayout: React.FC<IDashboardLayoutProps> = (
  props: IDashboardLayoutProps
) => {
  const { children, className } = props;
  const dispatch = useDispatch();
  const collapsed = useSelector((store: any) => store.theme.navCollapsed);
  const [unreadMessageCount, setUnreadMessageCount] = useState(0);

  const handleNavbarToggle = (bool: boolean) =>
    dispatch(themeActions.toggleNavbar(bool));

  useEffect(() => {
    const unReadChat = localStorage.getItem('unReadChat') ?? 0;
    setUnreadMessageCount(Number(unReadChat));
    window.Tawk_API.onLoad = function() {
      window.Tawk_API.hideWidget();
    };
    if (!Cookies.get(COOKIE_ACCESS)) {
      return;
    }
    dispatch(authActions.isAuthenticated());
  }, [dispatch]);

  return (
    <ProLayout
      {...routes}
      {...props}
      siderWidth={200}
      className={`dashboard-layout ${className}`}
      navTheme="dark"
      headerHeight={60}
      collapsed={collapsed}
      collapsedButtonRender={false}
      logo={() =>
        collapsed ? (
          <div className="sidebar-short-logo-position">
            <ShortApplyIMSLogoLight />
          </div>
        ) : (
          <ApplyIMSLogoLight />
        )
      }
      title={PROJECT_PORTAL.AGENT_TITLE}
      menuItemRender={(item, dom) => (
        <NavLink
          className={`${item.className && item.className} `}
          to={`${item.path}`}
        >
          {dom}
        </NavLink>
      )}
      fixSiderbar={true}
      fixedHeader={true}
      onCollapse={handleNavbarToggle}
      headerContentRender={() => (
        <DashboardHeader
          unReadMessageCount={unreadMessageCount}
          setCollapsed={handleNavbarToggle}
          collapsed={collapsed}
        />
      )}
    >
      {children}
      <div>
        <TawkMessengerReact
          onUnreadCountChanged={(e: number) => {
            localStorage.setItem('unReadChat', `${e}`);
            setUnreadMessageCount(e);
          }}
          propertyId={process.env.NX_TAWK_PROPERTY_ID}
          widgetId={process.env.NX_WIDGET_ID}
        />
      </div>
    </ProLayout>
  );
};

export { DashboardLayout };
