import { updateTask } from "@crm/libs/services.api/lib/tasks.api";
import { UpdateTaskPayload } from "@model/crm/note.model";
import { successNotificationHandler } from "@moxie/shared";
import { useMutation } from "@tanstack/react-query";

const useUpdateTask = () => {
  return useMutation({
    mutationFn: ({ taskId, ...payload }: UpdateTaskPayload & { taskId: string }) => updateTask(taskId, payload),
    onSuccess() {
      successNotificationHandler('Task marked as completed.')
    }

  })
}

export default useUpdateTask;
