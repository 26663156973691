import React from 'react';
import { Button, Space } from 'antd';
import { useForm } from 'antd/lib/form/Form';
import { DrawerElem } from '@shared-components/elements';
import { IAddEditProps, IEducation } from '@shared-components/models';
import {
  ADD_EDUCATION_TITLE,
  CANCEL_BTN,
  EDIT_EDUCATION_TITLE,
  SAVE_BTN,
  UPDATE_BTN,
} from '@moxie/constants';
import { EducationBackgroundForm } from '../forms/education-background';
import { educationBackgroundActions, useAppSelector } from '@lyra/core';
import { useDispatch } from 'react-redux';

const EducationBackgroundAddEdit: any = ({
  isOpen,
  onClose,
  id,
  initialData,
  disabled,
  setDisabled,
}: IAddEditProps) => {
  const socket: any = useAppSelector((state) => state.socket.wss);
  const user: any = useAppSelector((state) => state.auth.user);
  const lead: any = useAppSelector((state) => state.lead.lead);

  const educationId = initialData?.id;
  const [form] = useForm();
  const dispatch = useDispatch();

  const handleSubmitSuccess = () => {
    form.resetFields();
    onClose();
  };

  const handleSubmitButtonClick = () => {
    form.submit();
  };

  const handleSubmit = async (data: IEducation) => {
    data.parent_id = id;
    if (!initialData?.id) {
      const res = dispatch(
        educationBackgroundActions.addEducationBackgroundRequest(data)
      );
      const dataLeadActivities = {
        user_id: user.id,
        type_id: null,
        data: { data: res.payload },
        parent_id: id,
        company_id: user?.company?.id,
      };
      socket.emit('qualification-notification', {
        user_id: user.id,
        parent_id: lead.id,
        receiver_id: lead?.assignee_id,
        receivers_many: lead?.followers_id,
        profile_tab: 'education-background',
        type: 'education',
        update_type: 'added',
      });
      socket.emit(
        'added-new-education-background-to-server',
        dataLeadActivities
      );
      handleSubmitSuccess();
    } else {
      const res = dispatch(
        educationBackgroundActions.updateEducationBackgroundRequest({
          id: initialData.id,
          ...data,
        })
      );
      const dataLeadActivities = {
        user_id: user.id,
        type_id: initialData.id,
        data: { data: res.payload },
        parent_id: id,
        company_id: user?.company?.id,
      };
      socket.emit('qualification-notification', {
        user_id: user.id,
        parent_id: lead.id,
        receiver_id: lead?.assignee_id,
        receivers_many: lead?.followers_id,
        profile_tab: 'education-background',
        type: 'education',
        update_type: 'updated',
      });
      socket.emit('updated-education-background-to-server', dataLeadActivities);
      handleSubmitSuccess();
    }
  };
  return (
    <DrawerElem
      title={educationId ? EDIT_EDUCATION_TITLE : ADD_EDUCATION_TITLE}
      width={720}
      onClose={onClose}
      visible={isOpen}
      footer={
        <div className="padding-bottom-2 text-align-right">
          <Space direction="horizontal">
            <Button onClick={onClose} className="lead-margin-right">
              {CANCEL_BTN}
            </Button>
            <Button
              onClick={handleSubmitButtonClick}
              type="primary"
              disabled={disabled}
            >
              {initialData?.id ? UPDATE_BTN : SAVE_BTN}
            </Button>
          </Space>
        </div>
      }
    >
      <EducationBackgroundForm
        form={form}
        isOpen={isOpen}
        onSubmit={handleSubmit}
        initialData={{ ...initialData }}
        setDisabled={setDisabled}
      />
    </DrawerElem>
  );
};

export { EducationBackgroundAddEdit };
