import React, { useState } from 'react';
import { PageHeaderTitle } from '@shared-components/elements';
import { LABEL, TEXT } from '@moxie/constants';
import { getApplicationReport } from '@lyra/services.api';
import { ApplicationReportTable } from './application-report-table';
import { Excel } from 'antd-table-saveas-excel';
import { ExportReportColumns } from './export-report-columns';
import { useAppSelector } from '@lyra/core';

const ApplicationReportListComponent: React.FC = () => {
  const [filter, setFilter] = useState({});
  const [reportParams, setReportParams] = useState<string>('');
  const userTimeZone =
    useAppSelector((store) => store?.auth?.user?.time_zone) || '';

  const getData = async (params: string) => {
    setReportParams(params);
    return getApplicationReport(params);
  };

  const setFilterData = (key: string, value: string) => {
    setFilter({ [key]: value });
  };

  const handleExport = async () => {
    const response = await getApplicationReport(
      `${reportParams}&exportReport=true`
    );
    const excel = new Excel();
    excel
      .addSheet('data')
      .addColumns(ExportReportColumns(userTimeZone))
      .addDataSource(response?.data?.data)
      .saveAs('application_report.xlsx');
  };

  return (
    <div className="full-height">
      <PageHeaderTitle title={TEXT.APPLICATION_REPORT} />
      <ApplicationReportTable
        getData={getData}
        tableName={LABEL.APPLICATION}
        filter={filter}
        setFilter={setFilterData}
        handleExport={handleExport}
      />
    </div>
  );
};

export { ApplicationReportListComponent };
