import {
  AUTH_MESSAGE,
  COUNTRIES,
  DEFAULT_COUNTRY_CODE,
  EMAIL_LABEL,
  LABEL,
  PHONE_LABEL,
  PHONE_NUMBER_INVALID,
  regex,
  TITLE,
} from '@moxie/constants';
import { FormItem, PhoneInput } from '@shared-components/elements';
import { Col, Divider, FormItemProps, Input, Radio, Row } from 'antd';
import Title from 'antd/lib/typography/Title';
import React, { useEffect, useState } from 'react';
import { checkContactEmail, getOneLeadsForm } from '@lyra/services.api';
import { errorHandler, useQuery } from '@moxie/utils';
export interface ILeadContactDetails {
  contact_preference: boolean;
  email: boolean;
  id?: number;
  phone_number: boolean;
}
export interface ILeadContactDetailsProps {
  contactDetailsData: ILeadContactDetails;
}
const LeadFormContactDetails: React.FC<ILeadContactDetailsProps> = ({
  contactDetailsData,
}: ILeadContactDetailsProps) => {
  const id = useQuery().get('i');
  const [leadFormData, setLeadFormData] = useState<any>();
  const [countryCode, setCountryCode] = useState(DEFAULT_COUNTRY_CODE);
  const [contactPreference, setcontactPreference] = React.useState(1);
  const [emailValidationStatus, setEmailValidationStatus] = useState<
    FormItemProps['validateStatus']
  >('');
  const onChange = (e: any) => {
    setcontactPreference(e.target.value);
  };
  const handleCountryChange = (input: string) => {
    COUNTRIES.find((item) => {
      if (item.dial_code === input) {
        setCountryCode(item.dial_code);
      }
    });
  };
  const validateEmail = async (value: { email: string }) => {
    try {
      const body = {
        email: value?.email,
        company_id: leadFormData?.company_id,
      };
      const res = await checkContactEmail(body);
      return !!res?.data?.success;
    } catch (err) {
      return false;
    }
  };
  const handleEmailChange = async (rule: any, value: any): Promise<any> => {
    value = value?.toLowerCase();
    if (value.length === 0) {
      setEmailValidationStatus('error');
      return Promise.resolve(true);
    } else {
      const trimValue = value.replace(/ /g, '');
      setEmailValidationStatus('validating');

      if (String(trimValue).match(new RegExp(regex.EMAIL))) {
        const result = await validateEmail({ email: trimValue });

        if (result) {
          setEmailValidationStatus('success');
          return Promise.resolve(true);
        } else {
          setEmailValidationStatus('error');
          throw new Error(AUTH_MESSAGE.REGISTER_EMAIL_TAKEN);
        }
      } else {
        setEmailValidationStatus('error');
      }
    }
  };
  const getLeadsForms = async () => {
    try {
      const response = await getOneLeadsForm(id ? id : '');
      setLeadFormData(response.data.data);
    } catch (err) {
      errorHandler(err);
    }
  };
  useEffect(() => {
    getLeadsForms();
  }, [id]);
  return (
    <div className="created-leadForm-section ">
      <Title level={4}>{TITLE.CONTACT_DETAILS}</Title>
      <Row gutter={24}>
        {contactDetailsData?.phone_number && (
          <Col md={8} xs={24}>
            <FormItem
              name="phone"
              label={PHONE_LABEL}
              rules={[
                {
                  required: true,
                  pattern: new RegExp(regex.ONLY_NUMBER),
                  message: PHONE_NUMBER_INVALID,
                },
              ]}
            >
              <PhoneInput
                type="string"
                dialCode={countryCode}
                onChangeDialCode={handleCountryChange}
              />
            </FormItem>
          </Col>
        )}
        {contactDetailsData?.email && (
          <Col md={8} xs={24}>
            <FormItem
              name="email"
              className="lyra-label"
              label={EMAIL_LABEL}
              validateFirst={true}
              validateStatus={emailValidationStatus}
              rules={[
                {
                  required: true,
                  pattern: new RegExp(regex.EMAIL),
                  message: AUTH_MESSAGE.INVALID_EMAIL,
                },
                {
                  validator: handleEmailChange,
                },
              ]}
            >
              <Input />
            </FormItem>
          </Col>
        )}
        {contactDetailsData?.contact_preference && (
          <Col md={8} xs={24}>
            <FormItem
              name="contact_preference"
              className="lyra-label"
              label={LABEL.CONTACT_PREFERENCES}
            >
              <Radio.Group onChange={onChange} value={contactPreference}>
                <Radio value={'email'}>{EMAIL_LABEL}</Radio>
                <Radio value={'phone'}>{PHONE_LABEL}</Radio>
              </Radio.Group>
            </FormItem>
          </Col>
        )}
      </Row>
      <Divider plain />
    </div>
  );
};
export { LeadFormContactDetails };
