import { API_URL } from '@moxie/constants';
import crmAxios from './crm-api-axios';

const getSubjectDisciplines = async () => {
  return crmAxios.get(`${API_URL.CRM_API_SUBJECT_DISCIPLINES}`);
};

const getSubjectAreas = async (id: string) => {
  return crmAxios.get(`${API_URL.CRM_API_SUBJECT_DISCIPLINES}/subject-area/${id}`);
};

const getEducationDegreeLevel = async () => {
  return crmAxios.get("v1/education-backgrounds/degree-levels");
};

export { getSubjectDisciplines, getSubjectAreas, getEducationDegreeLevel };
