import React from 'react';
import { Col, Menu, Row, Skeleton, Spin, Typography } from 'antd';
import { TEXT } from '@moxie/constants';
import { PageHeaderTitle } from '@shared-components/elements';
import { RootState } from 'apps/crm/src/core/store';
import { useAppSelector } from '@crm/core';
import { WorkflowStages } from './workflow-stages';

const WorkflowDetailComponent: React.FC = () => {
  const { workflowDetail, loading, stages, stagesLoading } = useAppSelector(
    (state: RootState) => ({
      workflowDetail: state.workflows.singleData,
      loading: state.workflows.loading,
      stages: state.workflows.stages,
      stagesLoading: state.workflows.stages_loading,
    })
  );

  return (
    <div className="full-height">
      <PageHeaderTitle title={TEXT.WORKFLOW_DETAIL} />
      <Spin spinning={loading}>
        <Row className="page-header">
          <Col className="page-header__menu initial_capital">
            <Menu selectedKeys={['1']} mode="horizontal">
              <Menu.Item key="1">
                {loading ? (
                  <Skeleton paragraph={{ rows: 0 }} active />
                ) : (
                  <Typography.Text strong>
                    {`${workflowDetail?.workflowType?.name} (${workflowDetail?.country})`}
                  </Typography.Text>
                )}
              </Menu.Item>
            </Menu>
          </Col>
        </Row>
        {workflowDetail ? (
          <WorkflowStages
            stagesLoading={stagesLoading}
            stageList={!loading && stages}
            workflowId={workflowDetail?.id}
          />
        ) : null}
      </Spin>
    </div>
  );
};
export { WorkflowDetailComponent };
