import { AxiosResponse } from 'axios';
import { put, takeLatest } from 'redux-saga/effects';
import * as institutionBranches from './institution-branch.constant';
import * as institutionBranchesActions from './institution-branch.action';
import { apiGetInstituteDetail } from '@crm/services.api';
import { crmErrorHandler } from 'libs/shared/src/crm-modules/shared';

export function* branchesByInstitution({ payload }: any) {
  try {
    if (payload) {
      const response: AxiosResponse = yield apiGetInstituteDetail(payload);
      yield put(
        institutionBranchesActions.getBranchesByInstitutionSuccess(
          response.data?.branches
        )
      );
    }
  } catch (e) {
    crmErrorHandler(e);
    yield put(institutionBranchesActions.getBranchesByInstitutionFailure(e));
  }
}

export default function* branchesByInstitutionSaga(): unknown {
  yield takeLatest(
    institutionBranches.GET_BRANCHES_BY_INSTITUTION_REQUEST,
    branchesByInstitution
  );
}
