import React from 'react';
import { Tabs } from 'antd';
import {
  IdcardOutlined,
  BookOutlined,
  FileDoneOutlined,
} from '@ant-design/icons';
import { IProfileSectionProps } from '@shared-components/models';
import { TITLE } from '@moxie/constants';
import { GeneralDetails } from './general-details';
import { QualificationTab } from './education-background';
import { ProfessionalExperience } from './professional-experience';

const { TabPane } = Tabs;

const ProfileSection: React.FC<IProfileSectionProps> = (props) => {
  const { lead } = props;
  return (
    <>
      <div className="chrome-tab card-container">
        <Tabs>
          <TabPane
            tab={
              <span>
                <IdcardOutlined />
                {TITLE.GENERAL_DETAILS}
              </span>
            }
            key="other-details"
          >
            <GeneralDetails lead={lead} />
          </TabPane>
          <TabPane
            tab={
              <span>
                <BookOutlined />
                {TITLE.QUALIFICATIONS}
              </span>
            }
            key="education-background"
          >
            <QualificationTab id={lead?.id} />
          </TabPane>
          <TabPane
            tab={
              <span>
                <FileDoneOutlined />
                {TITLE.PROFESSIONAL_EXPERIENCE}
              </span>
            }
            key="professional-experiences"
          >
            <ProfessionalExperience id={lead?.id} />
          </TabPane>
        </Tabs>
      </div>
    </>
  );
};

export { ProfileSection };
