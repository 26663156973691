import { INote, IAction, INotePayload } from '@shared-components/models';
import * as actionTypes from './lead-notes.constant';

export const addNoteRequest = (): IAction => ({
  type: actionTypes.ADD_NOTE_REQUEST,
});
export const addNoteSuccess = (payload: INote): IAction<INote> => ({
  type: actionTypes.ADD_NOTE_SUCCESS,
  payload,
});
export const addNoteFailure = (payload: INotePayload): IAction => ({
  type: actionTypes.ADD_NOTE_FAILURE,
  payload,
});

export const getNotesRequest = (id: string): IAction<string> => ({
  type: actionTypes.GET_NOTES_REQUEST,
  payload: id,
});
export const getNotesSuccess = (payload: INote[]): IAction<INote[]> => ({
  type: actionTypes.GET_NOTES_SUCCESS,
  payload,
});
export const getNotesFailure = (payload: INotePayload): IAction => ({
  type: actionTypes.GET_NOTES_FAILURE,
  payload,
});
