import {
  IAction,
  IProductComparison,
  IProductComparisonModal,
} from '@shared-components/models';
import {
  ADD_PRODUCT_COMPARISON_FAILURE,
  ADD_PRODUCT_COMPARISON_REQUEST,
  ADD_PRODUCT_COMPARISON_SUCCESS,
} from './product-comparison.constant';

export interface IProductComparisonState {
  productComparisons: IProductComparisonModal[];
  productComparison: IProductComparison;
  error: boolean | null;
  loading: boolean;
}
export const initialProductComparisonState: IProductComparisonState = {
  productComparisons: [],
  productComparison: { product_id: {} },
  error: null,
  loading: false,
};
export const PRODUCT_COMPARISON_FEATURE_KEY = 'product-compare';

export const productComparisonReducer = (
  state = initialProductComparisonState,
  action: IAction
) => {
  switch (action.type) {
    case ADD_PRODUCT_COMPARISON_REQUEST:
      return {
        ...state,
        loading: true,
        error: null,
      };
    case ADD_PRODUCT_COMPARISON_SUCCESS:
      return {
        ...state,
        loading: false,
        error: null,
        productComparisons: action?.payload || [],
      };
    case ADD_PRODUCT_COMPARISON_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.payload,
      };
    default:
      return state;
  }
};
