import { useAppSelector } from '@admin/core';
import { preventMinus } from '@moxie/shared';
import { ITenantManageForm } from '@shared-components/models';
import { DatePicker, Form, InputNumber } from 'antd';
import moment from 'moment';
import React, { useEffect } from 'react';

const TenantManageForm: React.FC<ITenantManageForm> = ({
  form,
  handleSubmit,
  setDisabled,
}: ITenantManageForm) => {
  const initialData = useAppSelector((store) => store.tenant.singleData);

  const handleValues = () => {
    setDisabled(false);
  };

  useEffect(() => {
    if (initialData) {
      form.setFieldsValue({
        sharing_ratio: Number(initialData?.sharing_ratio),
        expiration_date: moment(initialData?.expiration_date),
      });
    }
  }, [initialData]);
  return (
    <Form
      onFinish={handleSubmit}
      form={form}
      layout={'horizontal'}
      labelCol={{ span: 5 }}
      wrapperCol={{ span: 15 }}
      onValuesChange={handleValues}
    >
      <Form.Item
        label="Sharing Ratio"
        name={'sharing_ratio'}
        rules={[{ required: true }]}
      >
        <InputNumber
          min={0}
          max={100}
          step={'0.1'}
          precision={2}
          onKeyPress={preventMinus}
        />
      </Form.Item>
      <Form.Item
        label="Expiration Date"
        name={'expiration_date'}
        rules={[{ required: true }]}
      >
        <DatePicker format={'MMMM DD,YYYY'} />
      </Form.Item>
    </Form>
  );
};

export default TenantManageForm;
