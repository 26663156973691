import { IAction, ILanguageTestScore } from '@shared-components/models';
import * as actionTypes from './language-test-score.constant';

export const LANGUAGE_TEST_SCORE_FEATURE_KEY = 'language_test_score';

export interface ILanguageTestScoreRootState {
  languageTestScoreList: ILanguageTestScore[];
  loading: boolean;
  error: boolean | null;
}

export const initialLanguageTestScore: ILanguageTestScoreRootState = {
  languageTestScoreList: [],
  loading: false,
  error: null,
};

export const languageTestScoreReducer = (
  state = initialLanguageTestScore,
  action: IAction<any>
) => {
  switch (action.type) {
    case actionTypes.GET_LANGUAGE_TEST_SCORES_REQUEST:
      return {
        ...state,
        loading: true,
      };
    case actionTypes.GET_LANGUAGE_TEST_SCORES_SUCCESS:
      return {
        ...state,
        loading: false,
        languageTestScoreList: [...action.payload],
      };
    case actionTypes.GET_LANGUAGE_TEST_SCORES_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.payload,
      };
    case actionTypes.GET_LANGUAGE_TEST_SCORES_CLEAR:
      return {
        ...state,
        languageTestScoreList: [],
        loading: false,
        error: null,
      };
    case actionTypes.ADD_LANGUAGE_TEST_SCORE_REQUEST:
      return {
        ...state,
        loading: true,
      };
    case actionTypes.ADD_LANGUAGE_TEST_SCORE_SUCCESS:
      return {
        ...state,
        loading: false,
        languageTestScoreList: [action.payload, ...state.languageTestScoreList],
      };
    case actionTypes.ADD_LANGUAGE_TEST_SCORE_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.payload,
      };
    case actionTypes.UPDATE_LANGUAGE_TEST_SCORE_REQUEST:
      return {
        ...state,
        loading: true,
      };
    case actionTypes.UPDATE_LANGUAGE_TEST_SCORE_SUCCESS:
      return {
        ...state,
        loading: false,
        languageTestScoreList: state.languageTestScoreList.map((item) =>
          item.id === action.payload.id ? action.payload : item
        ),
      };
    case actionTypes.UPDATE_LANGUAGE_TEST_SCORE_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.payload,
      };

    case actionTypes.DELETE_LANGUAGE_TEST_SCORE_REQUEST:
      return {
        ...state,
        loading: true,
      };
    case actionTypes.DELETE_LANGUAGE_TEST_SCORE_SUCCESS:
      return {
        ...state,
        loading: false,
        languageTestScoreList: state.languageTestScoreList.filter(
          (item) => item.id !== action.payload
        ),
      };
    case actionTypes.DELETE_LANGUAGE_TEST_SCORE_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.payload,
      };
    default:
      return state;
  }
};

export default languageTestScoreReducer;
