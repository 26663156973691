const alias = 'WORKFLOW';
const workflowStage = 'WORKFLOW_STAGE';
const wfStageChecklist = 'WF_STAGE_CHECKLIST';
const checklist = 'CHECKLIST';
const wfForm = 'WF_FORM';

export const FETCH_WORKFLOWS_REQUEST = `FETCH_${alias}S_REQUEST`;
export const FETCH_WORKFLOWS_SUCCESS = `FETCH_${alias}S_SUCCESS`;
export const FETCH_WORKFLOWS_FAILURE = `FETCH_${alias}S_FAILURE`;

export const FETCH_WORKFLOW_REQUEST = `FETCH_${alias}_REQUEST`;
export const FETCH_WORKFLOW_SUCCESS = `FETCH_${alias}_SUCCESS`;
export const FETCH_WORKFLOW_FAILURE = `FETCH_${alias}_FAILURE`;

export const ADD_WORKFLOW_REQUEST = `ADD_${alias}_REQUEST`;
export const ADD_WORKFLOW_SUCCESS = `ADD_${alias}_SUCCESS`;
export const ADD_WORKFLOW_FAILURE = `ADD_${alias}_FAILURE`;

export const UPDATE_WORKFLOW_REQUEST = `UPDATE_${alias}_REQUEST`;
export const UPDATE_WORKFLOW_SUCCESS = `UPDATE_${alias}_SUCCESS`;
export const UPDATE_WORKFLOW_FAILURE = `UPDATE_${alias}_FAILURE`;

export const DELETE_WORKFLOW_REQUEST = `DELETE_${alias}_REQUEST`;
export const DELETE_WORKFLOW_SUCCESS = `DELETE_${alias}_SUCCESS`;
export const DELETE_WORKFLOW_FAILURE = `DELETE_${alias}_FAILURE`;

export const CLEAR_WORKFLOW_FETCH = `CLEAR_${alias}_FETCH`;

export const FETCH_WORKFLOW_STAGES_REQUEST = `FETCH_${workflowStage}S_REQUEST`;
export const FETCH_WORKFLOW_STAGES_SUCCESS = `FETCH_${workflowStage}S_SUCCESS`;
export const FETCH_WORKFLOW_STAGES_FAILURE = `FETCH_${workflowStage}S_FAILURE`;

export const FETCH_WORKFLOW_STAGE_CHECKLISTS_REQUEST = `FETCH_${wfStageChecklist}S_REQUEST`;
export const FETCH_WORKFLOW_STAGE_CHECKLISTS_SUCCESS = `FETCH_${wfStageChecklist}S_SUCCESS`;
export const FETCH_WORKFLOW_STAGE_CHECKLISTS_FAILURE = `FETCH_${wfStageChecklist}S_FAILURE`;
export const CLEAR_WORKFLOW_STAGE_CHECKLISTS = `CLEAR_${wfStageChecklist}S`;

export const FETCH_CHECKLISTS_REQUEST = `FETCH_${checklist}S_REQUEST`;
export const FETCH_CHECKLISTS_SUCCESS = `FETCH_${checklist}S_SUCCESS`;
export const FETCH_CHECKLISTS_FAILURE = `FETCH_${checklist}S_FAILURE`;

export const DELETE_WF_STAGE_CHECKLIST_REQUEST = `DELETE_${wfStageChecklist}_REQUEST`;
export const DELETE_WF_STAGE_CHECKLIST_SUCCESS = `DELETE_${wfStageChecklist}_SUCCESS`;
export const DELETE_WF_STAGE_CHECKLIST_FAILURE = `DELETE_${wfStageChecklist}_FAILURE`;

export const UPDATE_WF_FORM_REQUEST = `UPDATE_${wfForm}S_REQUEST`;
export const UPDATE_WF_FORM_SUCCESS = `UPDATE_${wfForm}S_SUCCESS`;
export const UPDATE_WF_FORM_FAILURE = `UPDATE_${wfForm}S_FAILURE`;

export const FETCH_WF_FORM_REQUEST = `FETCH_WORKFLOW_FORM_${wfForm}S_REQUEST`;
export const FETCH_WF_FORM_SUCCESS = `FETCH_WORKFLOW_FORM_${wfForm}S_SUCCESS`;
export const FETCH_WF_FORM_FAILURE = `FETCH_WORKFLOW_FORM_${wfForm}S_FAILURE`;
export const CLEAR_WF_FORM = `CLEAR_WORKFLOW_FORM_${wfForm}S`;
