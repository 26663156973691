import axios from './axios';
import { IResponse, IOfficeBranch } from '@shared-components/models';
import { API_URL } from '@moxie/constants';
import { ObjectToParams } from '@moxie/utils';

const getOfficeBranches = async (params = ''): Promise<IResponse> => {
  return axios.get(`${API_URL.OFFICE_BRANCH}/${params}`);
};

const getBranchesDashboard = async (params: string): Promise<IResponse> => {
  const data = { s: params };
  const formatData = ObjectToParams(data);
  return axios.get(`${API_URL.OFFICE_BRANCH}?${formatData}`);
};

const postOfficeBranch = async (body: IOfficeBranch): Promise<IResponse> => {
  return axios.post(API_URL.OFFICE_BRANCH, body);
};

const getOneOfficeBranch = async (id: string): Promise<IResponse> => {
  return axios.get(`${API_URL.OFFICE_BRANCH}/${id}`);
};

const updateOfficeBranch = async (body: IOfficeBranch): Promise<IResponse> => {
  return axios.put(API_URL.OFFICE_BRANCH, body);
};

const deleteOfficeBranch = async (id: string): Promise<IResponse> => {
  return axios.delete(`${API_URL.OFFICE_BRANCH}/${id}`);
};

const getBranchUsers = async (id: string, str: string): Promise<IResponse> => {
  return axios.get(`${API_URL.OFFICE_BRANCH}/users/${id}/${str}`);
};

const checkBranchName = async (
  name?: string,
  id?: string
): Promise<IResponse> => {
  const params = { id, name };
  return axios.get(`${API_URL.OFFICE_BRANCH}/check/name`, { params });
};

const getMainBranch = async (id: string): Promise<IResponse> => {
  return axios.get(`${API_URL.OFFICE_BRANCH}/main/branch/${id}`);
};

const updateMainBranch = async (id: string): Promise<IResponse> => {
  return axios.put(`${API_URL.OFFICE_BRANCH}/main/branch/${id}`);
};

export {
  getOfficeBranches,
  postOfficeBranch,
  getOneOfficeBranch,
  updateOfficeBranch,
  deleteOfficeBranch,
  getBranchUsers,
  checkBranchName,
  getBranchesDashboard,
  getMainBranch,
  updateMainBranch,
};
