import React, { useState } from 'react';
import { Row, Col, Dropdown, Button, Menu, Space } from 'antd';
import { Table } from '@shared-components/elements';
import { ColumnsType } from 'antd/lib/table';
import { getAllRolesPermissions } from '@admin/services.api';
import {
  IBranchTitleMenu,
  ITableProps,
  ITableTitleCard,
} from '@shared-components/models';
import { Link } from 'react-router-dom';
import Text from 'antd/lib/typography/Text';
import {
  MoreOutlined,
  EyeOutlined,
  EditOutlined,
  DeleteOutlined,
} from '@ant-design/icons';
import { DELETE_BTN, EDIT_BTN, URL_OFFICE, VIEW_BTN } from '@moxie/constants';

const branchTitleMenu = ({
  data,
  deleteData,
  showDrawer,
  showView,
}: IBranchTitleMenu) => {
  return (
    <Menu>
      <>
        {showView && (
          <Menu.Item key={1}>
            <Space direction="horizontal">
              <Link to={`${URL_OFFICE}/detail/${data.id}/departments`}>
                <EyeOutlined /> {VIEW_BTN}
              </Link>
            </Space>
          </Menu.Item>
        )}
      </>
      <Menu.Item key={2} onClick={showDrawer}>
        <Space direction="horizontal">
          <EditOutlined />
          {EDIT_BTN}
        </Space>
      </Menu.Item>
      <Menu.Item key={3} danger onClick={deleteData}>
        <Space direction="horizontal">
          <DeleteOutlined />
          {DELETE_BTN}
        </Space>
      </Menu.Item>
    </Menu>
  );
};

export const TableBranchNameCard: React.FC<ITableTitleCard> = ({
  data,
  deleteData,
  showDrawer,
  showView = true,
}: ITableTitleCard) => {
  const handleDelete = () => {
    deleteData(data.id);
  };

  const handleDrawer = () => {
    showDrawer(data.id);
  };

  const menuActions = {
    data: data,
    deleteData: handleDelete,
    showDrawer: handleDrawer,
    showView: showView,
  };
  return (
    <Row>
      <Col span={18} className="align-items-center">
        {showView ? (
          <Link to={`${URL_OFFICE}/detail/${data.id}/departments`}>
            <Text className="text-primary-color">{data.name}</Text>
          </Link>
        ) : (
          <Text className="text-primary-color">{data.name}</Text>
        )}
      </Col>
      <Col span={3}>
        <Dropdown overlay={branchTitleMenu(menuActions)}>
          <Button
            type="text"
            className="table-cell-action-btn"
            icon={<MoreOutlined />}
          />
        </Dropdown>
      </Col>
    </Row>
  );
};

export const RoleTable: React.FC<ITableProps> = (props: ITableProps) => {
  const { showDrawer, refreshTable, handleDelete } = props;
  const [filter] = useState({});

  const getData = async (params: string) => {
    return getAllRolesPermissions(params);
  };

  const columns: ColumnsType<any> = [
    {
      title: 'Role Name',
      key: 'name',
      width: 200,
      render(data: any) {
        return (
          <TableBranchNameCard
            data={data}
            showDrawer={showDrawer}
            deleteData={handleDelete}
            showView={false}
          />
        );
      },
    },
    {
      title: 'Description',
      dataIndex: 'description',
      key: 'description',
    },
  ];
  return (
    <Row>
      <Col span={24} className="padding-top-2">
        <Table
          filterOptions={false}
          tableName={'Roles'}
          columns={columns}
          action={getData}
          className="roles-table"
          refresh={refreshTable}
          filter={filter}
          hasPagination={false}
        />
      </Col>
    </Row>
  );
};
