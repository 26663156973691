/* eslint-disable react-hooks/exhaustive-deps */
import React from 'react';
import { Button, Space } from 'antd';
import { useForm } from 'antd/lib/form/Form';
import { DrawerElem } from '@shared-components/elements';
import {
  IAddEditProps,
  IProfessionalExperience,
} from '@shared-components/models';
import {
  ADD_PROFESSIONAL_EXPERIENCE_TITLE,
  CANCEL_BTN,
  EDIT_PROFESSIONAL_EXPERIENCE_TITLE,
  SAVE_BTN,
  STATUS,
  DRAWER_WIDTH,
  UPDATE_BTN,
} from '@moxie/constants';
import { professionalExperienceActions, useAppSelector } from '@lyra/core';
import { useDispatch } from 'react-redux';
import { RootState } from 'apps/lyra/src/core/store';
import { ProfessionalExperienceForm } from '../forms/professional-experience';

const ProfessionalExperienceAddEdit = ({
  isOpen,
  onClose,
  id,
  initialData,
  disabled,
  setDisabled,
}: IAddEditProps) => {
  const { status, socket, lead, user } = useAppSelector((store: RootState) => ({
    status: store.professional_experience.status,
    socket: store.socket.wss,
    lead: store.lead.lead,
    user: store.auth.user,
  }));

  const pEid = initialData?.id;
  const [form] = useForm();
  const dispatch = useDispatch();

  const handleSubmitSuccess = () => {
    form.resetFields();
    onClose();
  };

  const handleSubmitButtonClick = () => {
    form.submit();
  };

  const handleSubmit = (data: IProfessionalExperience) => {
    data.parent_id = id;
    if (!initialData?.id) {
      dispatch(
        professionalExperienceActions.addProfessionalExperienceRequest(
          data,
          lead,
          socket,
          user
        )
      );
    } else {
      dispatch(
        professionalExperienceActions.editProfessionalExperienceRequest(
          {
            id: initialData.id,
            ...data,
          },
          lead,
          socket,
          user
        )
      );
    }
    handleSubmitSuccess();
  };

  return (
    <DrawerElem
      title={
        pEid
          ? EDIT_PROFESSIONAL_EXPERIENCE_TITLE
          : ADD_PROFESSIONAL_EXPERIENCE_TITLE
      }
      width={DRAWER_WIDTH}
      onClose={onClose}
      visible={isOpen}
      footer={
        <div className="padding-bottom-2 text-align-right">
          <Space direction="horizontal">
            <Button onClick={onClose} className="lead-margin-right">
              {CANCEL_BTN}
            </Button>
            <Button
              onClick={handleSubmitButtonClick}
              type="primary"
              loading={status !== STATUS.LOADING ? false : true}
              disabled={disabled}
            >
              {pEid ? UPDATE_BTN : SAVE_BTN}
            </Button>
          </Space>
        </div>
      }
    >
      <ProfessionalExperienceForm
        form={form}
        onSubmit={handleSubmit}
        initialData={{ ...initialData }}
        setDisabled={setDisabled}
      />
    </DrawerElem>
  );
};

export { ProfessionalExperienceAddEdit };
