import React, { useEffect, useState } from 'react';
import { Col, Input, InputNumber, Row, Select, Skeleton, Spin } from 'antd';
import Form from 'antd/lib/form';
import { AUTH_MESSAGE, GUTTER, LABEL } from '@moxie/constants';
import { SearchableSelect } from 'libs/shared/src/shared';
import { getEducationLevels } from '@admin/services.api';
import { IAcademicRequirementDrawer } from '@shared-components/models';
import { useAppSelector } from '@admin/core';
const { Option } = Select;

const AcademicRequirementForm: React.FC<IAcademicRequirementDrawer> = ({
  onSubmit,
  form,
  setDisabled,
}: IAcademicRequirementDrawer) => {
  const productDataLoading = useAppSelector(
    (state) => state.product.education_loading
  );
  const [degreeLevels, setDegreeLevels] = useState([]);
  const [degreeLevelLoading, setDegreeLevelLoading] = useState<Boolean>(false);

  const handleFormSubmit = (data: any) => {
    onSubmit(data);
  };

  const handleFieldsChange = (fields: any) => {
    if (fields.length === 1) setDisabled(false);
  };

  const fetchEducationLevels = async () => {
    setDegreeLevelLoading(true);
    const response = await getEducationLevels();
    setDegreeLevels(response.data.data);
    setDegreeLevelLoading(false);
  };

  useEffect(() => {
    fetchEducationLevels();
  }, []);

  return productDataLoading ? (
    <Skeleton active />
  ) : (
    <Form
      layout="vertical"
      form={form}
      onFinish={handleFormSubmit}
      onFieldsChange={handleFieldsChange}
    >
      <Row gutter={GUTTER}>
        <Col span={24}>
          <Form.Item
            name="degree_level"
            label={LABEL.DEGREE_LEVEL}
            rules={[{ required: true }]}
          >
            <SearchableSelect optionFilter="search_prop">
              {degreeLevelLoading ? (
                <Option value={''} disabled>
                  <Spin spinning className="full-width" size="small" />
                </Option>
              ) : (
                degreeLevels.map((item: any) => (
                  <Option
                    value={item.id}
                    key={item.name}
                    search_prop={item.name}
                  >
                    {item.name}
                  </Option>
                ))
              )}
            </SearchableSelect>
          </Form.Item>
        </Col>
      </Row>
      <Row gutter={GUTTER}>
        <Col span={24}>
          <Row gutter={GUTTER}>
            <Col span={12}>
              <Form.Item
                name="percentage"
                label={LABEL.PERCENTAGE}
                rules={[{ min: 0, max: 100, type: 'number' }]}
              >
                <InputNumber type="number" className="full-width" />
              </Form.Item>
            </Col>
          </Row>
          <Row gutter={GUTTER}>
            <Col span={12}>
              <Form.Item
                name="gpa"
                rules={[{ min: 0, max: 10, type: 'number' }]}
                label={LABEL.GPA}
              >
                <InputNumber className="full-width" type="number" />
              </Form.Item>
            </Col>
            <Col span={12}>
              <Form.Item
                name="total_gpa"
                rules={[
                  {
                    min: 0,
                    max: 10,
                    type: 'number',
                  },
                  ({ getFieldValue }) => ({
                    validator(_, value) {
                      if (value < getFieldValue('gpa')) {
                        return Promise.reject(AUTH_MESSAGE.GREATER_GPA);
                      }
                      return Promise.resolve();
                    },
                  }),
                ]}
                label={LABEL.TOTAL_GPA}
              >
                <InputNumber className="full-width" type="number" />
              </Form.Item>
            </Col>
            <Col span={24}>
              <Form.Item name="remark" label={LABEL.REMARK}>
                <Input.TextArea rows={4} className="full-width" />
              </Form.Item>
            </Col>
          </Row>
        </Col>
      </Row>
    </Form>
  );
};

export default AcademicRequirementForm;
