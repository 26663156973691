import React, { useEffect } from 'react';
import { Link, useParams } from 'react-router-dom';
import { OverViewHeader } from '@shared-components/elements';
import { APPLICATION_ACTORS, TEXT, URL_APPLICATION } from '@moxie/constants';
import { Button } from 'antd';
import { LeftOutlined } from '@ant-design/icons';
import { ApplicationDetail, ApplicationProvider } from '@moxie/shared';
import { useDispatch } from 'react-redux';
import { applicationActions } from '@admin/core';

const ApplicationDetailComponent = () => {
  const { applicationId }: { applicationId: string } = useParams();
  const dispatch = useDispatch();
  useEffect(() => {
    dispatch(applicationActions.getOneRequest(applicationId));
  }, [applicationId]);

  return (
    <div>
      <OverViewHeader
        title={TEXT.APPLICATIONS}
        actions={
          <Link to={URL_APPLICATION}>
            <Button icon={<LeftOutlined />}>{TEXT.APPLICATION_LIST}</Button>
          </Link>
        }
      />
      <div className="bg-white">
        <ApplicationProvider>
          <ApplicationDetail type={APPLICATION_ACTORS.ADMISSION} />
        </ApplicationProvider>
      </div>
    </div>
  );
};

export { ApplicationDetailComponent };
