import React, { useEffect } from 'react';
import { Col, InputNumber, Row, Typography } from 'antd';
import { IForm, ITestTypeForForm } from '@shared-components/models';
import Form from 'antd/lib/form';
import { GUTTER, LABEL } from '@moxie/constants';

const { Text } = Typography;

const LanguageTestScoreForm: React.FC<IForm<ITestTypeForForm>> = ({
  form,
  name,
  onSubmit,
  initialData,
  setDisabled,
}: IForm<ITestTypeForForm>) => {
  useEffect(() => {
    if (initialData && Object.keys(initialData).length > 0) {
      form.setFieldsValue(initialData);
    } else form.resetFields();
  }, []);

  const handleSubmit = async (data: ITestTypeForForm) => {
    onSubmit && onSubmit(data);
  };

  const onFieldsChange = (allFields: string | any[]) => {
    if (allFields.length > 0) {
      setDisabled(false);
    }
  };

  return (
    <Form
      layout="vertical"
      onFinish={handleSubmit}
      form={form}
      onFieldsChange={onFieldsChange}
    >
      <Row gutter={GUTTER} align="top">
        <Col span={3} className="align-vertically-centered">
          <Text className="align-score-title" strong>
            {name?.toUpperCase()}
          </Text>
        </Col>
        <Col span={name === 'PTE' ? 4 : 5}>
          <Form.Item
            name="reading"
            label={LABEL.READING}
            rules={[
              {
                type: 'number',
                min: 1,
                max: name === 'IELTS' ? 9 : name === 'PTE' ? 90 : 30,
              },
              {
                required: true,
              },
            ]}
          >
            <InputNumber className="full-width" />
          </Form.Item>
        </Col>
        <Col span={name === 'PTE' ? 4 : 5}>
          <Form.Item
            name="listening"
            label={LABEL.LISTENING}
            rules={[
              {
                type: 'number',
                min: 1,
                max: name === 'IELTS' ? 9 : name === 'PTE' ? 90 : 30,
              },
              {
                required: true,
              },
            ]}
          >
            <InputNumber className="full-width" />
          </Form.Item>
        </Col>
        <Col span={name === 'PTE' ? 4 : 5}>
          <Form.Item
            name="writing"
            label={LABEL.WRITING}
            rules={[
              {
                type: 'number',
                min: 1,
                max: name === 'IELTS' ? 9 : name === 'PTE' ? 90 : 30,
              },
              {
                required: true,
              },
            ]}
          >
            <InputNumber className="full-width" />
          </Form.Item>
        </Col>

        <Col span={name === 'PTE' ? 4 : 5}>
          <Form.Item
            name="speaking"
            label={LABEL.SPEAKING}
            rules={[
              {
                type: 'number',
                min: 1,
                max: name === 'IELTS' ? 9 : name === 'PTE' ? 90 : 30,
              },
              {
                required: true,
              },
            ]}
          >
            <InputNumber className="full-width" />
          </Form.Item>
        </Col>
        {name === 'PTE' && (
          <Col span={4}>
            <Form.Item
              name="overall_score"
              label={LABEL.OVERALL_SCORE}
              rules={[
                {
                  type: 'number',
                  min: 1,
                  max: 90,
                },
                {
                  required: true,
                },
              ]}
            >
              <InputNumber className="full-width" />
            </Form.Item>
          </Col>
        )}
      </Row>
    </Form>
  );
};

export { LanguageTestScoreForm };
