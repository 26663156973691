import React from 'react';
import { Col, Row, Typography } from 'antd';
import { COMPLETION_DETAIL, LABEL, PAGE_TITLE_SIZE } from '@moxie/constants';
import { CheckCircleFilled } from '@ant-design/icons';

const ApplicationCompletePage = () => {
  return (
    <Row
      justify="space-between"
      className="padding-left-3 padding-top-2 profile-body"
    >
      <Col span={24}>
        <Row>
          <Col className="margin-right-2">
            <Typography.Title level={PAGE_TITLE_SIZE}>
              <strong>{LABEL.COMPLETE_APPLICATION}</strong>
            </Typography.Title>
            <span className="ant-upload-text">{`${COMPLETION_DETAIL}`}</span>
          </Col>
        </Row>
        <Row className="padding-top-4" justify="center">
          <Col className="align-items-center-grid">
            <CheckCircleFilled className="stage-timeline-complete-button application-complete-icon" />
            <Col>
              <span className="ant-upload-text">{LABEL.COMPLETED}</span>
            </Col>
          </Col>
        </Row>
      </Col>
    </Row>
  );
};

export { ApplicationCompletePage };
