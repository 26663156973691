import { IAction } from '@shared-components/models';
import * as actionType from './document.constant';

export const getDocumentRequest = (id: string): IAction => ({
  type: actionType.GET_DOCUMENT_REQUEST,
  id,
});

export const getDocumentSuccess = (payload: any): IAction => ({
  type: actionType.GET_DOCUMENT_SUCCESS,
  payload,
});

export const getDocumentFailure = (error: any): IAction => ({
  type: actionType.GET_DOCUMENT_FAILURE,
  payload: error,
});

export const getDocumentTypeRequest = (id: string): IAction => ({
  type: actionType.GET_DOCUMENT_TYPE_REQUEST,
  id,
});

export const getDocumentTypeSuccess = (payload: any): IAction => ({
  type: actionType.GET_DOCUMENT_TYPE_SUCCESS,
  payload,
});

export const getDocumentTypeFailure = (error: any): IAction => ({
  type: actionType.GET_DOCUMENT_TYPE_FAILURE,
  payload: error,
});

export const clearDocumentFetch = (): IAction => ({
  type: actionType.CLEAR_DOCUMENT_FETCH,
});
