import React from 'react';
import { PageHeaderTitle } from '@shared-components/elements';
import { PAGE_HEADER_MENU_PRODUCTS, PRODUCT_TITLE } from '@moxie/constants';
import { ProductTable } from '@shared-components/modules';
import { PageHeaderMenu } from '../components/page-header-menu';

const ProductLists: React.FC = () => {
  return (
    <section className="full-height">
      <PageHeaderTitle title={PRODUCT_TITLE} />
      <PageHeaderMenu menu={PAGE_HEADER_MENU_PRODUCTS} />
      <ProductTable />
    </section>
  );
};

export { ProductLists };
