import React from 'react';
import { SERVICES_TITLE, TEXT } from '@moxie/constants';
import { ITableProps } from '@shared-components/models';
// import { PlusOutlined } from '@ant-design/icons';
import { ColumnsType } from 'antd/lib/table';
import { Col } from 'antd';
import { CrmTable } from '@shared-components/elements';
import { ServiceNameCard } from './service-name-card';
// import { serviceActions } from '@crm/core';
// import { useDispatch } from 'react-redux';
import { colSorter } from 'libs/shared/src/elements/crm-table/colSorter';
import { useCanAccess } from '@crm/libs/privilege/useCanAccess';

const useColumns = (props: ITableProps) => {
  const { handleFormVisible, deleteData, canAccess } = props;

  const cols: ColumnsType<any> = [
    {
      title: TEXT.NAME,
      key: 'name',
      width: '30%',
      sorter: () => 0,
      render(data) {
        return (
          <Col span={24}>
            <ServiceNameCard
              data={data}
              showDrawer={handleFormVisible}
              deleteData={deleteData}
              canAccess={canAccess}
            />
          </Col>
        );
      },
    },
    {
      title: TEXT.DESCRIPTION,
      key: 'description',
      width: '70%',
      render(data) {
        return (
          <Col span={24}>
            <div
              dangerouslySetInnerHTML={{
                __html: `${window.screen.width > 1366
                  ? data?.description?.length > 180
                    ? data?.description.substring(0, 180) + '...'
                    : data?.description
                  : data?.description.length > 100
                    ? data?.description.substring(0, 100) + '...'
                    : data?.description
                  }`,
              }}
            ></div>
          </Col>
        );
      },
    },
  ];

  return React.useMemo(() => {
    return cols.map((c) => colSorter(c as any))
  }, [])

}

const ServiceTableList: React.FC<ITableProps> = ({
  getData,
  handleFormVisible,
  refreshTable,
  deleteData,
}: ITableProps) => {
  const canAccess = useCanAccess();
  const ServiceTableColumns = useColumns({ handleFormVisible, deleteData, canAccess });

  return (
    <div className="bg-white padding-top-2">
      <CrmTable
        action={getData}
        tableName={SERVICES_TITLE}
        columns={ServiceTableColumns}
        refresh={refreshTable}
      //  TODO: Below codes would be needed if in future needs Import UI
      // tableButtons={
      //   <Button
      //     data-testid="service-button"
      //     type="primary"
      //     title={LABEL.IMPORT_EXISTING_SERVICE}
      //     typeof="button"
      //     onClick={() => {
      //       dispatch(serviceActions.getAdminServicesRequest());
      //       handleImportVisible && handleImportVisible();
      //     }}
      //   >
      //     <PlusOutlined /> {LABEL.IMPORT_EXISTING_SERVICE}
      //   </Button>
      // }
      />
    </div>
  );
};

export { ServiceTableList };
