import { put, takeLatest, ForkEffect } from 'redux-saga/effects';
import * as actionTypes from './notifications.constant';
import * as NotificationActions from './notifications.action';
import { AxiosResponse } from 'axios';
import {
  markNotificationRead,
  markNotificationReadAll,
} from '@admin/services.api';

function* fetchNotifications(data: any) {
  yield put(NotificationActions.getNotificationSuccess(data.payload));
}

function* updateNotifications(data: any) {
  try {
    if (!data) return;
    const response: AxiosResponse = yield markNotificationRead(data.payload);

    yield put(NotificationActions.updateNotificationSuccess(response.data));
  } catch (err) {
    yield put(NotificationActions.updateNotificationFailure(err));
  }
}

function* updateNotificationsMarkReadAll({ payload }: any) {
  try {
    const response: AxiosResponse = yield markNotificationReadAll(payload);
    yield put(
      NotificationActions.updateNotificationMarkReadSuccess(response.data)
    );
  } catch (err) {
    yield put(NotificationActions.updateNotificationMarkReadFailure(err));
  }
}

export default function* NotificationSagas(): Generator<
  ForkEffect<never>,
  void,
  unknown
> {
  yield takeLatest(actionTypes.GET_NOTIFICATION_REQUEST, fetchNotifications);
  yield takeLatest(
    actionTypes.NOTIFICATION_MARK_READ_ALL_REQUEST,
    updateNotificationsMarkReadAll
  );
  yield takeLatest(
    actionTypes.UPDATE_NOTIFICATION_REQUEST,
    updateNotifications
  );
}
