import {
  IAction,
  ICountry,
  IEducationLevel,
  IOfficeBranch,
  IService,
  IWorkflowType,
} from '@shared-components/models';
import {
  FAILED_SERVICE,
  FETCH_EDUCATION_LEVEL,
  FETCH_EDUCATION_LEVEL_REQUEST,
  FETCH_SERVICE,
  FETCH_SERVICE_COUNTRIES,
  FETCH_SERVICE_COUNTRIES_REQUEST,
  FETCH_SERVICE_INSTITUTION,
  FETCH_SERVICE_INSTITUTION_REQUEST,
  FETCH_SERVICE_REQUEST,
  FETCH_SERVICE_WORKFLOW,
  FETCH_SERVICE_WORKFLOW_REQUEST,
  IDLE_SERVICE,
  LOADING_SERVICE,
  RESET_COUNTRY,
  RESET_EDUCATION_LEVEL,
  RESET_SERVICE,
  RESET_WORKFLOW,
  SET_ERROR,
  SUCCEEDED_SERVICE,
} from './services.constant';

export const fetchService = (payload: IService[]): IAction<IService[]> => ({
  type: FETCH_SERVICE,
  payload,
});

export const fetchServiceRequest = () => ({
  type: FETCH_SERVICE_REQUEST,
});

export const fetchServiceWorkflow = (
  payload: IWorkflowType[]
): IAction<IWorkflowType[]> => ({
  type: FETCH_SERVICE_WORKFLOW,
  payload,
});

export const fetchServiceWorkflowRequest = (payload: any): IAction<string> => ({
  type: FETCH_SERVICE_WORKFLOW_REQUEST,
  payload,
});

export const fetchServiceCountries = (
  payload: ICountry[]
): IAction<ICountry[]> => ({
  type: FETCH_SERVICE_COUNTRIES,
  payload,
});

export const fetchEducationLevelRequest = (): IAction => ({
  type: FETCH_EDUCATION_LEVEL_REQUEST,
});

export const fetchEducationLevel = (
  payload: IEducationLevel
): IAction<IEducationLevel> => ({
  type: FETCH_EDUCATION_LEVEL,
  payload,
});

export const resetEducationLevel = (): IAction => ({
  type: RESET_EDUCATION_LEVEL,
});

export const fetchServiceCountryRequest = (
  payload: string | undefined
): IAction<string> => ({
  type: FETCH_SERVICE_COUNTRIES_REQUEST,
  payload,
});

export const fetchServiceInstitution = (
  payload: IOfficeBranch[]
): IAction<IOfficeBranch[]> => ({
  type: FETCH_SERVICE_INSTITUTION,
  payload,
});

export const fetchServiceInstitutionRequest = (payload: {
  serviceId: string;
  country: string;
}): IAction<{ serviceId: string; country: string }> => ({
  type: FETCH_SERVICE_INSTITUTION_REQUEST,
  payload,
});

export const setLoading = () => ({
  type: LOADING_SERVICE,
});

export const setIdle = () => ({
  type: IDLE_SERVICE,
});

export const setSucceed = () => ({
  type: SUCCEEDED_SERVICE,
});

export const setFailed = () => ({
  type: FAILED_SERVICE,
});

export const setError = () => ({
  type: SET_ERROR,
});

export const resetServices = (): IAction => ({
  type: RESET_SERVICE,
});

export const resetServiceWorkflows = (): IAction => ({
  type: RESET_WORKFLOW,
});

export const resetServiceWorkflowCountries = (): IAction => ({
  type: RESET_COUNTRY,
});
