/* eslint-disable no-unused-vars */
import React, { useEffect, useState } from 'react';
import {
  Card,
  Avatar,
  Col,
  Row,
  Space,
  Tag,
  Tooltip,
  Tabs,
  Typography,
} from 'antd';
import {
  PAGE_HEADER_MENU_INSTITUTION_DETAIL,
  TAB_POSITION,
  TEXT,
  URL_INSTITUTION_DETAIL_LINK,
} from '@moxie/constants';
import { IProfileSection, IWorkflow } from '@shared-components/models';
import { InstitutionBranchTable, InstitutionProductTable } from '@moxie/shared';
import { InstitutionBranchTagGroup } from '@shared-components/elements';
import { instituteServices } from '@lyra/services.api';
import { useParams, useHistory } from 'react-router-dom';
import InstitutionApplicationsTable from './institution-applications';

export const InstitutionProfile: React.FC<IProfileSection> = (
  props: IProfileSection
) => {
  const { data } = props;
  const { TabPane } = Tabs;
  const history = useHistory();
  const {
    name,
    email,
    business_registration_number: registration,
    website,
    currency,
  } = data;
  const params = useParams<{ slug: string; id: string }>();
  const [activeKey, setActiveKey] = useState(params.slug);
  const getApplicationsData = async (str: string) => {
    return instituteServices.apiGetInstitutionAgentApplications(data.id, str);
  };
  const setDefaultActiveKey = () => {
    const selected = PAGE_HEADER_MENU_INSTITUTION_DETAIL.find(
      (item) => item.id === params.slug
    );
    selected?.id && setActiveKey(selected?.id);
  };

  const handleChange = (key: string) => {
    const selected = PAGE_HEADER_MENU_INSTITUTION_DETAIL.find(
      (item) => item.id === key
    );
    if (selected?.url) {
      history.push(
        `${URL_INSTITUTION_DETAIL_LINK}${params?.id}/${selected?.url}`
      );
    }
  };

  useEffect(() => {
    setDefaultActiveKey();
  }, [params.slug]);

  const pageHeaderMenu = (slug: string) => {
    switch (slug) {
      case 'branches':
        return data?.id && <InstitutionBranchTable id={data.id} />;

      case 'products':
        return data?.id && <InstitutionProductTable id={data.id} />;

      case 'applications':
        return (
          data?.id && (
            <InstitutionApplicationsTable getData={getApplicationsData} />
          )
        );

      default:
        return;
    }
  };

  return (
    <>
      <section className="profile">
        <Card>
          <Row gutter={24}>
            <Col lg={8} className="custom_divider">
              <div className="profile__info">
                <div className="profile__info__item profile__info__institute padding-top-2 profile-margin-top">
                  <Avatar size="large">{name?.substring(0, 2)}</Avatar>
                  <div className="profile__info__user__detail ">
                    <h3 className="initial_capital">{name}</h3>
                  </div>
                </div>
                <div className="profile__info__item">
                  <Space size={8} className="align-items-center">
                    <h4 className="institute-profile">{TEXT.BRANCH}:</h4>
                    <span className="profile__link">
                      <InstitutionBranchTagGroup
                        data={data.branches}
                        numberOfBranch={3}
                      />
                    </span>
                  </Space>
                </div>
                <div className="profile__info__item">
                  {data?.officialId && (
                    <h3 className="profile__title">
                      {TEXT.CRICOS_PROVIDER_CODE}:
                      <span className="text-link-color padding-left-1">
                        {data?.identification_system_code
                          ? data.identification_system_code.toUpperCase()
                          : '-'}
                      </span>
                    </h3>
                  )}
                </div>
              </div>
            </Col>
            <Col lg={8} className="custom_divider">
              {email && (
                <div>
                  <Space size={8} className=" align-items-center">
                    <h4 className="institute-profile">{TEXT.EMAIL}:</h4>
                    <Tooltip title={email}>
                      <span className="profile__link">{email}</span>
                    </Tooltip>
                  </Space>
                </div>
              )}
              {data.website && (
                <div className="profile__info__item">
                  <Space size={8} className=" align-items-center">
                    <h4 className="institute-profile">{TEXT.WEBSITE}:</h4>
                    <span className="profile__link">
                      <a
                        href={`https://${data.website}`}
                        target="_blank"
                        rel="noopener noreferrer"
                      >
                        {data.website}
                      </a>
                    </span>
                  </Space>
                </div>
              )}
              <div className="profile__info__item">
                <Space size={8} className="align-items-center">
                  <h4 className="institute-profile">{TEXT.CURRENCY}:</h4>
                  <span>{currency}</span>
                </Space>
              </div>
            </Col>
            <Col lg={8}>
              <div className="profile__info__item">
                <Space size={8} className="align-items-center">
                  <h4 className="institute-profile">{TEXT.SERVICE}:</h4>
                  <span className="profile__link initial_capital">
                    {data.service?.name}
                  </span>
                </Space>
              </div>

              <div className="profile__info__item">
                <Space size={8} className="align-items-center">
                  <h4 className="institute-profile">{TEXT.WORKFLOW}:</h4>
                  <span className="profile__link initial_capital">
                    {data?.workflows?.map((item: IWorkflow) => (
                      <Tag key={item?.id}>{item?.workflowType?.name}</Tag>
                    ))}
                  </span>
                </Space>
              </div>
            </Col>
          </Row>
        </Card>
      </section>
      <section className="lead_content">
        <div className="pro">
          <Tabs
            activeKey={activeKey}
            tabPosition={TAB_POSITION}
            onTabClick={(key) => handleChange(key)}
          >
            {[...Array.from(PAGE_HEADER_MENU_INSTITUTION_DETAIL)].map(
              (item) => {
                return (
                  <TabPane
                    className="custom_tab"
                    tab={<Typography.Text strong>{item.name}</Typography.Text>}
                    key={item.id}
                  >
                    {pageHeaderMenu(item.id)}
                  </TabPane>
                );
              }
            )}
          </Tabs>
        </div>
      </section>
    </>
  );
};

export default InstitutionProfile;
