/* eslint-disable no-unused-vars */
import { Card, Typography } from 'antd';
import React, { ReactElement } from 'react';
import { EditDeleteButton } from '@shared-components/elements';
import { capitalizeFirstLetter } from '../../shared';
import Can, { LeadsEntity, PermissionAction } from '@lyra/privilege';

interface IProfessionalExperienceProps {
  title: string;
  companyName?: string;
  employmentType: string;
  onEdit: () => void;
  onDelete: () => void;
  lead?: any;
  children: React.ReactNode | ReactElement | React.ReactChildren;
}
const ProfessionalExperienceCard: React.FC<IProfessionalExperienceProps> = ({
  title,
  companyName,
  employmentType,
  onEdit,
  onDelete,
  children,
  lead,
}: IProfessionalExperienceProps) => {
  const handleEdit = () => {
    onEdit();
  };
  const handleDelete = () => {
    onDelete();
  };

  return (
    <Card
      className="education-card"
      title={
        <div className="professional-experience">
          <Typography.Title className="text-primary-color" level={4}>
            {title}
          </Typography.Title>
          <Typography.Text>{companyName}</Typography.Text>
          <Typography.Text type="secondary">
            -
            {capitalizeFirstLetter(
              employmentType === 'full_time'
                ? 'Full Time'
                : employmentType === 'part_time'
                ? 'part Time'
                : employmentType === 'self_employed'
                ? 'Self Employed'
                : employmentType
            )}
          </Typography.Text>
        </div>
      }
      extra={
        <>
          {
            // Can(
            //   PermissionAction.Update,
            //   new LeadsEntity({
            //     assignee_id: lead?.assignee_id,
            //     followers: lead?.followers?.map((item: any) => item.id),
            //   })
            // ) &&
            !lead?.archived && (
              <EditDeleteButton onEdit={handleEdit} onDelete={handleDelete} />
            )
          }
        </>
      }
    >
      {children}
    </Card>
  );
};
export { ProfessionalExperienceCard };
