/* eslint-disable no-unused-vars */
import React, { useState, useEffect } from 'react';
import { Form, Typography, Input, Select, Row } from 'antd';
import { EditOutlined, CheckOutlined, CloseOutlined } from '@ant-design/icons';
import { BOOLEAN_VALUE, LABEL_OPTION } from '@moxie/constants';
import { useParams } from 'react-router-dom';
import { otherDetailValueActions } from '@lyra/core';
import { useDispatch } from 'react-redux';
import { useForm } from 'antd/lib/form/Form';
import Can, { LeadsEntity, PermissionAction } from '@lyra/privilege';
interface ILabelValue {
  label: any;
  labelId: string;
  labelValue: any;
  index: number;
  categoryId: string;
}

const OtherDetailValueForm: React.FC<ILabelValue | any> = ({
  labelId,
  labelValue,
  label,
  index,
  categoryId,
  lead,
}: ILabelValue | any) => {
  const { Option } = Select;
  const [isEdit, setIsEdit] = useState(false);
  const dispatch = useDispatch();
  const [form] = useForm();
  const { id } = useParams<{ id: string }>();

  const handleClick = () => {
    setIsEdit(!isEdit);
  };

  useEffect(() => {
    const labelValues = labelValue?.filter((item: any) => {
      if (item.other_detail_field_id === label.id) {
        return item?.value;
      }
    });
    form.setFieldsValue({ labelValue: labelValues[0]?.value });
  }, [labelValue]);

  const handleSubmit = () => {
    const userInput = form.getFieldValue('labelValue');
    if (userInput === undefined) {
      return;
    }
    form.submit();
  };

  const handleFormFinish = async (data: { labelValue: any }) => {
    const body = {
      category_id: categoryId,
      parent_id: id,
      other_detail_field_id: labelId && labelId,
      value: data.labelValue,
    };
    dispatch(otherDetailValueActions.addLeadOtherDetailValueRequest(body));
    form.resetFields();
    setIsEdit(!isEdit);
  };
  const inputTypes = (type: string | undefined, options?: any) => {
    switch (type) {
      case LABEL_OPTION[0]:
        return <Input />;

      case LABEL_OPTION[1]:
        return <Input type="number" />;

      case LABEL_OPTION[2]:
        return (
          <Select className="select-width-other-details">
            {options &&
              options.map((item: { id: string; name: string }) => (
                <Option key={item?.id} value={item?.name}>
                  {item?.name}
                </Option>
              ))}
          </Select>
        );
      case LABEL_OPTION[3]:
        return (
          <Select className="select-width-other-details">
            {BOOLEAN_VALUE.map((value: string) => (
              <Option key={value} value={value}>
                {value}
              </Option>
            ))}
          </Select>
        );
      case LABEL_OPTION[4]:
        return <Input type="date" />;
      default:
        return;
    }
  };

  return (
    <>
      {isEdit ? (
        <Form
          layout="inline"
          key={index}
          form={form}
          onFinish={handleFormFinish}
        >
          <Form.Item
            name={'labelValue'}
            className="workflow-other-details-input"
          >
            {label?.options?.length !== 0
              ? inputTypes(label.data_type, label.options)
              : inputTypes(label.data_type)}
          </Form.Item>
          <Form.Item>
            <CheckOutlined onClick={handleSubmit} />
          </Form.Item>
          <Form.Item>
            <CloseOutlined onClick={handleClick} />
          </Form.Item>
        </Form>
      ) : (
        <Row>
          {labelValue?.map((item: any) => {
            return (
              item.other_detail_field_id === label?.id &&
              item.value &&
              item?.value.trim().length > 0 && (
                <Typography.Text className="padding-right-2">
                  {item?.value}
                </Typography.Text>
              )
            );
          })}
          {/* {Can(
            PermissionAction.Update,
            new LeadsEntity({
              assignee_id: lead?.assignee_id,
              followers: lead?.followers?.map((item: any) => item.id),
            })
          ) && ( */}
          <div key={index} onClick={handleClick}>
            <EditOutlined />
          </div>
          {/* )} */}
        </Row>
      )}
    </>
  );
};

export default OtherDetailValueForm;
