import React, { useEffect } from 'react';
import { useForm } from 'antd/lib/form/Form';
import { Card, Col, Form, Row } from 'antd';
import {
  CATEGORY_ITEMS,
  GUTTER,
  LABEL,
  NOT_AVAILABLE_MESSAGE,
} from '@moxie/constants';
import { Input, SearchableSelect } from '@moxie/shared';

const FinancialDetailsTab: React.FC<any> = (props) => {
  const { financialDetails } = props;
  const [form] = useForm();

  useEffect(() => {
    if (financialDetails?.length > 0) {
      form.setFieldsValue({ financialDetails: financialDetails });
    } else {
      form.resetFields();
    }
  }, [financialDetails, form]);

  return (
    <Card title={CATEGORY_ITEMS[4].name}>
      <Form
        initialValues={{
          financialDetails: [{}],
        }}
        form={form}
        labelCol={{ lg: 7, xl: 10, span: 8 }}
        wrapperCol={{ span: 14, offset: 1 }}
      >
        <Form.List name="financialDetails">
          {(fields) => {
            return (
              <>
                {fields.map(({ key, name }) => {
                  return (
                    <Card key={key} className="margin-bottom-1">
                      <Row gutter={GUTTER}>
                        <Col span={24} xl={12} xxl={8}>
                          <Form.Item
                            className="custom_label_style initial_capital"
                            name={[name, 'source_name']}
                            label={LABEL.SOURCE_NAME}
                            rules={[{ required: true }]}
                          >
                            {financialDetails &&
                            financialDetails[name]?.source_name
                              ? financialDetails[name]?.source_name
                              : NOT_AVAILABLE_MESSAGE}
                          </Form.Item>
                        </Col>
                        <Col span={24} xl={12} xxl={8}>
                          <Form.Item
                            className="custom_label_style initial_capital"
                            name={[name, 'relationship']}
                            label={LABEL.RELATIONSHIP}
                            rules={[{ required: true }]}
                          >
                            {financialDetails &&
                            financialDetails[name]?.relationship
                              ? financialDetails[name]?.relationship
                              : NOT_AVAILABLE_MESSAGE}
                          </Form.Item>
                        </Col>
                        <Col span={24} xl={12} xxl={8}>
                          <Form.Item
                            className="custom_label_style initial_capital"
                            name={[name, 'bank_name']}
                            label={LABEL.BANK_NAME}
                            rules={[{ required: true }]}
                          >
                            {financialDetails &&
                            financialDetails[name]?.bank_name
                              ? financialDetails[name]?.bank_name
                              : NOT_AVAILABLE_MESSAGE}
                          </Form.Item>
                        </Col>
                        <Col span={24} xl={12} xxl={8}>
                          <Form.Item
                            className="custom_label_style initial_capital"
                            name={[name, 'account_type']}
                            label={LABEL.ACCOUNT_TYPE}
                            rules={[{ required: true }]}
                          >
                            {financialDetails &&
                            financialDetails[name]?.account_type
                              ? financialDetails[name]?.account_type
                              : NOT_AVAILABLE_MESSAGE}
                          </Form.Item>
                        </Col>
                        <Col span={24} xl={12} xxl={8}>
                          <div id="currency" className="relative">
                            <Form.Item
                              className="custom_label_style"
                              name={[name, 'currency']}
                              label={LABEL.CURRENCY}
                              rules={[{ required: true }]}
                            >
                              {financialDetails &&
                              financialDetails[name]?.currency
                                ? financialDetails[name]?.currency
                                : NOT_AVAILABLE_MESSAGE}
                            </Form.Item>
                          </div>
                        </Col>
                        <Col span={24} xl={12} xxl={8}>
                          <Form.Item
                            className="custom_label_style"
                            name={[name, 'bank_balance']}
                            label={LABEL.BANK_BALANCE}
                            rules={[{ required: true }]}
                          >
                            {financialDetails &&
                            financialDetails[name]?.bank_balance
                              ? financialDetails[name]?.bank_balance
                              : NOT_AVAILABLE_MESSAGE}
                          </Form.Item>
                        </Col>
                      </Row>
                    </Card>
                  );
                })}
              </>
            );
          }}
        </Form.List>
      </Form>
    </Card>
  );
};

export { FinancialDetailsTab };
