import { Form, Input, Row, Col, Typography } from 'antd';
import React from 'react';
import { FormItem } from '@moxie/shared';
import { BACK_TO_SIGN_IN, EMAIL_LABEL, FORM_SIZE } from '@moxie/constants';
import { MailOutlined } from '@ant-design/icons';
import { Link } from 'react-router-dom';
import { Button } from '@shared-components/elements';
import { IForgetPassword, ILoginForm } from '@shared-components/models';

const { Paragraph } = Typography;
const ForgetPassword: React.FC<ILoginForm<IForgetPassword>> = ({ onSubmit, loading }: ILoginForm<IForgetPassword>) => {
  const handleSubmit = (data: IForgetPassword) => {
    onSubmit(data);
  };
  return (
    <Form
      layout='vertical'
      size={FORM_SIZE}
      name='forget-password-form'
      data-testid='forget-password-form'
      className='auth-layout__form'
      onFinish={handleSubmit}
    >
      <div className='header'>
        <FormItem
          name='email'
          className='lyra-label'
          label={EMAIL_LABEL}
        
          rules={[{ required: true, type: 'email' }]}
        >
          <Input
            autoFocus
            data-testid='forget-email'
            prefix={<MailOutlined className='site-form-item-icon' />}
          />
        </FormItem>
      </div>
      <Row className='footer'>
        <Col span={24}>
          <Button
            type='primary'
            htmlType='submit'
            html-testid='submit-button'
            loading={loading}
            block
          >
            Send
          </Button>
        </Col>
        <Col span={24} className='padding-top-2 text-align-center'>
          <Link to='/'>
            <Paragraph type='secondary'>{BACK_TO_SIGN_IN}</Paragraph>
          </Link>
        </Col>
      </Row>
    </Form>
  );
};

export { ForgetPassword };
