import React, { useEffect, useState } from 'react';
import {
  MenuFoldOutlined,
  MenuUnfoldOutlined,
  UserOutlined,
  SettingOutlined,
  LogoutOutlined,
  BellOutlined,
} from '@ant-design/icons';
import { NavLink, useHistory, useLocation } from 'react-router-dom';
import { Menu, Avatar, Empty, Tabs, Badge, Switch } from 'antd';
import { IDashboardHeader } from '@shared-components/models';
import {
  ADMIN_COOKIE_ACCESS,
  ADMIN_COOKIE_REFRESH,
  DASHBOARD_ROUTES,
  AUTH_MESSAGE,
  TEXT,
} from '@moxie/constants';
import { useDispatch } from 'react-redux';
import Cookies from 'js-cookie';
import { RootState } from 'apps/admin/src/core/store';
import { DownOutlined } from '@ant-design/icons';
import { InstituteCard } from '@shared-components/elements';
import { Button, successNotificationHandler } from 'libs/shared/src/shared';
import { authActions, notificationActions, useAppSelector } from '@admin/core';
import moment from 'moment';
import ReactHtmlParser from 'react-html-parser';

const { SubMenu } = Menu;
const { TabPane } = Tabs;
const DashboardHeader = (props: IDashboardHeader): React.ReactNode | any => {
  const { setCollapsed, collapsed } = props;
  const currentUser: any = useAppSelector(
    (store: RootState) => store.auth.user
  );
  const socket: any = useAppSelector((state) => state.socket.wss);
  const notifications: any = useAppSelector(
    (state) => state.notifications.notifications
  );

  const [filterUnread, setFilterUnRead] = React.useState(false);

  const dispatch = useDispatch();
  const history = useHistory();
  const location = useLocation();

  const handleLogout = async () => {
    const token = Cookies.get(ADMIN_COOKIE_ACCESS);
    Cookies.remove(ADMIN_COOKIE_ACCESS);
    Cookies.remove(ADMIN_COOKIE_REFRESH);
    history.push('/');
    dispatch(authActions.logout(token));
    successNotificationHandler(AUTH_MESSAGE.LOGOUT);
  };
  useEffect(() => {
    if (currentUser) {
      const socketClientData = { admin_user_id: currentUser.id };
      socket?.emit(
        'get-initial-admin-notification-to-server',
        socketClientData
      );
    }
    return () => {
      dispatch(notificationActions.cleargetNotificationRequest());
    };
  }, [socket, currentUser, dispatch]);

  useEffect(() => {
    dispatch(authActions.getAuthUser());
  }, [dispatch]);

  const handleMarkRead = (id: string) => {
    dispatch(notificationActions.updateNotificationRequest(id));
  };
  const handleMarkReadAll = () => {
    dispatch(
      notificationActions.updateNotificationMarkReadRequest(currentUser?.id)
    );
  };

  function filterNotification(filterUnread: boolean) {
    if (filterUnread !== true) {
      return notifications?.map((item: any, index: number) => (
        <Notification
          key={item.id}
          item={item}
          index={index}
          notifications={notifications}
          handleMarkRead={handleMarkRead}
          handleMarkReadAll={handleMarkReadAll}
        />
      ));
    } else {
      return notifications
        ?.filter((item: any) => item.is_read === false)
        .map((item: any, index: number) => (
          <Notification
            key={item.id}
            item={item}
            index={index}
            notifications={notifications}
            handleMarkRead={handleMarkRead}
            handleMarkReadAll={handleMarkReadAll}
          />
        ));
    }
  }

  return (
    <nav className="dashboard-header__nav">
      <div
        onClick={() => setCollapsed(!collapsed)}
        className="dashboard-header__nav_trigger"
      >
        {collapsed ? <MenuUnfoldOutlined /> : <MenuFoldOutlined />}
      </div>
      <div className="dashboard-header__nav_menu">
        <Menu
          mode="horizontal"
          triggerSubMenuAction="click"
          selectedKeys={[location.pathname]}
        >
          <SubMenu
            popupOffset={[-20, 0]}
            key="notificationMenu"
            title={
              <>
                <Badge
                  count={
                    notifications?.filter((item: any) => item.is_read === false)
                      ?.length
                  }
                  size="small"
                >
                  <BellOutlined />
                </Badge>
              </>
            }
          >
            <div className="notification-menu  ">
              <div className="notification-menu__header">
                <h4>{TEXT.NOTIFICATIONS}</h4>
                <div className="notification-menu__read">
                  <span>{TEXT.SHOW_ONLY_READ}</span>
                  <Switch
                    checked={filterUnread}
                    size="small"
                    onChange={() => {
                      setFilterUnRead(!filterUnread);
                    }}
                  />
                </div>
              </div>
              <div className="notification-menu__content">
                <Tabs defaultActiveKey="1" size="small">
                  <TabPane tab="Direct" key="1">
                    <div className="notification-menu__lists">
                      {notifications?.length <= 0 && (
                        <Empty
                          className="padding-bottom-2"
                          description="No Notifications"
                          image={Empty.PRESENTED_IMAGE_SIMPLE}
                        />
                      )}
                      {notifications && filterNotification(filterUnread)}
                    </div>
                  </TabPane>
                  <TabPane tab="Following" key="2">
                    {notifications?.filter(
                      (item: any) => item.category === 'following'
                    )?.length <= 0 && (
                        <Empty
                          className="padding-bottom-2"
                          description="No Notifications"
                          image={Empty.PRESENTED_IMAGE_SIMPLE}
                        />
                      )}
                  </TabPane>
                </Tabs>
              </div>
            </div>
          </SubMenu>
          <SubMenu
            popupOffset={[8, 0]}
            key="profileMenu"
            className="lyra-dashboard__layout_header_dropmenu"
            title={
              <div className="drop-menu">
                <div className="drop-menu__info">
                  <Avatar size="large" className="drop-menu__avatar">
                    {currentUser?.first_name?.substring(0, 1)}
                    {currentUser?.last_name?.substring(0, 1)}
                  </Avatar>
                  <div className="drop-menu__text">
                    <h4>
                      {currentUser?.first_name} {currentUser?.last_name}
                    </h4>
                    <p>{currentUser?.email}</p>
                  </div>
                </div>
                <DownOutlined />
              </div>
            }
          >
            <Menu.Item key="institute" className="dropdown-institute-card">
              <InstituteCard
                companyName={currentUser?.company?.company_name || ''}
                branchName={currentUser?.branch?.name}
              />
            </Menu.Item>
            <Menu.Divider />
            <Menu.Item
              key={DASHBOARD_ROUTES.profile.path}
              icon={<UserOutlined />}
            >
              <NavLink exact to={DASHBOARD_ROUTES.profile.path}>
                {DASHBOARD_ROUTES.profile.name}
              </NavLink>
            </Menu.Item>
            <Menu.Item
              key={DASHBOARD_ROUTES.settings.path}
              icon={<SettingOutlined />}
            >
              <NavLink exact to={DASHBOARD_ROUTES.settings.path}>
                {DASHBOARD_ROUTES.settings.name}
              </NavLink>
            </Menu.Item>
            <Menu.Divider />
            <Menu.Item
              key="2"
              icon={<LogoutOutlined />}
              onClick={handleLogout}
              danger
            >
              {DASHBOARD_ROUTES.logout.name}
            </Menu.Item>
          </SubMenu>
        </Menu>
      </div>
    </nav>
  );
};
const Notification = (props: any) => {
  const {
    item,
    index,
    notifications,
    handleMarkRead,
    handleMarkReadAll,
  } = props;
  const [showRead, setShowRead] = useState(false);

  useEffect(() => {
    const notification = notifications?.filter(
      (item: any) => item?.is_read === false
    );

    if (notification?.length > 0) {
      setShowRead(true);
    } else {
      setShowRead(false);
    }
  }, [notifications, showRead]);

  const compareDate = (d: Date, compareDate: Date) => {
    return (
      compareDate?.getFullYear() === d?.getFullYear() &&
      compareDate?.getDate() === d?.getDate() &&
      compareDate?.getMonth() === d?.getMonth()
    );
  };

  const compareDateYesterday = (d: Date, compareDate: Date) => {
    return (
      compareDate?.getFullYear() === d?.getFullYear() &&
      compareDate?.getDate() === d?.getDate() - 1 &&
      compareDate?.getMonth() === d?.getMonth()
    );
  };
  const compareDateWeek = (d: Date, compareDate: Date) => {
    return (
      compareDate?.getFullYear() === d?.getFullYear() &&
      compareDate?.getDate() >= d?.getDate() - 7 &&
      compareDate?.getMonth() === d?.getMonth()
    );
  };

  const dividerLogic = (showRead: boolean) => {
    const createdDate = new Date(item.created_at);
    const prevNotification = notifications[index - 1];
    const prevNotificationDate =
      prevNotification && new Date(prevNotification?.created_at);
    const currentDate = new Date(Date.now());

    let dateName;
    if (compareDate(createdDate, currentDate)) {
      dateName = 'Today';
    } else if (compareDateYesterday(currentDate, createdDate)) {
      dateName = 'Yesterday';
    } else if (compareDateWeek(currentDate, createdDate)) {
      dateName = 'Less than a week';
    } else {
      dateName = 'Older Notifications';
    }
    if (compareDate(createdDate, prevNotificationDate)) {
      return null;
    } else {
      return (
        <div className="notification-menu__title">
          <h5>{dateName}</h5>
          {showRead ? (
            <Button
              type="text"
              color="primary"
              size="small"
              onClick={handleMarkReadAll}
            >
              Mark all as read
            </Button>
          ) : null}
        </div>
      );
    }
  };
  return (
    <>
      {dividerLogic(showRead)}
      <div
        className="notification-menu__item"
        key={item.id}
        onClick={() => handleMarkRead(item.id)}
      >
        <div className="notification-menu__item_avatar">
          <Avatar>
            {item?.admin_user?.first_name?.substring(0, 1)}
            {item?.admin_user?.last_name?.substring(0, 1)}
          </Avatar>
        </div>
        <div className="notification-menu__item_content">
          <div className="notification-menu__item_action">
            {ReactHtmlParser(item.message)}
          </div>
          <div className="notification-menu__item_date">
            <span>{moment(item.created_at).fromNow()}</span>
          </div>
        </div>
        <div
          className={` notification-menu__item_read ${item.is_read ? '' : 'active'
            }`}
        >
          <span></span>
        </div>
      </div>
    </>
  );
};

export default DashboardHeader;
