import { IEducation, IAction } from '@shared-components/models';
import * as actionTypes from './education-background.constant';

export const EDUCATION_BACKGROUND_FEATURE_KEY = 'education_background';

export interface IEducationState {
  educationBackgrounds: IEducation[];
  error: boolean | null;
  loading: boolean;
}

export const initialEducationState: IEducationState = {
  educationBackgrounds: [],
  error: null,
  loading: false,
};

export const educationBackgroundReducer = (
  state = initialEducationState,
  action: IAction<any>
) => {
  switch (action.type) {
    case actionTypes.GET_EDUCATION_BACKGROUNDS_REQUEST:
      return {
        ...state,
        loading: true,
      };
    case actionTypes.GET_EDUCATION_BACKGROUNDS_SUCCESS:
      return {
        ...state,
        loading: false,
        educationBackgrounds: [...action.payload],
      };
    case actionTypes.GET_EDUCATION_BACKGROUNDS_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.payload,
      };
    case actionTypes.GET_EDUCATION_BACKGROUNDS_CLEAR:
      return {
        ...state,
        educationBackgrounds: [],
        loading: false,
        error: null,
      };
    case actionTypes.ADD_EDUCATION_BACKGROUND_REQUEST:
      return {
        ...state,
        loading: true,
      };
    case actionTypes.ADD_EDUCATION_BACKGROUND_SUCCESS:
      return {
        ...state,
        loading: false,
        educationBackgrounds: [action.payload, ...state.educationBackgrounds],
      };
    case actionTypes.ADD_EDUCATION_BACKGROUND_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.payload,
      };
    case actionTypes.UPDATE_EDUCATION_BACKGROUND_REQUEST:
      return {
        ...state,
        loading: true,
      };
    case actionTypes.UPDATE_EDUCATION_BACKGROUND_SUCCESS:
      return {
        ...state,
        loading: false,
        educationBackgrounds: state.educationBackgrounds.map((item) =>
          item.id === action.payload.id ? action.payload : item
        ),
      };
    case actionTypes.UPDATE_EDUCATION_BACKGROUND_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.payload,
      };

    case actionTypes.DELETE_EDUCATION_BACKGROUND_REQUEST:
      return {
        ...state,
        loading: true,
      };
    case actionTypes.DELETE_EDUCATION_BACKGROUND_SUCCESS:
      return {
        ...state,
        loading: false,
        educationBackgrounds: state.educationBackgrounds.filter(
          (item) => item.id !== action.payload
        ),
      };
    case actionTypes.DELETE_EDUCATION_BACKGROUND_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.payload,
      };
    default:
      return state;
  }
};

export default educationBackgroundReducer;
