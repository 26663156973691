import { Spin } from 'antd';

export const LoaderComponent = ({ minHeight = '50vh' }) => {
  return (
    <div
      style={{
        minHeight: minHeight,
      }}
      className="flex-center padding-3"
    >
      <Spin />
    </div>
  );
};
