/* eslint-disable no-unused-vars */
import React, { useEffect, useState } from 'react';
import {
  Collapse,
  Form,
  Input,
  Row,
  Col,
  Divider,
  Button,
  Select,
  Upload,
  Typography,
  Spin,
  Skeleton,
} from 'antd';
import {
  CaretRightOutlined,
  DeleteOutlined,
  PlusOutlined,
} from '@ant-design/icons';
import {
  AUTH_MESSAGE,
  COUNTRIES,
  COUNTRY_CURRENCY,
  DELETE_BTN,
  GUTTER,
  LABEL,
  MAIN_BRANCH,
  PHONE_NUMBER_INVALID,
  regex,
  TEXT,
  TITLE,
} from '@moxie/constants';
import {
  checkInstitutionName,
  getInstituteTypes,
  fetchServiceCountry,
  getServices,
  getWorkflow,
  uploadLogo,
} from '@admin/services.api';
import {
  IGetPopupContainer,
  IInstitutionForm,
  IServices,
} from '@shared-components/models';
import { useAppSelector } from '@admin/core';
import { SearchableSelect } from '@shared-components/elements';
import { errorHandler } from '@moxie/utils';
import { normalizePhrase } from 'libs/shared/src/functions';

const InstitutionForm: React.FC<IInstitutionForm> = ({
  form,
  onSubmit,
  setDisabled,
  setNameValidationStatus,
  nameValidationStatus,
  submitted,
  isEdit,
}: IInstitutionForm) => {
  const { Panel } = Collapse;
  const { Option } = Select;

  const [servicesData, setServicesData] = useState<IServices[]>([]);
  const [serviceWorkflow, setServiceWorkflow] = useState<any>([]);
  const [radioChecked, setRadioChecked] = useState<boolean>(false);
  const [checkedRadioMain, setCheckedRadio] = useState<number>(0);
  const [instituteType, setInstituteType] = useState<any>([]);
  const [signedURL, setSignedURL] = useState();
  const [logoPath, setLogoPath] = useState<string>();
  const [serviceId, setServiceId] = useState<string>();
  const [workflowCountry, setWorkflowCountry] = useState<any>();
  const [countryList, setCountryList] = useState<any[]>();
  const [serviceLoading, setServiceLoading] = useState<boolean>(false);
  const [typeLoading, setTypeLoading] = useState<boolean>(false);
  const [workflowLoading, setWorkflowLoading] = useState<boolean>(false);
  const [prevName, setPrevName] = useState<string | undefined>();

  const { institutionData, instituteLoading } = useAppSelector((state) => ({
    institutionData: state.institutions.institution,
    instituteLoading: state.institutions.loading,
  }));

  const handleFile = async (info: any) => {
    const formData = new FormData();
    formData.append(info.filename, info.file);
    try {
      const response = await uploadLogo(formData);
      setSignedURL(response?.data?.data?.signedURL);
      setLogoPath(response?.data?.data?.name);
    } catch (err) {
      errorHandler(err);
    }
  };

  const onFieldsChange = (allFields: string | any[]) => {
    if (allFields.length === 1) {
      setDisabled(false);
    }
  };

  const SETTING = {
    showUploadList: false,
    accept: '.jpg, .jpeg, .png',
    maxCount: 1,
    multiple: false,
    customRequest: handleFile,
  };

  const handleFinish = (data: any) => {
    data.logo_path = logoPath && logoPath;
    data.branches = data.branches.map((item: any, index: number) => {
      item.email = item?.email?.replace(/ /g, '');
      item.phone_number = item?.phone_number?.replace(/ /g, '');
      if (index === Number(checkedRadioMain)) {
        return {
          ...item,
          is_main_branch: true,
        };
      } else {
        return {
          ...item,
          is_main_branch: false,
        };
      }
    });
    if (data?.branches[0].email) {
      data.email = data.branches.filter((item: any, index: number) => {
        item.email = item?.email?.replace(/ /g, '');
        if (item.is_main_branch) {
          return item.email;
        }
      })[0].email;
    }

    if (data?.branches[0].website) {
      data.website = data.branches.filter((item: any, index: number) => {
        if (item.is_main_branch) {
          return item.website;
        }
      })[0].website;
    }
    if (institutionData?.id) {
      onSubmit({ ...data, id: institutionData.id });
    } else {
      onSubmit(data);
    }
  };

  const handleServiceChange = async (id: string) => {
    setServiceId(id);
    const countries = await fetchServiceCountry(id);
    setCountryList(countries?.data?.data);
    form.setFieldsValue({
      workflows: undefined,
    });
  };

  const handleCountryChange = async (name: any) => {
    setWorkflowCountry(name);
    form.setFieldsValue({
      workflows: undefined,
    });
  };

  const getAllInstituteTypes = async () => {
    setTypeLoading(true);
    const instituteTypes = await getInstituteTypes();
    setInstituteType(instituteTypes.data.data);
    setTypeLoading(false);
  };

  const handleRadioChange = (e: React.SyntheticEvent | any) => {
    setCheckedRadio(e.target.id);
    setRadioChecked(e.target.checked);
  };

  const fetchServices = async () => {
    setServiceLoading(true);
    const data = await getServices();
    if (data?.data?.success) {
      setServicesData(data?.data?.data);
      setServiceLoading(false);
    } else {
      errorHandler(AUTH_MESSAGE.FETCH_SERVICE_FAIL);
    }
  };

  useEffect(() => {
    servicesData.length === 0 && fetchServices();
    if (institutionData) {
      institutionData?.branches?.forEach((item: any, index: number) => {
        if (item?.is_main_branch === true) {
          setCheckedRadio(index);
        }
      });
      setServiceId(institutionData?.service_id);
      setWorkflowCountry(institutionData?.country);
      setServiceWorkflow(() =>
        institutionData?.workflows?.map((item: any) => {
          return item;
        })
      );
      form.setFieldsValue({
        ...institutionData,
        workflows: institutionData?.workflows?.map((item: any) => item?.id),
      });
    } else {
      form.setFieldsValue({});
    }
  }, [institutionData]);

  useEffect(() => {
    fetchServices();
  }, []);

  useEffect(() => {
    getAllInstituteTypes();
    if (!instituteLoading) {
      const radios: any = document.querySelectorAll('.radio_dyno');
      radios?.forEach((radio: any) => {
        radio.checked = false;
      });
      radios[checkedRadioMain].checked = true;
    }
  }, [radioChecked, checkedRadioMain]);

  const getWorkflows = async () => {
    setWorkflowLoading(true);
    if (serviceId && workflowCountry) {
      const response = await getWorkflow(serviceId, workflowCountry);
      setServiceWorkflow(response?.data?.data);
    }
    setWorkflowLoading(false);
  };

  const handleNameChange = async (rule: any, value: any): Promise<any> => {
    value = value?.toLowerCase();

    if (!value) {
      setNameValidationStatus('error');
      return Promise.resolve(true);
    }

    if (value?.length > 50) {
      setNameValidationStatus('error');
      return Promise.resolve(true);
    }

    if (value?.length === 0) {
      setNameValidationStatus('error');
      return Promise.resolve(true);
    } else {
      setNameValidationStatus('validating');

      if (value !== '' || null || undefined) {
        const formatValue = value?.trim();
        if (isEdit && formatValue?.toLowerCase() === prevName?.trim()) {
          return;
        }
        const result = await validateName({
          name: normalizePhrase(formatValue)!,
          id: institutionData?.id,
        });

        if (result) {
          setNameValidationStatus('success');
          return Promise.resolve(true);
        } else {
          setNameValidationStatus('error');
          throw new Error(AUTH_MESSAGE.NAME_EXIST);
        }
      }
    }
  };

  const validateName = async (value: { name: string; id?: number }) => {
    try {
      const res = await checkInstitutionName(value?.name, value?.id);
      return !!res?.data?.success;
    } catch (err) {
      return false;
    }
  };

  useEffect(() => {
    getWorkflows();
  }, [serviceId, workflowCountry]);

  useEffect(() => {
    if (isEdit) {
      setPrevName(form.getFieldValue('name')?.toLowerCase());
    }
  }, [instituteLoading]);


  return isEdit && instituteLoading ? (
    <Skeleton active />
  ) : (
    <>
      <Spin spinning={submitted}>
        <Form
          initialValues={{
            branches: [{}],
          }}
          layout="vertical"
          className="institution-add-form"
          form={form}
          onFinish={handleFinish}
          scrollToFirstError={true}
          onFieldsChange={onFieldsChange}
        >
          <Collapse
            defaultActiveKey={['1']}
            bordered={false}
            ghost={true}
            expandIcon={({ isActive }) => (
              <CaretRightOutlined rotate={isActive ? 90 : 0} />
            )}
          >
            <Panel header={<span>{TEXT.PRIMARY_INFORMATION}</span>} key="1">
              <Row gutter={GUTTER}>
                <Col
                  span={7}
                  className="text-align-center institution-add-form__avatar"
                >
                  <Upload
                    {...SETTING}
                    listType="picture-card"
                    name="logo_path"
                    showUploadList={false}
                    className="institution-add-form__upload-image-height"
                  >
                    {institutionData?.logo_path || signedURL ? (
                      <img
                        className="institution-add-form__image"
                        src={signedURL || institutionData?.logo_path}
                        alt="Logo"
                      />
                    ) : (
                      <div>
                        <PlusOutlined />
                        <p className="ant-upload-hint">
                          <Typography.Text type="secondary">
                            {TITLE.UPLOAD_LOGO}
                          </Typography.Text>
                        </p>
                      </div>
                    )}
                  </Upload>
                </Col>
                <Col span={17}>
                  <Row>
                    <Col span={24}>
                      <Form.Item
                        name="name"
                        validateStatus={nameValidationStatus}
                        rules={[
                          {
                            required: true,
                            max: 50,
                          },
                          {
                            validator: handleNameChange,
                          },
                        ]}
                        label={LABEL.NAME}
                      >
                        <Input className="note-title-input" />
                      </Form.Item>
                    </Col>
                  </Row>
                  <Row gutter={GUTTER}>
                    <Col span={12}>
                      <div id="institution_service" className="relative">
                        <Form.Item
                          name="service_id"
                          rules={[{ required: true }]}
                          label={LABEL.SERVICE}
                        >
                          <Select
                            onChange={handleServiceChange}
                            getPopupContainer={(): IGetPopupContainer =>
                              document.getElementById('institution_service')
                            }
                          >
                            {serviceLoading ? (
                              <Option value={''} disabled>
                                <Spin
                                  spinning
                                  size="small"
                                  className="full-width"
                                />
                              </Option>
                            ) : (
                              servicesData?.map((item) => (
                                <Option key={item?.name} value={item?.id}>
                                  {item.name}
                                </Option>
                              ))
                            )}
                          </Select>
                        </Form.Item>
                      </div>
                    </Col>
                    <Col span={12}>
                      <div id="institution_country" className="relative">
                        <Form.Item
                          name="country"
                          rules={[{ required: true }]}
                          label={LABEL.COUNTRY}
                        >
                          <SearchableSelect
                            onChange={(name) => handleCountryChange(name)}
                            getPopupContainer={(): IGetPopupContainer =>
                              document.getElementById('institution_country')
                            }
                          >
                            {COUNTRIES.map((item) => (
                              <Option key={item.name} value={item.name}>
                                {item.name}
                              </Option>
                            ))}
                          </SearchableSelect>
                        </Form.Item>
                      </div>
                    </Col>
                  </Row>
                  <Row gutter={GUTTER}>
                    <Col span={12}>
                      <div id="institution_workflow" className="relative">
                        <Form.Item
                          name="workflows"
                          rules={[{ required: true }]}
                          label={LABEL.WORKFLOW}
                        >
                          <Select
                            showArrow={true}
                            mode="multiple"
                            getPopupContainer={(): IGetPopupContainer =>
                              document.getElementById('institution_workflow')
                            }
                          >
                            {workflowLoading ? (
                              <Option value={''} disabled>
                                <Spin
                                  spinning
                                  size="small"
                                  className="full-width"
                                />
                              </Option>
                            ) : (
                              serviceWorkflow?.map((item: any) => {
                                return (
                                  <Option
                                    key={item?.name}
                                    value={item?.id || null}
                                  >
                                    {item?.workflowType?.name}
                                  </Option>
                                );
                              })
                            )}
                          </Select>
                        </Form.Item>
                      </div>
                    </Col>
                    <Col span={12}>
                      <div id="institution_type" className="relative">
                        <Form.Item
                          name="institution_type_id"
                          rules={[{ required: true }]}
                          label={LABEL.TYPE}
                        >
                          <Select
                            getPopupContainer={(): IGetPopupContainer =>
                              document.getElementById('institution_type')
                            }
                          >
                            {typeLoading ? (
                              <Option value={''} disabled>
                                <Spin
                                  spinning
                                  size="small"
                                  className="full-width"
                                />
                              </Option>
                            ) : (
                              instituteType?.map((item: any) => (
                                <Option key={item?.name} value={item.id}>
                                  {item.name}
                                </Option>
                              ))
                            )}
                          </Select>
                        </Form.Item>
                      </div>
                    </Col>
                  </Row>
                  <Row gutter={GUTTER}>
                    <Col span={12}>
                      <div id="institution_currency" className="relative">
                        <Form.Item
                          name="currency"
                          rules={[{ required: true }]}
                          label={LABEL.CURRENCY}
                        >
                          <Select
                            getPopupContainer={(): IGetPopupContainer =>
                              document.getElementById('institution_currency')
                            }
                          >
                            {COUNTRY_CURRENCY.map((item) => (
                              <Option key={item.id} value={item.name}>
                                {item.name}
                              </Option>
                            ))}
                          </Select>
                        </Form.Item>
                      </div>
                    </Col>
                    <Col span={12}>
                      <Form.Item
                        name="identification_system_code"
                        rules={[{ max: 15 }]}
                        label={'CRICOS Provider Code'}
                      >
                        <Input className="note-title-input" />
                      </Form.Item>
                    </Col>
                  </Row>
                </Col>
              </Row>
            </Panel>
          </Collapse>
          <Divider />
          <Collapse
            defaultActiveKey={['1']}
            bordered={false}
            ghost={true}
            expandIcon={({ isActive }) => (
              <CaretRightOutlined rotate={isActive ? 90 : 0} />
            )}
          >
            <Panel header={<span>{TEXT.BRANCHES}</span>} key="1">
              <Form.List name="branches">
                {(fields, { add, remove }) => (
                  <>
                    {fields.map(({ key, name, fieldKey, ...restField }) => (
                      <div key={key}>
                        <Row gutter={24}>
                          <Col span={12}>
                            <Form.Item
                              {...restField}
                              label={LABEL.NAME}
                              name={[name, 'name']}
                              fieldKey={[fieldKey, 'name']}
                              rules={[
                                {
                                  required: true,
                                  max: 50,
                                },
                              ]}
                            >
                              <Input />
                            </Form.Item>
                          </Col>
                          <Col span={8}>
                            <Form.Item className="institution-add-form__checkbox">
                              <div>
                                <Input
                                  id={`${key}`}
                                  type={'radio'}
                                  className="radio_dyno"
                                  name={`is_main_branch_${key}`}
                                  onChange={handleRadioChange}
                                />
                                <label
                                  className="margin-left-1"
                                  htmlFor={`${key}`}
                                >
                                  {MAIN_BRANCH}
                                </label>
                              </div>
                            </Form.Item>
                          </Col>
                          {key !== 0 ? (
                            <Col span={4}>
                              <Button
                                onClick={() => remove(name)}
                                type="link"
                                danger
                                icon={<DeleteOutlined />}
                              >
                                {DELETE_BTN}
                              </Button>
                            </Col>
                          ) : null}
                        </Row>
                        <Collapse
                          defaultActiveKey={['1']}
                          bordered={false}
                          ghost={true}
                          expandIcon={({ isActive }) => (
                            <CaretRightOutlined rotate={isActive ? 90 : 0} />
                          )}
                        >
                          <Panel header={<span>{TEXT.ADDRESS}</span>} key="1">
                            <Form.Item>
                              <Input.Group>
                                <Row gutter={GUTTER}>
                                  <Col span={12}>
                                    <Form.Item
                                      {...restField}
                                      label={LABEL.CITY}
                                      name={[name, 'address', 'city']}
                                      fieldKey={[fieldKey, 'city']}
                                      rules={[
                                        {
                                          required: true,
                                        },
                                      ]}
                                    >
                                      <Input />
                                    </Form.Item>
                                  </Col>
                                  <Col span={12}>
                                    <Form.Item
                                      {...restField}
                                      label={LABEL.STATE}
                                      name={[name, 'address', 'state']}
                                      fieldKey={[fieldKey, 'state']}
                                    >
                                      <Input />
                                    </Form.Item>
                                  </Col>
                                </Row>
                                <Row gutter={GUTTER}>
                                  <Col span={12}>
                                    <Form.Item
                                      {...restField}
                                      label={LABEL.POSTAL_CODE}
                                      name={[name, 'address', 'postal_code']}
                                      fieldKey={[fieldKey, 'postal_code']}
                                    >
                                      <Input type={'number'} />
                                    </Form.Item>
                                  </Col>
                                  <Col span={12}>
                                    <Form.Item
                                      {...restField}
                                      label={LABEL.STREET}
                                      name={[name, 'address', 'street']}
                                      fieldKey={[fieldKey, 'street']}
                                    >
                                      <Input />
                                    </Form.Item>
                                  </Col>
                                </Row>
                              </Input.Group>
                            </Form.Item>
                          </Panel>
                        </Collapse>
                        <Collapse
                          defaultActiveKey={['1']}
                          bordered={false}
                          ghost={true}
                          expandIcon={({ isActive }) => (
                            <CaretRightOutlined rotate={isActive ? 90 : 0} />
                          )}
                        >
                          <Panel
                            header={<span>{TEXT.CONTACT_DETAILS}</span>}
                            key="1"
                          >
                            <Row gutter={GUTTER}>
                              <Col span={12}>
                                <Form.Item
                                  {...restField}
                                  label={LABEL.PHONE_NUMBER}
                                  name={[name, 'phone_number']}
                                  fieldKey={[fieldKey, 'phone_number']}
                                  rules={[
                                    {
                                      pattern: new RegExp(regex.ONLY_NUMBER),
                                      message: PHONE_NUMBER_INVALID,
                                    },
                                  ]}
                                >
                                  <Input />
                                </Form.Item>
                              </Col>
                              <Col span={12}>
                                <Form.Item
                                  {...restField}
                                  label={LABEL.EMAIL}
                                  name={[name, 'email']}
                                  fieldKey={[fieldKey, 'email']}
                                  rules={[
                                    {
                                      pattern: new RegExp(regex.EMAIL),
                                      message: AUTH_MESSAGE.INVALID_EMAIL,
                                    },
                                  ]}
                                >
                                  <Input />
                                </Form.Item>
                              </Col>
                            </Row>
                            <Row gutter={GUTTER}>
                              <Col span={12}>
                                <Form.Item
                                  {...restField}
                                  label={LABEL.FAX}
                                  name={[name, 'fax']}
                                  fieldKey={[fieldKey, 'fax']}
                                >
                                  <Input />
                                </Form.Item>
                              </Col>
                              <Col span={12}>
                                <Form.Item
                                  {...restField}
                                  label={LABEL.WEBSITE}
                                  name={[name, 'website']}
                                  fieldKey={[fieldKey, 'website']}
                                  rules={[
                                    {
                                      required: false,
                                      pattern: new RegExp(regex.WEBSITE),
                                      message: AUTH_MESSAGE.INVALID_WEB_URL,
                                    },
                                  ]}
                                >
                                  <Input />
                                </Form.Item>
                              </Col>
                            </Row>
                          </Panel>
                        </Collapse>
                        <Divider className="institution-add-form__divider" />
                      </div>
                    ))}
                    <Row justify="end">
                      <Form.Item>
                        <Button
                          type="link"
                          onClick={() => add()}
                          icon={<PlusOutlined />}
                        >
                          {TEXT.ADD_ANOTHER_BRANCH}
                        </Button>
                      </Form.Item>
                    </Row>
                  </>
                )}
              </Form.List>
            </Panel>
          </Collapse>
        </Form>
      </Spin>
    </>
  );
};

export default InstitutionForm;
