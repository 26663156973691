import {
  GET_INSTITUTIONS_REQUEST,
  GET_INSTITUTIONS_SUCCESS,
  GET_INSTITUTIONS_FAILURE,
  GET_INSTITUTION_REQUEST,
  GET_INSTITUTION_SUCCESS,
  GET_INSTITUTION_FAILURE,
  CLEAR_INSTITUTION_FETCH,
} from './institution.constant';
import {
  IAction,
  IInstitutionDetail,
  IInstitutionPayload,
} from '@shared-components/models';

export const getInstitutionsRequest = () => ({
  type: GET_INSTITUTIONS_REQUEST,
});

export const getInstitutionsSuccess = (
  payload: IInstitutionDetail[]
): IAction<IInstitutionDetail[]> => ({
  type: GET_INSTITUTIONS_SUCCESS,
  payload,
});

export const getInstitutionsFailure = (
  payload: IInstitutionPayload
): IAction<IInstitutionPayload> => ({
  type: GET_INSTITUTIONS_FAILURE,
  payload,
});

export const getInstitutionRequest = (id: string): IAction<string> => ({
  type: GET_INSTITUTION_REQUEST,
  payload: id,
});

export const getInstitutionSuccess = (
  payload: IInstitutionDetail
): IAction<IInstitutionDetail> => ({
  type: GET_INSTITUTION_SUCCESS,
  payload,
});

export const getInstitutionFailure = (
  payload: IInstitutionPayload
): IAction => ({
  type: GET_INSTITUTION_FAILURE,
  payload,
});

export const clearFetchInstitution = (): IAction<string> => ({
  type: CLEAR_INSTITUTION_FETCH,
});
