import * as actionTypes from './application.constant';
import {
  IAction,
  IInitialState,
  ILeadApplication,
} from '@shared-components/models';
import { STATUS } from '@moxie/constants';

export const initialState: IInitialState<ILeadApplication | any> = {
  allData: [],
  checklists: [],
  singleData: {},
  error: null,
  loading: false,
  status: STATUS.IDLE,
};

export const APPLICATION_FEATURE_KEY = 'application';

const applicationReducer = (state = initialState, action: IAction<any>) => {
  switch (action.type) {
    case actionTypes.GET_APPLICATIONS_REQUEST:
      return {
        ...state,
        loading: true,
        error: null,
      };
    case actionTypes.GET_APPLICATIONS_SUCCESS:
      return {
        ...state,
        loading: false,
        error: null,
        allData: action.payload,
      };
    case actionTypes.GET_APPLICATIONS_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.payload,
      };
    case actionTypes.GET_APPLICATION_REQUEST:
      return {
        ...state,
        loading: true,
        error: null,
      };
    case actionTypes.GET_APPLICATION_SUCCESS:
      return {
        ...state,
        loading: false,
        error: null,
        singleData: action.payload,
      };
    case actionTypes.GET_APPLICATION_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.payload,
        singleData: null,
      };
    case actionTypes.GET_APPLICATION_COMMENT_REQUEST:
      return {
        ...state,
        loading: true,
        error: null,
      };
    case actionTypes.GET_APPLICATION_COMMENT_SUCCESS:
      return {
        ...state,
        loading: false,
        error: null,
        checklists: action.payload?.comments,
      };
    case actionTypes.GET_APPLICATION_COMMENT_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.payload,
        checklists: null,
      };
    case actionTypes.CLEAR_APPLICATION_FETCH:
      return {
        ...state,
        loading: false,
        error: null,
        singleData: {},
      };
    case actionTypes.UPDATE_APPLICATION_REQUEST:
      return {
        ...state,
        loading: true,
        error: null,
      };
    case actionTypes.UPDATE_APPLICATION_SUCCESS:
      return {
        ...state,
        loading: false,
        error: null,
        singleData: action.payload,
      };
    case actionTypes.UPDATE_APPLICATION_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.payload,
      };
    case actionTypes.ADD_APPLICATION_REQUEST:
      return {
        ...state,
        loading: true,
        error: null,
      };
    case actionTypes.ADD_APPLICATION_SUCCESS:
      return {
        ...state,
        loading: false,
        error: null,
        status: STATUS.SUCCEEDED,
        allData: [action.payload, ...state.allData],
      };
    case actionTypes.ADD_APPLICATION_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.payload,
        status: STATUS.FAILED,
      };
    case actionTypes.ADD_APPLICATION_COMMENT_REQUEST:
      return {
        ...state,
        loading: true,
        error: null,
      };
    case actionTypes.ADD_APPLICATION_COMMENT_SUCCESS:
      return {
        ...state,
        loading: false,
        error: null,
        status: STATUS.SUCCEEDED,
        checklists: action.payload?.comments,
      };
    case actionTypes.ADD_APPLICATION_COMMENT_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.payload,
        status: STATUS.FAILED,
      };
    case actionTypes.DELETE_APPLICATION_REQUEST:
      return {
        ...state,
        loading: true,
        error: null,
      };
    case actionTypes.DELETE_APPLICATION_SUCCESS:
      return {
        ...state,
        loading: false,
        error: null,
      };
    case actionTypes.DELETE_APPLICATION_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.payload,
      };
    case actionTypes.IDLE_APPLICATION:
      return {
        ...state,
        status: STATUS.IDLE,
      };
    default:
      return state;
  }
};

export { applicationReducer };
