import React, { useState } from 'react';
import { Select, Space, Spin, Typography } from 'antd';
import debounce from 'lodash.debounce';
import { Option } from '@shared-components/elements';
import {
  SLUG_INSTITUTIONS,
  SLUG_LEADS,
  SLUG_PRODUCTS,
  TEXT,
  URL_ALL_CONTACTS,
  URL_CONTACT_DETAIL,
  URL_INSTITUTE,
  URL_INSTITUTION_DETAIL_LINK,
  URL_PRODUCTS,
  URL_PRODUCT_DETAIL_LINK,
} from '@moxie/constants';
import { InstitutionTableNameCard } from '../institution/libs/institution-list';
import { SearchOutlined } from '@ant-design/icons';
import { useHistory } from 'react-router';
import { DebounceSelectProps } from '@shared-components/models';
import { ContactProfileCard } from '../contacts/libs/contact-profile';

const { Text } = Typography;

function DashboardSearch<
  ValueType extends {
    key?: string;
    label: React.ReactNode;
    value: string | number;
  } = any
>({
  fetchOptions,
  debounceTimeout = 800,
  selectedCategory,
  ...props
}: DebounceSelectProps) {
  const [fetching, setFetching] = useState(false);
  const [options, setOptions] = useState<ValueType[]>([]);
  const [searchText, setSearchText] = useState<string>('');
  const history = useHistory();
  const fetchRef = React.useRef(0);

  const debounceFetcher = React.useMemo(() => {
    const loadOptions = (value: string) => {
      fetchRef.current += 1;
      const fetchId = fetchRef.current;
      setOptions([]);
      setFetching(true);
      setSearchText(value);

      fetchOptions(value).then((res: any) => {
        if (fetchId !== fetchRef.current) {
          return;
        }
        setOptions(res?.data?.data || []);
        setFetching(false);
      });
    };

    return debounce(loadOptions, debounceTimeout);
  }, [fetchOptions, debounceTimeout]);

  const handleChange = async (value: any): Promise<void> => {
    setSearchText('');
    if (selectedCategory === `${SLUG_PRODUCTS}`) {
      history.push(`${URL_PRODUCT_DETAIL_LINK}${value}/applications`);
      if (value === TEXT.SEARCH_MORE) {
        history.push(`${URL_PRODUCTS}?s=${searchText}`);
        return;
      }
      return;
    } else if (selectedCategory === `${SLUG_LEADS}`) {
      if (value === TEXT.SEARCH_MORE) {
        history.push(`${URL_ALL_CONTACTS}?s=${searchText}`);
        return;
      }
      history.push(`${URL_CONTACT_DETAIL}${value}/activities`);
      return;
    } else if (selectedCategory === `${SLUG_INSTITUTIONS}`) {
      if (value === TEXT.SEARCH_MORE) {
        history.push(`${URL_INSTITUTE}?s=${searchText}`);
        return;
      }
      history.push(`${URL_INSTITUTION_DETAIL_LINK}${value}/branches`);
      return;
    }
  };

  return (
    <Select<ValueType>
      showSearch
      suffixIcon={<SearchOutlined />}
      filterOption={false}
      onSelect={handleChange}
      onSearch={debounceFetcher}
      autoClearSearchValue
      notFoundContent={
        fetching && (
          <div className="full-width">
            <Spin size="small" />
          </div>
        )
      }
      {...props}
    >
      {searchText.length > 0 && (
        <Option value={TEXT.SEARCH_MORE} onClick={handleChange}>
          <div className="dashboard-header__search-more">
            <Space direction="horizontal">
              <SearchOutlined className="icon" />
              {TEXT.SEARCH_FOR}
              <Text strong>{`${searchText}`}</Text>
            </Space>
          </div>
        </Option>
      )}
      {options?.map((item: any) => {
        if (selectedCategory === SLUG_LEADS)
          return (
            <Option
              value={item?.id || ''}
              key={item.id}
              onSelect={handleChange}
            >
              <ContactProfileCard
                firstName={item?.first_name}
                lastName={item?.last_name}
                email={item?.email}
                phone={item?.phone}
                status={item?.status}
                branchName={item?.branch?.name}
                archivedContact={item?.archived}
                id={`${item?.company.company_code}-${item?.internal_id}`}
              />
            </Option>
          );
        if (selectedCategory === SLUG_PRODUCTS)
          return (
            <Option value={item.id} key={item.id} onSelect={handleChange}>
              <Text className="text-primary-color ">{item.name}</Text>
              <br />
              <Text type="secondary">{item?.institution?.name}</Text>
            </Option>
          );
        if (selectedCategory === SLUG_INSTITUTIONS)
          return (
            <Option value={item.id} key={item.id} onSelect={handleChange}>
              <InstitutionTableNameCard
                id={item?.id}
                firstName={item?.name || ''}
                email={item?.email || ''}
              />
            </Option>
          );
        return;
      })}
    </Select>
  );
}

export { DashboardSearch };
