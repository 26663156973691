/* eslint-disable no-unused-vars */
import React, { useContext, useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { Link, useHistory, useParams } from 'react-router-dom';
import { leadActions, useAppSelector } from '@lyra/core';
import { getAllLead } from '@lyra/services.api';
import {
  DeleteOutlined,
  FormOutlined,
  MailFilled,
  ImportOutlined,
  PlusOutlined,
  RollbackOutlined,
  UserSwitchOutlined,
} from '@ant-design/icons';
import {
  Button,
  PageHeaderMenu,
  PageHeaderTitle,
  popConfirm,
  Table,
} from '@shared-components/elements';
import {
  ADD_LEAD,
  ARCHIVE,
  CONFIRM_MESSAGE,
  CONFIRM_TITLE,
  CONTACT,
  DELETE_BTN,
  IMPORT,
  LEAD_INITIAL_DATA,
  LEAD_TITLE,
  MANAGE_FORM,
  PAGE_HEADER_MENU_LEAD,
  RESTORE,
  SLUG_ALL_LEADS,
  SLUG_ARCHIVED,
  TEXT,
  URL_ACTIVE_LEAD_FORM,
} from '@moxie/constants';
import { LeadContext } from '..';
import { LeadAddEdit } from '../../lead-add-edit';
import ImportLeadDrawer from '../import-drawer';
import Can, { PermissionAction, PermissionObjects } from '@lyra/privilege';
import { getColumns } from '../../contacts/libs/lead-columns';

const Lead: React.FC = () => {
  const [
    initialData,
    setInitialData,
    isOpen,
    setOpen,
    disable,
    setDisable,
    refreshTable,
    triggerRefresh,
  ] = useContext(LeadContext);
  const [visible, setVisible] = useState(false);
  const [isSubmitted, setIsSubmitted] = useState<boolean>(false);
  const [selectedId, setSelectedId] = useState<string[]>([]);
  const { name } = useParams<{ name: string }>();
  const [filter, setFilter] = useState({ selectBy: name });
  const dispatch = useDispatch();
  const history = useHistory();
  const { assignee } = useAppSelector((store) => ({
    assignee: store.users.allData,
  }));
  const redirectToAll = useAppSelector((store) => store.lead.redirectToAll);
  const tableLoading = useAppSelector((store) => store.lead.loading);

  if (useAppSelector((item) => item.lead.redirect)) {
    dispatch(leadActions.clearLeads());
  }

  useEffect(() => {
    if (filter.selectBy !== name) setFilter({ selectBy: name });
  }, [name]);

  useEffect(() => {
    triggerRefresh();
  }, [assignee]);

  const getData = async (str: string) => {
    return await getAllLead(str);
  };

  const handleAddLead = () => {
    setInitialData(LEAD_INITIAL_DATA);
    setOpen(true);
  };

  const onClose = () => {
    setOpen(false);
    setDisable(true);
    setIsSubmitted(false);
  };

  const handleClick = () => {
    setVisible(true);
  };

  const onCloseImport = () => {
    setVisible(false);
  };

  const handleSelectedId = (ids: string[]) => {
    setSelectedId(ids);
  };

  const handleMultipleArchive = () => {
    dispatch(leadActions.archiveMultipleLeadAction(selectedId));
    triggerRefresh();
  };

  const handleMultipleRestore = () => {
    dispatch(leadActions.restoreMultipleLeadAction(selectedId));
    triggerRefresh();
  };

  const handleMultipleDelete = () => {
    dispatch(leadActions.deleteMultipleLeadAction(selectedId));
    triggerRefresh();
  };

  const handleAfterLeadUpdate = () => {
    dispatch(leadActions.clearLeads());
    redirectToAll && history.push(`/leads/${SLUG_ALL_LEADS}`);
    triggerRefresh();
  };
  return (
    <div className="full-height">
      <PageHeaderTitle title={LEAD_TITLE} />
      <PageHeaderMenu menu={PAGE_HEADER_MENU_LEAD} defaultActiveKey={name} />
      <LeadAddEdit
        setDisabled={setDisable}
        submitted={isSubmitted}
        setSubmitted={setIsSubmitted}
        disabled={disable}
        isOpen={isOpen}
        onAfterSuccess={handleAfterLeadUpdate}
        onClose={onClose}
        initialData={initialData}
        triggerRefresh={triggerRefresh}
      />
      <ImportLeadDrawer
        isOpen={visible}
        onClose={onCloseImport}
        triggerRefresh={triggerRefresh}
      />
      <div className="bg-white padding-top-2">
        <Table
          tableLoading={tableLoading}
          filterOptions={true}
          tableName={LEAD_TITLE}
          refresh={refreshTable}
          columns={getColumns(TEXT.LEADS, name)}
          onChangeSelected={handleSelectedId}
          action={getData}
          filter={filter}
          size={'small'}
          className="lead-table"
          tableButtons={
            <>
              <Link to={URL_ACTIVE_LEAD_FORM}>
                <Button type="ghost" icon={<FormOutlined />}>
                  {MANAGE_FORM}
                </Button>
              </Link>

              <Button type="primary" onClick={handleAddLead}>
                <PlusOutlined />
                {ADD_LEAD}
              </Button>
              <Button
                type="primary"
                icon={<ImportOutlined />}
                onClick={handleClick}
                typeof="button"
              >
                {IMPORT}
              </Button>
            </>
          }
        >
          <Button type="primary" icon={<MailFilled />}>
            {CONTACT}
          </Button>
          {name !== SLUG_ARCHIVED ? (
            <>
              <>
                <Button
                  type="primary"
                  icon={<UserSwitchOutlined />}
                  onClick={() =>
                    popConfirm(
                      CONFIRM_TITLE.ARCHIVE_MULTIPLE_LEADS,
                      `${CONFIRM_MESSAGE.ARCHIVE_MULTIPLE_LEADS} ${selectedId.length} leads?`,
                      handleMultipleArchive
                    )
                  }
                >
                  {ARCHIVE}
                </Button>
              </>
            </>
          ) : (
            <>
              <Button
                type="primary"
                icon={<RollbackOutlined />}
                onClick={() =>
                  popConfirm(
                    CONFIRM_TITLE.RESTORE_MULTIPLE_LEADS,
                    `${CONFIRM_MESSAGE.RESTORE_MULTIPLE_LEADS} ${selectedId.length} leads?`,
                    handleMultipleRestore
                  )
                }
              >
                {RESTORE}
              </Button>
              <Button
                className="margin-left-1"
                type="primary"
                title={DELETE_BTN}
                onClick={() =>
                  popConfirm(
                    CONFIRM_TITLE.DELETE_MULTIPLE_LEADS,
                    `${CONFIRM_MESSAGE.DELETE_MULTIPLE_LEADS} ${selectedId.length} leads`,
                    handleMultipleDelete
                  )
                }
                danger
                icon={<DeleteOutlined />}
              >
                {DELETE_BTN}
              </Button>
            </>
          )}
        </Table>
      </div>
    </div>
  );
};

export { Lead };
