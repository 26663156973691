import { IAction, ITasks, ITasksPayload } from '@shared-components/models';
import * as actionTypes from './tasks.constant';

export const getTasksRequest = (id: string | any): IAction<string> => ({
  type: actionTypes.GET_TASKS_REQUEST,
  payload: id,
});

export const getTasksSuccess = (payload: ITasks[]): IAction<ITasks[]> => ({
  type: actionTypes.GET_TASKS_SUCCESS,
  payload,
});

export const getTasksFailure = (payload: ITasksPayload): IAction => ({
  type: actionTypes.GET_TASKS_FAILURE,
  payload,
});

export const getTasksClear = () => {
  return { type: actionTypes.GET_TASKS_CLEAR };
};
export const deleteTasksRequest = (
  payload: ITasksPayload,
  triggerRefresh: () => void
): IAction<ITasksPayload> => ({
  type: actionTypes.DELETE_TASK_REQUEST,
  payload,
  triggerRefresh,
});
export const deleteTasksSuccess = (
  payload: ITasksPayload
): IAction<ITasksPayload> => ({
  type: actionTypes.DELETE_TASK_SUCCESS,
  payload,
});
export const deleteTasksFailure = (
  payload: ITasksPayload
): IAction<ITasksPayload> => ({
  type: actionTypes.DELETE_TASK_FAILURE,
  payload,
});

export const addTasksRequest = (
  payload: any,
  triggerRefresh: () => void
): IAction<ITasks> => ({
  type: actionTypes.ADD_TASK_REQUEST,
  payload,
  triggerRefresh,
});
export const addTasksSuccess = (payload: ITasks): IAction<ITasks> => ({
  type: actionTypes.ADD_TASK_SUCCESS,
  payload,
});

export const addTasksFailure = (
  payload: ITasksPayload
): IAction<ITasksPayload> => ({
  type: actionTypes.ADD_TASK_FAILURE,
  payload,
});

export const editTasksRequest = (
  payload: any,
  triggerRefresh: () => void
): IAction<ITasksPayload> => ({
  type: actionTypes.UPDATE_TASK_REQUEST,
  payload,
  triggerRefresh,
});

export const editTasksSuccess = (
  payload: ITasksPayload
): IAction<ITasksPayload> => ({
  type: actionTypes.UPDATE_TASK_SUCCESS,
  payload,
});

export const editTasksFailure = (
  payload: ITasksPayload
): IAction<ITasksPayload> => ({
  type: actionTypes.UPDATE_TASK_FAILURE,
  payload,
});

export const updateTaskAssigneeRequest = (
  payload: any,
  triggerRefresh: () => void
): IAction<ITasksPayload> => ({
  type: actionTypes.UPDATE_TASK_ASSIGNEE_REQUEST,
  payload,
  triggerRefresh,
});

export const updateTaskAssigneeSuccess = (
  payload: any
): IAction<ITasksPayload> => ({
  type: actionTypes.UPDATE_TASK_ASSIGNEE_SUCCESS,
  payload,
});

export const updateTaskAssigneeFailure = (
  payload: any
): IAction<ITasksPayload> => ({
  type: actionTypes.UPDATE_TASK_ASSIGNEE_FAILURE,
  payload,
});

export const updateTaskStatusRequest = (
  payload: ITasksPayload
): IAction<ITasksPayload> => ({
  type: actionTypes.UPDATE_TASK_STATUS_REQUEST,
  payload,
});
export const updateTaskStatusSuccess = (
  payload: ITasksPayload
): IAction<ITasksPayload> => ({
  type: actionTypes.UPDATE_TASK_STATUS_SUCCESS,
  payload,
});
export const updateTaskStatusFailure = (
  payload: ITasksPayload
): IAction<ITasksPayload> => ({
  type: actionTypes.UPDATE_TASK_STATUS_FAILURE,
  payload,
});

export const downloading = (payload: boolean): IAction<boolean> => ({
  type: actionTypes.DOWNLOADING,
  payload,
});
