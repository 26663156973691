import { IResponse } from '@shared-components/models';
import axios from './axios';
import { API_URL } from '@moxie/constants';

const getAllApplications = async (params = ''): Promise<IResponse> => {
  return axios.get(`${API_URL.ADMIN_APPLICATIONS}/all/${params}`);
};

const getApplication = async (id: string): Promise<IResponse> => {
  return axios.get(`${API_URL.ADMIN_APPLICATIONS}/detail/${id}`);
};

const filterTenantData = (): Promise<IResponse> => {
  return axios.get(`${API_URL.ADMIN_APPLICATIONS_FILTER_TENANT}`);
};

const postApplicationComment = async (body: {
  comment: string;
  application_id: string;
  created_by: string;
}): Promise<IResponse> => {
  return axios.post(`${API_URL.APPLICATIONS}/comment`, body);
};

const getApplicationComments = async (
  id: string | undefined
): Promise<IResponse> => {
  return axios.get(`${API_URL.APPLICATIONS}/many/comment/${id}`);
};

export {
  getAllApplications,
  getApplication,
  filterTenantData,
  postApplicationComment,
  getApplicationComments,
};
