import { put, takeLatest } from 'redux-saga/effects';
import * as actionTypes from './product.action';
import * as action from './product.constant';
import { AxiosResponse } from 'axios';
import { successNotificationHandler } from '@moxie/shared';
import { IAction } from '@shared-components/models';
import {
  getCRMProductsList,
  postCRMProduct,
} from '../../libs/services.api/lib/product.api';
import { ICrmProduct, ProductView } from '../../pages/products/product.model';
import { crmErrorHandler } from 'libs/shared/src/crm-modules/shared';
import { CrmPaginatedResponse } from '@model/api-response.model';

export const addProduct = function* ({
  payload,
  triggerRefresh,
}: IAction<ICrmProduct>) {
  try {
    if (payload) {
      const response: AxiosResponse = yield postCRMProduct(payload);
      successNotificationHandler(response?.data?.message);
      triggerRefresh && triggerRefresh();
    }
  } catch (err) {
    crmErrorHandler(err);
    yield put(actionTypes.addProductFailure(err));
  }
};

const getProducts = function* (params: any) {
  try {
    const { service, country, ...restParams } = params.payload;
    // TODO: Replace with dot-object 
    const res: CrmPaginatedResponse<ProductView> = yield getCRMProductsList({ 'filter.serviceId': service, 'filter.country': country, ...restParams });
    yield put(actionTypes.getProductsSuccess(res));
  } catch (err) {
    crmErrorHandler(err);
    yield put(actionTypes.getProductsFailure(err));
  }
};

const productSaga = function* (): unknown {
  yield takeLatest(action.ADD_PRODUCT_REQUEST, addProduct);
  yield takeLatest(action.GET_PRODUCTS_REQUEST, getProducts);
};

export default productSaga;
