import {
  IAction,
  ISubjectDiscipline,
  ISubjectDisciplinePayload,
} from '@shared-components/models';
import * as actionTypes from './subject-disciplines.constant';

export const getSubjectDisciplinesRequest = () => ({
  type: actionTypes.GET_SUBJECT_DISCIPLINES_REQUEST,
});
export const getSubjectDisciplinesSuccess = (
  payload: ISubjectDiscipline[]
): IAction<ISubjectDiscipline[]> => ({
  type: actionTypes.GET_SUBJECT_DISCIPLINES_SUCCESS,
  payload,
});
export const getSubjectDisciplinesFailure = (
  payload: ISubjectDisciplinePayload
): IAction<ISubjectDisciplinePayload> => ({
  type: actionTypes.GET_SUBJECT_DISCIPLINES_FAILURE,
  payload,
});
