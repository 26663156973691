import React from "react";
import { Col, Row } from "antd/es/grid";
import { TaskWidget } from "./tasks";
import WelcomeUser from "./welcome-user";


const CrmDashboard: React.FC = () => {

  return (
    <Row gutter={[15, 0]}>
      <Col span={8}>
        <div className="dashboard-wrapper">
          <WelcomeUser />
          <TaskWidget />
        </div>
      </Col>
      <Col span={16}>
      </Col>
    </Row>
  )
}

export { CrmDashboard };
