import React from 'react';
import { Row, Col, Avatar, Typography, Space, Dropdown, Menu, Tag } from 'antd';
import {
  DeleteOutlined,
  EditOutlined,
  MoreOutlined,
  PlusOutlined,
} from '@ant-design/icons';
import {
  InstitutionBranchTagGroup,
  Table,
  Button,
} from '@shared-components/elements';
import {
  IInstitutionBranch,
  ITableInstitutionProps,
  ITableNameCardProps,
  ITableProps,
} from '@shared-components/models';
import { Link } from 'react-router-dom';
import { BankOutlined } from '@ant-design/icons';
import {
  INSTITUTE_TITLE,
  URL_INSTITUTION_DETAIL_LINK,
  LABEL,
  EDIT_BTN,
  ADD_INSTITUTION,
  PRODUCT_TITLE,
  PAGE_HEADER_MENU_INSTITUTE,
} from '@moxie/constants';
import { DELETE_BTN } from '@moxie/constants';
import { useAppSelector } from '@admin/core';
import { PageHeaderMenu } from '../components/page-header-menu';

const { Text } = Typography;

export const InstitutionTableNameCard: React.FC<ITableNameCardProps> = ({
  firstName,
  email,
  id,
  index,
  menuActions,
}: ITableNameCardProps) => {
  return (
    <Row>
      <Col md={4}>
        <Link to={`${URL_INSTITUTION_DETAIL_LINK}${id}/branches`}>
          <Avatar icon={<BankOutlined />} className="margin-right-2" />
        </Link>
      </Col>
      <Col md={16}>
        <Row key={index}>
          <Col>
            <Link to={`${URL_INSTITUTION_DETAIL_LINK}${id}/branches`}>
              <Text
                className="text-primary-color ellipsis-text"
                ellipsis={
                  String(firstName).length >= 45
                    ? { tooltip: firstName }
                    : false
                }
              >
                {firstName}
              </Text>
            </Link>
          </Col>
          <Col span={24}>
            <Text className="text-primary-color">
              <small>{email?.toLowerCase()}</small>
            </Text>
          </Col>
        </Row>
      </Col>
      <Col md={4}>
        <Dropdown overlay={InstitutionTitleMenu(menuActions)}>
          <Button
            type="text"
            className="table-cell-action-btn"
            icon={<MoreOutlined />}
          />
        </Dropdown>
      </Col>
    </Row>
  );
};

const InstitutionTitleMenu = ({ data, deleteData, showDrawer }: any) => {
  return (
    <Menu>
      <Menu.Item key={1} onClick={() => showDrawer(data.id)}>
        <Space direction="horizontal">
          <EditOutlined />
          {EDIT_BTN}
        </Space>
      </Menu.Item>
      <Menu.Item key={2} danger onClick={deleteData}>
        <Space direction="horizontal">
          <DeleteOutlined />
          {DELETE_BTN}
        </Space>
      </Menu.Item>
    </Menu>
  );
};

export const columns = (props: ITableProps) => {
  const { deleteData, showDrawer } = props;
  return [
    {
      title: LABEL.NAME,
      key: 'name',
      width: 400,
      sorter: () => 0,
      render: function TableNameCardFunction(data: any) {
        const handleDelete = () => {
          if (data.id != null) {
            deleteData?.(data.id);
          }
        };

        const menuActions = {
          data: data,
          deleteData: handleDelete,
          showDrawer: showDrawer,
        };
        return (
          <InstitutionTableNameCard
            menuActions={menuActions}
            id={data.id}
            firstName={data.name}
            email={data.email}
            index={data?.id.toString()}
          />
        );
      },
    },
    {
      title: LABEL.SERVICE,
      dataIndex: 'service',
      render(data: { name: string }) {
        return data?.name;
      },
      key: 'service',
    },
    {
      title: LABEL.BRANCHES,
      dataIndex: 'branches',
      width: 300,
      key: 'branches',
      render(data: IInstitutionBranch[]) {
        return <InstitutionBranchTagGroup data={data} />;
      },
    },
    {
      title: LABEL.WORKFLOW,
      dataIndex: 'workflows',
      width: 300,
      key: 'workflows',
      render(data: any) {
        return (
          <div>
            {data?.map((item: any) => {
              return (
                <Tag key={item?.id} color="default" className="initial_capital">
                  {item?.workflowType?.name}
                </Tag>
              );
            })}
          </div>
        );
      },
    },
    {
      title: PRODUCT_TITLE,
      dataIndex: 'products',
    },
  ];
};

const InstitutionListComponent: React.FC<ITableInstitutionProps> = ({
  refreshTable,
  deleteData,
  getData,
  showDrawer,
  filter,
}: ITableInstitutionProps) => {
  const columnActions = {
    deleteData: deleteData,
    showDrawer: showDrawer,
  };
  const tableLoading = useAppSelector((state) => state.institutions.loading);
  return (
    <>
    <PageHeaderMenu menu={PAGE_HEADER_MENU_INSTITUTE} /> 
    <div className="bg-white padding-top-2">
      
      <Table
        refresh={refreshTable}
        filterOptions={true}
        tableName={INSTITUTE_TITLE}
        columns={columns(columnActions)}
        action={getData}
        className="institution-table"
        filter={filter}
        tableButtons={
          <Button type="primary" onClick={() => showDrawer && showDrawer()}>
            <PlusOutlined />
            {ADD_INSTITUTION}
          </Button>
        }
        tableLoading={tableLoading}
      />
    </div>
    </>
  );
};

export { InstitutionListComponent };
