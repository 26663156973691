import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import { Card, Col, Divider, Empty, Form, Input, Menu, Row } from 'antd';
import { SearchOutlined } from '@ant-design/icons';
import {
  otherDetailActions,
  otherDetailValueActions,
  useAppSelector,
} from '@lyra/core';
import OtherDetailCollapse from '../../lead-other-details';
import { GUTTER } from '@moxie/constants';

const LeadOtherDetails: React.FC<any> = (props: any) => {
  const dispatch = useDispatch();
  const { lead } = props;
  const { id } = useParams<{ id: string }>();

  const { categories, labelValue } = useAppSelector((store) => ({
    categories: store.setting_other_details.categories,
    labels: store.setting_other_details.labels,
    labelValue: store.lead_other_detail_value.valueData,
  }));
  const [searchData, setSearchData] = useState<any[]>();

  const [categoryKey, setCategoryKey] = useState<string>();

  const getFields = async () => {
    dispatch(otherDetailActions.getCategoriesRequest());
  };

  const handleClick = async ({ key }: { key: string }) => {
    setCategoryKey(key);
    dispatch(otherDetailValueActions.getLeadOtherDetailValuesClear());
    dispatch(otherDetailActions.getLabelRequest(key));
    dispatch(otherDetailValueActions.getLeadOtherDetailValuesRequest(id, key));
  };

  const handleSearch = (values: any) => {
    const searchResult: any = [];
    if (values.searchValues.trim() === '') {
      setSearchData(categories);
    } else {
      categories?.forEach((item: { labels: any[] }) => {
        const searchLabels = item.labels.filter((data: { label: any }) => {
          const searchEl = values.searchValues.toLowerCase();
          return data.label.toLowerCase().indexOf(searchEl) !== -1
            ? data
            : null;
        });
        if (searchLabels?.length !== 0) {
          searchResult.push({ ...item, labels: searchLabels });
        }
      });
      setSearchData(searchResult);
    }
  };

  useEffect(() => {
    setCategoryKey(categories && categories[0]?.id);
    setSearchData(categories);
    if (categories?.length !== 0) {
      dispatch(
        otherDetailValueActions.getLeadOtherDetailValuesRequest(
          id,
          categories[0]?.id
        )
      );
    }
  }, [categories, id]);

  useEffect(() => {
    getFields();
  }, [id]);

  return (
    <div className="overflow-scroll">
      <Divider plain={true} className="search-margin-top" />
      <Row gutter={GUTTER}>
        <Col span="8" className="padding-left-2">
          <Card title="Categories">
            {categories && categories?.length !== 0 ? (
              <Menu
                mode="inline"
                defaultSelectedKeys={[categories[0]?.id]}
                className="menu_ul"
              >
                {searchData?.map((category: { id: string; name: string }) => (
                  <Menu.Item
                    className="lead-other-details__menu initial_capital"
                    onClick={handleClick}
                    key={category.id}
                  >
                    {category.name}
                  </Menu.Item>
                ))}
              </Menu>
            ) : (
              <div className="align-contents-center">
                <Empty image={Empty.PRESENTED_IMAGE_SIMPLE} />
              </div>
            )}
          </Card>
        </Col>
        <Col xl={12} lg={16}>
          <Form onValuesChange={handleSearch}>
            <Form.Item name="searchValues">
              <Input
                suffix={
                  <SearchOutlined className="lead-other-details__search-color" />
                }
                placeholder="Search"
                bordered={true}
                size={'small'}
                className="lead-other-details__width padding-right-2"
              />
            </Form.Item>
          </Form>
          {searchData?.map((category: any) => {
            return (
              <OtherDetailCollapse
                key={category.id}
                lead={lead}
                labelValue={labelValue}
                parent_id={id}
                labels={category.labels}
                category={category}
                categoryKey={categoryKey && categoryKey}
              />
            );
          })}
        </Col>
      </Row>
    </div>
  );
};

export default LeadOtherDetails;
