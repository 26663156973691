import { Col, Row, Typography } from 'antd';
import { ITableTitleCard } from '@shared-components/models';

import React from 'react';
import { capitalizeFirstLetter } from 'libs/shared/src/functions';

const { Text } = Typography;

export const TableRoleNameCard: React.FC<ITableTitleCard> = ({
  data,
  showDrawer,
}: ITableTitleCard) => {
  const handleDrawer = () => {
    showDrawer(data.id);
  };

  return (
    <Row>
      <Col span={18} className="align-items-center">
        <Col span={16}>
          <Text
            className="text-primary-color email-ellipsis cursor-pointer"
            onClick={handleDrawer}
          >
            {(data.name === 'superAdmin' && 'Super Admin') ||
              capitalizeFirstLetter(data.name)}
          </Text>
        </Col>
      </Col>
    </Row>
  );
};
