import { CONSENT_TEXT, PRIVACY_INFO_TEXT } from './form-all-constant';

export const LEAD_INITIAL_DATA = {
  first_name: undefined,
  last_name: undefined,
  data_of_birth: undefined,
  country_code: undefined,
  assignee_user_id: undefined,
  phone: undefined,
  archived: undefined,
  email: undefined,
  avatar: undefined,
  street: undefined,
  city: undefined,
  assignee_id: undefined,
  state: undefined,
  client_id: undefined,
  address_line_one: undefined,
  address_line_two: undefined,
  zip_code: undefined,
  preferred_intake: undefined,
  passport_no: undefined,
  visa_type: undefined,
  visa_export_date: undefined,
  passport_expiry_date: undefined,
  application_id: undefined,
  followers_id: undefined,
  source: undefined,
  tags: undefined,
  postal_code: undefined,
  country: undefined,
  country_of_passport: undefined,
  status: undefined,
  crm_assignee_id: '',
};

export const LEAD_FORM_INITIAL_DATA = {
  compliance_section: {
    show_privacy_information: true,
    privacy_information: PRIVACY_INFO_TEXT,
    consent_information: CONSENT_TEXT,
  },
  logo_path_preview: undefined,
  cover_image_path_preview: undefined,
};
export const NOTE_INITIAL_DATA = {
  title: '',
  description: '',
};

export const APPLICATION_FILTER_INITIAL_DATA = {
  addedBy: [],
  applicationStatus: [],
  assignee: [],
  branches: [],
  companies: [],
  country: [],
  countryOfPassport: [],
  currentStages: [],
  institutions: [],
  products: [],
  services: [],
  source: [],
  workflows: [],
};
