import React from 'react';
import { TEXT } from '@moxie/constants';
import { Button, Table } from '@moxie/shared';
import { ContactReportTableColumns } from './table-columns';

const ContactReportTable = ({
  getData,
  tableName,
  filter,
  setFilter,
  handleExport,
}: any) => {
  return (
      <Table
        action={getData}
        columns={ContactReportTableColumns(setFilter)}
        tableName={tableName}
        tableLayout="fixed"
        size="small"
        filter={filter}
        filterOptions={true}
        scroll={window.screen.height < 800 ? { y: 500, x: 'max-content' } : { y: undefined, x: 'max-content' }}
        tableButtons={
          <Button type="primary" onClick={() => handleExport()}>
            {TEXT.EXPORT}
          </Button>
        }
      />
  );
};

export { ContactReportTable };
