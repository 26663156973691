import axios from './axios';
import { IDepartment, IResponse } from '@shared-components/models';
import { API_URL } from '@moxie/constants';

const addDepartment = async (body: IDepartment): Promise<IResponse> => {
  return axios.post(API_URL.DEPARTMENTS, body);
};

const updateDepartment = async (body: IDepartment): Promise<IResponse> => {
  return axios.put(API_URL.DEPARTMENTS, body);
};

const getAllDepartments = async (params = ''): Promise<IResponse> => {
  return axios.get(`${API_URL.DEPARTMENTS}/${params}`);
};

const getOneDepartment = async (id: string): Promise<IResponse> => {
  return axios.get(`${API_URL.DEPARTMENTS}/${id}`);
};

const getDepartmentUsers = async (
  id: string,
  str: string
): Promise<IResponse> => {
  return axios.get(`${API_URL.DEPARTMENTS}/users/${id}/${str}`);
};

const deleteDepartment = async (id: string): Promise<IResponse> => {
  return axios.delete(`${API_URL.DEPARTMENTS}/${id}`);
};

export {
  addDepartment,
  updateDepartment,
  getAllDepartments,
  getDepartmentUsers,
  getOneDepartment,
  deleteDepartment,
};
