import React, { useState } from 'react';
import { Avatar, Typography } from 'antd';
import {
  InstitutionBranchTagGroup,
  PageHeaderTitle,
  Table,
} from '@shared-components/elements';
import { getAllInstitutes } from '@lyra/services.api';
import {
  IInstituteInterface,
  ITableNameCardProps,
} from '@shared-components/models';
import { Link } from 'react-router-dom';
import { BankOutlined } from '@ant-design/icons';
import {
  INSTITUTE_TITLE,
  URL_INSTITUTION_DETAIL_LINK,
  LABEL,
  PRODUCT_TITLE,
  PAGE_HEADER_MENU_INSTITUTE,
} from '@moxie/constants';
import { ColumnsType } from 'antd/lib/table';
import { PageHeaderMenu } from '../components/page-header-menu';

const { Text } = Typography;

export const InstitutionTableNameCard: React.FC<ITableNameCardProps> = ({
  branchName,
  firstName,
  email,
  id,
}: ITableNameCardProps) => {
  return (
    <div className="table-user-card">
      <div className="table-user-card__avatar">
        <Link to={`${URL_INSTITUTION_DETAIL_LINK}${id}/branches`}>
          <Avatar icon={<BankOutlined />} className="margin-right-2" />
        </Link>
      </div>
      <div className="table-user-card__meta">
        <Link to={`${URL_INSTITUTION_DETAIL_LINK}${id}/branches`}>
          <Text
            className="text-primary-color product-name-table"
            ellipsis={
              String(firstName).length >= 45 ? { tooltip: firstName } : false
            }
          >
            {firstName}
          </Text>
          {branchName && <span>{` (${branchName})`}</span>}
        </Link>
        <Text className="text-primary-color">
          <small>{email?.toLowerCase()}</small>
        </Text>
      </div>
    </div>
  );
};

const InstitutionListComponent: React.FC = () => {
  const [filter] = useState({});
  const columns: ColumnsType<any> = [
    {
      title: LABEL.NAME,
      key: 'name',
      sorter: () => 0,
      width: 250,
      render: function TableNameCardFunction(
        _text: string,
        row: IInstituteInterface,
        index: number
      ) {
        return (
          <InstitutionTableNameCard
            id={row.id}
            firstName={row.name}
            email={row.email}
            index={index.toString()}
          />
        );
      },
    },
    {
      title: LABEL.SERVICE,
      dataIndex: 'service',
      width: 130,
      render(data) {
        return data?.name;
      },
      key: 'service',
    },
    {
      title: LABEL.BRANCHES,
      dataIndex: 'branches',
      width: 270,
      key: 'branches',
      render(data) {
        return <InstitutionBranchTagGroup data={data && data} />;
      },
    },
    {
      title: PRODUCT_TITLE,
      dataIndex: 'products',
      width: 80,
      key: 'products',
    },
    {
      title: LABEL.ENROLLED,
      dataIndex: 'enrolled',
      width: 80,
      key: 'enrolled',
      render(data) {
        return data ? data : '-';
      },
    },
    {
      title: LABEL.IN_PROGRESS,
      dataIndex: 'inprogress',
      width: 80,
      key: 'inprogress',
      render(data) {
        return data ? data : '-';
      },
    },
  ];

  const getData = async (params: string) => {
    return getAllInstitutes(params);
  };

  return (
    <div className="full-height">
      <PageHeaderTitle title={INSTITUTE_TITLE} />
      <PageHeaderMenu menu={PAGE_HEADER_MENU_INSTITUTE} />
      <div className="bg-white padding-top-2">
        <Table
          filterOptions={true}
          tableName={INSTITUTE_TITLE}
          columns={columns}
          action={getData}
          className="institution-table"
          filter={filter}
        />
      </div>
    </div>
  );
};

export { InstitutionListComponent };
