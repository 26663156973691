import { IAction, IPayload } from '@shared-components/models';
import * as actionTypes from './tenant.constant';

export const fetchTenants = (): IAction => ({
  type: actionTypes.GET_TENANTS_REQUEST,
});

export const fetchTenantsSuccess = (payload: any[]): IAction<any[]> => ({
  type: actionTypes.GET_TENANTS_SUCCESS,
  payload,
});

export const fetchTenantsFailure = (payload: IPayload<any>): IAction<any> => ({
  type: actionTypes.GET_TENANTS_FAILURE,
  payload,
});

export const fetchTenant = (payload: string): IAction<string> => ({
  type: actionTypes.GET_TENANT_REQUEST,
  payload,
});

export const fetchTenantSuccess = (payload: any): IAction<any> => ({
  type: actionTypes.GET_TENANT_SUCCESS,
  payload,
});

export const fetchTenantFailure = (payload: IPayload<any>): IAction => ({
  type: actionTypes.GET_TENANT_FAILURE,
  payload,
});

export const deleteTenantRequest = (
  id: string,
  triggerRefresh: any
): IAction<string> => ({
  type: actionTypes.DELETE_TENANT_REQUEST,
  payload: id,
  triggerRefresh,
});

export const deleteTenantSuccess = (payload: any): IAction => ({
  type: actionTypes.DELETE_TENANT_SUCCESS,
  payload,
});

export const deleteTenantFailure = (payload: IPayload<any>): IAction => ({
  type: actionTypes.DELETE_TENANT_FAILURE,
  payload,
});

export const clearFetchTenant = (): IAction => ({
  type: actionTypes.CLEAR_TENANT_FETCH,
});

export const addTenantRequest = (
  payload: any,
  onClose: any,
  triggerRefresh: any
): IAction<any> => ({
  type: actionTypes.ADD_TENANT_REQUEST,
  payload,
  onClose,
  triggerRefresh,
});

export const addTenantSuccess = (payload: any): IAction<any> => ({
  type: actionTypes.ADD_TENANT_SUCCESS,
  payload,
});

export const addTenantFailure = (payload: IPayload<any>): IAction => ({
  type: actionTypes.ADD_TENANT_FAILURE,
  payload,
});

export const updateTenantRequest = (
  id: string | undefined,
  payload: any,
  onClose: any,
  triggerRefresh: any
): IAction<any> => ({
  type: actionTypes.UPDATE_TENANT_REQUEST,
  id,
  payload,
  onClose,
  triggerRefresh,
});

export const updateTenantSuccess = (payload: any): IAction<any> => ({
  type: actionTypes.UPDATE_TENANT_SUCCESS,
  payload,
});

export const updateTenantFailure = (payload: IPayload<any>): IAction => ({
  type: actionTypes.UPDATE_TENANT_FAILURE,
  payload,
});

export const updateSharingRatioRequest = (
  id: string,
  payload: any
): IAction<any> => ({
  type: actionTypes.UPDATE_SHARING_RATIO_REQUEST,
  id,
  payload,
});

export const updateSharingRatioSuccess = (payload: any): IAction<any> => ({
  type: actionTypes.UPDATE_SHARING_RATIO_SUCCESS,
  payload,
});

export const updateSharingRatioFailure = (payload: IPayload<any>): IAction => ({
  type: actionTypes.UPDATE_SHARING_RATIO_FAILURE,
  payload,
});

export const disableTenantRequest = (
  id: string | undefined,
  payload: any,
  triggerRefresh: any
): IAction<any> => ({
  type: actionTypes.DISABLE_TENANT_REQUEST,
  id,
  payload,
  triggerRefresh,
});

export const disableTenantSuccess = (payload: any): IAction<any> => ({
  type: actionTypes.DISABLE_TENANT_SUCCESS,
  payload,
});

export const disableTenantFailure = (payload: IPayload<any>): IAction => ({
  type: actionTypes.DISABLE_TENANT_FAILURE,
  payload,
});
