import React, { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { useParams } from 'react-router-dom';
import { applicationActions, useAppSelector } from '@admin/core';
import { getClientApplications } from '@admin/services.api';
import { APPLICATION_ACTORS, LABEL } from '@moxie/constants';
import { ApplicationDetail, ApplicationProvider } from '@moxie/shared';
import { IURLParams } from '@shared-components/models';
import ClientApplicationTable from './client-application-table';

const ContactApplicationList: React.FC = () => {
  const dispatch = useDispatch();
  const params: IURLParams = useParams();
  const applicationId = params.applicationId;

  useEffect(() => {
    if (applicationId) {
      dispatch(applicationActions.getOneRequest(applicationId));
    }
  }, [applicationId]);

  const [refreshTable, setRefreshTable] = useState(true);

  const { id: contactId } = useParams<{ id: string }>();
  const leadData = useAppSelector((store) => store.lead.lead);
  const [filter] = useState({});
  const currentUser = useAppSelector((store) => store.auth.user);
  const socket = useAppSelector((store) => store.socket.wss);
  const triggerRefresh = () => setRefreshTable((prev) => !prev);
  const getAll = async (params: string) => {
    return getClientApplications(contactId, params);
  };
  return applicationId ? (
    <ApplicationProvider>
      <ApplicationDetail type={APPLICATION_ACTORS.ADMISSION} />
    </ApplicationProvider>
  ) : (
    <ClientApplicationTable
      getData={getAll}
      tableName={LABEL.LEAD_APPLICATIONS}
      filter={filter}
      refreshTable={refreshTable}
      setRefreshTable={setRefreshTable}
    />
  );
};

export { ContactApplicationList };
