/* eslint-disable no-unused-vars */
import React, { useEffect, useState } from 'react';
import { Table as AntTable, Input, Row, Space, Col, Pagination, TableProps } from 'antd';
import { useLocation } from 'react-router';
import { useParams } from 'react-router-dom';
import { SEARCH } from '@moxie/constants';
import { TableRowSelection } from 'antd/lib/table/interface';
import usePaginationCRM from './hooks/use-pagination-crm';
import { CrmPaginatedResponse } from '@model/api-response.model';
import axios, { CancelToken } from 'axios';

const Search = Input.Search;
const PAGINATION_CONFIG = {
  showSizeChanger: false,
  pageSizeOptions: ['10', '25', '50', '100'],
};

interface Props<T, K extends undefined> extends TableProps<T> {
  additionalFilters?: (filter: Record<string, unknown>) => React.ReactNode;
  onRowSelect?: (ids: string[]) => void;
  hasPagination?: boolean;
  tableButtons?: React.ReactNode;
  action: (params: Record<string, unknown>) => Promise<CrmPaginatedResponse<T>>;
  filter?: K | Record<string, unknown> | undefined;
  filterContent?: JSX.Element;
  tableName?: string;
  refresh?: boolean;
  hideSearch?: boolean;
  searchBarClassName?: string;
  allowRowSelection?: boolean;
  type?: string;
  token?: CancelToken;
  search?: string;
  optimisticUpdate?: boolean;
}

let isFirstRender = true;
function CrmTable<T, K extends undefined>({
  columns,
  action,
  tableName,
  filter,
  refresh,
  children,
  className,
  expandable,
  filterContent,
  tableButtons,
  hasPagination = true,
  size,
  scroll,
  additionalFilters,
  hideSearch = false,
  allowRowSelection = false,
  type,
  searchBarClassName = '',
  search,
  optimisticUpdate = false,
  ...props
}: Props<T, K>) {
  const location = useLocation();
  const query = new URLSearchParams(location.search.replace('?', ''));

  const [selectedRecords, setCurrentSelected] = useState<Array<string>>([]);
  const [currentSelectedByPage, setCurrentSelectedByPage] = useState<any>({});
  const [searchValue, setSearch] = useState(query.get('search') ?? '');
  const {
    data,
    isLoading,
    paginationMeta,
    setPagination,
    refetchRecords,
    refetching,
    initialLoading,
  } = usePaginationCRM({
    action,
    filter,
    type,
    optimisticUpdate,
  });

  useEffect(() => {
    setPagination(prev => ({
      ...prev,
      search,
      page: 1,
    }))
  }, [search, setPagination]);

  const rowSelection: TableRowSelection<any> = {
    type: 'checkbox',
    selectedRowKeys: selectedRecords,
    onChange: (selectedRowKeys: React.Key[]) => {
      setCurrentSelectedByPage(() => {
        return {
          ...currentSelectedByPage,
          [paginationMeta.currentPage]: selectedRowKeys,
        };
      });
    },
    getCheckboxProps: (record: any) => ({
      disabled: record.name === 'Disabled User',
      name: record.name,
    }),
  };

  const handleChange = (page: number, pageSize: number | any) => setPagination(prev => ({
    ...prev,
    page: page ?? 1,
    limit: pageSize ?? 10
  }));

  const handleTableChange = (_pagination: any, _filters: any, sorter: any) => {

    let sort = sorter.order;
    if (sort === 'ascend') {
      sort = 'ASC';
    } else if (sort === 'descend') {
      sort = 'DESC';
    }

    if (sorter?.column?.dataIndex && sort) {
      setPagination((prev) => ({
        ...prev,
        page: 1,
        sortBy: `${sorter?.column?.dataIndex}:${sort}`
      }))
    }
    else {
      setPagination((prev) => ({
        ...prev,
        page: 1,
        sortBy: undefined
      }))
    }

  };

  const handleUnselect = () => {
    setCurrentSelected([]);
  };

  const handleSearch = (value: string) => setPagination(prev => ({
    ...prev,
    search: value,
    page: 1,
  }))

  const handleSearchPropagation = (event: any) => {
    setSearch(event.target.value);
  };

  useEffect(() => {
    if (isFirstRender) {
      isFirstRender = false;
      return;
    }
    const cancelToken = axios.CancelToken;
    const source = cancelToken.source();
    refetchRecords(source.token);
    return () => source.cancel();
  }, [refresh]);

  return (
    <div className="padding-x1">
      {(!hideSearch || tableButtons) && (
        <Row justify="space-between" className="padding-bottom-1">
          {hideSearch ? null : (
            <Col>
              <Space>
                <Search
                  value={searchValue}
                  placeholder={SEARCH}
                  onSearch={handleSearch}
                  className={`table_search ${searchBarClassName}`}
                  allowClear
                  onChange={handleSearchPropagation}
                />
                <Space direction="horizontal">{filterContent}</Space>
              </Space>
            </Col>
          )}
          {tableButtons ? (
            <Col>
              <Space>{tableButtons}</Space>
            </Col>
          ) : null}
        </Row>
      )}

      <div className="table">
        <AntTable
          dataSource={data ?? [] as any}
          columns={columns as any}
          loading={optimisticUpdate ? initialLoading : isLoading}
          size={size ? size : 'small'}
          expandable={expandable as any}
          rowSelection={allowRowSelection ? rowSelection : undefined}
          className={className}
          pagination={false}
          onChange={handleTableChange}
          scroll={{ x: true, y: data?.length > 0 ? scroll?.y : undefined }}
          rowKey="id"
        />
        {hasPagination && (
          <Row justify="end">
            <Pagination
              {...PAGINATION_CONFIG}
              className="margin-top-2 margin-bottom-2"
              defaultPageSize={paginationMeta.itemsPerPage ?? 10}
              onChange={handleChange}
              total={paginationMeta.totalItems}
              hideOnSinglePage={paginationMeta.totalItems <= 10}
              current={paginationMeta.currentPage}
              showSizeChanger={true}
              onShowSizeChange={(current, pageSize) => handleChange(1, pageSize)}
            />
          </Row>
        )}
      </div>
    </div>
  );
}

export { CrmTable };
