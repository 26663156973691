import { Col, Dropdown, Menu, Row, Space, Tag, Typography } from 'antd';
import { IBranchTitleMenu, ITableTitleCard } from '@shared-components/models';
import {
  DeleteOutlined,
  EditOutlined,
  EyeOutlined,
  MoreOutlined,
  WarningOutlined,
} from '@ant-design/icons';
import {
  AM_PERMISSIONS,
  DELETE_BTN,
  EDIT_BTN,
  MAIN_BRANCH,
  SET_MAIN_BRANCH,
  URL_OFFICE,
  VIEW_BTN,
} from '@moxie/constants';
import { Button } from '@moxie/shared';
import React from 'react';
import { Link } from 'react-router-dom';
import { useAppSelector } from '@admin/core';
import permit from '@lyra/permit';

const { BRANCHES } = AM_PERMISSIONS
const { Text } = Typography;

const branchTitleMenu = ({
  data,
  deleteData,
  showDrawer,
  showView,
  setMainBranch,
  name,
  rolePerms
}: IBranchTitleMenu) => {
  return (
    <Menu>
      <>
        {permit([BRANCHES.MANAGE, BRANCHES.READ_ALL], rolePerms) && showView && (
          <Menu.Item key={1}>
            <Space direction="horizontal">
              <Link to={`${URL_OFFICE}/detail/${data.id}/departments`}>
                <EyeOutlined /> {VIEW_BTN}
              </Link>
            </Space>
          </Menu.Item>
        )}
      </>
      {
        permit([BRANCHES.MANAGE], rolePerms) &&
        (<Menu.Item key={2} onClick={showDrawer}>
          <Space direction="horizontal">
            <EditOutlined />
            {EDIT_BTN}
          </Space>
        </Menu.Item>)
      }
      {permit([BRANCHES.MANAGE], rolePerms) && !data?.is_main && (
        <Menu.Item key={3} danger onClick={deleteData}>
          <Space direction="horizontal">
            <DeleteOutlined />
            {DELETE_BTN}
          </Space>
        </Menu.Item>
      )}
      {permit([BRANCHES.MANAGE], rolePerms) && !data?.is_main && name !== 'role' && (
        <Menu.Item key={4} danger onClick={setMainBranch}>
          <Space direction="horizontal">
            <WarningOutlined />
            {SET_MAIN_BRANCH}
          </Space>
        </Menu.Item>
      )}
    </Menu>
  );
};

export const TableBranchNameCard: React.FC<ITableTitleCard> = ({
  data,
  deleteData,
  showDrawer,
  showView = true,
  setMainBranch,
  name,
}: ITableTitleCard) => {
  const rolePerms: string[] = useAppSelector(
    (state: any) => state?.auth?.user?.am_role?.permissions
  );
  const handleDelete = () => {
    deleteData(data.id);
  };

  const handleDrawer = () => {
    showDrawer(data.id);
  };
  const handleMainBranch = () => {
    setMainBranch(data?.id);
  };

  const menuActions = {
    data: data,
    deleteData: handleDelete,
    showDrawer: handleDrawer,
    showView: showView,
    setMainBranch: handleMainBranch,
    name,
    rolePerms
  };
  return (
    <Row>
      <Col span={18} className="align-items-center">
        {showView ? (
          <Col span={16}>
            <Link to={`${URL_OFFICE}/detail/${data.id}/departments`}>
              <Text className="text-primary-color email-ellipsis initial_capital">
                {data.name}
              </Text>
            </Link>
          </Col>
        ) : (
          <Col span={16}>
            <Text className="text-primary-color email-ellipsis initial_capital">
              {data.name}
            </Text>
          </Col>
        )}
        {data?.is_main && (
          <Col span={8}>
            <Tag color="processing" className="tag-processing">
              {MAIN_BRANCH}
            </Tag>
          </Col>
        )}
      </Col>
      <Col span={3}>
        <Dropdown overlay={branchTitleMenu(menuActions)}>
          <Button
            type="text"
            className="table-cell-action-btn"
            icon={<MoreOutlined />}
          />
        </Dropdown>
      </Col>
    </Row>
  );
};
