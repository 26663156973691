/* eslint-disable no-unused-vars */
import React, { useContext, useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { useHistory, useParams } from 'react-router-dom';
import { leadActions, useAppSelector } from '@lyra/core';
import { getAllLead } from '@lyra/services.api';
import {
  DeleteOutlined,
  MailFilled,
  RollbackOutlined,
  UserSwitchOutlined,
} from '@ant-design/icons';
import {
  Button,
  PageHeaderMenu,
  PageHeaderTitle,
  popConfirm,
  Table,
} from '@shared-components/elements';
import {
  ARCHIVE,
  CONFIRM_MESSAGE,
  CONFIRM_TITLE,
  CONTACT,
  DELETE_BTN,
  PAGE_HEADER_MENU_PROSPECT,
  RESTORE,
  SLUG_ALL_PROSPECTS,
  SLUG_ARCHIVED,
  TEXT,
} from '@moxie/constants';
import { LeadAddEdit } from '../../lead-add-edit';
import Can, { PermissionAction, PermissionObjects } from '@lyra/privilege';
import { LeadContext } from '../../lead';
import { getColumns } from '../../contacts/libs/lead-columns';
const Prospect: React.FC = () => {
  const [
    initialData,
    _setInitialData,
    isOpen,
    setOpen,
    disable,
    setDisable,
    refreshTable,
    triggerRefresh,
  ] = useContext(LeadContext);
  const [selectedId, setSelectedId] = useState<string[]>([]);
  const [isSubmitted, setIsSubmitted] = useState<boolean>(false);
  const { name } = useParams<{ name: string }>();
  const [filter, setFilter] = useState({ selectBy: name });
  const dispatch = useDispatch();
  const history = useHistory();
  const { assignee } = useAppSelector((store) => ({
    assignee: store.users.allData,
  }));
  const tableLoading = useAppSelector((store) => store.lead.loading);

  if (useAppSelector((item) => item.lead.redirect)) {
    dispatch(leadActions.clearLeads());
  }

  useEffect(() => {
    if (filter.selectBy !== name) setFilter({ selectBy: name });
  }, [name]);

  useEffect(() => {
    triggerRefresh();
  }, [assignee]);

  const getData = async (str: string) => {
    return await getAllLead(str);
  };

  const onClose = () => {
    setOpen(false);
    setDisable && setDisable(true);
  };

  const handleSelectedId = (ids: string[]) => {
    setSelectedId(ids);
  };

  const handleMultipleArchive = () => {
    dispatch(leadActions.archiveMultipleLeadAction(selectedId));
    triggerRefresh();
  };

  const handleMultipleRestore = () => {
    dispatch(leadActions.restoreMultipleLeadAction(selectedId));
    triggerRefresh();
  };

  const handleMultipleDelete = () => {
    dispatch(leadActions.deleteMultipleLeadAction(selectedId));
    triggerRefresh();
  };

  const handleAfterLeadUpdate = () => {
    dispatch(leadActions.clearLeads());
    history.push(`/prospects/${SLUG_ALL_PROSPECTS}`);
    triggerRefresh();
  };
  return (
    <div className="full-height">
      <PageHeaderTitle title={TEXT.PROSPECTS} />
      <PageHeaderMenu menu={PAGE_HEADER_MENU_PROSPECT} defaultActiveKey={name}>
        {/* {Can(PermissionAction.Update, PermissionObjects.LeadsEntity) && ( */}
          <LeadAddEdit
            isOpen={isOpen}
            onAfterSuccess={handleAfterLeadUpdate}
            onClose={onClose}
            initialData={initialData}
            triggerRefresh={triggerRefresh}
            setDisabled={setDisable}
            disabled={disable}
            submitted={isSubmitted}
            setSubmitted={setIsSubmitted}
          />
        {/* )} */}
      </PageHeaderMenu>

      <div className="bg-white padding-top-2">
        <Table
          tableLoading={tableLoading}
          filterOptions={true}
          tableName={TEXT.CONTACTS}
          refresh={refreshTable}
          size={'small'}
          columns={getColumns(TEXT.PROSPECTS, name)}
          onChangeSelected={handleSelectedId}
          action={getData}
          filter={filter}
          className="lead-table"
        >
          <Button type="primary" icon={<MailFilled />}>
            {CONTACT}
          </Button>
          {name !== SLUG_ARCHIVED ? (
            <>
              {/* {Can(PermissionAction.Update, PermissionObjects.LeadsEntity) && ( */}
                <Button
                  type="primary"
                  icon={<UserSwitchOutlined />}
                  onClick={() =>
                    popConfirm(
                      CONFIRM_TITLE.ARCHIVE_MULTIPLE_LEADS,
                      `${CONFIRM_MESSAGE.ARCHIVE_MULTIPLE_LEADS} ${selectedId.length} leads?`,
                      handleMultipleArchive
                    )
                  }
                >
                  {ARCHIVE}
                </Button>
              {/* )} */}
            </>
          ) : (
            <>
              {/* {Can(PermissionAction.Update, PermissionObjects.LeadsEntity) && ( */}
                <Button
                  type="primary"
                  icon={<RollbackOutlined />}
                  onClick={() =>
                    popConfirm(
                      CONFIRM_TITLE.RESTORE_MULTIPLE_LEADS,
                      `${CONFIRM_MESSAGE.RESTORE_MULTIPLE_LEADS} ${selectedId.length} leads?`,
                      handleMultipleRestore
                    )
                  }
                >
                  {RESTORE}
                </Button>
              {/* )} */}
              <Button
                className="margin-left-1"
                type="primary"
                title={DELETE_BTN}
                onClick={() =>
                  popConfirm(
                    CONFIRM_TITLE.DELETE_MULTIPLE_LEADS,
                    `${CONFIRM_MESSAGE.DELETE_MULTIPLE_LEADS} ${selectedId.length} leads`,
                    handleMultipleDelete
                  )
                }
                danger
                icon={<DeleteOutlined />}
              >
                {DELETE_BTN}
              </Button>
            </>
          )}
        </Table>
      </div>
    </div>
  );
};

export { Prospect };
