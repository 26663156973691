import React from "react";
import { Avatar, Card, Divider, Empty, Spin, Tag, Timeline } from "antd";
import InfiniteScroll from "react-infinite-scroll-component";
import { TEXT } from "@moxie/constants";
import { EnquiryActivity } from "@model/contact-form";
import { ICrmEnquiryData } from "@model/index";
import { EnquiryActivityPanels } from "./enquiry-activities/EnquiryActivity";

interface EnquiryViewFormProp {
  enquiryData: ICrmEnquiryData;
  activities: EnquiryActivity[];
  isLoading: boolean;
  hasMore: boolean;
  next: () => void;
}

const groupActivitiesByMonth = (activities: EnquiryActivity[]) => {
  return activities.reduce((acc: Record<string, EnquiryActivity[]>, activity: EnquiryActivity) => {
    const month = new Date(activity.createdAt).toLocaleString('default', { month: 'long' });
    if (!acc[month]) {
      acc[month] = [];
    }
    acc[month].push(activity);
    return acc;
  }, {});
};

const EnquiryActivityView = ({ enquiryData, activities, isLoading, hasMore, next }: EnquiryViewFormProp) => {
  const groupedActivities = groupActivitiesByMonth(activities);
  return (
    <>
      <Card style={{ width: '100%' }}>
        <div style={{ display: 'flex', alignItems: 'center' }}>
          <Avatar style={{ marginRight: 16 }}>
            {enquiryData.personalInformation.firstName?.substring(0, 1).toUpperCase()}
            {enquiryData.personalInformation.lastName?.substring(0, 1).toUpperCase()}
          </Avatar>
          <div>
            <div style={{ display: 'flex', alignItems: 'center' }}>
              <span style={{ fontWeight: '900', fontSize: '1.2rem' }} className="initial_capital">{enquiryData.personalInformation.firstName}  {enquiryData.personalInformation.lastName}</span>
              <Tag color="red" style={{ marginLeft: 8, backgroundColor: '#FAFAFA' }}>{enquiryData.branch?.name ?? 'Unassigned'}</Tag>
            </div>
            <div style={{ marginTop: 2, color: '#8c8c8c' }}>{enquiryData.personalInformation.email}</div>
          </div>
        </div>
      </Card >
      <div id="scrollableDiv">
        < InfiniteScroll
          height={650}
          hasMore={hasMore}
          next={() => next()}
          dataLength={activities?.length}
          loader={isLoading ? <Spin /> : null}
          scrollableTarget="scrollableDiv"
          endMessage={
            < Divider plain > You are all caught up</Divider >

          }
        >
          {!activities || activities.length === 0 ? (
            <Empty
              image={Empty.PRESENTED_IMAGE_SIMPLE}
              description={TEXT.NO_DATA}
            />
          ) : (
            <>
              <Timeline className="margin-top-1">
                {Object.entries(groupedActivities).map(([month, activities]) => (
                  <React.Fragment key={month}>
                    <div className="deal-date padding-bottom-2">
                      {month}
                    </div>
                    {activities.map((item) => (
                      <Timeline.Item key={item.id} >
                        <EnquiryActivityPanels
                          key={item.id}
                          activitiesAction={item.activitiesAction}
                          assignedUser={item.user}
                          createdAt={item.createdAt}
                          data={item.data}
                        />
                      </Timeline.Item>
                    ))}
                  </React.Fragment>
                ))}
              </Timeline>
            </>
          )}
        </InfiniteScroll >
      </div>
    </>
  )
}

export { EnquiryActivityView };
