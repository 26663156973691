import axios from './axios';

const validateCompanyDomain = (domain: string): Promise<boolean> => {
  return axios.post('company/check/domain/', { company_domain: domain });
};

const validateCompanyCode = ({
  code,
  id,
}: {
  code: string;
  id?: string;
}): Promise<boolean> => {
  return axios.post('company/check/code/', { company_code: code, id });
};
export { validateCompanyDomain, validateCompanyCode };
