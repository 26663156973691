import React from 'react';
import { Col, Form, Row } from 'antd';
import { FormItem, Select, Option } from '@shared-components/elements';
import Title from 'antd/lib/typography/Title';
import {
  COUNTRIES_OPERATED_LABEL,
  FEATURES,
  FORM_SIZE,
  GUTTER,
  NO_OF_DESTINATION_COUNTRIES_LABEL,
  NO_OF_EMPLOYEES_LABEL,
  NO_OF_ESTIMATES_LABEL,
  SELECT_FEATURE_LABEL,
  WOS_COUNTRIES_OPERATED_PLACEHOLDER,
  WOS_FEATURE_PLACEHOLDER,
  WOS_NO_OF_EMPLOYEES_PLACEHOLDER,
  COUNTRIES,
  NUMBER_OF_EMPLOYEES,
  NUMBER_OF_DESTINATION_COUNTRIES,
  NUMBER_OF_ESTIMATES,
  WOS_NO_OF_DESTINATION_COUNTRIES_PLACEHOLDER,
  WOS_NO_OF_ESTIMATED_APPLICATIONS_PLACEHOLDER,
} from '@moxie/constants';
import { IForm, IWorkOrderScope } from '@shared-components/models';
import { FieldData } from 'rc-field-form/es/interface.js';

const WorkOrderScopeDetailsForm: React.FC<IForm<IWorkOrderScope>> = ({
  onSubmit,
  form,
  isValid,
  setDisabled,
}: IForm<IWorkOrderScope>) => {
  const handleSubmit = (data: IWorkOrderScope) => {
    onSubmit && onSubmit(data);
  };

  const handleChange = (_data: FieldData[], data: FieldData[]) => {
    const backButtonError: boolean[] = [];
    form.getFieldsError().map((item) => {
      if (item.errors.length > 0) {
        backButtonError.push(true);
      } else {
        backButtonError.push(false);
      }

      if (backButtonError?.includes(true)) {
        setDisabled(true);
      } else {
        setDisabled(false);
      }
    });
    let isFieldsTouched = false;
    isFieldsTouched = form.isFieldsTouched(true);
    let error = false;
    let valueIsEmpty = false;

    data.forEach((item: any) => {
      if (!item?.value || item?.value?.length === 0) {
        valueIsEmpty = true;
      }
    });
    isFieldsTouched = isFieldsTouched || !valueIsEmpty;
    form.getFieldsError().forEach((item) => {
      if (item.errors.length > 0) {
        error = true;
      }
    });
    isValid && isValid(!error && isFieldsTouched);
  };
  const handleCountryFilter = (input: string, option: any): boolean =>
    option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0;

  return (
    <>
      <Title level={4}>Work Order Scope</Title>
      <Form
        name="work-order-scope-form"
        data-tesid="work-order-scope-form"
        layout="vertical"
        size={FORM_SIZE}
        form={form}
        onFinish={handleSubmit}
        onFieldsChange={handleChange}
      >
        <div>
          <FormItem
            id="feature"
            name="feature"
            label={SELECT_FEATURE_LABEL}
            rules={[{ required: true, type: 'string' }]}
          >
            <Select
              placeholder={WOS_FEATURE_PLACEHOLDER}
              style={{ opacity: 100 }}
            >
              {FEATURES.map((item) => (
                <Option key={item.value} value={item.value}>
                  {item.name}
                </Option>
              ))}
            </Select>
          </FormItem>
          <Row gutter={GUTTER}>
            <Col span="12">
              <FormItem
                shouldUpdate
                id="employee"
                name="number_of_employees"
                label={NO_OF_EMPLOYEES_LABEL}
                rules={[{ required: true }]}
              >
                <Select
                  placeholder={WOS_NO_OF_EMPLOYEES_PLACEHOLDER}
                  showSearch
                >
                  {NUMBER_OF_EMPLOYEES.map((item) => (
                    <Option key={item.key} value={item.name}>
                      {item.name}
                    </Option>
                  ))}
                </Select>
              </FormItem>
            </Col>
            <Col span="12">
              <FormItem
                id="destination"
                name="number_of_destination_countries"
                label={NO_OF_DESTINATION_COUNTRIES_LABEL}
                rules={[{ required: true }]}
              >
                <Select
                  placeholder={WOS_NO_OF_DESTINATION_COUNTRIES_PLACEHOLDER}
                  showSearch
                >
                  {NUMBER_OF_DESTINATION_COUNTRIES.map((item) => (
                    <Option key={item.value} value={item.name}>
                      {item.name}
                    </Option>
                  ))}
                </Select>
              </FormItem>
            </Col>
          </Row>
          <FormItem
            id="operation"
            name="countries_operated"
            label={COUNTRIES_OPERATED_LABEL}
            rules={[{ required: true }]}
          >
            <Select
              placeholder={WOS_COUNTRIES_OPERATED_PLACEHOLDER}
              mode="multiple"
              showSearch
              filterOption={handleCountryFilter}
            >
              {COUNTRIES.map((country) => (
                <Option value={country.code} key={country.code}>
                  {country.name}
                </Option>
              ))}
            </Select>
          </FormItem>
          <FormItem
            name="number_of_estimates"
            label={NO_OF_ESTIMATES_LABEL}
            rules={[{ required: true }]}
          >
            <Select
              placeholder={WOS_NO_OF_ESTIMATED_APPLICATIONS_PLACEHOLDER}
              showSearch
            >
              {NUMBER_OF_ESTIMATES.map((item) => (
                <Option key={item.key} value={item.name}>
                  {item.name}
                </Option>
              ))}
            </Select>
          </FormItem>
        </div>
      </Form>
    </>
  );
};

export { WorkOrderScopeDetailsForm };
