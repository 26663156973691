/* eslint-disable react-hooks/rules-of-hooks */
import React, { ReactNode } from 'react';
import { Link } from 'react-router-dom';
import moment from 'moment';
import { Col, Dropdown, Menu, Row, Space, Typography } from 'antd';
import {
  DeleteOutlined,
  EyeOutlined,
  FormOutlined,
  MoreOutlined,
  PlusOutlined,
} from '@ant-design/icons';
import {
  ITableProps,
  IWorkflow,
  IWorkflowType,
} from '@shared-components/models';
import {
  DELETE_BTN,
  VIEW_BTN,
  LABEL,
  TEXT,
  URL_WORKFLOWS,
  ADD_WORKFLOW,
  URL_WORKFLOW_TYPES,
  MANAGE_WORKFLOW_TYPES,
} from '@moxie/constants';
import { Button, Table } from '@moxie/shared';
import { useQuery } from '@moxie/utils';

const WorkflowTypeTitleMenu = ({ data, deleteData, showDrawer }: any) => {
  return (
    <Menu>
      <Menu.Item key={1} onClick={() => showDrawer(data.id)}>
        <Space direction="horizontal">
          <EyeOutlined />
          {VIEW_BTN}
        </Space>
      </Menu.Item>
      <Menu.Item key={2} danger onClick={deleteData}>
        <Space direction="horizontal">
          <DeleteOutlined />
          {DELETE_BTN}
        </Space>
      </Menu.Item>
    </Menu>
  );
};

export const columns = (props: ITableProps) => {
  const { deleteData, showDrawer } = props;
  return [
    {
      title: TEXT.SN,
      key: 'id',
      dataIndex: 'id',
      width: 50,
      render: (_data: string, _row: IWorkflow, index: number): ReactNode => {
        const pageNumber = useQuery().get('pageNo') || 1;
        const limit = useQuery().get('limit') || 10;
        return (pageNumber - 1) * limit + index + 1;
      },
    },
    {
      title: LABEL.WORKFLOW,
      width: 200,
      sorter: () => 0,
      render(data: IWorkflow) {
        const handleDelete = () => {
          if (data.id != null) {
            deleteData?.(data.id);
          }
        };

        const menuActions = {
          data: data,
          deleteData: handleDelete,
          showDrawer: showDrawer,
        };
        return (
          <Row>
            <Col span={18} className="align-items-center">
              <Link to={`${URL_WORKFLOWS}/view/${data?.id}`}>
                <Typography.Text className="text-primary-color">
                  {`${data?.workflowType?.name} (${data?.country})`}
                </Typography.Text>
              </Link>
            </Col>
            <Col span={3}>
              <Dropdown overlay={WorkflowTypeTitleMenu(menuActions)}>
                <Button
                  type="text"
                  className="table-cell-action-btn"
                  icon={<MoreOutlined />}
                />
              </Dropdown>
            </Col>
          </Row>
        );
      },
    },

    {
      title: LABEL.SERVICE,
      width: 300,
      dataIndex: 'workflowType',
      render(workflowType: IWorkflowType) {
        return workflowType?.service?.name;
      },
    },
    {
      title: LABEL.COUNTRY,
      width: 300,
      dataIndex: 'country',
      render(country: string) {
        return country;
      },
    },
    {
      title: TEXT.ADDED_ON,
      width: 200,
      dataIndex: 'created_at',
      render(date: string) {
        return moment(date).format('MMM DD YYYY');
      },
    },
  ];
};

const WorkflowTable = ({
  getData,
  tableName,
  refreshTable,
  deleteData,
  showDrawer,
  filter,
}: ITableProps) => {
  const columnActions = {
    deleteData: deleteData,
    showDrawer: showDrawer,
  };
  return (
    <div className="bg-white padding-top-2">
      <Table
        filter={filter}
        action={getData}
        tableName={tableName}
        columns={columns(columnActions)}
        refresh={refreshTable}
        filterOptions={true}
        tableButtons={
          <Space>
            <Link to={URL_WORKFLOW_TYPES}>
              <Button type="ghost" icon={<FormOutlined />}>
                {MANAGE_WORKFLOW_TYPES}
              </Button>
            </Link>

            <Button
              type="primary"
              typeof="button"
              onClick={() => showDrawer && showDrawer()}
            >
              <PlusOutlined />
              {ADD_WORKFLOW}
            </Button>
          </Space>
        }
      />
    </div>
  );
};

export { WorkflowTable };
