import { IProductFilterContext, ProductFilter } from "@model/product";
import React, { createContext, useEffect, useState } from "react";
import { useHistory, useLocation } from "react-router-dom";
import * as qs from 'qss';
import { countryServiceActions, serviceActions, useAppSelector } from "@crm/core";
import { useDispatch } from "react-redux";
import { ICrmService } from "@model/crm/service.model";
import { isValidUuid } from "@crm/libs/helpers";

export const ProductFilterContext = createContext<IProductFilterContext>({
  setProductSubTypes: () => [],
  subjectArea: [],
  setSubjectArea: () => [],
  productSubTypes: [],
  filter: {},
  setFilter: () => ({}),
  countriesLoading: false,
  handleServiceChange: (val: string) => { return },
  selectedService: undefined,
  country: undefined,
  countries: [],
  setSelectedService: (val: string) => { return },
  selectedCountry: undefined,
  searchInput: undefined,
  onSearch: (val: string | undefined) => { return },
  search: undefined,
  onSearchChange: (val: string | undefined) => { return },
  onCountryChange: (val: string | undefined) => { return },
  resetFilters: (resetServiceAndCountry?: boolean) => { return },
  setSelectedCountry: (val: string | undefined) => { return },
  currentPage: 1,
  setCurrentPage: () => { return },
  institutions: [],
  onInstitutionChange: () => { return }
});

interface Query {
  'filter.subjectArea'?: string | string[];
  'filter.productSubType'?: string | string[];
  service?: string;
  country?: string;
  s?: string;
}
interface SelectedFilter {
  service: string | undefined;
  country: string | undefined;
  institutions: string[];
}
const ProductFilterProvider: React.FC = ({ children }) => {
  const location = useLocation();
  const history = useHistory();
  const dispatch = useDispatch();

  const [currentPage, setCurrentPage] = useState(1);
  const [filter, setFilter] = useState<ProductFilter>({});
  const [country, setCountry] = useState<string | undefined>();
  const [subjectArea, setSubjectArea] = useState<string[]>([]);
  const [productSubTypes, setProductSubTypes] = useState<string[]>([]);
  const [search, setSearch] = useState<undefined | string>(undefined);
  const [searchInput, setSearchInput] = useState<string | undefined>();
  const [countries, setCountries] = useState<string[]>([])
  const [initialFilter, setInitialFilter] = useState<Query>({});
  const [selectedFilter, setSelectedFilter] = useState<SelectedFilter>({
    service: undefined,
    country: undefined,
    institutions: []
  });

  const services = useAppSelector((state) => state.services.allData);
  const { loading: countriesLoading } = useAppSelector(
    (state) => state.country_by_service
  );

  const selectedCountry = selectedFilter.country;
  const setSelectedCountry = (country: string | undefined) => setSelectedFilter(filter => ({ ...filter, country }));
  const selectedService = selectedFilter.service;
  const setSelectedService = (service: string | undefined) => setSelectedFilter(filter => ({ ...filter, service }));
  const edService = services.find((service: ICrmService) => service.name === 'Education')

  const onInstitutionChange = (institutions: string[]) => {
    const filteredInstitutions = institutions.filter(isValidUuid)
    setSelectedFilter((filter) => ({ ...filter, institutions: filteredInstitutions }));
    setCurrentPage(1);
  }

  useEffect(() => {
    dispatch(serviceActions.getProductServicesRequest());
    const queryString = Object.fromEntries(new URLSearchParams(location.search.replace(/[?]/g, '')));
    setInitialFilter(queryString);
    const val: ProductFilter = {}
    if (Object.values(queryString).length) {

      if (typeof queryString['filter.subjectArea'] === 'string') {
        val.subjectArea = [queryString['filter.subjectArea']];
      }
      else val.subjectArea = queryString['filter.subjectArea'];

      if (typeof queryString['filter.productSubType'] === 'string') {
        val.productSubTypes = queryString['filter.productSubType'].replace('$in:', '').split(',')
      }
      setFilter(val)

      if (val.subjectArea?.length) {
        setSubjectArea(val.subjectArea);
      }
      if (val.productSubTypes?.length) {
        setProductSubTypes(val.productSubTypes)
      }

      if (queryString.s) {
        setSearchInput(queryString.s);
        setSearch(queryString.s);
      }

      if (queryString.service) {
        setSelectedService(queryString.service)
      }
      if (queryString.country) {
        setSelectedCountry(queryString.country);
      }
    }
  }, []);


  useEffect(() => {
    if (services.length > 0 && !selectedService) {
      setSelectedService(edService?.id);
    }
  }, [services]);

  useEffect(() => {
    const filterQuery = {};
    if (filter.productSubTypes && filter.productSubTypes.length > 0) {
      Object.assign(filterQuery, {
        'filter.productSubType': '$in:' + filter.productSubTypes.join(','),
      })
      setCurrentPage(1);
    }
    if (filter.subjectArea && filter.subjectArea.length > 0) {
      Object.assign(filterQuery, {
        'filter.subjectArea': filter.subjectArea.join(','),
      })
      setCurrentPage(1);
    }

    const query = qs.encode({
      country: selectedCountry,
      service: selectedService,
      search: search,
      ...filterQuery
    });
    history.replace(location.pathname + '?' + query)

  }, [filter, selectedCountry, selectedService, search, filter])


  useEffect(() => {
    setSelectedCountry(undefined);
    onInstitutionChange([]);
    if (selectedService) {
      dispatch(
        countryServiceActions.getCountryByServiceRequest(selectedService, (countries) => {
          setCountries(countries);
          if (countries.length) {
            const name = countries[0];
            setSelectedCountry(selectedService === initialFilter?.service && initialFilter?.country !== name ? initialFilter?.country ?? name : name);
          } else {
            setSelectedCountry(undefined)
          }
        })
      );
    }
  }, [selectedService, initialFilter]);

  const handleServiceChange = (value: string) => {
    setSelectedService(value);
    resetFilters();
    if (countries.length > 0) {
      setCountry(countries[0]);
    } else setCountry(undefined);
  };

  const onCountryChange = (val: string | undefined) => {
    setSelectedCountry(val);
    resetFilters();
  }

  const resetFilters = (resetServiceAndCountry?: boolean) => {
    setSubjectArea([]);
    setProductSubTypes([]);
    setFilter({});
    setSearch(undefined);
    setSearchInput(undefined);
    if (resetServiceAndCountry) {
      setSelectedService(edService?.id);
      setSelectedCountry(countries.find((country: string) => country === 'Australia'));
    }
  }

  return (
    <ProductFilterContext.Provider value={{
      subjectArea,
      setSubjectArea,
      filter,
      setFilter,
      productSubTypes,
      setProductSubTypes,
      countriesLoading,
      handleServiceChange,
      country,
      selectedService,
      countries,
      setSelectedService,
      selectedCountry,
      searchInput,
      search,
      onSearch: (val: string | undefined) => {
        setSearch(val);
        setCurrentPage(1);
      },
      onSearchChange: (val: string | undefined) => setSearchInput(val),
      onCountryChange,
      resetFilters,
      setSelectedCountry,
      currentPage,
      setCurrentPage,
      institutions: selectedFilter.institutions,
      onInstitutionChange
    }}>
      {children}
    </ProductFilterContext.Provider>
  )

}

export default ProductFilterProvider


