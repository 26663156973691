import { productsActions } from '@admin/core';
import {
  ADD_LANGUAGE_TEST_SCORE,
  CANCEL_BTN,
  DRAWER_WIDTH,
  EDIT_LANGUAGE_TEST_SCORE,
  SAVE_BTN,
  UPDATE_BTN,
} from '@moxie/constants';
import { DrawerElem } from '@shared-components/elements';
import { ILanguageReqDrawer } from '@shared-components/models';
import { Button, Space } from 'antd';
import React, { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { LanguageTestScoreFormAdmin } from './language-test-score-form';

const LanguageRequirementDrawer: React.FC<ILanguageReqDrawer> = ({
  onClose,
  isOpen,
  name,
  form,
  isEdit,
  initialData,
  productId,
}: ILanguageReqDrawer) => {
  const dispatch = useDispatch();
  const [buttonDisabled, setButtonDisabled] = useState(true);

  const handleSubmitButtonClick = () => {
    form.submit();
  };

  const onFieldsChange = (allFields: any) => {
    if (allFields) {
      setButtonDisabled(false);
    }
  };

  const handleSubmit = async (data: any) => {
    if (initialData?.id) {
      data.product_id = productId;
      dispatch(
        productsActions.updateProductLanguageRequest(initialData?.id, data)
      );
    } else {
      data.product_id = productId;
      dispatch(productsActions.addProductLanguageRequest(data));
    }
    setButtonDisabled(true);
    onClose();
  };

  useEffect(() => {
    if (isEdit) {
      const each_bands = initialData?.each_band?.reduce(
        (obj: any, item: any) =>
          Object.assign(obj, { [item.name]: item.score }),
        {}
      );
      form.setFieldsValue({
        ...initialData,
        each_bands: each_bands,
      });
    } else {
      form.setFieldsValue({});
    }
  }, [initialData, form, isEdit]);

  return (
    <div>
      <DrawerElem
        title={initialData ? EDIT_LANGUAGE_TEST_SCORE : ADD_LANGUAGE_TEST_SCORE}
        width={DRAWER_WIDTH}
        onClose={onClose}
        visible={isOpen}
        footer={
          <div className="padding-bottom-2 text-align-right">
            <Space direction="horizontal">
              <Button onClick={onClose} className="lead-margin-right">
                {CANCEL_BTN}
              </Button>
              <Button
                onClick={handleSubmitButtonClick}
                type="primary"
                disabled={buttonDisabled}
              >
                {initialData ? UPDATE_BTN : SAVE_BTN}
              </Button>
            </Space>
          </div>
        }
      >
        <LanguageTestScoreFormAdmin
          name={name}
          form={form}
          onSubmit={handleSubmit}
          onFieldsChange={onFieldsChange}
        />
      </DrawerElem>
    </div>
  );
};

export default LanguageRequirementDrawer;
