import {
  CheckCircleOutlined,
  CheckOutlined,
  ClockCircleOutlined,
  CloseCircleOutlined,
  CloseOutlined,
  DeleteOutlined,
  EditOutlined,
  ExclamationCircleOutlined,
  LinkOutlined,
  MoreOutlined,
  PlusOutlined,
  SettingOutlined,
} from '@ant-design/icons';
import {
  ADD_TENANT,
  COMPANY_CODE,
  COMPANY_FEATURE,
  DELETE_BTN,
  EDIT_BTN,
  LABEL,
  TEXT,
  URL_TENANT_DETAIL_LINK,
} from '@moxie/constants';
import { Table } from '@shared-components/elements';
import { ITableProps, ITenantNameCard } from '@shared-components/models';
import {
  Button,
  Col,
  Dropdown,
  Menu,
  Row,
  Space,
  Switch,
  Tag,
  Tooltip,
  Typography,
} from 'antd';
import moment from 'moment';
import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import { capitalizeFirstLetter } from '@moxie/shared';
const { Text } = Typography;

const tenantMenu = ({
  data,
  deleteData,
  showDrawer,
  manageTenantDetail,
}: any) => {
  return (
    <Menu>
      <Menu.Item key={1} onClick={() => showDrawer(data?.id)}>
        <Space direction="horizontal">
          <EditOutlined />
          {EDIT_BTN}
        </Space>
      </Menu.Item>
      <Menu.Item key={2} onClick={() => manageTenantDetail(data?.id)}>
        <Space direction="horizontal">
          <SettingOutlined />
          {TEXT.MANAGE_DETAILS}
        </Space>
      </Menu.Item>
      <Menu.Item key={3} onClick={() => deleteData(data?.id)} danger>
        <Space direction="horizontal">
          <DeleteOutlined />
          {DELETE_BTN}
        </Space>
      </Menu.Item>
    </Menu>
  );
};

export const TenantNameCard: React.FC<ITenantNameCard> = ({
  id,
  name,
  menuActions,
}: ITenantNameCard) => {
  return (
    <Row justify="space-between">
      <Col span={20} className="margin-top-1">
        <Link to={`${URL_TENANT_DETAIL_LINK}${id}`}>
          <Tooltip overlay={name}>
            <Text className="text-primary-color initial_capital tenant-name-ellipsis">
              {name}
            </Text>
          </Tooltip>
        </Link>
      </Col>
      <Col span={4}>
        <Dropdown overlay={tenantMenu(menuActions)}>
          <Button
            type="text"
            className="table-cell-action-btn"
            icon={<MoreOutlined />}
          />
        </Dropdown>
      </Col>
    </Row>
  );
};

export const columns = (
  triggerRefresh: any,
  props: {
    deleteData: any;
    showDrawer: any;
    changeCompanyStatus: any;
    manageTenantDetail: any;
  }
) => {
  return [
    {
      title: LABEL.COMPANY_NAME,
      dataIndex: 'company_name',
      sorter: () => 0,
      key: 'company_name',
      width: 200,
      render(company_name: string, data: any) {
        const { deleteData, showDrawer, manageTenantDetail } = props;
        const handleDelete = () => {
          if (data.id != null) {
            deleteData?.(data.id);
          }
        };
        const menuActions = {
          data: data,
          deleteData: handleDelete,
          showDrawer: showDrawer,
          manageTenantDetail: manageTenantDetail,
        };
        return (
          <TenantNameCard
            id={data?.id}
            name={company_name}
            menuActions={menuActions}
          />
        );
      },
    },
    {
      title: COMPANY_FEATURE,
      dataIndex: 'feature',
      key: 'feature',
      render(feature: string) {
        return <Text> {feature === 'crm' ? 'CRM' : 'AM'}</Text>;
      },
    },
    {
      title: COMPANY_CODE,
      dataIndex: 'company_code',
      key: 'company_code',
      render(company_code: string) {
        return <Text> {company_code?.toUpperCase()}</Text>;
      },
    },
    {
      title: LABEL.DOMAIN_NAME,
      dataIndex: 'company_domain',
      key: 'company_domain',
      render(company_domain: string) {
        return (
          <a
            href={`https://${company_domain}`}
            target="_blank"
            rel="noopener noreferrer"
          >
            {company_domain}
          </a>
        );
      },
    },
    {
      title: TEXT.EMAIL,
      dataIndex: 'company_email',
      key: 'company_email',
      render(company_email: string) {
        const [isCopied, setIsCopied] = useState(false);
        const copyToClipboard = (text: string) => {
          navigator.clipboard.writeText(text);
          setIsCopied(true);
        };
        return (
          <Row className="email_div">
            <Col span={20}>
              <a
                className="text-primary-color"
                href={`mailto:${company_email}`}
              >
                {company_email?.toLowerCase()}
              </a>
            </Col>
            <Col className="hide" span={4}>
              <Tooltip
                className=" margin-left-1 "
                placement="right"
                color={isCopied ? '#8EAF74' : '#9B9D9A '}
                title={
                  isCopied ? `${TEXT.COPIED_SUCCESS}` : `${TEXT.COPY_EMAIL}`
                }
              >
                <LinkOutlined
                  className="copy_email_icon"
                  onClick={() => copyToClipboard(`${company_email}`)}
                />
              </Tooltip>
            </Col>
          </Row>
        );
      },
    },
    {
      title: LABEL.PHONE_NUMBER,
      dataIndex: 'company_phone',
      key: 'company_phone',
      render(company_phone: string) {
        return <Text> {company_phone}</Text>;
      },
    },
    {
      title: LABEL.EXPIRATION_DATE,
      dataIndex: 'expiration_date',
      key: 'expiration_date',
      render(expiration_date: Date, data: any) {
        return <Text>{moment(expiration_date).format('LL')}</Text>;
      },
    },
    {
      title: LABEL.STATUS,
      dataIndex: 'status',
      key: 'status',
      render(status: string) {
        return <Text className="initial_capital">{statusTags(status)}</Text>;
      },
    },
    {
      title: LABEL.ACTIONS,
      dataIndex: 'actions',
      key: 'actions',
      width: 200,
      render(name: any, data: any) {
        return <>{actionButtons(props, data, data?.status)}</>;
      },
    },
  ];
};

const statusTags = (name: string) => {
  switch (name) {
    case 'active':
      return (
        <Tag
          icon={<CheckCircleOutlined />}
          color="success"
          className="initial_capital"
        >
          {name}
        </Tag>
      );
    case 'rejected':
      return (
        <Tag
          icon={<ExclamationCircleOutlined />}
          color="warning"
          className="initial_capital"
        >
          {name}
        </Tag>
      );
    case 'disabled':
      return (
        <Tag
          icon={<CloseCircleOutlined />}
          color="error"
          className="initial_capital"
        >
          {name}
        </Tag>
      );
    case 'pending':
      return (
        <Tag
          icon={<ClockCircleOutlined />}
          color="processing"
          className="initial_capital tag-processing"
        >
          {name}
        </Tag>
      );

    default:
      return;
  }
};

const actionButtons = (props: any, data: any, name: string) => {
  switch (name) {
    case 'active':
      return (
        <Switch
          checkedChildren={<CheckOutlined />}
          unCheckedChildren={<CloseOutlined />}
          checked={true}
          onClick={() => {
            props.changeCompanyStatus(data?.id, 'disabled');
          }}
        />
      );
    case 'pending':
      return (
        <Space size="middle">
          <Button
            type="primary"
            size="small"
            onClick={() => {
              props.changeCompanyStatus(data?.id, 'approved');
            }}
          >
            {LABEL.APPROVE}
          </Button>
          <Button
            type="primary"
            size="small"
            onClick={() => {
              props.changeCompanyStatus(data?.id, 'rejected');
            }}
            danger
          >
            {LABEL.REJECT}
          </Button>
        </Space>
      );
    case 'disabled':
      return (
        <Switch
          checkedChildren={<CheckOutlined />}
          unCheckedChildren={<CloseOutlined />}
          checked={false}
          onClick={() => {
            props.changeCompanyStatus(data?.id, 'active');
          }}
        />
      );

    case 'rejected':
      return (
        <Button
          size="small"
          type="primary"
          onClick={() => {
            props.changeCompanyStatus(data?.id, 'approved');
          }}
        >
          {LABEL.APPROVE}
        </Button>
      );
    default:
      return;
  }
};

const TenantTable: React.FC<ITableProps> = ({
  getData,
  refreshTable,
  tableName,
  triggerRefresh,
  deleteData,
  showDrawer,
  loading,
  changeCompanyStatus,
  manageTenantDetail,
}: ITableProps) => {
  const [filter] = useState({});
  const columnActions = {
    deleteData: deleteData,
    showDrawer: showDrawer,
    changeCompanyStatus: changeCompanyStatus,
    manageTenantDetail: manageTenantDetail,
  };

  return (
    <div className="bg-white padding-top-2">
      <Table
        columns={columns(triggerRefresh, columnActions)}
        tableName={tableName}
        tableLoading={loading}
        filterOptions={true}
        refresh={refreshTable}
        className="interested-service-table"
        action={getData}
        filter={filter}
        tableButtons={
          <Button type="primary" onClick={() => showDrawer && showDrawer()}>
            <PlusOutlined />
            {ADD_TENANT}
          </Button>
        }
      />
    </div>
  );
};
export { TenantTable };
