import * as actionTypes from './department.constant';
import { IAction, IDepartment, IPayload } from '@shared-components/models';

export const addDepartmentRequest = (
  payload: IDepartment,
  triggerRefresh?: () => void
): IAction<IDepartment> => ({
  type: actionTypes.ADD_DEPARTMENT_REQUEST,
  payload,
  triggerRefresh,
});

export const addDepartmentSuccess = (
  payload: IDepartment
): IAction<IDepartment> => ({
  type: actionTypes.ADD_DEPARTMENT_SUCCESS,
  payload,
});

export const addDepartmentFailure = (
  payload: IPayload<IDepartment>
): IAction => ({
  type: actionTypes.ADD_DEPARTMENT_FAILURE,
  payload,
});

export const updateDepartmentRequest = (
  payload: IDepartment,
  triggerRefresh?: () => void
): IAction<IDepartment> => ({
  type: actionTypes.UPDATE_DEPARTMENT_REQUEST,
  payload,
  triggerRefresh,
});

export const updateDepartmentSuccess = (
  payload: IDepartment
): IAction<IDepartment> => ({
  type: actionTypes.UPDATE_DEPARTMENT_SUCCESS,
  payload,
});

export const updateDepartmentFailure = (
  payload: IPayload<IDepartment>
): IAction => ({
  type: actionTypes.UPDATE_DEPARTMENT_FAILURE,
  payload,
});

export const getDepartmentsRequest = (): IAction => ({
  type: actionTypes.GET_DEPARTMENTS_REQUEST,
});

export const getDepartmentsSuccess = (
  payload: IDepartment[]
): IAction<IDepartment[]> => ({
  type: actionTypes.GET_DEPARTMENTS_SUCCESS,
  payload,
});

export const getDepartmentsFailure = (
  payload: IPayload<IDepartment>
): IAction => ({
  type: actionTypes.GET_DEPARTMENTS_FAILURE,
  payload,
});

export const getDepartmentRequest = (id: string): IAction<string> => ({
  type: actionTypes.GET_DEPARTMENT_REQUEST,
  payload: id,
});

export const getDepartmentSuccess = (
  payload: IDepartment
): IAction<IDepartment> => ({
  type: actionTypes.GET_DEPARTMENT_SUCCESS,
  payload,
});

export const getDepartmentFailure = (
  payload: IPayload<IDepartment>
): IAction => ({
  type: actionTypes.GET_DEPARTMENT_FAILURE,
  payload,
});

export const deleteDepartmentRequest = (id: string): IAction<string> => ({
  type: actionTypes.DELETE_DEPARTMENT_REQUEST,
  payload: id,
});

export const deleteDepartmentSuccess = (payload: any): IAction => ({
  type: actionTypes.DELETE_DEPARTMENT_SUCCESS,
  payload,
});

export const deleteDepartmentFailure = (
  payload: IPayload<IDepartment>
): IAction => ({
  type: actionTypes.DELETE_DEPARTMENT_FAILURE,
  payload,
});

export const clearDepartmentFetch = (): IAction => ({
  type: actionTypes.CLEAR_DEPARTMENT_FETCH,
});

export const getAllBranchDepartments = (id: string): IAction<string> => ({
  type: actionTypes.GET_BRANCH_DEPARTMENTS_REQUEST,
  payload: id,
});

export const getAllBranchDepartmentsSuccess = (
  payload: any[]
): IAction<any[]> => ({
  type: actionTypes.GET_BRANCH_DEPARTMENTS_SUCCESS,
  payload,
});

export const getAllBranchDepartmentsFailure = (
  payload: IPayload<any>
): IAction => ({
  type: actionTypes.GET_BRANCH_DEPARTMENTS_FAILURE,
  payload,
});

export const clearBranchDepartmentFetch = (): IAction => ({
  type: actionTypes.CLEAR_BRANCH_DEPARTMENT_FETCH,
});
