import {
  IAction,
  IOtherTestScore,
  IOtherTestScorePayload,
} from '@shared-components/models';
import { Socket } from 'socket.io-client';
import * as actionTypes from './other-test-score.constant';

export const getOtherTestScoresRequest = (
  payload: string
): IAction<string> => ({
  type: actionTypes.GET_OTHER_TEST_SCORES_REQUEST,
  payload,
});

export const getOtherTestScoresSuccess = (
  payload: IOtherTestScore[]
): IAction<IOtherTestScore[]> => ({
  type: actionTypes.GET_OTHER_TEST_SCORES_SUCCESS,
  payload,
});

export const getOtherTestScoresFailure = (
  payload: IOtherTestScorePayload
): IAction<IOtherTestScore> => ({
  type: actionTypes.GET_OTHER_TEST_SCORES_FAILURE,
  payload,
});

export const getOtherTestScoresClear = (): IAction<IOtherTestScore> => {
  return { type: actionTypes.GET_OTHER_TEST_SCORES_CLEAR };
};

export const addOtherTestScoreRequest = (
  payload: IOtherTestScore,
  lead: any,
  socket?: Socket,
  user?: any
): IAction<IOtherTestScore> => ({
  type: actionTypes.ADD_OTHER_TEST_SCORES_REQUEST,
  payload,
  lead,
  socket,
  user,
});

export const addOtherTestScoreSuccess = (
  payload: IOtherTestScore
): IAction<IOtherTestScore> => ({
  type: actionTypes.ADD_OTHER_TEST_SCORES_SUCCESS,
  payload,
});

export const addOtherTestScoreFailure = (payload: IOtherTestScorePayload) => ({
  type: actionTypes.ADD_OTHER_TEST_SCORES_FAILURE,
  payload,
});

export const updateOtherTestScoreRequest = (
  payload: IOtherTestScore,
  lead: any,
  socket?: Socket,
  user?: any
): IAction<IOtherTestScore> => ({
  type: actionTypes.UPDATE_OTHER_TEST_SCORE_REQUEST,
  payload,
  lead,
  socket,
  user,
});

export const updateOtherTestScoreSuccess = (
  payload: IOtherTestScore
): IAction<IOtherTestScore> => ({
  type: actionTypes.UPDATE_OTHER_TEST_SCORE_SUCCESS,
  payload,
});
export const updateOtherTestScoreFailure = (
  payload: IOtherTestScorePayload
): IAction<IOtherTestScorePayload> => ({
  type: actionTypes.UPDATE_OTHER_TEST_SCORE_FAILURE,
  payload,
});
export const deleteOtherTestScoreFailure = (
  payload: IOtherTestScorePayload
): IAction<IOtherTestScore> => ({
  type: actionTypes.DELETE_OTHER_TEST_SCORE_FAILURE,
  payload,
});
export const deleteOtherTestScoreSuccess = (
  payload: IOtherTestScorePayload
): IAction<IOtherTestScorePayload> => ({
  type: actionTypes.DELETE_OTHER_TEST_SCORE_SUCCESS,
  payload,
});
export const deleteOtherTestScoreRequest = (
  payload: IOtherTestScore,
  lead: any,
  socket?: Socket,
  user?: any
): IAction<IOtherTestScore> => ({
  type: actionTypes.DELETE_OTHER_TEST_SCORE_REQUEST,
  payload,
  lead,
  socket,
  user,
});
