/* eslint-disable no-unused-vars */
import React from 'react';
import { IBranchTitleMenu, ITableTitleCard } from '@shared-components/models';
import { Col, Dropdown, Menu, Row, Space, Typography } from 'antd';
import { Link } from 'react-router-dom';
import { DELETE_BTN, EDIT_BTN, URL_OFFICE } from '@moxie/constants';
import { EditOutlined, MoreOutlined, DeleteOutlined } from '@ant-design/icons';
// import Can, { PermissionAction, PermissionObjects } from '@crm/privilege';
import { Button } from '@moxie/shared';
import { RootState } from 'apps/crm/src/core/store';
import { useSelector } from 'react-redux';
import { useCanAccess } from '@crm/libs/privilege/useCanAccess';

const { Text } = Typography;

const BranchTitleMenu: React.FC<IBranchTitleMenu> = ({
  menuItems,
}) => {
  return (
    <Menu>
      {menuItems?.map((comp, index) => (
        <React.Fragment key={index}>
          {comp}
        </React.Fragment>))}
    </Menu>
  );
};

export const TableBranchNameCard: React.FC<ITableTitleCard> = ({
  data,
  showDrawer,
  deleteData,
  showView = true,
  name,
  canAccess,
}: ITableTitleCard) => {
  const loggedUser = useSelector((state: RootState) => state?.auth?.user);

  const handleDrawer = () => {
    showDrawer(data.id);
  };
  const handleDelete = () => {
    deleteData(data.id);
  };

  const menuActions = {
    loggedUser,
    data: data,
    deleteData: handleDelete,
    showDrawer: handleDrawer,
    showView: showView,
    name,
  };

  const menuItems = [
    canAccess('branch', 'update') && (<Menu.Item key={2} onClick={handleDrawer}>
      <Space direction="horizontal">
        <EditOutlined data-testid="crm-editbranch-menu" />
        {EDIT_BTN}
      </Space>
    </Menu.Item>),

    canAccess('branch', 'delete') && <Menu.Item key={3} danger onClick={handleDelete}>
      <Space direction="horizontal">
        <DeleteOutlined data-testid="crm-deletebranch-menu" />
        {DELETE_BTN}
      </Space>
    </Menu.Item>
  ].filter(Boolean) as React.ReactElement[];

  return (
    <Row>
      <Col span={18} className="align-items-center initial_capital">
        {showView ? (
          <Col span={16}>
            <Link to={`${URL_OFFICE}/detail/${data.id}/departments`}>
              <Text className="text-primary-color email-ellipsis">
                {data.name}
              </Text>
            </Link>
          </Col>
        ) : (
          <Col span={16}>
            <Text className="text-primary-color email-ellipsis ">
              {data.name}
            </Text>
          </Col>
        )}
      </Col>
      {menuItems.length ? (
        <Col span={3}>
          <Dropdown overlay={<BranchTitleMenu {...menuActions} menuItems={menuItems} />}>
            <Button
              type="text"
              data-testid="crm-editdeletebranch-btn"
              className="table-cell-action-button"
              icon={<MoreOutlined />}
            />
          </Dropdown>
        </Col>
      ) : null}
    </Row>
  );
};
