import React, { useContext, useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import { Button, List, Space, Spin, Upload } from 'antd';
import { InboxOutlined } from '@ant-design/icons';
import {
  DrawerElem,
  errorNotificationHandler,
  FileNameComponent,
  successNotificationHandler,
} from '@moxie/shared';
import {
  AUDIO_TYPE,
  CANCEL_BTN,
  DOCUMENT_TYPE,
  DRAWER_WIDTH,
  FILE_SIZE_EXCEED,
  IMAGE_TYPE,
  MAXIMUM_NUMBER_OF_FILES,
  MAXIMUM_SIZE_OF_FILES,
  NO_FILES_SELECTED,
  PRESENTATION_TYPE,
  SAVE_BTN,
  SPREADSHEET_TYPE,
  TEXT,
  TITLE,
  VIDEO_TYPE,
} from '@moxie/constants';
import {
  IChecklistDocumentProps,
  IDocumentItem,
} from '@shared-components/models';
import {
  getLeadDocuments,
  saveChecklistDocuments,
  uploadAndSaveChecklistDocuments,
} from '@lyra/services.api';

import { uploadAndSaveChecklistDocuments as adminUploadAndSaveChecklistDocuments } from '@admin/services.api';
import { errorHandler } from '@moxie/utils';
import { applicationActions, useAppSelector } from '@lyra/core';
import { ExistingDocumentsList } from './existing-documents-list';
import { ApplicationContext } from '../applicationContext';
const { Dragger } = Upload;

const ChecklistDocumentDrawer = ({
  isOpen,
  onClose,
  triggerRefresh,
  checklistId,
  wfAppStageId,
  checklistName,
  uploadType,
  leadId,
  userType,
}: IChecklistDocumentProps) => {
  const dispatch = useDispatch();
  const params: { slug2: string; applicationId: string } = useParams();
  const applicationId = params?.slug2 ? params.slug2 : params.applicationId;
  const [documents, setDocuments] = useState<any>([]);
  const [existingDocuments, setExistingDocuments] = useState<[]>([]);
  const [selectedRowKeys, setSelectedRowKeys] = useState([]);
  const [loading, setLoading] = useState(false);
  const [documentsSize, setDocumentsSize] = useState<number>(0);
  const { applicationData, userId, socket } = useAppSelector((state) => ({
    applicationData: state.application.singleData,
    userId: state.auth.user?.id,
    socket: state.socket.wss,
  }));

  const { applicationState, setSelectedChecklist } = useContext(
    ApplicationContext
  );
  const { selectedChecklistData } = applicationState;

  const customRowSelection = {
    selectedRowKeys: selectedRowKeys,
    onSelectAll: () => {
      if (selectedRowKeys.length !== 0) {
        setSelectedRowKeys([]);
      }
    },
    onChange: (selectedRowKeys: any) => {
      const filterDocs = existingDocuments.filter((item: any) => {
        if (selectedRowKeys.includes(item.id)) {
          const documentData = item;
          documentData.checklist_id = checklistId;
          documentData.workflow_application_stage_id = wfAppStageId;
          documentData.documentType = checklistName;
          documentData.application_id = applicationId;
          documentData.contact_id = leadId;
          documentData.fileName = item.name;
          return documentData;
        }
      });
      setDocuments(filterDocs);
      setSelectedRowKeys(selectedRowKeys);
    },
  };

  const handleSubmit = async () => {
    if (documents.length === 0) {
      onClose();
      return;
    }

    let totalDocsSize = documents?.reduce((acc: number, curr: any) => {
      return Number(acc) + Number(curr.size);
    }, 0);
    totalDocsSize = totalDocsSize / 1024 / 1024;

    if (totalDocsSize > 50) {
      errorNotificationHandler(TEXT.MAX_TASK_DOCUMENTS_SIZE);
      return;
    }

    if (documents?.length > 20) {
      errorNotificationHandler(TEXT.MAX_TASK_DOCUMENTS_COUNT);
      return;
    }

    setLoading(true);
    try {
      let response;
      if (uploadType === TEXT.DEVICE) {
        const formData = new FormData();
        await documents.forEach((file: any) => {
          formData.append('files', file);
          formData.append(
            'fileName',
            file.fileName + file.extension || file.name
          );
        });
        checklistId && formData.append('checklist_id', checklistId.toString());
        formData.append(
          'workflow_application_stage_id',
          wfAppStageId.toString()
        );
        formData.append('documentType', checklistName);
        formData.append('application_id', applicationId);
        leadId && formData.append('contact_id', leadId);
        if (userType === 'Admission') {
          response = await adminUploadAndSaveChecklistDocuments(formData);
        } else {
          response = await uploadAndSaveChecklistDocuments(formData);
        }

        const newSelectedChecklistData = selectedChecklistData.map(
          (data: any) => {
            if (data.checklist_id === checklistId) {
              data.totalDocs = data.totalDocs + documents.length;
            }
            return data;
          }
        );
        setSelectedChecklist(newSelectedChecklistData);
      } else {
        const restData = {
          checklist_id: checklistId,
          workflow_application_stage_id: wfAppStageId,
          application_id: applicationId,
          contact_id: leadId,
        };
        response = await saveChecklistDocuments({
          documents,
          restData,
        });
      }
      socket?.emit('create-application-notification-to-server', {
        user_id: userId,
        receivers_many: applicationData.agent_clients?.map((item: any) => {
          return item.id;
        }),
        type: 'application-updated',
        update_type: 'document-added',
        parent_id: applicationData.client.id,
        application_identifier: applicationData.app_identifier,
        application_id: applicationData.id,
      });
      successNotificationHandler(response!.data!.message);
      setLoading(false);
      setDocuments([]);
      onClose();
      triggerRefresh();
      if (applicationId) {
        dispatch(applicationActions.getOneRequest(applicationId));
      }
    } catch (err) {
      errorHandler(err);
    } finally {
      setLoading(false);
    }
  };

  const handleDocument = async (files: any) => {
    setLoading(true);
    if (files.file.size / 1024 / 1024 < 10) {
      setDocuments((docs: []) => [...docs, files.file]);
      setLoading?.(false);
    } else {
      setLoading(false);
      errorNotificationHandler(FILE_SIZE_EXCEED);
    }
  };

  const SETTING = {
    name: 'files',
    multiple: true,
    accept: `${PRESENTATION_TYPE}, ${DOCUMENT_TYPE}, ${IMAGE_TYPE}, ${VIDEO_TYPE}, ${SPREADSHEET_TYPE}, ${AUDIO_TYPE}`,
    showUploadList: false,
    customRequest: handleDocument,
  };

  const handleRemove = async (file: IDocumentItem | undefined) => {
    const filterDocument = await documents.filter((item: IDocumentItem) => {
      if (item.id) {
        return item?.id !== file?.id;
      } else {
        return item?.uid !== file?.uid;
      }
    });
    setSelectedRowKeys((selectedKeys) =>
      selectedKeys.filter((selectedKeys) => selectedKeys !== file?.id)
    );
    setDocuments(filterDocument);
  };

  const getDocumentsList = async () => {
    const response = await getLeadDocuments(leadId || '', checklistName);
    setExistingDocuments(response.data.data);
    return response;
  };

  useEffect(() => {
    setDocumentsSize(() => {
      return documents?.reduce((acc: number, curr: { size: number }) => {
        return acc + curr.size;
      }, 0);
    });
  }, [documents]);

  return (
    <DrawerElem
      title={
        uploadType === TEXT.DEVICE
          ? TEXT.UPLOAD_FROM_DEVICE
          : TEXT.SELECT_EXISTING_DOCUMENTS
      }
      width={DRAWER_WIDTH}
      visible={isOpen}
      onClose={onClose}
      closable={!loading}
      footer={
        <div className="padding-bottom-2 text-align-right">
          <Space direction="horizontal">
            <Button
              onClick={onClose}
              className="lead-margin-right"
              disabled={loading}
              data-testid="cancel_documentUpload_btn"
            >
              {CANCEL_BTN}
            </Button>
            <Button
              type="primary"
              onClick={handleSubmit}
              disabled={loading}
              data-testid="save_documentUpload_btn"
            >
              {SAVE_BTN}
            </Button>
          </Space>
        </div>
      }
    >
      <div>
        {uploadType === TEXT.DEVICE ? (
          <Dragger className="dragger_height" {...SETTING} listType="picture">
            <p className="ant-upload-drag-icon">
              <InboxOutlined />
            </p>
            <p className="ant-upload-text">{TITLE.DRAG_TO_UPLOAD}</p>
            <div>
              <p className="font-size-20-px">
                {TEXT.SELECTED_DOCUMENTS}&nbsp;
                <span
                  style={{
                    color: documents?.length > 20 ? 'red' : 'black',
                  }}
                >
                  ({documents?.length})
                </span>{' '}
                <span
                  style={{
                    color: documentsSize / 1024 / 1024 > 50 ? 'red' : 'black',
                  }}
                >{` (${Math.round(documentsSize / 1024 / 1024)} MB)`}</span>
              </p>
              <p>{MAXIMUM_NUMBER_OF_FILES}: 20</p>
              <p>{MAXIMUM_SIZE_OF_FILES}: 50 MB</p>
              <p className="dragger_font">
                {documents?.length === 0 ? NO_FILES_SELECTED : null}
              </p>
            </div>
          </Dragger>
        ) : (
          <ExistingDocumentsList
            documentsList={getDocumentsList}
            count={documents.length}
            customRowSelection={customRowSelection}
          />
        )}
        <div className="margin-top-1">
          <Spin
            spinning={loading}
            tip={TEXT.UPLOAD_DOCUMENTS}
            className="margin-top-3"
          >
            {documents?.length !== 0 ? (
              <List
                bordered={true}
                dataSource={documents}
                renderItem={(item: IDocumentItem) => {
                  return (
                    <FileNameComponent
                      item={item}
                      documents={documents}
                      onSetDocument={setDocuments}
                      onRemove={handleRemove}
                      showType={false}
                    />
                  );
                }}
              />
            ) : null}
          </Spin>
        </div>
      </div>
    </DrawerElem>
  );
};

export { ChecklistDocumentDrawer };
