import { checkAccount } from '@luzon/services.api';
import {
  ADDRESS,
  ADDRESS1_PLACEHOLDER,
  AUTH_MESSAGE,
  CITY_OR_STATE_LABEL,
  CITY_OR_STATE_PLACEHOLDER,
  COUNTRIES,
  COUNTRY_LABEL,
  COUNTRY_PLACEHOLDER,
  EMAIL_LABEL,
  EMAIL_PLACEHOLDER,
  FIRST_NAME_LABEL,
  FIRST_NAME_PLACEHOLDER,
  GUTTER,
  LAST_NAME_LABEL,
  LAST_NAME_PLACEHOLDER,
  PLACEHOLDER,
  regex,
  TIMEZONE,
  TIMEZONE_LABEL,
} from '@moxie/constants';
import { FormItem, Input, Option, Select } from '@shared-components/elements';
import {
  IGetPopupContainer,
  IPersonalForm,
  IPersonalInformation,
} from '@shared-components/models';
import { Col, Row } from 'antd';
import React, { useState } from 'react';

const PersonalDetailsForm: React.FC<IPersonalForm<IPersonalInformation>> = ({
  form,
  emailValidationStatus,
  setEmailValidationStatus,
}: IPersonalForm<IPersonalInformation>) => {
  const [timezones, setTimeZones] = useState(TIMEZONE);
  const [countries] = useState(COUNTRIES);

  const checkEmailExist = async (rule: any, value: string): Promise<any> => {
    value = value?.toLowerCase();
    if (!value) {
      setEmailValidationStatus('error');
      return Promise.resolve(true);
    }

    const regexEmail = new RegExp(regex.EMAIL);
    if (!regexEmail.test(value)) {
      setEmailValidationStatus('error');
      throw new Error(AUTH_MESSAGE.INVALID_EMAIL);
    }
    setEmailValidationStatus('validating');
    const trimValue = value?.replace(/ /g, '');
    const result = await validateEmail({
      email: trimValue,
    });

    if (result) {
      setEmailValidationStatus('success');
      return Promise.resolve(true);
    } else {
      setEmailValidationStatus('error');
      throw new Error(AUTH_MESSAGE.REGISTER_EMAIL_TAKEN);
    }
  };

  const validateEmail = async ({ email }: { email: string }) => {
    try {
      const res = await checkAccount(email);
      return !!res?.data?.success;
    } catch (err) {
      return false;
    }
  };

  const handleCountryFilter = (input: string, option: any): boolean => {
    const filtered =
      option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0;
    return filtered;
  };

  const handleTimezonesFilter = (input: string, options: any): boolean => {
    return options.children?.toLowerCase().indexOf(input.toLowerCase()) >= 0;
  };
  const handelSelectCountry = (value: any, options: any) => {
    form.resetFields(['time_zone']);
    const timezonesArr = [
      ...timezones.map((item) => {
        const obj = {
          text: item.text,
          value: item.value,
          utc: `${item.utc}`,
        };
        return obj;
      }),
    ];
    function filterIt(arr: any[], searchKey: string) {
      return arr.filter(function (obj) {
        return Object.keys(obj).some(function (key) {
          return obj[key]?.toLowerCase().trim().includes(searchKey);
        });
      });
    }
    const filterTimezonesArr = filterIt(
      timezonesArr,
      options.children?.toLowerCase().trim()
    );
    const filterArrTimezones =
      filterTimezonesArr?.length <= 0 ? [...TIMEZONE] : [...filterTimezonesArr];
    setTimeZones(filterArrTimezones);
  };
  const handleSelectCountryClear = () => {
    form.resetFields(['time_zone']);
  };
  return (
    <>
      <div>
        <Row gutter={GUTTER}>
          <Col span="12">
            <FormItem
              name="first_name"
              label={FIRST_NAME_LABEL}
              rules={[{ required: true, max: 50, type: 'string' }]}
            >
              <Input placeholder={FIRST_NAME_PLACEHOLDER} />
            </FormItem>
          </Col>
          <Col span="12">
            <FormItem
              name="last_name"
              label={LAST_NAME_LABEL}
              rules={[{ required: true, max: 50, type: 'string' }]}
            >
              <Input placeholder={LAST_NAME_PLACEHOLDER} />
            </FormItem>
          </Col>
        </Row>
        <FormItem
          name="email"
          label={EMAIL_LABEL}
          validateStatus={emailValidationStatus}
          rules={[
            {
              required: true,
            },
            {
              validator: checkEmailExist,
            },
          ]}
        >
          <Input placeholder={EMAIL_PLACEHOLDER} />
        </FormItem>
        <Row gutter={GUTTER}>
          <Col span="12">
            <div id="country_list">
              <FormItem
                name="country"
                label={COUNTRY_LABEL}
                rules={[{ required: true, type: 'string', max: 50 }]}
              >
                <Select
                  placeholder={COUNTRY_PLACEHOLDER}
                  showSearch
                  filterOption={handleCountryFilter}
                  onSelect={handelSelectCountry}
                  onClear={handleSelectCountryClear}
                  allowClear
                  getPopupContainer={(): IGetPopupContainer =>
                    document.getElementById('country_list')
                  }
                >
                  {countries &&
                    countries.map((country) => {
                      return (
                        <Option value={country.code} key={country.code}>
                          {country.name}
                        </Option>
                      );
                    })}
                </Select>
              </FormItem>
            </div>
          </Col>
          <Col span="12">
            <FormItem
              name="city_or_state"
              label={CITY_OR_STATE_LABEL}
              rules={[{ required: true, type: 'string', max: 100 }]}
            >
              <Input placeholder={CITY_OR_STATE_PLACEHOLDER} />
            </FormItem>
          </Col>
          <Col span="24">
            <FormItem
              name="address1"
              label={ADDRESS}
              rules={[{ required: true, type: 'string', max: 100 }]}
            >
              <Input placeholder={ADDRESS} />
            </FormItem>
          </Col>
        </Row>
        <div id="timezone_list">
          <FormItem
            name="time_zone"
            label={TIMEZONE_LABEL}
            rules={[{ required: true }]}
          >
            <Select
              placeholder={PLACEHOLDER.SELECT_TIMEZONE}
              showSearch
              filterOption={handleTimezonesFilter}
              onClear={handleSelectCountryClear}
              allowClear
              getPopupContainer={(): IGetPopupContainer =>
                document.getElementById('timezone_list')
              }
            >
              {timezones &&
                timezones.map((item, index) => {
                  return (
                    <Option value={item.text} key={item.value + index}>
                      {item.text}
                    </Option>
                  );
                })}
            </Select>
          </FormItem>
        </div>
      </div>
    </>
  );
};

export { PersonalDetailsForm };
