import React, { useEffect, useState } from 'react';
import { useHistory, useParams } from 'react-router-dom';
import { Tabs, Typography } from 'antd';
import {
  PAGE_HEADER_MENU_LEAD_DETAIL,
  SLUG_ACTIVITIES,
  SLUG_APPLICATIONS,
  SLUG_DOCUMENTS,
  SLUG_PROFILE_SECTION,
  TAB_POSITION,
} from '@moxie/constants';
import { ContactApplicationList } from '@admin/shared';
import { ProfileSection } from './contact-profile-section/profile-section';
import ClientActivities from './client-activities';
import { ILeadProfileSection } from '@shared-components/models';
import ContactDocumentTable from './contact-document-section/contact-document-table';
import { getAdminAllDocuments } from '@admin/services.api';

const { TabPane } = Tabs;

const ContactDetailTabs: React.FC<ILeadProfileSection> = (
  props: ILeadProfileSection
) => {
  const history = useHistory();
  const { id: leadId, slug } = useParams<{
    id: string;
    slug: string;
    applicationId: string;
  }>();
  const { lead } = props;

  const [refreshTable, setRefreshTable] = useState(true);
  const [activeKey, setActiveKey] = useState(slug);

  const setDefaultActiveKey = () => {
    const selected = PAGE_HEADER_MENU_LEAD_DETAIL.find(
      (item) => item.id === slug
    );
    selected?.id && setActiveKey(selected?.id);
  };

  const handleChange = (key: string) => {
    const selected = PAGE_HEADER_MENU_LEAD_DETAIL.find(
      (item) => item.id === key
    );
    if (selected?.url) {
      history.push(`/contact/detail/${leadId}/${selected?.url}`);
    }
  };

  const getDocuments = async (str: string) => {
    return await getAdminAllDocuments(leadId, str);
  };

  const tabContent = (key: string) => {
    switch (key) {
      case `${SLUG_ACTIVITIES}`:
        // TODO: Display activities here
        return (
          <div className="overflow-scroll">
            <ClientActivities />
          </div>
        );
      case `${SLUG_PROFILE_SECTION}`:
        return (
          <div className="overflow-scroll">
            <ProfileSection lead={lead} />
          </div>
        );
      case `${SLUG_APPLICATIONS}`:
        return (
          <div className="full-height-tab-content overflow-scroll">
            <ContactApplicationList />
          </div>
        );
      case `${SLUG_DOCUMENTS}`:
        return (
          <div className="full-height-tab-content overflow-scroll">
            <ContactDocumentTable
              documentData={getDocuments}
              triggerRefresh={triggerRefresh}
              refreshTable={refreshTable}
            />
          </div>
        );
      default:
        return;
    }
  };

  const triggerRefresh = () => {
    setRefreshTable((prev) => !prev);
  };

  useEffect(() => {
    triggerRefresh();
    setDefaultActiveKey();
  }, [slug, activeKey]);

  return (
    <section className="lead_content">
      <div className="pro">
        <Tabs
          activeKey={activeKey}
          tabPosition={TAB_POSITION}
          onTabClick={(key) => handleChange(key)}
        >
          {PAGE_HEADER_MENU_LEAD_DETAIL.map((item) => {
            return (
              <TabPane
                className="custom_tab"
                key={item.id}
                tab={<Typography.Text strong>{item.name}</Typography.Text>}
              >
                {tabContent(item.id)}
              </TabPane>
            );
          })}
        </Tabs>
      </div>
    </section>
  );
};

export { ContactDetailTabs };
