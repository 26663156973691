import { AxiosResponse } from 'axios';
import axiosInstance from './axios';
import { IInterestedService } from '@shared-components/models';
import { API_URL } from '@moxie/constants';

const getInterestedServices = (
  contactId: string,
  params = ''
): Promise<AxiosResponse> => {
  return axiosInstance.get(
    `${API_URL.URL_GET_INTERESTED_SERVICE}${contactId}/${params}`
  );
};

const putInterestedServices = (
  interestedServices: IInterestedService
): Promise<AxiosResponse> => {
  return axiosInstance.put(
    `${API_URL.URL_INTERESTED_SERVICE}/${interestedServices.id}`,
    interestedServices
  );
};

const postInterestedServices = (
  interestedServices: IInterestedService
): Promise<AxiosResponse> => {
  return axiosInstance.post(
    `${API_URL.URL_INTERESTED_SERVICE}`,
    interestedServices
  );
};

const deleteInterestedServices = (id: string): Promise<AxiosResponse> => {
  return axiosInstance.delete(`${API_URL.URL_INTERESTED_SERVICE}/${id}`);
};

const postProductAsInterestedService = (
  contactId: string,
  productId: string,
  workflowId: string,
  institutionBranchId: string
): Promise<AxiosResponse> => {
  return axiosInstance.post(`${API_URL.URL_INTERESTED_SERVICE}/${contactId}`, {
    product_id: productId,
    workflow_id: workflowId,
    institution_branch_id: institutionBranchId,
  });
};

export {
  getInterestedServices,
  putInterestedServices,
  postInterestedServices,
  deleteInterestedServices,
  postProductAsInterestedService,
};
