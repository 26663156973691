import { applyMiddleware, combineReducers, createStore } from 'redux';
import { composeWithDevTools } from 'redux-devtools-extension';
import createSagaMiddleware from 'redux-saga';
import saga from './sagas/saga';
import authReducer, { AUTH_FEATURE_KEY } from './auth/auth.reducer';
import { themeReducer, THEME_FEATURE_KEY } from './theme/theme.reducer';
import {
  PRODUCTS_FEATURE_KEY,
  productReducer,
} from './products/products.reducer';
import {
  WORKFLOW_TYPE_FEATURE_KEY,
  workflowTypeReducer,
} from './workflow/workflow-type.reducer';
import {
  institutionReducer,
  INSTITUTION_FEATURE_KEY,
} from './institutions/institution.reducer';
import {
  WORKFLOW_FEATURE_KEY,
  workflowReducer,
} from './workflow/workflow.reducer';
import {
  rolePermissionReducer,
  ROLE_PERMISSION_FEATURE,
} from './role_permissions/role-permission.reducer';
import { otherDetailsReducer } from 'apps/lyra/src/core/setting-other-details/setting-other-details.reducer';
import { userReducer, USER_FEATURE_KEY } from './users/user.reducer';
import leadOtherDetailValueReducer, {
  LEAD_OTHER_DETAIL_VALUE_FEATURE_KEY,
} from 'apps/lyra/src/core/lead-other-detail-values/other-detail-values.reducer';
import leadReducer, {
  LEAD_FEATURE_KEY,
} from 'apps/lyra/src/core/lead/lead.reducer';
import professionalExperienceReducer, {
  PROFESSIONAL_EXPERIENCE_FEATURE_KEY,
} from 'apps/lyra/src/core/professional-experience/professional-experience.reducer';
import otherTestScoreReducer, {
  OTHER_TEST_SCORE_FEATURE_KEY,
} from 'apps/lyra/src/core/other-test-score/other-test-score.reducer';
import educationBackgroundReducer, {
  EDUCATION_BACKGROUND_FEATURE_KEY,
} from 'apps/lyra/src/core/education-background/education-background.reducer';
import languageTestScoreReducer, {
  LANGUAGE_TEST_SCORE_FEATURE_KEY,
} from 'apps/lyra/src/core/language-test-score/language-test-score.reducer';

import { applicationReducer } from './application/application.reducer';
import { clientReducer, CLIENT_FEATURE_KEY } from './client/client.reducer';
import {
  ClientActivitiesReducer,
  CLIENT_ACTIVITIES_FEATURE_KEY,
} from './client-activities/client-activities.reducer';
import { socketReducer, SOCKET_FEATURE_KEY } from './socket/socket.reducer';
import {
  NotificationsReducer,
  NOTIFICATIONS_FEATURE_KEY,
} from './notification/notifications.reducer';
import { tenantReducer, TENANT_FEATURE_KEY } from './tenant/tenant.reducer';

const SETTING_OTHER_DETAILS = 'setting_other_details';
const APPLICATION_FEATURE_KEY = 'application';

const rootReducer = combineReducers({
  [AUTH_FEATURE_KEY]: authReducer,
  [THEME_FEATURE_KEY]: themeReducer,
  [APPLICATION_FEATURE_KEY]: applicationReducer,
  [PRODUCTS_FEATURE_KEY]: productReducer,
  [WORKFLOW_TYPE_FEATURE_KEY]: workflowTypeReducer,
  [INSTITUTION_FEATURE_KEY]: institutionReducer,
  [WORKFLOW_FEATURE_KEY]: workflowReducer,
  [USER_FEATURE_KEY]: userReducer,
  [ROLE_PERMISSION_FEATURE]: rolePermissionReducer,
  [SETTING_OTHER_DETAILS]: otherDetailsReducer,
  [LEAD_FEATURE_KEY]: leadReducer,
  [CLIENT_ACTIVITIES_FEATURE_KEY]: ClientActivitiesReducer,
  [CLIENT_FEATURE_KEY]: clientReducer,
  [SOCKET_FEATURE_KEY]: socketReducer,
  [NOTIFICATIONS_FEATURE_KEY]: NotificationsReducer,
  [PROFESSIONAL_EXPERIENCE_FEATURE_KEY]: professionalExperienceReducer,
  [LANGUAGE_TEST_SCORE_FEATURE_KEY]: languageTestScoreReducer,
  [OTHER_TEST_SCORE_FEATURE_KEY]: otherTestScoreReducer,
  [EDUCATION_BACKGROUND_FEATURE_KEY]: educationBackgroundReducer,
  [LEAD_OTHER_DETAIL_VALUE_FEATURE_KEY]: leadOtherDetailValueReducer,
  [TENANT_FEATURE_KEY]: tenantReducer,
});

const sagaMiddleware = createSagaMiddleware();
const middleWares = [sagaMiddleware];

function configureStore() {
  const middlewareEnhancer = applyMiddleware(...middleWares);
  const composedEnhancers = composeWithDevTools(middlewareEnhancer);
  const store = createStore(rootReducer, composedEnhancers);
  sagaMiddleware.run(saga);
  return store;
}

const store = configureStore();

export type RootState = ReturnType<typeof store.getState>;
export type AppDispatch = typeof store.dispatch | any;

export default store;

export function createAction(type: string | any, ...argNames: any) {
  return function (...args: any) {
    const action: any = { type };
    argNames.forEach((arg: any, index: any) => {
      action[argNames[index]] = args[index];
    });
    return action;
  };
}
