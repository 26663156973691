import React, { useEffect, useState } from 'react';
import { Divider, Select, Input, Typography } from 'antd';
import { SearchOutlined, CaretDownOutlined } from '@ant-design/icons';
import {
  getAllApplications,
  getBranchesDashboard,
  getClientByAssigneeList,
  getContactByBranch,
  getOfficeBranches,
} from '@lyra/services.api';
import { IGetPopupContainer } from '@shared-components/models';
import { LABEL } from '@moxie/constants';

interface IDashboardDropdown {
  setBranchData: any;
  setClientByUserList: any;
  setApplicationData: any;
}

const { Option } = Select;
const DropdownComponent: React.FC<IDashboardDropdown> = ({
  setBranchData,
  setClientByUserList,
  setApplicationData,
}: IDashboardDropdown) => {
  const [searchResults, setSearchResults] = useState([]);

  const handleChange = async (value: string) => {
    const response = await getContactByBranch(value || '');
    const branchApplicationData = await getAllApplications(
      `?branchId=${value}`
    );

    const assigneeClientsByBranch = await getClientByAssigneeList(value || '');
    setBranchData(response?.data?.data);
    setApplicationData(branchApplicationData?.data?.data);
    setClientByUserList(assigneeClientsByBranch?.data?.data);
  };

  const getAllData = async () => {
    const response = await getOfficeBranches();
    setSearchResults(response?.data?.data);
  };
  const handleSearch = async (e: any) => {
    const response = await getBranchesDashboard(e.target.value);
    setSearchResults(response?.data?.data);
  };
  useEffect(() => {
    getAllData();
  }, []);
  return (
    <div id="area">
      <Typography.Text type="secondary" strong>
        Summary and Analytics:{' '}
      </Typography.Text>
      <Select
        className="dashboard-select-arrow"
        suffixIcon={<CaretDownOutlined />}
        defaultValue={''}
        bordered={false}
        getPopupContainer={(): IGetPopupContainer =>
          document.getElementById('area')
        }
        onChange={handleChange}
        dropdownRender={(menu) => (
          <div className="dashboard-dropdown">
            <div className="padding-1">
              <Input
                allowClear
                placeholder="Search Branches"
                onChange={handleSearch}
                suffix={<SearchOutlined />}
              />
            </div>
            <Divider />
            {menu}
          </div>
        )}
      >
        <Option value={''} key={'all_branches'}>
          All Branches
        </Option>
        <Option
          value={'branch'}
          key="branch"
          disabled
          className="dashboard-select-option"
        >
          <Divider />
          <b>{LABEL.BRANCHES}</b>
          <Divider />
        </Option>
        {searchResults &&
          searchResults?.map((result: any) => {
            return (
              <Option value={result?.id} key={result.id}>
                {result.name}
              </Option>
            );
          })}
      </Select>
    </div>
  );
};

export default DropdownComponent;
