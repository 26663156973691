import React from 'react';
import { IProductIntake } from '@shared-components/models';
import { Tag, Tooltip } from 'antd';

const IntakeTagGroup = ({ intakes }: { intakes: IProductIntake[] }) => {
  const tooltipData = (
    <div>
      {intakes &&
        intakes.slice(3, intakes.length).map((intake: IProductIntake) => {
          return (
            <Tag
              className="margin-bottom-1 initial_capital"
              key={intake.id}
              color="default"
            >
              {intake.intake}
            </Tag>
          );
        })}
    </div>
  );
  return (
    <>
      {intakes &&
        intakes.slice(0, 3).map((intake: IProductIntake) => {
          return (
            <Tag key={intake.id} color="default" className="initial_capital">
              {intake.intake}
            </Tag>
          );
        })}

      {intakes.length > 3 && (
        <Tooltip placement="topLeft" color="white" title={tooltipData}>
          <Tag color="default">+{intakes.length - 3}</Tag>
        </Tooltip>
      )}
    </>
  );
};

export { IntakeTagGroup };
