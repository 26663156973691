import { API_URL } from '@moxie/constants';
import { IVisitPurposeCrm } from '@shared-components/models';
import crmAxios from './crm-api-axios';

const getVisitPurposeListBasedOnService = async (
  serviceId: string
) => {
  return (await crmAxios.get<{data: IVisitPurposeCrm[]}>(`${API_URL.CRM_API_OFFICE_VISIT}/${serviceId}/visitPurposes`))?.data;
};

export { getVisitPurposeListBasedOnService };
