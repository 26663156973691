import React from 'react';
import { Checkbox, Col, Form, Row } from 'antd';
import { ILeadsFormComponent } from '@shared-components/models';
import { GUTTER, LABEL, TITLE } from '@moxie/constants';
import { globalHandleCheck } from '@moxie/shared';

const EnglishTestScore: React.FC<ILeadsFormComponent> = ({
  setLeadForm,
  leadForm,
  setDisabled,
}: ILeadsFormComponent) => {
  const handleChange = (e: any) => {
    globalHandleCheck(
      e,
      leadForm,
      setLeadForm,
      'english_test_score_section',
      setDisabled
    );
  };

  const onCheckAllChange = (e: any) => {
    setDisabled(false);
    if (!e.target.checked) {
      setLeadForm({
        ...leadForm,
        english_test_score_section: {
          ...leadForm.english_test_score_section,
          toefl: false,
          ielts: false,
          pte: false,
        },
      });
      return;
    }
    setLeadForm({
      ...leadForm,
      english_test_score_section: {
        ...leadForm.english_test_score_section,
        toefl: true,
        ielts: true,
        pte: true,
      },
    });
  };

  return (
    <>
      <Row justify="space-between" className="custom__title__text">
        <Col>{TITLE.ENGLISH_TEST_SCORES}</Col>
        <Col>
          <Checkbox
            checked={
              leadForm?.english_test_score_section?.toefl &&
              leadForm?.english_test_score_section?.ielts &&
              leadForm?.english_test_score_section?.pte
            }
            onChange={onCheckAllChange}
          />
        </Col>
      </Row>
      <Form.Item className="custom__formItem">
        <Row gutter={GUTTER}>
          <Col span={6} className="lead-form-box">
            <div>
              <Checkbox
                name="toefl"
                checked={leadForm?.english_test_score_section?.toefl}
                onChange={handleChange}
              >
                {LABEL.TOEFL}
              </Checkbox>
            </div>
          </Col>
          <Col span={6} className="lead-form-box">
            <div>
              <Checkbox
                name="ielts"
                checked={leadForm?.english_test_score_section?.ielts}
                onChange={handleChange}
              >
                {LABEL.IELTS}
              </Checkbox>
            </div>
          </Col>
          <Col span={6} className="lead-form-box">
            <div>
              <Checkbox
                name="pte"
                checked={leadForm?.english_test_score_section?.pte}
                onChange={handleChange}
              >
                {LABEL.PTE}
              </Checkbox>
            </div>
          </Col>
        </Row>
      </Form.Item>
    </>
  );
};

export default EnglishTestScore;
