import { IAction } from '@shared-components/models';
import * as actionTypes from './user.constant';
import { IUserData } from 'libs/shared/src/crm-modules/user/user.model';
export const USER_FEATURE_KEY = 'users';

interface UserReducerState {
  allData: IUserData[];
  followerUsers: IUserData[];
  singleData: Partial<IUserData>;
  error: Record<string, unknown> | null;
  loading: boolean;
}
export const initialUserState: UserReducerState = {
  allData: [],
  followerUsers: [],
  singleData: {},
  error: null,
  loading: false,
};

const userReducer = (state = initialUserState, action: IAction<any>): UserReducerState => {
  switch (action.type) {
    case actionTypes.GET_USERS_REQUEST:
      return {
        ...state,
        loading: true,
        error: null,
      };
    case actionTypes.GET_USERS_SUCCESS:
      return {
        ...state,
        loading: false,
        error: null,
        allData: action.payload as IUserData[],
      };
    case actionTypes.GET_USERS_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.payload,
      };
    case actionTypes.ADD_USER_REQUEST:
      return {
        ...state,
        loading: true,
        error: null,
      };
    case actionTypes.ADD_USER_SUCCESS:
      return {
        ...state,
        loading: false,
        error: null,
        allData: action.payload as IUserData[],
      };
    case actionTypes.ADD_USER_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.payload,
      };
    case actionTypes.GET_USER_REQUEST:
      return {
        ...state,
        loading: true,
        error: null,
      };
    case actionTypes.GET_USER_SUCCESS:
      return {
        ...state,
        loading: false,
        error: null,
        singleData: action.payload,
      };
    case actionTypes.GET_USER_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.payload,
      };
    case actionTypes.GET_FOLLOWER_USERS_REQUEST:
      return {
        ...state,
        loading: true,
        error: null,
      };
    case actionTypes.GET_FOLLOWER_USERS_SUCCESS:
      return {
        ...state,
        loading: false,
        error: null,
        followerUsers: action.payload,
      };
    case actionTypes.GET_FOLLOWER_USERS_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.payload,
      };
    case actionTypes.UPDATE_USER_REQUEST:
      return {
        ...state,
        loading: true,
        error: null,
      };
    case actionTypes.UPDATE_USER_SUCCESS:
      return {
        ...state,
        loading: false,
        error: null,
      };
    case actionTypes.UPDATE_USER_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.payload,
      };
    case actionTypes.DEACTIVATE_USER_REQUEST:
      return {
        ...state,
        loading: true,
        error: null,
      };
    case actionTypes.DEACTIVATE_USER_SUCCESS:
      return {
        ...state,
        loading: false,
        error: null,
      };
    case actionTypes.DEACTIVATE_USER_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.payload,
      };
    case actionTypes.ACTIVATE_USER_REQUEST:
      return {
        ...state,
        loading: true,
        error: null,
      };
    case actionTypes.ACTIVATE_USER_SUCCESS:
      return {
        ...state,
        loading: false,
        error: null,
      };
    case actionTypes.ACTIVATE_USER_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.payload,
      };
    case actionTypes.CLEAR_USER_FETCH:
      return {
        ...state,
        allData: [],
        followerUsers: [],
        singleData: {},
        error: null,
        loading: false,
      };
    default:
      return state;
  }
};

export { userReducer };
