import {
  CANCEL_BTN,
  DRAWER_WIDTH,
  PROFILE_EDIT_TITLE,
  SAVE_BTN,
  UPDATE_BTN,
  USER_ADD_PLACEMENT,
  USER_ADD_TITLE,
  USER_EDIT_TITLE,
} from '@moxie/constants';
import { Button, DrawerElem } from '@moxie/shared';
import { UserPersonalInfoForm } from './userPersonalForm';
import { UserDrawerProps } from '@shared-components/models';
import { Space } from 'antd';
import React from 'react';

const UserDrawer: React.FC<UserDrawerProps> = ({
  formData,
  edit,
  form,
  visible,
  isProfile,
  disabled,
  setDisabled,
  onClose,
  saveData,
  triggerRefresh,
  loading,
}: UserDrawerProps) => {
  if (visible) {
    return (
      <DrawerElem
        title={
          isProfile
            ? PROFILE_EDIT_TITLE
            : edit
            ? USER_EDIT_TITLE
            : USER_ADD_TITLE
        }
        width={DRAWER_WIDTH}
        visible={visible}
        onClose={onClose}
        placement={USER_ADD_PLACEMENT}
        footer={
          <div className="padding-bottom-2 text-align-right">
            <Space direction="horizontal">
              <Button onClick={onClose} className="lead-margin-right">
                {CANCEL_BTN}
              </Button>

              {edit ? (
                <Button type="primary" onClick={saveData} disabled={disabled}>
                  {UPDATE_BTN}
                </Button>
              ) : (
                <Button onClick={saveData} type="primary" disabled={disabled}>
                  {SAVE_BTN}
                </Button>
              )}
            </Space>
          </div>
        }
      >
        <UserPersonalInfoForm
          form={form}
          isProfile={isProfile}
          isEdit={edit}
          onClose={onClose}
          formData={formData && formData}
          triggerRefresh={triggerRefresh}
          setDisabled={setDisabled}
          loading={loading}
        />
      </DrawerElem>
    );
  } else return <div />;
};

UserDrawer.defaultProps = {
  isProfile: false,
};
export { UserDrawer };
