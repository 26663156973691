import React from 'react';
import { Row, Col } from 'antd';
import { Table } from '@shared-components/elements';
import { ColumnsType } from 'antd/lib/table';
import { getAllRolesPermissions } from '@lyra/services.api';
import { ITableProps } from '@shared-components/models';
import { TEXT } from '@moxie/constants';
import { TableRoleNameCard } from '../../office-branch/libs/table-role-name-card';

export const RoleTable: React.FC<ITableProps> = (props: ITableProps) => {
  const { showDrawer, refreshTable, handleDelete, setIsFieldsDisabled } = props;

  const getData = async (params: string) => {
    return getAllRolesPermissions(params);
  };

  const columns: ColumnsType<any> = [
    {
      title: 'Role Name',
      key: 'name',
      width: 200,
      render(data: any) {
        return (
          <TableRoleNameCard
            data={data}
            showDrawer={showDrawer}
            deleteData={handleDelete}
            setIsFieldsDisabled={setIsFieldsDisabled}
          />
        );
      },
    },
    {
      title: 'Description',
      dataIndex: 'description',
      key: 'description',
    },
  ];
  return (
    <Row>
      <Col span={24}>
        <Table
          filterOptions={false}
          tableName={TEXT.ROLES}
          columns={columns}
          action={getData}
          className="roles-table"
          refresh={refreshTable}
          hasPagination={false}
        />
      </Col>
    </Row>
  );
};
