import React, { useEffect, useState } from 'react';
import { Avatar, Button, Form, Space, Tooltip } from 'antd';
import {
  errorNotificationHandler,
  UserNameCard,
  UserSelectBox,
} from '@moxie/shared';
import { CheckOutlined, CloseOutlined, UserOutlined } from '@ant-design/icons';
import { useDispatch } from 'react-redux';
import { applicationActions, useAppSelector } from '@admin/core';
import { ASSIGN_TO_BTN, TEXT } from '@moxie/constants';
import { useForm } from 'antd/lib/form/Form';

const AdminAssigneeComponent = ({
  data,
  refreshTable,
  setRefreshTable,
  isTabChanged,
}: any) => {
  const dispatch = useDispatch();
  const [form] = useForm();
  const [selectValues, setSelectedValues] = useState([]);
  const [selectArr, setSelectedArr] = useState([]);
  const [showAssignee, setShowAssignee] = useState<boolean>(false);
  const toggleShowAssignee = () => setShowAssignee(!showAssignee);
  const socket = useAppSelector((state) => state.socket.wss);
  const user = useAppSelector((state) => state.auth.user);

  const handleRefreshTable = () => {
    setRefreshTable(!refreshTable);
    toggleShowAssignee();
  };
  const handleSubmit = (values: any) => {
    if (values) {
      const assignedData = {
        first_name: data?.first_name,
        last_name: data?.last_name,
        phone: data?.phone,
        country_code: data?.country_code,
        email: data?.email,
        admin_clients: values.admin_clients,
        avatar: data?.avatar,
      };
      const update_type = 'assignee';
      data?.id &&
        dispatch(
          applicationActions.updateRequest(
            {
              ...assignedData,
              id: data?.id,
              handleRefreshTable,
            },
            user,
            socket,
            data.client,
            update_type,
            values.admin_clients
          )
        );
    } else {
      errorNotificationHandler(TEXT.SELECT_ASSIGNEE, 2);
    }
  };

  useEffect(() => {
    const clients = data?.admin_clients.map((item: any) => item.id);
    setSelectedValues(clients);
    setSelectedArr(data?.admin_clients);
    form.setFieldsValue({ admin_clients: clients });
  }, [data?.admin_clients]);

  useEffect(() => {
    setShowAssignee(false);
  }, [isTabChanged]);

  return (
    <div className="lead-table__archived-by">
      {!showAssignee ? (
        <div onClick={toggleShowAssignee}>
          <Tooltip title="Assignee">
            {selectValues?.length > 0 ? (
              <Avatar.Group maxCount={2} size="default">
                {selectArr?.map((item: any) => {
                  return (
                    <Tooltip
                      key={item.id}
                      color="white"
                      title={
                        <UserNameCard
                          firstName={item.first_name || ''}
                          lastName={item.last_name}
                          email={item.email || ''}
                          index={item.id || ''}
                        />
                      }
                      placement="top"
                    >
                      <Avatar>
                        {`${item.first_name
                          ?.substring(0, 1)
                          .toUpperCase()}${item.last_name
                          ?.substring(0, 1)
                          .toUpperCase()}`}
                      </Avatar>
                    </Tooltip>
                  );
                })}
              </Avatar.Group>
            ) : (
              <Avatar icon={<UserOutlined />} alt="assignee" />
            )}
          </Tooltip>
        </div>
      ) : (
        <div className="lead-table__name user-name-card">
          <div className="lead-assignee-selector">
            <Form form={form} onFinish={handleSubmit}>
              <Form.Item
                name="admin_clients"
                className="lead-assignee-selector__item"
              >
                <UserSelectBox
                  showUnassigned={true}
                  defaultOpen={true}
                  placeholder={ASSIGN_TO_BTN}
                  mode="multiple"
                  maxTagCount={1}
                  showArrow={false}
                  bordered={false}
                  autoFocus={true}
                />
              </Form.Item>
              <Space size={8}>
                <Button
                  type="link"
                  htmlType="submit"
                  size="small"
                  className="padding-left-2 padding-right-1"
                >
                  <CheckOutlined />
                </Button>
                <Button
                  onClick={toggleShowAssignee}
                  type="link"
                  danger
                  size="small"
                >
                  <CloseOutlined />
                </Button>
              </Space>
            </Form>
          </div>
        </div>
      )}
    </div>
  );
};

export { AdminAssigneeComponent };
