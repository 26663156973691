import { Fragment, ReactNode } from "react";

interface ItemMapperProps<T> {
  dataSource: T[];
  emptyContent?: ReactNode;
  children: (item: T) => ReactNode;
}

function ItemsMapper<T>({ dataSource, emptyContent, children }: ItemMapperProps<T>) {
  if (dataSource?.length) {
    return (
      <>
        {dataSource?.map((item, index) => (
          <Fragment key={index}>
            {children(item)}
          </Fragment>
        ))}
      </>
    );
  }

  if (emptyContent) {
    return <>{emptyContent}</>;
  }

  return null;
}
export default ItemsMapper;
