import React from 'react';
import { Menu } from 'antd';
import { DELETE_BTN, EDIT_BTN } from '@moxie/constants';

const MenuItem = (
  onEdit: () => void,
  onDelete: () => void
): React.ReactElement => (
  <Menu>
    <Menu.Item onClick={onEdit} key="1">
      {EDIT_BTN}
    </Menu.Item>
    <Menu.Item danger onClick={onDelete} key="2">
      {DELETE_BTN}
    </Menu.Item>
  </Menu>
);

export { MenuItem };
