import { getBranchesList } from "@crm/services.api";
import { ICrmEnquiryData, UpdateContactEnquiryPayload } from "@model/index";
import { AUTH_MESSAGE, EMAIL_PLACEHOLDER, regex } from "@moxie/constants";
import { Input, Space } from "antd";
import Form, { FormInstance } from "antd/es/form";
import Modal from "antd/es/modal";
import { SearchBox } from "libs/shared/src/crm-modules/shared";
import SaveOutlined from "@ant-design/icons/SaveOutlined";
import { useEffect, useState } from "react";

interface EnquiryPersonalInfoModalProps {
  enquiryData: ICrmEnquiryData;
  visible: boolean;
  handleClose: () => void;
  form: FormInstance;
  handleSubmit: (data: UpdateContactEnquiryPayload) => void;
  onFieldsChange: (allFields: string | any[]) => void;
  handleSave: () => void;
  disabled: boolean;
}

const EnquiryPersonalInfoModal = ({ visible, handleClose, form, handleSubmit, onFieldsChange, enquiryData, handleSave, disabled }: EnquiryPersonalInfoModalProps) => {
  const [openEnquiryState, setOpenEnquiryState] = useState<boolean>(true);
  const openState = ['new', 'contacted', 'evaluating'];

  useEffect(() => {
    if (openState.includes(enquiryData.status)) {
      setOpenEnquiryState(false);
    } else {
      setOpenEnquiryState(true);
    }
  }, [enquiryData.status]);

  return (
    <Modal
      visible={visible}
      onCancel={handleClose}
      okText={
        <Space>
          <SaveOutlined />
          Save
        </Space>}
      okButtonProps={{ disabled: disabled }}
      title={"Edit Info"}
      onOk={handleSave}
    >
      <Form
        className="w-100"
        form={form}
        layout="vertical"
        initialValues={enquiryData}
        onFinish={handleSubmit}
        onFieldsChange={onFieldsChange}
      >
        <Form.Item
          label="First Name"
          name={["personalInformation", "firstName"]}
          validateFirst={true}
          rules={[
            {
              required: true,
              whitespace: true,
              message: 'Please enter First Name',
            },
            {
              pattern: new RegExp(regex.NAME_REGEX),
              message: AUTH_MESSAGE.INVALID_FIRST_NAME,
            },
          ]}
        >
          <Input placeholder="Enter First Name" />
        </Form.Item>
        <Form.Item
          label="Last Name"
          name={["personalInformation", "lastName"]}
          validateFirst={true}
          rules={[
            {
              required: true,
              whitespace: true,
              message: 'Please enter Last Name',
            },
            {
              pattern: new RegExp(regex.NAME_REGEX),
              message: AUTH_MESSAGE.INVALID_LAST_NAME,
            },
          ]}
        >
          <Input placeholder="Enter Last Name" />
        </Form.Item>
        <Form.Item
          name={["personalInformation", "email"]}
          label="Email"
          validateFirst={true}
          rules={
            [
              {
                required: true,
                whitespace: true,
                message: 'Please enter email',
              },
              {
                pattern: regex.EMAIL,
                message: 'Please enter valid email',
              },
            ]}
        >
          <Input type="email" placeholder={EMAIL_PLACEHOLDER} disabled={openEnquiryState} />
        </Form.Item>

        <Form.Item name="branchId" label="Branch">
          <SearchBox
            fetchQuery={getBranchesList}
            valueKey="id"
            labelKey="name"
            size="middle"
            showArrow
            allowClear={false}
            optionFilterProp="label"
            defaultOpen={false}
          />
        </Form.Item>
      </Form>
    </Modal >
  )
}

export { EnquiryPersonalInfoModal };
