import React, { useEffect, useState } from 'react';
import {
  ADDRESS1_LABEL,
  ADDRESS1_PLACEHOLDER,
  ADDRESS2_LABEL,
  ADDRESS2_PLACEHOLDER,
  ADDRESS_INFORMATION,
  ALTERNATIVE_PHONE_LABEL,
  ALTERNATIVE_PHONE_PLACEHOLDER,
  AM_ROLES,
  CHANGE_TIMEZONE,
  CITY_OR_STATE_LABEL,
  CITY_OR_STATE_PLACEHOLDER,
  CONTACT_INFORMATION,
  COUNTRIES,
  COUNTRY_LABEL,
  COUNTRY_PLACEHOLDER,
  EMAIL_LABEL,
  EMAIL_PLACEHOLDER,
  FIRST_NAME_LABEL,
  FIRST_NAME_PLACEHOLDER,
  FORM_SIZE,
  GUTTER,
  JOB_INFORMATION,
  LABEL,
  LAST_NAME_LABEL,
  LAST_NAME_PLACEHOLDER,
  PHONE_LABEL,
  PHONE_NUMBER_INVALID,
  PHONE_PLACEHOLDER,
  PLACEHOLDER,
  ROLE_LABEL,
  ROLE_PLACEHOLDER,
  TEXT,
  TIMEZONE,
  USER_PERSONAL_DETAIL,
  regex,
} from '@moxie/constants';
import { CaretRightOutlined } from '@ant-design/icons';
import { PhoneInput, SearchableSelect } from '@shared-components/elements';
import { Col, Collapse, Divider, Form, Input, Row } from 'antd';
import { Option, capitalizeFirstLetter } from 'libs/shared/src/shared';
import FormItem from 'antd/lib/form/FormItem';
import Select, { SelectValue } from 'antd/lib/select';
import { IGetPopupContainer } from '@shared-components/models';
import { useDispatch } from 'react-redux';
import { officeBranchActions, rolePermissionActions, useAppSelector, userActions } from '@lyra/core';
import { errorHandler } from '@moxie/utils';

const { Panel } = Collapse;

const UserProfileForm = ({ userProfileForm, onFieldsChange }: any) => {
  const [countryCode, setCountryCode] = useState('');
  const dispatch = useDispatch();
  const authUser: any = useAppSelector((state) => state.auth.user);
  const stateRoles = useAppSelector(
    (state: any) => state.role_permissions.roles
  );
  const branchList = useAppSelector((state) => state.office_branches?.allData);

  useEffect(() => {
    dispatch(rolePermissionActions.getRolesRequest());
    dispatch(officeBranchActions.getAllRequest());
  }, [dispatch]);

  const handleCountryChange = (input: string) => {
    COUNTRIES.find((item) => {
      if (item.name === input) {
        setCountryCode(item.dial_code);
      }
    });
  };

  const handleSubmit = async (data: any) => {
    if (data.department === undefined) data.department = null;
    data.email = data?.email?.replace(/ /g, '').toLowerCase();
    try {
      dispatch(
        userActions.updateUserRequest({ id: authUser.id, ...data }, () => null)
      );
    } catch (err) {
      errorHandler(err);
    }
  };

  const showRolesOptions = stateRoles.map((role: any) => {
    if (role.name === AM_ROLES.SUPER_USER) {
      return (
        <Option key={role.name} value={role.id}>
          Super Admin
        </Option>
      );
    } else {
      return (
        <Option key={role.name} value={role.id}>
          {capitalizeFirstLetter(role.name.toLowerCase().split('_').join(' '))}
        </Option>
      );
    }
  });

  const showBranchOptions = branchList.map((branch: any) => {
    return (
      <Option key={branch.id} value={branch.id}>
        {branch.name}
      </Option>
    );
  });
  useEffect(() => {
    COUNTRIES.find((item) => {
      if (item.name === authUser.am_user_info.country) {
        setCountryCode(item.dial_code);
      }
    });
  }, []);

  return (
    <Form
      onFieldsChange={onFieldsChange}
      layout="vertical"
      size={FORM_SIZE}
      form={userProfileForm}
      onFinish={handleSubmit}
    >
      <div>
        <div className="form_section_header">{USER_PERSONAL_DETAIL}</div>
        <Row gutter={GUTTER}>
          <Col span="12">
            <FormItem
              name="first_name"
              label={FIRST_NAME_LABEL}
              rules={[{ required: true, max: 50, type: 'string' }]}
            >
              <Input placeholder={FIRST_NAME_PLACEHOLDER} />
            </FormItem>
          </Col>
          <Col span="12">
            <FormItem
              name="last_name"
              label={LAST_NAME_LABEL}
              rules={[{ required: true, max: 50, type: 'string' }]}
            >
              <Input placeholder={LAST_NAME_PLACEHOLDER} />
            </FormItem>
          </Col>
          <Divider />
        </Row>
      </div>
      <Collapse
        defaultActiveKey={ADDRESS_INFORMATION}
        bordered={false}
        ghost={true}
        expandIcon={({ isActive }) => (
          <CaretRightOutlined rotate={isActive ? 90 : 0} />
        )}
      >
        <Panel
          header={<span>{ADDRESS_INFORMATION}</span>}
          key={ADDRESS_INFORMATION}
        >
          <Row gutter={GUTTER}>
            <Col span="12">
              <FormItem
                name="address1"
                label={ADDRESS1_LABEL}
                rules={[{ required: true, type: 'string', max: 50 }]}
              >
                <Input placeholder={ADDRESS1_PLACEHOLDER} />
              </FormItem>
            </Col>
            <Col span="12">
              <FormItem
                name="address2"
                label={ADDRESS2_LABEL}
                rules={[{ type: 'string', max: 100 }]}
              >
                <Input placeholder={ADDRESS2_PLACEHOLDER} />
              </FormItem>
            </Col>
          </Row>
          <Row gutter={GUTTER}>
            <Col span="12">
              <div id="user_country_list" className="relative">
                <FormItem
                  name="country"
                  label={COUNTRY_LABEL}
                  rules={[{ required: true, type: 'string', max: 50 }]}
                >
                  <SearchableSelect
                    onChange={(value: SelectValue) => {
                      if (value) handleCountryChange(value.toString());
                    }}
                    placeholder={COUNTRY_PLACEHOLDER}
                    getPopupContainer={(): IGetPopupContainer =>
                      document.getElementById('user_country_list')
                    }
                  >
                    {COUNTRIES.map((country) => {
                      return (
                        <Option value={country.name} key={country.code}>
                          {country.name}
                        </Option>
                      );
                    })}
                  </SearchableSelect>
                </FormItem>
              </div>
            </Col>
            <Col span="12">
              <FormItem
                name="city_or_state"
                label={CITY_OR_STATE_LABEL}
                rules={[{ required: true, type: 'string', max: 100 }]}
              >
                <Input placeholder={CITY_OR_STATE_PLACEHOLDER} />
              </FormItem>
            </Col>
          </Row>
        </Panel>
      </Collapse>
      <Divider />
      <Collapse
        defaultActiveKey={CONTACT_INFORMATION}
        bordered={false}
        ghost={true}
        expandIcon={({ isActive }) => (
          <CaretRightOutlined rotate={isActive ? 90 : 0} />
        )}
      >
        <Panel
          header={<span>{CONTACT_INFORMATION}</span>}
          key={CONTACT_INFORMATION}
        >
          <FormItem name="email" label={EMAIL_LABEL}>
            <Input disabled={true} placeholder={EMAIL_PLACEHOLDER} />
          </FormItem>
          <Row gutter={GUTTER}>
            <Col span="12">
              <Form.Item
                name="phone"
                label={PHONE_LABEL}
                rules={[
                  {
                    required: true,
                    pattern: new RegExp(regex.ONLY_NUMBER),
                    message: PHONE_NUMBER_INVALID,
                  },
                ]}
              >
                <PhoneInput
                  type="string"
                  placeholder={PHONE_PLACEHOLDER}
                  dialCode={countryCode}
                  onChangeDialCode={handleCountryChange}
                />
              </Form.Item>
            </Col>
            <Col span="12">
              <FormItem
                name="alternative_phone"
                label={ALTERNATIVE_PHONE_LABEL}
                rules={[
                  {
                    pattern: new RegExp(regex.ONLY_NUMBER),
                    message: PHONE_NUMBER_INVALID,
                  },
                ]}
              >
                <PhoneInput
                  type="string"
                  placeholder={ALTERNATIVE_PHONE_PLACEHOLDER}
                  dialCode={countryCode}
                />
              </FormItem>
            </Col>
          </Row>
        </Panel>
      </Collapse>
      <Divider />
      <Collapse
        defaultActiveKey={CHANGE_TIMEZONE}
        bordered={false}
        ghost={true}
        expandIcon={({ isActive }) => (
          <CaretRightOutlined rotate={isActive ? 90 : 0} />
        )}
      >
        <Panel header={<span>{CHANGE_TIMEZONE}</span>} key={CHANGE_TIMEZONE}>
          <div id="user_timezone" className="relative">
            <Row gutter={GUTTER}>
              <Col span="24">
                <Form.Item
                  label="Timezone"
                  name="time_zone"
                  rules={[
                    { required: true, message: `${TEXT.TIMEZONE_SELECT}` },
                  ]}
                >
                  <Select
                    getPopupContainer={(): IGetPopupContainer =>
                      document.getElementById('user_timezone')
                    }
                  >
                    {TIMEZONE.map((data, idx) => {
                      return (
                        <Option value={data.utc[0]} key={idx}>
                          {data.text}
                        </Option>
                      );
                    })}
                  </Select>
                </Form.Item>
              </Col>
            </Row>
          </div>
        </Panel>
      </Collapse>
      <Collapse
        defaultActiveKey={JOB_INFORMATION}
        bordered={false}
        ghost={true}
        expandIcon={({ isActive }) => (
          <CaretRightOutlined rotate={isActive ? 90 : 0} />
        )}
      >
        <Panel header={<span>{JOB_INFORMATION}</span>} key={JOB_INFORMATION}>
          <Row gutter={GUTTER}>
            <Col span="12">
              <div id="user_role" className="relative">
                <FormItem
                  name="role"
                  label={ROLE_LABEL}
                  rules={[{ required: true }]}
                >
                  <SearchableSelect
                    disabled={true}
                    optionFilter="key"
                    placeholder={ROLE_PLACEHOLDER}
                    getPopupContainer={(): IGetPopupContainer =>
                      document.getElementById('user_country_list')
                    }
                  >
                    {showRolesOptions}
                  </SearchableSelect>
                </FormItem>
              </div>
            </Col>

            <Col span="12">
              <div id="user_branch" className="relative">
                <FormItem
                  name="branch"
                  label={LABEL.BRANCH}
                  rules={[{ required: true }]}
                >
                  <SearchableSelect
                    disabled={true}
                    optionFilter="key"
                    placeholder={PLACEHOLDER.SELECT_BRANCH}
                    getPopupContainer={(): IGetPopupContainer =>
                      document.getElementById('user_branch')
                    }
                  >
                    {showBranchOptions}
                  </SearchableSelect>
                </FormItem>
              </div>
            </Col>

            <Col span="12">
              <div id="user_department" className="relative">
                <FormItem
                  name="department"
                  label={LABEL.DEPARTMENT}
                  rules={[{ required: false }]}
                >
                  <SearchableSelect
                    disabled={true}
                    optionFilter="key"
                    placeholder={PLACEHOLDER.SELECT_DEPARTMENT}
                    getPopupContainer={(): IGetPopupContainer =>
                      document.getElementById('user_department')
                    }
                  ></SearchableSelect>
                </FormItem>
              </div>
            </Col>
          </Row>
        </Panel>
      </Collapse>
    </Form>
  );
};

export default UserProfileForm;
