import React from 'react';
import {
  FileExcelOutlined,
  FileImageOutlined,
  FileTextOutlined,
  FilePdfOutlined,
  FileWordOutlined,
  FilePptOutlined,
  FileUnknownOutlined,
} from '@ant-design/icons';

const DocumentFileIcons = ({ mimetype }: { mimetype: string | undefined }) => {
  return (
    (mimetype?.match(/(image)/) && (
      <FileImageOutlined className="profile_document_file_icon" />
    )) ||
    (mimetype?.match(/(pdf)/) && (
      <FilePdfOutlined className="profile_document_file_icon" />
    )) ||
    (mimetype?.match(/(ms-excel|spreadsheetml)/) && (
      <FileExcelOutlined className="profile_document_file_icon" />
    )) ||
    (mimetype?.match(/(text|json)/) && (
      <FileTextOutlined className="profile_document_file_icon" />
    )) ||
    (mimetype?.match(/(msword|wordprocessingml)/) && (
      <FileWordOutlined className="profile_document_file_icon" />
    )) ||
    (mimetype?.match(/(ms-powerpoint|presentationml)/) && (
      <FilePptOutlined className="profile_document_file_icon" />
    )) || <FileUnknownOutlined className="profile_document_file_icon" />
  );
};

export { DocumentFileIcons };
