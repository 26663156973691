import axios from './axios';
import { IResponse } from '@shared-components/models';

const getContactReport = async (params = ''): Promise<IResponse> => {
  return axios.get(`/admin/contact/report/${params}`);
};

const getApplicationReport = async (params = ''): Promise<IResponse> => {
  return axios.get(`/applications/admin/report/${params}`);
};

const getAdminContactsReportFilterData = async (): Promise<IResponse> => {
  return axios.get(`/admin/contact/report-filter-data`);
};

const getAdminApplicationReportFilterData = async (): Promise<IResponse> => {
  const response = await axios.get(`/applications/admin/report-filter-data`);
  return response?.data;
};
export {
  getContactReport,
  getApplicationReport,
  getAdminContactsReportFilterData,
  getAdminApplicationReportFilterData,
};
