import { useQuery } from '@tanstack/react-query';
import { errorNotificationHandler } from '@moxie/shared';
import { getDeals } from '@crm/libs/services.api/lib/deals.api';

export const useFetchDeals = (filters: any, enabled?: boolean) => {
  const { data, isLoading } = useQuery({
    queryKey: ['deals-listing', filters],
    queryFn: async () => getDeals(filters),
    enabled: enabled ?? true,
    onError: () => errorNotificationHandler('Error fetching deals.'),
  });

  return {
    deals: data?.data?.data ?? [],
    isLoading,
    meta: data?.data?.meta,
    hasMorePage: Number(data?.data?.meta?.totalItems) <= 10,
  };
};
