import React, { useEffect, useState } from 'react';
import { Link, useHistory, useParams } from 'react-router-dom';
import { Button, Spin } from 'antd';
import { useDispatch } from 'react-redux';
import { LeftOutlined } from '@ant-design/icons';
import { useAppSelector, institutionActions } from '@admin/core';
import { TEXT, URL_INSTITUTE_ADMIN } from '@moxie/constants';
import { OverViewHeader } from '@shared-components/elements';
import { errorHandler } from '@moxie/utils';
import {
  InstitutionDetailContent,
  InstitutionDetailProfile,
} from '@admin/shared';

const useApiDetail = () => {
  const { id }: { id: string } = useParams();
  const dispatch = useDispatch();
  const history = useHistory();
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    const fetchInstitutionData = (id: any) => {
      try {
        setLoading(true);
        dispatch(institutionActions.getInstitutionRequest(id));
      } catch (err) {
        errorHandler(err);
      } finally {
        setLoading(false);
      }
    };
    if (id) {
      fetchInstitutionData(id);
    } else {
      history.goBack();
    }
    return () => {
      dispatch(institutionActions.clearFetchInstitution());
    };
  }, [id, dispatch, history]);

  const error = useAppSelector((state) => state.institutions.error);

  return [loading, error];
};

const InstitutionDetail: React.FC = () => {
  const institutionDetail = useAppSelector(
    (state) => state.institutions.institution
  );
  const dataLoading = useAppSelector((state) => state.institutions.loading);
  useApiDetail();
  return (
    <Spin spinning={dataLoading} tip={TEXT.LOADING}>
      <OverViewHeader
        title={TEXT.INSTITUTE}
        actions={
          <Link to={URL_INSTITUTE_ADMIN}>
            <Button icon={<LeftOutlined />}>{TEXT.INSTITUTE_LIST}</Button>
          </Link>
        }
      />
      <InstitutionDetailProfile
        loading={dataLoading}
        data={institutionDetail}
      />
      <InstitutionDetailContent data={institutionDetail} />
    </Spin>
  );
};

export default InstitutionDetail;
