/* eslint-disable react-hooks/exhaustive-deps */
import React from 'react';
import { Button, Space } from 'antd';
import { useForm } from 'antd/lib/form/Form';
import { DrawerElem } from '@shared-components/elements';
import {
  IAddEditProps,
  ILanguageTestScore,
  ITest,
  ITestType,
  ITestTypeForForm,
} from '@shared-components/models';
import {
  ADD_LANGUAGE_TEST_SCORE,
  CANCEL_BTN,
  EDIT_LANGUAGE_TEST_SCORE,
  LABEL,
  SAVE_BTN,
  DRAWER_WIDTH,
  UPDATE_BTN,
} from '@moxie/constants';
import { useDispatch } from 'react-redux';
import { LanguageTestScoreForm } from '@shared-components/forms';

import { languageTestScoreActions, useAppSelector } from '@lyra/core';

const LanguageTestScoreAddEdit: React.FC<IAddEditProps<ITestTypeForForm>> = ({
  isOpen,
  name,
  onClose,
  id,
  initialData,
  disabled,
  setDisabled,
}: IAddEditProps<ITestTypeForForm>) => {
  const educationId = initialData?.id;
  const [form] = useForm();
  const dispatch = useDispatch();
  const socket: any = useAppSelector((state) => state.socket.wss);
  const user = useAppSelector((state) => state.auth.user);
  const currentLead = useAppSelector((state) => state.lead.lead);
  const handleSubmitSuccess = () => {
    form.resetFields();
    onClose();
  };
  const handleSubmitButtonClick = () => {
    form.submit();
  };

  const handleSubmit = async (data: any) => {
    const copyData = { ...data };
    const overallScore = copyData.overall_score;
    delete copyData.overall_score;
    const updatedData: ITestType[] = Object.keys(copyData).map(
      (key: string) => {
        const returnObj: ITestType = {
          score: data[key],
          name: key,
        };
        if (key === LABEL.LISTENING.toLowerCase())
          returnObj.id = initialData?.listening_test_id;
        if (key === LABEL.READING.toLowerCase())
          returnObj.id = initialData?.reading_test_id;
        if (key === LABEL.WRITING.toLowerCase())
          returnObj.id = initialData?.writing_test_id;
        if (key === LABEL.SPEAKING.toLowerCase())
          returnObj.id = initialData?.speaking_test_id;

        return returnObj;
      }
    );
    const returnTestData: ITest = {
      name: name ? name.toLowerCase() : '',
      overall_score: overallScore,
      test_types: updatedData,
    };
    if (initialData?.id) {
      returnTestData.test_score_id = initialData.test_score_id;
      returnTestData.id = initialData?.id;
    }
    const returnData: ILanguageTestScore = {
      parent_id: id,
      id: returnTestData.test_score_id,
      tests: returnTestData,
    };
    if (!initialData?.id) {
      dispatch(
        languageTestScoreActions.addLanguageTestScoreRequest(
          returnData,
          user,
          socket,
          currentLead,
          onClose
        )
      );
      handleSubmitSuccess();
    } else {
      const data: any = {
        id: returnData?.tests?.id,
        ...returnData,
      };
      dispatch(
        languageTestScoreActions.updateLanguageTestScoreRequest(
          data,
          user,
          socket,
          currentLead,
          onClose
        )
      );
      handleSubmitSuccess();
    }
  };

  return (
    <DrawerElem
      title={educationId ? EDIT_LANGUAGE_TEST_SCORE : ADD_LANGUAGE_TEST_SCORE}
      width={DRAWER_WIDTH}
      onClose={onClose}
      visible={isOpen}
      footer={
        <div className="padding-bottom-2 text-align-right">
          <Space direction="horizontal">
            <Button onClick={onClose} className="lead-margin-right">
              {CANCEL_BTN}
            </Button>
            <Button
              onClick={handleSubmitButtonClick}
              type="primary"
              disabled={disabled}
            >
              {initialData?.id ? UPDATE_BTN : SAVE_BTN}
            </Button>
          </Space>
        </div>
      }
    >
      <LanguageTestScoreForm
        form={form}
        name={name}
        onSubmit={handleSubmit}
        initialData={{ ...initialData }}
        setDisabled={setDisabled}
      />
    </DrawerElem>
  );
};

export { LanguageTestScoreAddEdit };
