import React, { useEffect } from 'react';
import { OfficeBranchDetailComponent } from '@moxie/shared';
import { useParams } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import { departmentActions, officeBranchActions } from '@lyra/core';
import { errorHandler } from '@moxie/utils';

const OfficeBranchDetail: React.FC = () => {
  const { id }: { id: string } = useParams();
  const dispatch = useDispatch();

  useEffect(() => {
    if (id) {
      try {
        dispatch(officeBranchActions.getOneRequest(id));
      } catch (err) {
        errorHandler(err);
      }
    }
    dispatch(departmentActions.getDepartmentsRequest());
    return () => {
      dispatch(officeBranchActions.clearOfficeBranch());
    };
  }, [id, dispatch]);

  return <OfficeBranchDetailComponent />;
};

export default OfficeBranchDetail;
