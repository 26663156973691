import React, { useState } from 'react';
import { Typography } from 'antd';
import { Button } from '@moxie/shared';
import { IAppointmentDetailUtil } from '@shared-components/models';

const AppointmentDetailUtil: React.FC<IAppointmentDetailUtil> = ({
  index,
  description,
  count = 100,
}: IAppointmentDetailUtil) => {
  const [showMore, setShowMore] = useState(false);
  const formatTextWithTag = (descriptionData: any) => {
    const textToShow = descriptionData?.substring(0, count);
    return (
      <p
        dangerouslySetInnerHTML={{
          __html: textToShow,
        }}
      ></p>
    );
  };
  const handleParagraph = () => {
    setShowMore(!showMore);
  };

  return (
    <div className="text-justify">
      {showMore ? (
        <Typography.Text key={index}>
          <p
            dangerouslySetInnerHTML={{
              __html: description,
            }}
          ></p>
        </Typography.Text>
      ) : (
        <Typography.Text key={index}>
          {formatTextWithTag(description)}
        </Typography.Text>
      )}
      {description && description?.trim().length >= count && (
        <Button type="link" size="small" onClick={handleParagraph} key={index}>
          {showMore ? (
            <Typography.Text italic>Show Less</Typography.Text>
          ) : (
            <Typography.Text italic>Show More...</Typography.Text>
          )}
        </Button>
      )}
    </div>
  );
};

export { AppointmentDetailUtil };
