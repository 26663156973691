import React, { useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import { Form, Input, Button, Card, Row, Col, Upload, Space, Spin } from 'antd';
import { LoadingOutlined, PlusOutlined } from '@ant-design/icons';
import { useAppSelector } from '@crm/core';
import { PageHeaderMenu, PageHeaderTitle } from '@shared-components/elements';
import {
  ACTIONS,
  AUTH_MESSAGE,
  COMPANY_FORM_RESPONSE,
  CRM_COOKIE_ACCESS,
  GUTTER,
  LABEL,
  PAGE_HEADER_MENU_PREFRENCE,
  PREFERENCE_TITLE,
  regex,
  UPLOAD_RESPONSE_MESSAGE,
  VALIDATION_MESSAGE,
} from '@moxie/constants';
import { useProfile } from '../dashboard-profile/dashboard-profile';
import { IUser } from '@shared-components/models';
import { updateCompany } from '@crm/services.api';
import { errorHandler } from '@moxie/utils';
import cookies from 'js-cookie';
import {
  errorNotificationHandler,
  successNotificationHandler,
} from '@moxie/shared';
import { useForm } from 'antd/lib/form/Form';

const PrefrencesSettings: React.FC = () => {
  const statUser = useAppSelector<IUser | null>((state) => state.auth.user);
  const [loading, setloading] = useState(false);
  const [imageUrl, setimageUrl] = useState('');
  const { name } = useParams<{ name: string }>();
  const [form] = useForm();
  const [disabled, setDisabled] = useState(true);

  const initialValues = {
    company_name: statUser?.company?.company_name,
    company_phone: statUser?.company?.company_phone,
    company_fax: statUser?.company?.company_fax,
    company_email: statUser?.company?.company_email,
    company_website: statUser?.company?.company_domain,
    image: statUser?.company?.company_logo,
  };
  function getBase64(img: any, callback: any) {
    const reader = new FileReader();
    reader.addEventListener('load', () => callback(reader.result));
    reader.readAsDataURL(img);
  }

  function beforeUpload(file: any) {
    const isJpgOrPng = file.type === 'image/jpeg' || file.type === 'image/png';
    if (!isJpgOrPng) {
      errorNotificationHandler(UPLOAD_RESPONSE_MESSAGE.IMAGE_TYPE);
    }
    const isLt2M = file.size / 1024 / 1024 < 2;
    if (!isLt2M) {
      errorNotificationHandler(UPLOAD_RESPONSE_MESSAGE.IMAGE_SIZE);
    }
    return isJpgOrPng && isLt2M;
  }

  const handleuploadChange = (info: any) => {
    if (info.file.status === 'uploading') {
      setloading(true);
      return;
    }
    if (info.file.status === 'done') {
      getBase64(info.file.originFileObj, (imageUrl: string) => {
        setimageUrl(imageUrl);
        setloading(false);
      });
    }
    if (info.file.status === 'error') {
      setloading(false);
      errorNotificationHandler(UPLOAD_RESPONSE_MESSAGE.IMAGE_TYPE);
    }
  };
  const handleSubmit = async (data: {
    company_name: string;
    company_email: string;
    company_phone: string;
    company_fax: string;
  }) => {
    setloading(true);
    setDisabled && setDisabled(true);
    try {
      const dataobj = {
        company_name: data.company_name,
        company_phone: data.company_phone,
        company_fax: data.company_fax,
      };
      const updated = await updateCompany(dataobj);
      if (updated.data.success) {
        form.setFieldsValue(dataobj);
      }

      successNotificationHandler(AUTH_MESSAGE.COMPANY_PROFILE_UPDATED);
    } catch (err) {
      errorHandler(err);
    } finally {
      setloading(false);
    }
  };

  useProfile();

  useEffect(() => {
    if (!statUser) return;
    setimageUrl(statUser?.company?.company_logo);
  }, [statUser]);

  const uploadButton = (
    <div className="upload">
      {loading ? <LoadingOutlined /> : <PlusOutlined />}
      <div className="margin-top-1">{ACTIONS.UPLOAD}</div>
    </div>
  );

  const handleCancel = () => {
    setDisabled && setDisabled(true);
    form.setFieldsValue(initialValues);
  };
  const onFieldsChange = (allFields: string | any[]) => {
    if (allFields.length > 0) {
      setDisabled(false);
    }
  };
  return (
    <>
      <PageHeaderTitle title={PREFERENCE_TITLE} />
      <PageHeaderMenu
        menu={PAGE_HEADER_MENU_PREFRENCE}
        defaultActiveKey={name}
      />
      {statUser && (
        <Spin spinning={loading}>
          <Card className="margin-top-2">
            <Form
              name="basic"
              layout="vertical"
              initialValues={initialValues}
              onFinish={(val) => handleSubmit(val)}
              autoComplete="off"
              form={form}
              onFieldsChange={onFieldsChange}
            >
              <Row gutter={16}>
                <Col lg={4}>
                  <div className="upload">
                    <h4 className="upload__title">
                      {COMPANY_FORM_RESPONSE.COMPANY_PROFILE}
                    </h4>
                    <Upload
                      name="image"
                      listType="picture-card"
                      className="avatar-uploader"
                      showUploadList={false}
                      beforeUpload={beforeUpload}
                      onChange={handleuploadChange}
                      action={`${process.env.NX_BASE_URL}/company`}
                      method="put"
                      headers={{
                        authorization: `Bearer ${cookies.get(
                          CRM_COOKIE_ACCESS
                        )}`,
                      }}
                    >
                      {imageUrl ? (
                        <img
                          className="upload__img"
                          src={imageUrl}
                          alt="avatar"
                        />
                      ) : (
                        uploadButton
                      )}
                    </Upload>
                    <div className="upload__info">
                      <p>{COMPANY_FORM_RESPONSE.PREFERRED}</p>
                      <p>{COMPANY_FORM_RESPONSE.PREFERRED_UPLOAD_LIST_TYPE}</p>
                      <p>
                        {COMPANY_FORM_RESPONSE.PREFERRED_UPLOAD_LIST_RESOLUTION}
                      </p>
                      <p>{COMPANY_FORM_RESPONSE.PREFERRED_UPLOAD_LIST_SIZE}</p>
                    </div>
                  </div>
                </Col>
                <Col lg={20}>
                  <Row gutter={GUTTER}>
                    <Col lg={12}>
                      <Form.Item
                        label={LABEL.COMPANY_NAME}
                        name="company_name"
                        rules={[
                          {
                            required: true,
                            message: COMPANY_FORM_RESPONSE.COMPANY_NAME_REQ,
                          },
                          {
                            pattern: /^\S.*/,
                            message:
                              COMPANY_FORM_RESPONSE.COMPANY_NAME_NO_SPACE_IN_BEGINNING,
                          },
                        ]}
                      >
                        <Input />
                      </Form.Item>
                    </Col>
                    <Col lg={12}>
                      <Form.Item
                        label={LABEL.COMPANY_EMAIL}
                        name="company_email"
                        rules={[
                          {
                            required: true,
                            message: COMPANY_FORM_RESPONSE.COMPANY_EMAIL_REQ,
                          },
                        ]}
                      >
                        <Input disabled />
                      </Form.Item>
                    </Col>
                  </Row>
                  <Row gutter={GUTTER}>
                    <Col lg={12}>
                      <Form.Item
                        label={LABEL.COMPANY_PHONE}
                        name="company_phone"
                        rules={[
                          {
                            type: 'string',
                            min: 10,
                            pattern: new RegExp(regex.ONLY_NUMBER),
                            message: VALIDATION_MESSAGE.INVALID_PHONE_NUMBER,
                          },
                        ]}
                      >
                        <Input />
                      </Form.Item>
                    </Col>
                    <Col lg={12}>
                      <Form.Item label="Company Fax" name="company_fax">
                        <Input />
                      </Form.Item>
                    </Col>
                  </Row>

                  <Form.Item
                    label={LABEL.COMPANY_WEBSITE}
                    name="company_website"
                  >
                    <Input disabled />
                  </Form.Item>

                  <Form.Item>
                    <Space size={8}>
                      <Button
                        type="default"
                        htmlType="button"
                        onClick={handleCancel}
                      >
                        {ACTIONS.CANCEL}
                      </Button>
                      <Button
                        type="primary"
                        htmlType="submit"
                        disabled={disabled}
                      >
                        {ACTIONS.SAVE_CHANGES}
                      </Button>
                    </Space>
                  </Form.Item>
                </Col>
              </Row>
            </Form>
          </Card>
        </Spin>
      )}
    </>
  );
};

export default PrefrencesSettings;
