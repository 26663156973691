import React from 'react';
import {
  Card,
  Avatar,
  Col,
  Row,
  Space,
  Tag,
  Tooltip,
  DatePicker,
  Skeleton,
} from 'antd';
import { LABEL, TEXT } from '@moxie/constants';
import { IProfileSection } from '@shared-components/models';
import moment from 'moment';

const TenantProfile: React.FC<IProfileSection> = (props: IProfileSection) => {
  const { data, loading } = props;

  return (
    <section className="profile">
      <Card>
        <Row gutter={24}>
          <Col lg={8} className="custom_divider">
            {loading ? (
              <Skeleton avatar paragraph={{ rows: 2 }} active />
            ) : (
              <div className="profile__info">
                <div className="profile__info__item profile__info__institute">
                  <Avatar size="large">
                    {data?.company_name?.substring(0, 2).toUpperCase()}
                  </Avatar>
                  <div className="profile__info__user__detail initial_capital">
                    <h3>{data?.company_name}</h3>
                  </div>
                </div>
                <div className="profile__info__item">
                  <Space size={8} className="align-items-center">
                    <h4 className="institute-profile ">{LABEL.PLAN_TYPE}:</h4>
                    <span className="profile__link initial_capital">
                      {data?.price_plan}
                    </span>
                  </Space>
                </div>
                <div className="profile__info__item">
                  <Space size={8} className="align-items-center">
                    <h4 className="institute-profile">
                      {LABEL.EXPIRATION_DATE}:
                    </h4>
                    <span className="profile__link">
                      <Tag>
                        {data?.expiration_date
                          ? moment(data?.expiration_date).format('LL')
                          : '-'}
                      </Tag>
                    </span>
                  </Space>
                </div>
              </div>
            )}
          </Col>
          <Col lg={8} className="custom_divider">
            {loading ? (
              <Skeleton paragraph={{ rows: 3 }} title={false} active />
            ) : (
              <>
                <div>
                  <Space size={8} className=" align-items-center">
                    <h4 className="institute-profile">{TEXT.EMAIL}:</h4>
                    <Tooltip title={data?.company_email}>
                      <span className="profile__link">
                        {data?.company_email.toLowerCase()}
                      </span>
                    </Tooltip>
                  </Space>
                </div>
                <div className="profile__info__item">
                  <Space size={8} className=" align-items-center">
                    <h4 className="institute-profile">{LABEL.DOMAIN_NAME}:</h4>
                    <span>{data?.company_domain}</span>
                  </Space>
                </div>
                <div className="profile__info__item">
                  <Space size={8} className="align-items-center">
                    <h4 className="institute-profile">{TEXT.PHONE}:</h4>
                    <span>{data?.company_phone}</span>
                  </Space>
                </div>
              </>
            )}
          </Col>
          <Col lg={8}>
            <div className="profile__info__item">
              <div className="profile__header">
                <h4 className="profile__title align-items-center margin-0">
                  {TEXT.OVERVIEW}:
                </h4>
                <DatePicker picker="year" />
              </div>
              <div className="profile__overview">
                <div className="profile__overview__item">
                  <h4>Completed</h4>
                  <span>0</span>
                </div>
                <div className="profile__overview__item">
                  <h4>Completed</h4>
                  <span>0</span>
                </div>
              </div>
              <div className="profile__overview">
                <div className="profile__overview__item">
                  <h4>Completed</h4>
                  <span>0</span>
                </div>
                <div className="profile__overview__item">
                  <h4>Completed</h4>
                  <span>0</span>
                </div>
              </div>
            </div>
          </Col>
        </Row>
      </Card>
    </section>
  );
};

export { TenantProfile };
