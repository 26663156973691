import React, { useEffect } from 'react';
import { Col, Form, Row, Upload } from 'antd';
import Title from 'antd/lib/typography/Title';
import { InboxOutlined, PlusOutlined, FileTextFilled } from '@ant-design/icons';
import { Input, TextArea } from '@shared-components/elements';
import { ILeadsForm, ILeadsFormComponent } from '@shared-components/models';
import {
  PersonalDetails,
  ContactDetails,
  AddressDetails,
  VisaInformation,
  EducationBackground,
  EnglishTestScore,
  OtherTestScore,
  OtherDetails,
  Compliance,
} from '.';
import { GUTTER, LABEL, PLACEHOLDER, TITLE } from '@moxie/constants';
const { Dragger } = Upload;

const LeadsForm: React.FC<ILeadsFormComponent> = ({
  onChange,
  leadForm,
  setLeadForm,
  isEdit,
  handleFile,
  form,
  handleFormSubmit,
  setDisabled,
}: ILeadsFormComponent) => {
  const SETTING = {
    showUploadList: false,
    accept: '.jpg, .jpeg, .png',
    maxCount: 1,
    multiple: false,
    customRequest: handleFile,
  };

  useEffect(() => {
    if (leadForm && form) {
      form.setFieldsValue(leadForm);
    }
  }, [form]);

  const handleFinish = async (data: ILeadsForm) => {
    handleFormSubmit?.(data);
    form?.resetFields();
  };

  const onFieldsChange = (allFields: string | any[]) => {
    if (allFields.length > 0) {
      setDisabled(false);
    }
  };

  return (
    <Form
      form={form}
      layout="vertical"
      onFinish={handleFinish}
      scrollToFirstError={true}
      onFieldsChange={onFieldsChange}
    >
      <div>
        <Form.Item
          label={TITLE.SAVE_FORM_AS}
          name="form_name"
          rules={[{ required: true, message: PLACEHOLDER.ENTER_FORM_NAME }]}
        >
          <Input
            size="large"
            disabled={isEdit}
            placeholder={PLACEHOLDER.FORM_NAME}
            type="text"
            prefix={<FileTextFilled className="theme__color" />}
          />
        </Form.Item>
      </div>
      <br />
      <div>
        <Dragger {...SETTING} name="cover_image_path">
          {!leadForm?.cover_image_path ? (
            <>
              <PlusOutlined />
              <p className="ant-upload-text">{TITLE.ADD_COVER_IMAGE}</p>
              <p className="ant-upload-text">{TITLE.DRAG_TO_UPLOAD}</p>
              <p className="ant-upload-hint">{TITLE.UPLOAD_SINGLE_IMAGE}</p>
            </>
          ) : (
            <img
              className="cover__image__div"
              src={
                leadForm.cover_image_path_preview || leadForm.cover_image_path
              }
              alt="Cover"
            />
          )}
        </Dragger>
      </div>
      <br />

      <div className="margin-bottom-2">
        <Title className="margin-bottom-2" level={4}>
          {TITLE.FORM_HEADER}
        </Title>
        <Row justify="start" gutter={GUTTER}>
          <Col span={6}>
            <Dragger {...SETTING} name="logo_path" className="logo__height">
              {!leadForm?.logo_path ? (
                <>
                  <p className="ant-upload-drag-icon">
                    <InboxOutlined />
                  </p>
                  <p className="ant-upload-hint">{TITLE.UPLOAD_LOGO}</p>
                </>
              ) : (
                <img
                  className="logo__size"
                  src={leadForm.logo_path_preview || leadForm.logo_path}
                  alt="logo"
                />
              )}
            </Dragger>
          </Col>
          <Col span={18}>
            <Form.Item label={LABEL.FORM_TITLE}>
              <Input
                value={leadForm?.form_title}
                type="text"
                name="form_title"
                placeholder={PLACEHOLDER.FORM_TITLE}
                onChange={onChange}
              />
            </Form.Item>
            <Form.Item label={LABEL.HEADER_TEXT}>
              <TextArea
                value={leadForm?.header_text}
                placeholder={PLACEHOLDER.HEADER_TEXT}
                autoSize={{ minRows: 3, maxRows: 3 }}
                name="header_text"
                onChange={onChange}
              />
            </Form.Item>
          </Col>
        </Row>
      </div>
      <br />
      <div>
        <Title className="margin-bottom-2" level={4}>
          {TITLE.CHOOSE_FORM_FIELDS}
        </Title>
        <PersonalDetails
          setLeadForm={setLeadForm}
          leadForm={leadForm}
          setDisabled={setDisabled}
        />
        <ContactDetails
          setLeadForm={setLeadForm}
          leadForm={leadForm}
          setDisabled={setDisabled}
        />
        <AddressDetails
          setLeadForm={setLeadForm}
          leadForm={leadForm}
          setDisabled={setDisabled}
        />
        <VisaInformation
          setLeadForm={setLeadForm}
          leadForm={leadForm}
          setDisabled={setDisabled}
        />
        <EducationBackground
          setLeadForm={setLeadForm}
          leadForm={leadForm}
          setDisabled={setDisabled}
        />
        <EnglishTestScore
          setLeadForm={setLeadForm}
          leadForm={leadForm}
          setDisabled={setDisabled}
        />
        <OtherTestScore
          setLeadForm={setLeadForm}
          leadForm={leadForm}
          setDisabled={setDisabled}
        />
        <OtherDetails
          setLeadForm={setLeadForm}
          leadForm={leadForm}
          setDisabled={setDisabled}
        />
      </div>
      <br />
      <div>
        <Title className="margin-bottom-2" level={4}>
          {TITLE.GDPR_COMPLIANCE}
        </Title>
        <Compliance
          leadForm={leadForm}
          setLeadForm={setLeadForm}
          setDisabled={setDisabled}
        />
      </div>
    </Form>
  );
};

export default LeadsForm;
