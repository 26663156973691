import { IAction, ILead } from '@shared-components/models';
import * as actionTypes from './client.constant';

export interface IClientState {
  clients: ILead[];
  client: ILead;
  error: boolean | null;
  loading: boolean;
  redirect: boolean;
  downloading?: boolean;
}
const initialClientState: IClientState = {
  clients: [],
  client: {},
  error: null,
  loading: false,
  redirect: false,
  downloading: false,
};
export const CLIENT_FEATURE_KEY = 'clients';

export const clientReducer = (
  state = initialClientState,
  action: IAction<any>
): any => {
  switch (action.type) {
    case actionTypes.GET_CLIENTS_REQUEST:
      return { ...state, loading: true };
    case actionTypes.GET_CLIENTS_SUCCESS:
      return {
        ...state,
        loading: false,
        error: null,
        clients: [...action.payload],
      };
    case actionTypes.GET_CLIENTS_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.payload,
      };
    case actionTypes.GET_CLIENT_REQUEST:
      return { ...state, loading: true };
    case actionTypes.GET_CLIENT_SUCCESS:
      return {
        ...state,
        loading: false,
        redirect: false,
        error: null,
        client: action.payload,
      };
    case actionTypes.GET_CLIENT_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.payload,
      };
    case actionTypes.CLEAR_CLIENT:
      return {
        ...state,
        client: null,
        downloading: false,
      };
    case actionTypes.SET_REDIRECT:
      return {
        ...state,
        loading: false,
        redirect: true,
      };
    case actionTypes.SET_ERROR:
      return {
        ...state,
        loading: false,
        error: action.payload,
      };
    case actionTypes.DOWNLOADING:
      return {
        ...state,
        downloading: action.payload,
        redirect: false,
      };
    default:
      return state;
  }
};
