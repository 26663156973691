const alias = 'DEPARTMENT';

export const GET_DEPARTMENTS_REQUEST = `GET_${alias}S_REQUEST`;
export const GET_DEPARTMENTS_SUCCESS = `GET_${alias}S_SUCCESS`;
export const GET_DEPARTMENTS_FAILURE = `GET_${alias}_FAILURE`;

export const GET_DEPARTMENT_REQUEST = `GET_${alias}_REQUEST`;
export const GET_DEPARTMENT_SUCCESS = `GET_${alias}_SUCCESS`;
export const GET_DEPARTMENT_FAILURE = `GET_${alias}_FAILURE`;
export const CLEAR_DEPARTMENT_FETCH = `CLEAR_${alias}_FETCH`;

export const ADD_DEPARTMENT_REQUEST = `ADD_${alias}_REQUEST`;
export const ADD_DEPARTMENT_SUCCESS = `ADD_${alias}_SUCCESS`;
export const ADD_DEPARTMENT_FAILURE = `ADD_${alias}_FAILURE`;

export const UPDATE_DEPARTMENT_REQUEST = `UPDATE_${alias}_REQUEST`;
export const UPDATE_DEPARTMENT_SUCCESS = `UPDATE_${alias}_SUCCESS`;
export const UPDATE_DEPARTMENT_FAILURE = `UPDATE_${alias}_FAILURE`;

export const DELETE_DEPARTMENT_REQUEST = `DELETE_${alias}_REQUEST`;
export const DELETE_DEPARTMENT_SUCCESS = `DELETE_${alias}_SUCCESS`;
export const DELETE_DEPARTMENT_FAILURE = `DELETE_${alias}_FAILURE`;

export const GET_BRANCH_DEPARTMENTS_REQUEST = `GET_BRANCH_DEPARTMENTS_REQUEST`;
export const GET_BRANCH_DEPARTMENTS_SUCCESS = `GET_BRANCH_DEPARTMENTS_SUCCESS`;
export const GET_BRANCH_DEPARTMENTS_FAILURE = `GET_BRANCH_DEPARTMENTS_FAILURE`;
export const CLEAR_BRANCH_DEPARTMENT_FETCH = `CLEAR_BRANCH_DEPARTMENTS_FETCH`;
