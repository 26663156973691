import { MailOutlined, PhoneOutlined } from '@ant-design/icons';
import { TEXT } from '@moxie/constants';
import { IContactProfileCardProps } from '@shared-components/models';
import { Avatar, Col, Row, Tag, Tooltip } from 'antd';
import Text from 'antd/lib/typography/Text';
import React from 'react';

export const ContactProfileCard: React.FC<IContactProfileCardProps> = ({
  id,
  firstName,
  lastName,
  email,
  phone,
  status,
  branchName,
  archivedContact,
}: IContactProfileCardProps) => {
  return (
    <Row className="contact-name-card padding-top-1 padding-bottom-1">
      <Col span={2}>
        <div className="align-items-center user-name-card__avatar">
          <Avatar size={'large'} className="initial_capital">
            {firstName?.substring(0, 1)}
            {lastName?.substring(0, 1)}
          </Avatar>
        </div>
      </Col>
      <Col span={21}>
        <Row justify="space-between" className="padding-bottom-1 margin-left-1">
          {status === 'Prospect' ? (
            <>
              <Col md={22} lg={21} className="contact-profile-name">
                <Text className="text-primary-color initial_capital">
                  {`${firstName} ${lastName}`}
                </Text>
                <Text className="contact-profile-text margin-left-1">|</Text>
                <Text className="profile__title margin-left-1">{id}</Text>
              </Col>
              <Col md={2} lg={3} className="prospect-tag-position">
                {archivedContact ? (
                  <Tag className="tag-archived">{TEXT.ARCHIVED}</Tag>
                ) : (
                  ''
                )}
              </Col>
            </>
          ) : (
            <>
              <Col span={21} className="contact-profile-name">
                <Text className="text-primary-color initial_capital">
                  {`${firstName} ${lastName}`}
                </Text>
                <Text className="contact-profile-text margin-left-1">|</Text>
                <Text className="profile__title margin-left-1">{id}</Text>
              </Col>
              <Col span={3}>
                {archivedContact ? (
                  <Tag className="tag-archived">{TEXT.ARCHIVED}</Tag>
                ) : (
                  ''
                )}
              </Col>
            </>
          )}
        </Row>
        <Row justify="space-between" className="margin-left-1">
          <Col md={19} lg={18}>
            <Row>
              <Col>
                <MailOutlined className="table-mail-icon" />
              </Col>
              <Col>
                {email.length > 30 ? (
                  <Tooltip title={email} placement="left">
                    <Text className="contact-profile-email" ellipsis={true}>
                      {email?.toLocaleLowerCase()}
                    </Text>
                  </Tooltip>
                ) : (
                  <Text className="contact-profile-text">
                    {email?.toLocaleLowerCase()}
                  </Text>
                )}
              </Col>
              <Col>
                <Text className="contact-profile-text margin-left-1">|</Text>
                <PhoneOutlined className="table-mail-icon margin-left-1" />
                <Text className="contact-profile-text">{phone}</Text>
              </Col>
            </Row>
          </Col>
          <Col md={5} lg={6} className="profile-branch-position">
            <Text className="contact-profile-title">Branch:</Text>
            {branchName?.length > 15 ? (
              <Tooltip title={branchName} placement="bottom">
                <Text
                  className="contact-profile-branch padding-left-1"
                  ellipsis={true}
                >
                  {branchName}
                </Text>
              </Tooltip>
            ) : (
              <Text className="contact-profile-text padding-left-1">
                {branchName}
              </Text>
            )}
          </Col>
        </Row>
      </Col>
    </Row>
  );
};
