import React, { useState } from 'react';
import { Table, TextProductLinkField } from '@moxie/shared';
import { ColumnsType } from 'antd/lib/table';
import { LABEL, PRODUCT_TABLE, TEXT } from '@moxie/constants';
import { IInstitutionProducts, IProduct } from '@shared-components/models';
import { Tag } from 'antd';
import { instituteServices } from '@lyra/services.api';

const InstitutionProductTable = ({ id }: { id: any }) => {
  const [filter] = useState({});

  const getProductData = (params: string) => {
    return instituteServices.apiGetInstitutionProducts(id, params);
  };

  const columns: ColumnsType<IInstitutionProducts> = [
    {
      title: LABEL.NAME,
      width: 350,
      dataIndex: 'name',
      sorter: () => 0,
      render(data: string, row: IProduct) {
        return <TextProductLinkField text={data} row={row} />;
      },
    },
    {
      title: TEXT.BRANCH,
      dataIndex: 'institutionBranches',
      width: 350,
      render(data) {
        return data?.map((item: any) => (
          <Tag key={item.id}>{item?.branch?.name}</Tag>
        ));
      },
    },
    {
      title: PRODUCT_TABLE.DEGREE_LEVEL,
      dataIndex: 'level',
      width: 250,
    },
  ];

  return (
    <div className="margin-top-1">
      <Table action={getProductData} columns={columns} filter={filter} />
    </div>
  );
};

export { InstitutionProductTable };
