import React, { useEffect } from 'react';
import io from 'socket.io-client';
import { useDispatch } from 'react-redux';
import { BrowserRouter as Router, Route, Switch } from 'react-router-dom';
import Auth from './auth';
import Login from './login';
import VerifyEmail from './verify';
import { ListLeadsForm } from './leads-form';
import {
  URL_CHECK_MAIL,
  URL_DASHBOARD,
  URL_FORGET_PASSWORD,
  URL_LOGIN,
  URL_AUTH,
  // URL_LEAD,
  URL_VERIFICATION,
  URL_USER,
  URL_LEAD_FORM,
  URL_INSTITUTE,
  URL_LEAD_DETAIL,
  URL_CREATED_LEAD_FORM,
  URL_PRODUCTS,
  URL_INSTITUTE_DETAIL,
  URL_PRODUCT_DETAIL,
  URL_OFFICE,
  URL_USER_DETAIL,
  URL_VERIFY_ACCOUNT,
  URL_VERIFY_ACCOUNT_SUCCESS,
  URL_OFFICE_BRANCH_DETAIL,
  URL_DEPARTMENT,
  URL_DEPARTMENT_DETAIL,
  URL_LEAD_PRODUCT_COMPARE,
  // URL_APPOINTMENT,
  // URL_TASKS,
  URL_CONTACTS,
  // URL_PROSPECTS,
  // URL_CLIENTS,
  URL_APPLICATION,
  URL_LEAD_REPORT,
  URL_APPLICATION_REPORT,
  URL_NOTIFICATION,
} from '@moxie/constants';
import {
  ProtectedRoute,
  LogProtectedRoute,
  ProtectedRouteNoLayout,
  ScrollToTop,
} from '@moxie/shared';
import { Institution, InstitutionDetail } from './institution';
import { CreatedLeadForm } from './created-lead-form';
import { OfficeBranchDetail, OfficeBranchList } from './office-branch';
import { DepartmentDetail, DepartmentList } from './department';
import {
  SocketClient,
  socketSagaActions,
  useAppSelector,
  userActions,
} from '@lyra/core';
import DashboardRoute from './dashboard';
import NotFound from './not-found/NotFound';
import { ContactList } from './contacts';
// import { ProspectList } from './prospect/prospect-list';
// import { ClientList } from './client';
import { ApplicationListComponent } from './application';
const LazyForgetPassword = React.lazy(
  async () => await import('./forget-password')
);
const LazyProducts = React.lazy(async () => await import('./products'));
const LazyProductDetail = React.lazy(
  async () => await import('./products/product-detail')
);
const LazyUserDetail = React.lazy(
  async () => await import('./user/UserDetail/UserDetail')
);
const LazyLeadDetail = React.lazy(
  async () => await import('./lead/lead-detail')
);
// const LazyTasks = React.lazy(() => import('./tasks'));
const LazyUser = React.lazy(async () => await import('./user'));
// const LazyLead = React.lazy(async () => await import('./lead'));
const LazyProductComparison = React.lazy(
  async () => await import('./product-comparison')
);
// const LazyAppointment = React.lazy(async () => await import('./appointment'));
const LazyVerifyAccount = React.lazy(
  async () => await import('./auth/verify-account/verify-account')
);
const LazyVerifyAccountSuccess = React.lazy(
  async () => await import('./auth/verify-account/verify-account-success')
);
const LazyCheckMail = React.lazy(async () => await import('./check-mail'));
const LazyContactReport = React.lazy(
  async () => await import('./report/contact')
);
const LazyApplicationReport = React.lazy(
  async () => await import('./report/application')
);
const LazyNotification = React.lazy(async () => await import('./notification'));

const App: React.FC = () => {
  const isAuthenticated = useAppSelector((state) => state.auth.isAuthenticated);
  const authUser = useAppSelector((state) => state.auth.user);
  const dispatch = useDispatch();
  useEffect(() => {
    const socket = io(`${process.env.NX_API_URL}`, {
      transports: ['websocket'],
    });
    dispatch({
      type: socketSagaActions.SET_SOCKET_CONNECTION,
      payload: socket,
    });
    if (authUser) {
      socket?.emit('new-user-to-server', authUser);
    }
    return () => {
      dispatch({
        type: socketSagaActions.REMOVE_SOCKET_CONNECTION,
      });
      socket.close();
    };
  }, [dispatch, authUser]);

  useEffect(() => {
    if (authUser) {
      dispatch(userActions.fetchUsers());
    }
  }, [dispatch, authUser]);
  return (
    <Router>
      {isAuthenticated && <SocketClient />}
      <ScrollToTop />
      <Switch>
        <LogProtectedRoute exact path={URL_LOGIN} component={Login} />
        <LogProtectedRoute exact path={URL_AUTH} component={Auth} />
        <LogProtectedRoute
          exact
          path={URL_VERIFICATION}
          component={VerifyEmail}
        />
        <ProtectedRoute exact path={URL_INSTITUTE} component={Institution} />
        <ProtectedRoute exact path={URL_OFFICE} component={OfficeBranchList} />
        <ProtectedRoute
          exact
          path={URL_OFFICE_BRANCH_DETAIL}
          component={OfficeBranchDetail}
        />
        <ProtectedRoute
          exact
          path={URL_DEPARTMENT}
          component={DepartmentList}
        />
        <ProtectedRoute
          exact
          path={URL_DEPARTMENT_DETAIL}
          component={DepartmentDetail}
        />
        <Route exact path={URL_CREATED_LEAD_FORM} component={CreatedLeadForm} />
        <ProtectedRoute exact path={URL_LEAD_FORM} component={ListLeadsForm} />
        <ProtectedRoute
          exact
          path={URL_INSTITUTE_DETAIL}
          component={InstitutionDetail}
        />

        <ProtectedRouteNoLayout
          path={URL_DASHBOARD}
          component={DashboardRoute}
        />
        {/* <ProtectedRoute exact path={URL_TASKS} component={LazyTasks} /> */}
        <LogProtectedRoute
          exact
          path={URL_VERIFY_ACCOUNT}
          component={LazyVerifyAccount}
        />
        <LogProtectedRoute
          exact
          path={URL_VERIFY_ACCOUNT_SUCCESS}
          component={LazyVerifyAccountSuccess}
        />
        <LogProtectedRoute
          exact
          path={URL_FORGET_PASSWORD}
          component={LazyForgetPassword}
        />

        <ProtectedRoute exact path={URL_USER} component={LazyUser} />
        <LogProtectedRoute
          exact
          path={URL_CHECK_MAIL}
          component={LazyCheckMail}
        />
        {/* <ProtectedRoute exact path={URL_LEAD} component={LazyLead} /> */}
        {/* <ProtectedRoute exact path={URL_TASKS} component={LazyTasks} /> */}
        {/* <ProtectedRoute
          exact
          path={URL_APPOINTMENT}
          component={LazyAppointment}
        /> */}
        <ProtectedRoute
          exact
          path={URL_LEAD_DETAIL}
          component={LazyLeadDetail}
        />
        <ProtectedRoute exact path={URL_PRODUCTS} component={LazyProducts} />
        <ProtectedRoute
          exact
          path={URL_PRODUCT_DETAIL}
          component={LazyProductDetail}
        />
        <ProtectedRoute
          exact
          path={URL_USER_DETAIL}
          component={LazyUserDetail}
        />

        <ProtectedRoute
          exact
          path={URL_LEAD_PRODUCT_COMPARE}
          component={LazyProductComparison}
        />
        <ProtectedRoute exact path={URL_CONTACTS} component={ContactList} />
        {/* <ProtectedRoute exact path={URL_PROSPECTS} component={ProspectList} /> */}
        {/* <ProtectedRoute exact path={URL_CLIENTS} component={ClientList} /> */}
        <ProtectedRoute
          exact
          path={URL_APPLICATION}
          component={ApplicationListComponent}
        />
        <ProtectedRoute
          exact
          path={URL_LEAD_REPORT}
          component={LazyContactReport}
        />
        <ProtectedRoute
          exact
          path={URL_APPLICATION_REPORT}
          component={LazyApplicationReport}
        />
        <ProtectedRoute
          exact
          path={URL_NOTIFICATION}
          component={LazyNotification}
        />
        <Route component={NotFound} />
      </Switch>
    </Router>
  );
};

export default App;
