import React, { useEffect, useState } from 'react';
import {
  Table as AntTable,
  Divider,
  Button,
  Dropdown,
  Menu,
  Row,
  Col,
} from 'antd';
import { PlusOutlined } from '@ant-design/icons';
import { ColumnsType } from 'antd/lib/table';
import {
  ADD_BTN,
  GUTTER,
  PRODUCT_TABLE,
  ACADEMIC_REQUIREMENT,
  ACADEMIC_REQUIREMENT_CONTENT,
  ENGLISH_LANGUAGE_REQUIREMENT,
  ENGLISH_LANGUAGE_REQUIREMENT_CONTENT,
} from '@moxie/constants';
import {
  IAcademicRequirementsTable,
  IProductDetailFees,
} from '@shared-components/models';
import { AcademicRequirementDrawer } from './academic-requirement-drawer';
import LanguageRequirementDrawer from './language-requirement-drawer';
import { languageTestScoreMenuAdmin } from './language-requirement-menu-item';
import { useParams } from 'react-router-dom';
import { productsActions, useAppSelector } from '@admin/core';
import { useDispatch } from 'react-redux';
import ProductEducationNameCard from './product-education-name-card';
import ProductLanguageNameCard from './product-language-name-card';
import { useForm } from 'antd/lib/form/Form';
import { ProductDetailUtil } from '@shared-components/modules';

export const AcademicRequirementsTable: React.FC<IAcademicRequirementsTable> = (
  props: IAcademicRequirementsTable
) => {
  const { data, className, form, productId } = props;
  const loading = useAppSelector((state) => state.product.education_loading);

  const columns: ColumnsType<any> = [
    {
      title: PRODUCT_TABLE.DEGREE_LEVEL,
      key: 'degreeLevel',
      width: 200,
      render(data: any) {
        return (
          <Row gutter={GUTTER}>
            <Col span={14} className="prd-title">
              {data?.degreeLevel?.name}
            </Col>
            <Col span={10}>
              <ProductEducationNameCard
                id={data?.id}
                form={form}
                productId={productId && productId}
              />
            </Col>
          </Row>
        );
      },
    },
    {
      title: PRODUCT_TABLE.PERCENTAGE_REQUIREMENT,
      dataIndex: 'percentage',
      key: 'percentage',
      className: 'prd-primary',
      width: 200,
      render(data: string) {
        return <span className="prd-title prd-primary">{data && data}</span>;
      },
    },
    {
      title: PRODUCT_TABLE.GPA_REQUIREMENT,
      dataIndex: 'gpa',
      key: 'gpa',
      className: 'prd-primary',
      width: 200,
      render(data: string) {
        return <span className="prd-title prd-primary">{data && data}</span>;
      },
    },
    {
      title: PRODUCT_TABLE.REMARK,
      dataIndex: 'remark',
      key: 'remark',
      className: 'prd-primary ',
      width: 350,
      render(remark: any, data, index) {
        return (
          <div className="prd-title prd-primary">
            {remark && <ProductDetailUtil index={index} remark={remark} />}
          </div>
        );
      },
    },
  ];
  return (
    <AntTable
      dataSource={data || []}
      columns={columns}
      loading={loading}
      size={'small'}
      className={className}
      pagination={false}
      scroll={{
        x: true,
      }}
    />
  );
};

export const EnglishLanguageRequirementsTable: React.FC<IAcademicRequirementsTable> = (
  props: IAcademicRequirementsTable
) => {
  const { data, className, form, productId } = props;
  const loading = useAppSelector((state) => state.product.language_loading);
  const columns: ColumnsType<any> = [
    {
      title: PRODUCT_TABLE.NAME,
      key: 'test_name',
      width: 200,
      render(data: { test_name: string } | any) {
        return (
          <Row gutter={GUTTER}>
            <Col span={12} className="prd-title uppercase">
              {data?.test_name}
            </Col>
            <Col span={12}>
              <ProductLanguageNameCard
                productId={productId && productId}
                name={data?.test_name}
                id={data?.id}
                form={form}
              />
            </Col>
          </Row>
        );
      },
    },
    {
      title: PRODUCT_TABLE.LISTENING,
      key: 'listening',
      render(data: any) {
        return (
          <span className="prd-title prd-primary">
            {
              data?.each_band?.filter(
                (band: { name: string; score: any }) =>
                  band?.name.toLowerCase() === 'listening'
              )[0]?.score
            }
          </span>
        );
      },
    },
    {
      title: PRODUCT_TABLE.WRITING,
      key: 'writing',
      render(data: any) {
        return (
          <span className="prd-title prd-primary">
            {
              data?.each_band?.filter(
                (band: { name: string; score: any }) =>
                  band?.name.toLowerCase() === 'writing'
              )[0]?.score
            }
          </span>
        );
      },
    },
    {
      title: PRODUCT_TABLE.READING,
      key: 'reading',
      render(data: any) {
        return (
          <span className="prd-title prd-primary">
            {
              data?.each_band?.filter(
                (band: { name: string; score: any }) =>
                  band?.name.toLowerCase() === 'reading'
              )[0]?.score
            }
          </span>
        );
      },
    },
    {
      title: PRODUCT_TABLE.SPEAKING,
      key: 'speaking',
      render(data: any) {
        return (
          <span className="prd-title prd-primary">
            {
              data?.each_band?.filter(
                (band: { name: string; score: any }) =>
                  band?.name.toLowerCase() === 'speaking'
              )[0]?.score
            }
          </span>
        );
      },
    },
    {
      title: PRODUCT_TABLE.OVERALL,
      dataIndex: 'overall_score',
      key: 'overall_score',
      className: 'prd-primary',
      render(data: string) {
        return <span className="prd-title prd-primary">{data && data}</span>;
      },
    },
    {
      title: PRODUCT_TABLE.REMARK,
      dataIndex: 'remark',
      key: 'remark',
      className: 'prd-primary ',
      width: 350,
      render(remark: any, data, index) {
        return (
          <div className="prd-title prd-primary">
            {remark && <ProductDetailUtil index={index} remark={remark} />}
          </div>
        );
      },
    },
  ];
  return (
    <AntTable
      dataSource={data || []}
      columns={columns}
      loading={loading}
      size={'small'}
      className={className}
      pagination={false}
      scroll={{
        x: true,
      }}
    />
  );
};

const AdminProductRequirement: React.FC<IProductDetailFees> = () => {
  const [isOpen, setIsOpen] = useState(false);
  const [isLanguageDrawer, setIsLanguageDrawer] = useState(false);
  const [languageMode, setLanguageMode] = useState<string>();
  const { id } = useParams<{ id: string }>();
  const dispatch = useDispatch();
  const [form] = useForm();
  const [languageForm] = useForm();

  const productEducations = useAppSelector(
    (state: any) => state.product.product_educations
  );

  const productLangTestScores = useAppSelector(
    (state: any) => state.product.product_languages
  );

  const handleDrawer = () => {
    setIsOpen(!isOpen);
  };

  const handleDrawerClose = () => {
    form.resetFields();
    dispatch(productsActions.getProductEducationsClear());
    setIsOpen(false);
  };
  const handleLanguageDrawer = (name: string) => {
    setLanguageMode(name);
    setIsLanguageDrawer(!isLanguageDrawer);
  };

  const handleLanguageDrawerClose = () => {
    languageForm.resetFields();
    dispatch(productsActions.getProductLanguagesClear());
    setIsLanguageDrawer(false);
  };

  useEffect(() => {
    dispatch(productsActions.getProductEducationsRequest(id));
    dispatch(productsActions.getProductLanguagesRequest(id));
  }, [id]);
  return (
    <main className="prd-main table-height">
      <section className="prd-main__academic">
        <div className="text-align-right">
          <Button onClick={handleDrawer} type="primary" icon={<PlusOutlined />}>
            {ADD_BTN}
          </Button>
        </div>
        <h1>{ACADEMIC_REQUIREMENT}</h1>
        <div className="prd-main__desc">{ACADEMIC_REQUIREMENT_CONTENT}</div>
        <AcademicRequirementsTable
          data={productEducations}
          form={form}
          isOpen={isOpen}
          productId={id}
          onClose={handleDrawerClose}
          className="margin-top-1"
        />
        <AcademicRequirementDrawer
          isOpen={isOpen}
          onClose={handleDrawerClose}
          form={form}
          productId={id}
          isEdit={false}
        />
      </section>
      <Divider />
      <section className="prd-main__langrequirements">
        <div className="text-align-right">
          <Dropdown
            overlay={languageTestScoreMenuAdmin(
              handleLanguageDrawer,
              productLangTestScores && productLangTestScores
            )}
            overlayClassName="z-index-zero"
            trigger={['click']}
            disabled={
              productLangTestScores &&
              productLangTestScores?.length >= 3 &&
              true
            }
          >
            <Button type="primary" icon={<PlusOutlined />}>
              {ADD_BTN}
            </Button>
          </Dropdown>
        </div>
        <h1>{ENGLISH_LANGUAGE_REQUIREMENT}</h1>
        <div className="prd-main__desc">
          {ENGLISH_LANGUAGE_REQUIREMENT_CONTENT}
        </div>
        <EnglishLanguageRequirementsTable
          productId={id}
          data={productLangTestScores}
          form={languageForm}
          className="margin-top-1"
        />
        <LanguageRequirementDrawer
          name={languageMode}
          isOpen={isLanguageDrawer}
          onClose={handleLanguageDrawerClose}
          form={languageForm && languageForm}
          productId={id}
          isEdit={false}
        />
      </section>
    </main>
  );
};

export { AdminProductRequirement };
