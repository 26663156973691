import React, { useEffect, useState } from 'react';
import {
  ASSIGNEE_LABEL,
  ASSIGNEE_PLACEHOLDER,
  AUDIO_TYPE,
  CATEGORIES,
  DESCRIPTION_PLACEHOLDER,
  DOCUMENT_TYPE,
  FILE_SIZE_EXCEED,
  GUTTER,
  IMAGE_TYPE,
  LABEL,
  MAXIMUM_NUMBER_OF_FILES,
  MAXIMUM_SIZE_OF_FILES,
  NO_FILES_AVAILABLE,
  NO_FILES_SELECTED,
  PLACEHOLDER,
  PRESENTATION_TYPE,
  PRIORITIES,
  RELATED_TO_LIST,
  SEARCH,
  SPREADSHEET_TYPE,
  TEXT,
  TITLE,
  TITLE_PLACEHOLDER,
  VIDEO_TYPE,
} from '@moxie/constants';
import moment from 'moment-timezone';
import {
  Col,
  DatePicker,
  Form,
  Input,
  List,
  Radio,
  Row,
  Spin,
  TimePicker,
  Upload,
} from 'antd';
import { InboxOutlined, SearchOutlined } from '@ant-design/icons';
import { getAllLead } from '@lyra/services.api';
import {
  IDocumentItem,
  IGetPopupContainer,
  ILead,
  ITaskAddEditProps,
  ITaskDocument,
} from '@shared-components/models';
import {
  errorNotificationHandler,
  FileNameComponent,
  Option,
  Select,
  UserNameCard,
  UserSelectBox,
} from '@moxie/shared';
import { TaskFileNameComponent } from '../../document/task-file-name';
import { useAppSelector } from '@lyra/core';

const TasksAddEditForm = ({
  initialData,
  form,
  isView,
  documents,
  setDocuments,
  isEditMode,
  setDisabled,
  handleSubmit,
  onFieldsChange,
}: ITaskAddEditProps) => {
  const { Dragger } = Upload;
  const [contactList, setContactList] = useState<ILead[]>([]);
  const [showContact, setShowContact] = useState<boolean>(false);
  const [taskDocuments, setTaskDocuments] = useState<ITaskDocument[]>(
    initialData?.taskDocuments
  );
  const [documentsSize, setDocumentsSize] = useState<number>(0);
  const loading = useAppSelector((store) => store.tasks.loading);
  const downloading = useAppSelector((store) => store.tasks.downloading);

  useEffect(() => {
    if (initialData === {} || isView || isEditMode) {
      form.setFieldsValue({
        ...initialData,
        due_date: initialData?.due_date ? moment(initialData?.due_date) : null,
        due_time: initialData?.due_time ? moment(initialData?.due_time) : null,
        followers_id: initialData?.followers,
      });
    } else {
      form.setFieldsValue({});
    }
  }, [form, initialData]);

  useEffect(() => {
    if (form.getFieldValue('related_to') === 'contact') {
      setShowContact(true);
    } else {
      setShowContact(false);
    }
  }, [form]);

  const handleDocument = async (files: any) => {
    setDisabled(false);
    if (files.file.size / 1024 / 1024 < 10) {
      setDocuments((data: []) => [...data, files.file]);
    } else {
      errorNotificationHandler(FILE_SIZE_EXCEED);
    }
  };

  const handleRemove = async (file: IDocumentItem | undefined) => {
    const filterDocument = documents?.filter(({ uid }: IDocumentItem) => {
      return uid !== file?.uid;
    });
    setDocuments(filterDocument);
  };

  const handleDelete = (file: ITaskDocument | undefined) => {
    if (!isEditMode) {
      return;
    }

    const filterDocument = taskDocuments?.filter((item: ITaskDocument) => {
      return item?.document?.id !== file?.document?.id;
    });

    setTaskDocuments(filterDocument);
  };

  const SETTING = {
    name: 'files',
    multiple: true,
    accept: `${PRESENTATION_TYPE}, ${DOCUMENT_TYPE}, ${IMAGE_TYPE}, ${VIDEO_TYPE}, ${SPREADSHEET_TYPE}, ${AUDIO_TYPE}`,
    showUploadList: false,
    customRequest: handleDocument,
  };

  useEffect(() => {
    fetchLead();
  }, []);

  const fetchLead = async () => {
    const res = await getAllLead();
    setContactList(res?.data?.data);
  };

  const handleDate = (currentDate: any) => {
    const customDate = moment().format('YYYY-MM-DD');
    return currentDate && currentDate < moment(customDate, 'YYYY-MM-DD');
  };

  const handleRelatedToChange = (event: any) => {
    if (event.target.value === 'contact') {
      setShowContact(true);
    } else {
      form.setFieldsValue({
        contact_id: undefined,
      });
      setShowContact(false);
    }
  };

  useEffect(() => {
    setDocumentsSize(() => {
      return documents?.reduce((acc, curr) => {
        return acc + curr.size;
      }, 0);
    });
  }, [documents]);

  return (
    <>
      <Form
        layout="vertical"
        onFinish={handleSubmit}
        scrollToFirstError
        onFieldsChange={onFieldsChange}
        form={form}
      >
        <Spin
          spinning={loading || downloading}
          tip={downloading ? TEXT.DOWNLOADING_DOCUMENT : TEXT.UPDATING_TASK}
          className="margin-top-3"
        >
          <Row gutter={GUTTER}>
            <Col span={12}>
              <Form.Item
                label={TEXT.TITLE}
                name="title"
                rules={[
                  { required: true, message: `${TEXT.TITLE_SELECT}` },
                  { max: 50, min: 5 },
                  {
                    whitespace: true,
                    type: 'string',
                    message: TEXT.TITLE_SELECT,
                  },
                ]}
              >
                <Input
                  data-testid="title"
                  className="note-title-input"
                  disabled={isView}
                  placeholder={!isView ? TITLE_PLACEHOLDER : '-'}
                />
              </Form.Item>
            </Col>
            <Col span={12}>
              <div id="task_category" className="relative">
                <Form.Item label={LABEL.CATEGORY} name="category">
                  <Select
                    className="note-title-input"
                    value={initialData?.category}
                    placeholder={!isView ? PLACEHOLDER.SELECT_CATEGORY : '-'}
                    getPopupContainer={(): IGetPopupContainer =>
                      document.getElementById('task_category')
                    }
                    disabled={isView}
                  >
                    {CATEGORIES.map((data) => {
                      return (
                        <Option value={data.value} key={data.value}>
                          {data.name}
                        </Option>
                      );
                    })}
                  </Select>
                </Form.Item>
              </div>
            </Col>
            <Col span={24}>
              <Form.Item
                label={TEXT.DESCRIPTION}
                name="description"
                rules={[{ required: true, min: 10 }]}
              >
                <Input.TextArea
                  data-testid="description"
                  disabled={isView}
                  placeholder={!isView ? DESCRIPTION_PLACEHOLDER : '-'}
                  className="textArea-task note-title-input"
                />
              </Form.Item>
            </Col>
            <Col span={12}>
              <div id="task_assignee" className="relative">
                <Form.Item label={ASSIGNEE_LABEL} name="assignee_id">
                  <UserSelectBox
                    className="note-title-input"
                    disabled={isView}
                    placeholder={!isView ? ASSIGNEE_PLACEHOLDER : '-'}
                    getPopupContainer={(): IGetPopupContainer =>
                      document.getElementById('task_assignee')
                    }
                  />
                </Form.Item>
              </div>
            </Col>
            <Col span={12}>
              <div id="task_priority" className="relative">
                <Form.Item label={TEXT.PRIORITY} name="priority">
                  <Select
                    className="note-title-input"
                    disabled={isView}
                    showSearch
                    getPopupContainer={(): IGetPopupContainer =>
                      document.getElementById('task_priority')
                    }
                    placeholder={!isView ? PLACEHOLDER.SELECT_PRIORITY : '-'}
                  >
                    {PRIORITIES.map((data, idx) => {
                      return (
                        <Option value={data.value} key={idx}>
                          {data.name}
                        </Option>
                      );
                    })}
                  </Select>
                </Form.Item>
              </div>
            </Col>
            <Col span={12}>
              <div id="task_due_date" className="relative">
                <Form.Item name="due_date" label={LABEL.DUE_DATE}>
                  <DatePicker
                    disabled={isView}
                    disabledDate={handleDate}
                    placeholder={!isView ? PLACEHOLDER.DATE : '-'}
                    className="full-width note-title-input"
                    getPopupContainer={(): IGetPopupContainer =>
                      document.getElementById('task_due_date')
                    }
                  />
                </Form.Item>
              </div>
            </Col>
            <Col span={12}>
              <div id="task_due_time" className="relative">
                <Form.Item name="due_time" label={LABEL.DUE_TIME}>
                  <TimePicker
                    minuteStep={15}
                    use12Hours
                    showNow={false}
                    disabled={isView}
                    placeholder={!isView ? PLACEHOLDER.TIME : '-'}
                    format="h:mm a"
                    className="appointment-date-picker note-title-input"
                    getPopupContainer={(): IGetPopupContainer =>
                      document.getElementById('task_due_time')
                    }
                  />
                </Form.Item>
              </div>
            </Col>
            <Col span={24}>
              <div id="task_followers" className="relative">
                <Form.Item label={LABEL.FOLLOWERS} name="followers_id">
                  <UserSelectBox
                    className="note-title-input"
                    placeholder={!isView ? PLACEHOLDER.FOLLOWERS : '-'}
                    disabled={isView}
                    mode="multiple"
                    getPopupContainer={(): IGetPopupContainer =>
                      document.getElementById('task_followers')
                    }
                  />
                </Form.Item>
              </div>
            </Col>
            <Col span={24}>
              <Form.Item label={LABEL.RELATED_TO} name="related_to">
                <Radio.Group
                  disabled={isView}
                  onChange={handleRelatedToChange}
                  className="note-title-input"
                >
                  {RELATED_TO_LIST.map((data) => (
                    <Radio value={data.value} key={data.value}>
                      {data.name}
                    </Radio>
                  ))}
                </Radio.Group>
              </Form.Item>
            </Col>
            {showContact && (
              <Col span={24}>
                <div id="task_contact">
                  <Form.Item
                    label={LABEL.CONTACT}
                    rules={[{ required: true }]}
                    name="contact_id"
                  >
                    <Select
                      placeholder={SEARCH}
                      suffixIcon={<SearchOutlined />}
                      showSearch
                      className="note-title-input"
                      disabled={isView}
                      optionLabelProp="label"
                      getPopupContainer={(): IGetPopupContainer =>
                        document.getElementById('task_contact')
                      }
                    >
                      {contactList.map((item: ILead, index) => (
                        <Option
                          value={item.id || ''}
                          key={item?.id || index}
                          label={`${item?.first_name} ${item?.last_name}`}
                          search_prop={`${item?.first_name} ${item?.last_name} ${item?.email}`}
                        >
                          <UserNameCard
                            firstName={item?.first_name || ''}
                            lastName={item?.last_name}
                            email={item?.email || ''}
                            index={item?.id || ''}
                          />
                        </Option>
                      ))}
                    </Select>
                  </Form.Item>
                </div>
              </Col>
            )}

            <Col md={24} sm={24} xs={24}>
              <Form.Item label={LABEL.ATTACHMENTS}>
                {taskDocuments && taskDocuments.length > 0 && (
                  <List
                    bordered={true}
                    dataSource={taskDocuments}
                    renderItem={(item: any) => {
                      return (
                        <TaskFileNameComponent
                          isEditMode={isEditMode}
                          item={item}
                          onRemove={handleDelete}
                          isView={isView}
                        />
                      );
                    }}
                  />
                )}

                <div>
                  {!isView ? (
                    <Dragger
                      className="dragger_height"
                      {...SETTING}
                      listType="picture"
                    >
                      <p className="ant-upload-drag-icon">
                        <InboxOutlined />
                      </p>
                      <p className="ant-upload-text">{TITLE.DRAG_TO_UPLOAD}</p>
                      <div>
                        <p className="font-size-20-px">
                          {TEXT.SELECTED_DOCUMENTS}&nbsp;
                          <span
                            style={{
                              color: documents?.length > 20 ? 'red' : 'black',
                            }}
                          >
                            ({documents?.length})
                          </span>{' '}
                          <span
                            style={{
                              color:
                                documentsSize / 1024 / 1024 > 50
                                  ? 'red'
                                  : 'black',
                            }}
                          >{` (${Math.round(
                            documentsSize / 1024 / 1024
                          )} MB)`}</span>
                        </p>
                        <p>{MAXIMUM_NUMBER_OF_FILES}: 20</p>
                        <p>{MAXIMUM_SIZE_OF_FILES}: 50 MB</p>
                        <p className="dragger_font">
                          {documents?.length === 0 ? NO_FILES_SELECTED : null}
                        </p>
                      </div>
                    </Dragger>
                  ) : (
                    <p className="dragger_font">
                      {taskDocuments?.length === 0 ? NO_FILES_AVAILABLE : null}
                    </p>
                  )}
                  <div className="padding-top-1">
                    {documents.length !== 0 ? (
                      <>
                        {!isView ? (
                          <List
                            bordered={true}
                            dataSource={documents}
                            renderItem={(item: IDocumentItem) => {
                              return (
                                <FileNameComponent
                                  item={item}
                                  documents={documents}
                                  onSetDocument={setDocuments}
                                  onRemove={handleRemove}
                                  showType={false}
                                />
                              );
                            }}
                          />
                        ) : null}
                      </>
                    ) : null}
                  </div>
                </div>
              </Form.Item>
            </Col>
          </Row>
        </Spin>
      </Form>
    </>
  );
};
export { TasksAddEditForm };
