import axios from './axios';
import {
  IUserPersonalInfo,
  IUserAddResponse,
  IUserEmailCheckRes,
} from '@shared-components/models';
import { API_URL } from '@moxie/constants';

const UserAdd = async (body: IUserPersonalInfo): Promise<IUserAddResponse> => {
  return axios.post(API_URL.ADMIN_USER_ADD, body);
};

const UserList = async (params?: string): Promise<IUserAddResponse> => {
  return axios.get(`${API_URL.ADMIN_USER_LIST}/${params}`);
};

const UserData = async (id: string): Promise<IUserAddResponse> => {
  return axios.get(`${API_URL.ADMIN_USER}/${id}`);
};

const updateUserData = async (
  id: string,
  body: IUserPersonalInfo
): Promise<IUserAddResponse> => {
  return axios.put(`${API_URL.ADMIN_USER}/${id}`, body);
};

const UserListWithoutAssignee = async (
  leadId: string
): Promise<IUserAddResponse> => {
  return axios.get(`${API_URL.USER_LIST}/admin/assignee/${leadId}`);
};

const UserEmailCheck = async (email: string): Promise<IUserEmailCheckRes | any> => {
  return axios.get(`${API_URL.ADMIN_USER}/check-user-email/?email=${email}`);
};

export {
  UserAdd,
  UserList,
  UserData,
  updateUserData,
  UserListWithoutAssignee,
  UserEmailCheck,
};
