import React from 'react';
import { Checkbox, Col, Form, Row } from 'antd';
import { ILeadsFormComponent } from '@shared-components/models';
import { GUTTER, LABEL, TITLE } from '@moxie/constants';
import { globalHandleCheck } from '@moxie/shared';

const OtherTestScore: React.FC<ILeadsFormComponent> = ({
  setLeadForm,
  leadForm,
  setDisabled,
}: ILeadsFormComponent) => {
  const handleChange = (e: any) => {
    globalHandleCheck(
      e,
      leadForm,
      setLeadForm,
      'other_test_score_section',
      setDisabled
    );
  };

  const onCheckAllChange = (e: any) => {
    setDisabled(false);
    if (!e.target.checked) {
      setLeadForm({
        ...leadForm,
        other_test_score_section: {
          ...leadForm.other_test_score_section,
          sat_1: false,
          sat_2: false,
          gre: false,
          gmat: false,
        },
      });
      return;
    }
    setLeadForm({
      ...leadForm,
      other_test_score_section: {
        ...leadForm.other_test_score_section,
        sat_1: true,
        sat_2: true,
        gre: true,
        gmat: true,
      },
    });
  };

  return (
    <>
      <Row justify="space-between" className="custom__title__text">
        <Col>{TITLE.OTHER_TEST_SCORES}</Col>
        <Col>
          <Checkbox
            checked={
              leadForm?.other_test_score_section?.sat_1 &&
              leadForm?.other_test_score_section?.sat_2 &&
              leadForm?.other_test_score_section?.gre &&
              leadForm?.other_test_score_section?.gmat
            }
            onChange={onCheckAllChange}
          />
        </Col>
      </Row>
      <Form.Item className="custom__formItem">
        <Row gutter={GUTTER}>
          <Col span={5} className="lead-form-box">
            <Checkbox
              name="sat_1"
              checked={leadForm?.other_test_score_section?.sat_1}
              onChange={handleChange}
            >
              {LABEL.SAT_I}
            </Checkbox>
          </Col>
          <Col span={5} className="lead-form-box">
            <Checkbox
              name="sat_2"
              checked={leadForm?.other_test_score_section?.sat_2}
              onChange={handleChange}
            >
              {LABEL.SAT_II}
            </Checkbox>
          </Col>
          <Col span={5} className="lead-form-box">
            <Checkbox
              name="gre"
              checked={leadForm?.other_test_score_section?.gre}
              onChange={handleChange}
            >
              {LABEL.GRE}
            </Checkbox>
          </Col>
          <Col span={5} className="lead-form-box">
            <Checkbox
              name="gmat"
              checked={leadForm?.other_test_score_section?.gmat}
              onChange={handleChange}
            >
              {LABEL.GMAT}
            </Checkbox>
          </Col>
        </Row>
      </Form.Item>
    </>
  );
};

export default OtherTestScore;
