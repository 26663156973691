import {
  IAction,
  IOtherDetailsCategory,
  IOtherDetailsLabel,
  IOtherDetailsOptions,
} from '@shared-components/models';
import * as actionTypes from './setting-other-details.constant';

export const addCategoryRequest = (
  payload: IOtherDetailsCategory
): IAction<IOtherDetailsCategory> => ({
  type: actionTypes.POST_CATEGORY_REQUEST,
  payload,
});

export const addCategory = (
  payload: IOtherDetailsCategory
): IAction<IOtherDetailsCategory> => ({
  type: actionTypes.POST_CATEGORY,
  payload,
});

export const editCategoryRequest = (
  payload: IOtherDetailsCategory
): IAction<IOtherDetailsCategory> => ({
  type: actionTypes.PUT_CATEGORY_REQUEST,
  payload,
});

export const selectCategory = (payload: string): IAction<string> => ({
  type: actionTypes.SELECT_CATEGORY,
  payload,
});

export const selectCategoryRequest = (payload: string): IAction<string> => ({
  type: actionTypes.SELECT_CATEGORY_REQUEST,
  payload,
});

export const categoryRequestIdle = (): IAction => ({
  type: actionTypes.OTHER_CATEGORY_REQUEST_IDLE,
});

export const editCategory = (
  payload: IOtherDetailsCategory
): IAction<IOtherDetailsCategory> => ({
  type: actionTypes.PUT_CATEGORY,
  payload,
});

export const deleteCategoryRequest = (payload: string): IAction<string> => ({
  type: actionTypes.DELETE_CATEGORY_REQUEST,
  payload,
});

export const deleteCategory = (payload: string): IAction<string> => ({
  type: actionTypes.DELETE_CATEGORY,
  payload,
});

export const getCategoriesRequest = (): IAction => ({
  type: actionTypes.GET_CATEGORIES_REQUEST,
});

export const getCategoriesRequestByWfStageId = (
  payload: string
): IAction<string> => ({
  type: actionTypes.GET_CATEGORIES_REQUEST_BY_WF_STAGE,
  payload,
});

export const getCategories = (
  payload: IOtherDetailsCategory[]
): IAction<IOtherDetailsCategory[]> => ({
  type: actionTypes.GET_CATEGORIES,
  payload,
});

export const categoryRequestFailure = (): IAction => ({
  type: actionTypes.OTHER_CATEGORY_REQUEST_FAILURE,
});

export const fetchOtherCategoryRequestFailure = (): IAction => ({
  type: actionTypes.FETCH_OTHER_CATEGORY_REQUEST_FAILURE,
});

export const addLabelRequest = (
  payload: IOtherDetailsLabel
): IAction<IOtherDetailsLabel> => ({
  type: actionTypes.POST_CATEGORY_LABEL_REQUEST,
  payload,
});

export const addLabelWithOptionRequest = (
  payload: IOtherDetailsLabel
): IAction<IOtherDetailsLabel> => ({
  type: actionTypes.POST_CATEGORY_LABEL_WITH_OPTION_REQUEST,
  payload,
});

export const addLabel = (
  payload: IOtherDetailsLabel
): IAction<IOtherDetailsLabel> => ({
  type: actionTypes.POST_CATEGORIES_LABEL,
  payload,
});

export const editLabelRequest = (
  payload: IOtherDetailsLabel
): IAction<IOtherDetailsLabel> => ({
  type: actionTypes.PUT_CATEGORY_LABEL_REQUEST,
  payload,
});

export const editLabel = (
  payload: IOtherDetailsLabel
): IAction<IOtherDetailsLabel> => ({
  type: actionTypes.PUT_CATEGORY_LABEL,
  payload,
});

export const deleteLabel = (payload: string): IAction<string> => ({
  type: actionTypes.DELETE_CATEGORY_LABEL,
  payload,
});

export const deleteLabelRequest = (payload: string): IAction<string> => ({
  type: actionTypes.DELETE_CATEGORY_LABEL_REQUEST,
  payload,
});

export const getLabelRequest = (payload: string): IAction<string> => ({
  type: actionTypes.GET_CATEGORIES_LABEL_REQUEST,
  payload,
});

export const getLabel = (
  payload: IOtherDetailsLabel[]
): IAction<IOtherDetailsLabel[]> => ({
  type: actionTypes.GET_CATEGORIES_LABEL,
  payload,
});

export const labelRequestSuccess = (): IAction => ({
  type: actionTypes.OTHER_CATEGORY_LABEL_REQUEST_SUCCESS,
});

export const labelRequestFailure = (): IAction => ({
  type: actionTypes.OTHER_CATEGORY_LABEL_REQUEST_FAILURE,
});

export const labelRequestIdle = (): IAction => ({
  type: actionTypes.OTHER_CATEGORY_LABEL_REQUEST_IDLE,
});

export const setTempOption = (
  payload: IOtherDetailsOptions[]
): IAction<IOtherDetailsOptions[]> => ({
  type: actionTypes.SET_TEMP_OPTIONS,
  payload,
});
