import Avatar from "antd/es/avatar";
import { Col, Row } from "antd/es/grid";
import Typography from "antd/es/typography";

interface CustomizableUserCardProps {
  firstName: string;
  lastName: string;
  email: string;
  nameClassName?: string;
  emailClassName?: string;
  detailsColOffset?: number;
}

const CustomizableUserCard = ({
  firstName,
  lastName,
  email,
  nameClassName = "text-primary-color",
  emailClassName = "text-grey-secondary",
  detailsColOffset = 1,
}: CustomizableUserCardProps) => {

  const fullName = firstName + " " + lastName;

  return (
    <Row align="middle">
      <Col span={2}>
        <Avatar>
          {firstName?.substring(0, 1).toUpperCase()}
          {lastName?.substring(0, 1).toUpperCase()}
        </Avatar>
      </Col>
      <Col span={20} offset={detailsColOffset}>
        <Row>
          <Typography.Text className={`initial_capital w-100 ${nameClassName}`} ellipsis={{ tooltip: true }}>
            {fullName}
          </Typography.Text>
        </Row>
        <Row>
          <Typography.Text className={`w-100 ${emailClassName}`} ellipsis={{ tooltip: true }}>{email}</Typography.Text>
        </Row>
      </Col>
    </Row>
  )
}

export { CustomizableUserCard };
