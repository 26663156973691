import { IAction, IPayload, IService } from '@shared-components/models';
import * as actionType from './service.constant';

export const getServicesRequest = (): IAction<IService> => ({
  type: actionType.GET_SERVICES_REQUEST,
});
export const getServicesSuccess = (
  payload: IService[]
): IAction<IService[]> => ({
  type: actionType.GET_SERVICES_SUCCESS,
  payload,
});
export const getServiceFailure = (payload: any): IAction<any> => ({
  type: actionType.GET_SERVICES_FAILURE,
  payload,
});

export const getProductServicesRequest = (): IAction<IService> => ({
  type: actionType.GET_PRODUCT_SERVICES_REQUEST,
});

export const getProductServicesSuccess = (
  payload: IService[]
): IAction<IService[]> => ({
  type: actionType.GET_PRODUCT_SERVICES_SUCCESS,
  payload,
});
export const getProductServiceFailure = (payload: any): IAction<any> => ({
  type: actionType.GET_PRODUCT_SERVICES_FAILURE,
  payload,
});

export const getAdminServicesRequest = (): IAction<IService> => ({
  type: actionType.GET_ADMIN_SERVICES_REQUEST,
});
export const getAdminServicesSuccess = (
  payload: IService[]
): IAction<IService[]> => ({
  type: actionType.GET_ADMIN_SERVICES_SUCCESS,
  payload,
});
export const getAdminServicesFailure = (payload: any): IAction<any> => ({
  type: actionType.GET_ADMIN_SERVICES_FAILURE,
  payload,
});

export const getOneRequest = (id: any): IAction => ({
  type: actionType.GET_SERVICE_REQUEST,
  payload: id,
});
export const getOneSuccess = (
  payload: IPayload<IService>
): IAction<IService> => ({
  type: actionType.GET_SERVICE_SUCCESS,
  payload,
});
export const getOneFailure = (payload: IPayload<IService>): IAction => ({
  type: actionType.GET_SERVICE_FAILURE,
  payload,
});

export const addRequest = (
  payload: IService,
  triggerRefresh: () => void
): IAction<IService> => ({
  type: actionType.ADD_SERVICE_REQUEST,
  payload,
  triggerRefresh,
});
export const addSuccess = (): IAction => ({
  type: actionType.ADD_SERVICE_SUCCESS,
});
export const addFailure = (payload: IPayload<IService>): IAction => ({
  type: actionType.ADD_SERVICE_FAILURE,
  payload,
});

export const syncServiceRequest = (
  payload: any,
  triggerRefresh: () => void
): IAction => ({
  type: actionType.SYNC_SERVICE_CREATE_REQUEST,
  payload,
  triggerRefresh,
});
export const syncServiceSuccess = (): IAction => ({
  type: actionType.SYNC_SERVICE_CREATE_SUCCESS,
});
export const syncServiceFailure = (): IAction => ({
  type: actionType.SYNC_SERVICE_CREATE_FAILURE,
});

export const updateRequest = (
  payload: IService,
  triggerRefresh: () => void
): IAction<IService> => ({
  type: actionType.UPDATE_SERVICE_REQUEST,
  payload,
  triggerRefresh,
});
export const updateSuccess = (payload: IService): IAction<IService> => ({
  type: actionType.UPDATE_SERVICE_SUCCESS,
  payload,
});
export const updateFailure = (payload: IPayload<IService>): IAction => ({
  type: actionType.UPDATE_SERVICE_FAILURE,
  payload,
});

export const deleteServiceRequest = (
  id: string,
  triggerRefresh: () => void
): IAction<string> => ({
  type: actionType.DELETE_SERVICE_REQUEST,
  payload: id,
  triggerRefresh,
});
export const deleteServiceSuccess = (): IAction => ({
  type: actionType.DELETE_SERVICE_SUCCESS,
});
export const deleteServiceFailure = (payload: IPayload<IService>): IAction => ({
  type: actionType.DELETE_SERVICE_FAILURE,
  payload,
});

export const clearService = (): IAction<string> => ({
  type: actionType.CLEAR_SERVICE_FETCH,
});
