import { workflowActions } from '@admin/core';
import { otherDetailActions, SETTING_OTHER_DETAILS } from '@lyra/core';
import { STATUS } from '@moxie/constants';
import {
  IOtherDetailsCategory,
  IOtherDetailsLabel,
  IWorkflowForm,
} from '@shared-components/models';
import { WORKFLOW_FEATURE_KEY } from 'apps/admin/src/core/workflow/workflow.reducer';
import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

interface IUseWorkflowForm {
  categories: IOtherDetailsCategory[];
  categoryOptions: IOtherDetailsLabel[];
  categoryLoading: boolean;
  selectedCategory: string;
  selectedLabel: string[];
  defaultCheckedForm: string[];
  onChangeSelectedLabel: (value: any) => void;
  handleSubmit: () => void;
  onChangeSelected: (val: string) => void;
}

const useWorkflowForm = (stageId: string): IUseWorkflowForm => {
  const dispatch = useDispatch();
  const [selectedLabel, setSelectedLabel] = useState<any>();
  const [categoryOptions, setCategoryOptions] = useState([]);
  const [selectedCategory, setSelectedCategory] = useState('');
  const { categories, categoryLoading, formList } = useSelector(
    (store: any) => {
      return {
        categories: store[SETTING_OTHER_DETAILS].categories,
        formList: store[WORKFLOW_FEATURE_KEY].formList,
        categoryLoading:
          store[SETTING_OTHER_DETAILS].categoryStatus === STATUS.LOADING
            ? true
            : false,
      };
    }
  );

  useEffect(() => {
    dispatch(otherDetailActions.getCategoriesRequest());
  }, []);

  useEffect(() => {
    dispatch(workflowActions.getWfStageFormRequest(stageId));
  }, [stageId]);

  useEffect(() => {
    if (categories.length > 0) setSelectedCategory(categories[0].id);

    if (formList?.form_id)
      setSelectedLabel(() => {
        let data: any = {};
        categories.forEach((category: IOtherDetailsCategory) => {
          category.labels.forEach((label) => {
            if (formList.form_id.includes(label.id))
              data[category.id] = Array.isArray(data[category.id])
                ? [...data[category.id], label.id]
                : [label.id];
          });
        });
        return data;
      });
  }, [formList, categories]);

  useEffect(() => {
    setCategoryOptions(
      categories.find(
        (item: IOtherDetailsCategory) => item.id === selectedCategory
      )?.labels || []
    );
  }, [selectedCategory]);

  const handleSubmit = () => {
    let payload: IWorkflowForm = {
      workflow_application_stage_id: stageId,
      form_id: Object.keys(selectedLabel)
        .map((item) => selectedLabel[item])
        .reduce((prevValue, NextValue) => [...prevValue, ...NextValue], []),
    };
    dispatch(workflowActions.updateWfFormRequest(payload));
  };

  const onChangeSelectedLabel = (value: any) => {
    setSelectedLabel((prev: any) => {
      return { ...prev, [selectedCategory]: value };
    });
  };

  return {
    categories,
    categoryLoading,
    selectedCategory,
    categoryOptions,
    selectedLabel,
    defaultCheckedForm: formList?.form_id || [],
    onChangeSelectedLabel,
    handleSubmit,
    onChangeSelected: setSelectedCategory,
  };
};

export { useWorkflowForm };
