import { put, takeLatest, ForkEffect } from 'redux-saga/effects';
import * as actionTypes from './client-activities.constant';
import * as ClientActivitiesActions from './client-activities.action';

function* fetchClientActivities(data: any) {
  yield put(ClientActivitiesActions.getClientActivitiesSuccess(data.payload));
}

export default function* ClientActivitiesSagas(): Generator<
  ForkEffect<never>,
  void,
  unknown
> {
  yield takeLatest(
    actionTypes.GET_CLIENT_ACTIVITIES_REQUEST,
    fetchClientActivities
  );
}
