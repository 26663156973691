import React, { useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import {
  CANCEL_BTN,
  DRAWER_WIDTH,
  PROFILE_EDIT_TITLE,
  TEXT,
  UPDATE_BTN,
} from '@moxie/constants';
import { DrawerElem, OverViewHeader } from '@shared-components/elements';
import {
  DashboardLayout,
  UserProfileNew,
  UserTabSection,
} from '@shared-components/modules';
import { useDispatch } from 'react-redux';
import { authActions, useAppSelector, userActions } from '@lyra/core';
import { errorHandler } from '@moxie/utils';
import { useForm } from 'antd/lib/form/Form';
import { Button, Space } from 'antd';
import UserProfileForm from './profile-form';

export const useProfile = (): [loading: boolean] => {
  const dispatch = useDispatch();
  const history = useHistory();

  const [loading, setLoading] = useState(false);
  useEffect(() => {
    const fetchLeadData = () => {
      try {
        setLoading(true);
        dispatch(authActions.getAuthUser());
      } catch (err) {
        errorHandler(err);
      } finally {
        setLoading(false);
      }
    };
    fetchLeadData();
  }, [dispatch, history]);

  return [loading];
};

const DashboardProfile: React.FC = () => {
  const [visible, setVisible] = useState(false);
  const [disabled, setDisabled] = useState(true);
  const [userProfileForm] = useForm();
  const dispatch = useDispatch();
  const authUser: any = useAppSelector((state) => state.auth.user);
  const statUser = useAppSelector((state) => state.users.singleData);

  useProfile();

  useEffect(() => {
    dispatch(userActions.fetchUser(authUser?.id));
  }, [authUser]);

  useEffect(() => {
    if (authUser) {
      userProfileForm.setFieldsValue({
        first_name: authUser.am_user_info.first_name,
        last_name: authUser.am_user_info.last_name,
        address1: authUser.am_user_info.address1,
        address2: authUser.am_user_info.address2,
        country: authUser.am_user_info.country,
        city_or_state: authUser.am_user_info.city_or_state,
        email: authUser.am_user_info.email,
        phone: authUser.am_user_info.phone,
        alternative_phone: authUser.am_user_info.alternative_phone,
        time_zone: authUser.am_user_info.time_zone,
        role: authUser.am_role.id,
        branch: authUser.am_user_info.branch.id,
      });
    }
  }, [authUser]);

  const onFieldsChange = (allFields: string | any[]) => {
    if (allFields.length > 0) {
      for (const field of allFields) {
        field.errors.length < 1 ? setDisabled(false) : setDisabled(true);
      }
    }
  };

  return (
    <DashboardLayout>
      <OverViewHeader title={TEXT.PROFILE} />
      <DrawerElem
        visible={visible}
        title={PROFILE_EDIT_TITLE}
        width={DRAWER_WIDTH}
        onClose={() => setVisible(false)}
        footer={
          <div className="padding-bottom-2 text-align-right">
            <Space direction="horizontal">
              <Button
                onClick={() => setVisible(false)}
                className="lead-margin-right"
              >
                {CANCEL_BTN}
              </Button>

              <Button
                type="primary"
                onClick={() => {
                  userProfileForm.submit();
                  setVisible(false);
                }}
                disabled={disabled}
              >
                {UPDATE_BTN}
              </Button>
            </Space>
          </div>
        }
      >
        <UserProfileForm
          userProfileForm={userProfileForm}
          onFieldsChange={onFieldsChange}
        />
      </DrawerElem>
      <UserProfileNew setVisible={setVisible} />
      <UserTabSection lead={statUser} />
    </DashboardLayout>
  );
};

export default DashboardProfile;
