import { LABEL, TEXT, PHONE_LABEL } from '@moxie/constants';
import { Table } from '@shared-components/elements';
import { IAddress, IInstitutionBranch } from '@shared-components/models';
import { ColumnsType } from 'antd/lib/table';

const InstitutionDetailBranches = ({ getData }: { getData: any }) => {
  const columns: ColumnsType<IInstitutionBranch> = [
    {
      title: LABEL.NAME,
      dataIndex: 'name',
      sorter: () => 0,
    },
    {
      title: TEXT.EMAIL,
      dataIndex: 'email',
      sorter: () => 0,
    },
    {
      title: PHONE_LABEL,
      dataIndex: 'phone_number',
    },
    {
      title: TEXT.ADDRESS,
      dataIndex: 'address',
      sorter: () => 0,
      render(data: IAddress) {
        return <span>{data?.city}</span>;
      },
    },
  ];

  return (
    <div className="margin-top-1">
      <Table action={getData} columns={columns} />
    </div>
  );
};
export default InstitutionDetailBranches;
