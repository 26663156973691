import React, { FC } from 'react';
import { Row, Col, Typography } from 'antd';
import { Link } from 'react-router-dom';
import { URL_USER_DETAIL_LINK } from '@moxie/constants';
import { IDocumentAddUser } from '@shared-components/models';

const DocumentAddCard: FC<IDocumentAddUser> = ({
  data,
  index,
}: IDocumentAddUser) => {
  const { Text } = Typography;

  const {
    id: userId,
    first_name: firstName,
    last_name: lastName,
    email,
  } = data;
  return (
    <Col span={16}>
      <Row key={index}>
        <Col span={24}>
          <Link to={`${URL_USER_DETAIL_LINK}${userId}`}>
            <Text className="text-primary-color">{`${
              firstName ? firstName : ''
            } ${lastName ? lastName : ''}`}</Text>
          </Link>
        </Col>
        <Col span={24}>
          <Text className="text-primary-color">
            <small>{email}</small>
          </Text>
        </Col>
      </Row>
    </Col>
  );
};

export { DocumentAddCard };
