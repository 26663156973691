import { call, put, takeLatest } from 'redux-saga/effects';
import { IAction, ILead, IUpdateMultipleLead } from '@shared-components/models';
import { errorHandler } from '@moxie/utils';
import {
  axiosInstance as axios,
  postLead,
  multipleUpdateLead,
  updateLead,
  deleteLeads,
} from '@lyra/services.api';
import * as leadActions from './lead.action';
import { lead } from '../constants';
import { AxiosResponse } from 'axios';
import {
  ADD_SUCCESS,
  API_URL,
  ARCHIVE_SUCCESS,
  DELETE_SUCCESS,
  RESTORE_SUCCESS,
  UPDATE_SUCCESS,
} from '@moxie/constants';
import { successNotificationHandler } from 'libs/shared/src/shared';

const baseUrl = process.env.NX_BASE_URL;
const endpoint_lead = `${baseUrl}${API_URL.LEAD}`;

function* addLead({ payload, socket, user }: IAction<any>) {
  yield put(leadActions.loading(true));
  try {
    if (payload) {
      const response: AxiosResponse = yield postLead(payload);
      yield getLead({ payload: response.data.data.id, type: lead.FETCH_LEAD });
      if (payload?.assignee_user_id) {
        socket?.emit('create-contact-assignee-notification', {
          user_id: user?.id,
          parent_id: response?.data?.data?.id,
          receiver_id: response?.data?.data?.assignee_id,
        });
      }
      yield put(leadActions.redirectToAllLead(true));
      successNotificationHandler(ADD_SUCCESS);
      yield put(leadActions.setError(false));
      yield put(leadActions.loading(false));
    }
  } catch (e) {
    errorHandler(e);
    yield put(leadActions.setError(true));
    yield put(leadActions.loading(false));
  }
}

function* editLead({
  payload,
  socket,
  user,
  update_type,
  tab,
}: IAction<ILead>) {
  const softDelete = payload?.softDelete;
  payload?.softDelete && delete payload['softDelete'];
  yield put(leadActions.loading(true));
  try {
    if (payload) {
      const response: AxiosResponse = yield updateLead(payload);
      yield getLead({ payload: response.data.data.id, type: lead.FETCH_LEAD });
      yield put({ type: lead.SUCCESSFULL_EDIT_LEAD });

      if (payload?.assignee_user_id) {
        socket?.emit('create-contact-assignee-notification', {
          user_id: user?.id,
          parent_id: response?.data?.data?.id,
          receiver_id: response?.data?.data?.assignee_id,
        });
      } else if (payload?.followers_id) {
        socket?.emit('create-contact-followers-notification', {
          user_id: user?.id,
          parent_id: response?.data?.data?.id,
          receiver_id: payload?.assignee_id,
          receivers_many: response?.data?.data?.followers,
          previous_followers: payload.previous_followers,
        });
      } else if (typeof update_type === 'string') {
        socket?.emit('qualification-notification', {
          user_id: user?.id,
          parent_id: response?.data?.data?.id,
          receiver_id: response?.data?.data?.assignee_id,
          receivers_many: response?.data?.data?.followers?.map(
            (item: any) => item?.id
          ),
          type: update_type,
          update_type: 'updated',
          profile_tab: tab,
        });
      }
    }
    // yield put(leadActions.redirectToAllLead(false));
    if (softDelete) {
      successNotificationHandler(DELETE_SUCCESS);
    } else {
      successNotificationHandler(UPDATE_SUCCESS);
    }

    yield put(leadActions.setError(false));
    yield put(leadActions.loading(false));
  } catch (e) {
    errorHandler(e);
    yield put(leadActions.setError(true));
    yield put(leadActions.loading(false));
  }
}

function* archiveMultipleLead({ payload }: IAction<string[]>) {
  yield put(leadActions.loading(true));
  if (!payload) return;
  try {
    const data: IUpdateMultipleLead = {
      archived: true,
      selected: payload,
    };
    if (payload) yield multipleUpdateLead(data);
    yield put({ type: lead.ARCHIVE_SUCCESSFULL_MUTIPLE_LEAD_SAGA });
    successNotificationHandler(ARCHIVE_SUCCESS);
    yield put(leadActions.setError(false));
    yield put(leadActions.loading(false));
  } catch (e) {
    errorHandler(e);
    yield put(leadActions.setError(true));
    yield put(leadActions.loading(false));
  }
}

function* restoreMultipleLead({ payload }: IAction<string[]>) {
  yield put(leadActions.loading(true));
  if (!payload) return;
  try {
    const data: IUpdateMultipleLead = {
      archived: false,
      selected: payload,
    };
    if (payload) yield multipleUpdateLead(data);
    yield put({ type: lead.RESTORE_SUCCESSFULL_MUTIPLE_LEAD_SAGA });
    successNotificationHandler(RESTORE_SUCCESS);
    yield put(leadActions.setError(false));
    yield put(leadActions.loading(false));
  } catch (e) {
    errorHandler(e);
    yield put(leadActions.setError(true));
    yield put(leadActions.loading(false));
  }
}

export function* getLead(data: any) {
  yield put(leadActions.loading(true));
  try {
    const res: AxiosResponse<any> = yield call(axios.request, {
      url: `${endpoint_lead}/${data.payload}`,
      method: 'get',
    });
    if (res?.data?.success) {
      yield put(leadActions.getLead(res.data.data));
    } else {
      yield put(leadActions.redirectLead());
    }
  } catch (e) {
    errorHandler(e);
    yield put(leadActions.setError(true));
    yield put(leadActions.redirectLead());
  }
}

function* deleteMultipleLeads({ payload }: IAction<string[]>) {
  yield put(leadActions.loading(true));
  if (!payload) return;
  try {
    const data: IUpdateMultipleLead = {
      is_deleted: true,
      selected: payload,
    };
    if (payload) yield multipleUpdateLead(data);
    yield put({ type: lead.SUCCESSFULL_DELETE_LEAD });
    successNotificationHandler(DELETE_SUCCESS);
    yield put(leadActions.setError(false));
    yield put(leadActions.loading(false));
  } catch (e) {
    errorHandler(e);
    yield put(leadActions.setError(true));
    yield put(leadActions.loading(false));
  }
}

function* deleteSingleLead({ payload }: IAction<string>) {
  yield put(leadActions.loading(true));
  try {
    if (payload) yield deleteLeads([payload]);
    yield put({ type: lead.SUCCESSFULL_DELETE_LEAD });
    successNotificationHandler(DELETE_SUCCESS);
    yield put(leadActions.setError(false));
    yield put(leadActions.loading(false));
  } catch (e) {
    errorHandler(e);
    yield put(leadActions.setError(true));
    yield put(leadActions.loading(false));
  }
}

function* setErrorStatus({ payload }: IAction<boolean>) {
  yield put(leadActions.setError(payload));
}

function* resetErrorStatus() {
  yield put(leadActions.setError(null));
}

export default function* rootSage(): unknown {
  yield takeLatest(lead.POST_LEAD_SAGA, addLead);
  yield takeLatest(lead.TOGGLE_ERROR_STATUS, setErrorStatus);
  yield takeLatest(lead.RESET_ERROR_STATUS, resetErrorStatus);
  yield takeLatest(lead.FETCH_LEAD, getLead);
  yield takeLatest(lead.EDIT_LEAD_SAGA, editLead);
  yield takeLatest(lead.ARCHIVE_MULTIPLE_LEAD_SAGA, archiveMultipleLead);
  yield takeLatest(lead.RESTORE_MULTIPLE_LEAD_SAGA, restoreMultipleLead);
  yield takeLatest(lead.DELETE_SINGLE_LEAD_SAGA, deleteSingleLead);
  yield takeLatest(lead.DELETE_MULTIPLE_LEAD_SAGA, deleteMultipleLeads);
}
