import React, { useState } from 'react';
import { LABEL, PRODUCT_TABLE, TEXT } from '@moxie/constants';
import { CrmTable } from '@shared-components/elements';
import { Tag, Tooltip } from 'antd';
import Text from 'antd/lib/typography/Text';
import { capitalizeFirstLetter } from 'libs/shared/src/functions';
import { getProductsListBiInstitutionId } from '@crm/services.api';
import { colSorter } from 'libs/shared/src/elements/crm-table/colSorter';

const useColumns = () => {
  const cols = [
    {
      title: LABEL.NAME,
      width: 500,
      dataIndex: 'productName',
      sorter: () => 0,
      render(data: any) {
        return data && data?.length > 50 ? (
          <Tooltip title={data} placement="top">
            <Text
              className="text-primary-color product-ellipsis"
              ellipsis={true}
            >
              {capitalizeFirstLetter(data)}
            </Text>
          </Tooltip>
        ) : (
          <Text className="text-primary-color">
            {capitalizeFirstLetter(data)}
          </Text>
        );
      },
    },
    {
      title: TEXT.BRANCH,
      dataIndex: 'branches',
      width: 500,
      render(data: any) {
        return data?.map((item: string, index: number) => (
          <Tag key={index}>{item}</Tag>
        ));
      },
    },
    {
      title: PRODUCT_TABLE.DEGREE_LEVEL,
      dataIndex: 'level',
      width: 250,
    },
  ];

  return React.useMemo(() => {
    return cols.map((c) => colSorter(c)) 
  },[])
}

const InstitutionDetailProducts: React.FC<any> = ({ id }: { id: any }) => {
  const [filter] = useState({});

  const getProductData = async (params: Record<string, unknown>) => {
    return getProductsListBiInstitutionId({'filter.institutionId': id, ...params});
  };
  const columns = useColumns();

  return (
    <div className="margin-top-1">
      <CrmTable hideSearch tableName='' columns={columns} action={getProductData} filter={filter} />
    </div>
  );
};
export default InstitutionDetailProducts;
