import React from 'react';
import { LABEL, OFFICE_TITLE } from '@moxie/constants';
import { Button, CrmTable } from '@shared-components/elements';
import { ITableProps } from '@shared-components/models';
import { PlusOutlined } from '@ant-design/icons';
import { TableBranchNameCard } from './table-branch-name-card';
import { getOfficeList } from '@crm/services.api';
import { colSorter } from 'libs/shared/src/elements/crm-table/colSorter';
import { UserNameCard } from '../../shared';
import { ColumnsType } from 'antd/lib/table';
import { useCanAccess } from '@crm/libs/privilege/useCanAccess';
import { IOfficeBranchCRM } from '@model/branch';

export const useColumns = (props: ITableProps) => {
  const { deleteData, showDrawer, canAccess } = props;

  const cols: ColumnsType<IOfficeBranchCRM> = [
    {
      title: LABEL.NAME,
      key: 'name',
      dataIndex: 'name',
      width: 200,
      sorter: () => 0,
      render(_dataIndex: string, data: IOfficeBranchCRM) {
        return (
          <TableBranchNameCard
            data={data}
            showDrawer={showDrawer}
            deleteData={deleteData}
            canAccess={canAccess}
          />
        );
      },
    },
    {
      title: LABEL.CITY,
      dataIndex: 'address.city',
      sorter: () => 0,
      width: 200,
      render(_dataIndex: string, data: IOfficeBranchCRM) {
        return (
          <div className="initial_capital">
            {data?.address?.city ? data?.address?.city : '-'}
          </div>
        );
      },
    },
    {
      title: LABEL.COUNTRY,
      dataIndex: 'address.country',
      sorter: () => 0,
      width: 200,
      render(_dataIndex: string, data: IOfficeBranchCRM) {
        return data.address?.country ? data.address?.country : '-';
      },
    },
    {
      title: LABEL.PHONE_NUMBER,
      key: 'phone',
      dataIndex: 'phone',
      width: 200,
      render(_dataIndex: string, data: IOfficeBranchCRM) {
        return !data?.phone ? '-' : data?.phone;
      },
    },
    {
      title: LABEL.CONTACT_PERSON,
      key: 'contact_person_id',
      width: 200,
      render(data: IOfficeBranchCRM) {
        return data?.contactPerson ? (
          <UserNameCard
            index={data.contactPerson.id}
            actionBar={false}
            firstName={data.contactPerson.firstName || ''}
            lastName={data.contactPerson.lastName || ''}
            email={data.contactPerson.email || ''}
            id={data.contactPerson.id}
          />
        ) : (
          '-'
        );
      },
    },
  ];

  return React.useMemo(() => {
    return cols.map((c) => colSorter(c as any))
  }, [])
};

const OfficeTableList: React.FC<ITableProps> = ({
  deleteData,
  refreshTable,
  showDrawer,
}: ITableProps) => {
  const canAccess = useCanAccess();

  const columnActions = {
    deleteData: deleteData,
    showDrawer: showDrawer,
    canAccess,
  };

  const columns = useColumns(columnActions);

  return (
    <div className="bg-white padding-top-2" data-testid="crm_branch_list">
      <CrmTable
        action={getOfficeList}
        columns={columns}
        tableName={OFFICE_TITLE}
        refresh={refreshTable}
        tableButtons={
          <>
            {
              canAccess('branch', 'create') && (<Button
                type="primary"
                data-testid="crm-addbranch-btn"
                typeof="button"
                onClick={() => {
                  showDrawer && showDrawer();
                }}
              >
                <PlusOutlined /> {LABEL.CREATE_NEW_BRANCH}
              </Button>)
            }
          </>
        }
      />
    </div>
  );
};

export default OfficeTableList;
