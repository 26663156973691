const alias = 'NOTIFICATION';

export const GET_NOTIFICATIONS_REQUEST = `GET_${alias}S_REQUEST`;
export const GET_NOTIFICATIONS_SUCCESS = `GET_${alias}S_SUCCESS`;
export const GET_NOTIFICATIONS_FAILURE = `GET_${alias}_FAILURE`;
export const GET_NOTIFICATIONS_CLEAR = `GET_${alias}S_CLEAR`;

export const GET_NOTIFICATION_REQUEST = `GET_${alias}_REQUEST`;
export const GET_NOTIFICATION_SUCCESS = `GET_${alias}_SUCCESS`;
export const GET_NOTIFICATION_FAILURE = `GET_${alias}_FAILURE`;

export const ADD_NOTIFICATION_REQUEST = `ADD_${alias}_REQUEST`;
export const ADD_NOTIFICATION_SUCCESS = `ADD_${alias}_SUCCESS`;
export const ADD_NOTIFICATION_FAILURE = `ADD_${alias}_FAILURE`;

export const UPDATE_NOTIFICATION_REQUEST = `UPDATE_${alias}_REQUEST`;
export const UPDATE_NOTIFICATION_SUCCESS = `UPDATE_${alias}_SUCCESS`;
export const UPDATE_NOTIFICATION_FAILURE = `UPDATE_${alias}_FAILURE`;

export const NOTIFICATION_MARKREADALL_REQUEST = `${alias}_REQUEST`;
export const NOTIFICATION_MARKREADALL_SUCCESS = `${alias}_SUCCESS`;
export const NOTIFICATION_MARKREADALL_FAILURE = `${alias}_FAILURE`;

export const DELETE_NOTIFICATION_REQUEST = `DELETE_${alias}_REQUEST`;
export const DELETE_NOTIFICATION_SUCCESS = `DELETE_${alias}_SUCCESS`;
export const DELETE_NOTIFICATION_FAILURE = `DELETE_${alias}_FAILURE`;
