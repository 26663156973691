import { ITitleProps } from '@shared-components/models';
import React from 'react';

const Title: React.FC<ITitleProps> = ({
  title,
  subscript,
  superscript,
}: ITitleProps) => (
  <div className="education-card__title">
    {superscript && superscript.length > 0 && (
      <div className="superscript">{superscript}</div>
    )}
    {title && <div className="title">{title}</div>}
    {subscript && subscript.length > 0 && (
      <div className="subscript">{subscript}</div>
    )}
  </div>
);

Title.defaultProps = {
  subscript: '',
  superscript: '',
};

export { Title };
