import React from 'react';
import CheckableTag from "antd/es/tag/CheckableTag";

interface TagSelectProps {
  options: {
    label: string;
    value: string;
  }[];
  values: string[];
  onChange: (values: string[]) => void;
  className?: string;
}
const MultiTagSelect: React.FC<TagSelectProps> = ({ options, values, onChange, className = "" }) => {

  const handleChange = (value: string, checked: boolean) => {
    let selectedValues = values?.filter(userId => userId !== value);
    if (checked) {
      selectedValues = Array.from(new Set([...values ?? [], value]))
    }
    onChange(selectedValues);
  }
  return (
    <div className="tag-multi-select">
      {options.map(option => (
        <CheckableTag
          key={option.value}
          checked={values?.includes(option.value) ?? false}
          onChange={checked => handleChange(option.value, checked)}
          className={className}
        >
          {option.label}
        </CheckableTag>
      ))}
    </div>
  )
}

export default MultiTagSelect;
