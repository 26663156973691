import {
  IAction,
  IEducation,
  IEducationPayload,
  ILead,
  IUser,
} from '@shared-components/models';
import * as actionTypes from './language-test-score.constant';

export const addLanguageTestScoreRequest = (
  payload: IEducationPayload,
  user: IUser | null,
  socket: any,
  lead: ILead,
  onClose: () => void
): any => ({
  type: actionTypes.ADD_LANGUAGE_TEST_SCORE_REQUEST,
  payload,
  user,
  socket,
  lead,
  onClose,
});
export const addLanguageTestScoreSuccess = (
  payload: IEducation
): IAction<IEducation> => ({
  type: actionTypes.ADD_LANGUAGE_TEST_SCORE_SUCCESS,
  payload,
});
export const addLanguageTestScoreFailure = (payload: any): IAction<any> => ({
  type: actionTypes.ADD_LANGUAGE_TEST_SCORE_FAILURE,
  payload,
});

export const getLanguageTestScoresRequest = (id: string): IAction<string> => ({
  type: actionTypes.GET_LANGUAGE_TEST_SCORES_REQUEST,
  payload: id,
});
export const getLanguageTestScoresSuccess = (
  payload: IEducation[]
): IAction<IEducation[]> => ({
  type: actionTypes.GET_LANGUAGE_TEST_SCORES_SUCCESS,
  payload,
});
export const getLanguageTestScoresFailure = (
  payload: IEducationPayload
): IAction => ({
  type: actionTypes.GET_LANGUAGE_TEST_SCORES_FAILURE,
  payload,
});
export const getLanguageTestScoresClear = () => {
  return { type: actionTypes.GET_LANGUAGE_TEST_SCORES_CLEAR };
};

export const updateLanguageTestScoreRequest = (
  payload: IEducationPayload,
  user: IUser | null,
  socket: any,
  lead: ILead,
  onClose: () => void
): any => ({
  type: actionTypes.UPDATE_LANGUAGE_TEST_SCORE_REQUEST,
  payload,
  user,
  socket,
  lead,
  onClose,
});
export const updateLanguageTestScoreSuccess = (
  payload: IEducation
): IAction<IEducation> => ({
  type: actionTypes.UPDATE_LANGUAGE_TEST_SCORE_SUCCESS,
  payload,
});
export const updateLanguageTestScoreFailure = (
  payload: IEducationPayload
): IAction<IEducationPayload> => ({
  type: actionTypes.UPDATE_LANGUAGE_TEST_SCORE_FAILURE,
  payload,
});

export const deleteLanguageTestScoreRequest = (
  payload: IEducationPayload,
  user: IUser | null,
  socket: any,
  lead: ILead
): any => ({
  type: actionTypes.DELETE_LANGUAGE_TEST_SCORE_REQUEST,
  payload,
  user,
  socket,
  lead,
});
export const deleteLanguageTestScoreSuccess = (
  payload: IEducationPayload
): IAction<IEducationPayload> => ({
  type: actionTypes.DELETE_LANGUAGE_TEST_SCORE_SUCCESS,
  payload,
});
export const deleteLanguageTestScoreFailure = (
  payload: IEducationPayload
): IAction<IEducationPayload> => ({
  type: actionTypes.DELETE_LANGUAGE_TEST_SCORE_FAILURE,
  payload,
});
