import { useAppSelector } from '@lyra/core';
import {
  productComparisonActions,
  PRODUCT_COMPARISON_FEATURE_KEY,
} from '@lyra/core';
import { TEXT } from '@moxie/constants';
import { useQuery, getThreeArrayValue } from '@moxie/utils';
import {
  IProductComparisonModal,
  IProductCompare,
  ICompany,
} from '@shared-components/models';
import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory, useParams } from 'react-router-dom';
import { warnNotificationHandler } from '../../functions';

const useProductCompare = (): IProductCompare => {
  const dispatch = useDispatch();
  const history = useHistory();
  const data = useQuery().get('pid');
  const { id: leadId }: { id: string } = useParams();
  const [count, setCount] = useState(0);
  const [disabled, setDisabled] = useState({ left: false, right: false });
  const [productList, setProductList] = useState<IProductComparisonModal[]>([]);
  const {
    productListSource,
    loading,
  }: {
    productListSource: IProductComparisonModal[];
    loading: boolean;
  } = useSelector((store: any) => ({
    productListSource: store[PRODUCT_COMPARISON_FEATURE_KEY].productComparisons,
    loading: store[PRODUCT_COMPARISON_FEATURE_KEY].loading,
  }));

  const tenantDetails: ICompany = useAppSelector(
    (store: any) => store.auth.user?.company
  );

  useEffect(() => {
    setProductList(() => {
      if (productListSource.length <= 3)
        setDisabled({ left: false, right: false });
      else setDisabled({ left: true, right: true });
      return productListSource.slice(0, 3);
    });
  }, [productListSource]);

  useEffect(() => {
    if (productListSource.length <= 3) setDisabled({ left: true, right: true });
    else if (productList.at(-1)?.id === productListSource.at(-1)?.id) {
      setDisabled({ left: false, right: true });
    } else if (
      productList[0] &&
      productList[0]?.id === productListSource[0]?.id
    )
      setDisabled({ left: true, right: false });
    else setDisabled({ left: false, right: false });
  }, [productList]);

  useEffect(() => {
    dispatch(
      productComparisonActions.addProductComparisonRequest({
        product_id: data.split(','),
      })
    );
  }, [data]);

  const handleLeftClick = () => {
    setProductList((): IProductComparisonModal[] => {
      setCount(count - 1);
      return getThreeArrayValue(productListSource, count - 1);
    });
  };

  const handleRightClick = () => {
    setProductList((): IProductComparisonModal[] => {
      setCount(count + 1);
      return getThreeArrayValue(productListSource, count + 1);
    });
  };

  const handleDeleteProduct = (id: string) => {
    if (productListSource.length <= 2)
      warnNotificationHandler(TEXT.ALERT_MINIMUM_PRODUCT);
    else
      history.push(
        `/lead/product-compare/${leadId}?pid=${productListSource
          .filter((item) => item.id !== id)
          .map((item) => item.id)
          .join(',')}`
      );
  };

  return {
    productList,
    loading,
    disabled,
    handleLeftClick,
    handleRightClick,
    handleDeleteProduct,
    productListSource,
    tenantDetails,
  };
};

export { useProductCompare };
