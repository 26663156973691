import React, { useEffect } from 'react';
import Form from 'antd/lib/form';
import { Col, Row, Typography } from 'antd';
import { useForm } from 'antd/lib/form/Form';
import { CheckOutlined, CloseOutlined } from '@ant-design/icons';
import { Button, UserAvatarGroup, UserSelectBox } from '@moxie/shared';
import { ASSIGN_TO_BTN, LABEL, NO_FOLLOWERS } from '@moxie/constants';
import { ILeadFollowersSection } from '@shared-components/models';

const LeadFollowersSection = ({
  followers,
  users,
  followersId,
  editFollowersMode,
  setEditFollowersMode,
  onSubmit,
  archived,
}: ILeadFollowersSection) => {
  const [form] = useForm();

  const handleSubmit = (data: { followers_id: Array<string> }) => {
    onSubmit(data);
  };

  const handleCloseFollowersForm = () => {
    setEditFollowersMode(false);
    form.setFieldsValue({ followers_id: followersId });
  };

  const handleEdit = () => {
    setEditFollowersMode(true);
  };

  useEffect(() => {
    form.setFieldsValue({ followers_id: followersId });
  }, [followers]);

  return (
    <div className="profile__info__item profile__added">
      <div className="profile__header">
        <h4 className="profile__title padding-left-1">{LABEL.FOLLOWERS}:</h4>
      </div>
      <div>
        {followers?.length > 0 && !editFollowersMode ? (
          <div
            className={
              archived ? 'profile__user cursor-disabled' : 'profile__user'
            }
            onClick={!archived ? handleEdit : undefined}
          >
            <UserAvatarGroup userList={followers} />
          </div>
        ) : (
          !editFollowersMode && (
            <div
              className={
                archived
                  ? 'assignee-hover cursor-disabled'
                  : 'assignee-hover cursor-pointer'
              }
              onClick={!archived ? handleEdit : undefined}
            >
              <Typography.Text type="secondary" className="margin-left-1">
                {NO_FOLLOWERS}
              </Typography.Text>
            </div>
          )
        )}
      </div>
      {editFollowersMode && (
        <Form form={form} onFinish={handleSubmit}>
          <Row>
            <Col>
              <Form.Item className="follower_overflow" name="followers_id">
                <UserSelectBox
                  showUnassigned={true}
                  className="followers_select_width"
                  defaultOpen={true}
                  placeholder={ASSIGN_TO_BTN}
                  autoFocus={true}
                  mode="multiple"
                  maxTagCount={1}
                  users={users}
                  allowClear={false}
                />
              </Form.Item>
            </Col>

            <Button
              icon={<CheckOutlined />}
              type="link"
              htmlType="submit"
              size="small"
              className="padding-left-1"
            />
            <Button
              icon={<CloseOutlined />}
              onClick={handleCloseFollowersForm}
              type="link"
              danger
              size="small"
            />
          </Row>
        </Form>
      )}
    </div>
  );
};

export { LeadFollowersSection };
