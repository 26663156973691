/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from 'react';
import { GeneratedLeadForm } from '@moxie/shared';
import { useLocation } from 'react-router';

const CreatedLeadForm: React.FC = () => {
  const location = useLocation();
  const [id, setId] = useState<string | null>();
  const queryParams = new URLSearchParams(location.search).get('i');

  useEffect(() => {
    setId(queryParams);
  }, [id]);

  return (
    <div className="body-container">
      <main className="created-leadform-main">
        {id && <GeneratedLeadForm id={id} />}
      </main>
    </div>
  );
};
export { CreatedLeadForm };
