import { applyMiddleware, combineReducers, createStore } from 'redux';
import { composeWithDevTools } from 'redux-devtools-extension';
import createSagaMiddleware from 'redux-saga';
import saga from './sagas/saga';
import authReducer, { AUTH_FEATURE_KEY } from './auth/auth.reducer';
import { themeReducer, THEME_FEATURE_KEY } from './theme/theme.reducer';
import leadReducer, { LEAD_FEATURE_KEY } from './lead/lead.reducer';
import { userReducer, USER_FEATURE_KEY } from './users/user.reducer';
import {
  rolePermissionReducer,
  ROLE_PERMISSION_FEATURE,
} from './role_permissions/role-permission.reducer';
import { productsReducer, PRODUCTS_FEATURE } from './products/products.reducer';
import { socketReducer, SOCKET_FEATURE_KEY } from './socket/socket.reducer';
import {
  LeadActivitiesReducer,
  LEAD_ACTIVITIES_FEATURE_KEY,
} from './lead-activities/lead-activities.reducer';
import educationBackgroundReducer, {
  EDUCATION_BACKGROUND_FEATURE_KEY,
} from './education-background/education-background.reducer';
import languageTestScoreReducer, {
  LANGUAGE_TEST_SCORE_FEATURE_KEY,
} from './language-test-score/language-test-score.reducer';
import otherTestScoreReducer, {
  OTHER_TEST_SCORE_FEATURE_KEY,
} from './other-test-score/other-test-score.reducer';
import {
  subjectDisciplineReducer,
  SUBJECT_DISCIPLINE_FEATURE_KEY,
} from './subject-disciplines/subject-disciplines.reducer';
import professionalExperienceReducer, {
  PROFESSIONAL_EXPERIENCE_FEATURE_KEY,
} from './professional-experience/professional-experience.reducer';
import {
  leadNoteReducer,
  LEAD_NOTES_FEATURE_KEY,
} from './leads-notes/lead-notes.reducer';
import serviceReducer, {
  SERVICE_FEATURE_KEY,
} from './services/services.reducer';
import {
  INSTITUTION_FEATURE_KEY,
  institutionReducer,
} from './institutions/institution.reducer';
import interestedServiceReducers, {
  INTERESTED_SERVICE_FEATURE_KEY,
} from './interested-services/interested-services.reducer';
import {
  PRODUCT_COMPARISON_FEATURE_KEY,
  productComparisonReducer,
} from './product-comparison/product-comparison.reducer';
import {
  DEPARTMENT_FEATURE_KEY,
  departmentReducer,
} from './department/department.reducer';
import leadAppointmentReducer, {
  LEAD_APPOINTMENT_FEATURE_KEY,
} from './lead-appointments/lead-appointments.reducer';
import {
  OFFICE_BRANCH_FEATURE_KEY,
  officeBranchReducer,
} from './office-branch/office-branch.reducer';
import userAppointmentReducer, {
  USER_APPOINTMENT_FEATURE_KEY,
} from './user-appointments/user-appointment.reducer';
import {
  APPLICATION_FEATURE_KEY,
  applicationReducer,
} from './application/application.reducer';
import {
  NotificationsReducer,
  NOTIFICATIONS_FEATURE_KEY,
} from './notifications/notificaitons.reducer';
import {
  SETTING_OTHER_DETAILS,
  otherDetailsReducer,
} from './setting-other-details/setting-other-details.reducer';
import leadOtherDetailValueReducer, {
  LEAD_OTHER_DETAIL_VALUE_FEATURE_KEY,
} from './lead-other-detail-values/other-detail-values.reducer';
import tasksReducer, { TASKS_FEATURE_KEY } from './tasks/tasks.reducer';
import {
  adminUserReducer,
  ADMIN_USER_FEATURE_KEY,
} from './admin_users/admin-user.reducer';
import {
  workflowReducer,
  WORKFLOW_FEATURE_KEY,
} from 'apps/admin/src/core/workflow/workflow.reducer';
import {
  documentChecklistReducer,
  DOCUMENT_CHECKLIST_FEATURE_KEY,
} from './lead-document/lead-document.reducer';

const rootReducer = combineReducers({
  [AUTH_FEATURE_KEY]: authReducer,
  [THEME_FEATURE_KEY]: themeReducer,
  [SOCKET_FEATURE_KEY]: socketReducer,
  [ROLE_PERMISSION_FEATURE]: rolePermissionReducer,
  [LEAD_FEATURE_KEY]: leadReducer,
  [SERVICE_FEATURE_KEY]: serviceReducer,
  [OTHER_TEST_SCORE_FEATURE_KEY]: otherTestScoreReducer,
  [LANGUAGE_TEST_SCORE_FEATURE_KEY]: languageTestScoreReducer,
  [EDUCATION_BACKGROUND_FEATURE_KEY]: educationBackgroundReducer,
  [INSTITUTION_FEATURE_KEY]: institutionReducer,
  [USER_FEATURE_KEY]: userReducer,
  [ADMIN_USER_FEATURE_KEY]: adminUserReducer,
  [PRODUCTS_FEATURE]: productsReducer,
  [LEAD_NOTES_FEATURE_KEY]: leadNoteReducer,
  [SUBJECT_DISCIPLINE_FEATURE_KEY]: subjectDisciplineReducer,
  [PROFESSIONAL_EXPERIENCE_FEATURE_KEY]: professionalExperienceReducer,
  [INTERESTED_SERVICE_FEATURE_KEY]: interestedServiceReducers,
  [PRODUCT_COMPARISON_FEATURE_KEY]: productComparisonReducer,
  [DEPARTMENT_FEATURE_KEY]: departmentReducer,
  [LEAD_APPOINTMENT_FEATURE_KEY]: leadAppointmentReducer,
  [OFFICE_BRANCH_FEATURE_KEY]: officeBranchReducer,
  [USER_APPOINTMENT_FEATURE_KEY]: userAppointmentReducer,
  [LEAD_ACTIVITIES_FEATURE_KEY]: LeadActivitiesReducer,
  [NOTIFICATIONS_FEATURE_KEY]: NotificationsReducer,
  [APPLICATION_FEATURE_KEY]: applicationReducer,
  [SETTING_OTHER_DETAILS]: otherDetailsReducer,
  [LEAD_OTHER_DETAIL_VALUE_FEATURE_KEY]: leadOtherDetailValueReducer,
  [TASKS_FEATURE_KEY]: tasksReducer,
  [WORKFLOW_FEATURE_KEY]: workflowReducer,
  [DOCUMENT_CHECKLIST_FEATURE_KEY]: documentChecklistReducer,
});

const sagaMiddleware = createSagaMiddleware();
const middlewares = [sagaMiddleware];

function configureStore() {
  const middlewareEnhancer = applyMiddleware(...middlewares);
  const composedEnhancers = composeWithDevTools(middlewareEnhancer);
  const store = createStore(rootReducer, composedEnhancers);
  sagaMiddleware.run(saga);
  return store;
}

const store = configureStore();

export type RootState = ReturnType<typeof store.getState>;
export type AppDispatch = typeof store.dispatch | any;

export default store;

export function createAction(type: string | any, ...argNames: any) {
  return function (...args: any) {
    const action: any = { type };
    argNames.forEach((arg: any, index: any) => {
      action[argNames[index]] = args[index];
    });
    return action;
  };
}
