/* eslint-disable react-hooks/rules-of-hooks */
import { CloseOutlined, PlusOutlined } from '@ant-design/icons';
import { useAppSelector } from '@lyra/core';
import { ADD_DOCUMENT, CLOSE_BTN, PLACEHOLDER, TEXT } from '@moxie/constants';
import {
  Button,
  capitalizeFirstLetter,
  DocumentNameCard,
  Table,
} from '@moxie/shared';
import { useQuery } from '@moxie/utils';
import {
  IApplicationStageChecklist,
  IDocumentTypeClick,
  ILeadDocumentTable,
} from '@shared-components/models';
import { Dropdown, Menu, Space } from 'antd';
import { ColumnsType } from 'antd/lib/table';
import React, { ReactNode, useContext } from 'react';
import { ApplicationContext } from '../applicationContext';

const addDocumentMenu = ({ onClick }: IDocumentTypeClick) => {
  return (
    <Menu>
      <Menu.Item key={1}>
        <Space direction="horizontal" onClick={() => onClick?.(TEXT.EXISTING)}>
          {TEXT.EXISTING_DOCUMENTS}
        </Space>
      </Menu.Item>
      <Menu.Item key={3}>
        <Space direction="horizontal" onClick={() => onClick?.(TEXT.DEVICE)}>
          {TEXT.UPLOAD_FROM_DEVICE}
        </Space>
      </Menu.Item>
    </Menu>
  );
};

const ChecklistDocumentTable: React.FC<ILeadDocumentTable> = ({
  refreshTable,
  documentData,
  triggerRefresh,
  handleClick,
  selectedActor,
}: ILeadDocumentTable) => {
  const {
    applicationState,
    clearDetailAndChecklist,
    setSelectedChecklist,
  } = useContext(ApplicationContext);
  const applicationData = useAppSelector(
    (state) => state.application.singleData
  );

  const { selectedChecklistData, selectedChecklist } = applicationState;

  const handleDocumentCountDecrease = () => {
    const updatedChecklistData = selectedChecklistData.map((data: any) => {
      if (selectedChecklist === data.id) {
        data.totalDocs = data.totalDocs - 1;
      }
      return data;
    });

    setSelectedChecklist(updatedChecklistData);
  };

  const columns: ColumnsType<IApplicationStageChecklist> = [
    {
      title: TEXT.SN,
      key: 'id',
      render: (
        data: IApplicationStageChecklist,
        row: IApplicationStageChecklist,
        index: number
      ): ReactNode => {
        const pageNumber = useQuery().get('pageNo') || 1;
        const limit = useQuery().get('limit') || 10;
        return (pageNumber - 1) * limit + index + 1;
      },
    },
    {
      title: TEXT.FILE_NAME,
      render(data: IApplicationStageChecklist): ReactNode {
        return (
          <DocumentNameCard
            triggerRefresh={triggerRefresh}
            data={data?.document}
            handleDocumentCountDecrease={handleDocumentCountDecrease}
            applicationData={applicationData}
          />
        );
      },
    },
    {
      title: PLACEHOLDER.DOCUMENT_TYPE,
      render(data: IApplicationStageChecklist): ReactNode {
        return (
          <span>
            {capitalizeFirstLetter(data.document.document_type || '')}
          </span>
        );
      },
    },
    {
      title: TEXT.FILE_SIZE,
      render(data: IApplicationStageChecklist): ReactNode {
        return <span>{`${Math.round(data?.document?.size / 1024)} KB`}</span>;
      },
    },
  ];

  const menuActions = {
    onClick: handleClick,
  };

  return (
    <div className="padding-left-1">
      <div className="slider_content">
        {selectedActor && (
          <Dropdown
            overlayClassName="z-index-zero"
            overlay={addDocumentMenu(menuActions)}
          >
            <Button
              type="primary"
              icon={<PlusOutlined />}
              className="margin-right-2"
              data-testid="addDocument_btn"
            >
              {ADD_DOCUMENT}
            </Button>
          </Dropdown>
        )}

        <Button
          icon={<CloseOutlined />}
          onClick={() => {
            clearDetailAndChecklist();
          }}
        >
          {CLOSE_BTN}
        </Button>
      </div>
      <Table
        columns={columns}
        action={documentData}
        tableName={TEXT.DOCUMENTS}
        className="lead-table"
        refresh={refreshTable}
        hasPagination={false}
      />
    </div>
  );
};

export { ChecklistDocumentTable };
