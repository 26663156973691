import React from 'react';
import Icon from '@ant-design/icons';

const CompareFilledSVG = () => (
  <svg
    width="25"
    height="32"
    viewBox="0 0 104 99"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M80.3061 11.5674C67.2216 11.5674 56.6123 22.1767 56.6123 35.2612C56.6123 48.3456 67.2216 58.9549 80.3061 58.9549C93.3906 58.9549 104 48.3456 104 35.2612C104 22.1767 93.3906 11.5674 80.3061 11.5674ZM90.4606 36.5305C90.4606 36.7632 90.2702 36.9536 90.0375 36.9536H81.9985V44.9925C81.9985 45.2252 81.7327 45.5 81.5 45.5H78.5C78.2673 45.5 78 45.2327 78 45V36.9536H70.5747C70.342 36.9536 70.1516 36.7632 70.1516 36.5305V33.073C70.1516 32.8402 70.342 32.5 70.5747 32.5C70.8074 32.5 78 32.5 78 32.5V25.5C78 25.2673 78.2673 25 78.5 25H81.5C81.7327 25 81.9985 25.2971 81.9985 25.5298V32.5H90C90.2327 32.5 90.5 32.7673 90.5 33L90.4606 36.5305Z"
      fill="#4A2362"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M0 1C0 0.447715 0.447712 0 0.999997 0H80.601C81.1533 0 81.601 0.447716 81.601 1V6.22438C81.601 6.79177 81.1294 7.24378 80.562 7.2426C80.5421 7.24256 80.5222 7.24254 80.5024 7.24254C72.6433 7.24254 65.54 10.4781 60.4518 15.6898C60.26 15.8864 59.998 16 59.7233 16L49 16C47.3431 16 46 17.3431 46 19C46 20.6569 47.3431 22 49 22H54.1399C54.8965 22 55.3831 22.8104 55.0651 23.4969C53.5733 26.7169 52.6749 30.2678 52.5103 34.0094C52.4861 34.5576 52.0418 35 51.4931 35H49C47.3431 35 46 36.3431 46 38C46 39.6569 47.3431 41 49 41H52.2574C52.7312 41 53.1381 41.333 53.2481 41.7938C54.1712 45.6596 55.8954 49.2144 58.2355 52.2729C58.7616 52.9606 58.2899 54 57.4242 54H49C47.3431 54 46 55.3431 46 57C46 58.6569 47.3431 60 49 60H67.0823C67.2465 60 67.408 60.0407 67.5535 60.1167C71.4264 62.1386 75.8309 63.2818 80.5024 63.2818C80.5222 63.2818 80.5421 63.2818 80.562 63.2817C81.1294 63.2805 81.601 63.7325 81.601 64.2999V97.3144C81.601 97.8667 81.1533 98.3144 80.601 98.3144H1C0.447715 98.3144 0 97.8667 0 97.3144V1ZM39.3263 8.11428C39.3263 7.54272 39.7896 7.07939 40.3611 7.07939C40.9327 7.07939 41.396 7.54272 41.396 8.11428V90.005C41.396 90.5766 40.9327 91.0399 40.3611 91.0399C39.7896 91.0399 39.3263 90.5766 39.3263 90.005V8.11428ZM34 19C34 17.3431 32.6569 16 31 16L9 16C7.34315 16 6 17.3431 6 19C6 20.6569 7.34315 22 9 22L31 22C32.6569 22 34 20.6569 34 19ZM31 35C32.6569 35 34 36.3431 34 38C34 39.6569 32.6569 41 31 41H9C7.34315 41 6 39.6569 6 38C6 36.3431 7.34315 35 9 35H31ZM34 57C34 55.3431 32.6569 54 31 54H9C7.34315 54 6 55.3431 6 57C6 58.6569 7.34315 60 9 60H31C32.6569 60 34 58.6569 34 57ZM30.5 73C32.433 73 34 74.567 34 76.5C34 78.433 32.433 80 30.5 80H9.5C7.567 80 6 78.433 6 76.5C6 74.567 7.567 73 9.5 73H30.5ZM70.5 73C72.433 73 74 74.567 74 76.5C74 78.433 72.433 80 70.5 80H49.5C47.567 80 46 78.433 46 76.5C46 74.567 47.567 73 49.5 73H70.5Z"
      fill="#4A2362"
    />
  </svg>
);

export const CompareFilledIcon = (props: any) => (
  <Icon component={CompareFilledSVG} {...props} className="compared-filled" />
);
