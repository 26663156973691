/* eslint-disable react/display-name */
import React from 'react';
import {
  ASSIGNEE_LABEL,
  BLUE,
  LABEL,
  SYSTEM,
  URL_DETAIL_LINK,
} from '@moxie/constants';
import { TableUserNameCard } from '@shared-components/elements';
import { ILead, IUser } from '@shared-components/models';
import { ColumnsType } from 'antd/lib/table';
import { ReactNode } from 'react';
import { Col, Row, Tag } from 'antd';
import { store } from '@lyra/core';
import moment from 'moment';

export const columns: ColumnsType<ILead> = [
  {
    title: LABEL.NAME,
    dataIndex: 'first_name',
    sorter: () => 0,
    render: (_text: string, row: ILead, index: number): ReactNode => (
      <TableUserNameCard
        id={row.id}
        actionBar={false}
        link={`${URL_DETAIL_LINK}${row.id}/activities`}
        firstName={row.first_name || ''}
        email={row.email || ''}
        lastName={row.last_name}
        menu={undefined}
        data={row}
        index={index.toString()}
      />
    ),
  },
  {
    title: LABEL.ADDED_FROM,
    render: (): ReactNode => (
      <div className="lead-table__added-from">{SYSTEM}</div>
    ),
  },
  {
    title: LABEL.TAGS,
    render: (): ReactNode => (
      <div className="lead-table__tag">
        <Tag>{BLUE}</Tag>
      </div>
    ),
  },
  {
    title: LABEL.PHONE_NUMBER,
    dataIndex: 'phone',
    render: (_text: string, row: ILead): ReactNode => (
      <Row className="lead-table__phone">
        <Col>{`${row.country_code || ''} ${row.phone}`}</Col>
      </Row>
    ),
  },
  {
    title: LABEL.CURRENT_CITY,
    dataIndex: 'city',
    render: (text) => (
      <div className="lead-table__city">
        <span>{text && text}</span>
      </div>
    ),
  },
  {
    title: ASSIGNEE_LABEL,
    dataIndex: 'assignee_user_id',
    render: (text: string): React.ReactElement => {
      const user: IUser | undefined = store
        .getState()
        .users?.allData?.find((item: IUser) => item.id === text);

      return (
        <div className="lead-table__archived-by">
          {user && (
            <TableUserNameCard
              index={user.id || ''}
              actionBar={false}
              firstName={user.first_name || ''}
              lastName={user.last_name}
              email={user.email || ''}
              id={user.id || ''}
            />
          )}
        </div>
      );
    },
  },
  {
    title: LABEL.ADDED_ON,
    dataIndex: 'created_at',
    render: (text) => (
      <div className="lead-table__created-at">
        <span>{moment(text).format('MMM DD YYYY')}</span>
      </div>
    ),
  },
  {
    title: LABEL.VISA_EXPIRY_DATE,
    dataIndex: 'visa_expiry_date',
    render: (text) => (
      <div className="lead-table__visa-expiry-date">
        <span>{moment(text).format('MMM DD YYYY')}</span>
      </div>
    ),
  },
];
