import React, { useState } from 'react';
import { PAGE_HEADER_MENU_INSTITUTE } from '@moxie/constants';
import InstitutionTableList from './institution-table-list';
import { InstitutionDrawer } from './institutionDrawer';
import { PageHeaderMenu } from '../../shared';

const InstitutionTableComponent = () => {
  const [drawerOpen, setDrawerOpen] = useState(false);
  const [isEdit, setIsEdit] = useState(false);
  const [refreshTable, setRefreshTable] = useState(true);

  const handleDrawerEvent = (bool: boolean) => setDrawerOpen(bool);
  const handleEditEvent = (bool: boolean) => setIsEdit(bool);
  const triggerRefresh = () => setRefreshTable((prev) => !prev);

  return (
    <div className="full-height">
      <PageHeaderMenu menu={PAGE_HEADER_MENU_INSTITUTE} />
      <InstitutionTableList
        handleDrawerEvent={handleDrawerEvent}
        handleEditEvent={handleEditEvent}
        refreshTable={refreshTable}
        triggerRefresh={triggerRefresh}
        setIsEdit={setIsEdit}
      />
      <InstitutionDrawer
        drawerOpen={drawerOpen}
        handleDrawerEvent={handleDrawerEvent}
        isEdit={isEdit}
        handleEditEvent={handleEditEvent}
        triggerRefresh={triggerRefresh}
      />
    </div>
  );
};

export { InstitutionTableComponent };
