import { PresetColorType } from "antd/es/_util/colors";
import { PresetStatusColorType } from "antd/lib/_util/colors";

export function getTagColor(level: string): PresetColorType | PresetStatusColorType {
  switch (level) {
    case 'pending':
      return 'processing';
    case 'rejected':
      return 'lime';
    case 'discovery':
      return 'lime';
    case 'accepted':
      return 'error';
    default:
      return 'default';
  }
}
