
import { DealsInterface } from '@model/crm/deal.model';
import * as actionTypes from './deal.constant';
import { IAction } from '@model/data.model';

const initialState: Omit<DealsInterface, 'actions'> = {
  id: '',
  contact: {
    id: '',
    email: '',
    lastName: '',
    firstName: ''
  },
  name: '',
  createdAt: '',
  assignees: [],
  branch: {
    id: '',
    name: '',
    email: ''
  },
  payload: {
    id: '',
    countries: [],
    typeOfService: '',
    interests: [],
    services: [],
    degreeLevels: [],
  },
  interestedServiceId: '',
  status: 'discovery',
  internalId : ''
}

export const DEAL_FEATURE_KEY = 'deal';

export const dealReducer = (
  state = initialState,
  action: IAction<any>
): any => {
  switch (action.type) {
    case actionTypes.GET_DEAL_DETAILS_SUCCESS:
      return {
        ...state,
        ...action.payload,
      };

    case actionTypes.RESET_DEAL_STATE:
      return { ...state, ...initialState };
    default:
      return state;
  }
};
