import React, { useEffect } from 'react';
import { Col, InputNumber, Row } from 'antd';
import { IForm, IOtherTestData } from '@shared-components/models';
import Form from 'antd/lib/form';
import { GUTTER, LABEL, LEAD_OTHER_TEST_SCORES } from '@moxie/constants';

const OtherTestScoreForm: React.FC<IForm<IOtherTestData>> = ({
  form,
  name,
  onSubmit,
  initialData,
}: IForm<IOtherTestData>) => {
  useEffect(() => {
    if (initialData && Object.keys(initialData).length > 0) {
      form.setFieldsValue(initialData);
    } else form.resetFields();
  }, [form, initialData]);

  const handleSubmit = async (data: IOtherTestData) => {
    onSubmit && onSubmit(data);
  };

  return (
    <Form layout="vertical" onFinish={handleSubmit} form={form}>
      <Row gutter={GUTTER}>
        {name === LABEL.SAT_I && (
          <Col span={24}>
            <Form.Item
              name="sat_1"
              label={LABEL.SAT_I}
              rules={[
                {
                  required: true,
                  min: 400,
                  type: 'number',
                  max: 1600,
                  message: LEAD_OTHER_TEST_SCORES.VALUE_LIMIT_ERROR,
                },
              ]}
            >
              <InputNumber className="full-width" />
            </Form.Item>
          </Col>
        )}
        {name === LABEL.SAT_II && (
          <Col span={24}>
            <Form.Item
              name="sat_2"
              label={LABEL.SAT_II}
              rules={[
                {
                  required: true,
                  min: 400,
                  type: 'number',
                  max: 1600,
                  message: LEAD_OTHER_TEST_SCORES.VALUE_LIMIT_ERROR,
                },
              ]}
            >
              <InputNumber className="full-width" />
            </Form.Item>
          </Col>
        )}

        {name === LABEL.GRE && (
          <Col span={24}>
            <Form.Item
              name="gre"
              label={LABEL.GRE}
              rules={[
                {
                  required: true,
                  type: 'number',
                  min: 260,
                  max: 340,
                  message: LEAD_OTHER_TEST_SCORES.VALUE_LIMIT_ERROR_GRE,
                },
              ]}
            >
              <InputNumber className="full-width" />
            </Form.Item>
          </Col>
        )}
      </Row>
    </Form>
  );
};

export { OtherTestScoreForm };
