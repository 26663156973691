/* eslint-disable react/prop-types */
import React from 'react';
import { useAppSelector } from '@lyra/core';
import { Table as AntTable, Divider, Row, Col } from 'antd';
import { ColumnsType } from 'antd/lib/table';
import {
  ACADEMIC_REQUIREMENT,
  ACADEMIC_REQUIREMENT_CONTENT,
  ENGLISH_LANGUAGE_REQUIREMENT,
  ENGLISH_LANGUAGE_REQUIREMENT_CONTENT,
  GUTTER,
  PRODUCT_TABLE,
} from '@moxie/constants';
import {
  IAcademicRequirementsTable,
  IProductDetailFees,
} from '@shared-components/models';
import { ProductDetailUtil } from '@moxie/shared';

export const AcademicRequirementsTable: React.FC<IAcademicRequirementsTable> = (
  props: IAcademicRequirementsTable
) => {
  const { data, loading, className } = props;

  const columns: ColumnsType<any> = [
    {
      title: PRODUCT_TABLE.DEGREE_LEVEL,
      key: 'degreeLevel',
      width: 200,
      render(data: any) {
        return (
          <>
            <span className="prd-title">{data?.degreeLevel?.name}</span>
          </>
        );
      },
    },
    {
      title: PRODUCT_TABLE.PERCENTAGE_REQUIREMENT,
      dataIndex: 'percentage',
      key: 'percentage',
      className: 'prd-primary',
      width: 200,
      render(data: string) {
        return <span className="prd-title prd-primary">{data && data}</span>;
      },
    },
    {
      title: PRODUCT_TABLE.GPA_REQUIREMENT,
      dataIndex: 'gpa',
      key: 'gpa',
      className: 'prd-primary',
      width: 200,
      render(data: string) {
        return <span className="prd-title prd-primary">{data && data}</span>;
      },
    },
    {
      title: PRODUCT_TABLE.REMARK,
      dataIndex: 'remark',
      key: 'remark',
      className: 'prd-primary ',
      width: 350,
      render(remark: any, data, index) {
        return (
          <div className="prd-title prd-primary">
            {remark && <ProductDetailUtil index={index} remark={remark} />}
          </div>
        );
      },
    },
  ];
  return (
    <AntTable
      dataSource={data || []}
      columns={columns}
      loading={loading}
      size={'small'}
      className={className}
      pagination={false}
      scroll={{
        x: true,
      }}
    />
  );
};

export const EnglishLanguageRequirementsTable: React.FC<IAcademicRequirementsTable> = (
  props: IAcademicRequirementsTable
) => {
  const { data, loading, className } = props;
  const columns: ColumnsType<any> = [
    {
      title: PRODUCT_TABLE.NAME,
      key: 'test_name',
      width: 200,
      render(data: { test_name: string } | any) {
        return (
          <Row gutter={GUTTER}>
            <Col span={12} className="prd-title uppercase">
              {data?.test_name}
            </Col>
          </Row>
        );
      },
    },
    {
      title: PRODUCT_TABLE.LISTENING,
      key: 'listening',
      render(data: any) {
        return (
          <span className="prd-title prd-primary">
            {
              data?.each_band?.filter(
                (band: { name: string; score: any }) =>
                  band?.name.toLowerCase() === 'listening'
              )[0]?.score
            }
          </span>
        );
      },
    },
    {
      title: PRODUCT_TABLE.WRITING,
      key: 'writing',
      render(data: any) {
        return (
          <span className="prd-title prd-primary">
            {
              data?.each_band?.filter(
                (band: { name: string; score: any }) =>
                  band?.name.toLowerCase() === 'writing'
              )[0]?.score
            }
          </span>
        );
      },
    },
    {
      title: PRODUCT_TABLE.READING,
      key: 'reading',
      render(data: any) {
        return (
          <span className="prd-title prd-primary">
            {
              data?.each_band?.filter(
                (band: { name: string; score: any }) =>
                  band?.name.toLowerCase() === 'reading'
              )[0]?.score
            }
          </span>
        );
      },
    },
    {
      title: PRODUCT_TABLE.SPEAKING,
      key: 'speaking',
      render(data: any) {
        return (
          <span className="prd-title prd-primary">
            {
              data?.each_band?.filter(
                (band: { name: string; score: any }) =>
                  band?.name.toLowerCase() === 'speaking'
              )[0]?.score
            }
          </span>
        );
      },
    },
    {
      title: PRODUCT_TABLE.OVERALL,
      dataIndex: 'overall_score',
      key: 'overall_score',
      className: 'prd-primary',
      render(data: string) {
        return <span className="prd-title prd-primary">{data && data}</span>;
      },
    },
    {
      title: PRODUCT_TABLE.REMARK,
      dataIndex: 'remark',
      key: 'remark',
      className: 'prd-primary ',
      width: 350,
      render(remark: any, data, index) {
        return (
          <div className="prd-title prd-primary">
            {remark && <ProductDetailUtil index={index} remark={remark} />}
          </div>
        );
      },
    },
  ];
  return (
    <AntTable
      dataSource={data || []}
      columns={columns}
      loading={loading}
      size={'small'}
      className={className}
      pagination={false}
      scroll={{
        x: true,
      }}
    />
  );
};

export const OtherLanguageRequirementsTable: React.FC<IAcademicRequirementsTable> = (
  props: IAcademicRequirementsTable
) => {
  const { data, loading, className } = props;
  const columns: ColumnsType<any> = [
    {
      title: PRODUCT_TABLE.NAME,
      key: 'name',
      width: 200,
      render(data: { other_test_name: string }) {
        return (
          <>
            <span className="prd-title uppercase">
              {data?.other_test_name?.toString().replace('_', ' ')}
            </span>
          </>
        );
      },
    },
    {
      title: PRODUCT_TABLE.MARKS,
      key: 'overall_score',
      render(data: { overall_score: string }) {
        return (
          <>
            <span className="prd-primary">{data?.overall_score}</span>
          </>
        );
      },
    },
    {
      title: PRODUCT_TABLE.REMARK,
      dataIndex: 'remark',
      key: 'remark',
    },
  ];
  return (
    <AntTable
      dataSource={data || []}
      columns={columns}
      loading={loading}
      size={'small'}
      className={className}
      pagination={false}
      scroll={{
        x: true,
      }}
    />
  );
};

const ProductRequirement: React.FC<IProductDetailFees> = () => {
  const productEducations = useAppSelector(
    (state: any) => state.product?.product?.product_educations
  );
  const productLangTestScores = useAppSelector(
    (state: any) => state.product?.product?.product_lang_test_scores
  );
  const productLangOtherTestScores = useAppSelector(
    (state: any) => state.product?.product?.product_lang_other_test_scores
  );

  return (
    <main className="prd-main table-height">
      <section className="prd-main__academic">
        <h1>{ACADEMIC_REQUIREMENT}</h1>
        <div className="prd-main__desc">{ACADEMIC_REQUIREMENT_CONTENT}</div>
        <AcademicRequirementsTable
          data={productEducations}
          className="margin-top-1"
        />
      </section>
      <Divider />
      <section className="prd-main__langrequirements">
        <h1>{ENGLISH_LANGUAGE_REQUIREMENT}</h1>
        <div className="prd-main__desc">
          {ENGLISH_LANGUAGE_REQUIREMENT_CONTENT}
        </div>
        <EnglishLanguageRequirementsTable
          data={productLangTestScores}
          className="margin-top-1"
        />
      </section>
      <Divider />
      <section className="prd-main__otherrequirements">
        <h1>Other Requirements</h1>
        <OtherLanguageRequirementsTable
          data={productLangOtherTestScores}
          className="margin-top-1"
        />
      </section>
    </main>
  );
};

export { ProductRequirement };
