import React, { useEffect } from 'react';
import ProLayout from '@ant-design/pro-layout';
import { NavLink } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { IDashboardLayoutProps } from '@shared-components/models';
import { ApplyIMSLogoLight } from '@shared-components/elements';
import { ADMIN_COOKIE_ACCESS, PROJECT_PORTAL } from '@moxie/constants';
import Cookies from 'js-cookie';
import { themeActions } from '@admin/core';
import DashboardHeader from './dashboard-header';
import { routes } from '../dashboard-routes';
import { authActions } from '@admin/core';
import { ShortApplyIMSLogoLight } from 'libs/shared/src/elements/icons/libs/short-apply-ims-logo-light';

const AdminDashboardLayout: React.FC<IDashboardLayoutProps> = (
  props: IDashboardLayoutProps
) => {
  const { children, className } = props;
  const dispatch = useDispatch();
  const collapsed = useSelector((store: any) => store.theme.navCollapsed);

  const handleNavbarToggle = (bool: boolean) =>
    dispatch(themeActions.toggleNavbar(bool));

  useEffect(() => {
    if (!Cookies.get(ADMIN_COOKIE_ACCESS)) {
      return;
    }
    dispatch(authActions.isAuthenticated());
  }, [dispatch]);

  return (
    <ProLayout
      {...routes}
      {...props}
      siderWidth={250}
      className={`dashboard-layout ${className}`}
      navTheme="dark"
      headerHeight={60}
      collapsed={collapsed}
      collapsedButtonRender={false}
      logo={() =>
        collapsed ? (
          <div className="sidebar-short-logo-position">
            <ShortApplyIMSLogoLight />
          </div>
        ) : (
          <ApplyIMSLogoLight />
        )
      }
      title={PROJECT_PORTAL.ADMIN_TITLE}
      menuItemRender={(item, dom) => (
        <NavLink to={`${item.path}`}>{dom}</NavLink>
      )}
      fixSiderbar={true}
      fixedHeader={true}
      onCollapse={handleNavbarToggle}
      headerContentRender={() => (
        <DashboardHeader
          setCollapsed={handleNavbarToggle}
          collapsed={collapsed}
        />
      )}
    >
      {children}
    </ProLayout>
  );
};

export { AdminDashboardLayout };
