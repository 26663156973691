import { productsActions } from '@admin/core';
import {
  ADD_EDUCATION_TITLE,
  CANCEL_BTN,
  DRAWER_WIDTH,
  EDIT_EDUCATION_TITLE,
  SAVE_BTN,
  UPDATE_BTN,
} from '@moxie/constants';
import { Button } from '@moxie/shared';
import { DrawerElem } from '@shared-components/elements';
import { IAddEditProps } from '@shared-components/models';
import { Space } from 'antd';
import React, { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import AcademicRequirementForm from './academic-requirement-form';

const AcademicRequirementDrawer: React.FC<IAddEditProps> = ({
  isOpen,
  onClose,
  form,
  isEdit,
  initialData,
  productId,
}: IAddEditProps) => {
  const [disabled, setDisabled] = useState<boolean>(true);
  const dispatch = useDispatch();

  const handleSubmitButtonClick = () => {
    setDisabled(true);
    form.submit();
  };

  const handleSubmit = async (data: any) => {
    if (initialData?.id) {
      data.product_id = productId;
      dispatch(
        productsActions.updateProductEducationRequest(initialData?.id, data)
      );
    } else {
      data.product_id = productId;
      dispatch(productsActions.addProductEducationRequest(data));
    }
    onClose();
  };

  useEffect(() => {
    if (isEdit) {
      form.setFieldsValue(initialData);
    } else {
      form.setFieldsValue({});
    }
  }, [initialData, form, isEdit]);

  return (
    <DrawerElem
      title={initialData ? EDIT_EDUCATION_TITLE : ADD_EDUCATION_TITLE}
      width={DRAWER_WIDTH}
      onClose={onClose}
      visible={isOpen}
      footer={
        <div className="padding-bottom-2 text-align-right">
          <Space direction="horizontal">
            <Button onClick={onClose} className="lead-margin-right">
              {CANCEL_BTN}
            </Button>
            <Button
              onClick={handleSubmitButtonClick}
              type="primary"
              disabled={disabled}
            >
              {initialData?.id ? UPDATE_BTN : SAVE_BTN}
            </Button>
          </Space>
        </div>
      }
    >
      <AcademicRequirementForm
        onSubmit={handleSubmit}
        form={form && form}
        setDisabled={setDisabled}
      />
    </DrawerElem>
  );
};

export { AcademicRequirementDrawer };
