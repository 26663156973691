import { IOfficeVisitCrm, IResponse } from '@shared-components/models';
import { API_URL } from '@moxie/constants';
import crmAxios from './crm-api-axios';
import { CrmResponse, PaginationMeta } from '@model/api-response.model';

const officeVisitAdd = (payload: IOfficeVisitCrm): Promise<IResponse> => {
  return crmAxios.post(`${API_URL.CRM_API_OFFICE_VISIT}`, payload);
};

const officeVisitFetch = async (id: string): Promise<CrmResponse<IOfficeVisitCrm>> => {
  return (await crmAxios.get(`${API_URL.CRM_API_OFFICE_VISIT}/${id}`))?.data;
};

const officeVisitList = async (params: Record<string, unknown>): Promise<CrmResponse<IOfficeVisitCrm[]>> => {
  return (await crmAxios.get<CrmResponse<IOfficeVisitCrm[]>>(`${API_URL.CRM_API_OFFICE_VISIT}/`, {
    params: {
      ...params
    }
  }))?.data;
};

const officeVisitUpdate = (
  id: string,
  payload: Partial<IOfficeVisitCrm>
): Promise<IResponse> => {
  return crmAxios.put(`${API_URL.CRM_API_OFFICE_VISIT}/${id}`, payload);
};

const fetchPrevVisit = async (id: string): Promise<IResponse> => {
  return (await crmAxios.get(`${API_URL.CRM_API_OFFICE_VISIT}/${id}/`))?.data;
};

const fetchPrevVisitByContact = async (contactId: string, params?: Record<string, unknown>) => {

  const queryParams: Record<string, unknown> = {
    ...params,
    'filter.contactId': contactId,
  };

  return (await crmAxios.get<{ data: { data: IOfficeVisitCrm[]; meta: PaginationMeta; } }>(`${API_URL.CRM_API_OFFICE_VISIT}/`, {
    params: queryParams,
  }))?.data;
};

const getAllVisitActivityLogs = async (id: string): Promise<IResponse> => {
  const { data } = await crmAxios.get(`${API_URL.CRM_API_OFFICE_VISIT}/${id}/logs`);
  return data;
};

const getOfficeVisitsByContact = async (
  contactId: string,
  params?: any
): Promise<CrmResponse<IOfficeVisitCrm>> => {
  return (await crmAxios.get(`${API_URL.CRM_API_OFFICE_VISIT}`, {
    params: {
      ...params,
      'filter.contactId': contactId
    }
  })).data;
};

export {
  officeVisitAdd,
  officeVisitFetch,
  officeVisitUpdate,
  officeVisitList,
  fetchPrevVisit,
  fetchPrevVisitByContact,
  getAllVisitActivityLogs,
  getOfficeVisitsByContact,
};
