import { useAppSelector, workflowActions } from '@lyra/core';
import {
  getAllApplications,
  getClientByAssigneeList,
  getContactByBranch,
} from '@lyra/services.api';
import { TEXT } from '@moxie/constants';
import { DashboardLayout, PageHeaderTitle } from '@moxie/shared';
import { Spin } from 'antd';
import React, { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { RootState } from '../../core/store';
import DashboardCharts from './dashboard-cards';
import DropdownComponent from './dashboard-dropdown';

const Dashboard: React.FC = () => {
  const [branchData, setBranchData] = useState([]);
  const [applicationData, setApplicationData] = useState([]);
  const [clientByUserList, setClientByUserList] = useState([]);
  const [loading, setLoading] = useState(false);
  const dispatch = useDispatch();
  const { user } = useAppSelector((store: RootState) => ({
    user: store.auth.user,
  }));

  const fetchAllContacts = async () => {
    const response = await getContactByBranch();
    setBranchData(response?.data?.data);
  };
  const fetchAllApplications = async () => {
    try {
      const response = await getAllApplications();
      setApplicationData(response?.data?.data);
    } catch (e) {
      return;
    }
  };

  const fetchClientsByAssignee = async () => {
    const response = await getClientByAssigneeList();
    setClientByUserList(response?.data?.data);
  };

  useEffect(() => {
    const loadData = async () => {
      // dispatch(userAppointmentActions.getUserAppointmentRequest(user?.id));
      // dispatch(tasksActions.getTasksRequest(params));
      setLoading(true);
      dispatch(workflowActions.getWorkflowsRequest());
      await fetchAllContacts();
      await fetchAllApplications();
      await fetchClientsByAssignee();
      setLoading(false);
    };
    loadData();
  }, [user]);
  return (
    <DashboardLayout>
      {loading ? (
        <div className="dashboard-spinner">
          <Spin size="large" />
        </div>
      ) : (
        <div className="padding-right-2 padding-left-2">
          <PageHeaderTitle title={TEXT.DASHBOARD} />
          <DropdownComponent
            setBranchData={setBranchData}
            setClientByUserList={setClientByUserList}
            setApplicationData={setApplicationData}
          />

          <DashboardCharts
            currentUser={user}
            contacts={branchData}
            applications={applicationData && applicationData}
            clients={clientByUserList}
          />
        </div>
      )}
    </DashboardLayout>
  );
};

export { Dashboard };
