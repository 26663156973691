import React from 'react';
import { IService, IServiceDrawerComponent } from '@shared-components/models';
import { Button, DrawerElem } from '@shared-components/elements';
import {
  CANCEL_BTN,
  DRAWER_WIDTH,
  LABEL,
  SAVE_BTN,
  UPDATE_BTN,
} from '@moxie/constants';
import { Space } from 'antd';
import { ServiceForm } from '../../forms/service';
import { useDispatch } from 'react-redux';
import { serviceActions } from '@crm/core';

const ServiceDrawer: React.FC<IServiceDrawerComponent> = ({
  form,
  visible,
  onClose,
  disabled,
  setDisabled,
  handleClose,
  setRefreshTable,
  initialData,
  isEdit,
}: IServiceDrawerComponent) => {
  const dispatch = useDispatch();

  const triggerRefresh = () => {
    setRefreshTable((prev: boolean) => !prev);
  };

  const handleSubmit = async (data: IService) => {
    let serviceFormData;
    if (initialData?.id) {
      serviceFormData = {
        ...data,
        id: initialData.id,
      };
      await dispatch(
        serviceActions.updateRequest(serviceFormData, triggerRefresh)
      );
    } else {
      serviceFormData = data;
      await dispatch(
        serviceActions.addRequest(serviceFormData, triggerRefresh)
      );
    }
    handleClose && handleClose();
  };

  const handleFormSubmitViaBtn = () => {
    form.submit();
    setDisabled(true);
  };

  const handleFieldsChange = (allFields: string | any[]) => {
    for (const fields of allFields) {
      fields.errors.length <= 0 ? setDisabled(false) : setDisabled(true);
    }
  };

  return (
    <DrawerElem
      data-testid="crm-addservicedrawer"
      visible={visible}
      onClose={onClose}
      width={DRAWER_WIDTH}
      title={isEdit ? LABEL.EDIT_SERVICE : LABEL.CREATE_NEW_SERVICE}
      footer={
        <div className="padding-bottom-2 text-align-right">
          <Space direction="horizontal">
            <Button
              onClick={onClose}
              className="lead-margin-right"
              htmlType="reset"
            >
              {CANCEL_BTN}
            </Button>
            <Button
              type="primary"
              disabled={disabled}
              onClick={handleFormSubmitViaBtn}
              htmlType="submit"
            >
              {isEdit ? UPDATE_BTN : SAVE_BTN}
            </Button>
          </Space>
        </div>
      }
    >
      <ServiceForm
        form={form}
        setDisabled={setDisabled}
        onSubmit={handleSubmit}
        initialData={initialData}
        onChange={handleFieldsChange}
      />
    </DrawerElem>
  );
};
export { ServiceDrawer };
