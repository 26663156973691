import React from 'react';
import { LeftOutlined } from '@ant-design/icons';
import { TEXT, URL_PRODUCTS } from '@moxie/constants';
import { Button, OverViewHeader } from '@shared-components/elements';
import { Spin } from 'antd';
import { Link } from 'react-router-dom';
import { ProductProfile } from './product-profile';
import { ProductDetailContent } from './product-detail-content';
import { useAppSelector } from '@admin/core';

const ProductDetailComponent: React.FC = () => {
  const { productData, productDataLoading } = useAppSelector((state: any) => ({
    productData: state.product.product,
    productDataLoading: state.product.loading,
  }));
  return (
    <Spin spinning={productDataLoading} tip={TEXT.LOADING}>
      <OverViewHeader
        title={TEXT.PRODUCT}
        actions={
          <Link to={URL_PRODUCTS}>
            <Button icon={<LeftOutlined />}>{TEXT.PRODUCT_LIST}</Button>
          </Link>
        }
      />
      <ProductProfile
        data={!productDataLoading && productData}
        loading={productDataLoading}
      />
      {productData && <ProductDetailContent data={productData} />}
    </Spin>
  );
};
export { ProductDetailComponent };
