import axios from 'axios';
import {
  IForgetPassword,
  IForgetPasswordResponse,
} from '@shared-components/models';
import { AM_API_URL } from '@moxie/constants';

const forgetPassword = (
  body: IForgetPassword
): Promise<IForgetPasswordResponse> => {
  return axios.post(process.env.NX_BASE_URL + AM_API_URL.FORGETPASSWORD, body, {
    headers: {
      tenant: process.env.NX_DEV_TENANT_URL,
    },
  });
};

export { forgetPassword };
