import { MailOutlined, PhoneOutlined } from '@ant-design/icons';
import { TEXT } from '@moxie/constants';
import { Avatar, Col, Row, Tag, Typography } from 'antd';
import Text from 'antd/lib/typography/Text';
import React from 'react';

export interface IContactProfileCardProps {
  firstName: string;
  lastName: string;
  email: string;
  phone?: string;
  status?: string;
  branchName?: string;
  id: string;
  archivedContact?: boolean;
}

export const ContactProfile: React.FC<IContactProfileCardProps> = ({
  id,
  firstName,
  lastName,
  email,
  phone,
  status,
  branchName,
  archivedContact,
}: IContactProfileCardProps) => (
  <Row className="office-visit__contact-name-card padding-top-1 padding-bottom-1">
    <Col span={3}>
      <div className="align-items-center user-name-card__avatar">
        <Avatar size={'large'} className="initial_capital">
          {firstName?.substring(0, 1)}
          {lastName?.substring(0, 1)}
        </Avatar>
      </div>
    </Col>
    <Col span={20}>
      <Row className="padding-bottom-1 margin-left-1 padding-top-1">
        <Col span={21} className="contact-profile-name">
          <Text className="text-primary-color initial_capital">
            {`${firstName} ${lastName}`}
          </Text>
          <Text className="contact-profile-text margin-left-1">|</Text>
          <Text className="profile__title margin-left-1">{id}</Text>
        </Col>
        <Col span={3}>
          {archivedContact ? (
            <Tag className="tag-archived">{TEXT.ARCHIVED}</Tag>
          ) :
            <Tag className='initial_capital'>{status}</Tag>}
        </Col>
      </Row>
      <Row className="margin-left-1 office-visit__text" wrap={false}>
        <Col>
          <MailOutlined className="table-mail-icon" />
        </Col>
        <Col>
          <Typography.Paragraph className="contact-profile-text office-visit__text " ellipsis={{ rows: 1, tooltip: true }}>
            {email?.toLocaleLowerCase()}
          </Typography.Paragraph>
        </Col>
      </Row>

      <Row align="middle" className="office-visit__text" wrap={false}>
        <Col>
          <PhoneOutlined className="table-mail-icon margin-left-1" />
        </Col>
        <Col>
          <Text className="contact-profile-text office-visit__text">{phone ?? 'N/A'}</Text>
        </Col>
      </Row>

      {branchName ?
        <Row className="margin-left-1" wrap={false}>
          <Text className="contact-profile-title">Branch:</Text>
          <Typography.Paragraph className="contact-profile-text padding-left-1 office-visit__text" ellipsis={{ rows: 1, tooltip: true }}>
            {branchName}
          </Typography.Paragraph>
        </Row>
        : null}
    </Col>

  </Row>
);

