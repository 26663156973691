/* eslint-disable no-unused-vars */
import { ITasks, IAction } from '@shared-components/models';
import * as actionTypes from './tasks.constant';

export const TASKS_FEATURE_KEY = 'tasks';

export interface ITasksState {
  tasksList: ITasks[];
  remainingTasks: ITasks[];
  error: boolean | null;
  loading: boolean;
  downloading: boolean;
}

export const initialTasksState: ITasksState = {
  tasksList: [],
  remainingTasks: [],
  error: false,
  loading: false,
  downloading: false,
};

export const tasksReducer = (
  state = initialTasksState,
  action: IAction<any>
) => {
  switch (action.type) {
    case actionTypes.GET_TASKS_REQUEST:
      return {
        ...state,
        loading: true,
      };

    case actionTypes.GET_TASKS_SUCCESS:
      return {
        ...state,
        loading: false,
        tasksList: [...action.payload],
        remainingTasks: action.payload.filter(
          (task: any) => task.status !== 'done'
        ),
      };

    case actionTypes.GET_TASKS_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.payload,
      };

    case actionTypes.GET_TASKS_CLEAR:
      return {
        ...state,
        loading: false,
        tasksList: [],
        remainingTasks: [],
        error: null,
      };

    case actionTypes.DELETE_TASK_REQUEST:
      return { ...state, loading: true };

    case actionTypes.DELETE_TASK_SUCCESS:
      return {
        ...state,
        loading: true,
        tasksList: state.tasksList.filter((item) => item.id !== action.payload),
      };

    case actionTypes.DELETE_TASK_FAILURE:
      return { ...state, error: action.payload };

    case actionTypes.ADD_TASK_REQUEST:
      return {
        ...state,
        loading: true,
        error: null,
      };
    case actionTypes.ADD_TASK_SUCCESS:
      return {
        ...state,
        loading: false,
        error: false,
        tasksList: [action.payload, ...state.tasksList],
      };
    case actionTypes.ADD_TASK_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.payload,
      };
    case actionTypes.UPDATE_TASK_STATUS_REQUEST:
      return {
        ...state,
        error: false,
        loading: true,
      };
    case actionTypes.UPDATE_TASK_STATUS_SUCCESS:
      return {
        ...state,
        error: false,
        loading: false,
        remainingTasks: action.payload,
      };
    case actionTypes.UPDATE_TASK_STATUS_FAILURE:
      return {
        ...state,
        loading: false,
        error: true,
      };
    case actionTypes.UPDATE_TASK_REQUEST:
      return {
        ...state,
        error: false,
        loading: true,
      };
    case actionTypes.UPDATE_TASK_SUCCESS:
      return {
        ...state,
        loading: false,
        error: false,
        tasksList: state.tasksList.map((item) =>
          item.id === action.payload.id ? action.payload : item
        ),
      };
    case actionTypes.UPDATE_TASK_FAILURE:
      return {
        ...state,
        loading: false,
        error: true,
      };
    case actionTypes.UPDATE_TASK_ASSIGNEE_REQUEST:
      return {
        ...state,
        error: false,
        loading: true,
      };
    case actionTypes.UPDATE_TASK_ASSIGNEE_SUCCESS:
      return {
        ...state,
        error: false,
        loading: false,
        tasksList: action.payload,
      };
    case actionTypes.UPDATE_TASK_ASSIGNEE_FAILURE:
      return {
        ...state,
        loading: false,
        error: true,
      };

    case actionTypes.DOWNLOADING:
      return {
        ...state,
        loading: false,
        downloading: action.payload,
        error: false,
      };

    default:
      return state;
  }
};

export default tasksReducer;
