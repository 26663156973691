import axios from './axios';
import { IProfessionalExperience, IResponse } from '@shared-components/models';
import { API_URL } from '@moxie/constants';
const addProfessionalExperience = async (
  body: IProfessionalExperience
): Promise<IResponse> => {
  return axios.post(API_URL.PROFESSIONAL_EXPERIENCE, body);
};

const updateProfessionalExperience = async (
  body: IProfessionalExperience
): Promise<IResponse> => {
  return axios.put(`${API_URL.PROFESSIONAL_EXPERIENCE}/${body.id}`, body);
};

const getAllProfessionalExperience = async (
  params = ''
): Promise<IResponse> => {
  if (params?.length > 1) {
    return axios.get(`${API_URL.PROFESSIONAL_EXPERIENCE}/${params}`);
  } else {
    return axios.get(`${API_URL.PROFESSIONAL_EXPERIENCE}`);
  }
};

const deleteProfessionalExperience = async (id: string): Promise<IResponse> => {
  return axios.delete(`${API_URL.PROFESSIONAL_EXPERIENCE}/${id}`);
};

export {
  addProfessionalExperience,
  updateProfessionalExperience,
  getAllProfessionalExperience,
  deleteProfessionalExperience,
};
