import React, { useEffect, useState } from 'react';
import { Button, Card, Space, Spin, Typography } from 'antd';
import { MailFilled } from '@ant-design/icons';
import { Logo } from '@shared-components/elements';
import {
  NO_TOKEN_MESSAGE,
  VERIFICATION_BTN_SEND_VERIFICATION,
  VERIFICATION_TITLE,
  BACK_TO_SIGN_IN,
  RESEND_VERIFICATION_SUCCESS,
  VERIFICATION_FAILED,
  VERIFICATION_SUCCESSFUL,
  URL_LOGIN,
} from '@moxie/constants';
import { Link } from 'react-router-dom';
import { errorHandler, useQuery } from '@moxie/utils';
import {
  IResendEmailVerification,
  IVerifyEmailProps,
} from '@shared-components/models';
import { ForgetPassword } from '@shared-components/forms';
import { IEmailVerificationResponse } from '@shared-components/models';
import { successNotificationHandler } from '../../shared';

const { Title, Paragraph } = Typography;

const VerifyEmail: React.FC<IVerifyEmailProps> = ({
  onEmailVerification,
  onResendEmailVerification,
}: IVerifyEmailProps) => {
  const [loading, setLoading] = useState(true);
  const [showEmailInput, setShowEmailInput] = useState(false);
  const [resendEmailLoading, setResendEmailLoading] = useState(false);
  const [isVerified, setVerified] = useState(false);

  const query = useQuery();
  const [token] = useState(query.get('token'));

  const handleVerification = async (data: string) => {
    setLoading(true);
    try {
      if (!data || data.length === 0) throw new Error(NO_TOKEN_MESSAGE);
      const response: IEmailVerificationResponse = await onEmailVerification({
        token: data,
      });
      if (response?.data?.success) {
        setVerified(true);
      }
    } catch (err) {
      errorHandler(err);
    }
    setLoading(false);
  };

  const handleSendVerification = async (data: IResendEmailVerification) => {
    setResendEmailLoading(true);
    try {
      const response: IEmailVerificationResponse = await onResendEmailVerification(
        data
      );
      if (response?.data?.success) {
        successNotificationHandler(RESEND_VERIFICATION_SUCCESS);
      }
    } catch (err) {
      errorHandler(err);
    }
    setResendEmailLoading(false);
  };

  const handleSubmit = (data: IResendEmailVerification) => {
    handleSendVerification(data);
  };

  const toggleInputView = () => {
    setShowEmailInput(true);
  };

  useEffect(() => {
    handleVerification(token);
    setLoading(false);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [token]);

  return (
    <section className={`auth-layout auth-layout--login`}>
      <div className="auth-layout__card">
        <Card className="padding-3">
          <div className="padding-top-2 padding-bottom-2 text-align-center">
            <Logo mode="dark" />
            <MailFilled className="auth-layout__mail-icon padding-top-2 padding-bottom-2" />
            <Title level={3}>{VERIFICATION_TITLE}</Title>
            <Spin spinning={loading}>
              {!showEmailInput && (
                <Paragraph>
                  {isVerified && !loading
                    ? VERIFICATION_SUCCESSFUL
                    : VERIFICATION_FAILED}
                </Paragraph>
              )}
              {showEmailInput && (
                <ForgetPassword
                  onSubmit={handleSubmit}
                  loading={resendEmailLoading}
                />
              )}
            </Spin>
            <div className="padding-top-2">
              <Space direction="vertical">
                {!isVerified && !showEmailInput && (
                  <Button
                    type="ghost"
                    onClick={toggleInputView}
                    data-testid="toggle-button"
                  >
                    {VERIFICATION_BTN_SEND_VERIFICATION}
                  </Button>
                )}
                {!showEmailInput && (
                  <Link to={URL_LOGIN}>
                    <Paragraph type="secondary">{BACK_TO_SIGN_IN}</Paragraph>
                  </Link>
                )}
              </Space>
            </div>
          </div>
        </Card>
      </div>
    </section>
  );
};

export { VerifyEmail };
