import React, { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { ILogin as ILoginModal } from '@shared-components/models';
import { authActions, useAppSelector } from '@admin/core';
import { RootState } from 'apps/admin/src/core/store';
import { useHistory } from 'react-router-dom';
import { URL_DASHBOARD } from '@moxie/constants';
import { LoginForm } from '../forms';
import { Card, Typography } from 'antd';
import { ApplyIMSLogoDark, Logo } from '@shared-components/elements';

const { Title } = Typography;

const LoginModule: React.FC = () => {
  const [isAuthenticated, loading] = useAppSelector((store: RootState) => {
    return [store.auth.isAuthenticated, store.auth.loading];
  });
  const history = useHistory();

  useEffect(() => {
    if (isAuthenticated && !loading) history.push(URL_DASHBOARD);
  }, [isAuthenticated, loading, history]);

  const dispatch = useDispatch();

  const handleSubmit = async (data: ILoginModal) => {
    data.email = data.email.toLocaleLowerCase().trim();
    dispatch(authActions.login(data));
  };

  return (
    <>
      <div className="text-align-center padding-bottom-1">
        <ApplyIMSLogoDark />
        <Title level={3}>Sign in to your account</Title>
      </div>
      <LoginForm onSubmit={handleSubmit} />
    </>
  );
};

export { LoginModule };
