import React, { useEffect, useState } from 'react';
import { Col, Row, Typography } from 'antd';
import {
  DocumentFileIcons,
  DocumentNameEditCard,
  DocumentMenuCard,
} from '@moxie/shared';
import { IDocNameCardComponent, IDocNameForm } from '@shared-components/models';
import { useForm } from 'antd/lib/form/Form';
import { updateDocument } from '@lyra/services.api';
import { useAppSelector } from '@lyra/core';

const DocumentNameCard: React.FC<IDocNameCardComponent<any>> = ({
  data,
  triggerRefresh,
  showMenu = true,
  handleDocumentCountDecrease,
  archived,
  isAdmin,
  applicationData,
  leadData,
}: IDocNameCardComponent<any>) => {
  const [isEdit, setIsEdit] = useState(false);
  const [form] = useForm();
  const { userId, socket } = useAppSelector((state) => ({
    userId: state.auth.user?.id,
    socket: state.socket.wss,
  }));

  useEffect(() => {
    return;
  }, [isEdit]);

  const handleSubmit = async (formData: IDocNameForm) => {
    await updateDocument(data?.id, {
      name: formData.fileName,
      document_type: data.document_type,
    });
    if (applicationData)
      socket?.emit('create-application-notification-to-server', {
        user_id: userId,
        receivers_many: applicationData.agent_clients?.map((item: any) => {
          return item.id;
        }),
        type: 'application-updated',
        update_type: 'document-renamed',
        parent_id: applicationData.client.id,
        application_identifier: applicationData.app_identifier,
        application_id: applicationData.id,
      });
    if (leadData)
      socket?.emit('document-notification', {
        user_id: userId,
        parent_id: leadData.id,
        receiver_id: leadData.assignee_id,
        receivers_many: leadData?.followers.map((item: any) => item.id),
        type: 'document-renamed',
      });
    setIsEdit(false);
    triggerRefresh?.();
  };

  const handleCancel = () => {
    setIsEdit(false);
  };

  return (
    <Row gutter={10} justify="space-between">
      <Col span={showMenu ? 20 : 24}>
        <Row justify="center">
          <Col span={4}>
            <DocumentFileIcons mimetype={data?.mimetype} />
          </Col>
          <Col span={20} className="profile_document_icon_text">
            {isEdit ? (
              <DocumentNameEditCard
                documentData={data}
                form={form}
                handleCancel={handleCancel}
                handleSubmit={handleSubmit}
              />
            ) : (
              <Typography.Text
                className="text-primary-color margin-left-1 ellipsis-text"
                title={data?.name}
                ellipsis={
                  String(data?.name).length >= 35
                    ? { tooltip: data?.name }
                    : false
                }
              >
                {data?.name}
              </Typography.Text>
            )}
          </Col>
        </Row>
      </Col>
      {showMenu ? (
        <Col span={4}>
          <DocumentMenuCard
            extension={data.extension}
            docId={data?.id}
            setIsEdit={setIsEdit}
            triggerRefresh={triggerRefresh}
            handleDocumentCountDecrease={handleDocumentCountDecrease}
            archived={archived}
            isAdmin={isAdmin}
            applicationData={applicationData && applicationData}
            leadData={leadData && leadData}
          />
        </Col>
      ) : null}
    </Row>
  );
};

export { DocumentNameCard };
