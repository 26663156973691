import React from 'react';
import Icon from '@ant-design/icons';

const InterestedServiceFilled = () => (
  <svg
    width="48"
    height="47"
    viewBox="0 0 48 47"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M36.7254 6.45508C30.499 6.45508 25.4504 11.5036 25.4504 17.73C25.4504 23.9564 30.499 29.0049 36.7254 29.0049C42.9517 29.0049 48.0003 23.9564 48.0003 17.73C48.0003 11.5036 42.9517 6.45508 36.7254 6.45508ZM41.5575 18.334C41.5575 18.4447 41.4669 18.5353 41.3561 18.5353H37.5307V22.3608C37.5307 22.4715 37.4401 22.5621 37.3294 22.5621H36.1213C36.0106 22.5621 35.92 22.4715 35.92 22.3608V18.5353H32.0946C31.9839 18.5353 31.8932 18.4447 31.8932 18.334V17.126C31.8932 17.0152 31.9839 16.9246 32.0946 16.9246H35.92V13.0992C35.92 12.9885 36.0106 12.8979 36.1213 12.8979H37.3294C37.4401 12.8979 37.5307 12.9885 37.5307 13.0992V16.9246H41.3561C41.4669 16.9246 41.5575 17.0152 41.5575 17.126V18.334Z"
      fill="#4A2362"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M1.82791e-05 0H35.5558V5.00014C28.9575 5.52443 23.7664 11.0451 23.7664 17.7782C23.7664 24.5113 28.9575 30.032 35.5558 30.5562V33.8714H35.5559V46.6901L17.7783 33.8715L17.6843 33.8715L0 46.7839L1.82791e-05 33.8715V0Z"
      fill="#4A2362"
    />
  </svg>
);

const InterestedServiceFilledIcon = (props: any) => (
  <Icon component={InterestedServiceFilled} {...props} />
);

export { InterestedServiceFilledIcon };
