import React from 'react';
import { NavLink, useLocation } from 'react-router-dom';
import { Menu, Row, Col } from 'antd';
import { DASHBOARD_ROUTES, TEXT } from '@moxie/constants';

const OrganizationSettingsLayout: React.FC<{
  children: React.ReactNode;
}> = (props: { children: React.ReactNode }) => {
  const { children } = props;
  const location = useLocation();
  return (
    <section>
      <Row gutter={0}>
        <Col lg={5}>
          <div className="settings__menu">
            <div className="settings__menu_title">
              {TEXT.ORGANIZATION_SETTINGS}
            </div>
            <Menu mode="inline" selectedKeys={[location.pathname]}>
              <Menu.Item key={DASHBOARD_ROUTES.preferences.path}>
                <NavLink exact to={DASHBOARD_ROUTES.preferences.path}>
                  <h4 className="line-clamp-1">{TEXT.PREFERENCES}</h4>
                  <p className="line-clamp-2">{TEXT.PREFERENCES_DESC}</p>
                </NavLink>
              </Menu.Item>
              <Menu.Item key={DASHBOARD_ROUTES.roles.path}>
                <NavLink exact to={DASHBOARD_ROUTES.roles.path}>
                  <h4 className="line-clamp-1">{TEXT.ROLES}</h4>
                  <p className="line-clamp-2">{TEXT.ROLES_DESC}</p>
                </NavLink>
              </Menu.Item>
            </Menu>
          </div>
        </Col>
        <Col lg={19}>
          <div className="settings__content">{children}</div>
        </Col>
      </Row>
    </section>
  );
};

export { OrganizationSettingsLayout };
