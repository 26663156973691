import axios from './axios';
import { ILeadsForm, ILeadsFormResponse } from '@shared-components/models';
import { IResponse } from '@shared-components/models';
import { API_URL } from '@moxie/constants';

const getLeadsForms = async (params: string): Promise<ILeadsFormResponse> => {
  return axios.get(`${API_URL.LEADS_FORM}/${params}`);
};

const postLeadsForm = async (body: ILeadsForm): Promise<ILeadsFormResponse> => {
  return axios.post(API_URL.LEADS_FORM, body);
};

const postLeadFormFile = async (body: FormData): Promise<IResponse> => {
  return axios.post(`${API_URL.LEADS_FORM}/upload`, body);
};

const getOneLeadsForm = async (id: string): Promise<ILeadsFormResponse> => {
  return axios.get(`${API_URL.LEADS_FORM}/${id}`);
};

const updateLeadsForm = async (
  body: ILeadsForm | any
): Promise<ILeadsFormResponse> => {
  return axios.put(API_URL.LEADS_FORM, body);
};

const deleteLeadsForm = async (id: string): Promise<ILeadsFormResponse> => {
  return axios.delete(`${API_URL.LEADS_FORM}/${id}`);
};

const deleteMultiple = async (ids: string[]): Promise<ILeadsFormResponse> => {
  const data = { ids };
  return axios.delete(`${API_URL.LEADS_FORM}/delete/all`, { data });
};

export {
  getLeadsForms,
  postLeadsForm,
  getOneLeadsForm,
  updateLeadsForm,
  postLeadFormFile,
  deleteLeadsForm,
  deleteMultiple,
};
