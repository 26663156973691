/* eslint-disable react-hooks/rules-of-hooks */
import { useAppSelector as adminUseAppSelector } from '@admin/core';
import { applicationActions, useAppSelector } from '@lyra/core';
import { getSelectedStageChecklistData } from '@lyra/services.api';
import {
  APPLICATION_ACTORS,
  APPLICATION_STATUS,
  CONFIRM_MESSAGE,
  DISCONTINUE_BTN,
  LABEL,
  REVERT_BTN,
} from '@moxie/constants';
import { popConfirm } from '@moxie/shared';
import { errorHandler } from '@moxie/utils';
import { Col, Row, Skeleton } from 'antd';
import React, { useContext, useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { useHistory, useParams } from 'react-router-dom';
import { ApplicationContext } from '../applicationContext';
import {
  ApplicationDetailChecklist,
  ApplicationDetailProfile,
  ApplicationStageTimeline,
} from './';

const ApplicationDetail = ({ type }: { type: string }) => {
  const dispatch = useDispatch();
  const history = useHistory();
  const params = useParams<{ applicationId: string; slug2: string }>();
  const applicationId = params?.applicationId ? params.applicationId : params.slug2;

  const {
    applicationState,
    setSelectedChecklist,
    setCurrentStage,
  } = useContext(ApplicationContext);

  const { selectedStage } = applicationState;
  const [selectedChecklistData, setSelectedChecklistData] = useState([]);
  const [disableView, setDisableView] = useState<boolean>(false);
  const user = useAppSelector((state) => state.auth.user);
  const socket = useAppSelector((state) => state.socket.wss);
  const lead = useAppSelector((state) => state.lead.lead);
  let applicationDetail: any, currentStage: any, currentStageId: any;

  if (type === 'admin') {
    applicationDetail = adminUseAppSelector(
      (state) => state.application.singleData
    );
    currentStage = adminUseAppSelector(
      (state) => state?.application?.singleData?.currentStage
    );
    currentStageId = adminUseAppSelector(
      (state) => state?.application?.singleData?.currentStageId
    );
  } else {
    // eslint-disable-next-line react-hooks/rules-of-hooks
    applicationDetail = useAppSelector((state) => state.application.singleData);
    // eslint-disable-next-line react-hooks/rules-of-hooks
    currentStage = useAppSelector(
      (state) => state?.application?.singleData?.currentStage
    );
    // eslint-disable-next-line react-hooks/rules-of-hooks
    currentStageId = useAppSelector(
      (state) => state?.application?.singleData?.currentStageId
    );
  }

  useEffect(() => {
    if (applicationDetail === null) {
      history.push(`/application`);
    }
  }, [applicationDetail]);

  const handleStatus = (status: string) => {
    const update_type =
      status === APPLICATION_STATUS.DISCONTINUED ? 'discontinued' : 'revert';
    popConfirm(
      status === APPLICATION_STATUS.DISCONTINUED ? DISCONTINUE_BTN : REVERT_BTN,
      `${status === APPLICATION_STATUS.DISCONTINUED
        ? CONFIRM_MESSAGE.DISCONTINUE_APPLICATION
        : CONFIRM_MESSAGE.REVERT_APPLICATION
      }`,
      async () => {
        try {
          await dispatch(
            applicationActions.updateRequest(
              {
                id: applicationId,
                status: status,
              },
              user,
              socket,
              lead,
              update_type
            )
          );
        } catch (error) {
          errorHandler(error);
        }
      }
    );
  };

  const handleStageChange = async (stage: string) => {
    const update_type =
      stage === LABEL.PREVIOUS
        ? 'previous-stage'
        : stage === 'complete'
          ? 'complete-stage'
          : 'next-stage';

    let newStageId: any;

    if (applicationDetail?.status === APPLICATION_STATUS.COMPLETED) {
      newStageId = applicationDetail?.currentStageId;
    } else {
      newStageId =
        stage === LABEL.PREVIOUS
          ? applicationDetail?.previousStageId
          : applicationDetail?.nextStageId;
    }

    const newStage = applicationDetail?.workflowStages.find((item: any) => {
      if (item?.wfAppStageId === newStageId) {
        return item.stage;
      }
    });

    try {
      let data: { id: string; active_stage_id?: string; status: string };
      if (newStage) {
        data = {
          id: applicationId,
          active_stage_id: newStageId,
          status: APPLICATION_STATUS.IN_PROGRESS,
        };
      } else {
        data = {
          id: applicationId,
          status: APPLICATION_STATUS.COMPLETED,
        };
      }
      dispatch(
        applicationActions.updateRequest(
          data,
          user,
          socket,
          lead,
          update_type,
          newStage
        )
      );
      setDisableView(false);
    } catch (error) {
      errorHandler(error);
    }
  };
  useEffect(() => {
    (async () => {
      if (Object.keys(applicationDetail).length !== 0) {
        let res;
        if(!selectedStage){
          res = await getSelectedStageChecklistData(
            applicationId,
            applicationDetail?.currentStageId
          );
          setSelectedChecklistData(res.data.data);
        }else{
          res = await getSelectedStageChecklistData(
            applicationId,
            selectedStage?.wfAppStageId
          );
          setSelectedChecklistData(res.data.data);
        }
      }
    })()
  }, [applicationDetail,selectedStage]);

  useEffect(() => {
    (async () => {
      if (selectedStage?.wfAppStageId && applicationId) {
        const response = await getSelectedStageChecklistData(
          applicationId,
          selectedStage?.wfAppStageId
        );
        setSelectedChecklist(response.data.data);
      }
    })();
    if (selectedStage?.stage !== currentStage?.stage) {
      setDisableView(true);
    }
    dispatch(applicationActions.getOneRequest(applicationId));
    return () => {
      dispatch(applicationActions.clearApplication());
      setDisableView(false);
    };
  }, [dispatch, applicationId, selectedStage]);

  useEffect(() => {
    if (currentStage) {
      setCurrentStage(currentStage);
    }
  }, [currentStage]);

  if (Object.keys(applicationDetail).length === 0) {
    return (
      <div style={{ padding: '0 2rem' }}>
        <Skeleton active />
      </div>
    );
  }

  return (
    applicationDetail && (
      <Row className="margin-right-1">
        <Col span={5}>
          {currentStage !== null &&
            Object.keys(applicationDetail).length > 0 && (
              <ApplicationStageTimeline
                applicationDetail={applicationDetail}
                stages={applicationDetail.workflowStages}
                currentStage={currentStage}
              />
            )}
        </Col>
        <Col span={19} className="padding-right-2">
          {Object.keys(applicationDetail).length > 0 && (
            <ApplicationDetailProfile
              {...applicationDetail}
              user={user}
              type={type}
            />
          )}
          <ApplicationDetailChecklist
            selectedChecklistData={selectedChecklistData}
            disableView={disableView}
            currentStageId={selectedStage?.wfAppStageId || currentStageId}
            status={applicationDetail.status}
            previousStageId={applicationDetail.previousStageId}
            nextStageId={applicationDetail.nextStageId}
            stageChecklists={
              !disableView
                ? applicationDetail.checklistData
                : applicationState?.selectedChecklistData
            }
            handleStatus={handleStatus}
            handleStage={handleStageChange}
            isActor={
              currentStage?.actor === type ||
              currentStage?.actor === APPLICATION_ACTORS.BOTH
            }
            userType={type}
            leadId={applicationDetail.contact_id}
            currentStage={currentStage}
            stages={applicationDetail.workflowStages}
          />
        </Col>
      </Row>
    )
  );
};

export { ApplicationDetail };
