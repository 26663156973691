import React from 'react';
import { Contact, LeadContextProvider } from '@moxie/shared';

const ContactList: React.FC = () => {
  return (
  <LeadContextProvider>
   <Contact />
  </LeadContextProvider>
  )
};

export { ContactList };
