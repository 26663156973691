import React from 'react';
import { Avatar, Tooltip } from 'antd';
import { IUser } from '@shared-components/models';

const UserAvatarGroup: React.FC<{
  userList: IUser[];
}> = ({ userList }: { userList: IUser[] }) => {
  return (
    <Avatar.Group
      size="default"
      maxCount={3}
      maxStyle={{ backgroundColor: '#4a2362' }}
      style={{ paddingLeft: 12 }}
    >
      {userList?.map((user: any, idx: number) => {
        return (
          <Tooltip key={idx} title={`${user.firstName} ${user.lastName}`}>
            <Avatar className="cursor-pointer">
              {user.firstName.substring(0, 1).toUpperCase()}
              {user.lastName.substring(0, 1).toUpperCase()}
            </Avatar>
          </Tooltip>
        );
      })}
    </Avatar.Group>
  );
};

export { UserAvatarGroup };
