const alias = 'INSTITUTION';

export const GET_INSTITUTIONS_REQUEST = `GET_${alias}S_REQUEST`;
export const GET_INSTITUTIONS_SUCCESS = `GET_${alias}S_SUCCESS`;
export const GET_INSTITUTIONS_FAILURE = `GET_${alias}_FAILURE`;

export const GET_INSTITUTION_REQUEST = `GET_${alias}_REQUEST`;
export const GET_INSTITUTION_SUCCESS = `GET_${alias}_SUCCESS`;
export const GET_INSTITUTION_FAILURE = `GET_${alias}_FAILURE`;
export const CLEAR_INSTITUTION_FETCH = `CLEAR_${alias}_FETCH`;

export const ADD_INSTITUTION_REQUEST = `ADD_${alias}_REQUEST`;
export const ADD_INSTITUTION_SUCCESS = `ADD_${alias}_SUCCESS`;
export const ADD_INSTITUTION_FAILURE = `ADD_${alias}_FAILURE`;

export const UPDATE_INSTITUTION_REQUEST = `UPDATE_${alias}_REQUEST`;
export const UPDATE_INSTITUTION_SUCCESS = `UPDATE_${alias}_SUCCESS`;
export const UPDATE_INSTITUTION_FAILURE = `UPDATE_${alias}_FAILURE`;

export const DELETE_INSTITUTION_REQUEST = `DELETE_${alias}_REQUEST`;
export const DELETE_INSTITUTION_SUCCESS = `DELETE_${alias}_SUCCESS`;
export const DELETE_INSTITUTION_FAILURE = `DELETE_${alias}_FAILURE`;
