import React, { useState } from 'react';
import {
  COUNTRIES,
  COUNTRY_LABEL,
  COUNTRY_PLACEHOLDER,
  DEFAULT_COUNTRY_CODE,
  GUTTER,
  LABEL,
  TITLE,
} from '@moxie/constants';
import { FormItem } from '@shared-components/elements';
import { ILeadAddressDetailsProps } from '@shared-components/models';
import { Col, Divider, Input, Row, Select } from 'antd';
import Title from 'antd/lib/typography/Title';
const { Option } = Select;
const LeadFormAddressDetails: React.FC<ILeadAddressDetailsProps> = ({
  addressDetailsData,
}: ILeadAddressDetailsProps) => {
  // eslint-disable-next-line no-unused-vars
  const [countryCode, setCountryCode] = useState(DEFAULT_COUNTRY_CODE);
  const handleCountryChange = (input: string) => {
    COUNTRIES.find((item) => {
      if (item.name === input) {
        setCountryCode(item.dial_code);
      }
    });
  };
  const handleOptionFilter = (input: string, option: any): boolean =>
    option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0;
  return (
    <div className="created-leadForm-section ">
      <Title level={4}>{TITLE.ADDRESS_DETAILS}</Title>
      <Row gutter={GUTTER}>
        {addressDetailsData?.street && (
          <Col md={8} xs={24}>
            <FormItem
              name="street"
              label={LABEL.STREET}
              rules={[{ type: 'string', max: 50 }]}
            >
              <Input />
            </FormItem>
          </Col>
        )}
        {addressDetailsData?.city && (
          <Col md={8} xs={24}>
            <FormItem
              name="city"
              label={LABEL.CITY}
              rules={[{ type: 'string', max: 100 }]}
            >
              <Input />
            </FormItem>
          </Col>
        )}
        {addressDetailsData?.state && (
          <Col md={8} xs={24}>
            <FormItem
              name="state"
              label={LABEL.STATE}
              rules={[{ type: 'string', max: 100 }]}
            >
              <Input />
            </FormItem>
          </Col>
        )}
        {addressDetailsData?.postal_code && (
          <Col md={8} xs={24}>
            <FormItem
              name="postal_code"
              label={LABEL.ZIP_OR_POSTAL_CODE}
              rules={[{ type: 'string' }]}
            >
              <Input />
            </FormItem>
          </Col>
        )}
        {addressDetailsData?.country && (
          <Col md={8} xs={24}>
            <FormItem
              name="country"
              label={COUNTRY_LABEL}
              rules={[{ type: 'string', max: 50 }]}
            >
              <Select
                onChange={handleCountryChange}
                placeholder={COUNTRY_PLACEHOLDER}
                showSearch
                filterOption={handleOptionFilter}
              >
                <Option value="null" label="None">
                  None
                </Option>
                {COUNTRIES.map((country: any) => {
                  return (
                    <Option value={country.name} key={country.code}>
                      {country.name}
                    </Option>
                  );
                })}
              </Select>
            </FormItem>
          </Col>
        )}
      </Row>
      <Divider plain/>
    </div>
  );
};
export { LeadFormAddressDetails };
