const alias = 'NOTE';

export const GET_NOTES_REQUEST = `GET_${alias}S_REQUEST`;
export const GET_NOTES_SUCCESS = `GET_${alias}S_SUCCESS`;
export const GET_NOTES_FAILURE = `GET_${alias}S_FAILURE`;

export const GET_NOTE_REQUEST = `GET_${alias}_REQUEST`;
export const GET_NOTE_SUCCESS = `GET_${alias}_SUCCESS`;
export const GET_NOTE_FAILURE = `GET_${alias}_FAILURE`;

export const ADD_NOTE_REQUEST = `ADD_${alias}_REQUEST`;
export const ADD_NOTE_SUCCESS = `ADD_${alias}_SUCCESS`;
export const ADD_NOTE_FAILURE = `ADD_${alias}_FAILURE`;

export const DELETE_NOTE_REQUEST = `DELETE_${alias}_REQUEST`;
export const DELETE_NOTE_SUCCESS = `DELETE_${alias}_SUCCESS`;
export const DELETE_NOTE_FAILURE = `DELETE_${alias}_FAILURE`;
