import { useQuery } from "@tanstack/react-query";

import { IOfficeBranchCRM } from "@model/data.model";
import { errorNotificationHandler } from "@moxie/shared";
import { fetchPossibleUsersInContact, fetchUsersInContact } from "@crm/services.api";

export const useFetchAssignableUsers = (contactId?: string | undefined, branch?: IOfficeBranchCRM | undefined) => {

  const { data, refetch, isLoading } = useQuery({
    queryKey: ['contact-assignable-users', contactId],
    queryFn: () => fetchPossibleUsersInContact(contactId as string, branch),
    onError: () => errorNotificationHandler('Error fetching users'),
  });

  return {
    users: data?.data ?? [],
    refetchUsers: refetch,
    isLoading
  }
}

export const useFetchAssignees = () => {
  const { data, refetch, isLoading } = useQuery({
    queryKey: ['contact-assignable-users'],
    queryFn: () => fetchUsersInContact(),
    onError: () => errorNotificationHandler('Error fetching assignees'),
  });

  return {
    users: data ?? [],
    refetchUsers: refetch,
    isLoading
  }
}

