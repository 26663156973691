import React from 'react';
import { Tag, Tooltip } from 'antd';

const WorkflowTagGroup = ({ workflows }: any) => {
  const tooltipData = (
    <div>
      {workflows &&
        workflows.slice(3, workflows?.length)?.map((workflow: any) => {
          return (
            <Tag
              className="margin-bottom-1 initial_capital"
              key={workflow.id}
              color="default"
            >
              {workflow?.name || ''}
            </Tag>
          );
        })}
    </div>
  );
  return (
    <>
      {workflows &&
        workflows?.slice(0, 2).map((workflow: any) => {
          return (
            <Tag key={workflow.id} color="default" className="initial_capital">
              {workflow?.workflow?.workflowType?.name || ''}
            </Tag>
          );
        })}

      {workflows?.length > 2 && (
        <Tooltip placement="topLeft" color="white" title={tooltipData}>
          <Tag color="default">+{workflows.length - 2}</Tag>
        </Tooltip>
      )}
    </>
  );
};

export { WorkflowTagGroup };
