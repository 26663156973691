/* eslint-disable react-hooks/rules-of-hooks */
import { useAppSelector as adminAppSelector } from '@admin/core';
import { useAppSelector } from '@lyra/core';
import {
  CANCEL_BTN,
  DRAWER_WIDTH,
  SAVE_BTN,
  TEXT,
  TITLE,
} from '@moxie/constants';
import { Button, DrawerElem } from '@shared-components/elements';
import { ApplicationActivityLogDrawerComponent } from '@shared-components/models';
import { Avatar, Col, List, Row, Space, Tabs, Tag, Typography } from 'antd';
import moment from 'moment';
import React, { useState } from 'react';
import { ApplicationCommentsForm } from './application-comments-form';
import { ApplicationActivities } from './application-activities';
const { TabPane } = Tabs;

const ApplicationActivityLogsDrawer: React.FC<ApplicationActivityLogDrawerComponent> = ({
  isVisible,
  onClose,
  form,
  handleFormSubmit,
  handleSubmit,
  initialData,
  disabled,
  setDisabled,
  type,
}: ApplicationActivityLogDrawerComponent) => {
  const [selectedTab, setSelectedTab] = useState<string>('activities');
  let commentList: any = [];

  if (type === 'Admission') {
    commentList = adminAppSelector((store) => store.application.comments);
  } else {
    commentList = useAppSelector((store) => store.application.checklists);
  }
  return (
    <DrawerElem
      title={TITLE.ACTIVITY_LOG_DRAWER_TITLE}
      width={DRAWER_WIDTH}
      visible={isVisible}
      onClose={onClose}
      className="application-activity-drawer"
      footer={
        selectedTab === 'comments' && (
          <div className="padding-bottom-2 text-align-right">
            <Space direction="horizontal">
              <Button onClick={onClose} className="lead-margin-right">
                {CANCEL_BTN}
              </Button>
              <Button
                type="primary"
                onClick={handleFormSubmit}
                disabled={disabled}
              >
                {SAVE_BTN}
              </Button>
            </Space>
          </div>
        )
      }
    >
      <Tabs
        activeKey={selectedTab}
        onChange={(tabName: string) => {
          setSelectedTab(tabName);
        }}
      >
        <TabPane
          tab={<Typography.Text>Activities</Typography.Text>}
          key="activities"
          className="custom_tab"
        >
          <ApplicationActivities />
        </TabPane>
        <TabPane
          tab={<Typography.Text>Comments</Typography.Text>}
          key="comments"
          className="custom_tab"
        >
          <>
            <Typography.Title level={5}>
              {TEXT.PREVIOUS_COMMENTS}
            </Typography.Title>
            <div className="application_comment_list">
              <List
                itemLayout="horizontal"
                dataSource={commentList}
                renderItem={(item: any) => {
                  const createdUser = item?.createdBy
                    ? item?.createdBy
                    : item?.createdByAdmin;
                  return (
                    <List.Item>
                      <List.Item.Meta
                        avatar={
                          <>
                            <Avatar>
                              {createdUser?.first_name?.slice(0, 1)}
                              {createdUser?.last_name?.slice(0, 1)}
                            </Avatar>
                          </>
                        }
                        title={
                          <Row>
                            <Col span={12}>
                              <Space>
                                <Typography.Text strong>
                                  {createdUser?.first_name}
                                  &nbsp;{createdUser?.last_name}
                                </Typography.Text>

                                <Typography.Text type="secondary">
                                  {moment(item?.created_at).fromNow()}
                                </Typography.Text>
                              </Space>
                            </Col>
                            <Col span={12}>
                              <Tag className="float-right" color="purple">
                                {item?.applicationStage?.stage}
                              </Tag>
                            </Col>
                          </Row>
                        }
                        description={
                          <p
                            className="text-justify"
                            dangerouslySetInnerHTML={{
                              __html: `${item.comment}`,
                            }}
                          ></p>
                        }
                      />
                    </List.Item>
                  );
                }}
              />
            </div>
            <ApplicationCommentsForm
              form={form}
              onSubmit={handleSubmit}
              initialData={initialData}
              setDisabled={setDisabled}
            />
          </>
        </TabPane>
      </Tabs>
    </DrawerElem>
  );
};

export { ApplicationActivityLogsDrawer };
