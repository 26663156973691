import React from 'react';
import { Button, Card, Typography } from 'antd';
import { MailFilled } from '@ant-design/icons';
import { ApplyIMSLogoDark } from '@moxie/shared';
import { Link } from 'react-router-dom';
import {
  CHECKMAIL_DESCRIPTION,
  CHECK_MAIL_TITLE,
  URL_LOGIN,
} from '@moxie/constants';

const { Title, Paragraph, Text } = Typography;

const CheckMail: React.FC<{ portal: string }> = ({
  portal,
}: {
  portal: string;
}) => {
  return (
    <section className={`auth-layout auth-layout--login`}>
      <div className="auth-layout__card">
        <Card className="padding-3">
          <div className="padding-top-2 padding-bottom-2 text-align-center">
            <div className="card-logo-title-container">
              <ApplyIMSLogoDark />
              <Text className="card-logo-title">{portal}</Text>
            </div>
            <MailFilled className="auth-layout__mail-icon padding-top-2 padding-bottom-2" />
            <Title level={4}>{CHECK_MAIL_TITLE}</Title>
            <Paragraph>{CHECKMAIL_DESCRIPTION}</Paragraph>
            <div className="padding-top-2">
              <Link to={URL_LOGIN}>
                <Button type="primary">Back to login</Button>
              </Link>
            </div>
          </div>
        </Card>
      </div>
    </section>
  );
};

export { CheckMail };
