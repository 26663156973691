import {
  interestedServiceActions,
  leadActions,
  useAppSelector,
} from '@lyra/core';
import {
  ADD_INTERESTED_SERVICE,
  CANCEL_BTN,
  DRAWER_WIDTH,
  EDIT_INTERESTED_SERVICE,
  SAVE_BTN,
  STATUS,
  UPDATE_BTN,
} from '@moxie/constants';
import { DrawerElem } from '@shared-components/elements';
import { InterestedServiceForm } from '@shared-components/forms';
import { IAddEditProps, IInterestedService } from '@shared-components/models';
import { Button, Space, Spin } from 'antd';
import { RootState } from 'apps/lyra/src/core/store';
import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory, useParams } from 'react-router-dom';

const InterestedServiceAddEdit: React.FC<any> = ({
  isOpen,
  id,
  isEdit,
  name,
  disabled,
  setDisabled,
  onClose,
  initialData,
  form,
  isSubmitted,
  setSubmitted,
}: IAddEditProps<IInterestedService> | any) => {
  const dispatch = useDispatch();
  const { id: leadId } = useParams<{ id: string }>();

  const socket = useAppSelector((state) => state.socket.wss);
  const history = useHistory();

  const status = useSelector(
    (store: RootState) => store.interested_service.status
  );
  const leadData = useAppSelector((store) => store.lead.lead);
  const currentUser = useAppSelector((store) => store.auth.user);

  useEffect(() => {
    if (status === STATUS.SUCCEEDED) handleSubmitSuccess();
    else if (status === STATUS.FAILED) handleFailure();
    status === STATUS.IDLE && setSubmitted(false);
  }, [status]);

  const handleSubmitSuccess = async () => {
    onClose();
    setTimeout(() => form?.resetFields(), 500);
    dispatch(interestedServiceActions.setIdleInterestedServices());
    dispatch(leadActions.fetchLead(leadId));
    history.push(`/contact/detail/${id}/interested-services`);
    // if (leadData?.status === CONTACT_STATUS.LEAD) {
    //   const name = leadData?.first_name + ' ' + leadData?.last_name;
    //   successNotificationHandler(
    //     name + LEAD_CONVERSION_MESSAGE.LEAD_TO_PROSPECT
    //   );
    // }
  };

  const handleFailure = () => {
    dispatch(interestedServiceActions.setIdleInterestedServices());
  };

  const handleSubmitButtonClick = () => {
    form?.submit();
  };

  const handleSubmit = async (data: IInterestedService) => {
    setSubmitted && setSubmitted(true);
    setDisabled && setDisabled(true);
    if (data?.fees) data.fees = `${data.fees[0]}-${data.fees[1]}`;
    let subjectArea: any = data?.subject_area;
    if (subjectArea) {
      subjectArea = subjectArea?.join();
    }
    if (data?.subject_area) data.subject_area = subjectArea;
    data = { ...data, contact_id: id };
    if (data.id) {
      dispatch(
        interestedServiceActions.updateInterestedServiceRequest(
          data,
          currentUser,
          socket,
          leadData
        )
      );
    } else
      dispatch(
        interestedServiceActions.addInterestedServiceRequest(
          data,
          currentUser,
          socket,
          leadData
        )
      );
  };

  return (
    <DrawerElem
      title={initialData?.id ? EDIT_INTERESTED_SERVICE : ADD_INTERESTED_SERVICE}
      width={DRAWER_WIDTH}
      onClose={onClose}
      visible={isOpen}
      footer={
        <div className="padding-bottom-2 text-align-right">
          <Space direction="horizontal">
            <Button onClick={onClose} className="lead-margin-right">
              {CANCEL_BTN}
            </Button>
            <Button
              onClick={handleSubmitButtonClick}
              type="primary"
              disabled={disabled}
            >
              {isEdit ? UPDATE_BTN : SAVE_BTN}
            </Button>
          </Space>
        </div>
      }
    >
      <Spin spinning={isSubmitted}>
        <InterestedServiceForm
          form={form}
          name={name}
          onSubmit={handleSubmit}
          initialData={initialData}
          setDisabled={setDisabled}
        />
      </Spin>
    </DrawerElem>
  );
};

export { InterestedServiceAddEdit };
