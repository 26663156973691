import { useState } from 'react';
import React, { useEffect } from 'react';
import ReactQuill from 'react-quill';
import 'node_modules/react-quill/dist/quill.snow.css';

const Editor = (props: any) => {
  useEffect(() => {
    setEditorHtml(props.value);
  }, [props?.value]);
  const [editorHtml, setEditorHtml] = useState('');
  const handleChange = (html: string) => {
    setEditorHtml(html);
    props?.onChange(html);
  };

  return (
    <div>
      <ReactQuill
        id={props.name}
        onChange={handleChange}
        value={editorHtml || ''}
        modules={Editor.modules}
        formats={Editor.formats}
        placeholder={props.placeholder}
        readOnly={props.isView}
        className={props.isView ? 'hide-toolbar ql-toolbar border-top' : ''}
      />
    </div>
  );
};
Editor.modules = {
  toolbar: [
    ['bold', 'italic', 'underline', 'strike'],
    [{ list: 'ordered' }, { list: 'bullet' }],
  ],
  clipboard: {
    matchVisual: false,
  },
};
Editor.formats = ['bold', 'italic', 'underline', 'strike', 'list', 'bullet'];

export { Editor };
