import { TITLE } from '@moxie/constants';
import { FormItem } from '@shared-components/elements';
import { ILeadComplianceInformationProps } from '@shared-components/models';
import { Checkbox, Typography } from 'antd';
import React from 'react';
const { Paragraph, Title } = Typography;

const LeadFormComplianceInformation: React.FC<ILeadComplianceInformationProps> = ({
  privacyInformationData,
  setHasAcceptedTerms,
  hasAcceptedTerms,
}: ILeadComplianceInformationProps) => {
  return (
    <div className="created-leadForm-section ">
      <Title level={4}>{TITLE.PRIVACY_INFORMATION}</Title>
      <Paragraph>{privacyInformationData?.consent_information}</Paragraph>
      <FormItem name="privacy_information" valuePropName="checked">
        <Checkbox onChange={() => setHasAcceptedTerms(!hasAcceptedTerms)}>
          {privacyInformationData?.privacy_information}
        </Checkbox>
      </FormItem>
    </div>
  );
};
export { LeadFormComplianceInformation };
