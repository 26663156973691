import React from 'react';
import { Checkbox, Col, Form, Row } from 'antd';
import { GUTTER, LABEL, TITLE } from '@moxie/constants';
import { ILeadsFormComponent } from '@shared-components/models';
import { globalHandleCheck } from '@moxie/shared';
const AddressDetails: React.FC<ILeadsFormComponent> = ({
  setLeadForm,
  leadForm,
  setDisabled,
}: ILeadsFormComponent) => {
  const handleChange = (e: any) => {
    globalHandleCheck(
      e,
      leadForm,
      setLeadForm,
      'address_details_section',
      setDisabled
    );
  };

  const onCheckAllChange = (e: any) => {
    setDisabled(false);
    if (!e.target.checked) {
      setLeadForm({
        ...leadForm,
        address_details_section: {
          ...leadForm.address_details_section,
          street: false,
          city: false,
          state: false,
          postal_code: false,
          country: false,
        },
      });
      return;
    }
    setLeadForm({
      ...leadForm,
      address_details_section: {
        ...leadForm.address_details_section,
        street: true,
        city: true,
        state: true,
        postal_code: true,
        country: true,
      },
    });
  };

  return (
    <>
      <Row justify="space-between" className="custom__title__text">
        <Col>{TITLE.ADDRESS_DETAILS}</Col>
        <Col>
          <Checkbox
            checked={
              leadForm?.address_details_section?.street &&
              leadForm?.address_details_section?.city &&
              leadForm?.address_details_section?.state &&
              leadForm?.address_details_section?.postal_code &&
              leadForm?.address_details_section?.country
            }
            onChange={onCheckAllChange}
          />
        </Col>
      </Row>
      <Form.Item className="custom__formItem">
        <Row gutter={GUTTER}>
          <Col span={5} className="lead-form-box">
            <Checkbox
              name="street"
              checked={leadForm?.address_details_section?.street}
              onChange={handleChange}
            >
              {LABEL.STREET}
            </Checkbox>
          </Col>
          <Col span={5} className="lead-form-box">
            <Checkbox
              name="city"
              checked={leadForm?.address_details_section?.city}
              onChange={handleChange}
            >
              {LABEL.CITY}
            </Checkbox>
          </Col>
          <Col span={5} className="lead-form-box">
            <Checkbox
              name="state"
              checked={leadForm?.address_details_section?.state}
              onChange={handleChange}
            >
              {LABEL.STATE}
            </Checkbox>
          </Col>
          <Col span={5} className="lead-form-box">
            <Checkbox
              name="postal_code"
              checked={leadForm?.address_details_section?.postal_code}
              onChange={handleChange}
            >
              {LABEL.POSTAL_CODE}
            </Checkbox>
          </Col>
          <Col span={5} className="lead-form-box">
            <Checkbox
              name="country"
              checked={leadForm?.address_details_section?.country}
              onChange={handleChange}
            >
              {LABEL.COUNTRY}
            </Checkbox>
          </Col>
        </Row>
      </Form.Item>
    </>
  );
};

export default AddressDetails;
