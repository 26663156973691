/* eslint-disable no-unused-vars */
import {
  LinkOutlined,
  MailOutlined,
  MoreOutlined,
} from '@ant-design/icons';
import { TEXT } from '@moxie/constants';
import { Button } from '@shared-components/elements';
import { Col, Dropdown, Row, Tooltip, Typography } from 'antd';
import Avatar from 'antd/lib/avatar/avatar';
import React, { ReactElement, useState } from 'react';
import { Link } from 'react-router-dom';

export interface IUserNameCardProps {
  firstName: string;
  lastName: string;
  email?: string;
  actionBar?: boolean;
  index?: string;
  menu?: ReactElement | any | null;
  link?: string;
  id?: any;
  showEmailAsLink?: boolean;
  showMailIcon?: boolean;
  handleEdit?: () => void;
  size?: string | any;
  ellipsis?: boolean;
}

const { Text, Paragraph } = Typography;

export const UserNameCard: React.FC<IUserNameCardProps> = ({
  firstName,
  lastName,
  email,
  index,
  actionBar,
  link,
  menu,
  showEmailAsLink,
  showMailIcon,
  size,
  handleEdit,
  ellipsis = true,
}: IUserNameCardProps) => {
  const [isCopied, setIsCopied] = useState(false);
  const copyToClipboard = (text: string) => {
    navigator.clipboard.writeText(text);
    setIsCopied(true);
  };
  return (
    <Row className="lead-table__name user-name-card">
      <div className="align-items-center user-name-card__avatar initial_capital">
        {link ? (
          <Link to={link}>
            <Avatar size={size}>
              {firstName?.substring(0, 1)}
              {lastName?.substring(0, 1).toUpperCase()}
            </Avatar>
          </Link>
        ) : (
          <div onClick={handleEdit && handleEdit}>
            <Avatar size={size}>
              {firstName?.substring(0, 1)}
              {lastName?.substring(0, 1).toUpperCase()}
            </Avatar>
          </div>
        )}
      </div>
      <Col className="user-name-card__name">
        <div key={index}>
          <Col className="initial_capital">
            {link ? (
              <Link to={link}>
                <Text className="text-primary-color w-100" ellipsis={{ tooltip: true }}>{`${firstName} ${lastName}`}</Text>
              </Link>
            ) : (
              <>
                {showMailIcon ? (
                  <Tooltip title={TEXT.CLICK_SELECT_ASSIGNEE}>
                    <Col
                      onClick={handleEdit && handleEdit}
                      className="lead__hover-color"
                    >
                      <Text className='w-100' ellipsis={{ tooltip: true }}>{`${firstName} ${lastName}`}</Text>
                    </Col>
                  </Tooltip>
                ) : (
                  <Col onClick={handleEdit && handleEdit}>
                    <Text className='w-100' ellipsis={{ tooltip: true }}>{`${firstName} ${lastName}`}</Text>
                  </Col>
                )}
              </>
            )}
          </Col>
          <Col>
            <Row className="email_div">
              {showEmailAsLink === true ? (
                <>
                  {showMailIcon && (
                    <Col>
                      <a href={`mailto:${email}`}>
                        {' '}
                        <MailOutlined className="table-mail-icon" />{' '}
                      </a>
                    </Col>
                  )}
                  <Col lg={19}>
                    <small className="table-elements-email-container">
                      <a
                        className="text-primary-color"
                        href={`mailto:${email}`}
                      >
                        <Paragraph
                          ellipsis={
                            ellipsis ? { tooltip: `${email}` } : undefined
                          }
                          className="text-primary-color"
                        >
                          {email?.toLowerCase()}
                        </Paragraph>
                      </a>
                    </small>
                  </Col>
                </>
              ) : (
                <Col lg={18}>
                  <small>
                    <Paragraph
                      className="assigned-user-email text-primary-color"
                      ellipsis={ellipsis ? { tooltip: `${email}` } : undefined}
                    >
                      {email?.toLowerCase()}
                    </Paragraph>
                  </small>
                </Col>
              )}
              {showEmailAsLink && (
                <Col className="hide">
                  <Tooltip
                    className=" margin-left-1 "
                    placement="right"
                    color={isCopied ? '#8EAF74' : '#9B9D9A '}
                    title={
                      isCopied ? `${TEXT.COPIED_SUCCESS}` : `${TEXT.COPY_EMAIL}`
                    }
                  >
                    <LinkOutlined
                      className="copy_email_icon"
                      onClick={() => copyToClipboard(`${email}`)}
                    />
                  </Tooltip>
                </Col>
              )}
            </Row>
          </Col>
        </div>
      </Col>
      {actionBar && menu !== null && (
        <Col className="user-name-card__action">
          {menu && (
            <Dropdown overlay={menu}>
              <Button
                type="text"
                className="table-cell-action-btn"
                icon={<MoreOutlined />}
              />
            </Dropdown>
          )}
        </Col>
      )}
    </Row>
  );
};
