import { getContactEnquiries } from "@crm/services.api";
import { useQuery } from "@tanstack/react-query";

export const useFetchContactEnquiry = (contactId: string, params?: Record<string, unknown>) => {
  const { data, isLoading } = useQuery({
    queryKey: ['contact-enquiry', contactId, params],
    queryFn: () => getContactEnquiries(contactId, params),
    enabled: !!contactId,
  });

  return { contactEnquiry: data?.data?.data, isLoading, meta: data?.data?.meta };
}
