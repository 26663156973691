import {
  CANCEL_BTN,
  DRAWER_WIDTH,
  PROFILE_EDIT_TITLE,
  SAVE_BTN,
  UPDATE_BTN,
  USER_ADD_PLACEMENT,
  USER_ADD_TITLE,
  USER_EDIT_TITLE,
} from '@moxie/constants';
import { Button, DrawerElem } from '@moxie/shared';
import { UserPersonalInfoForm } from '@shared-components/forms';
import { UserDrawerProps } from '@shared-components/models';
import { Space } from 'antd';
import React from 'react';

const UserDrawer: React.FC<UserDrawerProps> = ({
  formData,
  edit,
  form,
  visible,
  isProfile,
  disabled,
  setDisabled,
  onClose,
  saveData,
  triggerRefresh,
}: UserDrawerProps) => {
  return (
    <DrawerElem
      title={
        isProfile ? PROFILE_EDIT_TITLE : edit ? USER_EDIT_TITLE : USER_ADD_TITLE
      }
      width={DRAWER_WIDTH}
      visible={visible}
      onClose={onClose}
      placement={USER_ADD_PLACEMENT}
      footer={
        <div className="padding-bottom-2 text-align-right">
          <Space direction="horizontal">
            <Button onClick={onClose} className="lead-margin-right">
              {CANCEL_BTN}
            </Button>

            <Button type="primary" onClick={saveData} disabled={disabled}>
              {edit ? UPDATE_BTN : SAVE_BTN}
            </Button>
          </Space>
        </div>
      }
    >
      <UserPersonalInfoForm
        form={form}
        isProfile={isProfile}
        isEdit={edit}
        onClose={onClose}
        disabled={disabled}
        setDisabled={setDisabled}
        formData={formData && formData}
        triggerRefresh={triggerRefresh}
      />
    </DrawerElem>
  );
};

UserDrawer.defaultProps = {
  isProfile: false,
};
export { UserDrawer };
