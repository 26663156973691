import React from 'react';
import { Table } from '@moxie/shared';
import { ColumnsType } from 'antd/lib/table';
import { LABEL, PHONE_LABEL, TEXT } from '@moxie/constants';
import { IAddress, IInstitutionBranch } from '@shared-components/models';
import { instituteServices } from '@lyra/services.api';

const InstitutionBranchTable = ({ id }: { id: number }) => {
  const getBranchData = async (params: string) => {
    return instituteServices.apiGetInstitutionBranches(id, params);
  };

  const columns: ColumnsType<IInstitutionBranch> = [
    {
      title: LABEL.NAME,
      dataIndex: 'name',
      sorter: () => 0,
    },
    {
      title: TEXT.EMAIL,
      dataIndex: 'email',
      sorter: () => 0,
    },
    {
      title: PHONE_LABEL,
      dataIndex: 'phone_number',
      render(data: string) {
        return <span>{data ? data : '-'}</span>;
      },
    },
    {
      title: TEXT.ADDRESS,
      dataIndex: 'address',
      sorter: () => 0,
      render(data: IAddress) {
        return <span>{data?.city}</span>;
      },
    },
  ];

  return (
    <div className="margin-top-1">
      <Table action={getBranchData} columns={columns} />
    </div>
  );
};

export { InstitutionBranchTable };
