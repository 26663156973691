import { IAction, IPayload } from '@shared-components/models';
import * as actionTypes from './office-branch.constant';
import { IOfficeBranchCRM,IUpdateOfficeCrm } from '@model/branch';

export const addRequest = (
  payload: IOfficeBranchCRM,
  triggerRefresh: () => void
): IAction<IOfficeBranchCRM> => ({
  type: actionTypes.ADD_BRANCH_REQUEST,
  payload,
  triggerRefresh,
});

export const addSuccess = (payload: IOfficeBranchCRM): IAction<IOfficeBranchCRM> => ({
  type: actionTypes.ADD_BRANCH_SUCCESS,
  payload,
});

export const addFailure = (payload: IPayload<IOfficeBranchCRM>): IAction => ({
  type: actionTypes.ADD_BRANCH_FAILURE,
  payload,
});

export const updateRequest = (
  payload: IUpdateOfficeCrm, 
  triggerRefresh: () => void
): IAction<IUpdateOfficeCrm> => ({
  type: actionTypes.UPDATE_BRANCH_REQUEST,
  payload,
  triggerRefresh
});

export const updateSuccess = (
  payload: IOfficeBranchCRM
): IAction<IOfficeBranchCRM> => ({
  type: actionTypes.UPDATE_BRANCH_SUCCESS,
  payload,
});

export const updateFailure = (payload: IPayload<IOfficeBranchCRM>): IAction => ({
  type: actionTypes.UPDATE_BRANCH_FAILURE,
  payload,
});

export const getAllRequest = (): IAction<IOfficeBranchCRM> => ({
  type: actionTypes.GET_BRANCHES_REQUEST,
});

export const getAllSuccess = (
  payload: IOfficeBranchCRM[]
): IAction<IOfficeBranchCRM[]> => ({
  type: actionTypes.GET_BRANCHES_SUCCESS,
  payload,
});

export const getAllFailure = (payload: IPayload<IOfficeBranchCRM>): IAction => ({
  type: actionTypes.GET_BRANCHES_FAILURE,
  payload,
});

export const getOneRequest = (id: string): IAction<string> => ({
  type: actionTypes.GET_BRANCH_REQUEST,
  payload: id,
});

export const getOneSuccess = (
  payload: IOfficeBranchCRM
): IAction<IOfficeBranchCRM> => ({
  type: actionTypes.GET_BRANCH_SUCCESS,
  payload,
});

export const getOneFailure = (payload: IPayload<IOfficeBranchCRM>): IAction => ({
  type: actionTypes.GET_BRANCH_FAILURE,
  payload,
});

export const deleteOfficeBranchRequest = (id: string, triggerRefresh: () => void): IAction<string> => ({
  type: actionTypes.DELETE_BRANCH_REQUEST,
  payload: id,
  triggerRefresh,
});

export const deleteOfficeBranchSuccess = (payload: IPayload<IOfficeBranchCRM>): IAction => ({
  type: actionTypes.DELETE_BRANCH_SUCCESS,
  payload,
})

export const deleteOfficeBranchFailure = (
  payload: IPayload<IOfficeBranchCRM>
): IAction => ({
  type: actionTypes.DELETE_BRANCH_FAILURE,
  payload,
});

export const clearOfficeBranch = (): IAction<string> => ({
  type: actionTypes.CLEAR_BRANCH_FETCH,
});
