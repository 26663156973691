import axios from './axios';
import { IResponse } from '@shared-components/models';
import { API_URL } from '@moxie/constants';

const getEducationLevels = async (): Promise<IResponse> => {
  return axios.get(`${API_URL.EDUCATION_BACKGROUND}/levels`);
};

const getAllProductEducations = async (id: string): Promise<IResponse> => {
  return axios.get(`product-education/all/${id}`);
};

const getOneProductEducation = async (id: string): Promise<IResponse> => {
  return axios.get(`product-education/${id}`);
};

const postProductEducation = async (data: any): Promise<IResponse> => {
  return axios.post('product-education', data);
};

const deleteProductEducation = async (id: string): Promise<IResponse> => {
  return axios.delete(`product-education/${id}`);
};

const updateProductEducation = async (
  id: string,
  data: any
): Promise<IResponse> => {
  return axios.put(`product-education/${id}`, data);
};

const getAllProductLanguageTestScore = async (
  id: string
): Promise<IResponse> => {
  return axios.get(`product-language-test-score/all/${id}`);
};

const getOneProductLanguageTestScore = async (
  id: string
): Promise<IResponse> => {
  return axios.get(`product-language-test-score/${id}`);
};

const postProductLanguageTestScore = async (data: any): Promise<IResponse> => {
  return axios.post('product-language-test-score', data);
};

const deleteProductLanguage = async (id: string): Promise<IResponse> => {
  return axios.delete(`product-language-test-score/${id}`);
};
const updateProductLanguage = async (
  id: string,
  data: any
): Promise<IResponse> => {
  return axios.put(`product-language-test-score/${id}`, data);
};

export {
  getEducationLevels,
  postProductEducation,
  postProductLanguageTestScore,
  getAllProductLanguageTestScore,
  getAllProductEducations,
  deleteProductEducation,
  updateProductEducation,
  deleteProductLanguage,
  updateProductLanguage,
  getOneProductLanguageTestScore,
  getOneProductEducation,
};
