import React, { useEffect, useState } from 'react';
import {
  CONFIRM_MESSAGE,
  DELETE_BTN,
  PAGE_HEADER_MENU_TASKS,
  TASKS_TITLE,
  TEXT,
} from '@moxie/constants';
import { useDispatch } from 'react-redux';
import { tasksActions, useAppSelector } from '@lyra/core';
import { ITasks } from '@shared-components/models';
import { getAllTasks, getOneTask } from '@lyra/services.api';
import { TasksTable } from './tasks-table';
import { errorHandler } from '@moxie/utils';
import { useParams } from 'react-router-dom';
import {
  errorNotificationHandler,
  TaskFormDrawer,
  PageHeaderMenu,
  PageHeaderTitle,
  popConfirm,
} from '@moxie/shared';
import { Form } from 'antd';

const TasksList: React.FC = () => {
  const [form] = Form.useForm();
  const [initialData, setInitialData] = useState<ITasks>({});
  const [isEditMode, setIsEditMode] = useState(false);
  const [isViewMode, setIsViewMode] = useState(false);
  const [isTaskOpen, setIsTaskOpen] = useState(false);
  const [refreshTable, setRefreshTable] = useState(true);
  const [documents, setDocuments] = useState<any>([]);
  const { name } = useParams<{ name: string }>();
  const [filter, setFilter] = useState({ selectBy: name });

  const { loading } = useAppSelector((store) => store.tasks);

  useEffect(() => {
    if (filter.selectBy !== name) setFilter({ selectBy: name });
  }, [name]);

  const triggerRefresh = () => setRefreshTable((prev) => !prev);
  const dispatch = useDispatch();
  const [disabled, setDisabled] = useState(true);

  const openTasksDrawer = async (id?: string, isView?: boolean) => {
    if (id) {
      if (isView) {
        setIsViewMode(true);
        setIsEditMode(false);
      } else {
        setIsViewMode(false);
        setIsEditMode(true);
      }
      const response = await getOneTask(id ? id : '');
      setInitialData(response.data.data);
    } else {
      setInitialData({});
    }
    setIsTaskOpen(true);
  };

  const onClose = () => {
    setDocuments([]);
    triggerRefresh();
    form.resetFields();
    setIsTaskOpen(false);
    setIsEditMode(false);
    setIsViewMode(false);
  };

  const handleFormSubmit = () => {
    form.submit();
  };

  const getTasksData = async (str: string) => {
    return getAllTasks(str);
  };

  const handleDelete = async (id: string) => {
    popConfirm(DELETE_BTN, `${CONFIRM_MESSAGE.DELETE_TASK}`, async () => {
      try {
        dispatch(tasksActions.deleteTasksRequest(id, triggerRefresh));
        triggerRefresh();
      } catch (err) {
        errorHandler(err);
      }
    });
  };

  const handleSubmit = async (data: ITasks) => {
    if (data.related_to !== 'contact') {
      data.contact_id = null;
    }
    if (data.assignee_id === undefined) {
      data.assignee_id = null;
    }
    const formData = new FormData();

    let totalDocsSize = documents?.reduce((acc: number, curr: any) => {
      return acc + curr.size;
    }, 0);
    totalDocsSize = totalDocsSize / 1024 / 1024;

    if (totalDocsSize > 50) {
      errorNotificationHandler(TEXT.MAX_TASK_DOCUMENTS_SIZE);
      return;
    }

    if (documents?.length > 20) {
      errorNotificationHandler(TEXT.MAX_TASK_DOCUMENTS_COUNT);
      return;
    }

    documents?.forEach((document: any) => {
      formData.append('files', document);
      formData.append(
        'fileName',
        document.fileName + document.extension || document.name
      );
    });

    if (initialData?.id) {
      const postData = { ...data, id: initialData?.id };
      formData.append('formData', JSON.stringify(postData));
      dispatch(tasksActions.editTasksRequest(formData, triggerRefresh));
      onClose();
    } else {
      formData.append('formData', JSON.stringify(data));
      dispatch(tasksActions.addTasksRequest(formData, triggerRefresh));
    }
    setDisabled(true);
    triggerRefresh();
  };

  useEffect(() => {
    if (loading === false) {
      onClose();
    }
  }, [loading]);

  return (
    <section className="full-height">
      <PageHeaderTitle title={TASKS_TITLE} />
      <PageHeaderMenu
        menu={PAGE_HEADER_MENU_TASKS}
        defaultActiveKey={name}
        data-testid="task_headers"
      />
      <TaskFormDrawer
        form={form}
        initialData={initialData}
        handleSubmit={handleSubmit}
        handleFormSubmit={handleFormSubmit}
        isOpen={isTaskOpen}
        documents={documents}
        setDocuments={setDocuments}
        isEdit={isEditMode}
        setIsEdit={setIsEditMode}
        setIsView={setIsViewMode}
        isView={isViewMode}
        onClose={onClose}
        disabled={disabled}
        setDisabled={setDisabled}
      />

      <TasksTable
        filterOptions={true}
        action={getTasksData}
        showDrawer={openTasksDrawer}
        actionBar={true}
        deleteData={handleDelete}
        refresh={refreshTable}
        triggerRefresh={triggerRefresh}
        filter={filter}
      />
    </section>
  );
};
export { TasksList };
