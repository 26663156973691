import React, { useContext } from 'react';
import { Editor } from '@tiptap/react';
import { MentionEditor } from '../editor';
import { Divider } from 'antd';
import TaskForm from './task-form';
import Button from 'antd/es/button';
import Icon from '@ant-design/icons';
import { ReactComponent as AiIcon } from '@crm/src/assets/icons/ai-star-outlined.svg';
import RetweetOutlined from '@ant-design/icons/RetweetOutlined';
import aiSystemPrompt from './system-prompt';
import { NoteMode, Task } from '@model/crm/note.model';
import summarizeNoteInput from '../editor/summarize-note';
import { NoteFormContext } from './note-form-provider';
import { FormInstance } from 'antd/es/form';


interface Props {
  editor: Editor | null;
  actions?: JSX.Element;
  task?: Task;
  form: FormInstance;
}

const NoteForm: React.FC<Props> = ({ editor, actions, task, form }) => {

  const { noteMode, setNoteMode } = useContext(NoteFormContext);
  const summarize = async (prompt: string, type: NoteMode) => {
    setNoteMode(type);
    await summarizeNoteInput(editor, prompt);
    setNoteMode(undefined);
  }
  return (
    <>
      <MentionEditor editor={editor} />
      <Divider style={{ 'margin': '1.5rem 0' }} />
      <TaskForm form={form} task={task as any} />
      <Divider style={{ 'margin': '1.5rem 0' }} />
      <div className="notes-btn-group">
        <Button disabled={noteMode !== undefined} htmlType="button" icon={<RetweetOutlined />} onClick={() => summarize(aiSystemPrompt.actionPlans, 'create-action-plan')} loading={noteMode === 'create-action-plan'}>
          Create Action Plan
          <Icon>
            <AiIcon />
          </Icon>
        </Button>
      </div>

      {actions && <div className="notes-btn-group">
        {actions}
      </div>}
    </>
  )
}
export default NoteForm;
