export const user = {
  ERROR_USER: 'ERROR_USER',
  TOGGLE_ERROR_STATUS: 'TOGGLE_ERROR_STATUS',
  RESET_ERROR_STATUS: 'RESET_ERROR_STATUS',
  FETCH_USERS: 'FETCH_USERS',
  FETCH_USER: 'FETCH_USER',
  FETCH_USER_LOADING: 'FETCH_USER_LOADING',
  FETCH_USER_ERROR: 'FETCH_USER_ERROR',
  FETCH_USER_CLEAR: 'FETCH_USER_CLEAR',
};
