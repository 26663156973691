import { Editor, EditorContent } from '@tiptap/react';
import React from 'react';
import ActionMenu from './action-menu';

interface Props {
  editor: Editor | null
}
const MentionEditor: React.FC<Props> = ({ editor }) => {

  if (!editor) {
    return null;
  }
  return (
    <>
      <ActionMenu editor={editor} />
      <EditorContent editor={editor} />
    </>
  )
}

export default MentionEditor;
