import React from 'react';
import { Typography } from 'antd';
import Collapse from 'antd/es/collapse';
import ScrollableTasks from './scrollable-tasks';
import moment from 'moment';

const TaskWidget = () => {

  const taskTabs = {
    'Overdue': <ScrollableTasks
      scrollableDivId="overdue-tasks"
      endDate={moment().subtract('day', 1).format('YYYY-MM-DD')}
      status="pending"
    />,
    'Today': <ScrollableTasks
      scrollableDivId="today-tasks"
      startDate={moment().format('YYYY-MM-DD')}
      endDate={moment().format('YYYY-MM-DD')}
      status="pending"
    />,
    'This Week': <ScrollableTasks
      scrollableDivId="this-week-tasks"
      startDate={moment().startOf('week').format('YYYY-MM-DD')}
      endDate={moment().endOf('week').format('YYYY-MM-DD')}
      status="pending"
    />,
    'Upcoming': <ScrollableTasks
      scrollableDivId="today-tasks"
      startDate={moment().add('week', 1).startOf('week').format('YYYY-MM-DD')}
      status="pending"
    />,
    'Completed': <ScrollableTasks
      scrollableDivId="completed-tasks"
      status="done"
    />,
  }
  return (
    <div className="dashboard-widget">
      <Typography.Title level={4}>
        Tasks
      </Typography.Title>
      <Collapse className="task-collapsable">
        {Object.entries(taskTabs).map(([label, comp]) => (
          <Collapse.Panel key={label} header={label}>
            {comp}
          </Collapse.Panel>
        ))}
      </Collapse>

    </div>
  )
}

export default TaskWidget;
