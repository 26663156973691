import React from 'react';
import { ApplyIMSLogoLight } from '@shared-components/elements';
import BottomText from './lib/bottom-text';

export interface IRegistrationLeftPage {
  icon?: React.ReactElement;
  title?: string;
  body?: string;
}

const RegistrationLeftPane: React.FC<IRegistrationLeftPage> = ({
  icon,
  title,
  body,
}: IRegistrationLeftPage) => {
  return (
    <div className="registration-left-pane padding-3">
      <div className="register-apply-ims-logo">
        <ApplyIMSLogoLight />
      </div>
      <BottomText icon={icon} title={title} body={body} />
    </div>
  );
};

RegistrationLeftPane.defaultProps = {
  title: '',
  body: '',
};

export { RegistrationLeftPane };
