import React, { useState } from 'react';
import { Dropdown, Menu, Modal } from 'antd';
import { useDispatch } from 'react-redux';
import {
  DeleteOutlined,
  DownloadOutlined,
  EditOutlined,
  MoreOutlined,
  FolderViewOutlined,
} from '@ant-design/icons';
import { leadActions } from '@lyra/core';
import { errorHandler } from '@moxie/utils';
import { Button, popConfirm } from '@shared-components/elements';
import { deleteDocument, getOneDocument } from '@lyra/services.api';
import {
  CONFIRM_MESSAGE,
  CONFIRM_TITLE,
  DELETE_BTN,
  DOWNLOAD,
  PREVIEW,
  RENAME,
} from '@moxie/constants';
import { IDocumentMenuCard } from '@shared-components/models';
import { successNotificationHandler } from '@moxie/shared';
import { useAppSelector } from '@lyra/core';
import DocViewer, {
  PDFRenderer,
  JPGRenderer,
  PNGRenderer,
  MSDocRenderer,
} from '@cyntler/react-doc-viewer';
import { getAdminOneDocument } from '@admin/services.api';

const DocumentMenuCard: React.FC<IDocumentMenuCard> = ({
  docId,
  setIsEdit,
  triggerRefresh,
  handleDocumentCountDecrease,
  archived,
  isAdmin,
  applicationData,
  leadData,
  extension,
}: IDocumentMenuCard) => {
  const dispatch = useDispatch();
  const [signedUrl, setSignedUrl] = useState('');
  const [docPreview, setDocPreview] = useState(false);
  const { userId, socket } = useAppSelector((state) => ({
    userId: state.auth.user?.id,
    socket: state.socket.wss,
  }));

  const getDocument = async () => {
    try {
      popConfirm(
        CONFIRM_TITLE.DOWNLOAD_DOCUMENT,
        `${CONFIRM_MESSAGE.DOWNLOAD_DOCUMENT}`,
        async () => {
          dispatch(leadActions.downloading(true));
          let data;
          if (isAdmin) {
            data = await getAdminOneDocument(docId);
          } else {
            data = await getOneDocument(docId);
          }
          window.open(data?.downloadURL);
          dispatch(leadActions.downloading(false));
        },
        false,
        async () => {
          dispatch(leadActions.downloading(false));
        }
      );
    } catch (err) {
      dispatch(leadActions.downloading(false));
      errorHandler(err);
    }
  };

  const renameDocument = async () => {
    setIsEdit?.(true);
  };

  const removeDocument = async () => {
    popConfirm(
      CONFIRM_TITLE.DELETE_DOCUMENT,
      `${CONFIRM_MESSAGE.DELETE_DOCUMENT}`,
      async () => {
        try {
          const response = await deleteDocument(docId);
          handleDocumentCountDecrease && handleDocumentCountDecrease();
          successNotificationHandler(response.data.message);
          if (applicationData)
            socket?.emit('create-application-notification-to-server', {
              user_id: userId,
              receivers_many: applicationData.agent_clients?.map(
                (item: any) => {
                  return item.id;
                }
              ),
              type: 'application-updated',
              update_type: 'document-deleted',
              parent_id: applicationData.client.id,
              application_identifier: applicationData.app_identifier,
              application_id: applicationData.id,
            });
          if (leadData)
            socket?.emit('document-notification', {
              user_id: userId,
              parent_id: leadData.id,
              receiver_id: leadData.assignee_id,
              receivers_many: leadData?.followers.map((item: any) => item.id),
              type: 'document-deleted',
            });
        } catch (err) {
          errorHandler(err);
        }
        triggerRefresh?.();
      }
    );
  };

  const previewDocument = async () => {
    dispatch(leadActions.downloading(true));
    let data;
    if (isAdmin) {
      data = await getAdminOneDocument(docId);
    } else {
      data = await getOneDocument(docId);
    }
    setSignedUrl(data?.downloadURL);
    setDocPreview(true);
    dispatch(leadActions.downloading(false));
  };

  const menu = (
    <Menu>
      <Menu.Item key="1" icon={<DownloadOutlined />} onClick={getDocument}>
        {DOWNLOAD}
      </Menu.Item>
      <Menu.Item
        key="4"
        icon={<FolderViewOutlined />}
        onClick={previewDocument}
      >
        {PREVIEW}
      </Menu.Item>
      <Modal
        width="100%"
        visible={docPreview}
        onCancel={() => {
          triggerRefresh?.();
          setDocPreview(false);
        }}
        onOk={() => {
          triggerRefresh?.();
          setDocPreview(false);
        }}
        cancelButtonProps={{ style: { display: 'none' } }}
      >
        <DocViewer
          className="doc-preview"
          config={{
            header: {
              disableHeader: true,
              retainURLParams: true,
            },
          }}
          documents={[
            {
              uri: signedUrl,
              fileType: extension,
            },
          ]}
          pluginRenderers={[
            PDFRenderer,
            PNGRenderer,
            JPGRenderer,
            MSDocRenderer,
          ]}
        />
      </Modal>

      {!archived && isAdmin !== true && (
        <>
          <Menu.Item key="2" icon={<EditOutlined />} onClick={renameDocument}>
            {RENAME}
          </Menu.Item>

          <Menu.Item key="3" icon={<DeleteOutlined />} onClick={removeDocument}>
            {DELETE_BTN}
          </Menu.Item>
        </>
      )}
    </Menu>
  );

  return (
    <Dropdown overlay={menu}>
      <Button
        type="text"
        className="table-cell-action-btn"
        icon={<MoreOutlined />}
      />
    </Dropdown>
  );
};

export { DocumentMenuCard };
