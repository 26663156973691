const alias = 'PRODUCT';
const alias_fee = 'PRODUCT_FEE';
const alias_education = 'PRODUCT_EDUCATION';
const alias_language = 'PRODUCT_LANGUAGE';

export const FETCH_PRODUCTS_REQUEST = `FETCH_${alias}S_REQUEST`;
export const FETCH_PRODUCTS_SUCCESS = `FETCH_${alias}S_SUCCESS`;
export const FETCH_PRODUCTS_CLEAR = `FETCH_${alias}S_CLEAR`;
export const FETCH_PRODUCTS_FAILURE = `FETCH_${alias}S_FAILURE`;

export const FETCH_PRODUCT_REQUEST = `FETCH_${alias}_REQUEST`;
export const FETCH_PRODUCT_SUCCESS = `FETCH_${alias}_SUCCESS`;
export const FETCH_PRODUCT_FAILURE = `FETCH_${alias}_FAILURE`;
export const FETCH_PRODUCT_CLEAR = `FETCH_${alias}_CLEAR`;

export const ADD_PRODUCT_REQUEST = `ADD_${alias}_REQUEST`;
export const ADD_PRODUCT_SUCCESS = `ADD_${alias}_SUCCESS`;
export const ADD_PRODUCT_FAILURE = `ADD_${alias}_FAILURE`;

export const UPDATE_PRODUCT_REQUEST = `UPDATE_${alias}_REQUEST`;
export const UPDATE_PRODUCT_SUCCESS = `UPDATE_${alias}_SUCCESS`;
export const UPDATE_PRODUCT_FAILURE = `UPDATE_${alias}_FAILURE`;

export const DELETE_PRODUCT_REQUEST = `DELETE_${alias}_REQUEST`;
export const DELETE_PRODUCT_SUCCESS = `DELETE_${alias}_SUCCESS`;
export const DELETE_PRODUCT_FAILURE = `DELETE_${alias}_FAILURE`;

export const GET_PRODUCT_FEES_REQUEST = `GET_${alias_fee}S_REQUEST`;
export const GET_PRODUCT_FEES_SUCCESS = `GET_${alias_fee}S_SUCCESS`;
export const GET_PRODUCT_FEES_FAILURE = `GET_${alias_fee}_FAILURE`;

export const GET_PRODUCT_FEE_REQUEST = `GET_${alias_fee}_REQUEST`;
export const GET_PRODUCT_FEE_SUCCESS = `GET_${alias_fee}_SUCCESS`;
export const GET_PRODUCT_FEE_FAILURE = `GET_${alias_fee}_FAILURE`;
export const CLEAR_PRODUCT_FEE_FETCH = `CLEAR_${alias_fee}_FETCH`;

export const ADD_PRODUCT_FEE_REQUEST = `ADD_${alias_fee}_REQUEST`;
export const ADD_PRODUCT_FEE_SUCCESS = `ADD_${alias_fee}_SUCCESS`;
export const ADD_PRODUCT_FEE_FAILURE = `ADD_${alias_fee}_FAILURE`;

export const UPDATE_PRODUCT_FEE_REQUEST = `UPDATE_${alias_fee}_REQUEST`;
export const UPDATE_PRODUCT_FEE_SUCCESS = `UPDATE_${alias_fee}_SUCCESS`;
export const UPDATE_PRODUCT_FEE_FAILURE = `UPDATE_${alias_fee}_FAILURE`;

export const DELETE_PRODUCT_FEE_REQUEST = `DELETE_${alias_fee}_REQUEST`;
export const DELETE_PRODUCT_FEE_SUCCESS = `DELETE_${alias_fee}_SUCCESS`;
export const DELETE_PRODUCT_FEE_FAILURE = `DELETE_${alias_fee}_FAILURE`;

export const GET_PRODUCT_EDUCATIONS_REQUEST = `GET_${alias_education}S_REQUEST`;
export const GET_PRODUCT_EDUCATIONS_SUCCESS = `GET_${alias_education}S_SUCCESS`;
export const GET_PRODUCT_EDUCATIONS_FAILURE = `GET_${alias_education}_FAILURE`;

export const GET_PRODUCT_EDUCATION_REQUEST = `GET_${alias_education}_REQUEST`;
export const GET_PRODUCT_EDUCATION_SUCCESS = `GET_${alias_education}_SUCCESS`;
export const GET_PRODUCT_EDUCATION_FAILURE = `GET_${alias_education}_FAILURE`;
export const CLEAR_PRODUCT_EDUCATION_FETCH = `CLEAR_${alias_education}_FETCH`;

export const ADD_PRODUCT_EDUCATION_REQUEST = `ADD_${alias_education}_REQUEST`;
export const ADD_PRODUCT_EDUCATION_SUCCESS = `ADD_${alias_education}_SUCCESS`;
export const ADD_PRODUCT_EDUCATION_FAILURE = `ADD_${alias_education}_FAILURE`;

export const UPDATE_PRODUCT_EDUCATION_REQUEST = `UPDATE_${alias_education}_REQUEST`;
export const UPDATE_PRODUCT_EDUCATION_SUCCESS = `UPDATE_${alias_education}_SUCCESS`;
export const UPDATE_PRODUCT_EDUCATION_FAILURE = `UPDATE_${alias_education}_FAILURE`;

export const DELETE_PRODUCT_EDUCATION_REQUEST = `DELETE_${alias_education}_REQUEST`;
export const DELETE_PRODUCT_EDUCATION_SUCCESS = `DELETE_${alias_education}_SUCCESS`;
export const DELETE_PRODUCT_EDUCATION_FAILURE = `DELETE_${alias_education}_FAILURE`;

export const GET_PRODUCT_LANGUAGES_REQUEST = `GET_${alias_language}S_REQUEST`;
export const GET_PRODUCT_LANGUAGES_SUCCESS = `GET_${alias_language}S_SUCCESS`;
export const GET_PRODUCT_LANGUAGES_FAILURE = `GET_${alias_language}_FAILURE`;

export const GET_PRODUCT_LANGUAGE_REQUEST = `GET_${alias_language}_REQUEST`;
export const GET_PRODUCT_LANGUAGE_SUCCESS = `GET_${alias_language}_SUCCESS`;
export const GET_PRODUCT_LANGUAGE_FAILURE = `GET_${alias_language}_FAILURE`;
export const CLEAR_PRODUCT_LANGUAGE_FETCH = `CLEAR_${alias_language}_FETCH`;

export const ADD_PRODUCT_LANGUAGE_REQUEST = `ADD_${alias_language}_REQUEST`;
export const ADD_PRODUCT_LANGUAGE_SUCCESS = `ADD_${alias_language}_SUCCESS`;
export const ADD_PRODUCT_LANGUAGE_FAILURE = `ADD_${alias_language}_FAILURE`;

export const UPDATE_PRODUCT_LANGUAGE_REQUEST = `UPDATE_${alias_language}_REQUEST`;
export const UPDATE_PRODUCT_LANGUAGE_SUCCESS = `UPDATE_${alias_language}_SUCCESS`;
export const UPDATE_PRODUCT_LANGUAGE_FAILURE = `UPDATE_${alias_language}_FAILURE`;

export const DELETE_PRODUCT_LANGUAGE_REQUEST = `DELETE_${alias_language}_REQUEST`;
export const DELETE_PRODUCT_LANGUAGE_SUCCESS = `DELETE_${alias_language}_SUCCESS`;
export const DELETE_PRODUCT_LANGUAGE_FAILURE = `DELETE_${alias_language}_FAILURE`;

export const REQUEST_PRODUCT_FILTERS_REQUEST = `GET_${alias}_FILTERS_REQUEST`;
export const REQUEST_PRODUCT_FILTERS_SUCCESS = `GET_${alias}_FILTERS_SUCCESS`;
