import {
  call,
  CallEffect,
  put,
  PutEffect,
  takeLatest,
} from 'redux-saga/effects';
import { login } from '@lyra/services.api';
import { authActions } from './auth.reducer';
import { errorHandler, handleTokens } from '@moxie/utils';
import {
  AM_API_URL,
  EMAIL_LABEL,
  JWT_TOKEN_HEADER,
  REMEMBER_ME_COOKIE_EXPIRY_DATE,
  COOKIE_ACCESS,
  COOKIE_REFRESH,
} from '@moxie/constants';
import { axiosInstance } from '@lyra/services.api';
import { IAction, ILogin } from '@shared-components/models';
import { message } from 'antd';
import Cookies from 'js-cookie';
import { successNotificationHandler } from '@moxie/shared';
const baseUrl = process.env.NX_BASE_URL;
const logout_endpoint = `${baseUrl}${AM_API_URL.LOGOUT}`;
const get_current_user_endpoint = `${baseUrl}${AM_API_URL.GET_CURRENT_USER}`;

function* getCurrentUser(): Generator<
  | CallEffect<unknown>
  | PutEffect<{
      payload: any;
      type: string;
    }>
> {
  try {
    const res: any = yield call(axiosInstance.request, {
      url: `${get_current_user_endpoint}`,
      method: 'get',
    });
    const authData = res?.data?.data;
    const perms = authData.am_role?.permissions.map((d: any) => d.name);
    authData.am_role.permissions = perms;
    yield put(authActions.authUser(authData));
  } catch (err) {
    errorHandler(err);
  }
}

function* logout(
  data: any
): Generator<
  | CallEffect<unknown>
  | PutEffect<{
      payload: any;
      type: string;
    }>
> {
  try {
    yield call(axiosInstance.request, {
      url: `${logout_endpoint}`,
      method: 'post',
      headers: { Authorization: `${JWT_TOKEN_HEADER} ${data.payload}` },
    });
    localStorage.removeItem(EMAIL_LABEL);
    Cookies.remove(COOKIE_ACCESS);
    Cookies.remove(COOKIE_REFRESH);
    const isPasswordChange = localStorage.getItem('isChangePassword');
    if (isPasswordChange === 'true') {
      successNotificationHandler('Password changed successfully');
      setTimeout(() => {
        window.location.replace('/');
      }, 1000);
    }
    localStorage.removeItem('isChangePassword');
  } catch (err) {
    errorHandler(err, false);
  }
}

function* loginAccount(
  data: IAction<ILogin>
): Generator<CallEffect<unknown> | PutEffect<IAction<ILogin>>> {
  try {
    yield put(authActions.loading(true));
    let response: any;
    if (data.payload) {
      response = yield call(login, data.payload);
    }
    if (response.data.message) {
      message.warning(response.data.message);
    } else {
      if (response.data.access_token && response.data.refresh_token) {
        if (data.payload?.remember_me)
          handleTokens(response, REMEMBER_ME_COOKIE_EXPIRY_DATE);
        handleTokens(response);
        yield put(authActions.setUser(response.data.user));
        yield put(authActions.closeSessionModal());
      }
    }
    yield put(authActions.loading(false));
  } catch (err) {
    errorHandler(err);
    yield put(authActions.loading(false));
  }
}

export default function* authSaga(): any {
  yield takeLatest(authActions.logout.type, logout);
  yield takeLatest(authActions.login.type, loginAccount);
  yield takeLatest(authActions.getAuthUser.type, getCurrentUser);
}
