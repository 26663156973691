import React, { useEffect } from 'react';
import { IServiceFormProps } from '@shared-components/models';
import { Form } from 'antd';
import { FormItem, Input } from '@shared-components/elements';
import {
  ALPHANUMERIC_REQUIRED,
  DESCRIPTION_LABEL,
  DESCRIPTION_PLACEHOLDER,
  LABEL,
  regex,
  SERVICE_NAME_PLACEHOLDER,
  SERVICE_RESPONSE_MESSAGE,
} from '@moxie/constants';
import { Editor } from '@shared-components/forms';

const ServiceForm: React.FC<any> = ({
  form,
  setDisabled,
  onSubmit,
  initialData,
  handleFieldsChange,
}: IServiceFormProps) => {
  useEffect(() => {
    if (initialData) {
      form.setFieldsValue(initialData);
    } else {
      form.setFieldsValue({});
    }
  }, [form, initialData]);

  const handleNameChange = (data: any) => {
    initialData.name === data ? setDisabled(true) : setDisabled(false);
  };

  const handleEditorChange = (data: string) => {
    if (data.replace(/<(.|\n)*?>/g, '').trim().length === 0) {
      setDisabled(true);
    } else {
      setDisabled(false);
    }
  };

  return (
    <Form
      name="service-form"
      form={form}
      layout="vertical"
      onFinish={onSubmit}
      onFieldsChange={handleFieldsChange}
    >
      <FormItem
        label={LABEL.NAME}
        
        validateFirst={true}
        name="name"
        rules={[
          {
            required: true,
            message: SERVICE_RESPONSE_MESSAGE.NAME_REQUIRED,
          },
          { whitespace: true },
          { max: 50, message: SERVICE_RESPONSE_MESSAGE.MAX_CHARACTER_MESSAGE },
          {
            pattern: new RegExp(regex.ALPHA_NUMERIC_WITH_SPACE),
            message: ALPHANUMERIC_REQUIRED,
          },  
        ]}
      >
        <Input
          name="name"
          data-testid="crm-servicename"
          placeholder={SERVICE_NAME_PLACEHOLDER}
          onChange={handleNameChange}
        />
      </FormItem>
      <FormItem
        label={DESCRIPTION_LABEL}
        data-testid="crm-servicedescription"
        name="description"
        rules={[
          {
            required: true,
            message: SERVICE_RESPONSE_MESSAGE.DESCRIPTION_REQUIRED,
          },
          ({ getFieldValue }) => ({
            validator(_, value) {
              if (
                value &&
                getFieldValue('description')
                  .replace(/<(.|\n)*?>/g, '')
                  .trim().length === 0
              ) {
                return Promise.reject(
                  SERVICE_RESPONSE_MESSAGE.DESCRIPTION_REQUIRED
                );
              }
              return Promise.resolve();
            },
          }),
        ]}
      >
        <Editor
          placeholder={DESCRIPTION_PLACEHOLDER}
          onChange={handleEditorChange}
        />
      </FormItem>
    </Form>
  );
};
export { ServiceForm };
