import React, { useState } from 'react';
import {
  BUTTON,
  CONFIRM_MESSAGE,
  CONFIRM_TITLE,
  DELETE_BTN,
} from '@moxie/constants';
import { Button, popConfirm } from '@shared-components/elements';
import { DeleteOutlined, EditOutlined, MoreOutlined } from '@ant-design/icons';
import { Dropdown, Menu } from 'antd';
import LanguageRequirementDrawer from './language-requirement-drawer';
import { productsActions, useAppSelector } from '@admin/core';
import { useDispatch } from 'react-redux';
import { IProductLanguageNameCard } from '@shared-components/models';

const ProductLanguageNameCard: React.FC<IProductLanguageNameCard> = ({
  name,
  id,
  form,
  productId,
}: IProductLanguageNameCard) => {
  const [isLanguageDrawer, setIsLanguageDrawer] = useState(false);
  const dispatch = useDispatch();
  const languageScores = useAppSelector(
    (store) => store.product.product_language
  );

  const handleDrawer = () => {
    setIsLanguageDrawer(!isLanguageDrawer);
    dispatch(productsActions.getProductLanguageRequest(id));
  };

  const handleRemove = () => {
    popConfirm(
      CONFIRM_TITLE.DELETE_SCORE,
      `${CONFIRM_MESSAGE.DELETE_SCORE}`,
      async () => {
        id && dispatch(productsActions.deleteProductLanguageRequest(id));
      }
    );
  };
  const menu = (
    <Menu>
      <Menu.Item key="2" icon={<EditOutlined />} onClick={handleDrawer}>
        {BUTTON.EDIT}
      </Menu.Item>
      <Menu.Item key="3" icon={<DeleteOutlined />} onClick={handleRemove}>
        {DELETE_BTN}
      </Menu.Item>
    </Menu>
  );

  const handleDrawerClose = () => {
    form.resetFields();
    dispatch(productsActions.getProductLanguagesClear());
    setIsLanguageDrawer(false);
  };
  return (
    <>
      <Dropdown overlay={menu}>
        <Button
          type="text"
          className="table-cell-action-btn"
          icon={<MoreOutlined />}
        />
      </Dropdown>
      <LanguageRequirementDrawer
        productId={productId}
        name={name}
        isEdit={true}
        isOpen={isLanguageDrawer}
        onClose={handleDrawerClose}
        initialData={languageScores}
        form={form}
      />
    </>
  );
};

export default ProductLanguageNameCard;
