import { useQuery } from '@tanstack/react-query';
import { errorNotificationHandler } from '@moxie/shared';
import { fetchInterestedServices } from '@crm/libs/services.api/lib/interested-services.api';

export const useFetchInterestedServices = (includeNotSure = false) => {
  const { data, isLoading } = useQuery({
    queryKey: ['interested-services'],
    queryFn: async () => fetchInterestedServices(),
    enabled: true,
    onError: () =>
      errorNotificationHandler('Error fetching interested services.'),
  });

  const interestedServices = data?.data || [];
  const treeData = interestedServices
    .filter((item) => (includeNotSure ? true : item.name !== 'Not sure'))
    .map((item: any) => {
      const children = []

      if (item.payload.countries.length) {
        children.push({
          title: 'countries',
          key: 'countries',
          children: item.payload.countries.map((country: any) => ({
            title: country,
            key: country,
          })),
        })

      }

      if (item.payload?.degreeLevels?.length || item.payload.interests?.length) {
        children.push({
          title: 'interests',
          key: 'interests',
          children: item.payload.degreeLevels
            ? item.payload?.degreeLevels?.map((degree: any) => ({
              title: degree,
              key: degree,
            }))
            : item.payload?.interests?.map((type: any) => ({
              title: type,
              key: type,
            })),
        })
      }
      return {
        title: item.name,
        key: item.name,
        id: item.id,
        children,
      };
    });
  return { interestedServices, treeData, isLoading };
};
