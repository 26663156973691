/* eslint-disable no-unused-vars */
import React, { useEffect, useState } from 'react';
import { IRoleForm } from '@shared-components/models';
import { useAppSelector } from '@lyra/core';
import {
  ADD_NEW_ROLE_LABEL,
  DESCRIPTION_LABEL,
  PERMISSIONS_OFFICES_TEAMS,
  ROLE_NAME_REQUIRED,
  ROLE_DESCRIPTION_REQUIRED,
  PERMISSIONS_ROLES,
  PERMISSIONS_CLIENTS,
  PERMISSIONS_APPLICATIONS,
  PERMISSIONS_DEPARTMENTS,
  PERMISSIONS_USERS,
} from '@moxie/constants';
import { Checkbox, Row, Col, Form, Input, Collapse } from 'antd';
import { CheckboxValueType } from 'antd/lib/checkbox/Group';
import { capitalizeFirstLetter } from 'libs/shared/src/functions';

const { TextArea } = Input;
const CheckboxGroup = Checkbox.Group;
const { Panel } = Collapse;

interface ICustomCheckBoxGroup {
  title: string;
  name: string;
  permissions: any[];
  selectedPermissions: (string | number | CheckboxValueType)[];
  label?: string | null;
}

export const CustomCheckBoxGroup: React.FC<ICustomCheckBoxGroup> = (
  props: ICustomCheckBoxGroup
) => {
  const { permissions, name, title, label, selectedPermissions } = props;

  const [hidePanel, sethidePanel] = useState<boolean>(false);
  const [checkAll, setCheckAll] = useState<boolean>(false);
  const [indeterminate, setIndeterminate] = React.useState(true);
  const [checkAllVal, setCheckAllValue] = useState<
    (string | number | CheckboxValueType)[]
  >(selectedPermissions || []);

  const onCheckAllChange = (e: {
    // eslint-disable-next-line no-unused-vars
    target: { checked: boolean | ((prevState: boolean) => boolean) };
  }) => {
    setCheckAll(!checkAll);
    const checkboxsArr = permissions.map((val) => val.value);
    setCheckAllValue(e.target.checked ? checkboxsArr : []);
    setIndeterminate(false);
    setCheckAll(e.target.checked);
  };

  const onChange = (list: (string | number | CheckboxValueType)[]) => {
    setCheckAllValue(list);
    setIndeterminate(!!list.length && list.length < permissions.length);
    setCheckAll(list.length === permissions.length);
  };

  const handlehidePanel = () => {
    sethidePanel(!hidePanel);
  };

  useEffect(() => {
    if (selectedPermissions.length <= 0) {
      setCheckAllValue([]);
    }
    setCheckAllValue(selectedPermissions);
    return () => {
      setCheckAllValue([]);
    };
  }, [selectedPermissions]);

  return (
    <div className={`custom-checkboxgroup ${hidePanel ? 'hide-content' : ''}`}>
      {label && <p className="custom-checkboxgroup__title">{label}</p>}
      <Row gutter={0} justify="space-between" className="custom__title__text">
        <Col lg={23} onClick={handlehidePanel}>
          {title}
        </Col>
        <Col lg={1}>
          <Checkbox
            indeterminate={indeterminate}
            name={`check-all_${name}`}
            onChange={onCheckAllChange}
            checked={checkAll}
          />
        </Col>
      </Row>
      <div className="custom-checkboxgroup__content">
        <Form.Item name={name} valuePropName="checked">
          <CheckboxGroup
            className="custom_role_vertical--checkbox"
            options={permissions || []}
            value={checkAllVal}
            onChange={onChange}
          />
        </Form.Item>
      </div>
    </div>
  );
};

export const RoleForm: React.FC<IRoleForm> = (props: IRoleForm) => {
  const { title, handleSubmit, form, isFieldsDisabled } = props;
  const statePermissions = useAppSelector(
    (state: any) => state.role_permissions.permissions
  );
  const selectedRole = useAppSelector(
    (state: any) => state.role_permissions.selectedRole
  );
  const [permissions, setPermissions] = useState<
    { label: string; value: number }[]
  >([]);
  const [officePermissions, setOfficePermissions] = useState<
    (string | number | CheckboxValueType)[]
  >([]);
  const [rolePermissions, setRolePermissions] = useState<
    (string | number | CheckboxValueType)[]
  >([]);
  const [userPermissions, setUserPermissions] = useState<
    (string | number | CheckboxValueType)[]
  >([]);
  const [clientPermissions, setClientPermissions] = useState<
    (string | number | CheckboxValueType)[]
  >([]);
  const [departmentPermissions, setDepartmentPermissions] = useState<
    (string | number | CheckboxValueType)[]
  >([]);
  const [applicationPermissions, setApplicationPermissions] = useState<
    (string | number | CheckboxValueType)[]
  >([]);

  const onChangeOfficePermission = (
    list: (string | number | CheckboxValueType)[]
  ) => {
    if (
      !list.includes(64) &&
      (list.includes(65) || list.includes(66) || list.includes(67))
    ) {
      list.push(64);
    }
    setOfficePermissions(list);
  };

  const onChangeRolePermission = (
    list: (string | number | CheckboxValueType)[]
  ) => {
    if (
      !list.includes(1) &&
      (list.includes(2) || list.includes(3) || list.includes(4))
    ) {
      list.push(1);
    }
    setRolePermissions(list);
  };

  const onChangeUserPermission = (
    list: (string | number | CheckboxValueType)[]
  ) => {
    if (
      !list.includes(11) &&
      (list.includes(12) || list.includes(13) || list.includes(14))
    ) {
      list.push(11);
    }
    setUserPermissions(list);
  };

  const onChangeClientPermission = (
    list: (string | number | CheckboxValueType)[]
  ) => {
    if (
      !list.includes(16) &&
      (list.includes(17) ||
        list.includes(18) ||
        list.includes(19) ||
        list.includes(21))
    ) {
      list.push(16);
    }
    if (
      !list.includes(22) &&
      (list.includes(23) || list.includes(24) || list.includes(25))
    ) {
      list.push(22);
    }
    if (
      !list.includes(27) &&
      (list.includes(28) || list.includes(29) || list.includes(30))
    ) {
      list.push(27);
    }
    if (
      !list.includes(32) &&
      (list.includes(33) || list.includes(34) || list.includes(35))
    ) {
      list.push(32);
    }
    if (
      !list.includes(48) &&
      (list.includes(49) || list.includes(50) || list.includes(51))
    ) {
      list.push(48);
    }
    if (
      !list.includes(43) &&
      (list.includes(44) || list.includes(45) || list.includes(46))
    ) {
      list.push(43);
    }
    if (
      !list.includes(53) &&
      (list.includes(54) ||
        list.includes(55) ||
        list.includes(56) ||
        list.includes(58))
    ) {
      list.push(53);
    }
    if (
      !list.includes(37) &&
      (list.includes(38) || list.includes(39) || list.includes(40))
    ) {
      list.push(37);
    }

    setClientPermissions(list);
  };

  const onChangeApplicationPermission = (
    list: (string | number | CheckboxValueType)[]
  ) => {
    if (
      !list.includes(42) &&
      (list.includes(43) || list.includes(44) || list.includes(45))
    ) {
      list.push(42);
    }
    setApplicationPermissions(list);
  };

  const onChangeDepartmentPermission = (
    list: (string | number | CheckboxValueType)[]
  ) => {
    if (
      !list.includes(59) &&
      (list.includes(60) || list.includes(61) || list.includes(62))
    ) {
      list.push(59);
    }
    setDepartmentPermissions(list);
  };

  useEffect(() => {
    if (statePermissions.length > 0) {
      const textpermissionArr = statePermissions?.map((item: any) => {
        return {
          label: item.label,
          value: item.id,
          type: item.type,
        };
      });
      setPermissions(textpermissionArr);
    }
  }, [statePermissions, setPermissions]);

  useEffect(() => {
    if (selectedRole) {
      const selectedPermissionArr = selectedRole.permission.map(
        (item: { id: number }) => item.id
      );
      form?.setFieldsValue({
        name:
          selectedRole?.name === 'superAdmin'
            ? 'Super Admin'
            : capitalizeFirstLetter(selectedRole?.name),
        description: selectedRole?.description,
        office_permissions: selectedRole.permission
          .map((item: any) => (item.type === 'branches' ? item : null))
          .filter((item: any) => item !== null)
          .map((item: { id: number }) => item.id),
        role_permissions: selectedRole.permission
          .map((item: any) => (item.type === 'roles' ? item : null))
          .filter((item: any) => item !== null)
          .map((item: { id: number }) => item.id),
        user_permissions: selectedRole.permission
          .map((item: any) => (item.type === 'users' ? item : null))
          .filter((item: any) => item !== null)
          .map((item: { id: number }) => item.id),
        client_permissions: selectedRole.permission
          .map((item: any) =>
            item.type === 'contacts' ||
            item.type === 'contacts_assigned' ||
            item.type === 'contacts_followed' ||
            item.type === 'contact_appointments' ||
            item.type === 'contact_documents' ||
            item.type === 'contacts_form' ||
            item.type === 'contacts_assigned_followed'
              ? item
              : null
          )
          .filter((item: any) => item !== null)
          .map((item: { id: number }) => item.id),
        application_permissions: selectedRole.permission
          .map((item: any) => (item.type === 'applications' ? item : null))
          .filter((item: any) => item !== null)
          .map((item: { id: number }) => item.id),
        department_permissions: selectedRole.permission
          .map((item: any) => (item.type === 'departments' ? item : null))
          .filter((item: any) => item !== null)
          .map((item: { id: number }) => item.id),
      });
      setOfficePermissions(selectedPermissionArr);
      setRolePermissions(selectedPermissionArr);
      setClientPermissions(selectedPermissionArr);
      setUserPermissions(selectedPermissionArr);
      setDepartmentPermissions(selectedPermissionArr);
      setApplicationPermissions(selectedPermissionArr);
    }
  }, [selectedRole, form]);

  useEffect(() => {
    return () => {
      form.resetFields();
      setOfficePermissions([]);
      setRolePermissions([]);
      setClientPermissions([]);
      setDepartmentPermissions([]);
      setApplicationPermissions([]);
    };
  }, [form, setOfficePermissions]);

  return (
    <>
      {title && <h1>{title}</h1>}
      <Form
        form={form}
        layout="vertical"
        onFinish={handleSubmit}
        scrollToFirstError
      >
        <Form.Item
          label={ADD_NEW_ROLE_LABEL}
          required
          tooltip={ROLE_NAME_REQUIRED}
          rules={[{ required: true, message: ROLE_NAME_REQUIRED }]}
          name="name"
        >
          <Input placeholder={ADD_NEW_ROLE_LABEL} disabled={isFieldsDisabled} />
        </Form.Item>
        <Form.Item
          label={DESCRIPTION_LABEL}
          required
          name="description"
          tooltip={ROLE_DESCRIPTION_REQUIRED}
          rules={[{ required: true, message: ROLE_DESCRIPTION_REQUIRED }]}
        >
          <TextArea
            placeholder={DESCRIPTION_LABEL}
            disabled={isFieldsDisabled}
          />
        </Form.Item>
        <Collapse defaultActiveKey={['1', '2']} ghost>
          <Panel
            showArrow={false}
            header={
              <Row
                gutter={0}
                justify="space-between"
                className="custom__title__text"
              >
                <Col lg={23}>{PERMISSIONS_OFFICES_TEAMS}</Col>
                <Col lg={1}></Col>
              </Row>
            }
            key="1"
          >
            <Form.Item name="office_permissions" valuePropName="checked">
              <CheckboxGroup
                className="custom_role_vertical--checkbox"
                options={
                  permissions
                    .map((item: any) =>
                      item.type === 'branches' ? item : null
                    )
                    .filter((item) => item !== null) || []
                }
                disabled={isFieldsDisabled}
                value={officePermissions}
                onChange={onChangeOfficePermission}
              />
            </Form.Item>
          </Panel>
          <Panel
            showArrow={false}
            header={
              <Row
                gutter={0}
                justify="space-between"
                className="custom__title__text"
              >
                <Col lg={23}>{PERMISSIONS_ROLES}</Col>
              </Row>
            }
            key="2"
          >
            <Form.Item name="role_permissions" valuePropName="checked">
              <CheckboxGroup
                className="custom_role_vertical--checkbox"
                options={
                  permissions
                    .map((item: any) => (item.type === 'roles' ? item : null))
                    .filter((item) => item !== null) || []
                }
                disabled={isFieldsDisabled}
                value={rolePermissions}
                onChange={onChangeRolePermission}
              />
            </Form.Item>
          </Panel>
          <Panel
            showArrow={false}
            header={
              <Row
                gutter={0}
                justify="space-between"
                className="custom__title__text"
              >
                <Col lg={23}>{PERMISSIONS_USERS}</Col>
              </Row>
            }
            key="6"
          >
            <Form.Item name="user_permissions" valuePropName="checked">
              <CheckboxGroup
                className="custom_role_vertical--checkbox"
                options={
                  permissions
                    .map((item: any) => (item.type === 'users' ? item : null))
                    .filter((item) => item !== null) || []
                }
                disabled={isFieldsDisabled}
                value={userPermissions}
                onChange={onChangeUserPermission}
              />
            </Form.Item>
          </Panel>
          <Panel
            showArrow={false}
            header={
              <Row
                gutter={0}
                justify="space-between"
                className="custom__title__text"
              >
                <Col lg={23}>{PERMISSIONS_CLIENTS}</Col>
              </Row>
            }
            key="3"
          >
            <Form.Item name="client_permissions" valuePropName="checked">
              <CheckboxGroup
                className="custom_role_vertical--checkbox"
                options={
                  permissions
                    .map((item: any) =>
                      item.type === 'contacts' ||
                      item.type === 'contacts_assigned' ||
                      item.type === 'contacts_followed' ||
                      item.type === 'contact_appointments' ||
                      item.type === 'contact_documents' ||
                      item.type === 'contacts_form' ||
                      item.type === 'contacts_assigned_followed'
                        ? item
                        : null
                    )
                    .filter((item) => item !== null) || []
                }
                disabled={isFieldsDisabled}
                value={clientPermissions}
                onChange={onChangeClientPermission}
              />
            </Form.Item>
          </Panel>
          <Panel
            showArrow={false}
            header={
              <Row
                gutter={0}
                justify="space-between"
                className="custom__title__text"
              >
                <Col lg={23}>{PERMISSIONS_APPLICATIONS}</Col>
              </Row>
            }
            key="4"
          >
            <Form.Item name="application_permissions" valuePropName="checked">
              <CheckboxGroup
                className="custom_role_vertical--checkbox"
                options={
                  permissions
                    .map((item: any) =>
                      item.type === 'applications' ? item : null
                    )
                    .filter((item) => item !== null) || []
                }
                disabled={isFieldsDisabled}
                value={applicationPermissions}
                onChange={onChangeApplicationPermission}
              />
            </Form.Item>
          </Panel>
          <Panel
            showArrow={false}
            header={
              <Row
                gutter={0}
                justify="space-between"
                className="custom__title__text"
              >
                <Col lg={23}>{PERMISSIONS_DEPARTMENTS}</Col>
              </Row>
            }
            key="5"
          >
            <Form.Item name="department_permissions" valuePropName="checked">
              <CheckboxGroup
                className="custom_role_vertical--checkbox"
                options={
                  permissions
                    .map((item: any) =>
                      item.type === 'departments' ? item : null
                    )
                    .filter((item) => item !== null) || []
                }
                value={departmentPermissions}
                onChange={onChangeDepartmentPermission}
                disabled={isFieldsDisabled}
              />
            </Form.Item>
          </Panel>
        </Collapse>
      </Form>
    </>
  );
};
