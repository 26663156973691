import { IAction } from '@shared-components/models';
import * as actionType from './subject-discipline.constant';

export const getSubjectDisciplineRequest = (): IAction => ({
  type: actionType.GET_SUBJECT_DISCIPLINE_REQUEST,
});

export const getSubjectDisciplineSuccess = (payload: any): IAction => ({
  type: actionType.GET_SUBJECT_DISCIPLINE_SUCCESS,
  payload,
});

export const getSubjectDisciplineFailure = (payload: any): IAction => ({
  type: actionType.GET_SUBJECT_DISCIPLINE_FAILURE,
  payload,
});

export const getSubjectAreasRequest = (id: any): IAction => ({
  type: actionType.GET_SUBJECT_AREAS_REQUEST,
  payload: id,
});

export const getSubjectAreasSuccess = (payload: any): IAction => ({
  type: actionType.GET_SUBJECT_AREAS_SUCCESS,
  payload,
});

export const getSubjectAreasFailure = (payload: any): IAction => ({
  type: actionType.GET_SUBJECT_AREAS_FAILURE,
  payload,
});
