import React, { useState, useEffect, createContext } from 'react';
import { errorHandler } from '@moxie/utils';
import {
  IProductComparisonListContext,
  IProductComparisonModal,
} from '@shared-components/models';

export const ProductComparisonListContext = createContext<IProductComparisonListContext>(
  {
    compareList: [],
    // eslint-disable-next-line no-unused-vars
    handleUpdateList: (_data) => {
      return;
    },
  }
);

interface IProductComparisonListProviderProps {
  children: React.ReactChild | React.ReactChildren;
}
export const ProductComparisonListProvider: React.FC<IProductComparisonListProviderProps> = ({
  children,
}: IProductComparisonListProviderProps) => {
  const [compareList, setCompareList] = useState<IProductComparisonModal[]>([]);

  useEffect(() => {
    updateProductList();
  }, []);

  const updateProductList = () => {
    try {
      const data = JSON.parse(localStorage.getItem('compare') || '');
      setCompareList(data);
    } catch (err) {
      errorHandler(err, false);
    }
  };

  const handleUpdateList = (compareList: IProductComparisonModal[]) =>
    setCompareList(compareList);

  return (
    <ProductComparisonListContext.Provider
      value={{ compareList, handleUpdateList }}
    >
      {children}
    </ProductComparisonListContext.Provider>
  );
};
