import { STATUS } from '@moxie/constants';
import { IInterestedService, IStatus } from '@shared-components/models';
import { AnyAction } from 'redux';
import {
  FETCH_INTERESTED_SERVICES,
  IDLE_INTERESTED_SERVICE,
  LOADING_INTERESTED_SERVICE,
  PRODUCT_SUCCEEDED_INTERESTED_SERVICE,
  SET_ERROR_INTERESTED_SERVICES,
  SUCCEEDED_INTERESTED_SERVICE,
} from './interested-services.constant';

export const INTERESTED_SERVICE_FEATURE_KEY = 'interested_service';
export interface IInterestedServiceReducers {
  interestedService: IInterestedService[];
  status: IStatus;
  error: string | null;
}

export const INITIAL_DATA: IInterestedServiceReducers = {
  interestedService: [],
  status: STATUS.IDLE,
  error: null,
};

export const interestedServiceReducers = (
  state = INITIAL_DATA,
  action: AnyAction
): IInterestedServiceReducers => {
  switch (action.type) {
    case FETCH_INTERESTED_SERVICES:
      return {
        ...state,
        interestedService: action.payload,
        status: STATUS.SUCCEEDED,
      };
    case LOADING_INTERESTED_SERVICE:
      return { ...state, status: STATUS.LOADING };
    case IDLE_INTERESTED_SERVICE:
      return { ...state, status: STATUS.IDLE };
    case SUCCEEDED_INTERESTED_SERVICE:
      return { ...state, status: STATUS.SUCCEEDED };
    case PRODUCT_SUCCEEDED_INTERESTED_SERVICE:
      return { ...state, status: STATUS.PRODUCT_SUCCEEDED };
    case SET_ERROR_INTERESTED_SERVICES:
      return { ...state, error: action.payload, status: STATUS.FAILED };
    default:
      return state;
  }
};

export default interestedServiceReducers;
