import { Prettify } from "@model/data.model";

const uuidRegex = new RegExp(/^[0-9a-fA-F]{8}\b-[0-9a-fA-F]{4}\b-[0-9a-fA-F]{4}\b-[0-9a-fA-F]{4}\b-[0-9a-fA-F]{12}$/g);

export const isValidUuid = (str: string) => Boolean(str.match(uuidRegex)?.length)

export const filterValidRefs = (val: string) => isValidUuid(val) || !isNaN(parseInt(val));

export const arrayToMap = (values: string[]) => {
  return values?.reduce((acc, cur) => {
    acc.set(cur, cur)
    return acc;
  }, new Map<string, string>())
}

export const arraysEqual = (arr1: string | any[], arr2: string | any[]) => {
  if (arr1.length !== arr2.length) {
    return false;
  }

  const sortedArr1 = [...arr1].sort();
  const sortedArr2 = [...arr2].sort();

  for (let i = 0; i < sortedArr1.length; i++) {
    if (sortedArr1[i] !== sortedArr2[i]) {
      return false;
    }
  }
  return true;
}

export const objectsEqual = (obj1: any, obj2: any) => {
  return JSON.stringify(obj1) === JSON.stringify(obj2);
}

interface Name {
  firstName: string;
  lastName: string;
}
export const generateAvatarNameChars = ({ firstName, lastName }: Name) => `${firstName.substring(0, 1).toUpperCase()}${lastName.substring(0, 1).toUpperCase()}`

export const cleanAndTitleCase = (input: string): string => {
  // Remove special characters and extra spaces
  const cleaned = input.replace(/[^a-zA-Z0-9\s]/g, ' ').replace(/\s+/g, ' ').trim();

  // Convert to title case
  const titleCased = cleaned.split(' ').map(word => {
    return word.charAt(0).toUpperCase() + word.slice(1).toLowerCase();
  }).join(' ');

  return titleCased;
}


export function formatFileSize(sizeInBytes: number): string {
  const units = ['Bytes', 'KB', 'MB', 'GB'];
  let size = sizeInBytes;
  let unitIndex = 0;

  while (size >= 1024 && unitIndex < units.length - 1) {
    size /= 1024;
    unitIndex++;
  }

  return `${size.toFixed(2)} ${units[unitIndex]}`;
}

interface Response<T> {
  [month: string]: T[];
}
export const groupByMonth = <T extends { createdAt: string }>(activities: T[]) => {
  return activities.reduce((acc, activity) => {
    const month = new Date(activity.createdAt).toLocaleString('default', { month: 'long' });
    if (!acc[month]) {
      acc[month] = [];
    }
    acc[month].push(activity);
    return acc;
  }, {} as Prettify<Response<T>>);
};


interface GroupBy<T> {
  [key: string | number]: T;
}
export const groupBy = <T>(data: T[], fn: (val: T) => number | string): GroupBy<T> => {
  return data.reduce((acc, cur) => {
    acc[fn(cur)] = cur;
    return acc;
  }, {} as GroupBy<T>)
}
