import React, { useState } from 'react';
import { Space } from 'antd';
import { ILeadTableAssignee, IUser } from '@shared-components/models';
import { TableUserNameCard, UnassignedCard } from '@moxie/shared';
import { TableAssignee } from '../../modules/lead/libs/lead-column-edit';

const AssigneeCard = ({
  data,
  assignee,
  handleSubmit,
  setAssignee,
  hasRole,
  users,
}: ILeadTableAssignee) => {
  const [addButton, setAddButton] = useState<boolean>(false);
  const user: IUser | undefined = data.assignee || data?.crm_assignee;
  const handleEdit = () => {
    // TODO:: currently disabled
    setAssignee(true);
  };

  const handleCancel = () => {
    setAssignee(true);
  };

  return (
    <div className="lead-table__archived-by">
      {user ? (
        assignee ? (
          <Space direction="horizontal">
            <div className="cursor-pointer">
              <TableUserNameCard
                index={user.id || ''}
                actionBar={false}
                firstName={user.first_name || ''}
                lastName={user.last_name}
                email={user.email || ''}
                id={user.id || ''}
                handleEdit={hasRole ? handleEdit : undefined}
              />
            </div>
          </Space>
        ) : (
          <TableAssignee
            handleSubmit={handleSubmit}
            addButton={addButton}
            setAddButton={setAddButton}
            handleCancel={handleCancel}
            assigneeId={user?.id}
            users={users}
          />
        )
      ) : assignee ? (
        <Space direction="horizontal">
            <div onClick={handleEdit} className="cursor-pointer">
              <UnassignedCard />
            </div>
        </Space>
      ) : (
        <TableAssignee
          handleSubmit={handleSubmit}
          addButton={addButton}
          setAddButton={setAddButton}
          handleCancel={handleCancel}
          users={users}
        />
      )}
    </div>
  );
};

export { AssigneeCard };
