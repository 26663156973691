import { API_URL } from '@moxie/constants';
import {
  IOtherDetailsCategory,
  IOtherDetailsLabel,
  IOtherDetailsOptions,
} from '@shared-components/models';
import { axiosInstance as axios } from '@admin/services.api';

const getOtherDetailsCategory = async () =>
  axios.get(API_URL.URL_OTHER_DETAIL_CATEGORIES);

const getOtherDetailsCategoryWfStageId = async (wfStageId: string) =>
  axios.get(`${API_URL.URL_OTHER_DETAIL_CATEGORIES}/workflow/${wfStageId}`);

const postOtherDetailsCategory = async (data: IOtherDetailsCategory) =>
  axios.post(API_URL.URL_OTHER_DETAIL_CATEGORIES, data);

const deleteOtherDetailsCategory = async (id: string) =>
  axios.delete(`${API_URL.URL_OTHER_DETAIL_CATEGORIES}/${id}`);

const putOtherDetailsCategory = async (
  id: string,
  category: IOtherDetailsCategory
) => axios.put(`${API_URL.URL_OTHER_DETAIL_CATEGORIES}/${id}`, category);

const getOtherDetailsLabel = async (id: string) =>
  axios.get(`${API_URL.URL_OTHER_DETAIL_LABELS}/${id}`);

const postOtherDetailsLabel = async (data: IOtherDetailsLabel) =>
  axios.post(API_URL.URL_OTHER_DETAIL_LABELS, data);

const getOtherDetailsLabelValue = async (id: string, field_id: string) =>
  axios.get(`${API_URL.URL_OTHER_DETAIL_VALUES}/${id}/${field_id}`);

const postOtherDetailsLabelValue = async (data: any) =>
  axios.post(API_URL.URL_OTHER_DETAIL_VALUES, data);

const putOtherDetailsLabelValue = async (id: string | undefined, body: any) =>
  axios.put(`${API_URL.URL_OTHER_DETAIL_VALUES}/${id}`, body);

const deleteOtherDetailsLabel = async (id: string) =>
  axios.delete(`${API_URL.URL_OTHER_DETAIL_LABELS}/${id}`);

const putOtherDetailsLabel = async (id: string, label: IOtherDetailsLabel) =>
  axios.put(`${API_URL.URL_OTHER_DETAIL_LABELS}/${id}`, label);

const postOtherDetailsOption = async (
  data: IOtherDetailsOptions[],
  otherDetailsLabelId: string
) => axios.post(`${API_URL.URL_OPTIONS}/${otherDetailsLabelId}`, data);

const postMultipleOtherDetailsOption = async (data: IOtherDetailsOptions[]) =>
  axios.post(`${API_URL.URL_OPTIONS}/multiple`, data);

export {
  getOtherDetailsCategory,
  getOtherDetailsCategoryWfStageId,
  postOtherDetailsCategory,
  deleteOtherDetailsCategory,
  putOtherDetailsCategory,
  getOtherDetailsLabel,
  postOtherDetailsLabel,
  getOtherDetailsLabelValue,
  postOtherDetailsLabelValue,
  putOtherDetailsLabelValue,
  deleteOtherDetailsLabel,
  putOtherDetailsLabel,
  postOtherDetailsOption,
  postMultipleOtherDetailsOption,
};
