import {
  IAction,
  IProduct,
  IProductComparison,
} from '@shared-components/models';
import * as actionTypes from './product-comparison.constant';

export const addProductComparisonRequest = (
  payload: IProductComparison
): IAction<IProductComparison> => ({
  type: actionTypes.ADD_PRODUCT_COMPARISON_REQUEST,
  payload,
});
export const addProductComparisonSuccess = (
  payload: IProduct[]
): IAction<IProduct[]> => ({
  type: actionTypes.ADD_PRODUCT_COMPARISON_SUCCESS,
  payload,
});
export const addProductComparisonFailure = (payload: any): IAction<any> => ({
  type: actionTypes.ADD_PRODUCT_COMPARISON_FAILURE,
  payload,
});
