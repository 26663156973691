import * as actionTypes from './lead-activities.constant';
import { IAction } from '@shared-components/models';

export const LEAD_ACTIVITIES_FEATURE_KEY = 'leadactivities';
export interface LeadActivitesState {
  leadactivities: any[];
  loading: boolean;
  error: null;
}

export const initialLeadActivitesState: LeadActivitesState = {
  leadactivities: [],
  loading: false,
  error: null,
};

export const LeadActivitiesReducer = (
  state = initialLeadActivitesState,
  action: IAction<any>
) => {
  switch (action.type) {
    case actionTypes.GET_LEAD_ACTIVITIES_REQUEST:
      return {
        ...state,
        loading: true,
      };
    case actionTypes.GET_LEAD_ACTIVITIES_SUCCESS:
      return {
        ...state,
        loading: false,
        error: null,
        leadactivities: [...action.payload],
      };
    case actionTypes.GET_LEAD_ACTIVITIES_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.payload,
      };
    case actionTypes.GET_LEAD_ACTIVITIES_CLEAR:
      return {
        ...state,
        loading: false,
        error: null,
        leadactivities: [],
      };
    case actionTypes.ADD_LEAD_ACTIVITIE_SUCCESS:
      return {
        ...state,
        loading: false,
        error: null,
        leadactivities: [...action.payload, ...state.leadactivities],
      };
    default:
      return state;
  }
};
