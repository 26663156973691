import React, { useEffect, useState } from 'react';
import { Col, Form, Row, Skeleton, Spin } from 'antd';
import {
  GUTTER,
  LABEL,
  PLACEHOLDER,
  WORKFLOW_TYPE_INFORMATION,
} from '@moxie/constants';
import { FormItem, Input, Option, SearchableSelect } from '@moxie/shared';
import { getServices } from '@admin/services.api';
import { IForm, IService } from '@shared-components/models';
import { useAppSelector } from '@admin/core';

const WorkflowTypeForm = ({
  form,
  onSubmit,
  setDisabled,
  submitted,
  isEdit,
}: IForm<any>) => {
  const [services, setServices] = useState<IService[]>([]);
  const [servicesLoading, setServicesLoading] = useState<boolean>(false);
  const { workflowTypeData, loading } = useAppSelector((state) => ({
    workflowTypeData: state.workflow_types.singleData,
    loading: state.workflow_types.loading,
  }));

  const onFieldsChange = (allFields: string | any[]) => {
    if (allFields.length > 0) {
      setDisabled(false);
    }
  };
  useEffect(() => {
    if (services?.length === 0) {
      setServicesLoading(true);
      (async () => {
        const response = await getServices();
        setServices(response?.data?.data);
        setServicesLoading(false);
      })();
    }
    if (workflowTypeData) {
      form.setFieldsValue(workflowTypeData);
    } else {
      form.setFieldsValue({});
    }
  }, [workflowTypeData]);

  return isEdit && loading && servicesLoading ? (
    <Skeleton active />
  ) : (
    <div>
      <Spin spinning={submitted}>
        <Form
          layout="vertical"
          form={form}
          onFieldsChange={onFieldsChange}
          onFinish={onSubmit}
        >
          <div className="form_section_header">{WORKFLOW_TYPE_INFORMATION}</div>
          <Row gutter={GUTTER}>
            <Col span="12">
              <FormItem
                name="service_id"
                label={LABEL.SERVICE}
                rules={[{ required: true }]}
              >
                <SearchableSelect
                  optionFilter="key"
                  placeholder={PLACEHOLDER.SELECT_SERVICE}
                >
                  {servicesLoading ? (
                    <Option value={''} disabled>
                      <Spin spinning size="small" className="full-width" />
                    </Option>
                  ) : (
                    services &&
                    services?.map((item) => {
                      return (
                        <Option value={item.id || ''} key={item.id}>
                          {item.name}
                        </Option>
                      );
                    })
                  )}
                </SearchableSelect>
              </FormItem>
            </Col>
            <Col span="12">
              <Form.Item
                name="name"
                label={LABEL.WORKFLOW_TYPE_NAME}
                rules={[{ required: true }]}
              >
                <Input placeholder={PLACEHOLDER.WORKFLOW_TYPE} />
              </Form.Item>
            </Col>
          </Row>
        </Form>
      </Spin>
    </div>
  );
};

export { WorkflowTypeForm };
