import { useEffect } from 'react';
import { otherDetailActions, SETTING_OTHER_DETAILS } from '@lyra/core';
import { useDispatch, useSelector } from 'react-redux';
import { IOtherDetailsCategory } from '@shared-components/models';
import { CONFIRM_MESSAGE, DELETE_BTN, STATUS } from '@moxie/constants';
import { popConfirm } from '@shared-components/elements';

const useDetailsCategory = () => {
  const dispatch = useDispatch();
  const { categoryList, categoryStatus, selectedCategoryId } = useSelector(
    (store: any) => ({
      categoryList: store[SETTING_OTHER_DETAILS].categories,
      categoryStatus: store[SETTING_OTHER_DETAILS].categoryStatus,
      selectedCategoryId: store[SETTING_OTHER_DETAILS].selectedCategory,
    })
  );

  useEffect(() => {
    dispatch(otherDetailActions.getCategoriesRequest());
  }, []);

  useEffect(() => {
    if (categoryStatus === STATUS.SUCCEEDED)
      dispatch(otherDetailActions.categoryRequestIdle());
    else if (categoryStatus === STATUS.FAILED)
      dispatch(otherDetailActions.categoryRequestIdle());
  }, [categoryStatus]);

  useEffect(() => {
    if (!selectedCategoryId || selectedCategoryId === '')
      dispatch(otherDetailActions.selectCategory(categoryList[0]?.id || ''));
  }, [categoryList]);

  const addCategory = (category: IOtherDetailsCategory) => {
    return dispatch(otherDetailActions.addCategoryRequest(category));
  };

  const onDeleteCategory = (id: string) => {
    popConfirm(DELETE_BTN, CONFIRM_MESSAGE.DELETE_OTHER_DETAILS_CATEGORY, () =>
      dispatch(otherDetailActions.deleteCategoryRequest(id))
    );
  };

  const onEditCategory = (data: IOtherDetailsCategory) => {
    dispatch(otherDetailActions.editCategoryRequest(data));
  };

  const onSelectCategory = (id: string) => {
    dispatch(otherDetailActions.selectCategoryRequest(id));
  };

  return {
    categoryList,
    selectedCategoryId,
    onSelectCategory,
    addCategory,
    onDeleteCategory,
    onEditCategory,
    status: categoryStatus,
  };
};

export { useDetailsCategory };
