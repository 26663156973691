/* eslint-disable no-unused-vars */
import React, { useState, useEffect } from 'react';
import { PlusOutlined } from '@ant-design/icons';
import { useParams } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import { ADD_APPOINTMENT_BTN } from '@moxie/constants';
import { Button } from '@shared-components/elements';
import { LeadAppointmentDrawer } from '../../leads-appointment/appointment-drawer';
import AppointmentContent from '../../leads-appointment';
import * as appointmentActions from 'apps/lyra/src/core/lead-appointments/lead-appointments.action';
import { RootState } from 'apps/lyra/src/core/store';
import { useAppSelector } from '@lyra/core';
import Can, { PermissionAction, PermissionObjects } from '@lyra/privilege';
import { useForm } from 'antd/lib/form/Form';
import { ILeadAppointmentArchive } from '@shared-components/models';
const LeadAppointment: React.FC<ILeadAppointmentArchive> = ({
  archived,
}: ILeadAppointmentArchive) => {
  const [visible, setVisible] = useState(false);
  const [refreshContent, setRefreshContent] = useState(false);
  const { id } = useParams<{ id: string }>();
  const [disabled, setDisabled] = useState(true);
  const [form] = useForm();

  const dispatch = useDispatch();
  const currentUser: any = useAppSelector(
    (store: RootState) => store.auth.user
  );

  const { appointment, pastData, upcomingData } = useAppSelector(
    (store: RootState) => ({
      appointment: store.lead_appointment.appointmentData,
      pastData: store.lead_appointment.pastData,
      upcomingData: store.lead_appointment.upcomingData,
    })
  );

  const handleClick = () => {
    setVisible(true);
  };

  const triggerRefresh = () => {
    setRefreshContent((prev) => !prev);
  };

  const handleClose = () => {
    setDisabled(true);
    setVisible(false);
    form.resetFields();
  };

  useEffect(() => {
    dispatch(appointmentActions.getLeadAppointmentsRequest(id));
  }, [refreshContent, id, currentUser]);

  return (
    <div>
      <div className="slider_content">
        {
          // Can(PermissionAction.Create, PermissionObjects.LeadAppointment) &&
          !archived && (
            <Button
              type="primary"
              icon={<PlusOutlined />}
              onClick={handleClick}
              className="margin-top-1 margin-right-1"
            >
              {ADD_APPOINTMENT_BTN}
            </Button>
          )
        }
        <LeadAppointmentDrawer
          isOpen={visible}
          onClose={handleClose}
          appointmentData={appointment && appointment}
          triggerRefresh={triggerRefresh}
          form={form}
          disabled={disabled}
          setDisabled={setDisabled}
        />
      </div>
      <AppointmentContent
        currentUser={currentUser && currentUser}
        appointmentData={appointment && appointment}
        upcoming={upcomingData}
        past={pastData}
        triggerRefresh={triggerRefresh}
        archived={archived}
      />
    </div>
  );
};

export default LeadAppointment;
