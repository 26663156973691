import { API_URL } from "@moxie/constants"
import { IResponse, ProductFeeFieldValues, ProductFeeValidation } from "@model/data.model";
import crmAxios from "./crm-api-axios";
import { CrmPaginatedResponse } from "@model/api-response.model";
import { IProductFeeCrm } from "apps/crm/src/pages/products/product.model";


const fetchProductFees = async (productId: string, page: number) => {
  const { data } = await crmAxios.get<CrmPaginatedResponse<IProductFeeCrm>>(`${API_URL.CRM_PRODUCT_FEE}`, {
    params: {
      page,
      limit: 10,
      'filter.productId': productId
    }
  })
  return data?.data;
}

const addProductFee = async (productId: string, body: ProductFeeFieldValues) => {
  const { data } = await crmAxios.post<IResponse>(API_URL.CRM_PRODUCT_FEE, {
    ...body,
    productId,
  })
  return data;
}

const updateProductFee = async (fee: string, productId: string, body: ProductFeeFieldValues) => {
  const { data } = await crmAxios.put<IResponse>(`${API_URL.CRM_PRODUCT_FEE}/${fee}`, { ...body, productId });
  return data;
}

const validateProductFeeName = async (params: ProductFeeValidation) => crmAxios.get<IResponse>(`${API_URL.CRM_PRODUCT_FEE}/validate-name`, { params })

const deleteProductFee = (feeId: string) => crmAxios.delete<IResponse>(`${API_URL.CRM_PRODUCT_FEE}/${feeId}`);

export {
  fetchProductFees,
  validateProductFeeName,
  addProductFee,
  deleteProductFee,
  updateProductFee,
}
