import React, { useEffect } from 'react';
import {
  Col,
  Input,
  InputNumber,
  Row,
  Skeleton,
  Space,
  Typography,
} from 'antd';
import { IForm, ITestTypeForForm } from '@shared-components/models';
import Form from 'antd/lib/form';
import { GUTTER, LABEL, regex } from '@moxie/constants';
import { useParams } from 'react-router-dom';
import { useAppSelector } from '@admin/core';

const { Text } = Typography;

const LanguageTestScoreFormAdmin: React.FC<IForm<ITestTypeForForm>> = ({
  form,
  name,
  onSubmit,
  onFieldsChange,
}: IForm<ITestTypeForForm>) => {
  const { id } = useParams<{ id: string }>();
  const loading = useAppSelector((state) => state.product.language_loading);

  const handleSubmit = async (data: ITestTypeForForm) => {
    data.test_name = name;
    data.product_id = id;
    onSubmit && onSubmit(data);
  };

  return loading ? (
    <Skeleton active />
  ) : (
    <Form
      layout="vertical"
      onFinish={handleSubmit}
      form={form}
      onFieldsChange={onFieldsChange}
    >
      <Col span={3} className="align-vertically-centered">
        <Text strong>{name?.toUpperCase()}</Text>
      </Col>
      <Row gutter={GUTTER} className="margin-top-2">
        <Col span={12}>
          <Form.Item
            name={['each_bands', 'reading']}
            label={LABEL.READING}
            rules={[
              {
                type: 'number',
                min: 0,
                max: name === 'IELTS' ? 9 : name === 'PTE' ? 90 : 30,
              },
            ]}
          >
            <InputNumber className="full-width" />
          </Form.Item>
        </Col>
        <Col span={12}>
          <Form.Item
            name={['each_bands', 'listening']}
            label={LABEL.LISTENING}
            rules={[
              {
                type: 'number',
                min: 0,
                max: name === 'IELTS' ? 9 : name === 'PTE' ? 90 : 30,
              },
            ]}
          >
            <InputNumber className="full-width" />
          </Form.Item>
        </Col>
      </Row>

      <Row gutter={GUTTER}>
        <Col span={12}>
          <Form.Item
            name={['each_bands', 'writing']}
            label={LABEL.WRITING}
            rules={[
              {
                type: 'number',
                min: 0,
                max: name === 'IELTS' ? 9 : name === 'PTE' ? 90 : 30,
              },
            ]}
          >
            <InputNumber className="full-width" />
          </Form.Item>
        </Col>

        <Col span={12}>
          <Form.Item
            name={['each_bands', 'speaking']}
            label={LABEL.SPEAKING}
            rules={[
              {
                type: 'number',
                min: 0,
                max: name === 'IELTS' ? 9 : name === 'PTE' ? 90 : 30,
              },
            ]}
          >
            <InputNumber className="full-width" />
          </Form.Item>
        </Col>
        <Col span={24}>
          <Form.Item
            name="overall_score"
            label={LABEL.OVERALL_SCORE}
            rules={[
              {
                type: 'number',
                min: 0,
                max: name === 'IELTS' ? 9 : name === 'PTE' ? 90 : 30,
              },
              {
                required: true,
              },
            ]}
          >
            <InputNumber className="full-width" />
          </Form.Item>
        </Col>
        <Col span={24}>
          <Form.Item name="remark" label={LABEL.REMARK}>
            <Input.TextArea rows={4} className="full-width" />
          </Form.Item>
        </Col>
      </Row>
    </Form>
  );
};

export { LanguageTestScoreFormAdmin };
