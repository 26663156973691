import { call, ForkEffect, put, takeLatest } from 'redux-saga/effects';
import axios from '../../libs/services.api/lib/axios';
import Cookies from 'js-cookie';
import {
  COOKIE_ACCESS,
  JWT_TOKEN_HEADER,
  ROLE_RESPONSE_MESSAGE,
} from '@moxie/constants';
import { errorHandler } from '@moxie/utils';
import { RolesApi } from '@lyra/services.api';
import { AxiosResponse } from 'axios';
import { successNotificationHandler } from 'libs/shared/src/shared';
import * as actionTypes from './role-permission.constant';
import * as rolePermissionActions from './role-permission.action';

export const setAuthToken = (token = Cookies.get(COOKIE_ACCESS)): any => {
  if (token) {
    axios.defaults.headers.common[
      'Authorization'
    ] = `${JWT_TOKEN_HEADER} ${token}`;
  } else {
    delete axios.defaults.headers.common['Authorization'];
  }
};

function* handleAddRole(data: any) {
  try {
    setAuthToken();
    // eslint-disable-next-line no-unused-vars
    const { id, ...rest } = data.payload.data;
    yield call(RolesApi.addRoleApi, rest);
    data.payload.triggerRefresh && data.payload.triggerRefresh();
    successNotificationHandler(ROLE_RESPONSE_MESSAGE.ROLE_ADDED);
  } catch (err) {
    errorHandler(err);
  }
}

function* handleUpdateRole(data: any) {
  try {
    setAuthToken();
    const { id, permissions, ...rest } = data.payload.data;
    if (permissions.length > 0) {
      rest.permissions = permissions;
    }
    yield call(RolesApi.updateRoleApi, rest, id);
    data.payload.triggerRefresh && data.payload.triggerRefresh();
    successNotificationHandler(ROLE_RESPONSE_MESSAGE.ROLE_UPDATE);
  } catch (err) {
    errorHandler(err);
  }
}

function* deleteRole(data: any) {
  try {
    setAuthToken();
    yield call(RolesApi.deleteRoleApi, data.payload.id);
    successNotificationHandler(ROLE_RESPONSE_MESSAGE.ROLE_DELETE);
    data.payload.triggerRefresh && data.payload.triggerRefresh();
  } catch (err) {
    errorHandler(err);
  }
}

function* getPermissions() {
  try {
    setAuthToken();
    const res: AxiosResponse = yield call(RolesApi.getPermissionsApi);
    yield put(rolePermissionActions.getPermissionsSuccess(res.data.data));
  } catch (err) {
    errorHandler(err);
  }
}

function* setRole(data: any) {
  try {
    setAuthToken();
    const res: AxiosResponse = yield call(RolesApi.setRolesApi, data);
    yield put(rolePermissionActions.getRoleSuccess(res.data.data));
  } catch (err) {
    errorHandler(err);
  }
}

function* getRoles() {
  try {
    setAuthToken();
    const res: AxiosResponse = yield call(RolesApi.getRolesApi);
    yield put(rolePermissionActions.getRolesSuccess(res.data.data));
  } catch (err) {
    errorHandler(err);
  }
}

export default function* rolePermissionSaga(): Generator<
  ForkEffect<never>,
  void,
  unknown
> {
  yield takeLatest(actionTypes.ADD_ROLE_REQUEST, handleAddRole);
  yield takeLatest(actionTypes.UPDATE_ROLE_REQUEST, handleUpdateRole);
  yield takeLatest(actionTypes.DELETE_ROLE_REQUEST, deleteRole);
  yield takeLatest(actionTypes.GET_PERMISSIONS_REQUEST, getPermissions);
  yield takeLatest(actionTypes.GET_ROLE_REQUEST, setRole);
  yield takeLatest(actionTypes.GET_ROLES_REQUEST, getRoles);
}
