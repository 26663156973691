import { useAppSelector } from "@admin/core";
import { useCallback } from "react";

const allowedUsers = [
  'sameer@experteducation.com.au',
  'utsav.sitaula@experteducation.com.au',
  'it@experteducation.com.au',
  'prajit.baniya@experteducation.com.au',
  'info@xprt.com',
  'one@xprt.com',
  'info@everest.com',
  'info@idea.com',
  'info@global.com',
  'cont@gmail.com',
  'indradev+2@heubert.com',
  'sairose+1@heubert.com',
  'sairose@heubert.com',
  'tenant1@heubert.com',
  'cont@gmail.com',
  'developers@heubert.com',
  'info@expert.com',
];

type Resource = 'contact-enquiry' | 'branch' | 'user' | 'service' | 'product' | 'product-fee' | 'product-requirement' | 'contact' | 'agent' | 'institution';
type Permission = 'all' | 'create' | 'read' | 'update' | 'delete';

export const useCanAccess = () => {
  const user = useAppSelector(store => store.auth.user);

  return useCallback((resource: Resource, ...permissions: Permission[]) => {
    // TODO:: implement permission once the permission is integrated from backend
    return Boolean(user && !!allowedUsers.includes(user.email.toLowerCase()));
  },[user])

}

