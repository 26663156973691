import React from 'react';
import { Typography } from 'antd';
import { CloseOutlined } from '@ant-design/icons';
import { Button } from '@shared-components/elements';
import { IProductComparisonListItemProps } from '@shared-components/models';

const { Text, Title } = Typography;
const ProductComparisonListItem: React.FC<IProductComparisonListItemProps> = ({
  id,
  name,
  location,
  onDelete,
}: IProductComparisonListItemProps) => {
  return (
    <div className="compare-item">
      <div className="header">
        <Title level={5} className="title">
          {name || ''}
        </Title>
        <Button
          onClick={() => onDelete(id)}
          className="close-btn"
          type="link"
          size="small"
        >
          <CloseOutlined />
        </Button>
      </div>
      <Text>
        <small>{location || ''}</small>
      </Text>
    </div>
  );
};

export { ProductComparisonListItem };
