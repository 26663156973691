const alias = 'LEAD_OTHER_DETAIL_VALUE';

export const GET_LEAD_OTHER_DETAIL_VALUES_REQUEST = `GET_${alias}S_REQUEST`;
export const GET_LEAD_OTHER_DETAIL_VALUES_SUCCESS = `GET_${alias}S_SUCCESS`;
export const GET_LEAD_OTHER_DETAIL_VALUES_FAILURE = `GET_${alias}_FAILURE`;
export const GET_LEAD_OTHER_DETAIL_VALUES_CLEAR = `GET_${alias}S_CLEAR`;

export const GET_LEAD_OTHER_DETAIL_VALUE_REQUEST = `GET_${alias}_REQUEST`;
export const GET_LEAD_OTHER_DETAIL_VALUE_SUCCESS = `GET_${alias}_SUCCESS`;
export const GET_LEAD_OTHER_DETAIL_VALUE_FAILURE = `GET_${alias}_FAILURE`;

export const ADD_LEAD_OTHER_DETAIL_VALUE_REQUEST = `ADD_${alias}_REQUEST`;
export const ADD_LEAD_OTHER_DETAIL_VALUE_SUCCESS = `ADD_${alias}_SUCCESS`;
export const ADD_LEAD_OTHER_DETAIL_VALUE_FAILURE = `ADD_${alias}_FAILURE`;

export const UPDATE_LEAD_OTHER_DETAIL_VALUE_REQUEST = `UPDATE_${alias}_REQUEST`;
export const UPDATE_LEAD_OTHER_DETAIL_VALUE_SUCCESS = `UPDATE_${alias}_SUCCESS`;
export const UPDATE_LEAD_OTHER_DETAIL_VALUE_FAILURE = `UPDATE_${alias}_FAILURE`;

export const DELETE_LEAD_OTHER_DETAIL_VALUE_REQUEST = `DELETE_${alias}_REQUEST`;
export const DELETE_LEAD_OTHER_DETAIL_VALUE_SUCCESS = `DELETE_${alias}_SUCCESS`;
export const DELETE_LEAD_OTHER_DETAIL_VALUE_FAILURE = `DELETE_${alias}_FAILURE`;
