import { fetchEnquiryDetails } from "@crm/services.api";
import { useQuery } from "@tanstack/react-query";

export const useFetchEnquiryDetails = (enquiryId: string) => {
  const { data, isLoading, refetch } = useQuery({
    queryKey: ['fetch-enquiry-details', enquiryId],
    queryFn: () => fetchEnquiryDetails(enquiryId),
    enabled: !!enquiryId,
  });

  return { enquiryData: data?.data, isLoading, refetch };
}
