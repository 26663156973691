const alias = 'APPLICATION';
const comment_alias = 'APPLICATION_COMMENT';

export const GET_APPLICATIONS_REQUEST = `GET_${alias}S_REQUEST`;
export const GET_APPLICATIONS_SUCCESS = `GET_${alias}S_SUCCESS`;
export const GET_APPLICATIONS_FAILURE = `GET_${alias}S_FAILURE`;

export const GET_APPLICATION_REQUEST = `GET_${alias}_REQUEST`;
export const GET_APPLICATION_SUCCESS = `GET_${alias}_SUCCESS`;
export const GET_APPLICATION_FAILURE = `GET_${alias}_FAILURE`;
export const CLEAR_APPLICATION_FETCH = `CLEAR_${alias}_FETCH`;

export const ADD_APPLICATION_REQUEST = `ADD_${alias}_REQUEST`;
export const ADD_APPLICATION_SUCCESS = `ADD_${alias}_SUCCESS`;
export const ADD_APPLICATION_FAILURE = `ADD_${alias}_FAILURE`;

export const UPDATE_APPLICATION_REQUEST = `UPDATE_${alias}_REQUEST`;
export const UPDATE_APPLICATION_SUCCESS = `UPDATE_${alias}_SUCCESS`;
export const UPDATE_APPLICATION_FAILURE = `UPDATE_${alias}_FAILURE`;

export const DELETE_APPLICATION_REQUEST = `DELETE_${alias}_REQUEST`;
export const DELETE_APPLICATION_SUCCESS = `DELETE_${alias}_SUCCESS`;
export const DELETE_APPLICATION_FAILURE = `DELETE_${alias}_FAILURE`;

export const GET_APPLICATION_COMMENT_REQUEST = `GET_${comment_alias}_REQUEST`;
export const GET_APPLICATION_COMMENT_SUCCESS = `GET_${comment_alias}S_SUCCESS`;
export const GET_APPLICATION_COMMENT_FAILURE = `GET_${comment_alias}S_FAILURE`;

export const ADD_APPLICATION_COMMENT_REQUEST = `ADD_${comment_alias}_REQUEST`;
export const ADD_APPLICATION_COMMENT_SUCCESS = `ADD_${comment_alias}_SUCCESS`;
export const ADD_APPLICATION_COMMENT_FAILURE = `ADD_${comment_alias}_FAILURE`;

export const SUCCEEDED_APPLICATION = `SUCCEEDED_${alias}`;
export const IDLE_APPLICATION = `IDLE_${alias}`;
export const SET_ERROR_APPLICATIONS = `SET_ERROR_${alias}`;
