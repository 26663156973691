import React, { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { useParams } from 'react-router-dom';
import { applicationActions } from '@lyra/core';
import { APPLICATION_ACTORS } from '@moxie/constants';
import {
  ApplicationDetail,
  ApplicationProvider,
  LeadApplicationList,
} from '@moxie/shared';
import { IURLParams } from '@shared-components/models';

const LeadApplication: React.FC<any> = () => {
  const dispatch = useDispatch();
  const params: IURLParams = useParams();
  const applicationId = params?.slug2;

  useEffect(() => {
    if (applicationId) {
      dispatch(applicationActions.getOneRequest(applicationId));
    }
  }, [applicationId]);

  return applicationId ? (
    <ApplicationProvider>
      <ApplicationDetail type={APPLICATION_ACTORS.CLIENT} />
    </ApplicationProvider>
  ) : (
    <LeadApplicationList />
  );
};

export { LeadApplication };
