import React, { useEffect, useState } from 'react';
import { Col, Divider, InputNumber, Row, Select, Typography } from 'antd';
import { GUTTER, LABEL, TITLE } from '@moxie/constants';
import { FormItem, SearchableSelect } from '@shared-components/elements';
import { ILeadEnglishTestScoreProps } from '@shared-components/models';

const { Option } = Select;
const { Title } = Typography;

const LeadFormEnglishTestScore: React.FC<ILeadEnglishTestScoreProps> = ({
  englishTestScoreData,
}: ILeadEnglishTestScoreProps) => {
  const [selectedType, setSelectedType] = useState<string>('');
  const [testTypes, setTestTypes] = useState<{ key: string; value: string }[]>(
    []
  );

  useEffect(() => {
    if (englishTestScoreData?.ielts) {
      setTestTypes((initial) => [...initial, { value: 'IELTS', key: 'ielts' }]);
    }
    if (englishTestScoreData?.pte) {
      setTestTypes((initial) => [...initial, { value: 'PTE', key: 'pte' }]);
    }
    if (englishTestScoreData?.toefl) {
      setTestTypes((initial) => [...initial, { value: 'TOEFL', key: 'toefl' }]);
    }
  }, []);

  return (
    <div className="created-leadForm-section ">
      <Title level={4}>{TITLE.ENGLISH_TEST_SCORES}</Title>
      <SearchableSelect
        className="language-select margin-bottom-2 margin-top-1"
        placeholder="Select Test Type"
        onChange={(selectValue: string | any) => setSelectedType(selectValue)}
      >
        {testTypes &&
          testTypes.map((item: { key: string; value: string }) => {
            return (
              <Option value={item.key} key={item.key}>
                {item.value}
              </Option>
            );
          })}
      </SearchableSelect>
      <Row gutter={GUTTER}>
        {selectedType === 'toefl' && (
          <>
            <Col>
              <FormItem
                name={['englishTestScores', 'toefl', 'listening']}
                label={LABEL.LISTENING}
                rules={[
                  { type: 'number', min: 1, max: 30 },
                  { required: true },
                ]}
              >
                <InputNumber className="full-width" />
              </FormItem>
            </Col>
            <Col>
              <FormItem
                name={['englishTestScores', 'toefl', 'reading']}
                label={LABEL.READING}
                rules={[
                  { type: 'number', min: 1, max: 30 },
                  { required: true },
                ]}
              >
                <InputNumber className="full-width" />
              </FormItem>
            </Col>
            <Col>
              <FormItem
                name={['englishTestScores', 'toefl', 'writing']}
                label={LABEL.WRITING}
                rules={[
                  { type: 'number', min: 1, max: 30 },
                  { required: true },
                ]}
              >
                <InputNumber className="full-width" />
              </FormItem>
            </Col>
            <Col>
              <FormItem
                name={['englishTestScores', 'toefl', 'speaking']}
                label={LABEL.SPEAKING}
                rules={[
                  { type: 'number', min: 1, max: 30 },
                  { required: true },
                ]}
              >
                <InputNumber className="full-width" />
              </FormItem>
            </Col>
          </>
        )}
        {selectedType === 'ielts' && (
          <>
            <Col>
              <FormItem
                name={['englishTestScores', 'ielts', 'listening']}
                label={LABEL.LISTENING}
                rules={[{ type: 'number', min: 1, max: 9 }, { required: true }]}
              >
                <InputNumber className="full-width" />
              </FormItem>
            </Col>
            <Col>
              <FormItem
                name={['englishTestScores', 'ielts', 'reading']}
                label={LABEL.READING}
                rules={[{ type: 'number', min: 1, max: 9 }, { required: true }]}
              >
                <InputNumber className="full-width" />
              </FormItem>
            </Col>
            <Col>
              <FormItem
                name={['englishTestScores', 'ielts', 'writing']}
                label={LABEL.WRITING}
                rules={[{ type: 'number', min: 1, max: 9 }, { required: true }]}
              >
                <InputNumber className="full-width" />
              </FormItem>
            </Col>
            <Col>
              <FormItem
                name={['englishTestScores', 'ielts', 'speaking']}
                label={LABEL.SPEAKING}
                rules={[{ type: 'number', min: 1, max: 9 }, { required: true }]}
              >
                <InputNumber className="full-width" />
              </FormItem>
            </Col>
          </>
        )}
        {selectedType === 'pte' && (
          <>
            <Col>
              <FormItem
                name={['englishTestScores', 'pte', 'overall_score']}
                label={LABEL.OVERALL_SCORE}
                rules={[
                  { type: 'number', min: 1, max: 90 },
                  { required: true },
                ]}
              >
                <InputNumber className="full-width" />
              </FormItem>
            </Col>
            <Col>
              <FormItem
                name={['englishTestScores', 'pte', 'listening']}
                label={LABEL.LISTENING}
                rules={[
                  { type: 'number', min: 1, max: 90 },
                  { required: true },
                ]}
              >
                <InputNumber className="full-width" />
              </FormItem>
            </Col>
            <Col>
              <FormItem
                name={['englishTestScores', 'pte', 'reading']}
                label={LABEL.READING}
                rules={[
                  { type: 'number', min: 1, max: 90 },
                  { required: true },
                ]}
              >
                <InputNumber className="full-width" />
              </FormItem>
            </Col>
            <Col>
              <FormItem
                name={['englishTestScores', 'pte', 'writing']}
                label={LABEL.WRITING}
                rules={[
                  { type: 'number', min: 1, max: 90 },
                  { required: true },
                ]}
              >
                <InputNumber className="full-width" />
              </FormItem>
            </Col>
            <Col>
              <FormItem
                name={['englishTestScores', 'pte', 'speaking']}
                label={LABEL.SPEAKING}
                rules={[
                  { type: 'number', min: 1, max: 90 },
                  { required: true },
                ]}
              >
                <InputNumber className="full-width" />
              </FormItem>
            </Col>
          </>
        )}
      </Row>
      <Divider plain />
    </div>
  );
};
export { LeadFormEnglishTestScore };
