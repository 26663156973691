import React, { useEffect } from 'react';
import { Card, Typography } from 'antd';
import { Login as LoginForm } from '@shared-components/forms';
import { ApplyIMSLogoDark } from '@shared-components/elements';
import { useDispatch, useSelector } from 'react-redux';
import { ILogin as ILoginModal } from '@shared-components/models';
import { authActions } from '@lyra/core';
import { RootState } from 'apps/lyra/src/core/store';
import { useHistory } from 'react-router-dom';
import { URL_DASHBOARD } from '@moxie/constants';

const { Title } = Typography;

const Login: React.FC = () => {
  const [isAuthenticated, loading] = useSelector((store: RootState) => {
    return [store.auth.isAuthenticated, store.auth.loading];
  });
  const history = useHistory();

  useEffect(() => {
    if (isAuthenticated && !loading) history.push(URL_DASHBOARD);
  }, [isAuthenticated, loading, history]);

  const dispatch = useDispatch();

  const handleSubmit = async (data: ILoginModal) => {
    data.email = data.email.toLocaleLowerCase().trim();
    dispatch(authActions.login(data));
  };

  return (
    <section className={`auth-layout auth-layout--login`}>
      <div className="auth-layout__card">
        <Card className="padding-2">
          <div className="text-align-center padding-bottom-1">
            <ApplyIMSLogoDark />
            <Title level={3}>Sign in to your account</Title>
          </div>
          <LoginForm onSubmit={handleSubmit} />
        </Card>
      </div>
    </section>
  );
};

export { Login };
