/* eslint-disable no-unused-vars */
import { createSlice } from '@reduxjs/toolkit';
import {
  IProfessionalExperience,
  IStatus,
  IAction,
} from '@shared-components/models';
import { STATUS } from '@moxie/constants';
import * as actionTypes from './professional-experience.constant';

export const PROFESSIONAL_EXPERIENCE_FEATURE_KEY = 'professional_experience';

export interface IProfessionalExperienceState {
  professionalExperienceList: IProfessionalExperience[];
  status: IStatus;
  error: boolean | null;
  loading: boolean;
}

export const initialProfessionalExperienceState: IProfessionalExperienceState = {
  professionalExperienceList: [],
  status: STATUS.IDLE,
  error: null,
  loading: false,
};

export const professionalExperienceReducer = (
  state = initialProfessionalExperienceState,
  action: IAction<any>
) => {
  switch (action.type) {
    case actionTypes.GET_PROFESSIONAL_EXPERIECES_REQUEST:
      return {
        ...state,
        loading: true,
      };

    case actionTypes.GET_PROFESSIONAL_EXPERIECES_SUCCESS:
      return {
        ...state,
        loading: false,
        professionalExperienceList: [...action.payload],
      };

    case actionTypes.GET_PROFESSIONAL_EXPERIECES_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.payload,
      };
    case actionTypes.GET_PROFESSIONAL_EXPERIECES_CLEAR:
      return {
        ...state,
        loading: false,
        professionalExperienceList: [],
        error: null,
      };

    case actionTypes.DELETE_PROFESSIONAL_EXPERIECES_REQUEST:
      return { ...state, loading: true };

    case actionTypes.DELETE_PROFESSIONAL_EXPERIECES_SUCCESS:
      return {
        ...state,
        loading: false,
        professionalExperienceList: state.professionalExperienceList.filter(
          (item) => item.id !== action.payload
        ),
      };

    case actionTypes.DELETE_PROFESSIONAL_EXPERIECES_FAILURE:
      return { ...state, error: action.payload };

    case actionTypes.ADD_PROFESSIONAL_EXPERIECES_REQUEST:
      return {
        ...state,
        loading: true,
      };
    case actionTypes.ADD_PROFESSIONAL_EXPERIECES_SUCCESS:
      return {
        ...state,
        loading: false,
        professionalExperienceList: [
          action.payload,
          ...state.professionalExperienceList,
        ],
      };
    case actionTypes.ADD_PROFESSIONAL_EXPERIECES_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.payload,
      };

    case actionTypes.UPDATE_PROFESSIONAL_EXPERIECES_REQUEST:
      return {
        ...state,
        loading: true,
      };
    case actionTypes.UPDATE_PROFESSIONAL_EXPERIECES_SUCCESS:
      return {
        ...state,
        loading: false,
        professionalExperienceList: state.professionalExperienceList.map(
          (item) => (item.id === action.payload.id ? action.payload : item)
        ),
      };
    case actionTypes.UPDATE_PROFESSIONAL_EXPERIECES_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.payload,
      };
    default:
      return state;
  }
};

export default professionalExperienceReducer;
