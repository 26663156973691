import { IAction } from '@shared-components/models';
import { AxiosResponse } from 'axios';
import { put, takeLatest } from 'redux-saga/effects';
import * as actionTypes from './lead.action';
import * as action from './lead.constant';
import { successNotificationHandler } from '@moxie/shared';
import {
  deleteLead,
  postLead,
  updateLead,
  archiveLead,
} from '@crm/services.api';
import { LEAD_RESPONSE_MESSAGE } from '@moxie/constants';
import { crmErrorHandler } from 'libs/shared/src/crm-modules/shared';
import { IContactCrm } from '@model/crm/contact.model';

export const addLead = function* ({
  payload,
  triggerRefresh,
}: IAction<IContactCrm>) {
  try {
    if (payload) {
      const response: AxiosResponse = yield postLead(payload);
      yield put(actionTypes.addLeadSuccess(response.data.data));
      successNotificationHandler(LEAD_RESPONSE_MESSAGE.CREATED);
      triggerRefresh && triggerRefresh(response.data);
    }
  } catch (err) {
    crmErrorHandler(err);
    yield put(actionTypes.addLeadFailure(err));
  }
};

export const deleteLeadData = function* ({
  payload,
  triggerRefresh,
}: IAction<any>) {
  try {
    if (payload) {
      const { id, data } = payload;
      yield deleteLead(id, data);
      successNotificationHandler(LEAD_RESPONSE_MESSAGE.LEAD_DELETE);
      triggerRefresh && triggerRefresh();
    }
  } catch (err) {
    crmErrorHandler(err);
    yield put(actionTypes.deleteLeadFailure(err));
  }
};

export const archiveLeadData = function* ({
  payload,
  triggerRefresh,
}: IAction<any>) {
  try {
    if (payload) {
      const response: AxiosResponse = yield archiveLead(payload?.id, payload?.data);
      successNotificationHandler(LEAD_RESPONSE_MESSAGE.LEAD_ARCHIVED);
      triggerRefresh && triggerRefresh?.(response.data);
    }
  } catch (err) {
    crmErrorHandler(err);
    yield put(actionTypes.archiveLeadFailure(err));
  }
};

export const unarchiveLeadData = function* ({
  payload,
  triggerRefresh,
}: IAction<any>) {
  try {
    if (payload) {
      const response: AxiosResponse = yield archiveLead(payload?.id, payload?.data);
      successNotificationHandler(LEAD_RESPONSE_MESSAGE.LEAD_UNARCHIVED);
      triggerRefresh && triggerRefresh?.(response.data);
    }
  } catch (err) {
    crmErrorHandler(err);
    yield put(actionTypes.unarchiveLeadFailure(err));
  }
};


function* editLead({ payload, triggerRefresh }: IAction<IContactCrm>) {
  try {
    if (payload) {
      const response: AxiosResponse = yield updateLead(payload);
      yield put({ type: actionTypes.editLeadSuccess, payload: response.data });
      payload.status === "Lead" ?
        successNotificationHandler(LEAD_RESPONSE_MESSAGE.LEAD_UPDATED) :
        successNotificationHandler("Client updated successfully");
      triggerRefresh && triggerRefresh(response.data);
    }
  } catch (e) {
    crmErrorHandler(e);
    yield put({ type: actionTypes.editLeadFailure(e) });
  }
}

const leadSaga = function* (): unknown {
  yield takeLatest(action.ADD_LEAD_REQUEST, addLead);
  yield takeLatest(action.DELETE_LEAD_REQUEST, deleteLeadData);
  yield takeLatest(action.UPDATE_LEAD_REQUEST, editLead);
  yield takeLatest(action.ARCHIVE_LEAD_REQUEST, archiveLeadData);
  yield takeLatest(action.UNARCHIVE_LEAD_REQUEST, unarchiveLeadData);

};

export default leadSaga;
