import { COLUMNS, LABEL, TEXT } from '@moxie/constants';
import { ILead, IUser, IWorkflow } from '@shared-components/models';
import moment from 'moment';
import { formatDateWithTimezone } from '@moxie/shared';

const ExportReportColumns = (userTimeZone: string) => {
  return [
    {
      title: TEXT.ID,
      dataIndex: 'app_identifier',
      render(app_identifier: string, data: any): string {
        return `${data?.company?.company_code || ''}-${app_identifier || ''}`;
      },
    },

    {
      title: LABEL.ASSIGNEE,
      dataIndex: 'agent_clients',
    },

    {
      title: LABEL.CLIENT_ID,
      dataIndex: 'client',
      render(data: ILead, row: any): string {
        return `${row?.company?.company_code || ''}-${data?.internal_id || ''}`;
      },
    },

    {
      title: COLUMNS.CONTACT_NAME,
      dataIndex: 'client',
      render(row: ILead): string {
        return `${row?.first_name || ''} ${row?.last_name || ''}`;
      },
    },

    {
      title: TEXT.PHONE,
      dataIndex: 'client',
      render: (data: ILead): string => {
        return `${data?.country_code || ''} ${data?.phone || ''}`;
      },
    },

    {
      title: LABEL.EMAIL,
      dataIndex: ['client', 'email'],
    },

    {
      title: LABEL.DATE_OF_BIRTH,
      dataIndex: 'date_of_birth',
      render: (value: string) => {
        return value ? moment(value).format('MMM DD, YYYY') : '-';
      },
    },

    {
      title: LABEL.COUNTRY_OF_RESIDENCE,
      dataIndex: ['contact', 'country'],
    },

    {
      title: LABEL.COUNTRY_OF_PASSPORT,
      dataIndex: ['contact', 'country_of_passport'],
    },

    {
      title: LABEL.VISA_EXPIRY_DATE,
      dataIndex: 'visa_expiry_date',
      render: (value: string) => {
        return value ? moment(value).format('MMM DD, YYYY') : '-';
      },
    },

    {
      title: LABEL.ADDED_DATE,
      dataIndex: 'created_at',
      render: (value: string) => {
        return formatDateWithTimezone(value, userTimeZone);
      },
    },

    {
      title: LABEL.BRANCH,
      dataIndex: ['branch', 'name'],
    },

    {
      title: LABEL.SERVICE,
      dataIndex: ['institution', 'service', 'name'],
    },

    {
      title: LABEL.WORKFLOW,
      ellipsis: true,
      dataIndex: 'workflow',
      render(data: IWorkflow) {
        return `${data?.workflowType?.name || ''} (${data?.country || ''})`;
      },
    },

    {
      title: LABEL.APPLIED_INSTITUTION,
      dataIndex: ['institution', 'name'],
    },

    {
      title: LABEL.APPLIED_PRODUCT,
      dataIndex: ['product', 'name'],
    },

    {
      title: LABEL.STATUS,
      dataIndex: 'status',
    },

    {
      title: LABEL.START_DATE,
      dataIndex: 'start_date',
      render: (value: string): string => {
        return value ? moment(value).format('MMM DD, YYYY') : '-';
      },
    },

    {
      title: LABEL.END_DATE,
      dataIndex: 'end_date',
      render: (value: string): string => {
        return value ? moment(value).format('MMM DD, YYYY') : '-';
      },
    },

    {
      title: LABEL.SOURCE,
      dataIndex: 'client',
      render: (client: ILead): string => client?.source || '',
    },

    {
      title: TEXT.CREATED_BY,
      dataIndex: 'created_by',
      render: (data: IUser) => {
        return `${data?.first_name || ''} ${data?.last_name || ''}`;
      },
    },

    {
      title: LABEL.CURRENT_STAGE,
      dataIndex: ['activeStage', 'stage'],
    },

    {
      title: LABEL.LAST_UPDATED,
      dataIndex: 'updated_at',
      render: (data: string): string => {
        return formatDateWithTimezone(data, userTimeZone);
      },
    },
  ];
};

export { ExportReportColumns };
