import { IAction, IOtherTestScoreRootState } from '@shared-components/models';
import * as actionTypes from './other-test-score.constant';

export const OTHER_TEST_SCORE_FEATURE_KEY = 'other_test_score';

export const initialOtherTestScore: IOtherTestScoreRootState = {
  otherTestScoreList: {},
  loading: false,
  error: null,
};

export const otherTestScoreReducer = (
  state = initialOtherTestScore,
  action: IAction<any>
) => {
  switch (action.type) {
    case actionTypes.GET_OTHER_TEST_SCORES_REQUEST:
      return { ...state, loading: true };

    case actionTypes.GET_OTHER_TEST_SCORES_SUCCESS:
      return { ...state, loading: false, otherTestScoreList: action.payload };

    case actionTypes.GET_OTHER_TEST_SCORES_FAILURE:
      return { ...state, loading: false, error: action.payload };

    case actionTypes.GET_OTHER_TEST_SCORES_CLEAR:
      return { ...state, loading: false, error: null, otherTestScoreList: {} };

    case actionTypes.ADD_OTHER_TEST_SCORES_REQUEST:
      return { ...state, loading: true };
    case actionTypes.ADD_OTHER_TEST_SCORES_SUCCESS:
      return { ...state, loading: false, otherTestScoreList: action.payload };

    case actionTypes.ADD_OTHER_TEST_SCORES_FAILURE:
      return { ...state, loading: false, error: action.payload };

    case actionTypes.UPDATE_OTHER_TEST_SCORE_REQUEST:
      return { ...state, loading: true };

    case actionTypes.UPDATE_OTHER_TEST_SCORE_SUCCESS:
      return { ...state, loading: false, otherTestScoreList: action.payload };

    case actionTypes.UPDATE_OTHER_TEST_SCORE_FAILURE:
      return { ...state, loading: false, otherTestScoreList: action.payload };

    case actionTypes.DELETE_OTHER_TEST_SCORE_REQUEST:
      return { ...state, loading: true };

    case actionTypes.DELETE_OTHER_TEST_SCORE_SUCCESS:
      return {
        ...state,
        loading: false,
        otherTestScoreList: action.payload,
      };

    case actionTypes.DELETE_OTHER_TEST_SCORE_FAILURE:
      return { ...state, loading: false, error: action.payload };
    default:
      return state;
  }
};
export default otherTestScoreReducer;
