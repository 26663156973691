import React, { FC } from 'react';
import { Form, FormItemProps } from 'antd';

const FormItem: FC<FormItemProps> = ({
  label,
  name,
  children,
  ...props
}: FormItemProps) => (
  <Form.Item label={label} name={name} {...props}>
    {children}
  </Form.Item>
);

export { FormItem };
