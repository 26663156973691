import React from 'react';
import { Menu } from 'antd';
import { Button } from '@shared-components/elements';
import { LABEL } from '@moxie/constants';
import { IOtherTestScore } from '@shared-components/models';

const otherTestScoreMenu = (
  // eslint-disable-next-line no-unused-vars
  cb: (value: string) => void,
  otherTestScoreList: IOtherTestScore
): React.ReactElement => (
  <Menu>
    {(otherTestScoreList?.sat_1?.toString() === '0' ||
      !otherTestScoreList?.sat_1) && (
      <Menu.Item key="1">
        <Button
          type="link"
          onClick={(event: any) => {
            event?.stopPropagation();
            cb(LABEL.SAT_I);
          }}
        >
          {LABEL.SAT_I}
        </Button>
      </Menu.Item>
    )}
    {(otherTestScoreList?.sat_2?.toString() === '0' ||
      !otherTestScoreList?.sat_2) && (
      <Menu.Item key="2">
        <Button
          type="link"
          onClick={(event: any) => {
            event?.stopPropagation();
            cb(LABEL.SAT_II);
          }}
        >
          {LABEL.SAT_II}
        </Button>
      </Menu.Item>
    )}
    {(otherTestScoreList?.gre?.toString() === '0' ||
      !otherTestScoreList?.gre) && (
      <Menu.Item key="3">
        <Button
          type="link"
          onClick={(event: any) => {
            event?.stopPropagation();
            cb(LABEL.GRE);
          }}
        >
          {LABEL.GRE}
        </Button>
      </Menu.Item>
    )}
  </Menu>
);

export { otherTestScoreMenu };
