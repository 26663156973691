import { IAction, ILead, IPayload } from '@shared-components/models';
import * as actionTypes from '../client/client.constant';

export const getClientsRequest = (): IAction<ILead> => ({
  type: actionTypes.GET_CLIENTS_REQUEST,
});

export const getClientsSuccess = (payload: ILead[]): IAction<ILead[]> => ({
  type: actionTypes.GET_CLIENTS_SUCCESS,
  payload,
});

export const getClientsFailure = (payload: IPayload<ILead>): IAction => ({
  type: actionTypes.GET_CLIENTS_FAILURE,
  payload,
});

export const fetchClient = (payload: string): IAction<string> => ({
  type: actionTypes.GET_CLIENT_REQUEST,
  payload,
});

export const getClientSuccess = (payload: ILead[]): IAction<ILead[]> => ({
  type: actionTypes.GET_CLIENT_SUCCESS,
  payload,
});

export const getClientFailure = (payload: IPayload<ILead>): IAction => ({
  type: actionTypes.GET_CLIENT_FAILURE,
  payload,
});

export const clearClient = (): IAction<ILead> => ({
  type: actionTypes.CLEAR_CLIENT,
});

export const redirectClient = (): IAction<boolean> => ({
  type: actionTypes.SET_REDIRECT,
});

export const setError = (payload: boolean | any): IAction<boolean | any> => {
  return {
    type: actionTypes.SET_ERROR,
    payload,
  };
};

export const downloading = (payload: boolean): IAction<boolean> => ({
  type: actionTypes.DOWNLOADING,
  payload,
});
