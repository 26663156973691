import { useQuery } from '@tanstack/react-query';
import { getCRMSingleProduct } from 'apps/crm/src/libs/services.api/lib/product.api';

export const useGetProductById = (id: string | undefined) => {
  const { isLoading, data } = useQuery({
    queryKey: ['productData', id],
    queryFn: () => getCRMSingleProduct(id!),
    enabled: id !== undefined,
  });

  return {
    isLoading,
    data: data,
  };
};
