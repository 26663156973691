/* eslint-disable no-unused-vars */
import { store } from '@lyra/core';
import { Ability, AbilityBuilder, buildMongoQueryMatcher } from '@casl/ability';
import { $or, or } from '@ucast/mongo2js';
export const enum PermissionAction {
  Read = 'read',
  Create = 'create',
  Update = 'update',
  Delete = 'delete',
  Manage = 'manage',
  Archive = 'archive',
  Download = 'download',
}

export const enum PermissionObjects {
  Role = 'Role',
  Plan = 'Plan',
  User = 'User',
  LeadsEntity = 'LeadsEntity',
  LeadsForm = 'LeadsForm',
  ClientApplication = 'ClientApplication',
  LeadAppointment = 'LeadAppointment',
  LeadEducationBackground = 'LeadEducationBackground',
  LeadLanguageTestScore = 'LeadLanguageTestScore',
  LeadOtherTestScore = 'LeadOtherTestScore',
  LeadQualification = 'LeadQualification',
  LeadDocumentEntity = 'LeadDocumentEntity',
  LeadNotes = 'LeadNotes',
  Department = 'Department',
  Branch = 'Branch',
  ContactsEntity = 'ContactsEntity',
}
const conditionsMatcher = buildMongoQueryMatcher({ $or }, { or });

export class ContactsEntity {
  constructor(attrs: any) {
    Object.assign(this, attrs);
  }
}

export class LeadsEntity {
  constructor(attrs: any) {
    Object.assign(this, attrs);
  }
}

export class LeadsForm {
  constructor(attrs: any) {
    Object.assign(this, attrs);
  }
}

export class ClientApplication {
  constructor(attrs: any) {
    Object.assign(this, attrs);
  }
}

export class User {
  constructor(attrs: any) {
    Object.assign(this, attrs);
  }
}

function subjectName(subject: any) {
  if (!subject || typeof subject === 'string') {
    return subject;
  }

  return subject[Symbol.for('instanceType')];
}

const ability = new Ability([], { conditionsMatcher });

store?.subscribe(() => {
  const user: any = store.getState()?.auth?.user;
  ability.update(defineRulesFor(user));
});

const defineRulesFor = (user: any) => {
  const permissions = user?.role?.permission;
  const { can, rules } = new AbilityBuilder<Ability>(Ability);
  if (user?.role?.name === 'superAdmin') {
    can('manage', 'all');
    return rules;
  }

  const parseCondition = (condition: any, variables: any) => {
    if (!condition) return null;
    const cond = condition.replace(/#{([a-zA-Z0-9]+)}/gi, variables.id);
    const parseCond = JSON.parse(cond);
    const tempcond: any = {};
    for (const [key, rawValue] of Object.entries(parseCond)) {
      tempcond[key] = rawValue;
    }
    return tempcond;
  };
  if (permissions) {
    permissions.forEach((p: any) => {
      can(
        p?.action,
        p?.object?.name,
        parseCondition(p?.condition, { id: user.id })
      );
    });
  }
  return rules;
};

export function classConversion(type: any, instance: any) {
  instance[Symbol.for('instanceType')] = type;
  return instance;
}

export default (
  action?: PermissionAction | any,
  subject?: PermissionObjects | any,
  fields?: any
) => {
  // return ability.can(action, subject, fields);
  return true;
};
