import React, { useCallback, useEffect, useState } from 'react';
import { PaginationMeta, CrmResponse } from '@model/api-response.model';
import { useDebounced } from '@crm/libs/hooks';
import { errorNotificationHandler } from '@moxie/shared';
import { filterValidRefs } from '../helpers';

interface Props<T> {
  fetchQuery?: (params: Record<string, unknown>) => Promise<CrmResponse<T>>;
  valueKey?: keyof T;
  filter?: Record<string, unknown>;
  search: string;
  setSearch: (val: string) => void;
  value: unknown;
  enabled?: boolean;
}

/***
  * @param enabled  Set this to `false` to disable automatic refetching when the query mounts.
  * */
export const useSearchBox = <T,>({
  fetchQuery,
  valueKey,
  filter,
  search,
  setSearch,
  enabled = true,
  ...props
}: Props<T>) => {
  const [options, setOptions] = useState<T[]>([]);
  const [isLoading, setLoading] = useState(true);
  const [page, setPage] = useState(1);
  const [initialLoading, setInitialLoading] = useState(true);
  const [paginationMeta, setPaginationMeta] = useState<PaginationMeta>({
    currentPage: 1,
    itemsPerPage: 10,
    sortBy: [],
    totalItems: 0,
    totalPages: 1,
  });

  const hasMorePage = (paginationMeta.totalPages >= page);
  const fetchOptions = useCallback(
    async (search: string, value?: unknown, page = 1) => {
      if (fetchQuery) {
        setLoading(true)
        try {
          let params = {
            ...filter,
            search,
            limit: 10,
            page,
          };

          if ((value as string | string[])?.length) {
            params = {
              ...params,
              [`filter.${valueKey as string}`]: `${Array.isArray(value)
                ? '$in:$not:' + value.filter(val => filterValidRefs(val)).join(',')
                : '$in:$not:' + value
                }`,
            };
          }

          const {
            data: { data, meta },
          } = await fetchQuery(params);

          if (page !== 1) {
            setOptions((prev) => [...prev, ...data]);
          } else {
            setOptions(data);
          }
          setPaginationMeta(meta);
          setPage(meta.currentPage);
        } catch (error) {
          if (error instanceof Error) {
            errorNotificationHandler(error.message);
          } else if (typeof error === 'string') {
            errorNotificationHandler(error);
          }
        } finally {
          setLoading(false);
        }
      }
    },
    [fetchQuery, valueKey]
  );

  const onSearch = useDebounced((value: string) => {
    setSearch(search);
    fetchOptions(value);
  }, 500);


  useEffect(() => {
    if (enabled === false) return;
    fetchOptions(search, props.value).then(() => setInitialLoading(false));
  }, [JSON.stringify(props.value), enabled]);

  const paginateOptions: React.UIEventHandler<HTMLDivElement> = (event) => {
    const { scrollHeight, scrollTop, clientHeight } = event.currentTarget;
    const isScrollingNearBottom =
      Math.floor(scrollHeight - scrollTop) <= clientHeight;
    if (isScrollingNearBottom && paginationMeta.totalPages > page) {
      fetchOptions(search, props.value, page + 1);
    }
  };

  const fetchNextPage = () => {
    if (paginationMeta.totalPages > page) {
      fetchOptions(search, props.value, page + 1);
    }
  }

  return {
    options,
    paginateOptions,
    onSearch,
    isLoading,
    initialLoading,
    paginationMeta,
    fetchNextPage,
    hasMorePage
  };
};
