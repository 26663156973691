/* eslint-disable no-unused-vars */
import React from 'react';
import { Card } from 'antd';
import { EditDeleteButton, Title } from '@shared-components/elements';
import Can, { LeadsEntity, PermissionAction } from '@lyra/privilege';

interface IEducationCardProps {
  title: string;
  subscript?: string;
  superscript?: string;
  onEdit: () => void;
  onDelete: () => void;
  lead?: any;
  children: React.ReactNode | React.ReactChildren;
}
interface IViewEducationCardProps {
  title: string;
  subscript?: string;
  superscript?: string;
  lead?: any;
  children: React.ReactNode | React.ReactChildren;
}

const EducationCard: React.FC<IEducationCardProps> = ({
  title,
  subscript,
  superscript,
  onEdit,
  onDelete,
  lead,
  children,
}: IEducationCardProps) => {
  const handleEdit = () => {
    onEdit();
  };
  const handleDelete = () => {
    onDelete();
  };

  return (
    <Card
      className="education-card"
      title={
        <Title subscript={subscript} superscript={superscript} title={title} />
      }
      extra={
        <>
          {
            // Can(
            //   PermissionAction.Update,
            //   new LeadsEntity({
            //     assignee_id: lead?.assignee_id,
            //     followers: lead?.followers?.map((item: any) => item.id),
            //   })
            // ) &&
            !lead?.archived && (
              <EditDeleteButton onEdit={handleEdit} onDelete={handleDelete} />
            )
          }
        </>
      }
    >
      {children}
    </Card>
  );
};
const ViewEducationCard: React.FC<IViewEducationCardProps> = ({
  title,
  subscript,
  superscript,
  lead,
  children,
}: IViewEducationCardProps) => {
  return (
    <Card
      className="education-card"
      title={
        <Title subscript={subscript} superscript={superscript} title={title} />
      }
    >
      {children}
    </Card>
  );
};

export { EducationCard, ViewEducationCard };
