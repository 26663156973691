import React from 'react';
import GeneralSettings from './dashboard-settings/general-settings';
import { Route } from 'react-router-dom';
import DashboardProfile from './dashboard-profile/dashboard-profile';
import { Dashboard } from './dashboard-content';
import UserAccountSettings from './dashboard-account-settings/account-settings';

const DashboardRoute: React.FC = () => {
  return (
    <>
      <Route exact path="/dashboard" component={Dashboard} />
      <Route path="/dashboard/settings" component={GeneralSettings} />
      <Route exact path="/dashboard/profile" component={DashboardProfile} />
      <Route
        exact
        path="/dashboard/account-settings"
        component={UserAccountSettings}
      />
    </>
  );
};

export default DashboardRoute;
