import { IAction, IOfficeBranch, IPayload } from '@shared-components/models';
import * as actionTypes from './office-branch.constant';

export const addRequest = (
  payload: IOfficeBranch,
  triggerRefresh: () => void
): IAction<IOfficeBranch> => ({
  type: actionTypes.ADD_BRANCH_REQUEST,
  payload,
  triggerRefresh,
});

export const addSuccess = (payload: IOfficeBranch): IAction<IOfficeBranch> => ({
  type: actionTypes.ADD_BRANCH_SUCCESS,
  payload,
});

export const addFailure = (payload: IPayload<IOfficeBranch>): IAction => ({
  type: actionTypes.ADD_BRANCH_FAILURE,
  payload,
});

export const updateRequest = (
  payload: IOfficeBranch
): IAction<IOfficeBranch> => ({
  type: actionTypes.UPDATE_BRANCH_REQUEST,
  payload,
});

export const updateSuccess = (
  payload: IOfficeBranch
): IAction<IOfficeBranch> => ({
  type: actionTypes.UPDATE_BRANCH_SUCCESS,
  payload,
});

export const updateFailure = (payload: IPayload<IOfficeBranch>): IAction => ({
  type: actionTypes.UPDATE_BRANCH_FAILURE,
  payload,
});

export const getAllRequest = (): IAction<IOfficeBranch> => ({
  type: actionTypes.GET_BRANCHES_REQUEST,
});

export const getAllSuccess = (
  payload: IOfficeBranch[]
): IAction<IOfficeBranch[]> => ({
  type: actionTypes.GET_BRANCHES_SUCCESS,
  payload,
});

export const getAllFailure = (payload: IPayload<IOfficeBranch>): IAction => ({
  type: actionTypes.GET_BRANCHES_FAILURE,
  payload,
});

export const getOneRequest = (id: string): IAction<string> => ({
  type: actionTypes.GET_BRANCH_REQUEST,
  payload: id,
});

export const getOneSuccess = (
  payload: IOfficeBranch
): IAction<IOfficeBranch> => ({
  type: actionTypes.GET_BRANCH_SUCCESS,
  payload,
});

export const getOneFailure = (payload: IPayload<IOfficeBranch>): IAction => ({
  type: actionTypes.GET_BRANCH_FAILURE,
  payload,
});

export const clearOfficeBranch = (): IAction<string> => ({
  type: actionTypes.CLEAR_BRANCH_FETCH,
});

export const deleteOfficeBranchRequest = (
  id: string,
  triggerRefresh: () => void
): IAction<string> => ({
  type: actionTypes.DELETE_BRANCH_REQUEST,
  payload: id,
  triggerRefresh,
});

export const deleteOfficeBranchSuccess = (payload: any): IAction => ({
  type: actionTypes.DELETE_BRANCH_SUCCESS,
  payload,
});

export const deleteOfficeBranchFailure = (
  payload: IPayload<IOfficeBranch>
): IAction => ({
  type: actionTypes.DELETE_BRANCH_FAILURE,
  payload,
});
