import * as actionTypes from './subject-disciplines.constant';
import { IAction, ISubjectDiscipline } from '@shared-components/models';
export const SUBJECT_DISCIPLINE_FEATURE_KEY = 'subject_discipline';
export interface SubjectDisciplineState {
  disciplines: ISubjectDiscipline[];
  loading: boolean;
  error: null;
}

export const initialSubjectDisciplineState: SubjectDisciplineState = {
  disciplines: [],
  loading: false,
  error: null,
};

export const subjectDisciplineReducer = (
  state = initialSubjectDisciplineState,
  action: IAction<any>
) => {
  switch (action.type) {
    case actionTypes.GET_SUBJECT_DISCIPLINES_REQUEST:
      return {
        ...state,
        loading: true,
      };
    case actionTypes.GET_SUBJECT_DISCIPLINES_SUCCESS:
      return {
        ...state,
        loading: false,
        error: null,
        disciplines: [...action.payload],
      };
    case actionTypes.GET_SUBJECT_DISCIPLINES_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.payload,
      };
    default:
      return state;
  }
};
