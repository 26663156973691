import { Notification, UpdateNotificationStatusPayload } from "@model/notification"
import { API_URL } from "@moxie/constants"
import crmAxios from "./crm-api-axios"
import { CrmPaginatedResponse } from "@model/api-response.model"

export const fetchUserNotifications = async (page = 1, limit: number) => {
  const { data } = await crmAxios.get<CrmPaginatedResponse<Notification>>(API_URL.CRM_API_NOTIFICATION, {
    params: {
      page,
      limit: limit
    }
  })

  return data;
};

export const markAllNotificationAsRead = () =>
  crmAxios.post(`${API_URL.CRM_API_NOTIFICATION}/mark-as-read`);

export const updateNotificationStatus = ({
  is_read,
  notification_id,
}: UpdateNotificationStatusPayload) =>
  crmAxios.patch(`${API_URL.CRM_API_NOTIFICATION}/${notification_id}`, {
    isRead: is_read,
  });

export const fetchNotificationsCount = async () => {
  const { data } = await crmAxios.get<{ data: number }>(
    `${API_URL.CRM_API_NOTIFICATION}/unread-count`
  );
  return data.data;
};
