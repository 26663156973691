import {
  Button,
  EditDeleteButton,
  popConfirm,
} from '@shared-components/elements';
import { PlusOutlined } from '@ant-design/icons';
import {
  ADD_BTN,
  CONFIRM_MESSAGE,
  DELETE_BTN,
  LABEL,
  TEXT,
} from '@moxie/constants';
import { useEffect, useState } from 'react';
import { Badge, Card, Empty, Form, FormItemProps, Tag } from 'antd';
import { ProductFeeDrawer } from './product-fee-drawer';
import { productsActions, useAppSelector } from '@admin/core';
import { IProductDetailFees, IProductFee } from '@shared-components/models';
import { useDispatch } from 'react-redux';
const ProductFeeList: React.FC<IProductDetailFees> = ({
  params,
  currency,
}: IProductDetailFees) => {
  const [visible, setVisible] = useState(false);
  const [isEdit, setIsEdit] = useState<boolean>(false);
  const [disabled, setDisabled] = useState(true);
  const [feeValidationStatus, setFeeValidationStatus] = useState<
    FormItemProps['validateStatus']
  >('');
  const [form] = Form.useForm();
  const fees = useAppSelector((state) => state.product.fees);
  const singleData = useAppSelector((state) => state.product.fee);
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(productsActions.getProductFeesRequest(params.id));
    return () => {
      dispatch(productsActions.getProductFeesClear(params.id));
    };
  }, [dispatch]);

  const showDrawer = (id?: string) => {
    if (id) {
      setIsEdit(true);
      dispatch(productsActions.getProductFeeRequest(id));
    }
    setVisible(true);
  };

  useEffect(() => {
    return;
  }, [isEdit]);

  const onClose = () => {
    setFeeValidationStatus('success');
    dispatch(productsActions.getProductFeesClear(params.id));
    form.resetFields();
    form.setFieldsValue({ pfees: [{}] });
    setVisible(false);
    setIsEdit(false);
    setDisabled(true);
  };

  const handleSubmit = async (values: any) => {
    const data = values;
    data.product_id = params.id;
    if (singleData?.id && isEdit) {
      data.id = singleData.id;
      dispatch(productsActions.updateProductFeeRequest(data));
    } else {
      dispatch(productsActions.addProductFeeRequest(data, params.id));
    }
    onClose();
  };
  const handleDelete = (data: IProductFee) => {
    popConfirm(DELETE_BTN, `${CONFIRM_MESSAGE.DELETE_PRODUCT_FEE}`, () => {
      dispatch(productsActions.deleteProductFeeRequest(data?.id));
    });
  };
  return (
    <>
      <div className="padding-right-2 padding-left-2">
        <div className="slider_content ">
          <Button
            type="primary"
            icon={<PlusOutlined />}
            onClick={() => showDrawer()}
          >
            {ADD_BTN}
          </Button>
        </div>
        <div className="empty-content bg-white">
          {fees && fees.length <= 0 && (
            <div className="empty-content bg-white">
              <Empty image={Empty.PRESENTED_IMAGE_SIMPLE} />
            </div>
          )}
          {fees &&
            fees.map((item: IProductFee) => {
              return (
                <Card
                  className="fee_card margin-top-1"
                  Product-
                  title={
                    <div className="fee_card__header">
                      <span className="fee_card__title">{item?.fee_name}</span>
                      {item?.country && (
                        <Tag>{TEXT.VALID_FOR_ALL_COUNTRIES}</Tag>
                      )}
                    </div>
                  }
                  key={item?.id}
                  extra={
                    <EditDeleteButton
                      item={item}
                      onEdit={() => showDrawer(item?.id)}
                      onDelete={() => handleDelete(item)}
                      canUpdate
                      canDelete
                    />
                  }
                >
                  <div className="fee_lists">
                    {item?.pfees?.map((feeList: any) => (
                      <div className="fee_lists__item" key={feeList?.id}>
                        <div className="fee_lists__info">
                          <Badge dot />
                          <span className="margin-left-2 fee_lists__info_name">
                            {feeList?.fee_type?.replace('_', ' ')}
                          </span>
                        </div>
                        <div className=" fee_lists__installment">
                          <span className=" fee_lists__installment_label">
                            {LABEL.INSTALLMENT_TYPE}
                          </span>
                          <span className="margin-left-1 fee_lists__installment_amount">
                            {feeList?.installment_type?.replace('_', ' ')}
                          </span>
                        </div>

                        <div className=" fee_lists__installment">
                          <span className=" fee_lists__installment_label">
                            {TEXT.INSTALLMENTS}
                          </span>
                          <span className="margin-left-1 fee_lists__installment_amount">
                            {feeList?.installments}
                          </span>
                        </div>
                        <div className=" fee_lists__installment">
                          <span className=" fee_lists__installment_label">
                            {TEXT.INSTALLMENT_AMOUNT}
                          </span>
                          <span className="margin-left-1 fee_lists__installment_amount">
                            {currency} {feeList?.installment_amount}
                          </span>
                        </div>
                        <div className="fee_lists__price">
                          <b>
                            {currency} {feeList?.total_fee}
                          </b>
                        </div>
                      </div>
                    ))}
                  </div>
                  <div className="fee_lists__total">
                    <span className="fee_lists__total_label">
                      {LABEL.FEE_NET_TOTAL}:
                    </span>
                    <span className="fee_lists__total_price">
                      {currency}{' '}
                      {item?.pfees
                        .map((item: any) => {
                          return item?.total_fee;
                        })
                        .reduce((a: number, b: number) => a + b, 0)}
                    </span>
                  </div>
                </Card>
              );
            })}
        </div>
      </div>
      <ProductFeeDrawer
        disabled={disabled}
        setDisabled={setDisabled}
        fees={fees}
        form={form}
        visible={visible}
        onClose={onClose}
        handleSubmit={handleSubmit}
        params={params}
        currency={currency}
        isEdit={isEdit}
        initialData={singleData}
        feeValidationStatus={feeValidationStatus}
        setFeeValidationStatus={setFeeValidationStatus}
      />
    </>
  );
};
export { ProductFeeList };
