import axios from './axios';
import { IOtherTestScore, IResponse } from '@shared-components/models';
import { API_URL } from '@moxie/constants';

const createOtherTestScore = async (
  body: IOtherTestScore
): Promise<IResponse> => {
  return axios.post(API_URL.OTHER_TEST_SCORE, body);
};

const updateOtherTestScore = async (
  body: IOtherTestScore
): Promise<IResponse> => {
  const id = body.id;
  delete body.id;
  return axios.put(`${API_URL.OTHER_TEST_SCORE}/${id}`, body);
};

const getAllOtherTestScore = async (params = ''): Promise<IResponse> => {
  if (params?.length > 1) return axios.get(`${API_URL.OTHER_TEST_SCORE}${params}`);
  else return axios.get(`${API_URL.OTHER_TEST_SCORE}`);
};

const deleteOtherTestScore = async (id: string): Promise<IResponse> => {
  return axios.delete(`${API_URL.OTHER_TEST_SCORE}/${id}`);
};

export {
  createOtherTestScore,
  updateOtherTestScore,
  getAllOtherTestScore,
  deleteOtherTestScore,
};
