import {
  CANCEL_BTN,
  CREATE_DEPARTMENT_TITLE,
  DRAWER_WIDTH,
  EDIT_DEPARTMENT_TITLE,
  SAVE_BTN,
  UPDATE_BTN,
} from '@moxie/constants';
import { Space } from 'antd';
import { Button, DrawerElem } from '@moxie/shared';
import React from 'react';
import { DepartmentDrawerComponent } from '@shared-components/models';
import { DepartmentAddForm } from '@shared-components/forms';

const DepartmentDrawer: React.FC<DepartmentDrawerComponent> = ({
  visible,
  onClose,
  handleFormSubmit,
  isEdit,
  form,
  handleSubmit,
  initialData,
  disabled,
  setDisabled,
}: DepartmentDrawerComponent) => {
  return (
    <DrawerElem
      title={isEdit ? EDIT_DEPARTMENT_TITLE : CREATE_DEPARTMENT_TITLE}
      width={DRAWER_WIDTH}
      visible={visible}
      onClose={onClose}
      footer={
        <div className="padding-bottom-2 text-align-right">
          <Space direction="horizontal">
            <Button onClick={onClose} className="lead-margin-right">
              {CANCEL_BTN}
            </Button>
            <Button
              type="primary"
              onClick={handleFormSubmit}
              disabled={disabled}
            >
              {isEdit ? UPDATE_BTN : SAVE_BTN}
            </Button>
          </Space>
        </div>
      }
    >
      <DepartmentAddForm
        form={form}
        onSubmit={handleSubmit}
        initialData={initialData}
        setDisabled={setDisabled}
      />
    </DrawerElem>
  );
};

export { DepartmentDrawer };
