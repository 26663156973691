import {
  IAction,
  IProfessionalExperience,
  IProfessionalExperiencePayload,
} from '@shared-components/models';
import { Socket } from 'socket.io-client';
import * as actionTypes from './professional-experience.constant';

export const getProfessionalExperiencesRequest = (
  id: string
): IAction<string> => ({
  type: actionTypes.GET_PROFESSIONAL_EXPERIECES_REQUEST,
  payload: id,
});

export const getProfessionalExperiencesSuccess = (
  payload: IProfessionalExperience[]
): IAction<IProfessionalExperience[]> => ({
  type: actionTypes.GET_PROFESSIONAL_EXPERIECES_SUCCESS,
  payload,
});

export const getProfessionalExperiencesFailure = (
  payload: IProfessionalExperiencePayload
): IAction => ({
  type: actionTypes.GET_PROFESSIONAL_EXPERIECES_FAILURE,
  payload,
});

export const getProfessionalExperiencesClear = () => {
  return { type: actionTypes.GET_PROFESSIONAL_EXPERIECES_CLEAR };
};
export const deleteProfessionalExperienceRequest = (
  payload: IProfessionalExperiencePayload,
  lead: any,
  socket?: Socket,
  user?: any
): IAction<IProfessionalExperiencePayload> => ({
  type: actionTypes.DELETE_PROFESSIONAL_EXPERIECES_REQUEST,
  payload,
  lead,
  socket,
  user,
});
export const deleteProfessionalExperienceSuccess = (
  payload: IProfessionalExperiencePayload
): IAction<IProfessionalExperiencePayload> => ({
  type: actionTypes.DELETE_PROFESSIONAL_EXPERIECES_SUCCESS,
  payload,
});
export const deleteProfessionalExperienceFailure = (
  payload: IProfessionalExperiencePayload
): IAction<IProfessionalExperiencePayload> => ({
  type: actionTypes.DELETE_PROFESSIONAL_EXPERIECES_FAILURE,
  payload,
});

export const addProfessionalExperienceRequest = (
  payload: IProfessionalExperience,
  lead: any,
  socket?: Socket,
  user?: any
): IAction<IProfessionalExperience> => ({
  type: actionTypes.ADD_PROFESSIONAL_EXPERIECES_REQUEST,
  payload,
  lead,
  socket,
  user,
});

export const addProfessionalExperienceSuccess = (
  payload: IProfessionalExperience
): IAction<IProfessionalExperience> => ({
  type: actionTypes.ADD_PROFESSIONAL_EXPERIECES_SUCCESS,
  payload,
});

export const addProfessionalExperienceFailure = (
  payload: IProfessionalExperiencePayload
): IAction<IProfessionalExperiencePayload> => ({
  type: actionTypes.ADD_PROFESSIONAL_EXPERIECES_FAILURE,
  payload,
});

export const editProfessionalExperienceRequest = (
  payload: IProfessionalExperiencePayload,
  lead: any,
  socket?: Socket,
  user?: any
): IAction<IProfessionalExperiencePayload> => ({
  type: actionTypes.UPDATE_PROFESSIONAL_EXPERIECES_REQUEST,
  payload,
  lead,
  socket,
  user,
});
export const editProfessionalExperienceSuccess = (
  payload: IProfessionalExperiencePayload
): IAction<IProfessionalExperiencePayload> => ({
  type: actionTypes.UPDATE_PROFESSIONAL_EXPERIECES_SUCCESS,
  payload,
});

export const editProfessionalExperienceFailure = (
  payload: IProfessionalExperiencePayload
): IAction<IProfessionalExperiencePayload> => ({
  type: actionTypes.UPDATE_PROFESSIONAL_EXPERIECES_FAILURE,
  payload,
});
