import { createSlice } from '@reduxjs/toolkit';

export const PRODUCTS_FEATURE = 'product';

export interface productsState {
  products: any[];
  fees: any[];
  product: null;
  productFilters: any;
  productFiltersLoading: boolean;
  loading?: boolean;
  error?: boolean;
  errorMesssage?: string;
}

export const initialproductsState: productsState = {
  products: [],
  fees: [],
  productFilters: {},
  productFiltersLoading: false,
  product: null,
  loading: false,
  error: false,
  errorMesssage: '',
};

export const productSlice = createSlice({
  name: PRODUCTS_FEATURE,
  initialState: initialproductsState,
  reducers: {
    requestFilters: (state) => {
      state.productFiltersLoading = true;
    },
    setFilters: (state, action) => {
      state.productFilters = action.payload;
      state.productFiltersLoading = false;
    },
    addProducts: (state, action) => {
      const productsData = action.payload;
      state.products = [...state.products, productsData];
    },
    deletingProduct: (state) => {
      state.loading = true;
    },
    deleteProduct: (state, action) => {
      state.loading = false;
      const filteredProducts = state.products.filter(
        (item) => item.id !== parseInt(action.payload)
      );
      state.products = [...filteredProducts];
    },
    fetchProducts: (state) => {
      state.products = [];
    },
    setProductsFees: (state, action) => {
      state.fees = [...action.payload];
    },
    clearProductsFees: (state) => {
      state.fees = [];
    },
    setProducts: (state, action) => {
      state.products = [...action.payload];
    },
    setProduct: (state, action) => {
      state.product = action.payload;
      state.loading = false;
    },
    setProductLoading: (state) => {
      state.loading = true;
    },
    setProductError: (state, action) => {
      state.loading = false;
      state.errorMesssage = action.payload;
    },
    clearProduct: (state) => {
      state.product = null;
    },
    setError: (state, action) => {
      state.error = action.payload;
    },
  },
});

export const productsReducer = productSlice.reducer;

export const productsActions = productSlice.actions;
