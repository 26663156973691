import {
  IAction,
  ISubjectDiscipline,
  ISubjectDisciplinePayload,
} from '@shared-components/models';
import * as actionTypes from './notifications.constant';

export const getNotificationRequest = (payload: any): IAction<any> => ({
  type: actionTypes.GET_NOTIFICATIONS_REQUEST,
  payload,
});
export const getNotificationSuccess = (
  payload: ISubjectDiscipline[]
): IAction<ISubjectDiscipline[]> => ({
  type: actionTypes.GET_NOTIFICATIONS_SUCCESS,
  payload,
});
export const getNotificationFailure = (
  payload: ISubjectDisciplinePayload
): IAction<ISubjectDisciplinePayload> => ({
  type: actionTypes.GET_NOTIFICATIONS_FAILURE,
  payload,
});
export const cleargetNotificationRequest = () => ({
  type: actionTypes.GET_NOTIFICATIONS_CLEAR,
});

export const addNotificationSuccess = (
  payload: ISubjectDiscipline[]
): IAction<ISubjectDiscipline[]> => ({
  type: actionTypes.ADD_NOTIFICATION_SUCCESS,
  payload,
});

export const updateNotificationRequest = (payload: any): IAction => ({
  type: actionTypes.UPDATE_NOTIFICATION_REQUEST,
  payload,
});
export const updateNotificationFailure = (
  payload: any
): IAction<ISubjectDiscipline> => ({
  type: actionTypes.UPDATE_NOTIFICATION_FAILURE,
  payload,
});
export const updateNotificationSuccess = (
  payload: ISubjectDiscipline[]
): IAction<ISubjectDiscipline[]> => ({
  type: actionTypes.UPDATE_NOTIFICATION_SUCCESS,
  payload,
});

export const updateNotificationMarkReadRequest = (payload: any): IAction => ({
  type: actionTypes.NOTIFICATION_MARK_READ_ALL_REQUEST,
  payload,
});
export const updateNotificationMarkReadFailure = (
  payload: any
): IAction<ISubjectDiscipline> => ({
  type: actionTypes.NOTIFICATION_MARK_READ_ALL_FAILURE,
  payload,
});
export const updateNotificationMarkReadSuccess = (
  payload: ISubjectDiscipline[]
): IAction<ISubjectDiscipline[]> => ({
  type: actionTypes.NOTIFICATION_MARK_READ_ALL_SUCCESS,
  payload,
});
