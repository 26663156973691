import { TableColumn } from "@model/data.model";

const apiOrder = ['ASC', 'DESC'] as const;
type ApiOrder = typeof apiOrder[number];

const tableOrder = ['ascend', 'descend'] as const;
type TableOrder = typeof tableOrder[number];

const sortOrderMap: Record<ApiOrder, TableOrder> = {
  'ASC': 'ascend',
  'DESC': 'descend'
};

export const colSorter = <T extends TableColumn<T>>(col: T) => {
  const searchQuery = Object.fromEntries(new URLSearchParams(window.location.search.replace('?', '')));
  let sortOrder: TableOrder | null = null;
  if (searchQuery.sortBy) {
    const [field, order] = searchQuery.sortBy.split(':');
    if (field === col?.dataIndex) {
      sortOrder = sortOrderMap[order as ApiOrder];
    }
  }
  return {
    ...col,
    defaultSortOrder: sortOrder
  };
}
