import React, { useEffect, useState } from 'react';
import {
  MenuFoldOutlined,
  MenuUnfoldOutlined,
  UserOutlined,
  SettingOutlined,
  LogoutOutlined,
} from '@ant-design/icons';
import { NavLink, useHistory, useLocation } from 'react-router-dom';
import {
  Menu,
  Avatar,
  Badge,
  Switch,
  Tabs,
  Button,
  Empty,
  Tooltip,
} from 'antd';
import {
  IDashboardHeader,
  IGetPopupContainer,
} from '@shared-components/models';
import {
  COOKIE_ACCESS,
  COOKIE_REFRESH,
  DASHBOARD_ROUTES,
  AUTH_MESSAGE,
  SLUG_INSTITUTIONS,
  SLUG_PRODUCTS,
  SLUG_LEADS,
  PRODUCT_TITLE,
  INSTITUTE_TITLE,
  SEARCH,
  TEXT,
  URL_NOTIFICATION,
} from '@moxie/constants';
import { useDispatch, useSelector } from 'react-redux';
import { authActions, notificationActions, useAppSelector } from '@lyra/core';
import Cookies from 'js-cookie';
import { RootState } from 'apps/lyra/src/core/store';
import {
  DownOutlined,
  MessageOutlined,
  ApartmentOutlined,
} from '@ant-design/icons';
import AntSelect from 'antd/lib/select';
import { InstituteCard } from '@shared-components/elements';
import {
  DashboardSearch,
  successNotificationHandler,
} from 'libs/shared/src/shared';
import { BellOutlined } from '@ant-design/icons';
import ReactHtmlParser from 'react-html-parser';
import moment from 'moment-timezone';
import { Select, Input } from 'antd';
import {
  getAllInstitutes,
  getAllLead,
  getAllProducts,
} from '@lyra/services.api';

const { Option } = AntSelect;

const { SubMenu } = Menu;
const { TabPane } = Tabs;

declare const window: any;

const DashboardHeader = (props: IDashboardHeader): React.ReactNode | any => {
  const { setCollapsed, collapsed } = props;
  const currentUser: any = useSelector((store: RootState) => store.auth.user);
  const socket: any = useAppSelector((state) => state.socket.wss);
  const notifications: any = useAppSelector(
    (state) => state.notifications.notifications
  );
  const [filterunread, setFilterUnRead] = React.useState(false);
  const dispatch = useDispatch();
  const history = useHistory();
  const location = useLocation();
  const [selectedCategory, setSelectedCategory] = useState(`${SLUG_LEADS}`);

  const handleCategoryChange = async (value: any) => {
    setSelectedCategory(value);
  };

  const handleFetch = async (value: string) => {
    if (selectedCategory === SLUG_LEADS)
      return await getAllLead(`?s=${value}&limit=10`);
    if (selectedCategory === SLUG_INSTITUTIONS)
      return await getAllInstitutes(`?s=${value}&limit=10`);
    if (selectedCategory === SLUG_PRODUCTS)
      return await getAllProducts(`?s=${value}&limit=10`);
  };

  const GlobalSearchBar = () => {
    return (
      <Input.Group>
        <Select
          className="global-search-category"
          onChange={handleCategoryChange}
          defaultValue={selectedCategory}
          bordered={true}
        >
          <Option key={SLUG_LEADS} value={SLUG_LEADS}>
            {TEXT.CONTACTS}
          </Option>
          <Option key={SLUG_PRODUCTS} value={SLUG_PRODUCTS}>
            {PRODUCT_TITLE}
          </Option>
          <Option key={SLUG_INSTITUTIONS} value={SLUG_INSTITUTIONS}>
            {INSTITUTE_TITLE}
          </Option>
        </Select>
        <DashboardSearch
          selectedCategory={selectedCategory}
          placeholder={SEARCH}
          fetchOptions={handleFetch}
          className="global-search-list"
        />
      </Input.Group>
    );
  };

  useEffect(() => {
    dispatch(authActions.getAuthUser());
  }, [dispatch]);

  useEffect(() => {
    if (currentUser) {
      const socketleadData = { user_id: currentUser.id };
      socket?.emit('get-initial-notification-to-server', socketleadData);
    }
    return () => {
      dispatch(notificationActions.cleargetNotificationRequest());
    };
  }, [socket, currentUser, dispatch]);

  const handleLogout = async () => {
    const token = Cookies.get(COOKIE_ACCESS);
    Cookies.remove(COOKIE_ACCESS);
    Cookies.remove(COOKIE_REFRESH);
    history.push('/');
    dispatch(authActions.logout(token));
    successNotificationHandler(AUTH_MESSAGE.LOGOUT);
  };

  const handleMarkRead = (id: string) => {
    dispatch(notificationActions.updateNotificationRequest({ id: id }));
  };
  const handleMarkReadUnread = (id: string, is_read: boolean) => {
    dispatch(
      notificationActions.updateNotificationRequest({
        id: id,
        is_read: is_read,
      })
    );
  };
  const handleMarkReadAll = () => {
    dispatch(
      notificationActions.updateNotificationMarkReadRequest(currentUser?.id)
    );
  };

  const handleChatSupportClick = () => {
    window.Tawk_API.maximize();
  };

  function filterNotification(filterunread: boolean) {
    if (filterunread !== true) {
      return notifications?.length <= 0 ? (
        <Empty
          className="padding-bottom-2"
          description="No Notifications"
          image={Empty.PRESENTED_IMAGE_SIMPLE}
        />
      ) : (
        notifications.map((item: any, index: number) => (
          <Notification
            key={item.id}
            item={item}
            index={index}
            notifications={notifications}
            handleMarkRead={handleMarkRead}
            handleMarkReadAll={handleMarkReadAll}
            handleMarkReadUnread={handleMarkReadUnread}
          />
        ))
      );
    } else {
      const filteredNotifications = notifications?.filter(
        (item: any) => item.is_read === false
      );
      return filteredNotifications.length <= 0 ? (
        <Empty
          className="padding-bottom-2"
          description="No Notifications"
          image={Empty.PRESENTED_IMAGE_SIMPLE}
        />
      ) : (
        filteredNotifications.map((item: any, index: number) => (
          <Notification
            key={item.id}
            item={item}
            index={index}
            notifications={notifications}
            handleMarkRead={handleMarkRead}
            handleMarkReadAll={handleMarkReadAll}
            handleMarkReadUnread={handleMarkReadUnread}
          />
        ))
      );
    }
  }

  return (
    <nav className="dashboard-header__nav ">
      <div
        onClick={() => setCollapsed(!collapsed)}
        className="dashboard-header__nav_trigger "
      >
        {collapsed ? <MenuUnfoldOutlined /> : <MenuFoldOutlined />}
      </div>
      <div className="dashboard-header__nav_menu">
        <Menu
          mode="horizontal"
          triggerSubMenuAction="click"
          selectedKeys={[location.pathname]}
          id="navigation-menu"
        >
          <Menu.Item key="search" className="dashboard-header__nav_search">
            <GlobalSearchBar />
          </Menu.Item>
          <Menu.Item onClick={handleChatSupportClick}>
            <Badge
              count={props?.unReadMessageCount}
              className="msg-badge"
              size="small"
              offset={[-2, 22]}
            >
              <div id="msg-badge">
                <Tooltip
                  getPopupContainer={(): IGetPopupContainer =>
                    document.getElementById('msg-badge')
                  }
                  title="Chat Support"
                >
                  <MessageOutlined className="header-icon-font-size mail-icon" />
                </Tooltip>
              </div>
            </Badge>
          </Menu.Item>
          <SubMenu
            popupOffset={[-20, 0]}
            key="notificationMenu"
            title={
              <>
                <Badge
                  size="small"
                  count={
                    notifications?.filter((item: any) => item.is_read === false)
                      ?.length
                  }
                  offset={[-3, 3]}
                >
                  <BellOutlined className="header-icon-font-size" />
                </Badge>
              </>
            }
          >
            <div className="notification-menu  ">
              <div className="notification-menu__header">
                <h4>Notifications</h4>
                <div className="notification-menu__read">
                  <span>Only show unread</span>
                  <Switch
                    checked={filterunread}
                    size="small"
                    onChange={() => {
                      setFilterUnRead(!filterunread);
                    }}
                  />
                </div>
              </div>
              <div className="notification-menu__content">
                <Tabs defaultActiveKey="1" size="small">
                  <TabPane tab="Direct" key="1">
                    <div className="notification-menu__lists">
                      {notifications && filterNotification(filterunread)}
                    </div>
                  </TabPane>
                  <TabPane tab="Following" key="2">
                    {notifications?.filter(
                      (item: any) => item.category === 'following'
                    )?.length <= 0 && (
                        <Empty
                          className="padding-bottom-2"
                          description="No Notifications"
                          image={Empty.PRESENTED_IMAGE_SIMPLE}
                        />
                      )}
                  </TabPane>
                </Tabs>
              </div>
              <div className="notification-menu__footer">
                <a
                  href={`${window.location.origin}${URL_NOTIFICATION}`}
                  className="margin-auto"
                >
                  <Button
                    type="text"
                    color="primary"
                    className="full-width ant-btn-ghost view-all-notification-btn"
                  >
                    View All Notifications
                  </Button>
                </a>
              </div>
            </div>
          </SubMenu>

          <SubMenu
            popupOffset={[8, 0]}
            key="profileMenu"
            className="lyra-dashboard__layout_header_dropmenu"
            title={
              <div className="drop-menu">
                <div className="drop-menu__info">
                  <Avatar size="large" className="drop-menu__avatar">
                    {currentUser?.am_user_info?.first_name?.substring(0, 1)}
                    {currentUser?.am_user_info?.last_name?.substring(0, 1)}
                  </Avatar>
                  <div className="drop-menu__text">
                    <h4>
                      {currentUser?.am_user_info.first_name}{' '}
                      {currentUser?.am_user_info.last_name}
                    </h4>
                    <p>{currentUser?.email}</p>
                  </div>
                </div>
                <DownOutlined />
              </div>
            }
          >
            <Menu.Item key="institute" className="dropdown-institute-card">
              <InstituteCard
                companyName={currentUser?.company?.company_name || ''}
                branchName={currentUser?.branch?.name}
              />
            </Menu.Item>
            <Menu.Divider />
            <Menu.Item
              key={DASHBOARD_ROUTES.profile.path}
              icon={<UserOutlined />}
            >
              <NavLink exact to={DASHBOARD_ROUTES.profile.path}>
                {DASHBOARD_ROUTES.profile.name}
              </NavLink>
            </Menu.Item>

            <Menu.Item
              key={DASHBOARD_ROUTES.accountSettings.path}
              icon={<SettingOutlined />}
            >
              <NavLink exact to={DASHBOARD_ROUTES.accountSettings.path}>
                {DASHBOARD_ROUTES.accountSettings.name}
              </NavLink>
            </Menu.Item>

            <Menu.Item
              key={DASHBOARD_ROUTES.settings.path}
              icon={<ApartmentOutlined />}
            >
              <NavLink exact to={DASHBOARD_ROUTES.settings.path}>
                {DASHBOARD_ROUTES.settings.name}
              </NavLink>
            </Menu.Item>
            <Menu.Divider />
            <Menu.Item
              key="2"
              icon={<LogoutOutlined />}
              onClick={handleLogout}
              danger
            >
              {DASHBOARD_ROUTES.logout.name}
            </Menu.Item>
          </SubMenu>
        </Menu>
      </div>
    </nav>
  );
};

const Notification = (props: any) => {
  const {
    item,
    index,
    notifications,
    handleMarkRead,
    handleMarkReadAll,
    handleMarkReadUnread,
  } = props;
  const [showRead, setShowRead] = useState(false);
  useEffect(() => {
    const notification = notifications?.filter(
      (item: any) => item?.is_read === false
    );
    if (notification?.length > 0) {
      setShowRead(true);
    } else {
      setShowRead(false);
    }
  }, [notifications, showRead]);

  const comparedate = (d: Date, compareDate: Date) => {
    return (
      compareDate?.getFullYear() === d?.getFullYear() &&
      compareDate?.getDate() === d?.getDate() &&
      compareDate?.getMonth() === d?.getMonth()
    );
  };

  const comparedateyesterday = (d: Date, compareDate: Date) => {
    return (
      compareDate?.getFullYear() === d?.getFullYear() &&
      compareDate?.getDate() === d?.getDate() - 1 &&
      compareDate?.getMonth() === d?.getMonth()
    );
  };
  const comparedateweek = (d: Date, compareDate: Date) => {
    return (
      compareDate?.getFullYear() === d?.getFullYear() &&
      compareDate?.getDate() >= d?.getDate() - 7 &&
      compareDate?.getMonth() === d?.getMonth()
    );
  };

  const dividerlogic = (showRead: boolean) => {
    const createdDate = new Date(item.created_at);
    const prevNotification = notifications[index - 1];
    const prevNotificationDate =
      prevNotification && new Date(prevNotification?.created_at);
    const currentDate = new Date(Date.now());

    let datename;
    if (comparedate(createdDate, currentDate)) {
      datename = 'Today';
    } else if (comparedateyesterday(currentDate, createdDate)) {
      datename = 'Yesterday';
    } else if (comparedateweek(currentDate, createdDate)) {
      datename = 'Less than a week';
    } else {
      datename = 'Older Notifications';
    }
    if (comparedate(createdDate, prevNotificationDate)) {
      return null;
    } else {
      return (
        <div className="notification-menu__title">
          <h5>{datename}</h5>
          {showRead ? (
            <Button
              type="text"
              color="primary"
              size="small"
              onClick={handleMarkReadAll}
            >
              Mark all as read
            </Button>
          ) : null}
        </div>
      );
    }
  };

  return (
    <>
      {dividerlogic(showRead)}
      <div
        className={`notification-menu__item ${!item.is_read ? 'unread' : ''}`}
        key={item.id}
      >
        <a href={`${window.location.origin}/${item?.url}`}>
          <div
            className="align-items-center"
            onClick={() => handleMarkRead(item.id)}
          >
            <div className="notification-menu__item_avatar">
              <Avatar>
                {item?.user?.first_name?.substring(0, 1)?.toUpperCase()}
                {item?.user?.last_name?.substring(0, 1)?.toUpperCase()}
              </Avatar>
            </div>
            <div className="notification-menu__item_content">
              <div className="notification-menu__item_action">
                {ReactHtmlParser(item.message)}
              </div>
              <div className="notification-menu__item_date">
                <span>{moment(item.created_at).fromNow()}</span>
              </div>
            </div>
          </div>
        </a>
        <Tooltip
          title={item.is_read ? 'Mark as unread' : 'Mark as read'}
          placement="right"
          mouseEnterDelay={1}
        >
          <div
            className={`notification-menu__item_read_container ${item.is_read ? '' : 'active'
              }`}
            onClick={() => {
              handleMarkReadUnread(item.id, item.is_read);
            }}
          >
            <div className="read-marker">
              <span></span>
            </div>
          </div>
        </Tooltip>
      </div>
    </>
  );
};

export default DashboardHeader;
