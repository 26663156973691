/* eslint-disable react-hooks/rules-of-hooks */
import React from 'react';
import { ApplicationStatus, Table, UserNameCard } from '@moxie/shared';
import {
  APPLICATION_STATUS,
  COLUMNS,
  LABEL,
  TEXT,
  URL_USER_DETAIL_LINK,
} from '@moxie/constants';
import {
  IActiveStage,
  IService,
  ITableProps,
  IWorkflow,
  IUser,
  ILeadApplication,
} from '@shared-components/models';
import { ColumnsType } from 'antd/lib/table';
import { ApplicationAssignee } from './application-assignee';
import { Link } from 'react-router-dom';
import { Button } from 'antd';
import { BlockOutlined } from '@ant-design/icons';
import { TableClientApplicationNameCard } from '../../../admin-modules/contact/client-application-name-card';

const LeadApplicationTable: React.FC<ITableProps> = ({
  getData,
  tableName,
  filter,
  refreshTable,
  setRefreshTable,
  tableButtons,
}: ITableProps) => {
  const columns = (): ColumnsType<any> => {
    return [
      {
        title: TEXT.ID,
        dataIndex: 'id',
        key: 'id',
        width: 200,
        ellipsis: true,
        render(id: string, data: any) {
          if (!data?.client?.archived) {
            return (
              <Link
                to={`/contact/detail/${data?.contact_id}/applications/${id}`}
                className="primary-id-color"
              >
                <Button
                  type="primary"
                  icon={<BlockOutlined />}
                  className="view_application"
                />
                {data?.company?.company_code + '-' + data?.app_identifier}
              </Link>
            );
          } else {
            return (
              <>
                <Link
                  to="#"
                  className="primary-id-color cursor-disabled"
                >
                  <Button
                    type="primary"
                    icon={<BlockOutlined />}
                    className="view_application cursor-disabled"
                  />
                  {data?.company?.company_code + '-' + data?.app_identifier}
                </Link>
              </>
            )
          }
        },
      },

      {
        title: LABEL.PRODUCT_INSTITUTION_NAME,
        width: 300,
        ellipsis: true,
        sorter: () => 0,
        render(data: ILeadApplication) {
          return <TableClientApplicationNameCard data={data} />;
        },
      },

      {
        title: LABEL.SERVICE,
        width: 200,
        dataIndex: ['workflow', 'workflowType', 'service'],
        sorter: () => 0,
        render(data: IService) {
          return data?.name;
        },
      },
      {
        title: COLUMNS.ADMIN_ASSIGNEE,
        dataIndex: 'agent_clients',
        render(text: any, data: any): React.ReactElement | any {
          return (
            <ApplicationAssignee
              data={data}
              refreshTable={refreshTable}
              setRefreshTable={setRefreshTable}
            />
          );
        },
      },
      {
        title: LABEL.WORKFLOW,
        width: 200,
        render({ workflow }: { workflow: IWorkflow }) {
          return `${workflow?.workflowType?.name} (${workflow?.country})`;
        },
      },
      {
        title: TEXT.CREATED_BY,
        width: 200,
        render({ created_by }: { created_by: IUser }) {
          return (
            <UserNameCard
              firstName={created_by.first_name || ''}
              lastName={created_by.last_name || ''}
              email={created_by.email || ''}
              link={`${URL_USER_DETAIL_LINK}${created_by?.id}`}
            />
          );
        },
      },
      {
        title: LABEL.CURRENT_STAGE,
        width: 200,
        render({
          activeStage,
          status,
        }: {
          activeStage: IActiveStage;
          status: string;
        }) {
          return status === APPLICATION_STATUS.COMPLETED
            ? TEXT.COMPLETE
            : activeStage?.stage;
        },
      },
      {
        title: LABEL.STATUS,
        width: 200,
        render({ status }: { status: string }) {
          return <ApplicationStatus status={status} />;
        },
      },
    ];
  };

  return (
    <Table
      tableName={tableName}
      columns={columns()}
      action={getData}
      size={'small'}
      refresh={refreshTable}
      filter={filter}
      filterOptions={true}
      tableButtons={tableButtons}
    />
  );
};

export default LeadApplicationTable;
