import React, { useEffect, useState } from 'react';
import { Link, useHistory, useParams } from 'react-router-dom';
import { Button, Spin } from 'antd';
import { useDispatch } from 'react-redux';
import { LeftOutlined } from '@ant-design/icons';
import { useAppSelector, institutionActions } from '@crm/core';
import { TEXT, URL_INSTITUTE_ADMIN } from '@moxie/constants';
import { OverViewHeader } from '@shared-components/elements';
import { errorHandler } from '@moxie/utils';
import {
  InstitutionDetailProfile,
  InstitutionDetailContent
} from '@crm/shared';


const InstitutionDetail: React.FC = () => {

  const { id }: { id: string } = useParams();
  const history = useHistory();
  const dispatch = useDispatch();

  const [refreshTable, setRefreshTable] = useState(true);
  const triggerRefresh = () => setRefreshTable((prev) => !prev);

  const dataLoading = useAppSelector((state) => state.institutions.loading);
  const institutionDetail = useAppSelector((state) => state.institutions.institution);

  const fetchInstitutionData = (id: any) => {
    try {
      dispatch(institutionActions.getInstitutionRequest(id));
    } catch (err) {
      errorHandler(err);
    }
  };

  useEffect(() => {
    if (id) {
      fetchInstitutionData(id);
    } else {
      history.goBack();
    }
    return () => {
      dispatch(institutionActions.clearFetchInstitution());
    };
  }, [refreshTable]);

  return (
    <Spin spinning={dataLoading} tip={TEXT.LOADING}>
      <OverViewHeader
        title={TEXT.INSTITUTE}
        actions={
          <Link to={URL_INSTITUTE_ADMIN}>
            <Button icon={<LeftOutlined />}>{TEXT.INSTITUTE_LIST}</Button>
          </Link>
        }
      />
      <InstitutionDetailProfile
        dispatch={dispatch}
        loading={dataLoading}
        data={institutionDetail}
        triggerRefresh={triggerRefresh}
      />
      <InstitutionDetailContent data={institutionDetail} />
    </Spin>
  );
};

export { InstitutionDetail };
