import { Space, Tag } from 'antd';
import React from 'react';

const LeadTags: React.FC = () => {
  return (
    <div className="profile__info__item profile__info__tags">
      <Space size={8}>
        <Tag>Lost</Tag>
        <Tag>Cold</Tag>
        <Tag>Warm</Tag>
        <Tag className="tag-primary">Hot</Tag>
      </Space>
    </div>
  );
};

export { LeadTags };
