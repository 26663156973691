import React, { useEffect, useState } from 'react';
import { Row, Typography, Col, Input, Form, Dropdown } from 'antd';
import { IOtherDetailsCategory } from '@shared-components/models';
import {
  BUTTON,
  CATEGORY_NAME_MAX,
  CATEGORY_REQUIRED,
  PLACEHOLDER,
  STATUS,
  TITLE,
} from '@moxie/constants';
import { Button, MenuItem } from '@shared-components/elements';
import {
  CheckOutlined,
  MinusCircleOutlined,
  MoreOutlined,
  CloseOutlined,
} from '@ant-design/icons';
import { useDetailsCategory } from './use-details-category.hook';
import { useForm } from 'antd/lib/form/Form';

const { Title } = Typography;

const OtherDetailCategories: React.FC = () => {
  const [selectedCategoryToEdit, setCategoryToEdit] = useState('');
  const [addFormIsVisible, setAddFormVisibility] = useState(false);
  const [form] = useForm();
  const {
    categoryList,
    selectedCategoryId,
    status,
    onSelectCategory,
    addCategory,
    onEditCategory,
    onDeleteCategory,
  } = useDetailsCategory();

  useEffect(() => {
    if (status === STATUS.SUCCEEDED) {
      handleSuccess();
    }
  }, [status]);

  const handleSuccess = () => {
    setAddFormVisibility(false);
    setCategoryToEdit('');
    form.resetFields();
  };

  const handleCategorySelect = (id: string): void => {
    onSelectCategory(id);
  };

  const handleEditSubmit = (data: IOtherDetailsCategory) => {
    onEditCategory({ ...data, id: selectedCategoryToEdit });
  };

  const handleEditBtnClick = (id: string) => {
    setAddFormVisibility(false);
    setCategoryToEdit(id);
  };

  const handleDeleteBtnClick = (id: string) => {
    return onDeleteCategory(id);
  };

  const onFormSubmit = (data: IOtherDetailsCategory): void => {
    addCategory(data);
  };

  const handleAddCategoryBtn = () => {
    setCategoryToEdit('');
    setAddFormVisibility(true);
  };

  const handleCloseCategoryForm = () => {
    setAddFormVisibility(false);
  };

  const handleCloseEditCategoryForm = () => {
    setCategoryToEdit('');
  };

  return (
    <Col span={7} className="other-details__category">
      <Row>
        <Title level={5}>{TITLE.CATEGORIES}</Title>
      </Row>
      <Row>
        {categoryList.map(
          (category: IOtherDetailsCategory): React.ReactElement => (
            <Col
              span={24}
              className={`other-details__category__list ${
                selectedCategoryId?.toString() === category.id.toString() &&
                'other-details__category__list__selected'
              }`}
              key={category.id}
            >
              {selectedCategoryToEdit === category.id ? (
                <Form
                  initialValues={category}
                  onFinish={handleEditSubmit}
                  className="full-width"
                >
                  <div className="general-setting-category-form-item">
                    <Form.Item
                      name="name"
                      rules={[
                        {
                          required: true,
                          whitespace: true,
                          type: 'string',
                          message: CATEGORY_REQUIRED,
                        },
                        {
                          max: 30,
                          message: CATEGORY_NAME_MAX,
                        },
                      ]}
                    >
                      <Input className="general-setting-category-input" />
                    </Form.Item>
                    <Button
                      icon={<CheckOutlined />}
                      type="link"
                      size="small"
                      htmlType="submit"
                      className="padding-left-1"
                    />
                    <Button
                      icon={<MinusCircleOutlined />}
                      type="link"
                      danger
                      onClick={handleCloseEditCategoryForm}
                      size="small"
                    />
                  </div>
                </Form>
              ) : (
                <>
                  <Col onClick={() => handleCategorySelect(category.id)}>
                    {category.name}
                  </Col>
                  <Dropdown
                    overlay={MenuItem(
                      () => handleEditBtnClick(category?.id),
                      () => handleDeleteBtnClick(category?.id)
                    )}
                  >
                    <Button type="link" icon={<MoreOutlined />} />
                  </Dropdown>
                </>
              )}
            </Col>
          )
        )}
        <Col span={24}>
          <Form onFinish={onFormSubmit} form={form}>
            {addFormIsVisible && (
              <div className="general-setting-category-form-item full-width">
                <Form.Item
                  name="name"
                  className="padding-top-1"
                  rules={[
                    {
                      required: true,
                      whitespace: true,
                      type: 'string',
                      message: CATEGORY_REQUIRED,
                    },
                    {
                      max: 30,
                      message: CATEGORY_NAME_MAX,
                    },
                  ]}
                >
                  <Input
                    placeholder={PLACEHOLDER.CATEGORY}
                    className="general-setting-category-input"
                  />
                </Form.Item>
                <Button
                  icon={<CheckOutlined />}
                  type="link"
                  htmlType="submit"
                  loading={status === STATUS.LOADING && true}
                  size="small"
                  className="padding-left-1"
                />
                <Button
                  icon={<CloseOutlined />}
                  onClick={handleCloseCategoryForm}
                  type="link"
                  danger
                  size="small"
                />
              </div>
            )}
          </Form>
        </Col>
        <Col span={24} className="padding-top-1">
          <Button
            type="default"
            className="full-width"
            size="middle"
            onClick={handleAddCategoryBtn}
          >
            {BUTTON.ADD_CATEGORY}
          </Button>
        </Col>
      </Row>
    </Col>
  );
};

export { OtherDetailCategories };
