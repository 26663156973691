import React from 'react';
import Icon from '@ant-design/icons';

const DegreeLevelIcon = () => (
  <svg
    id="Layer_1"
    height="1.1em"
    width="1.1em"
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 81.37 85.49"
  >
    <path
      className="cls-1"
      d="M1178,52.67V80.05a8.62,8.62,0,0,1-8.62,8.62h-40.83a8.61,8.61,0,0,1-8.62-8.61V51.15l30,22.18Z"
      transform="translate(-1109.33 -13.54)"
    />
    <polygon
      className="cls-1"
      points="80.87 30.15 68.68 39.13 40.65 59.79 10.61 37.61 0.5 30.15 40.65 0.5 80.87 30.15"
    />
    <line className="cls-1" x1="36.43" y1="29.52" x2="57.82" y2="47.32" />
    <line className="cls-1" x1="57.82" y1="85.49" x2="57.82" y2="46.74" />
  </svg>
);

const DegreeOutlinedIcon: React.FC = (props: any) => (
  <Icon component={DegreeLevelIcon} {...props} className="degree-level-icon" />
);

export { DegreeOutlinedIcon };
