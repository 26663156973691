import { useAppSelector } from '@crm/core';
import { FormInstance } from 'antd';
import { useMemo, useState } from 'react';
export const useGetSubjectAreas = (form: FormInstance<any>, isEdit: boolean | undefined) => {
  const [selectedDiscipline, setSelectedDiscipline] = useState<string | any>();

  const disciplines = useAppSelector(state => state.subject_discipline.allData);

  const disciplinesLoading = useAppSelector(
    (state) => state.subject_discipline.loading
  );

  const handleDisciplineChange = (value: string) => {
    setSelectedDiscipline(value);
    form.setFieldsValue({ subjectAreaId: undefined });
  };

  const selectedDisciplineData = useMemo(() => {
    return disciplines.find(discipline => discipline.id === selectedDiscipline);
  }, [selectedDiscipline, disciplinesLoading]);

  return {
    subjectAreas: selectedDisciplineData?.subjectAreas ?? [],
    subjectAreasLoading: disciplinesLoading,
    selectedDiscipline,
    setSelectedDiscipline,
    handleDisciplineChange,
  };
};
