import React, { useEffect } from 'react';
import moment from 'moment-timezone';
import { useAppSelector } from '@lyra/core';
import { TEXT, TIMEZONE } from '@moxie/constants';
import { UserSelectBox } from '@shared-components/elements';
import { IAppointmentForm, IForm } from '@shared-components/models';
import {
  Typography,
  Col,
  Form,
  Input,
  Select,
  DatePicker,
  TimePicker,
  Space,
} from 'antd';
import { RootState } from 'apps/lyra/src/core/store';

const AppointmentForm: React.FC<IForm<IAppointmentForm>> = ({
  initialData,
  onSubmit,
  form,
  isEdit,
  setDisabled,
}: IForm<IAppointmentForm>) => {
  const { Option } = Select;

  const dateFormat = 'YYYY-MM-DD';
  const roundedUp = Math.ceil(moment().minute() / 15) * 15;

  const currentUser: any = useAppSelector(
    (store: RootState) => store.auth.user
  );

  const handleFormSubmit = (data: any) => {
    onSubmit && onSubmit(data);
  };

  const onFieldsChange = (allFields: string | any[]) => {
    if (allFields.length > 0) {
      setDisabled(false);
    }
  };

  const handleDate = (currentDate: any) => {
    const customDate = moment().format('YYYY-MM-DD');
    return currentDate && currentDate < moment(customDate, 'YYYY-MM-DD');
  };

  useEffect(() => {
    if (isEdit && initialData) {
      form.setFieldsValue({
        added_by: initialData.added_by,
        time_zone: initialData.time_zone,
        time: moment(initialData?.time).utc(),
        date: moment(initialData?.date).utc(),
        title: initialData.title,
        description: initialData.description,
        user_list: initialData.user_list,
      });
    } else {
      form.setFieldsValue({
        time_zone: currentUser?.time_zone,
        date: moment().tz(currentUser?.time_zone),
        time: moment().tz(currentUser?.time_zone).minute(roundedUp).second(0),
      });
    }
  }, [initialData, form, isEdit]);

  moment.tz.setDefault(currentUser?.time_zone);

  return (
    <div>
      <Col>
        {isEdit && (
          <>
            <Col>
              <Typography.Text strong>{TEXT.ADDED_BY}:</Typography.Text>
            </Col>
            <Col className="margin-bottom-2">
              <Typography.Text>
                {initialData?.user?.first_name}&nbsp;
                {initialData?.user?.last_name}
              </Typography.Text>
            </Col>
          </>
        )}
        <Form
          layout="vertical"
          onFinish={handleFormSubmit}
          form={form}
          onValuesChange={onFieldsChange}
          onFieldsChange={onFieldsChange}
        >
          <Form.Item label="Timezone" name="time_zone">
            <Select disabled>
              {TIMEZONE.map((data, idx) => {
                return (
                  <Option value={data.utc[0]} key={idx}>
                    {data.text}
                  </Option>
                );
              })}
            </Select>
          </Form.Item>
          <Space size={12} direction="horizontal">
            <Col>
              <Form.Item
                label="Date"
                name="date"
                rules={[{ required: true, message: `${TEXT.DATE_SELECT}` }]}
              >
                <DatePicker
                  disabledDate={handleDate}
                  className="appointment-date-picker"
                  format={dateFormat}
                />
              </Form.Item>
            </Col>
            <Col>
              <Form.Item
                label="Time"
                name="time"
                rules={[{ required: true, message: `${TEXT.TIME_SELECT}` }]}
              >
                <TimePicker
                  minuteStep={15}
                  format={'hh:mm a'}
                  className="appointment-date-picker"
                  showNow={false}
                />
              </Form.Item>
            </Col>
          </Space>

          <Form.Item
            label="Title"
            name="title"
            rules={[{ required: true, message: `${TEXT.TITLE_SELECT}` }]}
          >
            <Input />
          </Form.Item>
          <Form.Item label="Description" name="description">
            <Input.TextArea />
          </Form.Item>
          <Form.Item label="Invitees" name="user_list">
            <UserSelectBox placeholder={TEXT.INVITEES} mode="multiple" />
          </Form.Item>
        </Form>
      </Col>
    </div>
  );
};

export default AppointmentForm;
