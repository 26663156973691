import { INSTITUTE_TITLE, LABEL } from '@moxie/constants';
import { Table } from '@shared-components/elements';
import React from 'react';
import { InstitutionTableNameCard } from './institution-table-list';
import { Button, Typography } from 'antd';
import { getImportInsitututions } from '@crm/services.api';
import { UnorderedListOutlined } from '@ant-design/icons';
import { useDispatch } from 'react-redux';
import { institutionActions } from '@crm/core';
import { useCanAccess } from '@crm/libs/privilege/useCanAccess';

const columns = (props: any) => {
  const {
    setSelectedInstitution,
    setSelectedInstitutionBranches,
    setInstitutionBranchesMeta,
    setCurrentBranchPage,
    selectedInsitution,
    setCurrentActiveKey,
    setSelectedInstitutionProducts,
    setInstitutionProductsMeta,
    canAccess,
  } = props;

  return [
    {
      title: LABEL.NAME,
      key: 'name',
      width: 400,
      sorter: () => 0,
      render(data: any) {
        const menuActions = {
          data,
          canAccess,
        };

        return (
          <InstitutionTableNameCard
            menuActions={menuActions}
            id={data.id}
            name={data.name}
          />
        );
      },
    },
    {
      title: LABEL.NUMBER_OF_BRANCHES,
      key: 'branches',
      dataIndex: 'branches',
      render(data: any[]) {
        return (
          <Typography.Text className="padding-2">{data.length}</Typography.Text>
        );
      },
    },
    {
      title: LABEL.TOTAL_PRODUCTS,
      dataIndex: 'products',
      key: 'products',
      render(productNumber: number) {
        return (
          <Typography.Text className="padding-2">
            {productNumber}
          </Typography.Text>
        );
      },
    },
    {
      title: '',
      render(data: any) {
        return (
          <Button
            className={
              selectedInsitution?.id === data?.id
                ? 'font-weight-bold text-primary-color'
                : ''
            }
            onClick={() => {
              if (selectedInsitution.id !== data.id) {
                setSelectedInstitutionBranches([]);
                setSelectedInstitutionProducts([]);
                setInstitutionBranchesMeta({ page: 1, total: 1 });
                setInstitutionProductsMeta({ page: 1, total: 1 });
                setCurrentBranchPage(1);
                setCurrentActiveKey('branches');
                setSelectedInstitution(data);
              }
            }}
          >
            <UnorderedListOutlined /> {'View Details'}
          </Button>
        );
      },
      key: 'service',
    },
  ];
};

export const InsitutitionImportTable = ({
  setSelectedInstitution,
  setSelectedInstitutionBranches,
  setInstitutionBranchesMeta,
  setCurrentBranchPage,
  selectedInsitution,
  setCurrentActiveKey,
  setSelectedInstitutionProducts,
  setInstitutionProductsMeta,
  country,
  service,
}: any) => {
  const dispatch = useDispatch();
  const canAccess = useCanAccess()


  const getData = (params = ''): any => {
    return getImportInsitututions(
      `${params}&country=${country}&service=${service}`
    );
  };

  const columnActions = {
    setSelectedInstitution: setSelectedInstitution,
    setSelectedInstitutionBranches: setSelectedInstitutionBranches,
    setInstitutionBranchesMeta: setInstitutionBranchesMeta,
    setCurrentBranchPage: setCurrentBranchPage,
    selectedInsitution: selectedInsitution,
    setCurrentActiveKey: setCurrentActiveKey,
    setSelectedInstitutionProducts: setSelectedInstitutionProducts,
    setInstitutionProductsMeta: setInstitutionProductsMeta,
    canAccess
  };

  const handleOnChangeSelected = (items: any) => {
    dispatch(institutionActions.setSelectedInstitution(items));
  };

  return (
    <div className="bg-white padding-top-2">
      {country && service && (
        <Table
          refresh={false}
          filterOptions={true}
          tableName={INSTITUTE_TITLE}
          columns={columns(columnActions)}
          action={getData}
          className="institution-table"
          onChangeSelected={handleOnChangeSelected}
        />
      )}
    </div>
  );
};
