import { IAction } from '@shared-components/models';
import * as actionTypes from './role-permission.constant';

export const getRolesRequest = (): IAction<any> => ({
  type: actionTypes.GET_ROLES_REQUEST,
});
export const getRolesSuccess = (payload: any[]): IAction<any[]> => ({
  type: actionTypes.GET_ROLES_SUCCESS,
  payload,
});
export const getRolesFailure = (payload: any[]): IAction<any[]> => ({
  type: actionTypes.GET_ROLES_FAILURE,
  payload,
});
