import { getBranchesByUserContactPermission } from '@crm/services.api';
import { useQuery } from '@tanstack/react-query';

export const useAssignedBranch = () => {
  const { data, isLoading } = useQuery({
    queryFn: () => getBranchesByUserContactPermission({ page: 0, maxLimit: 0, limit: 0 }),
    queryKey: ['fetch-all-branches-by-user-contact-permission'],
  })

  return {
    branches: data?.data?.data ?? [],
    isLoading
  }
}

