import { productServices } from '@lyra/services.api';
import { errorHandler } from '@moxie/utils';
import { AxiosResponse } from 'axios';
import {
  call,
  CallEffect,
  ForkEffect,
  PutEffect,
  put,
  takeLatest,
} from 'redux-saga/effects';
import { Product } from '../constants';
import { productsActions } from './products.reducer';

const getProducts = function* (): Generator<
  | CallEffect<unknown>
  | PutEffect<{
      payload: any;
      type: string;
    }>
> {
  try {
    const res: any = yield call(productServices.apiGetProduct);
    yield put(productsActions.setProducts(res.data.data));
  } catch (err) {
    errorHandler(err);
  }
};

const getProductsFees = function* (
  data: any
): Generator<
  | CallEffect<unknown>
  | PutEffect<{
      payload: any;
      type: string;
    }>
> {
  try {
    const res: AxiosResponse | any = yield call(
      productServices.apiGetProductFees,
      data.payload
    );
    yield put(productsActions.setProductsFees(res?.data?.data));
  } catch (err) {
    errorHandler(err);
  }
};

const getProduct = function* (data: any) {
  try {
    yield put(productsActions.setProductLoading());
    const res: AxiosResponse = yield call(
      productServices.apiGetProductDetail,
      data.payload
    );
    yield put(productsActions.setProduct(res.data.data));
    const fees = res.data.data.fees;
    yield put(productsActions.setProductsFees(fees));
  } catch (e) {
    errorHandler(e);
    yield put(productsActions.setError(true));
  }
};

const resetProduct = function* () {
  yield put(productsActions.clearProduct());
};

const clearProductsFees = function* () {
  yield put(productsActions.clearProductsFees());
};

const setProductFilters = function* (): any {
  try {
    const res: AxiosResponse = yield productServices.apiGetProductFiltersData();
    yield put(productsActions.setFilters(res.data));
  } catch (err) {
    errorHandler(err);
  }
};

export default function* productSagas(): Generator<
  ForkEffect<never>,
  void,
  unknown
> {
  yield takeLatest(productsActions.fetchProducts.type, getProducts);
  yield takeLatest(Product.FETCH_PRODUCT, getProduct);
  yield takeLatest(Product.FETCH_PRODUCT_CLEAR, resetProduct);
  yield takeLatest(Product.FETCH_PRODUCT_FEES, getProductsFees);
  yield takeLatest(Product.FETCH_PRODUCT_FEES_CLEAR, clearProductsFees);
  yield takeLatest(productsActions.requestFilters.type, setProductFilters);
}
