import React, { useState } from 'react';
import { PlusOutlined } from '@ant-design/icons';
import {
  ADD_APPLICATION_BTN,
  CONFIRM_MESSAGE,
  DELETE_BTN,
  LABEL,
} from '@moxie/constants';
import { Button, popConfirm } from '@moxie/shared';
import { LeadApplicationDrawer } from './application-drawer';
import { useForm } from 'antd/lib/form/Form';
import { getLeadApplications } from '@lyra/services.api';
import { errorHandler } from '@moxie/utils';
import { ILeadApplicationForm } from '@shared-components/models';
import { useParams } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import { applicationActions } from '@lyra/core';
import LeadApplicationTable from './application-table';
import Can, { PermissionAction, PermissionObjects } from '@lyra/privilege';
import { useAppSelector } from '@admin/core';

const LeadApplicationList = () => {
  const [refreshTable, setRefreshTable] = useState(true);
  const [visible, setVisible] = useState(false);
  const [isEdit, setIsEdit] = useState(false);
  const [isSubmitted, setIsSubmitted] = useState(false);
  const [initialData, setInitialData] = useState<any>();
  const [form] = useForm();
  const dispatch = useDispatch();
  const { id: contactId } = useParams<{ id: string }>();
  const leadData = useAppSelector((store) => store.lead.lead);
  const [filter] = useState({});
  const currentUser = useAppSelector((store) => store.auth.user);
  const socket = useAppSelector((store) => store.socket.wss);
  const triggerRefresh = () => setRefreshTable((prev) => !prev);

  const getApplications = async (params: string) => {
    return getLeadApplications(contactId, params);
  };

  const handleFormSubmit = () => {
    form.submit();
  };

  const handleDelete = async (id: string) => {
    popConfirm(
      DELETE_BTN,
      `${CONFIRM_MESSAGE.DELETE_APPLICATION}`,
      async () => {
        try {
          dispatch(
            applicationActions.deleteApplicationRequest(
              id,
              currentUser,
              socket,
              leadData
            )
          );
        } catch (err) {
          errorHandler(err);
        }
        triggerRefresh();
      }
    );
  };

  const onClose = () => {
    setVisible(false);
    setIsEdit(false);
    triggerRefresh();
    form.resetFields();
  };

  const handleSubmit = async (data: ILeadApplicationForm) => {
    setIsSubmitted(true);
    dispatch(
      applicationActions.addRequest(
        { ...data, contact_id: contactId },
        currentUser,
        socket,
        leadData,
        onClose,
        setIsSubmitted
      )
    );
  };

  const showDrawer = async () => {
    setInitialData({});
    setVisible(true);
  };

  return (
    <div className="padding-top-1">
      <LeadApplicationDrawer
        isOpen={visible}
        onClose={onClose}
        handleFormSubmit={handleFormSubmit}
        isSubmitted={isSubmitted}
        isEdit={isEdit}
        form={form}
        handleSubmit={handleSubmit}
        initialData={initialData}
      />
      <LeadApplicationTable
        tableName={LABEL.LEAD_APPLICATIONS}
        getData={getApplications}
        refreshTable={refreshTable}
        setRefreshTable={setRefreshTable}
        deleteData={handleDelete}
        filter={filter}
        tableButtons={
          Can(PermissionAction.Create, PermissionObjects.ClientApplication) ? (
            <>
              {!leadData?.archived && (
                <Button
                  type="primary"
                  icon={<PlusOutlined />}
                  onClick={() => showDrawer()}
                >
                  {ADD_APPLICATION_BTN}
                </Button>
              )}
            </>
          ) : null
        }
      />
    </div>
  );
};

export { LeadApplicationList };
