import * as otherDetails from './setting-other-details.constant';
import * as otherDetailsAction from './setting-other-details.action';
import { put, takeLatest } from '@redux-saga/core/effects';
import { AxiosResponse } from 'axios';
import {
  deleteOtherDetailsCategory,
  deleteOtherDetailsLabel,
  getOtherDetailsCategory,
  getOtherDetailsCategoryWfStageId,
  getOtherDetailsLabel,
  postOtherDetailsCategory,
  postOtherDetailsLabel,
  putOtherDetailsCategory,
  putOtherDetailsLabel,
} from '@lyra/services.api';
import {
  IAction,
  IOtherDetailsCategory,
  IOtherDetailsLabel,
} from '@shared-components/models';
import { otherDetailActions } from '..';
import { errorHandler } from '@moxie/utils';

function* fetchOtherDetailsCategories() {
  try {
    const res: AxiosResponse<any> = yield getOtherDetailsCategory();
    yield put(otherDetailsAction.getCategories(res.data.data));
    if (res.data.data.length > 0)
      yield put(otherDetailActions.selectCategoryRequest(res.data.data[0].id));
  } catch (err) {
    errorHandler(err);
    yield put(otherDetailsAction.categoryRequestFailure());
  }
}

function* fetchOtherDetailsCategoriesByWfStageId({ payload }: IAction<string>) {
  if (!payload) return;
  try {
    const res: AxiosResponse<any> = yield getOtherDetailsCategoryWfStageId(
      payload
    );
    yield put(otherDetailsAction.getCategories(res.data.data));
    if (res.data.data.length > 0)
      yield put(otherDetailActions.selectCategoryRequest(res.data.data[0].id));
  } catch (err) {
    errorHandler(err);
    yield put(otherDetailsAction.fetchOtherCategoryRequestFailure());
  }
}

function* addOtherDetailsCategory({ payload }: IAction<IOtherDetailsCategory>) {
  if (payload) {
    try {
      const res: AxiosResponse<any> = yield postOtherDetailsCategory(payload);
      yield put(otherDetailActions.addCategory(res.data.data));
    } catch (err) {
      errorHandler(err);
      yield put(otherDetailActions.categoryRequestFailure());
    }
  }
}

function* updateOtherDetailsCategory({
  payload,
}: IAction<IOtherDetailsCategory>) {
  if (payload) {
    try {
      const res: AxiosResponse<any> = yield putOtherDetailsCategory(
        payload.id,
        payload
      );
      yield put(otherDetailActions.editCategory(res.data.data));
    } catch (err) {
      errorHandler(err);
      yield put(otherDetailActions.categoryRequestFailure());
    }
  }
}

function* removeOtherDetailsCategory({ payload }: IAction<string>) {
  if (payload) {
    try {
      yield deleteOtherDetailsCategory(payload);
      yield put(otherDetailActions.deleteCategory(payload));
    } catch (err) {
      errorHandler(err);
      yield put(otherDetailActions.categoryRequestFailure());
    }
  }
}

function* fetchOtherDetailsLabel({ payload }: IAction<string>) {
  if (payload) {
    try {
      const response: AxiosResponse<any> = yield getOtherDetailsLabel(payload);
      yield put(otherDetailActions.getLabel(response?.data?.data || []));
    } catch (err) {
      errorHandler(err);
      yield put(otherDetailActions.labelRequestFailure());
    }
  }
}

function* selectCategory({ payload }: IAction<string>) {
  if (payload) {
    yield put(otherDetailActions.getLabelRequest(payload));
    yield put(otherDetailActions.selectCategory(payload));
  }
}

function* addOtherDetailsLabel({ payload }: IAction<IOtherDetailsLabel>) {
  if (payload) {
    try {
      const res: AxiosResponse<any> = yield postOtherDetailsLabel(payload);
      yield put(otherDetailActions.addLabel(res?.data?.data));
    } catch (err) {
      errorHandler(err);
      yield put(otherDetailActions.labelRequestFailure());
    }
  }
}

function* removeOtherDetailsLabel({ payload }: IAction<string>) {
  if (payload) {
    try {
      yield deleteOtherDetailsLabel(payload);
      yield put(otherDetailActions.deleteLabel(payload));
    } catch (err) {
      errorHandler(err);
      yield put(otherDetailActions.labelRequestFailure());
    }
  }
}

function* updateOtherDetailsLabel({ payload }: IAction<IOtherDetailsLabel>) {
  if (payload) {
    try {
      const res: AxiosResponse<any> = yield putOtherDetailsLabel(
        payload?.id || '',
        payload
      );
      yield put(otherDetailActions.editLabel(res?.data?.data));
    } catch (err) {
      errorHandler(err);
      yield put(otherDetailActions.labelRequestFailure());
    }
  }
}

function* addOtherDetailsWithOptionLabel({
  payload,
}: IAction<IOtherDetailsLabel>) {
  if (payload) {
    try {
      const res: AxiosResponse<any> = yield postOtherDetailsLabel(payload);
      yield put(otherDetailActions.addLabel(res?.data?.data));
    } catch (err) {
      errorHandler(err);
      yield put(otherDetailActions.labelRequestFailure());
    }
  }
}

export default function* rootSaga(): unknown {
  yield takeLatest(
    otherDetails.GET_CATEGORIES_REQUEST,
    fetchOtherDetailsCategories
  );
  yield takeLatest(
    otherDetails.GET_CATEGORIES_REQUEST_BY_WF_STAGE,
    fetchOtherDetailsCategoriesByWfStageId
  );
  yield takeLatest(otherDetails.POST_CATEGORY_REQUEST, addOtherDetailsCategory);
  yield takeLatest(
    otherDetails.PUT_CATEGORY_REQUEST,
    updateOtherDetailsCategory
  );
  yield takeLatest(
    otherDetails.DELETE_CATEGORY_REQUEST,
    removeOtherDetailsCategory
  );
  yield takeLatest(otherDetails.SELECT_CATEGORY_REQUEST, selectCategory);
  yield takeLatest(
    otherDetails.GET_CATEGORIES_LABEL_REQUEST,
    fetchOtherDetailsLabel
  );
  yield takeLatest(
    otherDetails.POST_CATEGORY_LABEL_REQUEST,
    addOtherDetailsLabel
  );
  yield takeLatest(
    otherDetails.DELETE_CATEGORY_LABEL_REQUEST,
    removeOtherDetailsLabel
  );
  yield takeLatest(
    otherDetails.PUT_CATEGORY_LABEL_REQUEST,
    updateOtherDetailsLabel
  );
  yield takeLatest(
    otherDetails.POST_CATEGORY_LABEL_WITH_OPTION_REQUEST,
    addOtherDetailsWithOptionLabel
  );
}
