import React, { useState } from 'react';
import { Upload, Row, Col } from 'antd';
import {
  LoadingOutlined,
  UserOutlined,
  DeleteOutlined,
} from '@ant-design/icons';
import { uploadAvatar } from '@lyra/services.api';
import { errorHandler } from '@moxie/utils';
import { Button } from '@shared-components/elements';
import { FILE_SIZE_INVALID, FILE_TYPE_INVALID } from '@moxie/constants';
import { errorNotificationHandler } from '../../shared';
import { IAvatarProps } from '@shared-components/models';

// eslint-disable-next-line no-unused-vars
function getBase64(img: Blob, callback: (data: any) => void) {
  const reader = new FileReader();
  reader.addEventListener('load', () => callback(reader.result));
  reader.readAsDataURL(img);
}

function beforeUpload(file: Blob) {
  const isJpgOrPng = file.type === 'image/jpeg' || file.type === 'image/png';
  if (!isJpgOrPng) {
    errorNotificationHandler(FILE_TYPE_INVALID);
  }
  const isLt2M = file.size / 1024 / 1024 < 2;
  if (!isLt2M) {
    errorNotificationHandler(FILE_SIZE_INVALID);
  }
  return isJpgOrPng && isLt2M;
}

const Avatar: React.FC<IAvatarProps> = ({
  onChange,
  src,
  disabled,
}: IAvatarProps) => {
  const [loading, setLoading] = useState(false);
  const [imageUrl, setImageUrl] = useState(src || '');

  const handleChange = (info: any) => {
    if (info.file.status === 'uploading') {
      setLoading(true);
      return;
    }
    if (info.file.status === 'done') {
      getBase64(info.file.originFileObj, () => {
        setLoading(false);
      });
    }
    setLoading(false);
  };

  const handleRemoveImage = () => {
    onChange('');
    setImageUrl('');
  };

  const uploadImage = async (options: any) => {
    const { onSuccess, onError, file, onProgress } = options;
    const formData = new FormData();
    const config = {
      headers: { 'content-type': 'mutipart/form-data' },
      onUploadProgress: (event: any) => {
        const percent = Math.floor((event.loaded / event.total) * 100);
        if (onProgress) onProgress({ percent });
      },
    };
    formData.append('image', file);
    try {
      const res = await uploadAvatar(formData, config);
      if (res.data.success) {
        onChange(res.data?.data?.path);
        setImageUrl(res.data?.data?.path);
        onSuccess(true);
      } else onSuccess(false);
    } catch (err) {
      errorHandler(err);
      onError(err);
    }
  };

  const uploadButton = (
    <div className="avatar-uploader__upload-btn">
      {loading ? (
        <LoadingOutlined />
      ) : (
        <UserOutlined className="avatar-uploader__icon" />
      )}
    </div>
  );

  return (
    <div className="avatar-uploader">
      <Upload
        name="avatar"
        listType="picture-card"
        multiple
        showUploadList={false}
        className="avatar-uploader__upload"
        action={`${process.env.NX_BASE_URL}/upload-url`}
        customRequest={uploadImage}
        beforeUpload={beforeUpload}
        onChange={handleChange}
      >
        {imageUrl ? (
          <Row>
            <Col span={24}>
              <img
                src={imageUrl}
                className="avatar-uploader__image"
                alt="avatar"
              />
            </Col>
          </Row>
        ) : (
          uploadButton
        )}
      </Upload>

      {imageUrl && !disabled && (
        <Row>
          <Col span={24} className="text-align-center">
            <Button
              icon={<DeleteOutlined className="avatar-uploader__delete" />}
              type="link"
              shape="round"
              danger
              onClick={handleRemoveImage}
            />
          </Col>
        </Row>
      )}
    </div>
  );
};

Avatar.defaultProps = {
  disabled: false,
};

export { Avatar };
