import React from 'react';
import { Login as LoginModule } from '@moxie/shared';

const Login: React.FC = () => {
  return (
    <div className="login-layout">
      <LoginModule />
    </div>
  );
};

export default Login;
