import React from 'react';
import { Card, Col, Row, Space, Tooltip } from 'antd';
import { IDepartment } from '@shared-components/models';
import { EditOutlined } from '@ant-design/icons';
import { Button, PageHeaderMenu, UserTable } from '@moxie/shared';
import { PAGE_HEADER_MENU_DEPARTMENT_DETAIL } from '@moxie/constants';
import { getDepartmentUsers } from '@lyra/services.api';

const DepartmentProfile: React.FC<any> = ({
  data,
  handleOpen,
}: {
  data: IDepartment;
  handleOpen: () => void;
}) => {
  const getUserData = async (str: string) => {
    return await getDepartmentUsers(data.id || '', str);
  };
  return (
    <>
      <section className="profile">
        <Card>
          <Row gutter={24} justify="space-between">
            <Col>
              <div className="profile__info">
                <div className="profile__info__item profile__info__user">
                  <Space>
                    <h3 className="profile__info__user__detail margin-top-1 initial_capital">
                      {data.name}
                    </h3>
                  </Space>
                </div>
              </div>
            </Col>
            <Col className="margin-top-1">
              <Tooltip title="Edit" className="profile__info__edit">
                <Button
                  icon={<EditOutlined />}
                  onClick={handleOpen}
                  type="link"
                />
              </Tooltip>
            </Col>
            <Col span={24}>
              <div className="profile__info__item">
                <div className="profile__info__user__detail ">
                  {data.description}
                </div>
              </div>
            </Col>
          </Row>
        </Card>
      </section>
      <div className="margin-top-2">
        <PageHeaderMenu menu={PAGE_HEADER_MENU_DEPARTMENT_DETAIL} />
        <Row>
          <Col span={24} className="padding-top-2">
            <UserTable getData={getUserData} />
          </Col>
        </Row>
      </div>
    </>
  );
};

export { DepartmentProfile };
