import { MenuItem, Select, Option, Input } from '@shared-components/elements';
import { IOtherDetailsLabel } from '@shared-components/models';
import { Button, Col, Dropdown, Form, Row, Spin, Typography } from 'antd';
import React from 'react';
import {
  MoreOutlined,
  MinusCircleOutlined,
  CheckOutlined,
} from '@ant-design/icons';
import {
  BUTTON,
  LABEL_MAX,
  LABEL_OPTION,
  LABEL_OPTION_REQUIRED,
  LABEL_REQUIRED,
  MINIMUM_SIZE_OPTION_REQUIRED,
  PLACEHOLDER,
} from '@moxie/constants';
import { useDetailLabel } from './use-detail-label.hook';
import { LabelOptionDrawer } from './add-label-option-drawer';

const { Title, Text } = Typography;

const OtherDetailsLabel: React.FC = () => {
  const {
    form,
    loading,
    editForm,
    labelList,
    tempOptions,
    isLabelAdd,
    drawerIsOpen,
    selectedLabel,
    selectedOption,
    selectedCategory,
    labelDataLoading,
    optionInitialData,
    handleAddOption,
    handleUpdateOption,
    handleDrawer,
    handleAddCancel,
    handleEditSubmit,
    handleEditBtn,
    handleSubmit,
    handleDeleteBtn,
    handleAddLabel,
    handleEditCancel,
    handleFormChange,
  } = useDetailLabel();

  const optionListValidator = {
    validator(_: any, value: any) {
      if (value === LABEL_OPTION[2]) {
        if (tempOptions.length < 2)
          return Promise.reject(MINIMUM_SIZE_OPTION_REQUIRED);
      }
      return Promise.resolve();
    },
  };

  const handleEditFinish = (e: IOtherDetailsLabel) => {
    handleEditSubmit(e);
  };

  const handleAddFinish = (e: IOtherDetailsLabel) => {
    handleSubmit(e);
  };
  if (selectedCategory?.length > 0)
    return (
      <Col span={16} className="other-details__labels">
        <LabelOptionDrawer
          isOpen={drawerIsOpen}
          onClose={() => handleDrawer(false)}
          selectedLabel={selectedLabel}
          initialData={optionInitialData}
        />
        <Spin spinning={labelDataLoading}>
          <Row>
            <Title level={5}>Labels for {selectedCategory} </Title>
          </Row>
          <Row gutter={[0, 8]}>
            {(labelList || []).map((item: IOtherDetailsLabel) => {
              return (
                <Col
                  span={24}
                  className="other-details__labels__item"
                  key={item.id}
                >
                  {item.id !== selectedLabel ? (
                    <>
                      <Col span={8}>{item.label}</Col>
                      <Col span={8}>
                        <Text disabled>Type: {item.data_type}</Text>
                      </Col>
                      <Col span={8} className="text-align-right">
                        <Dropdown
                          overlay={MenuItem(
                            () => handleEditBtn(item?.id || ''),
                            () => handleDeleteBtn(item?.id || '')
                          )}
                        >
                          <Button type="link" icon={<MoreOutlined />} />
                        </Dropdown>
                      </Col>
                    </>
                  ) : (
                    <Form
                      onFinish={handleEditFinish}
                      onValuesChange={handleFormChange}
                      initialValues={item}
                      className="full-width"
                      form={editForm}
                    >
                      <Row wrap={false}>
                        <Col flex="1 1 auto" className="padding-right-2">
                          <Form.Item
                            name="label"
                            rules={[
                              {
                                required: true,
                                message: LABEL_REQUIRED,
                                type: 'string',
                                whitespace: true,
                              },
                              {
                                max: 30,
                                message: LABEL_MAX,
                              },
                            ]}
                          >
                            <Input
                              placeholder={PLACEHOLDER.LABEL}
                              className="general-setting-category-input"
                            />
                          </Form.Item>
                        </Col>
                        <Col flex="1 1 auto">
                          <Form.Item
                            name="data_type"
                            validateStatus="validating"
                            rules={[
                              {
                                required: true,
                                message: LABEL_OPTION_REQUIRED,
                              },
                              optionListValidator,
                            ]}
                          >
                            <Select placeholder={PLACEHOLDER.LABEL_TYPE}>
                              {LABEL_OPTION.map((value) => (
                                <Option key={value} value={value}>
                                  {value}
                                </Option>
                              ))}
                            </Select>
                          </Form.Item>
                        </Col>
                        <Col span={5} className="padding-left-2">
                          {(!editForm.getFieldValue('data_type') &&
                            item.data_type === LABEL_OPTION[2]) ||
                          editForm.getFieldValue('data_type') ===
                            LABEL_OPTION[2] ||
                          (selectedOption?.label === item.id &&
                            selectedOption.data_type === LABEL_OPTION[2]) ? (
                            <Button size="middle" onClick={handleUpdateOption}>
                              {editForm.getFieldValue('options')?.length > 0
                                ? BUTTON.UPDATE_OPTION
                                : BUTTON.ADD_OPTION}
                            </Button>
                          ) : null}
                        </Col>
                        <Col
                          className="space-between padding-left-2"
                          flex="1 1 5rem"
                        >
                          <Row justify="end" wrap={false}>
                            <Button
                              icon={<CheckOutlined />}
                              loading={loading}
                              type="link"
                              onClick={() => editForm.submit()}
                              size="middle"
                              className="padding-left-1 padding-right-2"
                            />
                            <Button
                              icon={<MinusCircleOutlined />}
                              onClick={handleEditCancel}
                              type="link"
                              size="middle"
                              danger
                            />
                          </Row>
                        </Col>
                      </Row>
                    </Form>
                  )}
                </Col>
              );
            })}
            <Col span={24}>
              {isLabelAdd && (
                <Form
                  onFinish={handleAddFinish}
                  onValuesChange={handleFormChange}
                  form={form}
                >
                  <Row wrap={false}>
                    <Col span={8} className="padding-right-2">
                      <Form.Item
                        name="label"
                        rules={[
                          {
                            required: true,
                            message: LABEL_REQUIRED,
                            type: 'string',
                            whitespace: true,
                          },
                          {
                            max: 30,
                            message: LABEL_MAX,
                          },
                        ]}
                      >
                        <Input
                          placeholder={PLACEHOLDER.LABEL}
                          className="general-setting-category-input"
                        />
                      </Form.Item>
                    </Col>
                    <Col span={8} flex="1 1 auto">
                      <Form.Item
                        name="data_type"
                        validateStatus="validating"
                        rules={[
                          { required: true, message: LABEL_OPTION_REQUIRED },
                          optionListValidator,
                        ]}
                      >
                        <Select placeholder={PLACEHOLDER.LABEL_TYPE}>
                          {LABEL_OPTION.map((value) => (
                            <Option key={value} value={value}>
                              {value}
                            </Option>
                          ))}
                        </Select>
                      </Form.Item>
                    </Col>
                    <Col className="padding-left-2" flex="1 1">
                      {form.getFieldValue('data_type') === LABEL_OPTION[2] && (
                        <Button size="middle" onClick={handleAddOption}>
                          {BUTTON.ADD_OPTION}
                        </Button>
                      )}
                    </Col>
                    <Col
                      flex={'5rem'}
                      className="space-between padding-left-2 padding-right-1"
                    >
                      <Row justify="space-between">
                        <Button
                          icon={<CheckOutlined />}
                          loading={loading}
                          type="link"
                          onClick={() => form.submit()}
                          size="small"
                          className="padding-left-1"
                        />
                        <Button
                          icon={<MinusCircleOutlined />}
                          onClick={handleAddCancel}
                          type="link"
                          danger
                          size="small"
                        />
                      </Row>
                    </Col>
                  </Row>
                </Form>
              )}
            </Col>
            <Col span={24} className="padding-top-1">
              <Button
                type="default"
                disabled={isLabelAdd}
                className="full-width"
                size="middle"
                onClick={handleAddLabel}
              >
                {BUTTON.ADD_LABEL}
              </Button>
            </Col>
          </Row>
        </Spin>
      </Col>
    );
  else return null;
};

export { OtherDetailsLabel };
