import React from "react";
import { Card, Col, Row, Tag, Typography } from "antd";
import { ContactEnquiry } from "@model/index";
import moment from "moment";
import { ServiceList } from "@crm/src/shared/interested-service-tree/service-list-card";
import { UserCard } from "./user-card";
import { UnassignedCard } from "../../shared";

interface AcrtiveEnquiryProps {
  enquiry: ContactEnquiry;
}

const ActiveEnquiriesCard = ({ enquiry }: AcrtiveEnquiryProps) => {
  return (
    <Card className="office-visit__enquiries__card">
      <Row>
        <Col span={18}>
          <Row><Typography.Text className="office-visit__enquiries__label">Enquiries Id</Typography.Text></Row>
          <Row><Typography.Text className="office-visit__enquiries__text">{enquiry?.internalId}</Typography.Text></Row>
        </Col>
        <Col span={5} offset={1}>
          <Row><Typography.Text className="office-visit__enquiries__label">Source</Typography.Text></Row>
          <Row><Typography.Text className="office-visit__enquiries__text">{enquiry?.source}</Typography.Text></Row>
        </Col>
      </Row>
      <Row className="margin-top-1">
        <Col span={18}>
          <Row><Typography.Text className="office-visit__enquiries__label">Interested Services</Typography.Text></Row>
          <Row>
            {enquiry?.interestedService ? <ServiceList currentService={{
              parent: enquiry.interestedService.serviceName,
              countries: enquiry?.interestedService.destinationCountry,
              interests: Array.isArray(enquiry.interestedService.interests) ? enquiry.interestedService.interests : [enquiry.interestedService.interests].filter(Boolean) as string[],
              id: enquiry.interestedService.interestedServiceId
            }} /> : null}
          </Row>
        </Col>
        <Col span={5} offset={1}>
          <Row><Typography.Text className="office-visit__enquiries__label">Created Date: </Typography.Text></Row>
          <Row><Typography.Text className="office-visit__enquiries__text">{moment(enquiry?.createdAt).format("DD-MM-YYYY")}</Typography.Text></Row>
        </Col>
      </Row>
      <Row className="margin-top-1">
        <Col span={18}>
          <Row><Typography.Text className="office-visit__enquiries__label">Assignee</Typography.Text></Row>
          <Row>
            {enquiry?.assignee ? (
              <UserCard firstName={enquiry?.assignee?.firstName} lastName={enquiry?.assignee?.lastName} email={enquiry?.assignee?.email} />
            ) : <UnassignedCard />}
          </Row>
        </Col>
        <Col span={5} offset={1}>
          <Row><Typography.Text className="office-visit__enquiries__label">Status</Typography.Text></Row>
          <Row className="w-100">
            <Tag color={enquiry?.status === "new" ||
              enquiry?.status === "contacted" ||
              enquiry?.status === "evaluating"
              ? "default"
              : "lime"}
              className="initial_capital w-100">
              {enquiry?.status}
            </Tag>
          </Row>
        </Col>
      </Row>
    </Card>
  )
}

export { ActiveEnquiriesCard };
