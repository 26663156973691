import { IAction } from '@shared-components/models';
import * as actionTypes from './country-service.constant';

export interface ICountryByServiceState {
  serviceCountries: any[];
  serviceCountry: any;
  error: boolean | null;
  loading: boolean;
}

const initialCountryByServiceState: ICountryByServiceState = {
  serviceCountries: [],
  serviceCountry: {},
  error: null,
  loading: false,
};
export const COUNTRY_BY_SERVICE_FEATURE_KEY = 'country_by_service';

export const countryByServiceReducer = (
  state = initialCountryByServiceState,
  action: IAction<any>
): ICountryByServiceState => {
  switch (action.type) {
    case actionTypes.GET_COUNTRY_BY_SERVICE_REQUEST:
      return {
        ...state,
        loading: true,
        error: null,
      };
    case actionTypes.GET_COUNTRY_BY_SERVICE_SUCCESS:
      return {
        ...state,
        loading: false,
        error: null,
        serviceCountries: action.payload,
      };
    case actionTypes.GET_COUNTRY_BY_SERVICE_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.payload,
      };
    
    case actionTypes.GET_COUNTRY_BY_SERVICE_RESET:
      return {
        ...state,
        serviceCountries: [],
        serviceCountry: {}
      };
      
    default:
      return state;
  }
};
