import React from 'react';
import moment from 'moment';
import { Col, Collapse, Divider, Row, Typography } from 'antd';

import {
  GUTTER,
  LABEL,
  OTHER_TEST_KEY_VALUE,
  TEXT,
  TITLE,
} from '@moxie/constants';
import { capitalizeFirstLetter, ViewEducationCard } from '@moxie/shared';
import { ILeadLanguageType } from '@model/data.model';
import { useAppSelector } from '@lyra/core';
const { Text } = Typography;
const QualificationTab: React.FC<any> = (props: any) => {
  const { lead } = props;
  const {
    educationBackground,
    languageTestScore,
    otherTestScoreList,
  } = useAppSelector((store) => ({
    educationBackground: store.education_background.educationBackgrounds,
    languageTestScore: store.language_test_score.languageTestScoreList,
    otherTestScoreLoading: store.other_test_score.loading,
    otherTestScoreList: store.other_test_score.otherTestScoreList,
  }));
  return (
    <div className="overflow-scroll">
      <Row justify="end">
        <div className="padding-right-2" />
      </Row>
      <Collapse defaultActiveKey={['1']} ghost>
        <Collapse.Panel
          header={
            <Text className="education-background-title" strong>
              {TITLE.EDUCATION_BACKGROUND}
            </Text>
          }
          key="1"
        >
          <Row gutter={GUTTER}>
            {educationBackground.map((item) => {
              return (
                <Col
                  key={item.id}
                  xxl={{ span: 6 }}
                  span={8}
                  className="margin-bottom-2"
                >
                  <ViewEducationCard
                    lead={lead}
                    title={capitalizeFirstLetter(item.degree_title || '')}
                    superscript={capitalizeFirstLetter(item.institution || '')}
                    subscript={
                      `${
                        item.course_start
                          ? `${moment(item.course_start)
                              .format('YYYY MMM')
                              .toString()} - ${moment(item.course_end)
                              .format('YYYY MMM ')
                              .toString()}`
                          : ''
                      }` || ''
                    }
                  >
                    <Row className="padding-bottom-1">
                      <Col span={12}>
                        <strong>
                          {capitalizeFirstLetter(item?.subject_area || '')}
                        </strong>
                      </Col>
                      <Col span={12}>
                        {capitalizeFirstLetter(item?.subject || '')}
                      </Col>
                    </Row>
                    <Row>
                      <Col span={12}>{LABEL.ACADEMIC_SCORE}</Col>
                      <Col span={12}>
                        {item.percentage
                          ? item.percentage + '%'
                          : item.secured_gpa
                          ? item.secured_gpa + '/' + item.total_gpa
                          : TEXT.NOT_AVAILABLE}
                      </Col>
                    </Row>
                  </ViewEducationCard>
                </Col>
              );
            })}
          </Row>
        </Collapse.Panel>
      </Collapse>
      <Collapse defaultActiveKey={['1']} ghost>
        <Collapse.Panel
          header={
            <Text className="education-background-title" strong>
              {TITLE.ENGLISH_TEST_SCORES}
            </Text>
          }
          key="1"
        >
          <Row gutter={GUTTER}>
            {languageTestScore.map((item: ILeadLanguageType) => {
              return (
                <Col key={item.id} xxl={{ span: 6 }} span={8}>
                  <ViewEducationCard
                    lead={lead}
                    title={item.name?.toUpperCase() || ''}
                  >
                    <Row className="padding-bottom-1" gutter={GUTTER}>
                      <Col span={12} className="padding-bottom-1">
                        <Row justify="space-between">
                          <div>{LABEL.READING}</div>
                          <div>
                            {item.test_types?.find(
                              (item) =>
                                item.name === LABEL.READING.toLowerCase()
                            )?.score || '-'}
                          </div>
                        </Row>
                      </Col>
                      <Col span={12} className="padding-bottom-1">
                        <Row justify="space-between">
                          <div>{LABEL.WRITING}</div>
                          <div>
                            {item.test_types?.find(
                              (item) =>
                                item.name === LABEL.WRITING.toLowerCase()
                            )?.score || '-'}
                          </div>
                        </Row>
                      </Col>
                      <Col span={12} className="padding-bottom-1">
                        <Row justify="space-between">
                          <div>{LABEL.LISTENING}</div>
                          <div>
                            {item.test_types?.find(
                              (item) =>
                                item.name === LABEL.LISTENING.toLowerCase()
                            )?.score || '-'}
                          </div>
                        </Row>
                      </Col>
                      <Col span={12} className="padding-bottom-1">
                        <Row justify="space-between">
                          <div className="">{LABEL.SPEAKING}</div>
                          <div>
                            {item.test_types?.find(
                              (item) =>
                                item.name === LABEL.SPEAKING.toLowerCase()
                            )?.score || '-'}
                          </div>
                        </Row>
                      </Col>
                    </Row>
                    <Divider />
                    <Row>
                      <Col span={24}>
                        <Row justify="space-between">
                          <div className="overall_score_label">
                            {LABEL.OVERALL_SCORE}
                          </div>
                          <div className="overall_score">
                            {item.overall_score || '-'}
                          </div>
                        </Row>
                      </Col>
                    </Row>
                  </ViewEducationCard>
                </Col>
              );
            })}
          </Row>
        </Collapse.Panel>
      </Collapse>
      <Collapse defaultActiveKey={['1']} ghost>
        <Collapse.Panel
          header={
            <Text className="education-background-title" strong>
              {TITLE.OTHER_TEST_SCORES}
            </Text>
          }
          key="1"
        >
          <Row gutter={GUTTER}>
            {otherTestScoreList &&
              Object.keys(otherTestScoreList).map((item: string) => {
                if (
                  item === 'sat_1' ||
                  item === 'sat_2' ||
                  item === 'gre' ||
                  item === 'gmat'
                ) {
                  if (
                    otherTestScoreList[item] !== '0' &&
                    otherTestScoreList[item]
                  )
                    return (
                      <Col key={item} xxl={{ span: 6 }} span={8}>
                        <ViewEducationCard
                          lead={lead}
                          title={OTHER_TEST_KEY_VALUE[item] || ''}
                        >
                          <Row>
                            <Col span={24}>
                              <Row justify="space-between">
                                <div className="overall_score_label">
                                  {LABEL.OVERALL_SCORE}
                                </div>
                                <div className="overall_score">
                                  {otherTestScoreList[item] || '-'}
                                </div>
                              </Row>
                            </Col>
                          </Row>
                        </ViewEducationCard>
                      </Col>
                    );
                }
                return null;
              })}
          </Row>
        </Collapse.Panel>
      </Collapse>
    </div>
  );
};

export { QualificationTab };
