import { IAction, IInitialState, IService } from '@shared-components/models';
import * as actionTypes from './service.constant';
export const SERVICE_FEATURE_KEY = 'services';

interface AdminService {
  adminServices: []
}
export const initialServiceState: IInitialState<IService> &AdminService  = {
  allData: [],
  adminServices: [],
  singleData: {},
  error: null,
  loading: false,
};

const serviceReducer = (state = initialServiceState, action: IAction<any>) => {
  switch (action.type) {
    case actionTypes.GET_SERVICES_REQUEST:
      return {
        ...state,
        loading: true,
        error: null,
      };
    case actionTypes.GET_SERVICES_SUCCESS:
      return {
        ...state,
        loading: false,
        error: null,
        allData: action.payload,
      };
    case actionTypes.GET_SERVICES_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.payload,
      };
    case actionTypes.GET_PRODUCT_SERVICES_REQUEST:
      return {
        ...state,
        loading: true,
        error: null,
      };
    case actionTypes.GET_PRODUCT_SERVICES_SUCCESS:
      return {
        ...state,
        loading: false,
        error: null,
        allData: action.payload,
      };
    case actionTypes.GET_PRODUCT_SERVICES_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.payload,
      };
    case actionTypes.GET_ADMIN_SERVICES_REQUEST:
      return {
        ...state,
        loading: true,
        error: null,
      };
    case actionTypes.GET_ADMIN_SERVICES_SUCCESS:
      return {
        ...state,
        loading: false,
        error: null,
        adminServices: action.payload,
      };
    case actionTypes.GET_ADMIN_SERVICES_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.payload,
      };
    case actionTypes.GET_SERVICE_REQUEST:
      return {
        ...state,
        loading: true,
        error: null,
      };
    case actionTypes.GET_SERVICE_SUCCESS:
      return {
        ...state,
        loading: false,
        error: null,
        singleData: action.payload,
      };
    case actionTypes.GET_SERVICE_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.payload,
      };
    case actionTypes.ADD_SERVICE_REQUEST:
      return {
        ...state,
        loading: true,
        error: null,
      };
    case actionTypes.ADD_SERVICE_SUCCESS:
      return {
        ...state,
        loading: false,
        error: null,
      };
    case actionTypes.ADD_SERVICE_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.payload,
      };
    case actionTypes.SYNC_SERVICE_CREATE_REQUEST:
      return {
        ...state,
        loading: true,
        error: null,
      };
    case actionTypes.SYNC_SERVICE_CREATE_SUCCESS:
      return {
        ...state,
        loading: false,
        error: null,
        allData: [],
      };
    case actionTypes.SYNC_SERVICE_CREATE_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.payload,
      };
    case actionTypes.UPDATE_SERVICE_REQUEST:
      return {
        ...state,
        loading: true,
        error: null,
      };
    case actionTypes.UPDATE_SERVICE_SUCCESS:
      return {
        ...state,
        loading: false,
        error: null,
      };
    case actionTypes.UPDATE_SERVICE_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.payload,
      };
    case actionTypes.DELETE_SERVICE_REQUEST:
      return {
        ...state,
        loading: true,
        error: null,
      };
    case actionTypes.DELETE_SERVICE_SUCCESS:
      return {
        ...state,
        loading: false,
        error: null,
      };
    case actionTypes.DELETE_SERVICE_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.payload,
      };
    case actionTypes.CLEAR_SERVICE_FETCH:
      return {
        ...state,
        loading: false,
        error: null,
        allData: [],
        singleData: null,
      };
    default:
      return state;
  }
};

export { serviceReducer };
