import { getAllVisitActivityLogs } from '@crm/services.api';
import { IOfficeVisitActivityCrm } from '@model/crm/office-visit';
import { Empty, Timeline } from 'antd';
import moment from 'moment';
import React, { useEffect, useState } from 'react';

const OfficeVisitActivityLog = ({ id, initialData }: any) => {
  const [activitiesList, setActivitiesList] = useState<IOfficeVisitActivityCrm[]>([]);

  useEffect(() => {
    const getActivities = async () => {
      const res = await getAllVisitActivityLogs(id);
      setActivitiesList(res?.data);
    };
    if (id) {
      getActivities();
    }
  }, [id, initialData.assigneeId, initialData.sessionNotes]);

  if (activitiesList && activitiesList?.length <= 0) {
    return (
      <div className="full-height-activities-content ">
        <Empty image={Empty.PRESENTED_IMAGE_SIMPLE} />
      </div>
    );
  }

  return (
    <div className="margin-top-1">
      <Timeline>
        {activitiesList
          .filter((item: IOfficeVisitActivityCrm) => item.actionType !== 'created')
          .map((item: IOfficeVisitActivityCrm) => {
            return (
              <Timeline.Item key={item?.id}>
                <OfficeVisitActivityItem activities={item} key={item?.id} />
              </Timeline.Item>
            );
          })}
      </Timeline>
    </div>
  );
};

export { OfficeVisitActivityLog };

interface IOfficeVisitActivitiesItem {
  activities?: any;
  key?: any;
}

const OfficeVisitActivityItem = (props: IOfficeVisitActivitiesItem) => {
  const { activities } = props;
  const { activity, actionType, createdAt, user } = activities;

  const userName = user?.firstName + ' ' + user?.lastName;

  const getInformation = (): string | any => {
    if (actionType === 'started' && activity === 'office visit') {
      return (
        <>
          The session has been started{' '}
          <div className="ant-comment-content-author-name time-weight-size">
            by {userName}, {moment().from(moment(createdAt), true)} ago
          </div>
        </>
      );
    } else if (actionType === 'completed' && activity === 'office visit') {
      return (
        <>
          The session has been completed{' '}
          <div className="ant-comment-content-author-name time-weight-size">
            by {userName}, {moment().from(moment(createdAt), true)} ago
          </div>
        </>
      );
    } else if (actionType === 'updated' && activity === 'assignee') {
      return (
        <>
          Assignee has been updated{' '}
          <div className="ant-comment-content-author-name time-weight-size">
            by {userName}, {moment().from(moment(createdAt), true)} ago
          </div>
        </>
      );
    } else if (actionType === 'updated' && activity === 'session note') {
      return (
        <>
          Session notes have been updated{' '}
          <div className="ant-comment-content-author-name time-weight-size">
            by {userName}, {moment().from(moment(createdAt), true)} ago
          </div>
        </>
      );
    }
  };

  return (
    <div>
      <h4 className="margin-0">{getInformation()}</h4>
    </div>
  );
};
