import {
  API_URL,
  URL_DOCUMENT_DELETE,
  URL_LEAD_DOCUMENTS,
  URL_PRODUCT_DOCUMENTS,
} from '@moxie/constants';
import { errorHandler } from '@moxie/utils';
import { IResponse } from '@shared-components/models';
import axios from './axios';

const saveContactDocuments = async (body: FormData): Promise<IResponse> => {
  return await axios.post(`${API_URL.CONTACT_DOCUMENTS}`, body);
};

const getAllDocuments = (id: string, str: string): Promise<any> => {
  const params = { id };
  return axios.get(`${URL_LEAD_DOCUMENTS}${str}`, { params });
};

const getAllProductDocuments = (id: string, str: string): Promise<any> => {
  const params = { id };
  return axios.get(`${URL_PRODUCT_DOCUMENTS}${str}`, { params });
};

const getOneDocument = async (id: string): Promise<any> => {
  try {
    const response = await axios.get(
      `${API_URL.DOCUMENTS}/download-file/${id}`
    );
    return response?.data;
  } catch (err) {
    errorHandler(err);
  }
};

const deleteDocument = async (id: string): Promise<any> => {
  try {
    return await axios.delete(`${URL_DOCUMENT_DELETE}${id}`);
  } catch (err) {
    errorHandler(err);
  }
};

export {
  saveContactDocuments,
  getAllDocuments,
  getOneDocument,
  getAllProductDocuments,
  deleteDocument,
};
