const alias = 'PRODUCT';
const products = 'PRODUCTS';

export const ADD_PRODUCT_REQUEST = `ADD_${alias}_REQUEST`;
export const ADD_PRODUCT_SUCCESS = `ADD_${alias}_SUCCESS`;
export const ADD_PRODUCT_FAILURE = `ADD_${alias}_FAILURE`;

export const GET_PRODUCTS_REQUEST = `GET_${products}_REQUEST`;
export const GET_PRODUCTS_SUCCESS = `GET_${products}_SUCCESS`;
export const GET_PRODUCTS_FAILURE = `GET_${products}_FAILURE`;
