import * as actionTypes from './setting-other-details.constant';
import {
  IAction,
  IOtherDetailsCategory,
  IOtherDetailsLabel,
  IOtherDetailsOptions,
  IStatus,
} from '@shared-components/models';
import { STATUS } from '@moxie/constants';

export interface ISettingOtherDetails {
  categories: IOtherDetailsCategory[];
  labels: IOtherDetailsLabel[];
  tempOptions: IOtherDetailsOptions[];
  categoryStatus: IStatus;
  labelStatus: IStatus;
  selectedCategory: string;
  labelDataLoading: boolean;
}

export const initialState: ISettingOtherDetails = {
  categories: [],
  labels: [],
  tempOptions: [],
  categoryStatus: STATUS.IDLE,
  labelStatus: STATUS.IDLE,
  labelDataLoading: false,
  selectedCategory: '',
};

export const SETTING_OTHER_DETAILS = 'setting_other_details';

const otherDetailsReducer = (state = initialState, action: IAction<any>) => {
  switch (action.type) {
    case actionTypes.GET_CATEGORIES_REQUEST:
      return {
        ...state,
        categoryStatus: STATUS.LOADING,
        error: null,
      };
    case actionTypes.GET_CATEGORIES:
      return {
        ...state,
        categoryStatus: STATUS.SUCCEEDED,
        categories: action.payload,
      };
    case actionTypes.POST_CATEGORY_REQUEST:
      return {
        ...state,
        categoryStatus: STATUS.LOADING,
      };
    case actionTypes.POST_CATEGORY:
      return {
        ...state,
        categories: [...state.categories, action.payload],
        categoryStatus: STATUS.SUCCEEDED,
      };
    case actionTypes.OTHER_CATEGORY_REQUEST_IDLE:
      return { ...state, categoryStatus: STATUS.IDLE };
    case actionTypes.PUT_CATEGORY_REQUEST:
      return { ...state, categoryStatus: STATUS.LOADING };
    case actionTypes.PUT_CATEGORY:
      return {
        ...state,
        categoryStatus: STATUS.SUCCEEDED,
        categories: state.categories.map((item) => {
          if (item.id === action.payload.id) return action.payload;
          return item;
        }),
      };
    case actionTypes.DELETE_CATEGORY:
      return {
        ...state,
        categoryStatus: STATUS.SUCCEEDED,
        categories: state.categories.filter(
          (item) => item.id !== action.payload
        ),
      };
    case actionTypes.SELECT_CATEGORY:
      return { ...state, selectedCategory: action.payload };
    case actionTypes.OTHER_CATEGORY_REQUEST_FAILURE:
      return {
        ...state,
        categoryStatus: STATUS.FAILED,
      };
    case actionTypes.FETCH_OTHER_CATEGORY_REQUEST_FAILURE:
      return {
        ...state,
        categoryStatus: STATUS.FAILED,
        categories: [],
      };
    case actionTypes.OTHER_CATEGORY_LABEL_REQUEST_FAILURE:
      return {
        ...state,
        labelStatus: STATUS.FAILED,
        labelDataLoading: false,
      };
    case actionTypes.GET_CATEGORIES_LABEL_REQUEST:
      return {
        ...state,
        labelDataLoading: true,
        error: null,
      };
    case actionTypes.GET_CATEGORIES_LABEL:
      return {
        ...state,
        labelDataLoading: false,
        labels: action.payload,
      };
    case actionTypes.POST_CATEGORY_LABEL_REQUEST:
      return {
        ...state,
        labelStatus: STATUS.LOADING,
      };
    case actionTypes.PUT_CATEGORY_LABEL_REQUEST:
      return {
        ...state,
        labelStatus: STATUS.LOADING,
      };
    case actionTypes.POST_CATEGORIES_LABEL:
      return {
        ...state,
        labelStatus: STATUS.SUCCEEDED,
        labels: [...state.labels, action.payload],
      };
    case actionTypes.DELETE_CATEGORY_LABEL:
      return {
        ...state,
        labelStatus: STATUS.SUCCEEDED,
        labels: state.labels.filter((item) => item.id !== action.payload),
      };
    case actionTypes.DELETE_CATEGORY_LABEL_REQUEST:
      return {
        ...state,
        labelStatus: STATUS.LOADING,
      };
    case actionTypes.PUT_CATEGORY_LABEL:
      return {
        ...state,
        labelStatus: STATUS.SUCCEEDED,
        labels: state.labels.map((item) => {
          if (item.id === action.payload.id) {
            return action.payload;
          }
          return item;
        }),
      };
    case actionTypes.POST_CATEGORY_LABEL_WITH_OPTION_REQUEST: {
      return { ...state, labelStatus: STATUS.LOADING };
    }
    case actionTypes.SET_TEMP_OPTIONS:
      return { ...state, tempOptions: action.payload };
    default:
      return state;
  }
};

export { otherDetailsReducer };
