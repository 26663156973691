import {
  axiosInstance as axios
} from '@lyra/services.api';
import { API_URL } from '@moxie/constants';
import { IProductResponse } from '@shared-components/models';

const baseUrl = process.env.NX_BASE_URL;
export const endpoint_subject_discipline = `${baseUrl}${API_URL.SUBJECT_DISCIPLINES}`;

const subjectDisciplineServices = {
  apiGetSubjectDiscipline: async (): Promise<IProductResponse> => {
    return axios.get(`${endpoint_subject_discipline}`);
  },
};

export { subjectDisciplineServices };
