const alias = 'OFFICE_BRANCH';

export const GET_BRANCHES_REQUEST = `GET_${alias}ES_REQUEST`;
export const GET_BRANCHES_SUCCESS = `GET_${alias}ES_SUCCESS`;
export const GET_BRANCHES_FAILURE = `GET_${alias}ES_FAILURE`;

export const GET_BRANCH_REQUEST = `GET_${alias}_REQUEST`;
export const GET_BRANCH_SUCCESS = `GET_${alias}_SUCCESS`;
export const GET_BRANCH_FAILURE = `GET_${alias}_FAILURE`;

export const ADD_BRANCH_REQUEST = `ADD_${alias}_REQUEST`;
export const ADD_BRANCH_SUCCESS = `ADD_${alias}_SUCCESS`;
export const ADD_BRANCH_FAILURE = `ADD_${alias}_FAILURE`;

export const UPDATE_BRANCH_REQUEST = `UPDATE_${alias}_REQUEST`;
export const UPDATE_BRANCH_SUCCESS = `UPDATE_${alias}_SUCCESS`;
export const UPDATE_BRANCH_FAILURE = `UPDATE_${alias}_FAILURE`;

export const DELETE_BRANCH_REQUEST = `DELETE_${alias}_REQUEST`;
export const DELETE_BRANCH_SUCCESS = `DELETE_${alias}_SUCCESS`;
export const DELETE_BRANCH_FAILURE = `DELETE_${alias}_FAILURE`;

export const CLEAR_BRANCH_FETCH = `CLEAR_${alias}_FETCH`;
