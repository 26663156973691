import React, { ReactNode } from 'react';
import { Button, Table, TableUserNameCard } from '@moxie/shared';
import { IDepartment, ITable } from '@shared-components/models';
import { ColumnsType } from 'antd/lib/table';
import {
  AM_PERMISSIONS,
  DEPARTMENT_TITLE,
  LABEL,
  PHONE_LABEL,
  TEXT,
} from '@moxie/constants';
import { TableDepartmentNameCard } from './department-table-name-card';

import { PlusOutlined } from '@ant-design/icons';
import { useAppSelector } from '@lyra/core';
import permit from '@lyra/permit';

const DepartmentTable: React.FC<ITable> = ({
  filter,
  action,
  refresh,
  filterOptions,
  tableType,
  deleteData,
  showDrawer,
  actionBar,
}: ITable) => {
  const branchDepartmentsColumn: ColumnsType<IDepartment> = [
    {
      title: LABEL.NAME,
      render({ department }: { department: IDepartment }): ReactNode {
        return (
          <TableDepartmentNameCard
            showDrawer={showDrawer}
            data={department}
            deleteData={deleteData}
            actionBar={actionBar}
          />
        );
      },
    },
    {
      title: TEXT.DESCRIPTION,
      render({ department }: { department: IDepartment }): ReactNode {
        return (
          <div className="ellipsis-text">
            {department.description ? department.description : '-'}
          </div>
        );
      },
    },
    {
      title: TEXT.EMAIL,
      render({ department }: { department: IDepartment }): ReactNode {
        return department?.email?.toLowerCase();
      },
    },
    {
      title: PHONE_LABEL,
      render({ department }: { department: IDepartment }): ReactNode {
        return department?.phone_number ? department.phone_number : '-';
      },
    },
  ];

  const allDepartmentsColumn: ColumnsType<IDepartment> = [
    {
      title: LABEL.NAME,
      sorter: () => 0,
      render(department: IDepartment): ReactNode {
        return (
          <TableDepartmentNameCard
            showDrawer={showDrawer}
            data={department}
            deleteData={deleteData}
            actionBar={actionBar}
          />
        );
      },
    },
    {
      title: TEXT.DESCRIPTION,
      render({ description }: { description: string }): ReactNode {
        return (
          <div className="ellipsis-text">{description ? description : '-'}</div>
        );
      },
    },
    {
      title: TEXT.EMAIL,
      dataIndex: 'email',
      sorter: () => 0,
      render(email: string): ReactNode {
        return email && email.toLowerCase();
      },
    },
    {
      title: PHONE_LABEL,
      render(department: IDepartment): ReactNode {
        return department?.phone_number ? department?.phone_number : '-';
      },
    },
    {
      title: LABEL.CONTACT_PERSON,
      key: 'contactPerson',
      width: 200,
      render(data: IDepartment) {
        return data?.contactPerson ? (
          <TableUserNameCard
            index={data.contactPerson.id}
            actionBar={false}
            firstName={data.contactPerson.first_name || ''}
            lastName={data.contactPerson.last_name || ''}
            email={data.contactPerson.email || ''}
            id={data.contactPerson.id}
          />
        ) : (
          '-'
        );
      },
    },
  ];

  const rolePerms: string[] = useAppSelector(
    (state: any) => state?.auth?.user?.am_role?.permissions
  );

  return (
    <div className="bg-white padding-top-2">
      <Table
        filterOptions={filterOptions}
        tableName={DEPARTMENT_TITLE}
        filter={filter}
        size={'small'}
        action={action}
        columns={
          tableType === 'branch-department'
            ? branchDepartmentsColumn
            : allDepartmentsColumn
        }
        refresh={refresh}
        tableButtons={
          permit([AM_PERMISSIONS.DEPARTMENTS.MANAGE], rolePerms) && (
            <Button
              type="primary"
              typeof="button"
              onClick={() => showDrawer && showDrawer()}
            >
              <PlusOutlined /> {LABEL.CREATE_NEW_DEPARTMENT}
            </Button>
          )
        }
      />
    </div>
  );
};

export { DepartmentTable };
