/* eslint-disable react/display-name */
/* eslint-disable react-hooks/rules-of-hooks */
/* eslint-disable no-unused-vars */
import React, { ReactNode, useEffect, useRef, useState } from 'react';
import moment, { Moment } from 'moment';
import { Link } from 'react-router-dom';
import { ColumnType } from 'antd/lib/table';
import { RangeValue } from 'rc-picker/lib/interface';
import { FilterConfirmProps } from 'antd/lib/table/interface';
import { FilterFilled, SearchOutlined } from '@ant-design/icons';
import { Checkbox, DatePicker, Form, Input, Row, Typography } from 'antd';
import {
  IClientReportColumns,
  ICompany,
  IFilterDropdownProps,
  ILead,
} from '@shared-components/models';
import { Button, formatDateWithTimezone } from '@moxie/shared';
import { LABEL, SEARCH, TEXT, URL_DETAIL_LINK } from '@moxie/constants';
import { getAdminContactsReportFilterData } from '@admin/services.api';
import { useAppSelector } from '@admin/core';

const { Text } = Typography;
const { RangePicker } = DatePicker;
const CheckboxGroup = Checkbox.Group;

const ContactReportTableColumns = (
  setFilter: (key: string, value: string) => void
) => {
  const userTimeZone =
    useAppSelector((store) => store?.auth?.user?.time_zone) || '';
  const [countryList, setCountryList] = useState();
  const [assigneeList, setAssigneeList] = useState();
  const [statusList, setStatusList] = useState();

  useEffect(() => {
    (async () => {
      const response = await getAdminContactsReportFilterData();
      setCountryList(response?.data?.country);
      setAssigneeList(response?.data?.assignee);
      setStatusList(response?.data?.status);
    })();
  }, []);

  type DataIndex = keyof IClientReportColumns;
  const searchInput = useRef(null);

  const handleSearch = (
    selectedKeys: string[],
    confirm: (param?: FilterConfirmProps) => void,
    dataIndex: DataIndex,
    multipleData?: boolean
  ) => {
    confirm();
    if (multipleData) {
      setFilter(dataIndex, selectedKeys.join());
      return;
    }
    setFilter(dataIndex, selectedKeys[0]);
  };

  const handleReset = (clearFilters: () => void, dataIndex: string) => {
    clearFilters();
    setFilter(dataIndex, '');
  };

  const getColumnSearchProps = (
    dataIndex: DataIndex,
    name?: string
  ): ColumnType<IClientReportColumns> => ({
    filterDropdown: ({
      setSelectedKeys,
      selectedKeys,
      confirm,
      clearFilters,
    }: any) => {
      return (
        <div className="table-column-search-dropdown">
          <Input
            className="table-column-search-input"
            ref={searchInput}
            placeholder={`Search ${name}`}
            value={selectedKeys[0]}
            onChange={(e) =>
              setSelectedKeys(e.target.value ? [e.target.value] : [])
            }
            onPressEnter={() =>
              handleSearch(selectedKeys as string[], confirm, dataIndex)
            }
          />
          <Row justify="space-between">
            <Button
              className="margin-left-2"
              onClick={() =>
                clearFilters && handleReset(clearFilters, dataIndex)
              }
              size="small"
            >
              {LABEL.RESET}
            </Button>
            <Button
              type="primary"
              onClick={() => {
                handleSearch(selectedKeys as string[], confirm, dataIndex);
              }}
              size="small"
            >
              {SEARCH}
            </Button>
          </Row>
        </div>
      );
    },
    filterIcon: (filtered: boolean) => {
      return (
        <SearchOutlined className={filtered ? 'table-column-search-btn' : ''} />
      );
    },
  });

  const getStatusFilterProps = (
    dataIndex: DataIndex
  ): ColumnType<IClientReportColumns> => ({
    filterDropdown: ({
      setSelectedKeys,
      selectedKeys,
      confirm,
      clearFilters,
    }: IFilterDropdownProps) => {
      const handleStatusChange = (list: any) => {
        setSelectedKeys(list);
      };

      return (
        <div>
          <Form className="border-bottom padding-1">
            <Form.Item className="margin-bottom-0">
              <CheckboxGroup
                className="custom_role_vertical--checkbox"
                options={
                  dataIndex === 'status'
                    ? statusList
                    : dataIndex === 'country'
                    ? countryList
                    : assigneeList
                }
                onChange={handleStatusChange}
                value={selectedKeys}
              />
            </Form.Item>
          </Form>

          <Row className="padding-1" justify="space-between">
            <Button
              onClick={() => {
                clearFilters && handleReset(clearFilters, dataIndex);
                setSelectedKeys([]);
              }}
              size="small"
            >
              {LABEL.RESET}
            </Button>
            <Button
              type="primary"
              onClick={() => {
                handleSearch(
                  selectedKeys as string[],
                  confirm,
                  dataIndex,
                  true
                );
              }}
              size="small"
            >
              {TEXT.STATUS_DONE}
            </Button>
          </Row>
        </div>
      );
    },
    filterIcon: (filtered: boolean) => {
      return (
        <FilterFilled className={filtered ? 'table-column-search-btn' : ''} />
      );
    },
  });

  const getDateFilterProps = (
    dataIndex: DataIndex
  ): ColumnType<IClientReportColumns> => {
    return {
      filterDropdown: ({
        setSelectedKeys,
        selectedKeys,
        confirm,
      }: IFilterDropdownProps) => {
        const handleRangeChange = (
          data: RangeValue<Moment>,
          values: string[]
        ) => {
          let dateRange: React.Key[] = [];
          if (data) {
            dateRange = [
              values[0] && moment(values[0]).format('YYYY-MM-DD'),
              values[1] && moment(values[1]).format('YYYY-MM-DD'),
            ];
          }

          setSelectedKeys(dateRange);
        };
        return (
          <div className="padding-1">
            <Form>
              <Form.Item>
                <RangePicker
                  format="MMM DD, YYYY"
                  onChange={handleRangeChange}
                />
              </Form.Item>
              <Row justify="end" className="margin-top-n2">
                <Button
                  type="primary"
                  onClick={() =>
                    handleSearch(
                      selectedKeys as string[],
                      confirm,
                      dataIndex,
                      true
                    )
                  }
                  size="small"
                >
                  {TEXT.STATUS_DONE}
                </Button>
              </Row>
            </Form>
          </div>
        );
      },
      filterIcon: (filtered: boolean) => {
        return (
          <FilterFilled className={filtered ? 'table-column-search-btn' : ''} />
        );
      },
    };
  };

  return [
    {
      title: LABEL.CLIENT_ID,
      width: 200,
      dataIndex: 'internal_id',
      ellipsis: true,
      sorter: () => 0,
      ...getColumnSearchProps('internal_id', 'Internal Id'),
      render: (value: string, data: ILead): ReactNode => {
        return (
          <div className="lead-table__city">
            <Link to={`${URL_DETAIL_LINK}${data?.id}/profile-section`}>
              <Text className="text-primary-color">
                {data?.company?.company_code + '-' + value}
              </Text>
            </Link>
          </div>
        );
      },
    },

    {
      title: LABEL.CLIENT,
      width: 300,
      dataIndex: 'client_name',
      ellipsis: true,
      sorter: () => 0,
      ...getColumnSearchProps('client_name', 'Client Name'),
      render: (
        value: string,
        { id, first_name, last_name }: ILead
      ): ReactNode => {
        return (
          <div className="lead-table__phone">
            <Link to={`${URL_DETAIL_LINK}${id}/profile-section`}>
              <Text className="text-primary-color initial_capital">{`${first_name} ${last_name}`}</Text>
            </Link>
          </div>
        );
      },
    },
    {
      title: LABEL.COMPANY_NAME,
      dataIndex: 'company',
      key: 'company',
      width: 400,
      ...getColumnSearchProps('company', 'Company'),
      sorter: () => 0,
      render(company: ICompany) {
        return (
          <div className="lead-table__phone">
            <Text className="initial_capital"> {company?.company_name}</Text>
          </div>
        );
      },
    },

    {
      title: TEXT.PHONE,
      width: 200,
      ellipsis: true,
      ...getColumnSearchProps('phone', 'Phone'),
      render: ({ country_code, phone }: ILead): ReactNode => {
        return (
          <div className="lead-table__phone">{`${country_code} ${phone}`}</div>
        );
      },
    },

    {
      title: LABEL.EMAIL,
      width: 200,
      dataIndex: 'email',
      ellipsis: true,
      ...getColumnSearchProps('email', 'Email'),
      sorter: () => 0,
      render: (email: string): ReactNode => {
        return (
          <div className="lead-table__email">
            <a className="text-primary-color" href={`mailto:${email}`}>
              {email}
            </a>
          </div>
        );
      },
    },

    {
      title: LABEL.DATE_OF_BIRTH,
      width: 300,
      dataIndex: 'date_of_birth',
      sorter: () => 0,
      render: (value: string): ReactNode => {
        return (
          <div className="lead-table__visa-expiry-date">
            {value ? moment(value).format('MMM DD, YYYY') : '-'}
          </div>
        );
      },
    },

    {
      title: LABEL.ADDED_DATE,
      width: 200,
      dataIndex: 'created_at',
      ...getDateFilterProps('created_at'),
      sorter: () => 0,
      render: (value: string): ReactNode => {
        return (
          <div className="lead-table__visa-expiry-date">
            {formatDateWithTimezone(value, userTimeZone)}
          </div>
        );
      },
    },

    {
      title: LABEL.STREET,
      width: 200,
      dataIndex: 'street',
      ellipsis: true,
      sorter: () => 0,
      render: (value: string): ReactNode => {
        return <div className="lead-table__city">{value ? value : '-'}</div>;
      },
    },

    {
      title: LABEL.CITY,
      width: 200,
      dataIndex: 'city',
      ellipsis: true,
      sorter: () => 0,
      render: (value: string): ReactNode => {
        return <div className="lead-table__city">{value ? value : '-'}</div>;
      },
    },

    {
      title: LABEL.STATE,
      width: 200,
      dataIndex: 'state',
      ellipsis: true,
      sorter: () => 0,
      render: (value: string): ReactNode => {
        return <div className="lead-table__city">{value ? value : '-'}</div>;
      },
    },

    {
      title: LABEL.COUNTRY_OF_RESIDENCE,
      width: 200,
      dataIndex: 'country',
      ...getStatusFilterProps('country'),
      ellipsis: true,
      sorter: () => 0,
      render: (value: string): ReactNode => {
        return <div className="lead-table__city">{value ? value : '-'}</div>;
      },
    },

    {
      title: LABEL.COUNTRY_OF_PASSPORT,
      width: 200,
      dataIndex: 'country_of_passport',
      ellipsis: true,
      sorter: () => 0,
      render: (value: string): ReactNode => {
        return <div className="lead-table__city">{value ? value : '-'}</div>;
      },
    },

    {
      title: LABEL.PASSPORT_NUMBER,
      width: 200,
      dataIndex: 'passport_no',
      ellipsis: true,
      sorter: () => 0,
      render: (value: string): ReactNode => {
        return <div className="lead-table__city">{value ? value : '-'}</div>;
      },
    },

    {
      title: LABEL.PASSPORT_EXPIRY_DATE,
      width: 200,
      dataIndex: 'passport_expiry_date',
      ...getDateFilterProps('passport_expiry_date'),
      ellipsis: true,
      sorter: () => 0,
      render: (value: string): ReactNode => {
        return (
          <div className="lead-table__visa-expiry-date">
            {value ? moment(value).format('MMM DD, YYYY') : '-'}
          </div>
        );
      },
    },

    {
      title: LABEL.VISA_EXPIRY_DATE,
      width: 200,
      dataIndex: 'visa_expiry_date',
      ...getDateFilterProps('visa_expiry_date'),
      ellipsis: true,
      sorter: () => 0,
      render: (value: string): ReactNode => {
        return (
          <div className="lead-table__visa-expiry-date">
            {value ? moment(value).format('MMM DD, YYYY') : '-'}
          </div>
        );
      },
    },
  ];
};

export { ContactReportTableColumns };
