import { put, takeLatest, ForkEffect } from 'redux-saga/effects';
import * as actionTypes from './lead-activities.constant';
import * as LeadActivitiesActions from './lead-activities.action';

function* fetchLeadActivities(data: any) {
  yield put(LeadActivitiesActions.getLeadActivitiesSuccess(data.payload));
}

export default function* leadActivitiesSagas(): Generator<
  ForkEffect<never>,
  void,
  unknown
> {
  yield takeLatest(
    actionTypes.GET_LEAD_ACTIVITIES_REQUEST,
    fetchLeadActivities
  );
}
