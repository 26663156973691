import React, { useState } from "react";
import { Button } from "antd";
import { Col, Row } from "antd/es/grid";
import Input from "antd/es/input";
import { CopyOutlined } from "@ant-design/icons";

const CopyableInputText = ({ value }: { value: string }) => {
  const [copied, setCopied] = useState(false);

  const handleCopied = () => {
    navigator.clipboard.writeText(value);
    setCopied((prev) => !prev);
  }

  return (
    <Row align="middle">
      <Col span={21}>
        <Input value={value} readOnly={true} />
      </Col>
      <Col span={2}>
        <Button type="text" className={copied ? "text-secondary margin-left-1 padding-left-1" : "margin-left-1 padding-left-1"} onClick={handleCopied}>
          <CopyOutlined />
          {!copied ? "Copy" : "Copied"}
        </Button>
      </Col>
    </Row >
  )
}

export default CopyableInputText;
