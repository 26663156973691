import React, { useEffect, useState } from 'react';
import {
  Button,
  Checkbox,
  Col,
  Divider,
  Empty,
  Form,
  Input,
  InputNumber,
  Row,
  Select,
  Space,
  Card,
  Badge,
  Tag,
} from 'antd';
import { DeleteOutlined, PlusOutlined } from '@ant-design/icons';
import {
  ADD_BTN,
  ADD_FEES,
  CANCEL_BTN,
  COUNTRIES,
  COUNTRY_PLACEHOLDER,
  DELETE_BTN,
  DRAWER_WIDTH,
  FEE_TYPE_LIST,
  INSTALLMENT_TYPE,
  LABEL,
  TEXT,
  TITLE,
} from '@moxie/constants';
import { DrawerElem, Option } from '@shared-components/elements';
import { productServices } from '@lyra/services.api';
import { errorHandler } from '@moxie/utils';
import { successNotificationHandler } from '@moxie/shared';
import {
  IProductDetailFees,
  IProductFee,
  IProductFeeForm,
} from '@shared-components/models';

const ProductDetailFees: React.FC<IProductDetailFees> = (
  props: IProductDetailFees
) => {
  const { params, currency } = props;
  const [loading] = useState(false);
  const [visible, setVisible] = useState(false);
  const [fees, setFees] = useState([]);
  useEffect(() => {
    getFee();
  }, [params]);
  const getFee = async () => {
    const response = await productServices.apiGetProductFees(params.id);
    setFees(response?.data?.data);
  };
  const [form] = Form.useForm();

  const handleSubmit = () => {
    form.submit();
  };

  const onClose = () => {
    setVisible(false);
  };

  return (
    <>
      <div className="overflow-scroll bg-white">
        {fees && fees?.length <= 0 && (
          <div className="empty-content bg-white">
            <Empty image={Empty.PRESENTED_IMAGE_SIMPLE} />
          </div>
        )}
        {fees &&
          fees.map((item: IProductFee) => (
            <Card
              className="fee_card margin-top-1"
              Product-
              title={
                <div className="fee_card__header">
                  <span className="fee_card__title">{item?.fee_name}</span>
                  {item?.country && <Tag>{TEXT.VALID_FOR_ALL_COUNTRIES}</Tag>}
                </div>
              }
              key={item?.id}
            >
              <div className="fee_lists">
                {item?.pfees?.map((feeList: any) => (
                  <div className="fee_lists__item" key={feeList?.id}>
                    <div className="fee_lists__info">
                      <Badge dot />
                      <span className="margin-left-2 fee_lists__info_name">
                        {feeList?.fee_type?.replace('_', ' ')}
                      </span>
                    </div>
                    <div className=" fee_lists__installment">
                      <span className=" fee_lists__installment_label">
                        {LABEL.INSTALLMENT_TYPE}
                      </span>
                      <span className="margin-left-1 fee_lists__installment_amount">
                        {feeList?.installment_type?.replace('_', ' ')}
                      </span>
                    </div>

                    <div className=" fee_lists__installment">
                      <span className=" fee_lists__installment_label">
                        {TEXT.INSTALLMENTS}
                      </span>
                      <span className="margin-left-1 fee_lists__installment_amount">
                        {feeList?.installments}
                      </span>
                    </div>
                    <div className=" fee_lists__installment">
                      <span className=" fee_lists__installment_label">
                        {TEXT.INSTALLMENT_AMOUNT}
                      </span>
                      <span className="margin-left-1 fee_lists__installment_amount">
                        {currency} {feeList?.installment_amount}
                      </span>
                    </div>
                    <div className="fee_lists__price">
                      <span>
                        {currency} {feeList?.total_fee}
                      </span>
                    </div>
                  </div>
                ))}
              </div>
              <div className="fee_lists__total">
                <span className="fee_lists__total_label">
                  {LABEL.FEE_NET_TOTAL}:
                </span>
                <span className="fee_lists__total_price">
                  {currency}{' '}
                  {item?.pfees
                    .map((item: any) => {
                      return item?.total_fee;
                    })
                    .reduce((a: number, b: number) => a + b, 0)}
                </span>
              </div>
            </Card>
          ))}
      </div>
      <DrawerElem
        title={TITLE.ADD_PRODUCT_FEE_TITLE}
        width={DRAWER_WIDTH}
        onClose={onClose}
        visible={visible}
        footer={
          <div className="padding-bottom-2 text-align-right">
            <Space direction="horizontal">
              <Button onClick={onClose} className="lead-margin-right">
                {CANCEL_BTN}
              </Button>
              <Button onClick={handleSubmit} type="primary" loading={loading}>
                {ADD_BTN}
              </Button>
            </Space>
          </div>
        }
      >
        <ProductFeeForm form={form} onClose={onClose} product_id={params.id} />
      </DrawerElem>
    </>
  );
};

const ProductFeeForm: React.FC<IProductFeeForm> = (props: IProductFeeForm) => {
  const { form, onClose, product_id } = props;
  const [installment_type, setInstallmentType] = useState<string>('');
  const handleCountryFilter = (input: string, option: any): boolean => {
    return option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0;
  };

  const handleSubmit = async (values: any) => {
    const data = values;
    data.product_id = product_id;
    try {
      await productServices.apiAddProductFee(data);
      form.resetFields();
      onClose();
      successNotificationHandler(TITLE.PRODUCT_FEE_SUCCESS);
    } catch (err) {
      errorHandler(err);
    }
  };

  const handleSelectInstallmentType = (value: string) => {
    setInstallmentType(value);
  };

  const getInstallmentAmountByType = () => {
    switch (installment_type) {
      case 'full_fee':
        return 'Installment Amount';
      case 'per_year':
        return 'Per Year Amount';
      case 'per_month':
        return 'Per Month Amount';
      case 'per_term':
        return 'Per Term Amount';
      case 'per_week':
        return 'Per Week Amount';
      default:
        return 'Installment Amount';
    }
  };

  const getInstallmentsByType = () => {
    switch (installment_type) {
      case 'full_fee':
        return 'Installments';
      case 'per_year':
        return 'No. of Year';
      case 'per_month':
        return 'No. of Month';
      case 'per_term':
        return 'No. of Term';
      case 'per_week':
        return 'No. of Week';
      default:
        return 'Installments';
    }
  };

  const getClaimableType = () => {
    switch (installment_type) {
      case 'full_fee':
        return 'Claimable Terms';
      case 'per_year':
        return 'Claimable Year';
      case 'per_month':
        return 'Claimable Month';
      case 'per_term':
        return 'Claimable Term';
      case 'per_week':
        return 'Claimable Week';
      default:
        return 'Claimable Terms';
    }
  };

  return (
    <Form
      className="product-fee-form"
      form={form}
      onFinish={handleSubmit}
      layout="vertical"
    >
      <Row gutter={14}>
        <Col lg={8}>
          <Form.Item label={LABEL.FEE_OPTION_NAME} required name="fee_name">
            <Input placeholder="input placeholder" />
          </Form.Item>
        </Col>
        <Col lg={8}>
          <Form.Item
            name="country"
            label={LABEL.COUNTRY_OF_RESIDENCE}
            rules={[{ required: true }]}
          >
            <Select
              placeholder={COUNTRY_PLACEHOLDER}
              showSearch
              filterOption={handleCountryFilter}
            >
              {COUNTRIES.map((country) => (
                <Option value={country.code} key={country.code}>
                  {country.name}
                </Option>
              ))}
            </Select>
          </Form.Item>
        </Col>
        <Col lg={8}>
          <Form.Item
            name="installment_type"
            label={LABEL.INSTALLMENT_TYPE}
            required
          >
            <Select onSelect={handleSelectInstallmentType}>
              {INSTALLMENT_TYPE.map((data: any) => (
                <Option key={data.value} value={data.value}>
                  {data.name}
                </Option>
              ))}
            </Select>
          </Form.Item>
        </Col>
      </Row>
      <Form.List name="fees">
        {(fields, { add, remove }) => (
          <>
            {fields.map(({ key, name, fieldKey }) => (
              <Row key={key} gutter={14} className="margin-top-2">
                <Col lg={8}>
                  <Form.Item
                    name={[name, 'fee_type']}
                    fieldKey={[fieldKey, 'fee_type']}
                    label={LABEL.FEE_TYPE}
                    required
                  >
                    <Select>
                      {FEE_TYPE_LIST.map((data) => (
                        <Option key={data.value} value={data.value}>
                          {data.name}
                        </Option>
                      ))}
                    </Select>
                  </Form.Item>
                </Col>
                <Col lg={8}>
                  <Form.Item
                    name={[name, 'installment_amount']}
                    fieldKey={[fieldKey, 'installment_amount']}
                    label={getInstallmentAmountByType()}
                    required
                  >
                    <InputNumber type="number" />
                  </Form.Item>
                </Col>
                <Col lg={8}>
                  <Form.Item
                    name={[name, 'installments']}
                    fieldKey={[fieldKey, 'installments']}
                    label={getInstallmentsByType()}
                    required
                  >
                    <InputNumber type="number" />
                  </Form.Item>
                </Col>
                <Col>
                  <Form.Item
                    name={[name, 'terms']}
                    fieldKey={[fieldKey, 'terms']}
                    label={getClaimableType()}
                    required
                  >
                    <InputNumber type="number" />
                  </Form.Item>
                </Col>
                <Col>
                  <Form.Item
                    name={[name, 'commission']}
                    fieldKey={[fieldKey, 'commission']}
                    label={LABEL.FEE_COMMISSION}
                    required
                  >
                    <InputNumber type="number" />
                  </Form.Item>
                </Col>
                <Col>
                  <Form.Item
                    name={[name, 'quotation']}
                    fieldKey={[fieldKey, 'quotation']}
                    label={LABEL.ADD_QUOTATION}
                    required
                  >
                    <Checkbox />
                  </Form.Item>
                </Col>
                <Col>
                  <Form.Item label={LABEL.FEE_TOTAL}>
                    <p>0.00</p>
                  </Form.Item>
                </Col>
                <Col>
                  <Space
                    className="custom-delete-fee"
                    size={8}
                    onClick={() => remove(name)}
                  >
                    <DeleteOutlined />
                    <span>{DELETE_BTN}</span>
                  </Space>
                </Col>
              </Row>
            ))}
            <Space size={14} />
            <Form.Item>
              <Button
                type="dashed"
                onClick={() => add()}
                block
                icon={<PlusOutlined />}
              >
                {ADD_FEES}
              </Button>
            </Form.Item>
            <Space size={8} />
            <Divider />
            <div className="margin-top-1">
              <p>{LABEL.FEE_NET_TOTAL}: 0.00</p>
            </div>
          </>
        )}
      </Form.List>
    </Form>
  );
};

export { ProductDetailFees };
