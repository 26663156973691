const SUBJECT_AREA = [
  {
    name: 'Technology',
    value: 'technology',
    children: [
      {
        name: 'Computer Science',
        value: 'computer-science',
      },
      {
        name: 'Software Engineering',
        value: 'software-engineering',
      },
    ],
  },
  {
    name: 'Management',
    value: 'management',
    children: [
      {
        name: 'MBA',
        value: 'mba',
      },
      {
        name: 'MBS',
        value: 'mbs',
      },
    ],
  },
];
export { SUBJECT_AREA };
