/* eslint-disable no-unused-vars */
import React, { useEffect, useState } from 'react';
import { Col, DatePicker, InputNumber, Row, Select } from 'antd';
import { useAppSelector } from '@lyra/core';
import { useSubjectDiscipline } from '@lyra/hooks';
import {
  IEducation,
  IForm,
  IGetPopupContainer,
  ISubject,
} from '@shared-components/models';
import Form from 'antd/lib/form';
import {
  GUTTER,
  LABEL,
  SUBJECT_AREA_PLACEHOLDER,
  SUBJECT_PLACEHOLDER,
  SUBJECT_AREA_EXTRA,
  AUTH_MESSAGE,
} from '@moxie/constants';
import { Input, SearchableSelect } from 'libs/shared/src/shared';
import Checkbox, { CheckboxChangeEvent } from 'antd/lib/checkbox/Checkbox';
import moment, { Moment } from 'moment';
import { getEducationLevels } from '@lyra/services.api';
const { Option } = Select;

const EducationBackgroundForm: React.FC<IForm<IEducation>> = ({
  form,
  isOpen,
  onSubmit,
  initialData,
  setDisabled,
}: IForm<IEducation | any>) => {
  const disciplines = useAppSelector(
    (state) => state.subject_discipline.disciplines
  );
  const [isGpa, setGpa] = useState(false);
  const [isTrue, setTrue] = useState(false);
  const [subjectOptions, setSubjectOptions] = useState<ISubject[]>([]);
  const [subjectAreaOptions, setSubjectAreaOptions] = useState<ISubject[]>([]);
  const [degreeLevels, setDegreeLevels] = useState([]);

  const changeDateToMoment = (date?: string | Moment): Moment | undefined => {
    if (date) return moment(date);
    return;
  };

  const handleChangePercentageCheckbox = (e: CheckboxChangeEvent) => {
    setGpa(!e.target.checked);
    setDisabled(false);
  };

  const handleChangeGPACheckbox = (e: CheckboxChangeEvent) => {
    setGpa(e.target.checked);
    setDisabled(false);
  };

  const handleDateDisable = (currentDate: any) => {
    const customDate = moment().format('YYYY-MM-DD');
    return currentDate && currentDate >= moment(customDate, 'YYYY-MM-DD');
  };

  const handleSubmit = async (data: IEducation) => {
    if (data.course_start_and_end) {
      data.course_start = moment(data.course_start_and_end[0]).toString();
      data.course_end = moment(data.course_start_and_end[1]).toString();
    }
    if (isGpa) {
      data.percentage = null;
    } else {
      data.secured_gpa = null;
      data.total_gpa = null;
    }
    delete data['course_start_and_end'];
    if (data.discipline == undefined) {
      data.subject_area = undefined;
    }
    onSubmit && onSubmit(data);
    setDisabled(true);
  };

  const onFieldsChange = (allFields: string | any[]) => {
    if (allFields.length > 0) {
      setDisabled(false);
    }
  };

  const handleFormChange = (formValue: IEducation) => {
    const haveKey = Object.keys(formValue).some(
      (key: string) => key === 'discipline'
    );
    if (haveKey) {
      if (formValue.discipline) {
        setSubjectAreaOptions(
          disciplines
            .find((item) => item.id === formValue.discipline)
            ?.subject_areas.map((item: any) => {
              return {
                name: item.name,
                value: item.id,
              };
            }) || []
        );
        form.setFieldsValue({ ...form.getFieldsValue(), subject_area: '' });
      } else {
        setSubjectAreaOptions([]);
        form.setFieldsValue({ ...form.getFieldsValue(), subject_area: '' });
      }
    }
  };

  useSubjectDiscipline();

  useEffect(() => {
    setSubjectAreaOptions([]);
    getEducationLevels().then((res) => {
      setDegreeLevels(res.data.data);
    });
  }, [isOpen, setSubjectAreaOptions]);

  useEffect(() => {
    if (disciplines.length > 0) {
      const mapData: ISubject[] = disciplines.map((item) => {
        return {
          name: item.discipline,
          value: item.id,
        };
      });
      setSubjectOptions(mapData);
    }
  }, [disciplines, setSubjectOptions]);

  useEffect(() => {
    if (initialData && Object.keys(initialData).length > 0) {
      if (initialData.course_start && initialData.course_end) {
        initialData.course_start_and_end = [
          changeDateToMoment(initialData.course_start),
          changeDateToMoment(initialData.course_end),
        ];
      } else {
        initialData.course_start_and_end = null;
      }

      if (initialData.secured_gpa && initialData.total_gpa) {
        setGpa(true);
      }

      const initialValue = {
        ...initialData,
        discipline: initialData?.discipline_id?.id,
      };
      if (initialData?.discipline_id?.id) {
        setSubjectAreaOptions(
          disciplines
            .find((item) => item.id === initialData?.discipline_id?.id)
            ?.subject_areas.map((item: any) => {
              return {
                name: item.name,
                value: item.id,
              };
            }) || []
        );
        initialValue.subject_area = initialData?.subject_id?.id;
      } else {
        initialValue.subject_area = null;
      }
      form.setFieldsValue(initialValue);
    } else form.resetFields();
  }, []);

  const changeSubjectAreaRequire = async (data: any) => {
    if (isTrue || data == undefined) {
      return setTrue(false);
    } else {
      return setTrue(true);
    }
  };

  return (
    <Form
      layout="vertical"
      onFinish={handleSubmit}
      form={form}
      onValuesChange={handleFormChange}
      onFieldsChange={onFieldsChange}
    >
      <Row gutter={GUTTER}>
        <Col span={24}>
          <Form.Item
            name="degree_title"
            label={LABEL.DEGREE_TITLE}
            rules={[{ required: true }]}
          >
            <Input />
          </Form.Item>
        </Col>
      </Row>
      <Row gutter={GUTTER}>
        <Col span={24}>
          <Form.Item
            name="degree_level_id"
            label={LABEL.DEGREE_LEVEL}
            rules={[{ required: true }]}
          >
            <SearchableSelect optionFilter="search_prop">
              {degreeLevels &&
                degreeLevels.map((item: any) => (
                  <Option
                    value={item.id}
                    key={item.name}
                    search_prop={item.name}
                  >
                    {item.name}
                  </Option>
                ))}
            </SearchableSelect>
          </Form.Item>
        </Col>
      </Row>
      <Row gutter={GUTTER}>
        <Col span={24}>
          <Form.Item
            name="institution"
            label={LABEL.INSTITUTION}
            rules={[{ required: true }]}
          >
            <Input />
          </Form.Item>
        </Col>
      </Row>
      <Row gutter={GUTTER}>
        <Col span={12}>
          <Form.Item name="course_start_and_end" label={LABEL.COURSE_DURATION}>
            <DatePicker.RangePicker
              className="full-width"
              picker="month"
              disabledDate={handleDateDisable}
            />
          </Form.Item>
        </Col>
      </Row>
      <Row gutter={GUTTER}>
        <Col span={24}>
          <div id="lead_education_discipline" className="relative">
            <Form.Item name="discipline" label={LABEL.SUBJECT}>
              <Select
                allowClear={true}
                showSearch
                placeholder={SUBJECT_PLACEHOLDER}
                optionFilterProp="children"
                onChange={changeSubjectAreaRequire}
                filterOption={(input, option: any) =>
                  option.props.children
                    .toLowerCase()
                    .indexOf(input.toLowerCase()) >= 0
                }
                getPopupContainer={(): IGetPopupContainer =>
                  document.getElementById('lead_education_discipline')
                }
              >
                {subjectOptions.map((item) => (
                  <Option value={item.value} key={item.value}>
                    {item.name}
                  </Option>
                ))}
              </Select>
            </Form.Item>
          </div>
        </Col>
      </Row>
      <Row gutter={GUTTER}>
        <Col span={24}>
          <div id="lead_subject_area" className="relative">
            <Form.Item
              name="subject_area"
              label={LABEL.SUBJECT_AREA}
              extra={SUBJECT_AREA_EXTRA}
              rules={[{ required: isTrue }]}
            >
              <Select
                showSearch
                placeholder={SUBJECT_AREA_PLACEHOLDER}
                optionFilterProp="children"
                filterOption={(input, option: any) =>
                  option.props.children
                    .toLowerCase()
                    .indexOf(input.toLowerCase()) >= 0
                }
                getPopupContainer={(): IGetPopupContainer =>
                  document.getElementById('lead_subject_area')
                }
              >
                {subjectAreaOptions &&
                  subjectAreaOptions.map((item) => {
                    return (
                      <Option value={item.value} key={item.value}>
                        {item.name}
                      </Option>
                    );
                  })}
              </Select>
            </Form.Item>
          </div>
        </Col>
      </Row>

      <Row gutter={GUTTER}>
        <Col span={24}>
          {isGpa ? (
            <Row gutter={GUTTER}>
              <Col span={12}>
                <Form.Item
                  name="secured_gpa"
                  rules={[{ min: 0, max: 10, type: 'number' }]}
                  validateFirst
                  label={LABEL.GPA}
                >
                  <InputNumber className="full-width" type="number" />
                </Form.Item>
              </Col>
              <Col span={12}>
                <Form.Item
                  name="total_gpa"
                  rules={[
                    {
                      min: 0,
                      max: 10,
                      type: 'number',
                    },
                    ({ getFieldValue }) => ({
                      validator(_, value) {
                        if (value < getFieldValue('secured_gpa')) {
                          return Promise.reject(AUTH_MESSAGE.GREATER_GPA);
                        }
                        return Promise.resolve();
                      },
                    }),
                  ]}
                  validateFirst
                  label={LABEL.TOTAL_GPA}
                >
                  <InputNumber className="full-width" type="number" />
                </Form.Item>
              </Col>
            </Row>
          ) : (
            <Form.Item
              name="percentage"
              label={LABEL.PERCENTAGE}
              rules={[{ min: 0, max: 100, type: 'number' }]}
              validateFirst={true}
            >
              <InputNumber type="number" className="full-width" />
            </Form.Item>
          )}
          <Col span={24}>
            <Checkbox
              onChange={handleChangePercentageCheckbox}
              checked={!isGpa}
            >
              {LABEL.PERCENTAGE}
            </Checkbox>

            <Checkbox onChange={handleChangeGPACheckbox} checked={isGpa}>
              {LABEL.GPA}
            </Checkbox>
          </Col>
        </Col>
      </Row>
    </Form>
  );
};

export { EducationBackgroundForm };
