import axios from './axios';
import { ILoginResponse } from '@shared-components/models';
import { API_URL } from '@moxie/constants';

const getAllNotifications = (
  id: string | any,
  params: string
): Promise<any> => {
  return axios.get(
    `${process.env.NX_BASE_URL}${API_URL.NOTIFICATION}/${id}?${params}`
  );
};

const markNotificationRead = (data: any): Promise<ILoginResponse> => {
  return axios.put(
    `${process.env.NX_BASE_URL}${API_URL.NOTIFICATION}/markread/${data.id}`,
    {
      is_read: data?.is_read ? !data.is_read : true,
    }
  );
};

const markNotificationReadAll = (userId: string): Promise<ILoginResponse> => {
  return axios.put(
    `${process.env.NX_BASE_URL}${API_URL.NOTIFICATION}/markread/all`,
    { userId }
  );
};

export { markNotificationRead, markNotificationReadAll, getAllNotifications };
