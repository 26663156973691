import { getApplicationActivities } from '@lyra/services.api';
import { Empty, Timeline } from 'antd';
import moment from 'moment';
import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';

const ApplicationActivities: React.FC = () => {
  const [activitiesList, setActivitiesList] = useState<[]>([]);
  const { slug2: applicationId } = useParams<{ slug2: string }>();
  const { applicationId: adminApplicationId } = useParams<{ applicationId: string }>();
  useEffect(() => {
    const getActivities = async () => {
      const res = await getApplicationActivities(applicationId || adminApplicationId);
      setActivitiesList(res?.data?.data);
    };

    getActivities();
  }, []);

  if (activitiesList && activitiesList?.length <= 0) {
    return (
      <div className="full-height-activities-content ">
        <Empty image={Empty.PRESENTED_IMAGE_SIMPLE} />
      </div>
    );
  }

  return (
    <div className="margin-top-1">
      <Timeline>
        {activitiesList.map((item: any) => {
          return (
            <Timeline.Item key={item?.id}>
              <ApplicationActivityItem activity={item} key={item?.id} />
            </Timeline.Item>
          );
        })}
      </Timeline>
    </div>
  );
};

export { ApplicationActivities };

interface IApplicationActivitiesItem {
  activity?: any;
  key?: any;
}

const ApplicationActivityItem: React.FC<IApplicationActivitiesItem> = (
  props: IApplicationActivitiesItem
) => {
  const { activity } = props;
  const { user, update_type, activities_action, created_at, data } = activity;

  const parsedData = JSON.parse(data);

  const getInformation = (): string | any => {
    if (activities_action === 'created') {
      return 'Created the application';
    } else if (activities_action === 'deleted') {
      return 'Deleted the application';
    } else if (activities_action === 'updated' && update_type === 'assignee') {
      return 'Updated Assignee for the application';
    } else if (activities_action === 'updated' && update_type === 'intake') {
      return 'Updated Intake for the application';
    } else if (
      activities_action === 'updated' &&
      update_type === 'enrollment-period'
    ) {
      return 'Updated Enrollment Period for the application';
    } else if (
      activities_action === 'updated' &&
      update_type === 'discontinued'
    ) {
      return 'Discontinued the application';
    } else if (activities_action === 'updated' && update_type === 'revert') {
      return 'Reverted the application';
    } else if (
      activities_action === 'updated' &&
      update_type === 'previous-stage'
    ) {
      return (
        <>
          Updated the application stage to{' '}
          <span className="text-primary">
            {parsedData?.data?.currentStage?.stage}
          </span>
        </>
      );
    } else if (
      activities_action === 'updated' &&
      update_type === 'next-stage'
    ) {
      return (
        <>
          Updated the application stage to{' '}
          <span className="text-primary">
            {parsedData?.data?.currentStage?.stage}
          </span>
        </>
      );
    } else if (activities_action === 'updated') {
      return 'Updated the application';
    } else {
      return 'Application updated';
    }
  };

  return (
    <div className="application-activity">
      <h4 className="margin-0">{getInformation()}</h4>
      <span className="ant-comment-content-author-name">
        by {user?.first_name} {user?.last_name},{' '}
        {moment().from(moment(created_at), true)} ago
      </span>
    </div>
  );
};
