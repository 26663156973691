import React, { useState } from "react";
import Modal from "antd/es/modal";
import { Form, Typography } from "antd";
import Row from "antd/es/row";
import QRCode from "react-qr-code";
import CopyableInputText from "@crm/src/shared/copyable-input-text/copyable-input-text";
import { getBranchesByUserContactPermission } from "@crm/services.api";
import SearchBox from "../../shared/search-box";
import { useAppSelector } from "@crm/core";

interface ShareUrlModalProps {
  showShareModal: boolean;
  onCancel: () => void;
  selectedBranch: string;
}

const OfficeVisitUrlShareModal = ({ showShareModal, onCancel, selectedBranch }: ShareUrlModalProps) => {
  const source = "office-visit";
  const [branch, setBranch] = useState<string | undefined>(selectedBranch);

  const loggedUserCompanyId = useAppSelector((store) => store.auth.user?.companyId);

  const url = `${window.location.origin}/enquiry-form?source=${source}&branch=${branch}&company=${loggedUserCompanyId}`;
  return (
    <Modal visible={showShareModal}
      title={<Typography.Text style={{ fontWeight: '700' }}>Sharing Options</Typography.Text>}
      onCancel={onCancel}
      afterClose={() => setBranch(selectedBranch)}
      footer={null}
    >
      <Form layout="vertical" className="padding-1">
        <Form.Item label="Branch">
          <SearchBox
            fetchQuery={getBranchesByUserContactPermission}
            valueKey='id'
            labelKey='name'
            value={branch}
            className="branch-list"
            defaultOpen={false}
            showArrow
            placeholder="Select branch"
            onChange={setBranch}
          />
        </Form.Item>
        <Row align="middle" justify="center" className="margin-bottom-2">
          <QRCode value={url} size={150} />
        </Row>
        <Form.Item label="Shareable Link">
          <CopyableInputText key={`text-${branch}`} value={url} />
        </Form.Item>
        <Form.Item label="Embed Code">
          <CopyableInputText key={`iframe-${branch}`} value={`<iframe src="${url}" title="${url}"></iframe>`} />
        </Form.Item>
      </Form>
    </Modal >
  )
}

export default OfficeVisitUrlShareModal;
