import { PageHeaderTitle } from '@shared-components/elements';
import React, { useEffect, useState } from 'react';
import {
  Image,
  Row,
  Col,
  Card,
  Button,
  Form,
  Divider,
  Spin,
  Select,
} from 'antd';
import Title from 'antd/lib/typography/Title';
import {
  FORM_SIZE,
  LANGUAGE,
  SUBMIT_BTN,
  ADD_SUCCESS,
  LANGUAGE_LIST,
  TEXT,
} from '@moxie/constants';
import { getOneLeadsForm, postGenerateLead } from '@lyra/services.api';
import { LeadFormPersonalDetails } from '../forms/lead-form-personal-details';
import { LeadFormContactDetails } from '../forms/lead-form-contact-details';
import { LeadFormAddressDetails } from '../forms/lead-form-address-details';
import { LeadFormCurrentVisaDetails } from '../forms/lead-form-current-visa-information';
import { LeadFormOtherDetails } from '../forms/lead-form-other-details';
import { LeadFormEducationBackground } from '../forms/lead-form-education-background';
import { LeadFormEnglishTestScore } from '../forms/lead-form-english-test-score';
import { LeadFormOtherTestScore } from '../forms/lead-form-other-test-score';
import { LeadFormFooter } from '../forms/lead-form-footer';
import { errorHandler } from '@moxie/utils';
import FormItem from 'antd/lib/form/FormItem';
import { LeadFormComplianceInformation } from '../forms/lead-form-compliance-information';
import { IGeneratedLeadFormProps } from '@shared-components/models';
import {
  successNotificationHandler,
  errorNotificationHandler,
} from '../../shared';

const { Option } = Select;

const GeneratedLeadForm: React.FC<IGeneratedLeadFormProps> = ({
  id,
}: IGeneratedLeadFormProps) => {
  const [leadFormData, setLeadFormData] = useState<any>();
  const [hasPersonalDetails, setHasPersonalDetails] = useState<boolean>(false);
  const [hasAddressDetails, setHasAddressDetails] = useState<boolean>(false);
  const [hasContactDetails, setHasContactDetails] = useState<boolean>(false);
  const [hasVisaInformation, setHasVisaInformation] = useState<boolean>(false);
  const [hasEducationBackground, setHasEducationBackground] = useState<boolean>(
    false
  );
  const [hasEnglishTestScores, setHasEnglishTestScores] = useState<boolean>(
    false
  );
  const [hasOtherTestScore, setHasOtherTestScore] = useState<boolean>(false);
  const [hasPrivacyInformation, setHasPrivacyInformation] = useState<boolean>(
    false
  );
  const [hasOtherDetails, setHasOtherDetails] = useState<boolean>(false);
  const [loading, setLoading] = useState(true);
  const [hasAcceptedTerms, setHasAcceptedTerms] = useState<boolean>(false);
  const [documents, setDocuments] = useState<any>([]);

  const getLeadsForms = async () => {
    try {
      setLoading(true);
      const response = await getOneLeadsForm(id);
      setLeadFormData(response.data.data);
      setHasPersonalDetails(
        Object.values(response.data.data.personal_details_section).includes(
          true
        )
      );
      setHasAddressDetails(
        Object.values(response.data.data.address_details_section).includes(true)
      );
      setHasContactDetails(
        Object.values(response.data.data.contact_details_section).includes(true)
      );
      setHasEducationBackground(
        Object.values(response.data.data.education_background_section).includes(
          true
        )
      );
      setHasEnglishTestScores(
        Object.values(response.data.data.english_test_score_section).includes(
          true
        )
      );
      setHasOtherTestScore(
        Object.values(response.data.data.other_test_score_section).includes(
          true
        )
      );
      setHasVisaInformation(
        Object.values(response.data.data.visa_information_section).includes(
          true
        )
      );
      setHasPrivacyInformation(
        Object.values(response.data.data.compliance_section).includes(true)
      );
      setHasOtherDetails(
        response.data.data.comments || response.data.data.upload_documents
      );
    } catch (err) {
      errorHandler(err);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    getLeadsForms();
  }, [id]);

  const handleSubmit = async (data: any) => {
    setLoading(true);
    const formData = new FormData();
    data.branch_id = leadFormData?.branch_id;
    data.created_by = leadFormData?.created_by;
    data.company_id = leadFormData?.company_id;
    data.assignee_id = leadFormData?.created_by;
    documents?.forEach((document: any) => {
      formData.append('files', document);
      formData.append(
        'fileName',
        document.fileName + document.extension || document.name
      );
    });

    try {
      formData.append('formData', JSON.stringify(data));
      const response: any = await postGenerateLead(formData);
      if (response) {
        successNotificationHandler(ADD_SUCCESS);
        setTimeout(() => `${window.location.reload()}`, 500);
      }
    } catch (error: any) {
      setLoading(false);
      errorNotificationHandler(error.response.data.message);
    }
  };

  return (
    <div className="created-lead-form-content">
      {loading && (
        <div className="loading-spinner">
          <Spin spinning={loading} />
        </div>
      )}
      {leadFormData && !loading && (
        <Card className="created-lead-form-card">
          {leadFormData?.cover_image_path && (
            <div
              className="cover__image__background margin-bottom-2"
              style={{
                backgroundImage: `url(${leadFormData?.cover_image_path_preview})`,
              }}
            />
          )}
          <div className="created-leadForm-section ">
            <Row align="middle" justify="space-between">
              <Col span={8} xs={8}>
                <Image
                  height={90}
                  preview={false}
                  src={leadFormData?.logo_path_preview}
                />
              </Col>
              <Col span={8} xs={8}>
                <PageHeaderTitle
                  title={leadFormData ? leadFormData?.header_text : null}
                />
              </Col>
              <Col span={8} xs={8}>
                <Row>
                  <Title type="secondary" level={5} className="margin-right-2">
                    {LANGUAGE}:
                  </Title>

                  <Select
                    defaultValue={TEXT.DEFAULT_LANGUAGE}
                    className="language-select"
                    allowClear
                  >
                    {LANGUAGE_LIST.map((language) => (
                      <Option
                        value={language.language_value}
                        key={language.language_value}
                      >
                        {language.language_name}
                      </Option>
                    ))}
                  </Select>
                </Row>
              </Col>
            </Row>
            <Divider plain />
          </div>
          <Form
            data-testid="personal-details-form"
            layout="vertical"
            size={FORM_SIZE}
            onFinish={handleSubmit}
            scrollToFirstError={true}
          >
            <div id="personal">
              {hasPersonalDetails && (
                <LeadFormPersonalDetails
                  personalDetailsData={leadFormData?.personal_details_section}
                />
              )}
            </div>

            {hasContactDetails && (
              <LeadFormContactDetails
                contactDetailsData={leadFormData?.contact_details_section}
              />
            )}
            {hasAddressDetails && (
              <LeadFormAddressDetails
                addressDetailsData={leadFormData?.address_details_section}
              />
            )}
            {hasVisaInformation && (
              <LeadFormCurrentVisaDetails
                visaInformationData={leadFormData?.visa_information_section}
              />
            )}
            {hasEducationBackground && (
              <LeadFormEducationBackground
                EducationBackgroundData={
                  leadFormData?.education_background_section
                }
              />
            )}
            {hasEnglishTestScores && (
              <LeadFormEnglishTestScore
                englishTestScoreData={leadFormData?.english_test_score_section}
              />
            )}
            {hasOtherTestScore && (
              <LeadFormOtherTestScore
                otherTestScoreData={leadFormData?.other_test_score_section}
              />
            )}
            {hasOtherDetails && (
              <LeadFormOtherDetails
                otherDetailsData={{
                  comments: leadFormData?.comments,
                  upload_documents: leadFormData.upload_documents,
                }}
                documents={documents}
                setDocuments={setDocuments}
              />
            )}
            {hasPrivacyInformation && (
              <LeadFormComplianceInformation
                privacyInformationData={leadFormData?.compliance_section}
                setHasAcceptedTerms={setHasAcceptedTerms}
                hasAcceptedTerms={hasAcceptedTerms}
              />
            )}
            <FormItem>
              <div className="text-align-center">
                <Button
                  type="primary"
                  htmlType="submit"
                  disabled={hasPrivacyInformation && !hasAcceptedTerms}
                >
                  {SUBMIT_BTN}
                </Button>
              </div>
            </FormItem>
          </Form>
          <LeadFormFooter />
        </Card>
      )}
    </div>
  );
};
export { GeneratedLeadForm };
