import React, { useContext } from 'react';
import moment from 'moment-timezone';
import { List, Space, Typography } from 'antd';
import {
  FileAddOutlined,
  CheckOutlined,
  DownloadOutlined,
} from '@ant-design/icons';
import { IDocChecklistComponent } from '@shared-components/models';
import { TEXT } from '@moxie/constants';
import { ApplicationContext } from '../applicationContext';

const DocumentChecklistList = ({
  item,
  showDetail,
  onShowButton,
  handleAdd,
  selectedChecklist,
  selectedActor,
  handleDocumentsFromCheckListDownload,
  // forceRefresh,
  // currentStageId
}: IDocChecklistComponent) => {
  const { applicationState } = useContext(ApplicationContext);
  const { isDocumentsDownloading } = applicationState;

  return (
    <List.Item
      onClick={() => onShowButton(item)}
      className={`${
        selectedChecklist === item.id ? 'list__background' : 'assignee-hover'
      } padding-left-1 cursor-pointer`}
    >
      <List.Item.Meta
        title={
          <Space>
            {item?.totalDocs && item?.totalDocs > 0 ? (
              <div className="circle-with-tick">{<CheckOutlined />}</div>
            ) : (
              <div className="blank-circle" />
            )}
            <Typography.Text
              className={`margin-right-1 margin-left-1 ${
                selectedChecklist === item.id ? `text-white` : `text-link-color`
              }`}
            >
              {item.checklist.name}
            </Typography.Text>
            <div className="circle-with-text">{item?.totalDocs}</div>
          </Space>
        }
      />
      <Space>
        {!showDetail ? (
          <Typography.Text type="secondary" strong className="margin-right-2">
            {moment(item?.updated_at).format('MM/DD/YYYY, hh:mm A')}
          </Typography.Text>
        ) : null}
        <Typography.Title level={4}>
          {selectedChecklist === item.id && item.totalDocs! > 0 && (
            <DownloadOutlined
              disabled={isDocumentsDownloading}
              className={
                selectedChecklist === item.id
                  ? `text-white margin-right-2`
                  : `margin-right-2`
              }
              hidden={selectedChecklist !== item.id}
              onClick={() => {
                handleDocumentsFromCheckListDownload();
              }}
            />
          )}
        </Typography.Title>
        <Typography.Title level={4}>
          {selectedActor && (
            <FileAddOutlined
              className={
                selectedChecklist === item.id
                  ? `text-white margin-right-2`
                  : `margin-right-2`
              }
              onClick={() => handleAdd(TEXT.DEVICE)}
            />
          )}
        </Typography.Title>
      </Space>
    </List.Item>
  );
};

export { DocumentChecklistList };
