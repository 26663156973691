import React from 'react';
import {
  CANCEL_BTN,
  CREATE_WORKFLOW_TYPE_TITLE,
  DRAWER_WIDTH,
  EDIT_WORKFLOW_TYPE_TITLE,
  SAVE_BTN,
  UPDATE_BTN,
} from '@moxie/constants';
import { DrawerElem } from '@shared-components/elements';
import { IWorkflowTypeDrawerComponent } from '@shared-components/models';
import { Button, Space } from 'antd';
import { WorkflowTypeForm } from '../../forms/workflow-type';

const WorkflowTypeDrawer: React.FC<IWorkflowTypeDrawerComponent> = ({
  form,
  visible,
  onClose,
  handleFormSubmit,
  isEdit,
  handleSubmit,
  handleFieldsChange,
  serviceData,
  disabled,
  setDisabled,
  initialData,
}: IWorkflowTypeDrawerComponent) => {
  return (
    <DrawerElem
      title={isEdit ? EDIT_WORKFLOW_TYPE_TITLE : CREATE_WORKFLOW_TYPE_TITLE}
      width={DRAWER_WIDTH}
      visible={visible}
      onClose={onClose}
      footer={
        <div className="padding-bottom-2 text-align-right">
          <Space direction="horizontal">
            <Button onClick={onClose} className="lead-margin-right">
              {CANCEL_BTN}
            </Button>
            <Button
              type="primary"
              onClick={handleFormSubmit}
              disabled={disabled}
            >
              {isEdit ? UPDATE_BTN : SAVE_BTN}
            </Button>
          </Space>
        </div>
      }
    >
      <WorkflowTypeForm
        form={form}
        onSubmit={handleSubmit}
        initialData={initialData}
        serviceData={serviceData}
        setDisabled={setDisabled}
        handleFieldsChange={handleFieldsChange}
      />
    </DrawerElem>
  );
};
export { WorkflowTypeDrawer };
