import { API_URL } from '@moxie/constants';
import { crmAxios } from '.';
import { ICrmInterestedService } from '@model/index';

export const fetchInterestedServices = async (
  params?: Record<string, unknown>
) => {
  const { data } = await crmAxios.get<{ data: ICrmInterestedService[] }>(
    `${API_URL.CRM_API_INTERESTED_SERVICES}`,
    {
      params,
    }
  );
  return data;
};
