import * as actionTypes from './workflow-type.constant';
import { IAction, IPayload, IWorkflowType } from '@shared-components/models';

export const addWorkflowTypeRequest = (
  payload: IWorkflowType,
  triggerRefresh: any
): IAction<IWorkflowType> => {
  return {
    type: actionTypes.ADD_WORKFLOW_TYPE_REQUEST,
    payload,
    triggerRefresh,
  };
};

export const addWorkflowTypeSuccess = (
  payload: IWorkflowType
): IAction<IWorkflowType> => ({
  type: actionTypes.ADD_WORKFLOW_TYPE_SUCCESS,
  payload,
});

export const addWorkflowTypeFailure = (
  payload: IPayload<IWorkflowType>
): IAction => ({
  type: actionTypes.ADD_WORKFLOW_TYPE_FAILURE,
  payload,
});

export const updateWorkflowTypeRequest = (
  payload: any,
  triggerRefresh: any
): IAction<IWorkflowType> => ({
  type: actionTypes.UPDATE_WORKFLOW_TYPE_REQUEST,
  payload,
  triggerRefresh,
});

export const updateWorkflowTypeSuccess = (
  payload: IWorkflowType
): IAction<IWorkflowType> => ({
  type: actionTypes.UPDATE_WORKFLOW_TYPE_SUCCESS,
  payload,
});

export const updateWorkflowTypeFailure = (
  payload: IPayload<IWorkflowType>
): IAction => ({
  type: actionTypes.UPDATE_WORKFLOW_TYPE_FAILURE,
  payload,
});

export const getWorkflowTypesRequest = (): IAction => ({
  type: actionTypes.FETCH_WORKFLOW_TYPES_REQUEST,
});

export const getWorkflowTypesSuccess = (
  payload: IWorkflowType[]
): IAction<IWorkflowType[]> => ({
  type: actionTypes.FETCH_WORKFLOW_TYPES_SUCCESS,
  payload,
});

export const getWorkflowTypesFailure = (
  payload: IPayload<IWorkflowType>
): IAction => ({
  type: actionTypes.FETCH_WORKFLOW_TYPES_FAILURE,
  payload,
});

export const getWorkflowTypeRequest = (id: string): IAction<string> => ({
  type: actionTypes.FETCH_WORKFLOW_TYPE_REQUEST,
  payload: id,
});

export const getWorkflowTypeSuccess = (
  payload: IWorkflowType
): IAction<IWorkflowType> => ({
  type: actionTypes.FETCH_WORKFLOW_TYPE_SUCCESS,
  payload,
});

export const getWorkflowTypeFailure = (
  payload: IPayload<IWorkflowType>
): IAction => ({
  type: actionTypes.FETCH_WORKFLOW_TYPE_FAILURE,
  payload,
});

export const deleteWorkflowTypeRequest = (
  id: string,
  triggerRefresh: any
): IAction<string> => ({
  type: actionTypes.DELETE_WORKFLOW_TYPE_REQUEST,
  payload: id,
  triggerRefresh: triggerRefresh,
});

export const deleteWorkflowTypeSuccess = (): IAction => ({
  type: actionTypes.DELETE_WORKFLOW_TYPE_SUCCESS,
});

export const deleteWorkflowTypeFailure = (
  payload: IPayload<IWorkflowType>
): IAction => ({
  type: actionTypes.DELETE_WORKFLOW_TYPE_FAILURE,
  payload,
});

export const clearWorkflowTypeFetch = (): IAction => ({
  type: actionTypes.CLEAR_WORKFLOW_TYPE_FETCH,
});
