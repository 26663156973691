import { IAction } from '@shared-components/models';
import * as actionTypes from './product-type.constant';

export interface IProductTypeByServiceIdState {
  allData: any[];
  productSubTypes: [];
  error: boolean | null;
  loading: boolean;
}

const initialProductTypeByServiceIdState: IProductTypeByServiceIdState = {
  allData: [],
  productSubTypes: [],
  error: null,
  loading: false,
};
export const PRODUCT_TYPE_BY_SERVICE_ID_FEATURE_KEY = 'product_type';

export const productTypeByServiceIdReducer = (
  state = initialProductTypeByServiceIdState,
  action: IAction<any>
): any => {
  switch (action.type) {
    case actionTypes.FETCH_PRODUCT_TYPE_BY_SERVICE_ID_REQUEST:
      return {
        ...state,
        loading: true,
        error: null,
      };
    case actionTypes.FETCH_PRODUCT_TYPE_BY_SERVICE_ID_SUCCESS:
      return {
        ...state,
        loading: false,
        error: null,
        allData: action.payload,
      };
    case actionTypes.FETCH_PRODUCT_TYPE_BY_SERVICE_ID_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.payload,
      };
    case actionTypes.CLEAR_PRODUCT_TYPE_FETCH:
      return {
        ...state,
        allData: [],
      };
    case actionTypes.FETCH_PRODUCT_SUB_TYPES_REQUEST:
      return {
        ...state,
        loading: true,
        error: null,
      };
    case actionTypes.FETCH_PRODUCT_SUB_TYPES_SUCCESS:
      return {
        ...state,
        loading: false,
        error: null,
        productSubTypes: action.payload,
      };
    case actionTypes.FETCH_PRODUCT_SUB_TYPES_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.payload,
      };
    case actionTypes.CLEAR_PRODUCT_SUB_TYPE_FETCH:
      return {
        ...state,
        productSubTypes: [],
      };
    default:
      return state;
  }
};
