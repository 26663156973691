import { put, takeLatest } from 'redux-saga/effects';
import { AxiosResponse } from 'axios';
import { IAction, IUser } from '@shared-components/models';
import { errorHandler } from '@moxie/utils';
import {
  UserList,
  UserData,
  UserAdd,
  updateUserData,
  UserListWithoutAssignee,
} from '@admin/services.api';
import * as actionTypes from './user.constant';
import * as action from './user.action';
import { successNotificationHandler } from '@moxie/shared';

const getUsers = function* () {
  try {
    const res: AxiosResponse<any> = yield UserList('');
    yield put(action.fetchUsersSuccess(res?.data?.data));
  } catch (e) {
    errorHandler(e);
    yield put(action.fetchUsersFailure(e));
  }
};

const getUser = function* ({ payload }: IAction<string>) {
  if (payload) {
    try {
      const res: AxiosResponse = yield UserData(payload);
      yield put(action.fetchUserSuccess(res.data.data));
    } catch (e) {
      errorHandler(e);
      yield put(action.fetchUserFailure(e));
    }
  }
};

const addUser = function* ({ payload, triggerRefresh }: IAction<IUser>) {
  try {
    if (payload) {
      const response: AxiosResponse = yield UserAdd(payload);
      if (response) {
        successNotificationHandler(response.data.message);
      }
      yield put(action.addUserSuccess(response.data.data));
      triggerRefresh?.();
    }
  } catch (err) {
    errorHandler(err);
    yield put(action.addUserFailure(err));
  }
};

const getFollowerUsers = function* ({ payload }: IAction<string>) {
  if (payload) {
    try {
      const res: AxiosResponse = yield UserListWithoutAssignee(payload);
      yield put(action.fetchFollowerUsersSuccess(res?.data?.data));
    } catch (e) {
      errorHandler(e);
      yield put(action.fetchFollowerUsersFailure(e));
    }
  }
};

const editUser = function* ({ payload, triggerRefresh }: IAction<IUser>) {
  try {
    const response: AxiosResponse = yield updateUserData(
      payload?.id || '',
      payload || {}
    );
    yield getUser({
      payload: response.data.data.id,
      type: actionTypes.GET_USER_REQUEST,
    });
    yield put(action.updateUserSuccess(response.data.data));
    if (response) {
      successNotificationHandler(response.data.message);
    }
    triggerRefresh?.();
  } catch (err) {
    errorHandler(err);
    yield put(action.updateUserFailure(err));
  }
};

export default function* rootSage(): unknown {
  yield takeLatest(actionTypes.GET_USERS_REQUEST, getUsers);
  yield takeLatest(actionTypes.GET_USER_REQUEST, getUser);
  yield takeLatest(actionTypes.ADD_USER_REQUEST, addUser);
  yield takeLatest(actionTypes.UPDATE_USER_REQUEST, editUser);
  yield takeLatest(actionTypes.GET_FOLLOWER_USERS_REQUEST, getFollowerUsers);
}
