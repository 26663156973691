import { AxiosResponse } from 'axios';
import { put, takeLatest } from 'redux-saga/effects';
import * as institutionAction from './institution.action';
import * as institution from './institution.constant';
import { IAction } from '@shared-components/models';
import {
  saveInstitution,
  updateInstitution,
  deleteInstitution,
  apiGetInstituteDetail,
  importInstitution as importInstitutionApi,
  getInstitutions,
  // getInstitutionByService,
} from '../../libs/services.api/lib/institution.api';
import { successNotificationHandler } from '@moxie/shared';
import { INSTITUTION_RESPONSE_MESSAGE } from '@moxie/constants';
import { crmErrorHandler } from 'libs/shared/src/crm-modules/shared';


export function* getInstitution({ payload }: IAction<any>) {
  try {
    if (payload) {
      const response: AxiosResponse = yield apiGetInstituteDetail(payload);
      yield put(institutionAction.getInstitutionSuccess(response?.data));
    }
  } catch (e) {
    crmErrorHandler(e);
    yield put(institutionAction.getInstitutionFailure(e));
  }
}

export function* institutionByService({ payload }: any) {
  try {
    if (payload) {
      const response: AxiosResponse = yield getInstitutions({'filter.serviceId': payload.serviceId});
      yield put(
        institutionAction.getInstitutionByServiceSuccess(response.data.data)
      );
    }
  } catch (e) {
    crmErrorHandler(e);
    yield put(institutionAction.getInstitutionByServiceFailure(e));
  }
}

export function* addInstitution({ payload, triggerRefresh }: IAction<any>) {
  try {
    if (payload) {
      yield saveInstitution(payload);
      successNotificationHandler(INSTITUTION_RESPONSE_MESSAGE.ADD_SUCCESS);
    }
    triggerRefresh && triggerRefresh();
  } catch (e) {
    crmErrorHandler(e);
    yield put(institutionAction.addInstitutionFailure(e));
  }
}

export function* institutionUpdate({
  payload,
  triggerRefresh,
  onClose,
  clearFetchInstitution,
}: IAction<any>) {
  try {
    if (payload) {
      yield updateInstitution(payload);
      successNotificationHandler(INSTITUTION_RESPONSE_MESSAGE.UPDATE_SUCCESS);
      onClose && onClose();
      if (clearFetchInstitution)
        yield put(institutionAction.clearFetchInstitution());
    } 
    triggerRefresh && triggerRefresh();
  } catch (e) {
    crmErrorHandler(e);
    yield put(institutionAction.updateInstitutionFailure(e));
  }
}

export function* removeInstitution({ payload, triggerRefresh }: IAction<any>) {
  try {
    if (payload) {
      yield deleteInstitution(payload);
      successNotificationHandler(INSTITUTION_RESPONSE_MESSAGE.DELETE_SUCCESS);
    }
    triggerRefresh && triggerRefresh();
  } catch (e) {
    crmErrorHandler(e);
    yield put(institutionAction.deleteInstitutionFailure(e));
  }
}

export function* importInstitution({ payload, triggerRefresh }: IAction<any>) {
  try {
    if (payload) {
      const response: AxiosResponse = yield importInstitutionApi(payload);
      yield put(
        institutionAction.importInstitutionSuccess(response?.data?.data)
        
      );
      successNotificationHandler(response?.data?.message);
    }
    triggerRefresh && triggerRefresh();
  } catch (e) {
    crmErrorHandler(e);
    yield put(institutionAction.importInstitutionFailure(e));
  }
}

export default function* institutionSaga(): unknown {
  yield takeLatest(institution.GET_INSTITUTION_REQUEST, getInstitution);
  yield takeLatest(institution.ADD_INSTITUTION_REQUEST, addInstitution);
  yield takeLatest(institution.UPDATE_INSTITUTION_REQUEST, institutionUpdate);
  yield takeLatest(institution.DELETE_INSTITUTION_REQUEST, removeInstitution);
  yield takeLatest(institution.IMPORT_INSTITUTION_REQUEST, importInstitution);
  yield takeLatest(
    institution.GET_INSTITUTION_BY_SERVICE_REQUEST,
    institutionByService
  );
}
