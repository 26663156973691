import React, { useState } from 'react';
import { Card, Avatar, Col, Row, Tooltip, Typography } from 'antd';
import { IProfileSection } from '@shared-components/models';
import { LinkOutlined } from '@ant-design/icons';
import { DURATION, TEXT, URL_INSTITUTION_DETAIL_LINK } from '@moxie/constants';
import {
  InstitutionBranchTagGroup,
  WorkflowTagGroup,
} from '@shared-components/elements';
import { Link } from 'react-router-dom';
const { Text } = Typography;
export const ProductProfile: React.FC<IProfileSection> = (
  props: IProfileSection
) => {
  const {
    name,
    program_link,
    product_duration_year: year,
    product_duration_month: month,
    product_duration_week: week,
    branches,
    institution,
    institution_workflows,
    revenue_type,
    fees,
    officialId,
    identification_system_code,
  } = props?.data;

  const [isCopied, setIsCopied] = useState(false);
  const copyToClipboard = (text: string) => {
    navigator.clipboard.writeText(text);
    setIsCopied(true);
  };

  return (
    <section className="profile">
      <Card>
        <Row gutter={24}>
          <Col lg={8} className="custom_divider">
            <div className="profile__info">
              <div className="profile__info__item profile__info__user">
                <Avatar className="profile__info__user__avatar" size="large">
                  {name?.substring(0, 1)}
                </Avatar>
                <div className="profile__info__user__detail">
                  <h3>{name}</h3>
                  <Link
                    to={`${URL_INSTITUTION_DETAIL_LINK}${institution?.id}/branches`}
                  >
                    <Text className="text-primary-color">
                      {institution?.name}
                    </Text>
                  </Link>
                  {branches?.length > 0 && (
                    <Row className="padding-top-1">
                      <Col>
                        <h3 className="profile__title">{TEXT.BRANCHES}:</h3>
                      </Col>
                      <Col className="padding-left-1">
                        <InstitutionBranchTagGroup data={branches} />
                      </Col>
                    </Row>
                  )}
                </div>
              </div>
              <div className="padding-top-2">
                {officialId && (
                  <h3 className="profile__title">
                    CRICOS Course Code: &nbsp;{' '}
                    <span className="text-link-color">
                      {identification_system_code
                        ? identification_system_code?.toUpperCase()
                        : '-'}
                    </span>
                  </h3>
                )}
              </div>
            </div>
          </Col>

          <Col lg={8} className="custom_divider">
            <Row>
              <Col>
                <h3 className="profile__title">{TEXT.FEES}:</h3>
              </Col>
              <Col className="padding-left-1">
                {fees && fees.length > 0 ? (
                  <h3 className="profile__title">
                    {institution?.currency} {'  '}
                    {fees?.map((item: any) => {
                      const total_fees = item.pfees?.map(
                        (item: any) => item?.total_fee
                      );
                      const sum = total_fees.reduce(
                        (a: number, b: number) => a + b,
                        0
                      );
                      return sum;
                    })}
                  </h3>
                ) : (
                  '-'
                )}
              </Col>
            </Row>
            <Row>
              <Col>
                <h3 className="profile__title">{TEXT.DURATION}:</h3>
              </Col>
              <Col className="padding-left-1">
                {year || month || week ? (
                  <h3 className="profile__title">
                    &nbsp;
                    {year
                      ? `${year} ${year > 1 ? DURATION.YEARS : DURATION.YEAR}`
                      : ''}
                    {month
                      ? `${month} ${
                          month > 1 ? DURATION.MONTHS : DURATION.MONTH
                        }`
                      : ''}
                    {week
                      ? `${week} ${week > 1 ? DURATION.WEEKS : DURATION.WEEK}`
                      : ''}
                  </h3>
                ) : (
                  '-'
                )}
              </Col>
            </Row>
          </Col>
          <Col lg={8}>
            <Row>
              <Col>
                <h3 className="profile__title">{TEXT.WORKFLOW}:</h3>
              </Col>
              <Col className="padding-left-1">
                {institution_workflows ? (
                  <WorkflowTagGroup workflows={institution_workflows} />
                ) : (
                  '-'
                )}
              </Col>
            </Row>
            {revenue_type && (
              <Row>
                <Col>
                  <h3 className="profile__title">{TEXT.REVENUE_TYPE}:</h3>
                </Col>
                <Col className="padding-left-1">
                  <h3 className="profile__title">
                    {revenue_type ? revenue_type : '-'}
                  </h3>
                </Col>
              </Row>
            )}
            <Row>
              <Col>
                <h3 className="profile__title">{TEXT.PROGRAM_LINK}: &nbsp;</h3>
              </Col>
              {program_link ? (
                <>
                  <Col>
                    <a
                      className="text-primary-color "
                      target="_blank"
                      rel="noopener noreferrer"
                      href={program_link}
                    >
                      {TEXT.WEBSITE}
                    </a>
                  </Col>
                  <Col className="padding-left-1">
                    <Tooltip
                      placement="right"
                      color={isCopied ? '#8EAF74' : '#9B9D9A '}
                      title={
                        isCopied
                          ? `${TEXT.COPIED_SUCCESS}`
                          : `${TEXT.COPY_LINK}`
                      }
                    >
                      <LinkOutlined
                        onClick={() => copyToClipboard(`${program_link}`)}
                      />
                    </Tooltip>
                  </Col>
                </>
              ) : (
                '-'
              )}
            </Row>
          </Col>
        </Row>
      </Card>
    </section>
  );
};

export default ProductProfile;
