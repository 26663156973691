import { Editor, generateHTML, generateJSON } from '@tiptap/react';
import removeMentions from './remove-mentions';
import extractMentions from './extract-mentions';
import { extensions } from './use-tip-tap-editor';
import { summarizeNote } from '@crm/services.api';

const summarizeNoteInput = async (editor: Editor | null, systemPrompt: string) => {
  const payload = editor?.getJSON().content;
  const textWithoutMentions = removeMentions(payload);
  const { mentionContent: mentionJson } = extractMentions(payload)

  const html = generateHTML({
    type: 'doc',
    content: textWithoutMentions
  }, extensions);

  editor?.setEditable(false)
  const summarizedText = await summarizeNote(systemPrompt, html);
  const summarizedHtml = '<ul>' + summarizedText
    .replace('-', '')
    .split(/\n/g)
    .filter(text => text.length)
    .map(text => `<li>${text.replace(/\*\*(.*?)\*\*/g, "<b>$1</b>")}</li>`).join(' ') + '</ul>';

  const json = generateJSON(summarizedHtml, extensions);
  editor?.commands.setContent({
    type: 'doc',
    content: [{
      type: 'paragraph',
      content: mentionJson,
    },
    ...json.content,
    ],
  })
  editor?.setEditable(true);
}

export default summarizeNoteInput;
