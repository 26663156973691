import { getContactBranches } from '@crm/services.api';
import { errorNotificationHandler } from '@moxie/shared';
import { useQuery } from '@tanstack/react-query';

export const useFetchContactBranches = (contactId: string) => {
  const { data, isLoading } = useQuery({
    queryKey: ['contact-branches', contactId],
    queryFn: async () => await getContactBranches(contactId),
    onError: () => {
      errorNotificationHandler('Error fetching contact branches.');
    },
  });

  return {
    contactBranches: data?.data?.data ?? [],
    contactBranchesLoading: isLoading,
  };
};

