import { IAction, ISubjectDiciplineCRM, ISubjectDisciplineAreas } from '@shared-components/models';
import * as actionTypes from './subject-discipline.constant';

interface State {
  allData: ISubjectDiciplineCRM[],
  subjectAreas: ISubjectDisciplineAreas[],
  loading: boolean;
  error: unknown;
}
const initialSubjectDisciplineState : State = {
  allData: [],
  subjectAreas: [],
  loading: false,
  error: null,
};

export const SUBJECT_DISCIPLINE_FEATURE_KEY = 'subject_discipline';

export const subjectDisciplineReducer = (
  state = initialSubjectDisciplineState,
  action: IAction<any>
): State => {
  switch (action.type) {
    case actionTypes.GET_SUBJECT_DISCIPLINE_REQUEST:
      return {
        ...state,
        loading: true,
        error: null,
      };

    case actionTypes.GET_SUBJECT_DISCIPLINE_SUCCESS:
      return {
        ...state,
        loading: false,
        error: null,
        allData: action.payload,
      };

    case actionTypes.GET_SUBJECT_DISCIPLINE_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.payload,
      };
    case actionTypes.GET_SUBJECT_AREAS_REQUEST:
      return {
        ...state,
        loading: true,
        error: null,
      };

    case actionTypes.GET_SUBJECT_AREAS_SUCCESS:
      return {
        ...state,
        loading: false,
        error: null,
        subjectAreas: action.payload,
      };

    case actionTypes.GET_SUBJECT_AREAS_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.payload,
      };

    default:
      return state;
  }
};
