import { IAction } from '@shared-components/models';
import * as actionTypes from './role-permission.constant';

export const getRolesRequest = (): IAction<any> => ({
  type: actionTypes.GET_ROLES_REQUEST,
});
export const getRolesSuccess = (payload: any[]): IAction<any[]> => ({
  type: actionTypes.GET_ROLES_SUCCESS,
  payload,
});
export const getRolesFailure = (payload: any[]): IAction<any[]> => ({
  type: actionTypes.GET_ROLES_FAILURE,
  payload,
});
export const clearRolesSuccess = (): IAction<any[]> => ({
  type: actionTypes.GET_ROLES_CLEAR,
});

export const getRoleRequest = (payload: any): IAction<any> => ({
  type: actionTypes.GET_ROLE_REQUEST,
  payload,
});
export const getRoleSuccess = (payload: any[]): IAction<any[]> => ({
  type: actionTypes.GET_ROLE_SUCCESS,
  payload,
});
export const getRoleFailure = (payload: any[]): IAction<any[]> => ({
  type: actionTypes.GET_ROLE_FAILURE,
  payload,
});

export const updateRoleRequest = (payload: any): IAction<any> => ({
  type: actionTypes.UPDATE_ROLE_REQUEST,
  payload,
});
export const updateRoleSuccess = (payload: any[]): IAction<any[]> => ({
  type: actionTypes.UPDATE_ROLE_SUCCESS,
  payload,
});
export const updateRoleFailure = (payload: any[]): IAction<any[]> => ({
  type: actionTypes.UPDATE_ROLE_FAILURE,
  payload,
});

export const deleteRoleRequest = (payload: any): IAction<any> => ({
  type: actionTypes.DELETE_ROLE_REQUEST,
  payload,
});
export const deleteRoleSuccess = (payload: any[]): IAction<any[]> => ({
  type: actionTypes.DELETE_ROLE_SUCCESS,
  payload,
});
export const deleteRoleFailure = (payload: any[]): IAction<any[]> => ({
  type: actionTypes.DELETE_PERMISSION_FAILURE,
  payload,
});

export const addRoleRequest = (payload: any): IAction<any> => ({
  type: actionTypes.ADD_ROLE_REQUEST,
  payload,
});
export const addRoleSuccess = (payload: any[]): IAction<any[]> => ({
  type: actionTypes.ADD_ROLE_SUCCESS,
  payload,
});
export const addRoleFailure = (payload: any[]): IAction<any[]> => ({
  type: actionTypes.ADD_ROLE_FAILURE,
  payload,
});

export const getPermissionsRequest = (): IAction<any> => ({
  type: actionTypes.GET_PERMISSIONS_REQUEST,
});
export const getPermissionsSuccess = (payload: any[]): IAction<any[]> => ({
  type: actionTypes.GET_PERMISSIONS_SUCCESS,
  payload,
});
export const getPermissionsFailure = (payload: any[]): IAction<any[]> => ({
  type: actionTypes.GET_PERMISSIONS_FAILURE,
  payload,
});
