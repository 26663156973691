import {
  ADDRESS1_LABEL,
  ADDRESS2_LABEL,
  CATEGORY_ITEMS,
  GUTTER,
  LABEL,
  NOT_AVAILABLE_MESSAGE,
} from '@moxie/constants';
import { Card, Col, Form, Row } from 'antd';
import { useForm } from 'antd/lib/form/Form';
import React, { useEffect } from 'react';

const AddressDetailsTab: React.FC<any> = (props) => {
  const { lead } = props;
  const [form] = useForm();

  useEffect(() => {
    form.setFieldsValue({
      country: lead?.country,
      city: lead?.city,
      state: lead?.state,
      street: lead?.street,
      zip_code: lead?.zip_code,
      postal_code: lead?.postal_code,
      address_line_one: lead?.address_line_one,
      address_line_two: lead?.address_line_two,
    });
  }, [lead]);
  return (
    <Card title={CATEGORY_ITEMS[1].name}>
      <Form
        layout="horizontal"
        form={form}
        labelCol={{ lg: 6, md: 6, span: 10 }}
        wrapperCol={{ span: 14, offset: 1 }}
      >
        <Row gutter={GUTTER}>
          <Col span={24} xl={12} xxl={8}>
            <Form.Item
              className="custom_label_style initial_capital"
              name="address_line_one"
              label={ADDRESS1_LABEL}
            >
              {lead?.address_line_one
                ? lead?.address_line_one
                : NOT_AVAILABLE_MESSAGE}
            </Form.Item>
          </Col>
          <Col span={24} xl={12} xxl={8}>
            <Form.Item
              className="custom_label_style initial_capital"
              name="address_line_two"
              label={ADDRESS2_LABEL}
            >
              {lead?.address_line_two
                ? lead?.address_line_two
                : NOT_AVAILABLE_MESSAGE}
            </Form.Item>
          </Col>
          <Col span={24} xl={12} xxl={8}>
            <Form.Item
              className="custom_label_style initial_capital"
              name="city"
              label={LABEL.CITY}
              rules={[{ required: true }]}
            >
              {lead?.city ? lead?.city : NOT_AVAILABLE_MESSAGE}
            </Form.Item>
          </Col>
          <Col span={24} xl={12} xxl={8}>
            <Form.Item
              className="custom_label_style initial_capital"
              name="state"
              label={LABEL.STATE}
            >
              {lead?.state ? lead?.state : NOT_AVAILABLE_MESSAGE}
            </Form.Item>
          </Col>
          <Col span={24} xl={12} xxl={8}>
            <div id="country_list" className="relative">
              <Form.Item
                className="custom_label_style"
                name="country"
                label={LABEL.COUNTRY}
                rules={[{ required: true }]}
              >
                {lead?.country ? lead?.country : NOT_AVAILABLE_MESSAGE}
              </Form.Item>
            </div>
          </Col>
          <Col span={24} xl={12} xxl={8}>
            <Form.Item
              className="custom_label_style initial_capital"
              name="zip_code"
              label={LABEL.ZIP_CODE}
            >
              {lead?.zip_code ? lead?.zip_code : NOT_AVAILABLE_MESSAGE}
            </Form.Item>
          </Col>
        </Row>
      </Form>
    </Card>
  );
};

export { AddressDetailsTab };
