import React from 'react';
import { DrawerElem } from '@moxie/shared';
import {
  CANCEL_BTN,
  CREATE_WORKFLOW_TITLE,
  DRAWER_WIDTH,
  EDIT_WORKFLOW_TITLE,
  SAVE_BTN,
  UPDATE_BTN,
} from '@moxie/constants';
import { Button, Space } from 'antd';
import { IWorkflowTypeDrawerComponent } from '@shared-components/models';
import { CreateWorkflowForm } from '../../forms/workflow';
import { useAppSelector } from '@crm/core';
import { RootState } from 'apps/crm/src/core/store';

const CreateWorkflowDrawer: React.FC<IWorkflowTypeDrawerComponent> = ({
  form,
  visible,
  onClose,
  handleFormSubmit,
  handleFieldsChange,
  disabled,
  isEdit,
  handleSubmit,
}: IWorkflowTypeDrawerComponent) => {
  const workflowTypeList = useAppSelector(
    (state: RootState) => state.workflow_types.allData
  );

  return (
    <DrawerElem
      title={isEdit ? EDIT_WORKFLOW_TITLE : CREATE_WORKFLOW_TITLE}
      width={DRAWER_WIDTH}
      visible={visible}
      onClose={onClose}
      footer={
        <div className="padding-bottom-2 text-align-right">
          <Space direction="horizontal">
            <Button onClick={onClose} className="lead-margin-right">
              {CANCEL_BTN}
            </Button>
            <Button
              type="primary"
              onClick={handleFormSubmit}
              disabled={disabled}
            >
              {isEdit ? UPDATE_BTN : SAVE_BTN}
            </Button>
          </Space>
        </div>
      }
    >
      <CreateWorkflowForm
        form={form}
        onSubmit={handleSubmit}
        handleFieldsChange={handleFieldsChange}
        workflowTypeList={workflowTypeList}
      />
    </DrawerElem>
  );
};

export { CreateWorkflowDrawer };
