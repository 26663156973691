import React, { useEffect, useState } from 'react';
import Form from 'antd/lib/form';
import { Col, Collapse, Row } from 'antd';
import {
  FormItem,
  Input,
  Option,
  PhoneInput,
  SearchableSelect,
} from '@moxie/shared';
import {
  ADDRESS1_LABEL,
  ADDRESS2_LABEL,
  ADDRESS_INFORMATION,
  AUTH_MESSAGE,
  BRANCH_NAME_REQUIRED,
  BRANCH_RESPONSE_MESSAGE,
  COUNTRIES,
  COUNTRY_PLACEHOLDER,
  DEFAULT_COUNTRY_CODE,
  DEPARTMENT_TITLE,
  EMAIL_PLACEHOLDER,
  GUTTER,
  INPUT_NUMBER_INVALID,
  LABEL,
  OFFICE_INFORMATION,
  OFFICE_PLACEHOLDER,
  PHONE_NUMBER_INVALID,
  PHONE_PLACEHOLDER,
  PLACEHOLDER,
  regex,
  TIMEZONE,
  TIMEZONE_LABEL,
} from '@moxie/constants';
import { CaretRightOutlined } from '@ant-design/icons';
import {
  IOfficeBranch,
  IForm,
  IDepartment,
  IGetPopupContainer,
} from '@shared-components/models';
import { SelectValue } from 'antd/lib/select';
import { UserSelectBox } from '@moxie/shared';
import { useAppSelector } from '@lyra/core';
import { checkBranchName } from '@lyra/services.api';
const { Panel } = Collapse;

const OfficeBranchForm: React.FC<IForm<IOfficeBranch>> = ({
  form,
  initialData,
  setNameValidationStatus,
  onSubmit,
  setDisabled,
}: IForm<IOfficeBranch>) => {
  const [countryCode, setCountryCode] = useState(DEFAULT_COUNTRY_CODE);
  const departmentList = useAppSelector((store) => store.departments.allData);

  useEffect(() => {
    if (initialData) {
      form.setFieldsValue(initialData);
      handleCountryChange(initialData?.address?.country);
    } else {
      form.setFieldsValue({});
    }
  }, [form, initialData]);

  const handleCountryCodeChange = (code: string) => {
    setCountryCode(code);
    setDisabled(false);
  };

  const handleFinish = async (data: IOfficeBranch) => {
    data.email = data?.email?.replace(/ /g, '');
    onSubmit && onSubmit(data);
    form.resetFields();
  };

  const onFieldsChange = (allFields: string | any[]) => {
    if (allFields.length > 0) {
      setDisabled(false);
    }
  };
  const handleCountryChange = (countryName?: string) => {
    COUNTRIES.find((item) => {
      if (item.name === countryName) {
        setCountryCode(item.dial_code);
      }
    });
  };

  const handleBranchNameChange = async (
    rule: any,
    value: any
  ): Promise<any> => {
    value = value?.trim();
    value = value?.toLowerCase().replace(/  + /g, ' ');
    if (value?.length > 50) {
      setNameValidationStatus('error');
      return Promise.resolve(true);
    }
    if (value?.length > 0) {
      setNameValidationStatus('validating');
      if (value !== '' || null || undefined) {
        const result = await checkBranchName(value, initialData?.id);
        if (result.data.success) {
          setNameValidationStatus('success');
          return Promise.resolve(true);
        } else {
          setNameValidationStatus('error');
          return Promise.reject(
            new Error(BRANCH_RESPONSE_MESSAGE.BRANCH_NAME_ALREADY_EXISTS)
          );
        }
      }
    }
  };
  return (
    <div>
      <Form
        layout="vertical"
        form={form}
        onFinish={handleFinish}
        onFieldsChange={onFieldsChange}
      >
        <div className="form_section_header">{OFFICE_INFORMATION}</div>
        <Row gutter={GUTTER}>
          <Col span={12}>
            <Form.Item
              name="name"
              label={LABEL.BRANCH_NAME}
              rules={[
                { required: true, max: 50 },
                { validator: handleBranchNameChange },
                {
                  whitespace: true,
                  type: 'string',
                  message: BRANCH_NAME_REQUIRED,
                },
              ]}
            >
              <Input placeholder={OFFICE_PLACEHOLDER} />
            </Form.Item>
          </Col>
          <Col span={12}>
            <Form.Item
              name="email"
              label={LABEL.EMAIL}
              rules={[
                {
                  required: true,
                  message: AUTH_MESSAGE.INVALID_EMAIL,
                  pattern: new RegExp(regex.EMAIL),
                },
              ]}
            >
              <Input placeholder={EMAIL_PLACEHOLDER} />
            </Form.Item>
          </Col>
        </Row>
        <Row gutter={GUTTER}>
          <Col span={12}>
            <Form.Item
              name="mobile"
              label={LABEL.MOBILE}
              rules={[
                {
                  pattern: new RegExp(regex.ONLY_NUMBER),
                  message: PHONE_NUMBER_INVALID,
                },
              ]}
            >
              <PhoneInput
                type="string"
                placeholder={PHONE_PLACEHOLDER}
                dialCode={countryCode}
                onChangeDialCode={handleCountryCodeChange}
              />
            </Form.Item>
          </Col>
          <Col span={12}>
            <Form.Item
              name="phone"
              label={LABEL.OFFICE_PHONE}
              rules={[
                {
                  pattern: new RegExp(regex.ONLY_NUMBER),
                  message: PHONE_NUMBER_INVALID,
                },
              ]}
            >
              <PhoneInput
                type="string"
                placeholder={PHONE_PLACEHOLDER}
                dialCode={countryCode}
                onChangeDialCode={handleCountryCodeChange}
              />
            </Form.Item>
          </Col>
        </Row>
        <Row gutter={GUTTER}>
          <Col span={12}>
            <Form.Item
              name="business_registration_number"
              label={LABEL.BUSINESS_REG_NO}
            >
              <Input placeholder={LABEL.BUSINESS_REG_NO} />
            </Form.Item>
          </Col>
          <Col span="12">
            <div id="timezone_list">
              <FormItem
                name="timezone"
                label={TIMEZONE_LABEL}
                rules={[{ required: false }]}
              >
                <SearchableSelect
                  placeholder={PLACEHOLDER.SELECT_TIMEZONE}
                  getPopupContainer={(): IGetPopupContainer =>
                    document.getElementById('timezone_list')
                  }
                >
                  {TIMEZONE.map((item) => {
                    return (
                      <Option value={item.text} key={item.value}>
                        {item.text}
                      </Option>
                    );
                  })}
                </SearchableSelect>
              </FormItem>
            </div>
          </Col>
        </Row>
        <Row gutter={GUTTER}>
          <Col span={12}>
            <div id="contact_person_list">
              <Form.Item
                name="contact_person_id"
                label={LABEL.CONTACT_PERSON}
                rules={[{ required: false }]}
              >
                <UserSelectBox
                  placeholder={LABEL.CONTACT_PERSON}
                  getPopupContainer={(): IGetPopupContainer =>
                    document.getElementById('contact_person_list')
                  }
                />
              </Form.Item>
            </div>
          </Col>
          <Col span="12">
            <FormItem
              name="departments_id"
              label={DEPARTMENT_TITLE}
              rules={[{ required: false }]}
            >
              <SearchableSelect
                optionFilter="key"
                placeholder={DEPARTMENT_TITLE}
                mode="multiple"
              >
                {departmentList &&
                  departmentList.map((item: IDepartment) => {
                    return (
                      <Option value={item.id || ''} key={item.name}>
                        {item.name}
                      </Option>
                    );
                  })}
              </SearchableSelect>
            </FormItem>
          </Col>
        </Row>
        <Collapse
          defaultActiveKey={['addressInfo']}
          bordered={false}
          ghost={true}
          expandIcon={({ isActive }) => (
            <CaretRightOutlined rotate={isActive ? 90 : 0} />
          )}
        >
          <Panel header={<span>{ADDRESS_INFORMATION}</span>} key="addressInfo">
            <Row gutter={GUTTER}>
              <Col span={12}>
                <Form.Item
                  name={['address', 'address_line_one']}
                  label={ADDRESS1_LABEL}
                  rules={[{ required: false }]}
                >
                  <Input placeholder={ADDRESS1_LABEL} />
                </Form.Item>
              </Col>
              <Col span={12}>
                <Form.Item
                  name={['address', 'address_line_two']}
                  label={ADDRESS2_LABEL}
                  rules={[{ required: false }]}
                >
                  <Input placeholder={ADDRESS2_LABEL} />
                </Form.Item>
              </Col>
            </Row>
            <Row gutter={GUTTER}>
              <Col span={12}>
                <Form.Item
                  name={['address', 'city']}
                  label={LABEL.CITY}
                  rules={[{ required: false }]}
                >
                  <Input placeholder={PLACEHOLDER.CITY} />
                </Form.Item>
              </Col>
              <Col span={12}>
                <Form.Item
                  name={['address', 'state']}
                  label={LABEL.STATE}
                  rules={[{ required: false }]}
                >
                  <Input placeholder={PLACEHOLDER.STATE} />
                </Form.Item>
              </Col>
            </Row>
            <Row gutter={GUTTER}>
              <Col span={12}>
                <div id="country_list">
                  <Form.Item
                    name={['address', 'country']}
                    label={LABEL.COUNTRY}
                    rules={[{ required: false }]}
                  >
                    <SearchableSelect
                      onChange={(value: SelectValue) => {
                        if (value) handleCountryChange(value.toString());
                      }}
                      placeholder={COUNTRY_PLACEHOLDER}
                      optionFilter="search_prop"
                      getPopupContainer={(): IGetPopupContainer =>
                        document.getElementById('country_list')
                      }
                    >
                      <Option value="null" label="None">
                        None
                      </Option>
                      {COUNTRIES.map((item) => {
                        return (
                          <Option
                            value={item.name}
                            key={item.code}
                            search_prop={`${item.name} ${item.code}`}
                          >
                            {item.name}
                          </Option>
                        );
                      })}
                    </SearchableSelect>
                  </Form.Item>
                </div>
              </Col>
              <Col span={12}>
                <Form.Item
                  name={['address', 'postal_code']}
                  label={LABEL.ZIP_OR_POSTAL_CODE}
                  rules={[
                    {
                      required: false,
                      pattern: regex.ONLY_NUMBER,
                      message: INPUT_NUMBER_INVALID,
                    },
                  ]}
                >
                  <Input placeholder={LABEL.POSTAL_CODE} />
                </Form.Item>
              </Col>
            </Row>
          </Panel>
        </Collapse>
      </Form>
    </div>
  );
};

export { OfficeBranchForm };
