import React, { useEffect, useState } from 'react';
import { Col, Form, FormItemProps, Row } from 'antd';
import { FormItem, Input, Select, Option } from '@shared-components/elements';
import Title from 'antd/lib/typography/Title';
import {
  CITY_OR_STATE_LABEL,
  CITY_OR_STATE_PLACEHOLDER,
  COUNTRY_LABEL,
  COUNTRY_PLACEHOLDER,
  EMAIL_LABEL,
  EMAIL_PLACEHOLDER,
  FIRST_NAME_LABEL,
  FIRST_NAME_PLACEHOLDER,
  FORM_SIZE,
  GUTTER,
  LAST_NAME_LABEL,
  LAST_NAME_PLACEHOLDER,
  USER_PERSONAL_DETAIL,
  COUNTRIES,
  TIMEZONE_LABEL,
  PLACEHOLDER,
  TIMEZONE,
  regex,
  AUTH_MESSAGE,
  ADDRESS,
} from '@moxie/constants';
import { IPersonalForm, IPersonalInformation } from '@shared-components/models';
import { FieldData } from 'rc-field-form/es/interface.js';
import { checkAccount } from '@luzon/services.api';
import { formExtraNotes } from '@moxie/shared';

const PersonalDetailsForm: React.FC<IPersonalForm<IPersonalInformation>> = ({
  onSubmit,
  form,
  isValid,
}: IPersonalForm<IPersonalInformation>) => {
  const [timezones, setTimeZones] = useState<any>();
  const [countries] = useState(COUNTRIES);
  const [emailValidationStatus, setEmailValidationStatus] = useState<
    FormItemProps['validateStatus']
  >('');

  const checkEmailExist = async (rule: any, value: string): Promise<any> => {
    value = value?.toLowerCase();
    if (!value) {
      setEmailValidationStatus('error');
      return Promise.resolve(true);
    }

    const regexEmail = new RegExp(regex.EMAIL);
    if (!regexEmail.test(value)) {
      setEmailValidationStatus('error');
      throw new Error(AUTH_MESSAGE.INVALID_EMAIL);
    }
    setEmailValidationStatus('validating');
    const trimValue = value?.replace(/ /g, '');
    const result = await validateEmail({
      email: trimValue,
    });

    if (result) {
      setEmailValidationStatus('success');
      return Promise.resolve(true);
    } else {
      setEmailValidationStatus('error');
      throw new Error(AUTH_MESSAGE.REGISTER_EMAIL_TAKEN);
    }
  };

  const validateEmail = async ({ email }: { email: string }) => {
    try {
      const res = await checkAccount(email);

      return !!res?.data?.success;
    } catch (err) {
      return false;
    }
  };
  const handleSubmit = async (data: IPersonalInformation) => {
    data.email = data?.email?.replace(/ /g, '');
    onSubmit && onSubmit(data);
  };

  const handleCountryFilter = (input: string, option: any): boolean => {
    const filtered =
      option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0;
    return filtered;
  };
  const handleChange = (_data: FieldData[], data: FieldData[]) => {
    let isFieldsTouched = false;
    isFieldsTouched = form.isFieldsTouched(true);
    let error = false;
    let valueIsEmpty = false;
    data.forEach((item: any) => {
      if (!item?.value || item?.value?.length === 0) {
        valueIsEmpty = true;
      }
    });
    isFieldsTouched = isFieldsTouched || !valueIsEmpty;
    form.getFieldsError().forEach((item) => {
      if (item.errors.length > 0) {
        error = true;
      }
    });
    isValid && isValid(!error && isFieldsTouched);
  };
  const handleTimezonesFilter = (input: string, options: any): boolean => {
    return options.children?.toLowerCase().indexOf(input.toLowerCase()) >= 0;
  };

  const filterIt = (arr: any[], searchKey: string) => {
    return arr.filter(function (obj) {
      return Object.keys(obj).some(function (key) {
        return obj[key]?.toLowerCase().trim().includes(searchKey);
      });
    });
  };
  const timezonesArr = [
    ...TIMEZONE.map((item: { text: any; value: any; utc: any }) => {
      const obj = {
        text: item.text,
        value: item.value,
        utc: `${item.utc}`,
      };
      return obj;
    }),
  ];
  const handleSelectCountry = (value: any, options: any) => {
    form.resetFields(['time_zone']);

    const filterTimezonesArr = filterIt(
      timezonesArr,
      options.children?.toLowerCase().trim()
    );

    const filterArrTimezones =
      filterTimezonesArr?.length <= 0 ? [...TIMEZONE] : [...filterTimezonesArr];
    setTimeZones(filterArrTimezones);
  };
  const handleSelectCountryClear = () => {
    form.resetFields(['time_zone']);
  };

  useEffect(() => {
    if (form.getFieldValue('country')) {
      const filterTimezonesArr = filterIt(
        timezonesArr,
        form.getFieldValue('country')?.toLowerCase().trim()
      );

      const filterArrTimezones =
        filterTimezonesArr?.length <= 0
          ? [...TIMEZONE]
          : [...filterTimezonesArr];
      setTimeZones(filterArrTimezones);
    }
  }, []);

  return (
    <>
      <Title level={4}>{USER_PERSONAL_DETAIL}</Title>
      <Form
        data-testid="personal-details-form"
        layout="vertical"
        form={form}
        size={FORM_SIZE}
        onFinish={handleSubmit}
        onFieldsChange={handleChange}
      >
        <div>
          <Row gutter={GUTTER}>
            <Col span="12">
              <FormItem
                name="first_name"
                label={FIRST_NAME_LABEL}
                rules={[
                  {
                    required: true,
                    max: 50,
                    type: 'string',
                    pattern: new RegExp(regex.ONLY_STRING),
                    message: 'Please enter a valid string',
                  },
                ]}
              >
                <Input placeholder={FIRST_NAME_PLACEHOLDER} />
              </FormItem>
            </Col>
            <Col span="12">
              <FormItem
                name="last_name"
                label={LAST_NAME_LABEL}
                rules={[
                  {
                    required: true,
                    max: 50,
                    type: 'string',
                    pattern: new RegExp(regex.ONLY_STRING),
                    message: 'Please enter a valid string',
                  },
                ]}
              >
                <Input placeholder={LAST_NAME_PLACEHOLDER} />
              </FormItem>
            </Col>
          </Row>
          <FormItem
            name="email"
            label={EMAIL_LABEL}
            validateStatus={emailValidationStatus}
            rules={[
              {
                required: true,
              },
              {
                validator: checkEmailExist,
              },
            ]}
          >
            <Input placeholder={EMAIL_PLACEHOLDER} />
          </FormItem>
          <Row gutter={GUTTER}>
            <Col span="12">
              <FormItem
                shouldUpdate
                name="country"
                label={COUNTRY_LABEL}
                rules={[{ required: true, type: 'string', max: 50 }]}
              >
                <Select
                  placeholder={COUNTRY_PLACEHOLDER}
                  showSearch
                  filterOption={handleCountryFilter}
                  onSelect={handleSelectCountry}
                  onClear={handleSelectCountryClear}
                  allowClear
                >
                  {countries &&
                    countries.map((country) => {
                      return (
                        <Option value={country.name} key={country.code}>
                          {country.name}
                        </Option>
                      );
                    })}
                </Select>
              </FormItem>
            </Col>
            <Col span="12">
              <FormItem
                name="city_or_state"
                label={CITY_OR_STATE_LABEL}
                rules={[{ required: true, type: 'string', max: 100 }]}
              >
                <Input placeholder={CITY_OR_STATE_PLACEHOLDER} />
              </FormItem>
            </Col>
            <Col span="24">
              <FormItem
                name="address1"
                label={ADDRESS}
                rules={[{ required: true, type: 'string', max: 50 }]}
              >
                <Input placeholder={ADDRESS} />
              </FormItem>
            </Col>
          </Row>
          <FormItem
            name="time_zone"
            label={TIMEZONE_LABEL}
            rules={[{ required: true }]}
            extra={
              !form.getFieldValue('country') ? formExtraNotes('Country') : ''
            }
            shouldUpdate
          >
            <Select
              placeholder={PLACEHOLDER.SELECT_TIMEZONE}
              showSearch
              filterOption={handleTimezonesFilter}
              onClear={handleSelectCountryClear}
              allowClear
            >
              {timezones &&
                timezones.map(
                  (item: { text: string; value: any }, index: any) => {
                    return (
                      <Option value={item.text} key={item.value + index}>
                        {item.text}
                      </Option>
                    );
                  }
                )}
            </Select>
          </FormItem>
        </div>
      </Form>
    </>
  );
};

export { PersonalDetailsForm };
