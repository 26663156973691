import { axiosInstance as axios } from '@lyra/services.api';
import { API_URL } from '@moxie/constants';
import {
  IProductComparison,
  IProductResponse,
  IResponse,
} from '@shared-components/models';
import { AxiosResponse } from 'axios';

const baseUrl = process.env.NX_BASE_URL;
export const endpoint_product = `${baseUrl}${API_URL.PRODUCTS}`;
export const endpoint_product_fees = `${baseUrl}${API_URL.PRODUCTS_FEES}`;

const productServices = {
  apiGetProduct: async (): Promise<IProductResponse> => {
    return axios.get(`${endpoint_product}`);
  },

  apiGetProductDetail: async (id: string): Promise<IProductResponse> => {
    return axios.get(`${endpoint_product}/${id}`);
  },
  apiGetProductTypes: async (): Promise<IProductResponse> => {
    return axios.get(`${API_URL.PRODUCT_TYPES_FOR_AM}`);
  },

  apiAddProductFee: async (data: any): Promise<IProductResponse> => {
    return axios.post(`${endpoint_product_fees}`, data);
  },

  apiGetProductFees: async (id: string): Promise<IProductResponse> => {
    return axios.get(`${endpoint_product_fees}/product/${id}`);
  },

  apiGetProductFilters: async (id: string): Promise<IProductResponse> => {
    return axios.get(`${endpoint_product_fees}/product/${id}`);
  },

  apiGetProductWorkflows: async (id: string): Promise<AxiosResponse> => {
    return axios.get(`${endpoint_product}/workflows/${id}`);
  },

  apiAddProductComparison: async (
    data: IProductComparison
  ): Promise<IProductResponse> => {
    return axios.post(`${endpoint_product}/compare`, data);
  },

  apiGetProductBranches: async (id: string): Promise<AxiosResponse> => {
    return axios.get(`${endpoint_product}/${id}/branches`);
  },

  apiGetProductFiltersData: async (): Promise<IResponse> => {
    return axios.get(`/products-filter`);
  },

  apiGetProductIntakes: async (
    productId: string,
    institutionBranchId: string
  ): Promise<IResponse> => {
    return axios.get(
      `${endpoint_product}/intakes/${productId}/${institutionBranchId}`
    );
  },
};

export { productServices };
