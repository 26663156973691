export const GET_CATEGORIES_REQUEST = 'GET_CATEGORIES_REQUEST';
export const GET_CATEGORIES_REQUEST_BY_WF_STAGE =
  'GET_CATEGORIES_REQUEST_BY_WF_STAGE';
export const GET_CATEGORIES = 'GET_CATEGORIES';

export const POST_CATEGORY_REQUEST = 'POST_CATEGORY_REQUEST';
export const POST_CATEGORY = 'POST_CATEGORY';

export const PUT_CATEGORY_REQUEST = 'PUT_CATEGORY_REQUEST';
export const PUT_CATEGORY = 'PUT_CATEGORY';

export const SELECT_CATEGORY = 'SELECT_CATEGORY';
export const SELECT_CATEGORY_REQUEST = 'SELECT_CATEGORY_REQUEST';

export const DELETE_CATEGORY_REQUEST = 'DELETE_CATEGORY_REQUEST';
export const DELETE_CATEGORY = 'DELETE_CATEGORY';

export const GET_CATEGORIES_LABEL_REQUEST = 'GET_CATEGORIES_LABEL_REQUEST';
export const GET_CATEGORIES_LABEL = 'GET_CATEGORIES_LABEL';

export const POST_CATEGORY_LABEL_REQUEST = 'POST_CATEGORY_LABEL_REQUEST';
export const POST_CATEGORY_LABEL_WITH_OPTION_REQUEST =
  'POST_CATEGORY_LABEL_OPTION_REQUEST';
export const POST_CATEGORIES_LABEL = 'POST_CATEGORIES_LABEL';

export const PUT_CATEGORY_LABEL_REQUEST = 'PUT_CATEGORY_LABEL_REQUEST';
export const PUT_CATEGORY_LABEL = 'PUT_CATEGORY_LABEL';

export const DELETE_CATEGORY_LABEL_REQUEST = 'DELETE_CATEGORIES_LABEL_REQUEST';
export const DELETE_CATEGORY_LABEL = 'DELETE_CATEGORIES_LABEL';

export const OTHER_CATEGORY_REQUEST_SUCCESS = 'OTHER_CATEGORY_REQUEST_SUCCESS';
export const OTHER_CATEGORY_REQUEST_FAILURE = 'OTHER_CATEGORY_REQUEST_FAILURE';
export const OTHER_CATEGORY_REQUEST_IDLE = 'OTHER_CATEGORY_REQUEST_IDLE';
export const FETCH_OTHER_CATEGORY_REQUEST_FAILURE =
  'FETCH_OTHER_CATEGORY_REQUEST_FAILURE';

export const OTHER_CATEGORY_LABEL_REQUEST_SUCCESS =
  'OTHER_CATEGORY_LABEL_REQUEST_SUCCESS';
export const OTHER_CATEGORY_LABEL_REQUEST_FAILURE =
  'OTHER_CATEGORY_LABEL_REQUEST_FAILURE';
export const OTHER_CATEGORY_LABEL_REQUEST_IDLE =
  'OTHER_CATEGORY_LABEL_REQUEST_IDLE';

export const SET_TEMP_OPTIONS = 'SET_TEMP_OPTION';
