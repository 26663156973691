import { EditOutlined, LinkOutlined } from '@ant-design/icons';
import { useAppSelector, userActions } from '@lyra/core';
import { BRANCH, GUTTER, TEXT, TIMEZONE_LABEL } from '@moxie/constants';
import {
  Avatar,
  Button,
  Card,
  Col,
  DatePicker,
  Row,
  Space,
  Spin,
  Tag,
  Tooltip,
  Typography,
} from 'antd';
import React, { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';

const { Text, Paragraph } = Typography;

const UserProfileNew = ({ setVisible }: any) => {
  const [isCopied, setIsCopied] = useState(false);
  const [ellipsis] = useState(true);
  const copyToClipboard = (text: string) => {
    navigator.clipboard.writeText(text);
    setIsCopied(true);
  };

  const handleOpen = () => {
    setVisible(true);
  };

  const dispatch = useDispatch();
  const authUser: any = useAppSelector((state) => state.auth.user);
  const statUser = useAppSelector((state) => state.users.singleData);
  const loadingUser = useAppSelector((state) => state.users.loading);

  useEffect(() => {
    dispatch(userActions.fetchUser(authUser?.id));
  }, []);

  return (
    <Spin spinning={loadingUser}>
      {!loadingUser && (
        <section className="profile">
          <Card>
            <Row gutter={24}>
              <Col lg={8} className="custom_divider">
                <div className="profile__info">
                  <div className="profile__info__item profile__info__user">
                    {statUser.photo ? (
                      <Avatar size="large" src={statUser.photo} />
                    ) : (
                      <Avatar size="large">
                        {statUser.first_name?.substring(0, 1).toUpperCase() +
                          statUser.last_name?.substring(0, 1).toUpperCase()}
                      </Avatar>
                    )}
                    <div className="profile__info__user__detail initial_capital">
                      <h3>
                        {statUser.first_name} {statUser.last_name}
                      </h3>
                      <Tag className="tag-primary tag-primary--capitalize">
                        {statUser.position}
                      </Tag>
                    </div>
                    <div className="profile__info__edit">
                      <Button
                        icon={<EditOutlined />}
                        onClick={handleOpen}
                        type="link"
                      />
                    </div>
                  </div>
                  <Row>
                    {statUser.branch ? (
                      <Col>
                        <Row className="user__branch">
                          <Col className="profile__header">
                            <h4 className="profile__title">{BRANCH}:</h4>
                          </Col>
                          <Col className="user__branch_title">
                            <div className="user_branch">
                              <Tag>{statUser.branch?.name || ''}</Tag>
                            </div>
                          </Col>
                        </Row>
                      </Col>
                    ) : null}
                  </Row>
                  <div className="profile__footer">
                    {statUser.time_zone && (
                      <Space>
                        <Text className="profile__title" strong>
                          {TIMEZONE_LABEL}:
                        </Text>
                        <Text className="profile__title">
                          <small>{statUser.time_zone}</small>
                        </Text>
                      </Space>
                    )}
                  </div>
                </div>
              </Col>
              <Col lg={8} className="custom_divider">
                <Row justify="space-between" gutter={GUTTER}>
                  <Col span={24}>
                    <div className="profile__info__item">
                      {statUser.phone && (
                        <Row>
                          <Col>
                            <h3 className="profile__title">{TEXT.PHONE}:</h3>
                          </Col>
                          <Col className="padding-left-1">
                            <h3 className="profile__link">{statUser.phone}</h3>
                          </Col>
                        </Row>
                      )}
                    </div>
                    <div className="profile__info__item">
                      {statUser.email && (
                        <>
                          <Row className="email_div">
                            <Col>
                              <span className="profile__title margin-0">
                                {TEXT.EMAIL}:
                              </span>
                            </Col>

                            <Col
                              className="user-profile-email-container"
                              span={20}
                              lg={18}
                            >
                              <span className="profile__title padding-left-1">
                                <a
                                  className="email_link initial_capital"
                                  href={`mailto:${statUser.email}`}
                                >
                                  <Paragraph
                                    ellipsis={
                                      ellipsis
                                        ? { tooltip: `${statUser.email}` }
                                        : undefined
                                    }
                                    className="text-link-color initial_small"
                                  >
                                    {statUser.email}
                                  </Paragraph>
                                </a>
                              </span>
                            </Col>

                            <Col className="hide">
                              <Tooltip
                                className=" margin-left-1 "
                                placement="right"
                                color={isCopied ? '#8EAF74' : '#9B9D9A '}
                                title={
                                  isCopied
                                    ? `${TEXT.COPIED_SUCCESS}`
                                    : `${TEXT.COPY_EMAIL}`
                                }
                              >
                                <LinkOutlined
                                  className="copy_email_icon"
                                  onClick={() =>
                                    copyToClipboard(`${statUser.email}`)
                                  }
                                />
                              </Tooltip>
                            </Col>
                          </Row>
                        </>
                      )}
                    </div>
                  </Col>
                </Row>
              </Col>
              <Col lg={8}>
                <div className="profile__info__item">
                  <div className="profile__header">
                    <h4 className="profile__title align-items-center margin-0">
                      {TEXT.OVERVIEW}:
                    </h4>
                    <DatePicker picker="year" />
                  </div>
                  <div className="profile__overview">
                    <div className="profile__overview__item">
                      <h4>{TEXT.TOTAL_CLIENTS}</h4>
                      {/* TODO: Refactor it later. Get number of clients from backend.  */}
                      <span>3</span>
                    </div>
                    <div className="profile__overview__item">
                      <h4>{TEXT.TOTAL_APPLICATIONS}</h4>
                      <span>4</span>
                    </div>
                  </div>
                </div>
              </Col>
            </Row>
          </Card>
        </section>
      )}
    </Spin>
  );
};

export { UserProfileNew };
