import axios from './axios';
import { ILanguageTestScore, IResponse } from '@shared-components/models';
import { API_URL } from '@moxie/constants';

const createLanguageTestScore = async (
  body: ILanguageTestScore
): Promise<IResponse> => {
  return axios.post(API_URL.LANGUAGE_TEST_SCORE, body);
};

const updateLanguageTestScore = async (
  body: ILanguageTestScore
): Promise<IResponse> => {
  return axios.put(`${API_URL.LANGUAGE_TEST_SCORE}/${body.id}`, body);
};

const getAllLanguageTestScore = async (params = ''): Promise<IResponse> => {
  if (params?.length > 1) return axios.get(`${API_URL.LANGUAGE_TEST_SCORE}/${params}`);
  else return axios.get(`${API_URL.LANGUAGE_TEST_SCORE}`);
};

const deleteLanguageTestScore = async (id: string): Promise<IResponse> => {
  return axios.delete(`${API_URL.LANGUAGE_TEST_SCORE}/${id}`);
};

export {
  createLanguageTestScore,
  updateLanguageTestScore,
  getAllLanguageTestScore,
  deleteLanguageTestScore,
};
