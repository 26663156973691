import { API_URL } from '@moxie/constants';
import { axiosInstance as axios } from './axios';

const getSubjectDisciplines = async () => {
  return axios.get(`${API_URL.SUBJECT_DISCIPLINES}`);
};

const getEducationDegreeLevel = async () => {
  return axios.get(`${API_URL.EDUCATION_BACKGROUND_LEVELS}`);
};

export { getSubjectDisciplines, getEducationDegreeLevel };
