import { IAction, IChecklistData } from '@shared-components/models';
import * as documentTypes from './lead-document.constant';

export interface IChecklistState {
  documentChecklist: IChecklistData[];
  error: boolean | null;
  loading: boolean;
}

export const initialChecklistState: IChecklistState = {
  documentChecklist: [],
  error: null,
  loading: false,
};

export const DOCUMENT_CHECKLIST_FEATURE_KEY = 'checklist';
export const documentChecklistReducer = (
  state = initialChecklistState,
  action: IAction<any>
) => {
  switch (action.type) {
    case documentTypes.FETCH_DOCUMENT_CHECKLIST_REQUEST:
      return {
        ...state,
        loading: true,
        error: null,
      };
    case documentTypes.FETCH_DOCUMENT_CHECKLIST_SUCCESS:
      return {
        ...state,
        loading: false,
        error: null,
        documentChecklist: action.payload,
      };
    case documentTypes.FETCH_DOCUMENT_CHECKLIST_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.payload,
      };
    default:
      return state;
  }
};
