export const COUNTRIES = [
  {
    name: 'Afghanistan',
    flag: '🇦🇫',
    code: 'AF',
    dial_code: '+93',
    nationality: 'Afghan',
  },
  {
    name: 'Aland Islands',
    flag: '🇦🇽',
    code: 'AX',
    dial_code: '+358',
    nationality: 'Aland Island',
  },
  {
    name: 'Albania',
    flag: '🇦🇱',
    code: 'AL',
    dial_code: '+355',
    nationality: 'Albanian',
  },
  {
    name: 'Algeria',
    flag: '🇩🇿',
    code: 'DZ',
    dial_code: '+213',
    nationality: 'Algerian',
  },
  {
    name: 'American Samoa',
    flag: '🇦🇸',
    code: 'AS',
    dial_code: '+1684',
    nationality: 'American Samoan',
  },
  {
    name: 'Andorra',
    flag: '🇦🇩',
    code: 'AD',
    dial_code: '+376',
    nationality: 'Andorran',
  },
  {
    name: 'Angola',
    flag: '🇦🇴',
    code: 'AO',
    dial_code: '+244',
    nationality: 'Angolan',
  },
  {
    name: 'Anguilla',
    flag: '🇦🇮',
    code: 'AI',
    dial_code: '+1264',
    nationality: 'Anguillan',
  },
  {
    name: 'Antarctica',
    flag: '🇦🇶',
    code: 'AQ',
    dial_code: '+672',
    nationality: 'Antarctic',
  },
  {
    name: 'Antigua and Barbuda',
    flag: '🇦🇬',
    code: 'AG',
    dial_code: '+1268',
    nationality: 'Antiguan or Barbudan',
  },
  {
    name: 'Argentina',
    flag: '🇦🇷',
    code: 'AR',
    dial_code: '+54',
    nationality: 'Argentine',
  },
  {
    name: 'Armenia',
    flag: '🇦🇲',
    code: 'AM',
    dial_code: '+374',
    nationality: 'Armenian',
  },
  {
    name: 'Aruba',
    flag: '🇦🇼',
    code: 'AW',
    dial_code: '+297',
    nationality: 'Aruban',
  },
  {
    name: 'Australia',
    flag: '🇦🇺',
    code: 'AU',
    dial_code: '+61',
    nationality: 'Australian',
  },
  {
    name: 'Austria',
    flag: '🇦🇹',
    code: 'AT',
    dial_code: '+43',
    nationality: 'Austrian',
  },
  {
    name: 'Azerbaijan',
    flag: '🇦🇿',
    code: 'AZ',
    dial_code: '+994',
    nationality: 'Azerbaijani, Azeri',
  },
  {
    name: 'Bahamas',
    flag: '🇧🇸',
    code: 'BS',
    dial_code: '+1242',
    nationality: 'Bahamian',
  },
  {
    name: 'Bahrain',
    flag: '🇧🇭',
    code: 'BH',
    dial_code: '+973',
    nationality: 'Bahraini',
  },
  {
    name: 'Bangladesh',
    flag: '🇧🇩',
    code: 'BD',
    dial_code: '+880',
    nationality: 'Bangladeshi',
  },
  {
    name: 'Barbados',
    flag: '🇧🇧',
    code: 'BB',
    dial_code: '+1246',
    nationality: 'Barbadian',
  },
  {
    name: 'Belarus',
    flag: '🇧🇾',
    code: 'BY',
    dial_code: '+375',
    nationality: 'Belarusian',
  },
  {
    name: 'Belgium',
    flag: '🇧🇪',
    code: 'BE',
    dial_code: '+32',
    nationality: 'Belgian',
  },
  {
    name: 'Belize',
    flag: '🇧🇿',
    code: 'BZ',
    dial_code: '+501',
    nationality: 'Belizean',
  },
  {
    name: 'Benin',
    flag: '🇧🇯',
    code: 'BJ',
    dial_code: '+229',
    nationality: 'Beninese, Beninois',
  },
  {
    name: 'Bermuda',
    flag: '🇧🇲',
    code: 'BM',
    dial_code: '+1441',
    nationality: 'Bermudian, Bermudan',
  },
  {
    name: 'Bhutan',
    flag: '🇧🇹',
    code: 'BT',
    dial_code: '+975',
    nationality: 'Bhutanese',
  },
  {
    name: 'Bolivia, Plurinational State of bolivia',
    flag: '🇧🇴',
    code: 'BO',
    dial_code: '+591',
    nationality: 'Bolivian',
  },
  {
    name: 'Bosnia and Herzegovina',
    flag: '🇧🇦',
    code: 'BA',
    dial_code: '+387',
    nationality: 'Bosnian or Herzegovinian',
  },
  {
    name: 'Botswana',
    flag: '🇧🇼',
    code: 'BW',
    dial_code: '+267',
    nationality: 'Motswana, Botswanan',
  },
  {
    name: 'Bouvet Island',
    flag: '🇧🇻',
    code: 'BV',
    dial_code: '+47',
    nationality: 'Bouvet Island',
  },
  {
    name: 'Brazil',
    flag: '🇧🇷',
    code: 'BR',
    dial_code: '+55',
    nationality: 'Brazilian',
  },
  {
    name: 'British Indian Ocean Territory',
    flag: '🇮🇴',
    code: 'IO',
    dial_code: '+246',
    nationality: 'BIOT',
  },
  {
    name: 'Brunei Darussalam',
    flag: '🇧🇳',
    code: 'BN',
    dial_code: '+673',
    nationality: 'Bruneian',
  },
  {
    name: 'Bulgaria',
    flag: '🇧🇬',
    code: 'BG',
    dial_code: '+359',
    nationality: 'Bulgarian',
  },
  {
    name: 'Burkina Faso',
    flag: '🇧🇫',
    code: 'BF',
    dial_code: '+226',
    nationality: 'Burkinabé',
  },
  {
    name: 'Burundi',
    flag: '🇧🇮',
    code: 'BI',
    dial_code: '+257',
    nationality: 'Burundian',
  },
  {
    name: 'Cambodia',
    flag: '🇰🇭',
    code: 'KH',
    dial_code: '+855',
    nationality: 'Cambodian',
  },
  {
    name: 'Cameroon',
    flag: '🇨🇲',
    code: 'CM',
    dial_code: '+237',
    nationality: 'Cameroonian',
  },
  {
    name: 'Canada',
    flag: '🇨🇦',
    code: 'CA',
    dial_code: '+1',
    nationality: 'Canadian',
  },
  {
    name: 'Cape Verde',
    flag: '🇨🇻',
    code: 'CV',
    dial_code: '+238',
    nationality: 'Cabo Verdean',
  },
  {
    name: 'Cayman Islands',
    flag: '🇰🇾',
    code: 'KY',
    dial_code: '+345',
    nationality: 'Caymanian',
  },
  {
    name: 'Central African Republic',
    flag: '🇨🇫',
    code: 'CF',
    dial_code: '+236',
    nationality: 'Central African',
  },
  {
    name: 'Chad',
    flag: '🇹🇩',
    code: 'TD',
    dial_code: '+235',
    nationality: 'Chadian',
  },
  {
    name: 'Chile',
    flag: '🇨🇱',
    code: 'CL',
    dial_code: '+56',
    nationality: 'Chilean',
  },
  {
    name: 'China',
    flag: '🇨🇳',
    code: 'CN',
    dial_code: '+86',
    nationality: 'Chinese',
  },
  {
    name: 'Colombia',
    flag: '🇨🇴',
    code: 'CO',
    dial_code: '+57',
    nationality: 'Colombian',
  },
  {
    name: 'Comoros',
    flag: '🇰🇲',
    code: 'KM',
    dial_code: '+269',
    nationality: 'Comoran, Comorian',
  },
  {
    name: 'Congo',
    flag: '🇨🇬',
    code: 'CG',
    dial_code: '+242',
    nationality: 'Congolese',
  },
  {
    name: 'Congo, The Democratic Republic of the Congo',
    flag: '🇨🇩',
    code: 'CD',
    dial_code: '+243',
    nationality: 'Congolese',
  },
  {
    name: 'Cook Islands',
    flag: '🇨🇰',
    code: 'CK',
    dial_code: '+682',
    nationality: 'Cook Island',
  },
  {
    name: 'Costa Rica',
    flag: '🇨🇷',
    code: 'CR',
    dial_code: '+506',
    nationality: 'Costa Rican',
  },
  {
    name: "Cote d'Ivoire",
    flag: '🇨🇮',
    code: 'CI',
    dial_code: '+225',
    nationality: 'Ivorian',
  },
  {
    name: 'Croatia',
    flag: '🇭🇷',
    code: 'HR',
    dial_code: '+385',
    nationality: 'Croatian',
  },
  {
    name: 'Cuba',
    flag: '🇨🇺',
    code: 'CU',
    dial_code: '+53',
    nationality: 'Cuban',
  },
  {
    name: 'Cyprus',
    flag: '🇨🇾',
    code: 'CY',
    dial_code: '+357',
    nationality: 'Cypriot',
  },
  {
    name: 'Czech Republic',
    flag: '🇨🇿',
    code: 'CZ',
    dial_code: '+420',
    nationality: 'Czech',
  },
  {
    name: 'Denmark',
    flag: '🇩🇰',
    code: 'DK',
    dial_code: '+45',
    nationality: 'Danish',
  },
  {
    name: 'Djibouti',
    flag: '🇩🇯',
    code: 'DJ',
    dial_code: '+253',
    nationality: 'Djiboutian',
  },
  {
    name: 'Dominica',
    flag: '🇩🇲',
    code: 'DM',
    dial_code: '+1767',
    nationality: 'Dominican',
  },
  {
    name: 'Dominican Republic',
    flag: '🇩🇴',
    code: 'DO',
    dial_code: '+1849',
    nationality: 'Dominican',
  },
  {
    name: 'Ecuador',
    flag: '🇪🇨',
    code: 'EC',
    dial_code: '+593',
    nationality: 'Ecuadorian',
  },
  {
    name: 'Egypt',
    flag: '🇪🇬',
    code: 'EG',
    dial_code: '+20',
    nationality: 'Egyptian',
  },
  {
    name: 'El Salvador',
    flag: '🇸🇻',
    code: 'SV',
    dial_code: '+503',
    nationality: 'Salvadoran',
  },
  {
    name: 'Equatorial Guinea',
    flag: '🇬🇶',
    code: 'GQ',
    dial_code: '+240',
    nationality: 'Equatorial Guinean, Equatoguinean',
  },
  {
    name: 'Eritrea',
    flag: '🇪🇷',
    code: 'ER',
    dial_code: '+291',
    nationality: 'Eritrean',
  },
  {
    name: 'Estonia',
    flag: '🇪🇪',
    code: 'EE',
    dial_code: '+372',
    nationality: 'Estonian',
  },
  {
    name: 'Ethiopia',
    flag: '🇪🇹',
    code: 'ET',
    dial_code: '+251',
    nationality: 'Ethiopian',
  },
  {
    name: 'Falkland Islands (Malvinas)',
    flag: '🇫🇰',
    code: 'FK',
    dial_code: '+500',
    nationality: 'Falkland Island',
  },
  {
    name: 'Faroe Islands',
    flag: '🇫🇴',
    code: 'FO',
    dial_code: '+298',
    nationality: 'Faroese',
  },
  {
    name: 'Fiji',
    flag: '🇫🇯',
    code: 'FJ',
    dial_code: '+679',
    nationality: 'Fijian',
  },
  {
    name: 'Finland',
    flag: '🇫🇮',
    code: 'FI',
    dial_code: '+358',
    nationality: 'Finnish',
  },
  {
    name: 'France',
    flag: '🇫🇷',
    code: 'FR',
    dial_code: '+33',
    nationality: 'French',
  },
  {
    name: 'French Guiana',
    flag: '🇬🇫',
    code: 'GF',
    dial_code: '+594',
    nationality: 'French Guianese',
  },
  {
    name: 'French Polynesia',
    flag: '🇵🇫',
    code: 'PF',
    dial_code: '+689',
    nationality: 'French Polynesian',
  },
  {
    name: 'French Southern Territories',
    flag: '🇹🇫',
    code: 'TF',
    dial_code: '+262',
    nationality: 'French Southern Territories',
  },
  {
    name: 'Gabon',
    flag: '🇬🇦',
    code: 'GA',
    dial_code: '+241',
    nationality: 'Gabonese',
  },
  {
    name: 'Gambia',
    flag: '🇬🇲',
    code: 'GM',
    dial_code: '+220',
    nationality: 'Gambian',
  },
  {
    name: 'Georgia',
    flag: '🇬🇪',
    code: 'GE',
    dial_code: '+995',
    nationality: 'Georgian',
  },
  {
    name: 'Germany',
    flag: '🇩🇪',
    code: 'DE',
    dial_code: '+49',
    nationality: 'German',
  },
  {
    name: 'Ghana',
    flag: '🇬🇭',
    code: 'GH',
    dial_code: '+233',
    nationality: 'Ghanaian',
  },
  {
    name: 'Gibraltar',
    flag: '🇬🇮',
    code: 'GI',
    dial_code: '+350',
    nationality: 'Gibraltar',
  },
  {
    name: 'Greece',
    flag: '🇬🇷',
    code: 'GR',
    dial_code: '+30',
    nationality: 'Greek, Hellenic',
  },
  {
    name: 'Greenland',
    flag: '🇬🇱',
    code: 'GL',
    dial_code: '+299',
    nationality: 'Greenlandic',
  },
  {
    name: 'Grenada',
    flag: '🇬🇩',
    code: 'GD',
    dial_code: '+1473',
    nationality: 'Grenadian',
  },
  {
    name: 'Guadeloupe',
    flag: '🇬🇵',
    code: 'GP',
    dial_code: '+590',
    nationality: 'Guadeloupe',
  },
  {
    name: 'Guam',
    flag: '🇬🇺',
    code: 'GU',
    dial_code: '+1671',
    nationality: 'Guamanian, Guambat',
  },
  {
    name: 'Guatemala',
    flag: '🇬🇹',
    code: 'GT',
    dial_code: '+502',
    nationality: 'Guatemalan',
  },
  {
    name: 'Guernsey',
    flag: '🇬🇬',
    code: 'GG',
    dial_code: '+44',
    nationality: 'Channel Island',
  },
  {
    name: 'Guinea',
    flag: '🇬🇳',
    code: 'GN',
    dial_code: '+224',
    nationality: 'Guinean',
  },
  {
    name: 'Guinea-Bissau',
    flag: '🇬🇼',
    code: 'GW',
    dial_code: '+245',
    nationality: 'Bissau-Guinean',
  },
  {
    name: 'Guyana',
    flag: '🇬🇾',
    code: 'GY',
    dial_code: '+592',
    nationality: 'Guyanese',
  },
  {
    name: 'Haiti',
    flag: '🇭🇹',
    code: 'HT',
    dial_code: '+509',
    nationality: 'Haitian',
  },
  {
    name: 'Heard Island and Mcdonald Islands',
    flag: '🇭🇲',
    code: 'HM',
    dial_code: '+672',
    nationality: 'Heard Island or McDonald Islands',
  },
  {
    name: 'Holy See (Vatican City State)',
    flag: '🇻🇦',
    code: 'VA',
    dial_code: '+379',
    nationality: 'Vatican',
  },
  {
    name: 'Honduras',
    flag: '🇭🇳',
    code: 'HN',
    dial_code: '+504',
    nationality: 'Honduran',
  },
  {
    name: 'Hong Kong',
    flag: '🇭🇰',
    code: 'HK',
    dial_code: '+852',
    nationality: 'Hong Kong, Hong Kongese',
  },
  {
    name: 'Hungary',
    flag: '🇭🇺',
    code: 'HU',
    dial_code: '+36',
    nationality: 'Hungarian, Magyar',
  },
  {
    name: 'Iceland',
    flag: '🇮🇸',
    code: 'IS',
    dial_code: '+354',
    nationality: 'Icelandic',
  },
  {
    name: 'India',
    flag: '🇮🇳',
    code: 'IN',
    dial_code: '+91',
    nationality: 'Indian',
  },
  {
    name: 'Indonesia',
    flag: '🇮🇩',
    code: 'ID',
    dial_code: '+62',
    nationality: 'Indonesian',
  },
  {
    name: 'Iran, Islamic Republic of Persian Gulf',
    flag: '🇮🇷',
    code: 'IR',
    dial_code: '+98',
    nationality: 'Iranian, Persian',
  },
  {
    name: 'Iraq',
    flag: '🇮🇶',
    code: 'IQ',
    dial_code: '+964',
    nationality: 'Iraqi',
  },
  {
    name: 'Ireland',
    flag: '🇮🇪',
    code: 'IE',
    dial_code: '+353',
    nationality: 'Irish',
  },
  {
    name: 'Isle of Man',
    flag: '🇮🇲',
    code: 'IM',
    dial_code: '+44',
    nationality: 'Manx',
  },
  {
    name: 'Israel',
    flag: '🇮🇱',
    code: 'IL',
    dial_code: '+972',
    nationality: 'Israeli',
  },
  {
    name: 'Italy',
    flag: '🇮🇹',
    code: 'IT',
    dial_code: '+39',
    nationality: 'Italian',
  },
  {
    name: 'Jamaica',
    flag: '🇯🇲',
    code: 'JM',
    dial_code: '+1876',
    nationality: 'Jamaican',
  },
  {
    name: 'Japan',
    flag: '🇯🇵',
    code: 'JP',
    dial_code: '+81',
    nationality: 'Japanese',
  },
  {
    name: 'Jersey',
    flag: '🇯🇪',
    code: 'JE',
    dial_code: '+44',
    nationality: 'Channel Island',
  },
  {
    name: 'Jordan',
    flag: '🇯🇴',
    code: 'JO',
    dial_code: '+962',
    nationality: 'Jordanian',
  },
  {
    name: 'Kazakhstan',
    flag: '🇰🇿',
    code: 'KZ',
    dial_code: '+7',
    nationality: 'Kazakhstani, Kazakh',
  },
  {
    name: 'Kenya',
    flag: '🇰🇪',
    code: 'KE',
    dial_code: '+254',
    nationality: 'Kenyan',
  },
  {
    name: 'Kiribati',
    flag: '🇰🇮',
    code: 'KI',
    dial_code: '+686',
    nationality: 'I-Kiribati',
  },
  {
    name: "Korea, Democratic People's Republic of Korea",
    flag: '🇰🇵',
    code: 'KP',
    dial_code: '+850',
    nationality: 'North Korean',
  },
  {
    name: 'Korea, Republic of South Korea',
    flag: '🇰🇷',
    code: 'KR',
    dial_code: '+82',
    nationality: 'South Korean',
  },
  {
    name: 'Kuwait',
    flag: '🇰🇼',
    code: 'KW',
    dial_code: '+965',
    nationality: 'Kuwaiti',
  },
  {
    name: 'Kyrgyzstan',
    flag: '🇰🇬',
    code: 'KG',
    dial_code: '+996',
    nationality: 'Kyrgyzstani, Kyrgyz, Kirgiz, Kirghiz',
  },
  {
    name: 'Laos',
    flag: '🇱🇦',
    code: 'LA',
    dial_code: '+856',
    nationality: 'Lao, Laotian',
  },
  {
    name: 'Latvia',
    flag: '🇱🇻',
    code: 'LV',
    dial_code: '+371',
    nationality: 'Latvian',
  },
  {
    name: 'Lebanon',
    flag: '🇱🇧',
    code: 'LB',
    dial_code: '+961',
    nationality: 'Lebanese',
  },
  {
    name: 'Lesotho',
    flag: '🇱🇸',
    code: 'LS',
    dial_code: '+266',
    nationality: 'Basotho',
  },
  {
    name: 'Liberia',
    flag: '🇱🇷',
    code: 'LR',
    dial_code: '+231',
    nationality: 'Liberian',
  },
  {
    name: 'Libyan Arab Jamahiriya',
    flag: '🇱🇾',
    code: 'LY',
    dial_code: '+218',
    nationality: 'Libyan',
  },
  {
    name: 'Liechtenstein',
    flag: '🇱🇮',
    code: 'LI',
    dial_code: '+423',
    nationality: 'Liechtenstein',
  },
  {
    name: 'Lithuania',
    flag: '🇱🇹',
    code: 'LT',
    dial_code: '+370',
    nationality: 'Lithuanian',
  },
  {
    name: 'Luxembourg',
    flag: '🇱🇺',
    code: 'LU',
    dial_code: '+352',
    nationality: 'Luxembourg, Luxembourgish',
  },
  {
    name: 'Macao',
    flag: '🇲🇴',
    code: 'MO',
    dial_code: '+853',
    nationality: 'Macanese, Chinese',
  },
  {
    name: 'Macedonia',
    flag: '🇲🇰',
    code: 'MK',
    dial_code: '+389',
    nationality: 'Macedonian',
  },
  {
    name: 'Madagascar',
    flag: '🇲🇬',
    code: 'MG',
    dial_code: '+261',
    nationality: 'Malagasy',
  },
  {
    name: 'Malawi',
    flag: '🇲🇼',
    code: 'MW',
    dial_code: '+265',
    nationality: 'Malawian',
  },
  {
    name: 'Malaysia',
    flag: '🇲🇾',
    code: 'MY',
    dial_code: '+60',
    nationality: 'Malaysian',
  },
  {
    name: 'Maldives',
    flag: '🇲🇻',
    code: 'MV',
    dial_code: '+960',
    nationality: 'Maldivian',
  },
  {
    name: 'Mali',
    flag: '🇲🇱',
    code: 'ML',
    dial_code: '+223',
    nationality: 'Malian, Malinese',
  },
  {
    name: 'Malta',
    flag: '🇲🇹',
    code: 'MT',
    dial_code: '+356',
    nationality: 'Maltese',
  },
  {
    name: 'Marshall Islands',
    flag: '🇲🇭',
    code: 'MH',
    dial_code: '+692',
    nationality: 'Marshallese',
  },
  {
    name: 'Martinique',
    flag: '🇲🇶',
    code: 'MQ',
    dial_code: '+596',
    nationality: 'Martiniquais, Martinican',
  },
  {
    name: 'Mauritania',
    flag: '🇲🇷',
    code: 'MR',
    dial_code: '+222',
    nationality: 'Mauritanian',
  },
  {
    name: 'Mauritius',
    flag: '🇲🇺',
    code: 'MU',
    dial_code: '+230',
    nationality: 'Mauritian',
  },
  {
    name: 'Mayotte',
    flag: '🇾🇹',
    code: 'YT',
    dial_code: '+262',
    nationality: 'Mahoran',
  },
  {
    name: 'Mexico',
    flag: '🇲🇽',
    code: 'MX',
    dial_code: '+52',
    nationality: 'Mexican',
  },
  {
    name: 'Micronesia, Federated States of Micronesia',
    flag: '🇫🇲',
    code: 'FM',
    dial_code: '+691',
    nationality: 'Micronesian',
  },
  {
    name: 'Moldova',
    flag: '🇲🇩',
    code: 'MD',
    dial_code: '+373',
    nationality: 'Moldovan',
  },
  {
    name: 'Monaco',
    flag: '🇲🇨',
    code: 'MC',
    dial_code: '+377',
    nationality: 'Monégasque, Monacan',
  },
  {
    name: 'Mongolia',
    flag: '🇲🇳',
    code: 'MN',
    dial_code: '+976',
    nationality: 'Mongolian',
  },
  {
    name: 'Montenegro',
    flag: '🇲🇪',
    code: 'ME',
    dial_code: '+382',
    nationality: 'Montenegrin',
  },
  {
    name: 'Montserrat',
    flag: '🇲🇸',
    code: 'MS',
    dial_code: '+1664',
    nationality: 'Montserratian',
  },
  {
    name: 'Morocco',
    flag: '🇲🇦',
    code: 'MA',
    dial_code: '+212',
    nationality: 'Moroccan',
  },
  {
    name: 'Mozambique',
    flag: '🇲🇿',
    code: 'MZ',
    dial_code: '+258',
    nationality: 'Mozambican',
  },
  {
    name: 'Myanmar',
    flag: '🇲🇲',
    code: 'MM',
    dial_code: '+95',
    nationality: 'Burmese',
  },
  {
    name: 'Namibia',
    flag: '🇳🇦',
    code: 'NA',
    dial_code: '+264',
    nationality: 'Namibian',
  },
  {
    name: 'Nauru',
    flag: '🇳🇷',
    code: 'NR',
    dial_code: '+674',
    nationality: 'Nauruan',
  },
  {
    name: 'Nepal',
    flag: '🇳🇵',
    code: 'NP',
    dial_code: '+977',
    nationality: 'Nepali, Nepalese',
  },
  {
    name: 'Netherlands',
    flag: '🇳🇱',
    code: 'NL',
    dial_code: '+31',
    nationality: 'Dutch, Netherlandic',
  },
  {
    name: 'New Caledonia',
    flag: '🇳🇨',
    code: 'NC',
    dial_code: '+687',
    nationality: 'New Caledonian',
  },
  {
    name: 'New Zealand',
    flag: '🇳🇿',
    code: 'NZ',
    dial_code: '+64',
    nationality: 'New Zealand, NZ',
  },
  {
    name: 'Nicaragua',
    flag: '🇳🇮',
    code: 'NI',
    dial_code: '+505',
    nationality: 'Nicaraguan',
  },
  {
    name: 'Niger',
    flag: '🇳🇪',
    code: 'NE',
    dial_code: '+227',
    nationality: 'Nigerien',
  },
  {
    name: 'Nigeria',
    flag: '🇳🇬',
    code: 'NG',
    dial_code: '+234',
    nationality: 'Nigerian',
  },
  {
    name: 'Niue',
    flag: '🇳🇺',
    code: 'NU',
    dial_code: '+683',
    nationality: 'Niuean',
  },
  {
    name: 'Norfolk Island',
    flag: '🇳🇫',
    code: 'NF',
    dial_code: '+672',
    nationality: 'Norfolk Island',
  },
  {
    name: 'Northern Mariana Islands',
    flag: '🇲🇵',
    code: 'MP',
    dial_code: '+1670',
    nationality: 'Northern Marianan',
  },
  {
    name: 'Norway',
    flag: '🇳🇴',
    code: 'NO',
    dial_code: '+47',
    nationality: 'Norwegian',
  },
  {
    name: 'Oman',
    flag: '🇴🇲',
    code: 'OM',
    dial_code: '+968',
    nationality: 'Omani',
  },
  {
    name: 'Pakistan',
    flag: '🇵🇰',
    code: 'PK',
    dial_code: '+92',
    nationality: 'Pakistani',
  },
  {
    name: 'Palau',
    flag: '🇵🇼',
    code: 'PW',
    dial_code: '+680',
    nationality: 'Palauan',
  },
  {
    name: 'Palestinian Territory, Occupied',
    flag: '🇵🇸',
    code: 'PS',
    dial_code: '+970',
    nationality: 'Palestinian',
  },
  {
    name: 'Panama',
    flag: '🇵🇦',
    code: 'PA',
    dial_code: '+507',
    nationality: 'Panamanian',
  },
  {
    name: 'Papua New Guinea',
    flag: '🇵🇬',
    code: 'PG',
    dial_code: '+675',
    nationality: 'Papua New Guinean, Papuan',
  },
  {
    name: 'Paraguay',
    flag: '🇵🇾',
    code: 'PY',
    dial_code: '+595',
    nationality: 'Paraguayan',
  },
  {
    name: 'Peru',
    flag: '🇵🇪',
    code: 'PE',
    dial_code: '+51',
    nationality: 'Peruvian',
  },
  {
    name: 'Philippines',
    flag: '🇵🇭',
    code: 'PH',
    dial_code: '+63',
    nationality: 'Philippine, Filipino',
  },
  {
    name: 'Pitcairn',
    flag: '🇵🇳',
    code: 'PN',
    dial_code: '+64',
    nationality: 'Pitcairn Island',
  },
  {
    name: 'Poland',
    flag: '🇵🇱',
    code: 'PL',
    dial_code: '+48',
    nationality: 'Polish',
  },
  {
    name: 'Portugal',
    flag: '🇵🇹',
    code: 'PT',
    dial_code: '+351',
    nationality: 'Portuguese',
  },
  {
    name: 'Puerto Rico',
    flag: '🇵🇷',
    code: 'PR',
    dial_code: '+1939',
    nationality: 'Puerto Rican',
  },
  {
    name: 'Qatar',
    flag: '🇶🇦',
    code: 'QA',
    dial_code: '+974',
    nationality: 'Qatari',
  },
  {
    name: 'Romania',
    flag: '🇷🇴',
    code: 'RO',
    dial_code: '+40',
    nationality: 'Romanian',
  },
  {
    name: 'Russia',
    flag: '🇷🇺',
    code: 'RU',
    dial_code: '+7',
    nationality: 'Russian',
  },
  {
    name: 'Rwanda',
    flag: '🇷🇼',
    code: 'RW',
    dial_code: '+250',
    nationality: 'Rwandan',
  },
  {
    name: 'Reunion',
    flag: '🇷🇪',
    code: 'RE',
    dial_code: '+262',
    nationality: 'Réunionese, Réunionnais',
  },
  {
    name: 'Saint Barthelemy',
    flag: '🇧🇱',
    code: 'BL',
    dial_code: '+590',
    nationality: 'Barthélemois',
  },
  {
    name: 'Saint Helena, Ascension and Tristan Da Cunha',
    flag: '🇸🇭',
    code: 'SH',
    dial_code: '+290',
    nationality: 'Saint Helenian',
  },
  {
    name: 'Saint Kitts and Nevis',
    flag: '🇰🇳',
    code: 'KN',
    dial_code: '+1869',
    nationality: 'Kittitian or Nevisian',
  },
  {
    name: 'Saint Lucia',
    flag: '🇱🇨',
    code: 'LC',
    dial_code: '+1758',
    nationality: 'Saint Lucian',
  },
  {
    name: 'Saint Martin',
    flag: '🇲🇫',
    code: 'MF',
    dial_code: '+590',
    nationality: 'Saint-Martinoise',
  },
  {
    name: 'Saint Pierre and Miquelon',
    flag: '🇵🇲',
    code: 'PM',
    dial_code: '+508',
    nationality: 'Saint-Pierrais or Miquelonnais',
  },
  {
    name: 'Saint Vincent and the Grenadines',
    flag: '🇻🇨',
    code: 'VC',
    dial_code: '+1784',
    nationality: 'Saint Vincentian, Vincentian',
  },
  {
    name: 'Samoa',
    flag: '🇼🇸',
    code: 'WS',
    dial_code: '+685',
    nationality: 'Samoan',
  },
  {
    name: 'San Marino',
    flag: '🇸🇲',
    code: 'SM',
    dial_code: '+378',
    nationality: 'Sammarinese',
  },
  {
    name: 'Sao Tome and Principe',
    flag: '🇸🇹',
    code: 'ST',
    dial_code: '+239',
    nationality: 'São Toméan',
  },
  {
    name: 'Saudi Arabia',
    flag: '🇸🇦',
    code: 'SA',
    dial_code: '+966',
    nationality: 'Saudi, Saudi Arabian',
  },
  {
    name: 'Senegal',
    flag: '🇸🇳',
    code: 'SN',
    dial_code: '+221',
    nationality: 'Senegalese',
  },
  {
    name: 'Serbia',
    flag: '🇷🇸',
    code: 'RS',
    dial_code: '+381',
    nationality: 'Serbian',
  },
  {
    name: 'Seychelles',
    flag: '🇸🇨',
    code: 'SC',
    dial_code: '+248',
    nationality: 'Seychellois',
  },
  {
    name: 'Sierra Leone',
    flag: '🇸🇱',
    code: 'SL',
    dial_code: '+232',
    nationality: 'Sierra Leonean',
  },
  {
    name: 'Singapore',
    flag: '🇸🇬',
    code: 'SG',
    dial_code: '+65',
    nationality: 'Singaporean',
  },
  {
    name: 'Slovakia',
    flag: '🇸🇰',
    code: 'SK',
    dial_code: '+421',
    nationality: 'Slovak',
  },
  {
    name: 'Slovenia',
    flag: '🇸🇮',
    code: 'SI',
    dial_code: '+386',
    nationality: 'Slovenian, Slovene',
  },
  {
    name: 'Solomon Islands',
    flag: '🇸🇧',
    code: 'SB',
    dial_code: '+677',
    nationality: 'Solomon Island',
  },
  {
    name: 'Somalia',
    flag: '🇸🇴',
    code: 'SO',
    dial_code: '+252',
    nationality: 'Somali, Somalian',
  },
  {
    name: 'South Africa',
    flag: '🇿🇦',
    code: 'ZA',
    dial_code: '+27',
    nationality: 'South African',
  },
  {
    name: 'South Sudan',
    flag: '🇸🇸',
    code: 'SS',
    dial_code: '+211',
    nationality: 'South Sudanese',
  },
  {
    name: 'South Georgia and the South Sandwich Islands',
    flag: '🇬🇸',
    code: 'GS',
    dial_code: '+500',
    nationality: 'South Georgia or South Sandwich Islands',
  },
  {
    name: 'Spain',
    flag: '🇪🇸',
    code: 'ES',
    dial_code: '+34',
    nationality: 'Spanish',
  },
  {
    name: 'Sri Lanka',
    flag: '🇱🇰',
    code: 'LK',
    dial_code: '+94',
    nationality: 'Sri Lankan',
  },
  {
    name: 'Sudan',
    flag: '🇸🇩',
    code: 'SD',
    dial_code: '+249',
    nationality: 'Sudanese',
  },
  {
    name: 'Suriname',
    flag: '🇸🇷',
    code: 'SR',
    dial_code: '+597',
    nationality: 'Surinamese',
  },
  {
    name: 'Svalbard and Jan Mayen',
    flag: '🇸🇯',
    code: 'SJ',
    dial_code: '+47',
    nationality: 'Svalbard',
  },
  {
    name: 'Swaziland',
    flag: '🇸🇿',
    code: 'SZ',
    dial_code: '+268',
    nationality: 'Swazi',
  },
  {
    name: 'Sweden',
    flag: '🇸🇪',
    code: 'SE',
    dial_code: '+46',
    nationality: 'Swedish',
  },
  {
    name: 'Switzerland',
    flag: '🇨🇭',
    code: 'CH',
    dial_code: '+41',
    nationality: 'Swiss',
  },
  {
    name: 'Syrian Arab Republic',
    flag: '🇸🇾',
    code: 'SY',
    dial_code: '+963',
    nationality: 'Syrian',
  },
  {
    name: 'Taiwan',
    flag: '🇹🇼',
    code: 'TW',
    dial_code: '+886',
    nationality: 'Chinese, Taiwanese',
  },
  {
    name: 'Tajikistan',
    flag: '🇹🇯',
    code: 'TJ',
    dial_code: '+992',
    nationality: 'Tajikistani',
  },
  {
    name: 'Tanzania, United Republic of Tanzania',
    flag: '🇹🇿',
    code: 'TZ',
    dial_code: '+255',
    nationality: 'Tanzanian',
  },
  {
    name: 'Thailand',
    flag: '🇹🇭',
    code: 'TH',
    dial_code: '+66',
    nationality: 'Thai',
  },
  {
    name: 'Timor-Leste',
    flag: '🇹🇱',
    code: 'TL',
    dial_code: '+670',
    nationality: 'Timorese',
  },
  {
    name: 'Togo',
    flag: '🇹🇬',
    code: 'TG',
    dial_code: '+228',
    nationality: 'Togolese',
  },
  {
    name: 'Tokelau',
    flag: '🇹🇰',
    code: 'TK',
    dial_code: '+690',
    nationality: 'Tokelauan',
  },
  {
    name: 'Tonga',
    flag: '🇹🇴',
    code: 'TO',
    dial_code: '+676',
    nationality: 'Tongan',
  },
  {
    name: 'Trinidad and Tobago',
    flag: '🇹🇹',
    code: 'TT',
    dial_code: '+1868',
    nationality: 'Trinidadian or Tobagonian',
  },
  {
    name: 'Tunisia',
    flag: '🇹🇳',
    code: 'TN',
    dial_code: '+216',
    nationality: 'Tunisian',
  },
  {
    name: 'Turkey',
    flag: '🇹🇷',
    code: 'TR',
    dial_code: '+90',
    nationality: 'Turkish',
  },
  {
    name: 'Turkmenistan',
    flag: '🇹🇲',
    code: 'TM',
    dial_code: '+993',
    nationality: 'Turkmen',
  },
  {
    name: 'Turks and Caicos Islands',
    flag: '🇹🇨',
    code: 'TC',
    dial_code: '+1649',
    nationality: 'Turks and Caicos Island',
  },
  {
    name: 'Tuvalu',
    flag: '🇹🇻',
    code: 'TV',
    dial_code: '+688',
    nationality: 'Tuvaluan',
  },
  {
    name: 'Uganda',
    flag: '🇺🇬',
    code: 'UG',
    dial_code: '+256',
    nationality: 'Ugandan',
  },
  {
    name: 'Ukraine',
    flag: '🇺🇦',
    code: 'UA',
    dial_code: '+380',
    nationality: 'Ukrainian',
  },
  {
    name: 'United Arab Emirates',
    flag: '🇦🇪',
    code: 'AE',
    dial_code: '+971',
    nationality: 'Emirati, Emirian, Emiri',
  },
  {
    name: 'United Kingdom',
    flag: '🇬🇧',
    code: 'GB',
    dial_code: '+44',
    nationality: 'British, UK',
  },
  {
    name: 'United States',
    flag: '🇺🇸',
    code: 'US',
    dial_code: '+1',
    nationality: 'American',
  },
  {
    name: 'Uruguay',
    flag: '🇺🇾',
    code: 'UY',
    dial_code: '+598',
    nationality: 'Uruguayan',
  },
  {
    name: 'Uzbekistan',
    flag: '🇺🇿',
    code: 'UZ',
    dial_code: '+998',
    nationality: 'Uzbekistani, Uzbek',
  },
  {
    name: 'Vanuatu',
    flag: '🇻🇺',
    code: 'VU',
    dial_code: '+678',
    nationality: 'Ni-Vanuatu, Vanuatuan',
  },
  {
    name: 'Venezuela, Bolivarian Republic of Venezuela',
    flag: '🇻🇪',
    code: 'VE',
    dial_code: '+58',
    nationality: 'Venezuelan',
  },
  {
    name: 'Vietnam',
    flag: '🇻🇳',
    code: 'VN',
    dial_code: '+84',
    nationality: 'Vietnamese',
  },
  {
    name: 'Virgin Islands, British',
    flag: '🇻🇬',
    code: 'VG',
    dial_code: '+1284',
    nationality: 'British Virgin Island',
  },
  {
    name: 'Virgin Islands, U.S.',
    flag: '🇻🇮',
    code: 'VI',
    dial_code: '+1340',
    nationality: 'U.S. Virgin Island',
  },
  {
    name: 'Wallis and Futuna',
    flag: '🇼🇫',
    code: 'WF',
    dial_code: '+681',
    nationality: 'Wallis and Futuna, Wallisian or Futunan',
  },
  {
    name: 'Yemen',
    flag: '🇾🇪',
    code: 'YE',
    dial_code: '+967',
    nationality: 'Yemeni',
  },
  {
    name: 'Zambia',
    flag: '🇿🇲',
    code: 'ZM',
    dial_code: '+260',
    nationality: 'Zambian',
  },
  {
    name: 'Zimbabwe',
    flag: '🇿🇼',
    code: 'ZW',
    dial_code: '+263',
    nationality: 'Zimbabwean',
  },
];
