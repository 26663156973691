import {
  CONFIRM_MESSAGE,
  DELETE_BTN,
  DEPARTMENT_TITLE,
  PAGE_HEADER_MENU_DEPARTMENT_BRANCH,
} from '@moxie/constants';
import { DepartmentTable, PageHeaderTitle, popConfirm } from '@moxie/shared';
import React, { useState } from 'react';
import { getAllDepartments, getOneDepartment } from '@lyra/services.api';
import { errorHandler } from '@moxie/utils';
import { DepartmentDrawer } from './department-form-drawer';
import { IDepartment } from '@shared-components/models';
import { useForm } from 'antd/lib/form/Form';
import { useDispatch } from 'react-redux';
import { departmentActions } from '@lyra/core';
import { PageHeaderMenu } from '../components/page-header-menu';

const Department: React.FC = () => {
  const [refreshTable, setRefreshTable] = useState(false);
  const triggerRefresh = () => setRefreshTable((prev) => !prev);

  const [visible, setVisible] = useState(false);
  const [disabled, setDisabled] = useState(true);
  const [isEdit, setIsEdit] = useState(false);
  const [initialData, setInitialData] = useState<IDepartment>();
  const [filter] = useState({});

  const dispatch = useDispatch();
  const [form] = useForm();

  const handleDelete = async (id: string) => {
    popConfirm(DELETE_BTN, `${CONFIRM_MESSAGE.DELETE_DEPARTMENT}`, async () => {
      try {
        await dispatch(departmentActions.deleteDepartmentRequest(id));
        triggerRefresh();
      } catch (err) {
        errorHandler(err);
      }
    });
  };
  const getDepartmentData = async (str: string) => {
    return await getAllDepartments(str);
  };

  const onClose = () => {
    form.resetFields();
    setVisible(false);
    setIsEdit(false);
    setDisabled(true);
  };

  const handleFormSubmit = () => {
    form.submit();
  };

  const handleSubmit = async (data: IDepartment) => {
    if (data.contact_person_id === undefined) data.contact_person_id = null;
    let postData;
    if (initialData?.id) {
      postData = { ...data, id: initialData?.id };
      dispatch(
        departmentActions.updateDepartmentRequest(postData, triggerRefresh)
      );
    } else {
      postData = data;
      dispatch(
        departmentActions.addDepartmentRequest(postData, triggerRefresh)
      );
    }

    onClose();
  };

  const showDrawer = async (id?: string) => {
    if (id) {
      try {
        const response = await getOneDepartment(id ? id : '');
        setInitialData(response.data.data);
        setIsEdit(true);
      } catch (err) {
        errorHandler(err);
      }
    } else {
      setInitialData({});
    }
    setVisible(true);
  };
  return (
    <div className="full-height">
      <PageHeaderTitle title={DEPARTMENT_TITLE} />
      <PageHeaderMenu menu={PAGE_HEADER_MENU_DEPARTMENT_BRANCH} />
      <DepartmentDrawer
        visible={visible}
        onClose={onClose}
        handleFormSubmit={handleFormSubmit}
        isEdit={isEdit}
        form={form}
        handleSubmit={handleSubmit}
        initialData={initialData}
        disabled={disabled}
        setDisabled={setDisabled}
      />

      <DepartmentTable
        showDrawer={showDrawer}
        action={getDepartmentData}
        deleteData={handleDelete}
        refresh={refreshTable}
        actionBar={true}
        filterOptions={true}
        filter={filter}
      />
    </div>
  );
};

export { Department };
