import React from 'react';
import { Row, Typography, Col } from 'antd';
import { PAGE_TITLE_SIZE } from '@moxie/constants';

const { Title } = Typography;

interface IPageHeaderTitle {
  title: string;
  span?: number;
}

const PageHeaderTitle: React.FC<React.PropsWithChildren<IPageHeaderTitle>> = ({
  title,
  span,
  children
}) => {
  return (
    <Row justify='space-between'>
      <Col span={span ?? 24}>
        <Title level={PAGE_TITLE_SIZE}>
          <strong>{title}</strong>
        </Title>
      </Col>
      {children}
    </Row>
  );
};

export { PageHeaderTitle };
