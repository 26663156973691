/* eslint-disable no-unused-vars */
import React from 'react';
import { Dropdown, Menu, Space } from 'antd';
import {
  CheckCircleOutlined,
  DeleteOutlined,
  EditOutlined,
  MoreOutlined,
  CheckCircleFilled,
  StarFilled,
  StarOutlined,
  InfoCircleOutlined,
} from '@ant-design/icons';
import {
  BUTTON,
  CONFIRM_MESSAGE,
  DELETE_BTN,
  EDIT_BTN,
  MAKE_ACTIVE,
  MAKE_FAVORITE,
  MAKE_INACTIVE,
  REMOVE_FAVORITE,
  SLUG_ACTIVE,
  SLUG_INACTIVE,
} from '@moxie/constants';
import { ILeadsForm, ITableTitleCard } from '@shared-components/models';
import { Button, generateLeadsFormUrl } from '@moxie/shared';
import { popConfirm } from '@shared-components/elements';
import Can, { PermissionAction, PermissionObjects } from '@lyra/privilege';

const leadFormMenu = (
  {
    data,
    showDrawer,
    deleteLeadForm,
    handleFavourite,
    handleStatus,
  }: {
    showDrawer: () => void;
    deleteLeadForm: () => void;
    handleFavourite: () => void;
    handleStatus: () => void;
    data: ILeadsForm;
  },
  editpermissionCheck: boolean
) => {
  return (
    <Menu>
      {editpermissionCheck && (
        <>
          <Menu.Item key={1} onClick={showDrawer}>
            <Space direction="horizontal">
              <EditOutlined />
              {EDIT_BTN}
            </Space>
          </Menu.Item>
          <Menu.Item key={2} onClick={handleFavourite}>
            <Space direction="horizontal">
              {data.is_favourite ? (
                <>
                  <StarFilled />
                  {REMOVE_FAVORITE}
                </>
              ) : (
                <>
                  <StarOutlined />
                  {MAKE_FAVORITE}
                </>
              )}
            </Space>
          </Menu.Item>
          <Menu.Item key={3} onClick={handleStatus}>
            <Space direction="horizontal">
              {data.status === SLUG_ACTIVE ? (
                <>
                  <CheckCircleFilled />
                  {MAKE_INACTIVE}
                </>
              ) : (
                <>
                  <CheckCircleOutlined />
                  {MAKE_ACTIVE}
                </>
              )}
            </Space>
          </Menu.Item>
        </>
      )}

      {/* {Can(PermissionAction.Read, PermissionObjects.LeadsForm) && ( */}
      <Menu.Item key={4}>
        <Space direction="horizontal">
          <InfoCircleOutlined />
          <a
            href={generateLeadsFormUrl(data.id)}
            target="__blank"
            rel="noopener noreferrer"
          >
            {BUTTON.PREVIEW}
          </a>
        </Space>
      </Menu.Item>
      {/* )} */}
      {/* {Can(PermissionAction.Delete, PermissionObjects.LeadsForm) && ( */}
      <Menu.Item key={5} danger onClick={deleteLeadForm}>
        <Space direction="horizontal">
          <DeleteOutlined />
          {DELETE_BTN}
        </Space>
      </Menu.Item>
      {/* )} */}
    </Menu>
  );
};

const TableFormNameCard: React.FC<ITableTitleCard | any> = ({
  data,
  showDrawer,
  deleteData,
  editData,
  editpermissionCheck = true,
}: ITableTitleCard | any) => {
  const handleDrawer = () => {
    showDrawer(data.key);
  };

  const handleDelete = () => {
    popConfirm(
      DELETE_BTN,
      `${CONFIRM_MESSAGE.DELETE_LEAD_FORM} ${data.form_name}?`,
      () => deleteData(data.key)
    );
  };

  const handleFavourite = () => {
    editData({ id: data.key, is_favourite: !data.is_favourite });
  };

  const handleStatus = () => {
    if (data.status === SLUG_ACTIVE) {
      editData({ id: data.key, status: SLUG_INACTIVE });
    } else {
      editData({ id: data.key, status: SLUG_ACTIVE });
    }
  };

  const menuActions = {
    data: data,
    showDrawer: handleDrawer,
    deleteLeadForm: handleDelete,
    handleStatus: handleStatus,
    handleFavourite: handleFavourite,
  };

  return (
    <>
      <Dropdown overlay={leadFormMenu(menuActions, editpermissionCheck)}>
        <Button
          type="text"
          className="table-cell-action-btn"
          icon={<MoreOutlined />}
        />
      </Dropdown>
    </>
  );
};

export { TableFormNameCard };
