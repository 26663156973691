import {
  IAction,
  IPayload,
  IUser,
  IUserPersonalInformation,
} from '@shared-components/models';
import * as actionTypes from './admin-user.constant';

export const fetchAdminUsers = (): IAction => ({
  type: actionTypes.GET_USERS_REQUEST,
});

export const fetchAdminUsersSuccess = (payload: IUser[]): IAction<IUser[]> => ({
  type: actionTypes.GET_USERS_SUCCESS,
  payload,
});

export const fetchAdminUsersFailure = (
  payload: IPayload<IUser>
): IAction<any> => ({
  type: actionTypes.GET_USERS_FAILURE,
  payload,
});

export const fetchAdminUser = (payload: string): IAction<string> => ({
  type: actionTypes.GET_USERS_REQUEST,
  payload,
});

export const fetchAdminUserSuccess = (payload: IUser): IAction<IUser> => ({
  type: actionTypes.GET_USER_SUCCESS,
  payload,
});

export const fetchAdminUserFailure = (payload: IPayload<IUser>): IAction => ({
  type: actionTypes.GET_USER_FAILURE,
  payload,
});

export const deleteAdminUserRequest = (id: string): IAction<string> => ({
  type: actionTypes.DELETE_USER_REQUEST,
  payload: id,
});

export const deleteAdminUserSuccess = (payload: any): IAction => ({
  type: actionTypes.DELETE_USER_SUCCESS,
  payload,
});

export const deleteAdminUserFailure = (payload: IPayload<IUser>): IAction => ({
  type: actionTypes.DELETE_USER_FAILURE,
  payload,
});

export const clearFetchAdminUser = (): IAction => ({
  type: actionTypes.CLEAR_USER_FETCH,
});

export const addAdminUserRequest = (payload: IUser): IAction<IUser> => ({
  type: actionTypes.ADD_USER_REQUEST,
  payload,
});

export const addAdminUserSuccess = (payload: IUser): IAction<IUser> => ({
  type: actionTypes.ADD_USER_SUCCESS,
  payload,
});

export const addAdminUserFailure = (payload: IPayload<IUser>): IAction => ({
  type: actionTypes.ADD_USER_FAILURE,
  payload,
});

export const updateAdminUserRequest = (
  payload: IUserPersonalInformation
): IAction<IUser> => ({
  type: actionTypes.UPDATE_USER_REQUEST,
  payload,
});

export const updateAdminUserSuccess = (payload: IUser): IAction<IUser> => ({
  type: actionTypes.UPDATE_USER_SUCCESS,
  payload,
});

export const updateAdminUserFailure = (payload: IPayload<IUser>): IAction => ({
  type: actionTypes.UPDATE_USER_FAILURE,
  payload,
});
