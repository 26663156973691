import React, { useState } from 'react';
import { useForm } from 'antd/lib/form/Form';
import { useDispatch } from 'react-redux';
import {
  PageHeaderTitle,
  popConfirm,
} from '@shared-components/elements';
import {
  CONFIRM_MESSAGE,
  DELETE_BTN,
  PAGE_HEADER_MENU_WORKFLOW_TYPES,
  TEXT,
} from '@moxie/constants';
import { getAllWorkflowTypes } from '@admin/services.api';
import { WorkflowTypeTable } from './workflow-type-table';
import { WorkflowTypeDrawer } from './workflow-type-form-drawer';
import { workflowTypeActions } from '@admin/core';
import { IWorkflowType } from '@shared-components/models';
import { PageHeaderMenu } from '../components/page-header-menu';

const WorkflowTypeListComponent = () => {
  const [refreshTable, setRefreshTable] = useState(true);
  const [visible, setVisible] = useState(false);
  const [submitted, setSubmitted] = useState(false);
  const [isEdit, setIsEdit] = useState(false);
  const [filter] = useState({});
  const [form] = useForm();
  const [disabled, setDisabled] = useState(true);
  const dispatch = useDispatch();
  const [workflowId, setWorkflowId] = useState('');

  const triggerRefresh = () => setRefreshTable((prev) => !prev);

  const getData = async (params: string) => {
    return getAllWorkflowTypes(params);
  };

  const onClose = () => {
    form.resetFields();
    setVisible(false);
    setIsEdit(false);
    dispatch(workflowTypeActions.clearWorkflowTypeFetch());
    setDisabled(true);
    setSubmitted(false);
    setWorkflowId('');
  };

  const handleFormSubmit = async () => {
    form.submit();
  };

  const handleSubmit = (data: IWorkflowType) => {
    setSubmitted(true);
    if (workflowId) {
      dispatch(
        workflowTypeActions.updateWorkflowTypeRequest(
          { ...data, id: workflowId },
          triggerRefresh
        )
      );
    } else {
      dispatch(
        workflowTypeActions.addWorkflowTypeRequest(data, triggerRefresh)
      );
    }
    onClose();
  };

  const showDrawer = async (id?: string) => {
    if (id) {
      setWorkflowId(id);
      await dispatch(workflowTypeActions.getWorkflowTypeRequest(id));
      setIsEdit(true);
    }
    setVisible(true);
  };

  const deleteData = (id: string) => {
    popConfirm(DELETE_BTN, CONFIRM_MESSAGE.DELETE_WORKFLOW_TYPE, () => {
      dispatch(
        workflowTypeActions.deleteWorkflowTypeRequest(id, triggerRefresh)
      );
    });
  };

  return (
    <div className="full-height">
      <PageHeaderTitle title={TEXT.WORKFLOW_TYPES} />
      <PageHeaderMenu menu={PAGE_HEADER_MENU_WORKFLOW_TYPES} />
      {visible && (
        <WorkflowTypeDrawer
          visible={visible}
          onClose={onClose}
          isEdit={isEdit}
          form={form}
          handleFormSubmit={handleFormSubmit}
          handleSubmit={handleSubmit}
          disabled={disabled}
          setDisabled={setDisabled}
          submitted={submitted}
        />
      )}

      <WorkflowTypeTable
        getData={getData}
        tableName={TEXT.WORKFLOW_TYPES}
        refreshTable={refreshTable}
        deleteData={deleteData}
        showDrawer={showDrawer}
        filter={filter}
      />
    </div>
  );
};

export { WorkflowTypeListComponent };
