import { SuggestionProps } from '@tiptap/suggestion'
import React, {
  forwardRef, useEffect, useImperativeHandle,
  useState,
} from 'react'
import { Assignee } from '@model/application'

const MentionList = forwardRef<unknown, SuggestionProps<Assignee>>((props, ref) => {
  const [selectedIndex, setSelectedIndex] = useState(0)


  const selectItem = (index: number) => {
    const item = props.items[index]

    if (item) {
      props.command({ id: JSON.stringify(item), label: `${item.firstName} ${item.lastName}` })
    }
  }

  const upHandler = () => {
    setSelectedIndex((selectedIndex + props.items.length - 1) % props.items.length)
  }

  const downHandler = () => {
    setSelectedIndex((selectedIndex + 1) % props.items.length)
  }

  const enterHandler = () => {
    selectItem(selectedIndex)
  }

  useEffect(() => setSelectedIndex(0), [props.items])

  useImperativeHandle(ref, () => ({
    // @ts-ignore
    onKeyDown: ({ event }) => {
      if (event.key === 'ArrowUp') {
        upHandler()
        return true
      }

      if (event.key === 'ArrowDown') {
        downHandler()
        return true
      }

      if (event.key === 'Enter') {
        enterHandler()
        return true
      }

      if (event.key)

        return false
    },
  }))

  return (
    <div className="dropdown-menu">
      {props.items.length
        ? props.items.map((item, index) => (
          <span
            className={index === selectedIndex ? 'is-selected' : ''}
            key={index}
            onClick={() => selectItem(index)}
          >
            {item.firstName} {item.lastName}
          </span>
        ))
        : <div className="item">No result</div>
      }
    </div>
  )
})

export default MentionList;
