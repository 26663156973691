import {
  InstitutionListComponent,
  InstitutionTableNameCard,
} from './libs/institution-list';
import { InstitutionProfile } from './libs/institution-profile';
import { InstitutionBranchTable } from './libs/institution-branch-table';
import { InstitutionProductTable } from './libs/institution-products-list';

export {
  InstitutionListComponent,
  InstitutionProfile,
  InstitutionTableNameCard,
  InstitutionBranchTable,
  InstitutionProductTable,
};
