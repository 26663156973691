import { IAction } from '@shared-components/models';
import { socketSagaActions } from '../sagas/sagaAction';
export const SOCKET_FEATURE_KEY = 'socket';

export interface SocketState {
  wss: null;
}

export const initialSocketState: SocketState = {
  wss: null,
};
export const socketReducer = (
  state = initialSocketState,
  action: IAction<any>
) => {
  switch (action.type) {
    case socketSagaActions.SET_SOCKET_CONNECTION:
      return {
        wss: action.payload,
      };
    case socketSagaActions.REMOVE_SOCKET_CONNECTION:
      return {
        wss: null,
      };
    default:
      return state;
  }
};
