const alias = 'LEAD_ACTIVITIE';

export const GET_LEAD_ACTIVITIES_REQUEST = `GET_${alias}S_REQUEST`;
export const GET_LEAD_ACTIVITIES_SUCCESS = `GET_${alias}S_SUCCESS`;
export const GET_LEAD_ACTIVITIES_FAILURE = `GET_${alias}_FAILURE`;
export const GET_LEAD_ACTIVITIES_CLEAR = `GET_${alias}S_CLEAR`;

export const GET_LEAD_ACTIVITIE_REQUEST = `GET_${alias}_REQUEST`;
export const GET_LEAD_ACTIVITIE_SUCCESS = `GET_${alias}_SUCCESS`;
export const GET_LEAD_ACTIVITIE_FAILURE = `GET_${alias}_FAILURE`;

export const ADD_LEAD_ACTIVITIE_REQUEST = `ADD_${alias}_REQUEST`;
export const ADD_LEAD_ACTIVITIE_SUCCESS = `ADD_${alias}_SUCCESS`;
export const ADD_LEAD_ACTIVITIE_FAILURE = `ADD_${alias}_FAILURE`;

export const UPDATE_LEAD_ACTIVITIE_REQUEST = `UPDATE_${alias}_REQUEST`;
export const UPDATE_LEAD_ACTIVITIE_SUCCESS = `UPDATE_${alias}_SUCCESS`;
export const UPDATE_LEAD_ACTIVITIE_FAILURE = `UPDATE_${alias}_FAILURE`;

export const DELETE_LEAD_ACTIVITIE_REQUEST = `DELETE_${alias}_REQUEST`;
export const DELETE_LEAD_ACTIVITIE_SUCCESS = `DELETE_${alias}_SUCCESS`;
export const DELETE_LEAD_ACTIVITIE_FAILURE = `DELETE_${alias}_FAILURE`;
