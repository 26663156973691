import React from 'react';
import { Col, Empty, List, Row, Typography } from 'antd';
import { CHECKLIST_DETAIL, LABEL, PAGE_TITLE_SIZE } from '@moxie/constants';
import { IApplicationStageChecklist } from '@shared-components/models';
import { DocumentChecklistList } from './document-checklist-list';
import { ChecklistDocumentTable } from './checklist-document-table';

const SelectedStagePage = ({
  showDetail,
  showDownloadAllBtn,
  stageChecklists,
  handleDetailButton,
  handleAddDocument,
  selectedChecklist,
  selectedActor,
  handleDocumentsFromCheckListDownload,
  refreshTable,
  triggerRefresh,
  getDocuments,
  selectedChecklistData,
  currentStageId
}: any) => {
  return (
    <Row
      justify="space-between"
      className="padding-left-3 padding-top-2 profile-body"
    >
      <Col span={showDetail ? '8' : '24'}>
        <div className="margin-bottom-1">
          <Row>
            <Col className="margin-right-2">
              <Typography.Title level={PAGE_TITLE_SIZE}>
                <strong>{LABEL.DOCUMENT_CHECKLIST}</strong>
              </Typography.Title>
            </Col>
            <Col>{showDownloadAllBtn()}</Col>
          </Row>
          <span className="ant-upload-text">{`${CHECKLIST_DETAIL}`}</span>
        </div>
        {stageChecklists && stageChecklists.length > 0 ? (
          <List
            split={false}
            dataSource={selectedChecklistData}
            renderItem={(item: IApplicationStageChecklist) => {
              return (
                <DocumentChecklistList
                  item={item}
                  showDetail={showDetail}
                  onShowButton={handleDetailButton}
                  handleAdd={handleAddDocument}
                  selectedChecklist={selectedChecklist}
                  selectedActor={selectedActor}
                  handleDocumentsFromCheckListDownload={
                    handleDocumentsFromCheckListDownload
                  }
                  currentStageId={currentStageId}
                />
              );
            }}
          />
        ) : (
          <Empty
            image={Empty.PRESENTED_IMAGE_SIMPLE}
            className="padding-top-3"
            description={<span>No documents are required at this stage</span>}
          />
        )}
      </Col>
      {showDetail ? (
        <Col span="16">
          <ChecklistDocumentTable
            refreshTable={refreshTable}
            triggerRefresh={triggerRefresh}
            handleClick={handleAddDocument}
            documentData={getDocuments}
            selectedActor={selectedActor}
          />
        </Col>
      ) : null}
    </Row>
  );
};

export { SelectedStagePage };
