import { useAppSelector } from '@crm/core';
import { IContactCrm } from '@model/index';
import { DataAsKeyInResponse } from '@model/module.props';
import { useState } from 'react';

export const useAssigneeChanged = () => {
  const socket = useAppSelector(state => state.socket.wss);
  const user = useAppSelector(state => state.auth.user);

  const [refresh, setRefresh] = useState(false);

  const triggerRefresh = (previous_assigned_user_id?: string, response?: DataAsKeyInResponse<IContactCrm>) => {
    setRefresh((prev) => !prev);
    if (response && previous_assigned_user_id !== response.data.assigneeId) {
      socket?.emit('server::profile-updated', {
        activitiesType: "contact",
        activitiesAction: 'assigned',
        userId: user?.id as string,
        previousAssignedUserId: previous_assigned_user_id !== '' ? previous_assigned_user_id : null,
        assignedUserId: response.data.assigneeId !== '' ? response.data.assigneeId : null,
        contactId: response.data.id,
        companyId: user?.companyId as string,
        data: response,
      })

    }
  };

  return {
    refresh,
    triggerRefresh
  }
}
