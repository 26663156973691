import React from 'react';
import { ITableTitleCard, ITaskTitleMenu } from '@shared-components/models';
import { Col, Dropdown, Menu, Row, Space, Tooltip, Typography } from 'antd';
import { DELETE_BTN, EDIT_BTN, VIEW_BTN } from '@moxie/constants';
import { Button } from '@moxie/shared';
import {
  DeleteOutlined,
  EditOutlined,
  EyeOutlined,
  MoreOutlined,
} from '@ant-design/icons';

const taskTitleMenu = ({ deleteData, showDrawer }: ITaskTitleMenu) => {
  return (
    <Menu>
      <Menu.Item key={1}>
        <Space direction="horizontal" onClick={() => showDrawer(true)}>
          <EyeOutlined />
          {VIEW_BTN}
        </Space>
      </Menu.Item>
      <Menu.Item key={2} onClick={() => showDrawer()}>
        <Space direction="horizontal">
          <EditOutlined />
          {EDIT_BTN}
        </Space>
      </Menu.Item>

      <Menu.Item key={3} danger onClick={deleteData}>
        <Space direction="horizontal">
          <DeleteOutlined />
          {DELETE_BTN}
        </Space>
      </Menu.Item>
    </Menu>
  );
};

export const TaskNameCard: React.FC<ITableTitleCard> = ({
  data,
  deleteData,
  showDrawer,
  actionBar,
}: ITableTitleCard) => {
  const handleDelete = () => {
    deleteData(data.id);
  };

  const handleDrawer = (isView?: boolean) => {
    showDrawer(data.id, isView);
  };
  const menuActions = {
    data: data,
    deleteData: handleDelete,
    showDrawer: handleDrawer,
  };
  return (
    <Row>
      <Col span={18} className="align-items-center">
        <Tooltip title={data.title}>
          <Typography.Text
            className="text-primary-color cursor-pointer initial_capital"
            ellipsis={true}
            onClick={() => handleDrawer(true)}
          >
            {data.title}
          </Typography.Text>
        </Tooltip>
      </Col>
      {actionBar && (
        <Col span={3}>
          <Dropdown overlay={taskTitleMenu(menuActions)}>
            <Button
              type="text"
              className="table-cell-action-btn"
              icon={<MoreOutlined />}
            />
          </Dropdown>
        </Col>
      )}
    </Row>
  );
};
