import { markAllNotificationAsRead } from "@crm/services.api";
import { errorNotificationHandler } from "@moxie/shared";
import { useMutation, useQueryClient } from "@tanstack/react-query";

export const useUpdateAllNotificationAsReadMutation = () => {
    const queryClient = useQueryClient();

    return useMutation({
        mutationFn:() => markAllNotificationAsRead(),
        onSuccess() {
            queryClient.invalidateQueries({
                queryKey:['notification-count']
            });
            queryClient.invalidateQueries({
                queryKey:['notifications']
            });
        },
        onError() {
            errorNotificationHandler('Failed to update notification status');
        }
    })
}
