import React, { useEffect } from 'react';
import moment from 'moment';
import { Card, Col, DatePicker, Form, Row } from 'antd';
import { useForm } from 'antd/lib/form/Form';
import { formatDate, Input, SearchableSelect } from '@moxie/shared';
import {
  CATEGORY_ITEMS,
  GUTTER,
  LABEL,
  NOT_AVAILABLE_MESSAGE,
} from '@moxie/constants';

const VisaDetailsTab: React.FC<any> = (props) => {
  const { lead } = props;
  const [form] = useForm();

  useEffect(() => {
    form.setFieldsValue({
      passport_no: lead?.passport_no,
      visa_type: lead?.visa_type,
      visa_expiry_date: lead?.visa_expiry_date
        ? moment(lead?.visa_expiry_date)
        : null,
      country_of_passport: lead?.country_of_passport,
      passport_expiry_date: lead?.passport_expiry_date
        ? moment(lead?.passport_expiry_date)
        : null,
    });
  }, [lead]);

  return (
    <Card title={CATEGORY_ITEMS[2].name}>
      <Form
        layout="horizontal"
        form={form}
        labelCol={{ xl: 12, lg: 8, md: 10, span: 10 }}
        wrapperCol={{ span: 14, offset: 1 }}
      >
        <Row gutter={GUTTER}>
          <Col span={24} xl={12} xxl={8}>
            <Form.Item
              className="custom_label_style"
              name="passport_no"
              label={LABEL.PASSPORT_NUMBER}
              rules={[{ required: true }]}
            >
              {lead?.passport_no
                ? lead?.passport_no.toUpperCase()
                : NOT_AVAILABLE_MESSAGE}
            </Form.Item>
          </Col>
          <Col span={24} xl={12} xxl={10}>
            <div id="country_list" className="relative">
              <Form.Item
                className="custom_label_style"
                name="country_of_passport"
                label={LABEL.COUNTRY_OF_PASSPORT}
                rules={[{ required: true }]}
              >
                {lead?.country_of_passport
                  ? lead?.country_of_passport
                  : NOT_AVAILABLE_MESSAGE}
              </Form.Item>
            </div>
          </Col>
          <Col span={24} xl={12} xxl={6}>
            <Form.Item
              className="custom_label_style initial_capital"
              name="visa_type"
              label={LABEL.VISA_TYPE}
            >
              {lead?.visa_type ? lead?.visa_type : NOT_AVAILABLE_MESSAGE}
            </Form.Item>
          </Col>
          <Col span={24} xl={12} xxl={8}>
            <Form.Item
              className="custom_label_style"
              name="passport_expiry_date"
              label={LABEL.PASSPORT_EXPIRY_DATE}
              rules={[{ required: true }]}
            >
              {lead?.passport_expiry_date
                ? formatDate(lead?.passport_expiry_date)
                : NOT_AVAILABLE_MESSAGE}
            </Form.Item>
          </Col>
          <Col span={24} xl={12} xxl={10}>
            <Form.Item
              className="custom_label_style"
              name="visa_expiry_date"
              label={LABEL.VISA_EXPIRY_DATE}
              rules={[{ required: true }]}
            >
              {lead?.visa_expiry_date
                ? formatDate(lead?.visa_expiry_date)
                : NOT_AVAILABLE_MESSAGE}
            </Form.Item>
          </Col>
        </Row>
      </Form>
    </Card>
  );
};

export { VisaDetailsTab };
