import { createSlice } from '@reduxjs/toolkit';
export const SOCKET_FEATURE_KEY = 'socket';

export interface SocketState {
  wss: any;
}

export const initialSocketState: SocketState = {
  wss: null,
};

export const socketSlice = createSlice({
  name: SOCKET_FEATURE_KEY,
  initialState: initialSocketState,
  reducers: {
    setSocket: (state, action) => {
      state.wss = action.payload;
    },
    removeSocket: (state) => {
      state.wss = null;
    },
  },
});

export const socketReducer = socketSlice.reducer;

export const socketActions = socketSlice.actions;
