/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from 'react';
import { Button, Col, Form, Input, Row, Space } from 'antd';
import { useForm } from 'antd/lib/form/Form';
import { useAppSelector } from '@admin/core';
import {
  DrawerElem,
  PhoneInput,
  UserSelectBox,
} from '@shared-components/elements';
import { IAddEditProps } from '@shared-components/models';
import {
  CLOSE_BTN,
  VIEW_CLIENT_TITLE,
  GUTTER,
  ASSIGNEE_LABEL,
  ASSIGNEE_PLACEHOLDER,
  EMAIL_LABEL,
  EMAIL_PLACEHOLDER,
  FIRST_NAME_LABEL,
  FIRST_NAME_PLACEHOLDER,
  LAST_NAME_LABEL,
  LAST_NAME_PLACEHOLDER,
  PHONE_LABEL,
  PHONE_PLACEHOLDER,
  DEFAULT_COUNTRY_CODE,
  LEAD_INITIAL_DATA,
  DRAWER_WIDTH,
} from '@moxie/constants';
import { Moment } from 'moment';
import moment from 'moment';

const ContactAddEdit: React.FC<IAddEditProps<any>> = ({
  isOpen,
  onClose,
  initialData,
  isView,
}: IAddEditProps<any>) => {
  const [form] = useForm();

  const users = useAppSelector((store) => store.users.followerUsers);
  const [country_code, setCountryCode] = useState<string | any>(
    DEFAULT_COUNTRY_CODE
  );

  const getAssigneeName = (): string => {
    return `${initialData.assignee.first_name} ${initialData.assignee.last_name}`;
  };

  useEffect(() => {
    if (initialData) {
      initialData.date_of_birth = changeDateToMoment(
        initialData?.date_of_birth
      );
      initialData.date_of_birth = changeDateToMoment(initialData.date_of_birth);
      initialData.visa_expiry_date = changeDateToMoment(
        initialData.visa_expiry_date
      );
      initialData.passport_expiry_date = changeDateToMoment(
        initialData.passport_expiry_date
      );
      setCountryCode(initialData?.country_code);
      form.setFieldsValue(initialData);
    } else form.setFieldsValue(LEAD_INITIAL_DATA);
  }, [form, initialData, isView]);
  const changeDateToMoment = (date?: string | Moment): Moment | undefined => {
    if (date) return moment(date);
    return;
  };
  return (
    <DrawerElem
      title={VIEW_CLIENT_TITLE}
      width={DRAWER_WIDTH}
      onClose={onClose}
      visible={isOpen}
      footer={
        <div className="padding-bottom-2 text-align-right">
          <Space direction="horizontal">
            <Button onClick={onClose} className="lead-margin-right">
              {CLOSE_BTN}
            </Button>
          </Space>
        </div>
      }
    >
      <Form layout="vertical" form={form}>
        <Row gutter={GUTTER}>
          <Col span={12}>
            <Form.Item name="first_name" label={FIRST_NAME_LABEL}>
              <Input
                className="note-title-input"
                placeholder={FIRST_NAME_PLACEHOLDER}
                disabled={isView}
              />
            </Form.Item>
          </Col>
          <Col span={12}>
            <Form.Item name="last_name" label={LAST_NAME_LABEL}>
              <Input
                className="note-title-input"
                placeholder={LAST_NAME_PLACEHOLDER}
                disabled={isView}
              />
            </Form.Item>
          </Col>
        </Row>
        <Row gutter={GUTTER}>
          <Col span={12}>
            <Form.Item name="email" label={EMAIL_LABEL}>
              <Input
                className="note-title-input"
                placeholder={EMAIL_PLACEHOLDER}
                disabled={isView}
              />
            </Form.Item>
          </Col>
          <Col span={12}>
            <Form.Item
              name="phone"
              label={PHONE_LABEL}
              className="country_dial_code"
            >
              <PhoneInput
                className="note-title-input"
                type="string"
                placeholder={PHONE_PLACEHOLDER}
                disabled={isView}
                dialCode={country_code}
              />
            </Form.Item>
          </Col>
        </Row>
        <Row gutter={GUTTER}>
          <Col span={24}>
            <div className="relative">
              {isView ? (
                <Form.Item label={ASSIGNEE_LABEL}>
                  <Input
                    className="note-title-input"
                    value={getAssigneeName()}
                    disabled={true}
                  />
                </Form.Item>
              ) : (
                <Form.Item name="assignee_user_id" label={ASSIGNEE_LABEL}>
                  <UserSelectBox
                    className="note-title-input"
                    placeholder={!isView ? ASSIGNEE_PLACEHOLDER : '-'}
                    disabled={isView}
                    users={users}
                  />
                </Form.Item>
              )}
            </div>
          </Col>
        </Row>
      </Form>
    </DrawerElem>
  );
};

export { ContactAddEdit };
