import { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { subjectDisciplineActions } from '@lyra/core';

const useSubjectDiscipline = () => {
  const dispatch = useDispatch();
  useEffect(() => {
    dispatch(subjectDisciplineActions.getSubjectDisciplinesRequest());
  }, [dispatch]);
};

export { useSubjectDiscipline };
