import { put, takeLatest } from '@redux-saga/core/effects';
import * as service from './services.constant';
import * as serviceActions from './services.action';
import { AxiosResponse } from 'axios';
import { errorHandler } from '@moxie/utils';
import {
  getEducationLevels,
  getInstitutionByServiceCountry,
  getServices,
  getWorkflowByServiceCountry,
} from '@lyra/services.api';
import { IAction } from '@shared-components/models';
import { fetchServiceCountry } from '../../libs/services.api/lib/service.api';

export function* fetchService() {
  yield put(serviceActions.setLoading());
  try {
    const res: AxiosResponse<any> = yield getServices();
    yield put(serviceActions.fetchService(res.data.data));
    yield put(serviceActions.setIdle());
  } catch (e) {
    errorHandler(e);
    yield put(serviceActions.setIdle());
  }
}

export function* fetchServiceWorkflow({ payload }: IAction<any>) {
  yield put(serviceActions.setLoading());
  try {
    const res: AxiosResponse<any> = yield getWorkflowByServiceCountry(
      payload?.serviceId || '',
      payload?.country
    );
    yield put(serviceActions.fetchServiceWorkflow(res.data.data));
    yield put(serviceActions.setIdle());
  } catch (e) {
    errorHandler(e);
    yield put(serviceActions.setIdle());
  }
}

export function* fetchServiceCountries({ payload }: IAction<string>) {
  yield put(serviceActions.setLoading());
  try {
    const res: AxiosResponse<any> = yield fetchServiceCountry(payload || '');
    yield put(serviceActions.fetchServiceCountries(res.data.data));
    yield put(serviceActions.setIdle());
  } catch (e) {
    errorHandler(e);
    yield put(serviceActions.setIdle());
  }
}

export function* fetchServiceInstitution({ payload }: IAction<any>) {
  yield put(serviceActions.setLoading());
  try {
    const res: AxiosResponse<any> = yield getInstitutionByServiceCountry(
      payload.serviceId,
      payload.country
    );
    yield put(serviceActions.fetchServiceInstitution(res.data.data));
    yield put(serviceActions.setIdle());
  } catch (e) {
    errorHandler(e);
    yield put(serviceActions.setIdle());
  }
}

export function* fetchEducationLevels() {
  yield put(serviceActions.setLoading());
  try {
    const res: AxiosResponse<any> = yield getEducationLevels();
    yield put(serviceActions.fetchEducationLevel(res.data.data));
    yield put(serviceActions.setIdle());
  } catch (e) {
    errorHandler(e);
    yield put(serviceActions.setIdle());
  }
}
export default function* rootSaga(): unknown {
  yield takeLatest(service.FETCH_SERVICE_REQUEST, fetchService);
  yield takeLatest(
    service.FETCH_SERVICE_WORKFLOW_REQUEST,
    fetchServiceWorkflow
  );
  yield takeLatest(
    service.FETCH_SERVICE_COUNTRIES_REQUEST,
    fetchServiceCountries
  );
  yield takeLatest(
    service.FETCH_SERVICE_INSTITUTION_REQUEST,
    fetchServiceInstitution
  );
  yield takeLatest(service.FETCH_EDUCATION_LEVEL_REQUEST, fetchEducationLevels);
}
