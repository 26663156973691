import React, { useEffect, useState } from 'react';
import { Col, Collapse, Divider, Form, FormItemProps, Row } from 'antd';
import {
  FormItem,
  Input,
  Option,
  PhoneInput,
  SearchableSelect,
} from 'libs/shared/src/shared';
import {
  ADDRESS1_LABEL,
  ADDRESS1_PLACEHOLDER,
  ADDRESS2_LABEL,
  ADDRESS2_PLACEHOLDER,
  ADDRESS_INFORMATION,
  ALTERNATIVE_PHONE_LABEL,
  ALTERNATIVE_PHONE_PLACEHOLDER,
  AM_ROLES,
  AUTH_MESSAGE,
  CHANGE_TIMEZONE,
  CITY_OR_STATE_LABEL,
  CITY_OR_STATE_PLACEHOLDER,
  CONTACT_INFORMATION,
  COUNTRIES,
  COUNTRY_LABEL,
  COUNTRY_PLACEHOLDER,
  EMAIL_LABEL,
  EMAIL_PLACEHOLDER,
  FIRST_NAME_LABEL,
  FIRST_NAME_PLACEHOLDER,
  FORM_SIZE,
  GUTTER,
  JOB_INFORMATION,
  LABEL,
  LAST_NAME_LABEL,
  LAST_NAME_PLACEHOLDER,
  PHONE_LABEL,
  PHONE_NUMBER_INVALID,
  PHONE_PLACEHOLDER,
  PLACEHOLDER,
  regex,
  ROLE_LABEL,
  ROLE_PLACEHOLDER,
  TEXT,
  TIMEZONE,
  USER_PERSONAL_DETAIL,
} from '@moxie/constants';
import { errorHandler } from '@moxie/utils';
import { CaretRightOutlined } from '@ant-design/icons';
import { useForm } from 'antd/lib/form/Form';
import { useDispatch } from 'react-redux';
import {
  departmentActions,
  rolePermissionActions,
  useAppSelector,
  userActions,
} from '@lyra/core';
import {
  IGetPopupContainer,
  IOfficeBranch,
  IUserFormAdd,
} from '@shared-components/models';
import { capitalizeFirstLetter } from '@moxie/shared';
import Select, { SelectValue } from 'antd/lib/select';
import { RootState } from 'apps/lyra/src/core/store';
import { checkUserEmail } from '@lyra/services.api';

const { Panel } = Collapse;

const UserPersonalInfoForm: React.FC<IUserFormAdd> = ({
  onClose,
  form,
  isEdit,
  formData,
  triggerRefresh,
  setDisabled,
}: IUserFormAdd) => {
  const [countryCode, setCountryCode] = useState('');
  // const [timezone,setTimezone] = useState('');
  const [roleListData, setRoleList] = useState([] as any[]);
  const [updateRole, setUpdateRole] = useState<string | null>();
  const [infoForm] = useForm();
  const dispatch = useDispatch();
  const [emailValidationStatus, setEmailValidationStatus] = useState<
    FormItemProps['validateStatus']
  >('');
  const stateRoles = useAppSelector(
    (state: any) => state.role_permissions.roles
  );
  const branchList = useAppSelector((state) => state.office_branches?.allData);
  const departmentList =
    useAppSelector((state) => state.departments?.branchDepartments) || [];

  const handleCountryChange = (input: string) => {
    COUNTRIES.find((item) => {
      if (item.name === input) {
        setCountryCode(item.dial_code);
      }
    });
  };

  const currentUser: any = useAppSelector(
    (store: RootState) => store.auth.user
  );
  const handleSubmit = async (data: any) => {
    if (data.department === undefined) data.department = null;
    data.email = data?.email?.replace(/ /g, '').toLowerCase();

    const newData = {
      ...data,
      role: updateRole,
    };

    try {
      if (formData.id) {
        dispatch(
          userActions.updateUserRequest(
            {
              id: formData.id,
              ...newData,
              role: updateRole!,
            },
            triggerRefresh
          )
        );
      } else {
        dispatch(userActions.addUserRequest(newData, triggerRefresh));
      }
      onClose();
    } catch (err) {
      errorHandler(err);
    } finally {
      infoForm.resetFields();
    }
  };

  useEffect(() => {
    if (formData) {
      form.setFieldsValue(formData);
      handleCountryChange(formData.country);
      handleBranchChange(formData.branch_id);
    }
    dispatch(rolePermissionActions.getRolesRequest());
  }, [dispatch]);

  useEffect(() => {
    if (isEdit && Object.keys(formData).length > 0) {
      setUpdateRole(updateRole || formData.role_id);
      form.setFieldsValue({
        time_zone: formData.time_zone,
        department: formData.department_id,
      });
    } else {
      form.setFieldsValue({
        time_zone: currentUser?.time_zone,
      });
    }
  }, [formData, form, isEdit]);

  useEffect(() => {
    const rolesData = stateRoles?.map((item: any) => {
      const permissionArr =
        item.permission?.map((item: any) => item.name) || [];
      return {
        id: item.id?.toString(),
        name:
          item.name === AM_ROLES.SUPER_USER
            ? 'Super Admin'
            : capitalizeFirstLetter(
              item.name.toLowerCase().split('_').join(' ')
            ),
        permissions: [...permissionArr],
      };
    });
    setRoleList(rolesData);
  }, [stateRoles, setRoleList]);

  const handleOptionFilter = (input: string, option: any): boolean =>
    option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0;

  const onFieldsChange = (allFields: string | any[]) => {
    if (allFields.length > 0) {
      setDisabled(false);
    }
  };

  const handleBranchChange = (branchId: string | any) => {
    dispatch(departmentActions.getAllBranchDepartments(branchId));
    form.setFieldsValue({ department: null });
  };

  const handleBranchClear = () => {
    form.setFieldsValue({ department: null });
    dispatch(departmentActions.clearBranchDepartmentFetch());
  };
  const validateEmail = async (value: { email: string }) => {
    try {
      const res = await checkUserEmail(value?.email);
      return !!res?.data?.success;
    } catch (err) {
      return false;
    }
  };

  const handleEmailChange = async (rule: any, value: any): Promise<any> => {
    if (formData?.id) return true;
    value = value?.toLowerCase();
    if (!value) {
      setEmailValidationStatus('error');
      return Promise.resolve(true);
    }

    const regexEmail = new RegExp(regex.EMAIL);
    if (!regexEmail.test(value)) {
      setEmailValidationStatus('error');
      throw new Error(AUTH_MESSAGE.INVALID_EMAIL);
    }
    setEmailValidationStatus('validating');
    const trimValue = value?.replace(/ /g, '');
    const result = await validateEmail({
      email: trimValue,
    });

    if (result) {
      setEmailValidationStatus('success');
      return Promise.resolve(true);
    } else {
      setEmailValidationStatus('error');
      throw new Error(AUTH_MESSAGE.REGISTER_EMAIL_TAKEN);
    }
  };

  // const handleTimezoneChange = (value: string) => {
  //   form.setFieldsValue({
  //     time_zone: value,
  //   });
  // }
  useEffect(() => {
    form.setFieldsValue({
      time_zone: currentUser?.am_user_info?.time_zone,
    })
  },[currentUser])

  return (
    <Form
      data-testid="personal-details-form"
      layout="vertical"
      form={form}
      size={FORM_SIZE}
      onFinish={handleSubmit}
      onFieldsChange={onFieldsChange}
    >
      <div>
        <div className="form_section_header">{USER_PERSONAL_DETAIL}</div>
        <Row gutter={GUTTER}>
          <Col span="12">
            <FormItem
              name="first_name"
              label={FIRST_NAME_LABEL}
              rules={[{ required: true, max: 50, type: 'string' }]}
            >
              <Input placeholder={FIRST_NAME_PLACEHOLDER} />
            </FormItem>
          </Col>
          <Col span="12">
            <FormItem
              name="last_name"
              label={LAST_NAME_LABEL}
              rules={[{ required: true, max: 50, type: 'string' }]}
            >
              <Input placeholder={LAST_NAME_PLACEHOLDER} />
            </FormItem>
          </Col>
          <Divider />
        </Row>
      </div>

      <Collapse
        defaultActiveKey={ADDRESS_INFORMATION}
        bordered={false}
        ghost={true}
        expandIcon={({ isActive }) => (
          <CaretRightOutlined rotate={isActive ? 90 : 0} />
        )}
      >
        <Panel
          header={<span>{ADDRESS_INFORMATION}</span>}
          key={ADDRESS_INFORMATION}
        >
          <Row gutter={GUTTER}>
            <Col span="12">
              <FormItem
                name="address1"
                label={ADDRESS1_LABEL}
                rules={[{ required: true, type: 'string', max: 50 }]}
              >
                <Input placeholder={ADDRESS1_PLACEHOLDER} />
              </FormItem>
            </Col>
            <Col span="12">
              <FormItem
                name="address2"
                label={ADDRESS2_LABEL}
                rules={[{ type: 'string', max: 100 }]}
              >
                <Input placeholder={ADDRESS2_PLACEHOLDER} />
              </FormItem>
            </Col>
          </Row>
          <Row gutter={GUTTER}>
            <Col span="12">
              <div id="user_country_list" className="relative">
                <FormItem
                  name="country"
                  label={COUNTRY_LABEL}
                  rules={[{ required: true, type: 'string', max: 50 }]}
                >
                  <SearchableSelect
                    onChange={(value: SelectValue) => {
                      if (value) handleCountryChange(value.toString());
                    }}
                    placeholder={COUNTRY_PLACEHOLDER}
                    filterOption={handleOptionFilter}
                    getPopupContainer={(): IGetPopupContainer =>
                      document.getElementById('user_country_list')
                    }
                  >
                    {COUNTRIES.map((country) => {
                      return (
                        <Option value={country.name} key={country.code}>
                          {country.name}
                        </Option>
                      );
                    })}
                  </SearchableSelect>
                </FormItem>
              </div>
            </Col>
            <Col span="12">
              <FormItem
                name="city_or_state"
                label={CITY_OR_STATE_LABEL}
                rules={[{ required: true, type: 'string', max: 100 }]}
              >
                <Input placeholder={CITY_OR_STATE_PLACEHOLDER} />
              </FormItem>
            </Col>
          </Row>
        </Panel>
      </Collapse>
      <Divider />

      <Collapse
        defaultActiveKey={CONTACT_INFORMATION}
        bordered={false}
        ghost={true}
        expandIcon={({ isActive }) => (
          <CaretRightOutlined rotate={isActive ? 90 : 0} />
        )}
      >
        <Panel
          header={<span>{CONTACT_INFORMATION}</span>}
          key={CONTACT_INFORMATION}
        >
          <FormItem
            name="email"
            label={EMAIL_LABEL}
            validateStatus={emailValidationStatus}
            rules={[
              {
                required: true,
              },
              {
                validator: handleEmailChange,
              },
            ]}
          >
            <Input placeholder={EMAIL_PLACEHOLDER} disabled={isEdit} />
          </FormItem>
          <Row gutter={GUTTER}>
            <Col span="12">
              <Form.Item
                name="phone"
                label={PHONE_LABEL}
                rules={[
                  {
                    required: true,
                    pattern: new RegExp(regex.ONLY_NUMBER),
                    message: PHONE_NUMBER_INVALID,
                  },
                ]}
              >
                <PhoneInput
                  type="string"
                  placeholder={PHONE_PLACEHOLDER}
                  dialCode={countryCode}
                  onChangeDialCode={handleCountryChange}
                />
              </Form.Item>
            </Col>
            <Col span="12">
              <FormItem
                name="alternative_phone"
                label={ALTERNATIVE_PHONE_LABEL}
                rules={[
                  {
                    pattern: new RegExp(regex.ONLY_NUMBER),
                    message: PHONE_NUMBER_INVALID,
                  },
                ]}
              >
                <PhoneInput
                  type="string"
                  placeholder={ALTERNATIVE_PHONE_PLACEHOLDER}
                  dialCode={countryCode}
                  onChangeDialCode={handleCountryChange}
                />
              </FormItem>
            </Col>
          </Row>
        </Panel>
      </Collapse>
      <Divider />

      <Collapse
        defaultActiveKey={JOB_INFORMATION}
        bordered={false}
        ghost={true}
        expandIcon={({ isActive }) => (
          <CaretRightOutlined rotate={isActive ? 90 : 0} />
        )}
      >
        <Panel header={<span>{JOB_INFORMATION}</span>} key={JOB_INFORMATION}>
          <Row gutter={GUTTER}>
            <Col span="12">
              <div id="user_role" className="relative">
                <FormItem
                  name="role"
                  label={ROLE_LABEL}
                  rules={[{ required: true }]}
                >
                  <SearchableSelect
                    onChange={(value: any) => {
                      setUpdateRole(value);
                    }}
                    optionFilter="key"
                    placeholder={ROLE_PLACEHOLDER}
                    filterOption={handleOptionFilter}
                    getPopupContainer={(): IGetPopupContainer =>
                      document.getElementById('user_country_list')
                    }
                  >
                    {roleListData &&
                      roleListData.map((item) => {
                        return (
                          <Option value={item?.id} key={item?.name}>
                            {capitalizeFirstLetter(item?.name)}
                          </Option>
                        );
                      })}
                  </SearchableSelect>
                </FormItem>
              </div>
            </Col>

            <Col span="12">
              <div id="user_branch" className="relative">
                <FormItem
                  name="branch"
                  label={LABEL.BRANCH}
                  rules={[{ required: true }]}
                >
                  <SearchableSelect
                    optionFilter="key"
                    placeholder={PLACEHOLDER.SELECT_BRANCH}
                    onSelect={(data: any) => {
                      handleBranchChange(data);
                    }}
                    getPopupContainer={(): IGetPopupContainer =>
                      document.getElementById('user_branch')
                    }
                    onClear={handleBranchClear}
                  >
                    {branchList &&
                      branchList.map((item: IOfficeBranch) => {
                        return (
                          <Option value={item?.id || ''} key={item?.name}>
                            {item?.name}
                          </Option>
                        );
                      })}
                  </SearchableSelect>
                </FormItem>
              </div>
            </Col>

            <Col span="12">
              <div id="user_department" className="relative">
                <FormItem
                  name="department"
                  label={LABEL.DEPARTMENT}
                  rules={[{ required: false }]}
                >
                  <SearchableSelect
                    optionFilter="key"
                    placeholder={PLACEHOLDER.SELECT_DEPARTMENT}
                    getPopupContainer={(): IGetPopupContainer =>
                      document.getElementById('user_department')
                    }
                  >
                    {departmentList &&
                      departmentList.map((item: any) => {
                        return (
                          <Option
                            value={item.department?.id}
                            key={item.department?.name}
                          >
                            {item.department?.name}
                          </Option>
                        );
                      })}
                  </SearchableSelect>
                </FormItem>
              </div>
            </Col>
          </Row>
        </Panel>
      </Collapse>
      <Divider />

      <Collapse
        defaultActiveKey={CHANGE_TIMEZONE}
        bordered={false}
        ghost={true}
        expandIcon={({ isActive }) => (
          <CaretRightOutlined rotate={isActive ? 90 : 0} />
        )}
      >
        <Panel header={<span>{CHANGE_TIMEZONE}</span>} key={CHANGE_TIMEZONE}>
          <div id="user_timezone" className="relative">
            <Row gutter={GUTTER}>
              <Col span="24">
                <Form.Item
                  label="Timezone"
                  name="time_zone"
                  rules={[
                    { required: true, message: `${TEXT.TIMEZONE_SELECT}` },
                  ]}
                >
                  <Select
                    getPopupContainer={(): IGetPopupContainer =>
                      document.getElementById('user_timezone')
                    }
                  >
                    {TIMEZONE.map((data, idx) => {
                      return (
                        <Option value={data.utc[0]} key={idx}>
                          {data.text}
                        </Option>
                      );
                    })}
                  </Select>
                </Form.Item>
              </Col>
            </Row>
          </div>
        </Panel>
      </Collapse>
    </Form>
  );
};

UserPersonalInfoForm.defaultProps = {
  isProfile: false,
};
export { UserPersonalInfoForm };
