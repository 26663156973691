import { errorHandler } from '@moxie/utils';
import { AxiosResponse } from 'axios';
import { put, takeEvery, takeLatest } from 'redux-saga/effects';
import * as product from './products.constant';
import * as productActions from './products.action';
import { IAction, IProduct } from '@shared-components/models';
import { successNotificationHandler } from 'libs/shared/src/shared';
import {
  getAllProductEducations,
  getAllProductLanguageTestScore,
  postProductEducation,
  postProductLanguageTestScore,
  productServices,
  deleteProductEducation,
  deleteProductLanguage,
  getOneProductEducation,
  getOneProductLanguageTestScore,
  updateProductEducation,
  updateProductLanguage,
} from '@admin/services.api';

export const getProductFilters = function* (): any {
  try {
    const res: AxiosResponse = yield productServices.apiGetProductFiltersData();
    yield put(productActions.productFiltersRequestSuccess(res.data));
  } catch (err) {
    errorHandler(err);
  }
};

export const getProducts = function* ({ payload }: IAction<any>): any {
  try {
    const res: AxiosResponse = yield productServices.apiGetProducts(payload);
    yield put(productActions.getProductsSuccess(res.data.data));
  } catch (err) {
    errorHandler(err);
    yield put(productActions.getProductsFailure(err));
  }
};

export const getProduct = function* ({ payload }: IAction<any>) {
  try {
    if (payload) {
      const response: AxiosResponse = yield productServices.apiGetProductDetail(
        payload
      );
      yield put(productActions.getProductSuccess(response.data.data));
    }
  } catch (err) {
    errorHandler(err);
    yield put(productActions.getProductFailure(err));
  }
};
export const addProductData = function* ({
  payload,
  triggerRefresh,
}: IAction<IProduct>) {
  try {
    if (payload) {
      const response: AxiosResponse = yield productServices.apiAddProduct(
        payload
      );
      yield put(productActions.addProductSuccess(response.data.data));
      successNotificationHandler(response?.data?.message);
      triggerRefresh && triggerRefresh();
    }
  } catch (err) {
    errorHandler(err);
    yield put(productActions.addProductFailure(err));
  }
};

export const updateProductData = function* ({
  payload,
  triggerRefresh,
}: IAction<IProduct>) {
  try {
    if (payload) {
      const response: AxiosResponse = yield productServices.apiUpdateProduct(
        payload
      );
      yield put(productActions.updateProductSuccess(response.data.data));
      successNotificationHandler(response?.data?.message);
      triggerRefresh && triggerRefresh();
    }
  } catch (err) {
    errorHandler(err);
    yield put(productActions.updateProductFailure(err));
  }
};

export const deleteProductData = function* ({ payload }: IAction<string>) {
  try {
    if (payload) {
      const response: AxiosResponse = yield productServices.apiDeleteProduct(
        payload
      );
      yield put(productActions.deleteProductSuccess(response.data.data));
      successNotificationHandler(response.data.message);
    }
  } catch (err) {
    errorHandler(err);
    yield put(productActions.deleteProductFailure(err));
  }
};

export const getProductFees = function* ({ payload }: IAction<string>): any {
  if (payload) {
    try {
      const res: AxiosResponse = yield productServices.apiGetProductFees(
        payload
      );
      yield put(productActions.getProductFeesSuccess(res.data.data));
    } catch (err) {
      errorHandler(err);
      yield put(productActions.getProductFeesFailure(err));
    }
  }
};

export const getProductFee = function* ({ payload }: IAction<string>): any {
  if (payload) {
    try {
      const res: AxiosResponse = yield productServices.apiGetProductFee(
        payload
      );
      yield put(productActions.getProductFeeSuccess(res.data.data));
    } catch (err) {
      errorHandler(err);
      yield put(productActions.getProductFeeFailure(err));
    }
  }
};

export const addProductFee = function* ({ payload, id }: IAction<any>) {
  try {
    if (payload) {
      const response: AxiosResponse = yield productServices.apiAddProductFee(
        payload
      );
      yield put(productActions.addProductFeeSuccess(response.data.data));
      const res: AxiosResponse = yield productServices.apiGetProductFees(id);
      yield put(productActions.getProductFeesSuccess(res.data.data));
      successNotificationHandler(response.data.message);
    }
  } catch (err) {
    errorHandler(err);
    yield put(productActions.addProductFeeFailure(err));
  }
};
export const updateProductFee = function* ({ payload }: IAction<any>) {
  try {
    if (payload) {
      const response: AxiosResponse = yield productServices.apiUpdateProductFee(
        payload
      );
      yield put(productActions.updateProductFeeSuccess(response.data.data));
      successNotificationHandler(response.data.message);
    }
  } catch (err) {
    errorHandler(err);
    yield put(productActions.updateProductFeeFailure(err));
  }
};

export const deleteProductFee = function* ({ payload }: IAction<any>) {
  try {
    if (payload) {
      const response: AxiosResponse = yield productServices.apiDeleteProductFee(
        payload
      );
      yield put(productActions.deleteProductFeeSuccess(response.data.data));
      successNotificationHandler(response.data.message);
    }
  } catch (err) {
    errorHandler(err);
    yield put(productActions.deleteProductFeeFailure(err));
  }
};

export const getProductEducations = function* ({ payload }: IAction<any>): any {
  try {
    const res: AxiosResponse = yield getAllProductEducations(payload);
    yield put(productActions.getProductEducationsSuccess(res?.data?.data));
  } catch (err) {
    errorHandler(err);
    yield put(productActions.getProductEducationsFailure(err));
  }
};

export const getProductEducation = function* ({ payload }: IAction<any>): any {
  try {
    const res: AxiosResponse = yield getOneProductEducation(payload);
    yield put(productActions.getProductEducationSuccess(res?.data?.data));
  } catch (err) {
    errorHandler(err);
    yield put(productActions.getProductEducationFailure(err));
  }
};

export const addProductEducation = function* ({ payload }: IAction<any>) {
  try {
    if (payload) {
      const response: AxiosResponse = yield postProductEducation(payload);
      yield put(
        productActions.addProductEducationSuccess(response?.data?.data)
      );
      successNotificationHandler(response?.data?.message);
    }
  } catch (err) {
    errorHandler(err);
    yield put(productActions.addProductEducationFailure(err));
  }
};

export const editProductEducation = function* ({ id, payload }: IAction<any>) {
  try {
    if (payload) {
      const response: AxiosResponse = yield updateProductEducation(id, payload);
      yield put(
        productActions.updateProductEducationSuccess(response?.data?.data)
      );
      successNotificationHandler(response?.data?.message);
    }
  } catch (err) {
    errorHandler(err);
    yield put(productActions.updateProductEducationFailure(err));
  }
};

export const removeProductEducation = function* ({ payload }: IAction<string>) {
  try {
    if (payload) {
      const response: AxiosResponse = yield deleteProductEducation(payload);
      yield put(
        productActions.deleteProductEducationSuccess(response?.data?.data)
      );
      successNotificationHandler(response?.data?.message);
    }
  } catch (err) {
    errorHandler(err);
    yield put(productActions.deleteProductEducationFailure(err));
  }
};

export const addProductLanguage = function* ({ payload }: IAction<any>) {
  try {
    if (payload) {
      const response: AxiosResponse = yield postProductLanguageTestScore(
        payload
      );
      yield put(productActions.addProductLanguageSuccess(response?.data?.data));
      successNotificationHandler(response?.data?.message);
    }
  } catch (err) {
    errorHandler(err);
    yield put(productActions.addProductLanguageFailure(err));
  }
};
export const getProductLanguages = function* ({ payload }: IAction<any>): any {
  try {
    const res: AxiosResponse = yield getAllProductLanguageTestScore(payload);
    yield put(productActions.getProductLanguagesSuccess(res?.data?.data));
  } catch (err) {
    errorHandler(err);
    yield put(productActions.getProductLanguagesFailure(err));
  }
};

export const removeProductLangugae = function* ({ payload }: IAction<string>) {
  try {
    if (payload) {
      const response: AxiosResponse = yield deleteProductLanguage(payload);
      yield put(
        productActions.deleteProductLanguageSuccess(response?.data?.data)
      );
      successNotificationHandler(response?.data?.message);
    }
  } catch (err) {
    errorHandler(err);
    yield put(productActions.deleteProductLanguageFailure(err));
  }
};

export const getProductLanguage = function* ({ payload }: IAction<any>): any {
  try {
    const res: AxiosResponse = yield getOneProductLanguageTestScore(payload);
    yield put(productActions.getProductLanguageSuccess(res?.data?.data));
  } catch (err) {
    errorHandler(err);
    yield put(productActions.getProductLanguageFailure(err));
  }
};

export const editProductLanguage = function* ({ id, payload }: IAction<any>) {
  try {
    if (payload) {
      const response: AxiosResponse = yield updateProductLanguage(id, payload);
      yield put(
        productActions.updateProductLanguageSuccess(response?.data?.data)
      );
      successNotificationHandler(response?.data?.message);
    }
  } catch (err) {
    errorHandler(err);
    yield put(productActions.updateProductLanguageFailure(err));
  }
};
export default function* productSagas() {
  yield takeEvery(product.FETCH_PRODUCTS_REQUEST, getProducts);
  yield takeLatest(product.FETCH_PRODUCT_REQUEST, getProduct);
  yield takeLatest(product.DELETE_PRODUCT_REQUEST, deleteProductData);
  yield takeLatest(product.UPDATE_PRODUCT_REQUEST, updateProductData);
  yield takeLatest(product.DELETE_PRODUCT_FEE_REQUEST, deleteProductFee);
  yield takeLatest(product.ADD_PRODUCT_REQUEST, addProductData);
  yield takeLatest(product.ADD_PRODUCT_FEE_REQUEST, addProductFee);
  yield takeLatest(product.UPDATE_PRODUCT_FEE_REQUEST, updateProductFee);
  yield takeEvery(product.GET_PRODUCT_FEES_REQUEST, getProductFees);
  yield takeEvery(product.GET_PRODUCT_FEE_REQUEST, getProductFee);
  yield takeLatest(product.ADD_PRODUCT_EDUCATION_REQUEST, addProductEducation);
  yield takeLatest(
    product.GET_PRODUCT_EDUCATIONS_REQUEST,
    getProductEducations
  );
  yield takeLatest(product.ADD_PRODUCT_LANGUAGE_REQUEST, addProductLanguage);
  yield takeLatest(product.GET_PRODUCT_LANGUAGES_REQUEST, getProductLanguages);
  yield takeLatest(
    product.DELETE_PRODUCT_EDUCATION_REQUEST,
    removeProductEducation
  );
  yield takeLatest(
    product.DELETE_PRODUCT_LANGUAGE_REQUEST,
    removeProductLangugae
  );

  yield takeLatest(product.GET_PRODUCT_EDUCATION_REQUEST, getProductEducation);
  yield takeLatest(
    product.UPDATE_PRODUCT_EDUCATION_REQUEST,
    editProductEducation
  );
  yield takeLatest(product.GET_PRODUCT_LANGUAGE_REQUEST, getProductLanguage);
  yield takeLatest(
    product.UPDATE_PRODUCT_LANGUAGE_REQUEST,
    editProductLanguage
  );
  yield takeLatest(product.REQUEST_PRODUCT_FILTERS_REQUEST, getProductFilters);
}
