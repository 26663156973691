import { API_URL } from '@moxie/constants';
import {
  IEmailVerification,
  IEmailVerificationResponse,
  IResendEmailVerification,
} from '@shared-components/models';
import axiosInstance from './axios';

const verifyEmailToken = (
  body: IEmailVerification
): Promise<IEmailVerificationResponse> => {
  return axiosInstance.post(API_URL.EMAIL_VERIFICATION, body);
};

const resendEmailToken = (
  body: IResendEmailVerification
): Promise<IEmailVerificationResponse> => {
  return axiosInstance.post(API_URL.RESEND_EMAIL_VERIFICATION, body);
};

export { verifyEmailToken, resendEmailToken };
