import { put, takeLatest } from 'redux-saga/effects';
import { IAction, IEducation } from '@shared-components/models';
import { errorHandler } from '@moxie/utils';
import {
  createEducationBackground,
  getAllEducationBackground,
  updateEducationBackground,
  deleteEducationBackground,
} from '@lyra/services.api';
import { AxiosResponse } from 'axios';
import * as actionTypes from './education-background.constant';
import * as actionEducationBackground from './education-background.action';
import { successNotificationHandler } from '@moxie/shared';
import { ADD_SUCCESS, DELETE_SUCCESS, UPDATE_SUCCESS } from '@moxie/constants';

function* getEducationBackgrounds({ payload }: IAction<string>) {
  try {
    const response: AxiosResponse = yield getAllEducationBackground(payload);
    yield put(
      actionEducationBackground.getEducationBackgroundsSuccess(
        response.data.data
      )
    );
  } catch (err) {
    errorHandler(err);
    yield put(actionEducationBackground.getEducationBackgroundsFailure(err));
  }
}

function* addEducationBackground({ payload }: IAction<IEducation>) {
  try {
    if (payload) {
      const response: AxiosResponse = yield createEducationBackground(payload);
      yield put(
        actionEducationBackground.addEducationBackgroundSuccess(
          response.data.data
        )
      );
      successNotificationHandler(ADD_SUCCESS);
    }
  } catch (err) {
    errorHandler(err);
    yield put(actionEducationBackground.addEducationBackgroundFailure(err));
  }
}

function* updateEducationBakground({ payload }: IAction<IEducation>) {
  try {
    if (!payload) return;
    const response: AxiosResponse = yield updateEducationBackground(payload);
    yield put(
      actionEducationBackground.updateEducationBackgroundSuccess(
        response.data.data
      )
    );
    successNotificationHandler(UPDATE_SUCCESS);
  } catch (err) {
    errorHandler(err);
    yield put(actionEducationBackground.updateEducationBackgroundFailure(err));
  }
}

function* deleteEducationBakground({ payload }: IAction<string>) {
  try {
    if (!payload) return;
    yield deleteEducationBackground(payload);
    yield put(
      actionEducationBackground.deleteEducationBackgroundSuccess(payload)
    );
    successNotificationHandler(DELETE_SUCCESS);
  } catch (err) {
    errorHandler(err);
    yield put(actionEducationBackground.deleteEducationBackgroundFailure(err));
  }
}

export default function* rootSagas(): unknown {
  yield takeLatest(
    actionTypes.GET_EDUCATION_BACKGROUNDS_REQUEST,
    getEducationBackgrounds
  );
  yield takeLatest(
    actionTypes.ADD_EDUCATION_BACKGROUND_REQUEST,
    addEducationBackground
  );
  yield takeLatest(
    actionTypes.UPDATE_EDUCATION_BACKGROUND_REQUEST,
    updateEducationBakground
  );
  yield takeLatest(
    actionTypes.DELETE_EDUCATION_BACKGROUND_REQUEST,
    deleteEducationBakground
  );
}
