import React, { useEffect, useState } from 'react';
import {
  CANCEL_BTN,
  CREATE_OFFICE_TITLE,
  DRAWER_WIDTH,
  EDIT_OFFICE_TITLE,
  SAVE_BTN,
  UPDATE_BTN,
} from '@moxie/constants';
import { Space } from 'antd';
import { Button, DrawerElem } from '@moxie/shared';
import { OfficeBranchForm } from '../../forms/office-branch';
import { IOfficeDrawerComponent } from '@shared-components/models';
import { useDispatch } from 'react-redux';
import { departmentActions } from '@lyra/core';

const OfficeDrawer: React.FC<IOfficeDrawerComponent> = ({
  visible,
  onClose,
  handleFormSubmit,
  isEdit,
  nameValidationStatus,
  setNameValidationStatus,
  form,
  handleSubmit,
  initialData,
}: IOfficeDrawerComponent) => {
  const [disabled, setDisabled] = useState(true);
  const dispatch = useDispatch();
  useEffect(() => {
    dispatch(departmentActions.getDepartmentsRequest());
  }, []);

  return (
    <DrawerElem
      title={isEdit ? EDIT_OFFICE_TITLE : CREATE_OFFICE_TITLE}
      width={DRAWER_WIDTH}
      visible={visible}
      onClose={onClose}
      footer={
        <div className="padding-bottom-2 text-align-right">
          <Space direction="horizontal">
            <Button onClick={onClose} className="lead-margin-right">
              {CANCEL_BTN}
            </Button>
            <Button
              type="primary"
              onClick={handleFormSubmit}
              disabled={disabled}
            >
              {isEdit ? UPDATE_BTN : SAVE_BTN}
            </Button>
          </Space>
        </div>
      }
    >
      <OfficeBranchForm
        form={form}
        onSubmit={handleSubmit}
        initialData={initialData}
        nameValidationStatus={nameValidationStatus}
        setNameValidationStatus={setNameValidationStatus}
        setDisabled={setDisabled}
      />
    </DrawerElem>
  );
};

export { OfficeDrawer };
