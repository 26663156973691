import { put, takeLatest, ForkEffect } from 'redux-saga/effects';
import { socketActions } from './socket.reducer';
import { socketSagaActions } from '../sagas/sagaActions';

function* sagaSetSocket(data: any) {
  yield put(socketActions.setSocket(data.payload));
}

function* sagaRemoveSocket() {
  yield put(socketActions.removeSocket());
}

export default function* socketSagas(): Generator<
  ForkEffect<never>,
  void,
  unknown
> {
  yield takeLatest(socketSagaActions.SET_SOCKET_CONNECTION, sagaSetSocket);
  yield takeLatest(
    socketSagaActions.REMOVE_SOCKET_CONNECTION,
    sagaRemoveSocket
  );
}
