const alias = 'USER_APPOINTMENT';

export const GET_USER_APPOINTMENTS_REQUEST = `GET_${alias}S_REQUEST`;
export const GET_USER_APPOINTMENTS_SUCCESS = `GET_${alias}S_SUCCESS`;
export const GET_USER_APPOINTMENTS_FAILURE = `GET_${alias}_FAILURE`;
export const GET_USER_APPOINTMENTS_CLEAR = `GET_${alias}S_CLEAR`;

export const GET_USER_APPOINTMENT_REQUEST = `GET_${alias}_REQUEST`;
export const GET_USER_APPOINTMENT_SUCCESS = `GET_${alias}_SUCCESS`;
export const GET_USER_APPOINTMENT_FAILURE = `GET_${alias}_FAILURE`;

export const ADD_USER_APPOINTMENT_REQUEST = `ADD_${alias}_REQUEST`;
export const ADD_USER_APPOINTMENT_SUCCESS = `ADD_${alias}_SUCCESS`;
export const ADD_USER_APPOINTMENT_FAILURE = `ADD_${alias}_FAILURE`;

export const UPDATE_USER_APPOINTMENT_REQUEST = `UPDATE_${alias}_REQUEST`;
export const UPDATE_USER_APPOINTMENT_SUCCESS = `UPDATE_${alias}_SUCCESS`;
export const UPDATE_USER_APPOINTMENT_FAILURE = `UPDATE_${alias}_FAILURE`;

export const DELETE_USER_APPOINTMENT_REQUEST = `DELETE_${alias}_REQUEST`;
export const DELETE_USER_APPOINTMENT_SUCCESS = `DELETE_${alias}_SUCCESS`;
export const DELETE_USER_APPOINTMENT_FAILURE = `DELETE_${alias}_FAILURE`;
