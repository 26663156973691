import Typography from 'antd/es/typography';
import React from 'react';
import moment from 'moment';

interface Props {
    date: string;
}
const DateFormat: React.FC<Props> = ({ date }) => {
    const parsed = moment(date);
    return (
        <>
            <Typography.Text strong>
                {parsed.format('Do MMM, YYYY')}
            </Typography.Text>
            <div className="justify-content-center">
                <Typography.Text strong className="text-primary-color">
                    {parsed.format('dddd')}
                </Typography.Text>
            </div>
        </>
    )
}

export default DateFormat;
