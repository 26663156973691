import { updateNotificationStatus } from '@crm/services.api';
import { UpdateNotificationStatusPayload } from '@model/notification';
import { errorNotificationHandler } from '@moxie/shared';
import { useMutation, useQueryClient } from '@tanstack/react-query';

export const useToggleNotificationStatusMutation = () => {
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: (payload: UpdateNotificationStatusPayload) => {
      return updateNotificationStatus(payload);
    },
    onSuccess() {
      queryClient.invalidateQueries({
        queryKey: ['notification-count'],
      });
      queryClient.invalidateQueries({
        queryKey: ['notifications'],
      });
    },
    onError() {
      errorNotificationHandler('Failed to update notification status');
    },
  });
};
