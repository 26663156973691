import axios from './axios';
import { IResponse } from '@shared-components/models';
import { API_URL } from '@moxie/constants';

const addTasks = async (body: any): Promise<IResponse> => {
  return axios.post(API_URL.TASKS, body);
};

const updateTasks = async (body: any): Promise<IResponse> => {
  return axios.put(`${API_URL.TASKS}/update`, body);
};

const updateTaskStatus = async (
  id: string,
  status: string
): Promise<IResponse> => {
  return axios.put(`${API_URL.TASKS}/update-status`, { status, id });
};

const getAllTasks = async (params = ''): Promise<IResponse> => {
  if (params?.length > 1) {
    return axios.get(`${API_URL.TASKS}/${params}`);
  } else {
    return axios.get(`${API_URL.TASKS}`);
  }
};

const updateTaskAssignee = async (data: any) => {
  return axios.put(`${API_URL.TASKS}/update-assignee`, data);
};

const deleteTasks = async (id: string): Promise<IResponse> => {
  return axios.delete(`${API_URL.TASKS}/${id}`);
};

const getOneTask = async (id: string): Promise<IResponse> => {
  return axios.get(`${API_URL.TASKS}/${id}`);
};

const deleteTaskDocuments = async (body: any) => {
  return axios.put(API_URL.TASK_DOCUMENT_DELETE, body);
};

export {
  addTasks,
  updateTasks,
  updateTaskStatus,
  getAllTasks,
  deleteTasks,
  deleteTaskDocuments,
  getOneTask,
  updateTaskAssignee,
};
