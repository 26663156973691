import { otherDetailActions, SETTING_OTHER_DETAILS } from '@lyra/core';
import {
  CONFIRM_MESSAGE,
  DELETE_BTN,
  LABEL_OPTION,
  STATUS,
} from '@moxie/constants';
import { popConfirm } from '@shared-components/elements';
import {
  IOtherDetailLabelsValue,
  IOtherDetailsCategory,
  IOtherDetailsLabel,
} from '@shared-components/models';
import { setAddon } from '@storybook/react';
import { useForm } from 'antd/lib/form/Form';
import { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';

const useDetailLabel = () => {
  const [selectedLabel, setSelectedLabel] = useState('');
  const [isLabelAdd, setLabelAdd] = useState(false);
  const [selectedOption, setSelectedOption] = useState<{
    data_type: string;
    label: string;
  }>();
  const [drawerIsOpen, setDrawerOpen] = useState(false);
  const dispatch = useDispatch();
  // eslint-disable-next-line no-unused-vars
  const [optionInitialData, _setOptionInitialData] = useState<
    IOtherDetailLabelsValue[]
  >();
  const [form] = useForm();
  const [editForm] = useForm();
  const {
    labelList,
    selectedCategory,
    selectedCategoryId,
    labelDataLoading,
    labelStatus,
    tempOptions,
  } = useSelector((store: any) => ({
    labelList: store[SETTING_OTHER_DETAILS].labels,
    selectedCategory: store[SETTING_OTHER_DETAILS].categories.find(
      (item: IOtherDetailsCategory) =>
        item.id === store[SETTING_OTHER_DETAILS].selectedCategory
    )?.name,
    selectedCategoryId: store[SETTING_OTHER_DETAILS].selectedCategory,
    labelDataLoading: store[SETTING_OTHER_DETAILS].labelDataLoading,
    labelStatus: store[SETTING_OTHER_DETAILS].labelStatus,
    tempOptions: store[SETTING_OTHER_DETAILS].tempOptions,
  }));

  useEffect(() => {
    if (labelStatus === STATUS.SUCCEEDED) {
      dispatch(otherDetailActions.labelRequestIdle());
      handleSuccess();
    } else if (labelStatus === STATUS.FAILED) {
      dispatch(otherDetailActions.labelRequestIdle());
    }
  }, [labelStatus]);

  useEffect(() => {
    setLabelAdd(false);
  }, [selectedCategoryId]);

  useEffect(() => {
    setSelectedOption(undefined);
  }, [selectedLabel]);

  const handleSuccess = () => {
    form.resetFields();
    editForm.resetFields();
    dispatch(otherDetailActions.setTempOption([]));
    setLabelAdd(false);
    setSelectedLabel('');
  };

  const handleEditBtn = (id: string) => {
    setSelectedLabel(id);
    const selectedLabel = labelList.find(
      (item: IOtherDetailsLabel) => item.id === id
    );
    editForm.setFieldsValue(selectedLabel);
    dispatch(otherDetailActions.setTempOption(selectedLabel.options || []));
    setLabelAdd(false);
  };

  const handleFormChange = ({ data_type }: { data_type?: string }) => {
    if (data_type)
      setSelectedOption({ data_type: data_type, label: selectedLabel });
  };

  const handleDeleteBtn = (id: string) => {
    popConfirm(DELETE_BTN, CONFIRM_MESSAGE.DELETE_LABEL, () =>
      dispatch(otherDetailActions.deleteLabelRequest(id))
    );
  };

  const handleAddLabel = () => {
    form.resetFields();
    setLabelAdd(true);
    setSelectedLabel('');
  };

  const handleSubmit = (data: IOtherDetailsLabel) => {
    if (isLabelAdd)
      dispatch(
        otherDetailActions.addLabelWithOptionRequest({
          ...data,
          category_id: selectedCategoryId,
          options: tempOptions,
        })
      );
    else
      dispatch(
        otherDetailActions.addLabelRequest({
          ...data,
          category_id: selectedCategoryId,
          options: tempOptions,
        })
      );
  };

  const handleAddCancel = () => {
    setLabelAdd(false);
  };

  const handleEditCancel = () => {
    setSelectedLabel('');
  };

  const handleEditSubmit = (data: IOtherDetailsLabel) => {
    data.category_id = selectedCategoryId;
    data.id = selectedLabel;
    if (data.data_type === LABEL_OPTION[2]) data.options = tempOptions;
    dispatch(otherDetailActions.editLabelRequest(data));
  };

  const handleDrawer = (value: boolean) => setDrawerOpen(value);

  const handleAddOption = () => {
    setSelectedLabel('');
    setDrawerOpen(true);
  };

  const handleUpdateOption = () => {
    setDrawerOpen(true);
  };

  const handleClose = () => {
    setAddon(false);
    dispatch(otherDetailActions.setTempOption([]));
    form.resetFields();
  };

  return {
    labelList,
    selectedLabel,
    selectedOption,
    isLabelAdd,
    loading: labelStatus === STATUS.LOADING && true,
    form,
    editForm,
    tempOptions,
    selectedCategory,
    optionInitialData,
    labelDataLoading,
    drawerIsOpen,
    handleAddOption,
    handleUpdateOption,
    handleDrawer,
    handleSubmit,
    handleEditBtn,
    handleEditSubmit,
    handleDeleteBtn,
    handleEditCancel,
    handleAddLabel,
    handleFormChange,
    handleAddCancel,
    handleClose,
  };
};

export { useDetailLabel };
