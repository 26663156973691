import * as actionTypes from './department.constant';
import { IAction, IDepartment, IInitialState } from '@shared-components/models';

export const initialState: IInitialState<IDepartment> = {
  allData: [],
  singleData: {},
  error: null,
  loading: false,
  branchDepartments: [],
};

export const DEPARTMENT_FEATURE_KEY = 'departments';

const departmentReducer = (state = initialState, action: IAction<any>) => {
  switch (action.type) {
    case actionTypes.GET_DEPARTMENTS_REQUEST:
      return {
        ...state,
        loading: true,
        error: null,
      };
    case actionTypes.GET_DEPARTMENTS_SUCCESS:
      return {
        ...state,
        loading: false,
        error: null,
        allData: action.payload,
      };
    case actionTypes.GET_DEPARTMENTS_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.payload,
      };
    case actionTypes.GET_DEPARTMENT_REQUEST:
      return {
        ...state,
        loading: true,
        error: null,
      };
    case actionTypes.GET_DEPARTMENT_SUCCESS:
      return {
        ...state,
        loading: false,
        error: null,
        singleData: action.payload,
      };
    case actionTypes.GET_DEPARTMENT_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.payload,
      };
    case actionTypes.CLEAR_DEPARTMENT_FETCH:
      return {
        ...state,
        loading: false,
        error: null,
        singleData: null,
      };
    case actionTypes.UPDATE_DEPARTMENT_REQUEST:
      return {
        ...state,
        loading: true,
        error: null,
      };
    case actionTypes.UPDATE_DEPARTMENT_SUCCESS:
      return {
        ...state,
        loading: false,
        error: null,
      };
    case actionTypes.UPDATE_DEPARTMENT_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.payload,
      };
    case actionTypes.DELETE_DEPARTMENT_REQUEST:
      return {
        ...state,
        loading: true,
        error: null,
      };
    case actionTypes.DELETE_DEPARTMENT_SUCCESS:
      return {
        ...state,
        loading: false,
        error: null,
      };
    case actionTypes.DELETE_DEPARTMENT_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.payload,
      };
    case actionTypes.GET_BRANCH_DEPARTMENTS_REQUEST:
      return {
        ...state,
        loading: true,
        error: null,
      };
    case actionTypes.GET_BRANCH_DEPARTMENTS_SUCCESS:
      return {
        ...state,
        loading: false,
        error: null,
        branchDepartments: action.payload,
      };
    case actionTypes.GET_BRANCH_DEPARTMENTS_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.payload,
      };
    case actionTypes.CLEAR_BRANCH_DEPARTMENT_FETCH:
      return {
        ...state,
        loading: false,
        error: null,
        branchDepartments: null,
      };
    default:
      return state;
  }
};

export { departmentReducer };
