import React, { useEffect, useState } from 'react';
import {
  Row,
  Col,
  Input,
  Divider,
  Card,
  Menu,
  Empty,
  Form,
  Typography,
} from 'antd';
import { SearchOutlined } from '@ant-design/icons';
import { useDispatch } from 'react-redux';
import {
  otherDetailActions,
  otherDetailValueActions,
  useAppSelector,
} from '@lyra/core';
import OtherDetailCollapse from '../../lead-other-details';
import { useParams } from 'react-router-dom';
import {
  APPLICATION_FORM_DETAIL,
  GUTTER,
  LABEL,
  PAGE_TITLE_SIZE,
} from '@moxie/constants';

const WorkflowStageLeadOtherDetails: React.FC<{ stageId: string }> = ({
  stageId,
}: {
  stageId: string;
}) => {
  const dispatch = useDispatch();
  const { id } = useParams<{ id: string }>();

  const { categories, labelValue } = useAppSelector((store) => ({
    categories: store.setting_other_details.categories,
    labels: store.setting_other_details.labels,
    labelValue: store.lead_other_detail_value.valueData,
  }));
  const [searchData, setSearchData] = useState<any[]>();

  const [categoryKey, setCategoryKey] = useState<string>();

  const getFields = (id: string) => {
    dispatch(otherDetailActions.getCategoriesRequestByWfStageId(id));
  };

  const handleClick = async ({ key }: { key: string }) => {
    setCategoryKey(key);
    dispatch(otherDetailValueActions.getLeadOtherDetailValuesClear());
    dispatch(otherDetailActions.getLabelRequest(key));
    dispatch(otherDetailValueActions.getLeadOtherDetailValuesRequest(id, key));
  };

  const handleSearch = (values: any) => {
    const searchResult: any = [];
    if (values.searchValues.trim() === '') {
      setSearchData(categories);
    } else {
      categories?.forEach((item: { labels: any[] }) => {
        const searchLabels = item.labels.filter((data: { label: any }) => {
          const searchEl = values.searchValues.toLowerCase();
          return data.label.toLowerCase().indexOf(searchEl) !== -1
            ? data
            : null;
        });
        if (searchLabels?.length !== 0) {
          searchResult.push({ ...item, labels: searchLabels });
        }
      });
      setSearchData(searchResult);
    }
  };

  useEffect(() => {
    setCategoryKey(categories && categories[0]?.id);
    setSearchData(categories);
    if (categories?.length !== 0) {
      dispatch(
        otherDetailValueActions.getLeadOtherDetailValuesRequest(
          id,
          categories[0]?.id
        )
      );
    }
  }, [categories, id]);

  useEffect(() => {
    if (stageId && stageId.length > 0) getFields(stageId);
  }, [stageId]);

  return (
    <Col className="full-width">
      <div className="margin-bottom-1">
        <Row
          gutter={GUTTER}
          justify="space-between"
          className="form-description-position"
        >
          <Col>
            <Typography.Title level={PAGE_TITLE_SIZE}>
              <strong>{LABEL.APPLICATION_FORM}</strong>
            </Typography.Title>
          </Col>
          <Col>
            <Form onValuesChange={handleSearch}>
              <Form.Item name="searchValues">
                <Input
                  suffix={
                    <SearchOutlined className="lead-other-details__search-color" />
                  }
                  placeholder="Search"
                  bordered={true}
                  size={'small'}
                  className="lead-other-details__width padding-right-2"
                />
              </Form.Item>
            </Form>
          </Col>
        </Row>
        <span className="ant-upload-text">{APPLICATION_FORM_DETAIL}</span>
      </div>
      <Divider plain={true} className="search-margin-top" />
      <Row>
        <Col span={10} className="padding-left-2">
          <Card title="Categories">
            {categories && categories?.length !== 0 ? (
              <Menu
                mode="inline"
                defaultSelectedKeys={[categories[0]?.id]}
                className="menu_ul"
              >
                {searchData?.map((category: { id: string; name: string }) => (
                  <Menu.Item
                    className="lead-other-details__menu initial_capital"
                    onClick={handleClick}
                    key={category.id}
                  >
                    {category.name}
                  </Menu.Item>
                ))}
              </Menu>
            ) : (
              <div className="align-contents-center">
                <Empty
                  image={Empty.PRESENTED_IMAGE_SIMPLE}
                  description={
                    <span>
                      No further information is required at this stage
                    </span>
                  }
                />
              </div>
            )}
          </Card>
        </Col>
        <Col span={14} className="padding-left-2">
          {searchData?.map((category: any) => {
            return (
              <>
                <OtherDetailCollapse
                  labelValue={labelValue}
                  parent_id={id}
                  labels={category.labels}
                  category={category}
                  categoryKey={categoryKey && categoryKey}
                />
              </>
            );
          })}
        </Col>
      </Row>
    </Col>
  );
};

export { WorkflowStageLeadOtherDetails };
