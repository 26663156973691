/* eslint-disable no-unused-vars */
import React, { useEffect, useState } from 'react';
import { IRoleForm } from '@shared-components/models';
import { useAppSelector } from '@lyra/core';
import {
  ADD_NEW_ROLE_LABEL,
  DESCRIPTION_LABEL,
  PERMISSIONS_OFFICES_TEAMS,
  ROLE_NAME_REQUIRED,
  ROLE_DESCRIPTION_REQUIRED,
  PERMISSIONS_ROLES,
  PERMISSIONS_CLIENTS,
  PERMISSIONS_APPLICATIONS,
  PERMISSIONS_DEPARTMENTS,
  PERMISSIONS_USERS,
  PERMISSIONS_PRODUCTS,
  PERMISSIONS_INSTITUTIONS,
  PERMISSIONS_WORKFLOWTYPES,
  PERMISSIONS_WORKFLOWS,
} from '@moxie/constants';
import { Checkbox, Row, Col, Form, Input, Collapse } from 'antd';
import { CheckboxValueType } from 'antd/lib/checkbox/Group';
import { rolePermissionActions } from '@admin/core';
import { useDispatch } from 'react-redux';

const { TextArea } = Input;
const CheckboxGroup = Checkbox.Group;
const { Panel } = Collapse;

interface ICustomCheckBoxGroup {
  title: string;
  name: string;
  permissions: any[];
  selectedPermissions: (string | number | CheckboxValueType)[];
  label?: string | null;
}

export const CustomCheckBoxGroup: React.FC<ICustomCheckBoxGroup> = (
  props: ICustomCheckBoxGroup
) => {
  const { permissions, name, title, label, selectedPermissions } = props;

  const [hidePanel, sethidePanel] = useState<boolean>(false);
  const [checkAll, setCheckAll] = useState<boolean>(false);
  const [indeterminate, setIndeterminate] = React.useState(true);
  const [checkAllVal, setCheckAllValue] = useState<
    (string | number | CheckboxValueType)[]
  >(selectedPermissions || []);

  const onCheckAllChange = (e: {
    // eslint-disable-next-line no-unused-vars
    target: { checked: boolean | ((prevState: boolean) => boolean) };
  }) => {
    setCheckAll(!checkAll);
    const checkboxsArr = permissions.map((val) => val.value);
    setCheckAllValue(e.target.checked ? checkboxsArr : []);
    setIndeterminate(false);
    setCheckAll(e.target.checked);
  };

  const onChange = (list: (string | number | CheckboxValueType)[]) => {
    setCheckAllValue(list);
    setIndeterminate(!!list.length && list.length < permissions.length);
    setCheckAll(list.length === permissions.length);
  };

  const handlehidePanel = () => {
    sethidePanel(!hidePanel);
  };

  useEffect(() => {
    if (selectedPermissions.length <= 0) {
      setCheckAllValue([]);
    }
    setCheckAllValue(selectedPermissions);
    return () => {
      setCheckAllValue([]);
    };
  }, [selectedPermissions]);

  return (
    <div className={`custom-checkboxgroup ${hidePanel ? 'hide-content' : ''}`}>
      {label && <p className="custom-checkboxgroup__title">{label}</p>}
      <Row gutter={0} justify="space-between" className="custom__title__text">
        <Col lg={23} onClick={handlehidePanel}>
          {title}
        </Col>
        <Col lg={1}>
          <Checkbox
            indeterminate={indeterminate}
            name={`check-all_${name}`}
            onChange={onCheckAllChange}
            checked={checkAll}
          />
        </Col>
      </Row>
      <div className="custom-checkboxgroup__content">
        <Form.Item name={name} valuePropName="checked">
          <CheckboxGroup
            className="custom_role_vertical--checkbox"
            options={permissions || []}
            value={checkAllVal}
            onChange={onChange}
          />
        </Form.Item>
      </div>
    </div>
  );
};

export const RoleForm: React.FC<IRoleForm> = (props: IRoleForm) => {
  const { title, handleSubmit, form } = props;
  const dispatch = useDispatch();
  const statePermissions = useAppSelector(
    (state: any) => state.role_permissions.permissions
  );
  const selectedRole = useAppSelector(
    (state: any) => state.role_permissions.selectedRole
  );
  const [permissions, setPermissions] = useState<
    { label: string; value: number }[]
  >([]);
  const [officePermissions, setOfficePermissions] = useState<
    (string | number | CheckboxValueType)[]
  >([]);
  const [rolePermissions, setRolePermissions] = useState<
    (string | number | CheckboxValueType)[]
  >([]);
  const [userPermissions, setUserPermissions] = useState<
    (string | number | CheckboxValueType)[]
  >([]);
  const [clientPermissions, setClientPermissions] = useState<
    (string | number | CheckboxValueType)[]
  >([]);
  const [departmentPermissions, setDepartmentPermissions] = useState<
    (string | number | CheckboxValueType)[]
  >([]);
  const [applicationPermissions, setApplicationPermissions] = useState<
    (string | number | CheckboxValueType)[]
  >([]);
  const [productPermissions, setProductPermissions] = useState<
    (string | number | CheckboxValueType)[]
  >([]);
  const [institutionPermissions, setInstitutionPermissions] = useState<
    (string | number | CheckboxValueType)[]
  >([]);
  const [workflowPermissions, setWorkflowPermissions] = useState<
    (string | number | CheckboxValueType)[]
  >([]);
  const [workflowTypePermissions, setWorkflowTypePermissions] = useState<
    (string | number | CheckboxValueType)[]
  >([]);

  const onChangeOfficePermission = (
    list: (string | number | CheckboxValueType)[]
  ) => {
    setOfficePermissions(list);
  };

  const onChangeRolePermission = (
    list: (string | number | CheckboxValueType)[]
  ) => {
    setRolePermissions(list);
  };

  const onChangeUserPermission = (
    list: (string | number | CheckboxValueType)[]
  ) => {
    setUserPermissions(list);
  };

  const onChangeClientPermission = (
    list: (string | number | CheckboxValueType)[]
  ) => {
    setClientPermissions(list);
  };

  const onChangeApplicationPermission = (
    list: (string | number | CheckboxValueType)[]
  ) => {
    setApplicationPermissions(list);
  };

  const onChangeDepartmentPermission = (
    list: (string | number | CheckboxValueType)[]
  ) => {
    setDepartmentPermissions(list);
  };

  const onChangeProductPermission = (
    list: (string | number | CheckboxValueType)[]
  ) => {
    setProductPermissions(list);
  };
  const onChangeInstitutionPermission = (
    list: (string | number | CheckboxValueType)[]
  ) => {
    setInstitutionPermissions(list);
  };
  const onChangeWorkflowPermission = (
    list: (string | number | CheckboxValueType)[]
  ) => {
    setWorkflowPermissions(list);
  };

  const onChangeWorkflowTypePermission = (
    list: (string | number | CheckboxValueType)[]
  ) => {
    setWorkflowTypePermissions(list);
  };

  useEffect(() => {
    if (statePermissions.length > 0) {
      const textpermissionArr = statePermissions?.map((item: any) => {
        return {
          label: item.label,
          value: item.id,
          type: item.type,
        };
      });
      setPermissions(textpermissionArr);
    }
  }, [statePermissions, setPermissions]);

  useEffect(() => {
    if (selectedRole) {
      const selectedPermissionArr = selectedRole.permission.map(
        (item: { id: number }) => item.id
      );
      form?.setFieldsValue({
        name: selectedRole?.name,
        description: selectedRole?.description,
        office_permissions: selectedRole.permission
          .map((item: any) => (item.type === 'branches' ? item : null))
          .filter((item: any) => item !== null)
          .map((item: { id: number }) => item.id),
        role_permissions: selectedRole.permission
          .map((item: any) => (item.type === 'roles' ? item : null))
          .filter((item: any) => item !== null)
          .map((item: { id: number }) => item.id),
        user_permissions: selectedRole.permission
          .map((item: any) => (item.type === 'users' ? item : null))
          .filter((item: any) => item !== null)
          .map((item: { id: number }) => item.id),
        client_permissions: selectedRole.permission
          .map((item: any) =>
            item.type === 'leads' ||
            item.type === 'leads_assigned' ||
            item.type === 'leads_followed' ||
            item.type === 'lead_appointments' ||
            item.type === 'lead_documents' ||
            item.type === 'leads_assigneed_followed'
              ? item
              : null
          )
          .filter((item: any) => item !== null)
          .map((item: { id: number }) => item.id),
        application_permissions: selectedRole.permission
          .map((item: any) => (item.type === 'applications' ? item : null))
          .filter((item: any) => item !== null)
          .map((item: { id: number }) => item.id),
        department_permissions: selectedRole.permission
          .map((item: any) => (item.type === 'departments' ? item : null))
          .filter((item: any) => item !== null)
          .map((item: { id: number }) => item.id),
        products_permissions: selectedRole.permission
          .map((item: any) => (item.type === 'products' ? item : null))
          .filter((item: any) => item !== null)
          .map((item: { id: number }) => item.id),
        institutions_permissions: selectedRole.permission
          .map((item: any) => (item.type === 'institutions' ? item : null))
          .filter((item: any) => item !== null)
          .map((item: { id: number }) => item.id),
        workflows_permissions: selectedRole.permission
          .map((item: any) => (item.type === 'workflows' ? item : null))
          .filter((item: any) => item !== null)
          .map((item: { id: number }) => item.id),
        workflow_types_permissions: selectedRole.permission
          .map((item: any) => (item.type === 'workflow_types' ? item : null))
          .filter((item: any) => item !== null)
          .map((item: { id: number }) => item.id),
      });
      setOfficePermissions(selectedPermissionArr);
      setRolePermissions(selectedPermissionArr);
      setClientPermissions(selectedPermissionArr);
      setDepartmentPermissions(selectedPermissionArr);
      setApplicationPermissions(selectedPermissionArr);
      setProductPermissions(selectedPermissionArr);
      setInstitutionPermissions(selectedPermissionArr);
      setWorkflowPermissions(selectedPermissionArr);
      setWorkflowTypePermissions(selectedPermissionArr);
    }
  }, [selectedRole, form]);

  useEffect(() => {
    return () => {
      dispatch(rolePermissionActions.clearRolesSuccess());
      form.resetFields();
      setOfficePermissions([]);
      setRolePermissions([]);
      setClientPermissions([]);
      setDepartmentPermissions([]);
      setApplicationPermissions([]);
      setProductPermissions([]);
      setInstitutionPermissions([]);
      setWorkflowPermissions([]);
      setWorkflowTypePermissions([]);
    };
  }, [dispatch, form, setOfficePermissions]);

  return (
    <>
      {title && <h1>{title}</h1>}
      <Form form={form} layout="vertical" onFinish={handleSubmit}>
        <Form.Item
          label={ADD_NEW_ROLE_LABEL}
          required
          tooltip={ROLE_NAME_REQUIRED}
          rules={[{ required: true, message: ROLE_NAME_REQUIRED }]}
          name="name"
        >
          <Input placeholder={ADD_NEW_ROLE_LABEL} />
        </Form.Item>
        <Form.Item
          label={DESCRIPTION_LABEL}
          required
          name="description"
          tooltip={ROLE_DESCRIPTION_REQUIRED}
          rules={[{ required: true, message: ROLE_DESCRIPTION_REQUIRED }]}
        >
          <TextArea placeholder={DESCRIPTION_LABEL} />
        </Form.Item>
        <Collapse defaultActiveKey={['1', '2']} ghost>
          {permissions
            .map((item: any) => (item.type === 'branches' ? item : null))
            .filter((item) => item !== null).length > 0 && (
            <Panel
              showArrow={false}
              header={
                <Row
                  gutter={0}
                  justify="space-between"
                  className="custom__title__text"
                >
                  <Col lg={23}>{PERMISSIONS_OFFICES_TEAMS}</Col>
                </Row>
              }
              key="1"
            >
              <Form.Item name="office_permissions" valuePropName="checked">
                <CheckboxGroup
                  className="custom_role_vertical--checkbox"
                  options={
                    permissions
                      .map((item: any) =>
                        item.type === 'branches' ? item : null
                      )
                      .filter((item) => item !== null) || []
                  }
                  value={officePermissions}
                  onChange={onChangeOfficePermission}
                />
              </Form.Item>
            </Panel>
          )}
          {permissions.map((item: any) => (item.type === 'roles' ? item : null))
            .length > 0 && (
            <Panel
              showArrow={false}
              header={
                <Row
                  gutter={0}
                  justify="space-between"
                  className="custom__title__text"
                >
                  <Col lg={23}>{PERMISSIONS_ROLES}</Col>
                </Row>
              }
              key="2"
            >
              <Form.Item name="role_permissions" valuePropName="checked">
                <CheckboxGroup
                  className="custom_role_vertical--checkbox"
                  options={
                    permissions
                      .map((item: any) => (item.type === 'roles' ? item : null))
                      .filter((item) => item !== null) || []
                  }
                  value={rolePermissions}
                  onChange={onChangeRolePermission}
                />
              </Form.Item>
            </Panel>
          )}
          {permissions
            .map((item: any) => (item.type === 'users' ? item : null))
            .filter((item) => item !== null).length > 0 && (
            <Panel
              showArrow={false}
              header={
                <Row
                  gutter={0}
                  justify="space-between"
                  className="custom__title__text"
                >
                  <Col lg={23}>{PERMISSIONS_USERS}</Col>
                </Row>
              }
              key="6"
            >
              <Form.Item name="user_permissions" valuePropName="checked">
                <CheckboxGroup
                  className="custom_role_vertical--checkbox"
                  options={
                    permissions
                      .map((item: any) => (item.type === 'users' ? item : null))
                      .filter((item) => item !== null) || []
                  }
                  value={userPermissions}
                  onChange={onChangeUserPermission}
                />
              </Form.Item>
            </Panel>
          )}
          {permissions
            .map((item: any) =>
              item.type === 'leads' ||
              item.type === 'leads_assigned' ||
              item.type === 'leads_followed' ||
              item.type === 'lead_appointments' ||
              item.type === 'lead_documents' ||
              item.type === 'leads_assigneed_followed'
                ? item
                : null
            )
            .filter((item) => item !== null).length > 0 && (
            <Panel
              showArrow={false}
              header={
                <Row
                  gutter={0}
                  justify="space-between"
                  className="custom__title__text"
                >
                  <Col lg={23}>{PERMISSIONS_CLIENTS}</Col>
                </Row>
              }
              key="3"
            >
              <Form.Item name="client_permissions" valuePropName="checked">
                <CheckboxGroup
                  className="custom_role_vertical--checkbox"
                  options={
                    permissions
                      .map((item: any) =>
                        item.type === 'leads' ||
                        item.type === 'leads_assigned' ||
                        item.type === 'leads_followed' ||
                        item.type === 'lead_appointments' ||
                        item.type === 'lead_documents' ||
                        item.type === 'leads_assigneed_followed'
                          ? item
                          : null
                      )
                      .filter((item) => item !== null) || []
                  }
                  value={clientPermissions}
                  onChange={onChangeClientPermission}
                />
              </Form.Item>
            </Panel>
          )}
          {permissions
            .map((item: any) => (item.type === 'applications' ? item : null))
            .filter((item) => item !== null).length > 0 && (
            <Panel
              showArrow={false}
              header={
                <Row
                  gutter={0}
                  justify="space-between"
                  className="custom__title__text"
                >
                  <Col lg={23}>{PERMISSIONS_APPLICATIONS}</Col>
                </Row>
              }
              key="4"
            >
              <Form.Item name="application_permissions" valuePropName="checked">
                <CheckboxGroup
                  className="custom_role_vertical--checkbox"
                  options={
                    permissions
                      .map((item: any) =>
                        item.type === 'applications' ? item : null
                      )
                      .filter((item) => item !== null) || []
                  }
                  value={applicationPermissions}
                  onChange={onChangeApplicationPermission}
                />
              </Form.Item>
            </Panel>
          )}
          {permissions
            .map((item: any) => (item.type === 'departments' ? item : null))
            .filter((item) => item !== null).length > 0 && (
            <Panel
              showArrow={false}
              header={
                <Row
                  gutter={0}
                  justify="space-between"
                  className="custom__title__text"
                >
                  <Col lg={23}>{PERMISSIONS_DEPARTMENTS}</Col>
                </Row>
              }
              key="5"
            >
              <Form.Item name="department_permissions" valuePropName="checked">
                <CheckboxGroup
                  className="custom_role_vertical--checkbox"
                  options={
                    permissions
                      .map((item: any) =>
                        item.type === 'departments' ? item : null
                      )
                      .filter((item) => item !== null) || []
                  }
                  value={departmentPermissions}
                  onChange={onChangeDepartmentPermission}
                />
              </Form.Item>
            </Panel>
          )}
          {permissions
            .map((item: any) => (item.type === 'products' ? item : null))
            .filter((item) => item !== null).length > 0 && (
            <Panel
              showArrow={false}
              header={
                <Row
                  gutter={0}
                  justify="space-between"
                  className="custom__title__text"
                >
                  <Col lg={23}>{PERMISSIONS_PRODUCTS}</Col>
                </Row>
              }
              key="7"
            >
              <Form.Item name="products_permissions" valuePropName="checked">
                <CheckboxGroup
                  className="custom_role_vertical--checkbox"
                  options={
                    permissions
                      .map((item: any) =>
                        item.type === 'products' ? item : null
                      )
                      .filter((item) => item !== null) || []
                  }
                  value={productPermissions}
                  onChange={onChangeProductPermission}
                />
              </Form.Item>
            </Panel>
          )}
          {permissions
            .map((item: any) => (item.type === 'institutions' ? item : null))
            .filter((item) => item !== null).length > 0 && (
            <Panel
              showArrow={false}
              header={
                <Row
                  gutter={0}
                  justify="space-between"
                  className="custom__title__text"
                >
                  <Col lg={23}>{PERMISSIONS_INSTITUTIONS}</Col>
                </Row>
              }
              key="8"
            >
              <Form.Item
                name="institutions_permissions"
                valuePropName="checked"
              >
                <CheckboxGroup
                  className="custom_role_vertical--checkbox"
                  options={
                    permissions
                      .map((item: any) =>
                        item.type === 'institutions' ? item : null
                      )
                      .filter((item) => item !== null) || []
                  }
                  value={institutionPermissions}
                  onChange={onChangeInstitutionPermission}
                />
              </Form.Item>
            </Panel>
          )}
          {permissions
            .map((item: any) => (item.type === 'workflows' ? item : null))
            .filter((item) => item !== null).length > 0 && (
            <Panel
              showArrow={false}
              header={
                <Row
                  gutter={0}
                  justify="space-between"
                  className="custom__title__text"
                >
                  <Col lg={23}>{PERMISSIONS_WORKFLOWS}</Col>
                </Row>
              }
              key="9"
            >
              <Form.Item name="workflows_permissions" valuePropName="checked">
                <CheckboxGroup
                  className="custom_role_vertical--checkbox"
                  options={
                    permissions
                      .map((item: any) =>
                        item.type === 'workflows' ? item : null
                      )
                      .filter((item) => item !== null) || []
                  }
                  value={workflowPermissions}
                  onChange={onChangeWorkflowPermission}
                />
              </Form.Item>
            </Panel>
          )}
          {permissions
            .map((item: any) => (item.type === 'workflow_types' ? item : null))
            .filter((item) => item !== null).length > 0 && (
            <Panel
              showArrow={false}
              header={
                <Row
                  gutter={0}
                  justify="space-between"
                  className="custom__title__text"
                >
                  <Col lg={23}>{PERMISSIONS_WORKFLOWTYPES}</Col>
                </Row>
              }
              key="10"
            >
              <Form.Item
                name="workflow_types_permissions"
                valuePropName="checked"
              >
                <CheckboxGroup
                  className="custom_role_vertical--checkbox"
                  options={
                    permissions
                      .map((item: any) =>
                        item.type === 'workflow_types' ? item : null
                      )
                      .filter((item) => item !== null) || []
                  }
                  value={workflowTypePermissions}
                  onChange={onChangeWorkflowTypePermission}
                />
              </Form.Item>
            </Panel>
          )}
        </Collapse>
      </Form>
    </>
  );
};
