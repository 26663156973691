import { errorHandler } from '@moxie/utils';
import { useEffect, useState } from 'react';
import { PaginationMeta, CrmPaginatedResponse } from '@model/api-response.model';
import { useHistory, useLocation } from 'react-router';
import dot from 'dot-object';
import pickBy from 'lodash/pickBy';
import axios, { CancelToken } from 'axios';
import * as qss from 'qss';

interface Pagination {
  page: number;
  search?: string;
  sortBy?: string;
  limit?: number
}

interface ICrmPagination<T extends unknown> {
  // eslint-disable-next-line no-unused-vars
  action: (params: Record<string, unknown>, token?: CancelToken) => Promise<CrmPaginatedResponse<T>>;
  filter?: Record<string, unknown> | undefined;
  type?: string;
  token?: CancelToken;
  optimisticUpdate?: boolean;
}

let didInit = false;
function usePaginationCRM<T extends unknown>({
  action,
  filter,
  type,
  optimisticUpdate,
}: ICrmPagination<T>) {
  const history = useHistory();
  const location = useLocation();
  const [data, setData] = useState<T[]>([]);
  const [isLoading, setLoading] = useState(true);
  const [pagination, setPagination] = useState<Pagination>({
    page: 1,
    search: undefined,
    sortBy: undefined,
    limit: 10
  });
  const [refetching, setRefetching] = useState(false)
  const [initialLoading, setInitialLoading] = useState(false)

  const [paginationMeta, setPaginationMeta] = useState<PaginationMeta>({
    currentPage: 1,
    itemsPerPage: 10,
    sortBy: [],
    totalItems: 0,
    totalPages: 0,
  })

  useEffect(() => {
    const params = Object.fromEntries(new URLSearchParams(location.search.replace('?', '')));
    setPagination(prev => ({
      ...prev,
      page: Number(params['page'] ?? 1),
      limit: Number(params['limit'] ?? 10),
      search: params['search'] ?? undefined,
      sortBy: params['sortBy'] ?? undefined
    }))
  }, [])

  useEffect(() => {
    const cancelToken = axios.CancelToken;
    const source = cancelToken.source()
    executeAction(source.token)

    return () => {
      source.cancel()
      setPaginationMeta({
        currentPage: 1,
        itemsPerPage: 10,
        sortBy: [],
        totalItems: 0,
        totalPages: 0,
      });
      setData([]);
      setLoading(false);
    }
  }, [pagination]);

  useEffect(() => {
    setPagination(prev => ({
      ...prev,
      page: 1,
      limit: 10,
      // search: undefined,
      sortBy: undefined
    }))
  }, [JSON.stringify(filter)])

  const executeAction = async (cancelToken: CancelToken, refetch?: boolean) => {
    if (!didInit) {
      didInit = true;
      return;
    }
    !optimisticUpdate && setData([]);
    setLoading(true);
    refetch ? setRefetching(true) : setInitialLoading(true);

    try {

      dot.keepArray = true;
      const params = {
        ...pagination,
        ...((filter && Object.keys(filter).length) && dot.dot({ filter })),
        type,
      }
      const response = await action(params, cancelToken);
      setData(response.data.data);
      setPaginationMeta(response.data.meta);

      const url = qss.encode(pickBy(params, v => v !== undefined))
      history.replace({ search: url.toString() })
    } catch (err: any) {
      !optimisticUpdate && setData([]);
      if (err?.response?.status !== 404) errorHandler(err);
    }
    finally {
      setLoading(false);
      setRefetching(false);
      setInitialLoading(false)
    }
  };

  const refetchRecords = (token: CancelToken) => executeAction(token, true)

  return {
    data: data as readonly Record<string, unknown>[],
    paginationMeta,
    isLoading,
    setPagination,
    refetchRecords,
    pagination,
    refetching,
    initialLoading,
  }
}

export default usePaginationCRM;
