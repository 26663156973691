import {
  IAction,
  IPayload,
  IUser,
  IUserPersonalInformation,
} from '@shared-components/models';
import * as actionTypes from './user.constant';

export const fetchUsers = (): IAction => ({
  type: actionTypes.GET_USERS_REQUEST,
});

export const fetchUsersSuccess = (payload: IUser[]): IAction<IUser[]> => ({
  type: actionTypes.GET_USERS_SUCCESS,
  payload,
});

export const fetchUsersFailure = (payload: IPayload<IUser>): IAction<any> => ({
  type: actionTypes.GET_USERS_FAILURE,
  payload,
});

export const fetchUser = (payload: string): IAction<string> => ({
  type: actionTypes.GET_USER_REQUEST,
  payload,
});

export const fetchUserSuccess = (payload: IUser): IAction<IUser> => ({
  type: actionTypes.GET_USER_SUCCESS,
  payload,
});

export const fetchUserFailure = (payload: IPayload<IUser>): IAction => ({
  type: actionTypes.GET_USER_FAILURE,
  payload,
});

export const deleteUserRequest = (id: string): IAction<string> => ({
  type: actionTypes.DELETE_USER_REQUEST,
  payload: id,
});

export const deleteUserSuccess = (payload: any): IAction => ({
  type: actionTypes.DELETE_USER_SUCCESS,
  payload,
});

export const deleteUserFailure = (payload: IPayload<IUser>): IAction => ({
  type: actionTypes.DELETE_USER_FAILURE,
  payload,
});

export const clearFetchUser = (): IAction => ({
  type: actionTypes.CLEAR_USER_FETCH,
});

export const addUserRequest = (
  payload: IUser,
  triggerRefresh?: () => void
): IAction<IUser> => ({
  type: actionTypes.ADD_USER_REQUEST,
  payload,
  triggerRefresh,
});

export const addUserSuccess = (payload: IUser): IAction<IUser> => ({
  type: actionTypes.ADD_USER_SUCCESS,
  payload,
});

export const addUserFailure = (payload: IPayload<IUser>): IAction => ({
  type: actionTypes.ADD_USER_FAILURE,
  payload,
});

export const updateUserRequest = (
  payload: IUserPersonalInformation,
  triggerRefresh?: () => void
): IAction<IUser> => ({
  type: actionTypes.UPDATE_USER_REQUEST,
  payload,
  triggerRefresh,
});

export const updateUserSuccess = (payload: IUser): IAction<IUser> => ({
  type: actionTypes.UPDATE_USER_SUCCESS,
  payload,
});

export const updateUserFailure = (payload: IPayload<IUser>): IAction => ({
  type: actionTypes.UPDATE_USER_FAILURE,
  payload,
});

export const fetchFollowerUsers = (payload: string): IAction<string> => ({
  type: actionTypes.GET_FOLLOWER_USERS_REQUEST,
  payload,
});

export const fetchFollowerUsersSuccess = (
  payload: IUser[]
): IAction<IUser[]> => ({
  type: actionTypes.GET_FOLLOWER_USERS_SUCCESS,
  payload,
});

export const fetchFollowerUsersFailure = (
  payload: IPayload<IUser>
): IAction<any> => ({
  type: actionTypes.GET_FOLLOWER_USERS_FAILURE,
  payload,
});
