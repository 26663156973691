import React, { useEffect, useState, ReactNode } from 'react';
import { Link } from 'react-router-dom';
import {
  IActiveStage,
  ITableProps,
  IWorkflow,
  ILeadApplication,
  IFilterDropdownProps,
  IInstitutionDetail,
} from '@shared-components/models';
import { FilterConfirmProps } from 'antd/lib/table/interface';
import { APPLICATION_STATUS, COLUMNS, LABEL, TEXT } from '@moxie/constants';
import { ApplicationStatus, Table, UserNameCard } from '@moxie/shared';
import { filterTenantData } from '@admin/services.api';
import { AdminAssigneeComponent } from './application-admin-assignee';
import { Button, Form, Checkbox, Row } from 'antd';
import { ColumnType } from 'antd/lib/table';
import { BlockOutlined } from '@ant-design/icons';
import { TableClientApplicationNameCard } from '../../contact/client-application-name-card';

const CheckboxGroup = Checkbox.Group;
type DataIndex = keyof ILeadApplication;

const ApplicationTable = ({
  getData,
  tableName,
  filter,
  setFilter,
  tableButtons,
  isTabChanged,
}: ITableProps) => {
  const [refreshTable, setRefreshTable] = useState<boolean>(false);
  const [companies, setCompanies] = useState();

  useEffect(() => {
    (async () => {
      const response = await filterTenantData();
      setCompanies(response.data.data);
    })();
  }, []);

  const handleSearch = (
    selectedKeys: string[],
    confirm: (param?: FilterConfirmProps) => void,
    dataIndex: DataIndex,
    multipleData?: boolean
  ) => {
    confirm();
    if (multipleData) {
      setFilter && setFilter(dataIndex, selectedKeys.join());
      return;
    }
    setFilter && setFilter(dataIndex, selectedKeys[0]);
  };

  const handleReset = (clearFilters: () => void, dataIndex: string) => {
    clearFilters();
    setFilter && setFilter(dataIndex, '');
  };

  const getFilterProps = (
    dataIndex: DataIndex
  ): ColumnType<ILeadApplication> => ({
    filterDropdown: ({
      setSelectedKeys,
      selectedKeys,
      confirm,
      clearFilters,
    }: IFilterDropdownProps) => {
      const handleStatusChange = (list: any) => {
        setSelectedKeys(list);
      };

      return (
        <div>
          <Form className="border-bottom padding-1">
            <Form.Item className="margin-bottom-0">
              <CheckboxGroup
                className="custom_role_vertical--checkbox"
                options={dataIndex === 'company' ? companies : companies}
                value={selectedKeys}
                onChange={handleStatusChange}
              />
            </Form.Item>
          </Form>

          <Row className="padding-1" justify="space-between">
            <Button
              onClick={() => {
                clearFilters && handleReset(clearFilters, dataIndex);
                setSelectedKeys([]);
              }}
              size="small"
            >
              {LABEL.RESET}
            </Button>
            <Button
              type="primary"
              onClick={() => {
                handleSearch(
                  selectedKeys as string[],
                  confirm,
                  dataIndex,
                  true
                );
              }}
              size="small"
            >
              {TEXT.STATUS_DONE}
            </Button>
          </Row>
        </div>
      );
    },
  });

  const columns = () => {
    return [
      {
        title: TEXT.ID,
        dataIndex: 'id',
        key: 'id',
        width: 100,
        ellipsis: true,
        render(id: string, data: any) {
          return (
            <Link
              to={`/contact/detail/${data?.client?.id}/applications/${id}`}
              className="primary-id-color"
            >
              <Button
                type="primary"
                icon={<BlockOutlined />}
                className="view_application"
              />
              {data?.company?.company_code + '-' + data?.app_identifier}
            </Link>
          );
        },
      },
      {
        title: COLUMNS.CLIENT_NAME,
        dataIndex: 'client',
        width: 300,
        render(row: any): React.ReactElement {
          return (
            <UserNameCard
              firstName={row?.first_name || ''}
              lastName={row?.last_name || ''}
              email={row?.email || ''}
              link={`/contact/detail/${row?.id}/applications`}
            />
          );
        },
      },
      {
        title: COLUMNS.ADMIN_ASSIGNEE,
        dataIndex: 'admin_clients',
        ellipsis: true,
        render(text: any, data: any) {
          return (
            <AdminAssigneeComponent
              data={data}
              refreshTable={refreshTable}
              setRefreshTable={setRefreshTable}
              isTabChanged={isTabChanged}
            />
          );
        },
      },
      {
        title: LABEL.PRODUCT_INSTITUTION_NAME,
        width: 300,
        dataIndex: 'institution',
        ellipsis: true,
        sorter: () => 0,
        render(institution: IInstitutionDetail, data: ILeadApplication) {
          return <TableClientApplicationNameCard data={data} />;
        },
      },
      {
        title: LABEL.SERVICE,
        width: 200,
        ellipsis: true,
        dataIndex: 'service',
        sorter: () => 0,
        render(_text: string, data: ILeadApplication) {
          return data?.workflow?.workflowType?.service?.name;
        },
      },
      {
        title: LABEL.WORKFLOW,
        width: 300,
        ellipsis: true,
        render({ workflow }: { workflow: IWorkflow }) {
          return (
            <>
              <div>{workflow?.workflowType?.name}</div>
              <span className="table-mail-icon text-icon">
                {workflow?.country}
              </span>
            </>
          );
        },
      },
      {
        title: LABEL.TENANT,
        width: 350,
        ellipsis: true,
        ...getFilterProps('company'),
        render: (data: ILeadApplication): ReactNode => {
          return `${data?.company?.company_name}`;
        },
      },

      {
        title: LABEL.CURRENT_STAGE,
        width: 300,
        ellipsis: true,
        render({
          activeStage,
          status,
        }: {
          activeStage: IActiveStage;
          status: string;
        }) {
          return status === APPLICATION_STATUS.COMPLETED
            ? TEXT.COMPLETE
            : activeStage?.stage;
        },
      },
      {
        title: LABEL.STATUS,
        width: 200,
        ellipsis: true,
        render({ status }: { status: string }) {
          return <ApplicationStatus status={status} />;
        },
      },
    ];
  };

  return (
    <div className="padding-top-2 bg-white">
      <Table
        tableButtons={tableButtons}
        filter={filter}
        action={getData}
        tableName={tableName}
        columns={columns()}
        refresh={refreshTable}
        filterOptions={true}
      />
    </div>
  );
};

export { ApplicationTable };
