const alias = 'SUBJECT_DISCIPLINE';
const subject_areas = 'SUBJECT_AREAS';

export const GET_SUBJECT_DISCIPLINE_REQUEST = `GET_${alias}_REQUEST`;
export const GET_SUBJECT_DISCIPLINE_SUCCESS = `GET_${alias}_SUCCESS`;
export const GET_SUBJECT_DISCIPLINE_FAILURE = `GET_${alias}_FAILURE`;

export const GET_SUBJECT_AREAS_REQUEST = `GET_${subject_areas}_REQUEST`;
export const GET_SUBJECT_AREAS_SUCCESS = `GET_${subject_areas}_SUCCESS`;
export const GET_SUBJECT_AREAS_FAILURE = `GET_${subject_areas}_FAILURE`;
