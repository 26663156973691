/* eslint-disable react-hooks/exhaustive-deps */
import React from 'react';
import { Button, Space } from 'antd';
import { useForm } from 'antd/lib/form/Form';
import { DrawerElem } from '@shared-components/elements';
import { IAddEditProps, IOtherTestData } from '@shared-components/models';
import {
  ADD_OTHER_TEST_SCORE,
  CANCEL_BTN,
  DRAWER_WIDTH,
  EDIT_OTHER_TEST_SCORE,
  SAVE_BTN,
} from '@moxie/constants';
import { useDispatch } from 'react-redux';
import { OtherTestScoreForm } from '@shared-components/forms';
import { otherTestScoreActions, useAppSelector } from '@lyra/core';
import { RootState } from 'apps/lyra/src/core/store';

const OtherTestScoreAddEdit: React.FC<IAddEditProps<IOtherTestData>> = ({
  isOpen,
  name,
  onClose,
  id,
  initialData,
}: IAddEditProps<IOtherTestData>) => {
  const [form] = useForm();
  const dispatch = useDispatch();
  const { loading, user, socket, lead } = useAppSelector(
    (store: RootState) => ({
      loading: store.other_test_score.loading,
      user: store.auth.user,
      socket: store.socket.wss,
      lead: store.lead.lead,
    })
  );

  const handleSubmitSuccess = () => {
    form.resetFields();
    onClose();
  };

  const handleSubmitButtonClick = () => {
    form.submit();
  };

  const handleSubmit = async (data: any) => {
    if (!initialData?.id) {
      dispatch(
        otherTestScoreActions.addOtherTestScoreRequest(
          {
            parent_id: id,
            ...data,
          },
          lead,
          socket,
          user
        )
      );
      handleSubmitSuccess();
    } else {
      dispatch(
        otherTestScoreActions.updateOtherTestScoreRequest(
          {
            id: initialData?.id,
            parent_id: id,
            ...data,
          },
          lead,
          socket,
          user
        )
      );
      handleSubmitSuccess();
    }
  };

  return (
    <DrawerElem
      title={initialData?.id ? EDIT_OTHER_TEST_SCORE : ADD_OTHER_TEST_SCORE}
      width={DRAWER_WIDTH}
      onClose={onClose}
      visible={isOpen}
      footer={
        <div className="padding-bottom-2 text-align-right">
          <Space direction="horizontal">
            <Button onClick={onClose} className="lead-margin-right">
              {CANCEL_BTN}
            </Button>
            <Button
              onClick={handleSubmitButtonClick}
              type="primary"
              loading={loading}
            >
              {SAVE_BTN}
            </Button>
          </Space>
        </div>
      }
    >
      <OtherTestScoreForm
        form={form}
        name={name}
        onSubmit={handleSubmit}
        initialData={{ ...initialData }}
      />
    </DrawerElem>
  );
};

export { OtherTestScoreAddEdit };
