import { COMMENTS_PLACEHOLDER, LABEL } from '@moxie/constants';
import { Editor } from '@shared-components/forms';
import { IApplicationCommentsForm, IForm } from '@shared-components/models';
import Form from 'antd/lib/form';
import React from 'react';

const ApplicationCommentsForm: React.FC<IForm<IApplicationCommentsForm>> = ({
  form,
  setDisabled,
  onSubmit,
}: IForm<IApplicationCommentsForm>) => {
  const handleValueChange = ({ comment }: any) => {
    if (comment.replace(/<(.|\n)*?>/g, '').trim().length === 0) {
      setDisabled(true);
    } else {
      setDisabled(false);
    }
  };

  return (
    <div>
      <Form
        layout="vertical"
        form={form}
        onFinish={onSubmit}
        onValuesChange={handleValueChange}
      >
        <Form.Item
          name="comment"
          label={LABEL.COMMENTS}
          rules={[{ required: true }]}
        >
          <Editor placeholder={COMMENTS_PLACEHOLDER} />
        </Form.Item>
      </Form>
    </div>
  );
};

export { ApplicationCommentsForm };
