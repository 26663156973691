import React, { useEffect } from 'react';
import Form from 'antd/lib/form';
import { INoteFormProps } from '@shared-components/models';
import { Input } from '@shared-components/elements';
import {
  TITLE_LABEL,
  TITLE_PLACEHOLDER,
  DESCRIPTION_LABEL,
  DESCRIPTION_PLACEHOLDER,
  NOTES_RESPONSE_MESSAGE,
} from '@moxie/constants';
import { Editor } from '@shared-components/forms';

const NoteForm: React.FC<any> = ({
  form,
  noteForm,
  setNoteForm,
  isView,
  onSubmit,
  onChange,
  setDisabled,
}: INoteFormProps) => {
  useEffect(() => {
    form.setFieldsValue({
      ...noteForm,
      title: noteForm?.title,
      description: noteForm?.description,
    });
  }, [noteForm]);

  const handleSubmit = async () => {
    onSubmit(noteForm);
    setDisabled(true);
  };

  const handleEditorChange = (data: string) => {
    if (
      data.replace(/<(.|\n)*?>/g, '').trim().length === 0 ||
      noteForm?.description === data
    ) {
      setNoteForm({ ...noteForm, description: data });
      setDisabled(true);
    } else {
      setDisabled(false);
      setNoteForm({ ...noteForm, description: data });
    }
  };

  return (
    <Form
      initialValues={noteForm}
      name="note-form"
      form={form}
      layout="vertical"
      onFinish={handleSubmit}
    >
      <Form.Item
        label={TITLE_LABEL}
        validateFirst={true}
        name="title"
        rules={[
          { required: true, message: NOTES_RESPONSE_MESSAGE.REQUIRED_TITLE },
          { whitespace: true },
          { max: 70, message: NOTES_RESPONSE_MESSAGE.MAX_CHARACTER_MESSAGE },
        ]}
        className="note-title-input"
      >
        <Input
          name="title"
          placeholder={TITLE_PLACEHOLDER}
          onChange={onChange}
          disabled={isView}
        />
      </Form.Item>
      <Form.Item
        label={DESCRIPTION_LABEL}
        name="description"
        rules={[
          {
            required: true,
            message: NOTES_RESPONSE_MESSAGE.REQUIRED_DESCRIPTION,
          },
          ({ getFieldValue }) => ({
            validator(_, value) {
              if (
                value &&
                getFieldValue('description')
                  .replace(/<(.|\n)*?>/g, '')
                  .trim().length === 0
              ) {
                return Promise.reject(
                  NOTES_RESPONSE_MESSAGE.REQUIRED_DESCRIPTION
                );
              }
              return Promise.resolve();
            },
          }),
        ]}
      >
        <Editor
          placeholder={DESCRIPTION_PLACEHOLDER}
          onChange={handleEditorChange}
          isView={isView}
        />
      </Form.Item>
    </Form>
  );
};
export { NoteForm };
