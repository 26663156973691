import { STATUS } from '@moxie/constants';
import {
  IEducationLevel,
  IOfficeBranch,
  IService,
  IStatus,
  IWorkflowType,
} from '@shared-components/models';
import { AnyAction } from 'redux';
import {
  FAILED_SERVICE,
  FETCH_EDUCATION_LEVEL,
  FETCH_SERVICE,
  FETCH_SERVICE_COUNTRIES,
  FETCH_SERVICE_INSTITUTION,
  FETCH_SERVICE_WORKFLOW,
  IDLE_SERVICE,
  LOADING_SERVICE,
  RESET_COUNTRY,
  RESET_EDUCATION_LEVEL,
  RESET_SERVICE,
  RESET_WORKFLOW,
  SET_ERROR,
  SUCCEEDED_SERVICE,
} from './services.constant';

export interface IServiceReducers {
  service: IService[];
  status: IStatus;
  workflow: IWorkflowType[];
  error: string | null;
  servicesCountry: string[] | null;
  countries: any;
  education_levels: IEducationLevel[];
  institutions: IOfficeBranch[] | null;
}

export const INITIAL_DATA = {
  service: [],
  status: STATUS.IDLE,
  workflow: [],
  error: null,
  servicesCountry: [],
  countries: [],
  institutions: [],
  education_levels: [],
};

export const SERVICE_FEATURE_KEY = 'services';

export const serviceReducers = (
  state = INITIAL_DATA,
  action: AnyAction
): IServiceReducers => {
  switch (action.type) {
    case FETCH_SERVICE:
      return {
        ...state,
        service: action.payload,
      };
    case LOADING_SERVICE:
      return { ...state, status: STATUS.LOADING };
    case FETCH_SERVICE_WORKFLOW:
      return {
        ...state,
        workflow: action.payload,
      };
    case FETCH_SERVICE_COUNTRIES:
      return { ...state, countries: action.payload };
    case FETCH_EDUCATION_LEVEL:
      return { ...state, education_levels: action.payload };
    case FETCH_SERVICE_INSTITUTION:
      return { ...state, institutions: action.payload };
    case RESET_EDUCATION_LEVEL:
      return { ...state, institutions: [] };
    case IDLE_SERVICE:
      return { ...state, status: STATUS.IDLE };
    case SUCCEEDED_SERVICE:
      return { ...state, status: STATUS.SUCCEEDED };
    case FAILED_SERVICE:
      return { ...state, status: STATUS.FAILED };
    case SET_ERROR:
      return { ...state, error: action.payload };
    case RESET_SERVICE:
      return { ...state, workflow: [], countries: [], institutions: [] };
    case RESET_WORKFLOW:
      return { ...state, countries: [], institutions: [] };
    case RESET_COUNTRY:
      return { ...state, institutions: [] };
    default:
      return state;
  }
};

export default serviceReducers;
