import React from 'react';
import {
  PRODUCT_PROFILE_HEADERS,
  SLUG_DESCRIPTION,
  SLUG_EDUCATION_BACKGROUND,
  TAB_POSITION,
  URL_PRODUCT_DETAIL_LINK,
} from '@moxie/constants';
import { IProfileSection } from '@shared-components/models';
import { Card, Empty, Tabs, Typography } from 'antd';
import { useEffect, useState } from 'react';
import { useHistory, useParams } from 'react-router-dom';
import { AdminProductRequirement } from './product-detail-requirement';
import { ProductFeeList } from './product-fee-list';
const { TabPane } = Tabs;


const ProductDetailContent: React.FC<IProfileSection> = ({
  data,
}: IProfileSection) => {
  const { id } = data;
  const history = useHistory();
  const params = useParams<{ slug: string; id: string }>();
  const [activeKey, setActiveKey] = useState(params.slug);
  const handleChange = (key: string) => {
    const selected = PRODUCT_PROFILE_HEADERS.find((item) => item.slug === key);
    if (selected?.url) {
      history.push(`${URL_PRODUCT_DETAIL_LINK}${id}/${selected?.url}`);
    }
  };

  const tabContent = (key: string) => {
    switch (key) {
      // case 'applications':
      //   return (
      //     <div className="overflow-scroll bg-white">
      //       <div className="empty-content">
      //         <Empty image={Empty.PRESENTED_IMAGE_SIMPLE} />
      //       </div>
      //     </div>
      //   );
      case 'documents':
        return (
          <div className="overflow-scroll bg-white">
            <div className="empty-content">
              <Empty image={Empty.PRESENTED_IMAGE_SIMPLE} />
            </div>
          </div>
        );
      case 'fees':
        return (
          <div className="full-height-tab-content overflow-scroll">
            <ProductFeeList
              params={params}
              currency={data?.institution?.currency}
            />
          </div>
        );
      case 'requirements':
        return <AdminProductRequirement params={params} />;
      case 'other-information':
        return (
          <div className="overflow-scroll bg-white">
            <div className="empty-content">
              <Empty image={Empty.PRESENTED_IMAGE_SIMPLE} />
            </div>
          </div>
        );
      case 'promotions':
        return (
          <div className="overflow-scroll bg-white">
            <div className="empty-content">
              <Empty image={Empty.PRESENTED_IMAGE_SIMPLE} />
            </div>
          </div>
        );

      case SLUG_DESCRIPTION:
        return (
          <div className="overflow-scroll bg-white padding-top-2">
            {data?.description ? (
              <div
                className="padding-top-2 padding-left-2"
                dangerouslySetInnerHTML={{
                  __html: `${data?.description}`,
                }}
              ></div>
            ) : (
              <div className="empty-content">
                <Empty image={Empty.PRESENTED_IMAGE_SIMPLE} />
              </div>
            )}
          </div>
        );
      case SLUG_EDUCATION_BACKGROUND:
        return (
          <div className="empty-content">
            <Empty image={Empty.PRESENTED_IMAGE_SIMPLE} />
          </div>
        );
      default:
        return (
          <div className="overflow-scroll bg-white">
            <div className="empty-content">
              <Empty image={Empty.PRESENTED_IMAGE_SIMPLE} />
            </div>
          </div>
        );
    }
  };
  const setDefaultActiveKey = () => {
    const selected = PRODUCT_PROFILE_HEADERS.find(
      (item) => item.slug === params.slug
    );
    selected?.slug && setActiveKey(selected?.slug);
  };

  useEffect(() => {
    setDefaultActiveKey();
  }, [params.slug]);

  return (
    <section
      className={`lead_content ${activeKey === 'fees' ? 'product__fees' : ''} ${activeKey === 'requirements' ? 'product__requirements' : ''
        }`}
    >
      <div className="pro">
        <Tabs
          activeKey={activeKey}
          tabPosition={TAB_POSITION}
          onTabClick={(key) => handleChange(key)}
        >
          {[...Array.from(PRODUCT_PROFILE_HEADERS)].map((item) => {
            return (
              <TabPane
                tab={<Typography.Text strong>{item.name}</Typography.Text>}
                key={item.slug}
              >
                {tabContent(item.slug)}
              </TabPane>
            );
          })}
        </Tabs>
      </div>
    </section>
  );
};
export { ProductDetailContent };
