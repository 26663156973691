import { AxiosResponse } from 'axios';
import { put, takeLatest } from 'redux-saga/effects';
import * as institutionAction from './institution.action';
import * as institution from './institution.constant';
import { getAllInstitutes, instituteServices } from '@lyra/services.api';
import { errorHandler } from '@moxie/utils';
import { IAction } from '@shared-components/models';

export function* getInstitutions(): any {
  try {
    const res: AxiosResponse = yield getAllInstitutes();
    yield put(institutionAction.getInstitutionsSuccess(res.data.data));
  } catch (e) {
    errorHandler(e);
    yield put(institutionAction.getInstitutionsFailure(e));
  }
}

export function* getInstitution({ payload }: IAction<any>) {
  try {
    if (payload) {
      const response: AxiosResponse = yield instituteServices.apiGetInstituteDetail(
        payload
      );
      yield put(institutionAction.getInstitutionSuccess(response.data.data));
    }
  } catch (e) {
    errorHandler(e);
    yield put(institutionAction.getInstitutionFailure(e));
  }
}

export default function* institutionSaga(): unknown {
  yield takeLatest(institution.GET_INSTITUTIONS_REQUEST, getInstitutions);
  yield takeLatest(institution.GET_INSTITUTION_REQUEST, getInstitution);
}
