import React from 'react';
import { Row, Typography, Space } from 'antd';
import { IeOutlined } from '@ant-design/icons';
import { IPriceCard } from '@shared-components/models';

const { Title } = Typography;

const PriceCard: React.FC<IPriceCard> = ({
  id,
  icon,
  title,
  children,
  price,
  selected,
  onClick,
}: IPriceCard) => {
  return (
    <div
      className={`price-card padding-2 ${
        selected.toString() === id.toString() && 'selected'
      }`}
      data-testid="price-card"
      onClick={() => onClick(id)}
    >
      <Space direction="vertical">
        {icon && <Row>{React.cloneElement(icon, { className: 'icon' })}</Row>}
        <Title level={4}>{title}</Title>
        <div>{children}</div>
        <Title level={3}>{price || ''}</Title>
      </Space>
    </div>
  );
};

PriceCard.defaultProps = {
  icon: <IeOutlined />,
  title: 'Growth',
  selected: -1,
  id: -1,
  children: <> </>,
};
export { PriceCard };
