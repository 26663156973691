const alias = 'ADMIN_USER';

export const GET_USERS_REQUEST = `GET_${alias}S_REQUEST`;
export const GET_USERS_SUCCESS = `GET_${alias}S_SUCCESS`;
export const GET_USERS_FAILURE = `GET_${alias}_FAILURE`;

export const GET_USER_REQUEST = `GET_${alias}_REQUEST`;
export const GET_USER_SUCCESS = `GET_${alias}_SUCCESS`;
export const GET_USER_FAILURE = `GET_${alias}_FAILURE`;
export const CLEAR_USER_FETCH = `CLEAR_${alias}_FETCH`;

export const ADD_USER_REQUEST = `ADD_${alias}_REQUEST`;
export const ADD_USER_SUCCESS = `ADD_${alias}_SUCCESS`;
export const ADD_USER_FAILURE = `ADD_${alias}_FAILURE`;

export const UPDATE_USER_REQUEST = `UPDATE_${alias}_REQUEST`;
export const UPDATE_USER_SUCCESS = `UPDATE_${alias}_SUCCESS`;
export const UPDATE_USER_FAILURE = `UPDATE_${alias}_FAILURE`;

export const DELETE_USER_REQUEST = `DELETE_${alias}_REQUEST`;
export const DELETE_USER_SUCCESS = `DELETE_${alias}_SUCCESS`;
export const DELETE_USER_FAILURE = `DELETE_${alias}_FAILURE`;

export const GET_FOLLOWER_USERS_REQUEST = `GET_FOLLOWER_${alias}S_REQUEST`;
export const GET_FOLLOWER_USERS_SUCCESS = `GET_FOLLOWER_${alias}S_SUCCESS`;
export const GET_FOLLOWER_USERS_FAILURE = `GET_FOLLOWER_${alias}_FAILURE`;
