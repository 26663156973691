/* eslint-disable react/display-name */
import React, { ReactNode } from 'react';
import { Button, Table } from '@moxie/shared';
import { IUserForm, IUserTable } from '@shared-components/models';
import { TableUserNameCard } from './table-form-name-card';
import { ColumnsType } from 'antd/lib/table';
import {
  LABEL,
  PHONE_LABEL,
  TEXT,
  USER_ADD_TITLE,
  USER_TITLE,
} from '@moxie/constants';
import { PlusOutlined } from '@ant-design/icons';
import { useAppSelector } from '@admin/core';

const UserTable: React.FC<IUserTable> = ({
  showDrawer,
  filter,
  getData,
  refresh,
  filterOptions,
  actionBar,
}: IUserTable) => {
  const tableLoading = useAppSelector((state) => state.users.loading);

  const columns: ColumnsType<IUserForm> = [
    {
      title: LABEL.NAME,
      dataIndex: 'first_name',
      sorter: true,
      ellipsis: true,
      render: (_text: string, row: IUserForm, index: number): ReactNode => (
        <TableUserNameCard
          id={row.id}
          firstName={row.first_name}
          email={row.email}
          lastName={row.last_name}
          index={index.toString()}
          showDrawer={showDrawer}
          actionBar={actionBar}
        />
      ),
    },
    {
      title: TEXT.ADDRESS,
      render: (_text: string, row: IUserForm): ReactNode => (
        <div className="lead-table__added-from initial_capital">{`${
          row.address1 ? row.address1 : ''
        } ${row.city_or_state}, ${row.country}`}</div>
      ),
    },
    {
      title: PHONE_LABEL,
      render: (_text: string, row: IUserForm): ReactNode => (
        <div className="lead-table__added-from">{`${row.phone}`}</div>
      ),
    },
    {
      title: LABEL.EMAIL,
      render: (_text: string, row: IUserForm): ReactNode => (
        <div className="lead-table__added-from">{`${row.email.toLowerCase()}`}</div>
      ),
    },
  ];

  return (
    <div className="bg-white padding-top-2">
      <Table
        tableName={USER_TITLE}
        filterOptions={filterOptions}
        filter={filter}
        action={getData}
        columns={columns}
        refresh={refresh}
        tableLoading={tableLoading}
        tableButtons={
          <Button type="primary" onClick={() => showDrawer && showDrawer()}>
            <PlusOutlined />
            {USER_ADD_TITLE}
          </Button>
        }
      />
    </div>
  );
};

export { UserTable };
