import { ReactNode } from 'react';
import { LABEL, TEXT } from '@moxie/constants';
import { ILead, IOfficeBranch, IUser } from '@shared-components/models';
import moment from 'moment';
import { formatDateWithTimezone } from '@moxie/shared';

export const exportReportColumns = (userTimeZone: string) => [
  {
    title: LABEL.CLIENT_ID,
    width: 200,
    dataIndex: 'internal_id',
    render: (value: string, data: ILead): ReactNode => {
      return data?.company?.company_code + '-' + value;
    },
  },

  {
    title: LABEL.CLIENT,
    width: 300,
    dataIndex: 'client_name',
    render: (value: string, { first_name, last_name }: ILead): ReactNode => {
      return `${first_name} ${last_name}`;
    },
  },

  {
    title: TEXT.PHONE,
    width: 200,
    dataIndex: 'phone',
  },

  {
    title: LABEL.EMAIL,
    width: 200,
    dataIndex: 'email',
  },

  {
    title: LABEL.DATE_OF_BIRTH,
    width: 300,
    dataIndex: 'date_of_birth',
    render: (value: string): ReactNode => {
      return value ? moment(value).format('MMM DD, YYYY') : '-';
    },
  },

  {
    title: TEXT.ADDED_BY,
    width: 300,
    dataIndex: 'user',
    render: (data: IUser): ReactNode => {
      return data ? `${data?.first_name} ${data?.last_name}` : '-';
    },
  },

  {
    title: LABEL.ADDED_DATE,
    width: 200,
    dataIndex: 'created_at',
    render: (value: string): ReactNode => {
      return formatDateWithTimezone(value, userTimeZone);
    },
  },

  {
    title: LABEL.ASSIGNEE,
    width: 200,
    dataIndex: 'assignee',
    render: (data: IUser): ReactNode => {
      return data ? `${data?.first_name} ${data?.last_name}` : '-';
    },
  },

  {
    title: LABEL.BRANCH,
    width: 200,
    dataIndex: 'branch',
    render: (data: IOfficeBranch): ReactNode => {
      return data ? data?.name : '-';
    },
  },

  {
    title: LABEL.STREET,
    width: 200,
    dataIndex: 'street',
  },

  {
    title: LABEL.CITY,
    width: 200,
    dataIndex: 'city',
  },

  {
    title: LABEL.STATE,
    width: 200,
    dataIndex: 'state',
  },

  {
    title: LABEL.COUNTRY_OF_RESIDENCE,
    width: 200,
    dataIndex: 'country',
  },

  {
    title: LABEL.COUNTRY_OF_PASSPORT,
    width: 200,
    dataIndex: 'country_of_passport',
  },

  {
    title: LABEL.PASSPORT_NUMBER,
    width: 200,
    dataIndex: 'passport_no',
  },

  {
    title: LABEL.PASSPORT_EXPIRY_DATE,
    width: 200,
    dataIndex: 'passport_expiry_date',
    render: (value: string): ReactNode => {
      return value ? moment(value).format('MMM DD, YYYY') : '-';
    },
  },

  {
    title: LABEL.VISA_EXPIRY_DATE,
    width: 200,
    dataIndex: 'visa_expiry_date',
    render: (value: string): ReactNode => {
      return value ? moment(value).format('MMM DD, YYYY') : '-';
    },
  },
];
