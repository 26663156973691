import React from 'react';
import { Menu, Dropdown } from 'antd';
import {
  MoreOutlined,
  EditOutlined,
  DeleteOutlined,
  EyeOutlined,
} from '@ant-design/icons';
import { Button } from '@shared-components/elements';

import { DELETE_BTN, EDIT_BTN, VIEW_BTN } from '@moxie/constants';
import { INoteNameCardProps } from '@shared-components/models';

const NoteNameCard: React.FC<INoteNameCardProps> = ({
  data,
  showDrawer,
  deleteNote,
  archived,
}: INoteNameCardProps) => {
  const handleEditDrawer = () => {
    showDrawer(data?.key);
  };
  const handleViewDrawer = () => {
    showDrawer(data?.key, true);
  };

  const handleDelete = () => {
    deleteNote(data?.key);
  };

  const menu = (
    <Menu>
      <Menu.Item key="3" onClick={handleViewDrawer} icon={<EyeOutlined />}>
        {VIEW_BTN}
      </Menu.Item>
      {!archived && (
        <>
          <Menu.Item key="1" onClick={handleEditDrawer} icon={<EditOutlined />}>
            {EDIT_BTN}
          </Menu.Item>
          <Menu.Item
            key="2 "
            danger
            onClick={handleDelete}
            icon={<DeleteOutlined />}
          >
            {DELETE_BTN}
          </Menu.Item>
        </>
      )}
    </Menu>
  );

  return (
    <Dropdown overlay={menu}>
      <Button
        type="text"
        className="table-cell-action-btn"
        icon={<MoreOutlined />}
      />
    </Dropdown>
  );
};

export default NoteNameCard;
