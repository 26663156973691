import React, { useEffect, useState } from 'react';
import { Col, Row, Menu, Typography } from 'antd';
import { useHistory } from 'react-router-dom';
import { IPageHeaderMenuProps } from '@shared-components/models';
import { useAppSelector } from '@lyra/core';
import { AM_PERMISSIONS } from '@moxie/constants';
import permit from '@lyra/permit';

const { Text } = Typography;
const { CONTACTS } = AM_PERMISSIONS;

const PageHeaderMenu: React.FC<IPageHeaderMenuProps> = ({
  menu,
  children,
  defaultActiveKey,
}: IPageHeaderMenuProps) => {
  // TODO: REFACTOR REQUIRED!!!
  const history = useHistory();
  const [current, setCurrent] = useState(defaultActiveKey || menu[0].id);
  const rolePerms: string[] = useAppSelector(
    (state: any) => state?.auth?.user?.am_role?.permissions
  );

  useEffect(() => {
    if (defaultActiveKey) {
      if (defaultActiveKey !== current) setCurrent(defaultActiveKey);
    }
  }, [defaultActiveKey, current]);

  const handleChange = (e: any) => {
    const selected = menu.find((item) => item.id === e.key);
    setCurrent(e?.key);
    if (selected?.url) history.push(`/${selected?.url}`);
  };

  return (
    <Row className="page-header">
      <Col className="page-header__menu">
        <Menu onClick={handleChange} selectedKeys={[current]} mode="horizontal">
          {(menu || []).map((menuItem) => {
            switch (menuItem.id) {
              case "all-contacts":
                if (permit([CONTACTS.MANAGE], rolePerms)) {
                  return (
                    <Menu.Item key={menuItem.id}>
                      <Text strong>{menuItem.name}</Text>
                    </Menu.Item>
                  )
                }
                return
              case "archived":
                if (permit([CONTACTS.MANAGE, CONTACTS.READ_ARCHIVED], rolePerms)) {
                  return (
                    <Menu.Item key={menuItem.id}>
                      <Text strong>{menuItem.name}</Text>
                    </Menu.Item>
                  )
                }
                return
              case "my-contacts":
                if (permit([CONTACTS.MANAGE, CONTACTS.READ_ASSIGNED_FOLLOWED], rolePerms)) {
                  return (
                    <Menu.Item key={menuItem.id}>
                      <Text strong>{menuItem.name}</Text>
                    </Menu.Item>
                  )
                }
                return
              default:
                return
            }

          })}
        </Menu>
      </Col>
      <Col className="page-header__children">{children && children}</Col>
    </Row>
  );
};

export { PageHeaderMenu };
