import React from 'react';
import { Checkbox, Col, Form, Row } from 'antd';
import { ILeadsFormComponent } from '@shared-components/models';
import { TITLE, LABEL, GUTTER } from '@moxie/constants';
import { globalHandleCheck } from '@moxie/shared';

const ContactDetails: React.FC<ILeadsFormComponent> = ({
  setLeadForm,
  leadForm,
  setDisabled,
}: ILeadsFormComponent) => {
  const handleChange = (e: any) => {
    globalHandleCheck(
      e,
      leadForm,
      setLeadForm,
      'contact_details_section',
      setDisabled
    );
  };

  const onCheckAllChange = (e: any) => {
    setDisabled(false);
    if (!e.target.checked) {
      setLeadForm({
        ...leadForm,
        contact_details_section: {
          ...leadForm.contact_details_section,
          phone_number: false,
          email: false,
          contact_preference: false,
        },
      });
      return;
    }
    setLeadForm({
      ...leadForm,
      contact_details_section: {
        ...leadForm.contact_details_section,
        phone_number: true,
        email: true,
        contact_preference: true,
      },
    });
  };

  return (
    <>
      <Row justify="space-between" className="custom__title__text">
        <Col>{TITLE.CONTACT_DETAILS}</Col>
        <Col>
          <Checkbox
            checked={
              leadForm?.contact_details_section?.phone_number &&
              leadForm?.contact_details_section?.email &&
              leadForm?.contact_details_section?.contact_preference
            }
            onChange={onCheckAllChange}
          />
        </Col>
      </Row>
      <Form.Item className="custom__formItem">
        <Row gutter={GUTTER}>
          <Col span={5} className="lead-form-box">
            <Checkbox
              name="phone_number"
              disabled
              checked={leadForm?.contact_details_section?.phone_number || true}
              onChange={handleChange}
            >
              {LABEL.PHONE_NUMBER}
            </Checkbox>
          </Col>
          <Col span={5} className="lead-form-box">
            <Checkbox
              name="email"
              disabled
              checked={leadForm?.contact_details_section?.email || true}
              onChange={handleChange}
            >
              {LABEL.EMAIL}
            </Checkbox>
          </Col>
          <Col span={5} className="lead-form-box">
            <Checkbox
              name="contact_preference"
              checked={leadForm?.contact_details_section?.contact_preference}
              onChange={handleChange}
            >
              {LABEL.CONTACT_PREFERENCES}
            </Checkbox>
          </Col>
        </Row>
      </Form.Item>
    </>
  );
};

export default ContactDetails;
