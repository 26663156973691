import React from 'react';
import { Col, Row, Space, Typography } from 'antd';
import { Button } from '@shared-components/elements';
import { UNSELECT, ALL, ITEMS_SELECTED } from '@moxie/constants';
import { ToolBarProps } from '@shared-components/models';

const { Text } = Typography;

const ToolBar: React.FC<ToolBarProps> = ({
  currentSelected,
  totalDataCount,
  onUnselect,
  children,
}: ToolBarProps) => {
  return (
    <Row className="table__option-bar">
      <Col span={14}>
        <Row gutter={16}>
          <Col className="table__col">
            <Text>
              {currentSelected === totalDataCount ? ALL : ''} {currentSelected}{' '}
              {ITEMS_SELECTED}
            </Text>
          </Col>
          <Col className="table__col">
            <Button type="text" onClick={onUnselect}>
              <Text type="danger">{UNSELECT}</Text>
            </Button>
          </Col>
        </Row>
      </Col>
      <Col span={10}>
        <Row justify="end" gutter={24}>
          <Col className="table__col">
            <Space>{children}</Space>
          </Col>
        </Row>
      </Col>
    </Row>
  );
};

export { ToolBar };
