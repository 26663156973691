export enum socketSagaActions {
  SET_SOCKET_CONNECTION = 'SET_SOCKET_CONNECTION',
  REMOVE_SOCKET_CONNECTION =  'REMOVE_SOCKET_CONNECTION',
}
export const setSocketRequest = (data: any) => {
  return {
    type: socketSagaActions.SET_SOCKET_CONNECTION,
    payload: data.payload,
  };
};
export const removeSocketRequest = () => {
  return {
    type: socketSagaActions.REMOVE_SOCKET_CONNECTION,
  };
};
