import { useEditor } from '@tiptap/react';
import StarterKit from '@tiptap/starter-kit';
import Mention from '@tiptap/extension-mention';
import Underline from '@tiptap/extension-underline';
import suggestion from './suggestion';
import Highlight from '@tiptap/extension-highlight';
import Typography from '@tiptap/extension-typography'

export const extensions = [
  StarterKit.configure({
    history: {
      depth: 50
    },
  }),
  Underline,
  Highlight,
  Typography,
  Mention.configure({
    HTMLAttributes: {
      class: 'tiptap-mention'
    },
    suggestion,
  }),
];

const useTipTapEditor = (content?: string) => {
  const editor = useEditor({
    content,
    extensions,
    editorProps: {
      attributes: {
        class: 'tiptap-editor',
      },
    },
  });

  return editor;
}


export default useTipTapEditor;
