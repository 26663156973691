import axios from './axios';
import { IUserPersonalInfo, IUserAddResponse } from '@shared-components/models';
import { AM_API_URL } from '@moxie/constants';

const UserAdd = async (body: IUserPersonalInfo): Promise<IUserAddResponse> => {
  return axios.post(AM_API_URL.ADD_USER, body);
};

const UserList = async (params?: string): Promise<IUserAddResponse> => {
  return axios.get(`${AM_API_URL.USER_LIST}/${params}`);
};

const UserListWithoutAssignee = async (
  leadId: string
): Promise<IUserAddResponse> => {
  return axios.get(`${AM_API_URL.USER_LIST}/assignee/${leadId}`);
};

const UserData = async (id: string): Promise<IUserAddResponse> => {
  return axios.get(`${AM_API_URL.USER}/${id}`);
};

const updateUserData = async (
  id: string,
  body: IUserPersonalInfo
): Promise<IUserAddResponse> => {
  return axios.put(`${AM_API_URL.USER}/${id}`, body);
};

const checkUserEmail = (email: string): Promise<any> => {
  return axios.get(`${AM_API_URL.CHECK_USER_EMAIL}/${email}`);
};

export {
  UserAdd,
  UserList,
  UserData,
  updateUserData,
  UserListWithoutAssignee,
  checkUserEmail,
};
