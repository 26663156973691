import { errorHandler } from '@moxie/utils';
import { AxiosResponse } from 'axios';
import { call, ForkEffect, put, takeLatest } from 'redux-saga/effects';
import { getTenantRoles } from '../../libs/services.api/lib/role.api';
import * as rolePermissionActions from './role-permission.action';
import * as actionTypes from './role-permission.constant';

function* getRoles() {
  try {
    const res: AxiosResponse = yield call(getTenantRoles);
    yield put(rolePermissionActions.getRolesSuccess(res.data.data));
  } catch (err) {
    errorHandler(err);
  }
}

export default function* rolePermissionSaga(): Generator<
  ForkEffect<never>,
  void,
  unknown
> {
  yield takeLatest(actionTypes.GET_ROLES_REQUEST, getRoles);
}
